import { updateMapPosition } from '../../redux/action/map'

export const processRegistrationMessageThunk = (message) => (dispatch) => {
  switch (message?.type) {
    case 'registration.DRIVER_GEO_UPDATED_EVENT':
      dispatch(updateMapPosition(message.data))
      break
    default:
      // eslint-disable-next-line
      console.error('Unknown registration message type: ', JSON.stringify(message))
      break
  }
}
