import styled from 'styled-components'
import { theme } from '../../../style/theme'

export const ErrorModalBody = styled.div`
  > button {
    margin-top: 20px;
  }
`

export const ModalWrapper = styled.div`
	font-size: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.33);
	backdrop-filter: blur(5px);
	z-index: 9;
	visibility: hidden;
	opacity: 0;

	&.active {
		visibility: visible;
		opacity: 1;
		z-index: 999;
		background-color: #000000bf;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1rem;
		opacity: 1;
		top: 100%;
		background-color: transparent;
		backdrop-filter: none;
		transition: all .3s;

		&.active {
			top: 0;
			background-color: transparent;
			backdrop-filter: none;
		}
	}
`
ModalWrapper.displayName = 'ModalWrapper'

export const ModalMain = styled.main`
	position: absolute;
	height: auto;
	width: 31%;
	box-sizing: border-box;
	background-color: #FFFFFF;
	border: 2px solid #FFFFFF;
	font-size: 1em;
	padding: 2.8em 3.1em;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	text-align: center;
	border-radius: 3.75em;
	min-width: auto;

	@media screen and (max-width: ${theme.point820}) {
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			transform: none;
			padding: 0;
			border-radius: 0;
			overflow-y: auto;
			box-shadow: 0 4px 150px rgba(0, 0, 0, 0.41);
			min-width: auto;
			height: 100%;

		> .btn-close-modal {
			display: none;
		}

	}
`
ModalMain.displayName = 'ModalMain'

export const ModalMainBlock = styled.div`
	height: 100%;
`
ModalMainBlock.displayName = 'ModalMainBlock'

export const ModalHeader = styled.h2`
  color: #000000;
  min-height: 2.4em;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.45em;
  padding: 0 6em;

  @media screen and (max-width: ${theme.point820}) {
    position: sticky;
    z-index: 101;
    top: 0;
    padding: .5em 1em .5em 0.63em;
    min-height: 3.75em;
    margin: 0;
    background-color: #FFFFFF;
    box-shadow: 0 4px 4px 0 #00000026;
    border-bottom: 1px solid #777f8a;
    justify-content: space-between;
    flex-wrap: nowrap;
    text-align: start;
  }
`
ModalHeader.displayName = 'ModalHeader'

export const ModalHeaderTitle = styled.div`
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: .63em;
	flex-direction: column;

	@media screen and (max-width: ${theme.point820}) {
			flex-direction: row;
  }
`
ModalHeaderTitle.displayName = 'ModalHeaderTitle'

export const ModalHeaderTitleBlock = styled.div`
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: .94em;

  > svg.header-icon {
    width: 3.31em;
    height: 3.31em;
    margin-top: -0.75em;
    margin-bottom: -0.25em;
  }

  @media screen and (max-width: ${theme.point820}) {
    > svg.header-icon {
			display: none;
    }
  }
`
ModalHeaderTitleBlock.displayName = 'ModalHeaderTitleBlock'

export const ModalHeaderTitleText = styled.div`
  font-family: ${theme.fontRobotoBold};
  font-size: 1.5em;
`
ModalHeaderTitleText.displayName = 'ModalHeaderTitleText'

export const ModalBody = styled.div`
  margin-top: 2.7em;

  @media screen and (max-width: ${theme.point820}) {
    padding: 0 0.63em 0.01%;
    margin-top: 1.25em;
    max-height: none;
    overflow-y: visible;
    height: auto;
    position: relative;
		margin-bottom: 6.25em;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  padding-top: 1.25em;
  gap: 0.63em;
  justify-content: space-between;

  > :not(.back) {
    margin-left: auto;
  }

  @media screen and (max-width: ${theme.point820}) {
		width: 100%;
    padding: 0.63em;
    position: fixed;
    bottom: 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
		background-color: #FFFFFF;
  }

  @media screen and (max-width: ${theme.point720}) {
    > * {
      width: 100%;
    }
  }
`

export const ModalCloseBlock = styled.div`
  position: absolute;
  right: 3.1em;
  top: 2.8em;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    width: 2.1em;
    height: 2.1em;
		fill: #2981EE;
  }

  @media screen and (max-width: ${theme.point820}) {
    display: none;
  }
`
ModalCloseBlock.displayName = 'ModalCloseBlock'

export const ModalCloseKey = styled.div`
	width: 2.9em;
	height: 2.9em;
	box-sizing: border-box;
	border-radius: 0.5em;
	border: 1px solid #989da5;
	background-color: #FFFFFF;
	color: #989da5;
	font-family: ${theme.fontRobotoBold};
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8em;
	line-height: 1.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1.7em;
`

export const ButtonWrapper = styled.button`
  font-size: 1rem;
  background-color: #38cd90;
  border: 1px solid #38cd90;
  padding: 0.4em 0.88em;
  border-radius: 0.75em;
  display: flex;
  justify-content: center;
  gap: 0.63em;
  align-items: center;
  color: #FFF;
  min-height: 3.44em;
  cursor: pointer;
`
ButtonWrapper.displayName = 'ButtonWrapper'

export const ButtonText = styled.div`
	font-size: 1.25em;
	display: flex;
	align-items: center;
	font-family: ${theme.fontRobotoMedium};
	color: #FFF;
	text-align: left;

	@media screen and (max-width: ${theme.point720}) {
		white-space: nowrap;
	}
`
ButtonText.displayName = 'ButtonText'

export const ButtonIconWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5em;
	height: 1.56em;
	order: 0;
	flex-shrink: 0;

	> svg {
		width: 100%;
		height: 100%;
	}
`
ButtonIconWrap.displayName = 'ButtonIconWrap'
