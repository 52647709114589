import styled from 'styled-components'
import colors from '../../../../style/colors'
import { theme } from '../../../../style/theme'

export const DeliveryCostWrap = styled.label`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	cursor: pointer;
`
DeliveryCostWrap.displayName = 'DeliveryCostWrap'

export const DeliveryCostTitleWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 0.63em;
`
DeliveryCostTitleWrap.displayName = 'DeliveryCostTitleWrap'

export const DeliveryCostInputWrap = styled.div`
	display: flex;
	gap: 0.19em;
	align-items: center;
`
DeliveryCostInputWrap.displayName = 'DeliveryCostInputWrap'

export const DeliveryCostInput = styled.div`
	width: 5.88em;
	height: 2.13em;
	border-radius: 0.37em;
	border: 1px solid #777F8A;
	padding: 0.1em 0.31em;
	outline: 0;
	position: relative;
	align-self: normal;

	&.active {
		border-color: #2981EE;
	}

	&.long-currency {
		width: 6.4em;
	}

	&.error {
		border-color: ${colors.red};
	}

	> input {
		width: 100%;
		padding: 0;
		border: none;
		margin: 0;
		background: transparent;
		height: 100%;
		font-size: 1.12em;
		font-family: ${theme.fontRobotoMedium};
		float: left;
		box-sizing: border-box;
		color: ${colors.black};
		text-align: right;
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
			display: none;
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&:after {
		display: block;
		content: '${props => props.currencySymbol && props.currencySymbol}';
		font-size: 1em;
		font-family: ${theme.fontRobotoMedium};
		position: absolute;
		top: 0.5em;
		right: 0.88em;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.5em 0.56em;
		width: ${props => props.width ? '5em' : '3.6em'};
	}
`
DeliveryCostInput.displayName = 'DeliveryCostInput'
