import { ADD_TAKEAWAY_TESTED_RESTAURANT, APPEND_TAKEAWAY_RESTAURANTS, SET_TAKEAWAY_RESTAURANT, SET_TAKEAWAY_RESTAURANTS, SET_TAKEAWAY_RESTAURANTS_TOTAL_LENGTH, SET_TAKEAWAY_RESTAURANT_IS_NOT_VALID, SET_TAKEAWAY_SHOW_CREATE_RESTAURANT_ERROR_MODAL, SET_TAKEAWAY_SHOW_TEST_CLIENT_ERROR_MODAL, SET_TAKEAWAY_SHOW_TEST_SERVER_ERROR_MODAL, SET_TAKEAWAY_TEST_ERROR_STATUS } from '../../actionTypes/takeawayRestaurant'

const initialState = {
	restaurants: [],
	restaurantsTotalLength: 0,
	restaurant: null,
	testedRestaurants: [],
	showTestClientErrorModal: false,
	showTestServerErrorModal: false,
	testErrorStatus: null,
	showCreateRestaurantErrorModal: false,
	restaurantIsNotValid: false
}

export function takeawayRestaurantReducer(state = initialState, action) {
	switch (action.type) {
		case SET_TAKEAWAY_RESTAURANTS:
			return {
				...state,
				restaurants: action.value
			}
		case SET_TAKEAWAY_RESTAURANTS_TOTAL_LENGTH:
			return {
				...state,
				restaurantsTotalLength: action.value
			}
		case SET_TAKEAWAY_RESTAURANT:
			return {
				...state,
				restaurant: action.value
			}
		case APPEND_TAKEAWAY_RESTAURANTS:
			return {
				...state,
				restaurants: state.restaurants ? state.restaurants.concat(...action.value) : action.value
			}
		case ADD_TAKEAWAY_TESTED_RESTAURANT:
			const index = state.testedRestaurants.findIndex(tr => tr.id === action.value.id)
			return {
				...state,
				testedRestaurants: index === -1 ?
					[...state.testedRestaurants, action.value] :
					[...state.testedRestaurants.slice(0, index), action.value, ...state.testedRestaurants.slice(index + 1)]
			}
		case SET_TAKEAWAY_SHOW_TEST_CLIENT_ERROR_MODAL:
			return {
				...state,
				showTestClientErrorModal: action.value
			}
		case SET_TAKEAWAY_SHOW_TEST_SERVER_ERROR_MODAL:
			return {
				...state,
				showTestServerErrorModal: action.value
			}
		case SET_TAKEAWAY_TEST_ERROR_STATUS:
			return {
				...state,
				testErrorStatus: action.value
			}
		case SET_TAKEAWAY_SHOW_CREATE_RESTAURANT_ERROR_MODAL:
			return {
				...state,
				showCreateRestaurantErrorModal: action.value
			}
		case SET_TAKEAWAY_RESTAURANT_IS_NOT_VALID:
			return {
				...state,
				restaurantIsNotValid: action.value
			}

		default:
			return state
	}
}
