import { useEffect, useState, useMemo } from 'react'

const useAudio = url => {
	const audio = useMemo(() => new Audio(url), [url])
	const [playing, setPlaying] = useState(false)

	useEffect(() => {
		if (playing) {
			const doPlay = () => {
				audio.play()
					.catch(() => {
						// eslint-disable-next-line
						console.warn('Failed to play music')
						setTimeout(doPlay, 10000)
					})
			}
			audio.addEventListener('ended', doPlay)
			doPlay()
			return () => audio.removeEventListener('ended', doPlay)
		}
	}, [playing])

	return [playing, setPlaying]
}

export default useAudio
