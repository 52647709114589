import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const DailyStatistics = lazyWithRetry(() => import(/* webpackChunkName: "daily-statistics" */'../../component/Statistic/DailyStatistics'))

const DailyStatisticsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<DailyStatistics />
		</Suspense>
	)
})

DailyStatisticsPage.displayName = 'DailyStatisticsPage'
export default DailyStatisticsPage
