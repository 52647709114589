import React from 'react'
import { SvgTableWrap } from '../index.styled'

const SVGOvalTable = ({ index, onClick, data, children, color, testId, id, animationClassname }) => {
	const isVertical = data.width < data.height
	const layout = (isVertical ? 'vertical ' : '') + (color ? `${color} ` : '') + (data.isEm ? 'sizeWithEm ' : '') + (animationClassname ? animationClassname + ' ' : '')
	return (
		<SvgTableWrap {...data}
									className={layout}
									id={id}
									onClick={onClick}
									data-testid={testId}>
			{children}
			<OvalTableSVG chairCount={data.chairCount} className={layout} />
		</SvgTableWrap>
	)
}
SVGOvalTable.displayName = 'SVGOvalTable'
export default SVGOvalTable

export const OvalTableSVG = ({ chairCount, className }) => {
	const chairClassname = (chairNumber) => {
		return chairNumber <= chairCount ? '' : 'hidden'
	}
	return (
		<svg viewBox="0 0 260 196" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g className={chairClassname(5)}>
				<path d="M59.676 57.689L34.0185 76.4284C31.9245 77.9309 28.9383 77.4638 27.3253 75.3779L8.5985 49.8449C7.08875 47.761 7.55808 44.789 9.654 43.1838L35.3096 24.5471C37.4036 23.0446 40.3898 23.5117 42.0028 25.5976L60.7296 51.1306C62.2393 53.2146 61.77 56.1865 59.676 57.689Z" fill="#7A7A7A" />
				<path d="M38.2178 23.6764C39.6632 23.7035 41.0969 24.3471 42.0028 25.5975L60.7295 51.1304C62.2393 53.2144 61.77 56.1863 59.674 57.7915L34.0184 76.4282C33.1808 77.0292 32.1426 77.3181 31.1102 77.2988C29.6648 77.2717 28.2311 76.6281 27.3252 75.3777L8.59845 49.8447C7.0887 47.7608 7.55803 44.7889 9.65395 43.1836L35.3096 24.5469C36.2504 23.9479 37.1854 23.657 38.2178 23.6764ZM38.2761 20.594C36.521 20.5611 34.8594 21.0439 33.4959 22.1489L7.84224 40.6829C4.38669 43.1877 3.57072 48.106 6.08568 51.6477L24.8124 77.1807C26.3241 79.1619 28.5741 80.3347 31.0519 80.3812C32.807 80.4141 34.4685 79.9313 35.8321 78.8262L61.4877 60.1895C63.1629 58.9875 64.3335 57.1594 64.5788 55.1083C64.9274 53.0592 64.3469 50.9927 63.1391 49.3255L44.5155 23.7945C43.0058 21.7105 40.7539 20.6405 38.2761 20.594Z" fill="white" />
			</g>
			<g className={chairClassname(7)}>
				<path d="M198.324 57.689L223.982 76.4284C226.076 77.9309 229.062 77.4638 230.675 75.3779L249.401 49.8449C250.911 47.761 250.442 44.789 248.346 43.1838L222.69 24.5471C220.596 23.0446 217.61 23.5117 215.997 25.5976L197.27 51.1306C195.761 53.2146 196.23 56.1865 198.324 57.689Z" fill="#7A7A7A" />
				<path d="M219.782 23.6764C218.337 23.7035 216.903 24.3471 215.997 25.5975L197.27 51.1304C195.761 53.2144 196.23 56.1863 198.326 57.7915L223.982 76.4282C224.819 77.0292 225.857 77.3181 226.89 77.2988C228.335 77.2717 229.769 76.6281 230.675 75.3777L249.402 49.8447C250.911 47.7608 250.442 44.7889 248.346 43.1836L222.69 24.5469C221.75 23.9479 220.815 23.657 219.782 23.6764ZM219.724 20.594C221.479 20.5611 223.141 21.0439 224.504 22.1489L250.158 40.6829C253.613 43.1877 254.429 48.106 251.914 51.6477L233.188 77.1807C231.676 79.1619 229.426 80.3347 226.948 80.3812C225.193 80.4141 223.531 79.9313 222.168 78.8262L196.512 60.1895C194.837 58.9875 193.667 57.1594 193.421 55.1083C193.073 53.0592 193.653 50.9927 194.861 49.3255L213.484 23.7945C214.994 21.7105 217.246 20.6405 219.724 20.594Z" fill="white" />
			</g>
			<g className={chairClassname(1)}>
				<path d="M115.558 49.6167L80.0482 56.4036C77.1087 56.9887 74.2867 54.9995 73.6988 52.0741L66.8791 16.7354C66.2912 13.81 68.2901 11.0016 71.2296 10.4165L106.739 3.62962C109.679 3.04454 112.5 5.0338 113.088 7.95919L119.908 43.2979C120.496 46.2233 118.497 49.0317 115.558 49.6167Z" fill="#7A7A7A" />
				<path d="M107.798 3.51047C110.385 3.51047 112.619 5.2657 113.089 7.84004L119.909 43.1788C120.496 46.1041 118.498 48.9125 115.558 49.4976L80.0487 56.2845C79.696 56.4015 79.3432 56.4015 78.9905 56.4015C76.4037 56.4015 74.1697 54.6463 73.6994 52.0719L66.8797 16.7332C66.2918 13.8078 68.2906 10.9995 71.2302 10.4144L106.74 3.62748C107.092 3.51047 107.445 3.51047 107.798 3.51047ZM107.798 0C107.21 0 106.622 0 106.152 0.117016L70.5247 6.90392C65.7039 7.84004 62.5292 12.5207 63.4698 17.3183L70.2895 52.657C71.1126 56.8696 74.7576 59.912 79.1081 59.912C79.696 59.912 80.2839 59.912 80.7542 59.795L116.264 53.0081C121.084 52.0719 124.259 47.3913 123.318 42.5937L116.499 7.25497C115.793 3.04241 112.031 0 107.798 0Z" fill="white" />
			</g>
			<g className={chairClassname(3)}>
				<path d="M177.942 56.4042L142.439 49.6173C139.5 49.0322 137.619 46.2238 138.089 43.2984L144.908 7.95972C145.496 5.03433 148.317 3.16208 151.256 3.63015L186.759 10.4171C189.698 11.0021 191.579 13.8105 191.108 16.7359L184.29 52.0746C183.82 55 180.998 56.8722 177.942 56.4042Z" fill="#7A7A7A" />
				<path d="M150.316 3.51047C150.669 3.51047 151.021 3.51047 151.374 3.62748L186.877 10.4144C189.816 10.9995 191.697 13.8078 191.227 16.7332L184.408 52.0719C183.938 54.6463 181.587 56.4015 179.118 56.4015C178.765 56.4015 178.413 56.4015 178.06 56.2845L142.44 49.6146C139.501 49.0295 137.62 46.2212 138.09 43.2958L144.908 7.95706C145.496 5.2657 147.73 3.51047 150.316 3.51047ZM150.316 0C146.084 0 142.322 3.04241 141.499 7.25497L134.681 42.5937C133.74 47.3913 136.914 52.0719 141.734 53.0081L177.237 59.795C177.825 59.912 178.413 59.912 178.883 59.912C183.115 59.912 186.877 56.8696 187.7 52.657L194.518 17.3183C194.988 14.978 194.518 12.6377 193.107 10.6484C191.814 8.65915 189.698 7.37198 187.347 6.90392L151.844 0.117016C151.374 0 150.904 0 150.316 0Z" fill="white" />
			</g>
			<g className={chairClassname(8)}>
				<path d="M59.676 138.315L34.0185 119.575C31.9245 118.073 28.9383 118.54 27.3253 120.626L8.5985 146.159C7.08875 148.243 7.55808 151.215 9.654 152.82L35.3096 171.457C37.4036 172.959 40.3898 172.492 42.0028 170.406L60.7296 144.873C62.2393 142.789 61.77 139.817 59.676 138.315Z" fill="#7A7A7A" />
				<path d="M38.2178 172.328C39.6632 172.3 41.0969 171.657 42.0028 170.406L60.7295 144.873C62.2393 142.79 61.77 139.818 59.674 138.212L34.0184 119.576C33.1808 118.975 32.1426 118.686 31.1102 118.705C29.6648 118.732 28.2311 119.376 27.3252 120.626L8.59845 146.159C7.0887 148.243 7.55803 151.215 9.65395 152.82L35.3096 171.457C36.2504 172.056 37.1854 172.347 38.2178 172.328ZM38.2761 175.41C36.521 175.443 34.8594 174.96 33.4959 173.855L7.84224 155.321C4.38669 152.816 3.57072 147.898 6.08568 144.356L24.8124 118.823C26.3241 116.842 28.5741 115.669 31.0519 115.623C32.807 115.59 34.4685 116.073 35.8321 117.178L61.4877 135.814C63.1629 137.016 64.3335 138.845 64.5788 140.896C64.9274 142.945 64.3469 145.011 63.1391 146.678L44.5155 172.209C43.0058 174.293 40.7539 175.363 38.2761 175.41Z" fill="white" />
			</g>
			<g className={chairClassname(6)}>
				<path d="M198.324 138.315L223.982 119.575C226.076 118.073 229.062 118.54 230.675 120.626L249.401 146.159C250.911 148.243 250.442 151.215 248.346 152.82L222.69 171.457C220.596 172.959 217.61 172.492 215.997 170.406L197.27 144.873C195.761 142.789 196.23 139.817 198.324 138.315Z" fill="#7A7A7A" />
				<path d="M219.782 172.328C218.337 172.3 216.903 171.657 215.997 170.406L197.27 144.873C195.761 142.79 196.23 139.818 198.326 138.212L223.982 119.576C224.819 118.975 225.857 118.686 226.89 118.705C228.335 118.732 229.769 119.376 230.675 120.626L249.402 146.159C250.911 148.243 250.442 151.215 248.346 152.82L222.69 171.457C221.75 172.056 220.815 172.347 219.782 172.328ZM219.724 175.41C221.479 175.443 223.141 174.96 224.504 173.855L250.158 155.321C253.613 152.816 254.429 147.898 251.914 144.356L233.188 118.823C231.676 116.842 229.426 115.669 226.948 115.623C225.193 115.59 223.531 116.073 222.168 117.178L196.512 135.814C194.837 137.016 193.667 138.845 193.421 140.896C193.073 142.945 193.653 145.011 194.861 146.678L213.484 172.209C214.994 174.293 217.246 175.363 219.724 175.41Z" fill="white" />
			</g>
			<g className={chairClassname(4)}>
				<path d="M115.558 146.387L80.0482 139.6C77.1087 139.015 74.2867 141.004 73.6988 143.93L66.8791 179.269C66.2912 182.194 68.2901 185.002 71.2296 185.587L106.739 192.374C109.679 192.959 112.5 190.97 113.088 188.045L119.908 152.706C120.496 149.781 118.497 146.972 115.558 146.387Z" fill="#7A7A7A" />
				<path d="M107.798 192.493C110.385 192.493 112.619 190.738 113.089 188.164L119.909 152.825C120.496 149.9 118.498 147.091 115.558 146.506L80.0487 139.719C79.696 139.602 79.3432 139.602 78.9905 139.602C76.4037 139.602 74.1697 141.358 73.6994 143.932L66.8797 179.271C66.2918 182.196 68.2906 185.004 71.2302 185.59L106.74 192.376C107.092 192.493 107.445 192.493 107.798 192.493ZM107.798 196.004C107.21 196.004 106.622 196.004 106.152 195.887L70.5247 189.1C65.7039 188.164 62.5292 183.483 63.4698 178.686L70.2895 143.347C71.1126 139.134 74.7576 136.092 79.1081 136.092C79.696 136.092 80.2839 136.092 80.7542 136.209L116.264 142.996C121.084 143.932 124.259 148.613 123.318 153.41L116.499 188.749C115.793 192.962 112.031 196.004 107.798 196.004Z" fill="white" />
			</g>
			<g className={chairClassname(2)}>
				<path d="M177.942 139.6L142.439 146.387C139.5 146.972 137.619 149.78 138.089 152.705L144.908 188.044C145.496 190.97 148.317 192.842 151.256 192.374L186.759 185.587C189.698 185.002 191.579 182.193 191.108 179.268L184.29 143.929C183.82 141.004 180.998 139.132 177.942 139.6Z" fill="#7A7A7A" />
				<path d="M150.316 192.493C150.669 192.493 151.021 192.493 151.374 192.376L186.877 185.59C189.816 185.004 191.697 182.196 191.227 179.271L184.408 143.932C183.938 141.358 181.587 139.602 179.118 139.602C178.765 139.602 178.413 139.602 178.06 139.719L142.44 146.389C139.501 146.974 137.62 149.783 138.09 152.708L144.908 188.047C145.496 190.738 147.73 192.493 150.316 192.493ZM150.316 196.004C146.084 196.004 142.322 192.962 141.499 188.749L134.681 153.41C133.74 148.613 136.914 143.932 141.734 142.996L177.237 136.209C177.825 136.092 178.413 136.092 178.883 136.092C183.115 136.092 186.877 139.134 187.7 143.347L194.518 178.686C194.988 181.026 194.518 183.366 193.107 185.355C191.814 187.345 189.698 188.632 187.347 189.1L151.844 195.887C151.374 196.004 150.904 196.004 150.316 196.004Z" fill="white" />
			</g>
			<path d="M130 174.799C200.046 174.799 256.829 140.414 256.829 97.9971C256.829 55.5806 200.046 21.1953 130 21.1953C59.9543 21.1953 3.1709 55.5806 3.1709 97.9971C3.1709 140.414 59.9543 174.799 130 174.799Z" fill="#7A7A7A" />
			<path className="colorTable" d="M130 29.7288C162.342 29.7288 192.675 37.3023 215.293 50.956C236.642 63.863 248.374 80.5033 248.374 97.9971C248.374 115.491 236.642 132.131 215.293 145.038C192.675 158.798 162.342 166.265 130 166.265C97.6587 166.265 67.3254 158.692 44.7075 145.038C23.3579 132.131 11.6262 115.491 11.6262 97.9971C11.6262 80.5033 23.3579 63.863 44.7075 50.956C67.3254 37.3023 97.6587 29.7288 130 29.7288ZM130 21.1953C59.927 21.1953 3.1709 55.5428 3.1709 97.9971C3.1709 140.451 59.927 174.799 130 174.799C200.073 174.799 256.829 140.451 256.829 97.9971C256.829 55.5428 200.073 21.1953 130 21.1953Z" fill="#38CD90" />
			<path d="M130 21.1962C200.073 21.1962 256.829 55.5436 256.829 97.9979C256.829 140.452 200.073 174.8 130 174.8C59.9268 174.8 3.17073 140.452 3.17073 97.9979C3.17073 55.5436 59.9268 21.1962 130 21.1962ZM130 17.9961C95.5447 17.9961 63.0976 26.1029 38.6829 40.93C13.7398 56.077 0 76.3441 0 97.9979C0 119.652 13.7398 139.919 38.6829 155.066C63.0976 169.893 95.5447 178 130 178C164.455 178 196.902 169.893 221.317 155.066C246.26 139.919 260 119.652 260 97.9979C260 76.3441 246.26 56.077 221.317 40.93C196.902 26.2096 164.455 17.9961 130 17.9961Z" fill="white" />
		</svg>

	)
}
OvalTableSVG.displayName = 'OvalTableSVG'
