import styled from 'styled-components'

export const PrintButtonsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
`
PrintButtonsList.displayName = 'PrintButtonsList'

export const PrintMessageReconnect = styled.div`
	margin-top: 1em;
`
PrintMessageReconnect.displayName = 'PrintMessageReconnect'
