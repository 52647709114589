import { store } from '../../store/configureStore'

const modalLoginRequestInterceptor = config => {
  if (store.getState().modalLogin.accessToken) {
    config.headers['Authorization'] = `Bearer ${store.getState().modalLogin.accessToken}`
  }
  return config
}

export default modalLoginRequestInterceptor
