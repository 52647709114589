import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getNeededDateFormat } from '../../../../../helper'
import { selectIsDriverLate, selectRemainingTime } from '../../../../../redux/selector/timerCounter'
import { DriverTime } from './index.styled'

const PlatformDriverArriveTime = React.memo(({ estimatedProcessTimestamp, platform }) => {
	const { t } = useTranslation()
	const arriveRemainingTime = useSelector(selectRemainingTime(estimatedProcessTimestamp, t('order.hours'), t('order.minutes')))
	const isDriverLate = useSelector(selectIsDriverLate(estimatedProcessTimestamp))
	const driverArriveTime = getNeededDateFormat(estimatedProcessTimestamp, 'H:mm')
	const timeToShow = `${arriveRemainingTime} (${driverArriveTime})`

	return (
		<DriverTime className={'driver-time-text' + (isDriverLate ? ' time-over' : '')}>
			{t(`order.driver_come.${platform}`) + (isDriverLate ? '-' : '') + timeToShow}
		</DriverTime>
	)
})

PlatformDriverArriveTime.displayName = 'PlatformDriverArriveTime'
export default PlatformDriverArriveTime
