import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Coupon = lazyWithRetry(() => import(/* webpackChunkName: "coupon" */'../../component/Coupon'))

const CouponPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Coupon />
		</Suspense>
	)
})

CouponPage.displayName = 'CouponPage'
export default CouponPage

