import styled from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'

export const CountDownWrap = styled.div`
	font-size: 1rem;
  min-height: 2.69em;
  padding: 0.5em;
  background: ${colors.blue};
  display: grid;
  grid-template-columns: auto .5fr 1fr;
  border-radius: 0.75em;
	gap: 0.5em;
  align-items: center;

  h2 {
    font-family: ${theme.fontRobotoMedium};
    color: ${colors.white};
    font-size: 1.13em;
    margin: 0;
    user-select: none;
  }
`
CountDownWrap.displayName = 'CountDownWrap'

export const CountTime = styled.div`
  font-family: ${theme.fontRobotoBold};
  color: ${colors.white};
  font-size: 1.13em;
  margin: 0;
  user-select: none;
`
CountTime.displayName = 'CountTime'
