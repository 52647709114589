import React from 'react'
import { useSelector } from 'react-redux'
import { AreaButton, AreaButtonCircle, AreaButtonCount, AreaButtonName, AreaButtonWrap } from '../index.styled'
import { theme } from '../../../../style/theme'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { selectAreaHasWaiterNotifiedTable, selectAreaWaiterCall } from '../../../../redux/selector/restaurant'

const AreaRestaurantButton = ({ index, area, areaId, children, swapMode, toggleArea }) => {
	const isActive = areaId === area.id
	const isMobile = useWindowBreakPoint(theme.point720)
	const areaWaiterCall = useSelector(selectAreaWaiterCall(area))
	const areaHasWaiterNotifiedTable = useSelector(selectAreaHasWaiterNotifiedTable(area.id))
	const layout = (isActive ? 'active ' : '') + ((!isMobile && areaWaiterCall) ? 'waiter-call ' : '') + ((!isMobile && areaHasWaiterNotifiedTable) ? 'shadowPulse ' : '')

	return (
		<AreaButtonWrap key={index} color={area.meta?.color}>
			<AreaButton key={index}
									tabIndex={index + 1}
									color={area.meta?.color}
									onClick={() => toggleArea(area.id)}
									className={layout}>
				<AreaButtonCircle color={area.meta?.color} className={layout} />
				<AreaButtonName className={layout + (swapMode ? ' black' : '')}>{area.name}</AreaButtonName>
				{area.busyTableCount > 0 ?
					<AreaButtonCount className={layout}>{area.busyTableCount}</AreaButtonCount> : <div />}
				{isMobile && <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11" viewBox="0 0 18 11">
					<path fillRule="evenodd" clipRule="evenodd" d="M8.99186 10.5C8.68129 10.5 8.37272 10.3849 8.13528 10.1528L0.356913 2.53262C-0.117965 2.06831 -0.118966 1.3158 0.353907 0.850488C0.825778 0.385174 1.5932 0.383173 2.06707 0.847486L8.99186 7.63106L15.9337 0.846487C16.4076 0.383175 17.175 0.384175 17.6478 0.851491C18.1187 1.3168 18.1177 2.07031 17.6428 2.53463L9.84443 10.1538C9.609 10.3849 9.30043 10.5 8.99186 10.5Z" />
				</svg>}
			</AreaButton>
			{isActive && isMobile && children}
		</AreaButtonWrap>
	)
}

AreaRestaurantButton.displayName = 'AreaRestaurantButton'
export default AreaRestaurantButton
