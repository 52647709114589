import React, { useCallback, useEffect, useState } from 'react'
import { DemoShopText, DemoShopTextWrap, NotificationModalBody, NotificationModalCloseButton, NotificationModalCloseKey, NotificationModalContainer, NotificationModalHeaderLeft, NotificationModalInfo, NotificationModalLink, NotificationModalMain, NotificationModalPhoneImg, NotificationModalText, NotificationModalWrap, PaymentMethodsList, PaymentMethodsWrap, WhatsAppText, WhatsAppTitle } from './index.styled'
import { useTranslation } from 'react-i18next'
import Portal from '../../Elements/Portal'
import MainIcon from '../../../img/svg/main'
import KeyBindings from '../../Elements/keybindings'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompanyIndex, selectIsNeededZIndex } from '../../../redux/selector/system'
import phone from '../../../img/svg/header/phone.png'
import NotificationModalBtn from './NotificationModalBtn'
import { NotificationModalBtnWrap } from './NotificationModalBtn/index.styled'
import NotificationModalCart from './NotificationModalCart'
import { onlineShopUIUrl } from '../../../urls'
import PaymentIcon from '../../../img/svg/payment'
import { QRCodeSVG } from 'qrcode.react'
import whatsapp_icon from '../../../img/svg/whatsapp_icon.svg'
import { setAppEventZIndex } from '../../../redux/action/system'

const EVENT_Z_INDEX = 50

const WebShopNotificationModal = React.memo(({ isOpen = false, close }) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const [showWhatsAppInfo, setShowWhatsAppInfo] = useState(false)
	const dispatch = useDispatch()
	const whatsAppNumberOfDealer = useSelector(store => store.support.dealerContacts?.whatsAppNumber)
	const phoneNumberOfDealer = useSelector(store => store.support.dealerContacts?.supportNumber)
	const companyIndex = useSelector(selectCompanyIndex)
	const isDealer = useSelector(store => store.support.dealerContacts)
	const paymentsMethods = ['APPLE_PAY_FULL', 'GOOGLE_PAY_FULL', 'VISA_FULL', 'MASTERCARD_FULL', 'AMERICAN_EXPRESS_FULL', 'KLARNA_FULL', 'SOFORT_FULL', 'GIROPAY_FULL', 'PAYPAL_FULL', 'CASH_FULL', 'EC_KARTA_FULL']
	const [showDemoShopInfo, setShowDemoShopInfo] = useState(false)
	const whatsappHref = `https://wa.me/4951154681414?text=Hallo,%20meine%20Kundennummer%20${companyIndex},%20interessiere%20mich%20für%20Webshop.%20Bitte%20um%20Information.`
	const preparedWhatsAppNumber = whatsAppNumberOfDealer?.split('').filter(item => /\d/.test(item)).join('')

	const whatsappHrefOfDealer = `https://wa.me/${preparedWhatsAppNumber}?text=Hallo,%20meine%20Kundennummer%20${companyIndex},%20interessiere%20mich%20für%20Webshop.%20Bitte%20um%20Information.`

	const phoneHref = isDealer ? phoneNumberOfDealer : '4951154681414'

	const shopUrl = useSelector(store => `${onlineShopUIUrl}/${store.webshopinfo.config?.shopUrl}`)

	useEffect(() => {
		if (isOpen) {
			dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		}
		return () => dispatch(setAppEventZIndex(0))
	}, [isOpen])

	const handleModalWrapperMouseDown = useCallback(() => {
		setShowWhatsAppInfo(false)
		setShowDemoShopInfo(false)
		close()
	}, [close])

	const handleModalMainMouseDown = useCallback(e => {
		e.stopPropagation()
	}, [])

	const closeWhatsAppInfo = useCallback(() => {
		setShowWhatsAppInfo(false)
	}, [])

	const closeDemoShopInfo = useCallback(() => {
		setShowDemoShopInfo(false)
	}, [])

	const openWhatsAppInfo = useCallback(() => {
		setShowDemoShopInfo(false)

		setShowWhatsAppInfo(true)
	}, [])

	const openDemoShopInfo = useCallback(() => {
		setShowWhatsAppInfo(false)
		setShowDemoShopInfo(true)
	}, [])

	return (
		<Portal containerId="content">
			<NotificationModalWrap onMouseDown={handleModalWrapperMouseDown}
														 className={isOpen ? 'active' : ''}>
				<NotificationModalContainer>
					<NotificationModalMain onMouseDown={handleModalMainMouseDown}>
						<NotificationModalHeaderLeft>
							{t('WebShopNotification.modal.novelty')}
						</NotificationModalHeaderLeft>
						<NotificationModalCloseButton onClick={close}>
							<NotificationModalCloseKey>ESC</NotificationModalCloseKey>
							<MainIcon icon="white-cross" />
						</NotificationModalCloseButton>
						<NotificationModalBody>
							<NotificationModalInfo>
								<NotificationModalText>{t('WebShopNotification.modal.text')}</NotificationModalText>
								<NotificationModalBtnWrap>
									<NotificationModalBtn text={t('WebShopNotification.modal.btnInfo')} onClick={openWhatsAppInfo} className={showWhatsAppInfo ? 'active' : ''} />
									<NotificationModalBtn text={t('WebShopNotification.modal.btnDemo')} onClick={openDemoShopInfo} className={showDemoShopInfo ? 'active' : ''} />
								</NotificationModalBtnWrap>
								{!showWhatsAppInfo && !showDemoShopInfo &&
									<PaymentMethodsWrap>
										<PaymentMethodsList>
											{paymentsMethods.map(method => <PaymentIcon icon={method} key={method} />)}
										</PaymentMethodsList>
									</PaymentMethodsWrap>}

								<NotificationModalCart close={closeWhatsAppInfo}
																			 className={showWhatsAppInfo ? 'active' : ''}
																			 qrCode={<QRCodeSVG value={whatsAppNumberOfDealer ? whatsappHrefOfDealer : whatsappHref}
																													level="M"
																													// data-testid = "whatsApp-number"
																													imageSettings={{
																														src: whatsapp_icon,
																														width: 34,
																														height: 34,
																														x: null,
																														y: null,
																														excavate: true,
																													}} />}>
									<WhatsAppTitle>
										<MainIcon icon="scaning-qrCode" />
										WhatsApp
									</WhatsAppTitle>
									<WhatsAppText>{t('WebShopNotification.modal.whatsAppText')}</WhatsAppText>
									<NotificationModalLink href={`tel:${phoneHref}`}>{phoneHref}</NotificationModalLink>
								</NotificationModalCart>

								<NotificationModalCart close={closeDemoShopInfo}
																			 className={showDemoShopInfo ? 'active' : ''}
																			 qrCode={<QRCodeSVG value={shopUrl} level="M" />}>
									<DemoShopTextWrap>
										<MainIcon icon="scaning-qrCode" />
										<DemoShopText>
											{t('WebShopNotification.modal.demoShopText')}
										</DemoShopText>
										<NotificationModalLink href={shopUrl} className="col-2" target="_blank">{t('WebShopNotification.modal.demoShopLink')}</NotificationModalLink>
									</DemoShopTextWrap>
								</NotificationModalCart>

							</NotificationModalInfo>
							<NotificationModalPhoneImg>
								<MainIcon icon="zero-percent" />
								<img src={phone} alt="phone" />
							</NotificationModalPhoneImg>
						</NotificationModalBody>
					</NotificationModalMain>
				</NotificationModalContainer>
			</NotificationModalWrap>

			{isNeededZIndex &&
				<KeyBindings escape={close} />}
		</Portal>
	)

})

WebShopNotificationModal.displayName = 'WebShopNotificationModal'
export default WebShopNotificationModal
