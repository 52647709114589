import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const LoaderWrap = styled.div`
	position: relative;
`
LoaderWrap.displayName = 'LoaderWrap'

export const Circle = styled.div`
  display: block;
  position: relative;
  height: 9.94em;
  width: 9.94em;
  border: 1.19em solid ${colorTheme.loaders.hourglass.border};
	background: ${colorTheme.loaders.hourglass.background};
  border-radius: 50%;

  &.dark {
    border: 1.19em solid ${colorTheme.loaders.hourglass.dark.border};

    > svg path {
      fill: ${colorTheme.loaders.hourglass.dark.icon};
    }
  }

	> svg path {
		fill: ${colorTheme.loaders.hourglass.icon};
	}

  & > svg.hourglass {
    width: 2.56em;
    height: 4.19em;
    position: absolute;
    top: calc(50% - 2.1em);
    left: calc(50% - 1.28em);
    transform-origin: center;
    animation: hourglass 1.5s linear infinite;
  }

  & > svg.hourglass path.dot {
    animation: dot 1.5s infinite steps(6);
  }

  & > svg.hourglass path.dot-up {
    animation: dot-up 3s linear infinite;
  }

  @keyframes hourglass {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }

  }

  @keyframes dot {
    0% {
      rotate: x 0deg;
      transform-origin: center;
    }

    16.6%, 100% {
      rotate: x 180deg;
      transform-origin: center;
    }
  }

  @keyframes dot-up {
    0% {
      transform: translateY(-23%);
    }
    90%, 100% {
      transform: translateY(0);
    }
  }

	@media screen and (max-width: ${theme.point720}) {
		&.mob-small {
			height: 6.87em;
  		width: 6.87em;
			border: 0.81em solid ${colorTheme.loaders.hourglass.border};

			& > svg.hourglass {
				width: 1.68em;
				height: 2.87em;
				top: calc(50% - 1.4em);
    		left: calc(50% - 0.8em);
			}
		}
	}
`

Circle.displayName = 'Circle'

