import { ADD_MAP_MARKER, ADD_MAP_MARKERS, RE_INITIALIZE_MAP, REMOVE_MAP_MARKER, SET_MAP_COORDINATES_MARKER, SET_MAP_HEIGHT, SET_MAP_HEIGHT_HEADER, SET_MAP_MARKERS, SET_MAP_MARKERS_STATUS, SET_MAP_MARKERS_TOTAL_LENGTH, SET_MAP_ORDER_ID_OF_MARKER, SET_MAP_WOLT_DRIVER_DELIVERIES, SET_MAP_POSITIONS, SET_MAP_SELECTED_WOLT_DRIVER_DELIVERY, SET_MAP_WIDTH, SET_MAP_WIDTH_MENU, UPDATE_MAP_MARKER, UPDATE_MAP_WOLT_DRIVER_DELIVERIES, UPDATE_MAP_POSITION, UPDATE_MAP_SELECTED_WOLT_DRIVER_DELIVERY } from '../../actionTypes/map'
import { defaultMarkersStatus } from './constants'

const initialState = {
	markers: [],
	markersTotalLength: null,
	positions: [],
	orderId: null,
	markersStatus: defaultMarkersStatus,
	coordinatesMarker: null,
	widthMap: 0,
	heightMap: 0,
	widthMenu: 0,
	heightHeader: 0,
	selectedWoltDriverDelivery: null,
	woltDriverDeliveries: [],
}

export function mapReducer(state = initialState, action) {
	switch (action.type) {
		case RE_INITIALIZE_MAP:
			return initialState
		case SET_MAP_MARKERS:
			return {
				...state,
				markers: action.value
			}
		case ADD_MAP_MARKERS:
			let markersCopy = [...state.markers]
			markersCopy = markersCopy.concat(action.value)
			markersCopy = markersCopy.filter((item, index, items) =>
				items.filter((subItem, subIndex) =>
					item.orderId === subItem.orderId && index !== subIndex && index < subIndex).length === 0)
			return {
				...state,
				markers: markersCopy
			}
		case SET_MAP_MARKERS_TOTAL_LENGTH:
			return {
				...state,
				markersTotalLength: action.value
			}
		case ADD_MAP_MARKER:
			const markersNewCopy = [...state.markers]
			markersNewCopy.unshift(action.value)
			return {
				...state,
				markers: markersNewCopy
			}
		case UPDATE_MAP_MARKER:
			const markersUpdateCopy = [...state.markers]
			const markerToUpdate = markersUpdateCopy.find(order => order.orderId === action.value.orderId)
			if (markerToUpdate) {
				markersUpdateCopy[markersUpdateCopy.indexOf(markerToUpdate)] = action.value
			}
			return {
				...state,
				markers: markersUpdateCopy
			}
		case REMOVE_MAP_MARKER:
			let markersRemovedCopy = [...state.markers]
			markersRemovedCopy = markersRemovedCopy.filter(order => order.orderId !== action.value.orderId)
			return {
				...state,
				markers: markersRemovedCopy
			}
		case SET_MAP_POSITIONS:
			return {
				...state,
				positions: action.value
			}
		case UPDATE_MAP_POSITION:
			const updatedPositions = state.positions.map(position => {
				if (position.username === action.value?.username) {
					return action.value
				}
				return position
			})
			if (updatedPositions.indexOf(updatedPositions.find(position => position.username === action.value?.username)) === -1) {
				updatedPositions.push(action.value)
			}
			return {
				...state,
				positions: updatedPositions
			}
		case SET_MAP_ORDER_ID_OF_MARKER:
			return {
				...state,
				orderId: action.value
			}
		case SET_MAP_MARKERS_STATUS:
			return {
				...state,
				markersStatus: action.value
			}
		case SET_MAP_COORDINATES_MARKER:
			return {
				...state,
				coordinatesMarker: action.value
			}
		case SET_MAP_WIDTH:
			return {
				...state,
				widthMap: action.value
			}
		case SET_MAP_HEIGHT:
			return {
				...state,
				heightMap: action.value
			}
		case SET_MAP_WIDTH_MENU:
			return {
				...state,
				widthMenu: action.value
			}
		case SET_MAP_HEIGHT_HEADER:
			return {
				...state,
				heightHeader: action.value
			}
		case SET_MAP_SELECTED_WOLT_DRIVER_DELIVERY:
			return {
				...state,
				selectedWoltDriverDelivery: action.value
			}
		case UPDATE_MAP_SELECTED_WOLT_DRIVER_DELIVERY:
			return {
				...state,
				selectedWoltDriverDelivery: action.value
			}
		case SET_MAP_WOLT_DRIVER_DELIVERIES:
			return {
				...state,
				woltDriverDeliveries: action.value
			}
		case UPDATE_MAP_WOLT_DRIVER_DELIVERIES:
			const partnerDriverDeliveriesCopy = [ ...state.woltDriverDeliveries]
			const partnerDriverDeliveryIndex = partnerDriverDeliveriesCopy.findIndex(delivery => delivery.orderId === action.value.orderId)
			if (partnerDriverDeliveryIndex !== -1) partnerDriverDeliveriesCopy[partnerDriverDeliveryIndex] = action.value
			return {
				...state,
				woltDriverDeliveries: partnerDriverDeliveriesCopy
			}

		default:
			return state
	}
}
