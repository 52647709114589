import orderAdminInstance from '../adminInstance'

const setRestaurantOrderStatus = (orderId, data, accessToken) => {
  return orderAdminInstance.put('/orders/' + orderId + '/status', data, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
}

export default setRestaurantOrderStatus
