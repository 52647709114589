import styled, { keyframes } from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'
import { InputLabel } from '../../Elements/inputs/TextInput/index.styled'
import { AddressSearchWrapper } from '../../Elements/inputs/AddressSearchInputs/index.styled'
import { FormButtons } from '../../Elements/form/index.styled'

const breatheAnimation = keyframes`
	0% {
		opacity: 1;
	}
	99% {
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
`

export const CustomerFormWrapper = styled.div`
	font-size: 1rem;
	height: 100%;
	padding: 1.88em 1.75em 3.13em 1.88em;
	width: 100%;

	@media screen and (max-width: ${theme.point820}) {
		padding: 0.63em 0.63em 0 0.63em;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.63em 0.63em 0 0.63em;
	}
`
CustomerFormWrapper.displayName = 'CustomerFormWrapper'

export const CustomerMainForm = styled.form`
	display: grid;
	gap: 0.63em;
	max-width: 35.19em;
	position: relative;
	transition: all 0.2s ease-in-out;
	filter: drop-shadow(1.25600004196167px 17.95599937438965px 50px rgba(0, 0, 0, 0.25));;

	&.toggled {
		max-height: 5em;

		&:before {
			background-color: rgba(0, 0, 0, 0.3);
			box-shadow: 0 0 1.88em 1.56em rgba(0, 0, 0, 0.3);
		}
	}

	${FormButtons} {
		margin-top: 0.63em;
	}

	@media screen and (max-width: ${theme.point820}) {
		filter: none;
		max-width: 100%;
		row-gap: 1.43em;
		column-gap: 0.71em;

		${InputLabel} {
			border: 1px solid ${colors.gray_dark};

			&.focus {
				border-color: ${colors.blue};
				box-shadow: 0 4px 27px rgba(0, 0, 0, 0.22);
			}
		}

		${FormButtons} {
			margin-top: 0.71em;
			> * {
				width: auto;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		max-width: none;
		gap: 1.25em;

		${FormButtons} {
			> * {
				width: 100%;
			}
		}
	}
`
CustomerMainForm.displayName = 'CustomerMainForm'

export const FormLine = styled.div`
	display: grid;
	gap: 0.63em;
	grid-template-columns: 1.5fr 0.8fr;
	grid-auto-flow: column;

	&.hidden {
		animation: ${breatheAnimation} .2s;
		animation-fill-mode: forwards;
	}

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: 1fr 1fr;
		row-gap: 1.43em;
		column-gap: 0.71em;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
		gap: 1.25em;
		grid-auto-flow: inherit;

	}
`

export const CustomerNameWrapper = styled.div`
	&.hidden {
		-webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
	}
`

export const CustomerAddressWrapper = styled.div`
	&.hidden {
		animation: ${breatheAnimation} .2s;
		animation-fill-mode: forwards;
	}

	${AddressSearchWrapper} {
		@media screen and (max-width: ${theme.point820}) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: ${theme.point720}) {
			grid-template-columns: 1fr;
			gap: 1.25em;
		}
	}

`

export const BlockShowForm = styled.div`
	&.hidden {
		animation: ${breatheAnimation} .2s;
		animation-fill-mode: forwards;
		display: flex;
	}
`

export const CustomerNameContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.63em;

	@media screen and (max-width: ${theme.point820}) {
		&.columns {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
	}
	@media screen and (max-width: ${theme.point720}) {
		&.columns {
			grid-template-columns: 1fr;
		}
	}
`
CustomerNameContainer.dispalName = 'CustomerNameContainer'
