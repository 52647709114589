import { setLoginSuccess, setLogout } from '../action'
import registrationInstance from './registration/instance'
import { store } from '../../store/configureStore'
import { disconnectSocketThunk } from '../thunk/socket'
import { getLocalStorageRefreshToken } from '../../helper/clientMonitor'

let tokenIsRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const responseInterceptor = error => {
  const originalRequest = error.config

  if (error?.response?.status !== 401) {
    return Promise.reject(error)
  }

  if (error?.response?.status === 401 && (originalRequest.url === '/auth/login' || originalRequest.url === '/auth/less')) {
    return Promise.reject(error)
  }

  if (error?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    if (tokenIsRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token
        return registrationInstance(originalRequest)
      }).catch(err => {
        return Promise.reject(err)
      })
    }

    originalRequest._retry = true
    tokenIsRefreshing = true

    return new Promise((resolve, reject) => {
      registrationInstance.post('/auth/refresh', { 'refreshToken': getLocalStorageRefreshToken() })
        .then(res => {
          store.dispatch(setLoginSuccess({
            accessToken: res.data.accessToken,
            refreshToken: res.data.refreshToken,
          }))
          registrationInstance.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
          originalRequest.headers['Authorization'] = `Bearer ${res.data.accessToken}`
          processQueue(null, res.data.token)

          resolve(registrationInstance(originalRequest))
        })
        .catch((err) => {
          store.dispatch(disconnectSocketThunk())
          store.dispatch(setLogout())
        })
        .finally(() => {
          tokenIsRefreshing = false
        })
    })
  }

  return Promise.reject(error)
}

export default responseInterceptor
