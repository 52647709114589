export const getTimeMeter = (timeButtons, i, t) => {
	const oneHourIndex = timeButtons.indexOf(60)
	if (i < oneHourIndex) {
		return t('WebshopInfo.modal.disable_order_type_period.minutes')
	} else if (i === oneHourIndex) {
		return t('WebshopInfo.modal.disable_order_type_period.hour')
	} else {
		return t('WebshopInfo.modal.disable_order_type_period.hours')
	}
}
