import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { QRCodeSVG } from 'qrcode.react'
import CustomModal from '../../Elements/modal/CustomModal'
import Button from '../../Elements/buttons/Button'
import { StyledA } from '../../../style/text/index.styled'
import { selectCompanyData } from '../../../redux/selector/system'
import { ContactExtra, ContactItem, ContactWhatsApp, QrCodeWrapper, Text, WrapperContacts } from './index.styled'
import whatsapp_icon from '../../../img/svg/whatsapp_icon.svg'
import MainIcon from '../../../img/svg/main'
import { setAppModal } from '../../../redux/action/system'
import { setSupportShowExtraContactsModal } from '../../../redux/action/support'

const EVENT_Z_INDEX = 17

const ContactsModal = React.memo(() => {
	const { t } = useTranslation()
	const companyIndex = useSelector(store => store.login.companyIndex)
	const contactsModalShow = useSelector(store => store.system.modal?.contactsModalShow)
	const dispatch = useDispatch()
	const name = useSelector(selectCompanyData('name'))
	const firstName = useSelector(selectCompanyData('firstName'))
	const isDealer = useSelector(store => store.support.dealerContacts)
	const phoneNumberOfDealer = useSelector(store => store.support.dealerContacts?.supportNumber)
	const whatsAppNumberOfDealer = useSelector(store => store.support.dealerContacts?.whatsAppNumber)

	const preparedWhatsAppNumber = whatsAppNumberOfDealer?.split('').filter(item => /\d/.test(item)).join('')
	const whatsappHref = firstName ? `https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}`
		: `https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20Ihr%20Kunde,%20Firma%20${name},%20Kundennummer%20${companyIndex}`
	const phoneHrefOfDealer = `tel:${phoneNumberOfDealer}`
	const whatsappHrefOfDealer = firstName ? `https://wa.me/${preparedWhatsAppNumber}?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}` :
		`https://wa.me/${preparedWhatsAppNumber}?text=Hallo!%20Ich%20bin%20Ihr%20Kunde,%20Firma%20${name},%20Kundennummer%20${companyIndex}`

	const handleClose = useCallback(() => {
		dispatch(setAppModal({ contactsModalShow: false }))
	}, [dispatch])

	const showExtraContacts = () => {
		dispatch(setSupportShowExtraContactsModal(true))
	}

	return (
		<CustomModal isOpen={contactsModalShow}
								 close={handleClose}
								 zIndex={EVENT_Z_INDEX}
								 size="small"
								 title={isDealer ? t('app.modals.contact_dealer') : t('app.modals.contact_us')}
								 disableBackButton={true}
								 button={
									 <>
										 <ContactExtra className="back" onClick={showExtraContacts}>{t('app.modals.extraContacts')}</ContactExtra>
										 <Button name="accept" icon="checkmark" onClick={handleClose} tabIndex="1" text="Ok" color="green" />
									 </>
								 }>
			<WrapperContacts>
				{isDealer ? <>
					{!!phoneNumberOfDealer && <ContactItem>
						<MainIcon icon="telephone_icon_blue" />
						<StyledA href={phoneHrefOfDealer}>{phoneNumberOfDealer}</StyledA>
					</ContactItem>}
					{!!whatsAppNumberOfDealer &&
						<>
							<ContactWhatsApp>
								<MainIcon icon="mobile" />
								<Text>WhatsApp</Text>
							</ContactWhatsApp>
							<QrCodeWrapper>
								<QRCodeSVG value={whatsappHrefOfDealer}
													 level="M"
													 imageSettings={{
														 src: whatsapp_icon,
														 width: 32,
														 height: 32,
														 x: null,
														 y: null,
														 excavate: true,
													 }} />
							</QrCodeWrapper>
						</>}
				</> : <>
					<ContactItem>
						<MainIcon icon="mail" />
						<StyledA href="mailto:info@liefersoft.de">info@liefersoft.de</StyledA>
					</ContactItem>
					<ContactWhatsApp>
						<MainIcon icon="mobile" />
						<Text>WhatsApp</Text>
					</ContactWhatsApp>
					<QrCodeWrapper>
						<QRCodeSVG value={whatsappHref}
											 level="M"
											 imageSettings={{
												 src: whatsapp_icon,
												 width: 32,
												 height: 32,
												 x: null,
												 y: null,
												 excavate: true,
											 }} />
					</QrCodeWrapper>
				</>}
			</WrapperContacts>
		</CustomModal>
	)
})

ContactsModal.displayName = 'ContactsModal'
export default ContactsModal
