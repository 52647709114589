import { retrieveErrorFromApi } from '../../../../helper'
import { setAppErrors } from '../../../action/system'
import putAutoConfirmation from '../../../axios/order/acceptOrder/post'
import fetchAutoConfirmation from '../../../axios/order/acceptOrder'
import { setAutoConfirmationError, setAutoConfirmationOrder } from '../../../action/acceptOrder'

export const fetchAutoConfirmationThunk = () => dispatch => {
  return fetchAutoConfirmation()
    .then(res => {
      dispatch(setAutoConfirmationOrder(res.data))
      dispatch(setAutoConfirmationError(false))
    }).catch(error => {
      dispatch(setAutoConfirmationError(true))
    })
}

export const putAutoConfirmationThunk = data => dispatch => {
  return putAutoConfirmation(data)
    .then(res => {
      dispatch(setAutoConfirmationOrder(data))
    }).catch(error => {
      dispatch(setAppErrors(retrieveErrorFromApi(error)))
    })
}

