import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const RegistrationSuccess = lazyWithRetry(() => import(/* webpackChunkName: "registration-success" */'../../component/Registration/Company/RegistrationSuccess'))

const RegistrationSuccessPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader className="authPage" />}>
			<RegistrationSuccess />
		</Suspense>
	)
})

RegistrationSuccessPage.displayName = 'RegistrationSuccessPage'
export default RegistrationSuccessPage
