import React, { useCallback } from 'react'
import CustomModal from '../../../Elements/modal/CustomModal'
import RadioInputControlled from '../../../Elements/inputs/RadioInput/controlled'
import WebshopPreview from './WebshopPreview'
import { RadioInputWrapper, WebshopActivationModalLeft, WebshopActivationModalTitle, WebshopActivationModalWrap } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { updateShopConfigThunk } from '../../../../redux/thunk/webshopinfo'
import Button from '../../../Elements/buttons/Button'
import { FormInfo } from '../../../Elements/form/index.styled'
import { useTranslation } from 'react-i18next'
import SelectInputControlled from '../../../Elements/inputs/SelectInput/controlled'
import SelectOptions from '../../../Elements/inputs/SelectInput/SelectOptions'
import { BACKGROUND_IMAGES } from '../../../WebShopDesign/WebShopConfig/form/constants'

const EVENT_Z_INDEX = 38

const WebshopActivationModal = React.memo(({ isOpen, close, openSchedule }) => {
	const { t } = useTranslation()

	const config = useSelector(store => store.webshopinfo.config)
	const dispatch = useDispatch()
	let isEnabled = !!config?.enabled
	const pathImg = '/img/'
	const SelectImg = BACKGROUND_IMAGES(t, pathImg)

	const handleThemeChange = useCallback((colorTheme, layoutTheme) => {
		dispatch(updateShopConfigThunk({
			...config, colorTheme, layoutTheme, productsLayout: 'BLOCKS',
		}))
	}, [config])

	const handleActivationClick = useCallback(() => {
		openSchedule()
		close()
	}, [openSchedule, close])

	const handleImageChange = useCallback((selImage) => {
		dispatch(updateShopConfigThunk({
			...config, backgroundImage: selImage,
		}))
	}, [config])

	return (
		<CustomModal isOpen={isOpen}
								 close={close}
								 size="auto middle"
								 zIndex={EVENT_Z_INDEX}
								 showBackButton={true}
								 heading={true}
								 button={
									 <Button name="activate" icon="checkmark" text={isEnabled ? t('WebshopInfo.modal.deactivate') : t('buttons.controls.save')} color="green"
													 zIndex={EVENT_Z_INDEX} keyButton="F2" onClick={handleActivationClick} />
								 }>
			<WebshopActivationModalWrap>
				<WebshopActivationModalLeft>
					<WebshopActivationModalTitle>{isEnabled ? t('WebshopInfo.modal.title_deactivation') : t('WebshopInfo.modal.title_activation')}</WebshopActivationModalTitle>
					<FormInfo>{t('WebshopInfo.modal.info')}</FormInfo>
					<div className="checkboxes">
						<RadioInputWrapper checked={config?.colorTheme === 'LIGHT' && config?.layoutTheme === 'CLASSIC'}>
							<RadioInputControlled label={t('WebshopInfo.modal.light_theme')}
																		id="light"
																		name="colorTheme.LIGHT"
																		checked={config?.colorTheme === 'LIGHT' && config?.layoutTheme === 'CLASSIC'}
																		onChange={() => handleThemeChange('LIGHT', 'CLASSIC')}
							/>
						</RadioInputWrapper>
						<RadioInputWrapper checked={config?.colorTheme === 'DARK' && config?.layoutTheme === 'COMPACT'}>
							<RadioInputControlled label={t('WebshopInfo.modal.dark_theme')}
																		id="dark"
																		name="colorTheme.DARK"
																		checked={config?.colorTheme === 'DARK' && config?.layoutTheme === 'COMPACT'}
																		onChange={() => handleThemeChange('DARK', 'COMPACT')}
							/>
						</RadioInputWrapper>
						<RadioInputWrapper checked={config?.colorTheme === 'RED' && config?.layoutTheme === 'CLASSIC'}>
							<RadioInputControlled label={t('WebshopInfo.modal.red_theme')}
																		id="red"
																		name="colorTheme.RED"
																		checked={config?.colorTheme === 'RED' && config?.layoutTheme === 'CLASSIC'}
																		onChange={() => handleThemeChange('RED', 'CLASSIC')}
							/>
						</RadioInputWrapper>
					</div>
					<SelectInputControlled name="selectImg"
																 value={config?.backgroundImage}
																 onChange={(e) => handleImageChange(e.target.value)}>
						<SelectOptions options={SelectImg}
													 labelBy="label"
													 valueBy="value"
													 defaultOption={t('product.form.main.default')} />
					</SelectInputControlled>
				</WebshopActivationModalLeft>
				{isOpen && <WebshopPreview shopConfig={config} colorMobile="DARK" />}
			</WebshopActivationModalWrap>
		</CustomModal>
	)
})

WebshopActivationModal.displayName = 'WebshopActivationModal'
export default WebshopActivationModal
