import { TextBig } from '../../OrdersTable/index.styled'
import Currency from '../../../currency'
import React from 'react'
import { TableTdGrid } from '../../style/table/td/index.styled'

const OrderPayment = React.memo(({ item }) => {

	return (
		<TableTdGrid>
			<TextBig><Currency>{item.totalAmount}</Currency></TextBig>
		</TableTdGrid>
	)
})

OrderPayment.displayName = 'OrderPayment'
export default OrderPayment
