import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const SelectWrapper = styled.div`
	font-size: 1rem;
	position: relative;
	width: 100%;

	&.error {
		background-color: ${colorTheme.input.select.error.background};
		border-radius: 0.75em;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.error {
			border-radius: 0.86em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.error {
			border-radius: 0.75em;
		}
	}
`
SelectWrapper.displayName = 'SelectWrapper'

export const SelectLabel = styled.label`
	border: 1px solid ${colorTheme.input.select.border};
	background-color: ${colorTheme.input.select.background};
	border-radius: 0.75em;
	min-height: 4.19em;
	position: relative;
	cursor: pointer;
	z-index: 1;
	display: grid;
	width: 100%;

	&.defaultOption {
		select {
			color: ${colorTheme.input.select.defaultOption};
		}
	}

	&.disabled {
		background-color: ${colorTheme.input.select.disabled.background};
		border-color: ${colorTheme.input.select.disabled.border};
	}

	select {
		appearance: none;
		height: 100%;
		width: 100%;
		padding: 0.56em 2.6em 0.56em 1.25em;
		font-family: ${theme.fontRobotoMedium};
		font-weight: 500;
		font-size: 1.13em;
		color: ${colorTheme.input.select.text};
		background: transparent;
		cursor: pointer;
		user-select: none;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;

		&:focus {
			option {
				color: ${colorTheme.input.select.focus.text};
			}
		}

		&.disabled {
			cursor: not-allowed;
			color: ${colorTheme.input.select.disabled.text};
		}

		&.defaultOption {
			option {
				color: ${colorTheme.input.select.defaultOption};
			}
		}

		option {
			background: ${colorTheme.input.select.backgroundOption};
		}

		&.placeholder {
			#shadow-root > div {
				color: ${colorTheme.input.select.error.text};
			}
		}

	}

	.arrow {
		height: 0.69em;
		width: 1.25em;
		position: absolute;
		top: calc(50% - 0.35em);
		right: 1.07em;
		z-index: -1;
		fill: ${colorTheme.input.select.icon};
	}

	&.small {
		font-size: 0.7em;
	}

	&.medium {
		min-height: 3.44em;
		border-radius: 0.75em;

		select {
			font-size: 1em;
		}

		.arrow {
			height: 0.69em;
			width: 1.25em;
		}
	}

	&.purple {
		border: 2px solid ${colorTheme.input.select.purple.border};;
	}

	&:focus-within {
		border-color: ${colorTheme.input.select.focus.border};
		box-shadow: 0 4px 27px rgba(0, 0, 0, 0.22);
	}

	&.error {
		border-color: ${colorTheme.input.select.error.border};

		> select {
			color: ${colorTheme.input.select.error.text};
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		min-height: 3.93em;
		border-radius: 0.86em;

		select {
			font-size: 1.28em;
		}

		.arrow {
			height: 0.78em;
			width: 1.43em;
		}

		&.big {
			min-height: 4.79em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: 3.44em;
		border-radius: 0.75em;

		select {
			font-size: 1.13em;
		}

		.arrow {
			height: 0.69em;
			width: 1.25em;
		}

		&.big {
			min-height: 4.19em;
		}
	}
`

export const SelectInfoWrapper = styled.div`
	position: absolute;
	top: calc(50% - 0.75em);
	right: 0.82em;
	cursor: default;
	z-index: 5;

	svg {
		height: 1.5em;
		width: 1.5em;
		fill: ${colorTheme.input.select.icon};
	}

	> div > div {
		min-width: 15em;
	}
`
SelectInfoWrapper.displayName = 'SelectInfoWrapper'
