export const CREATE_FORM_STATE = 'CREATE_FORM_STATE'
export const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE'
export const ZIP_LOCALITY = 'ZIP_LOCALITY'
export const MANUAL = 'MANUAL'

export const zoneColors = ['#DE2020','#E9910F', '#C1BA00','#95CA00', '#00CF3A', '#00B695', '#0099CA', '#266FFA', '#6E00FF', '#C000DF', '#B2916D', '#9EA05C', '#4B9C7D',
	'#4C5076', '#C22D2D', '#BF7506', '#A19B00', '#7CA800', '#009F2D', '#019C80', '#007FA7', '#0058FF', '#5B00D3', '#AA00C6', '#8A6C4A', '#757737', '#388366',
	'#393D62', '#AB2626', '#A56506', '#857F00', '#618300', '#008B27', '#007F69', '#007195', '#0040BA', '#4B00AF', '#88009E', '#664D32', '#383915', '#2A6750',
	'#282F65', '#891E1E', '#8C5503', '#656101', '#4C6700', '#006D1F', '#006754', '#00546E', '#00318E', '#3A0086', '#69007A', '#44321F', '#383915', '#174533',
	'#1A204E','#6C1717', '#623C04', '#434001', '#2F4000', '#004513', '#00493C', '#00394B', '#002469', '#260058', '#460051', '#3A250D', '#28290A', '#0E3023']
