import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const OrderUnpaidWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`
OrderUnpaidWrapper.displayName = 'OrderUnpaidWrapper'

export const OrderUnpaidContent = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5em;

	> svg {
		width: 1.31em;
		height: 1.31em;
		fill: ${colorTheme.table.tableTr.dangerText};
	}
`
OrderUnpaidContent.displayName = 'OrderUnpaidContent'

export const OrderUnpaidText = styled.div`
	color: ${colorTheme.table.tableTr.dangerText};
	font-size: 0.8em;
`
OrderUnpaidText.displayName = 'OrderUnpaidText'
