import { ADD_Z_REPORT, APPEND_Z_REPORTS, APPEND_Z_REPORT_FOR_TODAY, SET_Z_REPORT, SET_Z_REPORT_DATE_FROM, SET_Z_REPORT_DATE_TO, SET_Z_REPORT_TEST_IS_LOADING, SET_Z_REPORTS, SET_Z_REPORTS_FOR_TODAY, SET_Z_REPORTS_TOTAL_LENGTH, SET_Z_REPORT_CASH_DATA, SET_Z_REPORT_DATEV } from '../../actionTypes/zreport'

const initialState = {
	reports: [],
	reportsTotalLength: 0,
	report: null,
	dateFrom: null,
	dateTo: null,
	testIsLoading: false,
	reportsForToday: [],
	reportCashData: null,
	reportDateV: null
}

export function zReportReducer(state = initialState, action) {
	switch (action.type) {
		case SET_Z_REPORTS:
			return {
				...state,
				reports: action.value,
			}
		case SET_Z_REPORTS_TOTAL_LENGTH:
			return {
				...state,
				reportsTotalLength: action.value,
			}
		case SET_Z_REPORT:
			return {
				...state,
				report: action.value,
			}
		case APPEND_Z_REPORTS:
			return {
				...state,
				reports: state.reports.concat(...action.value),
			}
		case SET_Z_REPORT_DATE_FROM:
			return {
				...state,
				dateFrom: action.value,
			}
		case SET_Z_REPORT_DATE_TO:
			return {
				...state,
				dateTo: action.value,
			}
		case SET_Z_REPORT_TEST_IS_LOADING:
			return {
				...state,
				testIsLoading: action.value
			}
		case SET_Z_REPORTS_FOR_TODAY:
			return {
				...state,
				reportsForToday: action.value
			}
		case APPEND_Z_REPORT_FOR_TODAY:
			return {
				...state,
				reportsForToday: [action.value, ...state.reportsForToday]
			}
		case ADD_Z_REPORT:
			return {
				...state,
				reports: [action.value, ...state.reports]
			}
		case SET_Z_REPORT_CASH_DATA:
			return {
				...state,
				reportCashData: action.value,
			}
		case SET_Z_REPORT_DATEV:
			return {
				...state,
				reportDateV: action.value,
			}

		default:
			return state
	}
}


