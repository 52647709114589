import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../Elements/buttons/Button'
import { selectCompanyData } from '../../../../redux/selector/system'
import whatsapp_icon from '../../../../img/svg/whatsapp_icon.svg'
import { QRCodeSVG } from 'qrcode.react'
import MainIcon from '../../../../img/svg/main'
import { setSupportShowDeactivationWarningModal, setSupportShowExtraContactsModal } from '../../../../redux/action/support'
import { useTranslation } from 'react-i18next'
import { selectSupportPhone, selectSupportWhatsappHref } from '../../../../redux/selector/support'
import CustomInfoModal from '../../../Elements/modal/CustomInfoModal'
import { DeactivationModalContacts, DeactivationModalContent, DeactivationModalInfo, DeactivationModalLink, DeactivationModalSub, DeactivationModalText, DeactivationModalWatsappContacts, DeactivationModalWrap } from './index.styled'
import { selectActivationData } from '../../../../redux/selector/company'

const EVENT_Z_INDEX = 17

const DeactivationWarningModal = React.memo(() => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const companyIndex = useSelector(store => store.login.companyIndex)
	const name = useSelector(selectCompanyData('name'))
	const isDealer = useSelector(store => store.support.dealerContacts)
	const showModal = useSelector(store => store.support.showDeactivationWarningModal)
	const { dateDifferenceInDays } = useSelector(selectActivationData)
	const whatsappHref = useSelector(selectSupportWhatsappHref)
	const phone = useSelector(selectSupportPhone)

	useEffect(() => {
		dispatch(setSupportShowDeactivationWarningModal(true))

		return () => dispatch(setSupportShowDeactivationWarningModal(false))
	}, [])

	const handleClose = useCallback(() => {
		dispatch(setSupportShowDeactivationWarningModal(false))
	}, [dispatch])

	const showExtraContacts = useCallback(() => {
		dispatch(setSupportShowExtraContactsModal(true))
	}, [dispatch])

	return (
		<CustomInfoModal isOpen={showModal}
										 title={t('app.modals.Attention')}
										 close={handleClose}
										 zIndex={EVENT_Z_INDEX}
										 className={!whatsappHref && 'bottom-middle'}
										 modalColor="warn"
										 id="deactivation-warning-modal"
										 button={<>
											 <DeactivationModalLink className="center" onClick={showExtraContacts}>{t('app.modals.extraContacts')}</DeactivationModalLink>
											 <Button icon="checkmark" onClick={handleClose} tabIndex="1" text="Ok" color="green" />
										 </>}>
			<DeactivationModalWrap>
				<div>
					<DeactivationModalText>
						{t('app.modals.deactivationWarning.text1')} {t('app.modals.deactivationWarning.text2')} {dateDifferenceInDays} {t('app.modals.deactivationWarning.days')}. {isDealer ? t('app.modals.contact_dealer') : t('app.modals.contact_us')}.
					</DeactivationModalText>
					<DeactivationModalText>{name} - {companyIndex}</DeactivationModalText>
				</div>
				<DeactivationModalContent>
					{whatsappHref &&
						<QRCodeSVG value={whatsappHref}
											 level="M"
											 imageSettings={{
												 src: whatsapp_icon,
												 width: 32,
												 height: 32,
												 x: null,
												 y: null,
												 excavate: true,
											 }} />}
					<DeactivationModalInfo>
						<MainIcon icon={whatsappHref ? 'qr-phone' : 'phone'} />
						{whatsappHref ?
							<DeactivationModalWatsappContacts>
								<DeactivationModalSub>{t('app.modals.warningWatsappText')}</DeactivationModalSub>
								<DeactivationModalSub>{t('app.modals.warningContactsText')}:</DeactivationModalSub>
								<DeactivationModalLink href={`tel:${phone}`}>{phone}</DeactivationModalLink>
							</DeactivationModalWatsappContacts> :
							<DeactivationModalContacts>
								<DeactivationModalSub>{t('app.modals.warningContactsText')}:</DeactivationModalSub>
								<DeactivationModalLink href={`tel:${phone}`}>{phone}</DeactivationModalLink>
							</DeactivationModalContacts>}
					</DeactivationModalInfo>
				</DeactivationModalContent>
			</DeactivationModalWrap>
		</CustomInfoModal>
	)
})

DeactivationWarningModal.displayName = 'DeactivationWarningModal'
export default DeactivationWarningModal
