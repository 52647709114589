import styled from 'styled-components'
import { theme } from '../../../style/theme'

export const VersionUpdaterWrapper = styled.div`
    font-size: 1rem;
    display: flex;
    height: 2.25em;
    padding: 0.12em 1.43em;
    justify-content: center;
    align-items: center;
    gap: 1.25em;
    background: #FFEDDC;
    position: sticky;
    width: 100%;
    z-index: 11;
    top: 0;
`
VersionUpdaterWrapper.displayName = 'VersionUpdaterWrapper'

export const VersionUpdaterText = styled.div`
    color: #060606;
    font-family: ${theme.fontRobotoMedium};
    font-size: 0.87em;
    font-weight: 500;
`
VersionUpdaterText.displayName = 'VersionUpdaterText'

export const VersionUpdaterButton = styled.div`
    display: flex;
    padding: 0.12em 1.25em;
    justify-content: center;
    align-items: center;
    gap: 0.38em;
    height: 1.37em;
    cursor: pointer;
    border-radius: 0.32em;
    background: #2981EE;

		> svg {
			width: 0.88em;
			height: 0.88em;
			fill: #FFF;
			flex-shrink: 0;
		}
`
VersionUpdaterButton.displayName = 'VersionUpdaterButton'

export const VersionUpdaterButtonText = styled.div`
    color: #FFF;
    font-family: ${theme.fontRobotoMedium};
    font-size: 0.87em;
    font-weight: 500;
`
VersionUpdaterButtonText.displayName = 'VersionUpdaterButtonText'
