import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../Elements/buttons/Button'
import { DriverConfirmationModalSubText, DriverConfirmationModalText, DriverConfirmationModalWrap } from './index.styled'
import CustomInfoModal from '../../Elements/modal/CustomInfoModal'
import { setWoltDriverIntegrationsOrder, setWoltDriverIntegrationsDeliveryPromises } from '../../../redux/action/woltDriverIntegrations'
import { storeWoltDriverDeliveriesThunk } from '../../../redux/thunk/woltDriverIntegrations'
import Currency from '../../Elements/currency'

const EVENT_Z_INDEX = 888

const DriverConfirmationModal = React.memo(() => {
	const { t } = useTranslation()
    const dispatch = useDispatch()
	const deliveryPromises = useSelector(store => store.woltDriverIntegrations.deliveryPromises)
	const order = useSelector(store => store.woltDriverIntegrations.order)

	const handleClose = useCallback(() => {
		dispatch(setWoltDriverIntegrationsDeliveryPromises(null))
		dispatch(setWoltDriverIntegrationsOrder(null))
	}, [])

	const handleAccept = useCallback(() => {
		dispatch(storeWoltDriverDeliveriesThunk({ 
			orderId: order?.orderId,
			orderDayCounter: order?.orderDayCounter,
			orderDate: order?.orderDate,
			status: order?.status?.status,
			customer: order?.customer,
			preOrder: order?.preOrder,
			items: order?.items,
			payments: order?.payments,
			deliveryPromiseId: deliveryPromises?.deliveryPromiseId, 
			estimatedDeliveryCost: deliveryPromises?.estimatedDeliveryCost
		}))
		handleClose()
	}, [order, deliveryPromises])

	return (
		<CustomInfoModal
			isOpen={deliveryPromises}
			title={t('app.modals.Attention')}
			close={handleClose}
			zIndex={EVENT_Z_INDEX}
			button={
				<>
					<Button icon="clear" onClick={handleClose} text={t('buttons.controls.cancel_1')} color="gray" />
					<Button icon="checkmark" onClick={handleAccept} tabIndex="1" text={t('buttons.controls.ready')} color="green" keyButton="space" />
				</>
			}>
			<DriverConfirmationModalWrap>
				<DriverConfirmationModalText>{t('woltDriverIntegrationsModal.driverIsFound')}!</DriverConfirmationModalText>
				<div>
					<DriverConfirmationModalSubText>
						{t('woltDriverIntegrationsModal.estimatedCost')}: <Currency>{deliveryPromises?.estimatedDeliveryCost}</Currency>
					</DriverConfirmationModalSubText>
					<DriverConfirmationModalSubText>{t('woltDriverIntegrationsModal.estimatedMinutes', { minutes: deliveryPromises?.estimatedPickupMinutes })}</DriverConfirmationModalSubText>
				</div>
			</DriverConfirmationModalWrap>
		</CustomInfoModal>
	)
})

DriverConfirmationModal.displayName = 'DriverConfirmationModal'
export default DriverConfirmationModal
