import React from 'react'
import { SelectInfoWrapper, SelectLabel, SelectWrapper } from '../index.styled'
import Tooltip from '../../../tooltip'

const SelectInputControlled = React.memo(({
	name,
	value,
	children,
	testId,
	tabIndex,
	color,
	size,
	disabled,
	infoMessage,

	onChange,
	onBlur = () => {
	},
	onFocus = () => {
	}
}) => {

	const layout = color + ' ' + size + (!value ? ' placeholder' : '') + (disabled ? ' disabled' : '')

	return (
		<SelectWrapper className={layout}>
			<SelectLabel className={layout}>
				<select data-testid={testId ?? null}
								id={name}
								name={name}
								value={value || ''}
								onChange={onChange}
								onFocus={onFocus}
								onBlur={onBlur}
								className={layout}
								tabIndex={tabIndex}
								disabled={disabled}>
					{children}
				</select>
				{!infoMessage && <svg className="arrow" width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M9.99095 11C9.64587 11 9.30302 10.8734 9.0392 10.618L0.396567 2.23589C-0.131074 1.72514 -0.132186 0.897383 0.393228 0.385538C0.917529 -0.126307 1.77022 -0.128509 2.29674 0.382236L9.99095 7.84417L17.7041 0.381137C18.2306 -0.128507 19.0833 -0.127406 19.6087 0.386641C20.1319 0.898486 20.1308 1.72735 19.6032 2.23809L10.9383 10.6191C10.6767 10.8734 10.3338 11 9.99095 11Z" fill="#3689EF" />
				</svg>}
			</SelectLabel>
			{infoMessage && <SelectInfoWrapper className="info">
				<Tooltip text={infoMessage} position="top">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" data-testid={`${testId}-info`}>
						<path d="M12 0C5.34545 0 0 5.34545 0 12C0 18.6545 5.34545 24 12 24C18.6545 24 24 18.6545 24 12C24 5.34545 18.6545 0 12 0ZM14.1818 17.4545C14.1818 18.6545 13.2 19.6364 12 19.6364C10.8 19.6364 9.81818 18.6545 9.81818 17.4545V12.5455C9.81818 11.3455 10.8 10.3636 12 10.3636C13.2 10.3636 14.1818 11.3455 14.1818 12.5455V17.4545ZM12 7.63636C10.8 7.63636 9.81818 6.65455 9.81818 5.45455C9.81818 4.25455 10.8 3.27273 12 3.27273C13.2 3.27273 14.1818 4.25455 14.1818 5.45455C14.1818 6.65455 13.2 7.63636 12 7.63636Z" fill="#2981EE" />
					</svg>
				</Tooltip>
			</SelectInfoWrapper>}
		</SelectWrapper>
	)
})

SelectInputControlled.displayName = 'SelectInputControlled'
export default SelectInputControlled
