export const denyStatusButtons = [
	{ status: 'TOO_BUSY', label: 'Modal.deny_order_modal.reasons.busy' },
	{ status: 'BAD_WEATHER', label: 'Modal.deny_order_modal.reasons.bad_weather' },
	{ status: 'CARD_READER_NOT_AVAILABLE', label: 'Modal.deny_order_modal.reasons.card_not_working' },
	{ status: 'FRAUD_PRANK', label: 'Modal.deny_order_modal.reasons.bad_order' },
	{ status: 'ITEM_UNAVAILABLE', label: 'Modal.deny_order_modal.reasons.product_not_valid' },
	{ status: 'NO_COURIER', label: 'Modal.deny_order_modal.reasons.no_courier' },
	{ status: 'OUTSIDE_DELIVERY_AREA', label: 'Modal.deny_order_modal.reasons.not_deliver_address' },
	{ status: 'TECHNICAL_PROBLEM', label: 'Modal.deny_order_modal.reasons.problem_in_kitchen' },
	{ status: 'TEST_ORDER', label: 'Modal.deny_order_modal.reasons.test_order' },
]
