import { SET_CASH_DESK_CHANGE_SETTINGS, SET_CASH_DESK_SETTINGS_CART_SETTINGS, UPDATE_CASH_DESK_CHANGE_SETTING, UPDATE_CASH_DESK_SETTINGS_CART_SETTING, SET_CASH_DESK_SETTINGS_WAITER_SETTINGS, SET_CASH_DESK_SETTINGS_PRINT_SETTINGS } from '../../actionTypes/cashDeskSettings'

export const setCashDeskSettingsCartSettings = value => ({ type: SET_CASH_DESK_SETTINGS_CART_SETTINGS, value })

export const updateCashDeskSettingsCartSetting = value => ({ type: UPDATE_CASH_DESK_SETTINGS_CART_SETTING, value })

export const setCashDeskSettingsChangeSettings = value => ({ type: SET_CASH_DESK_CHANGE_SETTINGS, value })

export const updateCashDeskSettingsChangeSetting = value => ({ type: UPDATE_CASH_DESK_CHANGE_SETTING, value })

export const setCashDeskSettingsWaiterSettings = value => ({ type: SET_CASH_DESK_SETTINGS_WAITER_SETTINGS, value })

export const setCashDeskSettingsPrintSettings = value => ({ type: SET_CASH_DESK_SETTINGS_PRINT_SETTINGS, value })
