import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const DailyReport = lazyWithRetry(() => import(/* webpackChunkName: "z-report" */'../../component/Report/form/daily'))

const DailyReportPage = React.memo(() => {
	return (
		<Suspense>
			<DailyReport />
		</Suspense>
	)
})

DailyReportPage.displayName = 'DailyReportPage'
export default DailyReportPage
