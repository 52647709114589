import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const DesktopUser = lazyWithRetry(() => import(/* webpackChunkName: "desktop-user" */'../../component/DesktopUser'))

const DesktopUserPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<DesktopUser />
		</Suspense>
	)
})

DesktopUserPage.displayName = 'DesktopUserPage'
export default DesktopUserPage
