import { ADD_DELIVERY_CART_ITEM, ADD_DELIVERY_CART_REMARK_TO_ITEM, ADD_DELIVERY_CART_SUBITEM, REMOVE_DELIVERY_CART_ITEM, REMOVE_DELIVERY_CART_REMARK, REMOVE_DELIVERY_CART_SUBITEM, SET_DELIVERY_CART_CURRENT_DELIVERY_COST, SET_SHOW_DELIVERY_CONFIG_ERROR, SET_DELIVERY_CART_DEFAULT_DELIVERY_COST, SET_DELIVERY_CART_FREE_FROM, SET_DELIVERY_CART_ITEM_FOCUS_INDEX, SET_DELIVERY_CART_ITEMS, SET_DELIVERY_CART_MINIMUM_PRICE, SET_DELIVERY_CART_ORDER, SET_DELIVERY_CART_ORDER_TYPE, SET_DELIVERY_CART_PREORDER_DATE, UPDATE_DELIVERY_CART_ITEM, SET_DELIVERY_CART_MODAL_CHANGE_CALCULATOR_IS_NEEDED, SET_DELIVERY_CART_MAX_DISTANCE, REMOVE_DELIVERY_CART_COUPON, SET_DELIVERY_CART_GIFT_COUPONS } from '../../actionTypes/deliveryCart'

export const setDeliveryCartOrderType = value => ({ type: SET_DELIVERY_CART_ORDER_TYPE, value })

export const setDeliveryCartItems = value => ({ type: SET_DELIVERY_CART_ITEMS, value })

export const addDeliveryCartItem = value => ({ type: ADD_DELIVERY_CART_ITEM, value })

export const addDeliveryCartSubItem = value => ({ type: ADD_DELIVERY_CART_SUBITEM, value })

export const removeDeliveryCartItem = value => ({ type: REMOVE_DELIVERY_CART_ITEM, value })

export const removeDeliveryCartSubItem = value => ({ type: REMOVE_DELIVERY_CART_SUBITEM, value })

export const updateDeliveryCartItem = value => ({ type: UPDATE_DELIVERY_CART_ITEM, value })

export const setDeliveryCartItemFocusIndex = value => ({ type: SET_DELIVERY_CART_ITEM_FOCUS_INDEX, value })

export const setDeliveryCartPreorderDate = value => ({ type: SET_DELIVERY_CART_PREORDER_DATE, value })

export const setDeliveryCartOrder = value => ({ type: SET_DELIVERY_CART_ORDER, value })

export const setDeliveryCartMaxDistance = value => ({ type: SET_DELIVERY_CART_MAX_DISTANCE, value })

export const setDeliveryCartMinimumPrice = value => ({ type: SET_DELIVERY_CART_MINIMUM_PRICE, value })

export const setDeliveryCartFreeFrom = value => ({ type: SET_DELIVERY_CART_FREE_FROM, value })

export const setDeliveryCartCurrentDeliveryCost = value => ({ type: SET_DELIVERY_CART_CURRENT_DELIVERY_COST, value })

export const setDeliveryCartDefaultDeliveryCost = value => ({ type: SET_DELIVERY_CART_DEFAULT_DELIVERY_COST, value })

export const removeDeliveryCartRemark = value => ({ type: REMOVE_DELIVERY_CART_REMARK, value })

export const addDeliveryCartRemarkToItem = value => ({ type: ADD_DELIVERY_CART_REMARK_TO_ITEM, value })

export const setDeliveryCartModalChangeCalculatorIsNeeded = value => ({ type: SET_DELIVERY_CART_MODAL_CHANGE_CALCULATOR_IS_NEEDED, value })

export const setShowDeliveryConfigError = value => ({ type: SET_SHOW_DELIVERY_CONFIG_ERROR, value })

export const removeDeliveryCartCoupon = () => ({ type: REMOVE_DELIVERY_CART_COUPON })
export const setDeliveryCartGiftCoupon = value => ({ type: SET_DELIVERY_CART_GIFT_COUPONS, value})
