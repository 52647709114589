import { store } from '../../store/configureStore'
import { setModalLoginAccessToken, setModalLoginRefreshToken } from '../action/modalLogin'
import registrationModalLoginInstance from './registration/modalLoginInstance'

let tokenIsRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const modalLoginResponseInterceptor = error => {
  const originalRequest = error.config

  if (error?.response?.status !== 401) {
    return Promise.reject(error)
  }

  if (error?.response?.status === 401 && (originalRequest.url === '/auth/login' || originalRequest.url === '/auth/less')) {
    return Promise.reject(error)
  }

  if (error?.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    if (tokenIsRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      }).then(token => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token
        return registrationModalLoginInstance(originalRequest)
      }).catch(err => {
        return Promise.reject(err)
      })
    }

    originalRequest._retry = true
    tokenIsRefreshing = true

    return new Promise((resolve, reject) => {
      registrationModalLoginInstance.post('/auth/refresh', { 'refreshToken': store.getState().modalLogin.refreshToken })
        .then(res => {
          store.dispatch(setModalLoginAccessToken(res.data.accessToken))
          store.dispatch(setModalLoginRefreshToken(res.data.refreshToken))
          registrationModalLoginInstance.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`
          originalRequest.headers['Authorization'] = `Bearer ${res.data.accessToken}`
          processQueue(null, res.data.token)

          resolve(registrationModalLoginInstance(originalRequest))
        })
        .catch((err) => {
          store.dispatch(setModalLoginAccessToken(null))
          store.dispatch(setModalLoginRefreshToken(null))
          reject(err)
        })
        .finally(() => {
          tokenIsRefreshing = false
        })
    })
  }

  return Promise.reject(error)
}

export default modalLoginResponseInterceptor
