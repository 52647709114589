import paymentInstance from '../instance'
import paymentModalLoginInstance from '../modalLoginInstance'

const fetchTerminals = (page = 0, size = 10, forCurrentUser = false, hasModalLogin = false) => {
	return (hasModalLogin ? paymentModalLoginInstance : paymentInstance).get(
		'/terminals'
		+ '?page=' + page
		+ '&size=' + size
		+ (forCurrentUser ? '&forCurrentUser=' + forCurrentUser : ''),
	)
}

export default fetchTerminals
