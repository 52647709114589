import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const RestaurantSettings = lazyWithRetry(() => import(/* webpackChunkName: "restaurant-settings" */'../../component/Settings/RestaurantSettings'))

const RestaurantSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<RestaurantSettings />
		</Suspense>
	)
})

RestaurantSettingsPage.displayName = 'RestaurantSettingsPage'
export default RestaurantSettingsPage
