import { SET_WEBSHOP_INFO_CONFIG, SET_WEBSHOP_LOGO_CONFIG, SET_WEBSHOP_LOGO_CLOUDINARY_DATA, SET_WEBSHOP_IMAGE_CONDITIONS, SET_WEBSHOP_IMAGE_ERROR_MODAL, UPDATE_WEBSHOP_ORDER_TYPE_STATUSES, SET_WEBSHOP_SHOW_ADVICE_WINDOW } from '../../actionTypes/webshopinfo'

const initialState = {
	config: null,
	logoConfig: null,
	logoCloudinaryData: null,
	imageConditions: false,
	showImageErrorModal: false,
	showAdviceWindow: false
}

export function webshopInfoReducer (state = initialState, action) {
	switch (action.type) {
		case SET_WEBSHOP_INFO_CONFIG:
			return {
				...state,
				config: action.value,
			}
		case SET_WEBSHOP_LOGO_CONFIG:
			return {
				...state,
				logoConfig: action.value,
			}
		case SET_WEBSHOP_LOGO_CLOUDINARY_DATA:
			return {
				...state,
				logoCloudinaryData: action.value,
			}
		case SET_WEBSHOP_IMAGE_CONDITIONS:
				return {
					...state,
					imageConditions: action.value,
				}
		case SET_WEBSHOP_IMAGE_ERROR_MODAL:
			return {
				...state,
				showImageErrorModal: action.value,
			}
		case UPDATE_WEBSHOP_ORDER_TYPE_STATUSES:
			const updatedOrderTypeWorkingStatuses = state.config?.orderTypeWorkingStatuses.map(el => el.orderType === action.value.orderType ? action.value : el)
			return {
				...state,
				config: { ...state.config, orderTypeWorkingStatuses: updatedOrderTypeWorkingStatuses },
			}
		case SET_WEBSHOP_SHOW_ADVICE_WINDOW:
			return {
				...state,
				showAdviceWindow: action.value,
			}
		default:
			return state
	}
}
