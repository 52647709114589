import hereInstance from './instance'
import i18next from 'i18next'

const getAutocomplete = (countryCode, centerPoint = null, query, apiKey, callbackSuccess, callbackError) => {

  if (!apiKey) return

  return async () => {

    const center = centerPoint ? centerPoint : '52.40715,9.70718'
    //const radius = `circle:${center};r=1000000`

    hereInstance.get(
      '/autocomplete?'
      + 'apiKey=' + apiKey
      + '&limit=20'
      + '&lang=' + i18next.language
      + '&at=' + center
      + (countryCode ? `&in=countryCode:${countryCode}` : '')
      + '&q=' + (query ? query : ''))
      .then(res => {
        if (res && typeof callbackSuccess === 'function') callbackSuccess(res)
      })
      .catch(error => {
        if (typeof callbackError === 'function') callbackError(error)
      })
  }

}

export default getAutocomplete
