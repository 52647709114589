import React from 'react'
import Cart from './Cart'
import { CartStepImage, CartStepImageWrapper, CartStepWrapper } from './index.styled'
import ClientMonitorCartLogo from '../common/ClientMonitorCartLogo'
import { useSelector } from 'react-redux'

const CartStep = React.memo(() => {
	const clientMonitorBackgroundImage = useSelector(state => state.clientMonitorConfig.config?.logoImg)

	return (
		<CartStepWrapper>
			<CartStepImageWrapper>
				<CartStepImage>
					<img src={clientMonitorBackgroundImage} alt="client-monitor-background" />
				</CartStepImage>
				<ClientMonitorCartLogo layout="custom" />
			</CartStepImageWrapper>

			<Cart />
		</CartStepWrapper>
	)
})

CartStep.displayName = 'CartStep'
export default CartStep
