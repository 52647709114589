import React from 'react'
import { ReactComponent as LieferandoSvg } from './assets/lieferando.svg'
import { ReactComponent as UberEatsSvg } from './assets/uber_eats.svg'
import { ReactComponent as WoltSvg } from './assets/wolt.svg'
import { ReactComponent as OrderSmartSvg } from './assets/order_smart.svg'
import { ReactComponent as JustEatSvg } from './assets/just_eat.svg'
import { ReactComponent as WebshopSvg } from './assets/webshop.svg'
import { ReactComponent as RestaurantSvg } from './assets/restaurant.svg'
import { ReactComponent as PhoneOrderSvg } from './assets/phone_order.svg'
import { ReactComponent as KioskSvg } from './assets/kiosk.svg'
import { ReactComponent as FoodoraSvg } from './assets/foodora.svg'
import { ReactComponent as LieferandoDarkSvg } from './assets/lieferando-dark.svg'
import { ReactComponent as UberEatsDarkSvg } from './assets/uber_eats-dark.svg'
import { ReactComponent as WoltDarkSvg } from './assets/wolt-dark.svg'
import { ReactComponent as OrderSmartDarkSvg } from './assets/order_smart-dark.svg'
import { ReactComponent as JustEatDarkSvg } from './assets/just_eat-dark.svg'
import { ReactComponent as WebshopDarkSvg } from './assets/webshop-dark.svg'
import { ReactComponent as RestaurantDarkSvg } from './assets/restaurant-dark.svg'
import { ReactComponent as PhoneOrderDarkSvg } from './assets/phone_order-dark.svg'
import { ReactComponent as KioskDarkSvg } from './assets/kiosk-dark.svg'
import { ReactComponent as FoodoraDarkSvg } from './assets/foodora-dark.svg'
import { ReactComponent as WoltDriveSvg } from './assets/wolt-drive.svg'
import { ReactComponent as TakeawayDriveSvg } from './assets/takeaway-drive.svg'
import { ReactComponent as FoodoraDriveSvg } from './assets/foodora-drive.svg'

const PlatformIcon = React.memo(({ icon, height = 20, width = 20, fill, stroke }) => {
	const svgProps = { height, width, fill, stroke, className: icon }

	return (
		<>
			{icon === 'UBER_EAT_PLUGIN-LIGHT' && <UberEatsSvg {...svgProps} />}
			{icon === 'WOLT_PLUGIN-LIGHT' && <WoltSvg {...svgProps} />}
			{icon === 'WOLT_DRIVE-LIGHT' && <WoltSvg {...svgProps} />}
			{icon === 'ORDER_SMART_PLUGIN-LIGHT' && <OrderSmartSvg {...svgProps} />}
			{icon === 'ONLINE_SHOP-LIGHT' && <WebshopSvg {...svgProps} />}
			{icon === 'FOODORA_PLUGIN-LIGHT' && <FoodoraSvg {...svgProps} />}
			{icon === 'lieferando-de-LIGHT' && <LieferandoSvg {...svgProps} />}
			{icon === 'lieferando-che-LIGHT' && <JustEatSvg {...svgProps} />}
			{icon === 'restaurant-LIGHT' && <RestaurantSvg {...svgProps} />}
			{icon === 'phone-order-LIGHT' && <PhoneOrderSvg {...svgProps} />}
			{icon === 'kiosk-LIGHT' && <KioskSvg {...svgProps} />}
			{icon === 'UBER_EAT_PLUGIN-DARK' && <UberEatsDarkSvg {...svgProps} />}
			{icon === 'WOLT_PLUGIN-DARK' && <WoltDarkSvg {...svgProps} />}
			{icon === 'WOLT_DRIVE-DARK' && <WoltDarkSvg {...svgProps} />}
			{icon === 'ORDER_SMART_PLUGIN-DARK' && <OrderSmartDarkSvg {...svgProps} />}
			{icon === 'ONLINE_SHOP-DARK' && <WebshopDarkSvg {...svgProps} />}
			{icon === 'FOODORA_PLUGIN-DARK' && <FoodoraDarkSvg {...svgProps} />}
			{icon === 'lieferando-de-DARK' && <LieferandoDarkSvg {...svgProps} />}
			{icon === 'lieferando-che-DARK' && <JustEatDarkSvg {...svgProps} />}
			{icon === 'restaurant-DARK' && <RestaurantDarkSvg {...svgProps} />}
			{icon === 'phone-order-DARK' && <PhoneOrderDarkSvg {...svgProps} />}
			{icon === 'kiosk-DARK' && <KioskDarkSvg {...svgProps} />}
			{icon === 'TAKEAWAY_PLUGIN-LIGHT' && <LieferandoSvg {...svgProps} />}
			{icon === 'WOLT_DRIVE' && <WoltDriveSvg {...svgProps} />}
			{icon === 'TAKEAWAY_DRIVE' && <TakeawayDriveSvg {...svgProps} />}
			{icon === 'FOODORA_DRIVE' && <FoodoraDriveSvg {...svgProps} />}
		</>
	)
})

PlatformIcon.displayName = 'PlatformIcon'
export default PlatformIcon
