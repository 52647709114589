import styled from 'styled-components'
import { colorTheme } from '../../../style/theme/colorTheme'
import { theme } from '../../../style/theme'

export const QrCodeStepWrapper = styled.div`
	font-size: 1rem;
	position: relative;
	display: grid;
	grid-template-rows: 1fr min-content;
	align-items: center;
	justify-content: center;
	background-color: ${colorTheme.clientMonitorCart.qrPaymentStep.background};
`
QrCodeStepWrapper.displayName = 'QrCodeStepWrapper'
export const QrCodeContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1.25em;
	max-width: 23.38em;
	justify-self: center;
`
QrCodeContent.displayName = 'QrCodeContent'
export const QrCodeWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 1.25em 1.25em 0.94em;
	background-color: ${colorTheme.clientMonitorCart.qrPaymentStep.content.background};
	border: 10px solid ${colorTheme.clientMonitorCart.qrPaymentStep.content.border};
	border-radius: 1.25em;
`
QrCodeWrap.displayName = 'QrCodeWrap'
export const QrCodeBlock = styled.div`
	> svg {
		width: 18.75em;
		height: 18.75em;
	}
`
QrCodeBlock.displayName = 'QrCodeBlock'
export const QrCodePaymentText = styled.div`
	color: ${colorTheme.clientMonitorCart.qrPaymentStep.content.title};
	text-align: center;
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.88em;
	font-weight: 500;
`
QrCodePaymentText.displayName = 'QrCodePaymentText'

