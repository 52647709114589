import React from 'react'
import { StartStepImg, StartStepWrap } from '../index.styled'
import ClientMonitorCartLogo from '../common/ClientMonitorCartLogo'
import { useSelector } from 'react-redux'

const StartStep = React.memo(() => {
	const clientMonitorBackgroundImage = useSelector(state => state.clientMonitorConfig.config?.logoImg) || '/img/clientMonitor/pizza.webp'

	return (
		<StartStepWrap>
			<StartStepImg src={clientMonitorBackgroundImage} alt="StartStep"></StartStepImg>
			<ClientMonitorCartLogo />
		</StartStepWrap>
	)
})

StartStep.displayName = 'StartStep'
export default StartStep
