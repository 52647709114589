import React from 'react'
import { CartProductBlock, CartProductQuantity, CartProductTitle, CartProductTitleBlock, CartProductWrapper, CartTopping, CartToppings, CartToppingTitle } from '../index.styled'

const CartProduct = React.memo(({ item, index, isMenuProduct = false }) => {
	const testId = (isMenuProduct ? 'menu-product-' : 'product-') + `${index}-` + item?.name
	const layout = (isMenuProduct ? 'menu ' : '')

	return (
		<CartProductWrapper data-testid={testId} className={layout}>
			<CartProductBlock className={layout}>
				<CartProductTitleBlock>
					<CartProductTitle>{item?.name}</CartProductTitle>
					<CartProductQuantity data-testid={testId + '-quantity'} className={layout}>x{item?.quantity}</CartProductQuantity>
				</CartProductTitleBlock>
				{item?.items?.length > 0 &&
					<CartToppings className={layout}>
						{item?.items?.map((subItem, subItemIndex) =>
							<CartTopping key={subItemIndex}>
								<CartToppingTitle>{subItem.name} x{subItem.quantity}</CartToppingTitle>
							</CartTopping>)}
					</CartToppings>}
			</CartProductBlock>
		</CartProductWrapper>
	)
})

CartProduct.displayName = 'CartProduct'
export default CartProduct
