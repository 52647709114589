import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { KeyboardMain } from './NumbersKeyboard/index.styled'
import { colorTheme } from '../../../style/theme/colorTheme'

export const KeyboardsWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.88em;
	margin-top: 1.88em;

	@media screen and (max-width: ${theme.point520}) {
		gap: 1.25em;
		grid-template-columns: 1fr;
		${KeyboardMain} {
			-webkit-filter: none;
		}
	}
`
KeyboardsWrapper.displayName = 'KeyboardsWrapper'

export const InputsWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr ;
	gap: 1.88em;
	margin-bottom: 0.88em;
	align-items: end;

	@media screen and (max-width: ${theme.point520}) {
		grid-template-columns: 1fr;
		gap: 1.25em;
	}
`
InputsWrapper.displayName = 'InputsWrapper'


export const GetMoneyInputField = styled.input`
	font-size: 1.75em;
	width: 100%;
	background-color: ${colorTheme.cartModal.changeCalculator.input.getMoney.background};
	color: ${colorTheme.cartModal.changeCalculator.input.getMoney.text};
	text-align: left;
	align-items: center;
`
GetMoneyInputField.displayName = 'GetMoneyInputField'

export const GetMoneyInputContainer = styled.div`
  font-size: 1rem;
  height: 4.19em;
	background-color: ${colorTheme.cartModal.changeCalculator.input.getMoney.background};
  border: 1px solid ${colorTheme.cartModal.changeCalculator.input.getMoney.border};
  border-radius: 0.75em;
  padding: 0.75em 1.25em;
  box-shadow: 0px 4px 9.38em 0px ${colorTheme.cartModal.changeCalculator.input.getMoney.shadow};
  box-sizing: border-box;

	display: flex;
	align-items: center;

  @media screen and (max-width: ${theme.point720}) {
    height: 3.44em;
    box-shadow: none;
  }
`
GetMoneyInputContainer.displayName = 'GetMoneyInputContainer'

export const GetMoneyLabelText = styled.label`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	color: ${colorTheme.cartModal.changeCalculator.input.getMoney.labelText};
	text-align: left;
`
GetMoneyLabelText.displayName = 'GetMoneyLabelText'
export const GetMoneyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: .31em;
  width: 100%;
  max-width: 25.37em;

  @media screen and (max-width: ${theme.point820}) {
    max-width: 27.67em;
  }

	@media screen and (max-width: ${theme.point720}) {
    max-width: 100%;
  }
`
GetMoneyWrapper.displayName = 'GetMoneyWrapper'

export const ChangeInput = styled.div`
  width: 100%;
	max-width: 25.37em;
  font-size: 1rem;
  height: 4.19em;
  font-family: ${theme.fontRobotoBold};
  background-color: ${colorTheme.cartModal.changeCalculator.input.change.background};
  border: 1px dashed ${colorTheme.cartModal.changeCalculator.input.change.border};
  border-radius: 0.75em;
  padding: 0.78em;
  box-shadow: none;

  display: flex;
  gap: .31em;
  align-items: center;
  justify-content: center;

	@media screen and (max-width: ${theme.point820}) {
		max-width: 27.67em;
	}

  @media screen and (max-width: ${theme.point720}) {
    height: 3.44em;
		max-width: 100%;
	}
`

ChangeInput.displayName = 'ChangeInput'

export const ChangeInputText = styled.span`
	font-family: ${theme.fontRoboto};
	font-size: 1.5em;
	color: ${colorTheme.cartModal.changeCalculator.input.change.lightText};
`
ChangeInputText.displayName = 'ChangeInputText'

export const ChangeInputSum = styled.span`
  font-size: 1.5em;
  font-family: ${theme.fontRobotoBold};
  color: ${colorTheme.cartModal.changeCalculator.input.change.text};
`
ChangeInputSum.displayName = 'ChangeInputSum'

export const ButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 1.88em;

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
		gap: 1.25em;
		margin-top: 0;
	}
`
ButtonsWrapper.displayName = 'ButtonsWrapper'

export const ChangeCalculatorWrapper = styled.div`
`
ChangeCalculatorWrapper.displayName = 'ChangeCalculatorWrapper'

export const ChangeCalculatorContent = styled.div`
	@media screen and (max-width: ${theme.point720}) {
		padding: 0;
		margin-bottom: 2.5em;
		margin-top: 2.5em;
	}
`
ChangeCalculatorContent.displayName = 'ChangeCalculatorContent'

