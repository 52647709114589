import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import { PER_LOAD_ALL } from '../constants'
import { setCartCategoryCategories } from '../../action/cartCategory'
import fetchCategories from '../../axios/category'

export const getCartCategoriesThunk = (isSubcategory, page = 0, size = PER_LOAD_ALL) => dispatch => {
  return fetchCategories(page, size, null, isSubcategory)
    .then((res) => {
      dispatch(setCartCategoryCategories(res.data))
    }).catch((error) => {
      dispatch(setAppErrors(retrieveErrorFromApi(error)))
    })
}
