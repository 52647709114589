import React from 'react'
import { useSelector } from 'react-redux'
import { KeyIconWrap } from './index.styled'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'

const KeyIcon = React.memo(({ keyButton, keyText, zIndex, onKeyPress }) => {
	const isNeededZIndex = useSelector(selectIsNeededZIndex(zIndex))

	return (
		<>
			<KeyIconWrap>{keyText || keyButton}</KeyIconWrap>

			{isNeededZIndex && keyButton &&
				<KeyBindings customKey={keyButton} customKeyPress={onKeyPress} />}
		</>
	)
})

KeyIcon.displayName = 'KeyIcon'
export default KeyIcon
