import { SET_CASH_DRAWER_CONFIG } from '../../actionTypes/cashDrawer'

const initialState = {
  config: [],
}

export function cashDrawerReducer (state = initialState, action) {
  switch (action.type) {
    case SET_CASH_DRAWER_CONFIG:
      return {
        ...state,
        config: action.value,
      }
    default:
      return state
  }
}
