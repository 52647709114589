export const calcItemTotalPrice = (item) => {
	if (item.quantity < 0) throw new Error('Invalid quantity')
	const itemTotal = (item.price != null && item.status !== 'CANCELED') ? item.price : 0
	let subItemsTotal = 0

	if (item.type === 'MENU') {
		subItemsTotal = item.items.filter(item => item.status !== 'CANCELED').reduce((it, i) => it + calcItemTotalPrice(i), 0)
	} else if (item.items) {
		subItemsTotal = item.items.filter(item => item.status !== 'CANCELED').reduce((it, i) => it + ((i.price && i.quantity) ? i.price * i.quantity : 0), 0)
	}

	return item.quantity != null ? Math.round((((itemTotal + (subItemsTotal < 0 ? 0 : subItemsTotal)) * item.quantity) + Number.EPSILON) * 100) / 100
		: itemTotal + (subItemsTotal < 0 ? 0 : subItemsTotal)
}


export const calcOrdersTotalPrices = (orders) => {
	orders && orders.forEach(order => {
		let totalPrice = 0
		if (order.items) {
			totalPrice = order.items.filter(item => item.status !== 'CANCELED').reduce((it, i) => it + calcItemTotalPrice(i), 0)
		}
		order.totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100
	})
	return orders
}

export const calcNewOrderTotalPrices = (order) => {
	if (!order) return null

	const copyOrder = { ...order }
	let totalPrice = 0
	if (order.items) {
		totalPrice = copyOrder.items.filter(item => item.status !== 'CANCELED').reduce((it, i) => it + calcItemTotalPrice(i), 0)
	}
	copyOrder.totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100
	return copyOrder
}

export const calculateItemsTotalWithDiscount = (item) => {
	return  item?.reduce((res, el) => res + el.items.reduce((tp, i) => tp + calcItemTotalPrice(i), 0), 0)
}
