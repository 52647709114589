export const SET_DELIVERY_CONFIG = 'SET_DELIVERY_CONFIG'
export const SET_DELIVERY_CONFIGS = 'SET_DELIVERY_CONFIGS'
export const APPEND_DELIVERY_CONFIGS = 'APPEND_DELIVERY_CONFIGS'
export const SET_DELIVERY_CONFIG_LENGTH = 'SET_DELIVERY_CONFIG_LENGTH'
export const SET_DELIVERY_CONFIG_TYPE = 'SET_DELIVERY_CONFIG_TYPE'
export const SET_DELIVERY_CONFIG_POLYGONS_LENGTH = 'SET_DELIVERY_CONFIG_POLYGONS_LENGTH'
export const APPEND_DELIVERY_CONFIG_POLYGON = 'APPEND_DELIVERY_CONFIG_POLYGON'
export const SET_DELIVERY_CONFIG_POLYGONS = 'SET_DELIVERY_CONFIG_POLYGONS'
export const SET_DELIVERY_CONFIG_ID_EDITED_ZONE ='SET_DELIVERY_CONFIG_ID_EDITED_ZONE'
export const SET_DELIVERY_CONFIG_POLYGON_COLOR = 'SET_DELIVERY_CONFIG_POLYGON_COLOR'
export const SET_DELIVERY_CONFIG_NEW_ZONE_DATA = 'SET_DELIVERY_CONFIG_NEW_ZONE_DATA'
export const SET_DELIVERY_CONFIG_SHOW_DEFINE_POLYGON_MODAL = 'SET_DELIVERY_CONFIG_SHOW_DEFINE_POLYGON_MODAL'
export const SET_DELIVERY_CONFIG_SHOW_CREATING_FORM = 'SET_DELIVERY_CONFIG_SHOW_CREATING_FORM'
export const SET_DELIVERY_CONFIG_POLYGON_ERROR ='SET_DELIVERY_CONFIG_POLYGON_ERROR'
export const SET_DELIVERY_CONFIG_DRAWING_MODE ='SET_DELIVERY_CONFIG_DRAWING_MODE'
export const SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SEARCH_IS_FINISHED ='SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SEARCH_IS_FINISHED'
export const SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SUGGESTIONS ='SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SUGGESTIONS'
export const UPDATE_DELIVERY_CONFIG_ZIP_LOCALITY_SUGGESTION ='UPDATE_DELIVERY_CONFIG_ZIP_LOCALITY_SUGGESTION'
export const SET_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA ='SET_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA'
export const APPEND_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA ='APPEND_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA'
export const UPDATE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA ='UPDATE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA'
export const DELETE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA ='DELETE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA'
