import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setAppModal, setAppModalData } from '../../../redux/action/system'
import CustomModal from '../../Elements/modal/CustomModal'
import Button from '../../Elements/buttons/Button'
import printJS from 'print-js'
import { PrintButtonsList, PrintMessageReconnect } from './index.styled'
import { fetchAllDesktopUsersThunk, putDesktopUserThunk } from '../../../redux/thunk/registration/desktop'
import { selectIsNeededZIndex } from '../../../redux/selector/system'
import KeyBindings from '../../Elements/keybindings'
import { selectActivationData } from '../../../redux/selector/company'

export const PRINT_MESSAGE_MODAL_EVENT_Z_INDEX = 15

const PrintMessageModal = React.memo(() => {
	const { t } = useTranslation()
	const printMessageModalShow = useSelector(store => store.system.modal?.printMessageModalShow)
	const printMessageModalData = useSelector(store => store.system.modalData?.printMessageModalData)
	const desktopUsers = useSelector(store => store.desktop.users)
	const printerDesktopSessionsExist = useSelector(store => !!store.socket.printerDesktopSessions?.length)
	const dispatch = useDispatch()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(PRINT_MESSAGE_MODAL_EVENT_Z_INDEX))
	const { isDeactivated } = useSelector(selectActivationData)

	useEffect(() => {
		if (printMessageModalShow) {
			dispatch(fetchAllDesktopUsersThunk())
		}
	}, [printMessageModalShow, dispatch])

	const handleClose = useCallback(() => {
		dispatch(setAppModalData({ printMessageModalData: [] }))
		dispatch(setAppModal({ printMessageModalShow: false }))
	}, [dispatch])

	const handlePrint = useCallback((index, data) => {
		const printModalData = [...printMessageModalData.slice(0, index), ...printMessageModalData.slice(index + 1)]
		if (printModalData.length > 0) {
			dispatch(setAppModalData({ printMessageModalData: printModalData }))
		} else {
			handleClose()
		}
		printJS({
			printable: data,
			type: 'raw-html',
		})
	}, [printMessageModalData, dispatch, handleClose])

	const handleDesktopReconnect = useCallback(() => {
		//eslint-disable-next-line
		console.warn('Trying to reconnect desktop with button..')
		desktopUsers?.forEach(user => {
			dispatch(putDesktopUserThunk(user?.id))
		})
	}, [desktopUsers, dispatch])

	return (
		<>
			<CustomModal isOpen={!isDeactivated && printMessageModalShow}
				close={handleClose}
				size="small"
				title={t('Modal.print_modal.title')}
				zIndex={PRINT_MESSAGE_MODAL_EVENT_Z_INDEX}
				id="print-message-modal">
				<PrintButtonsList>
					{printMessageModalData.map((printItem, index) =>
						<Button key={index} color="blue" icon="print" text={`${t('Modal.print_modal.button_print')} #${index + 1}`}
							onClick={() => handlePrint(index, printItem)} />)}
				</PrintButtonsList>
				<PrintMessageReconnect>
					{!!desktopUsers?.length &&
						<Button color={printerDesktopSessionsExist ? 'red' : 'gray'} icon="update"
							text={t('Modal.print_modal.reconnect_button_print')} onClick={handleDesktopReconnect} />}
				</PrintMessageReconnect>
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings escape={handleClose} />}
		</>
	)
})

PrintMessageModal.displayName = 'PrintMessageModal'
export default PrintMessageModal
