import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const Split = styled.div`
  display: flex;
  align-items: center;
  min-width: 13.75em;
  width: max-content;
  padding: 0.63em 1.25em;
  border: 2px solid ${colorTheme.restaurant.splitModal.split.border};
  border-radius: 0.75em;
  min-height: 4.06em;
  cursor: pointer;

  .image {
    width: 1.25em;
    height: 1.69em;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    margin-left: 0.55em;
    margin-right: 0.23em;
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.38em;
    line-height: 1.18em;
    color: ${colorTheme.restaurant.splitModal.split.name};
  }

  .price {
    font-family: ${theme.fontRobotoBold};
    font-size: 1.38em;
    line-height: 1.18em;
    color: ${colorTheme.restaurant.splitModal.split.price};
  }

  &.active {
    background: ${colorTheme.restaurant.splitModal.split.active.background};

    .name {
      color: ${colorTheme.restaurant.splitModal.split.active.name};
    }

    .price {
      color: ${colorTheme.restaurant.splitModal.split.active.price};
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    min-height: 3.44em;
    padding: 0.5em 0.94em;
    border-width: 1px;
    min-width: auto;
    width: auto;

    .image {
      width: 1.06em;
    }

    .name {
      font-size: 1.25em;
      margin-left: 0.3em;
      margin-right: 0.3em;
    }

    .price {
      font-size: 1.25em;
    }
  }
`
Split.displayName = 'Split'

export const TableOrders = styled.div`
  padding-top: 0.63rem;
  border: 2px solid ${props => props.color || colorTheme.restaurant.splitModal.table.border};
  border-radius: 0.75rem;

  @media screen and (max-width: ${theme.point720}) {
    padding: 0;
    height: auto;
    border-width: 1px;
  }
`
TableOrders.displayName = 'TableOrders'

export const TableOrdersHeadWrapper = styled.div`
  font-family: ${theme.fontRobotoRegular};
  line-height: 1.18em;
  text-align: left;
  display: grid;
	grid-template-columns: 1fr 1fr;
  gap: 1.69rem;
  padding: 0 0 0.63em 0.63em;
  font-size: 1.13em;
  font-family: ${theme.fontRobotoMedium};
  color: ${colorTheme.restaurant.splitModal.table.header};

`

export const TableOrdersHead = styled.div`
  font-family: ${theme.fontRobotoRegular};
  text-align: left;
  font-size: 1.13em;
  font-family: ${theme.fontRobotoMedium};
  color: ${colorTheme.restaurant.splitModal.table.header};

  @media screen and (max-width: ${theme.point720}) {
    padding: 0.63em 1em 0.63em 0.63em;
  }
`

export const TableOrdersProducts = styled.div`
  overflow: auto;
  height: 40vh;
  padding: 0.63rem;
  filter: drop-shadow(0px 4px 150px rgba(0, 0, 0, 0.41));
  margin-bottom: 0.63rem;
  margin-right: 0.19rem;

  > :first-child {
    border-top: 1px solid ${colorTheme.restaurant.splitModal.table.borderProducts};
  }

  button > svg {
    fill: none;
  }

  @media screen and (max-width: ${theme.point820}) {
    height: 25vh;
  }

  @media screen and (max-width: ${theme.point720}) {
    height: auto;
		overflow: visible;
    margin: 0;
    padding: 0;
    border-radius: 0 0 0.75rem 0.75rem;

    //&.split {
    //  height: calc(32vh - 10.7rem);
    //}
  }
`
TableOrdersProducts.displayName = 'TableOrdersProducts'

export const SplitGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.69rem;

  @media screen and (max-width: ${theme.point820}) {
    gap: 0.63rem;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
  }
`

export const SplitButtons = styled.div`
  display: flex;
  gap: 1.69rem;
	margin-top: 2em;
	justify-content: space-between;

`
