import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import updateOrderCustomer from '../../../axios/order/customer'

export const updateOrderCustomerThunk = (orderId, data) => async (dispatch) => {
	try {
		await updateOrderCustomer(orderId, data)
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}
