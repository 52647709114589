import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { InvoiceBillingAddressDescription, InvoiceBillingAddressSubtitle, InvoiceBillingAddressWrapper } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../buttons/Button'
import CustomModal from '../../../../modal/CustomModal'
import MainBillingAddressForm from './main'
import { formatAddress, removeEmptyFields } from '../../../../../../helper'
import { updateCustomerThunk } from '../../../../../../redux/thunk/customer'
import { updateInvoiceCustomerThunk } from '../../../../../../redux/thunk/invoice'
import { setCustomer } from '../../../../../../redux/action/customer'
import { setSelectedInvoice, setShowInvoiceBillingAddressModal, setShowInvoiceResendEmailInfoModal } from '../../../../../../redux/action/invoice'
import { convert2InvoiceCustomer } from './helper'

const EVENT_Z_INDEX = 82

const defaultValues = {
	phoneNumber: null, customerNumber: null, name: null, companyName: null, address: null, streetNumber: null,
	street: null, city: null, postalCode: null, customerAddress: null,
}

const InvoiceBillingAddressModal = React.memo(() => {
	const { t } = useTranslation()
	const { handleSubmit, register, formState: { errors }, reset, setValue, watch, setFocus } = useForm({ defaultValues })
	const dispatch = useDispatch()
	const showBillingAddressModal = useSelector(store => store.invoice.modal.showBillingAddress)
	const customer = useSelector(store => store.customerStore.customer)
	const geoData = useSelector(store => store.customerStore.geoData)
	const geoDataLoading = useSelector(store => store.customerStore.geoDataLoading)
	const selectedInvoice = useSelector(store => store.invoice.selectedInvoice)

	const handleReset = useCallback((values) => {
		reset({ ...defaultValues, ...values })
	}, [reset])

	useEffect(() => {
		if (customer) {
			handleReset({
				...customer,
				address: customer ? formatAddress(customer.street, customer.streetNumber, customer.postalCode, customer.city) : null,
				customerAddress: {
					city: customer.city || null,
					street: customer.street || null,
					house: customer.streetNumber || null,
					zipCode: customer.postalCode || null,
					latitude: geoData?.lat || null,
					longitude: geoData?.lon || null,
				},
			})
		}
	}, [customer]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleOpenResendEmailInfoModal = useCallback((data) => {
		const customerData = convert2InvoiceCustomer(data)
		dispatch(updateInvoiceCustomerThunk(selectedInvoice.id, customerData))
		dispatch(setShowInvoiceBillingAddressModal(false))
		dispatch(setShowInvoiceResendEmailInfoModal(true))
	}, [dispatch, selectedInvoice.id])

	const onSubmit = useCallback(async (data) => {
		let dataValue = {
			...data,
			address: formatAddress(data.customerAddress.street, data.customerAddress.house, data.customerAddress.zipCode, data.customerAddress.city),
			city: data.customerAddress.city,
			street: data.customerAddress.street,
			postalCode: data.customerAddress.zipCode,
			streetNumber: data.customerAddress.house,
			geoData: {
				lat: data.customerAddress.latitude,
				lon: data.customerAddress.longitude,
				distance: geoData?.distance, // if distance was calculated
				duration: geoData?.duration,
				image: geoData?.image,
			},
		}
		dataValue = removeEmptyFields(dataValue)
		await dispatch(updateCustomerThunk(dataValue))
		handleOpenResendEmailInfoModal(dataValue)
	}, [handleOpenResendEmailInfoModal, geoData, dispatch])

	const handleClose = useCallback(() => {
		dispatch(setShowInvoiceBillingAddressModal(false))
		handleReset(defaultValues)
		dispatch(setCustomer(null))
		dispatch(setSelectedInvoice(null))
	}, [dispatch, handleReset])

	return (
		<CustomModal isOpen={showBillingAddressModal && customer}
								 close={handleClose}
								 title={t('invoices.modal.billingAddress.title')}
								 size="x-small"
								 id="invoice-billing-address-modal"
								 zIndex={EVENT_Z_INDEX}
								 disableBackButton={true}
								 button={
									 <>
										 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')}
														 keyButton="F3" zIndex={EVENT_Z_INDEX}
														 onClick={handleClose}
														 className="back" />
										 <Button name="save" color="green" icon="save" text={t('buttons.controls.save')}
														 keyButton="F2" zIndex={EVENT_Z_INDEX}
														 onClick={handleSubmit(onSubmit)}
														 disabled={geoDataLoading} />
									 </>
								 }>
			<InvoiceBillingAddressWrapper>
				<InvoiceBillingAddressSubtitle>{t('invoices.modal.billingAddress.subtitle')}</InvoiceBillingAddressSubtitle>
				<MainBillingAddressForm {...{ handleSubmit, onSubmit, register, errors, setValue, setFocus, watch, zIndex: EVENT_Z_INDEX }} />
				<InvoiceBillingAddressDescription>{'*' + t('invoices.modal.billingAddress.description')}</InvoiceBillingAddressDescription>
			</InvoiceBillingAddressWrapper>
		</CustomModal>
	)
})

InvoiceBillingAddressModal.displayName = 'InvoiceBillingAddressModal'
export default InvoiceBillingAddressModal
