import React from 'react'

const SelectOptions = ({ options, labelBy, valueBy, indexValue = false, defaultOption }) => {
	return (
		<>
			{defaultOption &&
				<option key={-1} value="" className="defaultOption">{defaultOption}</option>}
			{options?.map((item, i) => <option key={i} data-name={item[labelBy]} value={indexValue ? i : item[valueBy]} disabled={item.disabled}>{item[labelBy]}</option>)}
		</>
	)
}

SelectOptions.displayName = 'SelectOptions'
export default SelectOptions
