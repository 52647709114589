import React from 'react'

export const Table10ChairsSvg = ({ chairCount, className }) => {
	const chairClassname = (chairNumber) => {
		return chairNumber <= chairCount ? '' : 'hidden'
	}
	return (
		<svg  viewBox="0 0 592 200" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g className={chairClassname(10)}>
				<path d="M3.8999 120.1V79.9004C3.8999 76.6004 6.5999 73.9004 9.8999 73.9004H50.0999C53.3999 73.9004 56.0999 76.6004 56.0999 79.9004V120.1C56.0999 123.4 53.3999 126.1 50.0999 126.1H9.8999C6.6999 126.1 3.8999 123.3 3.8999 120.1Z" fill="#7A7A7A" />
				<path d="M50.1 73.9C53.4 73.9 56.1 76.6 56.1 79.9V120.1C56.1 123.4 53.4 126.1 50.1 126.1H9.9C6.6 126.1 3.9 123.4 3.9 120.1V79.9C3.9 76.6 6.6 73.9 9.9 73.9H50.1ZM50.1 70H9.9C4.4 70 0 74.4 0 79.9V120.1C0 125.6 4.4 130 9.9 130H50.1C55.6 130 60 125.6 60 120.1V79.9C60 74.4 55.6 70 50.1 70Z" fill="white" />
			</g>
			<path d="M73.8999 190.1V149.9C73.8999 146.6 76.5999 143.9 79.8999 143.9H120.1C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.8999C76.6999 196.1 73.8999 193.3 73.8999 190.1Z" fill="#7A7A7A" />
			<path d="M120.1 143.9C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.9C76.6 196.1 73.9 193.4 73.9 190.1V149.9C73.9 146.6 76.6 143.9 79.9 143.9H120.1ZM120.1 140H79.9C74.4 140 70 144.4 70 149.9V190.1C70 195.6 74.4 200 79.9 200H120.1C125.6 200 130 195.6 130 190.1V149.9C130 144.4 125.6 140 120.1 140Z" fill="white" />
			<path d="M73.8999 50.1004V9.90039C73.8999 6.60039 76.5999 3.90039 79.8999 3.90039H120.1C123.4 3.90039 126.1 6.60039 126.1 9.90039V50.1004C126.1 53.4004 123.4 56.1004 120.1 56.1004H79.8999C76.6999 56.1004 73.8999 53.3004 73.8999 50.1004Z" fill="#7A7A7A" />
			<path d="M120.1 3.9C123.4 3.9 126.1 6.6 126.1 9.9V50.1C126.1 53.4 123.4 56.1 120.1 56.1H79.9C76.6 56.1 73.9 53.4 73.9 50.1V9.9C73.9 6.6 76.6 3.9 79.9 3.9H120.1ZM120.1 0H79.9C74.4 0 70 4.4 70 9.9V50.1C70 55.6 74.4 60 79.9 60H120.1C125.6 60 130 55.6 130 50.1V9.9C130 4.4 125.6 0 120.1 0Z" fill="white" />
			<path d="M204.9 50.1004V9.90039C204.9 6.60039 207.6 3.90039 210.9 3.90039H251.1C254.4 3.90039 257.1 6.60039 257.1 9.90039V50.1004C257.1 53.4004 254.4 56.1004 251.1 56.1004H210.9C207.7 56.1004 204.9 53.3004 204.9 50.1004Z" fill="#7A7A7A" />
			<path d="M251.1 3.9C254.4 3.9 257.1 6.6 257.1 9.9V50.1C257.1 53.4 254.4 56.1 251.1 56.1H210.9C207.6 56.1 204.9 53.4 204.9 50.1V9.9C204.9 6.6 207.6 3.9 210.9 3.9H251.1ZM251.1 0H210.9C205.4 0 201 4.4 201 9.9V50.1C201 55.6 205.4 60 210.9 60H251.1C256.6 60 261 55.6 261 50.1V9.9C261 4.4 256.6 0 251.1 0Z" fill="white" />
			<path d="M204.9 190.1V149.9C204.9 146.6 207.6 143.9 210.9 143.9H251.1C254.4 143.9 257.1 146.6 257.1 149.9V190.1C257.1 193.4 254.4 196.1 251.1 196.1H210.9C207.7 196.1 204.9 193.3 204.9 190.1Z" fill="#7A7A7A" />
			<path d="M251.1 143.9C254.4 143.9 257.1 146.6 257.1 149.9V190.1C257.1 193.4 254.4 196.1 251.1 196.1H210.9C207.6 196.1 204.9 193.4 204.9 190.1V149.9C204.9 146.6 207.6 143.9 210.9 143.9H251.1ZM251.1 140H210.9C205.4 140 201 144.4 201 149.9V190.1C201 195.6 205.4 200 210.9 200H251.1C256.6 200 261 195.6 261 190.1V149.9C261 144.4 256.6 140 251.1 140Z" fill="white" />
			<path d="M334.9 50.1004V9.90039C334.9 6.60039 337.6 3.90039 340.9 3.90039H381.1C384.4 3.90039 387.1 6.60039 387.1 9.90039V50.1004C387.1 53.4004 384.4 56.1004 381.1 56.1004H340.9C337.7 56.1004 334.9 53.3004 334.9 50.1004Z" fill="#7A7A7A" />
			<path d="M381.1 3.9C384.4 3.9 387.1 6.6 387.1 9.9V50.1C387.1 53.4 384.4 56.1 381.1 56.1H340.9C337.6 56.1 334.9 53.4 334.9 50.1V9.9C334.9 6.6 337.6 3.9 340.9 3.9H381.1ZM381.1 0H340.9C335.4 0 331 4.4 331 9.9V50.1C331 55.6 335.4 60 340.9 60H381.1C386.6 60 391 55.6 391 50.1V9.9C391 4.4 386.6 0 381.1 0Z" fill="white" />
			<path d="M334.9 190.1V149.9C334.9 146.6 337.6 143.9 340.9 143.9H381.1C384.4 143.9 387.1 146.6 387.1 149.9V190.1C387.1 193.4 384.4 196.1 381.1 196.1H340.9C337.7 196.1 334.9 193.3 334.9 190.1Z" fill="#7A7A7A" />
			<path d="M381.1 143.9C384.4 143.9 387.1 146.6 387.1 149.9V190.1C387.1 193.4 384.4 196.1 381.1 196.1H340.9C337.6 196.1 334.9 193.4 334.9 190.1V149.9C334.9 146.6 337.6 143.9 340.9 143.9H381.1ZM381.1 140H340.9C335.4 140 331 144.4 331 149.9V190.1C331 195.6 335.4 200 340.9 200H381.1C386.6 200 391 195.6 391 190.1V149.9C391 144.4 386.6 140 381.1 140Z" fill="white" />
			<path d="M588.1 120.1V79.9004C588.1 76.6004 585.4 73.9004 582.1 73.9004H541.9C538.6 73.9004 535.9 76.6004 535.9 79.9004V120.1C535.9 123.4 538.6 126.1 541.9 126.1H582.1C585.3 126.1 588.1 123.3 588.1 120.1Z" fill="#7A7A7A" />
			<path d="M541.9 73.9C538.6 73.9 535.9 76.6 535.9 79.9V120.1C535.9 123.4 538.6 126.1 541.9 126.1H582.1C585.4 126.1 588.1 123.4 588.1 120.1V79.9C588.1 76.6 585.4 73.9 582.1 73.9H541.9ZM541.9 70H582.1C587.6 70 592 74.4 592 79.9V120.1C592 125.6 587.6 130 582.1 130H541.9C536.4 130 532 125.6 532 120.1V79.9C532 74.4 536.4 70 541.9 70Z" fill="white" />
			<path d="M518.1 190.1V149.9C518.1 146.6 515.4 143.9 512.1 143.9H471.9C468.6 143.9 465.9 146.6 465.9 149.9V190.1C465.9 193.4 468.6 196.1 471.9 196.1H512.1C515.3 196.1 518.1 193.3 518.1 190.1Z" fill="#7A7A7A" />
			<path d="M471.9 143.9C468.6 143.9 465.9 146.6 465.9 149.9V190.1C465.9 193.4 468.6 196.1 471.9 196.1H512.1C515.4 196.1 518.1 193.4 518.1 190.1V149.9C518.1 146.6 515.4 143.9 512.1 143.9H471.9ZM471.9 140H512.1C517.6 140 522 144.4 522 149.9V190.1C522 195.6 517.6 200 512.1 200H471.9C466.4 200 462 195.6 462 190.1V149.9C462 144.4 466.4 140 471.9 140Z" fill="white" />
			<path d="M518.1 50.1004V9.90039C518.1 6.60039 515.4 3.90039 512.1 3.90039H471.9C468.6 3.90039 465.9 6.60039 465.9 9.90039V50.1004C465.9 53.4004 468.6 56.1004 471.9 56.1004H512.1C515.3 56.1004 518.1 53.3004 518.1 50.1004Z" fill="#7A7A7A" />
			<path d="M471.9 3.9C468.6 3.9 465.9 6.6 465.9 9.9V50.1C465.9 53.4 468.6 56.1 471.9 56.1H512.1C515.4 56.1 518.1 53.4 518.1 50.1V9.9C518.1 6.6 515.4 3.9 512.1 3.9H471.9ZM471.9 0H512.1C517.6 0 522 4.4 522 9.9V50.1C522 55.6 517.6 60 512.1 60H471.9C466.4 60 462 55.6 462 50.1V9.9C462 4.4 466.4 0 471.9 0Z" fill="white" />
			<path d="M557.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.6001 176 34.1001 176H558C563.5 176 568 171.5 568 166V34C568 28.5 563.4 24 557.9 24Z" fill="#7A7A7A" />
			<path d="M557.9 20H34.1C26.3 20 20 26.3 20 34V166C20 173.7 26.3 180 34.1 180H558C565.8 180 572.1 173.7 572.1 166V34C572 26.3 565.7 20 557.9 20ZM568 166C568 171.5 563.5 176 558 176H34.1C28.6 176 24.1 171.5 24.1 166V34C24.1 28.5 28.6 24 34.1 24H558C563.5 24 568 28.5 568 34V166Z" fill="white" />
			<path className="colorTable" d="M557.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.6001 176 34.1001 176H558C563.5 176 568 171.5 568 166V34C568 28.5 563.4 24 557.9 24ZM557.3 165.3H34.7001V34.7H557.2V165.3H557.3Z" fill="#38CD90" />
		</svg>

	)
}
Table10ChairsSvg.displayName = 'Table10ChairsSvg'
