import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const AddressSearchWrapper = styled.div`
  font-size: 1rem;
  display: grid;
  gap: 0.63em;
  grid-template-columns: 1fr;

	@media screen and (max-width: ${theme.point820}) {
		gap: 0.72em;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;
	}

  &.two {
    grid-template-columns: 1.5fr 0.8fr;

    @media screen and (max-width: ${theme.point720}) {
      gap: 0.31em;
    }
  }

	&.gap {
		gap: 1.25em;
	}

	&.cols-2 {
		grid-template-columns: 1.5fr 0.8fr;

		@media screen and (max-width: ${theme.point820}) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media screen and (max-width: ${theme.point720}) {
			grid-template-columns: 1fr;
			gap: 1.25em;
		}
	}
`

AddressSearchWrapper.dispalName = 'AddressSearchWrapper'
