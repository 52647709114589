import React from 'react'
import MainIcon from '../../../../../img/svg/main'
import { statusOptionsStatic } from '../../../../../redux/reducer/order/constants'
import { TextBig } from '../../OrdersTable/index.styled'
import { getNeededDateFormat } from '../../../../../helper'
import { useTranslation } from 'react-i18next'
import { PreOrderWrapper } from './index.styled'
import { TableTdGrid } from '../../style/table/td/index.styled'

const OrderStatus = React.memo(({ item }) => {
	const { t } = useTranslation()
	const isDeliveryByPlatform = item.orderType === 'DELIVERY_BY_PLATFORM'

	return (
		<>
			<TableTdGrid>
				<MainIcon icon={item?.status?.status} />
				<TextBig>{t(statusOptionsStatic.filter(status => status.value === item.status?.status).map(filtered => filtered.label))}</TextBig>
			</TableTdGrid>
			{item.preOrder && !['COMPLETED', 'CANCELED'].includes(item.status.status) && !isDeliveryByPlatform &&
				<PreOrderWrapper>
					<MainIcon icon="preorder" />
					<div>{getNeededDateFormat(item.preOrder, 'dd.MM.yyyy')}</div>
					<div>{getNeededDateFormat(item.preOrder, 'HH:mm')}</div>
				</PreOrderWrapper>}
		</>
	)
})

OrderStatus.displayName = 'OrderStatus'
export default OrderStatus
