import { ADD_CASH_DESK_ITEM, ADD_CASH_DESK_REMARK_TO_ITEM, ADD_CASH_DESK_SUBITEM, REMOVE_CASH_DESK_COUPON, REMOVE_CASH_DESK_ITEM, REMOVE_CASH_DESK_REMARK, REMOVE_CASH_DESK_SUBITEM, SET_CASH_DESK_CANCEL_REASON, SET_CASH_DESK_GIFT_COUPONS, SET_CASH_DESK_ITEM_FOCUS_INDEX, SET_CASH_DESK_ITEMS, SET_CASH_DESK_MODAL_CHANGE_CALCULATOR_IS_NEEDED, SET_CASH_DESK_ORDER, SET_CASH_DESK_ORDER_SHOW_PAYMENT_METHOD, SET_CASH_DESK_ORDER_TYPE, SET_CASH_DESK_PREORDER_DATE, SET_CASH_DESK_SHOW_CANCEL_ORDER, SET_CASH_DESK_SHOW_ORDER, SET_CASH_DESK_SHOW_ORDER_DATA, SET_CASH_DESK_SHOW_ORDERS_TABLE, UPDATE_CASH_DESK_ITEM } from '../../actionTypes/cashDesk'

export const setCashDeskOrderType = value => ({ type: SET_CASH_DESK_ORDER_TYPE, value })

export const setCashDeskItems = value => ({ type: SET_CASH_DESK_ITEMS, value })

export const addCashDeskItem = value => ({ type: ADD_CASH_DESK_ITEM, value })

export const addCashDeskSubItem = value => ({ type: ADD_CASH_DESK_SUBITEM, value })

export const removeCashDeskItem = value => ({ type: REMOVE_CASH_DESK_ITEM, value })

export const removeCashDeskSubItem = value => ({ type: REMOVE_CASH_DESK_SUBITEM, value })

export const updateCashDeskItem = value => ({ type: UPDATE_CASH_DESK_ITEM, value })

export const setCashDeskItemFocusIndex = value => ({ type: SET_CASH_DESK_ITEM_FOCUS_INDEX, value })

export const setCashDeskPreorderDate = value => ({ type: SET_CASH_DESK_PREORDER_DATE, value })

export const setCashDeskOrder = value => ({ type: SET_CASH_DESK_ORDER, value })

export const removeCashDeskRemark = value => ({ type: REMOVE_CASH_DESK_REMARK, value })

export const addCashDeskRemarkToItem = value => ({ type: ADD_CASH_DESK_REMARK_TO_ITEM, value })

export const setCashDeskModalChangeCalculatorIsNeeded = value => ({ type: SET_CASH_DESK_MODAL_CHANGE_CALCULATOR_IS_NEEDED, value })

export const removeCashDeskCoupon = () => ({ type: REMOVE_CASH_DESK_COUPON })
export const setCashDeskShowOrder = value => ({ type: SET_CASH_DESK_SHOW_ORDER, value })
export const setCashDeskShowOrderData = value => ({ type: SET_CASH_DESK_SHOW_ORDER_DATA, value })
export const setCashDeskShowCancelOrder = value => ({ type: SET_CASH_DESK_SHOW_CANCEL_ORDER, value })
export const setCashDeskShowPaymentMethod = value => ({ type: SET_CASH_DESK_ORDER_SHOW_PAYMENT_METHOD, value })
export const setCashDeskCanselReason = value => ({ type: SET_CASH_DESK_CANCEL_REASON, value })
export const setCashDeskShowOrdersTable = value => ({ type: SET_CASH_DESK_SHOW_ORDERS_TABLE, value })
export const setCashDeskGiftCoupons = value => ({ type: SET_CASH_DESK_GIFT_COUPONS, value })
