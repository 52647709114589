import React from 'react'

const InvoiceNumber = React.memo(({ item }) => {

	return (
		<>
			<div >{item.invoiceNumber}</div>
		</>
	)
})

InvoiceNumber.displayName = 'InvoiceNumber'
export default InvoiceNumber
