import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const MenuItemWrap = styled.div`
  font-size: 1rem;
	display: grid;
	min-height: 2.87em;
	grid-template-columns: 8.12em auto;
	gap: 0.25em;

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: 1fr;
		gap: 0.125em;
	}
`
MenuItemWrap.displayName = 'MenuItemWrap'

export const MenuItemContent = styled.div`
  font-size: 1rem;
  background: ${colorTheme.cart.productSearch.menuSelection.menuItem.background};
  border: 1px solid ${colorTheme.cart.productSearch.menuSelection.menuItem.border};
  gap: 0 0.31em;
  padding: 0.43em 0.5em 0.43em 0.56em;
  display: flex;
  align-items: center;
	border-radius: 0 0.75em 0.75em 0;

  text-align: left;
  cursor: pointer;

  &.focused {
		background: ${colorTheme.cart.productSearch.menuSelection.menuItem.backgroundContentFocused};
		border: 1px solid ${colorTheme.cart.productSearch.menuSelection.menuItem.borderFocused};
    box-shadow: 0.02em 0.25em 1.69em 0 rgba(0, 0, 0, 0.22);
  }

	@media screen and (max-width: ${theme.point820}) {
		border-radius: 0 0 0.75em 0.75em;
		padding: 0.43em 0.5em 0.43em 0.56em;
	}
`
MenuItemContent.displayName = 'MenuItemContent'

export const MenuItemProduct = styled.div`
  font-size: 0.82em;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid ${colorTheme.cart.productSearch.menuSelection.menuItem.borderFocused};
	border-radius: 0.92em 0 0 0.92em;
	text-align: center;
	color: ${colorTheme.cart.productSearch.menuSelection.menuItem.text};
	padding: 0.46em 0.76em;
	font-family: ${theme.fontRobotoBold};

	&.focused {
		color: ${colorTheme.cart.productSearch.menuSelection.menuItem.textFocused};
		background: ${colorTheme.cart.productSearch.menuSelection.menuItem.backgroundFocused};
    box-shadow: 0.02em 0.25em 1.69em 0 rgba(0, 0, 0, 0.22);
  }

	@media screen and (max-width: ${theme.point820}) {
		font-size: 0.94em;
		border-radius: 0.8em 0.8em 0 0;
		min-height: 3.07em;
	}
`
MenuItemProduct.displayName = 'MenuItemProduct'

export const MenuItemName = styled.div`
  font-family: ${theme.fontRobotoMedium};
  font-size: 1em;
  font-weight: 500;
  color: ${colorTheme.cart.productSearch.menuSelection.menuItem.itemName};
  display: flex;
  align-items: center;

  > svg {
    width: 0.94em;
    margin-right: 0.61em;
    flex-grow: 1;
    flex-shrink: 0;
  }
`
MenuItemName.displayName = 'MenuItemName'

export const MenuItemToppings = styled.div`
  display: flex;
  align-items: center;
  gap: 0.31em;
  flex-wrap: wrap;
`
MenuItemToppings.displayName = 'MenuItemToppings'

export const MenuItemTopping = styled.div`
  border-radius: 0.71em;
  border: 1px solid ${colorTheme.cart.productSearch.menuSelection.menuItem.toppingBorder};
  padding: 0.25em 0.42em 0.25em 0.42em;
  font-family: ${theme.fontRobotoRegular};
  font-size: 0.75em;
  line-height: 0.75em;
  color: ${colorTheme.cart.productSearch.menuSelection.menuItem.toppingText};
  background-color: ${colorTheme.cart.productSearch.menuSelection.menuItem.backgroundText};
  text-transform: uppercase;
	min-height: 1.67em;
	display: flex;
	align-items: center;
`
MenuItemTopping.displayName = 'MenuItemTopping'

export const MenuItemClose = styled.div`
  width: 1.87em;
  height: 1.87em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  background: ${colorTheme.cart.productSearch.menuSelection.menuItem.iconClose};
  flex-shrink: 0;
  margin-left: auto;
	align-self: start;

  > svg {
    width: 0.88em;
    height: 0.88em;
  }
`
MenuItemClose.displayName = 'MenuItemClose'

export const MenuItemCheck = styled.div`
	display: flex;
	align-items: center;

  > svg {
    width: 1.25em;
    height: 1.25em;
		fill: ${colorTheme.cart.productSearch.menuSelection.menuItem.iconCheck};
  }
`
MenuItemCheck.displayName = 'MenuItemCheck'

export const MenuItemContentWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.31em;
`
MenuItemContentWrap.displayName = 'MenuItemContentWrap'
