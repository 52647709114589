import styled from 'styled-components'
import { theme } from '../../style/theme'
import { colorTheme } from '../../style/theme/colorTheme'

export const ProfileWrapper = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
`
ProfileWrapper.displayName = 'ProfileWrapper'

export const ProfileInfo = styled.div`
	position: relative;
`
ProfileInfo.displayName = 'ProfileInfo'

export const ProfileUser = styled.div`
  display: flex;
  align-items: center;
  height: 2.5em;
  position: relative;
  cursor: pointer;

  div {
    margin-right: 0.56em;
  }

  .avatar {
    width: 1.9em;
    height: 1.9em;
    padding-top: 0.1em;
    background-color: ${props => props.color};
    color: ${colorTheme.profile.avatar};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.38em;
    font-family: ${theme.fontRobotoMedium};
    text-transform: uppercase;
  }

  .name {
    color: ${colorTheme.profile.name};
    font-size: 0.9em;
    font-family: ${theme.fontRobotoMedium};
    text-transform: uppercase;
  }

  .index {
    color: ${colorTheme.profile.index};
    font-size: 1.2em;
    font-family: ${theme.fontRobotoMedium};
    text-transform: uppercase;
  }

  .profile_btn {
    transform: rotate(0deg);
    transition: transform .2s;
    margin-top: -0.19em;

    &.active {
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: ${theme.point820}) {
    word-break: break-all;

		div {
			margin-right: 0.71em;
		}

    .avatar {
			font-size: 1.57em;
      flex-shrink: 0;
			width: 1.95em;
    	height: 1.95em;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
		div {
			margin-right: 0.63em;
		}

    .avatar {
			font-size: 1.38em;
    }
  }
`
ProfileUser.displayName = 'ProfileUser'

export const ProfileCompany = styled.ul`
  position: absolute;
  top: 3.2em;
  right: -1em;
  width: 19.19em;
  padding: 1.25em 1.88em 1.88em;
  border-radius: 0.75em;
  box-shadow: 0.02em 0.25em 5.44em 0 rgba(0, 0, 0, 0.53);
  background-color: ${colorTheme.profile.background};
	border: 1px solid ${colorTheme.profile.border};
	z-index: 999;
  max-height: calc(100vh - 5.13em);
  overflow: auto;
	display: grid;
	gap: 1em;

  button.logout {
    width: 100%;
  }
`
ProfileCompany.displayName = 'ProfileCompany'

export const ProfileCompanyItem = styled.li`
  height: 2.88em;
  display: flex;
  align-items: center;

  div {
    width: 1.63em;
    height: 1.56em;
    display: flex;
    align-items: center;

    svg {
      width: 100%;
      max-height: 100%;
			fill: ${colorTheme.profile.icon};
    }
  }

	span {
		color: ${colorTheme.profile.dealerTitle};
	}
`
ProfileCompanyItem.displayName = 'ProfileCompanyItem'

export const QrCodeWrapper = styled.div`
  background: ${colorTheme.profile.qrCode.background};
  border-radius: 0.75em;
  padding: 1.06em 1.44em 1.44em;
`

export const QrCodeImg = styled.div`
  margin-top: 1.13em;

  > img {
    width: 100%;
  }
`

export const QrCodeText = styled.div`
  font-family: ${theme.fontRobotoBold};
  font-size: 1em;
  font-weight: bold;
  line-height: 1.19em;
  text-transform: uppercase;
  color: ${colorTheme.profile.qrCode.text};
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    margin-right: 0.63em;
  }
`
export const ProfileCompanyText = styled.p`
	margin-left: 0.88em;
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.06em;
	font-weight: 500;
	word-break: break-all;
	color: ${colorTheme.profile.companyText};
`
ProfileCompanyText.displayName = 'ProfileCompanyText'
