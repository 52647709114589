import styled from 'styled-components'
import colors from '../../../../style/colors'
import { theme } from '../../../../style/theme'
export const NotificationModalBtnWrapper = styled.button`
	font-size: 1rem;
	width: max-content;
	min-height: 3.63em;
	background-color: #FF5821;
	padding: .3em 1em;
	border-radius: .75em;
	text-transform: uppercase;
	border: 2px solid transparent;
	transition: all .2s  ease-in-out;
	cursor: pointer;

	&.active {
		background-color: ${colors.white};
		border-color: #FF5821;
	}
`
NotificationModalBtnWrapper.displayName = 'NotificationModalBtnWrapper'

export const NotificationModalBtnText = styled.div`
	font-size: 1.38em;
	font-family: ${theme.fontRobotoMedium};
	color: ${colors.white};
	font-weight: 500;
	transition: color .2s  ease-in-out;

	&.active {
		color: #060606;
	}
`
NotificationModalBtnText.displayName = 'NotificationModalBtnText'

export const NotificationModalBtnWrap = styled.div`
	display: flex;
	gap: 2.5em;
	margin-top: 1.88em;
	max-width: 25em;
`
NotificationModalBtnWrap.displayName = 'NotificationModalBtnWrap'
