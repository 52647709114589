import { subDays, subMonths } from 'date-fns'
import { APPEND_REPORTS, SET_DATE_FROM, SET_DATE_TO, SET_MONTH_FROM, SET_MONTH_TO, SET_REPORT_TOTAL_LENGTH, SET_REPORTS, SET_SELECTED_STRIPE_ACCOUNT_ID, SET_STRIPE_REPORT_PRINT_MESSAGE_DATA, SET_STRIPE_REPORT_TO_PRINT, SET_STRIPE_REPORTS_DATA } from '../../actionTypes/report'

const initialState = {
	reports: [],
	dateFrom: subDays(new Date(), 7),
	dateTo: new Date(),
	monthFrom: subMonths(new Date(), 6),
	monthTo: new Date(),
	reportsTotalLength: 0,
	stripeReportsData: null,
	stripeReportToPrint: null,
	stripeReportPrintMessageData: null,
	selectedStripeAccountId: null,
}

export function reportReducer (state = initialState, action) {
	switch (action.type) {
		case SET_REPORTS:
			return {
				...state,
				reports: action.reports,
			}
		case SET_REPORT_TOTAL_LENGTH:
			return {
				...state,
				reportsTotalLength: action.length,
			}
		case APPEND_REPORTS:
			return {
				...state,
				reports: state.reports.concat(...action.reports),
			}
		case SET_DATE_TO:
			return {
				...state,
				dateTo: action.dateTo,
			}
		case SET_DATE_FROM:
			return {
				...state,
				dateFrom: action.dateFrom,
			}
		case SET_MONTH_TO:
			return {
				...state,
				monthTo: action.monthTo,
			}
		case SET_MONTH_FROM:
			return {
				...state,
				monthFrom: action.monthFrom,
			}
		case SET_STRIPE_REPORTS_DATA:
			return {
				...state,
				stripeReportsData: action.value,
			}
		case SET_STRIPE_REPORT_TO_PRINT:
			return {
				...state,
				stripeReportToPrint: action.value,
			}
		case SET_STRIPE_REPORT_PRINT_MESSAGE_DATA:
			return {
				...state,
				stripeReportPrintMessageData: action.value,
			}
		case SET_SELECTED_STRIPE_ACCOUNT_ID:
			return {
				...state,
				selectedStripeAccountId: action.value,
			}
		default:
			return state
	}
}


