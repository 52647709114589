import styled from 'styled-components'
import colors from '../../../../../style/colors'
import { theme } from '../../../../../style/theme'

export const CartMenuWrapper = styled.div`
  > div, > div:last-child {
    padding-left: 0;
    padding-right: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - 3.75em);
      height: 1px;
      background-color: ${colors.gray_light};
    }
  }

  &.touchPad {
    > div:before {
      display: none;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    > div.touchPad, > div:last-child {
      padding-left: 0;
      padding-right: 0;
    }
  }
`
CartMenuWrapper.displayName = 'CartMenuWrapper'
