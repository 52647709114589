import keyboardImg from '../../../../../img/svg/input/keyboard.svg'
import { InputLabel, InputWrapper } from '../index.styled'
import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Keyboard from '../../../keyboard'

const TextInputWithKeyboard = React.memo(({
	name, value, onChange, placeholder, disabled,
	onBlur = () => {
	},
	onFocus = () => {
	},
}) => {
	const [showKeyboard, setShowKeyboard] = useState(false)
	const [focused, setFocused] = useState(false)
	const domElement = document.getElementById('content')
	const layout = (focused ? 'focused ' : '') + (disabled ? 'disabled ' : '')

	useEffect(() => {
		if (!showKeyboard) {
			handleBlur()
		}
	}, [showKeyboard]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleOnChange = useCallback((event) => {
		const value = event.target.value
		onChange(value)
	}, [onChange])

	const handleOnFocus = useCallback(() => {
		setFocused(true)
		onFocus(name)
	}, [onFocus, name])

	const handleBlur = useCallback(() => {
		if (showKeyboard) return
		setFocused(false)
		onBlur()
	}, [onBlur, showKeyboard])

	const toggleKeyboard = useCallback(() => {
		setShowKeyboard(prevState => !prevState)
	}, [])

	const handleKeyboardType = useCallback((textCallbackKay) => {
		onChange(textCallbackKay)
	}, [onChange])

	const closeKeyboard = useCallback(() => {
		setShowKeyboard(false)
	}, [])

	const handleEnter = useCallback(() => {
		setShowKeyboard(false)
	}, [])

	return (
		<>
			<InputWrapper>
				<InputLabel className={layout}>
					<input name={name}
								 onChange={handleOnChange}
								 onBlur={handleBlur}
								 onFocus={handleOnFocus}
								 value={value}
								 placeholder={placeholder}
								 autoComplete="off"
								 disabled={disabled}
								 type="text"
								 role="presentation"
								 inputMode="tel" />
					{!disabled &&
						<img src={`${keyboardImg}`} alt="keyboard" onClick={toggleKeyboard} />}
				</InputLabel>
			</InputWrapper>

			{showKeyboard && ReactDOM.createPortal(
				<Keyboard onlyNumbers={false}
									handleType={handleKeyboardType}
									currentValue={value}
									close={closeKeyboard}
									enter={handleEnter} />, domElement)}
		</>
	)
})

TextInputWithKeyboard.displayName = 'TextInputWithKeyboard'
export default TextInputWithKeyboard


