const DesktopMode = {
	CASH_REGISTER: 'CASH_REGISTER',
	KIOSK: 'KIOSK',
}

export const isCashRegisterMode = (desktopMode) => {
	return desktopMode === DesktopMode.CASH_REGISTER
}

export const isLocalStorageCashRegisterMode = () => {
	return window.localStorage.desktopMode === DesktopMode.CASH_REGISTER
}
