export const START_WORKING_HOUR = 7
export const MIN_WORKING_MINUTES = 10
export const ERROR_BEFORE_BREAK = 'ERROR_BEFORE_BREAK'
export const ERROR_AFTER_BREAK = 'ERROR_AFTER_BREAK'
export const DEFAULT_TIME_BEFORE_BREAK = { openingTime: '07:00', closingTime: '10:00' }
export const DEFAULT_TIME_AFTER_BREAK = { openingTime: '11:00', closingTime: '22:30' }

export const DEFAULT_DAYS = [
	{ day: 'MONDAY', weekend: false, lunchBreak: false, periods: [DEFAULT_TIME_AFTER_BREAK] },
	{ day: 'TUESDAY', weekend: false, lunchBreak: false, periods: [DEFAULT_TIME_AFTER_BREAK] },
	{ day: 'WEDNESDAY', weekend: false, lunchBreak: false, periods: [DEFAULT_TIME_AFTER_BREAK] },
	{ day: 'THURSDAY', weekend: false, lunchBreak: false, periods: [DEFAULT_TIME_AFTER_BREAK] },
	{ day: 'FRIDAY', weekend: false, lunchBreak: false, periods: [DEFAULT_TIME_AFTER_BREAK] },
	{ day: 'SATURDAY', weekend: false, lunchBreak: false, periods: [DEFAULT_TIME_AFTER_BREAK] },
	{ day: 'SUNDAY', weekend: false, lunchBreak: false, periods: [DEFAULT_TIME_AFTER_BREAK] }
]

export const DEFAULT_TIME_PREORDER_DELAY = { hours: '00', minutes: '30' }

export const DEFAULT_PREORDER_DISABLED = { workingHours: false, nonWorkingHours: false }

