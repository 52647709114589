import React from 'react'
import { NewBlockContent, NewBlockText, NewBlockWrap } from '../index.styled'
import TableTdElementsTooltip from '../../TableTdElementsTooltip'
import { useTranslation } from 'react-i18next'

const NewBlock = React.memo(() => {
	const { t } = useTranslation()
	return (
		<TableTdElementsTooltip text={t('order.table.tooltip.new')}>
			<NewBlockWrap>
				<NewBlockContent>
					<NewBlockText>NEW</NewBlockText>
				</NewBlockContent>
			</NewBlockWrap>
		</TableTdElementsTooltip>
	)
})


NewBlock.displayName = 'NewBlock'
export default NewBlock
