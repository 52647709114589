import React, { useCallback } from 'react'
import { DishCourseButtonText, DishCourseButtonWrap } from './index.styled'
import { COLORS } from '../../../../../../RestaurantCartSettings/DishCoursesSettings/constants'
import ButtonIcon from '../../../../../../../img/svg/button'

const DishCourseButton = React.memo(({ index, button, isActive, onClick }) => {

	const handleClick = useCallback(() => {
		onClick( button, index)
	}, [onClick, button, index])

	return (
		<DishCourseButtonWrap tabIndex={index}
													data-testid={button.testId}
													onClick={handleClick}
													className={isActive ? 'active ' : ''}
													color={COLORS[index]}>
			<DishCourseButtonText>{button.name}</DishCourseButtonText>
			{isActive && <ButtonIcon icon="circle-checkmark" />}
		</DishCourseButtonWrap>
	)
})

DishCourseButton.displayName = 'DishCourseButton'
export default DishCourseButton
