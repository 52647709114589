import React from 'react'
import Header from '../Header'
import Button from '../Elements/buttons/Button'
import MainWrapper from '../MainWrapper'
import { useTranslation } from 'react-i18next'
import { SettingsWrapper } from '../Elements/settings/index.styled'
import SettingsTitle from '../Elements/settings/SettingsTitle'
import { useNavigate } from 'react-router-dom'
import CartTable from './table'

const DeliveryCartSettings = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const navigateBack = () => {
		navigate('/settings/delivery')
	}

	return (
		<>
			<Header>
				<Button name="back" size="small" icon="back" text={t('buttons.controls.back')} keyButton="Escape" keyText="esc" onClick={navigateBack} />
			</Header>
			<MainWrapper>
				<SettingsWrapper>
					<SettingsTitle icon="cart-settings">{t('Settings.CartSettings')}</SettingsTitle>
					<CartTable />
				</SettingsWrapper>
			</MainWrapper>
		</>
	)
}

DeliveryCartSettings.displayName = 'DeliveryCartSettings'
export default DeliveryCartSettings
