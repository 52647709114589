import React from 'react'
import { useDispatch } from 'react-redux'
import { WaiterCallAreaButton, WaiterCallAreaButtonContent, WaiterCallAreaButtonWaiter, WaiterCallAreaText } from './../index.styled'
import MainIcon from '../../../../img/svg/main'
import { confirmWaiterCallThunk } from '../../../../redux/thunk/restaurant/waiterCall'
import { useTranslation } from 'react-i18next'

const WaiterCallButton = ({ call }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const handleConfirmWaiterCall = () => {
		dispatch(confirmWaiterCallThunk(call?.tablePublicId))
	}

	return (
		<WaiterCallAreaButton onClick={handleConfirmWaiterCall}>
			<WaiterCallAreaButtonContent color={call?.areaColor}>
				<WaiterCallAreaText>{call?.areaName} {call?.tableNumber}</WaiterCallAreaText>
			</WaiterCallAreaButtonContent>
			<WaiterCallAreaButtonWaiter>
				<MainIcon icon="tableWaiter" />
				<WaiterCallAreaText className="waiter">{t('restaurant.waiterComing')}</WaiterCallAreaText>
			</WaiterCallAreaButtonWaiter>
		</WaiterCallAreaButton>
	)
}

WaiterCallButton.displayName = 'WaiterCallButton'
export default WaiterCallButton
