import { getFloat, roundPrice } from '../../../../../../helper'

export const calculateTotalDiscount = (discountValue, discountType, totalPrice) => {
	const discount = getFloat(discountValue)

	if (discountType === 'CURRENCY') {
		return discount
	} else {
		return roundPrice(totalPrice * discount / 100)
	}
}

export const convert2PresetDiscountOptions = (presetDiscounts) => {
	return presetDiscounts.map(({ id, name, discount }) => ({
		id,
		label: name + ' ' + discount + '%',
		type: 'PRESET_DISCOUNT'
	}))
}

export const convert2ExtraDiscountItem = (id, name, price) => ({
	itemId: id,
	name,
	value: price
})

export const convert2RestaurantCustomer = ({
	customerNumber = null, name = null, companyName = null,
	street = null, streetNumber = null, city = null, postalCode = null,
	phoneNumber = null, email = null
}) => {
	return ({
		customerNumber, name, companyName, phoneNumber, email,
		street, streetNumber, city, postalCode,
	})
}
