import styled from 'styled-components'
import { theme } from '../../../../../style/theme'

export const ProductsList = styled.ul`
	font-size: 1rem;
	filter: drop-shadow(0 18px 109px rgba(0, 0, 0, 0.15));

	&.touchPad {
		filter: none;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 0.63em;
	}

	> p {
		grid-row: 1;
		grid-column: 2 / 4;
		text-align: center;
	}

	@media screen and (max-width: ${theme.point1024}) {
		&.touchPad {
			grid-template-columns: 1fr 1fr 1fr;
			gap: 0.31em;
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		&.touchPad {
			grid-template-columns: 1fr 1fr;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.touchPad {
			grid-template-columns: 1fr 1fr 1fr;
			grid-auto-rows: 1fr;
		}
	}
`
ProductsList.displayName = 'ProductsList'

export const AllProductsLoader = styled.div`
	height: 2em;
	margin-top: 0.75em;

	&.touchPad {
		grid-column: 2 / 4;
		margin-bottom: 1.88em;
	}

	@media screen and (max-width: ${theme.point1024}) {
		&.touchPad {
			grid-column: 2;
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		&.touchPad {
			grid-column: 1 / 3;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.touchPad {
			grid-column: 2;
		}
	}
`
