import printerInstance from './instance'

const fetchPrinters = (page = 0, size = 10) => {
	return printerInstance.get(
		'/printers'
		+ '?page=' + page
		+ '&size=' + size
	)
}

export default fetchPrinters
