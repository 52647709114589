export const getOrdersThisDriver = (orders, driver) => orders.filter(order => order.processedBy === driver)


export const getLatestOrder = (orders) => {
	return orders?.reduce((latest, order) => {
		if (!latest || new Date(order.status.modifiedDate) > new Date(latest.status.modifiedDate)) {
			return order
		} else {
			return latest
		}
	}, null)
}
