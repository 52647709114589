import { retrieveErrorFromApi } from '../../../../helper'
import { setRestaurantTablesWaiterCalls } from '../../../action/restaurant'
import { setAppErrors } from '../../../action/system'
import fetchWaiterCalls from '../../../axios/restaurant/waiterCall/getAll'
import confirmWaiterCall from '../../../axios/restaurant/waiterCall/post'

export const fetchWaiterCallsThunk = () => dispatch => {
	return fetchWaiterCalls()
		.then(res => {
			dispatch(setRestaurantTablesWaiterCalls(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const confirmWaiterCallThunk = (publicId) => dispatch => {
	return confirmWaiterCall(publicId)
		.then(res => {
			return dispatch(fetchWaiterCallsThunk())
		})
		.catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}