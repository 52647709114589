import { ADD_CASHBOOK_NEW_TRANSACTION, APPEND_CASHBOOK_NEW_CASHBOOKS, SET_CASHBOOK_NEW_BALANCE_OF_OLD_CASHBOOK_WAS_ACCEPTED, SET_CASHBOOK_NEW_CASH_BALANCE, SET_CASHBOOK_NEW_CASH_TOTAL, SET_CASHBOOK_NEW_CASHBOOK, SET_CASHBOOK_NEW_CASHBOOK_ID, SET_CASHBOOK_NEW_CASHBOOKS, SET_CASHBOOK_NEW_CASHBOOKS_TOTAL_LENGTH, SET_CASHBOOK_NEW_CATEGORIES, SET_CASHBOOK_NEW_CATEGORY, SET_CASHBOOK_NEW_CATEGORY_ID, SET_CASHBOOK_NEW_DATE_FROM, SET_CASHBOOK_NEW_DATE_TO, SET_CASHBOOK_NEW_INITIAL_BALANCE_OLD_CASHBOOK, SET_CASHBOOK_NEW_LOADING, SET_CASHBOOK_NEW_LOADING_BALANCE, SET_CASHBOOK_NEW_LOADING_DATEV, SET_CASHBOOK_NEW_SHOW_FORM, SET_CASHBOOK_NEW_SHOW_FORM_CATEGORY, SET_CASHBOOK_NEW_SHOW_MODAL_NAVIGATE_TO_DATEV, SET_CASHBOOK_NEW_TRANSACTION_TYPE, UPDATE_CASHBOOK_NEW_TRANSACTION } from '../../actionTypes/cashbookNew'

const initialState = {
	showForm: false,
	categoryId: null,
	categories: [],
	dateFrom: null,
	dateTo: null,
	loading: false,
	cashBooks: [],
	cashBooksTotalLength: null,
	cashBookId: null,
	cashBook: null,
	initialBalanceOldCashBook: null,
	balanceOfOldCashBookWasAccepted: null,
	cashBalance: null,
	showFormCategory: false,
	transactionType: 'EXPENDITURE',
	category: null,
	loadingDateV: false,
	showModalNavigateToDateV: false,
	loadingCashBalance: false,
	cashTotal: null,
}

export function cashBookNewReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CASHBOOK_NEW_SHOW_FORM:
			return {
				...state,
				showForm: action.value
			}
		case SET_CASHBOOK_NEW_CATEGORY_ID:
			return {
				...state,
				categoryId: action.value
			}
		case SET_CASHBOOK_NEW_CATEGORIES:
			return {
				...state,
				categories: action.value
			}

		case SET_CASHBOOK_NEW_DATE_FROM:
			return {
				...state,
				dateFrom: action.value
			}
		case SET_CASHBOOK_NEW_DATE_TO:
			return {
				...state,
				dateTo: action.value
			}
		case SET_CASHBOOK_NEW_LOADING:
			return {
				...state,
				loading: action.value
			}
		case SET_CASHBOOK_NEW_CASHBOOKS_TOTAL_LENGTH:
			return {
				...state,
				cashBooksTotalLength: action.value
			}
		case SET_CASHBOOK_NEW_CASHBOOKS:
			return {
				...state,
				cashBooks: action.value
			}
		case APPEND_CASHBOOK_NEW_CASHBOOKS:
			return {
				...state,
				cashBooks: state.cashBooks.concat(...action.value)
			}
		case SET_CASHBOOK_NEW_CASHBOOK_ID:
			return {
				...state,
				cashBookId: action.value
			}
		case SET_CASHBOOK_NEW_CASHBOOK:
			return {
				...state,
				cashBook: action.value
			}
		case SET_CASHBOOK_NEW_INITIAL_BALANCE_OLD_CASHBOOK:
			return {
				...state,
				initialBalanceOldCashBook: action.value
			}
		case SET_CASHBOOK_NEW_BALANCE_OF_OLD_CASHBOOK_WAS_ACCEPTED:
			return {
				...state,
				balanceOfOldCashBookWasAccepted: action.value
			}
		case SET_CASHBOOK_NEW_CASH_BALANCE:
			return {
				...state,
				cashBalance: action.value
			}
		case SET_CASHBOOK_NEW_SHOW_FORM_CATEGORY:
			return {
				...state,
				showFormCategory: action.value
			}
		case SET_CASHBOOK_NEW_TRANSACTION_TYPE:
			return {
				...state,
				transactionType: action.value
			}
		case SET_CASHBOOK_NEW_CATEGORY:
			return {
				...state,
				category: action.value
			}
		case ADD_CASHBOOK_NEW_TRANSACTION:
			const cashBooksCopy = [...state.cashBooks]
			cashBooksCopy.unshift(action.value)
			return {
				...state,
				cashBooks: cashBooksCopy
			}
		case UPDATE_CASHBOOK_NEW_TRANSACTION:
			const cashBooksCopy1 = [...state.cashBooks]
			const cashBookIndex = cashBooksCopy1.findIndex(cashBook => cashBook.id === action.value.id)

			if (cashBookIndex !== -1) {
				cashBooksCopy1[cashBookIndex] = action.value
			}

			return {
				...state,
				cashBooks: cashBooksCopy1
			}
		case SET_CASHBOOK_NEW_LOADING_DATEV:
			return {
				...state,
				loadingDateV: action.value
			}
		case SET_CASHBOOK_NEW_SHOW_MODAL_NAVIGATE_TO_DATEV :
			return {
				...state,
				showModalNavigateToDateV: action.value
			}
		case SET_CASHBOOK_NEW_LOADING_BALANCE:
			return {
				...state,
				loadingCashBalance: action.value
			}
		case SET_CASHBOOK_NEW_CASH_TOTAL:
			return {
				...state,
				cashTotal: action.value
			}

		default:
			return state
	}
}


