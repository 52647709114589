export const positionUpdater = (groups, orderByAsc, payload) => {
	const categoryId = payload[0].category.id
	const filterByCategory = groups.filter(group => group.category.id === categoryId)
	const firstCategoryIndex = groups.indexOf(filterByCategory[0])

	payload.forEach(item => {
		const index = orderByAsc ? item.index - 1 : (filterByCategory.length - item.index)
		filterByCategory[index] = item
	})

	const orderedAdditivesGroups = [
		...groups.slice(0, firstCategoryIndex),
		...filterByCategory,
		...groups.slice(firstCategoryIndex + filterByCategory.length)
	]

	return orderedAdditivesGroups
}
