import { SET_CLIENT_MONITOR_CART_STATE, SET_CLIENT_MONITOR_QR_CODE_PAYMENT_SESSION, SHOW_CLIENT_MONITOR_FINAL_STEP } from '../../actionTypes/clientMonitorCart'

const initialState = {
	cartState: null,
	showFinalStep: false,
	qrCodePaymentSession: null
}

export function clientMonitorCartReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CLIENT_MONITOR_CART_STATE:
			return {
				...state,
				cartState: action.value
			}
		case SHOW_CLIENT_MONITOR_FINAL_STEP:
			return {
				...state,
				showFinalStep: action.value
			}
		case SET_CLIENT_MONITOR_QR_CODE_PAYMENT_SESSION:
			return {
				...state,
				qrCodePaymentSession: action.value
			}

		default:
			return state
	}
}
