import React from 'react'
import { setRestaurantPaymentTypeModal, setRestaurantSplitActionsModal } from '../../../../../redux/action/restaurant'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTableSplitThunk, printTableSplitThunk } from '../../../../../redux/thunk/restaurant/split'
import ButtonsModal from '../../../../Elements/modal/ButtonsModal'
import { useTranslation } from 'react-i18next'
import { selectProcessedBy } from '../../../../../redux/selector/restaurant'

const EVENT_Z_INDEX = 35

const ModalSplit = ({ split, splitNumber = '', tableId, hasModalLogin, clearSplit, setUpdateSplitMode }) => {
	const { t } = useTranslation()
	const showSplitActionsModal = useSelector(state => state.restaurant.showSplitActionsModal)
	const dispatch = useDispatch()
	const processedBy = useSelector(selectProcessedBy)

	const handleClose = () => {
		dispatch(setRestaurantSplitActionsModal(false))
		clearSplit()
		setUpdateSplitMode(false)
	}

	const updateSplit = () => {
		dispatch(setRestaurantSplitActionsModal(false))
		setUpdateSplitMode(true)
	}

	const paySplit = () => {
		dispatch(setRestaurantSplitActionsModal(false))
		dispatch(setRestaurantPaymentTypeModal(true))
	}

	const printSplit = () => {
		dispatch(printTableSplitThunk(split.id, tableId, hasModalLogin))
		dispatch(setRestaurantSplitActionsModal(false))
		clearSplit()
	}

	const deleteSplit = () => {
		dispatch(deleteTableSplitThunk(split.id, tableId, hasModalLogin, { processedBy }))
		dispatch(setRestaurantSplitActionsModal(false))
		clearSplit()
	}

	const buttons = [
		{ icon: 'change', text: t('restaurant.tables.modal.change'), onClick: updateSplit, show: true },
		{ icon: 'payment', text: t('restaurant.tables.modal.payment'), onClick: paySplit, show: true },
		{ icon: 'print', text: t('restaurant.tables.modal.print_interim_check'), onClick: printSplit, show: true },
		{ icon: 'cancel', text: t('restaurant.tables.modal.cancel'), onClick: deleteSplit, show: true }
	]

	return (
		<ButtonsModal isOpen={!!(showSplitActionsModal && split)}
									close={handleClose}
									id="restaurant-modal-split"
									title={`Split #${splitNumber}`}
									titlePrice={split.totalPrice}
									size="small"
									autoFocus={false}
									zIndex={EVENT_Z_INDEX}
									buttons={buttons} />
	)
}

ModalSplit.displayName = 'ModalSplit'
export default ModalSplit
