import { APPEND_CART_PRODUCT_PRODUCTS, SET_CART_PRODUCT_ACTIVE_PRODUCT_INDEX, SET_CART_PRODUCT_ENABLE_QUANTITY, SET_CART_PRODUCT_ITEM_FOCUS, SET_CART_PRODUCT_LOADING, SET_CART_PRODUCT_MENU, SET_CART_PRODUCT_MENU_PRODUCT_INDEX, SET_CART_PRODUCT_MUST_TOPPINGS, SET_CART_PRODUCT_PRODUCT_FOCUS, SET_CART_PRODUCT_PRODUCTS, SET_CART_PRODUCT_PRODUCTS_TOTAL, SET_CART_PRODUCT_SEARCH_TEXT, SET_CART_PRODUCT_TOPPINGS, SET_CART_PRODUCT_TOPPING_GROUPS, APPEND_CART_PRODUCT_MUST_TOPPINGS, APPEND_CART_PRODUCT_TOPPINGS, SET_CART_PRODUCT_TOPPINGS_TOTAL, SET_CART_PRODUCT_MUST_TOPPINGS_TOTAL } from '../../actionTypes/cartProduct'

const initialState = {
	products: [],
	productsTotal: null,
	activeProductIndex: 0,
	loading: false,
	productFocus: null,
	itemFocus: null,
	toppings: [],
	toppingsTotal: 0,
	mustToppings: [],
	mustToppingsTotal: 0,
	enableQuantity: false,
	menu: null,
	menuProductIndex: 0,
	searchText: '',
	toppingGroups: []
}

export function cartProductReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CART_PRODUCT_PRODUCTS:
			return {
				...state,
				products: action.value,
			}
		case SET_CART_PRODUCT_PRODUCTS_TOTAL:
			return {
				...state,
				productsTotal: action.value,
			}
		case APPEND_CART_PRODUCT_PRODUCTS:
			return {
				...state,
				products: state.products.concat(...action.value),
			}
		case SET_CART_PRODUCT_ACTIVE_PRODUCT_INDEX:
			return {
				...state,
				activeProductIndex: action.value,
			}
		case SET_CART_PRODUCT_LOADING:
			return {
				...state,
				loading: action.value,
			}
		case SET_CART_PRODUCT_PRODUCT_FOCUS:
			return {
				...state,
				productFocus: action.value,
			}
		case SET_CART_PRODUCT_ITEM_FOCUS:
			return {
				...state,
				itemFocus: action.value,
			}
		case SET_CART_PRODUCT_TOPPINGS:
			return {
				...state,
				toppings: action.value,
			}
		case SET_CART_PRODUCT_TOPPINGS_TOTAL:
			return {
				...state,
				toppingsTotal: action.value
			}
		case APPEND_CART_PRODUCT_TOPPINGS:
			return {
				...state,
				toppings: state.toppings.concat(...action.value)
			}
		case SET_CART_PRODUCT_MUST_TOPPINGS:
			return {
				...state,
				mustToppings: action.value,
			}
		case SET_CART_PRODUCT_MUST_TOPPINGS_TOTAL:
			return {
				...state,
				mustToppingsTotal: action.value
			}
		case APPEND_CART_PRODUCT_MUST_TOPPINGS:
			return {
				...state,
				mustToppings: state.mustToppings.concat(...action.value)
			}
		case SET_CART_PRODUCT_ENABLE_QUANTITY:
			return {
				...state,
				enableQuantity: action.value,
			}
		case SET_CART_PRODUCT_MENU:
			return {
				...state,
				menu: action.value,
			}
		case SET_CART_PRODUCT_MENU_PRODUCT_INDEX:
			return {
				...state,
				menuProductIndex: action.value,
			}
		case SET_CART_PRODUCT_SEARCH_TEXT:
			return {
				...state,
				searchText: action.value,
			}
		case SET_CART_PRODUCT_TOPPING_GROUPS:
			return {
				...state,
				toppingGroups: action.value,
			}
		default:
			return state
	}
}
