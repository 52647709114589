export const calculateTooltipPosition = (markerCoordinates, mapWidth, mapHeight, tooltipWidth, tooltipHeight, formWidth, widthMenu, heightHeader, orderTableWidth) => {
	const markerX = markerCoordinates.x
	const markerY = markerCoordinates.y
	const tooltipOffset = 21
	const widthMarkerAndMargin = 35
	const extraSmallMargin = 4
	const extraBigMargin = 24
	const distanceToBottom = (mapHeight + heightHeader) - markerY
	const distanceToLeft = markerX - formWidth - orderTableWidth - widthMenu
	const distanceToRight = (mapWidth + orderTableWidth + widthMenu) - markerX
	let tooltipTop
	let tooltipLeft
	let position
	if (distanceToBottom > tooltipHeight + tooltipOffset && distanceToLeft > tooltipWidth / 2 && distanceToRight > tooltipWidth / 2) {
		tooltipTop = markerY + tooltipOffset / 2
		tooltipLeft = markerX - widthMenu - orderTableWidth - tooltipWidth / 2 + tooltipOffset
		position = 'bottom'
	} else if (distanceToBottom < tooltipHeight + tooltipOffset && distanceToLeft > tooltipWidth / 2 && distanceToRight > tooltipWidth / 2) {
		tooltipTop = markerY - tooltipHeight - tooltipOffset - heightHeader - extraSmallMargin
		tooltipLeft = markerX - widthMenu - orderTableWidth - tooltipWidth / 2 + tooltipOffset
		position = 'top'

	} else if (distanceToLeft > tooltipWidth + tooltipOffset) {
		tooltipLeft = markerX - widthMenu - orderTableWidth - tooltipWidth - tooltipOffset
		tooltipTop = markerY - heightHeader + extraBigMargin - tooltipHeight / 2
		position = 'left'
	} else if (distanceToLeft < tooltipWidth + tooltipOffset) {
		tooltipLeft = markerX - widthMenu - orderTableWidth + widthMarkerAndMargin + tooltipOffset
		tooltipTop = markerY - heightHeader + extraBigMargin - tooltipHeight / 2
		position = 'right'
	}

	return {
		top: `${tooltipTop}px`,
		left: `${tooltipLeft}px`,
		position: `${position}`
	}
}
