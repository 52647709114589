import  { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { incrementTimeCounter } from '../../../redux/action/timeCounter'

const TimeCounter = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		let interval = setInterval(() => {
			dispatch(incrementTimeCounter())
		}, 60000)

		return () => clearInterval(interval)
	},[])

	return null
}

TimeCounter.displayName = 'TimeCounter'
export default TimeCounter
