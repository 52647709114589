import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const CompanyData = lazyWithRetry(() => import(/* webpackChunkName: "company-data" */'../../component/CompanyData'))

const CompanyDataPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<CompanyData />
		</Suspense>
	)
})

CompanyDataPage.displayName = 'CompanyDataPage'
export default CompanyDataPage
