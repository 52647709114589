import styled from 'styled-components'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { theme } from '../../../../style/theme'

export const SendToKitchenModalHeaderWrap = styled.div`
	font-size: 1em;
	padding: 0.56em 0;
	width: 100%;
`
SendToKitchenModalHeaderWrap.displayName = 'SendToKitchenModalHeaderWrap'

export const SendToKitchenModalHeaderTitleWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 1.25em;
	margin-bottom: 0.81em;
	border-bottom: 1px solid ${colorTheme.restaurant.sendToKitchenModal.header.border};

	@media screen and (max-width: ${theme.point820}) {
		padding: 0;
		margin: 0;
		border-bottom: none;
	}
`
SendToKitchenModalHeaderTitleWrap.displayName = 'SendToKitchenModalHeaderTitleWrap'
export const SendToKitchenModalHeaderTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 0.63em;
`
SendToKitchenModalHeaderTitle.displayName = 'SendToKitchenModalHeaderTitle'
export const SendToKitchenModalHeaderTitleText = styled.div`
	color: ${colorTheme.restaurant.sendToKitchenModal.header.title};
	font-family: ${theme.fontRobotoBold};
	font-size: 1.25em;
	font-weight: 700;
`
SendToKitchenModalHeaderTitleText.displayName = 'SendToKitchenModalHeaderTitleText'
export const SendToKitchenModalClose = styled.div`
	> svg {
		width: 1.19em;
		height: 1.19em;
		fill: ${colorTheme.modal.closeKey.icon};
		cursor: pointer;
	}
`
SendToKitchenModalClose.displayName = 'SendToKitchenModalClose'
