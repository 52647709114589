import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const DriverTime = styled.div`
	font-size: 0.7em;
	font-weight: 500;
	color: ${colorTheme.table.tableTr.driverTime};

	&.time-over {
		color: ${colorTheme.table.tableTr.dangerText};
	}
`
DriverTime.displayName = 'DriverTime'
