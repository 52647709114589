import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Category = lazyWithRetry(() => import(/* webpackChunkName: "category" */'../../component/Category'))

const CategoryPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Category />
		</Suspense>
	)
})

CategoryPage.displayName = 'CategoryPage'
export default CategoryPage
