export const PRESET_COLORS = {
	'LIGHT': ['#FFFFFF', '#000000', '#FF5821', '#6BB156', '#B33E17', '#213A41', '#FF9929', '#00DFAA', '#3E2000', '#282828'],
	'DARK': ['#FFFFFF', '#000000', '#FF9929', '#00DFAA', '#3E2000', '#282828', '#FF5821', '#6BB156', '#B33E17', '#213A41'],
	'RED': ['#FFFFFF', '#000000', '#E20000', '#C12A2E', '#ADB000', '#2B2B2B', '#FF5821', '#6BB156', '#B33E17', '#213A41']
}

export const BACKGROUND_IMAGES = (t, pathImg = '') => [
	{ value: `${pathImg}pizza-bg.webp`, label: t('backgroundImages.pizzeria') },
	{ value: `${pathImg}sushi-bg.webp`, label: t('backgroundImages.Sushi') },
	{ value: `${pathImg}sushi-pizza-bg.webp`, label: t('backgroundImages.Pizza_and_sushi') },
	{ value: `${pathImg}pasta-bg.webp`, label: t('backgroundImages.Pasta') },
	{ value: `${pathImg}burger-bg.webp`, label: t('backgroundImages.Burger') },
	{ value: `${pathImg}grill-bg.webp`, label: t('backgroundImages.Grill') },
	{ value: `${pathImg}beer-bg.webp`, label: t('backgroundImages.Beer_and_snacks') },
	{ value: `${pathImg}fish-bg.webp`, label: t('backgroundImages.Fish') },
	{ value: `${pathImg}vegan-bg.webp`, label: t('backgroundImages.Vegetarian_cuisine') },
	{ value: `${pathImg}deserts-bg.webp`, label: t('backgroundImages.Desserts') },
	{ value: `${pathImg}german-bg.webp`, label: t('backgroundImages.German_cuisine') },
	{ value: `${pathImg}italian-bg.webp`, label: t('backgroundImages.Italian_cuisine') },
	{ value: `${pathImg}thai-bg.webp`, label: t('backgroundImages.Thai_cuisine') },
	{ value: `${pathImg}japan-bg.webp`, label: t('backgroundImages.Japanese_cuisine') },
	{ value: `${pathImg}french-bg.webp`, label: t('backgroundImages.French_cuisine') },
	{ value: `${pathImg}doner-bg.webp`, label: t('backgroundImages.Doner') },
	{ value: `${pathImg}indian-bg.webp`, label: t('backgroundImages.Indian_cuisine') },
]
export const BACKGROUND_COLOR = (t) => [
	{ value: '#000000', label: t('backgroundColors.black') },
	{ value: '#FFFFFF', label: t('backgroundColors.white') },
	{ value: '#FF5821', label: t('backgroundColors.tangerine') },
	{ value: '#6BB156', label: t('backgroundColors.grass') },
	{ value: '#FF9929', label: t('backgroundColors.orange') },
	{ value: '#00DFAA', label: t('backgroundColors.mint') },
	{ value: '#E20000', label: t('backgroundColors.red') },
	{ value: '#ADB000', label: t('backgroundColors.swampy') },
]
