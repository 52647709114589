/* eslint no-console: off */
import { setCartPaymentQrCodePaymentSession, setCartPaymentQrCodePaymentSessionStarted, setCartPaymentTerminalPaymentSession, setProcessPaymentIsLoading } from '../../redux/action/cartPayment'

export const processPaymentMessageThunk = (message) => (dispatch, getState) => {
	switch (message?.type) {
		case 'payment.TERMINAL_PAYMENT_SESSION_PROCESS_REQUESTED':
			console.log(`Terminal payment process requested: ${message.data?.sessionId} companyIndex=${getState().login.companyIndex}`)
			handleTerminalPaymentProcessing(message.data, dispatch, getState)
			break
		case 'payment.TERMINAL_PAYMENT_CARD_VALIDATION_SUCCEEDED':
			console.log(`Terminal payment card validation succeeded: ${message.data?.sessionId} status=${message.data?.paymentSession?.status} companyIndex=${getState().login.companyIndex}`)
			handleTerminalPaymentCardValidation(message.data, dispatch, getState)
			break
		case 'payment.TERMINAL_PAYMENT_SESSION_CAPTURED':
			console.log(`Terminal payment session captured: ${message.data?.sessionId} status=${message.data?.paymentSession?.status} companyIndex=${getState().login.companyIndex}`)
			handleTerminalPaymentCardValidation(message.data, dispatch, getState)
			break
		case 'payment.TERMINAL_PAYMENT_CARD_VALIDATION_FAILED':
			console.log(`Terminal payment card validation failed: session=${message.data?.sessionId} code=${message.data?.failureCode} message=${message.data?.failureMessage} companyIndex=${getState().login.companyIndex}`)
			handleTerminalPaymentCardValidation(message.data, dispatch, getState)
			break
		case 'payment.TERMINAL_PAYMENT_SESSION_REFERRED_TO_ORDER':
			console.log(`Terminal payment session referred to order: session=${message.data?.sessionId} orderId=${message.data?.paymentSession?.orderId} companyIndex=${getState().login.companyIndex}`)
			handleTerminalPaymentSessionChanged(message.data, dispatch, getState)
			break
		case 'payment.TERMINAL_PAYMENT_SESSION_CANCELED':
			console.log(`Terminal payment session canceled: session=${message.data?.sessionId} orderId=${message.data?.paymentSession?.orderId} companyIndex=${getState().login.companyIndex}`)
			handleTerminalPaymentSessionChanged(message.data, dispatch, getState)
			break
		case 'payment.QR_PAYMENT_SESSION_CREDENTIALS_PROVIDED':
			console.log(`QR payment session credentials provided: session=${message.data?.sessionId} status=${message.data?.paymentSession?.status} companyIndex=${getState().login.companyIndex}`)
			handleQrCodePaymentSessionChanged(message.data, dispatch, getState)
			break
		case 'payment.QR_PAYMENT_SESSION_CAPTURED':
			console.log(`QR payment session captured: session=${message.data?.sessionId} status=${message.data?.paymentSession?.status} companyIndex=${getState().login.companyIndex}`)
			handleQrCodePaymentSessionChanged(message.data, dispatch, getState)
			break
		case 'payment.QR_PAYMENT_SESSION_CANCELED':
			console.log(`QR payment session cancelled: session=${message.data?.sessionId} status=${message.data?.paymentSession?.status} companyIndex=${getState().login.companyIndex}`)
			handleQrCodePaymentSessionChanged(message.data, dispatch, getState)
			break
		case 'payment.QR_PAYMENT_SESSION_REFERRED_TO_ORDER':
			console.log(`QR payment session cancelled: session=${message.data?.sessionId} status=${message.data?.paymentSession?.status} companyIndex=${getState().login.companyIndex}`)
			handleQrCodePaymentSessionChanged(message.data, dispatch, getState)
			break
		case 'payment.QR_PAYMENT_SESSION_STARTED':
			console.log(`QR payment session started: session=${message.data?.sessionId} status=${message.data?.paymentSession?.status} companyIndex=${getState().login.companyIndex}`)
			dispatch(setCartPaymentQrCodePaymentSessionStarted(true))
			break
		default:
			// eslint-disable-next-line
			console.error('Unknown payment message type: ', JSON.stringify(message))
			break
	}
}

export const handleTerminalPaymentProcessing = (data, dispatch, getState) => {
	const terminalPaymentSession = getState().cartPayment.terminalPaymentSession
	if (terminalPaymentSession?.sessionId && terminalPaymentSession?.sessionId === data?.paymentSession?.sessionId) {
		console.log(`Socket handleTerminalPaymentProcessing paymentSession=${data?.paymentSession?.sessionId}`)
		dispatch(setCartPaymentTerminalPaymentSession(data?.paymentSession))
	}
}

export const handleTerminalPaymentCardValidation = (data, dispatch, getState) => {
	const terminalPaymentSession = getState().cartPayment.terminalPaymentSession
	if (terminalPaymentSession?.sessionId && terminalPaymentSession?.sessionId === data?.paymentSession?.sessionId) {
		dispatch(setCartPaymentTerminalPaymentSession(data?.paymentSession))
		dispatch(setProcessPaymentIsLoading(false))
	}
}

export const handleTerminalPaymentSessionChanged = (data, dispatch, getState) => {
	const terminalPaymentSession = getState().cartPayment.terminalPaymentSession
	if (terminalPaymentSession?.sessionId && terminalPaymentSession?.sessionId === data?.paymentSession?.sessionId) {
		dispatch(setCartPaymentTerminalPaymentSession(data?.paymentSession))
	}
}

export const handleQrCodePaymentSessionChanged = (data, dispatch, getState) => {
	const qrCodePaymentSession = getState().cartPayment.qrCodePaymentSession
	if (qrCodePaymentSession?.sessionId && qrCodePaymentSession?.sessionId === data?.paymentSession?.sessionId) {
		dispatch(setCartPaymentQrCodePaymentSession(data?.paymentSession))
	}
}
