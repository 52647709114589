import React from 'react'
import { CartProductBlock, CartProductDiscountedPrice, CartProductInitialPriceBlock, CartProductPrice, CartProductQuantity, CartProductTitle, CartProductTotalPriceBlock, CartProductWrapper } from '../index.styled'
import Currency from '../../../../../Elements/currency'
import { calcCartItemTotalPrice, getPercentDiscountInCurrency } from '../../../../../../helper'
import { useSelector } from 'react-redux'
import CartProduct from '../CartProduct'
import { selectDiscountValue } from '../../../../../../redux/selector/clientMonitorCart'
import { CartProductQuantityWrap } from '../../index.styled'

const CartMenu = React.memo(({ item, index }) => {
	const totalPrice = item ? calcCartItemTotalPrice(item) : 0
	const discountValue = useSelector(selectDiscountValue)
	const discount = getPercentDiscountInCurrency(discountValue, totalPrice)
	const itemHasDiscount = discount > 0 && !item?.disabledDiscount
	const discountedPrice = itemHasDiscount ? totalPrice - discount : totalPrice
	const layoutTotalPrice = (itemHasDiscount ? 'active' : 'total')
	const dataTestId = 'menu-' + index

	return (
		<>
			<CartProductWrapper>
				<CartProductBlock>
					<CartProductTitle>{item?.name}</CartProductTitle>
					{item?.quantity > 1 &&
						<CartProductQuantityWrap>
							<CartProductQuantity data-testid={dataTestId + '-quantity'}>x {item?.quantity}</CartProductQuantity>
						</CartProductQuantityWrap>}
					<CartProductInitialPriceBlock>
						<CartProductPrice data-testid={dataTestId + '-initial-price'}><Currency>{item?.price}</Currency></CartProductPrice>
					</CartProductInitialPriceBlock>
					<CartProductTotalPriceBlock>
						{itemHasDiscount && <CartProductDiscountedPrice data-testid={dataTestId + '-discounted-price'}><Currency>{totalPrice}</Currency></CartProductDiscountedPrice>}
						<CartProductPrice data-testid={dataTestId + '-price'} className={layoutTotalPrice}><Currency>{discountedPrice}</Currency></CartProductPrice>
					</CartProductTotalPriceBlock>
					{item?.items.map((item, i) =>
						<CartProduct key={i}
												 item={item}
												 index={i}
												 isMenuProduct={true} />)}
				</CartProductBlock>
			</CartProductWrapper>
		</>
	)
})

CartMenu.displayName = 'CartMenu'
export default CartMenu
