import { subMinutes } from 'date-fns'

const DEFAULT_PREPARE_TIME = 30
const DELIVERY_PREPARE_TIME = 45

export const getOrderTypePrepareTime = (orderType) => {
	if (orderType === 'DELIVERY') {
		return DELIVERY_PREPARE_TIME
	} else {
		return DEFAULT_PREPARE_TIME
	}
}

export const getPreorderStartPrepareTime = (order) => {
	if (!order?.preOrder) return
	const preorderPrepareTime = getOrderTypePrepareTime(order?.orderType)
	return subMinutes(new Date(order?.preOrder), preorderPrepareTime)
}

export const groupAndSortItemsByCourse = (items) => {
	if (!items) return {}
	if (items.some(item => !item?.dishCourse)) {
		return { 0: items }
	}

	const grouped = items.reduce((acc, item) => {
		const key = item.dishCourse.index
		if (!acc[key]) {
			acc[key] = []
		}
		acc[key].push(item)
		return acc
	}, {})

	Object.keys(grouped).forEach(key => {
		grouped[key].sort((a, b) => a.dishCourse.index - b.dishCourse.index)
	})

	return grouped
}
