import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getNeededDateFormat } from '../../../../helper'
import MainIcon from '../../../../img/svg/main'
import PlatformIcon from '../../../../img/svg/platforms'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import Accordion from '../../../Elements/accordion'
import Currency from '../../../Elements/currency'
import KeyBindings from '../../../Elements/keybindings'
import Order from '../../../Elements/modal/order/Order'
import { CourierIcon, ModalButtons, ModalButtonsItem, ModalContent, ModalWrapper, OrderInfo, OrderInfoBlock, OrderInfoItem, OrderInfoText, OrderInfoWarning, OrderInfoWarningText, OrderTimeBlock, OrderWrap } from '../index.styled'
import sprite from '../../../../img/svg/main/index.svg'
import { useTheme } from 'styled-components'

const EVENT_Z_INDEX = 993
const TIME_BUTTONS = [0, 10, 15, 20, 25]
const TIME_BUTTONS_WITHOUT_ZERO = [10, 20, 30, 40, 50]

const OrderWithTimeButtons = React.memo(({ order, handleConfirm, setProcessingTime }) => {
	const { t } = useTranslation()
	const { colorTheme } = useTheme()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const isProcessingConfirmOrder = useSelector(store => store.order.isProcessingConfirmOrder)
	const isProcessingDenyOrder = useSelector(store => store.order.isProcessingDenyOrder)
	const [activeButtonIndex, setActiveButtonIndex] = useState(0)
	const isTakeawayDriver = order?.deliveryMetaData?.partner?.type === 'TAKEAWAY_DRIVE'
	const timeButtons = isTakeawayDriver ? TIME_BUTTONS_WITHOUT_ZERO : TIME_BUTTONS
	const buttonsCount = timeButtons.length
	const driverArriveTime = order?.preOrder || order?.deliveryMetaData?.partner?.pickupEta
	const isDisabled = isProcessingConfirmOrder || isProcessingDenyOrder
	const layoutDisabled = isDisabled ? 'disabled ' : ''

	const handleArrowUp = useCallback(() => {
		if ((activeButtonIndex - 1) < 0) {
			setActiveButtonIndex(buttonsCount - 1)
			setProcessingTime(timeButtons[buttonsCount - 1])
		} else {
			setActiveButtonIndex(activeButtonIndex - 1)
			setProcessingTime(timeButtons[activeButtonIndex - 1])
		}
	}, [buttonsCount, activeButtonIndex, setProcessingTime, timeButtons])

	const handleArrowDown = useCallback(() => {
		if ((activeButtonIndex + 1) >= buttonsCount) {
			setActiveButtonIndex(0)
			setProcessingTime(0)
		} else {
			setActiveButtonIndex(activeButtonIndex + 1)
			setProcessingTime(timeButtons[activeButtonIndex + 1])
		}
	}, [buttonsCount, activeButtonIndex, setProcessingTime, timeButtons])

	return (
		<>
			<ModalWrapper data-testid="platform-drivers-order-wrapper">
				<ModalContent>
					<OrderInfo>
						{driverArriveTime ?
							<OrderInfoBlock data-testid="time" className="with-image">
								<OrderTimeBlock>
									<OrderInfoItem>
										<MainIcon icon="clock_on_3" className="big" />
										<OrderInfoText className="bold">{getNeededDateFormat(driverArriveTime, 'H:mm')}</OrderInfoText>
									</OrderInfoItem>
									<OrderInfoItem>
										<MainIcon icon="date" className="big" />
										<OrderInfoText className="bold">{getNeededDateFormat(driverArriveTime, 'dd.MM.y')}</OrderInfoText>
									</OrderInfoItem>
								</OrderTimeBlock>
								<CourierIcon>
									<svg data-testid="courier-icon">
										<use href={sprite + '#give-courier-big'} />
									</svg>
								</CourierIcon>
							</OrderInfoBlock> :
							<OrderInfoWarning>
								<MainIcon icon="info" />
								<OrderInfoWarningText>{t('Modal.platform_drivers_order_modal.warning')}</OrderInfoWarningText>
							</OrderInfoWarning>}
						<OrderInfoBlock data-testid="platform">
							<PlatformIcon icon={`${order?.platformMetaData?.platformType}-${colorTheme}`} />
						</OrderInfoBlock>
						<OrderInfoBlock data-testid="payment">
							<OrderInfoItem>
								<MainIcon icon={order?.paymentMethod} />
								<OrderInfoText><Currency>{order?.totalPrice}</Currency></OrderInfoText>
							</OrderInfoItem>
						</OrderInfoBlock>
					</OrderInfo>
					<Accordion titleIcon="report" titleWhenOpen={t('Modal.platform_drivers_order_modal.viewOrder')} titleWhenClose={t('Modal.platform_drivers_order_modal.viewOrder')} size="small" outline={true}>
						<OrderWrap>
							<Order order={order} />
						</OrderWrap>
					</Accordion>
				</ModalContent>
				{<ModalButtons>
					{timeButtons?.map((time, index) =>
						<ModalButtonsItem key={time}
															className={(activeButtonIndex === index ? 'active' : '') + layoutDisabled}
															onClick={!isDisabled ? () => handleConfirm(time) : () => {
															}}>
							{(isTakeawayDriver || index > 0) && <MainIcon icon="cooking-pot" />}
							{time > 0 && '+'}{time} min
						</ModalButtonsItem>)}
				</ModalButtons>}
			</ModalWrapper>

			{(isNeededZIndex && !isDisabled) &&
				<KeyBindings arrowUp={handleArrowUp} arrowDown={handleArrowDown} />}
		</>
	)
})

OrderWithTimeButtons.displayName = 'OrderWithTimeButtons'
export default OrderWithTimeButtons
