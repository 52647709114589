import React, { useCallback, useState } from 'react'
import WebShopNotificationBtn from './WebShopNotificationBtn'
import WebShopNotificationModal from './WebShopNotificationModal'

const WebShopNotification = React.memo(() => {
	const [showNotificationModal, setShowNotificationModal] = useState(false)

	const openWebShopNotificationModal = useCallback(() => {
		setShowNotificationModal(true)
	},[])

	const closeWebShopNotificationModal = useCallback(() => {
		setShowNotificationModal(false)
	},[])

	return (
		<>
			<WebShopNotificationBtn openModal={openWebShopNotificationModal}/>
			<WebShopNotificationModal isOpen={showNotificationModal}
																close={closeWebShopNotificationModal} />
		</>
	)
})

WebShopNotification.displayName = 'WebShopNotification'

export default WebShopNotification
