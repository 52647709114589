import React from 'react'
import CartProduct from '../CartProduct'
import { CartMenuWrapper } from './index.styled'
import PlusMinus from '../PlusMinus'
import CartRemark from '../CartRemark'
import { useSelector } from 'react-redux'
import { CartProductWrap, Product, ProductCol, ProductDiscountPrice, ProductName, ProductNr, ProductPrice, ProductQuantity, ProductToppings, ProductTotal } from '../CartProduct/index.styled'
import Currency from '../../../../../../Elements/currency'
import { useLocalStorage } from '../../../../../../../hooks/useLocalStorage'
import { selectDiscountForItem } from '../../../../../../../redux/selector/cart'
import { calcCartItemTotalPrice } from '../../../../../../../helper'

const CartMenu = React.memo(({ active, item, onClickPlus, AddButton, savedOrder, onClickProduct }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const totalPrice = item ? calcCartItemTotalPrice(item) : 0
	const discount = useSelector(selectDiscountForItem(totalPrice))
	const layout = (touchPad ? 'touchPad ' : '') + (active ? 'active' : '') + (savedOrder ? ' secondary' : '')
	const layoutWrap = `${active ? 'active' : ''} ${touchPad ? 'touchPad' : ''} ${item?.dishCourse ? 'dishCourse' : ''}`
	const itemHasDiscount = discount > 0 && !item?.disabledDiscount

	const discountedPrice = itemHasDiscount ? totalPrice - discount : totalPrice
	const layoutPrice = (itemHasDiscount ? 'green ' : '')

	return (
		<CartProductWrap className={layoutWrap} onClick={onClickProduct}>
			<Product className={layout}>
				<ProductNr className={layout + ' menu'}>{item?.sku}</ProductNr>
				<ProductName className={layout + ' menu'}>
					{item?.name}
					{item?.quantity > 1 &&
						<ProductQuantity className={layout + ' menu'}> x {item?.quantity}</ProductQuantity>}
				</ProductName>
				<ProductCol className={layout + ' menu'}><Currency>{item?.price}</Currency></ProductCol>
				<ProductTotal className={layout + ' menu'}>
					{itemHasDiscount && <ProductDiscountPrice><Currency>{totalPrice}</Currency></ProductDiscountPrice>}
					<ProductPrice className={layoutPrice}><Currency>{discountedPrice}</Currency></ProductPrice>
				</ProductTotal>
				<PlusMinus {...{ AddButton, onClickPlus, incrementAbility: true, size: touchPad ? 'x-medium' : '' }} />
			</Product>
			<CartMenuWrapper className={layout}>
				{item?.remark &&
					<ProductToppings className={`toppings ${layout}`}>
						<CartRemark remark={item?.remark}
												layout={layout} />
					</ProductToppings>}
				{item?.items.map((item, i) =>
					<CartProduct key={i}
											 active={false}
											 item={item}
											 isMenuItem={true}
											 incrementAbility={false}
											 savedOrder={savedOrder} />)}
			</CartMenuWrapper>
		</CartProductWrap>
	)
})

CartMenu.displayName = 'CartMenu'
export default CartMenu
