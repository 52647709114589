import hereGeoCodeInstance from './instance'

const getGeoCode = (countryCode, center, query, apiKey) => {
	return hereGeoCodeInstance.get(
		'/geocode?'
		+ 'q=' + query
		+ '&at=' + center
		+ (countryCode ? `&in=countryCode:${countryCode}` : '')
		+ '&types=street,houseNumber'
		+ '&apiKey=' + apiKey,
	)
}
export default getGeoCode
