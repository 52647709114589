import React, { useMemo } from 'react'
import { BackCategoryButton, BackCategoryButtonIcon } from './index.styled'
import { getCategoryImage } from '../../../../../../redux/reducer/category/constants'
import CategoryIcon from '../../../../category'
import { getColorsForCart } from '../../../../../../helper/getColorsForCart'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage'

const BackCategory = React.memo(({ category, onClick }) => {
	const [colorTheme] = useLocalStorage('colorTheme', 'LIGHT')
	const iconUrl = category?.iconUrl
	const parentCategory = category?.parent
	const colorCategory = parentCategory ? parentCategory.colorData?.color : category.colorData?.color
	const themeCategory = parentCategory ? parentCategory.colorData?.theme : category.colorData?.theme
	const themeStyles = getColorsForCart(colorCategory, themeCategory, colorTheme)
	const categoryIcon = useMemo(() => !iconUrl && getCategoryImage(category.title), [iconUrl, category.title])

	return (
		<BackCategoryButton onClick={onClick} themeStyles={themeStyles}>
			<svg width="21" height="17" viewBox="0 0 21 17" xmlns="http://www.w3.org/2000/svg">
				<path d="M20.1575 8.68004C20.1575 9.27004 19.6875 9.74004 19.0975 9.74004H3.6175L8.4275 14.55C8.8375 14.96 8.8375 15.64 8.4275 16.05C8.2175 16.26 7.9475 16.36 7.6775 16.36C7.4075 16.36 7.1375 16.26 6.9275 16.05L0.3075 9.43004C-0.1025 9.02004 -0.1025 8.35004 0.3075 7.93004L6.9175 1.31004C7.3275 0.900041 8.0075 0.890041 8.4175 1.31004C8.8275 1.72004 8.8375 2.39004 8.4175 2.81004L3.6175 7.62004H19.0975C19.6875 7.62004 20.1575 8.10004 20.1575 8.68004Z"/>
			</svg>
			<div>
				{(categoryIcon || (iconUrl && iconUrl !== 'NO_ICON')) &&
					<BackCategoryButtonIcon data-testid={iconUrl || categoryIcon} themeStyles={themeStyles}>
						<CategoryIcon iconUrl={iconUrl} category={categoryIcon} />
					</BackCategoryButtonIcon>}
				{category?.title}
			</div>
		</BackCategoryButton>
	)
})

BackCategory.displayName = 'BackCategory'
export default BackCategory
