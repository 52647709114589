import { DEFAULT } from '../../../component/Elements/photo/UploadImage/constants'
import { clone } from '../../../helper'
import { APPEND_CATEGORIES, SET_CATEGORIES, SET_CATEGORIES_LENGTH, SET_CATEGORY, SET_CATEGORY_IMAGE_CONDITIONS, SET_CATEGORY_IMAGE_CONFIG, SET_CATEGORY_IMAGE_UPLOAD_STEP, SET_CATEGORY_PARENTS, SET_DEFAULT_CATEGORIES, SET_SHOW_CATEGORY_SIZE_ERROR_MODAL, UPDATE_CATEGORY_ORDER, SET_CATEGORIES_UPDATE_CATEGORY, SET_CATEGORY_IMAGE_ERROR_MODAL, SET_CATEGORY_ICON_URL, SET_SHOW_CATEGORY_ICON_PICKER, SET_CATEGORY_DELETE_ERROR_MODAL, SET_SHOW_CATEGORY_COLOR_PICKER, SET_CATEGORY_COLOR_DATA, SET_CATEGORY_IMAGE_NAV_UPLOAD_STEP, SET_CATEGORY_IMAGE_NAV_CONDITIONS, SET_CATEGORY_IMAGE_NAV_CONFIG, SET_CATEGORY_IMAGE_NAV_ERROR_MODAL } from '../../actionTypes/category'
import { NO_ICON_URL } from './constants'

const initialState = {
	category: null,
	categories: [],
	defaultCategories: [],
	categoriesLength: 0,
	categoryParents: [],
	showCategorySizeErrorModal: false,
	categoryImageUploadStep: DEFAULT,
	categoryImageCondition: false,
	categoryImageConfig: null,
	showCategoryImageErrorModal: false,
	categoryIconUrl: NO_ICON_URL,
	categoryColorData: null,
	showCategoryIconPicker: false,
	showCategoryDeleteErrorModal: false,
	showCategoryColorPicker: false,
	categoryImageNavUploadStep: DEFAULT,
	categoryImageNavCondition: false,
	categoryImageNavConfig: null,
	showCategoryImageNavErrorModal: false
}

export function categoryReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CATEGORY:
			return {
				...state,
				category: action.value
			}
		case SET_CATEGORIES_UPDATE_CATEGORY:
			return {
				...state,
				categories: [...state.categories].map(category => (category.id === action.value.id ? action.value : category))
			}
		case SET_CATEGORIES:
			return {
				...state,
				categories: action.value
			}
		case SET_CATEGORIES_LENGTH:
			return {
				...state,
				categoriesLength: action.value
			}
		case APPEND_CATEGORIES:
			return {
				...state,
				categories: state.categories.concat(...action.value)
			}
		case SET_DEFAULT_CATEGORIES:
			return {
				...state,
				defaultCategories: action.value
			}
		case SET_CATEGORY_PARENTS:
			return {
				...state,
				categoryParents: action.value
			}
		case UPDATE_CATEGORY_ORDER:
			const copyCategories = clone(state.categories)
			action.value.forEach(item => copyCategories[item.index - 1] = item)
			return {
				...state,
				categories: copyCategories
			}
		case SET_SHOW_CATEGORY_SIZE_ERROR_MODAL:
			return {
				...state,
				showCategorySizeErrorModal: action.value
			}
		case SET_CATEGORY_IMAGE_UPLOAD_STEP:
			return {
				...state,
				categoryImageUploadStep: action.value
			}
		case SET_CATEGORY_IMAGE_CONDITIONS:
			return {
				...state,
				categoryImageCondition: action.value
			}
		case SET_CATEGORY_IMAGE_CONFIG:
			return {
				...state,
				categoryImageConfig: action.value
			}
		case SET_CATEGORY_IMAGE_ERROR_MODAL:
			return {
				...state,
				showCategoryImageErrorModal: action.value
			}
		case SET_CATEGORY_ICON_URL:
			return {
				...state,
				categoryIconUrl: action.value
			}
		case SET_SHOW_CATEGORY_ICON_PICKER:
			return {
				...state,
				showCategoryIconPicker: action.value
			}
		case SET_SHOW_CATEGORY_COLOR_PICKER:
			return {
				...state,
				showCategoryColorPicker: action.value
			}
		case SET_CATEGORY_COLOR_DATA:
			return {
				...state,
				categoryColorData: action.value
			}
		case SET_CATEGORY_DELETE_ERROR_MODAL:
			return {
				...state,
				showCategoryDeleteErrorModal: action.value,
			}
		case SET_CATEGORY_IMAGE_NAV_UPLOAD_STEP:
			return {
				...state,
				categoryImageNavUploadStep: action.value
			}
		case SET_CATEGORY_IMAGE_NAV_CONDITIONS:
			return {
				...state,
				categoryImageNavCondition: action.value
			}
		case SET_CATEGORY_IMAGE_NAV_CONFIG:
			return {
				...state,
				categoryImageNavConfig: action.value
			}
		case SET_CATEGORY_IMAGE_NAV_ERROR_MODAL:
			return {
				...state,
				showCategoryImageNavErrorModal: action.value
			}
		default:
			return state
	}
}


