import productInstance from '../product/instance'

const fetchCategories = (page = 0, size = 0, title, isSubcategory = null, hasPriceDescriptions = null) => {
  return productInstance.get('/categories/'
    + ((page || size) ? '?page=' + page + '&size=' + size : '')
    + (title ? '&title=' + title : '')
    + (isSubcategory != null ? '&isSubcategory=' + isSubcategory : '')
    + (hasPriceDescriptions != null ? '&hasPriceDescriptions=' + hasPriceDescriptions : ''))
}

export default fetchCategories
