import React from 'react'
import { TableOrderMobileTitle, TextBig } from '../../OrdersTable/index.styled'
import { TableTd } from '../../style/table/td/index.styled'
import InvoiceNumber from '../../TableTdElements/InvoiceNumber'
import InvoiceDate from '../../TableTdElements/InvoiceDate'
import InvoiceAmount from '../../TableTdElements/InvoiceAmount'
import { TextWrap } from '../../TableTdElements/CustomerData/index.styled'
import InvoiceStatus from '../../TableTdElements/InvoiceStatus'

const InvoicesTableTdList = React.memo(({ tableHeadersStatic, item }) => {

	return (
		<>
			<TableTd data-title={tableHeadersStatic[0].title} className="col-tablet-1-3 col-mob-1-3">
				<TableOrderMobileTitle>{tableHeadersStatic[0].title} </TableOrderMobileTitle>
				<InvoiceNumber item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[1].title} className="col-tablet-3-4 col-mob-3-5" >
				<TableOrderMobileTitle>{tableHeadersStatic[1].title}</TableOrderMobileTitle>
				<InvoiceDate item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[2].title} className="col-tablet-1-3">
				<TableOrderMobileTitle>{tableHeadersStatic[2].title} </TableOrderMobileTitle>
				<InvoiceAmount item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[3].title} className="col-tablet-4-7 col-mob-1-5 row-mob-2">
				<TableOrderMobileTitle>{tableHeadersStatic[3].title} </TableOrderMobileTitle>
				<TextWrap>
					<TextBig>{item.customer?.billingAddress.companyName}</TextBig>
					<TextBig>{item.customer?.billingAddress?.name}</TextBig>
				</TextWrap>
				<p className="street">{item.customer?.billingAddress.city} {item.customer.billingAddress.postalCode} {item.customer?.billingAddress.street} {item.customer.billingAddress.streetNumber}
				</p>
			</TableTd>
			<TableTd data-title={tableHeadersStatic[4].title} className={'col-tablet-3-5 col-mob-1-5 row-tablet-2 row-mob-4'}>
				<TableOrderMobileTitle>{tableHeadersStatic[4].title} </TableOrderMobileTitle>
				<TextBig>{item.customer?.email}</TextBig>
			</TableTd>
			<TableTd data-title={tableHeadersStatic[5].title}
							 className="const-color col-tablet-5-7 col-mob-1-5">
				<TableOrderMobileTitle>{tableHeadersStatic[5].title} </TableOrderMobileTitle>
				<InvoiceStatus item={item} />
			</TableTd>
		</>
	)
})

InvoicesTableTdList.displayName = 'InvoicesTableTdList'
export default InvoicesTableTdList
