import { APPEND_COUPONS, SET_COUPON, SET_COUPONS, SET_COUPONS_LENGTH, SET_COUPON_ERROR_IS_EXIST, SET_GIFT_COUPON_IS_INVALID, SET_COUPON_DISABLED_FOR_KASSE, SET_GIFT_COUPONS, SET_GIFT_COUPONS_LENGTH, APPEND_GIFT_COUPONS, SET_GIFT_COUPON, SET_GIFT_COUPON_IS_NOT_EXIST, SET_GIFT_COUPON_IS_USED, SET_GIFT_COUPON_IS_CANCELED } from '../../actionTypes/coupon'

export const setCoupon = value => ({ type: SET_COUPON, value })

export const setCoupons = value => ({ type: SET_COUPONS, value })

export const appendCoupons = value => ({ type: APPEND_COUPONS, value })

export const setCouponsLength = value => ({ type: SET_COUPONS_LENGTH, value })

export const setCouponErrorIsExist = value => ({ type: SET_COUPON_ERROR_IS_EXIST, value })

export const setCouponDisabledForKasse = value => ({ type: SET_COUPON_DISABLED_FOR_KASSE, value })

export const setGiftCoupons = value => ({ type: SET_GIFT_COUPONS, value })

export const setGiftCouponsLength = value => ({ type: SET_GIFT_COUPONS_LENGTH, value })

export const appendGiftCoupons = value => ({ type: APPEND_GIFT_COUPONS, value })

export const setGiftCouponIsInvalid = value => ({ type: SET_GIFT_COUPON_IS_INVALID, value })

export const setGiftCouponIsUsed = value => ({ type: SET_GIFT_COUPON_IS_USED, value })

export const setGiftCouponIsNotExist = value => ({ type: SET_GIFT_COUPON_IS_NOT_EXIST, value })

export const setGiftCouponIsCanceled = value => ({ type: SET_GIFT_COUPON_IS_CANCELED, value })

export const setGiftCoupon = value => ({ type: SET_GIFT_COUPON, value })

