import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const DateVSettings = lazyWithRetry(() => import(/* webpackChunkName: "datev-settings" */'../../component/DateVSettings'))

const DateVSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<DateVSettings />
		</Suspense>
	)
})

DateVSettingsPage.displayName = 'DateVSettingsPage'
export default DateVSettingsPage
