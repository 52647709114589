import React, { useCallback, useEffect, useRef } from 'react'
import { ButtonToggle, HeaderCenter, HeaderRight, HeaderWrapper } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import back from '../../img/svg/menu/assets/back.svg'
import { setToggled } from '../../redux/action/system'
import { theme } from '../../style/theme'
import Logo from '../Elements/Logo'
import Profile from '../Profile'
import LayoutMode from '../LayoutMode'
import Language from '../Language'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { selectIsAuthorized } from '../../redux/selector/system'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import ButtonsLogin from './buttonsLogin'
import WebshopInfo from '../WebshopInfo'
import TimeCounter from './TimeCounter'
import { setMapHeightHeader } from '../../redux/action/map'
import { getShopConfigThunk } from '../../redux/thunk/webshopinfo'
import WebShopNotification from '../WebShopNotification'

const Header = React.memo(({ children, right }) => {
	const isAuthorized = useSelector(selectIsAuthorized)
	const toggled = useSelector(store => store.system.toggled)
	const dispatch = useDispatch()
	const [, setTouchPad] = useLocalStorage('touchPad', false)
	const isTablet = useWindowBreakPoint(theme.point820)
	const isMobile = useWindowBreakPoint(theme.point720)
	const headerRef = useRef(null)
	const height = headerRef?.current?.offsetHeight
	const configLoaded = useSelector(store => !!store.webshopinfo.config)
	const config = useSelector(store => store.webshopinfo.config)

	useEffect(() => {
		if (!configLoaded && isAuthorized) {
			dispatch(getShopConfigThunk())
		}
	}, [configLoaded, isAuthorized]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isTablet) {
			dispatch(setToggled(true))
			setTouchPad(true)
		}
	}, [isTablet, isAuthorized]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (height) {
			dispatch(setMapHeightHeader(height))
		}
	}, [height]) // eslint-disable-line react-hooks/exhaustive-deps


	const handleToggle = useCallback((event) => {
		event.preventDefault()
		dispatch(setToggled(!toggled))
	}, [toggled, dispatch])

	return (
		<HeaderWrapper className={!isAuthorized ? 'authPage' : ''} ref={headerRef}>
			{isAuthorized &&
				<ButtonToggle className={toggled ? 'toggled' : ''} onClick={handleToggle}>
					{!isTablet ?
						<img src={back} alt="back" /> :
						<><span /><span /><span /></>}
				</ButtonToggle>}
			<HeaderCenter className={(!isAuthorized && isTablet) ? 'authPage' : ''}>
				{((toggled || !isMobile) && isAuthorized) ? children : <Logo />}
			</HeaderCenter>
			<TimeCounter />
			<HeaderRight className={!isAuthorized ? 'hidden' : ''} id="header-right">
				{right}
				{!isTablet && isAuthorized && config?.activationAllowed &&
					<WebshopInfo />}
				{!isTablet && isAuthorized && config && !config?.activationAllowed &&
					<WebShopNotification />}
				{(!isTablet && isAuthorized) &&
					<LayoutMode />}
				{!isTablet && !isAuthorized && <ButtonsLogin />}
				{!isTablet &&
					<Language />}
				{!isTablet && isAuthorized &&
					<Profile />}
			</HeaderRight>
		</HeaderWrapper>
	)
})

Header.displayName = 'Header'
export default Header
