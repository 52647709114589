import { SET_LOGIN_DATA, SET_LOGIN_EXCHANGE_RATES, SET_LOGIN_FAILED, SET_LOGIN_INVALID_INDEX_ERROR, SET_LOGIN_INVALID_PASSWORD_ERROR, SET_LOGIN_LOADING, SET_LOGIN_SUCCESS, SET_LOGIN_USER, SET_LOGOUT, SET_PAGE_AFTER_LOGIN, SET_REACT_APP_VERSION } from '../../actionTypes'
import { getLocalStorageAccessToken, removeLocalStorageAccessToken, removeLocalStorageAuthUuid, removeLocalStorageRefreshToken, setLocalStorageAccessToken, setLocalStorageRefreshToken } from '../../../helper/clientMonitor'

const initialState = {
	email: localStorage.login ? localStorage.login : null,
	companyIndex: localStorage.companyIndex ? localStorage.companyIndex.toUpperCase() : null,
	accessToken: getLocalStorageAccessToken() ? getLocalStorageAccessToken() : null,
	user: null,
	pageAfterLogin: null,
	exchangeRates: null,
	loginRequested: false,
	loading: false,
	invalidPasswordError: false,
	invalidIndexError: false,
	reactAppVersion: null,
}

export function loginReducer (state = initialState, action) {
	switch (action.type) {
		case SET_LOGIN_DATA:
			if (action.payload.content.email) localStorage.login = action.payload.content.email
			if (action.payload.content.companyIndex) {
				localStorage.companyIndex = action.payload.content.companyIndex
			} else {
				localStorage.removeItem('companyIndex')
			}
			return {
				...state,
				email: action.payload.content.email ? action.payload.content.email : state.email,
				companyIndex: action.payload.content.companyIndex?.toUpperCase(),
			}
		case SET_LOGIN_SUCCESS:
			setLocalStorageAccessToken(action.payload.content.accessToken)
			setLocalStorageRefreshToken(action.payload.content.refreshToken)
			return {
				...state,
				accessToken: action.payload.content.accessToken,
			}
		case SET_LOGIN_FAILED:
			removeLocalStorageAccessToken()
			removeLocalStorageRefreshToken()
			removeLocalStorageAuthUuid()
			return {
				...state,
				accessToken: null,
				loginRequested: true,
			}
		case SET_LOGIN_INVALID_PASSWORD_ERROR:
			return {
				...state,
				invalidPasswordError: action.value,
			}
		case SET_LOGIN_INVALID_INDEX_ERROR:
			return {
				...state,
				invalidIndexError: action.value,
			}
		case SET_LOGIN_LOADING:
			return {
				...state,
				loading: action.value,
			}
		case SET_PAGE_AFTER_LOGIN:
			return {
				...state,
				pageAfterLogin: action.value,
			}
		case SET_LOGIN_USER:
			return {
				...state,
				user: action.payload.content,
			}
		case SET_LOGIN_EXCHANGE_RATES:
			return {
				...state,
				exchangeRates: action.value,
			}
		case SET_REACT_APP_VERSION:
			return {
				...state,
				reactAppVersion: action.value,
			}
		case SET_LOGOUT:
			removeLocalStorageAccessToken()
			removeLocalStorageRefreshToken()
			removeLocalStorageAuthUuid()
			return {
				...state,
				accessToken: null,
			}
		default:
			return state
	}
}
