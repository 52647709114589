import styled from 'styled-components'
import { TableTdText, TextNoWrap } from '../../../Report/table/cashBookNew/index.styled'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { theme } from '../../../../style/theme'

export const TableTrStyled = styled.tr`
  height: 3.95em;
  cursor: ${props => props.draggable && 'move'};
  transform: translate3d(0, 0, 0);

  &.strikethroughFont {
    ${TableTdText}, ${TextNoWrap} {
      text-decoration-line: line-through;
    }
  }

  &.small {
    height: 3em;
  }

  &:first-child td {
    border-top: 1px solid transparent;
  }

  &:last-child td {
    border-bottom: 1px solid ${colorTheme.table.tableTr.borderBottom};
  }

  &.disabled {
    td:not(.const-color), .unpaid, .light {
      color: ${colorTheme.table.tableTr.disabledNewTable.text};

      svg:not(button > svg) {
        fill: ${colorTheme.table.tableTr.disabledNewTable.text};
      }
    }

    span {
      background: ${colorTheme.table.tableTr.disabledNewTable.text};
    }
  }

  &.disabledStatus {
    td.const-color {
      color: ${colorTheme.table.tableTr.disabledNewTable.text};

      > div {
        color: ${colorTheme.table.tableTr.disabledNewTable.text};
      }

      svg {
        fill: ${colorTheme.table.tableTr.disabledNewTable.text} !important;
      }
    }
  }

  &.active {
    background: ${colorTheme.table.tableTr.active.backgroundTr};
    
    p {
      color: ${colorTheme.table.tableTr.active.text};
    }

    td {
      border-top: 1px solid ${colorTheme.table.tableTr.active.border};
      border-bottom: 1px solid ${colorTheme.table.tableTr.active.border};
      background: ${colorTheme.table.tableTr.active.background};
    }

    td:not(.const-color), .light {
      color: ${colorTheme.table.tableTr.active.text} !important;

      svg, svg:not(button > svg) {
        fill: ${colorTheme.table.tableTr.active.text};
      }

			svg:is(.unpaid) {
        fill: ${colorTheme.table.tableTr.dangerText};
      }
    }

    & + tr td {
      border-top: 1px solid transparent;
    }

    td:first-child {
      border-radius: 0.6em 0 0 0.6em;
      border-left: 1px solid ${colorTheme.table.tableTr.active.border};
    }

    td:last-child {
      border-radius: 0 0.6em 0.6em 0;
      border-right: 1px solid ${colorTheme.table.tableTr.active.border};
    }

    span {
      background: ${colorTheme.table.tableTr.active.border};
    }

    td.disabled .delivery {
      background-color: ${colorTheme.table.tableTr.active.backgroundOrderType};

      svg {
        fill: white !important;
      }
    }

		td.disabled .delivery-text {
			color: ${colorTheme.table.tableTr.active.backgroundOrderType};
		}

    td .order-type {
      color: ${colorTheme.table.tableTr.active.backgroundOrderType};
			background-color: ${colorTheme.table.tableTr.active.backgroundOrderType};

			svg {
				fill: white !important;
			}
    }

    td .delivery {
      color: ${colorTheme.table.tableTr.active.backgroundOrderType};
    }

    td .order-type-text, .delivery-text {
      color: ${colorTheme.table.tableTr.active.backgroundOrderType};
    }
  }

  &.small {
    font-size: 0.6em;
    vertical-align: middle;
  }

  &.td_hide {
    td:not(:last-child) {
      display: none;
    }

    td:last-child {
      width: 100%;
    }

  }

  &.dash-border td {
    border-top: 1px dashed ${colorTheme.table.tableTr.borderBottom};
  }

  &.bold-border-desktop td {
    border-top: 2px solid ${colorTheme.table.tableTr.boldBorder};
  }


  &.bold-border-desktop:first-child td {
    border-top: none;
  }

  &.bold-border-desktop:last-child td {
    border-bottom: 2px solid ${colorTheme.table.tableTr.boldBorder};
  }

  @media screen and (max-width: ${theme.point820}) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    position: relative;
    height: auto;
    overflow: hidden;
    border: none;
    border-bottom: 1px solid ${colorTheme.table.tableTr.mobileBorder};
    border-radius: 0;
    background-color: transparent;
    padding: 1.1em 0;
    row-gap: 1.14em;
    column-gap: .36em;

    &.active {
      background: transparent;
    }

    &.bold-border {
      border-top: 2px solid rgb(0, 0, 0);
      border-bottom: 1px solid transparent;
    }

    &.bold-border:first-child {
      border-top: none;
    }

    &.bold-border:last-child {
      border-bottom: 2px solid ${colorTheme.table.tableTr.boldBorder};
    }

    &.dash-border {
      border-top: 1px dashed ${colorTheme.table.tableTr.borderBottom};
      border-bottom: 1px solid transparent;
    }

    &.bold-border + .dash-bord:first-child {
      border-top: 1px dashed ${colorTheme.table.tableTr.borderBottom};
    }

    &.last-group {
      border-bottom: 3px solid ${colorTheme.table.tableTr.mobileBorder} !important;
    }

    &.header {
      border-bottom: none;

      > td {
        color: ${colorTheme.table.tableTr.mobileTitle} !important;
        padding: 1.12em 0.75em 0 0 !important;
        justify-content: flex-end;
        min-height: auto;
      }

      > td:nth-child(2) {
        padding-left: 0.5em !important;
      }
    }

    &.small {
      height: auto;
    }

    &.platform {
      column-gap: .71em;
      margin-top: 1.17em;
      padding: 0;
      row-gap: 0;

      &:first-child {
        margin-top: 2.5em;
      }
    }

    &.row-tablet-cols-1 {
      grid-template-columns: 1fr;
    }

    &.row-tablet-cols-2 {
      grid-template-columns: 1fr 1fr;
    }

    &.row-tablet-cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.row-tablet-cols-4 {
      grid-template-columns: repeat(4, 1fr);
    }

    &.row-tablet-cols-half-5 {
      grid-template-columns: 0.5fr repeat(5, 1fr);
    }

		&.row-tablet-cols-4-1-half-1-1 {
			grid-template-columns: 1fr 0.5fr 1fr 1fr;
		}
  }

  @media screen and (max-width: ${theme.point720}) {
    padding: 1.25em 0;

    &.row-mob-cols-6 {
      grid-template-columns: repeat(6, 1fr);
    }

    &.row-mob-cols-4 {
      grid-template-columns: repeat(4, 1fr);
    }

    &.row-mob-cols-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.row-mob-cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.row-mob-cols-1 {
      grid-template-columns: 1fr;
    }

    &.row-mob-cols-half-2 {
      grid-template-columns: 0.5fr repeat(2, 1fr);
    }
  }

  &.platform {
    margin-top: 1.25em;
  }
`
TableTrStyled.displayName = 'TableTrStyled'
