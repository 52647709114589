import React from 'react'
import { TableOrderMobileTitle } from '../index.styled'
import DriverReturnTime from '../../TableTdElements/DriverReturnTime'
import { useSelector } from 'react-redux'
import { selectLastOrderIdByDriver } from '../../../../../redux/selector/order'
import PlatformDriverArriveTime from '../../TableTdElements/PlatformDriverArriveTime'
import DayCounter from '../../TableTdElements/OrderDayCounter'
import OrderDate from '../../TableTdElements/OrderDate'
import PlatformType from '../../TableTdElements/PlatformType'
import OrderPayment from '../../TableTdElements/OrderPayment'
import CustomerData from '../../TableTdElements/CustomerData'
import PartnerDriver from '../../TableTdElements/PartnerDriver'
import OrderType from '../../TableTdElements/OrderType'
import OrderStatus from '../../TableTdElements/OrderStatus'
import { selectIsTestCompany } from '../../../../../redux/selector/system'
import PartnerDriverTracker from '../../TableTdElements/PartnerDriverTracker'
import { TableTd, TableTdStatus } from '../../style/table/td/index.styled'

const OrdersTableTdList = React.memo(({ tableHeadersStatic, item }) => {
	const lastOrderIdThisDriverCompleted = useSelector(selectLastOrderIdByDriver(item.processedBy))
	const isCanceled = item?.status?.status === 'CANCELED'
	const isDisabled = isCanceled || !!item?.zNumber
	const beingPreparedOrder = ['BEING_PREPARED', 'READY'].includes(item.status.status)
	const lastTableHeaderIndex = tableHeadersStatic.length - 1
	const partnerDeliveryMetaData = item?.deliveryMetaData?.partner
	const isDeliveryByPlatform = item?.orderType === 'DELIVERY_BY_PLATFORM'
	const updatedDeliveryByPlatform = !!(item?.orderType === 'DELIVERY' && partnerDeliveryMetaData && partnerDeliveryMetaData?.pickupEta)
	const isPlatformDriverOrder = isDeliveryByPlatform || updatedDeliveryByPlatform
	const driverArriveTime = isDeliveryByPlatform ? item?.platformMetaData?.estimatedProcessTimestamp : partnerDeliveryMetaData?.pickupEta
	const isTestCompany = useSelector(selectIsTestCompany)

	return (
		<>
			<TableTd data-title={tableHeadersStatic[0].title}>
				<TableOrderMobileTitle>{tableHeadersStatic[0].title} </TableOrderMobileTitle>
				<DayCounter item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[1].title}>
				<TableOrderMobileTitle>{tableHeadersStatic[1].title}</TableOrderMobileTitle>
				<OrderDate item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[2].title} className="col-tablet-3-5">
				<TableOrderMobileTitle>{tableHeadersStatic[2].title} </TableOrderMobileTitle>
				<PlatformType item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[3].title} className="col-tablet-1-3 col-mob-1-5 row-mob-2">
				<TableOrderMobileTitle>{tableHeadersStatic[3].title} </TableOrderMobileTitle>
				<CustomerData item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[4].title} className="col-tablet-3-4 col-mob-2 ">
				<TableOrderMobileTitle>{tableHeadersStatic[4].title} </TableOrderMobileTitle>
				<OrderPayment item={item} />
			</TableTd>
			{isTestCompany &&
				<TableTd data-title={tableHeadersStatic[5].title} className="col-tablet-4-5 col-mob-3-5">
					<TableOrderMobileTitle>{tableHeadersStatic[5].title}</TableOrderMobileTitle>
					{(isPlatformDriverOrder || partnerDeliveryMetaData?.estimatedDeliveryCost || partnerDeliveryMetaData?.deliveryCost) &&
						<PartnerDriver item={item} />}
				</TableTd>}
			<TableTd data-title={tableHeadersStatic[lastTableHeaderIndex - 1].title} className={'col-tablet-1-2 col-mob-1-5' + (isDisabled ? ' disabled' : '')}>
				<TableOrderMobileTitle>{tableHeadersStatic[lastTableHeaderIndex - 1].title}</TableOrderMobileTitle>
				{(isTestCompany && partnerDeliveryMetaData?.deliveryId) ?
					<PartnerDriverTracker item={item} /> :
					<>
						<OrderType item={item} />
						{lastOrderIdThisDriverCompleted === item.orderId &&
							<DriverReturnTime duration={item.customer.geoData?.duration}
																modifiedDate={item.status?.modifiedDate} />}
						{isPlatformDriverOrder && beingPreparedOrder &&
							<PlatformDriverArriveTime estimatedProcessTimestamp={driverArriveTime}
																				platform={item?.platformMetaData?.platformType} />}
					</>}
			</TableTd>
			<TableTdStatus data-title={tableHeadersStatic[lastTableHeaderIndex].title}
										 className={'const-color col-tablet-3-5 col-mob-1-5 ' + item?.status.status}>
				<TableOrderMobileTitle>{tableHeadersStatic[lastTableHeaderIndex].title} </TableOrderMobileTitle>
				<OrderStatus item={item} />
			</TableTdStatus>
		</>
	)
})

OrdersTableTdList.displayName = 'OrdersTableTdList'
export default OrdersTableTdList
