import { batch } from 'react-redux'
import { toast } from 'react-toastify'
import postDesktopUser from '../../../axios/registration/desktop/post'
import fetchAllUsers from '../../../axios/registration/user'
import deleteUser from '../../../axios/registration/user/delete'
import putDesktopUser from '../../../axios/registration/desktop/put'
import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import { setAllDesktopUsers, setDesktopClientJson, setDesktopIsConnecting, setDesktopShowModal } from '../../../action/desktop'
import axios from 'axios'

export const postDesktopUserThunk = (login) => dispatch => {
	return postDesktopUser(login)
		.then(res => {
			toast.success('Desktop client created')
			batch(() => {
				dispatch(fetchAllDesktopUsersThunk())
				dispatch(setDesktopClientJson(res.data.token))
				dispatch(setDesktopShowModal(true))
			})
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const putDesktopUserThunk = (id) => dispatch => {
	return putDesktopUser(id)
		.then(res => {
			dispatch(connectDesktopClientThunk(res.data.token))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const connectDesktopClientThunk = (token) => (dispatch, getState) => {
	if (!getState().desktop.desktopIsConnecting) {
		dispatch(setDesktopIsConnecting(true))
		axios.get('http://127.0.0.1:18080/auth?code=' + token)
			.then(res => {
				toast.success('Desktop client connection success')
				dispatch(setDesktopIsConnecting(false))
			})
			.catch(error => {
				toast.error('Failed to connect desktop client')
				dispatch(setDesktopIsConnecting(false))
			})
	}
}

export const fetchAllDesktopUsersThunk = () => dispatch => {
	return fetchAllUsers('DESKTOP_CLIENT_CONTEXT', null, 0, 20)
		.then(res => {
			batch(() => {
				dispatch(setAllDesktopUsers(res.data))
			})
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteDesktopUserThunk = (id) => dispatch => {
	return deleteUser(id)
		.then(res => {
			toast.success('Desktop client deleted')
			dispatch(fetchAllDesktopUsersThunk())
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
