import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const RecaptchaWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	gap: 0.38em;
	align-items: flex-start;
	text-align: start;

	> svg {
		width: 1.25em;
		height: 1.25em;
	}

	@media screen and (max-width: ${theme.point820}) {
		> svg {
			width: 1.43em;
			height: 1.43em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		> svg {
			width: 1.25em;
			height: 1.25em;
		}
	}

	@media screen and (max-width: ${theme.point520}) {
		&.main {
			padding: 0 1.25em;
		}
	}
`
RecaptchaWrapper.displayName = 'RecaptchaWrapper'

export const RecaptchaText = styled.div`
	font-family: ${theme.fontRoboto};
	font-size: 0.75em;
	letter-spacing: -0.3px;
	color: ${colorTheme.recaptcha.text};
	width: 29em;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 0.86em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 0.75em;
	}

	@media screen and (max-width: ${theme.point400}) {
		&.main {
			width: 100%;
		}
	}
`
RecaptchaText.displayName = 'RecaptchaText'
