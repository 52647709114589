import React from 'react'

export const Table6ChairsSvg = ({ chairCount, className }) => {
	const chairClassname = (chairNumber) => {
		return chairNumber <= chairCount ? '' : 'hidden'
	}
	return (
		<svg  viewBox="0 0 332 200" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g className={chairClassname(6)}>
				<path d="M3.8999 120.1V79.9004C3.8999 76.6004 6.5999 73.9004 9.8999 73.9004H50.0999C53.3999 73.9004 56.0999 76.6004 56.0999 79.9004V120.1C56.0999 123.4 53.3999 126.1 50.0999 126.1H9.8999C6.6999 126.1 3.8999 123.3 3.8999 120.1Z" fill="#7A7A7A" />
				<path d="M50.1 73.9C53.4 73.9 56.1 76.6 56.1 79.9V120.1C56.1 123.4 53.4 126.1 50.1 126.1H9.9C6.6 126.1 3.9 123.4 3.9 120.1V79.9C3.9 76.6 6.6 73.9 9.9 73.9H50.1ZM50.1 70H9.9C4.4 70 0 74.4 0 79.9V120.1C0 125.6 4.4 130 9.9 130H50.1C55.6 130 60 125.6 60 120.1V79.9C60 74.4 55.6 70 50.1 70Z" fill="white" />
			</g>
			<g className={chairClassname(4)}>
				<path d="M73.8999 190.1V149.9C73.8999 146.6 76.5999 143.9 79.8999 143.9H120.1C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.8999C76.6999 196.1 73.8999 193.3 73.8999 190.1Z" fill="#7A7A7A" />
				<path d="M120.1 143.9C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.9C76.6 196.1 73.9 193.4 73.9 190.1V149.9C73.9 146.6 76.6 143.9 79.9 143.9H120.1ZM120.1 140H79.9C74.4 140 70 144.4 70 149.9V190.1C70 195.6 74.4 200 79.9 200H120.1C125.6 200 130 195.6 130 190.1V149.9C130 144.4 125.6 140 120.1 140Z" fill="white" />
			</g>
			<g className={chairClassname(1)}>
				<path d="M73.8999 50.1004V9.90039C73.8999 6.60039 76.5999 3.90039 79.8999 3.90039H120.1C123.4 3.90039 126.1 6.60039 126.1 9.90039V50.1004C126.1 53.4004 123.4 56.1004 120.1 56.1004H79.8999C76.6999 56.1004 73.8999 53.3004 73.8999 50.1004Z" fill="#7A7A7A" />
				<path d="M120.1 3.9C123.4 3.9 126.1 6.6 126.1 9.9V50.1C126.1 53.4 123.4 56.1 120.1 56.1H79.9C76.6 56.1 73.9 53.4 73.9 50.1V9.9C73.9 6.6 76.6 3.9 79.9 3.9H120.1ZM120.1 0H79.9C74.4 0 70 4.4 70 9.9V50.1C70 55.6 74.4 60 79.9 60H120.1C125.6 60 130 55.6 130 50.1V9.9C130 4.4 125.6 0 120.1 0Z" fill="white" />
			</g>
			<g className={chairClassname(5)}>
				<path d="M328.1 120.1V79.9004C328.1 76.6004 325.4 73.9004 322.1 73.9004H281.9C278.6 73.9004 275.9 76.6004 275.9 79.9004V120.1C275.9 123.4 278.6 126.1 281.9 126.1H322.1C325.3 126.1 328.1 123.3 328.1 120.1Z" fill="#7A7A7A" />
				<path d="M281.9 73.9C278.6 73.9 275.9 76.6 275.9 79.9V120.1C275.9 123.4 278.6 126.1 281.9 126.1H322.1C325.4 126.1 328.1 123.4 328.1 120.1V79.9C328.1 76.6 325.4 73.9 322.1 73.9H281.9ZM281.9 70H322.1C327.6 70 332 74.4 332 79.9V120.1C332 125.6 327.6 130 322.1 130H281.9C276.4 130 272 125.6 272 120.1V79.9C272 74.4 276.4 70 281.9 70Z" fill="white" />
			</g>
			<g className={chairClassname(2)}>
				<path d="M258.1 190.1V149.9C258.1 146.6 255.4 143.9 252.1 143.9H211.9C208.6 143.9 205.9 146.6 205.9 149.9V190.1C205.9 193.4 208.6 196.1 211.9 196.1H252.1C255.3 196.1 258.1 193.3 258.1 190.1Z" fill="#7A7A7A" />
				<path d="M211.9 143.9C208.6 143.9 205.9 146.6 205.9 149.9V190.1C205.9 193.4 208.6 196.1 211.9 196.1H252.1C255.4 196.1 258.1 193.4 258.1 190.1V149.9C258.1 146.6 255.4 143.9 252.1 143.9H211.9ZM211.9 140H252.1C257.6 140 262 144.4 262 149.9V190.1C262 195.6 257.6 200 252.1 200H211.9C206.4 200 202 195.6 202 190.1V149.9C202 144.4 206.4 140 211.9 140Z" fill="white" />
			</g>
			<g className={chairClassname(3)}>
				<path d="M258.1 50.1004V9.90039C258.1 6.60039 255.4 3.90039 252.1 3.90039H211.9C208.6 3.90039 205.9 6.60039 205.9 9.90039V50.1004C205.9 53.4004 208.6 56.1004 211.9 56.1004H252.1C255.3 56.1004 258.1 53.3004 258.1 50.1004Z" fill="#7A7A7A" />
				<path d="M211.9 3.9C208.6 3.9 205.9 6.6 205.9 9.9V50.1C205.9 53.4 208.6 56.1 211.9 56.1H252.1C255.4 56.1 258.1 53.4 258.1 50.1V9.9C258.1 6.6 255.4 3.9 252.1 3.9H211.9ZM211.9 0H252.1C257.6 0 262 4.4 262 9.9V50.1C262 55.6 257.6 60 252.1 60H211.9C206.4 60 202 55.6 202 50.1V9.9C202 4.4 206.4 0 211.9 0Z" fill="white" />
			</g>
			<path d="M297.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.7001 176 34.1001 176H298C303.5 176 308 171.5 308 166V34C308 28.5 303.4 24 297.9 24Z" fill="#7A7A7A" />
			<path d="M297.9 20H34.1C26.3 20 20 26.3 20 34V166C20 173.7 26.3 180 34.1 180H298C305.8 180 312.1 173.7 312.1 166V34C312 26.3 305.7 20 297.9 20ZM308 166C308 171.5 303.4 176 298 176H34.1C28.6 176 24.1 171.5 24.1 166V34C24.1 28.5 28.7 24 34.1 24H298C303.5 24 308 28.5 308 34V166Z" fill="white" />
			<path className="colorTable" d="M297.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.7001 176 34.1001 176H298C303.5 176 308 171.5 308 166V34C308 28.5 303.4 24 297.9 24ZM297.3 165.3H34.7001V34.7H297.2V165.3H297.3Z" fill="#38CD90" />
		</svg>
	)
}
Table6ChairsSvg.displayName = 'Table6ChairsSvg'
