import styled from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'

export const PinCodeModalLoginWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 1.88em;
	padding: 0 1.06em;

	@media screen and (max-width: ${theme.point820}) {
		gap: 2.14em;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 1.88em;
	}
`
PinCodeModalLoginWrapper.displayName = 'PinCodeModalLoginWrapper'

export const PinCodeInfoText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1em;
	font-weight: 500;
	color: ${colors.black};
	text-align: center;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.14em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1em;
	}
`
PinCodeInfoText.displayName = 'PinCodeInfoText'

export const ErrorMessageWrapper = styled.div`
	font-size: 1em;
	display: flex;
	gap: 0.44em;
	align-items: start;
	align-self: center;
	margin-top: -1.25em;

	svg {
		width: 1.25em;
		height: 1.25em;
		flex-shrink: 0;
		fill: ${colors.red};
	}
`
ErrorMessageWrapper.displayName = 'ErrorMessageWrapper'

export const ErrorMessageText = styled.div`
	font-family: ${theme.fontRobotoRegular};
	font-size: 1em;
	font-weight: 400;
	color: ${colors.red};
	text-align: left;
	max-width: 20em;
`
ErrorMessageText.displayName = 'ErrorMessageText'

