import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setModalOrderCustomer } from '../../../../../redux/action/customer'
import ButtonsModal from '../../../../Elements/modal/ButtonsModal'
import { printOrderThunk } from '../../../../../redux/thunk/order'

const EVENT_Z_INDEX = 22

const PrintCanceledOrder = React.memo(() => {
	const selectedOrder = useSelector(store => store.order.selectedOrder)
	const showPrintCanceledOrder = useSelector(store => store.customerStore.modal.showPrintCanceledOrder)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleBack = useCallback(() => {
		dispatch(setModalOrderCustomer({ showPrintCanceledOrder: false }))
		dispatch(setModalOrderCustomer({ showOrder: true }))
	}, [dispatch])

	const printOrderClick = useCallback(async () => {
		await dispatch(printOrderThunk(selectedOrder?.orderId, false))
		dispatch(setModalOrderCustomer({ showPrintCanceledOrder: false }))
	}, [selectedOrder?.orderId, dispatch])

	const printCanceledOrderClick = useCallback(async () => {
		await dispatch(printOrderThunk(selectedOrder?.orderId, true))
		dispatch(setModalOrderCustomer({ showPrintCanceledOrder: false }))
	}, [selectedOrder?.orderId, dispatch])

	const buttons = useMemo(() => [
		{ icon: 'print', text: t('order.modal.print_order'), onClick: printOrderClick, show: true },
		{ icon: 'print', text: t('order.modal.print_canceled_order'), onClick: printCanceledOrderClick, show: true },
	], [printOrderClick, printCanceledOrderClick, t])

	return (
		<ButtonsModal isOpen={showPrintCanceledOrder}
									close={handleBack}
									title={t('order.modal.want_cancel_order')}
									size="small"
									zIndex={EVENT_Z_INDEX}
									returnZIndex={1}
									buttons={buttons} />
	)
})

PrintCanceledOrder.displayName = 'PrintCanceledOrder'
export default PrintCanceledOrder
