import React from 'react'
import { CartItemAlignWrapper, CartProductBlock, CartProductQuantity, CartProductTitle, CartProductTitleBlock, CartProductWrapper, CartTopping, CartToppings, CartToppingTitle } from '../index.styled'
import ArrowButton from '../ArrowButton'
import { useTranslation } from 'react-i18next'
import KitchenStatusCircle from '../../KitchenStatusCircle'
import CartDishCourse from '../CartDishCourse'
import PreparedMessage from '../PreparedMessage'
import Button from '../../../../Elements/buttons/Button'
import { useSelector } from 'react-redux'
import Loader from '../../../../Elements/loaders'

const CartProduct = React.memo(({
	item, index, includeStatus, enabledDishCourse, isMenuProduct = false, isMenuCanceled,
	direction, onDirectionClick, onPreparedClick,
}) => {
	const { t } = useTranslation()
	const testId = (isMenuProduct ? 'menu-product-' : 'product-') + index
	const isBeingPreparedItem = item?.status === 'BEING_PREPARED'
	const isPlacedItem = item?.status === 'PLACED'
	const isPreparedItem = item?.status === 'PREPARED'
	const isCanceledItem = item?.status === 'CANCELED'
	const itemWithToppings = item?.items.length > 0
	const itemHasDishCourse = enabledDishCourse || item?.dishCourse
	const layout = (isMenuProduct ? 'menu ' : '') +
		(isCanceledItem ? 'canceled ' + (itemWithToppings ? 'middle ' : '') : '') +
		((includeStatus && itemHasDishCourse) ? 'with-dish-course-and-status ' : '') +
		((includeStatus && !itemHasDishCourse) ? 'with-status ' : '') +
		((!includeStatus && itemHasDishCourse) ? 'with-dish-course ' : '')
	const alignLayout = itemWithToppings ? 'start ' : ''
	const titleLayout = layout + (isMenuCanceled ? 'canceled ' : '')
	const loadingItemId = useSelector(state => state.tableCart.loadingItemId)

	return (
		<>
			<CartProductWrapper className={layout} data-testid={testId}>
				{itemHasDishCourse && <CartItemAlignWrapper className={alignLayout}><CartDishCourse item={item} /></CartItemAlignWrapper>}
				{includeStatus && <CartItemAlignWrapper className={alignLayout}><KitchenStatusCircle testId={`${testId}-status-${item.status}`} status={item.status} /></CartItemAlignWrapper>}
				<CartProductBlock className={layout}>
					<CartProductTitleBlock>
						<CartProductTitle className={titleLayout}>{item?.name}</CartProductTitle>
						<CartProductQuantity data-testid={testId + '-quantity'} className={layout}>x{item?.quantity}</CartProductQuantity>
					</CartProductTitleBlock>
					{item?.items?.length > 0 &&
						<CartToppings className={layout}>
							{item?.items?.map((subItem, subItemIndex) =>
								<CartTopping key={subItemIndex}>
									<CartToppingTitle className={titleLayout}>{subItem.name} x{subItem.quantity}</CartToppingTitle>
								</CartTopping>)}
						</CartToppings>}
				</CartProductBlock>
				{!isMenuProduct &&
					<CartItemAlignWrapper className={alignLayout + 'mobile-ml-auto'}>
						{isPlacedItem && <ArrowButton testId={`${testId}-${direction}`} direction={direction} onClick={(e) => onDirectionClick(e, direction)} />}
						{isBeingPreparedItem &&
							<Button name="prepared" icon={loadingItemId !== item.itemPositionId && 'checkmark'} color="green" size="small"
											text={loadingItemId !== item.itemPositionId && t('restaurant.sendToKitchenModal.button.prepared')}
											onClick={onPreparedClick}>
								{loadingItemId === item.itemPositionId && <Loader zoom={0.5}/>}
							</Button>}
						{isPreparedItem && <PreparedMessage />}
					</CartItemAlignWrapper>}
			</CartProductWrapper>
		</>
	)
})

CartProduct.displayName = 'CartProduct'
export default CartProduct
