import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const DropDownButtonWrap = styled.div`
  font-size: 1.38rem;
  width: 100%;
  height: 2.45em;
  background: ${colorTheme.dropDownButton.background};
  border: 1px solid ${colorTheme.dropDownButton.border};
  border-radius: 0.55em;
  line-height: 1.18em;
  font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0.91em;
  transition: all 0.2s;
	color: ${colorTheme.dropDownButton.text};

  svg {
    margin-right: 0.36em;
    transition: all 0.2s;

    path {
      fill: ${colorTheme.dropDownButton.icon};
    }
  }

  &.active {
    background: ${colorTheme.dropDownButton.active.background};
    border: 1px solid ${colorTheme.dropDownButton.active.border};
    color: ${colorTheme.dropDownButton.active.text};

    svg {
      transform: rotate(180deg);

      path {
        fill: ${colorTheme.dropDownButton.active.icon};
      }
    }
  }

  @media screen and (max-width: ${theme.point820}) {
		font-size: 1.58em;
  }

  @media screen and (max-width: ${theme.point720}) {
		font-size: 1.38em;
    margin-bottom: 0.91em;
  }
`
