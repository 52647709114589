import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const PreOrderTypeTr = styled.tr`
	font-size: 1rem;
`

export const PreOrderTypeTd = styled.td.attrs({
  colSpan: 8
})`
	padding: 1.18em 0 1.12em;
	&.border {
		border-top: 1px solid ${colorTheme.table.preOrderType.border};
	}
`

export const PreOrderTypeWrap = styled.div`
	background: ${colorTheme.table.preOrderType.background};
	padding: 0.63em 1.25em;
	border-radius: 0.63em;
	width: max-content;
`

export const PreOrderTypeText = styled.div`
	color: ${colorTheme.table.preOrderType.text};
	font-size: 1.25em;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
`
