import React from 'react'
import CartMenu from './CartMenu'
import CartProduct from './CartProduct'

const CartItem = React.memo(({
	item, index, includeStatus,
	enabledDishCourse, direction,
	onDirectionClick, onPreparedClick,
}) => {
	return (
		item?.type === 'MENU' ?
			<CartMenu item={item} index={index} includeStatus={includeStatus}
								enabledDishCourse={enabledDishCourse} direction={direction}
								onDirectionClick={onDirectionClick}
								onPreparedClick={onPreparedClick} /> :
			<CartProduct item={item} index={index} includeStatus={includeStatus}
									 enabledDishCourse={enabledDishCourse}
									 direction={direction}
									 onDirectionClick={onDirectionClick}
									 onPreparedClick={onPreparedClick} />
	)
})

CartItem.displayName = 'CartItem'
export default CartItem
