import customerInstance from '../instance'

const getDeliveryConfig = (distance, lat, lon) => {
	return customerInstance.get('/deliveryConfigs'
	+ (distance >= 0 ? '?distance=' + distance : '')
	+ (lat ? '&lat=' + lat : '')
	+ (lon ? '&lon=' + lon : ''))
}

export default getDeliveryConfig
