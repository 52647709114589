import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { DatePickerHeaderWrapper, DatePickerSelect, DatePickerTimeWrapper, DatePickerWrapper } from './index.styled'
import colors from '../../../style/colors'
import { de, enGB, ru } from 'date-fns/locale'
import { getHourString, getMinuteString } from '../../../helper'

registerLocale('de', de)
registerLocale('ru', ru)
registerLocale('en', enGB)

const CustomDatePicker = ({ date, setDate, customInput, dateFormat, minDate = null, maxDate = null, size, showMonthYearPicker }) => {
	const [language, setLanguage] = useState('de')

	useEffect(() => {
		const locale = window.localStorage.getItem('i18nextLng')
		setLanguage(locale)
		// eslint-disable-next-line
	}, [window.localStorage.getItem('i18nextLng')])

	const isBig = size !== 'small'
	return (
		<DatePickerWrapper className={size}>
			<DatePicker selected={date}
									onChange={(date) => setDate(date)}
									inline={isBig}
									isClearable={isBig}
									showMonthYearPicker={showMonthYearPicker}
									dateFormat={dateFormat || 'MM/dd/yyyy h:mm aa'}
									customInput={customInput}
									showTimeInput={isBig}
									locale={language}
									timeInputLabel=""
									minDate={minDate}
									maxDate={maxDate}
									renderCustomHeader={DatePickerHeader}
									customTimeInput={<DatePickerTime />}
									 />
		</DatePickerWrapper>
	)
}

export const DatePickerHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
	const locale = window.localStorage.getItem('i18nextLng')
	const monthNames = locale === 'en' ? ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] :
		locale === 'ru' ? ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'] :
			['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']

	return (
		<DatePickerHeaderWrapper>
			<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="prev" type="button">
				<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
					<path d="M1 15L8 7.99746L1 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</button>
			<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="img">
				<path d="M4.08 3.31V0.93C4.08 0.42 4.5 0 5.01 0C5.52 0 5.94 0.42 5.94 0.93V3.31C5.94 3.82 5.52 4.24 5.01 4.24C4.5 4.23 4.08 3.82 4.08 3.31ZM12.07 4.23C12.58 4.23 13 3.81 13 3.3V0.93C13 0.42 12.58 0 12.07 0C11.56 0 11.14 0.42 11.14 0.93V3.31C11.14 3.82 11.56 4.23 12.07 4.23ZM17.08 2.11V14.67C17.08 15.08 16.75 15.41 16.34 15.41H0.74C0.33 15.41 0 15.08 0 14.67V2.11C0 1.7 0.33 1.3 0.74 1.3H2.48C2.89 1.3 3.22 1.63 3.22 2.04C3.22 2.45 2.89 2.78 2.48 2.78H1.49V5.94H15.6V2.78H14.6C14.19 2.78 13.86 2.45 13.86 2.04C13.86 1.63 14.19 1.3 14.6 1.3H16.34C16.75 1.3 17.08 1.7 17.08 2.11ZM15.6 13.93V7.24H1.49V13.92H15.6V13.93ZM7.37 2.78H9.71C10.12 2.78 10.45 2.45 10.45 2.04C10.45 1.63 10.12 1.3 9.71 1.3H7.37C6.96 1.3 6.63 1.63 6.63 2.04C6.63 2.45 6.96 2.78 7.37 2.78Z" />
			</svg>
			{monthNames[date.getMonth()]} {date.getFullYear()}
			<button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="next" type="button">
				<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 15L8 7.99746L1 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</button>
		</DatePickerHeaderWrapper>
	)
}
DatePickerHeader.displayName = 'DatePickerHeader'

const DatePickerTime = ({ date, onChange }) => {
	const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
	const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]

	const hourValue = date.getHours()
	const minuteValue = date.getMinutes()

	return (
		<DatePickerTimeWrapper>
			<DatePickerSelect key="hour">
				<svg className="clock" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 237.54 237.54" width="30px" height="30px">
					<path fill={colors.gray} d="M118.77,0c32.8,0,62.49,13.29,83.98,34.79c21.49,21.49,34.79,51.19,34.79,83.98s-13.29,62.49-34.79,83.98 c-21.49,21.49-51.19,34.79-83.98,34.79c-32.8,0-62.49-13.29-83.98-34.79C13.29,181.26,0,151.56,0,118.77s13.29-62.49,34.79-83.98 C56.28,13.29,85.97,0,118.77,0L118.77,0z M109.06,60.2c0-3.59,2.91-6.5,6.5-6.5s6.5,2.91,6.5,6.5v60l45.14,26.76 c3.08,1.82,4.11,5.8,2.29,8.89c-1.82,3.08-5.8,4.11-8.89,2.29l-47.99-28.45c-2.11-1.08-3.55-3.27-3.55-5.79V60.2L109.06,60.2z M193.56,43.98C174.42,24.84,147.98,13,118.77,13c-29.21,0-55.65,11.84-74.79,30.98C24.84,63.12,13,89.56,13,118.77 c0,29.21,11.84,55.65,30.98,74.79c19.14,19.14,45.58,30.98,74.79,30.98c29.21,0,55.65-11.84,74.79-30.98 c19.14-19.14,30.98-45.58,30.98-74.79C224.54,89.56,212.7,63.12,193.56,43.98L193.56,43.98z" />
				</svg>
				<select name="hour" className="hour" value={hourValue || 0}
								onChange={e => onChange(e.target.value + ':' + getMinuteString(minuteValue))}>
					{hours.map(hour =>
						<option key={hour} value={hour}>{getHourString(hour)}</option>)}
				</select>
				<svg className="arrow" width="14" height="24" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }}>
					<path d="M1 15L8 7.99746L1 1" stroke={colors.blue} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</DatePickerSelect>
			<DatePickerSelect key="minute">
				<select name="minute" className="minute" value={minuteValue || 0}
								onChange={e => onChange(getHourString(hourValue) + ':' + e.target.value)}>
					{minutes.map(minute =>
						<option key={minute} value={minute}>{getMinuteString(minute)}</option>)}
				</select>
				<svg className="arrow" width="14" height="24" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }}>
					<path d="M1 15L8 7.99746L1 1" stroke={colors.blue} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</DatePickerSelect>
		</DatePickerTimeWrapper>
	)
}
DatePickerTime.displayName = 'DatePickerTime'

CustomDatePicker.displayName = 'CustomDatePicker'
export default CustomDatePicker
