import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ToggleButtonWrapper = styled.div`
	font-size: 1rem;
	background-color: transparent;
	border: 1px solid ${colorTheme.toggleButton.border};
	border-radius: 0.75em;
	padding: 0.25em;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(${props => props.cols}, 1fr);
	gap: 0.31em;
	height: 100%;
	min-height: 2.7em;
	cursor: pointer;
	position: relative;
	z-index: 0;

	&.medium {
		min-height: 3em;
	}

	&.big {
		min-height: 4.19em;
	}

	&.disabled {
		border-color:${colorTheme.toggleButton.disabled};
	}

	&.withoutBorder {
		border-color: transparent;
	}

	&.filledBackground {
		background-color: ${colorTheme.toggleButton.filledBackground};
	}

	@media screen and (max-width: ${theme.point820}) {
		&.medium {
			min-height: 3.42em;
		}

		&.big {
			min-height: 3.93em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0;
		padding: 2px;

		&.medium {
			min-height: 3em;
		}

		&.big {
			min-height: 3.44em;
		}
	}
`
ToggleButtonWrapper.displayName = 'ToggleButtonWrapper'

export const ToggleBlock = styled.div`
	position: absolute;
	top: 0.25em;
	height: calc(100% - 0.5em);
	background-color: ${colorTheme.toggleButton.background};
	border-radius: 0.5em;
	z-index: 0;
	transition: left 0.2s;
	width: calc((100% - 0.5em - 0.31em * ${props => props.cols - 1}) / ${props => props.cols});
	left: calc((100% - 0.5em - 0.31em * ${props => props.cols - 1}) / ${props => props.cols} * ${props => props.toggleCol} + 0.25em + 0.31em * ${props => props.toggleCol});

	&.disabled {
		background: ${colorTheme.toggleButton.disabled};
	}

	@media screen and (max-width: ${theme.point720}) {
		border-radius: 9px;
		top: 3px;
		height: calc(100% - 6px);
		width: calc((100% - 6px) / ${props => props.cols});
		left: calc((100% - 6px) / ${props => props.cols} * ${props => props.toggleCol} + 3px);
	}
`
ToggleBlock.displayName = 'ToggleBlock'

export const ToggleItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.31em;
	padding: 0 0.63em;
	height: 100%;
	z-index: 1;
	justify-self: center;
	user-select: none;
`
ToggleItem.displayName = 'ToggleItem'

export const ToggleItemIcon = styled.svg`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.19em;
	height: 1.19em;

	svg {
		width: 100%;
		height: 100%;
	}

	&.blue {
		svg {
			fill: ${colorTheme.toggleButton.blueColor};
		}

		&.toggled {
			svg {
				fill: ${colorTheme.toggleButton.whiteColor};
			}
		}
	}

	&.gray {
		svg {
			fill: ${colorTheme.toggleButton.grayColor};
		}

		&.toggled {
			svg {
				fill: ${colorTheme.toggleButton.whiteColor};
			}
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		margin: 0;
	}
`
ToggleItemIcon.displayName = 'ToggleItemIcon'

export const ToggleItemText = styled.div`
	font-size: 1.06em;
	font-family: ${theme.fontRobotoRegular};
	color: ${colorTheme.toggleButton.whiteColor};

	&.big {
		font-size: 1.2em;
	}

	&.blue {
		&:not(.toggled) {
			color: ${colorTheme.toggleButton.blueColor};
		}
	}
	&.gray {
		&:not(.toggled) {
			color: ${colorTheme.toggleButton.grayColor};
		}
	}

	&.purple {
		&:not(.toggled) {
			color: ${colorTheme.toggleButton.purpleColor};
		}
	}

	&.font-14 {
		font-size: 0.88em;
	}
`
ToggleItemText.displayName = 'ToggleItemText'
