import React, { useCallback } from 'react'
import { NavImage, NavItem, NavTitle } from '../index.styled'
import { setToggled } from '../../../redux/action/system'
import { theme } from '../../../style/theme'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import OrdersNotification from './notification'
import { selectContainsAnyRole } from '../../../redux/selector/system'
import MenuIcon from '../../../img/svg/menu'

const NavListItem = React.memo(({ item }) => {
	const toggled = useSelector(store => store.system.toggled)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isTablet = useWindowBreakPoint(theme.point820)
	const hasRoleServiceWrite = useSelector(selectContainsAnyRole(['DELIVERY_SERVICE_WRITE']))

	const handleNavItemClick = useCallback(() => {
		if (isTablet) {
			dispatch(setToggled(true))
		}
	}, [isTablet, dispatch])

	return (
		<NavItem tabIndex="-1" to={item.link} activeClassName="active"
						 callback={handleNavItemClick} className={toggled ? 'toggled' : ''}>
			<NavImage>
				<MenuIcon icon={item.id}/>
			</NavImage>
			<NavTitle className={toggled ? 'toggled' : ''}>{t(item.title)}</NavTitle>
			{item.id === 'DELIVERY_SERVICE' && hasRoleServiceWrite &&
				<OrdersNotification />}
		</NavItem>
	)
})

NavListItem.displayName = 'NavListItem'
export default NavListItem
