import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import { useSearchParams } from 'react-router-dom'

const ProcessDriver = lazyWithRetry(() => import(/* webpackChunkName: "process-driver" */'../../component/ProcessDriver'))

const ProcessDriverPage = React.memo(() => {
	let [params] = useSearchParams()

	return (
		<Suspense fallback={<PageLoader />}>
			<ProcessDriver orderId={params.get('orderId')} lat={params.get('lat')} lon={params.get('lon')} />
		</Suspense>
	)
})

ProcessDriverPage.displayName = 'ProcessDriverPage'
export default ProcessDriverPage
