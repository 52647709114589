import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const OrderAndPaySettings = lazyWithRetry(() => import(/* webpackChunkName: "restaurant-cart-settings" */'../../component/OrderAndPaySettings'))

const OrderAndPaySettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<OrderAndPaySettings />
		</Suspense>
	)
})

OrderAndPaySettingsPage.displayName = 'OrderAndPaySettingsPage'
export default OrderAndPaySettingsPage
