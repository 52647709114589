import MainIcon from '../../../../../img/svg/main'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PartnerDriverTrackerIconWrapper, PartnerDriverTrackerText, PartnerDriverTrackerWrapper } from './index.styled'
import PartnerDriverTrackingModal from './PartnerDriverTrackingModal'
import TableTdElementsTooltip from '../TableTdElementsTooltip'

const PartnerDriverTracker = React.memo(({ item }) => {
	const { t } = useTranslation()
	const [partnerDriverTrackingModalIsOpen, setPartnerDriverTrackingModalIsOpen] = useState(false)
	const partnerDriverType = item?.deliveryMetaData?.partner?.type

	const openPartnerDriverTrackingModal = useCallback((e) => {
		e.stopPropagation()
		setPartnerDriverTrackingModalIsOpen(true)
	}, [])

	const handleClosePartnerDriverTrackingModal = useCallback((e) => {
		e.stopPropagation()
		setPartnerDriverTrackingModalIsOpen(false)
	}, [])

	return (
		<>
			<PartnerDriverTrackerWrapper>
				<TableTdElementsTooltip text={t(`order.table.platform_driver.${partnerDriverType}`)}>
					<PartnerDriverTrackerIconWrapper><MainIcon icon="motorbike" /></PartnerDriverTrackerIconWrapper>
				</TableTdElementsTooltip>
				<PartnerDriverTrackerText onClick={openPartnerDriverTrackingModal}>{t('order.table.partner_driver_tracker')}</PartnerDriverTrackerText>
			</PartnerDriverTrackerWrapper>

			<PartnerDriverTrackingModal isOpen={partnerDriverTrackingModalIsOpen}
																	close={handleClosePartnerDriverTrackingModal}
																	order={item} />
		</>
	)
})

PartnerDriverTracker.displayName = 'PartnerDriverTracker'
export default PartnerDriverTracker
