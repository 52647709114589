import marketingInstance from '../instance'

const fetchGiftCoupons = (page = 0, size = 10, status = null, code = null) => {
  return marketingInstance.get(
		'/giftCoupons'
		+ '?page=' + page
		+ '&size=' + size
		+ (status ? '&status=' + status : '')
		+ (code ? '&code=' + code : '')
	)
}

export default fetchGiftCoupons
