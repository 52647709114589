import { SET_CANCEL_REASON, SET_COMPANY_REGISTERED, SET_COMPANY_REGISTERED_LOADING, SET_LOGIN_DATA, SET_LOGIN_EXCHANGE_RATES, SET_LOGIN_FAILED, SET_LOGIN_INVALID_INDEX_ERROR, SET_LOGIN_INVALID_PASSWORD_ERROR, SET_LOGIN_LOADING, SET_LOGIN_SUCCESS, SET_LOGIN_USER, SET_LOGOUT, SET_ORDER_EVENT, SET_PAGE_AFTER_LOGIN, SET_REACT_APP_VERSION, SET_RECOVERY_COMPLETE, SET_REGISTRATION_COMPLETE, SET_REGISTRATION_LOADING, SET_REGISTRATION_OWNER_EMAIL, SET_REGISTRATION_OWNER_PHONE_NUMBER } from '../actionTypes'

export const setRegistrationComplete = content => ({ type: SET_REGISTRATION_COMPLETE, payload: { content } })

export const setLoginData = content => ({ type: SET_LOGIN_DATA, payload: { content } })

export const setLoginLoading = value => ({ type: SET_LOGIN_LOADING, value })

export const setLoginSuccess = content => ({ type: SET_LOGIN_SUCCESS, payload: { content } })

export const setRecoveryComplete = content => ({ type: SET_RECOVERY_COMPLETE, payload: { content } })

export const setCompanyRegistered = content => ({ type: SET_COMPANY_REGISTERED, payload: { content } })

export const setCompanyRegisteredLoading = content => ({ type: SET_COMPANY_REGISTERED_LOADING, payload: { content } })

export const setLoginUser = content => ({ type: SET_LOGIN_USER, payload: { content } })

export const setLogout = content => ({ type: SET_LOGOUT, payload: { content } })

export const setLoginFailed = content => ({ type: SET_LOGIN_FAILED, payload: { content } })

export const setLoginInvalidPasswordError = value => ({ type: SET_LOGIN_INVALID_PASSWORD_ERROR, value })

export const setLoginInvalidIndexError = value => ({ type: SET_LOGIN_INVALID_INDEX_ERROR, value })

export const setOrderEvent = content => ({ type: SET_ORDER_EVENT, payload: { content } })

export const setCancelReason = cancelReason => ({ type: SET_CANCEL_REASON, cancelReason })

export const setRegistrationOwnerEmail = content => ({ type: SET_REGISTRATION_OWNER_EMAIL, payload: { content } })

export const setExchangeRates = value => ({ type: SET_LOGIN_EXCHANGE_RATES, value })

export const setRegistrationLoading = value => ({ type: SET_REGISTRATION_LOADING, value })

export const setReactAppVersion = value => ({ type: SET_REACT_APP_VERSION, value })

export const setPageAfterLogin = value => ({ type: SET_PAGE_AFTER_LOGIN, value })

export const setRegistrationOwnerPhoneNumber = value => ({ type: SET_REGISTRATION_OWNER_PHONE_NUMBER, value })

