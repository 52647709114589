import { SET_CART_DISCOUNT, SET_CART_DISCOUNT_TYPE, SET_CART_EXTRA_DISCOUNT, SET_CART_EXTRA_DISCOUNT_TYPE, SET_CART_INITIAL_DISCOUNT } from '../../actionTypes/cartDiscount'

export const setCartDiscount = value => ({ type: SET_CART_DISCOUNT, value })

export const setCartDiscountType = value => ({ type: SET_CART_DISCOUNT_TYPE, value })
export const setCartInitialDiscount = value => ({ type: SET_CART_INITIAL_DISCOUNT, value })

export const setCartExtraDiscount = value => ({ type: SET_CART_EXTRA_DISCOUNT, value })

export const setCartExtraDiscountType = value => ({ type: SET_CART_EXTRA_DISCOUNT_TYPE, value })
