import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const QrCodeModalWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
`
QrCodeModalWrapper.displayName = 'QrCodeModalWrapper'

export const QrCodePaymentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.88em;
	width: 19.38em;
`
QrCodePaymentWrapper.displayName = 'QrCodePaymentWrapper'

export const QrCodePaymentContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	border-radius: 1.25em;
	border: 10px solid ${colorTheme.cartModal.qrCodePayment.border};
	background: ${colorTheme.cartModal.qrCodePayment.background};
	padding: 0.94em;
	width: 100%;
	min-height: 21.63em;

	&.started {
		min-height: 23.38em;
	}

	&.error {
		border-color: ${colorTheme.cartModal.qrCodePayment.error.border};
		min-height: 21.63em;
	}

	&.success {
		border-color: ${colorTheme.cartModal.qrCodePayment.success.border};
		min-height: 21.63em;
	}
`
QrCodePaymentContent.displayName = 'QrCodePaymentContent'

export const QrCodePaymentLoaderWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3.13em;
`
QrCodePaymentLoaderWrap.displayName = 'QrCodePaymentLoaderWrap'

export const QrCodePaymentText = styled.div`
	color: ${colorTheme.cartModal.qrCodePayment.text};
	text-align: center;
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	font-weight: 600;

	&.error {
		color: ${colorTheme.cartModal.qrCodePayment.error.text};
	}
`
QrCodePaymentText.displayName = 'QrCodePaymentText'

export const QrCodeProcessWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
QrCodeProcessWrap.displayName = 'QrCodeProcessWrap'

export const QrCodeBlock = styled.div`
	> svg {
		width: 16.25em;
		height: 16.25em;
	}
`
QrCodeBlock.displayName = 'QrCodeBlock'

export const QrCodePaymentErrorWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 4.44em;

	> svg {
		width: 8.13em;
		height: 7.38em;
		flex-shrink: 0;
		fill: ${colorTheme.cartModal.qrCodePayment.error.icon};
	}
`
QrCodePaymentErrorWrap.displayName = 'QrCodePaymentErrorWrap'

export const QrCodePaymentSuccessWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 4.06em;

	> svg {
		width: 8.13em;
		height: 8.06em;
		flex-shrink: 0;
	}
`
QrCodePaymentSuccessWrap.displayName = 'QrCodePaymentSuccessWrap'
