import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { theme } from '../../../../../style/theme'

export const CartProductDishCourse = styled.div`
    display: flex;
    padding: 0.3em;
    flex-direction: column;
    align-items: center;
    gap: 0.63em;
    width: 1.94em;
    height: 2.5em;
    border-radius: 0.5em;
    background: ${props => props.color || colorTheme.restaurant.dishCourse.defaultBackground};
    justify-content: center;
    cursor: pointer;

	@media screen and (max-width: ${theme.point720}) {
		border-radius: 0.5em 0 0 0.5em;
        height: calc(100% - 2px);
	}

`
CartProductDishCourse.displayName = 'CartProductDishCourse'

export const CartProductDishCourseText = styled.div`
    color: ${colorTheme.restaurant.dishCourse.text};
    font-size: 1.13em;
    font-weight: 700;
`
CartProductDishCourseText.displayName = 'CartProductDishCourseText'

export const CartProductDishCourseEmpty = styled.div`
    width: 1.94em;
`
CartProductDishCourseEmpty.displayName = 'CartProductDishCourseEmpty'
