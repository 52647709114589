import React, { useCallback } from 'react'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import DropDownSelect from '../Elements/buttons/DropDownSelect'

const LayoutMode = React.memo(() => {
	const [touchPad, setTouchPad] = useLocalStorage('touchPad', false)

	const handleItemClick = useCallback(() => {
		setTouchPad(!touchPad)
	}, [touchPad])

	return (
		<DropDownSelect className="mode" items={[{ icon: 'touchpad' }, { icon: 'list' }]} selectedIndex={touchPad ? 0 : 1}
										onClickItem={handleItemClick} />
	)
})

LayoutMode.displayName = 'LayoutMode'
export default LayoutMode
