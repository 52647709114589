import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const AccordionWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	border: 1px solid transparent;
	background: transparent;

	&.outline {
		border-radius: 0.75em;
		border-color: ${colorTheme.accordion.border};
	}
`
AccordionWrapper.displayName = 'AccordionWrapper'

export const AccordionHeader = styled.div`
	font-size: 1rem;
	padding: 1.87em 1.88em;

	&.small {
		padding: 0;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.63em;
	}
`
AccordionHeader.displayName = 'AccordionHeader'


export const AccordionButton = styled.button`
	width: 100%;
	height: 3.43em;
	border-radius: 0.75em;
	background: ${colorTheme.accordion.background};
	color: ${colorTheme.accordion.text};
	font-size: 1.25em;
	cursor: pointer;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 0.62em;

	&.small {
		height: 2.75em;

		> svg {
			width: 1.05em;
			height: 1.3em;
			fill: ${colorTheme.accordion.icon};
		}
	}

	&.gray {
		background: ${colorTheme.accordion.gray.background};
	}

	.accordion_arrow {
    transform: rotate(0deg);
    transition: transform .2s;

		path {
			stroke: ${colorTheme.accordion.arrow_icon};
		}
  }

	.open_accordion {
		transform: rotate(180deg);
	}
`
AccordionButton.displayName = 'AccordionButton'

export const AccordionBody = styled.div`
	opacity: 0;
	overflow: hidden;
	height: 0;
	transition: opacity 0.3s;

	&.open_accordion {
		height: auto;
		overflow: visible;
		opacity: 1;
	}
`
AccordionBody.displayName = 'AccordionBody'
