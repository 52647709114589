import styled from 'styled-components'
import checked from '../../../../img/svg/checkmark.svg'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const CheckboxWrapper = styled.label`
	font-size: 1rem;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	user-select: none;

	&.start {
		align-items: flex-start;
	}

	&.left-align {
		text-align: left;
	}

	&.maxContent {
		width: max-content;
	}

	input {
		display: none;
	}

	.checkmark {
		position: relative;
		font-size: 1rem;
		height: 1.88em;
		width: 1.88em;
		border-radius: ${props => props.circle ? '50%' : '0.38em'};
		background-color: ${colorTheme.input.checkBox.background};
		cursor: pointer;
		margin-left: 0;
		margin-right: 0.56em;
		border: 1px solid ${colorTheme.input.checkBox.border};
		flex-shrink: 0;
	}

	.checkmark:after {
		content: url(${checked});
		position: absolute;
		display: none;
		width: 60%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		flex-shrink: 0;
	}

	.label {
		font-family: ${theme.fontRobotoRegular};
		font-size: 1.13em;
		color: ${colorTheme.input.checkBox.text};
		display: flex;
		align-items: center;
		gap: 0.63em;

		> svg {
			width: 1.78em;
			height: 1.61em;
			margin-left: 0.9em;
			fill: ${colorTheme.input.checkBox.icon};
		}
	}

	&.disabled {
		cursor: not-allowed;
		opacity: 0.3;
	}

	&:hover input ~ .checkmark {
		box-shadow: 0 4px 27px rgba(0, 0, 0, 0.22);
		border: 1px solid ${colorTheme.input.checkBox.active.border};

		&.disabled {
			border: 1px solid ${colorTheme.input.checkBox.disabled.border};
			box-shadow: none;
			cursor: not-allowed;
		}
	}

	input:checked ~ .checkmark {
		background-color: white;
	}

	input:checked ~ .label {
		font-family: ${theme.fontRobotoMedium};

		> svg {
			fill: ${colorTheme.input.checkBox.active.icon};
		}
	}

	input:checked ~ .checkmark {
		background-color: ${colorTheme.input.checkBox.active.background};
		border-color: ${colorTheme.input.checkBox.active.background};

		&:after {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
	}
	@media screen and (max-width: ${theme.point1440}) {
		.checkmark:after {
			top: 55%;
			left: 51%;
			transform: translate(-50%, -50%);
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		.checkmark {
			height: 2.14em;
			width: 2.14em;
			border-radius: ${props => props.circle ? '50%' : '0.43em'};
			margin-right: 0.64em;
		}
		.checkmark:after {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&.tablet.filledBackground {
			min-height: 3.44em;
			justify-content: center;
			background: ${colorTheme.input.checkBox.gray_background};
			border-radius: 0.75em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		.checkmark {
			height: 1.88em;
			width: 1.88em;
			border-radius: ${props => props.circle ? '50%' : '0.38em'};
			margin-right: 0.56em;
		}

		&.filledBackground {
			min-height: 3.44em;
			min-width: 6.13em;
			justify-content: center;
			background: ${colorTheme.input.checkBox.gray_background};
			border-radius: 0.75em;
		}
	}
`
