import { SET_WOLT_DRIVER_ERROR_OTHER_FIELDS, SET_WOLT_DRIVER_ERROR_VENUEID, SET_WOLT_DRIVER_INTEGRATIONS, SET_WOLT_DRIVER_NOT_FOUND, SET_WOLT_DRIVER_INTEGRATIONS_ORDER, SET_WOLT_DRIVER_INTEGRATIONS_DELIVERY_PROMISES } from '../../actionTypes/woltDriverIntegrations'

const initialState = {
	driverIntegrations: null,
	errorVenueId: false,
	errorOtherFields: false,
	deliveryPromises: null,
	order: null,
	driverNotFound: false
}

export function woltDriverIntegrationsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_WOLT_DRIVER_INTEGRATIONS:
			return {
				...state,
				driverIntegrations: action.value
			}
		case SET_WOLT_DRIVER_ERROR_VENUEID:
			return {
				...state,
				errorVenueId: action.value
			}

		case SET_WOLT_DRIVER_ERROR_OTHER_FIELDS:
			return {
				...state,
				errorOtherFields: action.value
			}

		case SET_WOLT_DRIVER_INTEGRATIONS_DELIVERY_PROMISES:
			return {
				...state,
				deliveryPromises: action.value
			}
		case SET_WOLT_DRIVER_INTEGRATIONS_ORDER:
			return {
				...state,
				order: action.value
			}
		case SET_WOLT_DRIVER_NOT_FOUND:
			return {
				...state,
				driverNotFound: action.value
			}
		default:
			return state
	}
}
