import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const KitchenStatusLegendWrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 2.67em 1fr;

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: 1fr;
	}
`
KitchenStatusLegendWrap.displayName = 'KitchenStatusLegendWrap'
export const KitchenStatusLegendText = styled.div`
	color: ${colorTheme.restaurant.sendToKitchenModal.legend.text};
	font-family: ${theme.fontRobotoBold};
	font-size: 1.13em;
	font-weight: 700;
`
KitchenStatusLegendText.displayName = 'KitchenStatusLegendText'
export const KitchenStatusLegendStatusesWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.63em;

	@media screen and (max-width: ${theme.point820}) {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.43em;
		padding-bottom: 0.71em;
	}
`
KitchenStatusLegendStatusesWrap.displayName = 'KitchenStatusLegendStatusesWrap'
export const KitchenStatusLegendStatuses = styled.div`
	display: flex;
	align-items: center;
	gap: 0.63em;

	@media screen and (max-width: ${theme.point820}) {
		flex-wrap: wrap;
	}
`
KitchenStatusLegendStatuses.displayName = 'KitchenStatusLegendStatuses'
export const KitchenStatusLegendStatus = styled.div`
	display: flex;
	align-items: center;
	gap: 0.44em;
`
KitchenStatusLegendStatus.displayName = 'KitchenStatusLegendStatus'
export const KitchenStatusLegendStatusText = styled.div`
	color: ${colorTheme.restaurant.sendToKitchenModal.legend.statusText};
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.88em;
	font-weight: 500;
`
KitchenStatusLegendStatusText.displayName = 'KitchenStatusLegendStatusText'
