import React from 'react'
import { Outlet } from 'react-router-dom'

const DeliveryPage = React.memo(() => {
	return (
		<Outlet />
	)
})

DeliveryPage.displayName = 'DeliveryPage'
export default DeliveryPage
