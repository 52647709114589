import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import { useLocation, useSearchParams } from 'react-router-dom'

const Login = lazyWithRetry(() => import(/* webpackChunkName: "login" */'../../component/Login'))

const LoginPage = React.memo(() => {
	let { state } = useLocation()
	let [params] = useSearchParams()

	return (
		<Suspense fallback={<PageLoader className="authPage" />}>
			<Login emailParam={params.get('email')}
						 companyIndexParam={params.get('company_index')}
						 from={state ? state.from : null}
						 orderId={params.get('orderId')}
						 lat={params.get('lat')} lon={params.get('lon')}
						 desktopDeviceId={params.get('desktopDeviceId')}/>
		</Suspense>
	)
})

LoginPage.displayName = 'LoginPage'
export default LoginPage
