import React from 'react'
import { ButtonCount, ButtonIconWrap, ButtonKey, ButtonText } from './style/index.styled'
import { useSelector } from 'react-redux'
import colors from '../../../../style/colors'
import { theme } from '../../../../style/theme'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import KeyBindings from '../../keybindings'
import ButtonIcon from '../../../../img/svg/button'
import { getColorType } from './helper'
import { ButtonWrapper } from './style/buttonWrap/index.styled'

const Button = React.memo(({
														 id, name, onClick, children, text, icon, keyButton, keyText, count,
														 zIndex = 0, color, textColor, outline, bold, size, type, tabIndex, disabled,
														 testId, refProp, className, showTextForTablet = true, showTextForMobile = true
													 }) => {
	const isNeededZIndex = useSelector(selectIsNeededZIndex(zIndex))
	const isTablet = useWindowBreakPoint(theme.point820)
	const isMobile = useWindowBreakPoint(theme.point720)

	const layout = size + (outline ? ' outline' : '') + (bold ? ' bold' : '') + (!showTextForTablet ? ' hiddenTabletText' : '') + (` ${getColorType(color)}`) + (disabled ? ' disabled' : '') + (className ? ' ' + className : '')

	return (
		<>
			<ButtonWrapper id={id}
										 name={name}
										 type={type || 'button'}
										 onClick={type !== 'submit' ? onClick : null}
										 ref={refProp}
										 className={layout}
										 tabIndex={tabIndex || '-1'}
										 disabled={disabled}
										 data-testid={testId}>
				{icon &&
					<ButtonIconWrap className={layout}>
						<ButtonIcon icon={icon} fill={colors.white} />
					</ButtonIconWrap>}
				{(children || text) && (!isMobile || showTextForMobile) && (!isTablet || showTextForTablet) &&
					<ButtonText className={layout} textColor={textColor}>{children}{text}</ButtonText>}
				{keyButton &&
					<ButtonKey className={layout}>{keyText || keyButton}</ButtonKey>}
				{count > 0 &&
					<ButtonCount className={layout}>{count}</ButtonCount>}
			</ButtonWrapper>

			{isNeededZIndex && keyButton && !disabled &&
				<KeyBindings customKey={keyButton} customKeyPress={onClick} />}
		</>
	)
})

Button.displayName = 'Button'
export default Button
