import { isWithinInterval } from 'date-fns'
import getWorkingDayStart from '../getWorkingDayStart'
import getWorkingDayEnd from '../getWorkingDayEnd'
import { setWorkingHoursHelper } from '../index'
import { zonedTimeToUtc } from 'date-fns-tz'

export const checkIsTodayOrder = (orderDate) => {
	const todayOrderInterval = getTodayOrderInterval()
	return isWithinInterval(new Date(orderDate), todayOrderInterval)
}

const getTodayOrderInterval = () => {
	const todayDate = setWorkingHoursHelper()
	return {
		start: zonedTimeToUtc(getWorkingDayStart(todayDate, 7), 'Europe/Berlin'),
		end: zonedTimeToUtc(getWorkingDayEnd(todayDate, 6), 'Europe/Berlin'),
	}
}
