import OrderAndPayPaymentIcon from '../../../../../img/svg/orderAndPay/payment'
import React from 'react'
import { QrCodeIconsWrap, QrCodeTextWrap, TerminalInfoText, TerminalInfoTextWrap, TerminalTextWrap } from './index.styled'
import MainIcon from '../../../../../img/svg/main'

export const QrCodeButtonText = React.memo(({ text }) => {
	const payments = ['APPLE_PAY', 'GOOGLE_PAY', 'AMERICAN_EXPRESS', 'KLARNA', 'SOFORT', 'GIROPAY', 'PAYPAL']

	return (
		<QrCodeTextWrap>
			{text}
			<QrCodeIconsWrap>
				{payments.map(payment => <OrderAndPayPaymentIcon key={payment} icon={payment} />)}
			</QrCodeIconsWrap>
		</QrCodeTextWrap>
	)
})
QrCodeButtonText.displayName = 'QrCodeButtonText'

export const TerminalButtonText = React.memo(({ paymentMethod, infoPaymentMethod }) => (
	<TerminalTextWrap>
		{paymentMethod}
		<TerminalInfoTextWrap className="hide-on-active">
			<TerminalInfoText>
				{infoPaymentMethod}
			</TerminalInfoText>
			<MainIcon icon="info" />
		</TerminalInfoTextWrap>
	</TerminalTextWrap>
))
TerminalButtonText.displayName = 'TerminalButtonText'
