import React from 'react'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import colors from '../../../../../style/colors'
import { Topping, ToppingMinus, ToppingName } from '../CartProduct/index.styled'

const CartRemark = React.memo(({ remark, layout, handleRemoveRemark, removeAbility = true }) => {
	const [touchPad] = useLocalStorage('touchPad', false)

	return (
		<Topping className={layout}>
			<ToppingName className={layout}>
				<svg xmlns="http://www.w3.org/2000/svg" width="11.625" height="11.625" viewBox="0 0 11.625 11.625" fill={touchPad ? colors.blue : colors.black}>
					<path d="M324.433,202.056l4.309-.018a0.754,0.754,0,0,0,.752-0.752,0.743,0.743,0,0,0-.747-0.748l-4.31.019,0.019-4.31a0.746,0.746,0,0,0-.748-0.748,0.756,0.756,0,0,0-.752.753l-0.019,4.309-4.309.019a0.754,0.754,0,0,0-.752.752,0.741,0.741,0,0,0,.747.747l4.31-.018-0.019,4.309a0.744,0.744,0,0,0,.748.748,0.754,0.754,0,0,0,.752-0.752Z" transform="translate(-317.875 -195.5)" />
				</svg>
				{remark}
			</ToppingName>
			<div />
			<div />
			{removeAbility &&
				<ToppingMinus className={layout} onClick={handleRemoveRemark} data-testid="remark-remove">
					{touchPad ?
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.75 8.782" fill="#fff">
							<path id="btn-cross-white" d="M636.662,796.46l3.364-3.385a0.6,0.6,0,0,0,0-.838,0.587,0.587,0,0,0-.833,0l-3.364,3.385-3.365-3.385a0.586,0.586,0,0,0-.832,0,0.6,0.6,0,0,0,0,.838L635,796.46l-3.364,3.385a0.6,0.6,0,0,0,0,.838,0.587,0.587,0,0,0,.832,0l3.365-3.385,3.364,3.385a0.591,0.591,0,1,0,.833-0.838Z" transform="translate(-631.469 -792.062)" />
						</svg> :
						<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="22" height="4" viewBox="0 0 10.781 1.844">
							<path d="M486.363,202.33h-8.948a0.915,0.915,0,1,1,0-1.83h8.948A0.915,0.915,0,1,1,486.363,202.33Z" transform="translate(-476.5 -200.5)" />
						</svg>}
				</ToppingMinus>}
		</Topping>
	)
})

CartRemark.displayName = 'CartRemark'
export default CartRemark
