import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ClientMonitorCartLogo from '../common/ClientMonitorCartLogo'
import { FinalStepTitle, FinalStepWrapper } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { showClientMonitorFinalStep } from '../../../redux/action/clientMonitorCart'

const FinalStep = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const showFinalStep = useSelector(state => state.clientMonitorCart.showFinalStep)

	useEffect(() => {
		if (showFinalStep) {
			setTimeout(() => {
				dispatch(showClientMonitorFinalStep(false))
			}, 30000)
		}
	}, [showFinalStep])

	return (
		<FinalStepWrapper>
			<FinalStepTitle>{t('clientMonitorCart.finalStepTitle')}</FinalStepTitle>
			<ClientMonitorCartLogo />
		</FinalStepWrapper>
	)
})

FinalStep.displayName = 'FinalStep'
export default FinalStep
