import styled from 'styled-components'
import { theme } from '../../../style/theme'
import colors from '../../../style/colors'

export const NotificationModalWrap = styled.div`
	font-size: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	transition: none;

	&.active {
		transition: all .2s ease-in-out;
		visibility: visible;
		opacity: 1;
		z-index: ${props => props.zIndex || 100};
	}
`
NotificationModalWrap.displayName = 'NotificationModalWrap'

export const NotificationModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
`
NotificationModalContainer.displayName = 'NotificationModalContainer'

export const NotificationModalMain = styled.main`
	position: relative;
	height: 40.63em;
	width: 56.88em;
	box-sizing: border-box;
	background-image: url('/img/webShopNotification/ShopAdvBack.webp');
	font-size: 1rem;
	min-height: 100%;
	padding: 2.44em 2.94em;
	border-radius: 3.75em;
	border: 3px solid #38CD90;
`
NotificationModalMain.displayName = 'NotificationModalMain'


export const PositionModalTitle = styled.h2`
	font-family: ${theme.fontRoboto};
	font-weight: 700;
	font-size: 1.13em;
	color: ${colors.gray_dark};
	text-align: left;

	&.medium {
		font-size: 1.5em;
	}
`
PositionModalTitle.displayName = 'PositionModalTitle'

export const NotificationModalText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 2.25em;
	font-weight: 500;
	color: ${colors.white};
`
NotificationModalText.displayName = 'NotificationModalText'

export const NotificationModalBody = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 1.19em;
	margin-top: 4.34em;
`
NotificationModalBody.displayName = 'NotificationModalBody'

export const NotificationModalCloseButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.13em;

	position: absolute;
	top: 2.63em;
	right: 3.19em;

	svg {
		cursor: pointer;
		width: 2em;
		height: 2em;
		fill: ${colors.white};
	}

`
NotificationModalCloseButton.displayName = 'NotificationModalCloseButton'

export const NotificationModalCloseKey = styled.div`
	border: 1px solid ${colors.white};
	font-size: .81em;
	border-radius: .38em;
	color: ${colors.white};
	padding: .92em .54em;
	cursor: pointer;
`
NotificationModalCloseKey.displayName = 'NotificationModalCloseKey'

export const NotificationModalInfo = styled.div`
	margin-top: 3.94em;

`
NotificationModalInfo.displayName = 'NotificationModalInfo'

export const NotificationModalPhoneImg = styled.div`
	height: 30em;
	position: relative;

	> img {
		height: 100%;
	}

	> svg {
		position: absolute;
		width: 7.75em;
		height: 7.75em;
		top: -3em;
		left: -3.13em;
	}
`
NotificationModalPhoneImg.displayName = 'NotificationModalPhoneImg'

export const NotificationModalHeaderLeft = styled.div`

	width: 7.5em;
	height: 2.15em;
	border-radius: calc(3.75em - .81em) 0 3.75em 0;
	background-color: #38CD90;

	position: absolute;
	top: -2px;
	left: -2px;

	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${theme.fontRobotoBold};
	font-size: 2em;
	color: ${colors.white};
	text-transform: uppercase;;
`
NotificationModalHeaderLeft.displayName = 'NotificationModalHeaderLeft'

export const NotificationModalCartWrap = styled.div`
  background-color: ${colors.white};
  border-radius: .75em;
  border: 2px solid #FF5821;
  padding: .94em;
  position: relative;
  margin-top: .63em;

  display: none;
  grid-template-columns: 10.5em 1fr;
  align-items: center;
  gap: 1.25em;
  transition: all .3s ease-in-out;

  > svg {
    width: 1.5em;
    height: 1.5em;
  }

  &.active {
    display: grid;
  }
`
NotificationModalCartWrap.displayName = 'NotificationModalCartWrap'

export const NotificationModalCartQRCode = styled.div`
	width: 10.5em;
	height: 10.5em;
	display: flex;

	& > svg {
		display: block;
		width: 100%;
		height: 100%;
	}
`
NotificationModalCartQRCode.displayName = 'NotificationModalCartQRCode'

export const NotificationModalCartCloseBtn = styled.div`
	width: 1.5em;
	height: 1.5em;
	position: absolute;
	top: .94em;
	right: .94em;
	cursor: pointer;

	svg {
		width: 100%;
		height: 100%;
	}
`
NotificationModalCartCloseBtn.displayName = 'NotificationModalCartCloseBtn'

export const NotificationModalCartContainer = styled.div`
	display: flex;
	flex-direction: column;
`
NotificationModalCartContainer.displayName = 'NotificationModalCartContainer'

export const WhatsAppTitle = styled.div`
  display: flex;
	align-items: center;
  gap: .5em;
	font-size: 1.5em;
	margin-bottom: .63em;

	> svg {
		width: 1.4em;
		height: 2.3em;
	}
`
WhatsAppTitle.displayName = 'WhatsAppTitle'

export const WhatsAppText = styled.div`
	font-size: 1em;
	margin-bottom: .44em;
`
WhatsAppText.displayName = 'WhatsAppText'

export const NotificationModalLink = styled.a`
  font-size: 1.25em;
  color: ${colors.blue};
  text-decoration: underline;

  &.col-2 {
    grid-column: 2;
  }
`
NotificationModalLink.displayName = 'NotificationModalLink'


export const DemoShopText = styled.div`
  font-size: 1.25em;
  font-weight: 500;
  display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: .63em;
`
DemoShopText.displayName = 'DemoShopText'

export const DemoShopTextWrap = styled.div`
	display: grid;
	grid-template-columns: 2.13em 1fr;
	align-items: flex-start;
	gap: .5em;

	> svg {
		width: 2.13em;
		height: 3.5em;
	}
`
DemoShopTextWrap.displayName = 'DemoShopTextWrap'

export const PaymentMethodsWrap = styled.div`
  margin-top: 3.13em;
	width: 32.13em;
  min-height: 6.63em;
	padding: 1.25em;
	border-radius: 12px;
	background: rgba(255, 255, 255, 0.20);
`
PaymentMethodsWrap.displayName = 'PaymentMethodsWrap'

export const PaymentMethodsList = styled.div`
	font-size: 1rem;
  display: flex;
	flex-wrap: wrap;
	gap: 1.13em;
	justify-content: center;

  > .APPLE_PAY_FULL {
		width: 3.75em;
		height: 1.5em;
  }

	> .GOOGLE_PAY_FULL, .AMERICAN_EXPRESS_FULL {
		width: 3.8em;
		height: 1.5em;
  }

	> .VISA_FULL {
		width: 3.56em;
		height: 1.13em;
  }
	> .MASTERCARD_FULL {
		width: 2.81em;
		height: 1.69em;
  }

	> .KLARNA_FULL {
		width: 4.13em;
		height: .88em;
  }
	> .SOFORT_FULL {
		width: 4.8em;
		height: 1.5em;
  }
	> .GIROPAY_FULL {
		width: 5.5em;
		height: 1.25em;
  }
	> .PAYPAL_FULL {
		width: 4.75em;
		height: 1.25em;
  }
	> .EC_KARTA_FULL {
		width: 6.13em;
		height: 1.25em;
  }
	> .CASH_FULL {
		width: 3.75em;
		height: 1.25em;
  }

`
PaymentMethodsList.displayName = 'PaymentMethodsList'
