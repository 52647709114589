import React from 'react'
import { MinusButtonWrap, ProductCode, ProductCouponCol, ProductCouponName, ProductCouponTotal } from './index.styled'
import { PlusButton } from '../PlusMinus/index.styled'
import { CartProductWrap, Product, ProductNr } from '../CartProduct/index.styled'
import { encryptGiftCode } from '../../../../../../../helper/encryptGiftCode'
import { useLocalStorage } from '../../../../../../../hooks/useLocalStorage'
import Currency from '../../../../../../Elements/currency'

const CartGiftCoupon = React.memo(({ coupon, AddButton, onClickPlus }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const { name, price, couponMetaData: { code: couponCode } } = coupon
	const layout = (touchPad ? 'touchPad ' : '')
	const code = AddButton ? encryptGiftCode(couponCode) : couponCode

	return (
		<CartProductWrap className={`${layout}`}>
			<Product className={layout}>
				<ProductNr className={layout}>1</ProductNr>
				<ProductCouponName className={layout}>
					{name}
					<ProductCode>
						{code}
					</ProductCode>
				</ProductCouponName>
				<ProductCouponCol className={layout} />
				<ProductCouponTotal className={layout}>
					<Currency>{price}</Currency>
				</ProductCouponTotal>
				<MinusButtonWrap>
					{AddButton &&
						<PlusButton className="bgdColor" onClick={onClickPlus}>
							<AddButton />
						</PlusButton>}
				</MinusButtonWrap>
			</Product>
		</CartProductWrap>
	)
})

CartGiftCoupon.displayName = 'CartGiftCoupon'
export default CartGiftCoupon
