import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const PaymentsQrCodeSuccess = lazyWithRetry(() => import(/* webpackChunkName: "payments-gr-code-success" */'../../component/StaticPayments/QrCodeSuccess'))

const PaymentsQrCodeSuccessPage = React.memo(() => {
	return (
		<Suspense>
			<PaymentsQrCodeSuccess />
		</Suspense>
	)
})

PaymentsQrCodeSuccessPage.displayName = 'PaymentsQrCodeSuccessPage'
export default PaymentsQrCodeSuccessPage
