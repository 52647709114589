import axios from 'axios'
import { restaurantServiceUrl } from '../../../../urls'

const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
}

const restaurantAdminInstance = axios.create({
  baseURL: restaurantServiceUrl,
  headers,
})

export default restaurantAdminInstance
