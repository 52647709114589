import styled from 'styled-components'
import { theme } from '../../../style/theme'

export const DriverConfirmationModalWrap = styled.div`
    display: grid;
    align-items: center;
    gap: 1.5em;
`
DriverConfirmationModalWrap.displayName = 'DriverConfirmationModalWrap'

export const DriverConfirmationModalText = styled.div`
    color: #383A50;
    text-align: center;
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.75em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
DriverConfirmationModalText.displayName = 'DriverConfirmationModalText'

export const DriverConfirmationModalSubText = styled.div`
    color: #2981EE;
    text-align: center;
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.25em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
DriverConfirmationModalSubText.displayName = 'DriverConfirmationModalSubText'