import React from 'react'

export const Table12ChairsSvg = ({ chairCount, className }) => {
	const chairClassname = (chairNumber) => {
		return chairNumber <= chairCount ? '' : 'hidden'
	}
	return (
		<svg data-testid="table-twelve-chairs" viewBox="0 0 722 200" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g className={chairClassname(12)}>
				<path d="M3.8999 120.1V79.9004C3.8999 76.6004 6.5999 73.9004 9.8999 73.9004H50.0999C53.3999 73.9004 56.0999 76.6004 56.0999 79.9004V120.1C56.0999 123.4 53.3999 126.1 50.0999 126.1H9.8999C6.6999 126.1 3.8999 123.3 3.8999 120.1Z" fill="#7A7A7A" />
				<path d="M50.1 73.9C53.4 73.9 56.1 76.6 56.1 79.9V120.1C56.1 123.4 53.4 126.1 50.1 126.1H9.9C6.6 126.1 3.9 123.4 3.9 120.1V79.9C3.9 76.6 6.6 73.9 9.9 73.9H50.1ZM50.1 70H9.9C4.4 70 0 74.4 0 79.9V120.1C0 125.6 4.4 130 9.9 130H50.1C55.6 130 60 125.6 60 120.1V79.9C60 74.4 55.6 70 50.1 70Z" fill="white" />
			</g>
			<path d="M73.8999 190.1V149.9C73.8999 146.6 76.5999 143.9 79.8999 143.9H120.1C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.8999C76.6999 196.1 73.8999 193.3 73.8999 190.1Z" fill="#7A7A7A" />
			<path d="M120.1 143.9C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.9C76.6 196.1 73.9 193.4 73.9 190.1V149.9C73.9 146.6 76.6 143.9 79.9 143.9H120.1ZM120.1 140H79.9C74.4 140 70 144.4 70 149.9V190.1C70 195.6 74.4 200 79.9 200H120.1C125.6 200 130 195.6 130 190.1V149.9C130 144.4 125.6 140 120.1 140Z" fill="white" />
			<path d="M73.8999 50.1004V9.90039C73.8999 6.60039 76.5999 3.90039 79.8999 3.90039H120.1C123.4 3.90039 126.1 6.60039 126.1 9.90039V50.1004C126.1 53.4004 123.4 56.1004 120.1 56.1004H79.8999C76.6999 56.1004 73.8999 53.3004 73.8999 50.1004Z" fill="#7A7A7A" />
			<path d="M120.1 3.9C123.4 3.9 126.1 6.6 126.1 9.9V50.1C126.1 53.4 123.4 56.1 120.1 56.1H79.9C76.6 56.1 73.9 53.4 73.9 50.1V9.9C73.9 6.6 76.6 3.9 79.9 3.9H120.1ZM120.1 0H79.9C74.4 0 70 4.4 70 9.9V50.1C70 55.6 74.4 60 79.9 60H120.1C125.6 60 130 55.6 130 50.1V9.9C130 4.4 125.6 0 120.1 0Z" fill="white" />
			<path d="M204.9 50.1004V9.90039C204.9 6.60039 207.6 3.90039 210.9 3.90039H251.1C254.4 3.90039 257.1 6.60039 257.1 9.90039V50.1004C257.1 53.4004 254.4 56.1004 251.1 56.1004H210.9C207.7 56.1004 204.9 53.3004 204.9 50.1004Z" fill="#7A7A7A" />
			<path d="M251.1 3.9C254.4 3.9 257.1 6.6 257.1 9.9V50.1C257.1 53.4 254.4 56.1 251.1 56.1H210.9C207.6 56.1 204.9 53.4 204.9 50.1V9.9C204.9 6.6 207.6 3.9 210.9 3.9H251.1ZM251.1 0H210.9C205.4 0 201 4.4 201 9.9V50.1C201 55.6 205.4 60 210.9 60H251.1C256.6 60 261 55.6 261 50.1V9.9C261 4.4 256.6 0 251.1 0Z" fill="white" />
			<path d="M204.9 190.1V149.9C204.9 146.6 207.6 143.9 210.9 143.9H251.1C254.4 143.9 257.1 146.6 257.1 149.9V190.1C257.1 193.4 254.4 196.1 251.1 196.1H210.9C207.7 196.1 204.9 193.3 204.9 190.1Z" fill="#7A7A7A" />
			<path d="M251.1 143.9C254.4 143.9 257.1 146.6 257.1 149.9V190.1C257.1 193.4 254.4 196.1 251.1 196.1H210.9C207.6 196.1 204.9 193.4 204.9 190.1V149.9C204.9 146.6 207.6 143.9 210.9 143.9H251.1ZM251.1 140H210.9C205.4 140 201 144.4 201 149.9V190.1C201 195.6 205.4 200 210.9 200H251.1C256.6 200 261 195.6 261 190.1V149.9C261 144.4 256.6 140 251.1 140Z" fill="white" />
			<path d="M334.9 50.1004V9.90039C334.9 6.60039 337.6 3.90039 340.9 3.90039H381.1C384.4 3.90039 387.1 6.60039 387.1 9.90039V50.1004C387.1 53.4004 384.4 56.1004 381.1 56.1004H340.9C337.6 56.1004 334.9 53.3004 334.9 50.1004Z" fill="#7A7A7A" />
			<path d="M381.1 3.9C384.4 3.9 387.1 6.6 387.1 9.9V50.1C387.1 53.4 384.4 56.1 381.1 56.1H340.9C337.6 56.1 334.9 53.4 334.9 50.1V9.9C334.9 6.6 337.6 3.9 340.9 3.9H381.1ZM381.1 0H340.9C335.4 0 331 4.4 331 9.9V50.1C331 55.6 335.4 60 340.9 60H381.1C386.6 60 391 55.6 391 50.1V9.9C391 4.4 386.6 0 381.1 0Z" fill="white" />
			<path d="M334.9 190.1V149.9C334.9 146.6 337.6 143.9 340.9 143.9H381.1C384.4 143.9 387.1 146.6 387.1 149.9V190.1C387.1 193.4 384.4 196.1 381.1 196.1H340.9C337.6 196.1 334.9 193.3 334.9 190.1Z" fill="#7A7A7A" />
			<path d="M381.1 143.9C384.4 143.9 387.1 146.6 387.1 149.9V190.1C387.1 193.4 384.4 196.1 381.1 196.1H340.9C337.6 196.1 334.9 193.4 334.9 190.1V149.9C334.9 146.6 337.6 143.9 340.9 143.9H381.1ZM381.1 140H340.9C335.4 140 331 144.4 331 149.9V190.1C331 195.6 335.4 200 340.9 200H381.1C386.6 200 391 195.6 391 190.1V149.9C391 144.4 386.6 140 381.1 140Z" fill="white" />
			<path d="M464.9 50.1004V9.90039C464.9 6.60039 467.6 3.90039 470.9 3.90039H511.1C514.4 3.90039 517.1 6.60039 517.1 9.90039V50.1004C517.1 53.4004 514.4 56.1004 511.1 56.1004H470.9C467.6 56.1004 464.9 53.3004 464.9 50.1004Z" fill="#7A7A7A" />
			<path d="M511.1 3.9C514.4 3.9 517.1 6.6 517.1 9.9V50.1C517.1 53.4 514.4 56.1 511.1 56.1H470.9C467.6 56.1 464.9 53.4 464.9 50.1V9.9C464.9 6.6 467.6 3.9 470.9 3.9H511.1ZM511.1 0H470.9C465.4 0 461 4.4 461 9.9V50.1C461 55.6 465.4 60 470.9 60H511.1C516.6 60 521 55.6 521 50.1V9.9C521 4.4 516.6 0 511.1 0Z" fill="white" />
			<path d="M464.9 190.1V149.9C464.9 146.6 467.6 143.9 470.9 143.9H511.1C514.4 143.9 517.1 146.6 517.1 149.9V190.1C517.1 193.4 514.4 196.1 511.1 196.1H470.9C467.6 196.1 464.9 193.3 464.9 190.1Z" fill="#7A7A7A" />
			<path d="M511.1 143.9C514.4 143.9 517.1 146.6 517.1 149.9V190.1C517.1 193.4 514.4 196.1 511.1 196.1H470.9C467.6 196.1 464.9 193.4 464.9 190.1V149.9C464.9 146.6 467.6 143.9 470.9 143.9H511.1ZM511.1 140H470.9C465.4 140 461 144.4 461 149.9V190.1C461 195.6 465.4 200 470.9 200H511.1C516.6 200 521 195.6 521 190.1V149.9C521 144.4 516.6 140 511.1 140Z" fill="white" />
			<path d="M718.1 120.1V79.9004C718.1 76.6004 715.4 73.9004 712.1 73.9004H671.9C668.6 73.9004 665.9 76.6004 665.9 79.9004V120.1C665.9 123.4 668.6 126.1 671.9 126.1H712.1C715.4 126.1 718.1 123.3 718.1 120.1Z" fill="#7A7A7A" />
			<path d="M671.9 73.9C668.6 73.9 665.9 76.6 665.9 79.9V120.1C665.9 123.4 668.6 126.1 671.9 126.1H712.1C715.4 126.1 718.1 123.4 718.1 120.1V79.9C718.1 76.6 715.4 73.9 712.1 73.9H671.9ZM671.9 70H712.1C717.6 70 722 74.4 722 79.9V120.1C722 125.6 717.6 130 712.1 130H671.9C666.4 130 662 125.6 662 120.1V79.9C662 74.4 666.4 70 671.9 70Z" fill="white" />
			<path d="M648.1 190.1V149.9C648.1 146.6 645.4 143.9 642.1 143.9H601.9C598.6 143.9 595.9 146.6 595.9 149.9V190.1C595.9 193.4 598.6 196.1 601.9 196.1H642.1C645.4 196.1 648.1 193.3 648.1 190.1Z" fill="#7A7A7A" />
			<path d="M601.9 143.9C598.6 143.9 595.9 146.6 595.9 149.9V190.1C595.9 193.4 598.6 196.1 601.9 196.1H642.1C645.4 196.1 648.1 193.4 648.1 190.1V149.9C648.1 146.6 645.4 143.9 642.1 143.9H601.9ZM601.9 140H642.1C647.6 140 652 144.4 652 149.9V190.1C652 195.6 647.6 200 642.1 200H601.9C596.4 200 592 195.6 592 190.1V149.9C592 144.4 596.4 140 601.9 140Z" fill="white" />
			<path d="M648.1 50.1004V9.90039C648.1 6.60039 645.4 3.90039 642.1 3.90039H601.9C598.6 3.90039 595.9 6.60039 595.9 9.90039V50.1004C595.9 53.4004 598.6 56.1004 601.9 56.1004H642.1C645.4 56.1004 648.1 53.3004 648.1 50.1004Z" fill="#7A7A7A" />
			<path d="M601.9 3.9C598.6 3.9 595.9 6.6 595.9 9.9V50.1C595.9 53.4 598.6 56.1 601.9 56.1H642.1C645.4 56.1 648.1 53.4 648.1 50.1V9.9C648.1 6.6 645.4 3.9 642.1 3.9H601.9ZM601.9 0H642.1C647.6 0 652 4.4 652 9.9V50.1C652 55.6 647.6 60 642.1 60H601.9C596.4 60 592 55.6 592 50.1V9.9C592 4.4 596.4 0 601.9 0Z" fill="white" />
			<path d="M687.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.6001 176 34.1001 176H688C693.5 176 698 171.5 698 166V34C698 28.5 693.4 24 687.9 24Z" fill="#7A7A7A" />
			<path d="M687.9 20H34.1C26.3 20 20 26.3 20 34V166C20 173.7 26.3 180 34.1 180H688C695.8 180 702.1 173.7 702.1 166V34C702 26.3 695.7 20 687.9 20ZM698 166C698 171.5 693.4 176 688 176H34.1C28.6 176 24.1 171.5 24.1 166V34C24.1 28.5 28.6 24 34.1 24H688C693.5 24 698 28.5 698 34V166Z" fill="white" />
			<path className="colorTable" d="M687.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.6001 176 34.1001 176H688C693.5 176 698 171.5 698 166V34C698 28.5 693.4 24 687.9 24ZM687.3 165.3H34.7001V34.7H687.2V165.3H687.3Z" fill="#38CD90" />
		</svg>

	)
}
Table12ChairsSvg.displayName = 'Table12ChairsSvg'
