import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const ButtonsWrapper = styled.div`
	width: 100%;
	font-size: 1rem;
	display: flex;
	gap: 1.25em;
	align-items: center;
	margin-bottom: 1.875em;

	@media screen and (max-width: ${theme.point820}) {
		flex-direction: column;
	}
`
ButtonsWrapper.displayName = 'ButtonsWrapper'

export const PrintReceiptConfirmationWrap = styled.div`
	width: 37.5em;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: ${theme.point820}) {
		width: auto;
	}
`
PrintReceiptConfirmationWrap.displayName = 'PrintReceiptConfirmationWrap'
