import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { filterNumber, getFloat } from '../../../../helper'
import { setCartDiscount } from '../../../../redux/action/cartDiscount'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { getBusinessScopeDiscountThunk } from '../../../../redux/thunk/cartDiscount'
import KeyIcon from '../../buttons/KeyIcon'
import KeyBindings from '../../keybindings'
import Tooltip from '../../tooltip'
import { DiscountInput, DiscountLabel, DiscountTextWrap } from './index.styled'
import { CartTotalText, CartTotalValue } from '../index.styled'
import Currency from '../../currency'
import { updateClientMonitorCartThunk } from '../../../../redux/thunk/clientMonitorCart'
import { selectCartInitialDiscount } from '../../../../redux/selector/cart'

const EVENT_Z_INDEX = 7

const Discount = React.memo(({ type, orderType, previousIndex, hasWaiterDiscountError = false, maxDiscountForWaiter, isDisabled, discountInCurrency }) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const discount = useSelector(state => state.cartDiscount.discount)
	const initialCartDiscount = useSelector(selectCartInitialDiscount)
	const dispatch = useDispatch()
	const discountInput = useRef()
	const [inputValue, setInputValue] = useState('')
	const errorTooltipText = t('Cart.tooltip.discount_for_waiter_error', { maxDiscount: maxDiscountForWaiter })
	const layout = (isNeededZIndex ? 'active ' : '')

	useEffect(() => {
		if (initialCartDiscount) {
			setInputValue(`${initialCartDiscount}`.replace('.', ','))
		}
	}, [initialCartDiscount])

	useEffect(() => {
		if (isNeededZIndex) {
			discountInput.current.focus()
		}
	}, [isNeededZIndex])

	useEffect(() => {
		if (initialCartDiscount === discount) {
			setInputValue(`${initialCartDiscount}`.replace('.', ','))
		}
	}, [initialCartDiscount, discount])

	useEffect(() => {
		if (orderType) {
			dispatch(getBusinessScopeDiscountThunk(orderType))
		}
	}, [orderType]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(setCartDiscount(getFloat(inputValue)))
	}, [inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

	const selectInput = useCallback(() => {
		discountInput.current.select()
	}, [])

	const handleDiscountChange = useCallback(e => {
		e.target.value = filterNumber(e.target.value).replace('.', ',')
		setInputValue(e.target.value)
		dispatch(updateClientMonitorCartThunk(type, () => {}))
	},[dispatch, type])

	const handleArrowUp = useCallback(() => {
		dispatch(setAppEventZIndex(previousIndex || 0))
	}, [dispatch, previousIndex])

	const handleArrowDown = useCallback(() => {
		dispatch(setAppEventZIndex(8))
	}, [dispatch])

	const handleEnter = useCallback(() => {
		dispatch(setAppEventZIndex(0))
	}, [dispatch])

	const handleF10 = useCallback(() => {
		dispatch(setAppEventZIndex(previousIndex || 0))
	}, [dispatch, previousIndex])

	const handleF8 = () => {
		dispatch(setAppEventZIndex(8))
	}

	const handleFocusDiscount = useCallback(() => {
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
	}, [dispatch])

	const resetDiscount = (e) => {
		if (e.target.value === '') setInputValue('0')
	}

	return (
		<>
			<DiscountLabel onClick={handleFocusDiscount}>
				<DiscountTextWrap>
					<CartTotalText className={layout}>{t('Cart.discount')}:</CartTotalText>
					<KeyIcon keyButton="F9" zIndex={EVENT_Z_INDEX} onKeyPress={handleFocusDiscount} />
				</DiscountTextWrap>
				<Tooltip isShow={hasWaiterDiscountError} text={errorTooltipText} position="top" margin="4">
					<DiscountInput className={layout}>
						<input type="text"
									 name="discount"
									 data-testid="discount-input"
									 ref={discountInput}
									 onBlur={resetDiscount}
									 onFocus={selectInput}
									 value={!isDisabled ? inputValue : '0'}
									 onChange={handleDiscountChange}
									 disabled={isDisabled} />
						<div>%</div>
					</DiscountInput>
				</Tooltip>
				<CartTotalValue>
					-<Currency>{discountInCurrency}</Currency>
				</CartTotalValue>
			</DiscountLabel>


			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 enter={handleEnter}
										 f8={handleF8}
										 f10={handleF10} />}
		</>
	)
})

Discount.displayName = 'Discount'
export default Discount
