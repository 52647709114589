import { addSubItem, clone, deleteItem, recalculateSubItems } from '../../../helper'
import { ADD_CASH_DESK_ITEM, ADD_CASH_DESK_REMARK_TO_ITEM, ADD_CASH_DESK_SUBITEM, REMOVE_CASH_DESK_COUPON, REMOVE_CASH_DESK_ITEM, REMOVE_CASH_DESK_REMARK, REMOVE_CASH_DESK_SUBITEM, SET_CASH_DESK_CANCEL_REASON, SET_CASH_DESK_GIFT_COUPONS, SET_CASH_DESK_ITEM_FOCUS_INDEX, SET_CASH_DESK_ITEMS, SET_CASH_DESK_MODAL_CHANGE_CALCULATOR_IS_NEEDED, SET_CASH_DESK_ORDER, SET_CASH_DESK_ORDER_SHOW_PAYMENT_METHOD, SET_CASH_DESK_ORDER_TYPE, SET_CASH_DESK_PREORDER_DATE, SET_CASH_DESK_SHOW_CANCEL_ORDER, SET_CASH_DESK_SHOW_ORDER, SET_CASH_DESK_SHOW_ORDER_DATA, SET_CASH_DESK_SHOW_ORDERS_TABLE, UPDATE_CASH_DESK_ITEM } from '../../actionTypes/cashDesk'
import { setCancelReasonFoodoraOptions, setCancelReasonOptions } from '../all-order/constants'

const initialState = {
	orderType: 'PICKUP',
	items: [],
	itemFocusIndex: null,
	preOrderDate: null,
	order: null,
	modalChangeCalculatorIsNeeded: false,
	cancelReason: setCancelReasonOptions()[0].value,
	cancelReasonOptions: setCancelReasonOptions(),
	cancelReasonFoodoraOptions: setCancelReasonFoodoraOptions(),
	modal: {
		showOrder: false,
		showOrderData: false,
		showCancelOrder: false,
		showPaymentMethod: false,
		showOrdersTable: false,
	},
	giftCoupons: [],
}

export function cashDeskReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CASH_DESK_ORDER_TYPE:
			return {
				...state,
				orderType: action.value,
			}
		case SET_CASH_DESK_ITEMS:
			return {
				...state,
				items: action.value,
			}
		case ADD_CASH_DESK_ITEM:
			const itemsCopy = clone(state.items)
			itemsCopy.push(action.value)
			recalculateSubItems(action.value)
			return {
				...state,
				items: itemsCopy,
			}
		case ADD_CASH_DESK_SUBITEM:
			const itemsCopy2 = clone(state.items)
			addSubItem(itemsCopy2[action.value.itemIndex], action.value.item)
			recalculateSubItems(itemsCopy2[action.value.itemIndex])
			return {
				...state,
				items: itemsCopy2,
			}
		case REMOVE_CASH_DESK_ITEM:
			const itemsWithoutOne = deleteItem([...state.items], action.value)
			recalculateSubItems(itemsWithoutOne)
			return {
				...state,
				items: itemsWithoutOne,
			}
		case REMOVE_CASH_DESK_SUBITEM:
			const itemsCopy4 = clone(state.items)
			itemsCopy4[action.value.itemIndex].items.splice(action.value.subItemIndex, 1)
			recalculateSubItems(itemsCopy4[action.value.itemIndex])
			return {
				...state,
				items: itemsCopy4,
			}
		case UPDATE_CASH_DESK_ITEM:
			const itemsCopy7 = [...state.items]
			itemsCopy7[action.value.itemIndex] = recalculateSubItems({ ...action.value.item })
			return {
				...state,
				items: itemsCopy7,
			}
		case SET_CASH_DESK_ITEM_FOCUS_INDEX:
			return {
				...state,
				itemFocusIndex: action.value,
			}
		case SET_CASH_DESK_PREORDER_DATE:
			return {
				...state,
				preOrderDate: action.value,
			}
		case SET_CASH_DESK_ORDER:
			return {
				...state,
				order: action.value,
			}
		case REMOVE_CASH_DESK_REMARK:
			const itemsCopy5 = clone(state.items)
			itemsCopy5[action.value].remark = null
			return {
				...state,
				items: itemsCopy5,
			}
		case ADD_CASH_DESK_REMARK_TO_ITEM:
			const itemsCopy6 = clone(state.items)
			itemsCopy6[action.value.itemFocusIndex].remark = action.value.comment
			return {
				...state,
				items: itemsCopy6,
			}
		case SET_CASH_DESK_MODAL_CHANGE_CALCULATOR_IS_NEEDED:
			return {
				...state,
				modalChangeCalculatorIsNeeded: action.value,
			}
		case REMOVE_CASH_DESK_COUPON:
			const itemsCopy8 = [...state.items]?.filter(item => item.type !== 'MARKETING_COUPON')
			return {
				...state,
				items: itemsCopy8,
			}
		case SET_CASH_DESK_SHOW_ORDER:
			return {
				...state,
				modal: {
					...state.modal,
					showOrder: action.value,
				},
			}
		case SET_CASH_DESK_SHOW_ORDER_DATA:
			return {
				...state,
				modal: {
					...state.modal,
					showOrderData: action.value,
				},
			}
		case SET_CASH_DESK_SHOW_CANCEL_ORDER:
			return {
				...state,
				modal: {
					...state.modal,
					showCancelOrder: action.value,
				},
			}
		case SET_CASH_DESK_ORDER_SHOW_PAYMENT_METHOD:
			return {
				...state,
				modal: {
					...state.modal,
					showPaymentMethod: action.value,
				},
			}
		case SET_CASH_DESK_CANCEL_REASON:
			return {
				...state,
				cancelReason: action.value ? action.value : setCancelReasonOptions()[0].value,
			}
		case SET_CASH_DESK_SHOW_ORDERS_TABLE:
			return {
				...state,
				modal: {
					...state.modal,
					showOrdersTable: action.value,
				}
			}
		case SET_CASH_DESK_GIFT_COUPONS:
			return {
				...state,
				giftCoupons: action.value,
			}
		default:
			return state
	}
}
