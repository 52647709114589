import React, { Suspense } from 'react'
import PageLoader from '../../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../../helper/lazyWithRetry'

const RegistrationCompany = lazyWithRetry(() => import(/* webpackChunkName: "company" */'../../../component/Registration/Company'))

const RegistrationCompanyPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader className="authPage" />}>
			<RegistrationCompany />
		</Suspense>
	)
})

RegistrationCompanyPage.displayName = 'RegistrationCompanyPage'
export default RegistrationCompanyPage
