import styled from 'styled-components'
import { theme } from '../../style/theme'

export const DeliveryWrapper = styled.div`
  position: relative;
	min-height: 33.9rem;

	@media screen and (max-width: ${theme.point820}) {
		padding-top: 1.43em;
		min-height: auto;
	}

  @media screen and (max-width: ${theme.point720}) {
		padding-top: 0.63em;
		min-height: auto;
  }
`
DeliveryWrapper.displayName = 'DeliveryWrapper'

export const DeliveryTableWrap = styled.div`
	padding: 0 1.88em 2.94em 1.88em;
	@media screen and (max-width: ${theme.point820}) {
		padding: 0 0.71em 2.86em 0.71em;
		margin-top: 2.85em;
	}
	@media screen and (max-width: ${theme.point720}) {
		padding: 0 1.25em 2.5em 1.25em;
		margin-top: 2.5em;

		&.platform:first-child {
			margin-top: 0;
		}
	}

`
DeliveryTableWrap.displayName = 'DeliveryTableWrap'
