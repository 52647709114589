import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { theme } from '../../../../../style/theme'

export const CartProductWrapper = styled.div`
	font-size: 1rem;
	padding: 1.88em 0;
	border-bottom: 1px dashed ${colorTheme.clientMonitorCart.cart.product.border};

	&.menu {
		padding: 0.63em 0 0 1.25em;
		border-bottom: none;
		grid-column: 1 / 5;
	}
`
CartProductWrapper.displayName = 'CartProductWrapper'

export const CartProductBlock = styled.div`
	display: grid;
	grid-template-columns: 2.65fr 0.4fr 1fr 1fr;
	align-items: center;

	&.menu {
		grid-template-columns: 2.53fr 0.4fr 1fr 1fr;
	}
`
CartProductBlock.displayName = 'CartProductBlock'

export const CartProductTitle = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.clientMonitorCart.cart.product.title};
	font-size: 1.75em;
	font-weight: 600;
	padding: 0.07em 0;

	&.coupon-item {
		color: ${colorTheme.clientMonitorCart.cart.product.title};
		grid-column: 1 / 4;
	}

	&.coupon-payment {
		color: ${colorTheme.clientMonitorCart.cart.product.coupon};
		grid-column: 1 / 5;
	}

	&.menu {
		font-size: 1.5em;
		padding: 0.08em 0;
	}
`
CartProductTitle.displayName = 'CartProductTitle'

export const CartProductQuantity = styled.div`
	font-family: ${theme.fontRobotoBold};
	color: ${colorTheme.clientMonitorCart.cart.product.title};
	font-size: 1.75em;
	font-weight: 700;
	text-align: right;

	&.menu {
		font-size: 1.5em;
	}
`
CartProductQuantity.displayName = 'CartProductQuantity'

export const CartProductInitialPriceBlock = styled.div`
	display: flex;
	justify-content: end;
	grid-column: 3;
`
CartProductInitialPriceBlock.displayName = 'CartProductInitialPriceBlock'

export const CartProductTotalPriceBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-self: end;
	grid-column: 4;
`
CartProductTotalPriceBlock.displayName = 'CartProductTotalPriceBlock'

export const CartProductPrice = styled.div`
	font-family: ${theme.fontRobotoBold};
	color: ${colorTheme.clientMonitorCart.cart.product.price.initial};
	font-size: 1.75em;
	font-weight: 700;

	&.active {
		color: ${colorTheme.clientMonitorCart.cart.product.price.active};
	}

	&.total {
		color: ${colorTheme.clientMonitorCart.cart.product.price.total};
	}
`
CartProductPrice.displayName = 'CartProductPrice'

export const CartProductDiscountedPrice = styled.div`
	font-family: ${theme.fontRobotoBold};
	color: ${colorTheme.clientMonitorCart.cart.product.price.discounted};
	font-weight: 700;
	font-size: 1.25em;
	text-decoration: line-through;
`
CartProductDiscountedPrice.displayName = 'CartProductDiscountedPrice'

export const CartToppings = styled.div`
	display: flex;
	gap: 0.31em;
	margin-top: 0.63em;
	grid-column: 1 / 4;
	flex-wrap: wrap;

	&.menu {
		margin-top: 0.31em;
		padding-left: 0.63em;
	}
`
CartToppings.displayName = 'CartToppings'
export const CartTopping = styled.div`
	display: flex;
	gap: 0.13em;
`
CartTopping.displayName = 'CartTopping'
export const CartToppingTitle = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.13em;
	font-weight: 600;
	line-height: 1.4em;
	color: ${colorTheme.clientMonitorCart.cart.product.topping.title};
`
CartToppingTitle.displayName = 'CartToppingTitle'
export const CartToppingPrice = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.13em;
	font-weight: 500;
	line-height: 1.4em;
	color: ${colorTheme.clientMonitorCart.cart.product.topping.price};
`
CartToppingPrice.displayName = 'CartToppingPrice'


