export const getColorsForCart = (background, themeCategory, colorTheme, touchPad) => {
	const themeLight = {
		categories: { background, color: '#000000', colorTouchPad: '#000000' },
		category: { border: '#CDCDCE', background, color: '#383A50', icon: '#383A50' },
		backCategory: { border: '#CDCDCE', background, backgroundInside: '#FFFFFF', color: '#383A50', icon: '#383A50', backIcon: '#383A50' },
		product: { border: background }
	}
	const themeDark = {
		categories: { background, color: '#FFFFFF', colorTouchPad: '#FFFFFF' },
		category: { border: '#A4ACB8', background, color: '#FFFFFF', icon: '#FFFFFF' },
		backCategory: { border: '#A4ACB8', background: '#A4ACB8', backgroundInside: background, color: '#FFFFFF', icon: '#FFFFFF', backIcon: '#FFFFFF' },
		product: { border: background }
	}
	const themeDefault = {
		categories: { background: null, color: '#2981EE', colorTouchPad: '#000000' },
		category: {
			border: colorTheme === 'LIGHT' ? '#2981EE' : '#2B2B2B',
			background: colorTheme === 'LIGHT' ? '#FFFFFF' : '#2B2B2B',
			color: colorTheme === 'LIGHT' ? '#383A50' : '#FFF',
			icon: colorTheme === 'LIGHT' ? '#2981EE' : '#FFF'
		},
		backCategory: {
			border: colorTheme === 'LIGHT' ? '#2981EE' : '#A4ACB8',
			background: colorTheme === 'LIGHT' ? '#2981EE' : '#A4ACB8',
			backgroundInside: colorTheme === 'LIGHT' ? '#FFFFFF' : '#2B2B2B',
			color: colorTheme === 'LIGHT' ? '#2981EE': '#FFFFFF',
			icon: colorTheme === 'LIGHT' ? '#2981EE' : '#FFFFFF',
			backIcon: '#FFFFFF' },
		product: {
			border: colorTheme === 'LIGHT' ? '#FFFFFF' : touchPad ? '#A4ACB8' : '#2B2B2B',
		}

	}

	return !background ? themeDefault : themeCategory === 'LIGHT' ? themeLight : themeDark
}
