import React from 'react'
import { ReactComponent as CashSvg } from './assets/cash.svg'
import { ReactComponent as EcSvg } from './assets/ec.svg'
import { ReactComponent as PaypalSvg } from './assets/paypal.svg'
import { ReactComponent as AmericanExpressSvg } from './assets/american_express.svg'
import { ReactComponent as ApplePaySvg } from './assets/apple_pay.svg'
import { ReactComponent as GiropaySvg } from './assets/giropay.svg'
import { ReactComponent as GooglePaySvg } from './assets/google_pay.svg'
import { ReactComponent as KlarnaSvg } from './assets/klarna.svg'
import { ReactComponent as MastercardSvg } from './assets/mastercard.svg'
import { ReactComponent as SofortSvg } from './assets/sofort.svg'
import { ReactComponent as VisaSvg } from './assets/visa.svg'
import { ReactComponent as EcTerminalSvg } from './assets/ec_terminal.svg'
import { ReactComponent as CashFullSvg } from './assets/cash_full.svg'
import { ReactComponent as PaypalFullSvg } from './assets/paypal_full.svg'
import { ReactComponent as MastercarFulldSvg } from './assets/mastercard_full.svg'
import { ReactComponent as VisaFullSvg } from './assets/visa_full.svg'
import { ReactComponent as ApplePayFullSvg } from './assets/apple_pay_full.svg'
import { ReactComponent as GooglePayFullSvg } from './assets/google_pay_full.svg'
import { ReactComponent as KlarnaFullSvg } from './assets/klarna_full.svg'
import { ReactComponent as AmericanExpressFullSvg } from './assets/american_express_full.svg'
import { ReactComponent as GiropayFullSvg } from './assets/giropay_full.svg'
import { ReactComponent as SofortFullSvg } from './assets/sofort_full.svg'
import { ReactComponent as ECKartaFullSvg } from './assets/ec-karte.svg'
import { ReactComponent as QrCodeSvg } from './assets/qr_code.svg'
import { ReactComponent as QrCodeBigSvg } from './assets/qr_code_big.svg'
import { ReactComponent as InvoiceSvg } from './assets/invoice.svg'

const PaymentIcon = React.memo(({ icon, height = 20, width = 20, fill, stroke }) => {
	const svgProps = { height, width, fill, stroke, className: icon }

	return (
		<>
			{icon === 'CASH' && <CashSvg {...svgProps} />}
			{icon === 'EC_AT_DOORSTEP' && <EcSvg {...svgProps} />}
			{icon === 'EC_TERMINAL' && <EcTerminalSvg {...svgProps} />}
			{icon === 'PAYPAL' && <PaypalSvg {...svgProps} />}
			{icon === 'MASTERCARD' && <MastercardSvg {...svgProps} />}
			{icon === 'VISA' && <VisaSvg {...svgProps} />}
			{icon === 'APPLE_PAY' && <ApplePaySvg {...svgProps} />}
			{icon === 'GOOGLE_PAY' && <GooglePaySvg {...svgProps} />}
			{icon === 'KLARNA' && <KlarnaSvg {...svgProps} />}
			{icon === 'AMERICAN_EXPRESS' && <AmericanExpressSvg {...svgProps} />}
			{icon === 'GIROPAY' && <GiropaySvg {...svgProps} />}
			{icon === 'SOFORT' && <SofortSvg {...svgProps} />}
			{icon === 'ONLINE' && <SofortSvg {...svgProps} />}
			{icon === 'QR_CODE' && <QrCodeSvg {...svgProps} />}
			{icon === 'CASH_FULL' && <CashFullSvg {...svgProps} />}
			{icon === 'PAYPAL_FULL' && <PaypalFullSvg {...svgProps} />}
			{icon === 'MASTERCARD_FULL' && <MastercarFulldSvg {...svgProps} />}
			{icon === 'VISA_FULL' && <VisaFullSvg {...svgProps} />}
			{icon === 'APPLE_PAY_FULL' && <ApplePayFullSvg {...svgProps} />}
			{icon === 'GOOGLE_PAY_FULL' && <GooglePayFullSvg {...svgProps} />}
			{icon === 'KLARNA_FULL' && <KlarnaFullSvg {...svgProps} />}
			{icon === 'AMERICAN_EXPRESS_FULL' && <AmericanExpressFullSvg {...svgProps} />}
			{icon === 'GIROPAY_FULL' && <GiropayFullSvg {...svgProps} />}
			{icon === 'SOFORT_FULL' && <SofortFullSvg {...svgProps} />}
			{icon === 'EC_KARTA_FULL' && <ECKartaFullSvg {...svgProps} />}
			{icon === 'QR_CODE_BIG' && <QrCodeBigSvg {...svgProps} />}
			{icon === 'INVOICE' && <InvoiceSvg {...svgProps} />}
		</>
	)
})

PaymentIcon.displayName = 'PaymentIcon'
export default PaymentIcon
