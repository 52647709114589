import { SET_COMPANY_REGISTERED, SET_COMPANY_REGISTERED_LOADING } from '../../actionTypes'

const initialState = {
  complete: localStorage.complete ? JSON.parse(localStorage.complete) : false,
  loading: false
}

export function registrationCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY_REGISTERED:
			localStorage.complete = action.payload.content
      return {
        ...state,
        complete: action.payload.content ? action.payload.content : state.complete
      }
      case SET_COMPANY_REGISTERED_LOADING:
        return {
          ...state,
          loading: action.payload.content
        }
    default:
      return state
  }
}


