import { APPEND_UBEREAT_RESTAURANTS, SET_UBEREAT_RESTAURANT, SET_UBEREAT_RESTAURANTS, SET_UBEREAT_RESTAURANTS_TOTAL_LENGTH, SET_UBEREAT_RESTAURANTS_SHOW_ERROR_MODAL } from '../../actionTypes/ubereatRestaurant'

const initialState = {
  restaurants: [],
  restaurantsTotalLength: 0,
  restaurant: null,
}

export function uberEatRestaurantReducer (state = initialState, action) {
  switch (action.type) {
    case SET_UBEREAT_RESTAURANTS:
      return {
        ...state,
        restaurants: action.value,
      }
    case SET_UBEREAT_RESTAURANTS_TOTAL_LENGTH:
      return {
        ...state,
        restaurantsTotalLength: action.value,
      }
    case SET_UBEREAT_RESTAURANT:
      return {
        ...state,
        restaurant: action.value,
      }
    case APPEND_UBEREAT_RESTAURANTS:
      return {
        ...state,
        restaurants: state.restaurants ? state.restaurants.concat(...action.value) : action.value,
      }
		case SET_UBEREAT_RESTAURANTS_SHOW_ERROR_MODAL:
			return {
				...state,
				showErrorModal: action.value,
			}
    default:
      return state
  }
}
