import React from 'react'
import { SendToKitchenModalClose, SendToKitchenModalHeaderTitle, SendToKitchenModalHeaderTitleText, SendToKitchenModalHeaderTitleWrap, SendToKitchenModalHeaderWrap } from './index.styled'
import { useTranslation } from 'react-i18next'
import KitchenStatusLegend from '../legend'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import Price from '../../../Elements/blocks/Price'
import { theme } from '../../../../style/theme'

const SendToKitchenModalHeader = React.memo(({ tableNumber, tableTotalPrice, close }) => {
	const { t } = useTranslation()
	const isTablet = useWindowBreakPoint(theme.point820)

	return (
		<SendToKitchenModalHeaderWrap data-testid="send-to-kitchen-modal-header">
			<SendToKitchenModalHeaderTitleWrap>
				<SendToKitchenModalHeaderTitle>
					<SendToKitchenModalHeaderTitleText>
						{t('restaurant.sendToKitchenModal.title')} #{tableNumber}
					</SendToKitchenModalHeaderTitleText>
					<Price value={tableTotalPrice} size="big" />
				</SendToKitchenModalHeaderTitle>
				<SendToKitchenModalClose onClick={close} className="btn-close-modal">
					<svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
						<path d="M0.6 0.599219C1.3 -0.100781 2.5 -0.100781 3.3 0.599219L9.6 6.89922L15.9 0.599219C16.6 -0.100781 17.8 -0.100781 18.6 0.599219C19.3 1.29922 19.3 2.49922 18.6 3.29922L12.3 9.59922L18.6 15.8992C19.3 16.5992 19.3 17.7992 18.6 18.5992C18.2 18.8992 17.8 19.0992 17.3 19.0992C16.8 19.0992 16.3 18.8992 16 18.4992L9.7 12.1992L3.4 18.4992C2.9 18.7992 2.4 18.9992 1.9 18.9992C1.4 18.9992 0.9 18.7992 0.6 18.3992C-0.1 17.6992 -0.1 16.4992 0.6 15.6992L6.9 9.39922L0.6 3.19922C-0.2 2.49922 -0.2 1.29922 0.6 0.599219Z" />
					</svg>
				</SendToKitchenModalClose>
			</SendToKitchenModalHeaderTitleWrap>
			{!isTablet && <KitchenStatusLegend />}
		</SendToKitchenModalHeaderWrap>
	)
})

SendToKitchenModalHeader.displayName = 'SendToKitchenModalHeader'
export default SendToKitchenModalHeader
