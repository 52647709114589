import styled from 'styled-components'
import { theme } from '../../../../style/theme'
export const ButtonWrap = styled.div`
	width: 100%;
	cursor: pointer;
	font-size: 1em;
	margin-bottom: .56em;

	> button {
		width: 100%;
	}
	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 0;
	}
`
ButtonWrap.displayName = 'ButtonWrap'
