import { notifyToast, retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import { setDeliverySettingsCartSettings, updateDeliverySettingsCartSetting, setDeliverySettingsChangeSettings, updateDeliverySettingsChangeSetting } from '../../action/deliverySettings'
import getExpertCartSettings from '../../axios/registration/expertCartSettings'
import updateExpertCartSettings from '../../axios/registration/expertCartSettings/put'
import getChangeSettings from '../../axios/registration/changeSettings'
import updateChangeSettings from '../../axios/registration/changeSettings/put'

export const getDeliverySettingsCartSettingsThunk = (module) => dispatch => {
	return getExpertCartSettings(module)
		.then(res => {
			dispatch(setDeliverySettingsCartSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateDeliverySettingsCartSettingsThunk = (data) => dispatch => {
	return updateExpertCartSettings(data)
		.then((res) => {
			notifyToast({ message: 'Cart setting successfully updated' })
			return dispatch(updateDeliverySettingsCartSetting(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getDeliverySettingsChangeSettingsThunk = (module) => dispatch => {
	return getChangeSettings(module)
		.then(res => {
			dispatch(setDeliverySettingsChangeSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateDeliverySettingsChangeSettingsThunk = (data) => dispatch => {
	return updateChangeSettings(data)
		.then((res) => {
			notifyToast({ message: 'Change setting successfully updated' })
			return dispatch(updateDeliverySettingsChangeSetting(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
