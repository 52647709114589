import { APPEND_USERS, SET_ALL_USERS, SET_EDIT_USER_MODE, SET_USER_GROUPS, SET_USERS_TOTAL, SET_USER_LOADING, SET_USER_DUPLICATE_PIN_CODE_ERROR } from '../../actionTypes/user'

const initialState = {
  users: [],
  usersTotal: 0,
  editUser: null,
  groups: [],
  loading: false,
	duplicatePinCodeError: false
}

export function userReducer (state = initialState, action) {
  switch (action.type) {
    case SET_ALL_USERS:
      return {
        ...state,
        users: action.value,
      }
    case APPEND_USERS:
      return {
        ...state,
        users: state.users.concat(...action.value),
      }
    case SET_USERS_TOTAL:
      return {
        ...state,
        usersTotal: action.value,
      }
    case SET_EDIT_USER_MODE:
      return {
        ...state,
        editUser: action.value,
      }
    case SET_USER_GROUPS:
      return {
        ...state,
        groups: action.value,
      }
    case SET_USER_LOADING:
      return {
        ...state,
        loading: action.value,
      }
    case SET_USER_DUPLICATE_PIN_CODE_ERROR:
      return {
        ...state,
        duplicatePinCodeError: action.value,
      }
    default:
      return state
  }
}
