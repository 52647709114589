import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CustomTable from '../index'
import { PreOrderTableWrapper } from './index.styled'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { reInitializeOrderFilter, setOrderFilter } from '../../../../redux/action/orderfilter'
import { getPreOrdersDaysThunk, getPreOrdersTodayThunk } from '../../../../redux/thunk/order'
import PreOrdersToday from './preOrdersToday'
import PreOrdersDays from './preOrderDays'
import { selectIsNeededZIndex, selectIsTestCompany } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'
import { setPreOrdersDays, setPreOrdersDaysTotalLength, setPreOrdersToday, setPreOrdersTodayTotalLength } from '../../../../redux/action/order'
import { setSocketReconnected } from '../../../../redux/action/socket'
import { FINAL_WOLT_DRIVER_NOTIFICATION_DATE } from '../constants'
import { PartnerDriverTooltipText } from '../OrdersTable/index.styled'
import { useNavigate } from 'react-router-dom'
import { getDateParameterString, setWorkingHoursHelper } from '../../../../helper'
import { addDays } from 'date-fns'

const EVENT_Z_INDEX = 0

const PreOrdersTable = React.memo(({ id, defaultFilter, reverseToday, reverseDays, onClick, onDbClick, onEnter, onEscape, onDelete, onNrClick }) => {
	const { t } = useTranslation()
	const [filtersInitialized, setFiltersInitialized] = useState(false)
	const [activeRow, setActiveRow] = useState(0)
	const [focusRow, setFocusRow] = useState({ today: true, days: false })
	const [woltDriverNotificationIsNeeded, setWoltDriverNotificationIsNeeded] = useState(false)
	const preOrdersToday = useSelector(store => store.order.preOrdersToday)
	const preOrdersDays = useSelector(store => store.order.preOrdersDays)
	const preOrdersTodayTotalLength = useSelector(store => store.order.preOrdersTodayTotalLength)
	const preOrdersDaysTotalLength = useSelector(store => store.order.preOrdersDaysTotalLength)
	const orderType = useSelector(store => store.orderfilter.orderType)
	const date = useSelector(store => store.orderfilter.datePreOrder)
	const dateWithDaysFrom = useSelector(store => store.orderfilter.datePreOrderDaysFrom)
	const dateWithDaysTo = useSelector(store => store.orderfilter.datePreOrderDaysTo)
	const reversePreOrder = useSelector(store => store.orderfilter.reversePreOrder)
	const reversePreOrderDays = useSelector(store => store.orderfilter.reversePreOrderDays)
	const closed = useSelector(store => store.orderfilter.closed)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const socketReconnected = useSelector(store => store.socket.socketReconnected)
	const woltDriverIntegration = useSelector(store => store.woltDriverIntegrations.driverIntegrations)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isTestCompany = useSelector(selectIsTestCompany)

	const currentLength = preOrdersToday?.length + preOrdersDays?.length
	const totalLength = preOrdersTodayTotalLength + preOrdersDaysTotalLength

	const preOrderToday = preOrdersToday && preOrdersToday[activeRow]
	const preOrderDays = preOrdersDays && preOrdersDays[activeRow]
	const currentPreOrder = focusRow.today ? preOrderToday : preOrderDays
	const currentItemFocus = useMemo(() => ({
		TODAY: { today: true, days: false },
		OTHER_DAYS: { today: false, days: true },
	}), [])

	const navigateToWoltDriverSettings = useCallback(() => {
		navigate('/settings/driver-integrations/wolt')
	}, [navigate])

	const partnerDriverTooltip = useMemo(() => (
		<PartnerDriverTooltipText>{t('order.table.tooltip.partner_driver.main_text') + ' '}
			<span onClick={navigateToWoltDriverSettings}>
				{t('order.table.tooltip.partner_driver.to_settings')}
			</span>
		</PartnerDriverTooltipText>
	), [t, navigateToWoltDriverSettings])

	const tableHeadersStatic = useMemo(() => isTestCompany ?
		[
			{ title: t('order.table.nr'), onClick: onNrClick },
			{ title: t('order.table.time') },
			{ title: t('order.table.platform') },
			{ title: t('order.table.customer') },
			{ title: t('order.table.sum') },
			{
				title: t('order.table.partner_driver'),
				image: woltDriverNotificationIsNeeded ? 'new-green' : '',
				width: '10%', className: 'partner-driver',
				tooltip: { isShow: woltDriverNotificationIsNeeded, text: partnerDriverTooltip, width: '15.35em', position: 'top' },
			},
			{ title: t('order.table.type') },
			{ title: t('order.table.status') },
		] : [
			{ title: t('order.table.nr'), onClick: onNrClick },
			{ title: t('order.table.time') },
			{ title: t('order.table.platform') },
			{ title: t('order.table.customer') },
			{ title: t('order.table.sum') },
			{ title: t('order.table.type') },
			{ title: t('order.table.status') },
		], [t, woltDriverNotificationIsNeeded, onNrClick, isTestCompany, partnerDriverTooltip])

	useEffect(() => {
		batch(() => {
			dispatch(setPreOrdersToday([]))
			dispatch(setPreOrdersTodayTotalLength(0))
			dispatch(setPreOrdersDays([]))
			dispatch(setPreOrdersDaysTotalLength(0))
			setActiveRow(0)
		})
	}, [date, orderType, closed]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (socketReconnected) {
			// eslint-disable-next-line
			console.info('Load orders by socket reconnect')
			batch(() => {
				dispatch(setPreOrdersToday([]))
				dispatch(setPreOrdersTodayTotalLength(0))
				dispatch(setPreOrdersDays([]))
				dispatch(setPreOrdersDaysTotalLength(0))
			})
		}
		return () => dispatch(setSocketReconnected(false))
	}, [socketReconnected]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const filter = {
			...defaultFilter, reversePreOrder: reverseToday, reversePreOrderDays: reverseDays,
			datePreOrder: getDateParameterString(setWorkingHoursHelper(new Date())),
			datePreOrderDaysFrom: getDateParameterString(setWorkingHoursHelper(addDays(new Date(), 1))),
			datePreOrderDaysTo: getDateParameterString(setWorkingHoursHelper(addDays(new Date(), 7))),
		}
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		dispatch(setOrderFilter(filter))
		setFiltersInitialized(true)
		return () => dispatch(reInitializeOrderFilter())
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const now = new Date()
		const finalDate = new Date(FINAL_WOLT_DRIVER_NOTIFICATION_DATE)
		if (now <= finalDate && woltDriverIntegration !== null && !woltDriverIntegration?.length) {
			setWoltDriverNotificationIsNeeded(true)
		}
	}, [woltDriverIntegration])

	const onLoad = useCallback((page, size) => {
		dispatch(getPreOrdersTodayThunk(
			date,
			date,
			orderType,
			page, size, reversePreOrder, closed))
		if (preOrdersTodayTotalLength === preOrdersToday?.length) {
			dispatch(getPreOrdersDaysThunk(
				dateWithDaysFrom,
				dateWithDaysTo,
				orderType,
				page, size, reversePreOrderDays, closed))
		}
	}, [dispatch, date, dateWithDaysFrom, dateWithDaysTo, orderType, closed, reversePreOrder, reversePreOrderDays, preOrdersToday?.length, preOrdersTodayTotalLength])

	const handleFocus = useCallback((param) => {
		setFocusRow(param)
	}, [])

	const handleArrowUp = useCallback(() => {
		if (focusRow.today) {
			if (activeRow - 1 < 0) {
				setActiveRow(preOrdersDays.length - 1)
				handleFocus(currentItemFocus['OTHER_DAYS'])
			} else {
				setActiveRow(activeRow - 1)
			}
		} else {
			if (activeRow - 1 < 0) {
				setActiveRow(preOrdersToday.length - 1)
				handleFocus(currentItemFocus['TODAY'])
			} else {
				setActiveRow(activeRow - 1)
			}
		}
	}, [activeRow, preOrdersToday?.length, currentItemFocus, focusRow.today, handleFocus, preOrdersDays.length])

	const handleArrowDown = useCallback(() => {
		if (focusRow.today) {
			if (activeRow < preOrdersToday?.length - 1) {
				setActiveRow(activeRow + 1)
			} else {
				setActiveRow(0)
				handleFocus(currentItemFocus['OTHER_DAYS'])
			}
		} else {
			if (activeRow < preOrdersDays?.length - 1) {
				setActiveRow(activeRow + 1)
			} else {
				setActiveRow(0)
				handleFocus(currentItemFocus['TODAY'])
			}
		}
	}, [activeRow, preOrdersToday?.length, handleFocus, currentItemFocus, focusRow.today, preOrdersDays?.length])

	const handleEnter = useCallback(() => {
		if (!currentPreOrder) return
		if (onEnter) onEnter(currentPreOrder)
	}, [currentPreOrder, onEnter])

	const handleEscape = useCallback(() => {
		if (onEscape) onEscape()
	}, [onEscape])

	const handleDelete = useCallback(() => {
		if (!currentPreOrder) return
		if (onDelete) onDelete(currentPreOrder)
	}, [currentPreOrder, onDelete])

	const handleClick = useCallback((item, i, type) => {
		setActiveRow(i)
		if (onClick) onClick(item)
		handleFocus(currentItemFocus[type])
	}, [onClick, currentItemFocus, handleFocus])

	const handleDbClick = useCallback((item, i, type) => {
		setActiveRow(i)
		if (onDbClick) onDbClick(item)
		handleFocus(currentItemFocus[type])
	}, [onDbClick, currentItemFocus, handleFocus])

	return (
		<PreOrderTableWrapper id={id}>
			{filtersInitialized &&
				<CustomTable headers={tableHeadersStatic}
										 onLoad={onLoad}
										 currentLength={currentLength}
										 totalLength={totalLength}
										 perLoad={10}>
					<PreOrdersToday activeRow={activeRow}
													focusRow={focusRow.today}
													tableHeadersStatic={tableHeadersStatic}
													handleClick={handleClick}
													handleDbClick={handleDbClick} />
					<PreOrdersDays activeRow={activeRow}
												 focusRow={focusRow.days}
												 tableHeadersStatic={tableHeadersStatic}
												 handleClick={handleClick}
												 handleDbClick={handleDbClick} />
				</CustomTable>}

			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 enter={handleEnter}
										 escape={handleEscape}
										 delet={handleDelete} />}
		</PreOrderTableWrapper>
	)
})

PreOrdersTable.displayName = 'PreOrdersTable'
export default PreOrdersTable
