import phoneInstance from './instance'
import { notifyToast } from '../../../helper'

const deletePhone = (data, callbackSuccess, callbackError) => {

  if (!data.id) {
    notifyToast({ message: 'No phone parameter given' }, 'error')
    return
  }

  return async dispatch => {
    phoneInstance.delete(
      '/voip-settings/' + data.id,
    )
      .then(() => {
        typeof callbackSuccess === 'function' && callbackSuccess()
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }
}

export default deletePhone
