import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Home = lazyWithRetry(() => import(/* webpackChunkName: "home" */'../../component/Home'))

const HomePage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Home />
		</Suspense>
	)
})

HomePage.displayName = 'HomePage'
export default HomePage
