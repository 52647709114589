import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const PlusMinusWrap = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-left: auto;

  &.double {
    .plus {
      margin-left: 0.13em;
    }

    .minus {
      margin-right: 0.13em
    }

    &.medium, &.x-medium, &.big {
      .plus {
        margin-left: 0.25em;
      }

      .minus {
        margin-right: 0.25em;
      }
    }

    &.large {
      .plus {
        margin-left: 1.56em;
      }

      .minus {
        margin-right: 1.56em;
      }
    }
  }

  @media screen and (max-width: ${theme.point820}) {
    font-size: 0.71rem;
  }

  @media screen and (max-width: ${theme.point720}) {
    font-size: 1rem;

    &.double {
      &.large {
        justify-content: center;

        .plus {
          margin-left: 0.32em;
        }

        .minus {
          margin-right: 0.32em;
        }
      }

      &.x-medium {
        .plus {
          margin-left: 0.15em;
        }

        .minus {
          margin-right: 0.15em;
        }
      }
    }
  }
`
PlusMinusWrap.displayName = 'PlusMinusWrap'

export const SignButton = styled.button`
  font-size: 1em;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.background};
  border: 1px solid ${colorTheme.cart.cartProduct.plusMinusButtons.minus.border};
  border-radius: 0.75em;
  cursor: pointer;
  box-sizing: border-box;

  > svg {
    width: 1em;
    height: 1em;
    fill: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.icon};

    > path {
      width: 100%;
    }
  }

  // sizes:

  &.medium {
    width: 2.44em;
    height: 2.44em;
  }

  &.x-medium {
    width: 2.81em;
    height: 2.81em;

    > svg {
      width: 1.25em;
      height: 1.25em;
    }
  }

  &.big {
    width: 3.44em;
    height: 3.44em;

    > svg {
      width: 1.48em;
      height: 1.48em;
    }
  }

  &.large {
    width: 11.56em;
    height: 11.56em;
    border-radius: 1.5em;

    > svg {
      width: 5.81em;
      height: 5.81em;
    }
  }

  // colors:

  &.outline {
    background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.outline.background};
    border-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.outline.border};

    > svg {
      fill: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.outline.icon};
    }
  }

  &.gray {
    background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.gray.background};
    border-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.gray.border};

    > svg {
      fill: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.gray.icon};
    }
  }

  &.outline.gray {
    background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.outline.background};
    border-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.outline.border};

    > svg {
      fill: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.outline.icon};
    }
  }

  &.transparent {
		background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.transparent.background};
    border-color: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.transparent.border};

    > svg {
      fill: ${colorTheme.cart.cartProduct.plusMinusButtons.minus.transparent.icon};
    }
  }

  @media screen and (max-width: ${theme.point820}) {
    border-radius: 1.01em;

    &.x-medium > svg {
      width: 1.4em;
      height: 1.4em;
    }

		&.medium {
		  font-size: 1rem;
			width: 2.79em;
			height: 2.79em;
		}
  }

  @media screen and (max-width: ${theme.point720}) {
    border-radius: 0.75em;

    &.x-medium {
      width: 2em;
      height: 2em;

      > svg {
        width: 0.94em;
        height: 0.94em;
      }
    }

		&.medium {
			width: 2.44em;
			height: 2.44em;
		}
  }
`

export const PlusButton = styled(SignButton)`
	background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.background};
	border: 1px solid ${colorTheme.cart.cartProduct.plusMinusButtons.plus.border};

	> svg {
		fill: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.icon};
	}

	&.green {
		background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.green.background};
		border-color: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.green.border};
	}

	&.gray {
		background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.gray.background};
		border-color: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.gray.border};

		> svg {
			fill: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.gray.icon};
		}
	}

	&.transparent {
		background-color: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.transparent.background};
		border-color: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.transparent.border};

		> svg {
			fill: ${colorTheme.cart.cartProduct.plusMinusButtons.plus.transparent.icon};
		}
	}
`
PlusButton.displayName = 'PlusButton'

export const MinusButton = styled(SignButton)`
`
MinusButton.displayName = 'MinusButton'
