import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../buttons/Button'
import CustomModal from '../../../../modal/CustomModal'
import { useTranslation } from 'react-i18next'
import { getNeededDateFormat } from '../../../../../../helper'
import { HeaderCompanyTitle, HeaderCompanyWrap, HeaderContent, HeaderText, HeaderWrap, InvoiceContent, InvoiceDataWrap, InvoiceFooterWrap, InvoiceInfoText, InvoiceInfoWrap, InvoiceLabelBill, InvoiceLabelText, InvoiceOrderCount, InvoiceOrderHeader, InvoiceOrderHeaderTitle, InvoiceSheetWrap, InvoiceTitle } from './index.styled'
import MainIcon from '../../../../../../img/svg/main'
import InvoiceOrderProducts from './order/orderProducts'
import InvoiceOrderTotals from './order/orderTotals'
import { setShowInvoiceDataModal, setShowInvoiceOptionsModal } from '../../../../../../redux/action/invoice'

const EVENT_Z_INDEX = 81

const InvoiceDataModal = React.memo(({ handlePrint }) => {
	const { t } = useTranslation()
	const invoice = useSelector(store => store.invoice.selectedInvoice)
	const showInvoiceDataModal = useSelector(store => store.invoice.modal.showInvoiceData)
	const dispatch = useDispatch()
	const billingAddress = invoice.customer.billingAddress
	const company = invoice.company
	const order = invoice.order
	const date = getNeededDateFormat(invoice.createDate)
	const emailToDisplay = company?.ordersEmail ? company.ordersEmail : company.ownerEmail
	const layoutLabel = invoice.markAsPaid ? 'green' : 'orange'

	const handleClose = useCallback(() => {
		dispatch(setShowInvoiceDataModal(false))
		dispatch(setShowInvoiceOptionsModal(true))
	}, [dispatch])

	return (
		<CustomModal isOpen={showInvoiceDataModal}
								 close={handleClose}
								 title={t('invoices.modal.invoiceDataModal.title') + ' #' + invoice.invoiceNumber}
								 size="medium height-scroll"
								 id="invoice-data-modal"
								 zIndex={EVENT_Z_INDEX}
								 disableBackButton={true}
								 button={
									 <>
										 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')}
														 keyButton="F3" zIndex={EVENT_Z_INDEX}
														 onClick={handleClose}
														 className="back" />
										 <Button name="print" color="green" icon="print" text={t('buttons.controls.print')}
														 keyButton="F2" zIndex={EVENT_Z_INDEX}
														 onClick={handlePrint} />
									 </>
								 }>
			<InvoiceSheetWrap>
				<InvoiceLabelBill className={layoutLabel}>
					<MainIcon icon={invoice.markAsPaid ? 'paidInvoice' : 'unpaidInvoice'} />
					<InvoiceLabelText>{invoice.markAsPaid ? t('invoices.modal.invoiceDataModal.paid') : t('invoices.modal.invoiceDataModal.unpaid')}</InvoiceLabelText>
				</InvoiceLabelBill>
				<HeaderCompanyWrap>
					<HeaderCompanyTitle>
						{company.name} | {company.street} {company.house} | {company.zipCode} {company.city}
					</HeaderCompanyTitle>
				</HeaderCompanyWrap>
				<HeaderWrap>
					<HeaderContent>
						<HeaderText>{billingAddress.companyName}</HeaderText>
						<HeaderText>{billingAddress.name}</HeaderText>
						<HeaderText>{billingAddress.street} {billingAddress.streetNumber}</HeaderText>
						<HeaderText>{billingAddress.postalCode} {billingAddress.city}</HeaderText>
					</HeaderContent>
					<HeaderContent className="flex-end">
						<HeaderText>{t('invoices.modal.invoiceDataModal.invoice')}: #{invoice.invoiceNumber}</HeaderText>
						<HeaderText>{t('invoices.modal.invoiceDataModal.orderNumber')}: {order.orderDayCounter}</HeaderText>
						<HeaderText>{t('invoices.modal.invoiceDataModal.date')}: {date}</HeaderText>
						<HeaderText>{t('invoices.modal.invoiceDataModal.index')}: {invoice.customer.customerNumber}</HeaderText>
					</HeaderContent>
				</HeaderWrap>
				<InvoiceContent>
					<InvoiceTitle>{t('invoices.modal.invoiceDataModal.invoice')}</InvoiceTitle>
					<InvoiceInfoWrap>
						<InvoiceInfoText>{t('invoices.modal.invoiceDataModal.info')} {billingAddress.name},</InvoiceInfoText>
						<InvoiceInfoText>{t('invoices.modal.invoiceDataModal.info2')}</InvoiceInfoText>
					</InvoiceInfoWrap>
					<InvoiceOrderHeader>
						<InvoiceOrderHeaderTitle>{t('invoices.modal.invoiceDataModal.orderHeader.mark')}</InvoiceOrderHeaderTitle>
						<InvoiceOrderHeaderTitle>{t('invoices.modal.invoiceDataModal.orderHeader.totalPrice')}</InvoiceOrderHeaderTitle>
					</InvoiceOrderHeader>
					<InvoiceOrderProducts order={order} />
					<InvoiceOrderTotals order={order} />
					<InvoiceOrderCount><span className="bold">{t('invoices.modal.invoiceDataModal.order.count')}:</span> <span>{order?.purchaseItems?.length}</span></InvoiceOrderCount>
					<InvoiceInfoWrap className="big-gap">
						<InvoiceInfoText>{t('invoices.modal.invoiceDataModal.info3')}</InvoiceInfoText>
						<InvoiceInfoText>{t('invoices.modal.invoiceDataModal.info4')}:</InvoiceInfoText>
					</InvoiceInfoWrap>
					<InvoiceDataWrap>
						<div>
							<InvoiceInfoText>{t('invoices.modal.invoiceDataModal.owner')}:</InvoiceInfoText>
							<InvoiceInfoText>{company.name}</InvoiceInfoText>
						</div>
						<div>
							<InvoiceInfoText>{t('invoices.modal.invoiceDataModal.bic')}:</InvoiceInfoText>
							<InvoiceInfoText>{invoice.iban.bic}</InvoiceInfoText>
						</div>
						<div>
							<InvoiceInfoText>{t('invoices.modal.invoiceDataModal.iban')}:</InvoiceInfoText>
							<InvoiceInfoText>{invoice.iban.number}</InvoiceInfoText>
						</div>
					</InvoiceDataWrap>
					<InvoiceInfoWrap className="big-gap">
						<InvoiceInfoText>{t('invoices.modal.invoiceDataModal.regards') + ','}</InvoiceInfoText>
					</InvoiceInfoWrap>
					<InvoiceInfoWrap className="big-gap">
						<InvoiceInfoText>{company.name}</InvoiceInfoText>
					</InvoiceInfoWrap>
					<InvoiceFooterWrap>
						<InvoiceInfoText className="center">{company.name}</InvoiceInfoText>
						<InvoiceInfoText className="center">{company.street} {company.house} {company.zipCode} {company.city} {company.countryName}</InvoiceInfoText>
						<InvoiceInfoText className="center">Tel:{company.phonePrefix} {company.phoneNumber} {emailToDisplay}</InvoiceInfoText>
						<InvoiceInfoText className="center">St.-Nr.: {invoice.company.taxId} IBAN: {invoice.iban.number}</InvoiceInfoText>
					</InvoiceFooterWrap>
				</InvoiceContent>
			</InvoiceSheetWrap>
		</CustomModal>
	)
})

InvoiceDataModal.displayName = 'InvoiceDataModal'
export default InvoiceDataModal
