import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const LogoWrap = styled.div`
	font-size: 1rem;
	width: 100%;
	color: ${colorTheme.logo.text};
	display: flex;
	min-height: 5.13em;
	align-items: center;
	opacity: 1;
	padding: 0 7em 0 1.44em;
	transition: all 0.2s ease-in-out;

	> svg {
		width: 8em;
		height: 1.94em;
	}

	&.toggled {
		padding: 0 1.25em;

		> svg {
			width: 1.93em;
			height: 1.93em;
		}
	}

	&.authPage {
		padding: 0;
		min-height: 4.06em;

		> svg {
			width: 16.94em;
			height: 4.06em
		}
	}

	@media screen and (max-width: ${theme.point1440}) {
		&.toggled {
			> svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		min-height: 1em;

		&.authPage {
			min-height: 3.43em;

			> svg {
				width: 14.29em;
				height: 3.43em
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0;

		&.authPage {
			min-height: 1.93em;

			> svg {
				width: 8em;
				height: 1.93em;
			}
		}
	}
`
LogoWrap.displayName = 'LogoWrap'
