import React from 'react'
import { Key, ClearKey, EnterKey, Keyboard, KeyText } from './index.styled'
import MainIcon from '../../../../img/svg/main'

const NumbersKeyboard = React.memo(({ handleClick, enter, reset }) => {
	return (
		<Keyboard>
			<Key onClick={() => handleClick('7')}><KeyText>7</KeyText></Key>
			<Key onClick={() => handleClick('8')}><KeyText>8</KeyText></Key>
			<Key onClick={() => handleClick('9')}><KeyText>9</KeyText></Key>
			<Key onClick={() => handleClick('4')}><KeyText>4</KeyText></Key>
			<Key onClick={() => handleClick('5')}><KeyText>5</KeyText></Key>
			<Key onClick={() => handleClick('6')}><KeyText>6</KeyText></Key>
			<Key onClick={() => handleClick('1')}><KeyText>1</KeyText></Key>
			<Key onClick={() => handleClick('2')}><KeyText>2</KeyText></Key>
			<Key onClick={() => handleClick('3')}><KeyText>3</KeyText></Key>

			<ClearKey onClick={reset}>
				<KeyText>CE</KeyText>
			</ClearKey>

			<Key onClick={() => handleClick('0')}><KeyText>0</KeyText></Key>

			<EnterKey data-testid="pin-keyboard-enter" onClick={enter}>
				<MainIcon icon="enter" />
			</EnterKey>
		</Keyboard>
	)
})

NumbersKeyboard.displayName = 'NumbersKeyboard'
export default NumbersKeyboard
