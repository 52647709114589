import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Area = lazyWithRetry(() => import(/* webpackChunkName: "area" */'../../component/Area'))

const AreaPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Area />
		</Suspense>
	)
})

AreaPage.displayName = 'AreaPage'
export default AreaPage
