import styled from 'styled-components'
import { theme } from '../../../../../style/theme'

export const PartnerDriverWrapper = styled.div`
	display: flex;
	gap: 0.31em;
	align-items: center;

	> svg {
		width: 2.5em;
		height: 2.5em;
		flex-shrink: 0;
	}

	> svg.TAKEAWAY_DRIVE, svg.FOODORA_DRIVE {
		width: 5em;
		height: 1.3em;
	}
`
PartnerDriverWrapper.displayName = 'PartnerDriverWrapper'

export const PartnerDriverTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`
PartnerDriverTextWrapper.displayName = 'PartnerDriverTextWrapper'

export const OtherDriverText = styled.div`
	color: #2981EE;
	font-family: ${theme.fontRoboto};
	font-size: 0.65em;
	font-weight: 500;
	line-height: 100%;
`
OtherDriverText.displayName = 'OtherDriverText'
