import React from 'react'
import { CartProductWrap, Product, ProductCol, ProductDiscountPrice, ProductName, ProductNr, ProductPrice, ProductQuantity, ProductToppings, ProductTotal, Topping, ToppingName, ToppingPrice, ToppingTotal } from './index.styled'
import PlusMinus from '../PlusMinus'
import CartRemark from '../CartRemark'
import { useSelector } from 'react-redux'
import { useLocalStorage } from '../../../../../../../hooks/useLocalStorage'
import { calcCartItemTotalPrice } from '../../../../../../../helper'
import { selectDiscountForItem } from '../../../../../../../redux/selector/cart'
import Currency from '../../../../../../Elements/currency'

const CartProduct = React.memo(({ item, incrementAbility = true, onClickPlus, AddButton, plusBtnColor, isMenuItem = false }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const totalPrice = item ? calcCartItemTotalPrice(item) : 0
	const discount = useSelector(selectDiscountForItem(totalPrice))
	const layout = (touchPad ? 'touchPad ' : '')
	const itemHasDiscount = discount > 0 && !item?.disabledDiscount && !isMenuItem

	const discountedPrice = itemHasDiscount ? totalPrice - discount : totalPrice
	const layoutPrice = (itemHasDiscount ? 'green ' : '')
	const layoutProductWrap = `${layout} ${item?.dishCourse ? 'dishCourse' : ''}`

	return (
		<CartProductWrap className={layoutProductWrap} data-testid="item-product">
			<Product className={`product ${layout}`}>
				<ProductNr className={layout}>{item?.sku}</ProductNr>
				<ProductName className={layout}>
					{item?.name}
					{item?.quantity > 1 &&
						<ProductQuantity className={layout}> x {item?.quantity}</ProductQuantity>}
				</ProductName>

				<ProductCol className={layout}>
					<Currency>{item?.price}</Currency>
				</ProductCol>
				<ProductTotal className={layout}>
					{itemHasDiscount && <ProductDiscountPrice><Currency>{totalPrice}</Currency></ProductDiscountPrice>}
					<ProductPrice className={layoutPrice}><Currency>{discountedPrice}</Currency></ProductPrice>
				</ProductTotal>
				<PlusMinus {...{ AddButton, onClickPlus, incrementAbility, size: touchPad ? 'x-medium' : '', plusBtnColor }} />
			</Product>
			{(item?.items?.length > 0 || item?.remark) &&
				<ProductToppings className={`toppings ${layout}`}>
					{item?.remark &&
						<CartRemark remark={item?.remark}
												layout={`${layout}`} />}
					{item?.items?.map((subItem, subItemIndex) =>
						<Topping key={subItemIndex} className={layout + (subItem.quantity < 0 ? ' minus' : '') + (subItem.status === 'CANCELED' ? ' strikethrough' : '')} data-testid={subItem.name}>
							<ToppingName className={layout + (subItem.quantity < 0 ? ' minus' : '')}>
								{subItem.quantity > 0 ?
									<svg xmlns="http://www.w3.org/2000/svg" width="11.625" height="11.625" viewBox="0 0 11.625 11.625">
										<path d="M324.433,202.056l4.309-.018a0.754,0.754,0,0,0,.752-0.752,0.743,0.743,0,0,0-.747-0.748l-4.31.019,0.019-4.31a0.746,0.746,0,0,0-.748-0.748,0.756,0.756,0,0,0-.752.753l-0.019,4.309-4.309.019a0.754,0.754,0,0,0-.752.752,0.741,0.741,0,0,0,.747.747l4.31-.018-0.019,4.309a0.744,0.744,0,0,0,.748.748,0.754,0.754,0,0,0,.752-0.752Z" transform="translate(-317.875 -195.5)"></path>
									</svg> :
									<svg width="11" height="1" viewBox="0 0 11 1" xmlns="http://www.w3.org/2000/svg">
										<rect width="11" height="1" rx="0.5" />
									</svg>}
								{subItem.name}
								{(subItem?.quantity > 1 || subItem?.quantity < -1) &&
									<ProductQuantity className={layout}> x {Math.abs(subItem.quantity)}</ProductQuantity>}
							</ToppingName>
							<ToppingPrice className={layout}><Currency>{subItem.price}</Currency></ToppingPrice>
							<ToppingTotal className={layout} />
						</Topping>)}
				</ProductToppings>}
		</CartProductWrap>
	)
})

CartProduct.displayName = 'CartProduct'
export default CartProduct
