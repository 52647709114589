import React from 'react'
import { CartLogoSubtitle, CartLogoSubTitleWrapper, CartLogoTitle, CartLogoTitleWrapper, CartLogoWrapper } from './index.styled'
import { useSelector } from 'react-redux'

const ClientMonitorCartLogo = React.memo(({ layout }) => {
	const logoTextEnabled = useSelector(state => state.clientMonitorConfig.config?.logoTextEnabled)
	const logoText = useSelector(state => state.clientMonitorConfig.config?.logoText)

	return (
		<CartLogoWrapper className={layout}>
			{logoTextEnabled &&
				<CartLogoTitleWrapper>
					<CartLogoTitle>{logoText}</CartLogoTitle>
				</CartLogoTitleWrapper>}
			<CartLogoSubTitleWrapper>
				<CartLogoSubtitle>Powered by liefersoft.de</CartLogoSubtitle>
			</CartLogoSubTitleWrapper>
		</CartLogoWrapper>
	)
})

ClientMonitorCartLogo.displayName = 'ClientMonitorCartLogo'
export default ClientMonitorCartLogo
