import { DEFAULT_FONT_SIZE, fillMeta, PATTERN_SIZE_TABLE, point1024, point1280, point1440, point1660, point720, point820 } from './constants'

export const detectFontSize = () => {
	if (point720) return 16
	if (point820) return 14
	if (point1024) return 10.7
	if (point1280) return 10.7
	if (point1440) return 12
	if (point1660) {
		return 13.8
	} else {
		return 16
	}
}

const pxToMeasurement = (obj) => {
	let value
	switch (obj.type) {
		case 'EM':
			value = Math.floor((obj.value / DEFAULT_FONT_SIZE) * 100) / 100
			break
		default:
			value = obj.value
	}
	return { value, type: obj.type }
}

export const fillTables = (tables) => {
	if (!tables || !tables.length || tables.length === 0) return []
	let ii = 0
	let yy = 50
	return tables.reduce((res, row) => {
		const { meta } = row
		if (meta.formType) return [...res, { ...row, meta: { ...row.meta } }]
		let newmeta = { ...fillMeta }
		const { width, height } = PATTERN_SIZE_TABLE.reduce((e, res) => e.formType === newmeta.formType ? e : res, { width: 0, height: 0 })
		newmeta['width'] = pxToMeasurement({ ...newmeta['width'], value: width })
		newmeta['height'] = pxToMeasurement({ ...newmeta['height'], value: height })
		if (ii === 6) {
			ii = 0
			yy = yy + height + 45
		}
		newmeta['coordX'] = pxToMeasurement({ ...newmeta['coordX'], value: newmeta['coordX']['value'] + (ii * (width + 45)) })
		newmeta['coordY'] = pxToMeasurement({ ...newmeta['coordY'], value: yy })
		ii++
		return [...res, { ...row, meta: { ...row.meta, ...newmeta } }]
	}, [])
}

