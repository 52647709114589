import { createSelector } from 'reselect'
import { selectTimerCounter } from '../timerCounter'
import { compareAsc } from 'date-fns'

export const selectOrderTypeWorkingStatus = (orderType) => store => {
	const orderTypeWorkingStatuses = store.webshopinfo.config?.orderTypeWorkingStatuses || []
	return orderTypeWorkingStatuses.find(el => el.orderType === orderType)
}

export const selectIsWorkingOrderType = (orderType) => createSelector(
	selectOrderTypeWorkingStatus(orderType),
	selectTimerCounter,
	(orderTypeWorkingStatus, counter) => {
		const breakDateTo = orderTypeWorkingStatus?.breakDateTo
		const isWorking = orderTypeWorkingStatus?.working

		if (!isWorking) return false
		else if (isWorking && !breakDateTo) return true
		else if (isWorking && breakDateTo) {
			const orderTypeBreakDateTo = new Date(breakDateTo)
			const currentTime = new Date()
			return compareAsc(currentTime, orderTypeBreakDateTo) === 1 // the first date is after the second
		}
	}
)

export const selectOrderTypeBreakDateTo = (orderType) => createSelector(
	selectOrderTypeWorkingStatus(orderType),
	selectTimerCounter,
	(orderTypeWorkingStatus, counter) => {
		const breakDateTo = orderTypeWorkingStatus?.breakDateTo
		const isWorking = orderTypeWorkingStatus?.working

		if (!isWorking || !breakDateTo) return null
		else if (isWorking && breakDateTo) {
			const orderTypeBreakDateTo = new Date(breakDateTo)
			const currentTime = new Date()
			const isBreakDateAfterNow = compareAsc(orderTypeBreakDateTo, currentTime) === 1 // the first date is after the second
			return isBreakDateAfterNow ? breakDateTo : null
		}
	}
)
