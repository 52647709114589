import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CustomModal from '../../Elements/modal/CustomModal'
import TodayPreOrdersTable from './TodayPreOrderTable'
import { getNeededDateFormat } from '../../../helper'
import { setPreOrdersToday, setPreOrdersTodayTotalLength, setShowTodayPreOrdersModal } from '../../../redux/action/order'
import { ButtonWrapper } from './index.styled'
import Button from '../../Elements/buttons/Button'
import { registerPreOrdersThunk } from '../../../redux/thunk/order/registerPreOrders'

const EVENT_Z_INDEX = 1000

const TodayPreOrderModal = React.memo(() => {
	const { t } = useTranslation()
	const showTodayPreOrderModal = useSelector(store => store.order?.showTodayPreOrderModal)
	const dispatch = useDispatch()
	const today = getNeededDateFormat(new Date(), 'dd.MM.yyyy')

	const handleClose = useCallback(() => {
		dispatch(setPreOrdersToday([]))
		dispatch(setPreOrdersTodayTotalLength(0))
		dispatch(setShowTodayPreOrdersModal(false))
	}, [])

	const handleRegisterAllPreOrders = useCallback(() => {
		dispatch(registerPreOrdersThunk())
		handleClose()
	}, [])

	return (
		<CustomModal isOpen={showTodayPreOrderModal}
								 close={handleClose}
								 id="today-pre-order-modal"
								 disableBackButton={true}
								 size="x-larger height-scroll"
								 zIndex={EVENT_Z_INDEX}
								 title={t('Modal.today_pre_order_modal.title') + ' ' + today}>
			<TodayPreOrdersTable />
			<ButtonWrapper>
				<Button name="accept" icon="checkmark" color="green"
								keyButton="F2" zIndex={EVENT_Z_INDEX}
								onClick={handleRegisterAllPreOrders}
								text={t('Modal.today_pre_order_modal.button')} />
			</ButtonWrapper>
		</CustomModal>

	)
})

TodayPreOrderModal.displayName = 'TodayPreOrderModal'
export default TodayPreOrderModal
