import loadable from '@loadable/component'
import React from 'react'

const PAGE_REFRESHED_KEY = 'page-has-been-force-refreshed'

/**
 * @deprecated The method should not be used
 */
export const loadableWithRetry = (componentImport, options) =>
	loadable(async () => {
		const pageHasAlreadyBeenForceRefreshed =
			JSON.parse(window.localStorage.getItem(PAGE_REFRESHED_KEY) || 'false')
		let component = () => (options?.fallback || <div>Loading...</div>)
		try {
			component = await componentImport()
			window.localStorage.setItem(PAGE_REFRESHED_KEY, 'false')
		} catch (error) {
			if (!pageHasAlreadyBeenForceRefreshed) {
				window.localStorage.setItem(PAGE_REFRESHED_KEY, 'true')
				window.location.reload()
				return component
			}
			throw error
		}
		return component
	}, options)

export const lazyWithRetry = (componentImport, options) => {
	return React.lazy(() => {
		const pageHasAlreadyBeenForceRefreshed =
			JSON.parse(window.localStorage.getItem(PAGE_REFRESHED_KEY) || 'false')
		let component = () => (options?.fallback || <div>Loading...</div>)
		try {
			component = componentImport()
			window.localStorage.setItem(PAGE_REFRESHED_KEY, 'false')
		} catch (error) {
			if (!pageHasAlreadyBeenForceRefreshed) {
				window.localStorage.setItem(PAGE_REFRESHED_KEY, 'true')
				window.location.reload()
				return component
			}
			throw error
		}
		return component
	})
}
