import React from 'react'
import { CartProductBlock, CartProductDiscountedPrice, CartProductInitialPriceBlock, CartProductPrice, CartProductQuantity, CartProductTitle, CartProductTotalPriceBlock, CartProductWrapper, CartTopping, CartToppingPrice, CartToppings, CartToppingTitle } from '../index.styled'
import Currency from '../../../../../Elements/currency'
import { calcCartItemTotalPrice, getPercentDiscountInCurrency } from '../../../../../../helper'
import { useSelector } from 'react-redux'
import { selectDiscountValue } from '../../../../../../redux/selector/clientMonitorCart'
import { CartProductQuantityWrap } from '../../index.styled'

const CartProduct = React.memo(({ item, index, isMenuProduct = false }) => {
	const totalPrice = item ? calcCartItemTotalPrice(item) : 0
	const discountValue = useSelector(selectDiscountValue)
	const discount = getPercentDiscountInCurrency(discountValue, totalPrice)
	const itemHasDiscount = discount > 0 && !item?.disabledDiscount && !isMenuProduct
	const discountedPrice = itemHasDiscount ? totalPrice - discount : totalPrice
	const layout = (isMenuProduct ? 'menu' : '')
	const layoutTotalPrice = (itemHasDiscount ? 'active' : 'total')
	const testId = (isMenuProduct ? 'menu-product-' : 'product-') + index

	return (
		<>
			<CartProductWrapper className={layout}>
				<CartProductBlock className={layout}>
					<CartProductTitle className={layout}>{item?.name}</CartProductTitle>
					{item?.quantity > 1 &&
						<CartProductQuantityWrap>
							<CartProductQuantity data-testid={testId + '-quantity'} className={layout}>x {item?.quantity}</CartProductQuantity>
						</CartProductQuantityWrap>
					}
					{!isMenuProduct &&
						<>
							<CartProductInitialPriceBlock>
								<CartProductPrice data-testid={testId + '-initial-price'}><Currency>{item?.price}</Currency></CartProductPrice>
							</CartProductInitialPriceBlock>
							<CartProductTotalPriceBlock>
								{itemHasDiscount && <CartProductDiscountedPrice data-testid={testId + '-discounted-price'}><Currency>{totalPrice}</Currency></CartProductDiscountedPrice>}
								<CartProductPrice data-testid={testId + '-price'} className={layoutTotalPrice}><Currency>{discountedPrice}</Currency></CartProductPrice>
							</CartProductTotalPriceBlock>
						</>}
					{item?.items?.length > 0 &&
						<CartToppings className={layout}>
							{item?.items?.map((subItem, subItemIndex) =>
								<CartTopping key={subItemIndex}>
									<CartToppingTitle>{subItem.name} x{subItem.quantity}</CartToppingTitle>
									<CartToppingPrice>({subItem.quantity > 0 ? '+' : '-'}<Currency>{subItem.price}</Currency>)</CartToppingPrice>
								</CartTopping>)}
						</CartToppings>}
				</CartProductBlock>
			</CartProductWrapper>
		</>
	)
})

CartProduct.displayName = 'CartProduct'
export default CartProduct
