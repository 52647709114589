import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { getCustomerCallThunk } from '../../../redux/thunk/customer'
import { useDispatch, useSelector } from 'react-redux'
import { setAppEvent, setAppModal } from '../../../redux/action/system'
import { setCustomer } from '../../../redux/action/customer'
import { setDeliveryCartCurrentDeliveryCost, setDeliveryCartOrderType } from '../../../redux/action/deliveryCart'
import Button from '../../Elements/buttons/Button'

const CustomerMenu = React.memo(({ handleSubmit, onSubmit, trigger, watch, navigateToCart }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const incomeModalShow = useSelector(store => store.system.modal.incomeModalShow)

	const handleCustomerCalls = useCallback(() => {
		dispatch(getCustomerCallThunk())
		dispatch(setAppModal({ incomeModalShow: !incomeModalShow }))
		dispatch(setAppEvent({ eventCall: true }))
	}, [incomeModalShow, dispatch])

	const handleValidationAndNavigation = useCallback(async (orderType) => {
		const valid = await trigger(['phoneNumber', 'name'])
		const customerNumber = watch('customerNumber')

		if (!customerNumber && !watch('phoneNumber') && !watch('name')) {
			dispatch(setCustomer(null))
			dispatch(setDeliveryCartOrderType(orderType))
			navigateToCart()
		} else if (customerNumber && valid) {
			dispatch(setDeliveryCartOrderType(orderType))
			navigateToCart()
		} else if (valid) {
			dispatch(setDeliveryCartOrderType(orderType))
			handleSubmit(onSubmit)(false)
		}
		dispatch(setDeliveryCartCurrentDeliveryCost(0))
	}, [watch, trigger, dispatch, navigateToCart, onSubmit, handleSubmit])

	return (
		<>
			<Button icon="pickup" text={t('AddOrder.Client.menu.Selbstabholer')} keyButton="F5"
							showTextForMobile={false} color="blue" size="small" zIndex={0} onClick={() => handleValidationAndNavigation('PICKUP')} />
			<Button icon="counter" text={t('AddOrder.Client.menu.Hausverkauf')} keyButton="F6"
							showTextForMobile={false} color="blue" size="small" zIndex={0} onClick={() => handleValidationAndNavigation('COUNTER')} />
			<Button icon="phone" text={t('Modal.incoming_call.title')} keyButton="F3"
							showTextForMobile={false} color="green" size="small" zIndex={0} onClick={handleCustomerCalls} />
		</>
	)
})

CustomerMenu.displayName = 'CustomerMenu'
export default CustomerMenu
