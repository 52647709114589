import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { getOrdersThunk } from '../../../../redux/thunk/order'
import { useTranslation } from 'react-i18next'
import { setOrders, setOrdersTotalLength } from '../../../../redux/action/order'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { reInitializeOrderFilter, setOrderFilter } from '../../../../redux/action/orderfilter'
import CustomTable from '../index'
import { setSocketReconnected } from '../../../../redux/action/socket'
import OrdersTableTdList from './OrdersTableTdList'
import TableTr from '../tr'
import { selectIsNeededZIndex, selectIsTestCompany } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'
import { OrderTableWrapper, PartnerDriverTooltipText } from './index.styled'
import { isPaidOrder } from './helper'
import { useNavigate } from 'react-router-dom'
import { FINAL_WOLT_DRIVER_NOTIFICATION_DATE } from '../constants'
import { getDateParameterString, setWorkingHoursHelper } from '../../../../helper'

const EVENT_Z_INDEX = 0
const PER_LOAD = 10

const OrdersTable = React.memo(({ id, reverse, defaultFilter = {}, onClick, onDbClick, onEnter, onEscape, onDelete, onNrClick }) => {
	const { t } = useTranslation()
	const [activeRow, setActiveRow] = useState(0)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const orders = useSelector(store => store.order.orders)
	const ordersLoading = useSelector(store => store.order.ordersLoading)
	const ordersTotalLength = useSelector(store => store.order.ordersTotalLength)
	const paymentMethod = useSelector(store => store.orderfilter.paymentMethod)
	const status = useSelector(store => store.orderfilter.status)
	const deliveryPartnerType = useSelector(store => store.orderfilter.deliveryPartnerType)
	const excludedStatus = useSelector(store => store.orderfilter.excludedStatus)
	const orderType = useSelector(store => store.orderfilter.orderType)
	const processedByUsername = useSelector(store => store.orderfilter.processedByUsername)
	const processedByUsernameWithEmpty = useSelector(store => store.orderfilter.processedByUsernameWithEmpty)
	const withOnlyRegisteredPreOrders = useSelector(store => store.orderfilter.withOnlyRegisteredPreOrders)
	const date = useSelector(store => store.orderfilter.date)
	const reverseSort = useSelector(store => store.orderfilter.reverse)
	const closed = useSelector(store => store.orderfilter.closed)
	const socketReconnected = useSelector(store => store.socket.socketReconnected)
	const woltDriverIntegration = useSelector(store => store.woltDriverIntegrations.driverIntegrations)
	const [filtersInitialized, setFiltersInitialized] = useState(false)
	const [woltDriverNotificationIsNeeded, setWoltDriverNotificationIsNeeded] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isTestCompany = useSelector(selectIsTestCompany)
	const order = orders && orders[activeRow]

	const navigateToWoltDriverSettings = useCallback(() => {
		navigate('/settings/driver-integrations/wolt')
	}, [navigate])

	const partnerDriverTooltip = useMemo(() => <PartnerDriverTooltipText>{t('order.table.tooltip.partner_driver.main_text') + ' '}
		<span onClick={navigateToWoltDriverSettings}>{t('order.table.tooltip.partner_driver.to_settings')}</span></PartnerDriverTooltipText>, [navigateToWoltDriverSettings, t])

	const tableHeadersStatic = useMemo(() =>
		isTestCompany ?
			[
				{ title: t('order.table.nr'), onClick: onNrClick },
				{ title: t('order.table.time') },
				{ title: t('order.table.platform') },
				{ title: t('order.table.customer') },
				{ title: t('order.table.sum') },
				{
					title: t('order.table.partner_driver'),
					image: woltDriverNotificationIsNeeded ? 'new-green' : '',
					width: '10%', className: 'partner-driver',
					tooltip: { isShow: woltDriverNotificationIsNeeded, text: partnerDriverTooltip, width: '15.35em', position: 'top' }
				},
				{ title: t('order.table.type') },
				{ title: t('order.table.status') }
			] : [
				{ title: t('order.table.nr'), onClick: onNrClick },
				{ title: t('order.table.time') },
				{ title: t('order.table.platform') },
				{ title: t('order.table.customer') },
				{ title: t('order.table.sum') },
				{ title: t('order.table.type') },
				{ title: t('order.table.status') }
			], [isTestCompany, partnerDriverTooltip, woltDriverNotificationIsNeeded, onNrClick, t])

	useEffect(() => {
		const filter = {
			...defaultFilter, reverse,
			date: getDateParameterString(setWorkingHoursHelper(new Date())),
		}
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		dispatch(setOrderFilter(filter))
		setFiltersInitialized(true)
		return () => dispatch(reInitializeOrderFilter())
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		batch(() => {
			dispatch(setOrders([]))
			dispatch(setOrdersTotalLength(0))
			setActiveRow(0)
		})
	}, [date, paymentMethod, status, orderType,deliveryPartnerType, processedByUsername, processedByUsernameWithEmpty, closed]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (socketReconnected) {
			// eslint-disable-next-line
			console.info('Load orders by socket reconnect')
			batch(() => {
				dispatch(setOrders([]))
				dispatch(setOrdersTotalLength(0))
			})
		}
		return () => dispatch(setSocketReconnected(false))
	}, [socketReconnected]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (filtersInitialized)
			onLoad(0, PER_LOAD)
	}, [filtersInitialized]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const now = new Date()
		const finalDate = new Date(FINAL_WOLT_DRIVER_NOTIFICATION_DATE)
		if (now <= finalDate && woltDriverIntegration !== null && !woltDriverIntegration?.length) {
			setWoltDriverNotificationIsNeeded(true)
		}
	}, [woltDriverIntegration])

	const onLoad = useCallback((page, size) => {
		if (filtersInitialized)
			dispatch(getOrdersThunk(
				date,
				date,
				paymentMethod,
				status,
				excludedStatus,
				orderType,
				deliveryPartnerType,
				processedByUsername,
				processedByUsernameWithEmpty,
				page, size, reverseSort, closed, withOnlyRegisteredPreOrders))
	}, [filtersInitialized, date, paymentMethod, status, orderType, deliveryPartnerType, processedByUsername, processedByUsernameWithEmpty, reverseSort, closed, withOnlyRegisteredPreOrders, excludedStatus, dispatch])

	const handleArrowUp = useCallback(() => {
		if (activeRow < 0) {
			setActiveRow(orders.length)
		} else {
			setActiveRow(activeRow - 1)
		}
	}, [activeRow, orders?.length])

	const handleArrowDown = useCallback(() => {
		if (activeRow < orders?.length) {
			setActiveRow(activeRow + 1)
		} else {
			setActiveRow(0)
		}
	}, [activeRow, orders?.length])

	const handleClick = useCallback((item, i) => {
		setActiveRow(i)
		if (onClick) onClick(item)
	}, [onClick])

	const handleDbClick = useCallback((item, i) => {
		setActiveRow(i)
		if (onDbClick) onDbClick(item)
	}, [onDbClick])

	const handleEnter = useCallback(() => {
		if (!order) return
		if (onEnter) onEnter(order)
	}, [order, onEnter])

	const handleEscape = useCallback(() => {
		if (onEscape) onEscape()
	}, [onEscape])

	const handleDelete = useCallback(() => {
		if (!order) return
		if (onDelete) onDelete(order)
	}, [order, onDelete])

	return (
		<OrderTableWrapper id={id}>
			<CustomTable headers={tableHeadersStatic}
										onLoad={onLoad}
										loading={ordersLoading}
										currentLength={orders?.length}
										totalLength={ordersTotalLength}
										params={[date, paymentMethod, status, orderType, deliveryPartnerType, processedByUsername, processedByUsernameWithEmpty, reverse, closed]}
										perLoad={PER_LOAD}>
				{orders?.map((item, i) =>
					<TableTr key={i}
										onDoubleClick={() => handleDbClick(item, i)} onClick={() => handleClick(item, i)}
										isActive={i === activeRow}
										disabled={item?.status?.status === 'CANCELED' || !!item?.zNumber}
										disabledStatus={!!item?.zNumber}
										name={item?.zNumber ? 'CLOSED' : item?.status?.status}
										className="row-tablet-cols-4 bold-border"
										dataCypressInfo={isPaidOrder(item)}>
						<OrdersTableTdList tableHeadersStatic={tableHeadersStatic} item={item} />
					</TableTr>)}
			</CustomTable>

			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 enter={handleEnter}
										 escape={handleEscape}
										 delet={handleDelete} />}
		</OrderTableWrapper>
	)
})

OrdersTable.displayName = 'OrdersTable'
export default OrdersTable
