import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const CashDrawer = lazyWithRetry(() => import(/* webpackChunkName: "cash-drawer" */'../../component/CashDrawer'))

const CashDrawerPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<CashDrawer />
		</Suspense>
	)
})

CashDrawerPage.displayName = 'CashDrawerPage'
export default CashDrawerPage
