import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const KeyIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 21px;
  height: 21px;
  padding: 0 2px;
  border-radius: 6px;
  border: 1px solid ${colorTheme.keyIcon.border};
  color: ${colorTheme.keyIcon.text};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.1em;
  font-family: ${theme.fontRobotoBold};
  text-transform: uppercase;
  box-sizing: border-box;

  &.big {
    min-width: 31px;
    height: 31px;
    font-size: 12px;
  }

	@media screen and (max-width: ${theme.point720}) {
		display: none;
	}
`
