import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { TooltipWrapper } from '../../tooltip/index.styled'

export const ExtraDiscountLabel = styled.label`
	width: 100%;
	height: 100%;
	cursor: pointer;
	font-size: 1em;
	display: grid;
	align-items: center;
	grid-template-columns: 2.5fr 1fr 1fr;

	> ${TooltipWrapper} {
		justify-self: end;
	}
`
ExtraDiscountLabel.displayName = 'ExtraDiscountLabel'

export const ExtraDiscountTextWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 0.63em;
`
ExtraDiscountTextWrap.displayName = 'ExtraDiscountTextWrap'

export const ExtraDiscountInput = styled.div`
	display: flex;
	width: 5.88em;
	height: 2.13em;
	border-radius: 0.37em;
	border: 1px solid ${colorTheme.cart.extraDiscountInput.border};
	background-color: ${colorTheme.cart.extraDiscountInput.background};
	outline: 0;
	padding: 0;
	position: relative;
	z-index: 0;
	justify-self: end;

	&.active {
		border-color: ${colorTheme.cart.extraDiscountInput.borderActive};
	}

	> input {
		font-size: 1.12em;
		width: 54%;
		padding: 0;
		border: none;
		margin: 0 0.31em;
		background: transparent;
		font-family: ${theme.fontRobotoMedium};
		text-align: center;
		box-sizing: border-box;
		color: ${colorTheme.cart.extraDiscountInput.input.text};
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
			display: none;
			-webkit-appearance: none;
			margin: 0;
		}
	}

	select {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 46%;
		padding: 0.31em;
		outline: none;
		border-left: 1px solid ${colorTheme.cart.extraDiscountInput.border};
		background-color: transparent;
		appearance: none;
		font-size: 1.12em;
		font-family: ${theme.fontRobotoMedium};
		color: ${colorTheme.cart.extraDiscountInput.currency.text};
		z-index: 1;
	}

	&:after {
		display: block;
		content: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.22 8.21C6.97 8.21 6.72001 8.12 6.53 7.92L0.290005 1.67C-0.0899951 1.29 -0.0899951 0.670005 0.290005 0.290005C0.670005 -0.0899951 1.29 -0.0899951 1.67 0.290005L7.23 5.86L12.81 0.290005C13.19 -0.0899951 13.81 -0.0899951 14.19 0.290005C14.57 0.670005 14.57 1.29 14.19 1.67L7.91 7.92C7.72 8.11 7.47 8.21 7.22 8.21Z' fill='%232881EE'/%3E%3C/svg%3E%0A");
		width: 0.94em;
		position: absolute;
		top: 0.30em;
		right: 0.17em;
		z-index: 0;
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 6.06em;
	}
`
