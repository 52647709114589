import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const CategoryWrap = styled.li`
	margin: 0;
	border-radius: 0.75em;
	border: 2px solid ${props => props.themeStyles.category.border};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.19em;
	padding: 1.25em 0.31em;
	cursor: pointer;
	background: ${props => props.themeStyles.category.background};
	min-height: 11.8em;
	transition: all 0.3s ease;

	&:hover {
		border: 4px solid ${colorTheme.productTableCategory.borderHover};
	}

	&:active {
		border: 4px solid ${colorTheme.productTableCategory.borderActive};
	}

	&.dark {
		&:active {
			border-top: 4px solid ${colorTheme.productTableCategory.borderTopActive};
			border-bottom: none;
			border-left: none;
			border-right: none;
		}
	}

	@media screen and (max-width: ${theme.point1024}) {
		min-height: 9em;
	}

	@media screen and (max-width: ${theme.point820}) {
		min-height: 8.43em;
		gap: 0.63em;
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: auto;
	}
`
CategoryWrap.displayName = 'CategoryWrap'

export const CategoryImg = styled.div`
	width: 4em;
	height: 4em;

	> svg, img {
		width: 100%;
		height: 100%;
		fill: ${props => props.themeStyles.category.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 3.1em;
		height: 3.13em;
	}
`
CategoryImg.displayName = 'CategoryImg'

export const CategoryTitle = styled.div`
	color: ${props => props.themeStyles.category.color};
	user-select: none;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	font-size: 1.38em;
	word-wrap: anywhere;
	text-align: center;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.06em;
	}
`
CategoryTitle.displayName = 'CategoryTitle'
