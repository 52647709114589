import { jwtGetGroup, jwtGetRoles, uuidGenerator } from './helper'
import axios from 'axios'

const mezmoClientLogger = (data, method) => {
	const meta = {
		companyIndex: localStorage.companyIndex,
		login: localStorage.login,
		group: localStorage.accessToken ? jwtGetGroup(localStorage.accessToken) : null,
		roles: localStorage.accessToken ? jwtGetRoles(localStorage.accessToken) : null,
		deviceId: uuidGenerator(),
		deviceType: navigator.userAgent,
		deviceSize: { width: window.screen.availWidth, height: window.screen.availHeight },
		env: window.location.host === 'app.liefersoft.de' ? 'live' : 'dev',
		version: window.REACT_APP_VERSION,
		versionDate: window.REACT_APP_VERSION_DATE,
	}
	const dataJoin = Array.isArray(data) ? data?.join?.(' ') : data
	switch (method) {
		case 'info':
			logger.info(dataJoin, meta)
			break
		case 'warn':
			logger.warn(dataJoin, meta)
			break
		case 'error':
			logger.error(dataJoin, meta)
			break
		default:
			logger.info(dataJoin, meta)
			break
	}
}

const logger = {
	info: (data, meta) => {
		log(data, meta, 'INFO')
	},
	warn: (data, meta) => {
		log(data, meta, 'WARN')
	},
	error: (data, meta) => {
		log(data, meta, 'ERROR')
	},
}

const log = (data, meta, level) => {
	const apiKey = 'b73b589f7ac94450a14f8c4ec08de475'
	const now = new Date().getTime()
	const env = window.location.host === 'app.liefersoft.de' ? 'live' : 'dev'
	return axios.post(`https://logs.mezmo.com/logs/ingest?hostname=${env}&mac=&ip=&tags=&now=${now}`, {
		e: 'ls',
		ls: [{
			app: 'liefersoft',
			level,
			line: data,
			meta,
			timestamp: now,
		}],
	}, {
		auth: { username: apiKey, password: '' },
	})
}

export default mezmoClientLogger
