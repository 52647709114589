import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const WebShopSettings = lazyWithRetry(() => import(/* webpackChunkName: "webshop-settings" */'../../component/Settings/WebShopSettings'))

const WebShopSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<WebShopSettings />
		</Suspense>
	)
})

WebShopSettingsPage.displayName = 'WebShopSettingsPage'
export default WebShopSettingsPage
