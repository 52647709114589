import styled from 'styled-components'
import colors from '../../../../../style/colors'
import { theme } from '../../../../../style/theme'

export const CouponModalWrapper = styled.div`
  font-size: 1em;
	display: grid;
	padding-bottom: 1em;
	gap: 1.88em;

	input {
		text-align: center !important;
	}
`
CouponModalWrapper.displayName = 'CouponModalWrapper'

export const ErrorText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	font-size: 1.25em;
	line-height: 1.15em;
	text-align: center;
	color: ${colors.red};
	margin-top: -0.9em;
`
ErrorText.displayName = 'ErrorText'
