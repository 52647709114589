import styled from 'styled-components'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { theme } from '../../../../style/theme'

export const SelectButton = styled.div`
	position: relative;
	cursor: pointer;
	user-select: none;
	height: 2.69em;

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: .63em;
	}
`
SelectButton.displayName = 'SelectButton'

export const SelectButtonContent = styled.div`
	overflow: hidden;
	display: flex;
	height: 100%;
	align-items: center;
	border-radius: .75em;
	background: ${props => props.color || colorTheme.cart.dishCourse.selectButton.background};

	@media screen and (max-width: ${theme.point720}) {
		border: 1px solid ${colorTheme.cart.dishCourse.selectButton.border};
	}
`
SelectButtonContent.displayName = 'SelectButtonContent'

export const SelectButtonValueWrap = styled.div`
	display: flex;
	border: 1px solid ${colorTheme.cart.dishCourse.selectButton.border};
	height: 100%;
	align-items: center;
	width: 100%;
	padding: .13em .63em;
	gap: .56em;
	border-radius: .75em 0 0 .75em;

	@media screen and (max-width: ${theme.point720}) {
		border: none;
		border-radius: 0;
		justify-content: center;
	}
`
SelectButtonValueWrap.displayName = 'SelectButtonValueWrap'

export const SelectButtonIconWrap = styled.div`
	display: flex;

	> svg {
		width: 1.06em;
		height: 1em;
		fill: ${colorTheme.cart.dishCourse.selectButton.icon};
	}

	&.dark {
		> svg {
			fill: ${colorTheme.cart.dishCourse.selectButton.iconDark};
		}
	}
`

export const SelectButtonText = styled.div`
	font-size: 1.06em;
	font-family: ${theme.fontRobotoRegular};
	color: ${colorTheme.cart.dishCourse.selectButton.text};

	&.dark {
		color: ${colorTheme.cart.dishCourse.selectButton.textDark};
	}
`
SelectButtonText.displayName = 'SelectButtonText'

export const SelectButtonToggle = styled.div`
	background: ${colorTheme.cart.dishCourse.selectButton.toggle};
	padding: .63em;
	height: 100%;
	display: flex;
	align-items: center;

	> svg {
		width: .94em;
		height: .75em;
		fill: ${colorTheme.cart.dishCourse.selectButton.icon};
	}

	&.up {
		> svg {
			transform: rotate(180deg);
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		display: none;
	}
`
SelectButtonToggle.displayName = 'SelectButtonToggle'
export const SelectOptionsWrap = styled.div`
	width: 24.8em;
	position: absolute;
	top: 3em;
	left: -1px;
	border: 1px solid ${colorTheme.cart.dishCourse.selectButton.options.border};
	border-radius: 0.75em;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	transition: all 1s ease-in-out;
	overflow: hidden;
	z-index: 10;

	@media screen and (max-width: ${theme.point720}) {
		width: 100%;
	}
`
SelectOptionsWrap.displayName = 'SelectOptionsWrap'

export const SelectOptions = styled.div`
	width: 100%;
	max-height: 17.56em;
	overflow-y: auto;
	background-color: ${colorTheme.partnerPortal.selectInput.options.background};

	::-webkit-scrollbar {
		display: none;
	}
`
SelectOptions.displayName = 'SelectOptions'

export const SelectOption = styled.div`
	height: 3.43em;
	padding: 0.1em 1.19em;
	font-family: ${theme.fontRobotoMedium};
	display: flex;
	align-items: center;
	border-bottom: 1px dashed ${colorTheme.cart.dishCourse.selectButton.options.borderBottom};
	background-color: ${props => props.color || colorTheme.cart.dishCourse.selectButton.options.backgroundDefault};

	&:last-child {
		border-bottom: none;
	}

`
SelectOption.displayName = 'SelectOption'

export const SelectOptionText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	display: flex;
	align-items: center;
	gap: 0.31em;
	font-size: 1.12em;
	color: ${colorTheme.cart.dishCourse.selectButton.options.text};

`
SelectOptionText.displayName = 'SelectOptionText'
