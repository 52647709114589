import { setCustomerCalls, setCustomerIncomingCall } from '../../redux/action/customer'
import { setAppModal } from '../../redux/action/system'
import { selectHasAnyGroup } from '../../redux/selector/system'

const groupsForAcceptCall = ['OWNER', 'SUPERADMIN', 'ADMIN', 'CALL_AGENT']

export const processCustomerMessageThunk = (message) => (dispatch, getState) => {
	switch (message?.type) {
		case 'customer.INCOMING_PHONE_LIST_UPDATED_EVENT':
			if (!message?.data?.content?.length) return
			const hasRoleAcceptCallOrder = selectHasAnyGroup(groupsForAcceptCall)(getState())
			if (hasRoleAcceptCallOrder) {
				dispatch(setCustomerIncomingCall(message.data.content[0]))
				dispatch(setCustomerCalls(message.data.content))
				dispatch(setAppModal({ incomeModalShow: true }))
			}
			break
		default:
			// eslint-disable-next-line
			console.error('Unknown customer message type: ', JSON.stringify(message))
			break
	}
}
