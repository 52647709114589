import { notifyToast, retrieveErrorFromApi } from '../../../../helper'
import { setAppErrors } from '../../../action/system'
import getAutocomplete from '../../../axios/_vendor/here-rest/autoComplete/autocomplete'
import getGeoCode from '../../../axios/_vendor/here-rest/geoCode/geoCode'

export const autocompleteHereThunkSilent = (countryCode, centerPoint, query, autocomplete, searchCallback = () => {
}, spinnerCallback = () => {
}) => {

	if (typeof spinnerCallback === 'function') spinnerCallback(true)

	return (dispatch, getState) => {
		const here_api_key = window.REACT_APP_HERE_MAP_API_KEY ?? 'oL_wpFfBK8oFk74Z6-tiONi0RKl2VBtedZpTsk29vRI' // TODO remove if global variable presented

		if (!here_api_key) {
			notifyToast({ message: 'Oops Api key is missed' }, 'error')
			return
		}

		dispatch(getAutocomplete(countryCode, centerPoint, query, here_api_key,
			res => {
				if (res.data.items) typeof searchCallback === 'function' && searchCallback(res.data.items)
				if (typeof spinnerCallback === 'function') spinnerCallback(false)
			},
			error => {
				dispatch(setAppErrors('Here autosuggest error'))
				if (typeof spinnerCallback === 'function') spinnerCallback(false)
			}))
	}
}

export const geoDataHereThunk =  (countryCode, centerPoint, query) => async (dispatch) => {
	const center = centerPoint ? centerPoint : '52.40715,9.70718'

	const here_api_key = window.REACT_APP_HERE_MAP_API_KEY ?? 'oL_wpFfBK8oFk74Z6-tiONi0RKl2VBtedZpTsk29vRI' // TODO remove if global variable presented

	if (!here_api_key) {
		notifyToast({ message: 'Oops Api key is missed' }, 'error')
		return
	}
	try {
		const res = await getGeoCode(countryCode, center, query, here_api_key)
		return res.data
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

