import { APPEND_TERMINALS, SET_PAYMENT_ACTIVE_TAB, SET_PAYMENT_CONFIGS, SET_PAYMENT_INVOICE_CONFIGS, SET_PAYMENT_IS_LOADING, SET_PAYMENT_PAYPAL_ACCOUNT, SET_PAYMENT_PAYPAL_ACCOUNT_NOT_FOUND, SET_PAYMENT_PAYPAL_IS_LOADING, SET_PAYMENT_STRIPE_ACCOUNT, SET_PAYMENT_STRIPE_ACCOUNT_NOT_FOUND, SET_PAYMENT_STRIPE_IS_LOADING, SET_PAYMENT_TEST_STATUS, SET_PAYPAL_NOTIFICATION, SET_STRIPE_NOTIFICATION, SET_TERMINAL, SET_TERMINAL_ERROR_IS_INVALID, SET_TERMINAL_ID, SET_TERMINALS, SET_TERMINALS_LENGTH } from '../../actionTypes/payment'
import { PAYMENTS } from './constants'

const initialState = {
	paymentConfigs: [],
	isLoading: true,
	paymentTestStatus: null,
	stripeAccount: null,
	stripeAccountNotFound: false,
	stripeIsLoading: false,
	activeTab: PAYMENTS,
	terminals: [],
	terminalsLength: 0,
	terminal: null,
	terminalId: null,
	terminalErrorIsInvalid: false,
	paypalAccount: null,
	paypalAccountNotFound: false,
	paypalIsLoading: false,
	paypalNotification: null,
	stripeNotification: null,
	invoiceConfigs: null,
}

export function paymentReducer(state = initialState, action) {
	switch (action.type) {
		case SET_PAYMENT_CONFIGS:
			return {
				...state,
				paymentConfigs: action.value,
			}
		case SET_PAYMENT_IS_LOADING:
			return {
				...state,
				isLoading: action.value,
			}
		case SET_PAYMENT_TEST_STATUS:
			return {
				...state,
				paymentTestStatus: action.value,
			}
		case SET_PAYMENT_STRIPE_ACCOUNT:
			return {
				...state,
				stripeAccount: action.value,
			}
		case SET_PAYMENT_STRIPE_ACCOUNT_NOT_FOUND:
			return {
				...state,
				stripeAccountNotFound: action.value,
			}
		case SET_PAYMENT_STRIPE_IS_LOADING:
			return {
				...state,
				stripeIsLoading: action.value,
			}
		case SET_PAYMENT_ACTIVE_TAB:
			return {
				...state,
				activeTab: action.value,
			}
		case SET_TERMINALS:
			return {
				...state,
				terminals: action.value,
			}
		case APPEND_TERMINALS:
			return {
				...state,
				terminals: state.terminals.concat(...action.value),
			}
		case SET_TERMINALS_LENGTH:
			return {
				...state,
				terminalsLength: action.value,
			}
		case SET_TERMINAL:
			return {
				...state,
				terminal: action.value,
			}
		case SET_TERMINAL_ID:
			return {
				...state,
				terminalId: action.value,
			}
		case SET_TERMINAL_ERROR_IS_INVALID:
			return {
				...state,
				terminalErrorIsInvalid: action.value,
			}
		case SET_PAYMENT_PAYPAL_ACCOUNT:
			return {
				...state,
				paypalAccount: action.value,
			}
		case SET_PAYMENT_PAYPAL_ACCOUNT_NOT_FOUND:
			return {
				...state,
				paypalAccountNotFound: action.value,
			}
		case SET_PAYMENT_PAYPAL_IS_LOADING:
			return {
				...state,
				paypalIsLoading: action.value,
			}
		case SET_PAYPAL_NOTIFICATION:
			return {
				...state,
				paypalNotification: action.value,
			}
		case SET_STRIPE_NOTIFICATION:
			return {
				...state,
				stripeNotification: action.value,
			}
		case SET_PAYMENT_INVOICE_CONFIGS:
			return {
				...state,
				invoiceConfigs: action.value,
			}
		default:
			return state
	}
}
