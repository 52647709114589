import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CustomInfoModal from '../../../../Elements/modal/CustomInfoModal'
import Button from '../../../../Elements/buttons/Button'
import { setRestaurantShowProcessPaymentErrorModal } from '../../../../../redux/action/restaurant'

const EVENT_Z_INDEX = 88

const ModalProcessPaymentError = React.memo(() => {
	const { t } = useTranslation()
	const showProcessPaymentErrorModal = useSelector(state => state.restaurant.showProcessPaymentErrorModal)
	const dispatch = useDispatch()

	const handleClose = useCallback(() => {
		dispatch(setRestaurantShowProcessPaymentErrorModal(false))
	}, [dispatch])

	return (
		<CustomInfoModal isOpen={showProcessPaymentErrorModal}
										 title={t('app.modals.Attention')}
										 close={handleClose}
										 zIndex={EVENT_Z_INDEX}
										 text={t('restaurant.tables.modal.process_payment_error')}
										 button={
											 <Button name="ok" icon="checkmark" text={t('buttons.controls.ok')}
															 keyButton="F2" zIndex={EVENT_Z_INDEX} color="green"
															 onClick={handleClose} />} />
	)
})

ModalProcessPaymentError.displayName = 'ProcessPaymentErrorModal'
export default ModalProcessPaymentError
