import { notifyToast, retrieveErrorFromApi } from '../../../helper'
import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import getExpertCartSettings from '../../axios/registration/expertCartSettings'
import { setRestaurantSettingsCartSettings, setRestaurantSettingsChangeSettings, setRestaurantSettingsDishCourses, setRestaurantSettingsPresetDiscounts, setRestaurantSettingsPrintSettings, setRestaurantSettingsWaiterSettings, updateRestaurantSettingsCartSetting, updateRestaurantSettingsChangeSetting } from '../../action/restaurantSettings'
import updateExpertCartSettings from '../../axios/registration/expertCartSettings/put'
import getChangeSettings from '../../axios/registration/changeSettings'
import updateChangeSettings from '../../axios/registration/changeSettings/put'
import getWaiterSettings from '../../axios/order/waiterSettings/get'
import updateWaiterSettings from '../../axios/order/waiterSettings/put'
import getAutoPrintConfigs from '../../axios/printer/printerSettings'
import updateAutoPrintConfigs from '../../axios/printer/printerSettings/put'
import getPresetDiscounts from '../../axios/restaurant/presetDiscounts'
import updatePresetDiscounts from '../../axios/restaurant/presetDiscounts/put'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'
import updateDishCourses from '../../axios/restaurant/settings/put'
import fetchDishCourses from '../../axios/restaurant/settings'

export const getRestaurantSettingsCartSettingsThunk = (module) => dispatch => {
	return getExpertCartSettings(module)
		.then(res => {
			dispatch(setRestaurantSettingsCartSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateRestaurantSettingsCartSettingsThunk = (data) => dispatch => {
	return updateExpertCartSettings(data)
		.then((res) => {
			notifyToast({ message: 'Cart setting successfully updated' })
			return dispatch(updateRestaurantSettingsCartSetting(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getRestaurantSettingsChangeSettingsThunk = (module) => dispatch => {
	return getChangeSettings(module)
		.then(res => {
			dispatch(setRestaurantSettingsChangeSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateRestaurantSettingsChangeSettingsThunk = (data) => dispatch => {
	return updateChangeSettings(data)
		.then((res) => {
			notifyToast({ message: 'Change setting successfully updated' })
			return dispatch(updateRestaurantSettingsChangeSetting(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getRestaurantSettingsWaiterSettingsThunk = (module) => dispatch => {
	return getWaiterSettings(module)
		.then(res => {
			dispatch(setRestaurantSettingsWaiterSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateRestaurantSettingsWaiterSettingsThunk = (data) => dispatch => {
	return updateWaiterSettings('RESTAURANT', data)
		.then(res => {
			return dispatch(getRestaurantSettingsWaiterSettingsThunk('RESTAURANT'))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getRestaurantSettingsPrintSettingsThunk = (module) => dispatch => {
	return getAutoPrintConfigs(module)
		.then(res => {
			dispatch(setRestaurantSettingsPrintSettings(res.data?.[0]))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateRestaurantSettingsPrintSettingsThunk = (id, data, module) => dispatch => {
	return updateAutoPrintConfigs(id, data)
		.then((res) => {
			notifyToast({ message: 'Print setting successfully updated' })
			return dispatch(getRestaurantSettingsPrintSettingsThunk(module))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getRestaurantSettingsPresetDiscountsThunk = () => dispatch => {
	return getPresetDiscounts()
		.then(res => {
			dispatch(setRestaurantSettingsPresetDiscounts(res.data.discounts))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateRestaurantSettingsPresetDiscountsThunk = (presetDiscounts) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))
	return updatePresetDiscounts(presetDiscounts)
		.then((res) => {
			dispatch(succeedSystemFormButtonSaveThunk())
			return dispatch(setRestaurantSettingsPresetDiscounts(res.data.discounts))
		}).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getRestaurantSettingsDishCoursesThunk = () => dispatch => {
	return fetchDishCourses()
		.then(res => {
			dispatch(setRestaurantSettingsDishCourses(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})

}

export const updateRestaurantSettingsDishCoursesThunk = (enabled, coursesCount) => dispatch => {
	return updateDishCourses(enabled, coursesCount)
		.then((res) => {
			return dispatch(getRestaurantSettingsDishCoursesThunk())
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
