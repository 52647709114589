import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CheckBoxInputControlled from '../../../Elements/inputs/CheckboxInput/controlled'
import { WorkingHoursWrapper, DayBlock, DayTitle, MarginWrapper, CopyButton } from './index.styled'
import PreorderDelay from './PreorderDelay'
import { deleteWebshopDayWithPeriodsError, addWebshopDayWithPeriodsError } from '../../../../redux/action/webshopSchedule'
import TimePeriods from './TimePeriods'
import { isValidTime, isValidHoursOrMinutes, isValidTimePeriodsOrder, setInvalidTimePeriodsArray, handleTimeValidationOnBlur, handleHoursOrMinutesValidationOnBlur } from '../../helper'
import { ERROR_BEFORE_BREAK, ERROR_AFTER_BREAK, DEFAULT_TIME_BEFORE_BREAK } from '../../constants'

const WorkingHoursConfig = React.memo(({ isModal = false, workDays, setWorkDays, delayAfterOpen, setDelayAfterOpen, delayBeforeClose, setDelayBeforeClose, preOrderDisabled, setPreOrderDisabled }) => {
	const { t, i18n } = useTranslation()
	const daysWithPeriodsError = useSelector(state => state.webshopSchedule.daysWithPeriodsError)
	const dispatch = useDispatch()
	const layout = isModal ? 'modal ' : ''

	const daysOfWeek = useMemo(() => [
		{ title: t('WebShopWorkingHours.days.monday'), value: 'MONDAY' },
		{ title: t('WebShopWorkingHours.days.tuesday'), value: 'TUESDAY' },
		{ title: t('WebShopWorkingHours.days.wednesday'), value: 'WEDNESDAY' },
		{ title: t('WebShopWorkingHours.days.thursday'), value: 'THURSDAY' },
		{ title: t('WebShopWorkingHours.days.friday'), value: 'FRIDAY' },
		{ title: t('WebShopWorkingHours.days.saturday'), value: 'SATURDAY' },
		{ title: t('WebShopWorkingHours.days.sunday'), value: 'SUNDAY' },
	], [i18n.language])

	const checkDayHasPeriodsError = useCallback((day) => {
		if (daysWithPeriodsError?.[day]?.length) return true
		return false
	}, [daysWithPeriodsError])

	const checkCurrentPeriodHasError = useCallback((day, i) => {
		if (i === 0) return daysWithPeriodsError?.[day]?.includes(ERROR_BEFORE_BREAK)
		return daysWithPeriodsError?.[day]?.includes(ERROR_AFTER_BREAK)
	}, [daysWithPeriodsError])

	const deleteTimePeriodError = useCallback((dayToDel, periodIndex = null) => {
		if (checkDayHasPeriodsError(dayToDel)) {
			if (daysWithPeriodsError?.[dayToDel]?.length > 1) {
				if (periodIndex !== null) {
					const filteredPeriodError = daysWithPeriodsError?.[dayToDel]?.filter(item => {
						if (periodIndex === 0) return item !== ERROR_BEFORE_BREAK
						return item !== ERROR_AFTER_BREAK
					})

					dispatch(addWebshopDayWithPeriodsError({ [dayToDel]: filteredPeriodError }))
				} else {
					dispatch(deleteWebshopDayWithPeriodsError(dayToDel))
				}
			} else {
				if (periodIndex === null || checkCurrentPeriodHasError(dayToDel, periodIndex)) {
					dispatch(deleteWebshopDayWithPeriodsError(dayToDel))
				}
			}
		}
	}, [daysWithPeriodsError, checkDayHasPeriodsError, checkCurrentPeriodHasError])

	const handleTimePeriodError = useCallback((day, periods) => {
		if (isValidTimePeriodsOrder(periods, true, delayAfterOpen, delayBeforeClose)) {
			deleteTimePeriodError(day)
		} else {
			const invalidTimePeriodsArray = setInvalidTimePeriodsArray(periods, delayAfterOpen, delayBeforeClose)
			dispatch(addWebshopDayWithPeriodsError({ [day]: invalidTimePeriodsArray }))
		}
	}, [deleteTimePeriodError, delayAfterOpen, delayBeforeClose])

	const onChangeWeekend = useCallback((item, e) => {
		const changedDays = workDays?.map(day => day.day !== item.day ? day : { ...day, weekend: e.target.checked })
		setWorkDays(changedDays)
	}, [workDays])

	const onChangeBreak = useCallback((item, e) => {
		const target = e.target
		const value = target.checked
		let periods = [...item.periods]

		if (value) {
			periods = [DEFAULT_TIME_BEFORE_BREAK, periods[0]]
		} else {
			periods = [periods[1]]
		}

		const changedDays = workDays?.map(day => day.day !== item.day ? day : { ...day, lunchBreak: value, periods })
		setWorkDays(changedDays)

		handleTimePeriodError(item.day, periods)
	}, [workDays, handleTimePeriodError])

	const onChangeTime = useCallback((item, e, i) => {
		const target = e.target
		let value = target.value
		const inputName = target.name
		let periods = [...item.periods]
		const periodsLength = periods.length

		if (isValidTime(value)) {
			if (i === 0) {
				periods = periodsLength > 1 ? [{ ...periods[0], [inputName]: value }, periods[1]] : [{ ...periods[0], [inputName]: value }]
			} else {
				periods = [periods[0], { ...periods[1], [inputName]: value }]
			}
		}

		const changedDays = workDays?.map(day => day.day !== item.day ? day : { ...day, periods })
		setWorkDays(changedDays)

		deleteTimePeriodError(item.day, i)
	}, [workDays, deleteTimePeriodError])

	const onBlurTimeInput = useCallback((item, e, i) => {
		const target = e.target
		const value = target.value
		const inputName = target.name
		let periods = [...item.periods]
		const periodsLength = periods.length
		const newTime = handleTimeValidationOnBlur(value)

		if (i === 0) {
			periods = periodsLength > 1 ? [{ ...periods[0], [inputName]: newTime }, periods[1]] : [{ ...periods[0], [inputName]: newTime }]
		} else {
			periods = [periods[0], { ...periods[1], [inputName]: newTime }]
		}

		const changedDays = workDays?.map(day => day.day !== item.day ? day : { ...day, periods })
		setWorkDays(changedDays)

		handleTimePeriodError(item.day, periods)
	}, [workDays, handleTimePeriodError])

	const copyDay = useCallback((item, i) => {
		if (!checkDayHasPeriodsError(item.day)) {
			const changedDays = [...workDays.slice(0, i + 1), { ...item, day: workDays[i + 1].day }, ...workDays.slice(i + 2)]

			setWorkDays(changedDays)
		}
	}, [workDays, checkDayHasPeriodsError])

	const copyDayToAllDays = useCallback((item) => {
		if (!checkDayHasPeriodsError(item.day)) {
			const changedDays = workDays?.map(day => ({ ...item, day: day.day }))

			setWorkDays(changedDays)
		}
	}, [workDays, checkDayHasPeriodsError])

	const onChangeDelayAfterOpen = useCallback((e) => {
		const target = e.target
		const value = target.value
		const inputName = target.name

		if (isValidHoursOrMinutes(value, inputName)) {
			const copyDelayAfterOpen = { ...delayAfterOpen, [inputName]: value }
			setDelayAfterOpen(copyDelayAfterOpen)
		}
	}, [delayAfterOpen])

	const handleAllDaysPeriodError = useCallback(() => {
		workDays.forEach(item => {
			if (!item.weekend) handleTimePeriodError(item.day, item.periods)
		})
	}, [workDays, handleTimePeriodError])

	const onBlurDelayAfterOpen = useCallback((e) => {
		const target = e.target
		const value = target.value
		const inputName = target.name
		const newTime = handleHoursOrMinutesValidationOnBlur(value)

		const copyDelayAfterOpen = { ...delayAfterOpen, [inputName]: newTime }
		setDelayAfterOpen(copyDelayAfterOpen)
		handleAllDaysPeriodError()
	}, [delayAfterOpen, handleAllDaysPeriodError])

	const onChangeDelayBeforeClose = useCallback((e) => {
		const target = e.target
		const value = target.value
		const inputName = target.name

		if (isValidHoursOrMinutes(value, inputName)) {
			const copyDelayBeforeClose = { ...delayBeforeClose, [inputName]: value }
			setDelayBeforeClose(copyDelayBeforeClose)
		}
	}, [delayBeforeClose])

	const onBlurDelayBeforeClose = useCallback((e) => {
		const target = e.target
		const value = target.value
		const inputName = target.name
		const newTime = handleHoursOrMinutesValidationOnBlur(value)

		const copyDelayBeforeClose = { ...delayBeforeClose, [inputName]: newTime }
		setDelayBeforeClose(copyDelayBeforeClose)
		handleAllDaysPeriodError()
	}, [delayBeforeClose, handleAllDaysPeriodError])

	const onChangePreOrderDisabled = useCallback((name) => {
		if (name === 'workingHours') {
			const newPreOrderDisabled = {...preOrderDisabled, workingHours: !preOrderDisabled?.workingHours }
			setPreOrderDisabled(newPreOrderDisabled)
		}
		else if (name === 'nonWorkingHours') {
			const newPreOrderDisabled = {...preOrderDisabled, nonWorkingHours: !preOrderDisabled?.nonWorkingHours }
			setPreOrderDisabled(newPreOrderDisabled)
		}
	}, [preOrderDisabled])


	return (
		<WorkingHoursWrapper className={layout}>

			{workDays?.map((day, index) =>
				<DayBlock key={index} id={day.day} className={layout + (index === workDays.length - 1 ? 'border-bold' : '')}>
					<DayTitle>{daysOfWeek.find(item => item.value === day.day)?.title}</DayTitle>
					<CheckBoxInputControlled label={t('WebShopWorkingHours.weekend')}
						id={day.day + '-weekend'}
						name={day.day + '-weekend'}
						testId={day.day + '-weekend'}
						checked={day.weekend}
						maxContent={true}
						onChange={(e) => onChangeWeekend(day, e)}
						disabled={checkDayHasPeriodsError(day.day)} />
					<CheckBoxInputControlled label={t('WebShopWorkingHours.break')}
						id={day.day + '-lunchBreak'}
						name={day.day + '-lunchBreak'}
						testId={day.day + '-lunchBreak'}
						checked={day.lunchBreak}
						maxContent={true}
						onChange={(e) => onChangeBreak(day, e)}
						disabled={day.weekend} />

					{day.periods?.map((period, i, array) =>
						<TimePeriods key={i} {...{
							period, i, array,
							firstInputName: 'openingTime', secondInputName: 'closingTime',
							firstInputId: day.day + '-openingTime-' + i, secondInputId: day.day + '-closingTime-' + i,
							error: checkCurrentPeriodHasError(day.day, i), disabled: day.weekend,
							onChangeTime: (e) => onChangeTime(day, e, i),
							onBlurTime: (e) => onBlurTimeInput(day, e, i)
						}} />
					)}
					{day.day !== 'SUNDAY' &&
						<MarginWrapper>
							<CopyButton onClick={() => copyDay(day, index)} color="#777E89">
								{t('WebShopWorkingHours.copyNextDay')}
							</CopyButton>
						</MarginWrapper>}
					{day.day === 'MONDAY' &&
						<CopyButton onClick={() => copyDayToAllDays(day)}>
							{t('WebShopWorkingHours.copyAllDay')}
						</CopyButton>}
				</DayBlock>
			)}

			<PreorderDelay {...{ isModal, delayAfterOpen, delayBeforeClose, onChangeDelayAfterOpen, onBlurDelayAfterOpen, onChangeDelayBeforeClose, onBlurDelayBeforeClose, onChangePreOrderDisabled, preOrderDisabled }} />
		</WorkingHoursWrapper>
	)
})

WorkingHoursConfig.displayName = 'WorkingHoursConfig'
export default WorkingHoursConfig
