export const filterError = (error) => {
  return !(excludedRequests.find(request =>
    request.url.test(error?.response?.config?.url)
    && request.status === error?.response?.status))
}

const excludedRequests = [
  { url: /^\/auth\/refresh/, status: 400 },
  { url: /^\/geomap/, status: 403 },
  { url: /^\/auth\/less/, status: 401 },
  { url: /^\/orders\/.*\/process/, status: 403 },
  { url: /^\/orders\/.*\/status/, status: 400 },
  { url: /^\/users/, status: 400 },
  { url: /^\/auth\/authenticated/, status: 401 },
  { url: /^\/cash-book\/.*\/print/, status: 404 },
]

