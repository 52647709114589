import { uuidGenerator } from '../../../../../../helper'

export const convert2MoneyExchange = (totalSum, t, currencySymbol) => {
	if (totalSum) {
		return {
			itemId: uuidGenerator(),
			type: 'CASH_EXCHANGE',
			quantity: 1,
			name: t('Cart.MoneyExchange.nameItem'),
			price: totalSum,
			remark: `${t('Cart.MoneyExchange.remarkItem')} - ${totalSum} ${currencySymbol}`,
			disabledDiscount: true // so that the discount is not taken for this item in the Cart
		}
	} else {
		return null
	}
}
