import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { KitchenStatusLegendStatus, KitchenStatusLegendStatuses, KitchenStatusLegendStatusesWrap, KitchenStatusLegendStatusText, KitchenStatusLegendText, KitchenStatusLegendWrap } from './index.styled'
import KitchenStatusCircle from '../KitchenStatusCircle'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'

const KitchenStatusLegend = React.memo(() => {
	const { t } = useTranslation()
	const isTablet = useWindowBreakPoint(theme.point820)

	const kitchenStatuses = useMemo(() => [
		{ text: t('restaurant.sendToKitchenModal.header.statuses.notSendToKitchen'), status: 'PLACED' },
		{ text: t('restaurant.sendToKitchenModal.header.statuses.inPreparation'), status: 'BEING_PREPARED' },
		{ text: t('restaurant.sendToKitchenModal.header.statuses.prepared'), status: 'PREPARED' },
		{ text: t('restaurant.sendToKitchenModal.header.statuses.canceled'), status: 'CANCELED' },
	], [t])

	return (
		<KitchenStatusLegendWrap data-testid="send-to-kitchen-modal-legend">
			{!isTablet &&
				<>
					<KitchenStatusLegendText>
						{t('restaurant.sendToKitchenModal.header.allProducts')}
					</KitchenStatusLegendText>
					<div />
				</>
			}
			<KitchenStatusLegendStatusesWrap>
				<KitchenStatusLegendText>
					{t('restaurant.sendToKitchenModal.header.sendToKitchenProducts')}
				</KitchenStatusLegendText>
				<KitchenStatusLegendStatuses>
					{kitchenStatuses.map((status, i) =>
						<KitchenStatusLegendStatus key={i}>
							<KitchenStatusCircle status={status.status} />
							<KitchenStatusLegendStatusText>
								{status.text}
							</KitchenStatusLegendStatusText>
						</KitchenStatusLegendStatus>,
					)}
				</KitchenStatusLegendStatuses>
			</KitchenStatusLegendStatusesWrap>
		</KitchenStatusLegendWrap>
	)
})

KitchenStatusLegend.displayName = 'KitchenStatusLegend'
export default KitchenStatusLegend
