import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getNeededDateFormat } from '../../../../helper'
import MainIcon from '../../../../img/svg/main'
import { EmptyPhoneTableText, PhoneTableAddressTd, PhoneTableRow, PhoneTableTelTd, PhoneTableTelText, PhoneTableTimeTd, PhoneTableWrapper } from './index.styled'

const IncomePhoneTable = React.memo(({ activeRowIndex, onRowClick, onRowDoubleClick }) => {
	const { t } = useTranslation()
	const customerCalls = useSelector(state => state.customerStore.calls)

	return (
		<PhoneTableWrapper id="incoming-phone-calls">
			{customerCalls.length > 0 ?
				customerCalls.map((call, i) =>
					<PhoneTableRow key={i}
												 className={activeRowIndex === i ? 'active' : ''}
												 data-testid={`income-phone-row-${i}`}
												 onClick={() => onRowClick(i)}
												 onDoubleClick={() => onRowDoubleClick(call)}>
						<PhoneTableTelTd>
							<MainIcon icon="smartphone" />
							<PhoneTableTelText data-testid={`income-phone-tel-${i}`}>
								{call.customerPhone === 'anonymous' ? t('Modal.incoming_call.anonymous') : call.customerPhone}
							</PhoneTableTelText>
						</PhoneTableTelTd>
						<PhoneTableTimeTd>{getNeededDateFormat(call.date, 'HH:mm')}</PhoneTableTimeTd>
						{call.customer &&
							<PhoneTableAddressTd>
								<>{call.customer?.name}, {call.customer?.street} {call.customer?.streetNumber}</>
							</PhoneTableAddressTd>}
					</PhoneTableRow>) :
				<PhoneTableRow className="empty">
					<EmptyPhoneTableText>{t('Modal.incoming_call.no_calls')}</EmptyPhoneTableText>
				</PhoneTableRow>}
		</PhoneTableWrapper>
	)
})

IncomePhoneTable.displayName = 'IncomePhoneTable'
export default IncomePhoneTable

