import axios from 'axios'
import { paymentServiceUrl } from '../../../../urls'
import modalLoginRequestInterceptor from '../../modalLoginRequestInterceptor'
import modalLoginResponseInterceptor from '../../modalLoginResponseInterceptor'

const headers = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest',
}

const paymentModalLoginInstance = axios.create({
	baseURL: paymentServiceUrl,
	headers,
})

paymentModalLoginInstance.interceptors.request.use(config => {
	return modalLoginRequestInterceptor(config)
}, error => Promise.reject(error))

paymentModalLoginInstance.interceptors.response.use(response => response, error => {
	return modalLoginResponseInterceptor(error)
})

export default paymentModalLoginInstance
