import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import { addWebshopSpecialDays, removeWebshopSpecialDay, setWebshopPreorderDelay, setWebshopSpecialDays, setWebshopSpecialDaysTotalLength, setWebshopWorkingHoursConfig } from '../../action/webshopSchedule'
import getShopSpecialDays from '../../axios/shop/specialDays/get'
import updateShopSpecialDays from '../../axios/shop/specialDays/put'
import deleteShopSpecialDay from '../../axios/shop/specialDays/delete'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'
import updateShopWorkSchedule from '../../axios/shop/workSchedule/put'
import getShopWorkSchedule from '../../axios/shop/workSchedule/get'

export const getShopSpecialDaysThunk = (page, size) => dispatch => {
	return getShopSpecialDays(page, size)
		.then((res) => {
			if (res && res.data) {
				if (res.headers['x-special-days-total']) {
					dispatch(setWebshopSpecialDaysTotalLength(parseInt(res.headers['x-special-days-total'])))
				}

				if (page === 0) {
					dispatch(setWebshopSpecialDays(res.data))
				} else {
					dispatch(addWebshopSpecialDays(res.data))
				}
			}
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateShopSpecialDaysThunk = (data, page, size, callback) => (dispatch) => {
	return updateShopSpecialDays(data)
		.then(() => {
			dispatch(getShopSpecialDaysThunk(page, size))

			if (typeof callback === 'function') {
				callback()
			}
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteShopSpecialDayThunk = (dayId) => dispatch => {
	return deleteShopSpecialDay(dayId)
		.then(() => {
			dispatch(removeWebshopSpecialDay(dayId))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateShopWorkScheduleThunk = (daysOfWeek,	orderingSetting) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

	return updateShopWorkSchedule(daysOfWeek,	orderingSetting)
		.then(() => {
			dispatch(succeedSystemFormButtonSaveThunk())
			return dispatch(getShopWorkScheduleThunk())
		}).catch((error) => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getShopWorkScheduleThunk = () => dispatch => {
	return getShopWorkSchedule()
		.then((res) => {
			dispatch(setWebshopWorkingHoursConfig(res.data.daysOfWeek))
			dispatch(setWebshopPreorderDelay(res.data.orderingSetting))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
