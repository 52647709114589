import React from 'react'
import { TableTrStyled } from './index.styled'

const TableTr = React.memo(({ isActive, onClick, onDoubleClick, name, children, size, disabled, disabledStatus, strikethroughFont, className, dataCypressInfo }) => {
	let layout = (isActive ? 'active ' : '') + size + (disabled ? ' disabled' : '')
		+ (disabledStatus ? ' disabledStatus' : '') + (strikethroughFont ? ' strikethroughFont' : '') + (className ? ` ${className}` : '')

	return (
		<TableTrStyled onClick={onClick}
									 onDoubleClick={onDoubleClick}
									 className={layout}
									 name={name}
									 data-ispaidorder={dataCypressInfo}>
			{children}
		</TableTrStyled>
	)
})

TableTr.displayName = 'TableTr'
export default TableTr
