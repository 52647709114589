import styled from 'styled-components'
import { colorTheme } from '../../../style/theme/colorTheme'
import { theme } from '../../../style/theme'

export const FinalStepWrapper = styled.div`
	font-size: 1rem;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colorTheme.clientMonitorCart.finalStep.background};
`
FinalStepWrapper.displayName = 'FinalStepWrapper'

export const FinalStepTitle = styled.div`
	color: ${colorTheme.clientMonitorCart.finalStep.title};
	font-family: ${theme.fontRobotoMedium};
	font-size: 6em;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
`
FinalStepTitle.displayName = 'FinalStepTitle'
