import { ADD_Z_REPORT, APPEND_Z_REPORTS, APPEND_Z_REPORT_FOR_TODAY, SET_Z_REPORT, SET_Z_REPORT_DATE_FROM, SET_Z_REPORT_DATE_TO, SET_Z_REPORT_TEST_IS_LOADING, SET_Z_REPORTS, SET_Z_REPORTS_FOR_TODAY, SET_Z_REPORTS_TOTAL_LENGTH, SET_Z_REPORT_CASH_DATA, SET_Z_REPORT_DATEV } from '../../actionTypes/zreport'

export const setZReports = value => ({ type: SET_Z_REPORTS, value })

export const setZReportsTotalLength = value => ({ type: SET_Z_REPORTS_TOTAL_LENGTH, value })

export const setZReport = value => ({ type: SET_Z_REPORT, value })

export const appendZReports = value => ({ type: APPEND_Z_REPORTS, value })

export const setZReportDateFrom = value => ({ type: SET_Z_REPORT_DATE_FROM, value })

export const setZReportDateTo = value => ({ type: SET_Z_REPORT_DATE_TO, value })

export const setZReportTestIsLoading = value => ({ type: SET_Z_REPORT_TEST_IS_LOADING, value })

export const appendZReportsForToday = value => ({ type: APPEND_Z_REPORT_FOR_TODAY, value })

export const setZReportsForToday = value => ({ type: SET_Z_REPORTS_FOR_TODAY, value })

export const addZReport = value => ({ type: ADD_Z_REPORT, value })

export const setZReportCashData = value => ({ type: SET_Z_REPORT_CASH_DATA, value })

export const setZReportDateV = value => ({ type: SET_Z_REPORT_DATEV, value })
