import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { deliveryDistanceCustomer, distanceCustomer, searchCustomersByCompanyNameThunk, searchCustomersByNameThunk, searchCustomersByNumberThunk, searchCustomersByPhoneThunk, searchResetThunk } from '../../../redux/thunk/customer'
import { BlockShowForm, CustomerAddressWrapper, CustomerFormWrapper, CustomerMainForm, CustomerNameContainer, CustomerNameWrapper, FormLine } from './index.styled'
import { setAppEventZIndex } from '../../../redux/action/system'
import { capitalize, formatAddress } from '../../../helper'
import TextInput from '../../Elements/inputs/TextInput'
import { setAutocompleteGeo, setCustomer, setCustomerAddressIsNotFull, setCustomerGeoData, setCustomerGeoDataLoading, setCustomersByCompanyName, setCustomersByName, setCustomersByNumber, setCustomersByPhone } from '../../../redux/action/customer'
import { setDeliveryCartCurrentDeliveryCost, setDeliveryCartDefaultDeliveryCost, setDeliveryCartFreeFrom, setDeliveryCartItemFocusIndex, setDeliveryCartItems, setDeliveryCartMaxDistance, setDeliveryCartMinimumPrice, setDeliveryCartOrderType, setShowDeliveryConfigError } from '../../../redux/action/deliveryCart'
import { getDeliveryConfigThunk } from '../../../redux/thunk/deliveryConfig'
import AutocompleteInput from '../../Elements/inputs/AutocompleteInput'
import Button from '../../Elements/buttons/Button'
import { CompanyNameAutocompleteItem, NameAutocompleteItem, NumberAutocompleteItem, PhoneAutocompleteItem } from './autocomplete/items'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { FormButtons } from '../../Elements/form/index.styled'
import CheckBoxInputControlled from '../../Elements/inputs/CheckboxInput/controlled'
import AddressSearchInputs from '../../Elements/inputs/AddressSearchInputs'
import { filterSearchString } from '../../../helper/filterSearchString'
import { selectCompanyData } from '../../../redux/selector/system'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import CheckBoxInputControlledGray from '../../Elements/inputs/CheckBoxInputGray/controlled'

const EVENT_Z_INDEX = 0
const PHONE_EVENT_Z_INDEX = 9
const NUMBER_EVENT_Z_INDEX = 10
const NAME_EVENT_Z_INDEX = 11
const COMPANY_NAME_EVENT_Z_INDEX = 88

const CustomerForm = React.memo(({ onSubmit, handleSubmit, register, errors, handleReset, setValue, watch, setFocus, formRef, clearErrors }) => {
	const customer = useSelector(store => store.customerStore.customer)
	const deliveryCost = useSelector(store => store.customerStore.deliveryCost)
	const geoData = useSelector(store => store.customerStore.geoData)
	const customersByPhone = useSelector(store => store.customerStore.customersByPhone)
	const customersByNumber = useSelector(store => store.customerStore.customersByNumber)
	const customersByName = useSelector(store => store.customerStore.customersByName)
	const customersByCompanyName = useSelector(store => store.customerStore.customersByCompanyName)
	const geoDataLoading = useSelector(store => store.customerStore.geoDataLoading)
	const customerAddressIsNotFull = useSelector(store => store.customerStore.customerAddressIsNotFull)
	const [alwaysShowForm, setAlwaysShowForm] = useLocalStorage('showForm', true)
	const [showForm, setShowForm] = useState(true)
	const [isFirma, setIsFirma] = useState(false)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isTablet = useWindowBreakPoint(theme.point820)
	const companyContryCode = useSelector(selectCompanyData('countryCode'))
	const watchAddressHouse = watch('customerAddress.house')
	const watchAddressStreet = watch('customerAddress.street')
	const watchAddressCity = watch('customerAddress.city')
	const watchAddressZipCode = watch('customerAddress.zipCode')
	const watchDeliveryCost = watch('deliveryCost')
	const watchPhone = watch('phoneNumber')
	const isValidAddress = watchAddressStreet && watchAddressHouse && watchAddressCity && watchAddressZipCode
	const orderType = useSelector(store => store.deliveryCart.orderType)
	const validationIsRequired =  !['PICKUP', 'COUNTER'].includes(orderType)

	const customerDistance = (geoData?.distance || 0) / 1000.0
	const customerLat = geoData?.lat
	const customerLon = geoData?.lon

	useEffect(() => {
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		dispatch(setDeliveryCartOrderType(null))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setShowForm(alwaysShowForm)
	}, [alwaysShowForm])

	useEffect(() => {
		if (deliveryCost) setValue('deliveryCost', deliveryCost)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deliveryCost])

	const setDeliveryCost = useCallback((deliveryConfig) => {
		if (!deliveryCost && deliveryConfig.deliveryCost) {
			setValue('deliveryCost', deliveryConfig.deliveryCost)
		}
	}, [deliveryCost, setValue])

	useEffect(() => {
		if (customerDistance >= 0 && customerLat && customerLon) {
			dispatch(getDeliveryConfigThunk(customerDistance, customerLat, customerLon, setDeliveryCost))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerDistance, customerLat, customerLon])

	useEffect(() => {
		if (geoData) {
			setValue('customerAddress.latitude', geoData.lat)
			setValue('customerAddress.longitude', geoData.lon)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoData])

	useEffect(() => {
		if (isValidAddress && !customerAddressIsNotFull) {
			dispatch(setCustomerGeoData(null)) // should reset geoData, cause of coordinates change, so it contains deprecated values
			dispatch(setCustomerGeoDataLoading(true))
			const timeOutId = setTimeout(() => {
				dispatch(deliveryDistanceCustomer({
					street: watchAddressStreet,
					streetNumber: watchAddressHouse,
					city: watchAddressCity,
					postalCode: watchAddressZipCode,
				}))
			}, 300)
			return () => clearTimeout(timeOutId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchAddressHouse, customerAddressIsNotFull])

	const onAutoCompletePhone = useCallback(value => {
		if (value) {
			dispatch(searchCustomersByPhoneThunk(value))
		} else {
			dispatch(setCustomersByPhone(null))
		}
	}, [dispatch])

	const filterPhone = useCallback(v => v.replace(/\D/g, '').slice(0, 20), [])

	const setItemsByPhone = useCallback(value => {
		dispatch(setCustomersByPhone(value))
	}, [dispatch])

	const onAutoCompleteCustomerNumber = useCallback(value => {
		if (value) {
			dispatch(searchCustomersByNumberThunk(value))
		} else {
			dispatch(setCustomersByNumber(null))
		}
	}, [dispatch])

	const filterNumber = useCallback(v => v.replace(/\D/g, '').slice(0, 19), [])

	const setItemsByNumber = useCallback(value => {
		dispatch(setCustomersByNumber(value))
	}, [dispatch])

	const onAutoCompleteCustomerName = useCallback(value => {
		if (value && !watchPhone) {
			dispatch(searchCustomersByNameThunk(value))
		} else {
			dispatch(setCustomersByName(null))
		}
	}, [watchPhone, dispatch])

	const filterName = useCallback(v => {
		const replaceForbiddenSymbol = filterSearchString(v)
		return capitalize(replaceForbiddenSymbol)
	}, [])

	const setItemsByName = useCallback(value => {
		dispatch(setCustomersByName(value))
	}, [dispatch])

	const onAutoCompleteCompanyName = useCallback(value => {
		if (value && isFirma) {
			dispatch(searchCustomersByCompanyNameThunk(value))
		} else {
			dispatch(setCustomersByCompanyName(null))
		}
	}, [dispatch, isFirma])

	const filterCompanyName = useCallback(v => {
		return filterSearchString(v)
	}, [])

	const setItemsByCompanyName = useCallback(value => {
		dispatch(setCustomersByCompanyName(value))
	}, [dispatch])

	const onSubmitData = useCallback((data) => {
		onSubmit(data)
		dispatch(setDeliveryCartOrderType('DELIVERY'))
	}, [onSubmit, dispatch])

	const onBlurDeliveryCost = useCallback(() => {
		if (!watchDeliveryCost || parseInt(watchDeliveryCost) <= 0) {
			setValue('deliveryCost', 0)
		}
	}, [setValue, watchDeliveryCost ])

	const handleF2 = useCallback(() => {
		dispatch(setDeliveryCartOrderType('DELIVERY'))
		handleSubmit(onSubmitData)()
	}, [onSubmitData, dispatch, handleSubmit])

	const handleEscape = useCallback(() => {
		setFocus('phoneNumber')
		handleReset()
		dispatch(setCustomerAddressIsNotFull(false))
		dispatch(setCustomer(null))
		dispatch(searchResetThunk())
		dispatch(setDeliveryCartItems([]))
		dispatch(setDeliveryCartItemFocusIndex(null))
		dispatch(setDeliveryCartCurrentDeliveryCost(0))
		dispatch(setDeliveryCartDefaultDeliveryCost(null))
		dispatch(setDeliveryCartMaxDistance(null))
		dispatch(setShowDeliveryConfigError(false))
		dispatch(setDeliveryCartMinimumPrice(0))
		dispatch(setDeliveryCartFreeFrom(null))
	}, [handleReset, dispatch, setFocus])

	const handleShowFormClick = () => setAlwaysShowForm(!alwaysShowForm)

	const handleCustomerClick = useCallback((data) => {
		if (!data.geoData || !data.geoData.lon || !data.geoData.lat) {
			dispatch(setCustomer(data))
			dispatch(setAutocompleteGeo(true))
		} else {
			dispatch(setCustomer(data))
			dispatch(setAutocompleteGeo(false))
			if (data.geoData.distance === null) {
				dispatch(distanceCustomer(data.geoData))
			}
		}

		dispatch(searchResetThunk())
		dispatch(setAppEventZIndex(0))
	}, [dispatch])

	const handleShowForm = useCallback(() => {
		setShowForm(true)
	}, [])

	const toggleFirmaShow = useCallback(() => {
		setIsFirma(!isFirma)
	}, [isFirma])

	const formatAddressInInput = useCallback((data) => {
		return formatAddress(data.street, data.countryName, data.postalCode, data.city)
	}, [])


	return (
		<CustomerFormWrapper>
			<CustomerMainForm id="client-form"
												data-testid="customer-form"
												onSubmit={handleSubmit(onSubmitData)}
												onFocus={handleShowForm}
												onChange={handleShowForm}
												className={!showForm ? 'toggled' : ''}>
				<FormLine ref={formRef}>
					<AutocompleteInput testId="customer-phone-number"
														 name="phoneNumber"
														 tabIndex="901"
														 zIndex={PHONE_EVENT_Z_INDEX}
														 returnZIndex={EVENT_Z_INDEX}
														 autoFocus={true}
														 label={t('AddOrder.Client.form.Telefonnumer')}
														 errors={errors}
														 register={register}
														 options={{
															 required: t('app.validation.required'),
															 pattern: {
																 value: /^[0-9]{3,15}$/i,
																 message: t('app.validation.minNumber3')
															 }
														 }}
														 items={customersByPhone}
														 Item={PhoneAutocompleteItem}
														 watch={watch('phoneNumber')}
														 onAutoComplete={onAutoCompletePhone}
														 filter={filterPhone}
														 setItems={setItemsByPhone}
														 onClick={handleCustomerClick}
														 onReset={handleEscape}
														 onSubmit={handleF2}
														 inputMode="tel" />
					<AutocompleteInput testId="customer-number"
														 name="customerNumber"
														 tabIndex="902"
														 disabled={customer ? customer.customerNumber : false}
														 zIndex={NUMBER_EVENT_Z_INDEX}
														 returnZIndex={EVENT_Z_INDEX}
														 label={t('AddOrder.Client.form.customerNumber')}
														 errors={errors}
														 register={register}
														 options={{
															 pattern: {
																 value: /^[0-9]{1,150}$/i,
																 message: t('app.validation.number')
															 }
														 }}
														 items={customersByNumber}
														 Item={NumberAutocompleteItem}
														 watch={watch('customerNumber')}
														 onAutoComplete={onAutoCompleteCustomerNumber}
														 filter={filterNumber}
														 setItems={setItemsByNumber}
														 onClick={handleCustomerClick}
														 onReset={handleEscape}
														 onSubmit={handleF2}
														 inputMode="tel" />
				</FormLine>
				<CustomerNameContainer className={isFirma ? 'columns' : ''}>
					<CustomerNameWrapper className={!showForm ? 'hidden name' : ''}>
						<AutocompleteInput testId="customer-name"
															 name="name"
															 tabIndex="903"
															 zIndex={NAME_EVENT_Z_INDEX}
															 returnZIndex={EVENT_Z_INDEX}
															 label={t('AddOrder.Client.form.Name')}
															 errors={errors}
															 register={register}
															 options={{
																 required: t('app.validation.required')
															 }}
															 items={customersByName}
															 Item={NameAutocompleteItem}
															 watch={watch}
															 onAutoComplete={onAutoCompleteCustomerName}
															 filter={filterName}
															 setItems={setItemsByName}
															 onClick={handleCustomerClick}
															 onReset={handleEscape}
															 onSubmit={handleF2}>
							<CheckBoxInputControlled id="firma-id" label={t('AddOrder.Client.form.Company')} className="firma-id"
																			 testId="is-firma" checked={isFirma} onChange={toggleFirmaShow} />
						</AutocompleteInput>
					</CustomerNameWrapper>
					{isFirma &&
						<CustomerNameWrapper className={!showForm ? 'hidden name' : ''}>
							<AutocompleteInput testId="customer-company"
																 name="companyName"
																 tabIndex="904"
																 zIndex={COMPANY_NAME_EVENT_Z_INDEX}
																 returnZIndex={EVENT_Z_INDEX}
																 label={t('AddOrder.Client.form.Company')}
																 errors={errors}
																 register={register}
																 items={customersByCompanyName}
																 Item={CompanyNameAutocompleteItem}
																 watch={watch('companyName')}
																 onAutoComplete={onAutoCompleteCompanyName}
																 filter={filterCompanyName}
																 setItems={setItemsByCompanyName}
																 onClick={handleCustomerClick}
																 onReset={handleEscape}
																 onSubmit={handleF2} />
						</CustomerNameWrapper>}
				</CustomerNameContainer>
				<CustomerAddressWrapper className={!showForm ? 'hidden' : ''}>
					<AddressSearchInputs firstInputName="address"
															 secondInputName="streetNumber"
															 dataName="customerAddress"
															 firstTabIndex="905"
															 secondTabIndex="906"
															 firstLabel={t('AddOrder.Client.form.Strasse')}
															 secondLabel={t('AddOrder.Client.form.Hausnummer')}
															 errors={errors}
															 watch={watch}
															 setValue={setValue}
															 formatAddress={formatAddressInInput}
															 countryDataRequired={false}
															 autocompleteRequest={true}
															 register={register}
															 setFocus={setFocus}
															 cols="two"
															 countryCode={companyContryCode}
															 orderType={orderType}
															 validationIsRequired={validationIsRequired}
															 clearErrors={clearErrors} />
					<input data-testid="customer-geodata-distance" type="hidden" value={geoData?.distance || ''} /> {/* only for tests */}
				</CustomerAddressWrapper>
				<FormLine className={!showForm ? 'hidden' : ''}>
					<TextInput testId="customer-remark"
										 name="remark"
										 tabIndex="907"
										 errors={errors}
										 label={t('AddOrder.Client.form.Bemmerkung')}
										 register={register}
										 watch={watch('remark')} />
					<TextInput testId="customer-delivery-cost"
										 name="deliveryCost"
										 tabIndex="908"
										 onBlur={onBlurDeliveryCost}
										 errors={errors}
										 label={t('AddOrder.Client.form.Anfahrthosten')}
										 register={register}
										 watch={watch} />
				</FormLine>
				{!isTablet && <BlockShowForm className={!showForm ? 'hidden' : ''}>
					<CheckBoxInputControlledGray label={t('AddOrder.Client.form.control')}
																			 onChange={handleShowFormClick}
																			 isActive={alwaysShowForm} />
				</BlockShowForm>}
				<FormButtons className={'justify' + (showForm ? '' : ' hidden')}>
					<Button text={t('AddOrder.Client.form.Loschen')} keyButton="Escape" keyText="Esc" size={!isTablet && 'big'} zIndex={EVENT_Z_INDEX} onClick={handleEscape} />
					<Button text={t('AddOrder.Client.form.Bestellung')} color="green" keyButton="F2" size={!isTablet && 'big'} zIndex={EVENT_Z_INDEX} disabled={geoDataLoading} onClick={handleF2} type="submit" />
				</FormButtons>
			</CustomerMainForm>
		</CustomerFormWrapper>
	)
})

CustomerForm.displayName = 'CustomerForm'
export default CustomerForm
