import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../Elements/buttons/Button'
import { NoDriverModalText, NoDriverModalWrap } from './index.styled'
import CustomInfoModal from '../../Elements/modal/CustomInfoModal'
import { setWoltDriverNotFound } from '../../../redux/action/woltDriverIntegrations'

const EVENT_Z_INDEX = 889

const NoDriverModal = React.memo(() => {
	const { t } = useTranslation()
    const dispatch = useDispatch()
	const showModal = useSelector(store => store.woltDriverIntegrations.driverNotFound)

	const handleClose = useCallback(() => {
		dispatch(setWoltDriverNotFound(false))
	}, [])

	return (
		<CustomInfoModal isOpen={showModal}
			title={t('app.modals.Attention')}
			close={handleClose}
			zIndex={EVENT_Z_INDEX}
            modalColor="orange"
			button={<Button icon="checkmark" onClick={handleClose} tabIndex="1" text={t('buttons.controls.ready')} color="green" keyButton="space" />}>
			<NoDriverModalWrap>
				<NoDriverModalText>{t('woltDriverIntegrationsModal.driverIsNotFound')}!</NoDriverModalText>
			</NoDriverModalWrap>
		</CustomInfoModal>
	)
})

NoDriverModal.displayName = 'NoDriverModal'
export default NoDriverModal
