import React from 'react'
import { useSelector } from 'react-redux'
import { OrderCost, OrderDelivery, OrderRate, OrderSubTitle, OrderTitle, OrderTotalWrapper, OrderTotalsWrap } from '../index.styled'
import { selectCurrencySymbol } from '../../../../../../../../redux/selector/system'
import Currency from '../../../../../../currency'
import { getFormattedCurrency } from '../../../../../../../../helper'

const InvoiceOrderTotalsForPrint = React.memo(({ order }) => {
	const currency = useSelector(selectCurrencySymbol)
	const orderExchangeRate = order?.exchangeRateMetaData?.rate
	const orderExchangeCurrency = order?.exchangeRateMetaData?.currency
	const exchangeRateCurrency = getFormattedCurrency(orderExchangeRate, 'de-DE', orderExchangeCurrency)
	const coupon = order?.purchaseItems?.filter(item => item?.type === 'MARKETING_COUPON')?.[0]
	const extraDiscount = order?.purchaseItems?.filter(item => item?.type === 'EXTRA_DISCOUNT')?.[0]
	const discount = order?.purchaseItems?.filter(item => item?.type === 'DISCOUNT')?.[0]
	const uberEatPlatformFee = order?.purchaseItems?.filter(item => item?.type === 'PLATFORM_FEE')?.[0]
	const deliveryCost = order?.purchaseItems?.filter(item => item?.type === 'DELIVERY_COST')?.[0]

	return (
		<OrderTotalsWrap>
			<OrderDelivery>
				{deliveryCost && <OrderCost>
					 <OrderSubTitle className="medium">Anfahrt: </OrderSubTitle>
					<OrderSubTitle className="medium"><Currency>{deliveryCost?.price}</Currency></OrderSubTitle>
				</OrderCost>}
				{uberEatPlatformFee &&
					<OrderCost data-testid="order-uber-platform-fee">
						<OrderSubTitle className="medium">UberEats Zahlungsgebühr: </OrderSubTitle>
						<OrderSubTitle className="medium"><Currency>{uberEatPlatformFee?.price}</Currency></OrderSubTitle>
					</OrderCost>}
				{discount && <OrderCost data-testid="order-discount">
					<OrderSubTitle className="medium">Rabatt: </OrderSubTitle>
					<OrderSubTitle className="medium"><Currency>{Math.abs(discount?.price)}</Currency></OrderSubTitle>
				</OrderCost>}
				{extraDiscount && <OrderCost data-testid="order-extra-discount">
					<OrderSubTitle className="medium">{extraDiscount.name}: </OrderSubTitle>
					<OrderSubTitle className="medium"><Currency>{Math.abs(extraDiscount?.price)}</Currency></OrderSubTitle>
				</OrderCost>}
				{coupon && <OrderCost data-testid="order-coupon">
					<OrderSubTitle className="medium">Gutschein {coupon?.name}</OrderSubTitle>
					<OrderSubTitle className="medium"><Currency>{coupon?.price}</Currency></OrderSubTitle>
				</OrderCost>}
			</OrderDelivery>
			{order?.tips > 0 && (
				<OrderSubTitle data-testid="order-tips" className="medium right bold">Trinkgeld: <Currency>{order?.tips}</Currency></OrderSubTitle>
			)}
			{order?.paymentFee > 0 && (
				<OrderSubTitle className="medium right bold">Zahlungsgebühr: <Currency>{order?.paymentFee}</Currency></OrderSubTitle>
			)}
			<OrderCost className="cost">
				<OrderTitle className="bold">{order?.payments?.map((payment, i) =>
					<div key={i}>Betrag:</div>)}</OrderTitle>
				<OrderTitle className="bold">{order?.payments?.map((payment, i) =>
					<div key={i}><Currency>{payment.total}</Currency></div>)}</OrderTitle>
			</OrderCost>
			{order?.exchangeRateMetaData && <OrderTotalWrapper className="top">
				<OrderRate>
					{order?.payments?.map((payment, i) => {
						const totalInCurrency = getFormattedCurrency(Math.floor((payment?.total * orderExchangeRate) * 100) / 100, 'de-DE', orderExchangeCurrency)
						return <div key={i}>
							Betrag in {orderExchangeCurrency}: {totalInCurrency}
						</div>})}
				</OrderRate>
				<OrderRate>
					Wechselkurs: 1{currency} = {exchangeRateCurrency}
				</OrderRate></OrderTotalWrapper>}

			<OrderTotalWrapper>
				{order?.taxTotal?.taxes &&
					order?.taxTotal?.taxes.map((tax, i) => (
						<OrderCost key={'vat_' + i}>
							<OrderSubTitle className="medium">MwSt {tax.title}</OrderSubTitle>
							<OrderSubTitle className="medium"><Currency>{tax.vat}</Currency></OrderSubTitle>
						</OrderCost>
					))}
			</OrderTotalWrapper>
		</OrderTotalsWrap>
	)
})

InvoiceOrderTotalsForPrint.displayName = 'InvoiceOrderTotalsForPrint'
export default InvoiceOrderTotalsForPrint
