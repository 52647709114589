import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { processUserThunk } from '../../../../redux/thunk/order/processUser'
import { setShowOrder, setShowSingleDriver } from '../../../../redux/action/driver-monitor'
import { setModalOrderCustomer } from '../../../../redux/action/customer'
import ButtonsModal from '../../../Elements/modal/ButtonsModal'
import { fetchAllUsersByRoleThunk } from '../../../../redux/thunk/orderfilter'

const EVENT_Z_INDEX = 23

const SingleDriverModal = React.memo(() => {
	const { t } = useTranslation()
	const showSingleDriver = useSelector((store) => store.drivermonitor.modal.showSingleDriver)
	const users = useSelector((store) => store.user.users)
	const selectedOrder = useSelector((store) => store.order.selectedOrder)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchAllUsersByRoleThunk(null))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showSingleDriver) {
			dispatch(fetchAllUsersByRoleThunk(null))
		}
	}, [showSingleDriver]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		dispatch(setModalOrderCustomer({ showOrder: false }))
		dispatch(setShowOrder(false))
		dispatch(setShowSingleDriver(false))
	}, [dispatch])

	const handleChoseUser = useCallback(async (user) => {
		await dispatch(processUserThunk(selectedOrder.orderId, user.username))
		handleClose()
	}, [selectedOrder?.orderId, dispatch, handleClose])

	const buttons = useMemo(() => users?.map(user =>
		({ icon: 'user', text: user.username, onClick: () => handleChoseUser(user), show: true })), [users, handleChoseUser])

	return (
		<ButtonsModal isOpen={showSingleDriver}
									close={handleClose}
									title={t('order.modal.reserve_driver')}
									zIndex={EVENT_Z_INDEX}
									size="height-scroll"
									buttons={buttons} />
	)
})

SingleDriverModal.displayName = 'SingleDriverModal'
export default SingleDriverModal
