import React, { useCallback, useState } from 'react'
import { ClearKey, EnterKey, KeyboardCloseButton, KeyboardMain, KeyboardWrapper, NumberKey, ShiftKey, SpaceKey, TextKey, ToggleKey } from './index.styled'
import MainIcon from '../../../img/svg/main'
import { letterKeys, numberKeys, symbolKeys } from './constants'

const Keyboard = ({ handleType, currentValue, onlyNumbers = false, close, enter }) => {
	const [lettersMode, setLettersMode] = useState(!onlyNumbers)
	const [isShiftActive, setIsShiftActive] = useState(false)

	const layout = lettersMode ? 'letters' : ''
	const layoutShift = isShiftActive ? ' active' : ''

	const handleEnter = useCallback(() => {
		enter()
	}, [enter])

	const handleKeyClick = useCallback((value) => {
		handleType(currentValue + value)
	}, [handleType, currentValue])

	const handleClear = useCallback(() => {
		handleType(currentValue?.replace(/.$/, ''))
	}, [handleType, currentValue])

	const handleToggleClick = useCallback(() => {
		setLettersMode(!lettersMode)
	}, [lettersMode])

	const handleMainClick = useCallback(e => {
		e.stopPropagation()
	}, [])

	const handleToggleShift = () => {
		setIsShiftActive(!isShiftActive)
	}

	return (
		<KeyboardWrapper onClick={close} data-testid="keyboard-wrapper">
			<KeyboardMain className={layout} onClick={handleMainClick} data-testid="keyboard-main">
				<KeyboardCloseButton onClick={close} className={layout}>
					<MainIcon icon="cross" />
				</KeyboardCloseButton>
				{numberKeys.map((key, index) => (
					<NumberKey key={index} className={layout} onClick={() => handleKeyClick(key)} data-testid="number-key">{key}</NumberKey>
				))}
				<ClearKey className={layout} onClick={handleClear}>
					<MainIcon icon="backspace" />
				</ClearKey>
				<EnterKey className={layout} onClick={handleEnter}>
					<MainIcon icon="enter" />
				</EnterKey>
				<SpaceKey className={layout} onClick={() => handleKeyClick(' ')}>SPACE</SpaceKey>
				{letterKeys.map((key, index) => {
						const formattedKey = isShiftActive ? key.toUpperCase() : key.toLowerCase()
						return (
							<TextKey key={index} className={layout} onClick={() => handleKeyClick(formattedKey)} data-testid="letter-key">{formattedKey}</TextKey>
						)
					}
				)}
				<ShiftKey className={layout + layoutShift} onClick={handleToggleShift}>shift</ShiftKey>
				{symbolKeys.map((key, index) => (
					<TextKey key={index} className={layout} onClick={() => handleKeyClick(key)} data-testid="symbol-key">{key}</TextKey>
				))}
				<ToggleKey className={layout} onClick={handleToggleClick}>{lettersMode ? '123' : 'ABC'}</ToggleKey>
			</KeyboardMain>
		</KeyboardWrapper>
	)
}

Keyboard.displayName = 'Keyboard'
export default Keyboard
