import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const WebShopSchedule = lazyWithRetry(() => import(/* webpackChunkName: "webshop-schedule" */'../../component/WebShopSchedule'))

const WebShopSchedulePage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<WebShopSchedule />
		</Suspense>
	)
})

WebShopSchedulePage.displayName = 'WebShopSchedulePage'
export default WebShopSchedulePage
