import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import InvoiceDataModal from './InvoiceDataModal'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import InvoiceSheetForPrint from './InvoiceSheetForPrint'
import jsPDF from 'jspdf'
import InvoiceBillingAddressModal from './InvoiceBillingAddressModal'
import InvoiceResendEmailInfoModal from './InvoiceResendEmailInfoModal'
import InvoiceResendEmailModal from './InvoiceResendEmailModal'

const InvoiceTableModals = React.memo(() => {
	const showOptionsModal = useSelector(store => store.invoice.modal.showOptions)
	const showInvoiceDataModal = useSelector(store => store.invoice.modal.showInvoiceData)
	const showBillingAddressModal = useSelector(store => store.invoice.modal.showBillingAddress)
	const showInfoResendEmailModal = useSelector(store => store.invoice.modal.showInfoResendEmail)
	const showResendEmailModal = useSelector(store => store.invoice.modal.showResendEmail)

	const handlePrintInvoice = useCallback(() => {
		const invoiceForPrint = document.getElementById('invoice-sheet-for-print')
		const pdf = new jsPDF({
			orientation: 'p',
			unit: 'px',
			format: 'a4',
			hotfixes: ['px_scaling'],
		})
		pdf.html(invoiceForPrint, {
			callback: (pdf) => {
				pdf.autoPrint()
				pdf.output('dataurlnewwindow')
			},
			margin: [63, 0, 0, 0],
			x: 27,
			y: 0,
		})
	}, [])


	return (
		<>
			{showOptionsModal && <InvoiceOptionsModal handlePrint={handlePrintInvoice} />}
			{showInvoiceDataModal && <InvoiceDataModal handlePrint={handlePrintInvoice} />}
			{showBillingAddressModal && <InvoiceBillingAddressModal />}
			{showInfoResendEmailModal && <InvoiceResendEmailInfoModal />}
			{showResendEmailModal && <InvoiceResendEmailModal />}
			<InvoiceSheetForPrint />
		</>
	)
})

InvoiceTableModals.displayName = 'InvoiceTableModals'
export default InvoiceTableModals
