import React from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../Header'
import { CustomerName } from '../../../Elements/cart/index.styled'
import Button from '../../../Elements/buttons/Button'
import MobileCartButtonPortal from '../../../Elements/cart/MobileCartButtonPortal'
import DishCourses from '../DishCourses'
import { convertTimeToMilliseconds } from '../../../../helper'
import LoginTimeCountDown from '../../../Elements/loginTimeCountDown'
import { selectCurrentOrdersInTableCart, selectGiftCouponInTableCart } from '../../../../redux/selector/restaurant'
import { theme } from '../../../../style/theme'
import { setTableCartModalCustomProductIsOpen } from '../../../../redux/action/tableCart'
import { useDispatch, useSelector } from 'react-redux'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import MobileCartButton from '../../../Elements/cart/MobileCartButton'

const CartHeader = React.memo(({ toggleMobileCart, zIndex }) => {
	const { t } = useTranslation()
	const { table } = useSelector(state => state.restaurant)
	const orders = useSelector(selectCurrentOrdersInTableCart)
	const hasGiftCoupon = useSelector(selectGiftCouponInTableCart)
	const logoutTime = useSelector(state => state.restaurantSettings.waiterSettings)?.logoutTime || 0
	const itemsCount = orders?.reduce((cnt, o) => cnt + o.items?.length, 0)
	const isTablet = useWindowBreakPoint(theme.point820)
	const isMobile = useWindowBreakPoint(theme.point720)
	const dispatch = useDispatch()

	const openCustomProduct = () => {
		dispatch(setTableCartModalCustomProductIsOpen(true))
	}

	return (
		<>
			<Header right={
				<>
					<MobileCartButton count={itemsCount} onClick={toggleMobileCart} />
					<MobileCartButtonPortal cartItemsCount={itemsCount} toggleMobileCart={toggleMobileCart} />
				</>
			}>
				<CustomerName>{t('restaurant.tables.modal.table')} {table?.number}</CustomerName>
				{isTablet &&
					<>
						<Button icon="plus" text={t('Cart.Buttons.extras')} keyButton="F3" color="blue" showTextForMobile={false}
										size="small" zIndex={zIndex} onClick={openCustomProduct} disabled={hasGiftCoupon} />
						{!isMobile && <DishCourses />}
					</>}
				<LoginTimeCountDown logoutTime={convertTimeToMilliseconds(logoutTime)} />
			</Header>
		</>
	)
})

CartHeader.displayName = 'CartHeader'
export default CartHeader
