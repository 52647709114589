import React, { useEffect, useRef, useState } from 'react'
import { AutoCompleteItem, AutocompleteItems, AutocompleteWrapper } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { setAppEventZIndex } from '../../../../redux/action/system'
import TextInput from '../TextInput'
import TextAreaInput from '../TextAreaInput'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'

const AutocompleteInput = React.memo(({
	id,
	register,
	options,
	name,
	defaultValue,
	label = '',
	errors = {},
	errorParser,
	autoFocus = false,
	testId,
	tabIndex,
	zIndex = 0,
	returnZIndex = 0,
	items,
	Item,
	children,
	className,
	color,
	disabled,
	inputMode,
	hideOnBlur,
	size,
	textArea = false,
	watch,
	onAutoComplete,
	filter,
	setItems,
	onClick,
	onReset,
	onSubmit,
	onFocus = () => {
	},
}) => {
	const [activeRow, setActiveRow] = useState(0)
	const [value, setValue] = useState('')
	const isNeededZIndex = useSelector(selectIsNeededZIndex(zIndex))
	const autocompleteRef = useRef()
	const dispatch = useDispatch()
	const [focused, setFocused] = useState(false)
	const isMobile = useWindowBreakPoint(theme.point720)
	let timeOutId

	useEffect(() => {
		if (items && items.length > 0) {
			dispatch(setAppEventZIndex(zIndex))
		}
		return () => dispatch(setAppEventZIndex(returnZIndex))
	}, [items])

	useEffect(() => {
		const autocomplete = document.querySelector(`#${id || name}-autocomplete`)
		if (autocomplete) {
			const autocompleteActive = autocomplete.querySelector('div .active')
			if (autocompleteActive) {
				autocomplete.scrollTop = autocompleteActive.offsetTop - 20
			}
		}
	}, [activeRow])

	useEffect(() => {
		if (value) {
			timeOutId = setTimeout(() => onAutoComplete(value), 300)
			return () => clearTimeout(timeOutId)
		}
	}, [value])

	const handleOnChange = e => {
		if (filter) {
			e.target.value = filter(e.target.value)
		}
		setValue(e.target.value)
	}

	const handleArrowUp = () => {
		if (!items) return
		if (activeRow === 0) {
			setActiveRow(items.length - 1)
		} else {
			setActiveRow(activeRow - 1)
		}
	}

	const handleArrowDown = () => {
		if (!items) return
		if (activeRow < (items.length - 1)) {
			setActiveRow(activeRow + 1)
		} else {
			setActiveRow(0)
		}
	}

	const handleEnter = () => {
		if (!items || !items[activeRow]) return
		onClick(items[activeRow])
	}

	const handleClick = (event, data) => {
		event.preventDefault()
		onClick(data)
	}

	const handleEsc = () => {
		onReset()
	}

	const handleF2 = () => {
		onSubmit()
	}

	const handleOnBlur = () => {
		setItems(null)
		clearTimeout(timeOutId)
		setFocused(false)
	}

	const handleOnFocus = () => {
		setFocused(true)
		onFocus()
	}

	useOutsideClick(autocompleteRef, () => {
		setItems(null)
	})

	const errorMessage = errors && (errorParser ? errorParser(errors) : errors[name])?.message
	const layout = errorMessage ? 'error ' : ''

	return (
		<>
			<AutocompleteWrapper className={className}>
				{isMobile && textArea ?
					<TextAreaInput testId={testId}
												name={name}
												defaultValue={defaultValue}
												tabIndex={tabIndex}
												autoFocus={autoFocus}
												label={label}
												errors={errors}
												errorParser={errorParser}
												register={register}
												options={options}
												watch={watch}
												onChange={handleOnChange}
												onBlur={handleOnBlur}
												onFocus={handleOnFocus}
												color={color}
												inputMode={inputMode}
												disabled={disabled}
												size="small">
						{children}
					</TextAreaInput> :
					<TextInput testId={testId}
										name={name}
										defaultValue={defaultValue}
										tabIndex={tabIndex}
										autoFocus={autoFocus}
										label={label}
										errors={errors}
										errorParser={errorParser}
										register={register}
										options={options}
										watch={watch}
										onChange={handleOnChange}
										onBlur={handleOnBlur}
										onFocus={handleOnFocus}
										color={color}
										inputMode={inputMode}
										disabled={disabled}
										size={size}>
						{children}
					</TextInput>}
				{(!hideOnBlur || focused) && items && items.length > 0 &&
					<AutocompleteItems id={`${id || name}-autocomplete`}
														 data-testid={`${id || name}-autocomplete`}
														 className={'autocomplete-block ' + layout}
														 ref={autocompleteRef}>
						{items.map((item, i) =>
							<AutoCompleteItem key={i}
																className={activeRow === i ? 'active' : ''}
																tabIndex={'1' + i}
																onMouseDown={(event) => handleClick(event, item)}>
								<Item item={item} active={activeRow === i} />
							</AutoCompleteItem>)}
					</AutocompleteItems>}
			</AutocompleteWrapper>

			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 escape={handleEsc}
										 arrowDown={handleArrowDown}
										 enter={handleEnter}
										 f2={handleF2} />}
		</>
	)
})

AutocompleteInput.displayName = 'AutocompleteInput'
export default AutocompleteInput
