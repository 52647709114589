import phoneInstance from '../instance'

const phoneCallTest = (customerPhone, callbackSuccess, callbackError) => {
  return async dispatch => {
    phoneInstance.post('/phones/test',
      {
        eventId: '7423afd2-e7ab-4bae-ac30-048b7c0346c1',
        traceId: '87243EDB-AA9F-400B-B281-DB70CD72833E',
        type: 'desktop-client.TEST_PHONE_CALL_EVENT',
        context: 'desktop',
        eventTime: '2020-06-25T06:30:23.497Z',
        data: {
          customerPhone,
          organizationPhone: '987654321',
        },
      },
    ).then(() => {
      typeof callbackSuccess === 'function' && callbackSuccess()
    }).catch(error => {
      typeof callbackError === 'function' && callbackError(error)
    })
  }
}

export default phoneCallTest
