import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../buttons/Button'
import ActivateCouponModal from './ActivateCouponModal'

const Coupon = React.memo(({ addCoupon, removeCoupon, disabled, cartType }) => {
	const { t } = useTranslation()
	const [showActivateModal, setShowActivateModal] = useState(false)

	const openActivationModal = useCallback(() => {
		setShowActivateModal(true)
	}, [])

	const closeActivationModal = useCallback(() => {
		setShowActivateModal(false)
	}, [])

	return (
		<>
			<Button name="coupon"
							testId="coupon"
							color="blue"
							icon="coupon"
							text={t('Cart.Coupon.title')}
							onClick={openActivationModal}
							showTextForTablet={false}
							size="small"
							disabled={disabled} />

			<ActivateCouponModal isOpen={showActivateModal}
													 close={closeActivationModal}
													 addCoupon={addCoupon}
													 removeCoupon={removeCoupon}
													 cartType={cartType}
													 giftCouponsType={cartType} />
		</>
	)
})

Coupon.displayName = 'Coupon'
export default Coupon
