import { APPEND_CATEGORIES, SET_CATEGORIES, SET_CATEGORIES_LENGTH, SET_CATEGORY, SET_CATEGORY_IMAGE_CONDITIONS, SET_CATEGORY_IMAGE_CONFIG, SET_CATEGORY_IMAGE_UPLOAD_STEP, SET_CATEGORY_PARENTS, SET_DEFAULT_CATEGORIES, SET_SHOW_CATEGORY_SIZE_ERROR_MODAL, UPDATE_CATEGORY_ORDER, SET_CATEGORIES_UPDATE_CATEGORY, SET_CATEGORY_IMAGE_ERROR_MODAL, SET_CATEGORY_ICON_URL, SET_SHOW_CATEGORY_ICON_PICKER, SET_CATEGORY_DELETE_ERROR_MODAL, SET_SHOW_CATEGORY_COLOR_PICKER, SET_CATEGORY_COLOR_DATA, SET_CATEGORY_IMAGE_NAV_CONDITIONS, SET_CATEGORY_IMAGE_NAV_CONFIG, SET_CATEGORY_IMAGE_NAV_UPLOAD_STEP, SET_CATEGORY_IMAGE_NAV_ERROR_MODAL } from '../../actionTypes/category'

export const setCategory = value => ({ type: SET_CATEGORY, value })

export const setCategoriesUpdateCategory = value => ({ type: SET_CATEGORIES_UPDATE_CATEGORY, value })

export const setCategories = value => ({ type: SET_CATEGORIES, value })

export const setCategoriesTotalLength = value => ({ type: SET_CATEGORIES_LENGTH, value })

export const appendCategories = value => ({ type: APPEND_CATEGORIES, value })

export const setDefaultCategories = value => ({ type: SET_DEFAULT_CATEGORIES, value })

export const setCategoryParents = value => ({ type: SET_CATEGORY_PARENTS, value })

export const updateCategoryOrder = value => ({ type: UPDATE_CATEGORY_ORDER, value })

export const setShowCategorySizeErrorModal = value => ({ type: SET_SHOW_CATEGORY_SIZE_ERROR_MODAL, value })

export const setCategoryImageUploadStep = value => ({ type: SET_CATEGORY_IMAGE_UPLOAD_STEP, value })

export const setCategoryImageConditions = value => ({ type: SET_CATEGORY_IMAGE_CONDITIONS, value })

export const setCategoryImageConfig = value => ({ type: SET_CATEGORY_IMAGE_CONFIG, value })

export const setCategoryImageErrorModal = (value) => ({ type: SET_CATEGORY_IMAGE_ERROR_MODAL, value })

export const setCategoryIconUrl = (value) => ({ type: SET_CATEGORY_ICON_URL, value })
export const setCategoryColorData = (value) => ({ type: SET_CATEGORY_COLOR_DATA, value })
export const setShowCategoryIconPicker = (value) => ({ type: SET_SHOW_CATEGORY_ICON_PICKER, value })

export const setCategoryDeleteErrorModal = (value) => ({ type: SET_CATEGORY_DELETE_ERROR_MODAL, value })
export const setShowCategoryColorPicker = (value) => ({ type: SET_SHOW_CATEGORY_COLOR_PICKER, value })

export const setCategoryImageNavUploadStep = value => ({ type: SET_CATEGORY_IMAGE_NAV_UPLOAD_STEP, value })

export const setCategoryImageNavConditions = value => ({ type: SET_CATEGORY_IMAGE_NAV_CONDITIONS, value })

export const setCategoryImageNavConfig = value => ({ type: SET_CATEGORY_IMAGE_NAV_CONFIG, value })

export const setCategoryImageNavErrorModal = (value) => ({ type: SET_CATEGORY_IMAGE_NAV_ERROR_MODAL, value })
