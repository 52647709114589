import { SET_CART_DISCOUNT, SET_CART_DISCOUNT_TYPE, SET_CART_EXTRA_DISCOUNT, SET_CART_EXTRA_DISCOUNT_TYPE, SET_CART_INITIAL_DISCOUNT } from '../../actionTypes/cartDiscount'
import { CURRENCY, PERCENTS } from '../businessScopeDiscount/constants'

const initialState = {
	initialDiscount: null,
	discount: 0,
	discountType: localStorage.discount ? localStorage.discount : PERCENTS,
	extraDiscount: 0,
	extraDiscountType: CURRENCY
}

export function cartDiscountReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CART_DISCOUNT:
			return {
				...state,
				discount: action.value
			}
		case SET_CART_DISCOUNT_TYPE:
			localStorage.discount = action.value
			return {
				...state,
				discountType: action.value
			}
		case SET_CART_INITIAL_DISCOUNT:
			return {
				...state,
				initialDiscount: action.value
			}
		case SET_CART_EXTRA_DISCOUNT:
			return {
				...state,
				extraDiscount: action.value
			}
		case SET_CART_EXTRA_DISCOUNT_TYPE:
			return {
				...state,
				extraDiscountType: action.value
			}
		default:
			return state
	}
}
