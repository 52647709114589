import { OrderGiftCouponTitle, OrderGiftCouponWrap, OrderIngredients, OrderMenuWrapper, OrderProduct, OrderProductRemark, OrderProductsWrap, OrderProductWrapper, OrderSubTitle, OrderText, OrderTitle, OrderTopping, OrderToppings } from '../index.styled'
import React, { useCallback } from 'react'
import Currency from '../../../../currency'
import { encryptGiftCode } from '../../../../../../helper/encryptGiftCode'
import { getOrderGroupCategories, isShowIngredients } from '../helper'
import { getNeededDateFormat } from '../../../../../../helper'

const OrderProducts = React.memo(({ order }) => {
	const resultItems = order?.items?.filter(item => !['MARKETING_COUPON', 'EXTRA_DISCOUNT', 'PLATFORM_FEE'].includes(item?.type))
	const orderGroupCategories = getOrderGroupCategories(resultItems)
	const formatCurrency = useCallback((data) => data.toFixed(2).replace('.', ','), [])
	const isGiftCouponProduct = order?.items?.[0]?.type === 'GIFT_COUPON'
	const expirationDateOfGiftCoupon = getNeededDateFormat(order?.items?.[0]?.couponMetaData?.expirationDate)

	return (orderGroupCategories?.length > 0 &&
		<OrderProductsWrap>
			{orderGroupCategories?.map((item, i) =>
				<OrderProduct key={'order-wrapper_' + i}>
					{item?.category && <OrderTitle>{item.category}</OrderTitle>}
					{item.products?.map((prod, j) =>
						(prod?.type === 'GIFT_COUPON' ?
							<React.Fragment key={'product-wrapper_' + j}>
								<OrderGiftCouponWrap>
									<OrderProductWrapper>
										<OrderGiftCouponTitle>
											{prod.name}
										</OrderGiftCouponTitle>
										<OrderGiftCouponTitle className="bold">
											<Currency>{prod.price}</Currency>
										</OrderGiftCouponTitle>
									</OrderProductWrapper>
									<OrderIngredients>
										<OrderSubTitle>
											{encryptGiftCode(prod?.couponMetaData?.code)}
										</OrderSubTitle>
										<OrderSubTitle>
											{expirationDateOfGiftCoupon}
										</OrderSubTitle>
									</OrderIngredients>
								</OrderGiftCouponWrap>
							</React.Fragment> :
							prod?.type === 'MENU' ?
								<React.Fragment key={'product-wrapper_' + j}>
									<OrderMenuWrapper>
										<OrderSubTitle className="bold">{prod.quantity}x {prod.sku && `#${prod.sku}`} {prod.name}
											{prod.quantity > 1 && <span> ({formatCurrency(prod.price)})</span>}
										</OrderSubTitle>
										<OrderSubTitle className="bold">
											<Currency>{prod.quantity * prod.price}</Currency>
										</OrderSubTitle>
									</OrderMenuWrapper>
									{prod?.items?.map((subItem, p) => (
										<React.Fragment key={'sub-order-wrapper_' + p}>
											<OrderProductWrapper>
												<OrderSubTitle className="bold">{subItem.quantity}x {subItem.sku && `#${subItem.sku}`} {subItem.name}
													{subItem.quantity > 1 && <span> ({formatCurrency(subItem.price)})</span>}
												</OrderSubTitle>
												<OrderSubTitle className="bold">
													<Currency>{subItem.quantity * subItem.price}</Currency>
												</OrderSubTitle>
											</OrderProductWrapper>
											{isShowIngredients(subItem) &&
												<OrderIngredients>
													<OrderSubTitle>{subItem?.ingredients}</OrderSubTitle>
												</OrderIngredients>}
											{subItem?.items?.length > 0 && <OrderToppings>
												{subItem?.items?.map((topping, t) => (
													<OrderTopping key={'topping-wrapper_' + t}>
														<OrderText>{topping?.quantity >= 0 ? '+' : '-'} {topping?.quantity}x {topping.sku && `#${topping.sku}`} {topping?.name}
															{topping.quantity > 1 && <span> ({formatCurrency(topping.price)})</span>}
														</OrderText>
														<OrderText>
															<Currency>{topping.quantity * topping?.price}</Currency>
														</OrderText>
													</OrderTopping>
												))}
											</OrderToppings>}
											{subItem.remark && <OrderProductRemark>{subItem.remark}</OrderProductRemark>}
										</React.Fragment>))}
								</React.Fragment> :
								<React.Fragment key={'product-wrapper_' + j}>
									<OrderProductWrapper data-testid={'order-product-' + j}>
										<OrderSubTitle className="bold">{prod.quantity}x {prod.sku && `#${(prod.sku)}`} {prod.name}
											{prod.quantity > 1 && <span> ({formatCurrency(prod.price)})</span>}
										</OrderSubTitle>
										<OrderSubTitle className="bold">
											<Currency>{prod.quantity * prod.price}</Currency>
										</OrderSubTitle>
									</OrderProductWrapper>
									{isShowIngredients(prod) &&
										<OrderIngredients>
											<OrderSubTitle>{prod?.ingredients}</OrderSubTitle>
										</OrderIngredients>}
									{isGiftCouponProduct &&
										<OrderIngredients>
											<OrderSubTitle className="bold">
												{encryptGiftCode(prod?.couponMetaData?.code)}
											</OrderSubTitle>
											<OrderSubTitle className="bold">
												{expirationDateOfGiftCoupon}
											</OrderSubTitle>
										</OrderIngredients>}
									{prod?.items?.length > 0 && <OrderToppings>
										{prod?.items?.map((subItem, t) => (
											<OrderTopping key={'sub-order-wrapper_' + t}>
												<OrderText>{subItem?.quantity >= 0 ? '+' : '-'} {subItem?.quantity}x {subItem.sku && `#${subItem.sku}`} {subItem?.name}
													{subItem.quantity > 1 && <span> ({formatCurrency(subItem.price)})</span>}
												</OrderText>
												<OrderText>
													<Currency>{subItem.quantity * subItem?.price}</Currency>
												</OrderText>
											</OrderTopping>
										))}
									</OrderToppings>}
									{prod.remark && <OrderProductRemark>{prod.remark}</OrderProductRemark>}
								</React.Fragment>
						))}
				</OrderProduct>
			)}
		</OrderProductsWrap>
	)
})

OrderProducts.displayName = 'OrderProducts'
export default OrderProducts
