import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeTableSplitThunk, fetchTableSplitsThunk, storeTableSplitThunk, updateTableSplitThunk } from '../../../../../redux/thunk/restaurant/split'
import { CartTableHead, SplitGrid, TableOrders, TableOrdersHead, TableOrdersProducts } from './index.styled'
import { calcItemTotalPrice } from '../../../../../redux/reducer/tableCart/helper'
import { setTableCartOrders } from '../../../../../redux/action/tableCart'
import ModalPaymentType from '../modalPaymentType'
import { fetchTableOrdersThunk } from '../../../../../redux/thunk/restaurant/order'
import { calcCartItemTotalPrice, getPercentDiscountInCurrency, getTablePrice, roundPrice, uuidGenerator } from '../../../../../helper'
import CustomModal from '../../../../Elements/modal/CustomModal'
import { setRestaurantSplitActionsModal, setRestaurantSplitModal } from '../../../../../redux/action/restaurant'
import Button from '../../../../Elements/buttons/Button'
import colors from '../../../../../style/colors'
import ModalSplit from '../modalSplit'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import { getGiftCouponsPayment, getGiftCouponsSum } from '../../../../../helper/getGiftCouponsPayment'
import { getBusinessScopeDiscountThunk } from '../../../../../redux/thunk/cartDiscount'
import { selectCartDiscountValue, selectProcessedBy } from '../../../../../redux/selector/restaurant'
import SplitsList from './splits'
import { convert2RestaurantCustomer } from '../modalPaymentType/helper'
import { useWindowBreakPoint } from '../../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../../style/theme'
import CartItem from './CartItem'
import { selectCompanyIndex } from '../../../../../redux/selector/system'

const EVENT_Z_INDEX = 37

const ModalSplitBoard = () => {
	const { t } = useTranslation()
	const table = useSelector(state => state.restaurant.table)
	const showSplitModal = useSelector(state => state.restaurant.showSplitModal)
	const splits = useSelector(state => state.tableCart?.splits)
	const orders = useSelector(state => state.tableCart?.orders)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const dispatch = useDispatch()
	const [split, setSplit] = useState({ items: [], totalPrice: 0 })
	const [updateSplitMode, setUpdateSplitMode] = useState(false)
	const [touchPad] = useLocalStorage('touchPad', false)
	const modalLoginUsername = useSelector(state => state.modalLogin.username)

	const hasGiftCouponInSplit = !!split.items?.find(item => item.type === 'GIFT_COUPON')
	const discountValue = useSelector(selectCartDiscountValue(hasGiftCouponInSplit))

	const pinCodeModalLoginIsNeeded = useSelector(state => state.modalLogin.pinCodeModalLoginIsNeeded)
	const hasModalLogin = !!waiterAccessToken
	const itemsTotalWithDiscount = split.items?.reduce((tp, i) => tp + calcCartItemTotalPrice(i, true), 0)
	const totalDiscount = getPercentDiscountInCurrency(discountValue, itemsTotalWithDiscount)
	const processedBy = useSelector(selectProcessedBy)
	const companyIndex = useSelector(selectCompanyIndex)

	useEffect(() => {
		dispatch(getBusinessScopeDiscountThunk('RESTAURANT'))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const closeModal = () => {
		dispatch(setRestaurantSplitModal(false))
		setSplit({ items: [], totalPrice: 0 })
	}

	useEffect(() => {
		if (!modalLoginUsername && pinCodeModalLoginIsNeeded) {
			closeModal()
		}
	}, [modalLoginUsername, pinCodeModalLoginIsNeeded]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showSplitModal) {
			dispatch(fetchTableOrdersThunk(table?.id, true, 'BEING_PREPARED'))
			dispatch(fetchTableSplitsThunk(table?.id))
		}
	}, [table?.id, showSplitModal]) // eslint-disable-line react-hooks/exhaustive-deps

	const changeSplit = (newSplit) => {
		let totalPrice = 0
		if (newSplit.items) {
			totalPrice = newSplit.items.reduce((it, i) => it + calcItemTotalPrice(i), 0)
		}
		newSplit.totalPrice = Math.round((totalPrice + Number.EPSILON) * 100) / 100
		setSplit(newSplit)
	}

	const addItemToSplit = (event, item, orderIndex, itemIndex) => {
		event?.stopPropagation()
		const ordersCopy = [...orders]
		const removedItem = ordersCopy[orderIndex].items.splice(itemIndex, 1)[0]
		dispatch(setTableCartOrders(ordersCopy))
		const splitCopy = { ...split }
		removedItem.orderIndex = orderIndex
		splitCopy.items.push(removedItem)
		changeSplit(splitCopy)
	}

	const removeItemFromSplit = (itemIndex) => {
		const splitCopy = { ...split }
		const removedItem = splitCopy.items.splice(itemIndex, 1)[0]
		changeSplit(splitCopy)
		let ordersCopy = [...orders]
		if (ordersCopy.length > 0) {
			if (split.id) {
				ordersCopy[ordersCopy.length - 1]?.items.push(removedItem)
			} else {
				ordersCopy[removedItem.orderIndex]?.items.push(removedItem)
			}
		} else {
			ordersCopy = [{ id: null, items: [removedItem], status: 'BEING_PREPARED' }]
		}
		dispatch(setTableCartOrders(ordersCopy))
	}

	const clearSplit = useCallback(() => {
		setSplit({ items: [], totalPrice: 0 })
	}, [])

	const createSplit = () => {
		const splitItems = split.items.map(item => ({ orderId: orders[item.orderIndex].id, itemPositionId: item.itemPositionId }))
		dispatch(storeTableSplitThunk(table.id, { items: splitItems, processedBy }, hasModalLogin))
		clearSplit()
	}

	const updateSplit = () => {
		if (split.id) {
			const splitItems = split.items.map(item => ({ orderId: (item.orderIndex >= 0) ? orders[item.orderIndex].id : null, itemPositionId: item.itemPositionId }))
			dispatch(updateTableSplitThunk(split.id, table.id, { items: splitItems, processedBy }, hasModalLogin))
			setUpdateSplitMode(false)
			clearSplit()
		}
	}

	const selectSplit = (split) => {
		setUpdateSplitMode(false)
		setSplit(split)
		dispatch(setRestaurantSplitActionsModal(true))
	}

	const resetSplit = useCallback(() => {
		dispatch(fetchTableSplitsThunk(table?.id))
		clearSplit()
	}, [dispatch, clearSplit, table?.id])

	const handlePaymentType = useCallback(({ paymentId, paymentMethod, totalDiscount, extraDiscountItem, tips = 0, totalPrice, giftCoupons, customer }) => {
		const isEcTerminalPaymentMethod = paymentMethod === 'EC_TERMINAL'
		const paymentWithCoupons = getGiftCouponsPayment(giftCoupons)
		const giftCouponSum = getGiftCouponsSum(giftCoupons)
		const restSum = roundPrice(totalPrice - giftCouponSum)
		const payments = paymentMethod === 'GIFT_COUPON' ? paymentWithCoupons :
			[...paymentWithCoupons, { id: paymentId, method: paymentMethod, total: restSum, payed: false }]
		const extraDiscount = extraDiscountItem.itemId ? extraDiscountItem : null
		const customerData = customer ? convert2RestaurantCustomer(customer) : null
		// eslint-disable-next-line no-console
		console.log(`Close split ${table?.id} on total price ${totalPrice} by ${paymentMethod} companyIndex=${companyIndex} from deviceType: ${navigator.userAgent} and deviceId ${uuidGenerator()}`)
		dispatch(closeTableSplitThunk(split.id, table.id, { paymentId, paymentMethod, totalDiscount, extraDiscount: extraDiscountItem.value, extraDiscountItem: extraDiscount, totalPrice, payments, tips, processedBy, customer: customerData }, hasModalLogin))
		if (!isEcTerminalPaymentMethod) {
			resetSplit()
		}
	}, [split?.id, table?.id, resetSplit, hasModalLogin, dispatch, processedBy, companyIndex])

	const showCreateSplitButton = !split.id && split.items?.length > 0
	const showUpdateSplitButton = split.id && split.items?.length > 0 && updateSplitMode
	const splitNumber = splits?.findIndex(sp => sp.id === split.id) + 1
	const isMobile = useWindowBreakPoint(theme.point720)


	return (
		<>
			<CustomModal isOpen={showSplitModal}
									 close={closeModal}
									 title={'Splitt Tisch #' + (table?.number || '')}
									 titlePrice={getTablePrice(table) || null}
									 size={isMobile ? 'height-scroll' : 'large'}
									 disableBackButton={showUpdateSplitButton}
									 zIndex={EVENT_Z_INDEX}
									 id="split-board-modal"
									 button={
										 <>
											 {showCreateSplitButton &&
												 <Button name="save" icon="checkmark" text="Splitt erstellen" color="green" onClick={createSplit} />}
											 {showUpdateSplitButton && <>
												 <Button key="cancel" name="cancel-change" icon="basket" text="Abbrechen" onClick={clearSplit} className="back warning" />
												 <Button key="update" name="update" icon="checkmark" text="Splitt ändern" color="green" onClick={updateSplit} />
											 </>}
										 </>
									 }>
				<SplitsList selectSplit={selectSplit} split={split} />
				<SplitGrid>
					<TableOrders>
						<CartTableHead className={touchPad ? 'touchPad' : ''}>
							<div>Nr.</div>
							<div>{t('Cart.name')}</div>
							<div>{t('Cart.price')}</div>
							<div>{t('Cart.total')}</div>
						</CartTableHead>
						<TableOrdersProducts>
							{orders?.map((order, orderIndex) =>
								order.items.map((item, itemIndex) =>
									<CartItem key={`${orderIndex}-${itemIndex}`}
														removeAbility={false}
														item={item}
														onClickPlus={event => addItemToSplit(event, item, orderIndex, itemIndex)}
														AddButton={() =>
															<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M1 15L8 7.99746L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
															</svg>} />))}
						</TableOrdersProducts>
					</TableOrders>
					<TableOrders color={(showCreateSplitButton || showUpdateSplitButton) ? colors.green : ''}>
						<TableOrdersHead>{t('restaurant.tables.modal.splitt_artikell')}</TableOrdersHead>
						<TableOrdersProducts className="split">
							{split?.items?.map((item, itemIndex) =>
								<CartItem key={itemIndex}
													removeAbility={false}
													item={item}
													onClickPlus={() => removeItemFromSplit(itemIndex)}
													AddButton={() =>
														<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M8 1L1 8.00254L8 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
														</svg>} />)}
						</TableOrdersProducts>
					</TableOrders>
				</SplitGrid>
			</CustomModal>

			<ModalSplit split={split} splitNumber={splitNumber}
									tableId={table?.id}
									hasModalLogin={hasModalLogin} clearSplit={clearSplit}
									setUpdateSplitMode={setUpdateSplitMode} />
			{showSplitModal &&
				<ModalPaymentType title={`Split #${splitNumber}`}
													totalPrice={split.totalPrice}
													totalDiscount={totalDiscount}
													handlePaymentType={handlePaymentType}
													resetTable={resetSplit} />}

		</>
	)
}

ModalSplitBoard.displayName = 'ModalSplit'
export default ModalSplitBoard
