import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { TableWrapper } from '../../Elements/table/old/index.styled'

export const OrderRestaurantMeta = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	gap: 0.69em;

	.color {
		flex-shrink: 0;
		width: 1.44em;
		height: 1.44em;
		border-radius: 50%;
		background: ${props => props.color};
	}

	.area {
		font-family: ${theme.fontRobotoRegular};
		font-size: 1.25em;
		line-height: 1.15em;
	}

	.table {
		font-family: ${theme.fontRobotoMedium};
		font-size: 1.25em;
		line-height: 1.15em;
	}

	@media screen and (max-width: ${theme.point720}) {
		.color {
			width: 2em;
			height: 2em;
		}

		.area {
			font-size: 1.13em;
		}

		.table {
			font-size: 1.13em;
		}
	}
`
OrderRestaurantMeta.displayName = 'OrderRestaurantMeta'

export const RestaurantOrdersContainer = styled.div`
	@media screen and (max-width: ${theme.point720}) {
		${TableWrapper} {
			max-height: 100%;
		}
	}
`
RestaurantOrdersContainer.displayName = 'RestaurantOrdersContainer'
