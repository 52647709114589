import { APPEND_PRINTERS, SET_NAME_PRINTERS, SET_PRINTER, SET_PRINTERS, SET_PRINTERS_LENGTH, SET_PRINTERS_UPDATE_TRIGGER } from '../../actionTypes/printer'

const initialState = {
  printer: null,
  printers: [],
  printersTotalLength: 0,
  printerNameOptions: [],
  printersUpdateTrigger: 0,
}

export function printerReducer (state = initialState, action) {
  switch (action.type) {
    case SET_PRINTERS:
      return {
        ...state,
        printers: action.value,
      }
    case SET_PRINTER:
      return {
        ...state,
        printer: action.value,
      }
    case SET_PRINTERS_LENGTH:
      return {
        ...state,
        printersTotalLength: action.value,
      }
    case APPEND_PRINTERS:
      return {
        ...state,
        printers: state.printers.concat(...action.value),
      }
    case SET_NAME_PRINTERS:
      return {
        ...state,
        printerNameOptions: action.value,
      }
    case SET_PRINTERS_UPDATE_TRIGGER:
      return {
        ...state,
        printersUpdateTrigger: action.value,
      }
    default:
      return state
  }
}
