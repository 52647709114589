import React, { useMemo } from 'react'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage'
import { CategoryImg, CategoryTitle, CategoryWrap } from './index.styled'
import { getCategoryImage } from '../../../../../../redux/reducer/category/constants'
import CategoryIcon from '../../../../category'
import { getColorsForCart } from '../../../../../../helper/getColorsForCart'

const Category = React.memo(({ item, index, onClick }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const [colorTheme] = useLocalStorage('colorTheme', 'LIGHT')
	const layout = (touchPad === true ? 'touchPad ' : '') + (colorTheme === 'DARK' ? 'dark ' : '')
	const iconUrl = item?.iconUrl
	const categoryIcon = useMemo(() => !iconUrl && getCategoryImage(item.title), [iconUrl, item.title])
	const parentCategory = item?.parent
	const colorCategory = parentCategory ? parentCategory.colorData?.color : item.colorData?.color
	const themeCategory = parentCategory ? parentCategory.colorData?.theme : item.colorData?.theme
	const themeStyles = getColorsForCart(colorCategory, themeCategory, colorTheme, touchPad)

	return (
		<CategoryWrap className={layout}
									tabIndex={10 + index}
									onClick={onClick}
									themeStyles={themeStyles}>
			{(categoryIcon || (iconUrl && iconUrl !== 'NO_ICON')) &&
				<CategoryImg className={layout} data-testid={iconUrl || categoryIcon} themeStyles={themeStyles}>
					<CategoryIcon iconUrl={iconUrl} category={categoryIcon} />
				</CategoryImg>}
			<CategoryTitle className={layout} themeStyles={themeStyles}>{item.title}</CategoryTitle>
		</CategoryWrap>
	)
})

Category.displayName = 'Category'
export default Category
