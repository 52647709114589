import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const TouchPadSearchWrap = styled.div`
  font-size: 1rem;
  margin-top: 0.44em;

  @media screen and (max-width: ${theme.point720}) {
    margin-top: 0;
    grid-column: 1;
    grid-row: 1;

    &.hasSearchText {
      grid-column: 1 / 4;
    }
  }
`

export const SearchInput = styled.div`
  font-size: 1em;
  position: relative;
  transition: all 0.2s;

  input {
    width: 100%;
    padding: 0.5em 1.5em;
    height: 3.7em;
    border-radius: 0.6em;
    border: solid 2px ${colorTheme.input.search.border};
    background-color: ${colorTheme.input.search.background};
    outline: 0;
    color: ${colorTheme.input.search.text};
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.25em;

    &::placeholder {
      color: ${colorTheme.input.search.placeholder};
    }
  }

  .backspace {
    font-size: 1em;
    display: block;
    position: absolute;
    top: calc(50% - 0.63em);
    right: 1.13em;
    width: 1.88em;
    height: 1.25em;
    cursor: pointer;
  }

  .keyboard {
    font-size: 1em;
    display: block;
    position: absolute;
    top: calc(50% - 0.63em);
    cursor: pointer;
    width: 1.44em;
    height: 1.44em;
    right: 1em;
  }

  @media screen and (max-width: ${theme.point820}) {
    font-size: 0.7em;
  }

  @media screen and (max-width: ${theme.point720}) {
    font-size: 1em;

    input {
      padding: 0.5em 1em;
      font-size: 1em;
      height: 3.43em;
      border-radius: 0.75em;
      border-color: ${colorTheme.input.searchModal.border};
      color: ${colorTheme.input.searchModal.text};

      &::placeholder {
        color: ${colorTheme.input.searchModal.placeholder};
      }
    }
  }
`
SearchInput.displayName = 'SearchInput'

export const SearchNumbers = styled.div`
  font-size: 1em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 0.19em;
  margin-top: 0.63em;

  @media screen and (max-width: ${theme.point720}) {
    display: none;
  }
`
SearchNumbers.displayName = 'SearchNumbers'

export const SearchNumber = styled.button`
  background-color: ${colorTheme.keyboard.key.background};
  border: 2px solid ${colorTheme.keyboard.key.border};
  border-radius: 0.33em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colorTheme.keyboard.key.text};
  font-size: 2.25em;
  font-family: ${theme.fontRobotoRegular};
  padding: 0.47em 0;
  user-select: none;
  cursor: pointer;

  @media screen and (max-width: ${theme.point820}) {
    font-size: 1.5em;
  }

  @media screen and (max-width: ${theme.point720}) {
    padding: 0;
  }
`
SearchNumber.displayName = 'SearchNumber'

export const SearchClear = styled(SearchNumber)`
  background-color: ${colorTheme.keyboard.key.clear.background};
`
SearchClear.displayName = 'SearchClear'

export const SearchEnter = styled(SearchNumber)`
  background-color: ${colorTheme.keyboard.key.enter.background};
`
SearchEnter.displayName = 'SearchEnter'
