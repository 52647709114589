import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const SplitList = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.63em;

  @media screen and (max-width: ${theme.point720}) {
    gap: 0.31em;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
		width: calc(100vw - 1.25em);
  }
`
export const SplitListWrap = styled.div`
  @media screen and (max-width: ${theme.point720}) {
    overflow-x: auto;
    width: min-content;
  }
`
SplitListWrap.displayName = 'SplitListWrap'
export const Split = styled.div`
  display: flex;
  align-items: center;
  min-width: 13.75em;
  width: max-content;
  padding: 0.63em 1.25em;
  border: 2px solid ${colorTheme.restaurant.splitBoardModal.split.border};
  border-radius: 0.75em;
  min-height: 4.06em;
  cursor: pointer;

  .image {
    width: 1.25em;
    height: 1.69em;

    > svg {
      width: 100%;
      height: 100%;
      fill: ${colorTheme.restaurant.splitBoardModal.split.icon};
    }
  }

  .name {
    margin-left: 0.55em;
    margin-right: 0.23em;
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.38em;
    line-height: 1.18em;
    color: ${colorTheme.restaurant.splitBoardModal.split.name};
  }

  .price {
    font-family: ${theme.fontRobotoBold};
    font-size: 1.38em;
    line-height: 1.18em;
    color: ${colorTheme.restaurant.splitBoardModal.split.price};
  }

  &.active {
    background: ${colorTheme.restaurant.splitBoardModal.split.active.background};

    .image {
      > svg {
        fill: ${colorTheme.restaurant.splitBoardModal.split.active.icon};
      }
    }

    .name {
      color: ${colorTheme.restaurant.splitBoardModal.split.active.name};
    }

    .price {
      color: ${colorTheme.restaurant.splitBoardModal.split.active.price};
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    min-height: 3.44em;
    padding: 0.5em 0.94em;
    border-width: 1px;
    min-width: auto;
    width: auto;

    .image {
      width: 1.06em;
    }

    .name {
      font-size: 1.25em;
      margin-left: 0.3em;
      margin-right: 0.3em;
    }

    .price {
      font-size: 1.25em;
    }
  }
`
Split.displayName = 'Split'

export const TableOrders = styled.div`
  padding-top: 0.63rem;
  border: 2px solid ${props => props.color || colorTheme.restaurant.splitBoardModal.table.border};
  border-radius: 0.75rem;

  @media screen and (max-width: ${theme.point720}) {
    padding: 0;
    height: auto;
    border-width: 1px;
  }
`
TableOrders.displayName = 'TableOrders'

export const TableOrdersHead = styled.div`
  font-family: ${theme.fontRobotoRegular};
  line-height: 1.18em;
  text-align: left;
  display: grid;
  padding: 0 1em 0.63em 0.63em;
  border-bottom: 1px solid #989da5;
  font-size: 1.13em;
  font-family: ${theme.fontRobotoMedium};
  color: ${colorTheme.restaurant.splitBoardModal.table.header};

  @media screen and (max-width: ${theme.point720}) {
    padding: 0.63em 1em 0.63em 0.63em;
  }
`

export const TableOrdersProducts = styled.div`
  overflow-y: auto;
  height: 40vh;
  padding: 0.63rem;
  filter: drop-shadow(0px 4px 150px rgba(0, 0, 0, 0.41));
  margin-bottom: 0.63rem;
  margin-right: 0.19rem;

  > :first-child {
    border-top: 1px solid ${colorTheme.restaurant.splitBoardModal.table.borderProducts};
  }

  button > svg {
    fill: none;
  }

  @media screen and (max-width: ${theme.point820}) {
    height: 25vh;
  }

  @media screen and (max-width: ${theme.point720}) {
    height: auto;
    overflow-y: visible;
    margin: 0;
    padding: 0;
    padding-bottom: 1em;
    border-radius: 0 0 0.75rem 0.75rem;

    &.split {
      min-height: 4em;
    }
  }
`
TableOrdersProducts.displayName = 'TableOrdersProducts'

export const SplitGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.69rem;
  margin-top: 1.88rem;

  @media screen and (max-width: ${theme.point820}) {
    margin-top: 1.25rem;
    gap: 0.63rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

	@media screen and (max-width: ${theme.point720}) {
		height: 100%;
		max-height: 100%;
		margin-bottom: 4.75em;
	}
`
export const CartTableHead = styled.div`
	display: grid;
	list-style: none;
	padding: 0 1em 0.63em 0.63em;
	grid-auto-columns: 1fr;
	grid-auto-rows: 1fr;
	grid-template-columns: 3.75em 3.5fr 3.75em 1fr 1fr;
	gap: 0.25em;

	> div {
		font-size: 1.13em;
		font-weight: 500;
		font-family: ${theme.fontRobotoMedium};
		color: ${colorTheme.cart.textCartTableHead};
	}

	&.touchPad {
		grid-template-columns: 3.75em 3.5fr 3.75em 1fr 1fr;

		> div {
			font-size: 1.25em;
		}
	}

	&.dishCourse {
		grid-template-columns: 3.75em 3.75em 3.5fr 3.75em 1fr 1fr;
		padding: 0 1em 0.63em 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.touchPad {
			grid-template-columns: 1.63em 3fr 1fr 1fr 4.31em;
			gap: 0.5em;

			> div {
				font-size: 1.1em;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.touchPad {
			padding: 0.63em 1em 0.63em 0.63em;
			grid-template-columns: 1em 3fr 1fr 1fr 2.5em;

			> div {
				font-size: 1.13em;
			}
		}

	}
`
