import React from 'react'
import MainIcon from '../../../../../img/svg/main'
import { TextBig } from '../../OrdersTable/index.styled'
import { useTranslation } from 'react-i18next'
import { TableTdGrid } from '../../style/table/td/index.styled'

const InvoiceStatus = React.memo(({ item }) => {
	const { t } = useTranslation()
	const text = item?.markAsPaid ? t('invoices.table.paidInvoice') : t('invoices.table.unpaidInvoice')
	return (
		<>
			<TableTdGrid className={item?.markAsPaid ? 'green' : 'orange'}>
				<MainIcon icon={item?.markAsPaid ? 'paidInvoice' : 'unpaidInvoice'} />
				<TextBig>{text}</TextBig>
			</TableTdGrid>
		</>
	)
})

InvoiceStatus.displayName = 'InvoiceStatus'
export default InvoiceStatus
