import { orderFilter, preOrderFilter, restaurantOrderFilter } from '../../helper/ordersFilter'
import { addNewOrder, addNewPreOrderDays, addNewPreOrderToday, addNewUnpaidOrder, removeOrder, removePreOrderDays, removePreOrderToday, removeUnpaidOrder, setOrdersTotalLength, setPreOrdersDaysTotalLength, setPreOrdersTodayTotalLength, setUnpaidOrdersTotalLength, updateOrder, updatePreOrderDays, updatePreOrderToday, updateUnpaidOrder } from '../../redux/action/order'
import { setAppModalData } from '../../redux/action/system'
import { removeOrderFromProcessingTimeModalDataThunk } from '../../redux/thunk/order/addOrderProcessingTime'
import { removeOrderFromConfirmPreOrderModalDataThunk } from '../../redux/thunk/order/confirmPreOrder'
import { appendOrdersCounterOrder, appendUnpaidOrdersCounterOrder, removeOrdersCounterOrder, removeUnpaidOrdersCounterOrder } from '../../redux/action/ordersCounter'
import { selectHasAnyGroup } from '../../redux/selector/system'
import { removeOrderFromPlatformDriversOrderModalDataThunk } from '../../redux/thunk/order/addPlatformDriverOrderProcessingTime'
import { removeOrderFromConfirmExpressOrderModalDataThunk } from '../../redux/thunk/order/confirmExpressOrder'
import { addMarkerToMapThunk, updateMarkerToMapThunk } from '../../redux/thunk/map'
import { addNewRestaurantOrder, removeRestaurantOrder, setRestaurantOrdersTotal, updateRestaurantOrder } from '../../redux/action/restaurant'

export const processOrderViewMessageThunk = (message) => (dispatch, getState) => {
	switch (message?.type) {
		case 'order-view.ORDER_PLACED_EVENT':
			addOrderToTable(message.data, message.type, dispatch, getState)
			dispatch(addMarkerToMapThunk(message.data))
			showProcessingTimeModal(message.data, dispatch, getState)
			showConfirmPreOrderModal(message.data, dispatch, getState)
			showPlatformDriversOrderModal(message.data, dispatch, getState)
			showConfirmExpressOrderModal(message.data, dispatch, getState)
			dispatch(handleOrderForOrdersCounterThunk(message.data))
			addUnpaidOrderToTable(message.data, message.type, dispatch, getState)
			dispatch(handleOrderForUnpaidOrdersCounterThunk(message.data))
			addPreOrderTodayToTable(message.data, message.type, dispatch, getState)
			addPreOrderOtherDaysToTable(message.data, message.type, dispatch, getState)
			break
		case 'order-view.ORDER_PARTNER_DELIVERY_ESTIMATED_DATA_ADDED_EVENT':
		case 'order-view.ORDER_PARTNER_DELIVERY_CONFIRMED_EVENT':
			updatePartnerDeliveryOrderThunk(message.data, message.type, dispatch, getState)
			break
		default:
			dispatch(updateOrderToTableThunk(message.data, message.type))
			dispatch(updateMarkerToMapThunk(message.data))
			dispatch(closeProcessingTimeModalThunk(message.data))
			dispatch(closeConfirmPreOrderModalThunk(message.data))
			dispatch(closePlatformDriversOrderModalThunk(message.data))
			dispatch(closeConfirmExpressOrderModalThunk(message.data))
			dispatch(handleOrderForOrdersCounterThunk(message.data))
			dispatch(updateUnpaidOrderToTableThunk(message.data, message.type))
			dispatch(handleOrderForUnpaidOrdersCounterThunk(message.data))
			dispatch(updatePreOrderTodayToTableThunk(message.data, message.type))
			dispatch(updatePreOrderOtherDaysToTableThunk(message.data, message.type))
			dispatch(updateOrderToRestaurantTableThunk(message.data, message.type))
			break
	}
}

const addOrderToTable = (data, messageType, dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received order save orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const orders = getState().order.orders
	const existOrder = orders.find(order => order.orderId === data.orderId)
	// check if order was already added to the table by http requests
	if (!existOrder) {
		const orderFilterTrue = orderFilter(data, getState().orderfilter)
		if (orderFilterTrue) {
			dispatch(addNewOrder({ order: data, reverse: getState().orderfilter.reverse }))
			dispatch(setOrdersTotalLength(getState().order.ordersTotalLength + 1))
		}
	}
}

export const updateOrderToTableThunk = (data, messageType) => (dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received order update orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const orders = getState().order.orders
	if (!orders) return
	const existOrder = orders.find(order => order.orderId === data.orderId)

	const orderFilterTrue = orderFilter(data, getState().orderfilter)
	if (orderFilterTrue) {
		if (existOrder) {
			dispatch(updateOrder({ order: data, reverse: getState().orderfilter.reverse }))
		} else {
			dispatch(addNewOrder({ order: data, reverse: getState().orderfilter.reverse }))
			dispatch(setOrdersTotalLength(getState().order.ordersTotalLength + 1))
		}
	} else {
		setTimeout(() => {
			dispatch(removeOrder(data))
		}, 500)
	}
}

export const updateOrderToRestaurantTableThunk = (data, messageType) => (dispatch, getState) => {
	const showRestaurantOrdersModal = getState().restaurant.ordersChangeModal
	if (showRestaurantOrdersModal) {
		// eslint-disable-next-line
		console.log(`Received restaurant order update orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
		const orders = getState().restaurant.orders
		if (!orders) return
		const existOrder = orders.find(order => order.orderId === data.orderId)
		const orderFilterTrue = restaurantOrderFilter(data)
		if (orderFilterTrue) {
			if (existOrder) {
				dispatch(updateRestaurantOrder({ order: data }))
			} else {
				dispatch(addNewRestaurantOrder({ order: data }))
				dispatch(setRestaurantOrdersTotal(getState().restaurant.ordersTotal + 1))
			}
		} else {
			setTimeout(() => {
				dispatch(removeRestaurantOrder(data))
			}, 500)
		}
	}
}

const addPreOrderTodayToTable = (data, messageType, dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received preOrder save orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const preOrdersToday = getState().order.preOrdersToday
	if (!preOrdersToday) return
	const existOrder = preOrdersToday?.find(preOrder => preOrder.orderId === data.orderId)
	// check if order was already added to the table by http requests
	if (!existOrder) {
		const orderFilterTrue = preOrderFilter(data, getState().orderfilter, 'TODAY')
		if (orderFilterTrue) {
			dispatch(addNewPreOrderToday({ order: data, reverse: getState().orderfilter.reversePreOrder }))
			dispatch(setPreOrdersTodayTotalLength(getState().order.preOrdersTodayTotalLength + 1))
		}
	}
}

export const updatePreOrderTodayToTableThunk = (data, messageType) => (dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received preOrder update orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const preOrdersToday = getState().order.preOrdersToday
	if (!preOrdersToday) return
	const existOrder = preOrdersToday?.find(order => order.orderId === data.orderId)

	const orderFilterTrue = preOrderFilter(data, getState().orderfilter, 'TODAY')
	if (orderFilterTrue) {
		if (existOrder) {
			dispatch(updatePreOrderToday({ order: data, reverse: getState().orderfilter.reversePreOrder }))
		} else {
			dispatch(addNewPreOrderToday({ order: data, reverse: getState().orderfilter.reversePreOrder }))
			dispatch(setPreOrdersTodayTotalLength(getState().order.preOrdersTodayTotalLength + 1))
		}
	} else {
		setTimeout(() => {
			dispatch(removePreOrderToday(data))
		}, 500)
	}
}

const addPreOrderOtherDaysToTable = (data, messageType, dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received preOrder other days save orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const preOrdersDays = getState().order.preOrdersDays
	if (!preOrdersDays) return
	const existOrder = preOrdersDays?.find(preOrder => preOrder.orderId === data.orderId)
	// check if order was already added to the table by http requests
	if (!existOrder) {
		const orderFilterTrue = preOrderFilter(data, getState().orderfilter, 'OTHER_DAYS')
		if (orderFilterTrue) {
			dispatch(addNewPreOrderDays({ order: data, reverse: getState().orderfilter.reversePreOrderDays }))
			dispatch(setPreOrdersDaysTotalLength(getState().order.preOrdersDaysTotalLength + 1))
		}
	}
}

export const updatePreOrderOtherDaysToTableThunk = (data, messageType) => (dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received preOrder other days update orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const preOrdersDays = getState().order.preOrdersDays
	if (!preOrdersDays) return
	const existOrder = preOrdersDays?.find(order => order.orderId === data.orderId)

	const orderFilterTrue = preOrderFilter(data, getState().orderfilter, 'OTHER_DAYS')
	if (orderFilterTrue) {
		if (existOrder) {
			dispatch(updatePreOrderDays({ order: data, reverse: getState().orderfilter.reversePreOrderDays }))
		} else {
			dispatch(addNewPreOrderDays({ order: data, reverse: getState().orderfilter.reversePreOrderDays }))
			dispatch(setPreOrdersDaysTotalLength(getState().order.preOrdersDaysTotalLength + 1))
		}
	} else {
		setTimeout(() => {
			dispatch(removePreOrderDays(data))
		}, 500)
	}
}

const addUnpaidOrderToTable = (data, messageType, dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received order save orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const orders = getState().order.unpaidOrders
	const existOrder = orders?.find(order => order.orderId === data.orderId)
	// check if order was already added to the table by http requests
	if (!existOrder) {
		const orderFilterTrue = orderFilter(data, getState().orderfilter)
		if (orderFilterTrue) {
			dispatch(addNewUnpaidOrder({ order: data, reverse: getState().orderfilter.reverse }))
			dispatch(setUnpaidOrdersTotalLength(getState().order.unpaidOrdersTotalLength + 1))
		}
	}
}

export const updateUnpaidOrderToTableThunk = (data, messageType) => (dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received order update orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const orders = getState().order.unpaidOrders
	if (!orders) return
	const existOrder = orders.find((order) => order.orderId === data.orderId)
	const orderFilterTrue = orderFilter(data, getState().orderfilter)

	if (orderFilterTrue) {
		if (existOrder) {
			dispatch(updateUnpaidOrder({ order: data, reverse: getState().orderfilter.reverse }))
		} else {
			dispatch(addNewUnpaidOrder({ order: data, reverse: getState().orderfilter.reverse }))
			dispatch(setUnpaidOrdersTotalLength(getState().order.unpaidOrdersTotalLength + 1))
		}
	} else {
		if (existOrder) {
			setTimeout(() => {
				dispatch(removeUnpaidOrder(data))
			}, 500)
		}
	}
}

export const handleOrderForUnpaidOrdersCounterThunk = data => (dispatch, getState) => {
	const orders = getState().ordersCounter?.unpaidOrders
	if (!orders) return
	const existOrder = orders.find(order => order === data.orderId)
	const orderFilterTrue = orderFilter(data, { ...getState().orderfilter, withOnlyRegisteredPreOrders: null, isPaid: false, processedByUsernameWithEmpty: null })

	if (!existOrder) {
		if (orderFilterTrue) {
			dispatch(appendUnpaidOrdersCounterOrder(data.orderId))
		}
	} else {
		if (!orderFilterTrue) {
			dispatch(removeUnpaidOrdersCounterOrder(data.orderId))
		}
	}
}

const showProcessingTimeModal = (data, dispatch, getState) => {
	const state = getState()
	const hasAdminGroup = selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN', 'CALL_AGENT'])(state)
	const isNotPreOrder = !data.preOrder
	const isNotAccepted = 'NOT_ACCEPTED' === data.status.status
	const isNotExpressOrder = !data.expressDelivery && !data?.expectedPickupTime
	const isNotPlatformDriverOrder = !(data.orderType === 'DELIVERY' && data.deliveryMetaData?.partner?.type)
	if (data.platformMetaData && isNotPreOrder && hasAdminGroup && isNotAccepted && isNotExpressOrder && isNotPlatformDriverOrder) {
		const modalData = state.system.modalData?.processingTimeOrderModalData
		let processingTimeOrderModalData = [...modalData]
		processingTimeOrderModalData = processingTimeOrderModalData.filter(o => o.orderId !== data.orderId)
		processingTimeOrderModalData.push(data)
		dispatch(setAppModalData({ processingTimeOrderModalData }))
	}
}

export const closeProcessingTimeModalThunk = data => (dispatch, getState) => {
	const processingTimeOrder = getState().system.modalData?.processingTimeOrderModalData.find(o => o.orderId === data.orderId)
	const isAccepted = 'NOT_ACCEPTED' !== data.status.status
	const isClosed = data.zNumber

	if (processingTimeOrder && (isAccepted || isClosed)) {
		dispatch(removeOrderFromProcessingTimeModalDataThunk(data.orderId))
	}
}

const showConfirmPreOrderModal = (data, dispatch, getState) => {
	const state = getState()
	const hasAdminGroup = selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN', 'CALL_AGENT'])(state)
	const isNotAccepted = 'NOT_ACCEPTED' === data.status.status
	const isPreOrder = data.preOrder
	const isDeliveryByPlatform = data.orderType === 'DELIVERY_BY_PLATFORM'
	const updatedDeliveryByPlatform = !!(data.orderType === 'DELIVERY' && data.deliveryMetaData?.partner?.type)
	const isNotPlatformDriverOrder = !(isDeliveryByPlatform || updatedDeliveryByPlatform)

	if (data.platformMetaData && isPreOrder && isNotPlatformDriverOrder && hasAdminGroup && isNotAccepted) {
		const modalData = state.system.modalData?.confirmPreOrderModalData
		let confirmPreOrderModalData = [...modalData]
		confirmPreOrderModalData = confirmPreOrderModalData.filter(o => o.orderId !== data.orderId)
		confirmPreOrderModalData.push(data)
		dispatch(setAppModalData({ confirmPreOrderModalData }))
	}
}

export const closeConfirmPreOrderModalThunk = data => (dispatch, getState) => {
	const confirmPreOrder = getState().system.modalData?.confirmPreOrderModalData.find(o => o.orderId === data.orderId)
	const isAccepted = 'NOT_ACCEPTED' !== data.status.status
	const isClosed = data.zNumber
	if (confirmPreOrder && (isAccepted || isClosed)) {
		dispatch(removeOrderFromConfirmPreOrderModalDataThunk(data.orderId))
	}
}

const showPlatformDriversOrderModal = (data, dispatch, getState) => {
	const state = getState()
	const hasAdminGroup = selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN', 'CALL_AGENT'])(state)
	const isNotAccepted = 'NOT_ACCEPTED' === data.status.status
	const isDeliveryByPlatform = data.orderType === 'DELIVERY_BY_PLATFORM'
	const updatedDeliveryByPlatform = !!(data.orderType === 'DELIVERY' && data.deliveryMetaData?.partner?.type)
	const isPlatformDriverOrder = isDeliveryByPlatform || updatedDeliveryByPlatform
	if (data.platformMetaData && isPlatformDriverOrder && hasAdminGroup && isNotAccepted) {
		const modalData = state.system.modalData?.platformDriversOrderModalData
		let platformDriversOrderModalData = [...modalData]
		platformDriversOrderModalData = platformDriversOrderModalData.filter(o => o.orderId !== data.orderId)
		platformDriversOrderModalData.push(data)
		dispatch(setAppModalData({ platformDriversOrderModalData }))
	}
}

export const closePlatformDriversOrderModalThunk = data => (dispatch, getState) => {
	const platformDriversOrder = getState().system.modalData?.platformDriversOrderModalData.find(o => o.orderId === data.orderId)
	const isAccepted = 'NOT_ACCEPTED' !== data.status.status
	const isClosed = data.zNumber

	if (platformDriversOrder && (isAccepted || isClosed)) {
		dispatch(removeOrderFromPlatformDriversOrderModalDataThunk(data.orderId))
	}
}

export const handleOrderForOrdersCounterThunk = data => (dispatch, getState) => {
	const existOrder = getState().ordersCounter?.orders.find(order => order === data.orderId)
	const orderFilterTrue = data.status?.status === 'NOT_ACCEPTED' && !data.zNumber
	if (!existOrder) {
		if (orderFilterTrue) {
			dispatch(appendOrdersCounterOrder(data.orderId))
		}
	} else {
		if (!orderFilterTrue) {
			dispatch(removeOrdersCounterOrder(data.orderId))
		}
	}
}

const showConfirmExpressOrderModal = (data, dispatch, getState) => {
	const state = getState()
	const hasAdminGroup = selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN', 'CALL_AGENT'])(state)
	const isNotAccepted = 'NOT_ACCEPTED' === data.status.status
	const isExpressOrder = data.expressDelivery || data?.expectedPickupTime
	if (data.platformMetaData && isExpressOrder && hasAdminGroup && isNotAccepted) {
		const modalData = state.system.modalData?.confirmExpressOrderModalData
		let confirmExpressOrderModalData = [...modalData]
		confirmExpressOrderModalData = confirmExpressOrderModalData.filter(o => o.orderId !== data.orderId)
		confirmExpressOrderModalData.push(data)
		dispatch(setAppModalData({ confirmExpressOrderModalData }))
	}
}

export const closeConfirmExpressOrderModalThunk = data => (dispatch, getState) => {
	const confirmExpressOrder = getState().system.modalData?.confirmExpressOrderModalData?.find(o => o.orderId === data.orderId)
	const isAccepted = 'NOT_ACCEPTED' !== data.status.status
	const isClosed = data.zNumber
	if (confirmExpressOrder && (isAccepted || isClosed)) {
		dispatch(removeOrderFromConfirmExpressOrderModalDataThunk(data.orderId))
	}
}

export const updatePartnerDeliveryOrderThunk = (data, messageType, dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received partner delivery data for order orderId=${data.orderId} status=${data.status?.status} event=${messageType}`)
	const isDelivery = data.orderType === 'DELIVERY'
	const orders = getState().order.orders
	const existOrder = orders.find(order => order.orderId === data.orderId)
	const preOrdersToday = getState().order.preOrdersToday
	const existPreorderOrder = preOrdersToday?.find(order => order.orderId === data.orderId)
	const orderFilterTrue = orderFilter(data, getState().orderfilter)
	const isPreOrderFilterTrue = preOrderFilter(data, getState().orderfilter, 'TODAY')

	if (isDelivery) {
		if (orderFilterTrue && existOrder) {
			dispatch(updateOrder({ order: data, reverse: getState().orderfilter.reverse }))
		}

		if (isPreOrderFilterTrue && existPreorderOrder) {
			dispatch(updatePreOrderToday({ order: data, reverse: getState().orderfilter.reversePreOrder }))
		}
	}
}
