import { getLocalStorageAccessToken } from '../../helper/clientMonitor'

const requestInterceptor = config => {
	const accessToken = getLocalStorageAccessToken()

	if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
    config.headers['X-Auth-Token'] = `Bearer ${accessToken}`
  }
  return config
}

export default requestInterceptor
