import React from 'react'
import Currency from '../../../../../Elements/currency'
import { Table, TableIcon, TablePrice } from '../../index.styled'
import { getTablePrice, tableIsBusy } from '../../../../../../helper'
import { swapTableThunk, swapTableMoveThunk } from '../../../../../../redux/thunk/restaurant/table'
import { setRestaurantSwapModal, setRestaurantOrdersMove } from '../../../../../../redux/action/restaurant'
import { useDispatch, useSelector } from 'react-redux'
import RestaurantIcon from '../../../../../../img/svg/restaurant'

const SwapTable = ({ index, table }) => {
	const { table: selectedTable, move } = useSelector(state => state.restaurant)
	const dispatch = useDispatch()

	const selectTable = (table) => {
		if (move && move.length){
			dispatch(swapTableMoveThunk(selectedTable.id, { items: move, table: { id: table.id } }))
		}
		else if (move !== null)
			dispatch(swapTableThunk(selectedTable.id, { tableId: table.id }))
		dispatch(setRestaurantSwapModal(false))
		dispatch(setRestaurantOrdersMove([]))
	}

	const layout = 'swap ' + (tableIsBusy(table) ? 'busy' : '') + (selectedTable?.id === table.id ? ' selected' : '')
	const image = selectedTable?.id === table.id ?
		(tableIsBusy(table) ? 'table-closed-white' : 'table-open-white') :
		(tableIsBusy(table) ? 'table-closed' : 'table-open-green')

	return (
		<Table key={index} data-testid={`swap_table_${index}`} onClick={() => selectTable(table)} className={layout}>
			<TableIcon className={layout}>
				<div className="number">{table.number}</div>
				<RestaurantIcon icon={image}/>
			</TableIcon>
			{tableIsBusy(table) &&
				<TablePrice className={(selectedTable?.id === table.id ? ' selected' : '')}>
					<Currency>{getTablePrice(table)}</Currency>
				</TablePrice>}
		</Table>
	)
}

SwapTable.displayName = 'SwapTable'
export default SwapTable
