import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { reInitializeOrderFilter } from '../../../../redux/action/orderfilter'
import CustomTable from '../index'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import TableTr from '../tr'
import { OrderTableWrapper } from '../OrdersTable/index.styled'
import InvoicesTableTdList from './InvoicesTableTdList'
import { getInvoicesThunk, getInvoiceThunk } from '../../../../redux/thunk/invoice'
import KeyBindings from '../../keybindings'
import { setShowInvoiceOptionsModal } from '../../../../redux/action/invoice'
import InvoiceTableModals from './modal'

const EVENT_Z_INDEX = 0
const PER_LOAD = 10

const InvoicesTable = React.memo(({ id }) => {
	const { t } = useTranslation()
	const [activeRow, setActiveRow] = useState(0)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const invoices = useSelector(store => store.invoice.invoices)
	const invoicesTotalLength = useSelector(store => store.invoice.invoicesTotalLength)
	const selectedInvoice = useSelector(store => store.invoice.selectedInvoice)
	const dispatch = useDispatch()

	const tableHeadersStatic = useMemo(() => {
		return [
			{ title: t('invoices.table.number') },
			{ title: t('invoices.table.time') },
			{ title: t('invoices.table.amount') },
			{ title: t('invoices.table.company') },
			{ title: t('invoices.table.mail') },
			{ title: t('invoices.table.status') },
		]
	}, [t])

	useEffect(() => {
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		return () => dispatch(reInitializeOrderFilter())
	}, []) //eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setActiveRow(0)
	}, [])

	const onLoad = useCallback((page, size) => {
		dispatch(getInvoicesThunk(page, size))
	}, [dispatch])

	const selectInvoice = useCallback(async (invoice) => {
		if (!selectedInvoice) {
			await dispatch(getInvoiceThunk(invoice.id))
			dispatch(setShowInvoiceOptionsModal(true))
		}
	}, [selectedInvoice, dispatch])

	const handleClick = useCallback((item, i) => {
		setActiveRow(i)
		selectInvoice(item)
	}, [selectInvoice])

	const handleArrowUp = useCallback(() => {
		if (activeRow < 0) {
			setActiveRow(invoices.length)
		} else {
			setActiveRow(activeRow - 1)
		}
	}, [activeRow, invoices?.length])

	const handleArrowDown = useCallback(() => {
		if (activeRow < invoices?.length) {
			setActiveRow(activeRow + 1)
		} else {
			setActiveRow(0)
		}
	}, [activeRow, invoices?.length])


	return (
		<>
			<OrderTableWrapper id={id}>
				<CustomTable headers={tableHeadersStatic}
										 onLoad={onLoad}
										 currentLength={invoices?.length}
										 totalLength={invoicesTotalLength}
										 perLoad={PER_LOAD}>
					{invoices?.map((item, i) =>
						<TableTr key={i}
										 onClick={() => handleClick(item, i)}
										 isActive={i === activeRow}
										 name={item?.invoiceNumber}
										 className="row-mob-cols-4 bold-border">
							<InvoicesTableTdList tableHeadersStatic={tableHeadersStatic} item={item} />
						</TableTr>)}
				</CustomTable>

				{isNeededZIndex &&
					<KeyBindings arrowUp={handleArrowUp}
											 arrowDown={handleArrowDown} />}
			</OrderTableWrapper>

			<InvoiceTableModals />
		</>
	)
})

InvoicesTable.displayName = 'InvoicesTable'
export default InvoicesTable
