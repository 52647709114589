import { batch } from 'react-redux'
import { addCashBookNewTransaction, setCashBookNewCashBooksTotalLength, updateCashBookNewTransaction } from '../../redux/action/cashbookNew'

export const processCashBookMessageThunk = (message) => (dispatch, getState) => {
	switch (message?.type) {
		case 'cashbook.CASH_BOOK_RECORD_CREATED_EVENT':
			batch(() => {
				addTransactionToTable(message.data, dispatch, getState)
			})
			break
		case 'cashbook.CASH_BOOK_RECORD_CANCELED_EVENT':
			batch(() => {
				updateTransactionToTable(message.data, dispatch, getState)
			})
			break
		default:
			// eslint-disable-next-line
			console.error('Unknown cashbook message type: ', JSON.stringify(message))
			break
	}
}

const addTransactionToTable = (data, dispatch, getState) => {
	const transactions = getState().cashbookNew.cashBooks
	const existTransaction = transactions.find(transaction => transaction.id === data.id)
	// check if order was already added to the table by http requests
	if (!existTransaction) {
		dispatch(addCashBookNewTransaction(data))
		dispatch(setCashBookNewCashBooksTotalLength(getState().cashbookNew.cashBooksTotalLength + 1))
	}
}

const updateTransactionToTable = (data, dispatch, getState) => {
	// eslint-disable-next-line
	console.log(`Received cashbook record update cashBookId=${data.id}`)
	const transactions = getState().cashbookNew.cashBooks
	if (!transactions) return
	const existTransaction = transactions.find(transaction => transaction.id === data.id)

	if (!existTransaction) {
		dispatch(addCashBookNewTransaction(data))
		dispatch(setCashBookNewCashBooksTotalLength(getState().cashbookNew.cashBooksTotalLength + 1))
	} else {
		dispatch(updateCashBookNewTransaction(data))
	}
}
