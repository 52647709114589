import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const CartLogoWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2.5em;
	bottom: 1.44em;
	right: 50%;
	transform: translateX(50%);
	z-index: 1;

	&.custom {
		position: static;
		margin-bottom: 1.44em;
		right: 0;
		transform: none;
	}
`
CartLogoWrapper.displayName = 'CartLogoWrapper'

export const CartLogoTitleWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.88em 3.75em;
	border-radius: 1.25em;
	background: ${colorTheme.clientMonitorCart.logo.background};
	min-height: 9.94em;
`
CartLogoTitleWrapper.displayName = 'CartLogoTitleWrapper'

export const CartLogoTitle = styled.div`
	color: ${colorTheme.clientMonitorCart.logo.color};
	text-align: center;
	font-family: ${theme.fontBerkshireSwash};
	font-size: 5em;
	font-weight: 400;
`
CartLogoTitle.displayName = 'CartLogoTitle'

export const CartLogoSubTitleWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.31em 1.88em;
	border-radius: 1.25em;
	background: ${colorTheme.clientMonitorCart.logo.background};
	min-height: 3em;
`
CartLogoSubTitleWrapper.displayName = 'CartLogoSubTitleWrapper'

export const CartLogoSubtitle = styled.div`
	color: ${colorTheme.clientMonitorCart.logo.color};
	text-align: center;
	font-family: ${theme.fontRoboto};
	font-size: 1.5em;
	font-weight: 400;
`
CartLogoSubtitle.displayName = 'CartLogoSubtitle'
