import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Product = lazyWithRetry(() => import(/* webpackChunkName: "product" */'../../component/Product'))

const ProductPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Product />
		</Suspense>
	)
})

ProductPage.displayName = 'ProductPage'
export default ProductPage
