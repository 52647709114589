import styled from 'styled-components'
import colors from './style/colors'
import { theme } from './style/theme'
import { colorTheme } from './style/theme/colorTheme'

export const AppWrapper = styled.div`
`
AppWrapper.displayName = 'AppWrapper'

export const Section = styled.section`
	height: 100vh;
	position: relative;
	max-height: -webkit-fill-available;
	background: ${colorTheme.section.background};
	display: grid;
	grid-template-columns: min-content auto;
	grid-template-rows: 5.13em auto;
	transition: all 0.2s;

	&.toggled {
		grid-template-columns: min-content auto;
	}

	&.authWrapper {
		min-height: 100vh;
		height: 100%;
		grid-template-columns: auto 62.5em auto;
		grid-template-rows: 10.625em auto;
	}

	&.versionUpdater {
		height: calc(100vh - 2.25em);

		&.authWrapper {
			min-height: calc(100vh - 2.25em);
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		grid-template-rows: min-content auto;
		grid-template-columns: 1fr;

		&.toggled {
			grid-template-columns: 1fr;
		}

		&.authWrapper {
			grid-template-columns: 1fr;
			grid-template-rows: 14.29em 1fr;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		overflow-y: auto;

		&.authWrapper {
			grid-template-rows: 6.25em 1fr;
		}
	}
`
Section.displayName = 'Section'

export const ToastWrapper = styled.div`
	font-size: 1rem;

	.Toastify {

		.Toastify__toast-container {
			width: 20em;
		}

		.Toastify__toast {
			padding: 0.8em 2em 0.8em 0.8em;
			border-radius: 0.75em;
			margin-bottom: .5em;
			min-height: 4em;
			background: ${colors.white};
			box-shadow: 0 1px 10px 0 rgb(0 0 0 / 30%), 0 2px 15px 0 rgb(0 0 0 / 5%);
		}

		.Toastify__toast--success {
			border: 1px solid ${colors.green};

			.Toastify__toast-icon svg {
				fill: ${colors.green};
			}

			.Toastify__progress-bar {
				background: ${colors.green};
			}

			.Toastify__toast-body {
				color: ${colors.green};
			}

			.Toastify__close-button--light {
				opacity: 1;
			}

			.Toastify__close-button svg {
				fill: ${colors.green};
			}
		}

		.Toastify__toast--error {
			border: 1px solid ${colors.red};

			.Toastify__toast-icon svg {
				fill: ${colors.red};
			}

			.Toastify__progress-bar {
				background: ${colors.red};
			}

			.Toastify__toast-body {
				color: ${colors.red};
			}

			.Toastify__close-button--light {
				opacity: 1;
			}

			.Toastify__close-button svg {
				fill: ${colors.red};
			}
		}

		.Toastify__toast--warning {
			border: 1px solid ${colors.orange};

			.Toastify__toast-icon svg {
				fill: ${colors.orange};
			}

			.Toastify__progress-bar {
				background: ${colors.orange};
			}

			.Toastify__toast-body {
				color: ${colors.orange};
			}

			.Toastify__close-button--light {
				opacity: 1;
			}

			.Toastify__close-button svg {
				fill: ${colors.orange};
			}
		}

		.Toastify__toast--info {
			border: 1px solid ${colors.blue};

			.Toastify__toast-icon svg {
				fill: ${colors.blue};
			}

			.Toastify__progress-bar {
				background: ${colors.blue};
			}

			.Toastify__toast-body {
				color: ${colors.blue};
			}

			.Toastify__close-button--light {
				opacity: 1;
			}

			.Toastify__close-button svg {
				fill: ${colors.blue};
			}
		}

		.Toastify__toast-body {
			font-size: 1em;
		}

		.Toastify__close-button {
			width: 1em;
			height: 1em;
			position: absolute;
			top: 0.5em;
			right: 0.5em;

			> svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		.Toastify {
			.Toastify__toast-container--top-right {
				top: 0.63em;
				right: 0.63em;
				left: auto;
				padding: 0;
			}

			.Toastify__toast-container {
				width: 11em;
			}

			.Toastify__toast {
				padding: 0.63em 1.2em 0.63em 0.63em;
				border-radius: 0.75em;
				margin-bottom: .3em;
				min-height: 2.8em;
			}

			.Toastify__toast-body {
				font-size: 0.8em;
				word-break: break-all;
			}

			.Toastify__close-button {
				top: 0.4em;
				right: 0.4em;
			}
		}
	}
`
ToastWrapper.displayName = 'ToastWrapper'
