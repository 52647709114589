import orderViewInstance from './instance'

const getOrders = ({
	dateFrom,
	dateTo,
	paymentMethod,
	status,
	excludedStatus,
	orderType,
	deliveryPartnerType,
	processedByUsername,
	processedByUsernameWithEmpty,
	reverseSort,
	closed,
	isPreOrder,
	withOnlyRegisteredPreOrders,
	isPaid,
	page = 0, size = 10,
}) => {
	return orderViewInstance.get('/orders/?' + ((page || size) ? '&page=' + page + '&size=' + size : '')
		+ (dateFrom ? '&dateFrom=' + dateFrom : '')
		+ (dateTo ? '&dateTo=' + dateTo : '')
		+ (paymentMethod ? '&paymentMethod=' + paymentMethod : '')
		+ (status ? '&status=' + status : '')
		+ (excludedStatus ? '&excludedStatus=' + excludedStatus : '')
		+ (orderType ? '&orderType=' + orderType : '')
		+ (deliveryPartnerType ? '&deliveryPartnerType=' + deliveryPartnerType : '')
		+ (processedByUsername ? '&processedByUsername=' + processedByUsername : '')
		+ (processedByUsernameWithEmpty ? '&processedByUsernameWithEmpty=' + processedByUsernameWithEmpty : '')
		+ (reverseSort ? '&reverseSort=' + reverseSort : '')
		+ (isPreOrder ? '&isPreOrder=' + isPreOrder : '')
		+ (withOnlyRegisteredPreOrders ? '&withOnlyRegisteredPreOrders=' + withOnlyRegisteredPreOrders : '')
		+ ((typeof closed === 'boolean') ? '&closed=' + closed : '')
		+ ((typeof isPaid === 'boolean') ? '&isPaid=' + isPaid : ''),
	)
}

export default getOrders
