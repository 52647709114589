import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { printEntertainmentExpensesReceiptThunk, printOrderThunk } from '../../../../redux/thunk/order'
import { fetchRestaurantOrdersThunk, setRestaurantOrderStatusThunk, updateRestaurantOrderThunk } from '../../../../redux/thunk/restaurant/orders'
import ModalPaymentType from '../../areas/tables/modalPaymentType'
import { setRestaurantOrderChangeModal, setRestaurantPaymentTypeModal } from '../../../../redux/action/restaurant'
import ModalLogin from '../../../Elements/modalLogin'
import ButtonsModal from '../../../Elements/modal/ButtonsModal'
import { useTranslation } from 'react-i18next'
import { selectAccessToken, selectContainsAnyRole } from '../../../../redux/selector/system'
import { getGiftCouponsPayment, getGiftCouponsSum } from '../../../../helper/getGiftCouponsPayment'
import { getPreparedItems } from '../../areas/tables/modalOrder/helper'
import { roundPrice } from '../../../../helper'

const ModalOrder = ({ selectedOrder, setSelectedOrder }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const  showModalOrder  = useSelector(state => state.restaurant.orderChangeModal)
	const [adminAccessToken, setAdminAccessToken] = useState(null)
	const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false)
	const hasRoleChangeOrder = useSelector(selectContainsAnyRole(['ORDERS_WRITE']))
	const userAccessToken = useSelector(selectAccessToken)
	const extraDiscountOrderItem = selectedOrder?.items?.find(i => i.type === 'EXTRA_DISCOUNT')
	const extraDiscountPrice = extraDiscountOrderItem?.price || 0
	const extraDiscountPriceWithoutMinus = Math.abs(extraDiscountPrice)
	const totalPriceWithExtraDiscount = selectedOrder?.paymentMethod !== 'EC_TERMINAL' ? selectedOrder?.totalPrice + extraDiscountPriceWithoutMinus - selectedOrder?.tips : selectedOrder?.totalPrice + extraDiscountPriceWithoutMinus - selectedOrder?.tips
	const accessTokenForChangeOrder = hasRoleChangeOrder ? userAccessToken : adminAccessToken
	const isClosed = !!selectedOrder?.zNumber

	useEffect(() => {
		if (selectedOrder) {
			dispatch(setRestaurantOrderChangeModal(true))
		}
	}, [selectedOrder]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		setSelectedOrder(null)
		dispatch(setRestaurantOrderChangeModal(false))
	}, [dispatch, setSelectedOrder])

	const handlePaymentChange = useCallback(() => {
		dispatch(setRestaurantPaymentTypeModal(true))
		dispatch(setRestaurantOrderChangeModal(false))
	}, [dispatch])

	const handlePaymentChangeClick = useCallback(() => {
		if (hasRoleChangeOrder) {
			handlePaymentChange()
		} else {
			setModalLoginIsOpen(true)
		}
	}, [hasRoleChangeOrder, handlePaymentChange])

	const handleResetOrder = useCallback(async () => {
		dispatch(fetchRestaurantOrdersThunk(0, 20))
		setSelectedOrder(null)
	}, [dispatch, setSelectedOrder])

	const handlePaymentType = useCallback(({ paymentId, paymentMethod, totalDiscount, extraDiscountItem, tips = 0, giftCoupons }) => {
		const isEcTerminalPaymentMethod = paymentMethod === 'EC_TERMINAL'
		const totalPrice = isEcTerminalPaymentMethod ?
			roundPrice(totalPriceWithExtraDiscount - extraDiscountItem.value  + tips) :
			roundPrice(totalPriceWithExtraDiscount - extraDiscountItem.value - tips)
		const paymentWithCoupons = getGiftCouponsPayment(giftCoupons)
		const giftCouponSum = getGiftCouponsSum(giftCoupons)
		const payments = paymentMethod === 'GIFT_COUPON' ? paymentWithCoupons :
			[...paymentWithCoupons, { id: paymentId, method: paymentMethod, total: totalPrice - giftCouponSum, payed: false }]
		const items = getPreparedItems(selectedOrder, extraDiscountItem)

		dispatch(updateRestaurantOrderThunk(selectedOrder.orderId, {
			...selectedOrder, paymentMethod, totalPrice, totalDiscount, items, payments, tips,
		}, accessTokenForChangeOrder))
		if (!isEcTerminalPaymentMethod) {
			handleResetOrder()
		}
	}, [totalPriceWithExtraDiscount, selectedOrder, accessTokenForChangeOrder, dispatch, handleResetOrder])

	const handlePrint = async () => {
		await dispatch(printOrderThunk(selectedOrder.orderId, false))
		setSelectedOrder(null)
		dispatch(setRestaurantOrderChangeModal(false))
	}

	const handleCancel = useCallback(async () => {
		dispatch(setRestaurantOrderChangeModal(false))
		await dispatch(setRestaurantOrderStatusThunk(selectedOrder?.orderId, 'CANCELED', null, accessTokenForChangeOrder))
		setSelectedOrder(null)
	}, [selectedOrder, accessTokenForChangeOrder, dispatch, setSelectedOrder])

	const handlePrintEntertainmentExpensesReceipt = async () => {
		await dispatch(printEntertainmentExpensesReceiptThunk(selectedOrder.orderId))
		setSelectedOrder(null)
		dispatch(setRestaurantOrderChangeModal(false))
	}

	const onAdminLogin = (accessToken) => {
		setAdminAccessToken(accessToken)
		handlePaymentChange()
	}

	const handleModalLoginClose = () => {
		setModalLoginIsOpen(false)
	}

	const buttons = [
		{ icon: 'change', text: t('order.modal.change_payment'), onClick: handlePaymentChangeClick, show: !isClosed },
		{ icon: 'print', text: t('order.modal.print'), onClick: handlePrint, show: true },
		{ icon: 'cancel', text: t('order.modal.cancel_order'), onClick: handleCancel, show: hasRoleChangeOrder && !isClosed },
		{ icon: 'check', text: t('order.modal.receipt'), onClick: handlePrintEntertainmentExpensesReceipt, show: true },
	]

	return (
		<>
			<ButtonsModal isOpen={!!selectedOrder && showModalOrder}
										id="modal-order"
										close={handleClose}
										title={`${t('Modal.order')} #` + selectedOrder?.orderDayCounter}
										titlePrice={selectedOrder?.totalPrice}
										autoFocus={false}
										buttons={buttons} />
			<ModalLogin isOpen={modalLoginIsOpen}
									close={handleModalLoginClose}
									group="SUPERADMIN"
									title={t('Login.LoginRestaurantAdmin')}
									onLogin={onAdminLogin}
									storeAccessToken={false}
									returnZIndex={0} />
			{selectedOrder &&
				<ModalPaymentType totalPrice={totalPriceWithExtraDiscount}
													extraDiscountOrderItem={extraDiscountOrderItem}
													title={'Bestellung Nr. ' + selectedOrder?.orderDayCounter}
													totalDiscount={selectedOrder?.totalDiscount}
													handlePaymentType={handlePaymentType}
													updatePaymentMode={true}
													resetTable={handleResetOrder} />}
		</>
	)
}

ModalOrder.displayName = 'ModalOrder'
export default ModalOrder
