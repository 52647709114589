import React, { useEffect } from 'react'
import { VersionUpdaterButton, VersionUpdaterButtonText, VersionUpdaterText, VersionUpdaterWrapper } from './index.styled'
import { useTranslation } from 'react-i18next'
import { selectCompanyIndex, selectIsOldReactAppVersion } from '../../../redux/selector/system'
import { useDispatch, useSelector } from 'react-redux'
import { getConfigJsDataThunk } from '../../../redux/thunk/configJs'
import ButtonIcon from '../../../img/svg/button'

const VersionUpdater = React.memo(() => {
	const { t } = useTranslation()
	const reactAppVersion = useSelector(store => store.login.reactAppVersion)
	const companyIndex = useSelector(selectCompanyIndex)
	const isOldReactAppVersion = useSelector(selectIsOldReactAppVersion)
	const dispatch = useDispatch()

	useEffect(() => {
		let interval = setInterval(() => {
			dispatch(getConfigJsDataThunk())
		}, 300000)
		return () => clearInterval(interval)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOldReactAppVersion) {
			// eslint-disable-next-line
			console.log(`Opening popup for update app version: companyIndex=${companyIndex} version=${reactAppVersion} currentVersion=${window.REACT_APP_VERSION}`)
		}
	}, [isOldReactAppVersion]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleReload = () => {
		// eslint-disable-next-line
		console.log(`Reload and update app version: companyIndex=${companyIndex} version=${reactAppVersion} currentVersion=${window.REACT_APP_VERSION}`)
		window.location.reload()
	}

	return (isOldReactAppVersion &&
		<VersionUpdaterWrapper>
			<VersionUpdaterText>{t('versionUpdater.title')}</VersionUpdaterText>
			<VersionUpdaterButton onClick={handleReload}>
				<ButtonIcon icon="reload" />
				<VersionUpdaterButtonText>{t('versionUpdater.button')}</VersionUpdaterButtonText>
			</VersionUpdaterButton>
		</VersionUpdaterWrapper>
	)
})

VersionUpdater.displayName = 'VersionUpdater'
export default VersionUpdater
