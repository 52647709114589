import shopInstance from './instance'

const updateShopConfig = ({
	enabled, colorTheme, layoutTheme, backgroundImage, logoTitle, logoImage,
	productsLayout, productsSizesLayout, orderTypeWorkingStatuses,
	extraButton, showLogoTitle, useBackgroundContainer, backgroundContainerColor,
	imageConditionsConfirmed, companyName, headerTitleColor, disableDarkFilter,
	categoryDisplayType, categoryScrollingType
}) => {
	return shopInstance.put('/shops/config', {
		enabled, colorTheme, layoutTheme, backgroundImage, logoTitle, logoImage,
		productsLayout, productsSizesLayout, orderTypeWorkingStatuses,
		extraButton, showLogoTitle, useBackgroundContainer, backgroundContainerColor,
		imageConditionsConfirmed, companyName, headerTitleColor, disableDarkFilter,
		categoryDisplayType, categoryScrollingType })
}

export default updateShopConfig
