import React from 'react'
import { Topping, ToppingName } from '../CartProduct/index.styled'
import { useLocalStorage } from '../../../../../../../hooks/useLocalStorage'
import colors from '../../../../../../../style/colors'

const CartRemark = React.memo(({ remark, layout }) => {
	const [touchPad] = useLocalStorage('touchPad', false)

	return (
		<Topping className={layout}>
			<ToppingName className={layout}>
				{/* eslint-disable-next-line no-undef */}
				<svg xmlns="http://www.w3.org/2000/svg" width="11.625" height="11.625" viewBox="0 0 11.625 11.625" fill={touchPad ? colors.blue : colors.black}>
					<path d="M324.433,202.056l4.309-.018a0.754,0.754,0,0,0,.752-0.752,0.743,0.743,0,0,0-.747-0.748l-4.31.019,0.019-4.31a0.746,0.746,0,0,0-.748-0.748,0.756,0.756,0,0,0-.752.753l-0.019,4.309-4.309.019a0.754,0.754,0,0,0-.752.752,0.741,0.741,0,0,0,.747.747l4.31-.018-0.019,4.309a0.744,0.744,0,0,0,.748.748,0.754,0.754,0,0,0,.752-0.752Z" transform="translate(-317.875 -195.5)" />
				</svg>
				{remark}
			</ToppingName>
			<div />
			<div />
		</Topping>
	)
})

CartRemark.displayName = 'CartRemark'
export default CartRemark
