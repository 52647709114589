import React, { useCallback, useEffect } from 'react'
import { InView, useInView } from 'react-intersection-observer'
import MainIcon from '../../../../img/svg/main'
import { PER_LOAD } from '../../../../redux/thunk/constants'
import { Table, TableBody, TableHead, TableHeadTr, TableLoader, TableTh, TableThInner, TableWrapper } from './index.styled'

const CustomTable = React.memo(({
	headers = [], onLoad, totalLength = 0, currentLength = totalLength,
	params = [], perLoad = PER_LOAD, children, id, isScrollBody, className
}) => {
	// eslint-disable-next-line
	const { ref, inView } = useInView({
		threshold: 0,
	})

	const loadTable = useCallback(() => {
		if (onLoad) onLoad(Math.ceil(currentLength / perLoad), perLoad)
	}, [currentLength, perLoad, onLoad])

	useEffect(() => {
		// eslint-disable-next-line no-mixed-operators
		if ((totalLength === currentLength && currentLength === 0) || (inView && totalLength > currentLength)) {
			loadTable()
		}
	}, [totalLength, currentLength, ...params])

	const handleInViewChange = useCallback((inView) => {
		if (inView && totalLength > currentLength) {
			loadTable()
		}
	}, [totalLength, currentLength, loadTable])

	return (
		<>
			<TableWrapper isScrollBody={isScrollBody}>
				<Table id={id}>
					<TableHead>
						<TableHeadTr isScrollBody={isScrollBody}>
							{headers?.map((head, i) =>
								<TableTh key={i} className={head.className} onClick={head.onClick}>
									{(head.title && head.image) ?
										<TableThInner>{head.title} <MainIcon icon={head.image} /> </TableThInner> :
										head.image ? <MainIcon icon={head.image} /> : head.title}
								</TableTh>)}
						</TableHeadTr>
					</TableHead>
					<TableBody className={className}>
						{children}
					</TableBody>
				</Table>
			</TableWrapper>
			<InView as={TableLoader} onChange={handleInViewChange}>
				<span id="total-count" style={{ opacity: 0 }} ref={ref}>{totalLength}</span>
			</InView>
		</>
	)
})

CustomTable.displayName = 'CustomTable'
export default CustomTable
