import React, { useCallback, useEffect, useState } from 'react'
import { PayPalNotificationContacts, PayPalNotificationContent, PayPalNotificationInfo, PayPalNotificationLink, PayPalNotificationSub, PayPalNotificationText, PayPalNotificationTitle, PayPalNotificationWatsappContacts, PayPalNotificationWrap } from './index.styled'
import { useTranslation } from 'react-i18next'
import MainIcon from '../../../img/svg/main'
import { useDispatch, useSelector } from 'react-redux'
import CustomInfoModal from '../../Elements/modal/CustomInfoModal'
import whatsapp_icon from '../../../img/svg/whatsapp_icon_green.svg'
import { QRCodeSVG } from 'qrcode.react'
import CheckBoxInputControlled from '../../Elements/inputs/CheckboxInput/controlled'
import Button from '../../Elements/buttons/Button'
import ContactsModal from './ContactsModal'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import CheckBoxInputControlledGray from '../../Elements/inputs/CheckBoxInputGray/controlled'
import { selectCompanyData, selectCompanyIndex, selectHasAnyGroup, selectUsername } from '../../../redux/selector/system'
import { updatePayPalNotificationThunk } from '../../../redux/thunk/payment'
import { FINAL_DATE } from './constants'

const EVENT_Z_INDEX = 51

const PayPalDeadlineWarningModal = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const companyIndex = useSelector(selectCompanyIndex)
	const isMobile = useWindowBreakPoint(theme.point720)
	const isDealer = useSelector(store => store.support.dealerContacts)
	const name = useSelector(selectCompanyData('name'))
	const hasRoleOwner = useSelector(selectHasAnyGroup(['OWNER']))
	const phoneNumberOfDealer = useSelector(store => store.support.dealerContacts?.supportNumber)
	const whatsAppNumberOfDealer = useSelector(store => store.support.dealerContacts?.whatsAppNumber)
	const username = useSelector(selectUsername)
	const paypalNotification = useSelector(store => store.payment?.paypalNotification)

	const [openModal, setOpenModal] = useState(false)
	const [showContactsModal, setShowContactsModal] = useState(false)
	const [disabledModal, setDisabledModal] = useState(false)

	const preparedWhatsAppNumber = whatsAppNumberOfDealer?.split('').filter(item => /\d/.test(item)).join('')

	const whatsappHref = `https://wa.me/4951154681414?text=Guten%20Tag!%20Ich%20bin%20Kunde%20${companyIndex}%20bei%20${name}%20und%20möchte%20Einzelheiten%20zu%20den%20neuen%20Geschäftsbedingungen%20von%20PayPal%20erfahren.`
	const whatsappHrefOfDealer = `https://wa.me/${preparedWhatsAppNumber}?text=Guten%20Tag!%20Ich%20bin%20Kunde%20${companyIndex}%20bei%20${name}%20und%20möchte%20Einzelheiten%20zu%20den%20neuen%20Geschäftsbedingungen%20von%20PayPal%20erfahren.`
	const showQrCode = (isDealer && whatsAppNumberOfDealer) || !isDealer
	const phoneHref = isDealer ? `${phoneNumberOfDealer}` : '+49 (0) 511 546-814-14'

	const localStorageModalDisabled = JSON.parse(window.localStorage.getItem('payPalDeadlineWarningModalDisabled'))
	const localStorageModalEnabledByUser = !localStorageModalDisabled?.[username]
	const shouldShowModal = paypalNotification && !paypalNotification?.newConditionsDeadlineWarningDisabled && localStorageModalEnabledByUser
	const deadlineDays = Math.abs(Math.floor((new Date() - new Date(FINAL_DATE)) / (1000 * 3600 * 24)))

	useEffect(() => {
		const now = new Date()
		const finalDate = new Date(FINAL_DATE)
		if (now <= finalDate && shouldShowModal && name)
			setOpenModal(true)
		else setOpenModal(false)
	}, [shouldShowModal, name])

	const handleOpenContacts = useCallback(() => {
		setShowContactsModal(true)
	}, [])

	const handleCloseContacts = useCallback(() => {
		setShowContactsModal(false)
	}, [])

	const handleChangeDisabledModal = useCallback(() => {
		setDisabledModal(!disabledModal)
	}, [disabledModal])

	const handleCloseModal = useCallback(() => {
		setOpenModal(false)
		setDisabledModal(false)
	}, [])

	const handleAccept = useCallback(() => {
		if (disabledModal) {
			if (hasRoleOwner)
				dispatch(updatePayPalNotificationThunk({...paypalNotification, newConditionsDeadlineWarningDisabled: disabledModal }))
			else window.localStorage.setItem('payPalDeadlineWarningModalDisabled', JSON.stringify({ ...localStorageModalDisabled, [username]: disabledModal }))
		}
		handleCloseModal()
	}, [hasRoleOwner, paypalNotification, disabledModal, dispatch, handleCloseModal, localStorageModalDisabled, username])

	return (
		<>
			<CustomInfoModal isOpen={openModal}
				title={t('Payments.modal.notification.title')}
				close={handleCloseModal}
				zIndex={EVENT_Z_INDEX}
				className={(isDealer && !whatsAppNumberOfDealer) && 'bottom-middle'}
				button={<>
					<PayPalNotificationLink className="center" onClick={handleOpenContacts}>{t('Payments.modal.notification.extraContacts')}</PayPalNotificationLink>
					<Button icon="checkmark" onClick={handleAccept} tabIndex="1" text="Ok" color="green" keyButton="F2" />
				</>}>
				<PayPalNotificationWrap>
					<div>
						<PayPalNotificationText>{t('Payments.modal.notification.warningTitle', { deadlineDays })}</PayPalNotificationText>
						<PayPalNotificationText>{t('Payments.modal.notification.warningTitle2')}</PayPalNotificationText>
					</div>
					<PayPalNotificationContent>
						{showQrCode &&
							<QRCodeSVG value={isDealer ? whatsappHrefOfDealer : whatsappHref}
													level="M"
													imageSettings={{
														src: whatsapp_icon,
														width: 32,
														height: 32,
														x: null,
														y: null,
														excavate: true
													}} />}
						<PayPalNotificationInfo>
							<MainIcon icon={showQrCode ? 'qr-phone' : 'phone'} />
							{showQrCode ? <PayPalNotificationWatsappContacts>
								<PayPalNotificationSub>{t('Payments.modal.notification.warningWatsappText')}</PayPalNotificationSub>
								<PayPalNotificationSub>{t('Payments.modal.notification.warningContactsText')}:</PayPalNotificationSub>
								<PayPalNotificationLink href={phoneHref}>{phoneHref}</PayPalNotificationLink>
							</PayPalNotificationWatsappContacts> :
							<PayPalNotificationContacts>
								<PayPalNotificationTitle>{name} - {companyIndex}</PayPalNotificationTitle>
								<PayPalNotificationSub>{t('Payments.modal.notification.warningContactsText')}:</PayPalNotificationSub>
								<PayPalNotificationLink href={phoneHref}>{phoneHref}</PayPalNotificationLink>
							</PayPalNotificationContacts>}
						</PayPalNotificationInfo>
					</PayPalNotificationContent>
					{!isMobile ? <CheckBoxInputControlled id="accept" label={t('Payments.modal.notification.accept')} className="accept"
																				checked={disabledModal} onChange={handleChangeDisabledModal} />
					: <CheckBoxInputControlledGray label={t('Payments.modal.notification.accept')}
																			 onChange={handleChangeDisabledModal}
																			 isActive={disabledModal} />}
				</PayPalNotificationWrap>
			</CustomInfoModal>
			<ContactsModal isOpen={showContactsModal} close={handleCloseContacts} />
		</>
	)
})

PayPalDeadlineWarningModal.displayName = 'PayPalDeadlineWarningModal'
export default PayPalDeadlineWarningModal
