import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const UberEatRestaurant = lazyWithRetry(() => import(/* webpackChunkName: "uber-eat-restaurant" */'../../component/UberEatRestaurant'))

const UberEatRestaurantPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<UberEatRestaurant />
		</Suspense>
	)
})

UberEatRestaurantPage.displayName = 'UberEatRestaurantPage'
export default UberEatRestaurantPage
