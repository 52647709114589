import styled from 'styled-components'
import { theme } from '../../style/theme'
import TableTr from '../Elements/table/tr'
import { TableMobileTitle } from '../Elements/table/style/table/title/index.styled'

export const CouponFormButtons = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	margin-bottom: 1.9em;
	margin-top: 1.875em;

	@media screen and (max-width: ${theme.point820}) {
		justify-content: center;
		margin-bottom: 2.14em;
		margin-top: 2.14em;
		> button {
			font-size: 1.15rem;
			padding-left: 2.93em;
			padding-right: 2.93em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		display: grid;
		grid-template-columns: 1fr;
		margin-bottom: 1.9em;
		margin-top: 1.875em;

		> button {
			font-size: 1rem;
			margin-left: 0;
		}
	}
`

CouponFormButtons.displayName = 'CouponFormButtons'

export const InfoText = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.64em;
	color: #777E89;
	font-size: 1.2em;
	font-weight: 400;

	svg {
		width: 1.75em;
		height: 1.67em;
		flex-shrink: 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.3em;
		flex-direction: column;
	}
`

InfoText.displayName = 'InfoText'

export const CouponTableWrapper = styled.div`

&.tablet {
	table {
		font-size: 1.29rem;
		display: block;
		margin-top: 2.77em;
	}

	tbody {
		@media screen and (max-width: ${theme.point720}) {
			font-size: 1rem;

			table {
				margin-top: 3.125em;
			}

			tr {
				margin-top: 1.25em !important;
			}

			td {
				&.no-coupons {
					grid-column: 1 / 7;
					height: auto;
				}
			}
		}
	}

	tr {
		gap: 1px;
		grid-auto-flow: dense;
		margin-top: 1.1em !important;

		&.no-coupons {
			grid-template-columns: 1fr;
		}
	}

	td {
		font-family: ${theme.fontRobotoBold};
		box-sizing: content-box;
		gap: 0.28em;
		min-height: 2.44em;
		padding: 0 0 1.1em !important;
		display: flex;
		flex-direction: column;

		&:first-child {
			align-items: end;
			div {
				padding-right: 0.1em
			}
			label > span {
				padding-left: 2.9em;
			}
		}

		&:nth-child(5) {
			padding-bottom: 0.9em !important;
			> div:last-child {
				display: flex;
				gap: 0.5em;
				font-family: ${theme.fontRobotoBold};
				> div {
					font-family: ${theme.fontRobotoBold};
				}
			}
		}

		&.no-coupons {
			grid-column: 1 / 7;
			align-items: center;
			text-align: center;
		}
	}
}
`
CouponTableWrapper.displayName = 'CouponTableWrapper'

export const TableCouponMobileTitle = styled(TableMobileTitle)`
	@media screen and (max-width: ${theme.point820}) {
		display: block;
		font-size: 0.84em;
		font-weight: 500;
	}
`
TableCouponMobileTitle.displayName = 'TableCouponMobileTitle'

export const GiftCouponTableWrapper = styled.div`
	&.tablet {
		td {
			&.no-coupons {
				grid-column: 1 / 7;
				align-items: center;
				text-align: center;
			}
		}
	}
`
GiftCouponTableWrapper.displayName = 'GiftCouponTableWrapper'

export const GiftCouponCodeWrapper = styled.div`
	padding: 0.31em 0.93em;
	border-radius: 0.63em;
	width: max-content;

	&.ACTIVE {
		background: #CCF7CE;
	}

	&.CANCELED {
		background: #F7CCD4;
	}

	&.FULLY_USED {
		background: #EADCCC;
	}

	&.DEACTIVATED {
		background: #EAECEF;
		color: #60616E;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.11em;
	}
`
GiftCouponCodeWrapper.displayName = 'GiftCouponCodeWrapper'

export const CouponTableTr = styled(TableTr)`
	&.disabled {
		color: #A4ACB8;
	}

	@media screen and (max-width: ${theme.point820}) {
		> td {
			font-family: ${theme.fontRobotoMedium};
		}
	}
`
CouponTableTr.displayName = 'CouponTableTr'
