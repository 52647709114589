import { setCategoriesUpdateCategory, setCategory } from '../../redux/action/category'
import { setProductsUpdateProduct, setProduct } from '../../redux/action/product'

export const processProductMessageThunk = (message) => (dispatch, getState) => {
	switch (message?.type) {
		case 'product.PRODUCT_CHANGED_EVENT':
			handleUpdateProduct(message.data.product, dispatch, getState)
			break
		case 'product.CATEGORY_CHANGED_EVENT':
			handleUpdateCategory(message.data.category, dispatch, getState)
			break
		default:
			// eslint-disable-next-line
			console.warn('Unknown product message type: ', JSON.stringify(message))
			break
	}
}

export const handleUpdateProduct = (data, dispatch, getState) => {
	if (window.location.pathname === '/settings/product/ingredients/article'
		|| window.location.pathname === '/settings/product/ingredients/article/product') {
		const products = getState().product.products
		const isExistProduct = !!products.find(product => product?.productId === data.productId)
		const isEditCurrentProduct = getState().product.product?.productId === data.productId
		if (isExistProduct) dispatch(setProductsUpdateProduct(data))
		if (isEditCurrentProduct) dispatch(setProduct(data))
	}
}

export const handleUpdateCategory = (data, dispatch, getState) => {
	if (window.location.pathname === '/settings/product/ingredients/category'
		|| window.location.pathname === '/settings/product/ingredients/category/edit') {
		const categories = getState().category.categories
		const isExistCategory = !!categories.find(category => category?.id === data.id)
		const isEditCurrentCategory = getState().category.category?.id === data.id
		if (isExistCategory) dispatch(setCategoriesUpdateCategory(data))
		if (isEditCurrentCategory) dispatch(setCategory(data))
	}
}

