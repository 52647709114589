import geoDataInstance from '../instance'

const getGeoApiKey = (callbackSuccess, callbackError) => {
  return async () => {
    geoDataInstance.get('/geomap')
      .then(res => {
        if (res) {
          typeof callbackSuccess === 'function' && callbackSuccess(res)
        }
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }
}

export default getGeoApiKey
