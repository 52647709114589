import React, { useCallback } from 'react'
import { ContactsTitle, PayPalNotificationContent, PayPalNotificationInfo, PayPalNotificationLink, PayPalNotificationSub, PayPalNotificationWatsappContacts, PayPalNotificationWrap } from './../index.styled'
import { useTranslation } from 'react-i18next'
import MainIcon from '../../../../img/svg/main'
import { useSelector } from 'react-redux'
import CustomInfoModal from '../../../Elements/modal/CustomInfoModal'
import whatsapp_icon from '../../../../img/svg/whatsapp_icon_green.svg'
import { QRCodeSVG } from 'qrcode.react'
import Button from '../../../Elements/buttons/Button'
import { selectCompanyData, selectCompanyIndex } from '../../../../redux/selector/system'

const EVENT_Z_INDEX = 52

const ContactsModal = React.memo(({ isOpen = false, close }) => {
	const { t } = useTranslation()
	const companyIndex = useSelector(selectCompanyIndex)
	const name = useSelector(selectCompanyData('name'))
	const whatsappHref = `https://wa.me/4951154681414?text=Guten%20Tag!%20Ich%20bin%20Kunde%20${companyIndex}%20bei%20${name}%20und%20möchte%20Einzelheiten%20zu%20den%20neuen%20Geschäftsbedingungen%20von%20PayPal%20erfahren.`

	const handleCloseContacts = useCallback(() => {
		close()
	}, [])

	return (
		<CustomInfoModal isOpen={isOpen}
			title={t('Payments.modal.notification.title')}
			close={handleCloseContacts}
			zIndex={EVENT_Z_INDEX}
			isIcon={false}
			button={<Button icon="checkmark" onClick={handleCloseContacts} tabIndex="1" text="Ok" color="green" keyButton="F2" />}>
			<PayPalNotificationWrap className="contacts">
				<ContactsTitle>{t('Payments.modal.notification.contactsTitle')}</ContactsTitle>
				<PayPalNotificationContent className="contacts">
					<QRCodeSVG value={whatsappHref}
											level="M"
											imageSettings={{
												src: whatsapp_icon,
												width: 32,
												height: 32,
												x: null,
												y: null,
												excavate: true
											}} />
					<PayPalNotificationInfo>
						<MainIcon icon="qr-phone" />
						<PayPalNotificationWatsappContacts>
							<PayPalNotificationSub>{t('Payments.modal.notification.warningWatsappText')}</PayPalNotificationSub>
							<PayPalNotificationSub>{t('Payments.modal.notification.warningContactsText')}:</PayPalNotificationSub>
							<PayPalNotificationLink href="tel:+49 511 546-814-14">+49 (0) 511 546-814-14</PayPalNotificationLink>
						</PayPalNotificationWatsappContacts>
					</PayPalNotificationInfo>
				</PayPalNotificationContent>
			</PayPalNotificationWrap>
		</CustomInfoModal>
	)
})

ContactsModal.displayName = 'ContactsModal'
export default ContactsModal
