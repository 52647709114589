import React from 'react'
import { CheckboxContainer, CheckboxLabel, CheckboxWrapper, CheckedIcon } from '../index.styled'

const CheckBoxInputControlledGray = React.memo(({ onChange, isActive, disabled, testId, label, name, refCheckbox = null }) => {
	const layout =(isActive ? 'active ' : '') + (disabled ? 'disabled ' : '')

	return (
		<CheckboxWrapper className={layout}
										 onClick={!disabled ? onChange : () => {}}
										 data-testid={testId}
										 name={name}
										 ref={refCheckbox}>
			<CheckboxContainer className={layout}>
				<CheckedIcon className={layout}>
					<svg viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.1553 0.936439L6.84282 11.0844L2.85646 6.6381C2.35223 6.07614 1.49392 6.0358 0.940089 6.55047C0.389011 7.06513 0.349747 7.93937 0.854672 8.50202L5.83159 14.0507C6.01827 14.2579 6.25868 14.4019 6.52182 14.4645C6.62032 14.4868 6.72089 14.5 6.82353 14.5C7.2024 14.5027 7.56267 14.3442 7.82236 14.0639L18.1351 2.82401C18.6455 2.26692 18.6173 1.39338 18.0703 0.872453C17.5262 0.352919 16.6672 0.379348 16.1553 0.936439Z" />
					</svg>
				</CheckedIcon>
			</CheckboxContainer>
			<CheckboxLabel className={layout}>{label}</CheckboxLabel>
		</CheckboxWrapper>
)
})

CheckBoxInputControlledGray.displayName = 'CheckBoxInputControlledGray'
export default CheckBoxInputControlledGray
