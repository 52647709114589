import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { theme } from '../../../../../style/theme'

export const PartnerDriverTrackerWrapper = styled.div`
	font-size: inherit;
	display: flex;
	align-items: center;
	gap: 0.25em;
`

PartnerDriverTrackerWrapper.displayName = 'PartnerDriverTrackerWrapper'

export const PartnerDriverTrackerIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${colorTheme.table.tableTr.partnerDriverTracker.background};
	border-radius: 0.5em;
	padding: 0.5em;
	width: 2.5em;
	height: 1.8em;

	> svg {
		width: 1.25em;
		height: 1em;
		flex-shrink: 0;
		fill: ${colorTheme.table.tableTr.partnerDriverTracker.icon};
	}
`

PartnerDriverTrackerIconWrapper.displayName = 'PartnerDriverTrackerIconWrapper'

export const PartnerDriverTrackerText = styled.div`
	font-size: 0.8em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	color: ${colorTheme.table.tableTr.partnerDriverTracker.color};
	text-decoration: underline;
	cursor: pointer;
`

PartnerDriverTrackerText.displayName = 'PartnerDriverTrackerText'
