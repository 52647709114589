import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../buttons/Button'
import ActivateGiftVoucherModal from './ActivateGiftVoucherModal'
import { ButtonWrap } from './index.styled'
import { useSelector } from 'react-redux'
import { selectHasAnyItemsInCart } from '../../../../redux/selector/cart'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'

const GiftVoucher = React.memo(({ disabled, module }) => {
	const { t } = useTranslation()
	const [showActivateModal, setShowActivateModal] = useState(false)
	const cartIsNotEmpty = useSelector(selectHasAnyItemsInCart(module))
	const openActivationModal = useCallback(() => {
		setShowActivateModal(true)
	}, [])

	const closeActivationModal = useCallback(() => {
		setShowActivateModal(false)
	}, [])

	const [colorTheme] = useLocalStorage('colorTheme', 'LIGHT')

	return (
		<ButtonWrap>
			<Button name="coupon"
							testId="gift-voucher"
							color={colorTheme === 'LIGHT' ? 'blue' : 'gray'}
							icon="gift"
							text={t('Cart.GiftVoucher.title')}
							onClick={openActivationModal}
							showTextForMobile={false}
							disabled={disabled || cartIsNotEmpty} />

			<ActivateGiftVoucherModal isOpen={showActivateModal}
																close={closeActivationModal}
																module={module} />
		</ButtonWrap>
	)
})

GiftVoucher.displayName = 'GiftVoucher'
export default GiftVoucher
