import { SET_MODAL_LOGIN_ACCESS_TOKEN, SET_MODAL_LOGIN_INVALID_PASSWORD_ERROR, SET_MODAL_LOGIN_LOADING, SET_MODAL_LOGIN_OPEN_FOR_WAITER, SET_MODAL_LOGIN_REFRESH_TOKEN, SET_MODAL_LOGIN_USERS, SET_PIN_CODE_MODAL_LOGIN_IS_NEEDED, SET_MODAL_LOGIN_USERNAME } from '../../actionTypes/modalLogin'

export const setModalLoginUsers = value => ({ type: SET_MODAL_LOGIN_USERS, value })

export const setModalLoginAccessToken = value => ({ type: SET_MODAL_LOGIN_ACCESS_TOKEN, value })

export const setModalLoginRefreshToken = value => ({ type: SET_MODAL_LOGIN_REFRESH_TOKEN, value })

export const setModalLoginInvalidPasswordError = value => ({ type: SET_MODAL_LOGIN_INVALID_PASSWORD_ERROR, value })

export const setModalLoginLoading = value => ({ type: SET_MODAL_LOGIN_LOADING, value })

export const setModalLoginOpenForWaiter = value => ({ type: SET_MODAL_LOGIN_OPEN_FOR_WAITER, value })

export const setPinCodeModalLoginIsNeeded = value => ({ type: SET_PIN_CODE_MODAL_LOGIN_IS_NEEDED, value })

export const setModalLoginUsername = value => ({ type: SET_MODAL_LOGIN_USERNAME, value })
