import React from 'react'
import { CheckboxWrapper } from '../index.styled'

const CheckBoxInputControlled = React.memo(({ id, label, name, checked, onChange, className, testId, circle = false, disabled, maxContent }) => {
	const layout = 'checkmark ' + (disabled ? ' disabled ' : '') + (maxContent ? 'maxContent ' : '')

	return (
		<CheckboxWrapper id={id} className={className} circle={circle}>
			<input name={name}
						 checked={!!checked}
						 onChange={onChange}
						 type="checkbox"
						 data-testid={testId}
						 disabled={disabled} />
			<span className={layout} />
			<span className="label">{label}</span>
		</CheckboxWrapper>
	)
})

CheckBoxInputControlled.displayName = 'CheckBoxInputControlled'
export default CheckBoxInputControlled
