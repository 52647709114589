import styled from 'styled-components'
import { colorTheme } from '../../../style/theme/colorTheme'

export const WrapperContacts = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 0.5em;
	align-items: center;

	&.tse-modal-notification {
		margin-bottom: 2em;
	}
`
WrapperContacts.displayName = 'WrapperContacts'

export const ContactIcon = styled.img`
	margin-right: 0.3em;
`
ContactIcon.displayName = 'ContactIcon'

export const ContactItem = styled.div`
	display: flex;
	grid-gap: 0.3em;

	svg {
		width: 2.17em;
		height: 2.17em;
		fill: ${colorTheme.contactsModal.icon};
	}
`
ContactItem.displayName = 'ContactItem'

export const QrCodeWrapper = styled.div`
	width: 10.5em;
	height: 10.5em;
	display: flex;

	& > svg {
		display: block;
		width: 100%;
		height: 100%;
	}
`
