import { NotificationModalCartCloseBtn, NotificationModalCartContainer, NotificationModalCartQRCode, NotificationModalCartWrap } from '../index.styled'
import React from 'react'

const NotificationModalCart = React.memo(({close, children, qrCode, className }) => {
	return(
		<NotificationModalCartWrap className={className}>
			<NotificationModalCartCloseBtn onClick={close} data-testid="btn-close">
				<svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.7301 17.4001L33.1401 3.93006C33.8101 3.26006 33.8101 2.17006 33.1401 1.50006C32.4701 0.830059 31.3901 0.830059 30.7301 1.50006L17.3201 14.9801L3.91006 1.50006C3.24006 0.830059 2.16006 0.830059 1.50006 1.50006C0.830059 2.17006 0.830059 3.26006 1.50006 3.93006L14.9001 17.4001L1.50006 30.8801C0.830059 31.5501 0.830059 32.6401 1.50006 33.3101C1.83006 33.6401 2.27006 33.8101 2.71006 33.8101C3.15006 33.8101 3.58006 33.6401 3.92006 33.3101L17.3201 19.8401L30.7201 33.3001C31.0501 33.6301 31.4901 33.8001 31.9301 33.8001C32.3701 33.8001 32.8001 33.6301 33.1401 33.3001C33.8101 32.6301 33.8101 31.5401 33.1401 30.8701L19.7301 17.4001Z" fill="#3F3F46" />
				</svg>
			</NotificationModalCartCloseBtn>
			<NotificationModalCartQRCode>
				{qrCode}
			</NotificationModalCartQRCode>
			<NotificationModalCartContainer>
				{children}
			</NotificationModalCartContainer>
		</NotificationModalCartWrap>
	)
})

NotificationModalCart.displayName ='NotificationModalCart'
export default NotificationModalCart
