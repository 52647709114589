import React from 'react'
import { CartTotalItem, CartTotalItemPrice, CartTotalItemTitle, CartTotalsWrapper } from './index.styled'
import Currency from '../../../../Elements/currency'
import { useTranslation } from 'react-i18next'
import { selectCartItemsTotal, selectCartTotal, selectDeliveryCost, selectGiftCouponsTotal, selectTotalDiscount } from '../../../../../redux/selector/clientMonitorCart'
import { useSelector } from 'react-redux'

const CartTotals = React.memo(() => {
	const { t } = useTranslation()
	const itemsTotal = useSelector(selectCartItemsTotal)
	const totalDiscount = useSelector(selectTotalDiscount)
	const giftCouponsTotal = useSelector(selectGiftCouponsTotal)
	const deliveryCost = useSelector(selectDeliveryCost)
	const cartTotal = useSelector(selectCartTotal)

	return (
		<CartTotalsWrapper>
			<CartTotalItem>
				<CartTotalItemTitle>{t('Cart.subtotal')}</CartTotalItemTitle>
				<CartTotalItemPrice><Currency>{itemsTotal}</Currency></CartTotalItemPrice>
			</CartTotalItem>
			{totalDiscount !== 0 &&
				<CartTotalItem>
					<CartTotalItemTitle>{t('Cart.saving')}</CartTotalItemTitle>
					<CartTotalItemPrice>-<Currency>{totalDiscount}</Currency></CartTotalItemPrice>
				</CartTotalItem>}
			{giftCouponsTotal !== 0 &&
				<CartTotalItem>
					<CartTotalItemTitle>{t('Cart.gift_coupon')}</CartTotalItemTitle>
					<CartTotalItemPrice>-<Currency>{giftCouponsTotal}</Currency></CartTotalItemPrice>
				</CartTotalItem>}
			{deliveryCost !== 0 &&
				<CartTotalItem>
					<CartTotalItemTitle>{t('AddOrder.Client.form.Anfahrthosten')}</CartTotalItemTitle>
					<CartTotalItemPrice><Currency>{deliveryCost}</Currency></CartTotalItemPrice>
				</CartTotalItem>}
			<CartTotalItem className="summary">
				<CartTotalItemTitle className="summary">{t('Cart.total')}:</CartTotalItemTitle>
				<CartTotalItemPrice className="summary"><Currency>{cartTotal}</Currency></CartTotalItemPrice>
			</CartTotalItem>
		</CartTotalsWrapper>
	)
})

CartTotals.displayName = 'CartTotals'
export default CartTotals
