import styled from 'styled-components'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const KitchenStatusCircleBlock = styled.div`
	font-size: 1em;
	width: 1.25em;
	height: 1.25em;
	border-radius: 50%;
	flex-shrink: 0;
	background-color: ${colorTheme.restaurant.sendToKitchenModal.statuses.default};

	&.PLACED {
		background-color: ${colorTheme.restaurant.sendToKitchenModal.statuses.default};
	}

	&.BEING_PREPARED {
		background-color: ${colorTheme.restaurant.sendToKitchenModal.statuses.orange};
	}

	&.PREPARED {
		background-color: ${colorTheme.restaurant.sendToKitchenModal.statuses.green};
	}

	&.CANCELED {
		background-color: white;
		border: 2px solid ${colorTheme.restaurant.sendToKitchenModal.statuses.red};
	}
`
KitchenStatusCircleBlock.displayName = 'KitchenStatusCircleBlock'
