import { getReactAppVersion } from '../../../component/Elements/versionUpdater/helper'
import { retrieveErrorFromApi } from '../../../helper'
import { setReactAppVersion } from '../../action'
import { setAppErrors } from '../../action/system'
import getConfigJsData from '../../axios/configJs'

export const getConfigJsDataThunk = () => (dispatch, getState) => {
	const timestamp = new Date().toISOString()
	return getConfigJsData(timestamp)
		.then((res) => {
			const version = getReactAppVersion(res?.data)	
			dispatch(setReactAppVersion(version))
			// eslint-disable-next-line
			console.log(`Received app version: companyIndex=${getState().login.companyIndex} version=${version} currentVersion=${window.REACT_APP_VERSION}`)
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}