import React from 'react'

export const Table8ChairsSvg = ({ chairCount, className }) => {
	const chairClassname = (chairNumber) => {
		return chairNumber <= chairCount ? '' : 'hidden'
	}
	return (
		<svg  viewBox="0 0 462 200" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g className={chairClassname(8)}>
				<path d="M3.8999 120.1V79.9004C3.8999 76.6004 6.5999 73.9004 9.8999 73.9004H50.0999C53.3999 73.9004 56.0999 76.6004 56.0999 79.9004V120.1C56.0999 123.4 53.3999 126.1 50.0999 126.1H9.8999C6.6999 126.1 3.8999 123.3 3.8999 120.1Z" fill="#7A7A7A" />
				<path d="M50.1 73.9C53.4 73.9 56.1 76.6 56.1 79.9V120.1C56.1 123.4 53.4 126.1 50.1 126.1H9.9C6.6 126.1 3.9 123.4 3.9 120.1V79.9C3.9 76.6 6.6 73.9 9.9 73.9H50.1ZM50.1 70H9.9C4.4 70 0 74.4 0 79.9V120.1C0 125.6 4.4 130 9.9 130H50.1C55.6 130 60 125.6 60 120.1V79.9C60 74.4 55.6 70 50.1 70Z" fill="white" />
			</g>
			<path d="M73.8999 190.1V149.9C73.8999 146.6 76.5999 143.9 79.8999 143.9H120.1C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.8999C76.6999 196.1 73.8999 193.3 73.8999 190.1Z" fill="#7A7A7A" />
			<path d="M120.1 143.9C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.9C76.6 196.1 73.9 193.4 73.9 190.1V149.9C73.9 146.6 76.6 143.9 79.9 143.9H120.1ZM120.1 140H79.9C74.4 140 70 144.4 70 149.9V190.1C70 195.6 74.4 200 79.9 200H120.1C125.6 200 130 195.6 130 190.1V149.9C130 144.4 125.6 140 120.1 140Z" fill="white" />
			<path d="M73.8999 50.1004V9.90039C73.8999 6.60039 76.5999 3.90039 79.8999 3.90039H120.1C123.4 3.90039 126.1 6.60039 126.1 9.90039V50.1004C126.1 53.4004 123.4 56.1004 120.1 56.1004H79.8999C76.6999 56.1004 73.8999 53.3004 73.8999 50.1004Z" fill="#7A7A7A" />
			<path d="M120.1 3.9C123.4 3.9 126.1 6.6 126.1 9.9V50.1C126.1 53.4 123.4 56.1 120.1 56.1H79.9C76.6 56.1 73.9 53.4 73.9 50.1V9.9C73.9 6.6 76.6 3.9 79.9 3.9H120.1ZM120.1 0H79.9C74.4 0 70 4.4 70 9.9V50.1C70 55.6 74.4 60 79.9 60H120.1C125.6 60 130 55.6 130 50.1V9.9C130 4.4 125.6 0 120.1 0Z" fill="white" />
			<path d="M204.9 50.1004V9.90039C204.9 6.60039 207.6 3.90039 210.9 3.90039H251.1C254.4 3.90039 257.1 6.60039 257.1 9.90039V50.1004C257.1 53.4004 254.4 56.1004 251.1 56.1004H210.9C207.7 56.1004 204.9 53.3004 204.9 50.1004Z" fill="#7A7A7A" />
			<path d="M251.1 3.9C254.4 3.9 257.1 6.6 257.1 9.9V50.1C257.1 53.4 254.4 56.1 251.1 56.1H210.9C207.6 56.1 204.9 53.4 204.9 50.1V9.9C204.9 6.6 207.6 3.9 210.9 3.9H251.1ZM251.1 0H210.9C205.4 0 201 4.4 201 9.9V50.1C201 55.6 205.4 60 210.9 60H251.1C256.6 60 261 55.6 261 50.1V9.9C261 4.4 256.6 0 251.1 0Z" fill="white" />
			<path d="M204.9 190.1V149.9C204.9 146.6 207.6 143.9 210.9 143.9H251.1C254.4 143.9 257.1 146.6 257.1 149.9V190.1C257.1 193.4 254.4 196.1 251.1 196.1H210.9C207.7 196.1 204.9 193.3 204.9 190.1Z" fill="#7A7A7A" />
			<path d="M251.1 143.9C254.4 143.9 257.1 146.6 257.1 149.9V190.1C257.1 193.4 254.4 196.1 251.1 196.1H210.9C207.6 196.1 204.9 193.4 204.9 190.1V149.9C204.9 146.6 207.6 143.9 210.9 143.9H251.1ZM251.1 140H210.9C205.4 140 201 144.4 201 149.9V190.1C201 195.6 205.4 200 210.9 200H251.1C256.6 200 261 195.6 261 190.1V149.9C261 144.4 256.6 140 251.1 140Z" fill="white" />
			<path d="M458.1 120.1V79.9004C458.1 76.6004 455.4 73.9004 452.1 73.9004H411.9C408.6 73.9004 405.9 76.6004 405.9 79.9004V120.1C405.9 123.4 408.6 126.1 411.9 126.1H452.1C455.3 126.1 458.1 123.3 458.1 120.1Z" fill="#7A7A7A" />
			<path d="M411.9 73.9C408.6 73.9 405.9 76.6 405.9 79.9V120.1C405.9 123.4 408.6 126.1 411.9 126.1H452.1C455.4 126.1 458.1 123.4 458.1 120.1V79.9C458.1 76.6 455.4 73.9 452.1 73.9H411.9ZM411.9 70H452.1C457.6 70 462 74.4 462 79.9V120.1C462 125.6 457.6 130 452.1 130H411.9C406.4 130 402 125.6 402 120.1V79.9C402 74.4 406.4 70 411.9 70Z" fill="white" />
			<path d="M388.1 190.1V149.9C388.1 146.6 385.4 143.9 382.1 143.9H341.9C338.6 143.9 335.9 146.6 335.9 149.9V190.1C335.9 193.4 338.6 196.1 341.9 196.1H382.1C385.3 196.1 388.1 193.3 388.1 190.1Z" fill="#7A7A7A" />
			<path d="M341.9 143.9C338.6 143.9 335.9 146.6 335.9 149.9V190.1C335.9 193.4 338.6 196.1 341.9 196.1H382.1C385.4 196.1 388.1 193.4 388.1 190.1V149.9C388.1 146.6 385.4 143.9 382.1 143.9H341.9ZM341.9 140H382.1C387.6 140 392 144.4 392 149.9V190.1C392 195.6 387.6 200 382.1 200H341.9C336.4 200 332 195.6 332 190.1V149.9C332 144.4 336.4 140 341.9 140Z" fill="white" />
			<path d="M388.1 50.1004V9.90039C388.1 6.60039 385.4 3.90039 382.1 3.90039H341.9C338.6 3.90039 335.9 6.60039 335.9 9.90039V50.1004C335.9 53.4004 338.6 56.1004 341.9 56.1004H382.1C385.3 56.1004 388.1 53.3004 388.1 50.1004Z" fill="#7A7A7A" />
			<path d="M341.9 3.9C338.6 3.9 335.9 6.6 335.9 9.9V50.1C335.9 53.4 338.6 56.1 341.9 56.1H382.1C385.4 56.1 388.1 53.4 388.1 50.1V9.9C388.1 6.6 385.4 3.9 382.1 3.9H341.9ZM341.9 0H382.1C387.6 0 392 4.4 392 9.9V50.1C392 55.6 387.6 60 382.1 60H341.9C336.4 60 332 55.6 332 50.1V9.9C332 4.4 336.4 0 341.9 0Z" fill="white" />
			<path d="M427.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.7001 176 34.1001 176H428C433.5 176 438 171.5 438 166V34C438 28.5 433.4 24 427.9 24Z" fill="#7A7A7A" />
			<path d="M427.9 20H34.1C26.3 20 20 26.3 20 34V166C20 173.7 26.3 180 34.1 180H428C435.8 180 442.1 173.7 442.1 166V34C442 26.3 435.7 20 427.9 20ZM438 166C438 171.5 433.4 176 428 176H34.1C28.6 176 24.1 171.5 24.1 166V34C24.1 28.5 28.7 24 34.1 24H428C433.5 24 438 28.5 438 34V166Z" fill="white" />
			<path className="colorTable" d="M427.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.7001 176 34.1001 176H428C433.5 176 438 171.5 438 166V34C438 28.5 433.4 24 427.9 24ZM427.3 165.3H34.7001V34.7H427.2V165.3H427.3Z" fill="#38CD90" />
		</svg>

	)
}
Table8ChairsSvg.displayName = 'Table8ChairsSvg'
