import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const PriceWrapper = styled.span`
  font-size: 0.88rem;
  display: block;
  width: min-content;
  height: auto;
  padding: 0.14em 0.5em;
  border-radius: 0.86em;
  background-color: ${colorTheme.price.background};
  font-family: ${theme.fontRobotoBold};
  color: ${colorTheme.price.text};
  letter-spacing: -0.05em;
  text-align: center;
  border: 1px solid ${colorTheme.price.border};
  transition: all .2s;

	&.gray-dark {
		background-color: ${colorTheme.price.grayDark.background};
		border-color: ${colorTheme.price.grayDark.border};
	}

	&.blue {
		background-color: ${colorTheme.price.blue.background};
		border-color: ${colorTheme.price.blue.border};
	}

  &.active {
    background: ${colorTheme.price.active.background};
    border-color: ${colorTheme.price.active.border};
    color: ${colorTheme.price.active.text};
  }

  &.small {
    font-size: 0.75rem;
    font-family: ${theme.fontRobotoMedium};
    font-weight: 500;
  }

  &.medium {
    font-size: 1.13rem;
    font-family: ${theme.fontRobotoMedium};
		border-radius: 0.67em;
		height: 1.5em;
  }

  &.big {
    font-size: 1.13rem;
    font-family: ${theme.fontRobotoMedium};
  }

  @media screen and (max-width: ${theme.point820}) {
    &.medium {
			font-size: 1.29rem;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    &.medium {
      font-size: 1.13rem;
    }

    &.big {
      font-size: 1.13rem;
    }
  }
`
