import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const CategoriesOfCashBook = lazyWithRetry(() => import(/* webpackChunkName: "categories-of-cashbook" */'../../component/CategoriesOfCashBook'))

const CategoriesOfCashBookPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<CategoriesOfCashBook />
		</Suspense>
	)
})

CategoriesOfCashBookPage.displayName = 'CategoriesOfCashBookPage'
export default CategoriesOfCashBookPage
