const mapPostalCode = (res) => {
	const filterByPostalCode = res.map(item => item.address.postalCode ?
		item : { ...item, address: { ...item.address, postalCode: '00000' } })
	return filterByPostalCode
}

export const filterAutocompleteSearchResult = (res) => {
	const filteredByStreet = res.filter(item => item.address.street)
	return mapPostalCode(filteredByStreet)
}
