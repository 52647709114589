import { setSocketPrinterDesktopSessions } from '../../redux/action/socket'
import { setPrintersUpdateTrigger } from '../../redux/action/printer'
import { setAppModal, setAppModalData } from '../../redux/action/system'
import { setStripeReportPrintMessageData } from '../../redux/action/report'

export const processPrinterMessageThunk = (message) => (dispatch, getState) => {
  const state = getState()
  switch (message?.type) {
    case 'printer.DESKTOP_SESSION_LIST_PROVIDED_EVENT':
      dispatch(setSocketPrinterDesktopSessions(message.data))
      break
    case 'printer.PRINTERS_UPDATED_EVENT':
      if (window.location.href.includes('/settings/printer')) {
        dispatch(setPrintersUpdateTrigger(state.printer.printersUpdateTrigger + 1))
      }
      break
    case 'printer.FRONTEND_PRINT_REQUESTED_EVENT':
			const stripeReportToPrint = state.reportStore.stripeReportToPrint
			if (window.location.href.includes('/report/monthly') && stripeReportToPrint) {
        dispatch(setStripeReportPrintMessageData(message.data))
      } else {
				dispatch(setAppModal({ printMessageModalShow: true }))
				const modalData = state.system.modalData?.printMessageModalData
				dispatch(setAppModalData({ printMessageModalData: [...modalData, message.data] }))
			}
      break
    default:
      // eslint-disable-next-line
      console.error('Unknown printer message type: ', JSON.stringify(message))
      break
  }
}
