import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SendToKitchenModalHeader from './header'
import { SendToKitchenHeaderTitle, SendToKitchenItems, SendToKitchenItemsBlock, SendToKitchenItemsDivider, SendToKitchenItemsHeader, SendToKitchenModalWrapper } from './index.styled'
import KitchenStatusLegend from './legend'
import CartItem from './CartItem'
import { getItemDirection, getMoveToKitchenOrderItems, getSplittedAllOrderItems, getSplittedPlacedOrderItems, getSplittedPreparedOrderItems, isDisableMoveToKitchen, sortOrdersByDishCourseIndex, updateKitchenItems } from './helper'
import { selectCurrentOrdersInTableCart, selectProcessedBy, selectTableOrdersAndSplits, selectTotalPriceOnTable } from '../../../redux/selector/restaurant'
import CustomFullScreenModal from '../../Elements/modal/CustomFullScreenModal'
import Button from '../../Elements/buttons/Button'
import CircleLoader from '../../Elements/loaders/CircleLoader'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import { fetchTableOrdersThunk, moveTableOrderToKitchenThunk, updateTableOrderItemStatusThunk } from '../../../redux/thunk/restaurant/order'
import { setRestaurantShowSendToKitchenModal, setRestaurantTable } from '../../../redux/action/restaurant'
import { ARROW_LEFT, ARROW_RIGHT } from './constants'
import ActionModal from '../../Elements/modal/ActionModal'
import { fetchTableSplitsThunk } from '../../../redux/thunk/restaurant/split'
import { setLoadingItemId, setTableCartOrders, setTableCartSplits } from '../../../redux/action/tableCart'

const Z_INDEX = 92
const ACTION_MODAL_Z_INDEX = 93

const SendToKitchenModal = React.memo(({
	tableCart = false, createOrder = () => {
	},
}) => {
	const { t } = useTranslation()
	const showSendToKitchenModal = useSelector(state => state.restaurant.showSendToKitchenModal)
	const selectedTable = useSelector(state => state.restaurant.table)
	const tableCartOrders = useSelector(selectCurrentOrdersInTableCart)
	const ordersAndSplits = useSelector(selectTableOrdersAndSplits)
	const enabledDishCourse = useSelector(store => store.restaurantSettings.dishCourses?.enabled)
	const tableTotalPrice = useSelector(selectTotalPriceOnTable)
	const { accessToken: waiterAccessToken } = useSelector(state => state.modalLogin)
	const hasModalLogin = !!waiterAccessToken
	const processedBy = useSelector(selectProcessedBy)
	const dispatch = useDispatch()
	const isTablet = useWindowBreakPoint(theme.point820)
	const [allKitchenItems, setAllKitchenItems] = useState([])
	const [availableKitchenItems, setAvailableKitchenItems] = useState([])
	const [placedKitchenItems, setPlacedKitchenItems] = useState([])
	const [loading, setLoading] = useState(false)
	const [showQuestionKitchenModal, setShowQuestionKitchenModal] = useState(false)
	const orders = tableCart ? tableCartOrders : ordersAndSplits
	const allProductsHeaderLayout = enabledDishCourse ? 'with-dish-course' : ''
	const sendToKitchenHeaderLayout = enabledDishCourse ? 'with-dish-course-and-status' : 'with-status'
	const sortedAvailableKitchenItems = availableKitchenItems.sort(sortOrdersByDishCourseIndex)
	const disableMoveToKitchen = isDisableMoveToKitchen(allKitchenItems)

	useEffect(() => {
		if (showSendToKitchenModal && selectedTable?.id) {
			dispatch(fetchTableOrdersThunk(selectedTable?.id))
			dispatch(fetchTableSplitsThunk(selectedTable?.id))
		}
	}, [showSendToKitchenModal, selectedTable?.id]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showSendToKitchenModal && !!orders.length) {
			setAllKitchenItems(getSplittedAllOrderItems(orders))
			setAvailableKitchenItems(getSplittedPlacedOrderItems(orders))
			setPlacedKitchenItems(getSplittedPreparedOrderItems(orders))
		}
	}, [showSendToKitchenModal, !orders.length]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleReset = useCallback(() => {
		setAllKitchenItems([])
		setAvailableKitchenItems([])
		setPlacedKitchenItems([])
	}, [])

	const handleClose = useCallback(() => {
		handleReset()
		dispatch(setRestaurantShowSendToKitchenModal(false))
		dispatch(setRestaurantTable(null))
		dispatch(setTableCartOrders([]))
		dispatch(setTableCartSplits([]))
	}, [dispatch, handleReset])

	const placeItemToKitchen = useCallback((event, item) => {
		event?.stopPropagation()
		const updatedAvailableKitchenItems = availableKitchenItems.filter(avItem => avItem.item.itemPositionId !== item.item.itemPositionId)
		setAvailableKitchenItems(updatedAvailableKitchenItems)
		setPlacedKitchenItems(prevState => [...prevState, item])
	}, [availableKitchenItems])

	const removeItemFromKitchen = useCallback((event, item) => {
		event?.stopPropagation()
		const updatedPlacedKitchenItems = placedKitchenItems.filter(avItem => avItem.item.itemPositionId !== item.item.itemPositionId)
		setPlacedKitchenItems(updatedPlacedKitchenItems)
		setAvailableKitchenItems(prevState => [...prevState, item])
	}, [placedKitchenItems])

	const handleDirectionClick = useCallback((event, item, direction) => {
		if (!direction) return

		if (direction === ARROW_RIGHT) {
			placeItemToKitchen(event, item)
		} else if (direction === ARROW_LEFT) {
			removeItemFromKitchen(event, item)
		}
	}, [placeItemToKitchen, removeItemFromKitchen])

	const handleCreateNewOrder = useCallback(async (newOrder, sendAllToKitchen = false) => {
		const orderItems = newOrder.items.map(orderItem => {
			const isNeededToPlaceKitchenItem = sendAllToKitchen || !!placedKitchenItems.find(item => item.item.itemPositionId === orderItem.itemPositionId)
			return {
				...orderItem,
				kitchenMeta: {
					toKitchen: isNeededToPlaceKitchenItem,
				},
			}
		})
		await createOrder(orderItems)
	}, [placedKitchenItems, createOrder])

	const handleMoveTableOrderToKitchen = useCallback(async (readyToMoveToKitchenItems) => {
		if (readyToMoveToKitchenItems.length) {
			const preparedMove = {
				moveToKitchen: readyToMoveToKitchenItems,
				processedBy,
			}
			await dispatch(moveTableOrderToKitchenThunk(selectedTable?.id, preparedMove, hasModalLogin))
		}
	}, [dispatch, selectedTable, processedBy, hasModalLogin])

	const handleSubmit = useCallback(async (readyToMoveToKitchenItems, sendAllToKitchen = false) => {
		setLoading(true)
		if (readyToMoveToKitchenItems.length) {
			await handleMoveTableOrderToKitchen(readyToMoveToKitchenItems)
		}

		const newOrder = orders?.find(order => !order.id)
		if (newOrder) {
			await handleCreateNewOrder(newOrder, sendAllToKitchen)
		}

		setLoading(false)
		handleClose()
	}, [orders, handleClose, handleMoveTableOrderToKitchen, handleCreateNewOrder])

	const handleSendToKitchen = useCallback(async () => {
		const isExistNewOrderReadyToMoveToKitchenItems = placedKitchenItems.some(item => !item.orderId)
		const readyToMoveToKitchenItems = getMoveToKitchenOrderItems(placedKitchenItems)
		const isExistReadyToMoveToKitchenItems = !!readyToMoveToKitchenItems.length

		if (tableCart && !isExistNewOrderReadyToMoveToKitchenItems && !isExistReadyToMoveToKitchenItems) {
			setShowQuestionKitchenModal(true)
		} else {
			await handleSubmit(readyToMoveToKitchenItems)
		}
	}, [placedKitchenItems, handleSubmit, tableCart])

	const handlePrepared = useCallback(async (orderItem) => {
		dispatch(setLoadingItemId(orderItem.item.itemPositionId))
		const itemPositionId = orderItem.item.itemPositionId
		const placedItemIndex = placedKitchenItems.findIndex(plItem => plItem.item.itemPositionId === itemPositionId)
		const allItemIndex = allKitchenItems.findIndex(plItem => plItem.item.itemPositionId === itemPositionId)

		try {
			await dispatch(updateTableOrderItemStatusThunk(selectedTable?.id, orderItem.orderId, itemPositionId, 'PREPARED', hasModalLogin))
			dispatch(setLoadingItemId(''))
			const updatedItem = { ...orderItem, item: { ...orderItem.item, status: 'PREPARED' } }
			setPlacedKitchenItems(updateKitchenItems(placedKitchenItems, placedItemIndex, updatedItem))
			setAllKitchenItems(updateKitchenItems(allKitchenItems, allItemIndex, updatedItem))
		} catch (e) {
			dispatch(setLoadingItemId(''))
			setPlacedKitchenItems(updateKitchenItems(placedKitchenItems, placedItemIndex, orderItem))
			setAllKitchenItems(updateKitchenItems(allKitchenItems, allItemIndex, orderItem))
		}
	}, [dispatch, placedKitchenItems, selectedTable, hasModalLogin, allKitchenItems])

	const handleCloseQuestionKitchenModal = useCallback(() => {
		setShowQuestionKitchenModal(false)
	}, [])

	const handleAcceptQuestionKitchenModal = useCallback(async () => {
		handleCloseQuestionKitchenModal()
		const readyToMoveToKitchenItems = getMoveToKitchenOrderItems(availableKitchenItems)
		await handleSubmit(readyToMoveToKitchenItems, true)
	}, [handleCloseQuestionKitchenModal, availableKitchenItems, handleSubmit])

	return (
		<>
			<CustomFullScreenModal isOpen={showSendToKitchenModal}
														 close={handleClose}
														 customHeaderTitle={<SendToKitchenModalHeader tableNumber={selectedTable?.number || ''} tableTotalPrice={tableTotalPrice} close={handleClose} />}
														 bodyClassName={!isTablet && 'fullSizeBody'}
														 showCloseCross={false}
														 showBackButton={true}
														 footerClassname="mobile-column"
														 zIndex={Z_INDEX}
														 button={
															 <Button name="send-to-kitchen" icon={!loading && 'pan'} color="green" className="icon-auto-width"
																			 text={!loading ? t('restaurant.sendToKitchenModal.button.sendToKitchen') : t('restaurant.button_is_printing')}
																			 onClick={handleSendToKitchen}
																			 disabled={disableMoveToKitchen || loading}
																			 keyButton="F2" keyText="F2" zIndex={Z_INDEX}>
																 {loading && <CircleLoader />}
															 </Button>
														 }>
				<SendToKitchenModalWrapper>
					{isTablet ?
						<>
							<KitchenStatusLegend />
							<SendToKitchenItemsBlock data-testid="all-kitchen-items">
								<SendToKitchenItemsHeader>
									<SendToKitchenHeaderTitle>{t('restaurant.sendToKitchenModal.header.productName')}</SendToKitchenHeaderTitle>
								</SendToKitchenItemsHeader>
								<SendToKitchenItems>
									{allKitchenItems?.map((item, itemIndex) =>
										<CartItem key={itemIndex} item={item.item} index={itemIndex}
															includeStatus={true} enabledDishCourse={enabledDishCourse}
															direction={getItemDirection(item, placedKitchenItems)}
															onDirectionClick={(event, direction) => handleDirectionClick(event, item, direction)}
															onPreparedClick={() => handlePrepared(item)} />)}
								</SendToKitchenItems>
							</SendToKitchenItemsBlock>
						</> : <>
							<SendToKitchenItemsBlock data-testid="available-kitchen-items">
								<SendToKitchenItemsHeader className={allProductsHeaderLayout}>
									<SendToKitchenHeaderTitle className={allProductsHeaderLayout}>{t('restaurant.sendToKitchenModal.header.productName')}</SendToKitchenHeaderTitle>
								</SendToKitchenItemsHeader>
								<SendToKitchenItems>
									{sortedAvailableKitchenItems?.map((item, itemIndex) =>
										<CartItem key={itemIndex} item={item.item} index={itemIndex}
															enabledDishCourse={enabledDishCourse}
															direction={ARROW_RIGHT}
															onDirectionClick={(event, direction) => handleDirectionClick(event, item, direction)}
															onPreparedClick={() => handlePrepared(item)} />)}
								</SendToKitchenItems>
							</SendToKitchenItemsBlock>
							<SendToKitchenItemsDivider />
							<SendToKitchenItemsBlock data-testid="placed-kitchen-items">
								<SendToKitchenItemsHeader className={sendToKitchenHeaderLayout}>
									<SendToKitchenHeaderTitle className={sendToKitchenHeaderLayout}>{t('restaurant.sendToKitchenModal.header.productName')}</SendToKitchenHeaderTitle>
								</SendToKitchenItemsHeader>
								<SendToKitchenItems>
									{placedKitchenItems?.map((item, itemIndex) =>
										<CartItem key={itemIndex} item={item.item} index={itemIndex}
															includeStatus={true} enabledDishCourse={enabledDishCourse}
															direction={ARROW_LEFT}
															onDirectionClick={(event, direction) => handleDirectionClick(event, item, direction)}
															onPreparedClick={() => handlePrepared(item)} />)}
								</SendToKitchenItems>
							</SendToKitchenItemsBlock>
						</>}
				</SendToKitchenModalWrapper>
			</CustomFullScreenModal>

			<ActionModal modalIsOpen={showQuestionKitchenModal}
									 accept={handleAcceptQuestionKitchenModal}
									 cancel={handleCloseQuestionKitchenModal}
									 close={handleCloseQuestionKitchenModal}
									 modalTitle={t('restaurant.sendToKitchenModal.questionModal.title')}
									 modalBody={t('restaurant.sendToKitchenModal.questionModal.body')}
									 zIndex={ACTION_MODAL_Z_INDEX} />
		</>
	)
})

SendToKitchenModal.displayName = 'SendToKitchenModal'
export default SendToKitchenModal
