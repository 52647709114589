import React from 'react'
import { useTranslation } from 'react-i18next'
import { TimeWrapper, TimeError, PeriodWrapper } from './index.styled'
import TimeInput from '../../../../Elements/inputs/TimeInput'

const TimePeriods = React.memo(({ period, array, i, onChangeTime, firstInputName, secondInputName, firstInputId, secondInputId, error, readOnly = false, disabled, onBlurTime }) => {
	const { t } = useTranslation()
	const layout = (disabled ? 'disabled ' : '') + (i === 1 ? 'margin ' : '') + (error ? 'error ' : '')

	return (
		array.length > 1 ?
			<TimeWrapper key={i} className={layout}>
				<TimeError className={layout}>{t('WebShopWorkingHours.timeError')}</TimeError>
				<PeriodWrapper className={layout}>
					<TimeInput name={firstInputName}
						id={firstInputId}
						value={period?.openingTime}
						onChange={onChangeTime}
						onBlur={onBlurTime}
						error={error}
						disabled={disabled}
						readOnly={readOnly} />
					-
					<TimeInput name={secondInputName}
						id={secondInputId}
						value={period?.closingTime}
						onChange={onChangeTime}
						onBlur={onBlurTime}
						error={error}
						disabled={disabled}
						readOnly={readOnly} />
				</PeriodWrapper>
			</TimeWrapper> :
			<React.Fragment key={i}>
				<TimeWrapper />
				<TimeWrapper className={layout + 'margin'}>
					<TimeError className={layout}>{t('WebShopWorkingHours.timeError')}</TimeError>
					<PeriodWrapper className={layout}>
						<TimeInput name={firstInputName}
							id={firstInputId}
							value={period?.openingTime}
							onChange={onChangeTime}
							onBlur={onBlurTime}
							error={error}
							disabled={disabled}
							readOnly={readOnly} />
						-
						<TimeInput name={secondInputName}
							id={secondInputId}
							value={period?.closingTime}
							onChange={onChangeTime}
							onBlur={onBlurTime}
							error={error}
							disabled={disabled}
							readOnly={readOnly} />
					</PeriodWrapper>
				</TimeWrapper>
			</React.Fragment>)
})

TimePeriods.displayName = 'TimePeriods'
export default TimePeriods
