import cashDeskInstance from './instance'

const updateClientMonitorCart = (desktopDeviceId, purchaseItems, payments) => {
	return cashDeskInstance.put(`/cartState/${desktopDeviceId}`, {
		purchaseItems,
		payments
	})
}

export default updateClientMonitorCart
