import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { TableTrStyled } from '../../tr/index.styled'

export const TableWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	border-top-left-radius: 0.6em;
	position: relative;
	${props => props.isScrollBody && `
		max-height: 65vh;
		height: 100%;
		overflow: auto;
  `};

	@media screen and (max-width: ${theme.point820}) {
		box-shadow: none;
		${props => props.isScrollBody && `
			overflow: initial;
  	`};
	}
`

export const Table = styled.table`
	width: 100%;
	border-collapse: separate;
	font-size: 1.25rem;
	font-weight: 400;

	@media screen and (max-width: ${theme.point820}) {
		display: block;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1rem;
	}
`

export const TableBody = styled.tbody`
	@media screen and (max-width: ${theme.point820}) {
		display: block;
		width: 100%;
		gap: 0;

		&.platform {
			gap: 1.25em;
		}
	}
`

export const ShortTableWrapper = styled.div`
	width: 100%;
	max-width: 73.75em;

	&.small {
		max-width: 55em;
	}

	@media screen and (max-width: ${theme.point1280}) {
		max-width: 100%;
	}

	@media screen and (max-width: ${theme.point1024}) {
		&.small {
			max-width: 100%;
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		margin-top: 4.4em;

		&.small {
			margin-top: 0;
		}

		${TableTrStyled} {
			padding-top: 0;
		}
	}
`

ShortTableWrapper.displayName = 'ShortTableWrapper'
