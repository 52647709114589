import { RE_INITIALIZE_CART_CATEGORY, SET_CART_CATEGORY_CATEGORIES, SET_CART_CATEGORY_CATEGORY_ID, SET_CART_CATEGORY_PRODUCT_TYPE } from '../../actionTypes/cartCategory'

const initialState = {
  categories: [],
  productType: null,
  categoryId: null,
}

export function cartCategoryReducer (state = initialState, action) {
  switch (action.type) {
    case RE_INITIALIZE_CART_CATEGORY:
      return initialState
    case SET_CART_CATEGORY_CATEGORIES:
      return {
        ...state,
        categories: action.value,
      }
    case SET_CART_CATEGORY_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.value,
      }
    case SET_CART_CATEGORY_CATEGORY_ID:
      return {
        ...state,
        categoryId: action.value,
      }
    default:
      return state
  }
}


