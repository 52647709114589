import { SET_ORDER_USER_MODAL, SET_SELECTED_USER, SET_SHOW_CANCEL_ORDER, SET_SHOW_ORDER, SET_SHOW_ORDER_DATA, SET_SHOW_SINGLE_DRIVER } from '../../actionTypes/driver-monitor'

export const setOrderModal = showSelectedUser => ({ type: SET_ORDER_USER_MODAL, showSelectedUser })

export const setSelectedUser = user => ({ type: SET_SELECTED_USER, user })

export const setShowCancelOrder = content => ({
  type: SET_SHOW_CANCEL_ORDER,
  payload: {
    content,
  },
})

export const setShowOrder = content => ({
  type: SET_SHOW_ORDER,
  payload: {
    content,
  },
})

export const setShowOrderData = content => ({
  type: SET_SHOW_ORDER_DATA,
  payload: {
    content,
  },
})

export const setShowSingleDriver = content => ({
  type: SET_SHOW_SINGLE_DRIVER,
  payload: {
    content,
  },
})

