import { SET_WEBSHOP_WORKING_HOURS_CONFIG, SET_WEBSHOP_PREORDER_DELAY, SET_WEBSHOP_SPECIAL_DAYS, SET_WEBSHOP_SPECIAL_DAYS_TOTAL_LENGTH, ADD_WEBSHOP_SPECIAL_DAYS, REMOVE_WEBSHOP_SPECIAL_DAY, SET_WEBSHOP_SPECIAL_DAY_PERIODS_ERROR, DELETE_WEBSHOP_DAY_WITH_PERIODS_ERROR, ADD_WEBSHOP_DAY_WITH_PERIODS_ERROR  } from '../../actionTypes/webshopSchedule'

const initialState = {
	workingHoursConfig: [],
	preOrderDelay: null,
	specialDays: [],
	specialDaysTotalLength: 0,
	specialDayPeriodsError: [],
	daysWithPeriodsError: {}
}

export function webshopScheduleReducer (state = initialState, action) {
	switch (action.type) {
		case SET_WEBSHOP_WORKING_HOURS_CONFIG:
			return {
				...state,
				workingHoursConfig: action.value,
			}
		case SET_WEBSHOP_PREORDER_DELAY:
			return {
				...state,
				preOrderDelay: action.value,
			}
		case SET_WEBSHOP_SPECIAL_DAYS:
			return {
				...state,
				specialDays: action.value,
			}
		case SET_WEBSHOP_SPECIAL_DAYS_TOTAL_LENGTH:
			return {
				...state,
				specialDaysTotalLength: action.value,
			}
		case ADD_WEBSHOP_SPECIAL_DAYS:
			return {
				...state,
				specialDays: state.specialDays ? state.specialDays.concat(...action.value) : action.value,
			}
		case REMOVE_WEBSHOP_SPECIAL_DAY:
			let specialDaysRemoveCopy = [...state.specialDays]
			specialDaysRemoveCopy = specialDaysRemoveCopy.filter(day => day.id !== action.value)

			return {
				...state,
				specialDays: specialDaysRemoveCopy,
			}
		case SET_WEBSHOP_SPECIAL_DAY_PERIODS_ERROR:
			return {
				...state,
				specialDayPeriodsError: action.value,
			}
		case ADD_WEBSHOP_DAY_WITH_PERIODS_ERROR:
			return {
				...state,
				daysWithPeriodsError: { ...state.daysWithPeriodsError, ...action.value },
			}
		case DELETE_WEBSHOP_DAY_WITH_PERIODS_ERROR:
			const daysWithPeriodsErrorCopy = { ...state.daysWithPeriodsError }
			delete daysWithPeriodsErrorCopy[action.value]

			return {
				...state,
				daysWithPeriodsError: daysWithPeriodsErrorCopy,
			}
		default:
			return state
	}
}
