export const getPaymentBusinessScope = (orderType) => {
	switch (orderType) {
		case 'DELIVERY':
			return 'DELIVERY'
		case 'RESTAURANT':
			return 'RESTAURANT'
		default:
			return 'CASH_REGISTER'
	}
}
