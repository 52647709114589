import { SET_COMPANY_ACTIVATION_DATA } from '../../actionTypes/company'

const initialState = {
	activationData: null
}

export function companyReducer (state = initialState, action) {
	switch (action.type) {
		case SET_COMPANY_ACTIVATION_DATA:
			return {
				...state,
				activationData: action.value
			}
		default:
			return state
	}
}


