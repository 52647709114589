import { getNeededDateFormat } from '../../../../../helper'
import React from 'react'
import { TableTdGrid } from '../../style/table/td/index.styled'

const InvoiceDate = React.memo(({ item }) => {
	return (
		<TableTdGrid>
			{item.createDate && <div>{getNeededDateFormat(item.createDate, 'dd.MM.yyyy HH:mm')}</div>}
		</TableTdGrid>
	)
})

InvoiceDate.displayName = 'InvoiceDate'
export default InvoiceDate
