import { ADD_MAP_MARKER, ADD_MAP_MARKERS, RE_INITIALIZE_MAP, REMOVE_MAP_MARKER, SET_MAP_COORDINATES_MARKER, SET_MAP_HEIGHT, SET_MAP_HEIGHT_HEADER, SET_MAP_MARKERS, SET_MAP_MARKERS_STATUS, SET_MAP_MARKERS_TOTAL_LENGTH, SET_MAP_ORDER_ID_OF_MARKER, SET_MAP_POSITIONS, SET_MAP_SELECTED_WOLT_DRIVER_DELIVERY, SET_MAP_WIDTH, SET_MAP_WIDTH_MENU, SET_MAP_WOLT_DRIVER_DELIVERIES, UPDATE_MAP_MARKER, UPDATE_MAP_POSITION, UPDATE_MAP_SELECTED_WOLT_DRIVER_DELIVERY, UPDATE_MAP_WOLT_DRIVER_DELIVERIES } from '../../actionTypes/map'

export const reInitializeMap = () => ({ type: RE_INITIALIZE_MAP })

export const setMapMarkers = value => ({ type: SET_MAP_MARKERS, value })

export const addMapMarkers = value => ({ type: ADD_MAP_MARKERS, value })

export const setMapMarkersTotalLength = value => ({ type: SET_MAP_MARKERS_TOTAL_LENGTH, value })

export const addMapMarker = value => ({ type: ADD_MAP_MARKER, value })

export const updateMapMarker = value => ({ type: UPDATE_MAP_MARKER, value })

export const removeMapMarker = value => ({ type: REMOVE_MAP_MARKER, value })

export const setMapPositions = value => ({ type: SET_MAP_POSITIONS, value })

export const updateMapPosition = value => ({ type: UPDATE_MAP_POSITION, value })

export const setMapCoordinatesMarker = value => ({ type: SET_MAP_COORDINATES_MARKER, value })

export const setMapOrderIdOfMarker = value => ({ type: SET_MAP_ORDER_ID_OF_MARKER, value })

export const setMapMarkersStatus = value => ({ type: SET_MAP_MARKERS_STATUS, value })

export const setMapWidth = value => ({ type: SET_MAP_WIDTH, value })

export const setMapHeight = value => ({ type: SET_MAP_HEIGHT, value })

export const setMapWidthMenu = value => ({ type: SET_MAP_WIDTH_MENU, value })

export const setMapHeightHeader = value => ({ type: SET_MAP_HEIGHT_HEADER, value })

export const setMapSelectedWoltDriverDelivery = value => ({ type: SET_MAP_SELECTED_WOLT_DRIVER_DELIVERY, value })

export const updateMapSelectedWoltDriverDelivery = value => ({ type: UPDATE_MAP_SELECTED_WOLT_DRIVER_DELIVERY, value })

export const setMapWoltDriverDeliveries = value => ({ type: SET_MAP_WOLT_DRIVER_DELIVERIES, value })

export const updateMapWoltDriverDeliveries = value => ({ type: UPDATE_MAP_WOLT_DRIVER_DELIVERIES, value })


