import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { InputError } from '../TextInput/index.styled'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const TextAreaWrapper = styled.div`
	font-size: 1rem;
	position: relative;

	&.error, &.error-big {
		background-color: ${colorTheme.input.textarea.error.background};
		border-radius: 0.75em;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.error, &.error-big {
			border-radius: 0.86em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.error, &.error-big {
			border-radius: 0.75em;
		}
	}
`
TextAreaWrapper.displayName = 'TextAreaWrapper'

export const TextAreaError = styled(InputError)`
`

TextAreaError.displayName = 'TextAreaError'

export const TextAreaLabel = styled.label`
	display: grid;
	height: 9em;
	padding: .93em 1.31em;
	background: ${colorTheme.input.textarea.background};
	border-radius: 0.75em;
	cursor: pointer;
	border: 1px solid transparent;
	box-sizing: border-box;

	&.gray {
		border: 1px solid ${colorTheme.input.textarea.gray.border};
	}

	&.focus {
		border-color: ${colorTheme.input.textarea.focus.border};
		box-shadow: 0 4px 27px rgba(0, 0, 0, 0.22);
	}

	&.error,&.error-big {
		border: 1px solid ${colorTheme.input.textarea.error.border};
	}

	@media screen and (max-width: ${theme.point820}) {
		border-radius: 0.86em;
		height: 14.64em;
		padding: .65em 1.5em;

		&.small {
			height: 8.57em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		border-radius: 0.75em;
		height: 12.81em;
		padding: .56em 1.31em;

		&.small {
			height: 8.13em;
		}
	}
`
TextAreaLabel.displayName = 'TextAreaLabel'

export const TextAreaLabelText = styled.div`
	font-size: 0.88em;
	color: ${colorTheme.input.textarea.text};
	text-transform: uppercase;
	line-height: 1.14em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	transition: all 0.2s ease-in-out;
	text-align: left;

	&.toggled {
		transform: translateY(0.45em);
		font-size: 1.13em;
		line-height: 1.16em;
	}

	&.focus {
		color: ${colorTheme.input.textarea.focus.text};
	}

	&.error, &.error-big {
		color: ${colorTheme.input.textarea.error.text};
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1em;

		&.toggled {
			font-size: 1.28em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 0.88em;
		line-height: 1.14em;

		&.toggled {
			font-size: 1.13em;
		}
	}
`
TextAreaLabelText.displayName = 'TextAreaLabelText'

export const TextAreaField = styled.textarea`
	display: block;
	resize: none;
	font-size: 1.13em;
	background: transparent;
	width: 100%;
	border: none;
	border-radius: 0;
	outline: none;
	font-weight: 500;
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.input.textarea.field};
	text-align: left;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.28em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;
	}
`
TextAreaField.displayName = 'TextAreaField'
