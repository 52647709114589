import InvoiceConfigsModal from './InvoiceConfigsModal'
import React from 'react'
import InvoiceCustomerDataModal from './InvoiceCustomerDataModal'

const InvoiceModals = React.memo(({
	invoiceConfigsModalIsOpen, closeInvoiceConfigsModal, submitInvoiceConfig,
	invoiceCustomerDataModalIsOpen, closeInvoiceCustomerDataModal, handlePayment,
	captureCustomerData = false,
}) => {
	return (
		<>
			<InvoiceConfigsModal isOpen={invoiceConfigsModalIsOpen}
													 close={closeInvoiceConfigsModal}
													 onSubmitData={submitInvoiceConfig} />

			<InvoiceCustomerDataModal isOpen={invoiceCustomerDataModalIsOpen}
																close={closeInvoiceCustomerDataModal}
																handlePayment={handlePayment}
																captureCustomerData={captureCustomerData} />
		</>

	)
})

InvoiceModals.displayName = 'InvoiceModals'
export default InvoiceModals
