import { addZReport, appendZReportsForToday, setZReport, setZReportCashData, setZReportsTotalLength, setZReportTestIsLoading } from '../../redux/action/zreport'
import { setCashBookNewCashTotal } from '../../redux/action/cashbookNew'

export const processFiscalMessageThunk = (message) => (dispatch, getState) => {
	switch (message?.type) {
		case 'fiscal.Z_REPORT_CREATED_EVENT':
			if (window.location.pathname === '/report') {
				addZReportToTableForToday(message.data, dispatch, getState)
				addZReportToTable(message.data, dispatch, getState)

			}
			break
		case 'fiscal.TEST_Z_REPORT_CREATED_EVENT':
			if (window.location.pathname === '/report') {
				dispatch(setZReportTestIsLoading(false))
				dispatch(setZReport({ ...message.data, isTest: true }))
			}
			if (window.location.pathname === '/report/cashbook') {
				dispatch(setCashBookNewCashTotal(message.data.cashTotal))
			}
			break
		case 'fiscal.TEST_Z_REPORT_CASH_DATA_CREATED_EVENT':
			if (window.location.pathname === '/report') {
				dispatch(setZReportCashData({ ...message.data }))
			}
			break
		default:
			// eslint-disable-next-line
			console.error('Unknown fiscal message type: ', JSON.stringify(message))
			break
	}
}

const addZReportToTableForToday = (data, dispatch, getState) => {
	const reports = getState().zReport.reportsForToday
	const existTransaction = reports.find(report => report.id === data.id)
	// check if order was already added to the table by http requests
	if (!existTransaction) {
		dispatch(appendZReportsForToday(data))
	}
}

const addZReportToTable = (data, dispatch, getState) => {
	const reports = getState().zReport.reports
	const existTransaction = reports.find(report => report.id === data.id)
	// check if order was already added to the table by http requests
	if (!existTransaction) {
		dispatch(addZReport(data))
		dispatch(setZReportsTotalLength(getState().zReport.reportsTotalLength + 1))
	}
}
