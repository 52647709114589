import styled from 'styled-components'
import { theme } from '../../../../../../../style/theme'
import colors from '../../../../../../../style/colors'

export const InfoOrderWrap = styled.div`
	font-size: 1rem;
	position: absolute;
	left: 20px;
	bottom: 20px;
	width: 334px;
	padding: 28px 18px 20px;
	background-color: #fff;
	border: 2px solid ${colors.gray_lighter};
	border-radius: 10px;
	z-index: 20;

	&::before {
		content: '';
		display: block;
		position: absolute;
		transform: translateX(-50%);
		width: 0;
		height: 0;
	}
;

	&::after {
		content: '';
		display: block;
		position: absolute;
		transform: translateX(-50%);
		width: 0;
		height: 0;
	}
;

	@media screen and (max-width: ${theme.point1280}) {
		width: 223px;
		padding: 19px 12px 13px;
	}
`
InfoOrderWrap.displayName = 'InfoOrderWrap'

export const InfoOrderBody = styled.div``
InfoOrderBody.displayName = 'InfoOrderBody'

export const InfoOrderData = styled.div`
	display: flex;
	align-items: center;
	gap: 7px;
	padding-bottom: 10px;
	width: 110px;

	svg {
		width: 19px;
		height: 19px;
		fill: ${colors.gray_lighter};
		flex-shrink: 0;
	}

	&.big > svg {
		width: 43px;
		height: 26px;
	}

	&.big {
		font-weight: 700;
	}

	&.blue {
		svg {
			fill: ${colors.blue};
		}
	}

	&:last-child {
		width: 100%;
	}


	@media screen and (max-width: ${theme.point1280}) {
		gap: 4px;
		svg {
			width: 12px;
			height: 12px;
		}

		&.big > svg {
			width: 29px;
			height: 17px;
		}
	}
`
InfoOrderData.displayName = 'InfoOrderData'

export const InfoOrderText = styled.div`
  font-size: 14px;
  font-family: ${theme.fontRoboto};

  &.medium {
    font-family: ${theme.fontRobotoMedium};
  }

  &.spacing {
    font-family: ${theme.fontRobotoMedium};
    font-size: 13px;
    letter-spacing: -0.7px;
  }

  &.big {
    font-size: 24px;
  }

  &.blue {
    color: ${colors.blue};
  }

	@media screen and (max-width: ${theme.point1280}) {
		font-size: 9px;
		&.medium {
			font-size: 10.7px;
		}

    &.big {
      font-size: 16px;
    }
  }
`

InfoOrderText.displayName = 'InfoOrderText'

export const InfoOrderTitle = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	display: flex;
	flex-direction: column;
	padding: 7px 10px;
	gap: 5px;
	border: 1px dashed ${colors.gray};
	border-radius: 5px;

	svg {
		width: 18px;
		height: 18px;
		fill: ${colors.blue};
	}


	@media screen and (max-width: ${theme.point1280}) {
		padding: 4px 7px;
		width: 85.6px;
		border-radius: 3px;

		svg {
			width: 12px;
			height: 12px;
		}
	}

`
InfoOrderTitle.displayName = 'InfoOrderTitle'

export const InfoOrderTime = styled(InfoOrderData)`
	display: grid;
	gap: 4px;
	grid-template-columns: 14px 80%;
	color: ${colors.blue};
	padding-bottom: 0;

	&.warning {
		${InfoOrderText} {
			color: #DE7B00;
			width: max-content;
		}
		svg {
			fill: #DE7B00;
		}
	}

	svg {
		width: 14px;
		height: 14px;
		fill: ${colors.blue};
	}

	@media screen and (max-width: ${theme.point1280}) {
		grid-template-columns: 9px 80%;
		gap: 3px;
		svg {
			width: 9px;
			height: 9px;
		}

		&.warning {
			${InfoOrderText} {
				font-size: 8px;
			}
		}

	}

`
InfoOrderTime.displayName = 'InfoOrderTime'

export const InfoOrderCounter = styled(InfoOrderData)`
	padding-bottom: 0;
	width: max-content;
`
InfoOrderCounter.displayName = 'InfoOrderCounter'

export const InfoOrderFooter = styled.div`
	font-size: 1rem;
	padding-top: 16px;
	border-top: 1px solid #A5A9B0;
	display: flex;
	gap: 7px;
	justify-content: center;
	align-items: center;

	svg {
		width: 28px;
		height: 28px;
		fill: ${colors.gray_lighter};
	}

	${InfoOrderText} {
		font-weight: 500;
	}

	@media screen and (max-width: ${theme.point1280}) {
		padding-top: 10.7px;
		gap: 4px;
		svg {
			width: 19px;
			height: 19px;
		}
	}

`
InfoOrderFooter.displayName = 'InfoOrderFooter'
