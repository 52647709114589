export const extractQueryParam = (paramKey) => {
	const queryString = window.location.search
	if (!queryString) {
		return null
	}
	const params = new URLSearchParams(queryString)
	return params.get(paramKey)
}

export const isClientMonitor = () => window.location.pathname === '/client-monitor'

export const withSocketClientMonitor = () => {
	return true
}

export const withJsClientMonitor = () => {
	return false
}

export const setLocalStorageAccessToken = (accessToken) => {
	if (isClientMonitor()) {
		localStorage.accessTokenClientMonitor = accessToken
	} else {
		localStorage.accessToken = accessToken
	}
}

export const setLocalStorageRefreshToken = (refreshToken) => {

	if (isClientMonitor()) {
		localStorage.refreshTokenClientMonitor = refreshToken
	} else {
		localStorage.refreshToken = refreshToken
	}
}

export const getLocalStorageAccessToken = () => {
	if (isClientMonitor()) {
		return localStorage.accessTokenClientMonitor
	} else {
		return localStorage.accessToken
	}
}

export const getLocalStorageRefreshToken = () => {
	if (isClientMonitor()) {
		return localStorage.refreshTokenClientMonitor
	} else {
		return localStorage.refreshToken
	}
}

export const removeLocalStorageAccessToken = () => {
	if (isClientMonitor()) {
		return localStorage.removeItem('accessTokenClientMonitor')
	} else {
		return localStorage.removeItem('accessToken')
	}
}

export const removeLocalStorageRefreshToken = () => {
	if (isClientMonitor()) {
		return localStorage.removeItem('refreshTokenClientMonitor')
	} else {
		return localStorage.removeItem('refreshToken')
	}
}

export const removeLocalStorageAuthUuid = () => {
	return localStorage.removeItem('authUuid')
}

export const getAuthUuid = () => {
	return extractQueryParam('authUuid') || localStorage.getItem('authUuid')
}