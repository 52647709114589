import axios from 'axios'
import requestInterceptor from '../../requestInterceptor'
import responseInterceptor from '../../responseInterceptor'
import { orderServiceUrl } from '../../../../urls'
import { getLocalStorageAccessToken } from '../../../../helper/clientMonitor'

const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
}

const accessToken = getLocalStorageAccessToken()

if (accessToken) {
  headers.Authorization = `Bearer ${accessToken}`
}

const orderInstance = axios.create({
  baseURL: orderServiceUrl,
  headers,
})

orderInstance.interceptors.request.use(config => {
  return requestInterceptor(config)
}, error => Promise.reject(error))

orderInstance.interceptors.response.use(response => response, error => {
  return responseInterceptor(error)
})

export default orderInstance
