/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { clone, convertProduct2Item, getMenuProductPriceIndex, getPosPriceByIndex, getPosPriceValue, getPosPricesByOrderType, getItemsByExistPriceId } from '../../../../../helper'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import CustomModal from '../../../modal/CustomModal'
import Price from '../../../blocks/Price'
import ProductQuantity from './ProductQuantity'
import { AddButtonWrap, InputRemark, ModalFooterWrap, SizeButtonWrap, SizesBlock, ToppingsBlock, ToppingsButtonsWrapper, ToppingsWrapper, IngredientsBlock, EmptyToppingsWrapper, KeyboardButtonWrap, InputRemarkWrap } from './index.styled'
import { getCartMustToppingsThunk, getCartToppingGroupsThunk, getCartToppingsThunk } from '../../../../../redux/thunk/cartProduct'
import { setCartProductItemFocus, setCartProductMustToppingsTotal, setCartProductProductFocus, setCartProductToppingsTotal, setCartProductToppings, setCartProductMustToppings } from '../../../../../redux/action/cartProduct'
import DropDownButton from '../../../buttons/DropDownButton'
import { theme } from '../../../../../style/theme'
import Button from '../../../buttons/Button'
import { ButtonKey } from '../../../buttons/Button/style/index.styled'
import { selectIsNeededZIndex } from '../../../../../redux/selector/system'
import { useWindowBreakPoint } from '../../../../../hooks/useWindowBreakPoint'
import KeyBindings from '../../../keybindings'
import { InView, useInView } from 'react-intersection-observer'
import { TableLoader } from '../../../table/style/table/loader/index.styled'
import MainIcon from '../../../../../img/svg/main'
import RemarkWithKeyboard from './RemarkWithKeyboard'
import ReactDOM from 'react-dom'

const EVENT_Z_INDEX = 91
const PER_LOAD = 30

const ModalSize = React.memo(({ orderType, addMainDish }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const dispatch = useDispatch()
	const productFocus = useSelector(state => state.cartProduct.productFocus)
	const reduxItemFocus = useSelector(state => state.cartProduct.itemFocus)
	const mustToppings = useSelector(state => state.cartProduct.mustToppings)
	const mustToppingTotalLength = useSelector(state => state.cartProduct.mustToppingsTotal)
	const toppings = useSelector(state => state.cartProduct.toppings)
	const toppingTotalLength = useSelector(state => state.cartProduct.toppingsTotal)
	const toppingGroups = useSelector(state => state.cartProduct.toppingGroups)
	const enableQuantity = useSelector(state => state.cartProduct.enableQuantity)
	const menu = useSelector(state => state.cartProduct.menu)
	const menuProductIndex = useSelector(state => state.cartProduct.menuProductIndex)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const [activeSize, setActiveSize] = useState(0)
	const [itemFocus, setItemFocus] = useState(null)
	const [mustToppingItems, setMustToppingItems] = useState([])
	const [toppingItems, setToppingItems] = useState([])
	const [toppingGroupsItems, setToppingGroupsItems] = useState({})
	const [toppingFocus, setToppingFocus] = useState(null)
	const [showToppings, setShowToppings] = useState(false)
	const [showToppingGroups, setShowToppingGroups] = useState({})
	const [remarkText, setRemarkText] = useState('')
	const [showRemarkWithKeyboard, setShowRemarkWithKeyboard] = useState(false)
	const isTablet = useWindowBreakPoint(theme.point820)
	const isMobile = useWindowBreakPoint(theme.point720)
	const { t } = useTranslation()
	const domElement = document.getElementById('content')

	const [refToppings, inViewToppings] = useInView({
		threshold: 0
	})

	const [refMustToppings, inViewMustToppings] = useInView({
		threshold: 0
	})

	const prices = getPosPricesByOrderType(productFocus, orderType)

	const toppingPriceIndex = itemFocus?.priceIndex || 0
	const toppingPriceId = prices?.[toppingPriceIndex]?.priceTitle.id
	const isExistToppingGroupWithToppings = Object.values(toppingGroupsItems).some(group => group.length > 0)
	const showExtraToppings = !productFocus?.hideAllToppings || !isExistToppingGroupWithToppings

	const focusedOnToppings = toppingFocus !== null
	const mustToppingsToShow = useMemo(() => getItemsByExistPriceId(mustToppings, orderType, toppingPriceId), [mustToppings, orderType, toppingPriceId])
	const showMustToppings = productFocus?.numberOfMustExtras > 0 && !showToppings && showExtraToppings

	const toppingsToShow = useMemo(() => getItemsByExistPriceId(toppings, orderType, toppingPriceId), [toppings, orderType, toppingPriceId])
	const showAllToppingsButton = toppingItems?.length > 0 && showExtraToppings
	const isExistToppings = mustToppingItems?.length > 0 || toppingItems?.length > 0 || isExistToppingGroupWithToppings

	const categoryId = productFocus?.category?.id
	const toppingLength = toppings?.length
	const mustToppingLength = mustToppings?.length

	const closeModal = useCallback(() => {
		setActiveSize(0)
		setItemFocus(null)
		setMustToppingItems([])
		setToppingItems([])
		setToppingFocus(null)
		setShowToppings(false)
		setShowToppingGroups({})
		setRemarkText('')
		dispatch(setCartProductProductFocus(null))
		dispatch(setCartProductItemFocus(null))
		dispatch(setCartProductToppings([]))
		dispatch(setCartProductToppingsTotal(0))
		dispatch(setCartProductMustToppings([]))
		dispatch(setCartProductMustToppingsTotal(0))
	}, [dispatch])

	const addToCart = useCallback(() => {
		const toppingGroupsToSave = Object.keys(toppingGroupsItems).reduce((res, key) => {
			const items = toppingGroupsItems[key]
			items.forEach(el => {
				let duplicateItem = res.find(item => item.itemId === el.itemId && item.price === el.price)
				if (duplicateItem) {
					duplicateItem.quantity += el.quantity
				} else {
					res.push({ ...el })
				}
			})
			return res
		}, [])

		let subItems = [...mustToppingItems, ...toppingItems, ...toppingGroupsToSave]
		const reduxSubItems = reduxItemFocus?.items?.reduce((res, el) => {
			const result = subItems.find(itm => itm.itemId === el.itemId)
			if (result) {
				return res
			} else {
				return [...res, el]
			}
		}, []) || []

		subItems = [...subItems, ...reduxSubItems].filter(item => item.quantity !== 0)

		const mainDishItem = {
			...itemFocus,
			priceIndex: toppingPriceIndex,
			items: subItems,
			itemPositionId: reduxItemFocus?.itemPositionId || null,
			remark: remarkText.trim() || null
		}
		addMainDish(mainDishItem, reduxItemFocus)
		closeModal()
	}, [mustToppingItems, toppingItems, itemFocus, toppingPriceIndex, reduxItemFocus, remarkText, addMainDish, closeModal, toppingGroupsItems])

	const selectSize = useCallback((i, quantity = 1) => {
		setActiveSize(i)
		const price = getPosPriceByIndex(productFocus, orderType, i)
		const priceTitle = prices.length > 1 ? price.priceTitle?.title : ''
		setItemFocus(convertProduct2Item(productFocus, quantity, price.value, i, [], priceTitle))
	}, [productFocus, orderType, prices])

	const onLoadToppings = useCallback((page, size) => {
		dispatch(getCartToppingsThunk(page, size, categoryId || '', orderType))
	}, [dispatch, categoryId, orderType])

	const onLoadMustToppings = useCallback((page, size) => {
		dispatch(getCartMustToppingsThunk(page, size, categoryId || '', orderType))
	}, [dispatch, categoryId, orderType])

	const loadToppings = useCallback(() => {
		if (onLoadToppings) {
			onLoadToppings(Math.ceil(toppingLength / PER_LOAD), PER_LOAD)
		}
	}, [toppingLength, onLoadToppings])

	const loadMustToppings = useCallback(() => {
		if (onLoadMustToppings && showMustToppings) {
			onLoadMustToppings(Math.ceil(mustToppingLength / PER_LOAD), PER_LOAD)
		}
	}, [mustToppingLength, onLoadMustToppings, showMustToppings])

	useEffect(() => {
		if (productFocus) {
			if (menu) {
				selectSize(getMenuProductPriceIndex(menu, menuProductIndex, productFocus, orderType))
				setToppingFocus(0)
			} else if (reduxItemFocus) {
				selectSize(reduxItemFocus.priceIndex, reduxItemFocus.quantity)
			} else {
				selectSize(0)
			}
			dispatch(getCartToppingGroupsThunk(productFocus?.category?.id || '', productFocus?.productId || '', orderType))
			if (prices?.length === 1 && showMustToppings) {
				setToppingFocus(0)
			}
		}
	}, [productFocus, reduxItemFocus]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (productFocus) {
			if ((toppingTotalLength === toppingLength && toppingLength === 0)
				|| (inViewToppings && toppingTotalLength > toppingLength)) {
				loadToppings()
			}
		}
	}, [toppingTotalLength, toppingLength, productFocus]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (productFocus) {
			if ((mustToppingTotalLength === mustToppingLength && mustToppingLength === 0)
				|| (inViewMustToppings && mustToppingTotalLength > mustToppingLength)) {
				loadMustToppings()
			}
		}
	}, [mustToppingTotalLength, mustToppingLength, productFocus]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (productFocus) {
			const convertMustToppings = mustToppingsToShow?.map(pr => {
				const qnt = reduxItemFocus?.items?.filter(subItem => subItem.itemId === pr.productId)?.reduce((qs, itm) => qs + itm.quantity, 0) || 0
				const filteredMustToppings = mustToppingItems.filter(itm => itm.itemId === pr.productId)
				const qnt2 = filteredMustToppings.reduce((qs, itm) => qs + itm.quantity, 0) || 0
				const quantity = (filteredMustToppings.length && qnt !== qnt2) ? qnt2 : qnt
				return convertProduct2Item(pr, quantity, getPosPriceValue(pr, orderType, toppingPriceId), toppingPriceIndex, null)
			})
			setMustToppingItems(convertMustToppings)
		}
	}, [mustToppingsToShow, toppingPriceId, toppingPriceIndex, reduxItemFocus]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (productFocus) {
			const toppingGroupsItems = toppingGroups?.reduce((res, item) => {
				const items = getItemsByExistPriceId(item.products, orderType, toppingPriceId)
				const groupItems = items?.map(el => {
					const qnt = reduxItemFocus?.items?.filter(subItem => subItem.itemId === el.productId)?.reduce((qs, itm) => qs + itm.quantity, 0) || 0
					return convertProduct2Item(el, qnt, getPosPriceValue(el, orderType, toppingPriceId), toppingPriceIndex, null)
				})
				return { ...res, [item.id]: groupItems }
			}, {})
			setToppingGroupsItems(toppingGroupsItems)
		}
	}, [toppingGroups, toppingPriceId, toppingPriceIndex, reduxItemFocus]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (productFocus && toppingGroups?.length && !reduxItemFocus) {
			const toppingGroupsToOpen = toppingGroups?.reduce((res, item) => {
				const isRequiredAdditives = item?.requiredAdditives
				const minCount = item?.minCount || 0
				const toppingGroupIsNeddedToOpen = isRequiredAdditives && minCount > 0
				return { ...res, [item.id]: toppingGroupIsNeddedToOpen }
			}, {})
			setShowToppingGroups(toppingGroupsToOpen)
		}
	}, [productFocus, toppingGroups, reduxItemFocus]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (productFocus) {
			const convertToppings = toppingsToShow?.map(pr => {
				const qnt = reduxItemFocus?.items?.filter(subItem => subItem.itemId === pr.productId)?.reduce((qs, itm) => qs + itm.quantity, 0) || 0
				const filteredToppings = toppingItems.filter(itm => itm.itemId === pr.productId)
				const qnt2 = filteredToppings.reduce((qs, itm) => qs + itm.quantity, 0)
				const quantity = (filteredToppings.length && qnt !== qnt2) ? qnt2 : qnt
				return convertProduct2Item(pr, quantity, getPosPriceValue(pr, orderType, toppingPriceId), toppingPriceIndex, null)
			})
			setToppingItems(convertToppings)
		}
	}, [toppingsToShow, toppingPriceId, toppingPriceIndex, reduxItemFocus]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (reduxItemFocus && reduxItemFocus.remark) {
			setRemarkText(reduxItemFocus.remark)
		}
	}, [reduxItemFocus])

	const handleArrowUp = useCallback(() => {
		if (focusedOnToppings) {
			if (toppingFocus === 0) {
				setToppingFocus(mustToppingItems.length - 1)
			} else {
				setToppingFocus(toppingFocus - 1)
			}
		} else {
			if (activeSize === 0) {
				selectSize(prices.length - 1)
			} else {
				selectSize(activeSize - 1)
			}
		}
	}, [focusedOnToppings, toppingFocus, mustToppingItems, activeSize, prices?.length, selectSize])

	const handleArrowDown = useCallback(() => {
		if (focusedOnToppings) {
			if (toppingFocus < (mustToppingItems.length - 1)) {
				setToppingFocus(toppingFocus + 1)
			} else {
				setToppingFocus(0)
			}
		} else {
			if (activeSize < (prices.length - 1)) {
				selectSize(activeSize + 1)
			} else {
				selectSize(0)
			}
		}
	}, [focusedOnToppings, toppingFocus, mustToppingItems, activeSize, prices?.length, selectSize])

	const addMustToppingWithQuantity = useCallback((index, quantity) => {
		const toppingItemsCopy = clone(mustToppingItems)
		toppingItemsCopy[index].quantity = toppingItemsCopy[index].quantity + quantity
		setMustToppingItems(toppingItemsCopy)
	}, [mustToppingItems])

	const addMustToppingItem = useCallback((index) => {
		addMustToppingWithQuantity(index, 1)
	}, [addMustToppingWithQuantity])

	const addNegativeMustTopping = useCallback((index) => {
		addMustToppingWithQuantity(index, -1)
	}, [addMustToppingWithQuantity])

	const addToppingWithQuantity = useCallback((index, quantity) => {
		const toppingItemsCopy = clone(toppingItems)
		toppingItemsCopy[index].quantity = toppingItemsCopy[index].quantity + quantity
		setToppingItems(toppingItemsCopy)
	}, [toppingItems])

	const addToppingItem = useCallback((index) => {
		addToppingWithQuantity(index, 1)
	}, [addToppingWithQuantity])

	const addNegativeTopping = useCallback((index) => {
		addToppingWithQuantity(index, -1)
	}, [addToppingWithQuantity])

	const addToppingGroupsWithQuantity = useCallback((id, index, quantity) => {
		const toppingItemsCopy = clone(toppingGroupsItems[id])
		toppingItemsCopy[index].quantity = toppingItemsCopy[index].quantity + quantity
		setToppingGroupsItems({ ...toppingGroupsItems, [id]: toppingItemsCopy })
	}, [toppingGroupsItems])

	const addToppingGroupsItem = useCallback((id, index) => {
		addToppingGroupsWithQuantity(id, index, 1)
	}, [addToppingGroupsWithQuantity])

	const addNegativeGroupsTopping = useCallback((id, index) => {
		addToppingGroupsWithQuantity(id, index, -1)
	}, [addToppingGroupsWithQuantity])

	const handleEnter = useCallback(() => {
		if (!showMustToppings) {
			addToCart()
		} else if (focusedOnToppings) {
			addMustToppingItem(toppingFocus)
		} else {
			setToppingFocus(0)
		}
	}, [showMustToppings, addToCart, focusedOnToppings, toppingFocus, addMustToppingItem])

	const handlePlus = useCallback(() => {
		addMustToppingItem(toppingFocus)
	}, [toppingFocus, addMustToppingItem])

	const handleMinus = useCallback(() => {
		addNegativeMustTopping(toppingFocus)
	}, [toppingFocus, addNegativeMustTopping])

	const onChangeRemarkInput = useCallback((keyboardText, event) => {
		const value = keyboardText !== null ? keyboardText : event.target.value
		setRemarkText(value)
	}, [])

	const openToppingGroups = useCallback((id) => {
		const itemToOpen = showToppingGroups[id] || false
		const closeToppings = Object.keys(showToppingGroups).reduce((res, key) => ({ ...res, [key]: false }), {})
		const groupsToOpen = !itemToOpen ? closeToppings : showToppingGroups
		if (!itemToOpen) setShowToppings(false)
		setShowToppingGroups({ ...groupsToOpen, [id]: !itemToOpen })
	}, [showToppingGroups])

	const openToppings = useCallback(() => {
		if (!showToppings) setShowToppingGroups(Object.keys(showToppingGroups).reduce((res, key) => ({ ...res, [key]: false }), {}))
		setShowToppings(!showToppings)
	}, [showToppings, showToppingGroups])

	const handleInViewToppingsChange = useCallback((inView) => {
		if (inView && toppingTotalLength > toppingLength) {
			loadToppings()
		}
	}, [toppingTotalLength, toppingLength, loadToppings])

	const handleInViewMustToppingsChange = useCallback((inView) => {
		if (inView && mustToppingTotalLength > mustToppingLength) {
			loadMustToppings()
		}
	}, [mustToppingTotalLength, mustToppingLength, loadMustToppings])

	const handleRemarkInputClick = useCallback((e) => {
		e.stopPropagation()
		setShowRemarkWithKeyboard(true)
	}, [])

	const handleRemarkWithKeyboardClose = useCallback(() => {
		setShowRemarkWithKeyboard(false)
	}, [])

	const ModalFooter = (
		<ModalFooterWrap>
			<AddButtonWrap quantity={enableQuantity}>
				{enableQuantity && itemFocus && !menu &&
					<ProductQuantity focus={false} item={{ ...itemFocus, name: t('Cart.count') }} size="big" color="gray" type="mainDish"
													 onClickPlus={() => setItemFocus({ ...itemFocus, quantity: itemFocus.quantity + 1 })}
													 onClickMinus={() => itemFocus.quantity > 1 && setItemFocus({ ...itemFocus, quantity: itemFocus.quantity - 1 })} />}
				<InputRemarkWrap>
					{!isMobile && <KeyboardButtonWrap>
						<Button name="open-keyboard" icon="keyboard" color="blue" onClick={handleRemarkInputClick} />
					</KeyboardButtonWrap>}
					<InputRemark>
						<MainIcon icon="remark" />
						<input name="remark"
									 value={remarkText}
									 placeholder={isTablet ? t('Modal.remark_input_short') : t('Modal.remark_input')}
									 autoComplete="off"
									 onChange={!isMobile ? (e) => onChangeRemarkInput(null, e) : () => {
									 }}
									 readOnly={isMobile}
									 onClick={isMobile ? handleRemarkInputClick : () => {
									 }}
									 role="presentation"
									 autoFocus={true}
									 inputMode="none" />
					</InputRemark>
				</InputRemarkWrap>

				{domElement && ReactDOM.createPortal(
					<RemarkWithKeyboard isOpen={showRemarkWithKeyboard}
															close={handleRemarkWithKeyboardClose}
															onChangeRemarkInput={onChangeRemarkInput}
															remarkText={remarkText}
															itemFocus={itemFocus}
															setItemFocus={setItemFocus}
															addToCart={addToCart} />, domElement)}
				{isTablet &&
					<Button name="back" icon="back" text={t('buttons.controls.back')} onClick={closeModal} keyButton="Escape" keyText="esc" zIndex={EVENT_Z_INDEX} />}
				<Button name="add-cart" icon="checkmark" text={t('Cart.Buttons.buttonAdd')} keyButton="F2" color="green" className="add"
								disabled={!itemFocus} zIndex={EVENT_Z_INDEX} onClick={addToCart} />
			</AddButtonWrap>
		</ModalFooterWrap>
	)
	const SizeButton = ({ item, index }) => {
		const handleSelectSizeButtonClick = useCallback(() => selectSize(index), [index])

		return (
			<SizeButtonWrap className={((menu || activeSize === index) ? 'active ' : '') + (touchPad ? 'touchPad' : ' ')}
											onClick={handleSelectSizeButtonClick}>
				{item.priceTitle.title}
				<Price active={menu || activeSize === index} value={item.value} size="medium" color="gray-dark" />
				{(menu || activeSize === index) &&
					<ButtonKey className="key-small">Enter</ButtonKey>}
			</SizeButtonWrap>)
	}
	SizeButton.displayName = 'SizeButton'

	return (
		<>
			<CustomModal isOpen={!!productFocus}
									 size="x-larger"
									 title={`${productFocus?.title}`}
									 titlePrice={itemFocus?.price}
									 close={closeModal}
									 autoFocus={false}
									 colorFooter="#F7F7F7"
									 zIndex={EVENT_Z_INDEX}
									 disableBackButton={true}
									 button={ModalFooter}>
				{productFocus?.ingredients &&
					<IngredientsBlock>
						{productFocus?.ingredients}
					</IngredientsBlock>}
				{prices?.length > 0 &&
					<SizesBlock id="size-list">
						{(menu ? [prices[activeSize]] : prices)?.map((item, i) =>
							<SizeButton key={i} item={item} index={i} />)}
					</SizesBlock>}
				<ToppingsButtonsWrapper quantity={enableQuantity}>
					{isExistToppings ?
						<>
							{showMustToppings &&
								<>
									<ToppingsWrapper className="must-extras">
										<ToppingsBlock id="extra-list">
											{mustToppingItems.map((item, i) =>
												<ProductQuantity key={i} focus={toppingFocus === i} item={item} index={i}
																				 onClickPlus={() => addMustToppingItem(i)}
																				 onClickMinus={() => addNegativeMustTopping(i)} />)}
										</ToppingsBlock>
									</ToppingsWrapper>
									<InView as={TableLoader} onChange={handleInViewMustToppingsChange} style={{ marginTop: 0, height: 0 }}>
										<span id="must-toppings-total-count" style={{ opacity: 0 }} ref={refMustToppings}>{mustToppingTotalLength}</span>
									</InView>
								</>}
							{toppingGroups?.map((item, i) =>
								<React.Fragment key={i}>
									{toppingGroupsItems[item.id]?.length > 0 &&
										<DropDownButton text={item.title} active={showToppingGroups[item.id]} onClick={() => openToppingGroups(item.id)} />}
									{showToppingGroups[item.id] &&
										<ToppingsWrapper className="groups">
											<ToppingsBlock>
												{toppingGroupsItems[item.id]?.length &&
													toppingGroupsItems[item.id].map((itm, i) =>
														<ProductQuantity key={i} focus={false} item={itm} index={i}
																						 onClickPlus={() => addToppingGroupsItem(item.id, i)}
																						 onClickMinus={() => addNegativeGroupsTopping(item.id, i)} />)}
											</ToppingsBlock>
										</ToppingsWrapper>}
								</React.Fragment>)}
							{showAllToppingsButton &&
								<DropDownButton text={t('Cart.Buttons.allextras')} active={showToppings} onClick={() => openToppings()} />}
							{showToppings &&
								<>
									<ToppingsWrapper className="all">
										<ToppingsBlock>
											{toppingItems.map((item, i) =>
												<ProductQuantity key={i} focus={false} item={item} index={i}
																				 onClickPlus={() => addToppingItem(i)}
																				 onClickMinus={() => addNegativeTopping(i)} />)}
										</ToppingsBlock>
									</ToppingsWrapper>
									<InView as={TableLoader} onChange={handleInViewToppingsChange} style={{ marginTop: 0, height: 0 }}>
										<span id="toppings-total-count" style={{ opacity: 0 }} ref={refToppings}>{toppingTotalLength}</span>
									</InView>
								</>}
						</> :
						<EmptyToppingsWrapper>
							{t('Cart.no_extras')}
						</EmptyToppingsWrapper>}
				</ToppingsButtonsWrapper>
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 arrowRight={handleArrowDown}
										 arrowLeft={handleArrowUp}
										 enter={handleEnter}
										 plus={handlePlus}
										 minus={handleMinus} />}
		</>
	)
})

ModalSize.displayName = 'ModalSize'
export default ModalSize
