export const SET_CASHBOOK_SHOW_FORM = 'SET_CASHBOOK_SHOW_FORM'

export const SET_CASHBOOK_DATE_FROM = 'SET_CASHBOOK_DATE_FROM'

export const SET_CASHBOOK_DATE_TO = 'SET_CASHBOOK_DATE_TO'

export const SET_CASHBOOK_DATE_ENTRY = 'SET_CASHBOOK_DATE_ENTRY'

export const SET_CASHBOOK_PAYMENT_TRANSACTION_ID = 'SET_CASHBOOK_PAYMENT_TRANSACTION_ID'

export const SET_CASHBOOK_ENTRIES_TOTAL_LENGTH_FOR_ALL_DAYS = 'SET_CASHBOOK_ENTRIES_TOTAL_LENGTH_FOR_ALL_DAYS'

export const SET_CASHBOOK_ENTRIES_FOR_ALL_DAYS = 'SET_CASHBOOK_ENTRIES_FOR_ALL_DAYS'

export const APPEND_CASHBOOK_ENTRIES_FOR_ALL_DAYS = 'APPEND_CASHBOOK_ENTRIES_FOR_ALL_DAYS'

export const SET_CASHBOOK_ONE_ENTRY = 'SET_CASHBOOK_ONE_ENTRY'

export const SET_CASHBOOK_ENTRIES_FOR_ONE_DAY = 'SET_CASHBOOK_ENTRIES_FOR_ONE_DAY'

export const SET_CASHBOOK_ENTRIES_TOTAL_LENGTH_FOR_ONE_DAY = 'SET_CASHBOOK_ENTRIES_TOTAL_LENGTH_FOR_ONE_DAY'

export const APPEND_CASHBOOK_ENTRIES_FOR_ONE_DAY = 'APPEND_CASHBOOK_ENTRIES_FOR_ONE_DAY'

export const SET_CASHBOOK_LOADING = 'SET_CASHBOOK_LOADING'
