import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDeliveryCartPreorderDate } from '../../../../redux/action/deliveryCart'
import { useTranslation } from 'react-i18next'
import Header from '../../../Header'
import MobileCartButton from '../../../Elements/cart/MobileCartButton'
import MobileCartButtonPortal from '../../../Elements/cart/MobileCartButtonPortal'
import { CustomerAddress, CustomerData, CustomerName } from '../../../Elements/cart/index.styled'
import Button from '../../../Elements/buttons/Button'
import { selectGiftCouponInCart, selectMoneyExchangeInCart } from '../../../../redux/selector/cart'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'

const DeliveryCartHeader = React.memo(({ zIndex, toggleMobileCart, openPreOrderDateModal, openModalCustomProduct, resetDeliveryCost }) => {
	const { t } = useTranslation()
	const storedCustomer = useSelector(store => store.customerStore.customer)
	const items = useSelector(store => store.deliveryCart.items)
	const preOrderDate = useSelector(store => store.deliveryCart.preOrderDate)
	const hasGiftCoupon = useSelector(selectGiftCouponInCart('DELIVERY'))
	const hasMoneyExchange = useSelector(selectMoneyExchangeInCart('DELIVERY'))
	const isDisabled = hasGiftCoupon || hasMoneyExchange
	const dispatch = useDispatch()
	const isTablet = useWindowBreakPoint(theme.point820)

	return (
		<Header right={
			<>
				<MobileCartButton count={items?.length} onClick={toggleMobileCart} />
				<MobileCartButtonPortal cartItemsCount={items?.length} toggleMobileCart={toggleMobileCart} />
			</>
		}>
			{storedCustomer?.postalCode ?
				<CustomerData>
					<CustomerName>{storedCustomer?.name}</CustomerName>
					<CustomerAddress>{storedCustomer?.street} {storedCustomer?.streetNumber}, {storedCustomer?.postalCode}</CustomerAddress>
				</CustomerData> :
				<CustomerName>{storedCustomer?.name}</CustomerName>}
			{isTablet &&
				<>
					{preOrderDate ?
						<Button icon="clear" text={t('Cart.Buttons.resetPreOrder')} keyButton="F8" color="red" showTextForTablet={false}
										size="small" zIndex={zIndex} onClick={() => dispatch(setDeliveryCartPreorderDate(null))} /> :
						<Button icon="date" text={t('Cart.Buttons.preOrder')} name="add-preorder" keyButton="F8" color="blue" showTextForTablet={false}
										size="small" zIndex={zIndex} onClick={openPreOrderDateModal} />}
					<Button icon="plus" text={t('Cart.Buttons.extras')} keyButton="F3" color="blue" showTextForTablet={false}
									size="small" zIndex={zIndex} onClick={openModalCustomProduct} disabled={isDisabled} />
					<Button icon="car" text={t('Cart.Buttons.freeTravel')} keyButton="F6" color="blue" showTextForTablet={false}
									size="small" zIndex={zIndex} onClick={resetDeliveryCost} />
				</>}
		</Header>
	)
})

DeliveryCartHeader.displayName = 'DeliveryCartHeader'
export default DeliveryCartHeader
