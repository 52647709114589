import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const InvoiceConfigsWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1.25em;
	margin-bottom: 0.63em;
`
InvoiceConfigsWrapper.displayName = 'InvoiceConfigsWrapper'

export const InvoiceConfigsSubtitle = styled.div`
	color: ${colorTheme.cartModal.invoiceModals.invoiceConfigs.subtitle};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.13em;
	font-weight: 500;
`
InvoiceConfigsSubtitle.displayName = 'InvoiceConfigsSubtitle'

export const InvoiceConfigsDescription = styled.div`
	color: ${colorTheme.cartModal.invoiceModals.invoiceConfigs.description};
	font-size: 1.06em;
	font-weight: 400;
`
InvoiceConfigsDescription.displayName = 'InvoiceConfigsDescription'
