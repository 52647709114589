import styled from 'styled-components'
import color from '../../../../style/colors'
import { theme } from '../../../../style/theme'

export const AutocompleteWrapper = styled.div`
  position: relative;
  font-size: 1rem;
`
AutocompleteWrapper.displayName = 'AutocompleteWrapper'

export const AutocompleteItems = styled.div`
  transition: all 1s ease-in-out;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: ${color.white};
  border-radius: 0.75em;
  top: 4.44em;
  max-height: 17em;
  overflow-y: auto;
  box-shadow: 0 1em 2.5em rgba(0, 0, 0, 0.33);
  padding: 0.75em;

  &.error {
    top: 6.44em;
  }
`
AutocompleteItems.displayName = 'AutocompleteItems'

export const AutoCompleteItem = styled.div`
  display: grid;
  gap: 0.13em;
  background: ${color.white};
  padding: 1em 0.75em;
  border: 1px solid transparent;
  border-top-color: ${color.gray_lighter};
  cursor: pointer;

  &:last-child {
    border-bottom-color: ${color.gray_lighter};
  }

  &.active {
    border-color: ${color.blue};
    border-radius: 0.63em;

    + div {
      border-top: 1px solid transparent;
    }
  }
`
AutoCompleteItem.displayName = 'AutoCompleteItem'

export const AutoCompleteNoItems = styled.div`
  font-family: ${theme.fontRobotoRegular};
  font-size: 1em;
  color: ${color.blue};
`
AutoCompleteNoItems.displayName = 'AutoCompleteNoItems'
