import React from 'react'
import { DropDown, DropDownItem, DropDownItems, DropDownSelected } from './index.styled'
import HeaderIcon from '../../../../img/svg/header'

const DropDownSelect = React.memo(({ className, items, selectedIndex, onClickItem }) => {
	const filteredItems = items.filter((itm, i) => i !== selectedIndex)

	return (
		<DropDown className={className}>
			<DropDownSelected>
				<HeaderIcon icon={items[selectedIndex]?.icon} />
				{items[selectedIndex]?.text}
			</DropDownSelected>
			<DropDownItems className="items">
				{filteredItems.map((item, i) =>
					<DropDownItem key={i} onClick={() => onClickItem(item)}>
						<HeaderIcon icon={item.icon} />
						{item.text}
					</DropDownItem>)}
			</DropDownItems>
		</DropDown>
	)
})

DropDownSelect.displayName = 'DropDownSelect'
export default DropDownSelect
