import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const RestaurantDiscountWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	gap: 0.63em;
	margin-bottom: 1.25em;

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 1.875em;
	}
`
RestaurantDiscountWrapper.displayName = 'RestaurantDiscountWrapper'

export const DiscountFormInputs = styled.div`
  font-size: 1rem;
  display: grid;
  grid-template-columns: auto 6.4em;
  gap: 0.63em;

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 0.63em;
	}
`
DiscountFormInputs.displayName = 'DiscountFormInputs'
export const DiscountTitle = styled.div`
	font-family: ${theme.fontRobotoBold};
	display: flex;
	gap: 0.25em;
	font-weight: 500;
	font-size: 1.25em;
	color: ${colorTheme.restaurantDiscount.title};
	text-align: left;

	> svg {
		width: 1.2em;
		height: 1.2em;
		flex-shrink: 0;
	}
`
DiscountTitle.displayName = 'DiscountTitle'

export const DiscountMessage = styled.div`
	font-size: 1em;
	display: flex;
	gap: 0.43em;
	text-align: left;
	color: ${colorTheme.restaurantDiscount.message.title};

  > svg {
		width: 1.5em;
		height: 1.5em;
		flex-shrink: 0;
		fill: ${colorTheme.restaurantDiscount.message.icon};
	}
`
DiscountMessage.displayName = 'DiscountMessage'
