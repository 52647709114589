import React, { useCallback } from 'react'
import { MainWrapperStyled } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { setToggled } from '../../redux/action/system'
import { theme } from '../../style/theme'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import Recaptcha from '../Elements/Recaptcha'

const MainWrapper = React.memo(({ children, className, showRecaptcha }) => {
	const toggled = useSelector(store => store.system.toggled)
	const dispatch = useDispatch()
	const isMobile = useWindowBreakPoint(theme.point720)
	const layout = (toggled ? ' toggled ' : '') + (showRecaptcha ? 'with-recaptcha ' : '') + className

	const handleClick = useCallback((e) => {
		if (!toggled && isMobile) {
			e.preventDefault()
			e.stopPropagation()
			dispatch(setToggled(true))
		}
	}, [toggled, isMobile])

	return (
		<MainWrapperStyled id="content"
			data-testid="content"
			className={layout}
			onClick={handleClick}>
			{children}
			{showRecaptcha && <Recaptcha className="main" />}
		</MainWrapperStyled>
	)
})

MainWrapper.displayName = 'MainWrapper'
export default MainWrapper
