import { addMinutes } from 'date-fns'
import { QRCodeSVG } from 'qrcode.react'
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import ButtonIcon from '../../img/svg/button'
import { selectIsWorkingOrderType } from '../../redux/selector/shop'
import { getShopConfigThunk, updateShopConfigThunk, updateShopOrderTypeWorkingStatusThunk } from '../../redux/thunk/webshopinfo'
import { onlineShopUIUrl } from '../../urls'
import AdviceWindow from '../Elements/AdviceWindow'
import Button from '../Elements/buttons/Button'
import { QrCodeAdviceWrapper, QrCodeImg, QrCodeText, WebShopButton, WebShopButtonExpand, WebShopButtonIcon, WebShopButtonIconWrapper, WebShopButtonLine, WebShopButtonText, WebshopInfoDropdown, WebshopInfoWrapper } from './index.styled'
import DisableOrderTypePeriodModal from './modal/DisableOrderTypePeriodModal'
import WebshopActivationModal from './modal/WebshopActivationModal'
import WebshopScheduleModal from './modal/WebshopScheduleModal'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import WebshopDropDown from './WebshopDropDown'
import { setWebshopShowAdviceWindow } from '../../redux/action/webshopinfo'

// const AdviceWindow = lazyWithRetry(() => import(/* webpackChunkName: "advice-window" */'../Elements/AdviceWindow'))
const QrDownloadModal = lazyWithRetry(() => import(/* webpackChunkName: "qr-download-modal" */'./modal/WebshopQrDownloadModal'))

const WebshopInfo = React.memo(() => {
	const { t } = useTranslation()
	const [webshopInfoIsOpen, setWebshopInfoIsOpen] = useState(false)
	const [activationInfoIsOpen, setActivationInfoIsOpen] = useState(false)
	const showActivePopup = useSelector(store => store.webshopinfo.showAdviceWindow)
	const configLoaded = useSelector(store => !!store.webshopinfo.config)
	const config = useSelector(store => store.webshopinfo.config)
	const isEnabled = useSelector(store => !!store.webshopinfo.config?.enabled)
	const shopUrl = useSelector(store => `${onlineShopUIUrl}/${store.webshopinfo.config?.shopUrl}`)
	const activationDate = useSelector(store => store.webshopinfo.config?.activationDate)
	const isDeliveryWorkingNow = useSelector(selectIsWorkingOrderType('DELIVERY'))
	const isPickupWorkingNow = useSelector(selectIsWorkingOrderType('PICKUP'))
	const dispatch = useDispatch()
	const [shopActivationIsOpen, setShopActivationIsOpen] = useState(false)
	const [shopScheduleIsOpen, setShopScheduleIsOpen] = useState(false)
	const [shopQrDownloadIsOpen, setShopQrDownloadIsOpen] = useState(false)
	const [disablePeriodModalIsOpen, setDisablePeriodModalIsOpen] = useState(false)
	const [disabledOrderType, setDisabledOrderType] = useState(null)
	const webshopWrapperRef = useRef()
	const navigate = useNavigate()

	const layout = isEnabled ? 'active' : (!isEnabled && activationDate) ? 'inactive' : ''
	const layoutExpand = ((webshopInfoIsOpen || activationInfoIsOpen) ? 'is-open' : '') + (isEnabled ? ' active' : '')
	const layoutDeliveryButton = (isDeliveryWorkingNow && 'active') + (!isEnabled ? ' inactive' : '')
	const layoutPickupButton = (isPickupWorkingNow && 'active') + (!isEnabled ? ' inactive' : '')

	useEffect(() => {
		if (!configLoaded) {
			dispatch(getShopConfigThunk())
		}
	}, [configLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleShopClick = useCallback(() => {
		if (isEnabled && activationDate) {
			setWebshopInfoIsOpen(!webshopInfoIsOpen)
		} else if (!isEnabled && activationDate) {
			setActivationInfoIsOpen(!activationInfoIsOpen)
		} else {
			setShopActivationIsOpen(true)
		}
	}, [isEnabled, webshopInfoIsOpen, activationInfoIsOpen, activationDate])

	const handleActivationModalClose = useCallback(() => {
		setShopActivationIsOpen(false)
	}, [])

	const handleActivationModalOpen = useCallback(() => {
		setShopActivationIsOpen(true)
	}, [])

	useOutsideClick(webshopWrapperRef, useCallback(() => {
		setWebshopInfoIsOpen(false)
		setActivationInfoIsOpen(false)
	}, []))

	const closeAdvice = useCallback(() => {
		dispatch(setWebshopShowAdviceWindow(false))
	}, [dispatch])

	const handleNavigateToShop = useCallback(() => {
		closeAdvice()
		window.open(shopUrl, '_blank')
	}, [shopUrl, closeAdvice])

	const handleNavigateToShopSettings = useCallback(() => {
		closeAdvice()
		navigate('/settings/webshop')
	}, [closeAdvice, navigate])

	const handleDeactivateShop = useCallback(() => {
		if (!isEnabled && activationDate) {
			dispatch(updateShopConfigThunk({
				...config, enabled: !isEnabled,
			}))
			setActivationInfoIsOpen(false)
		} else {
			setShopActivationIsOpen(true)
		}
	}, [isEnabled, activationDate, config, dispatch])

	const handleOpenPopup = useCallback(() => {
		dispatch(setWebshopShowAdviceWindow(true))
	}, [dispatch])

	const handleOpenSchedule = useCallback(() => {
		setShopScheduleIsOpen(true)
	}, [])

	const handleCloseSchedule = useCallback(() => {
		setShopScheduleIsOpen(false)
	}, [])

	const handleCloseDisablePeriodModal = useCallback(() => {
		setDisablePeriodModalIsOpen(false)
	}, [])

	const handleOrderStatusChange = useCallback((orderType) => (e) => {
		if ((isDeliveryWorkingNow && orderType === 'DELIVERY') || (isPickupWorkingNow && orderType === 'PICKUP')) {
			setDisabledOrderType(orderType)
			setDisablePeriodModalIsOpen(true)
		} else {
			dispatch(updateShopOrderTypeWorkingStatusThunk(orderType, {
				working: e.target.checked, breakDateTo: null,
			}))
		}
	}, [isDeliveryWorkingNow, isPickupWorkingNow, dispatch])

	const handleDisableOrderTypePeriod = useCallback((disableTime) => {
		const breakDateTo = addMinutes(new Date(), disableTime).toISOString()
		dispatch(updateShopOrderTypeWorkingStatusThunk(disabledOrderType, {
			working: true, breakDateTo,
		}))
	}, [disabledOrderType, dispatch])

	const handleOpenDownloadModal = useCallback(() => {
		setShopQrDownloadIsOpen(true)
		setWebshopInfoIsOpen(false)
	}, [])

	const handleQrDownloadModalClose = useCallback(() => {
		setShopQrDownloadIsOpen(false)
	}, [])

	return (
		<>
			<WebshopInfoWrapper ref={webshopWrapperRef}>
				{configLoaded &&
					<AdviceWindow showAdvice={showActivePopup}
						icon="shop_active"
						title={t('WebshopInfo.popup.title')}
						text={t('WebshopInfo.popup.text')}
						closeWindow={closeAdvice}
						buttons={<>
							<Button name="navigate" color="green" text={t('WebshopInfo.navigate_to_shop')} icon="navigate-shop" onClick={handleNavigateToShop} />
							<Button name="settings" color="blue" text={t('WebshopInfo.navigate_to_settings')} icon="settings-shop" onClick={handleNavigateToShopSettings} />
						</>}
						additionalContent={
							<QrCodeAdviceWrapper>
								<QrCodeText>Webshop</QrCodeText>
								<QrCodeImg className="advice"><QRCodeSVG value={shopUrl} level="M" /></QrCodeImg>
							</QrCodeAdviceWrapper>
						}>
						<WebShopButton className={layout} onClick={handleShopClick}>
							{(activationDate && !isEnabled) ? <ButtonIcon icon="webshop-inactive" /> : <ButtonIcon icon="webshop" />}
							<WebShopButtonText>WEBSHOP</WebShopButtonText>
							{activationDate && <>
								<WebShopButtonIconWrapper>
									<WebShopButtonIcon className={layoutDeliveryButton} data-testid="delivery-shop">
										<ButtonIcon icon="delivery-shop" />
									</WebShopButtonIcon>
									<WebShopButtonIcon className={layoutPickupButton} data-testid="pickup-shop">
										<ButtonIcon icon="pickup-shop" />
									</WebShopButtonIcon>
								</WebShopButtonIconWrapper>
								<WebShopButtonLine className={layout} />
								<WebShopButtonExpand className={layoutExpand}>
									<ButtonIcon icon="expand-down" />
								</WebShopButtonExpand>
							</>}
						</WebShopButton>
					</AdviceWindow>}
				{webshopInfoIsOpen &&
					<WebshopDropDown {... { handleOpenDownloadModal, handleOrderStatusChange }} />}
				{activationInfoIsOpen &&
					<WebshopInfoDropdown>
						<Button name="settings" color="blue" text={t('WebshopInfo.navigate_to_settings')} className="activation" icon="settings-shop" onClick={handleNavigateToShopSettings} />
						<Button name="activate" color="green" text={t('WebshopInfo.activate_shop')} icon="activate_shop" onClick={handleDeactivateShop} />
					</WebshopInfoDropdown>}
			</WebshopInfoWrapper>

			<WebshopActivationModal isOpen={shopActivationIsOpen}
				close={handleActivationModalClose}
				openSchedule={handleOpenSchedule} />
			<WebshopScheduleModal isOpen={shopScheduleIsOpen}
				close={handleCloseSchedule}
				openPopup={handleOpenPopup}
				openActivationModal={handleActivationModalOpen} />
			{disablePeriodModalIsOpen &&
				<DisableOrderTypePeriodModal isOpen={disablePeriodModalIsOpen}
					close={handleCloseDisablePeriodModal}
					orderTypeToDisable={disabledOrderType}
					disableOrderTypePeriod={handleDisableOrderTypePeriod} />}
			<Suspense>
				<QrDownloadModal isOpen={shopQrDownloadIsOpen}
					close={handleQrDownloadModalClose} />
			</Suspense>
		</>
	)
})

WebshopInfo.displayName = 'WebshopInfo'
export default WebshopInfo
