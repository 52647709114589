import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextInput from '../../inputs/TextInput'
import { convertProduct2Item, getFloat } from '../../../../helper'
import CustomModal from '../../modal/CustomModal'
import { CustomProductForm } from './index.styled'
import SelectInput from '../../inputs/SelectInput'
import Button from '../../buttons/Button'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'

const EVENT_Z_INDEX = 83
const defaultValues = {
	price: '0',
}

const ModalCustomProduct = React.memo(({ isOpen, close, addProduct, addRemarkToItem, focusedItemRemark, itemExist }) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const { register, watch, formState: { errors }, handleSubmit, reset, setFocus } = useForm({ defaultValues })
	const [focusIndex, setFocusIndex] = useState(0)
	const selectedComment = watch('itemType') === 'COMMENT'
	const isBottleDeposit = watch('itemType') === 'BOTTLE_DEPOSIT'

	useEffect(() => {
		if (isNeededZIndex) {
			setFocusIndex(0)
		}
	}, [isNeededZIndex])

	useEffect(() => {
		if (isNeededZIndex) {
			switch (focusIndex) {
				case 0:
					setFocus('itemType')
					break
				case 1:
					setFocus('title')
					break
				case 2:
					setFocus('price')
					break
				default:
					setFocusIndex(0)
					break
			}
		}
	}, [focusIndex, isNeededZIndex]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedComment && itemExist && focusedItemRemark) {
			reset({ title: focusedItemRemark, itemType: 'COMMENT' })
		}
	}, [selectedComment, itemExist, focusedItemRemark]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleReset = useCallback(() => {
		reset({ itemType: 'MAIN_DISH', title: '', price: '0' })
	}, [reset])

	const onSubmit = useCallback((data) => {
		if (data.itemType === 'COMMENT') {
			addRemarkToItem(data.title)
		} else {
			let customProduct =
				convertProduct2Item(
					{ productId: 'custom', productType: data.itemType, title: data.title },
					1, getFloat(data.price), 0, [])
			addProduct(customProduct)
		}
		handleReset()
		close()
	}, [handleReset, addRemarkToItem, addProduct, close])

	const handleEnter = useCallback(() => {
		setFocusIndex(focusIndex + 1)
	}, [focusIndex])

	const handleF2 = useCallback(() => {
		handleSubmit(onSubmit)()
	}, [onSubmit, handleSubmit])

	const handleClose = useCallback(() => {
		handleReset()
		close()
	}, [handleReset, close])

	return (
		<>
			<CustomModal isOpen={isOpen}
									 size="small"
									 id="custom-product-modal"
									 close={handleClose}
									 title={t('Cart.Buttons.extras')}
									 zIndex={EVENT_Z_INDEX}
									 button={
										 <Button name="add" icon="checkmark" text={t('Cart.Buttons.buttonAdd')} keyButton="F2"
														 onClick={handleF2} color="green" zIndex={EVENT_Z_INDEX} tabIndex="909" />
									 }>
				<CustomProductForm onSubmit={handleSubmit(onSubmit)}>
					<SelectInput name="itemType"
											 tabIndex="1"
											 watch={watch}
											 register={register}
											 options={{ required: t('app.validation.required') }}
											 errors={errors}
											 color="purple">
						<option key="MAIN_DISH" value="MAIN_DISH">{t('product.form.main.type_options.product')}</option>
						<option key="BEVERAGE" value="BEVERAGE">{t('product.form.main.type_options.drink')}</option>
						<option key="BOTTLE_DEPOSIT" value="BOTTLE_DEPOSIT">{t('product.form.main.type_options.deposit')}</option>
						{itemExist && <option key="COMMENT" value="COMMENT">{t('product.form.main.type_options.comment')}</option>}
					</SelectInput>
					<TextInput name="title"
										 type="text"
										 tabIndex="2"
										 label={t('product.table.description')}
										 errors={errors}
										 watch={watch}
										 register={register}
										 options={{ required: t('app.validation.required') }}
										 color="purple" />
					{watch('itemType') !== 'COMMENT' &&
						<TextInput name="price"
											 type="text"
											 tabIndex="3"
											 label="Preis"
											 errors={errors}
											 watch={watch}
											 register={register}
											 options={{
												required: t('app.validation.required'),
												pattern: {
													value: /^-?\d+([\\,]\d+)*([\\.]\d+)?$/,
													message: t('app.validation.number'),
												},
												validate: (value) => (!isBottleDeposit && getFloat(value) < 0) ? t('app.validation.greaterThenZero') : true
											}}
											 color="purple" />}
				</CustomProductForm>
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter} />}
		</>
	)
})

ModalCustomProduct.displayName = 'ModalCustomProduct'
export default ModalCustomProduct
