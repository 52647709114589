import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const TerminalWizard = lazyWithRetry(() => import(/* webpackChunkName: "terminal-wizard" */'../../component/PaymentsSettings/Terminal'))

const TerminalWizardPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<TerminalWizard />
		</Suspense>
	)
})

TerminalWizardPage.displayName = 'TerminalWizardPage'
export default TerminalWizardPage
