import { PER_LOAD } from '../../thunk/constants'
import phoneInstance from './instance'

const fetchPhones = (page = 0, size = PER_LOAD) => {
  return phoneInstance.get(
			'/voip-settings'
      + '?page=' + page
      + '&size=' + size,
    )
}

export default fetchPhones
