import React, { useCallback, useEffect, useRef, useState } from 'react'
import ButtonIcon from '../../../../img/svg/button'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { SelectButton, SelectButtonContent, SelectButtonIconWrap, SelectButtonText, SelectButtonToggle, SelectButtonValueWrap, SelectOption, SelectOptions, SelectOptionsWrap, SelectOptionText } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setTableCartSelectedDishCourseIndex } from '../../../../redux/action/tableCart'
import { COLORS } from '../../../RestaurantCartSettings/DishCoursesSettings/constants'

const DishCourses = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const pickerRef = useRef()
	const dishCoursesLength = useSelector(store => store.restaurantSettings.dishCourses?.coursesCount)
	const dishCoursesEnabled = useSelector(store => store.restaurantSettings.dishCourses?.enabled)
	const [isOptionsVisible, setIsOptionsVisible] = useState(false)
	const dishCoursesArray = Array.from({ length: dishCoursesLength }, (_, index) => `${t('Settings.dishCourses.dishCourse')} ${index + 1}`)
	const options = [t('Settings.dishCourses.defaultDishCourse'), ...dishCoursesArray]
	const selectDishCourseIndex = useSelector(state => state.tableCart.selectedDishCourseIndex)
	const layout = selectDishCourseIndex !== 0 ? 'dark' : ''

	useEffect(() => {
		dispatch(setTableCartSelectedDishCourseIndex(0))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const handleSelect = useCallback(() => {
		setIsOptionsVisible(!isOptionsVisible)
	}, [isOptionsVisible])

	const handleClickOption = useCallback((index) => {
		dispatch(setTableCartSelectedDishCourseIndex(index))
	}, [dispatch])

	useOutsideClick(pickerRef, () => {
		setIsOptionsVisible(false)
	})

	return (
		<>
			{dishCoursesEnabled &&
				<SelectButton tabIndex="0" onClick={handleSelect} data-testid="select-dish_courses" ref={pickerRef}>
					<SelectButtonContent color={COLORS[selectDishCourseIndex - 1]}>
						<SelectButtonValueWrap>
							<SelectButtonIconWrap className={layout}>
								<ButtonIcon icon="dish-courses" />
							</SelectButtonIconWrap>
							<SelectButtonText className={layout}>{options[selectDishCourseIndex]}</SelectButtonText>
						</SelectButtonValueWrap>
						<SelectButtonToggle className={!isOptionsVisible ? 'up' : ''}>
							<ButtonIcon icon="triangle" />
						</SelectButtonToggle>
					</SelectButtonContent>
					{isOptionsVisible && (
						<SelectOptionsWrap>
							<SelectOptions>
								{options.map((option, index) => (
									<SelectOption key={index}
																data-testid={`dish-course-${index}`}
																onClick={() => handleClickOption(index)}
																color={COLORS[index - 1]}>
										<SelectOptionText>
											<span>{option}</span>
										</SelectOptionText>
									</SelectOption>
								))}
							</SelectOptions>
						</SelectOptionsWrap>
					)}
				</SelectButton>}
		</>
	)
})

DishCourses.displayName = 'DishCourses'
export default DishCourses
