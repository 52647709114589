import React, { useCallback } from 'react'
import CancelOrder from './order/cancel'
import { useDispatch, useSelector } from 'react-redux'
import CustomerOrderButtonsModal from './order/'
import OrderDataModal from '../../Elements/modal/order/OrderDataModal'
import SingleDriverModal from '../../DriverMonitor/modal/singleDriver'
import { setModalOrderCustomer } from '../../../redux/action/customer'
import ModalPayment from '../../Elements/cart/ModalPayment'
import { confirmOrderPaymentsThunk, updateOrderThunk } from '../../../redux/thunk/order'
import { setDeliveryCartOrder } from '../../../redux/action/deliveryCart'
import PrintCanceledOrder from './order/printCanceled'
import DriverConfirmationModal from '../../WoltDriver/DriverConfirmationModal'
import NoDriverModal from '../../WoltDriver/NoDriverModal'
import PrintReceiptConfirmationModal from '../../Elements/modal/PrintReceiptConfirmationModal'
import { roundPrice } from '../../../helper'
import { getGiftCouponsPayment, getGiftCouponsSum } from '../../../helper/getGiftCouponsPayment'
import { updateOrderCustomerThunk } from '../../../redux/thunk/order/updateOrderCustomer'

const DeliveryModals = React.memo(() => {
	const showOrderData = useSelector(store => store.customerStore.modal.showOrderData)
	const showPaymentMethod = useSelector(store => store.customerStore.modal.showPaymentMethod)
	const deliveryCartOrder = useSelector(store => store.deliveryCart.order)
	const orderData = useSelector(store => store.order.selectedOrder)
	const showDriverConfirmationModal = useSelector(store => store.woltDriverIntegrations.deliveryPromises)
	const showNoDriverModal = useSelector(store => store.woltDriverIntegrations.driverNotFound)
	const dispatch = useDispatch()
	const qrCodePaymentSession = useSelector(state => state.cartPayment.qrCodePaymentSession)
	const paymentSessionLink = qrCodePaymentSession?.link

	const orderPayment = deliveryCartOrder?.payments?.filter(item => item?.method === deliveryCartOrder?.paymentMethod)
	const isPaid = orderPayment?.[0]?.payed
	const totalSum = (isPaid && deliveryCartOrder?.paymentMethod === 'EC_TERMINAL') ? roundPrice(deliveryCartOrder?.totalPrice - deliveryCartOrder?.tips) : deliveryCartOrder?.totalPrice

	const giftCoupons = deliveryCartOrder?.payments?.filter(payment => payment.method === 'GIFT_COUPON')
	const giftCouponSum = giftCoupons?.length ? giftCoupons.reduce((res, el) => res + el.total, 0) : 0
	const totalSumWithoutGiftCoupons = roundPrice(totalSum - giftCouponSum)

	const closeModalPayment = useCallback((forceClose = true) => {
		// force = false: not reset order when pay by ec-terminal
		forceClose && dispatch(setDeliveryCartOrder(null))
		dispatch(setModalOrderCustomer({ showPaymentMethod: false }))
	}, [dispatch])

	const processUnpaidOrder = useCallback(async (paymentId, paymentMethod, giftCouponsAsPayment, isEcTerminalPaymentMethod, tips, customer) => {
		const isGiftCouponPayment = paymentMethod === 'GIFT_COUPON'
		const isQrCodePayment = paymentMethod === 'QR_CODE'
		const isInvoicePayment = paymentMethod === 'INVOICE'
		if (isInvoicePayment) {
			await dispatch(updateOrderCustomerThunk(deliveryCartOrder.orderId, customer))
		}
		const paymentWithCoupons = getGiftCouponsPayment(giftCouponsAsPayment)
		const allGiftCoupons = [...paymentWithCoupons, ...giftCoupons]
		const giftCouponSum = getGiftCouponsSum(giftCouponsAsPayment)
		const orderPaymentId = isEcTerminalPaymentMethod || isQrCodePayment ? paymentId : orderPayment[0].id
		const paymentLink = isQrCodePayment ? paymentSessionLink : null
		const payments = isGiftCouponPayment ? allGiftCoupons : [{ id: orderPaymentId, method: paymentMethod, total: roundPrice(totalSumWithoutGiftCoupons - giftCouponSum), link: paymentLink }, ...allGiftCoupons]
		dispatch(confirmOrderPaymentsThunk(deliveryCartOrder.orderId, { payments, tips }, { module: deliveryCartOrder?.module?.moduleType, businessScope: deliveryCartOrder?.orderType, platformType: deliveryCartOrder?.platformMetaData?.platformType }))
	}, [ giftCoupons, orderPayment, deliveryCartOrder, dispatch, totalSumWithoutGiftCoupons, paymentSessionLink ])

	const handlePayment = useCallback(({ paymentId, paymentMethod, remark, tips = 0, giftCoupons: giftCouponsAsPayment, customer }) => {
		const isEcTerminalPaymentMethod = paymentMethod === 'EC_TERMINAL'
		const isQrCodePayment = paymentMethod === 'QR_CODE'
		const resultTotalPrice = isEcTerminalPaymentMethod ? roundPrice(deliveryCartOrder.totalPrice - deliveryCartOrder.tips + tips) : roundPrice(deliveryCartOrder.totalPrice - deliveryCartOrder.tips)
		if (isPaid) {
			const orderData = {
				...deliveryCartOrder,
				totalPrice: resultTotalPrice,
				tips,
				paymentMethod, payments: [{ id: paymentId, method: paymentMethod, total: resultTotalPrice, payed: true }], remark,
			}
			dispatch(updateOrderThunk(orderData.orderId, orderData))
		} else {
			processUnpaidOrder(paymentId, paymentMethod, giftCouponsAsPayment, isEcTerminalPaymentMethod, tips, customer)
		}
		if (!isEcTerminalPaymentMethod && !isQrCodePayment) {
			closeModalPayment()
		}
	}, [deliveryCartOrder, closeModalPayment, dispatch, isPaid, processUnpaidOrder])

	const closeModalOrderData = useCallback(() => {
		dispatch(setModalOrderCustomer({ showOrderData: false }))
	}, [dispatch])

	const reOpenPaymentModal = useCallback(() => {
		dispatch(setModalOrderCustomer({ showPaymentMethod: true }))
	}, [dispatch])

	return (
		<>
			<CustomerOrderButtonsModal />
			<SingleDriverModal />
			<CancelOrder />
			<OrderDataModal order={orderData}
											modalIsOpen={showOrderData}
											close={closeModalOrderData} />
			<ModalPayment isOpen={showPaymentMethod}
										closeModal={closeModalPayment}
										openModal={reOpenPaymentModal}
										totalSum={isPaid ? totalSum : totalSumWithoutGiftCoupons}
										handlePayment={handlePayment}
										remark={deliveryCartOrder?.remark}
										isDeferredPayment={!isPaid}
										updatePaymentMode={isPaid}
										module={deliveryCartOrder?.module?.moduleType}
										orderType={deliveryCartOrder?.orderType}
										platformType={deliveryCartOrder?.platformMetaData?.platformType}
										resetCart={closeModalPayment}
										customerOrder={deliveryCartOrder?.customer}/>
			<PrintCanceledOrder />
			{showDriverConfirmationModal && <DriverConfirmationModal />}
			{showNoDriverModal && <NoDriverModal />}
			<PrintReceiptConfirmationModal />
		</>
	)
})

DeliveryModals.displayName = 'DeliveryModals'
export default DeliveryModals
