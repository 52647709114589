import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const PaymentsSettings = lazyWithRetry(() => import(/* webpackChunkName: "payment" */'../../component/PaymentsSettings'))

const PaymentsSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<PaymentsSettings />
		</Suspense>
	)
})

PaymentsSettingsPage.displayName = 'PaymentsSettingsPage'
export default PaymentsSettingsPage
