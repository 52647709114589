import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RestaurantOrdersContainer } from './index.styled'
import { fetchRestaurantOrdersThunk } from '../../../redux/thunk/restaurant/orders'
import { setRestaurantOrdersChangeModal, setRestaurantTable } from '../../../redux/action/restaurant'
import Button from '../../Elements/buttons/Button'
import CustomFullScreenModal from '../../Elements/modal/CustomFullScreenModal'
import Tabs from './tabs/Tabs'
import OrdersTable from './OrdersTable'
import InvoicesTable from '../../Elements/table/InvoicesTable'
import { getInvoicesThunk } from '../../../redux/thunk/invoice'

const RestaurantOrders = () => {
	const showOrdersModal = useSelector(state => state.restaurant.ordersChangeModal)
	const ordersTotal = useSelector(state => state.restaurant.ordersTotal)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState('ORDERS')

	useEffect(() => {
		dispatch(fetchRestaurantOrdersThunk(0, 20))
		dispatch(getInvoicesThunk(0, 10))
	}, [showOrdersModal]) // eslint-disable-line react-hooks/exhaustive-deps

	const openRestaurantOrders = () => {
		dispatch(setRestaurantOrdersChangeModal(true))
		dispatch(setRestaurantTable(null))
	}

	const handleTabClick = useCallback((tab) => {
		setActiveTab(tab)
	}, [])

	return (
		<>
			<Button text={t('order.button')} size="small" color="blue" count={ordersTotal} onClick={openRestaurantOrders} />
			<CustomFullScreenModal isOpen={showOrdersModal}
														 close={() => dispatch(setRestaurantOrdersChangeModal(false))}
														 title={t('order.table_orders')}
														 showBackButton={true}>
				<RestaurantOrdersContainer>
					<Tabs onTabClick={handleTabClick} activeTab={activeTab} />
					{activeTab === 'ORDERS' && <OrdersTable />}
					{activeTab === 'INVOICES' &&
						<InvoicesTable id="restaurant-invoices-table" />}
				</RestaurantOrdersContainer>
			</CustomFullScreenModal>
		</>
	)
}

RestaurantOrders.displayName = 'RestaurantOrders'
export default RestaurantOrders
