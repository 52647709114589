import React from 'react'
import ButtonIcon from '../../../img/svg/button'
import { WebShopNotificationBtnContent, WebShopNotificationBtnPercent, WebShopNotificationBtnText, WebShopNotificationBtnWrap } from './index.styled'

const WebShopNotificationBtn = React.memo(({openModal}) => {
	return (
		<WebShopNotificationBtnWrap onClick={openModal}>
			<WebShopNotificationBtnContent>
				<ButtonIcon icon="webshop" />
				<WebShopNotificationBtnText>WEBSHOP</WebShopNotificationBtnText>
				<WebShopNotificationBtnPercent>
					<ButtonIcon icon="burst-shape" />
				</WebShopNotificationBtnPercent>
			</WebShopNotificationBtnContent>
			<WebShopNotificationBtnText className="big">NEW!</WebShopNotificationBtnText>
		</WebShopNotificationBtnWrap>
	)
})

WebShopNotificationBtn.displayName = 'WebShopNotificationBtn'

export default WebShopNotificationBtn
