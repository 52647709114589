import axios from 'axios'
import { cashDeskServiceUrl } from '../../../../../urls'
import { getAuthUuid } from '../../../../../helper/clientMonitor'

const headers = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest',
}

const authUuid = getAuthUuid()

if (authUuid) {
	headers.Authorization = `authUuid:${authUuid}`
}

const cashDeskPublicInstance = axios.create({
	baseURL: cashDeskServiceUrl,
	headers,
})

export default cashDeskPublicInstance
