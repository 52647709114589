import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const TableMobileTitle = styled.div`
	font-size: 1em;
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.table.tableTr.mobileTitle};
	display: none;
	grid-column: 2;
	grid-row: 1;

	@media screen and (max-width: ${theme.point820}) {
		display: block;
		margin-bottom: .36em;

		&.small {
			font-size: 0.86em;
			margin-bottom: 0.8em;
		}

		&.medium {
			font-size: 1.06em;
			color: ${colorTheme.table.tableTd.color};
			font-weight: 600;
			margin-bottom: 0.5em;
		}
	}
	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: .31em;

		&.small {
			font-size: 0.83em;
		}

		&.medium {
			font-size: 1em;
		}
	}

`
TableMobileTitle.displayName = 'TableMobileTitle'

export const TableBoldTitle = styled.div`
	font-weight: 500;

	@media screen and (max-width: ${theme.point820}) {
		display: flex;
		align-items: center;
		font-weight: 600;
		font-size: 1.14em;
		height: 100%;

		&.medium {
			font-size: 1em;
			color:#383A50;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.11em;
		margin-top: 0.55em;
	}
`
TableBoldTitle.displayName = 'TableBoldTitle'
