import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { ButtonWrapper } from '../../../buttons/Button/style/buttonWrap/index.styled'

export const MenuSelectionWrapper = styled.div`
  font-size: 1rem;
  background-color: ${colorTheme.cart.productSearch.menuSelection.background};
  margin-bottom: 0.63em;
  border-radius: 0.75em;
  border: 2px solid ${colorTheme.cart.productSearch.menuSelection.border};
  position: relative;
  padding: 0.5em;
`
MenuSelectionWrapper.displayName = 'MenuSelectionWrapper'

export const MenuSelectionTitle = styled.div`
  display: flex;
  align-items: center;
	justify-content: center;
  margin-bottom: 0.63em;
  gap: 0.32em;

  > svg {
    width: 2.26em;
    height: 1.75em;
		fill:${colorTheme.cart.productSearch.menuSelection.titleIcon};
  }

  > div {
    display: flex;
    align-items: center;
    font-family: ${theme.fontRobotoMedium};
    color: ${colorTheme.cart.productSearch.menuSelection.title};
    font-size: 1.25em;
    font-weight: 700;
		margin-left: 0.25em;

    > span {
      margin-left: 0.67em;
    }
  }

  @media screen and (max-width: ${theme.point820}) {
    flex-wrap: wrap;
  }

	@media screen and (max-width: ${theme.point720}) {
    > div {
			font-size: 1.125em;
			margin-left: 0.28em;
		}
  }
`
MenuSelectionTitle.displayName = 'MenuSelectionTitle'

export const MenuProductsList = styled.div`
	display: grid;
	gap: 0.63em;
	padding: 0.63em 0;
	border-top: 1px dashed ${colorTheme.cart.productSearch.menuSelection.borderList};
	border-bottom: 1px dashed ${colorTheme.cart.productSearch.menuSelection.borderList};
`
MenuProductsList.displayName = 'MenuProductsList'

export const MenuProduct = styled.div`
  background: ${colorTheme.cart.productSearch.menuSelection.product.background};
  border-radius: 0.75em;
  border: 1px solid ${colorTheme.cart.productSearch.menuSelection.product.border};
  display: flex;
  padding: 0.28em 0.5em 0.28em 0.63em;
  align-items: center;
	justify-content: space-between;
  min-height: 2.87em;
  text-align: left;
  color: ${colorTheme.cart.productSearch.menuSelection.product.text};
  cursor: pointer;
  font-family: ${theme.fontRobotoMedium};
  font-size: 1em;
  font-weight: 500;

  &.active {
    color: ${colorTheme.cart.productSearch.menuSelection.product.textActive};
    border: 1px solid ${colorTheme.cart.productSearch.menuSelection.product.borderActive};
    background: ${colorTheme.cart.productSearch.menuSelection.product.backgroundActive};
    box-shadow: 0.02em 0.25em 1.69em 0 rgba(0, 0, 0, 0.22);
  }
`
MenuProduct.displayName = 'MenuProduct'

export const MenuProductTitle = styled.div`
  display: flex;
	gap: 0.32em;

	> svg {
		width: 1.25em;
		height: 1.25em;
		fill: ${colorTheme.cart.productSearch.menuSelection.product.icon};
	}

	&.active {
    > svg {
			fill: ${colorTheme.cart.productSearch.menuSelection.product.iconActive};
		}
  }
`
MenuProduct.displayName = 'MenuProduct'

export const MenuButtonsWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.63em;
	margin-top: 0.63em;

	${ButtonWrapper} {
		min-height: 2.68em;
		padding: 0.5em 1em;
	}

	@media screen and (max-width: ${theme.point820}) {
		flex-direction: column-reverse;
    ${ButtonWrapper} {
			width: 100%;
			> div:nth-child(2) {
				font-size: 1em;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: row;
		${ButtonWrapper} {
			width: auto;
		}
  }
`
MenuButtonsWrapper.displayName = 'MenuButtonsWrapper'
