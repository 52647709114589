import React from 'react'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'
import Portal from '../../Portal'
import MobileCartButton from '../MobileCartButton'

const MobileCartButtonPortal = React.memo(({ cartItemsCount, toggleMobileCart }) => {
	const isMobile = useWindowBreakPoint(theme.point720)

	return isMobile &&
		<Portal containerId="header-right">
			<MobileCartButton count={cartItemsCount} onClick={toggleMobileCart}/>
		</Portal>
})

MobileCartButtonPortal.displayName = 'MobileCartButtonPortal'
export default MobileCartButtonPortal
