import productInstance from './instance'

const fetchProducts = (page = 0, size = 10, title, nrTitle, productType, categoryId = null, mustExtras = null, activePosPrices = false, orderByTitleAsc = null, hasAdditivesGroup = null, enabledForMenu = false, businessScope = null, priceTitleId = null, menuPositionId = null) => {
	return productInstance.get(
    '/products/'
    + '?page=' + page
    + '&size=' + size
    + (title ? '&title=' + title : '')
    + (nrTitle ? '&nrTitle=' + nrTitle : '')
    + (productType ? '&productType=' + productType : '')
    + (categoryId != null ? '&categoryId=' + categoryId : '')
    + (mustExtras !== null ? '&mustExtras=' + mustExtras : '')
    + (activePosPrices ? '&activePosPrices=' + activePosPrices : '')
		+ (orderByTitleAsc !== null ? '&orderByTitleAsc=' + orderByTitleAsc : '')
		+ (hasAdditivesGroup !== null ? '&hasAdditivesGroup=' + hasAdditivesGroup : '')
		+ (enabledForMenu ? '&enabledForMenu=' + enabledForMenu : '')
		+ (businessScope !== null ? '&businessScope=' + businessScope : '')
		+ (priceTitleId !== null ? '&priceTitleId=' + priceTitleId : '')
		+ (menuPositionId !== null ? '&menuPositionId=' + menuPositionId : '')
	)
}

export default fetchProducts

