import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const ProductSettings = lazyWithRetry(() => import(/* webpackChunkName: "product-settings" */'../../component/Settings/ProductSettings'))

const ProductSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<ProductSettings />
		</Suspense>
	)
})

ProductSettingsPage.displayName = 'ProductSettingsPage'
export default ProductSettingsPage
