import addOrderProcessingTime from '../../../axios/order/addOrderProcessingTime'
import { retrieveErrorFromApi } from '../../../../helper'
import { setAppErrors, setAppModal, setAppModalData } from '../../../action/system'
import denyOrder from '../../../axios/order/denyOrder'
import { closePlatformDriversOrderModalThunk, closeProcessingTimeModalThunk, handleOrderForOrdersCounterThunk, handleOrderForUnpaidOrdersCounterThunk, updateOrderToTableThunk, updatePreOrderOtherDaysToTableThunk, updatePreOrderTodayToTableThunk, updateUnpaidOrderToTableThunk } from '../../../../socket/order'
import { setIsProcessingConfirmOrder, setIsProcessingDenyOrder } from '../../../action/order'

export const removeOrderFromProcessingTimeModalDataThunk = (orderId) => (dispatch, getState) => {
	const processingTimeOrderModalData = getState().system.modalData?.processingTimeOrderModalData?.filter(o => o.orderId !== orderId)
	dispatch(setAppModal({ processingTimeOrderModalShow: false }))
	dispatch(setAppModalData({ processingTimeOrderModalData }))
}

export const addOrderProcessingTimeThunk = (orderId, processingTime) => dispatch => {
	dispatch(setIsProcessingConfirmOrder(true))
	return addOrderProcessingTime(orderId, processingTime)
		.then(res => {
			dispatch(removeOrderFromProcessingTimeModalDataThunk(orderId))
			dispatch(closeProcessingTimeModalThunk(res.data))
			dispatch(updateOrderToTableThunk(res.data, 'confirmed_manually'))
			dispatch(closePlatformDriversOrderModalThunk(res.data))
			dispatch(handleOrderForOrdersCounterThunk(res.data))
			dispatch(updatePreOrderTodayToTableThunk(res.data, 'confirmed_manually'))
			dispatch(updatePreOrderOtherDaysToTableThunk(res.data, 'confirmed_manually'))
			dispatch(updateUnpaidOrderToTableThunk(res.data, 'confirmed_manually'))
			dispatch(handleOrderForUnpaidOrdersCounterThunk(res.data))
			dispatch(setIsProcessingConfirmOrder(false))
		}).catch(error => {
			dispatch(removeOrderFromProcessingTimeModalDataThunk(orderId))
			dispatch(setIsProcessingConfirmOrder(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const denyOrderThunk = (orderId, reason) => dispatch => {
	dispatch(setIsProcessingDenyOrder(true))
	return denyOrder(orderId, reason)
		.then(res => {
			dispatch(removeOrderFromProcessingTimeModalDataThunk(orderId))
			dispatch(setIsProcessingDenyOrder(false))
		}).catch(error => {
			dispatch(removeOrderFromProcessingTimeModalDataThunk(orderId))
			dispatch(setIsProcessingDenyOrder(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
