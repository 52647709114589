import React, { useCallback, useRef, useState } from 'react'
import { Tooltip, TooltipBox, TooltipLineWrap, TooltipText, TooltipWrapper, TooptipArrow } from './index.styled'
import { useOutsideClick } from '../../../../../hooks/useOutsideClick'

const TableTdElementsTooltip = ({ text, children }) => {
	const [isHovered, setIsHovered] = useState(false)
	const tooltipRef = useRef(null)

	const handlePointerEnter = useCallback(() => {
		setIsHovered(true)
	}, [])

	const handlePointerLeave = useCallback(() => {
		setIsHovered(false)
	}, [])

	useOutsideClick(tooltipRef, () => {
		setIsHovered(false)
	})

	return (
		<TooltipWrapper onPointerEnter={handlePointerEnter}
										onPointerLeave={handlePointerLeave}
										ref={tooltipRef}>
			{isHovered &&
				<Tooltip>
					<TooltipLineWrap>
						<TooltipBox>
							<TooltipText>{text}</TooltipText>
						</TooltipBox>
					</TooltipLineWrap>
					<TooptipArrow />
				</Tooltip>}
			{children}
		</TooltipWrapper>
	)
}

TableTdElementsTooltip.displayName = 'TableTdElementsTooltip'
export default TableTdElementsTooltip
