import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { theme } from '../../../../../style/theme'

export const IncomingCallIconWrapper = styled.div`
	display: flex;
	width: 3.5em;
	height: 3.5em;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: ${colorTheme.incomeCallModal.selectedCall.iconBackground};

	@media screen and (max-width: ${theme.point1440}) {
		width: 4.3em;
		height: 4.3em;
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 3.57em;
		height: 3.57em;
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 3.13em;
		height: 3.13em;
	}

	& > svg path.phone {
		transform-origin: center;
		animation: shaking 2.5s infinite;
	}

	& > svg path.wave-first {
		animation: waveFirst 0.7s infinite 0.2s;
	}

	& > svg path.wave-second {
		animation: waveSecond 0.7s infinite 0.3s;
	}


	@keyframes waveFirst {
		0% {
			opacity: 0;
		}

		10% {
			opacity: 0.5;
		}

		50% {
			opacity: 1;
		}

		75% {
			opacity: 0.5;
		}

		90%, 100% {
			opacity: 0;
		}
	}

	@keyframes waveSecond {
		0% {
			opacity: 0;
		}

		25% {
			opacity: 0.5;
		}

		50% {
			opacity: 1;
		}

		70% {
			opacity: 1;
		}

		90% {
			opacity: 0.5;
		}

		100% {
			opacity: 0;
		}
	}


	@keyframes shaking {
		0%, 5%, 10%, 15%, 20%, 25%, 30%, 35%, 40% {
			transform: rotate(0deg);
		}

		2%, 12%, 22%, 32% {
			transform: rotate(10deg);
		}

		7%, 17%, 27%, 37% {
			transform: rotate(-10deg);
		}
	}
`
IncomingCallIconWrapper.displayName = 'IncomingCallIconWrapper'
