import { set, addDays, compareAsc, sub, add, differenceInMinutes } from 'date-fns'
import { ERROR_BEFORE_BREAK, ERROR_AFTER_BREAK, START_WORKING_HOUR, MIN_WORKING_MINUTES } from '../constants'

export const isValidTime = (value) => {
	const regexp = /^\d{0,2}?:?\d{0,2}$/
	const [hoursStr, minutesStr] = value.split(':')

	if (!regexp.test(value)) {
		return false
	}

	const hours = Number(hoursStr)
	const minutes = Number(minutesStr)

	if (!isValidHoursOrMinutes(hours, 'hours') || !(isValidHoursOrMinutes(minutes, 'minutes') && hours >= 0 && hours < 24)) {
		return false
	}

	return true
}

export const isValidHoursOrMinutes = (value, type = '') => {
	const regexp = /^\d{0,2}$/

	if (!regexp.test(value)) {
		return false
	}

	if (type === 'hours') {
		const hours = Number(value)

		if (!(Number.isInteger(hours) && hours >= 0 && hours < 24)) {
			return false
		}

		return true
	} else {
		const minutes = Number(value)

		if (!(Number.isInteger(minutes) || Number.isNaN(minutes))) {
			return false
		}

		if (minutes && (parseInt(minutes, 10) < 0 || parseInt(minutes, 10) > 59)) {
			return false
		}

		return true
	}
}

export const handleHoursOrMinutesValidationOnBlur = (value) => {
	if (!value.length) value = '00'
	if (value.length === 1) value = '0' + value

	return value
}

export const handleTimeValidationOnBlur = (value) => {
	if (!value || value === ':') {
		return '00:00'
	}

	let [hoursStr, minutesStr] = value.split(':')
	hoursStr = handleHoursOrMinutesValidationOnBlur(hoursStr)
	minutesStr = handleHoursOrMinutesValidationOnBlur(minutesStr)

	return hoursStr + ':' + minutesStr
}

export const convertTimePeriodsToMinutes = (array) => {
	return array?.map(item => ({
		...item,
		periods: item.periods?.map(period => ({
			openingTime: period?.openingTime.split(':').slice(0, 2).join(':'),
			closingTime: period?.closingTime.split(':').slice(0, 2).join(':'),
		}))
	}))
}

export const convertTimePeriodsToSeconds = (data) => {
	const convertTimeToSeconds = (time) => {
		return time.split(':').length < 3 ? time + ':00' : time
	}

	// for schedule array
	if (Array.isArray(data)) {
		return data?.map(item => {
			const itemPeriods = item.periods?.map(period => {
				return ({
					openingTime: convertTimeToSeconds(period.openingTime),
					closingTime: convertTimeToSeconds(period.closingTime),
				})
			})

			return ({
				...item,
				periods: itemPeriods
			})
		})
	} else {
		// for special day object
		const itemPeriods = data.periods?.map(period => {
			return ({
				openingTime: convertTimeToSeconds(period.openingTime),
				closingTime: convertTimeToSeconds(period.closingTime),
			})
		})

		return ({
			...data,
			periods: itemPeriods
		})
	}
}

const convertTimeToDateFormat = (item, closeTimeExactOpenTime = false) => {
	const today = new Date()
	const [hours, minutes] = item.split(':')
	const isTommorow = +hours < START_WORKING_HOUR || closeTimeExactOpenTime
	const date = isTommorow ? addDays(today, 1) : today
	return set(date, { hours, minutes, seconds: 0 })
}

const checkIsValidCompareTime = (firstDate, secondDate) => {
	if (compareAsc(firstDate, secondDate) === -1) return true // is valid - first date is before the second
	return false
}

const checkTimeIsStartWorkingHours = (time) => {
	const [hours, minutes] = time.split(':')
	return +hours === START_WORKING_HOUR && +minutes === 0
}

export const checkPeriodCloseTimeExactOpenTime = (periods) => {
	const openingHours = periods[0].openingTime
	const openTimeIsDateStart = checkTimeIsStartWorkingHours(openingHours)
	const closingHours = periods.length > 1 ? periods[1].closingTime : periods[0].closingTime
	const closeTimeIsDateEnd = checkTimeIsStartWorkingHours(closingHours)
	return openTimeIsDateStart && closeTimeIsDateEnd
}

export const isValidTimePeriodsOrder = (periods, minDifferenceTime = false, delayAfterOpen = { hours: 0, minutes: 0 }, delayBeforeClose = { hours: 0, minutes: 0 }) => {
	const today = new Date()
	const tommorow = addDays(today, 1)
	const controlDateStart = set(today, { hours: START_WORKING_HOUR - 1, minutes: 59, seconds: 59 })
	const controlDateEnd = set(tommorow, { hours: START_WORKING_HOUR, minutes: 0, seconds: 1 })
	const minDifferenceTimeInMinutes = minDifferenceTime ? MIN_WORKING_MINUTES : 1
	const periodCloseTimeExactOpenTime = checkPeriodCloseTimeExactOpenTime(periods)

	const openingHoursBeforeBreak = periods[0].openingTime
	const closingHoursBeforeBreak = periods[0].closingTime
	const convertedOpeningHoursBeforeBreak = convertTimeToDateFormat(openingHoursBeforeBreak)
	const watchClosingHoursBeforeBreakIsExactOpenTime = periods.length > 1 ? false : periodCloseTimeExactOpenTime
	const convertedClosingHoursBeforeBreak = convertTimeToDateFormat(closingHoursBeforeBreak, watchClosingHoursBeforeBreakIsExactOpenTime)
	const openingHoursBeforeBreakWithPreorderDelay = add(convertedOpeningHoursBeforeBreak, delayAfterOpen)
	const closingHoursBeforeBreakWithPreorderDelay = sub(convertedClosingHoursBeforeBreak, delayBeforeClose)
	const isValidMinWorkingTimeBeforeBreak = differenceInMinutes(closingHoursBeforeBreakWithPreorderDelay, openingHoursBeforeBreakWithPreorderDelay) >= minDifferenceTimeInMinutes

	if (periods.length > 1) {
		const openingHoursAfterBreak = periods[1].openingTime
		const closingHoursAfterBreak = periods[1].closingTime
		const convertedOpeningHoursAfterBreak = convertTimeToDateFormat(openingHoursAfterBreak)
		const convertedClosingHoursAfterBreak = convertTimeToDateFormat(closingHoursAfterBreak, periodCloseTimeExactOpenTime)
		const openingHoursAfterBreakWithPreorderDelay = add(convertedOpeningHoursAfterBreak, delayAfterOpen)
		const closingHoursAfterBreakWithPreorderDelay = sub(convertedClosingHoursAfterBreak, delayBeforeClose)
		const isValidMinWorkingTimeAfterBreak = differenceInMinutes(closingHoursAfterBreakWithPreorderDelay, openingHoursAfterBreakWithPreorderDelay) >= minDifferenceTimeInMinutes

		return checkIsValidCompareTime(controlDateStart, convertedOpeningHoursBeforeBreak)
			&& checkIsValidCompareTime(convertedOpeningHoursBeforeBreak, controlDateEnd)
			&& checkIsValidCompareTime(convertedOpeningHoursAfterBreak, controlDateEnd)
			&& checkIsValidCompareTime(convertedClosingHoursBeforeBreak, convertedOpeningHoursAfterBreak)
			&& isValidMinWorkingTimeBeforeBreak && isValidMinWorkingTimeAfterBreak
	} else {
		return checkIsValidCompareTime(controlDateStart, convertedOpeningHoursBeforeBreak)
			&& checkIsValidCompareTime(convertedClosingHoursBeforeBreak, controlDateEnd)
			&& isValidMinWorkingTimeBeforeBreak
	}
}

export const setInvalidTimePeriodsArray = (periods, delayAfterOpen, delayBeforeClose) => {
	const resultArray = []

	if (periods.length > 1) {
		const isValidPeriodBeforeBreak = isValidTimePeriodsOrder([periods[0]], true, delayAfterOpen, delayBeforeClose)
		const isValidPeriodAfterBreak = isValidTimePeriodsOrder([periods[1]], true, delayAfterOpen, delayBeforeClose)

		if (!isValidPeriodBeforeBreak && isValidPeriodAfterBreak) {
			return resultArray.concat(ERROR_BEFORE_BREAK)
		} else if (isValidPeriodBeforeBreak && !isValidPeriodAfterBreak) {
			return resultArray.concat(ERROR_AFTER_BREAK)
		} else {
			return resultArray.concat(ERROR_BEFORE_BREAK, ERROR_AFTER_BREAK)
		}
	} else {
		if (!isValidTimePeriodsOrder(periods, true, delayAfterOpen, delayBeforeClose)) {
			return resultArray.concat(ERROR_BEFORE_BREAK)
		}
	}
}

export const convertTimeStrToHoursAndMinutesObj = (time) => {
	if (time) {
		const [hours, minutes] = time.split(':')
		return { hours, minutes }
	}
}
