import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import TextInput from '../../component/Elements/inputs/TextInput'
import phoneCallTest from '../../redux/axios/phone/phoneCallTest/post'
import { toast } from 'react-toastify'
import { FormInputs } from '../../component/Elements/form/index.styled'
import Button from '../../component/Elements/buttons/Button'
import Header from '../../component/Header'
import MainWrapper from '../../component/MainWrapper'

const DesktopClientPage = React.memo(() => {
	const [phoneCall, setPhoneCall] = useState()
	const dispatch = useDispatch()

	const doPhoneCall = useCallback((e) => {
		dispatch(phoneCallTest(phoneCall,
			() => toast.success('Call sent'),
			() => toast.error('Empty phone')))
	}, [phoneCall])

	return (
		<>
			<Header />
			<MainWrapper>
				<FormInputs cols={2} className="half">
					<TextInput name="phoneCall"
										 label="Phone"
										 register={(name, options) => ({ name, ...options })}
										 onChange={e => setPhoneCall(e.target.value)}
										 color="gray" />
					<Button color="blue" onClick={doPhoneCall} size="big" text="Push incoming phone" className="btn-phone-call" />
				</FormInputs>
			</MainWrapper>
		</>
	)
})

DesktopClientPage.displayName = 'DesktopClientPage'
export default DesktopClientPage
