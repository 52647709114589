import { SET_ALL_PLATFORM_USERS, SET_PLATFORM_COPY_DATA, SET_PLATFORM_SHOW_MODAL } from '../../actionTypes/platform'
import { SET_API_UPDATE_NEW_RESTAURANT, SET_API_UPDATE_RESTAURANT } from '../../actionTypes/api'

const initialState = {
  users: [],
  showModal: false,
  copyData: {
    login: null,
    password: null,
    applicationId: null,
  },
  updateRestaurant: null,
  updatedNewRestaurant: null,
}

export function platformUserReducer (state = initialState, action) {
  switch (action.type) {
    case SET_ALL_PLATFORM_USERS:
      return {
        ...state,
        users: action.value,
      }
    case SET_PLATFORM_SHOW_MODAL:
      return {
        ...state,
        showModal: action.value,
      }
    case SET_PLATFORM_COPY_DATA:
      return {
        ...state,
        copyData: action.value,
      }
    case SET_API_UPDATE_RESTAURANT:
      return {
        ...state,
        updateRestaurant: action.value,
      }
    case SET_API_UPDATE_NEW_RESTAURANT:
      return {
        ...state,
        updatedNewRestaurant: action.value,
      }
    default:
      return state
  }
}
