import styled from 'styled-components'
import { theme } from '../../../style/theme'

export const SettingsWrapper = styled.div`
	font-size: 1rem;
	padding: 1.88em 3em 2.5em 2.5em;

	&.short {
		@media screen and (max-width: ${theme.point1280}) {
			max-width: 73.75em;
		}
	}

	@media screen and (max-width: ${theme.point1280}) {
		padding: 1.43em 0.95em;
	}

	@media screen and (max-width: ${theme.point820}) {
		padding: 1em 0.71em;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.63em 1.25em;
	}
`
