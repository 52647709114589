import React, { useCallback, useEffect, useState } from 'react'
import { ChildrenWrapper, InputError, InputField, InputImg, InputLabel, InputLabelText, InputWrapper } from './index.styled'
import keyboardImg from '../../../../img/svg/input/keyboard.svg'
import closedEyeImg from '../../../../img/svg/input/closed_eye.svg'
import openedEyeImg from '../../../../img/svg/input/opened_eye.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setSystemKeyboardText, setSystemShowKeyboard } from '../../../../redux/action/system'

const TextInput = React.memo(({
	register,
	options,
	name,
	label = '',
	type = 'text',
	defaultValue,
	readOnly,
	errors = {},
	errorParser,
	children,
	autoFocus,
	testId,
	keyBoard,
	tabIndex,
	color,
	inputMode,
	autoComplete,
	disabled,
	size,
	className,
	hasLongError = false,
	showEye,
	watch,
	setValue = () => {
	},
	onChange,
	onBlur = () => {
	},
	onFocus = () => {
	}, passwordClass
}) => {
	const [focused, setFocused] = useState(false)
	const [passwordType, setPasswordType] = useState('password')
	const keyboardText = useSelector(state => state.system.keyboardText)
	const showKeyboard = useSelector(state => state.system.showKeyboard)
	const dispatch = useDispatch()

	const value = typeof watch === 'function' ? watch?.(name) : watch

	useEffect(() => {
		if (showKeyboard && focused) {
			setValue(name, keyboardText)
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyboardText])

	useEffect(() => {
		if (!showKeyboard && focused) {
			setFocused(false)
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showKeyboard])

	const toggleKeyboard = useCallback(() => {
		dispatch(setSystemShowKeyboard(!showKeyboard))
		dispatch(setSystemKeyboardText(value))
	}, [showKeyboard, value, dispatch])

	const toggleEye = () => {
		if (passwordType === 'password') {
			setPasswordType('text')
			return
		}

		setPasswordType('password')
	}

	const handleFocus = useCallback(() => {
		setFocused(true)
		onFocus()
	}, [onFocus])

	const handleBlur = useCallback(() => {
		if (!keyBoard || !showKeyboard) {
			setFocused(false)
		}
		onBlur(value, (v) => setValue(name, v))
	}, [keyBoard, showKeyboard, onBlur, value, name, setValue])

	const isIcon = showEye || keyBoard
	const labelUp = focused || readOnly || defaultValue || (value || value === 0)
	const errorMessage = errors && (errorParser ? errorParser(errors) : errors[name])?.message
	const errorClass = hasLongError ? 'error-big' : 'error'
	const layout = (focused ? 'focus ' : '') + (errorMessage ? `${errorClass} `  : '')
		+ (labelUp ? '' : 'toggled ') + (disabled ? 'disabled ' : '')
		+ (color ? color + ' ' : '') + (isIcon ? 'isIcon ' : '')
		+ size + ' ' + className + ' ' + (passwordType === 'password' ? passwordClass : '')


		let typeInput = ''
	if (type && type === 'password') {
		typeInput = passwordType
	} else {
		typeInput = type
	}

	return (
		<InputWrapper className={layout}>
			<InputError type={type} className={layout}>{errorMessage}</InputError>
			<InputLabel className={layout}>
				<InputLabelText className={layout}>{label}</InputLabelText>
				<InputField {...register(name, { ...options, onChange, onBlur: handleBlur })}
										data-testid={testId ?? null}
										id={name}
										defaultValue={defaultValue ? defaultValue : ''}
										type={typeInput}
										autoFocus={autoFocus}
										autoComplete={autoComplete || 'off'}
										role="presentation"
										onFocus={handleFocus}
										className={layout}
										readOnly={readOnly}
										tabIndex={tabIndex}
										inputMode={keyBoard ? 'none' : inputMode}
										disabled={disabled} />
				<ChildrenWrapper className={layout}>{children}</ChildrenWrapper>
				{keyBoard &&
					<InputImg onClick={toggleKeyboard} className={errorMessage && 'error'}>
						<img src={keyboardImg} alt="keyboard" />
					</InputImg>}
				{showEye &&
					<InputImg onClick={toggleEye} className={errorMessage && 'error'}>
						<img src={passwordType === 'password' ? closedEyeImg : openedEyeImg} alt="eye" />
					</InputImg>
				}
			</InputLabel>
		</InputWrapper>
	)
})

TextInput.displayName = 'TextInput'
export default TextInput
