import styled from 'styled-components'
import { colorTheme } from '../../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../../style/theme'

export const DishCourseButtonWrap = styled.button`
  background: ${props => props.color};
  border-radius: 0.75em;
  display: flex;
  align-items: center;
  font-family: ${theme.fontRobotoMedium};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.62em;
  padding: 0.31em 1.38em;
  text-align: left;
  border: 2px solid ${colorTheme.cart.dishCourse.modal.border};
	color: ${colorTheme.cart.dishCourse.modal.text};
  min-height: 4.19em;
  margin-bottom: 0.63em;
  cursor: pointer;
  position: relative;
  width: 100%;
  gap: 1.37em;
  justify-content: space-between;

  > svg {
    width: 1.63em;
    height: 1.63em;
  }

  &:last-child {
    background: ${colorTheme.cart.dishCourse.modal.defaultBackground};
  }

  &.active {
    border-color: ${colorTheme.cart.dishCourse.modal.borderActive};
  }
`
DishCourseButtonWrap.displayName = 'DishCourseButtonWrap'

export const DishCourseButtonText = styled.div`
	font-size: 1.375rem;
`
DishCourseButtonText.displayName = 'DishCourseButtonText'
