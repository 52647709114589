import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const ShopSeo = lazyWithRetry(() => import(/* webpackChunkName: "shop-seo" */'../../component/ShopSeo'))

const ShopSeoPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<ShopSeo />
		</Suspense>
	)
})

ShopSeoPage.displayName = 'ShopSeoPage'
export default ShopSeoPage
