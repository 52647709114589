import { SET_MIGRATION_FIELDS_FOR_CONFIRM, SET_MIGRATION_SHOW_CONTENT_FOR_LINK_EXPIRED } from '../../actionTypes/migration'

const initialState = {
	fieldsForConfirm: null,
	showContentForLinkExpired: false
}

export function migrationReducer(state = initialState, action) {
	switch (action.type) {
		case SET_MIGRATION_FIELDS_FOR_CONFIRM:
			return {
				...state,
				fieldsForConfirm: action.value
			}
		case SET_MIGRATION_SHOW_CONTENT_FOR_LINK_EXPIRED:
			return {
				...state,
				showContentForLinkExpired: action.value
			}

		default:
			return state
	}
}
