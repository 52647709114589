import { APPEND_PRINTERS, SET_NAME_PRINTERS, SET_PRINTER, SET_PRINTERS, SET_PRINTERS_LENGTH, SET_PRINTERS_UPDATE_TRIGGER } from '../../actionTypes/printer'

export const setNamePrinters = value => ({ type: SET_NAME_PRINTERS, value })

export const setPrinters = value => ({ type: SET_PRINTERS, value })

export const setPrinter = value => ({ type: SET_PRINTER, value })

export const setPrintersTotalLength = value => ({ type: SET_PRINTERS_LENGTH, value })

export const appendPrinters = value => ({ type: APPEND_PRINTERS, value })

export const setPrintersUpdateTrigger = value => ({ type: SET_PRINTERS_UPDATE_TRIGGER, value })
