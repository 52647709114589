import { SET_WEBSHOP_WORKING_HOURS_CONFIG, SET_WEBSHOP_PREORDER_DELAY, SET_WEBSHOP_SPECIAL_DAYS, SET_WEBSHOP_SPECIAL_DAYS_TOTAL_LENGTH, ADD_WEBSHOP_SPECIAL_DAYS, REMOVE_WEBSHOP_SPECIAL_DAY, SET_WEBSHOP_SPECIAL_DAY_PERIODS_ERROR, DELETE_WEBSHOP_DAY_WITH_PERIODS_ERROR, ADD_WEBSHOP_DAY_WITH_PERIODS_ERROR } from '../../actionTypes/webshopSchedule'

export const setWebshopWorkingHoursConfig = value => ({ type: SET_WEBSHOP_WORKING_HOURS_CONFIG, value })

export const setWebshopPreorderDelay = value => ({ type: SET_WEBSHOP_PREORDER_DELAY, value })

export const setWebshopSpecialDays = value => ({ type: SET_WEBSHOP_SPECIAL_DAYS, value })

export const setWebshopSpecialDaysTotalLength = value => ({ type: SET_WEBSHOP_SPECIAL_DAYS_TOTAL_LENGTH, value })

export const addWebshopSpecialDays = value => ({ type: ADD_WEBSHOP_SPECIAL_DAYS, value })

export const removeWebshopSpecialDay = value => ({ type: REMOVE_WEBSHOP_SPECIAL_DAY, value })

export const setWebshopSpecialDayPeriodsError = value => ({ type: SET_WEBSHOP_SPECIAL_DAY_PERIODS_ERROR, value })

export const addWebshopDayWithPeriodsError = value => ({ type: ADD_WEBSHOP_DAY_WITH_PERIODS_ERROR, value })

export const deleteWebshopDayWithPeriodsError = value => ({ type: DELETE_WEBSHOP_DAY_WITH_PERIODS_ERROR, value })
