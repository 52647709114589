import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppEventZIndex, setToggled } from '../../../redux/action/system'
import { addTableCartOrderItem, setTableCartItemFocusIndex, setTableCartLoading, setTableCartNewOrder, setTableCartOrderFocusIndex, setTableCartOrders, setTableCartShowMobileCart } from '../../../redux/action/tableCart'
import { useNavigate } from 'react-router-dom'
import { jwtContainsAnyGroup, uuidGenerator } from '../../../helper'
import { createTableOrderThunk, deleteTableOrderItemThunk, deleteTableOrderSubItemThunk, fetchTableOrdersThunk } from '../../../redux/thunk/restaurant/order'
import ModalLogin from '../../Elements/modalLogin'
import MainWrapper from '../../MainWrapper'
import { selectCartDiscountValue, selectCurrentOrdersInTableCart, selectGiftCouponInTableCart, selectIsSelectedItemsKitchenConfigMode, selectProcessedBy, selectSelectDishCourseIndex, selectTotalPriceNewOrder } from '../../../redux/selector/restaurant'
import DishCourseModal from '../../Elements/cart/CartItem/CartDishCourse/modal'
import CartWithProducts from './CartWithProducts'
import CartHeader from './Header'
import { fetchRestaurantTableThunk } from '../../../redux/thunk/restaurant/table'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { setRestaurantAreaId, setRestaurantShowSendToKitchenModal } from '../../../redux/action/restaurant'
import SendToKitchenModal from '../SendToKitchenModal'
import { getRestaurantSettingsDishCoursesThunk } from '../../../redux/thunk/restaurantSettings'
import { getKitchenConfigThunk } from '../../../redux/thunk/kitchenMonitor'
import { selectCompanyIndex, selectIsTestCompany } from '../../../redux/selector/system'

const EVENT_Z_INDEX = 0

const TableCart = () => {
	const navigate = useNavigate()
	const orderFocusIndex = useSelector(state => state.tableCart.orderFocusIndex)
	const itemFocusIndex = useSelector(state => state.tableCart.itemFocusIndex)
	const orders = useSelector(selectCurrentOrdersInTableCart)
	const table  = useSelector(state => state.restaurant.table)
	const { toggled } = useSelector(state => state.system)
	const { accessToken } = useSelector(state => state.login)
	const { accessToken: waiterAccessToken } = useSelector(state => state.modalLogin)
	const { menu } = useSelector(store => store.cartProduct)
	const dispatch = useDispatch()
	const [itemToDelete, setItemToDelete] = useState(null)
	const [adminAccessToken, setAdminAccessToken] = useState(null)
	const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false)
	const userIsNotAdmin = !jwtContainsAnyGroup(accessToken, ['OWNER', 'ADMIN', 'SUPERADMIN'])
	const pinCodeModalLoginIsNeeded = useSelector(state => state.modalLogin.pinCodeModalLoginIsNeeded)
	const modalLoginUsername = useSelector(state => state.modalLogin.username)
	const hasModalLogin = !!waiterAccessToken
	const hasGiftCoupon = useSelector(selectGiftCouponInTableCart)
	const discountType = useSelector(store => store.cartDiscount.discountType)
	const discountValue = useSelector(selectCartDiscountValue(hasGiftCoupon))
	const totalPriceNewOrder = useSelector(selectTotalPriceNewOrder)
	const itemIsRemoving = useSelector(state => state.tableCart.loading)
	const processedBy = useSelector(selectProcessedBy)
	const selectDishCourseIndex = useSelector(selectSelectDishCourseIndex)
	const showMobileCart = useSelector(state => state.tableCart.showMobileCart)
	const [tableId, setTableId] = useLocalStorage('tableId', null)
	const [, setRestaurantNewOrder] = useLocalStorage('restaurantNewOrder', null)
	const isSelectedItemsKitchenConfigMode  = useSelector(selectIsSelectedItemsKitchenConfigMode)
	const companyIndex = useSelector(selectCompanyIndex)
	const isTestCompany = useSelector(selectIsTestCompany)

	useEffect(() => {
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		dispatch(setToggled(true))

		return () => {
			dispatch(setTableCartOrders([]))
			dispatch(setTableCartNewOrder(null))
			setRestaurantNewOrder(null)
			setTableId(null)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (tableId) {
			dispatch(fetchRestaurantTableThunk(tableId))
			dispatch(getRestaurantSettingsDishCoursesThunk())
			dispatch(getKitchenConfigThunk(companyIndex))
		}
	}, [tableId]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (table) {
			dispatch(fetchTableOrdersThunk(table?.id))
		}

		return () => {
			dispatch(setRestaurantAreaId(table?.areaId))
		}
	}, [table]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if ((orderFocusIndex === null || itemFocusIndex === null) && orders?.length > 0) {
			const ordersCopy = [...orders]
			const lastOrderWithExistItems = ordersCopy.reverse().find(order => order.items?.length > 0)
			if (lastOrderWithExistItems) {
				dispatch(setTableCartOrderFocusIndex(orders.indexOf(lastOrderWithExistItems)))
				dispatch(setTableCartItemFocusIndex(lastOrderWithExistItems.items.length - 1))
			}
		}
	}, [orders])// eslint-disable-line react-hooks/exhaustive-deps

	const navigateBack = () => {
		navigate('/restaurant')
		dispatch(setTableCartShowMobileCart(false))
	}

	useEffect(() => {
		if (!modalLoginUsername && pinCodeModalLoginIsNeeded) {
			navigateBack()
		}
	}, [modalLoginUsername, pinCodeModalLoginIsNeeded]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!tableId) {
			navigateBack()
		}
	}, [tableId]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleSuccessRedirect = useCallback(() => {
		navigate('/restaurant')
	}, [navigate])

	const handleCreateOrder = useCallback(async (orderItems = []) => {
		const order = orders?.find(order => !order.id)
		const preparedOrder = {
			...order,
			items: orderItems.length ? orderItems : order?.items,
			totalPrice: totalPriceNewOrder,
			dailyDiscount: { value: discountValue, type: discountType },
			processedBy,
		}

		if (!menu && order) {
			dispatch(setTableCartLoading(true))
			// eslint-disable-next-line no-console
			console.log(`Create order for table ${table?.id} on total price ${totalPriceNewOrder} from deviceType: ${navigator.userAgent} and deviceId ${uuidGenerator()}`)
			await dispatch(createTableOrderThunk(table?.id, preparedOrder, hasModalLogin, () => handleSuccessRedirect()))
		}
	}, [dispatch, orders, totalPriceNewOrder, discountValue, discountType, processedBy, menu, table, hasModalLogin, handleSuccessRedirect])

		const submitCart = (orderItems = []) => {
			if (!isSelectedItemsKitchenConfigMode || hasGiftCoupon || !isTestCompany) {
				handleCreateOrder(orderItems)
			} else {
				dispatch(setRestaurantShowSendToKitchenModal(true))
			}
		}

		const createNewOrder = (item) => {
			const itemWithDishCourse = { ...item, dishCourse: selectDishCourseIndex }

			dispatch(setTableCartOrderFocusIndex(null))
			dispatch(setTableCartItemFocusIndex(null))
			dispatch(addTableCartOrderItem(itemWithDishCourse))
		}

		const onAdminLogin = (accessToken, itemToDelete) => {
			if (itemIsRemoving) return
			const { orderIndex, itemIndex, subItemIndex, item } = itemToDelete
			setAdminAccessToken(accessToken)
			const orderId = orders[orderIndex].id
			const itemPositionId = orders[orderIndex].items[itemIndex].itemPositionId

			if (!subItemIndex && subItemIndex !== 0) {
				if (orders[orderIndex].items[itemIndex].quantity > 1) {
					const formattedItems = item.items?.filter(it => it.status !== 'CANCELED')
					dispatch(deleteTableOrderItemThunk(table?.id, orderId, itemPositionId, accessToken, userIsNotAdmin))
					createNewOrder({ ...item, items: formattedItems, quantity: item.quantity - 1 })
				} else {
					dispatch(deleteTableOrderItemThunk(table?.id, orderId, itemPositionId, accessToken, userIsNotAdmin))
				}

			} else {
				const subItemPositionId = orders[orderIndex].items[itemIndex].items[subItemIndex].itemPositionId
				dispatch(deleteTableOrderSubItemThunk(table?.id, orderId, itemPositionId, subItemPositionId, accessToken, userIsNotAdmin, orderIndex, itemIndex, subItemIndex))
			}
		}

		const toggleMobileCart = () => {
			dispatch(setTableCartShowMobileCart(!showMobileCart))
			if (!toggled) dispatch(setToggled(true))
		}

		const handleModalLoginClose = () => {
			setModalLoginIsOpen(false)
		}

		return (
			<>
				<CartHeader {...{ toggleMobileCart, zIndex: EVENT_Z_INDEX }} />
				<MainWrapper>
					<CartWithProducts {...{ zIndex: EVENT_Z_INDEX, submitCart, onAdminLogin, setModalLoginIsOpen, setItemToDelete, adminAccessToken, createNewOrder }} />
					<ModalLogin isOpen={modalLoginIsOpen}
											close={handleModalLoginClose}
											group="SUPERADMIN"
											title="Als Restaurantadmin anmelden"
											onLogin={(token) => onAdminLogin(token, itemToDelete)}
											storeAccessToken={false}
											returnZIndex={0} />
					<DishCourseModal />
					<SendToKitchenModal createOrder={handleCreateOrder} tableCart={true} />
				</MainWrapper>
			</>
		)
	}

	TableCart.displayName = 'TableCart'
	export default TableCart
