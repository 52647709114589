import styled from 'styled-components'
import colors from '../../../../../style/colors'
import { theme } from '../../../../../style/theme'

export const TimeWrapper = styled.div`
	font-size: 1rem;
	border-radius: 0.75em;

	&.disabled {
		color: ${colors.gray_more_lighter};
	}

	&.margin {
		margin-left: -1.25em;
	}

	&.error {
		background-color: ${colors.red};
		width: max-content;
		margin: 0 -0.49em;

		&.margin {
			margin-left: -1.56em;
		}
	}

	@media screen and (max-width: ${theme.point1024}) {
		&.margin {
			margin-left: 0;
		}

		&.error {
			margin: 0;

			&.margin {
				margin-left: 0;
			}
		}
	}
`
TimeWrapper.displayName = 'TimeWrapper'

export const TimeError = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.88em;
	right: 0;
	top: 0;
	padding: 0 1.43em;
	width: 100%;
	color: ${colors.white};
	height: 0;
	max-height: 0;
	transition: all .2s;
	text-align: left;
	border-radius: 0.75em;

	&.error {
		height: 2em;
		max-height: 2em;
		padding-top: 0.5em;
	}
`
TimeError.displayName = 'TimeError'

export const PeriodWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, max-content);
	align-items: center;
	column-gap: 0.31em;
	background-color: ${colors.white};

	&.error {
		border: 1px dashed ${colors.red};
		border-radius: 0.75em;
		padding: 0.31em;
	}
`
PeriodWrapper.displayName = 'PeriodWrapper'
