import React from 'react'
import { InfoOrderBody, InfoOrderCounter, InfoOrderData, InfoOrderFooter, InfoOrderText, InfoOrderTime, InfoOrderTitle, InfoOrderWrap } from './index.styled'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compareDesc } from 'date-fns'
import { selectRemainingTime } from '../../../../../../../redux/selector/timerCounter'
import MainIcon from '../../../../../../../img/svg/main'
import { getNeededDateFormat } from '../../../../../../../helper'
import Currency from '../../../../../currency'

const InfoOrder = React.memo(({ order }) => {
	const { t } = useTranslation()
	const counterTime = useSelector(selectRemainingTime(order?.platformMetaData?.estimatedProcessTimestamp, t('order.hours'), t('order.minutes')))
	const isDelayTime = order?.platformMetaData?.estimatedProcessTimestamp && compareDesc(new Date(), new Date(order?.platformMetaData?.estimatedProcessTimestamp)) === -1
	const timeText = isDelayTime ? t('order.delay', { counterTime }) : t('order.left', { counterTime })

	return (
		<>
			<InfoOrderWrap>
				<InfoOrderBody>
					<InfoOrderTitle className={counterTime && 'big'}>
						<InfoOrderCounter className="big blue">
							<MainIcon icon="DELIVERY" />
							<InfoOrderText className="big">№ {order?.orderDayCounter}</InfoOrderText>
						</InfoOrderCounter>
						<InfoOrderText className="blue medium">{getNeededDateFormat(order?.orderDate, 'dd.MM.yyyy HH:mm')}</InfoOrderText>
						{counterTime &&
							<InfoOrderTime className={isDelayTime && 'warning'}>
								<MainIcon icon="clock_on_3" />
								<InfoOrderText className="spacing blue">{timeText}</InfoOrderText>
							</InfoOrderTime>}
					</InfoOrderTitle>

					<InfoOrderData>
						<MainIcon icon="user_in_circle" />
						<InfoOrderText>{order?.customer.customerNumber}</InfoOrderText>
					</InfoOrderData>
					<InfoOrderData>
						<MainIcon icon="user" />
						<InfoOrderText>{order?.customer.name}</InfoOrderText>
					</InfoOrderData>
					<InfoOrderData>
						<MainIcon icon="telephone_icon" />
						<InfoOrderText>{order?.customer.phoneNumber}</InfoOrderText>
					</InfoOrderData>
					<InfoOrderData column="1 / 3">
						<MainIcon icon="address" />
						<InfoOrderText>{order?.customer.street} {order?.customer.streetNumber}, {order?.customer.city}, {order?.customer.postalCode}</InfoOrderText>
					</InfoOrderData>
				</InfoOrderBody>
				<InfoOrderFooter>
					<MainIcon icon="CASH" />
					<InfoOrderText className="big">
						<Currency>{order?.totalPrice}</Currency>
					</InfoOrderText>
				</InfoOrderFooter>
			</InfoOrderWrap>
		</>
	)
})

InfoOrder.displayName = 'InfoOrder'
export default InfoOrder
