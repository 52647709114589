import styled from 'styled-components'

export const CartStepWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
`
CartStepWrapper.displayName = 'CartStepWrapper'

export const CartStepImageWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0 3.75em;
`
CartStepImageWrapper.displayName = 'CartStepImageWrapper'

export const CartStepImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`
CartStepImage.displayName = 'CartStepImage'
