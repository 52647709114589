import React from 'react'
import { useSelector } from 'react-redux'
import { IncomingCallAddress, IncomingCallPhone, IncomingCallPhoneText, IncomingCallTime, IncomingCallWrapper } from './index.styled'
import { getNeededDateFormat } from '../../../../helper'
import IncomingCallIcon from './IncomingCallIcon'

const IncomingCall = React.memo(({ onDoubleClick }) => {
	const customerCall = useSelector(state => state.customerStore.incomingCall)

	return (
		<IncomingCallWrapper data-testid="incoming-call" onDoubleClick={() => onDoubleClick(customerCall)}>
			<IncomingCallPhone>
				<IncomingCallIcon />
				<IncomingCallPhoneText>{customerCall?.customerPhone}</IncomingCallPhoneText>
			</IncomingCallPhone>
			<IncomingCallAddress>
				{customerCall?.customer &&
					<>
						<div>{customerCall?.customer?.name}</div>
						<div>{customerCall?.customer?.street} {customerCall?.customer?.streetNumber}</div>
					</>}
			</IncomingCallAddress>
			<IncomingCallTime>{getNeededDateFormat(customerCall?.date, 'HH:mm')}</IncomingCallTime>
		</IncomingCallWrapper>
	)
})

IncomingCall.displayName = 'IncomingCall'
export default IncomingCall
