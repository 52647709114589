import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const ModalTableWaiterNotifiedTitleWrapper = styled.div`
  font-size: 1rem;
	display: flex;
	align-items: center;
	gap: 0.63em;
`
ModalTableWaiterNotifiedTitleWrapper.displayName = 'ModalTableWaiterNotifiedTitleWrapper'

export const ModalTableWaiterNotifiedTitleArea = styled.div`
  font-size: 1em;
	display: flex;
	align-items: center;
	gap: 0.44em;
`
ModalTableWaiterNotifiedTitleArea.displayName = 'ModalTableWaiterNotifiedTitleArea'

export const ModalTableWaiterNotifiedTitleText = styled.div`
	color: ${colorTheme.restaurant.tableWaiterNotifiedModal.header.title};
	font-family: ${theme.fontRobotoBold};
	font-size: 1.25em;
	font-weight: 700;

	&.area {
		color: ${colorTheme.restaurant.tableWaiterNotifiedModal.header.areaTitle};
	}
`
ModalTableWaiterNotifiedTitleText.displayName = 'ModalTableWaiterNotifiedTitleText'

export const AreaColorCircle = styled.div`
  font-size: 1em;
	width: 1.25em;
	height: 1.25em;
	border-radius: 50%;
	border: 2px solid ${colorTheme.restaurant.tableWaiterNotifiedModal.header.areaColorCircleBorder};
	background: ${props => props.color};
`
AreaColorCircle.displayName = 'AreaColorCircle'

export const ModalTableWaiterNotifiedWrapper = styled.div`
  font-size: 1rem;
	display: grid;
	gap: 1.88em;
	margin-bottom: 1.25em;
`
ModalTableWaiterNotifiedWrapper.displayName = 'ModalTableWaiterNotifiedWrapper'

export const ModalTableWaiterNotifiedHeaderTitle = styled.div`
	color: ${colorTheme.restaurant.tableWaiterNotifiedModal.title};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	font-weight: 500;
`
ModalTableWaiterNotifiedHeaderTitle.displayName = 'ModalTableWaiterNotifiedHeaderTitle'

export const ModalTableWaiterNotifiedItemsBlock = styled.div`
	display: grid;
	gap: 0.94em;
	max-height: 50vh;
	overflow-y: auto;

	@media screen and (max-width: ${theme.point820}) {
		max-height: none;
		overflow-y: visible;
	}
`
ModalTableWaiterNotifiedItemsBlock.displayName = 'ModalTableWaiterNotifiedItemsBlock'
