import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import colors from '../../../../style/colors'

export const TimeButtonsBlock = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.63em;
	margin-bottom: 0.63em;
`
TimeButtonsBlock.displayName = 'TimeButtonsBlock'

export const TimeButton = styled.button`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	font-weight: 500;
	display: flex;
	min-width: 5.83em;
	max-height: 3.75em;
	padding: 0.67em 0.42em;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-radius: 0.5em;
	border: 1px solid ${colors.gray_dark};
	background-color: ${colors.white};
	color: ${colors.gray_dark};
	user-select: none;

	&.active {
		background-color: ${colors.blue};
		border-color: ${colors.blue};
		color: ${colors.white};
	}
`
TimeButton.displayName = 'TimeButton'

