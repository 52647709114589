import React from 'react'
import { ReactComponent as AllOrdersBigSvg } from './assets/all-orders-big.svg'
import { ReactComponent as AllOrdersSvg } from './assets/all-orders.svg'
import { ReactComponent as CashDeskBigSvg } from './assets/cashdesk-big.svg'
import { ReactComponent as DeliveryServiceBigSvg } from './assets/delivery-service-big.svg'
import { ReactComponent as DeliveryServiceSvg } from './assets/delivery-service.svg'
import { ReactComponent as DriverMonitorBigSvg } from './assets/driver-monitor-big.svg'
import { ReactComponent as HelpBigSvg } from './assets/help-big.svg'
import { ReactComponent as KitchenBigSvg } from './assets/kitchen-big.svg'
import { ReactComponent as ReportsBigSvg } from './assets/reports-big.svg'
import { ReactComponent as RestaurantBigSvg } from './assets/restaurant-big.svg'
import { ReactComponent as SettingsBigSvg } from './assets/settings-big.svg'
import { ReactComponent as HomeSvg } from './assets/home.svg'
import { ReactComponent as CashDeskSvg } from './assets/cashdesk.svg'
import { ReactComponent as RestaurantSvg } from './assets/restaurant.svg'
import { ReactComponent as KitchenSvg } from './assets/kitchen.svg'
import { ReactComponent as DriverMonitorSvg } from './assets/driver-monitor.svg'
import { ReactComponent as ReportsSvg } from './assets/reports.svg'
import { ReactComponent as PartnerPortalSvg } from './assets/partnerPortal.svg'
import { ReactComponent as SettingsSvg } from './assets/settings.svg'
import { ReactComponent as HelpSvg } from './assets/help.svg'

const MenuIcon = React.memo(({ icon, fill, stroke }) => {
	const svgProps = { fill, stroke, className: icon }
	return (
		<>
			{icon === 'ORDERS_BIG' && <AllOrdersBigSvg {...svgProps} />}
			{icon === 'CASH_DESK_BIG' && <CashDeskBigSvg {...svgProps} />}
			{icon === 'DELIVERY_SERVICE_BIG' && <DeliveryServiceBigSvg {...svgProps} />}
			{icon === 'DRIVER_MONITOR_BIG' && <DriverMonitorBigSvg {...svgProps} />}
			{icon === 'HELP_BIG' && <HelpBigSvg {...svgProps} />}
			{icon === 'KITCHEN_MONITOR_BIG' && <KitchenBigSvg {...svgProps} />}
			{icon === 'REPORTS_BIG' && <ReportsBigSvg {...svgProps} />}
			{icon === 'RESTAURANT_BIG' && <RestaurantBigSvg {...svgProps} />}
			{icon === 'PROGRAM_SETTINGS_BIG' && <SettingsBigSvg {...svgProps} />}
			{icon === 'HOME' && <HomeSvg {...svgProps} />}
			{icon === 'ORDERS' && <AllOrdersSvg {...svgProps} />}
			{icon === 'DELIVERY_SERVICE' && <DeliveryServiceSvg {...svgProps} />}
			{icon === 'CASH_DESK' && <CashDeskSvg {...svgProps} />}
			{icon === 'RESTAURANT' && <RestaurantSvg {...svgProps} />}
			{icon === 'KITCHEN_MONITOR' && <KitchenSvg {...svgProps} />}
			{icon === 'DRIVER_MONITOR' && <DriverMonitorSvg {...svgProps} />}
			{icon === 'REPORTS' && <ReportsSvg {...svgProps} />}
			{icon === 'PARTNER_PORTAL' && <PartnerPortalSvg {...svgProps} />}
			{icon === 'PROGRAM_SETTINGS' && <SettingsSvg {...svgProps} />}
			{icon === 'HELP' && <HelpSvg {...svgProps} />}
		</>
	)
})

MenuIcon.displayName = 'MenuIcon'
export default MenuIcon
