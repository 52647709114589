import React, { useCallback, useRef, useState } from 'react'
import { SelectOption, SelectWrapper } from './index.styled'
import Options from './Options'
import { useOutsideClick } from '../../../../../../../hooks/useOutsideClick'

const SelectControlled = React.memo(({ name, testId, onClick, options, selectedOption, disabled }) => {
	const [showOptions, setShowOptions] = useState(false)
	const selectRef = useRef()
	const optionsToShow = options.filter(option => option.label !== selectedOption)
	const shouldShowOptions = showOptions && !disabled

	const handleShowOptions = useCallback(() => {
		setShowOptions(prevState => !prevState)
	}, [])

	const handleSelectOption = (option) => {
		onClick(option)
	}

	useOutsideClick(selectRef, () => {
		setShowOptions(false)
	})

	return (
		<SelectWrapper ref={selectRef}
									 data-testid={testId ?? null}
									 id={name}
									 onClick={handleShowOptions}>
			<SelectOption>{selectedOption}</SelectOption>
			{shouldShowOptions &&
				<Options options={optionsToShow} onClick={handleSelectOption} />}
			{!disabled &&
				<svg className="arrow" width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M9.99095 11C9.64587 11 9.30302 10.8734 9.0392 10.618L0.396567 2.23589C-0.131074 1.72514 -0.132186 0.897383 0.393228 0.385538C0.917529 -0.126307 1.77022 -0.128509 2.29674 0.382236L9.99095 7.84417L17.7041 0.381137C18.2306 -0.128507 19.0833 -0.127406 19.6087 0.386641C20.1319 0.898486 20.1308 1.72735 19.6032 2.23809L10.9383 10.6191C10.6767 10.8734 10.3338 11 9.99095 11Z" fill="#3689EF" />
				</svg>}
		</SelectWrapper>
	)
})

SelectControlled.displayName = 'SelectControlled'
export default SelectControlled
