import axios from 'axios'

const URL = 'https://autocomplete.search.hereapi.com/v1'

const headers = {
  'X-Request-ID': '',
}

const hereInstance = axios.create({
  baseURL: URL,
  headers,
})

export default hereInstance
