import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../buttons/Button'
import MoneyExchangeModal from './MoneyExchangeModal'
import { selectHasAnyItemsInCart } from '../../../../redux/selector/cart'
import { useSelector } from 'react-redux'

const MoneyExchange = React.memo(({ module, giftCoupons }) => {
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)
	const hasAnyItemInCart = useSelector(selectHasAnyItemsInCart(module))
	const conditionForDisabled = hasAnyItemInCart || giftCoupons?.length

	const openModal = useCallback(() => {
		setShowModal(true)
	}, [])

	const closeModal = useCallback(() => {
		setShowModal(false)
	}, [])

	return (
		<>
			<Button name="moneyExchange"
							testId="moneyExchange"
							color="blue"
							icon="money-exchange"
							text={t('Cart.MoneyExchange.title')}
							onClick={openModal}
							showTextForTablet={false}
							size="small"
							disabled={conditionForDisabled} />

			<MoneyExchangeModal isOpen={showModal}
													close={closeModal}
													module={module} />
		</>
	)
})

MoneyExchange.displayName = 'MoneyExchange'
export default MoneyExchange
