import styled from 'styled-components'
import { ProductCol, ProductTotal } from '../CartProduct/index.styled'
export const ProductCouponCol = styled(ProductCol)`
  color: #060606;
`
ProductCouponCol.displayName = 'ProductCouponCol'

export const ProductCouponName = styled(ProductCol)`
  font-weight: 600;
	flex-direction: column;
	align-items: start;
	color: #060606 ;
`
ProductCouponName.displayName = 'ProductCouponName'

export const ProductCode = styled.div`
  color: #060606;
	font-weight: 500;
  white-space: nowrap;
`
ProductCode.displayName = 'ProductCode'

export const ProductCouponTotal = styled(ProductTotal)`
	color: #060606;
`
ProductCouponTotal.displayName = 'ProductCouponTotal'

export const MinusButtonWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;

	> button {
		width: 2.81em;
		height: 2.81em;
	}
`
MinusButtonWrap.displayName = 'MinusButtonWrap'

