import React from 'react'
import { CartProductBlock, CartProductPrice, CartProductTitle, CartProductTotalPriceBlock, CartProductWrapper } from '../index.styled'
import { encryptGiftCode } from '../../../../../../helper/encryptGiftCode'
import { useTranslation } from 'react-i18next'
import Currency from '../../../../../Elements/currency'

const CartCoupon = React.memo(({ coupon, isPayment = false }) => {
	const { t } = useTranslation()
	const isMarketingCoupon = coupon?.type === 'MARKETING_COUPON'
	const isGiftCoupon = coupon?.type === 'GIFT_COUPON'
	const code = encryptGiftCode(coupon?.couponMetaData?.code)
	const couponName = isMarketingCoupon ?
		(t('Cart.coupon') + ' ' + coupon.name) :
		(t('Cart.gift_coupon') + ' ' + code)
	const couponLayout = ((isPayment || isMarketingCoupon) ? 'coupon-payment' : 'coupon-item')

	return (
		<CartProductWrapper>
			<CartProductBlock>
				<CartProductTitle className={couponLayout}>{couponName}</CartProductTitle>
				{!isPayment && isGiftCoupon &&
					<CartProductTotalPriceBlock>
						<CartProductPrice data-testid="coupon-price" className="total"><Currency>{coupon?.price}</Currency></CartProductPrice>
					</CartProductTotalPriceBlock>}
			</CartProductBlock>
		</CartProductWrapper>
	)
})

CartCoupon.displayName = 'CartCoupon'
export default CartCoupon
