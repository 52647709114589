import React, { useCallback } from 'react'
import { DisabledOrderTypePeriodText, QrCodeWrapper, SwitchText, WebshopInfoSwitch, WebshopInfoSwitchWrapper } from './index.styled'
import Button from '../../Elements/buttons/Button'
import SwitchInputControlled from '../../Elements/inputs/SwitchInput/controlled'
import ButtonIcon from '../../../img/svg/button'
import { getNeededDateFormat } from '../../../helper'
import { onlineShopUIUrl } from '../../../urls'
import { selectIsWorkingOrderType, selectOrderTypeBreakDateTo } from '../../../redux/selector/shop'
import { QrCodeImg, QrCodeText, WebshopInfoDropdown } from '../index.styled'
import { setWebshopShowAdviceWindow } from '../../../redux/action/webshopinfo'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'

const WebshopDropDown = React.memo(({ handleOpenDownloadModal, handleOrderStatusChange }) => {
	const { t } = useTranslation()
    const shopUrl = useSelector(store => `${onlineShopUIUrl}/${store.webshopinfo.config?.shopUrl}`)
    const isDeliveryWorkingNow = useSelector(selectIsWorkingOrderType('DELIVERY'))
    const isPickupWorkingNow = useSelector(selectIsWorkingOrderType('PICKUP'))
    const deliveryBreakDateTo = useSelector(selectOrderTypeBreakDateTo('DELIVERY'))
    const pickupBreakDateTo = useSelector(selectOrderTypeBreakDateTo('PICKUP'))
	const dispatch = useDispatch()
	const navigate = useNavigate()

    const handleNavigateToShop = useCallback(() => {
		dispatch(setWebshopShowAdviceWindow(false))
		window.open(shopUrl, '_blank')
	}, [shopUrl, dispatch])

	const handleNavigateToShopSettings = useCallback(() => {
		dispatch(setWebshopShowAdviceWindow(false))
		navigate('/settings/webshop')
	}, [dispatch, navigate])

	return (
		<WebshopInfoDropdown>
			<QrCodeWrapper>
				<QrCodeText>Webshop</QrCodeText>
				<QrCodeImg data-testid="qr-code" className="info" onClick={handleNavigateToShop}>
					<QRCodeSVG value={shopUrl} level="M" />
				</QrCodeImg>
			</QrCodeWrapper>
			<Button name="download" color="gray" text={t('WebshopInfo.qr_download')} icon="download" onClick={handleOpenDownloadModal} />
			<Button name="navigate" color="green" text={t('WebshopInfo.navigate_to_shop')} icon="navigate-shop" onClick={handleNavigateToShop} />
			<WebshopInfoSwitchWrapper>
				<WebshopInfoSwitch>
					<SwitchInputControlled name="check_delivery" checked={isDeliveryWorkingNow} onChange={handleOrderStatusChange('DELIVERY')} />
					<SwitchText className={isDeliveryWorkingNow && 'active'}>
						<ButtonIcon icon="delivery-shop" />
						{t('WebshopInfo.DELIVERY')}
					</SwitchText>
					{deliveryBreakDateTo && <DisabledOrderTypePeriodText>{t('WebshopInfo.modal.disabled_to') + getNeededDateFormat(deliveryBreakDateTo, 'HH:mm')}</DisabledOrderTypePeriodText>}
					<SwitchInputControlled name="check_pickup" checked={isPickupWorkingNow} onChange={handleOrderStatusChange('PICKUP')} />
					<SwitchText className={isPickupWorkingNow && 'active'}>
						<ButtonIcon icon="pickup-shop" />
						{t('WebshopInfo.PICKUP')}
					</SwitchText>
					{pickupBreakDateTo && <DisabledOrderTypePeriodText>{t('WebshopInfo.modal.disabled_to') + getNeededDateFormat(pickupBreakDateTo, 'HH:mm')}</DisabledOrderTypePeriodText>}
				</WebshopInfoSwitch>
			</WebshopInfoSwitchWrapper>
			<Button name="settings" color="blue" text={t('WebshopInfo.navigate_to_settings')} icon="settings-shop" onClick={handleNavigateToShopSettings} />
		</WebshopInfoDropdown>
	)
})

WebshopDropDown.displayName = 'WebshopDropDown'
export default WebshopDropDown
