import styled from 'styled-components'
import colors from '../../../../../style/colors'
import { theme } from '../../../../../style/theme'
import { DayTitle } from '../index.styled'

export const PreorderDelayWrapper = styled.div`
	font-size: 1rem;
	padding: 1.88em 0;
	border-bottom: 1px dashed ${colors.gray_light};
	margin-bottom: 1.88em;

	&.modal {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 3.13em;
		border-bottom: none;
		padding: 1.88em 0 0;
		margin-bottom: 0.38em;
	}

	@media screen and (max-width: ${theme.point1024}) {
		&.modal {
			flex-direction: column;
			align-items: flex-start;
			width: max-content;
			gap: 1.25em;
		}
	}
`
PreorderDelayWrapper.displayName = 'PreorderDelayWrapper'

export const PreorderTitle = styled(DayTitle)`
	margin-bottom: 0.83em;

	&.modal {
		max-width: 10.5em;
		margin-bottom: 0;
	}

	@media screen and (max-width: ${theme.point1024}) {
		&.modal {
			width: max-content;
		}
	}
`
PreorderTitle.displayName = 'PreorderTitle'

export const PreorderTimeWrapper = styled.div`
	display: flex;
	gap: 3.13em;

	@media screen and (max-width: ${theme.point1024}) {
		flex-direction: column;
		gap: 1.25em;
	}
`
PreorderTimeWrapper.displayName = 'PreorderTimeWrapper'

export const TimeTitle = styled.div`
	font-weight: 600;
	font-size: 1em;
	line-height: 1.19em;
	color: ${colors.gray_dark};
	text-align: left;
`
TimeTitle.displayName = 'TimeTitle'

export const TimeBlock = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto);
	align-items: center;
	column-gap: 0.63em;
	row-gap: 0.38em;

	> ${TimeTitle} {
		grid-column: 1 / 5;
	}

	@media screen and (max-width: ${theme.point1024}) {
		column-gap: 1.88em;
	}
`
TimeBlock.displayName = 'TimeBlock'

export const TimeText = styled.div`
	font-size: 1em;
	line-height: 1.19em;
	color: ${colors.gray_dark};
`
TimeText.displayName = 'TimeText'

export const TimeValueWrapper = styled.div`
	display: flex;
	gap: 0.63em;
	align-items: center;
`
TimeValueWrapper.displayName = 'TimeValueWrapper'

export const DisabledBlock = styled.div`
	display: flex;
	align-items: end;
	gap: 1.25em;

	> label {
		max-width: 25em;
	}

	@media screen and (max-width: ${theme.point820}) {
		display: grid;
		> label {
			max-width: none;
		}
	}
`
DisabledBlock.displayName = 'DisabledBlock'
