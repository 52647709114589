import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CartHeader, CartHeadTitle, CartProducts, CartProductsInner, CartWrapper } from './index.styled'
import CartTotals from './CartTotals'
import CartItem from './CartItem'
import { usePrevious } from '../../../../hooks/usePrevious'
import { useSelector } from 'react-redux'
import CartCoupon from './CartItem/CartCoupon'
import { selectCartCouponPayments, selectCartItems, selectCartToppingsLength } from '../../../../redux/selector/clientMonitorCart'

const Cart = React.memo(() => {
	const { t } = useTranslation()
	const items = useSelector(selectCartItems)
	const couponPayments = useSelector(selectCartCouponPayments)
	const cartToppingsLength = useSelector(selectCartToppingsLength)
	const cartItems = [...items, couponPayments]
	const cartRef = useRef()
	const prevItems = usePrevious(cartItems)
	const prevToppingsLength = usePrevious(cartToppingsLength)
	const isScrollCart = (cartItems?.length > prevItems?.length) || (cartToppingsLength > prevToppingsLength)
	
	useEffect(() => {
		if (isScrollCart) {
			cartRef.current.scrollTop = 100000
		}
		// eslint-disable-next-line
	}, [cartItems])

	return (
		<>
			<CartWrapper>
				<CartHeader>
					<CartHeadTitle>{t('Cart.name')}</CartHeadTitle>
					<CartHeadTitle />
					<CartHeadTitle className="align-right">{t('Cart.price')}</CartHeadTitle>
					<CartHeadTitle className="align-right">{t('Cart.total')}</CartHeadTitle>
				</CartHeader>
				<CartProducts ref={cartRef}>
					<CartProductsInner>
						{items?.map((item, index) => (
							<CartItem key={index} item={item} index={index} />
						))}
						{couponPayments?.map((coupon, index) => (
							<CartCoupon key={index} coupon={coupon} isPayment={true} />
						))}
					</CartProductsInner>
				</CartProducts>
				<CartTotals />
			</CartWrapper>
		</>
	)
})

Cart.displayName = 'Cart'
export default Cart
