import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrencySymbol } from '../../../../redux/selector/system'
import { SumButton, SumMain } from './index.styled'

const sums = [1, 2, 5, 10, 20, 50, 100, 200, 500]

const SumKeyboard = React.memo(({ handleType }) => {
	const currencySymbol = useSelector(selectCurrencySymbol)

	const handleClick = useCallback((value) => {
		handleType(value)
	}, [handleType])

	return (
		<SumMain>
			{sums.map(sum => (
				<SumButton key={sum} onClick={() => handleClick(sum)}>{sum + currencySymbol}</SumButton>
			))}
		</SumMain>
	)
})

SumKeyboard.displayName = 'SumKeyboard'
export default SumKeyboard
