import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setAppFormChanged, setAppNavigateTo } from '../../../../redux/action/system'

const CustomNavLink = React.memo(({ id, tabIndex, className, activeClassName, to, children, callback, target }) => {
	const formChangedModal = useSelector(store => store.system.formChangedModal)
	const dispatch = useDispatch()

	const handleClick = useCallback((event) => {
		if (formChangedModal) {
			event.preventDefault()
			dispatch(setAppFormChanged(true))
			dispatch(setAppNavigateTo(to))
			return
		}
		if (callback) callback(event)
	}, [formChangedModal, to])

	const getClassName = useCallback(
		({ isActive }) => className + (isActive ? ' ' + activeClassName : ''),
		[className, activeClassName])

	return (
		<NavLink id={id}
						 tabIndex={tabIndex}
						 className={getClassName}
						 onClick={handleClick}
						 to={to}
						 target={target}>
			{children}
		</NavLink>
	)
})

CustomNavLink.displayName = 'CustomNavLink'
export default CustomNavLink
