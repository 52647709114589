import { createSelector } from 'reselect'
const selectPositions = store => store.map.positions || []

const selectWoltDriverDeliveries = store => store.map.woltDriverDeliveries || []

export const selectWoltDriversPositions = createSelector(
	selectWoltDriverDeliveries,
	(woltDriverDeliveries) => {
		return woltDriverDeliveries.map(delivery => ({ ...delivery?.courierLocation, partnerDriverType: 'WOLT_DRIVE' }))
	}
)

export const selectDriversPositions = createSelector(
	selectPositions,
	selectWoltDriversPositions,
	(selfDriversPositions, woltDriversPositions) => {
		return [...selfDriversPositions, ...woltDriversPositions]
	}
)
