import React from 'react'
import { ArrowButtonText, ArrowButtonWrapper } from './index.styled'
import { useTranslation } from 'react-i18next'
import { useWindowBreakPoint } from '../../../../../hooks/useWindowBreakPoint'
import MainIcon from '../../../../../img/svg/main'
import { theme } from '../../../../../style/theme'
import { ARROW_RIGHT } from '../../constants'

const ArrowButton = React.memo(({ testId, direction, onClick }) => {
	const { t } = useTranslation()
	const isTablet = useWindowBreakPoint(theme.point820)
	const buttonText = direction === ARROW_RIGHT ? t('restaurant.sendToKitchenModal.button.toKitchen') : t('restaurant.sendToKitchenModal.button.return')

	return (
		<ArrowButtonWrapper className={direction} data-testid={testId} onClick={onClick} type="button">
			{isTablet ?
				<>
					<ArrowButtonText className={direction}>{buttonText}</ArrowButtonText>
					<MainIcon icon="small-arrow-right" />
				</> :
				<MainIcon icon="arrow-right" />}
		</ArrowButtonWrapper>
	)
})

ArrowButton.displayName = 'ArrowButton'
export default ArrowButton
