import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectCompanyIndex } from '../../../redux/selector/system'
import { addPlatformDriversOrderProcessingTimeThunk, denyPlatformDriversOrderThunk, removeOrderFromPlatformDriversOrderModalDataThunk } from '../../../redux/thunk/order/addPlatformDriverOrderProcessingTime'
import Button from '../../Elements/buttons/Button'
import CustomModal from '../../Elements/modal/CustomModal'
import OrderWithTimeButtons from './OrderWithTimeButtons'
import OrderWithoutTimeButtons from './OrderWithoutTimeButtons'

const EVENT_Z_INDEX = 993

const PlatformDriversOrderModal = React.memo(() => {
	const { t } = useTranslation()
	const platformDriversOrderModalShow = useSelector(store => store.system.modal?.platformDriversOrderModalShow)
	const order = useSelector(store => store.system.modalData?.platformDriversOrderModalData[0])
	const isTakeawayDriver = order?.deliveryMetaData?.partner?.type === 'TAKEAWAY_DRIVE'
	const isFoodoraDriver = order?.deliveryMetaData?.partner?.type === 'FOODORA_DRIVE'
	const driverArriveTime = order?.deliveryMetaData?.partner?.pickupEta
	const isProcessingConfirmOrder = useSelector(store => store.order.isProcessingConfirmOrder)
	const isProcessingDenyOrder = useSelector(store => store.order.isProcessingDenyOrder)
	const dispatch = useDispatch()
	const companyIndex = useSelector(selectCompanyIndex)
	const [processingTime, setProcessingTime] = useState(0)
	const isDisabled = isProcessingConfirmOrder || isProcessingDenyOrder

	useEffect(() => {
		if (platformDriversOrderModalShow) {
			// eslint-disable-next-line
			console.log(`Opening platform driver popup for confirming the order from platform orderId=${order.orderId} companyIndex=${companyIndex} path=${window.location.pathname}`)
		}
	}, [platformDriversOrderModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleConfirm = useCallback((processingTime) => {
		dispatch(addPlatformDriversOrderProcessingTimeThunk(order?.orderId, processingTime))
	}, [dispatch, order?.orderId])

	const handleClose = useCallback(() => {
		dispatch(removeOrderFromPlatformDriversOrderModalDataThunk(order?.orderId))
	}, [dispatch, order])

	const handleEnter = useCallback(() => {
		handleConfirm(processingTime)
	}, [handleConfirm, processingTime])

	const handleDeny = useCallback(() => {
		dispatch(denyPlatformDriversOrderThunk(order?.orderId, 'Denied by user'))
	}, [dispatch, order?.orderId])

	return (
		<>
			<CustomModal isOpen={platformDriversOrderModalShow}
									 close={handleClose}
									 id="platform-drivers-order-modal"
									 enableOutsideClick={false}
									 size="auto"
									 disableBackButton={true}
									 zIndex={EVENT_Z_INDEX}
									 mobileFooter={true}
									 title={t(`Modal.platform_drivers_order_modal.title.${order?.platformMetaData?.platformType}`)}
									 button={
										 <>
											 <Button name="cancel" icon="clear" text={t('Modal.deny_order')}
															 keyButton="F3" zIndex={EVENT_Z_INDEX}
															 onClick={handleDeny}
															 className="back"
															 disabled={isDisabled} />
											 <Button name="accept" icon="checkmark" color="green"
															 keyButton="Enter" zIndex={EVENT_Z_INDEX}
															 onClick={handleEnter}
															 text={t('buttons.controls.takeOrder')}
															 disabled={isDisabled} />
										 </>
									 }>
				{(isTakeawayDriver || isFoodoraDriver) && driverArriveTime ?
					<OrderWithoutTimeButtons order={order} />
					: <OrderWithTimeButtons order={order}
																	handleConfirm={handleConfirm}
																	setProcessingTime={setProcessingTime} />}
			</CustomModal>
		</>
	)
})

PlatformDriversOrderModal.displayName = 'PlatformDriversOrderModal'
export default PlatformDriversOrderModal
