import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store/configureStore'
import './i18n'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import getRecaptchaSiteKey from './helper/getRecaptchaSiteKey'
import isRecaptchaEnabled from './helper/isRecaptchaEnabled'

const RECAPTCHA_SITE_KEY = getRecaptchaSiteKey()
const recaptchaEnabled = isRecaptchaEnabled()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<Provider store={store}>
		{recaptchaEnabled ?
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
				<App />
			</GoogleReCaptchaProvider> :
			<App />}
	</Provider>,
)

serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
