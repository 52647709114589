import orderViewInstance from '../instance'

const calculateAllOrderSum = (
	dateFrom,
	dateTo,
	paymentMethod,
	status,
	orderType,
	processedByUsername,
	processedByUsernameWithEmpty,
) => {
	return orderViewInstance.get('/orders/sum?'
		+ (dateFrom ? 'dateFrom=' + dateFrom : '')
		+ (dateTo ? '&dateTo=' + dateTo : '')
		+ (paymentMethod ? '&paymentMethod=' + paymentMethod : '')
		+ (status ? '&status=' + status : '')
		+ (orderType ? '&orderType=' + orderType : '')
		+ (processedByUsername ? '&processedByUsername=' + processedByUsername : '')
		+ (processedByUsernameWithEmpty ? '&processedByUsernameWithEmpty=' + processedByUsernameWithEmpty : ''),
	)
}

export default calculateAllOrderSum
