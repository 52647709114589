import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import fetchAreas from '../../../axios/restaurant/area'
import { setRestaurantAreas, setRestaurantShowProcessPaymentErrorModal, setRestaurantTable, setRestaurantTables, setRestaurantTablesLoading } from '../../../action/restaurant'
import fetchTables from '../../../axios/restaurant/table'
import swapTable from '../../../axios/restaurant/table/swapTable/put'
import swapTableMove from '../../../axios/restaurant/table/swapTableMove/put'
import fetchTable from '../../../axios/restaurant/table/get'

export const fetchRestaurantAreasThunk = () => dispatch => {
	return fetchAreas(0, 20)
		.then(res => {
			dispatch(setRestaurantAreas(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const fetchRestaurantTablesThunk = (areaId) => dispatch => {
  dispatch(setRestaurantTablesLoading(true))
  return fetchTables(0, 50, areaId)
    .then(res => {
      if (res.data) {
        dispatch(setRestaurantTablesLoading(false))
        return dispatch(setRestaurantTables(res.data))
      }
    }).catch(error => {
      dispatch(setRestaurantTablesLoading(false))
      dispatch(setAppErrors(retrieveErrorFromApi(error)))
    })
}

export const fetchRestaurantTableThunk = (tableId) => dispatch => {
	return fetchTable(tableId)
		.then(res => {
			dispatch(setRestaurantTable(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const swapTableThunk = (tableId, data) => (dispatch, getState) => {
	return swapTable(tableId, data)
		.then(res => {
			dispatch(setRestaurantTable(null))
			return dispatch(fetchRestaurantTablesThunk(getState().restaurant.areaId))
		}).catch(error => {
			const errorStatusCode = error.response.data?.apierror.errorStatusCode
			if (errorStatusCode === 'order_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			}
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const swapTableMoveThunk = (tableId, data) => (dispatch, getState) => {
	return swapTableMove(tableId, data)
		.then(res => {
			dispatch(setRestaurantTable(null))
			return dispatch(fetchRestaurantTablesThunk(getState().restaurant.areaId))
		}).catch(error => {
			const errorStatusCode = error.response.data?.apierror.errorStatusCode
			if (errorStatusCode === 'order_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			}
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
