import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const ProductMenu = lazyWithRetry(() => import(/* webpackChunkName: "product" */'../../component/Product/form/menu'))

const ProductMenuPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<ProductMenu />
		</Suspense>
	)
})

ProductMenuPage.displayName = 'ProductMenuPage'
export default ProductMenuPage
