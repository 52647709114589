import { ADD_STATISTICS_ITEMS, SET_STATISTICS_ITEMS, SET_STATISTICS_ITEMS_LENGTH, SET_STATISTICS_LOADING, SET_STATISTICS_PER_ORDER_TYPE, SET_STATISTICS_PER_PAYMENT_METHOD } from '../../actionTypes/sttistics'

const initialState = {
	perOrdersType: [],
	perPaymentMethod: [],
	loading: false,
	items: [],
	itemsLength: 0,
}

export function statisticsReducer (state = initialState, action) {
	switch (action.type) {
		case SET_STATISTICS_PER_ORDER_TYPE:
			return {
				...state,
				perOrdersType: action.value,
			}
		case SET_STATISTICS_PER_PAYMENT_METHOD:
			return {
				...state,
				perPaymentMethod: action.value,
			}
		case SET_STATISTICS_LOADING:
			return {
				...state,
				loading: action.value,
			}
		case SET_STATISTICS_ITEMS:
			return {
				...state,
				items: action.value,
			}
		case ADD_STATISTICS_ITEMS:
			return {
				...state,
				items: [...state.items].concat(action.value),
			}
		case SET_STATISTICS_ITEMS_LENGTH:
			return {
				...state,
				itemsLength: action.value,
			}
		default:
			return state
	}
}
