import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getNeededDateFormat } from '../../../helper'
import MainIcon from '../../../img/svg/main'
import { createTseNullOrderThunk, getTseNullOrdersThunk } from '../../../redux/thunk/tse'
import Button from '../../Elements/buttons/Button'
import CustomModal from '../../Elements/modal/CustomModal'
import { EmptyNullOrder, InfoItem, InfoItemData, InfoItemTitle, InfoWrapper, NullOrderBlock, NullOrderDataWrapper, NullOrdersWrapper, TseInformationModalWrapper } from './index.styled'

const EVENT_Z_INDEX = 996

const TseInformationModal = React.memo(({ isOpen, close }) => {
	const { t } = useTranslation()
	const tseConfig = useSelector(store => store.tse.config)
	const nullOrders = useSelector(store => store.tse.nullOrders)
	const [showNullOrderData, setShowNullOrderData] = useState(false)
	const dispatch = useDispatch()

	const tssId = tseConfig?.tssId
	const creationDate = tseConfig?.activationDate
	const creationDateToTimeFormat = getNeededDateFormat(creationDate, 'dd.MM.y, HH:mm:ss')
	const serialNumber = tseConfig?.serialNumber
	const certificate = tseConfig?.certificate
	const cashRegisterId = tseConfig?.cashRegisterId
	const cashRegisterVersion = tseConfig?.cashRegisterVersion
	const isAustriaTseType = tseConfig?.type === 'AUT'
	const infoLayoyt = !isAustriaTseType ? 'deu-type' : ''

	useEffect(() => {
		if (isOpen && showNullOrderData) {
			dispatch(getTseNullOrdersThunk())
		}
	}, [showNullOrderData])


	const handlePrint = useCallback(() => {
		if (showNullOrderData) {
			const date = new Date().toISOString()
			dispatch(createTseNullOrderThunk(date))
		} else {
			window.print()
		}
	}, [showNullOrderData])

	const handleShowNullOrderData = useCallback(() => {
		setShowNullOrderData(true)
	}, [])

	const handleClose = useCallback(() => {
		if (showNullOrderData) {
			setShowNullOrderData(false)
		} else {
			close()
		}
	}, [close, showNullOrderData])

	return (
		<CustomModal
			isOpen={isOpen}
			close={handleClose}
			zIndex={EVENT_Z_INDEX}
			size="medium height-scroll"
			title={!showNullOrderData ?
				t('Modal.tse_modal.information.title') :
				t('Modal.tse_modal.information.nullOrder')}
			disableBackButton={true}
			id="tse-information-modal"
			dividedFooterLine={showNullOrderData && nullOrders.length}
			mobileFooter={!showNullOrderData}
			footerClassname={!showNullOrderData && 'big-gap'}
			button={
				<>
					{isOpen &&
						<Button name="send" icon="print" text={t('Modal.tse_modal.information.print')} keyButton="F2" zIndex={EVENT_Z_INDEX} color="green" onClick={handlePrint} className="back no-print" />}
					{isAustriaTseType && !showNullOrderData &&
						<Button name="null-order" text={t('Modal.tse_modal.information.nullOrder')} keyButton="F3" zIndex={EVENT_Z_INDEX} onClick={handleShowNullOrderData} className="back no-print" />}
					<Button icon="checkmark" text={t('buttons.controls.ready')} color="green" onClick={handleClose} className="no-print" />
				</>
			}>
			<TseInformationModalWrapper>
				{!showNullOrderData ?
					<InfoWrapper className={infoLayoyt}>
						{tssId && <InfoItem>
							<InfoItemTitle>{t('Modal.tse_modal.information.tseId')}</InfoItemTitle>
							<InfoItemData>{tssId}</InfoItemData>
						</InfoItem>}
						{creationDate && <InfoItem>
							<InfoItemTitle>{t('Modal.tse_modal.information.createDate')}</InfoItemTitle>
							<InfoItemData>{creationDateToTimeFormat}</InfoItemData>
						</InfoItem>}
						{serialNumber && <InfoItem>
							<InfoItemTitle>{t('Modal.tse_modal.information.serialNumber')}</InfoItemTitle>
							<InfoItemData>{serialNumber}</InfoItemData>
						</InfoItem>}
						{certificate && <InfoItem>
							<InfoItemTitle>{t('Modal.tse_modal.information.certificate')}</InfoItemTitle>
							<InfoItemData className="certificate">{certificate}</InfoItemData>
						</InfoItem>}
						{cashRegisterId && <InfoItem>
							<InfoItemTitle>{t('Modal.tse_modal.information.cashDeskId')}</InfoItemTitle>
							<InfoItemData>{cashRegisterId}</InfoItemData>
						</InfoItem>}
						{cashRegisterVersion && <InfoItem>
							<InfoItemTitle>{t('Modal.tse_modal.information.cashDeskVersion')}</InfoItemTitle>
							<InfoItemData>{cashRegisterVersion}</InfoItemData>
						</InfoItem>}
					</InfoWrapper> :
					<NullOrderData {...{ nullOrders }} />}
			</TseInformationModalWrapper>
		</CustomModal>
	)
})

const NullOrderData = React.memo(({ nullOrders }) => {
	const { t } = useTranslation()

	return (
		<NullOrderDataWrapper>
			{nullOrders.length ?
				<NullOrdersWrapper>
					{nullOrders.map(order =>
						<NullOrderBlock key={order.id}>
							<MainIcon icon="check" />
							{getNeededDateFormat(order.date, 'dd.MM.y')}
						</NullOrderBlock>)}
				</NullOrdersWrapper> :
				<EmptyNullOrder>
					<MainIcon icon="check" />
					{t('Modal.tse_modal.information.emptyNullOrders')}
				</EmptyNullOrder>}
		</NullOrderDataWrapper>
	)
})

NullOrderData.displayName = 'NullOrderData'

TseInformationModal.displayName = 'TseInformationModal'
export default TseInformationModal
