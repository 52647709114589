import React, { Suspense } from 'react'
import PageLoader from '../../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../../helper/lazyWithRetry'

const RecoveryPassword = lazyWithRetry(() => import(/* webpackChunkName: "password" */'../../../component/Recovery/Password'))

const RecoveryPasswordPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader className="authPage" />}>
			<RecoveryPassword />
		</Suspense>
	)
})

RecoveryPasswordPage.displayName = 'RecoveryPasswordPage'
export default RecoveryPasswordPage
