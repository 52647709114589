import React, { useState, useCallback, useRef } from 'react'
import { TimeWrapper, TimeInputField } from './index.styled'

const TimeInput = React.memo(({
	name,
	value,
	onChange,
	disabled,
	readOnly,
	onlyHours,
	onlyMinutes,
	success,
	error,
	id,
	testId,
	size,
	onBlur = () => {
	}
}) => {
	const [focused, setFocused] = useState(false)
	const refInput = useRef()

	const layout = (disabled ? 'disabled ' : '') + (readOnly ? 'readOnly ' : '') +
		(onlyHours ? 'onlyHours ' : '') + (onlyMinutes ? 'onlyMinutes ' : '') +
		(error ? 'error ' : '') + (focused ? 'focus ' : '') + (size ? size + ' ' : '') +
		(success ? 'success ' : '')

	const handleFocus = useCallback(() => {
		if (!readOnly && !error) {
			setFocused(true)
		}
	}, [readOnly, error])

	const handleBlur = useCallback((e) => {
		setFocused(false)

		onBlur(e)
	}, [onBlur])

	const handleClick = useCallback((e) => {
		const target = e.target
		const value = target.value
		const selectionStart = target.selectionStart

		if (value.length === 5 && !readOnly) {
			if (selectionStart < 3) {
				refInput.current.setSelectionRange(0, 2)
			} else {
				refInput.current.setSelectionRange(3, 5)
			}
		}
	}, [readOnly])

	return (
		<TimeWrapper className={layout}>
			<TimeInputField type="text"
				id={id}
				data-testid={testId ? testId : id}
				name={name}
				ref={refInput}
				className={layout}
				disabled={disabled}
				value={value}
				onClick={handleClick}
				onChange={onChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				readOnly={readOnly}
				autocomplete="off"
				inputMode="numeric" />
		</TimeWrapper>
	)
})

TimeInput.displayName = 'TimeInput'
export default TimeInput
