export const encryptGiftCode = (code) => {
	const arrCode = code?.split('-')
	let i = 0
	return arrCode?.reduce((res, el) => {
		if (arrCode.length - 1 > i) {
			i++
			return `${res}xxxx-`
		}
		else return `${res}${el}`
	}, '')
}
