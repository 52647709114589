import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'


export const TabsContent = styled.div`
	font-size: 1rem;
	display: flex;
	gap: 0.63em;
	width: 100%;
	position: relative;

	@media screen and (max-width: ${theme.point820}) {
		justify-content: center;
		gap: 2.14em 0.72em;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;
		align-items: center;
		flex-wrap: wrap;

		&:after {
			position: absolute;
			height: 1px;
			width: 100%;
			top: 3.4em;
			left: 0;
			content: '';
			background: ${colorTheme.tabs.border};
		}
	}

`

export const TabsWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	grid-template-columns: max-content auto;
	gap: 0.63em;
	margin-bottom: 1.87em;
	border-bottom: 1px solid ${colorTheme.tabs.border};
	justify-content: space-between;

	@media screen and (max-width: ${theme.point820}) {
		justify-content: center;
		flex-direction: column-reverse;
		gap: 2.14em 0.72em;
		grid-template-columns: max-content;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 1.87em 0.63em;
	}

`
