import registrationInstance from '../../instance'

const postLogout = (refreshToken, callbackSuccess, callbackError) => {
  return dispatch => {
    registrationInstance.post('/auth/logout',
      {
        'refreshToken': refreshToken,
      })
      .then(res => {
        typeof callbackSuccess === 'function' && callbackSuccess(res)
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }
}

export default postLogout
