import styled from 'styled-components'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { theme } from '../../../../style/theme'

export const CartProductWrapper = styled.div`
	font-size: 1em;
	position: relative;
	display: grid;
	grid-template-columns: 1fr auto;
	min-height: 3.63em;
	padding: 0.5em 0;
	align-items: center;
	border-bottom: 1px solid ${colorTheme.restaurant.sendToKitchenModal.items.product.border};

	&.menu {
		padding: 0 0 0 1.25em;
		border-bottom: none;
		min-height: max-content;
	}

	&.canceled {
		> div:first-child::after {
			content: "";
			position: absolute;
			top: 1.07em;
			bottom: 0;
			width: 100%;
			height: 2px;
			background: linear-gradient(to right, ${colorTheme.restaurant.sendToKitchenModal.items.product.canceledBorder} 50%, transparent 50%);
			background-size: 6px 2px;
		}

		&.middle {
			> div:first-child::after {
				top: 48%;
			}
		}
	}

	&.with-status {
		grid-template-columns: 1.88em 1fr auto;
	}

	&.with-dish-course {
		grid-template-columns: 3.13em 1fr auto;
	}

	&.with-dish-course-and-status {
		grid-template-columns: 2.94em 1.88em 1fr auto;
	}

	@media screen and (max-width: ${theme.point820}) {
		display: flex;
		gap: 0.71em;
		border-bottom: 1px solid ${colorTheme.restaurant.sendToKitchenModal.items.product.tabletBorder};
		padding: 1.07em 0;

		&.menu {
			padding: 0 0 0 1em;
		}

		&.canceled {
			> div:first-child::after {
				top: 1.6em;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;
		padding: 0.94em 0;

		&.menu {
			padding: 0 0 0 1em;
		}

		&.canceled {
			> div:first-child::after {
				top: 1.5em;
			}
		}
	}
`
CartProductWrapper.displayName = 'CartProductWrapper'

export const CartProductBlock = styled.div`
	display: grid;
	gap: 0.63em;
	align-items: center;

	&.menu {
		gap: 0.31em;
	}
`
CartProductBlock.displayName = 'CartProductBlock'

export const CartProductTitle = styled.div`
	color: ${colorTheme.restaurant.sendToKitchenModal.items.product.title};
	font-family: ${theme.fontRoboto};
	font-size: 1.13em;
	font-weight: 400;

	&.menu {
		font-size: 1em;
	}

	&.canceled {
		color: ${colorTheme.restaurant.sendToKitchenModal.items.product.canceled};
	}
`
CartProductTitle.displayName = 'CartProductTitle'

export const CartProductTitleBlock = styled.div`
	display: flex;
	gap: 0.63em;
	align-items: center;
`
CartProductTitleBlock.displayName = 'CartProductTitleBlock'

export const CartProductQuantity = styled.div`
	color: ${colorTheme.restaurant.sendToKitchenModal.items.product.quantity};
	font-family: ${theme.fontRobotoBold};
	font-size: 1.13em;
	font-weight: 700;

	&.menu {
		font-size: 1em;
	}

	&.canceled {
		color: ${colorTheme.restaurant.sendToKitchenModal.items.product.canceled};
	}
`
CartProductQuantity.displayName = 'CartProductQuantity'

export const CartToppings = styled.div`
	display: flex;
	gap: 0.31em;
	flex-wrap: wrap;

	&.menu {
		margin-top: 0.31em;
		padding-left: 0.63em;
	}
`
CartToppings.displayName = 'CartToppings'
export const CartTopping = styled.div`
	display: flex;
	gap: 0.13em;
`
CartTopping.displayName = 'CartTopping'
export const CartToppingTitle = styled.div`
	font-family: ${theme.fontRoboto};
	font-size: 1em;
	font-weight: 400;
	color: ${colorTheme.restaurant.sendToKitchenModal.items.product.topping};

	&.canceled {
		color: ${colorTheme.restaurant.sendToKitchenModal.items.product.canceled};
	}
`
CartToppingTitle.displayName = 'CartToppingTitle'
export const CartItemAlignWrapper = styled.div`
	align-self: center;

	&.start {
		align-self: start;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.mobile-ml-auto {
			margin-left: auto;
		}
	}
`
CartItemAlignWrapper.displayName = 'CartItemAlignWrapper'


