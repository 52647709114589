import styled from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'
import { ButtonWrapper } from '../buttons/Button/style/buttonWrap/index.styled'

export const WebshopAdviceWrapper = styled.div`
  position: relative;
  display:block;
`

export const WebshopActiveDropdown = styled.div`
  position: absolute;
  top: 4.2em;
  right: -7em;
  padding: 1.25em 1.13em 1.13em;
  width: 24em;
  border: 2px solid #38CD90;
  border-radius: 0.75em;
  box-shadow: 0.02em 0.25em 5.44em 0 rgba(0, 0, 0, 0.53);
  background-color: ${colors.white};
  z-index: 3;
  max-height: calc(100vh - 5.13em);
  text-align: center;
  display: flex;
  gap: 0.82em;
  flex-direction: column;
  justify-content: center;
  align-items: center;

	${ButtonWrapper} {
		font-size: 0.75rem !important;
		width: 100%;
		border-radius: 0.75em;
		gap: 0.55em;
  }

	&:before, &:after {
		content: "";
		position: absolute;
	}

	&:before {
		border-left: 10px solid rgba(113, 101, 58, 0);
		border-right: 10px solid rgba(113, 101, 58, 0);
		border-bottom: 10px solid #38CD90;
		top: -11px;
	}

  &:after {
		border-left: 10px solid rgba(255, 241, 190, 0);
		border-right: 10px solid rgba(255, 241, 190, 0);
		border-bottom: 10px solid white;
		top: -9px;
  }
`
WebshopActiveDropdown.displayName = 'WebshopActiveDropdown'

export const WebshopDropdownSvg = styled.svg`
	width: 2.57em;
	height: 2.57em;
`
WebshopDropdownSvg.displayName = 'WebshopDropdownSvg'

export const WebshopDropdownWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5em;
	text-align: left;
`
WebshopDropdownWrapper.displayName = 'WebshopDropdownWrapper'

export const WebshopButtonWrapper = styled.div`
	display: flex;
	gap: 0.5em;
	width: 100%;
	margin-bottom: 0.44em;
`
WebshopButtonWrapper.displayName = 'WebshopButtonWrapper'

export const WebshopDropdownTitle = styled.div`
	font-family: ${theme.fontRobotoBold};
	font-size: 1em;
	line-height: 1.18em;
	font-weight: 700;
	width: 50%;
`
WebshopDropdownTitle.displayName = 'WebshopDropdownTitle'

export const WebshopDropdownText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.88rem;
	color: #60616E;
	font-weight: 500;
	line-height: 1.14em;
`
WebshopDropdownText.displayName = 'WebshopDropdownText'
