import styled from 'styled-components'
import { theme } from '../theme'
import { colorTheme } from '../theme/colorTheme'

export const TextSpan = styled.span`
	font-family: ${theme.fontRobotoMedium};
	font-style: normal;
	font-size: 1rem;
	color: ${colorTheme.basic.textSpan};
	padding: unset;
	line-height: normal;
	margin-left: unset;
	border: none;
`
export const TextP = styled.p`
	font-family: ${theme.fontRobotoMedium};
	font-style: normal;
	font-size: 1.5rem;
	color: ${colorTheme.basic.textP};
	padding: unset;
	line-height: normal;
	margin-left: unset;
	border: none;
	margin-bottom: 0.3em;

	&.small {
		font-size: 1.2rem;
	}

	&.red {
		color: ${colorTheme.basic.textP_red};
	}
`
TextP.displayName = 'TextP'

export const StyledA = styled.a`
	font-family: ${theme.fontRobotoMedium};
	font-style: normal;
	font-size: 1.5rem;
	color: ${colorTheme.basic.styledA};
	text-decoration: underline;
	padding: unset;
	line-height: normal;
	margin-left: unset;
	border: none;
`
StyledA.displayName = 'StyledA'
