import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const ProductQuantityWrap = styled.div`
  font-size: 1em;
  padding: 0.44em 0.5em 0.44em 1.44em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorTheme.cartModal.modalSize.productQuantity.background};
  border: 1px solid ${colorTheme.cartModal.modalSize.productQuantity.border};
  border-radius: 0.75em;
  box-sizing: border-box;
	min-height: 3.44em;
	gap: 0.63em;

  &.plus {
    border-color: ${colorTheme.cartModal.modalSize.productQuantity.plus.border};
    background: ${colorTheme.cartModal.modalSize.productQuantity.plus.background};
    box-shadow: 0 0.25em 1.7em rgba(0, 0, 0, 0.22);
  }

  &.minus {
    border-color: ${colorTheme.cartModal.modalSize.productQuantity.minus.border};
    background: ${colorTheme.cartModal.modalSize.productQuantity.minus.background};
    box-shadow: 0 0.25em 1.7em rgba(0, 0, 0, 0.22);
  }

  &.focus {
    border-color: ${colorTheme.cartModal.modalSize.productQuantity.focus.border};
    box-shadow: 0 0.25em 1.69em rgba(0, 0, 0, 0.22);
  }

  @media screen and (max-width: ${theme.point820}) {
		padding: 0.5em 0.58em 0.5em 0.72em;
		min-height: 3.93em;
	}

  @media screen and (max-width: ${theme.point720}) {
		min-height: 3.44em;
		padding: 0.44em 0.5em 0.44em 0.63em;

    &.focus {
      border-color: ${colorTheme.cartModal.modalSize.productQuantity.focus.border720};
      box-shadow: none;
    }
  }
`
ProductQuantityWrap.displayName = 'ProductQuantityWrap'

export const ProductQuantityTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
  font-size: 1.13em;
  color: ${colorTheme.cartModal.modalSize.productQuantity.title};
	text-align: left;

  &.mainDish {
    margin-right: 1em;

		> p {
			word-break: normal;
		}
  }

  > span, > div {
    margin-left: 0.67em;
  }

  > svg {
    margin-right: 0.5em;
  }

  &.plus {
    color: ${colorTheme.cartModal.modalSize.productQuantity.plusTitle};
  }

  &.minus {
    color: ${colorTheme.cartModal.modalSize.productQuantity.minusTitle};
  }

	> p {
		word-break: break-all;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.29em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;
	}
`
ProductQuantityTitle.displayName = 'ProductQuantityTitle'

export const ProductQuantityInput = styled.input`
  font-size: 1.25em;
  min-height: 1.95em;
  width: 3.1em;
  background-color: ${colorTheme.cartModal.modalSize.productQuantity.input.background};
  border: 1px solid ${colorTheme.cartModal.modalSize.productQuantity.input.border};
  border-radius: 0.6em;
  padding: 0.3em 0;
  font-family: ${theme.fontRobotoMedium};
  line-height: 1.15em;
  text-align: center;
  color: ${colorTheme.cartModal.modalSize.productQuantity.input.text};

  &.active {
    border-color: ${colorTheme.cartModal.modalSize.productQuantity.input.active.border};
    color: ${colorTheme.cartModal.modalSize.productQuantity.input.active.text};
  }

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.43rem;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.25rem;
	}
`
ProductQuantityInput.displayName = 'ProductQuantityInput'
