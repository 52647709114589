import fetchAllUsers from '../../axios/registration/user'
import { setModalLoginAccessToken, setModalLoginInvalidPasswordError, setModalLoginLoading, setModalLoginRefreshToken, setModalLoginUsername, setModalLoginUsers, setPinCodeModalLoginIsNeeded } from '../../action/modalLogin'
import { setAppErrors } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'
import postLogin from '../../axios/registration/auth/login/post'
import { toast } from 'react-toastify'
import { batch } from 'react-redux'
import postModalLogout from '../../axios/registration/auth/modalLogout/post'
import getUsersPinCodesSettings from '../../axios/registration/userPinCodes/settings/get'
import getUsersPinCode from '../../axios/registration/userPinCodes/get'

export const fetchModalLoginUsersThunk = (group) => dispatch => {
	return fetchAllUsers('USERS_CONTEXT', group, 0, 20)
		.then(res => {
			dispatch(setModalLoginUsers(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const postModalLoginThunk = (login, companyIndex, password, recaptchaValue, onLogin, storeAccessToken) => dispatch => {
	return postLogin({ email: login, companyIndex, password, recaptchaValue })
		.then(res => {
			if (storeAccessToken) {
				batch(() => {
					dispatch(setModalLoginAccessToken(res.data.accessToken))
					dispatch(setModalLoginRefreshToken(res.data.refreshToken))
				})
			}
			onLogin(res.data.accessToken)
			dispatch(setModalLoginUsername(res.data.user.username))
		}).catch(error => {
			if (error.response.status === 401) {
				dispatch(setModalLoginInvalidPasswordError(true))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		}).finally(
			dispatch(setModalLoginLoading(false))
		)
}

export const postModalLogoutThunk = () => (dispatch, getState) => {
	const logInWithToken = getState().modalLogin.refreshToken
	if (logInWithToken) {
		return postModalLogout(getState().modalLogin.refreshToken)
		.then(res => {
			dispatch(setModalLoginAccessToken(null))
			dispatch(setModalLoginRefreshToken(null))
			dispatch(setModalLoginUsername(null))
		}).catch(error => {
			toast.error('Logout failed')
			dispatch(setModalLoginAccessToken(null))
			dispatch(setModalLoginRefreshToken(null))
			dispatch(setModalLoginUsername(null))
		})
	} else {
		dispatch(setModalLoginUsername(null))
	}
}

export const getModalLoginUserPinCodeSettingsThunk = (module) => dispatch => {
	return getUsersPinCodesSettings(module)
		.then((res) => {
			dispatch(setPinCodeModalLoginIsNeeded(res.data.showModal))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getModalLoginUserPinCodeThunk = (pinCode, callbackSuccess) => dispatch => {
	return getUsersPinCode(pinCode)
		.then((res) => {
			dispatch(setModalLoginUsername(res.data.username))
			if (typeof callbackSuccess === 'function') callbackSuccess()
		}).catch(error => {
			const isInvalidPinCode = error.response.data?.errorStatusCode === 'invalid_user_pin_code'
			if (isInvalidPinCode) {
				dispatch(setModalLoginInvalidPasswordError(true))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		}).finally(
			dispatch(setModalLoginLoading(false))
		)
}
