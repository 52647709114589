import { retrieveErrorFromApi } from '../../../../helper'
import { setSumOrders } from '../../../action/order'
import { setAppErrors } from '../../../action/system'
import calculateAllOrderSum from '../../../axios/orderview/calculateAllOrderSum'

export const calculateAllOrderSumThunk = (dateFrom, dateTo, paymentMethod, status, orderType, processedByUsername, processedByUsernameWithEmpty) => dispatch => {
	return calculateAllOrderSum(dateFrom, dateTo, paymentMethod, status, orderType, processedByUsername, processedByUsernameWithEmpty)
		.then(res => {
			dispatch(setSumOrders(res.data.sumOrders))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
