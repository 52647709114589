import marketingInstance from './instance'

const fetchCoupons = (page = 0, size = 10, active = null, activeForShop = null) => {
  return marketingInstance.get(
		'/coupons'
		+ '?page=' + page
		+ '&size=' + size
		+ (active !== null ? '&active=' + active : '')
		+ (activeForShop !== null ? '&activeForShop=' + activeForShop : '')
	)
}

export default fetchCoupons
