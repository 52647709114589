import React, { useCallback, useEffect } from 'react'
import CustomInfoModal from '../../../../modal/CustomInfoModal'
import Button from '../../../../buttons/Button'
import TextInput from '../../../../inputs/TextInput'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ResendEmailInfoModalWrap, ResendEmailInfoText } from './index.styled'
import { removeEmptyFields } from '../../../../../../helper'
import { updateCustomerThunk } from '../../../../../../redux/thunk/customer'
import { convert2InvoiceCustomer } from '../InvoiceBillingAddressModal/helper'
import { sendInvoiceEmailThunk, updateInvoiceCustomerThunk } from '../../../../../../redux/thunk/invoice'
import { setSelectedInvoice, setShowInvoiceResendEmailInfoModal } from '../../../../../../redux/action/invoice'
import { setCustomer } from '../../../../../../redux/action/customer'
import { Form } from '../../../../form/index.styled'
import { emailPattern } from '../../../../../../helper/email'

const EVENT_Z_INDEX = 84

const defaultValues = {
	email: null,
}

const InvoiceResendEmailInfoModal = React.memo(() => {
	const { t } = useTranslation()
	const { handleSubmit, register, formState: { errors }, reset, watch } = useForm({ defaultValues })
	const dispatch = useDispatch()
	const showInfoResendEmailModal = useSelector(store => store.invoice.modal.showInfoResendEmail)
	const customer = useSelector(store => store.customerStore.customer)
	const selectedInvoice = useSelector(store => store.invoice.selectedInvoice)

	const handleReset = useCallback((values) => {
		reset({ ...defaultValues, ...values })
	}, [reset])

	useEffect(() => {
		if (customer) {
			handleReset({
				...customer,
			})
		}
	}, [customer]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		dispatch(setShowInvoiceResendEmailInfoModal(false))
		handleReset(defaultValues)
		dispatch(setCustomer(null))
		dispatch(setSelectedInvoice(null))
	}, [dispatch, handleReset])

	const handleResendEmail = useCallback(async (data) => {
		const customerData = convert2InvoiceCustomer(data)
		await dispatch(updateInvoiceCustomerThunk(selectedInvoice.id, customerData))
		dispatch(sendInvoiceEmailThunk(selectedInvoice.id))
		handleClose()
	}, [dispatch, selectedInvoice, handleClose])

	const onSubmit = useCallback(async (data) => {
		let dataValue = { ...data }
		dataValue = removeEmptyFields(dataValue)
		await dispatch(updateCustomerThunk(dataValue))
		handleResendEmail(dataValue)
	}, [dispatch, handleResendEmail])

	return (
		<CustomInfoModal isOpen={showInfoResendEmailModal}
										 title={t('app.modals.Attention')}
										 close={handleClose}
										 zIndex={EVENT_Z_INDEX}
										 text={t('invoices.modal.resendEmail.sendQuestion')}
										 button={
											 <>
												 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')}
																 keyButton="F3" zIndex={EVENT_Z_INDEX}
																 onClick={handleClose}
																 className="back" />
												 <Button name="send-email" color="green" icon="send-email" text={t('buttons.controls.send_invoice')}
																 keyButton="F2" zIndex={EVENT_Z_INDEX}
																 onClick={handleSubmit(onSubmit)} />
											 </>}>
			<ResendEmailInfoModalWrap>
				<ResendEmailInfoText>{t('invoices.modal.resendEmail.description')}</ResendEmailInfoText>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<TextInput testId="billing-address-email"
										 name="email"
										 label={t('AddOrder.Client.form.email') + '*'}
										 errors={errors}
										 register={register}
										 options={{
											 required: t('app.validation.required'),
											 pattern: {
												 value: emailPattern,
												 message: t('app.validation.invalid_email'),
											 },
										 }}
										 watch={watch('email')}
										 color="gray" />
				</Form>
			</ResendEmailInfoModalWrap>
		</CustomInfoModal>
	)
})

InvoiceResendEmailInfoModal.displayName = 'InvoiceResendEmailInfoModal'
export default InvoiceResendEmailInfoModal
