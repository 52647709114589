import styled from 'styled-components'
import { colorTheme } from '../../../../../../../style/theme/colorTheme'

export const OrderTitle = styled.div`
	font-size: 10px;
	text-align: left;

	&.center {
		text-align: center;
	}

	&.right {
		text-align: right;
	}

	&.bold {
		> div {
			font-weight: 700;
		}
	}
`
OrderTitle.displayName = 'OrderTitle'

export const OrderCategoryTitle = styled.div`
	font-size: 10px;
	text-align: left;
`
OrderCategoryTitle.displayName = 'OrderCategoryTitle'

export const OrderSubTitle = styled.div`
	font-size: 10px;
	text-align: left;

	> span {
		font-weight: 400;
	}

	&.center {
		text-align: center;
	}

	&.right {
		text-align: right;
	}

	&.bold {
		font-weight: 700;
	}

	&.medium {
		font-size: 10px;
	}
`
OrderSubTitle.displayName = 'OrderSubTitle'

export const OrderText = styled.div`
	font-size: 10px;
	text-align: left;
`
OrderText.displayName = 'OrderText'

export const OrderRemark = styled.div`
	font-size: 10px;
	padding: 10px 0;
	font-style: italic;
	border-top: 1px dashed ${colorTheme.invoices.modal.invoiceData.text};
	border-bottom: 1px dashed ${colorTheme.invoices.modal.invoiceData.text};
`
OrderRemark.displayName = 'OrderRemark'

export const OrderProductRemark = styled.div`
	font-size: 10px;
	padding: 10px 0;
	font-style: italic;
	border-top: 1px dashed ${colorTheme.invoices.modal.invoiceData.text};
	border-bottom: 1px dashed ${colorTheme.invoices.modal.invoiceData.text};
`
OrderProductRemark.displayName = 'OrderProductRemark'

export const OrderProductsWrap = styled.div`
	margin-bottom: 10px;

	> div:last-child {
		border-bottom: 1px dashed ${colorTheme.invoices.modal.invoiceData.text};
	}
`
OrderProductsWrap.displayName = 'OrderProductsWrap'

export const OrderProduct = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	padding-bottom: 10px;
	border-bottom: 1px dashed ${colorTheme.invoices.modal.invoiceData.borderProduct};
	margin-bottom: 10px;
`
OrderProduct.displayName = 'OrderProduct'

export const OrderMenuWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`
OrderMenuWrapper.displayName = 'OrderMenuWrapper'

export const OrderProductWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-left: 10px;
`
OrderProductWrapper.displayName = 'OrderProductWrapper'

export const OrderIngredients = styled.div`
	margin-left: 10px;
`
OrderIngredients.displayName = 'OrderIngredients'

export const OrderToppings = styled.div`
	display: flex;
	gap: 6px;
	flex-direction: column;
`
OrderToppings.displayName = 'OrderToppings'

export const OrderTopping = styled.div`
	display: flex;
	justify-content: space-between;
	margin-left: 20px;
`
OrderTopping.displayName = 'OrderTopping'

export const OrderCost = styled.div`
	display: flex;
	justify-content: space-between;

	&.cost {
		margin: 5px 0;
		text-transform: uppercase;
	}
`
OrderCost.displayName = 'OrderCost'

export const OrderDelivery = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-bottom: 10px;
	border-bottom: 1px dashed ${colorTheme.invoices.modal.invoiceData.text};
`
OrderDelivery.displayName = 'OrderDelivery'

export const OrderTotalsWrap = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
`
OrderTotalsWrap.displayName = 'OrderTotalsWrap'

export const OrderTotalWrapper = styled.div`
	display: flex;
	gap: 6px;
	flex-direction: column;

	&.top {
		margin-top: -5px;
	}
`
OrderTotalWrapper.displayName = 'OrderTotalWrapper'

export const OrderRate = styled.div`
	font-size: 10px;
	text-align: right;
	text-transform: uppercase;
`
OrderRate.displayName = 'OrderRate'

export const OrderGiftCouponTitle = styled.div`
	font-size: 10px;
	text-align: left;
`
OrderGiftCouponTitle.displayName = 'OrderGiftCouponTitle'

export const OrderGiftCouponWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`
OrderGiftCouponWrap.displayName = 'OrderGiftCouponWrap'
