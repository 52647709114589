import React from 'react'
import { ActionModalText } from './index.styled'
import { useTranslation } from 'react-i18next'
import CustomModal from '../CustomModal'
import Button from '../../buttons/Button'

const ActionModal = React.memo(({ modalIsOpen = false, accept, cancel, close, acceptButton, closeButton, modalTitle, modalBody, item, children, zIndex, returnZIndex, disabled }) => {
	const { t } = useTranslation()
	return (
		<CustomModal isOpen={modalIsOpen}
								 size="small"
								 close={close}
								 title={modalTitle}
								 disableBackButton={true}
								 zIndex={zIndex}
								 returnZIndex={returnZIndex}
								 button={
									 <>
										 <Button name="cancel" icon="back" text={closeButton || t('Modal.cancel')} keyButton="F3" zIndex={zIndex} onClick={cancel} className="back" />
										 <Button name="accept" icon="checkmark" text={acceptButton || t('Modal.accept')} color="green" keyButton="F2" zIndex={zIndex} onClick={accept} disabled={disabled} />
									 </>
								 }>
			<ActionModalText>
				{modalBody} {item && <><b>{item}</b> ?</>}
				{children}
			</ActionModalText>
		</CustomModal>
	)
})

ActionModal.displayName = 'ActionModal'
export default ActionModal
