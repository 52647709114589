export const checkSavedCartItemsRemoveAbility = (orders) => {
	const notCanceledSavedItemsCount = orders.reduce((itemsCount, order) => {
		if (order.id) {
			const validItems = order.items.filter(item => item.status !== 'CANCELED')
			return itemsCount + validItems.length
		}
		return itemsCount
	}, 0)

	return notCanceledSavedItemsCount > 1
}
