import React, { Suspense } from 'react'
import PageLoader from '../../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../../helper/lazyWithRetry'
import { useSearchParams } from 'react-router-dom'

const OwnerRegistration = lazyWithRetry(() => import(/* webpackChunkName: "registration-owner" */'../../../component/Registration/Owner'))

const OwnerRegistrationPage = React.memo(() => {
	let [params] = useSearchParams()

	return (
		<Suspense fallback={<PageLoader className="authPage" />}>
			<OwnerRegistration dealerNumber={params.get('dealerNumber')} />
		</Suspense>
	)
})

OwnerRegistrationPage.displayName = 'OwnerRegistrationPage'
export default OwnerRegistrationPage
