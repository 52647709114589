import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const PinCodeInputWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	gap: 0.63em;
	align-items: center;

	&.big {
		gap: 1.63em;
	}

	@media screen and (max-width: ${theme.point820}) {
		gap: 1.86em;

		&.big {
			gap: 1.86em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 1.63em;

		&.big {
			gap: 1.63em;
		}
	}
`
PinCodeInputWrapper.displayName = 'PinCodeInputWrapper'

export const PinNumberInputWrapper = styled.label`
	font-size: 1em;
	display: flex;
	width: 3.5em;
	height: 4.19em;
	padding: 0.63em;
	justify-content: center;
	align-items: center;
	gap: 0.63em;
	flex-shrink: 0;
	border-radius: 0.5em;
	border: 1px solid ${colorTheme.input.pinCode.border};
	background: ${colorTheme.input.pinCode.background};

	&.big {
		height: 4.38em;
	}

	&.focus {
		border-color: ${colorTheme.input.pinCode.focus.border};
		box-shadow: 0 4px 27px rgba(0, 0, 0, 0.22);
	}

	&.error {
		border: 1px solid ${colorTheme.input.pinCode.error.border};
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 4em;
		height: 3.93em;
		border-radius: 0.57em;

		&.big {
			height: 5em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 3.5em;
		height: 3.43em;
		border-radius: 0.5em;

		&.big {
			height: 4.38em;
		}
	}
`

PinNumberInputWrapper.displayName = 'PinNumberInputWrapper'

export const PinNumberInputField = styled.input`
  width: 100%;
  font-size: 2.25em;
  font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
  background: transparent;
  border: none;
  color: ${colorTheme.input.pinCode.text};
	cursor: pointer;
	text-align: center;

	&.password {
		-webkit-text-security: disc; // convert text to dot
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 2.29em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 2em;
	}
`

PinNumberInputField.displayName = 'PinNumberInputField'
