import React from 'react'
import CartMenu from './CartMenu'
import CartProduct from './CartProduct'

const CartItem = React.memo(({ item, index }) => {
	return (
		item?.type === 'MENU' ?
			<CartMenu item={item} index={index} /> :
			<CartProduct item={item} index={index} />
	)
})

CartItem.displayName = 'CartItem'
export default CartItem
