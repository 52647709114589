import React from 'react'
import { Key, ClearKey, EnterKey, KeyboardMain } from './index.styled'

const NumbersKeyboard = React.memo(({ handleClick, handleCommaClick, enter, reset }) => {
	return (
		<KeyboardMain>
			<Key onClick={() => handleClick('7')}>7</Key>
			<Key onClick={() => handleClick('8')}>8</Key>
			<Key onClick={() => handleClick('9')}>9</Key>
			<Key onClick={() => handleClick('4')}>4</Key>
			<Key onClick={() => handleClick('5')}>5</Key>
			<Key onClick={() => handleClick('6')}>6</Key>
			<Key onClick={() => handleClick('1')}>1</Key>
			<Key onClick={() => handleClick('2')}>2</Key>
			<Key onClick={() => handleClick('3')}>3</Key>
			<Key onClick={() => handleClick('0')}>0</Key>
			<Key onClick={() => handleClick('00')}>00</Key>
			<Key onClick={() => handleCommaClick('.')}>,</Key>

			<ClearKey onClick={reset}>
				CE
			</ClearKey>

			<EnterKey onClick={enter}>
				<svg xmlns="http://www.w3.org/2000/svg" width="25.93" height="20.81" viewBox="0 0 25.93 20.81" fill="#FFF">
					<path d="M1871.82,1036.52v-12.1a1.66,1.66,0,1,0-3.32,0v10.43h-16.99l2.5-2.52a1.7,1.7,0,0,0,0-2.37,1.654,1.654,0,0,0-2.35,0l-5.33,5.38a1.709,1.709,0,0,0-.21.26l-0.23.57a1.735,1.735,0,0,0-.01.68l0.24,0.58a0.973,0.973,0,0,0,.21.27l5.33,5.38a1.665,1.665,0,1,0,2.35-2.36l-2.5-2.52h18.65A1.667,1.667,0,0,0,1871.82,1036.52Z" transform="translate(-1845.88 -1022.75)" />
				</svg>
			</EnterKey>
		</KeyboardMain>
	)
})

NumbersKeyboard.displayName = 'NumbersKeyboard'
export default NumbersKeyboard
