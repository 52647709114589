import shopInstance from '../instance'

const updateShopWorkSchedule = (daysOfWeek, orderingSetting) => {
	return shopInstance.put('/workSchedule', {
		daysOfWeek,
		orderingSetting,
	})
}

export default updateShopWorkSchedule
