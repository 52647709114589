import { addRestaurantTablesWaiterCall, updateRestaurantTable } from '../../redux/action/restaurant'
import { setTableCartSplits } from '../../redux/action/tableCart'
import { fetchRestaurantAreasThunk } from '../../redux/thunk/restaurant/table'

export const processRestaurantMessageThunk = (message) => (dispatch) => {
	switch (message?.type) {
		case 'restaurant.TABLE_UPDATED_EVENT':
			dispatch(updateRestaurantTable(message.data))
			dispatch(setTableCartSplits(message.data.splits))
			dispatch(fetchRestaurantAreasThunk())
			break
		case 'restaurant.WAITER_IS_CALLED_EVENT':
			dispatch(addRestaurantTablesWaiterCall(message.data))
			break
		default:
			// eslint-disable-next-line
			console.error('Unknown restaurant message type: ', JSON.stringify(message))
			break
	}
}
