import { setRestaurantCouponActivateModal, setRestaurantModalChangeCalculatorIsOpen, setRestaurantModalInvoiceCustomerDataIsOpen, setRestaurantModalTerminalButtonsIsOpen, setRestaurantModalTerminalPaymentProcessIsOpen, setRestaurantOrderChangeModal, setRestaurantOrderModal, setRestaurantOrdersChangeModal, setRestaurantOrdersMove, setRestaurantOrderSplitModal, setRestaurantPaymentModal, setRestaurantPaymentTypeModal, setRestaurantShowProcessPaymentErrorModal, setRestaurantSplitActionsModal, setRestaurantSplitModal, setRestaurantSwapModal, setRestaurantTable, setRestaurantTableClosedErrorModal, setRestaurantTableModal } from '../../action/restaurant'

export const resetRestaurantModalsThunk = () => dispatch => {
	dispatch(setRestaurantTable(null))
	dispatch(setRestaurantOrderModal(false))
	dispatch(setRestaurantOrderSplitModal(false))
	dispatch(setRestaurantPaymentModal(false))
	dispatch(setRestaurantPaymentTypeModal(false))
	dispatch(setRestaurantSplitModal(false))
	dispatch(setRestaurantTableModal(false))
	dispatch(setRestaurantTableClosedErrorModal(false))
	dispatch(setRestaurantOrdersMove([]))
	dispatch(setRestaurantSwapModal(false))
	dispatch(setRestaurantSplitActionsModal(false))
	dispatch(setRestaurantModalChangeCalculatorIsOpen(false))
	dispatch(setRestaurantModalTerminalButtonsIsOpen(false))
	dispatch(setRestaurantModalTerminalPaymentProcessIsOpen(false))
	dispatch(setRestaurantCouponActivateModal(false))
	dispatch(setRestaurantOrderChangeModal(false))
	dispatch(setRestaurantOrdersChangeModal(false))
	dispatch(setRestaurantModalInvoiceCustomerDataIsOpen(false))
	dispatch(setRestaurantShowProcessPaymentErrorModal(false))
}
