import { SET_SOCKET_IO_CLIENT, SET_SOCKET_PRINTER_DESKTOP_SESSIONS, SET_SOCKET_RECONNECTED, SET_SOCKET_VOIP_DESKTOP_SESSIONS } from '../../actionTypes/socket'

const initialState = {
	socketIoClient: null,
	socketReconnected: false,
	printerDesktopSessions: [],
	voipDesktopSessions: [],
}

export function socketReducer (state = initialState, action) {
	switch (action.type) {
		case SET_SOCKET_IO_CLIENT:
			return {
				...state,
				socketIoClient: action.value,
			}
		case SET_SOCKET_RECONNECTED:
			return {
				...state,
				socketReconnected: action.value,
			}
		case SET_SOCKET_PRINTER_DESKTOP_SESSIONS:
			return {
				...state,
				printerDesktopSessions: action.value,
			}
		case SET_SOCKET_VOIP_DESKTOP_SESSIONS:
			return {
				...state,
				voipDesktopSessions: action.value,
			}
		default:
			return state
	}
}


