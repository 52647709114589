import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const ClientMonitorSettings = lazyWithRetry(() => import(/* webpackChunkName: "client-screen" */'../../component/ClientMonitorSettings'))

const ClientMonitorSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<ClientMonitorSettings />
		</Suspense>
	)
})

ClientMonitorSettingsPage.displayName = 'ClientMonitorSettingsPage'
export default ClientMonitorSettingsPage
