import { SET_DELIVERY_CHANGE_SETTINGS, SET_DELIVERY_SETTINGS_CART_SETTINGS, UPDATE_DELIVERY_CHANGE_SETTING, UPDATE_DELIVERY_SETTINGS_CART_SETTING } from '../../actionTypes/deliverySettings'

const initialState = {
	cartSettings: [],
	changeSettings: [],
}

export function deliverySettingsReducer (state = initialState, action) {
	switch (action.type) {
		case SET_DELIVERY_SETTINGS_CART_SETTINGS:
			return {
				...state,
				cartSettings: action.value,
			}
		case UPDATE_DELIVERY_SETTINGS_CART_SETTING:
			const updateCartSettings = [...state.cartSettings].map(item => item.businessScope === action.value.businessScope ? action.value : item)
			return {
				...state,
				cartSettings: updateCartSettings,
			}
		case SET_DELIVERY_CHANGE_SETTINGS:
			return {
				...state,
				changeSettings: action.value,
			}
		case UPDATE_DELIVERY_CHANGE_SETTING:
			const updateChangeSettings = [...state.changeSettings].map(item => item.businessScope === action.value.businessScope ? action.value : item)
			return {
				...state,
				changeSettings: updateChangeSettings,
			}
		default:
			return state
	}
}
