import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import getClientMonitorConfig from '../../axios/clientMonitorConfig/get'
import updateClientMonitorConfig from '../../axios/clientMonitorConfig/put'
import { setClientMonitorConfig } from '../../action/clientMonitorConfig'
import { closeClientMonitorWindow, openClientMonitorWindow, sendMessageToClientMonitor } from '../../../component/ClientMonitorWindow/helper'
import getClientMonitorPublicConfig from '../../axios/clientMonitorConfig/public/get'
import { withJsClientMonitor } from '../../../helper/clientMonitor'
import toggleClientMonitor from '../../axios/clientMonitorConfig/toggle'

export const getClientMonitorConfigThunk = () => dispatch => {
	return getClientMonitorConfig()
		.then((res) => {
			dispatch(setClientMonitorConfig(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getClientMonitorPublicConfigThunk = () => dispatch => {
	return getClientMonitorPublicConfig()
		.then((res) => {
			dispatch(setClientMonitorConfig(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateClientMonitorConfigThunk = (config) => dispatch => {
	dispatch(setClientMonitorConfig(config))
	return updateClientMonitorConfig(config).then((res) => {
		dispatch(setClientMonitorConfig(res.data))
	}).catch((error) => {
		dispatch(getClientMonitorConfigThunk())
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const startClientMonitorThunk = () => async dispatch => {
	try {
		const res = await getClientMonitorConfig()
		if (res.data.enabled) {
			if (withJsClientMonitor()) {
				openClientMonitorWindow()
				window.addEventListener('message', (event) => {
					if (event.data?.type === 'client-monitor.CLIENT_MONITOR_LOADED') {
						sendMessageToClientMonitor('cashdesk.CLIENT_MONITOR_CONFIG_CHANGED', res.data)
					}
				})
			} else {
				dispatch(updateClientMonitorConfigThunk({
					...res.data, enabled: true
				}))
			}
		}
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const stopClientMonitorThunk = () => async dispatch => {
	try {
		const res = await getClientMonitorConfig()
		if (!res.data.enabled) {
			closeClientMonitorWindow()
		}
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const toggleClientMonitorThunk = (state) => async dispatch => {
	try {
		await toggleClientMonitor(state)
		dispatch(getClientMonitorConfigThunk())
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

