import React, { useCallback, useState } from 'react'
import { ChildrenWrapper, InputError, InputField, InputImg, InputLabel, InputLabelText, InputWrapper } from '../index.styled'
import checked from '../../../../../img/svg/input/checked.svg'
import plusImg from '../../../../../img/svg/input/plus.svg'

const TextInputControlled = React.memo(({
	options,
	name,
	label = '',
	type = 'text',
	defaultValue,
	readOnly,
	value,
	children,
	autoFocus,
	testId,
	tabIndex,
	color,
	autoComplete,
	disabled,
	size,
	className,
	errorMessage,
	isIcon = false,
	hasLongError = false,
	hasLongTextError = false,
	onChange,
	onBlur = () => {
	},
	showCheck,
	onFocus = () => {
	},
	showPlus,
	onClickPlus,
}) => {

	const [focused, setFocused] = useState(false)
	const errorClass = (hasLongError ? 'error-big' : 'error') + (hasLongTextError ? ' error-big-text' : '')

	const handleFocus = useCallback(() => {
		setFocused(true)
		onFocus()
	}, [onFocus])

	const handleBlur = () => {
		setFocused(false)
		onBlur()
	}

	const labelUp = focused || readOnly || defaultValue || (value || value === 0)
	const layout = (focused ? 'focus ' : '') + (errorMessage ? `${errorClass} ` : '') + (labelUp ? '' : 'toggled ') + (disabled ? 'disabled ' : '') + (color ? color + ' ' : '') + (isIcon ? 'isIcon ' : '') + size + ' ' + className

	let typeInput = ''
	if (type) {
		typeInput = type
	}

	return (
		<InputWrapper className={layout}>
			<InputError type={type} className={layout}>{errorMessage}</InputError>
			<InputLabel className={layout}>
				<InputLabelText className={layout}>{label}</InputLabelText>
				<InputField data-testid={testId ?? null}
										id={name}
										value={value}
										type={typeInput}
										autoFocus={autoFocus}
										autoComplete={autoComplete || 'off'}
										role="presentation"
										onFocus={handleFocus}
										className={layout}
										readOnly={readOnly}
										onBlur={handleBlur}
										tabIndex={tabIndex}
										disabled={disabled}
										onChange={onChange} />
				<ChildrenWrapper>{children}</ChildrenWrapper>
				{showCheck &&
					<InputImg className="small">
						<img src={checked} alt="check" />
					</InputImg>
				}
				{showPlus &&
					<InputImg onClick={onClickPlus} className={errorMessage && 'error'}>
						<img src={plusImg} alt="plus" />
					</InputImg>}
			</InputLabel>
		</InputWrapper>
	)
})

TextInputControlled.displayName = 'TextInputControlled'
export default TextInputControlled
