import { APPEND_USERS, SET_ALL_USERS, SET_EDIT_USER_MODE, SET_USER_GROUPS, SET_USERS_TOTAL, SET_USER_LOADING, SET_USER_DUPLICATE_PIN_CODE_ERROR } from '../../actionTypes/user'

export const setAllUsers = value => ({ type: SET_ALL_USERS, value })

export const appendUsers = value => ({ type: APPEND_USERS, value })

export const setUsersTotal = value => ({ type: SET_USERS_TOTAL, value })

export const setEditUserMode = value => ({ type: SET_EDIT_USER_MODE, value })

export const setUserGroups = value => ({ type: SET_USER_GROUPS, value })

export const setUserLoading = value => ({ type: SET_USER_LOADING, value })

export const setUserDuplicatePinCodeError = value => ({ type: SET_USER_DUPLICATE_PIN_CODE_ERROR, value })
