import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Phone = lazyWithRetry(() => import(/* webpackChunkName: "phone" */'../../component/Phone'))

const PhonePage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Phone />
		</Suspense>
	)
})

PhonePage.displayName = 'PhonePage'
export default PhonePage
