import phoneInstance from '../instance'

const testPhone = (data, callbackSuccess, callbackError) => {
  return async dispatch => {
    phoneInstance.post(
      '/voip-settings/test',
      data,
    ).then(() => {
      typeof callbackSuccess === 'function' && callbackSuccess()
    }).catch(error => {
      typeof callbackError === 'function' && callbackError(error)
    })
  }
}

export default testPhone
