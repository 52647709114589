import React from 'react'
import { ItemGrid } from './index.styled'

export const PhoneAutocompleteItem = ({ item, active }) =>
	<ItemGrid className={active ? 'active' : ''}>
		<svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17.9195 19C15.5679 19 11.6196 17.96 5.57133 13.4005C-1.89948 7.78243 0.0359591 3.0744 0.810137 1.76506C1.54561 0.502159 2.62945 -0.212868 3.3746 0.0564278C4.96166 0.632164 6.54873 1.81149 8.08741 3.54799C8.71642 4.25373 8.85191 5.25662 8.45514 6.10166C7.70999 7.66172 7.75838 8.03316 7.76806 8.0703C8.02934 8.46032 11.2809 10.949 13.5066 12.054C14.726 12.6669 14.9195 12.509 15.5002 12.0633C15.7905 11.8404 16.1485 11.5619 16.6808 11.3111C17.8324 10.7818 20.6291 11.9519 21.8097 12.6576C22.6323 13.1591 23 13.5769 23 14.0041C23 14.5241 22.7097 15.3134 22.3903 16.1399C22.3419 16.2606 22.3032 16.3628 22.2742 16.4556C21.8581 17.5978 20.9097 18.4243 19.6227 18.7771C19.1775 18.9071 18.6259 19 17.9195 19ZM2.99719 1.02218C2.80364 1.04075 1.86495 1.6722 1.36174 3.12083C0.761751 4.82946 0.597238 8.34888 6.23906 12.5926C13.9808 18.415 17.9582 18.1735 19.3033 17.7928C20.2613 17.5235 20.9291 16.9478 21.2387 16.1213C21.2678 16.0285 21.3162 15.917 21.3645 15.7963C21.5484 15.332 21.8774 14.4777 21.9065 14.0784C21.7129 13.7905 20.5904 13.0941 19.1388 12.6019C17.9872 12.2119 17.3195 12.184 17.1453 12.2676C16.7292 12.4626 16.4485 12.6762 16.1776 12.8805C15.355 13.5119 14.7357 13.8369 13.0131 12.9826C10.9422 11.9519 7.22613 9.28678 6.81001 8.53461C6.58744 8.1353 6.61647 7.49457 7.48742 5.68378C7.71967 5.21019 7.63258 4.63446 7.2842 4.23516C5.85197 2.61938 4.41006 1.5422 2.99719 1.02218Z" fill="#ADADB4" />
		</svg>
		<div className="first">{item.phoneNumber}</div>
		<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.97552 10.3724C5.10767 10.3724 2.78409 8.0488 2.78409 5.19143C2.77363 2.32358 5.10767 0 7.97552 0C10.8434 0 13.1669 2.32358 13.1669 5.19143C13.1669 8.0488 10.8434 10.3724 7.97552 10.3724ZM7.97552 1.40252C5.8822 1.40252 4.18661 3.09811 4.18661 5.19143C4.18661 7.27428 5.8822 8.96986 7.97552 8.96986C10.0584 8.96986 11.7644 7.27428 11.7644 5.19143C11.7644 3.09811 10.0584 1.40252 7.97552 1.40252Z" fill="#ADADB4" />
			<path d="M15.2393 19.6978C14.8521 19.6978 14.5381 19.3838 14.5381 18.9965C14.5381 15.3856 11.597 12.4445 7.97554 12.4445C4.3541 12.4445 1.41299 15.3856 1.41299 18.9965C1.41299 19.3838 1.09899 19.6978 0.711728 19.6978C0.313998 19.6978 0 19.3838 0 18.9965C0 14.611 3.57957 11.0419 7.97554 11.0419C12.3715 11.0419 15.9511 14.611 15.9511 18.9965C15.9406 19.3838 15.6266 19.6978 15.2393 19.6978Z" fill="#ADADB4" />
		</svg>
		<div className="second">
			<div>{item.name}</div>
			<div>{item.street}</div>
		</div>
	</ItemGrid>
PhoneAutocompleteItem.displayName = 'PhoneAutocompleteItem'

export const NumberAutocompleteItem = ({ item, active }) =>
	<ItemGrid className={(active ? 'active ' : '') + 'col-1'}>
		<div className="first">{item.customerNumber}</div>
		<div />
		<div className="second">
			<div>{item.name}</div>
			<div>{item.street}</div>
		</div>
		<div />
	</ItemGrid>
NumberAutocompleteItem.displayName = 'NumberAutocompleteItem'

export const NameAutocompleteItem = ({ item, active }) =>
	<ItemGrid className={(active ? 'active ' : '') + 'col-1'}>
		<div className="first">{item.name}</div>
		<div />
		<div className="second">
			<div>{item.street}</div>
		</div>
		<div />
	</ItemGrid>
NameAutocompleteItem.displayName = 'NameAutocompleteItem'

export const CompanyNameAutocompleteItem = ({ item, active }) =>
	<ItemGrid className={(active ? 'active ' : '') + ''}>
		<svg xmlns="http://www.w3.org/2000/svg" width="22.84" height="19.969" viewBox="0 0 22.84 19.969" fill="none">
			<path d="M3667.24,211.867h-3.11a1.542,1.542,0,0,0-1.54,1.542v2.381a1.542,1.542,0,0,0,1.54,1.541h3.11a1.542,1.542,0,0,0,1.54-1.541v-2.381A1.542,1.542,0,0,0,3667.24,211.867Zm0.42,3.923a0.427,0.427,0,0,1-.42.421h-3.11a0.424,0.424,0,0,1-.42-0.421v-2.381a0.421,0.421,0,0,1,.42-0.421h3.11a0.424,0.424,0,0,1,.42.421v2.381Zm6.19-8.252a0.527,0.527,0,0,0-.07-0.275l-3.4-5.978a0.573,0.573,0,0,0-.49-0.285h-14.93a0.551,0.551,0,0,0-.49.285l-3.4,5.978a0.644,0.644,0,0,0-.07.275,3.283,3.283,0,0,0,1.96,3V220.4a0.56,0.56,0,0,0,.56.56h17.81a0.567,0.567,0,0,0,.56-0.56v-9.789a0.117,0.117,0,0,0-.01-0.06A3.284,3.284,0,0,0,3673.85,207.538Zm-18.57-5.413h14.29l2.65,4.675h-19.59Zm11.98,5.791a2.154,2.154,0,0,1-4.24,0h4.24Zm-5.43,0a2.154,2.154,0,0,1-4.24,0h4.24Zm-9.67,0h4.24A2.154,2.154,0,0,1,3652.16,207.916Zm8.25,11.919h-3.22v-6.048a0.8,0.8,0,0,1,.8-0.8h1.63a0.8,0.8,0,0,1,.8.8v6.048h-0.01Zm10.36,0h-9.24v-6.048a1.915,1.915,0,0,0-1.91-1.92h-1.63a1.92,1.92,0,0,0-1.92,1.92v6.052h-1.99v-9.027c0.07,0,.13,0,0.2,0a3.269,3.269,0,0,1,5.43,0,3.269,3.269,0,0,1,5.43,0,3.269,3.269,0,0,1,5.43,0c0.07,0,.13,0,0.2,0v9.023h0Zm-0.2-10.139a2.155,2.155,0,0,1-2.12-1.775h4.25A2.17,2.17,0,0,1,3670.57,209.7Z" transform="translate(-3651 -201)" fill="#ADADB4" />
		</svg>
		<div className="first">{item.companyName}</div>
		<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.97552 10.3724C5.10767 10.3724 2.78409 8.0488 2.78409 5.19143C2.77363 2.32358 5.10767 0 7.97552 0C10.8434 0 13.1669 2.32358 13.1669 5.19143C13.1669 8.0488 10.8434 10.3724 7.97552 10.3724ZM7.97552 1.40252C5.8822 1.40252 4.18661 3.09811 4.18661 5.19143C4.18661 7.27428 5.8822 8.96986 7.97552 8.96986C10.0584 8.96986 11.7644 7.27428 11.7644 5.19143C11.7644 3.09811 10.0584 1.40252 7.97552 1.40252Z" fill="#ADADB4" />
			<path d="M15.2393 19.6978C14.8521 19.6978 14.5381 19.3838 14.5381 18.9965C14.5381 15.3856 11.597 12.4445 7.97554 12.4445C4.3541 12.4445 1.41299 15.3856 1.41299 18.9965C1.41299 19.3838 1.09899 19.6978 0.711728 19.6978C0.313998 19.6978 0 19.3838 0 18.9965C0 14.611 3.57957 11.0419 7.97554 11.0419C12.3715 11.0419 15.9511 14.611 15.9511 18.9965C15.9406 19.3838 15.6266 19.6978 15.2393 19.6978Z" fill="#ADADB4" />
		</svg>
		<div className="second">
			<div>{item.name}</div>
			<div>{item.street}</div>
		</div>
	</ItemGrid>
CompanyNameAutocompleteItem.displayName = 'CompanyNameAutocompleteItem'
