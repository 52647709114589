import React from 'react'
import { CartButton, MobileCartButtonWrap } from './index.styled'
import ButtonIcon from '../../../../img/svg/button'

const MobileCartButton = React.memo(({ count, onClick }) => {
	return (
		<MobileCartButtonWrap onClick={onClick}>
			<CartButton id="mobile-cart-btn" count={count}>
				<ButtonIcon icon="cart" />
			</CartButton>
		</MobileCartButtonWrap>
	)

})

MobileCartButton.displayName = 'MobileCartButton'
export default MobileCartButton
