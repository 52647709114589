export const SET_ADDITIVES_GROUP_CATEGORY_ID = 'SET_ADDITIVES_GROUP_CATEGORY_ID'
export const SET_ADDITIVES_GROUP_IS_SELECTED_ALL_TOPPINGS = 'SET_ADDITIVES_GROUP_IS_SELECTED_ALL_TOPPINGS'
export const SET_ADDITIVES_GROUP_TYPE_BUSINESS_SCOPE = 'SET_ADDITIVES_GROUP_TYPE_BUSINESS_SCOPE'
export const SET_ADDITIVES_GROUP_TOPPINGS = 'SET_ADDITIVES_GROUP_TOPPINGS'
export const SET_ADDITIVES_GROUP_TOPPINGS_TOTAL_LENGTH = 'SET_ADDITIVES_GROUP_TOPPINGS_TOTAL_LENGTH'
export const APPEND_ADDITIVES_GROUP_TOPPINGS = 'APPEND_ADDITIVES_GROUP_TOPPINGS'
export const SET_ADDITIVES_GROUP_SELECTED_TOPPINGS = 'SET_ADDITIVES_GROUP_SELECTED_TOPPINGS'
export const REMOVE_ADDITIVES_GROUP_SELECTED_TOPPING = 'REMOVE_ADDITIVES_GROUP_SELECTED_TOPPING'
export const APPEND_ADDITIVES_GROUP_SELECTED_TOPPING = 'APPEND_ADDITIVES_GROUP_SELECTED_TOPPING'
export const SET_ADDITIVES_GROUP ='SET_ADDITIVES_GROUP'
export const SET_ADDITIVES_GROUPS = 'SET_ADDITIVES_GROUPS'
export const APPEND_ADDITIVES_GROUPS = 'APPEND_ADDITIVES_GROUPS'
export const SET_ADDITIVES_GROUPS_TOTAL_LENGTH = 'SET_ADDITIVES_GROUPS_TOTAL_LENGTH'
export const SET_ADDITIVES_GROUPS_ORDER_BY_ASC = 'SET_ADDITIVES_GROUPS_ORDER_BY_ASC'
export const UPDATE_ADDITIVES_GROUPS_ORDER = 'UPDATE_ADDITIVES_GROUPS_ORDER'
export const SET_ADDITIVES_GROUP_TO_EDIT = 'SET_ADDITIVES_GROUP_TO_EDIT'
export const SET_ADDITIVES_GROUP_SELECTED_PRODUCTS = 'SET_ADDITIVES_GROUP_SELECTED_PRODUCTS'
export const APPEND_ADDITIVES_GROUP_SELECTED_PRODUCT = 'APPEND_ADDITIVES_GROUP_SELECTED_PRODUCT'
export const REMOVE_ADDITIVES_GROUP_SELECTED_PRODUCT = 'REMOVE_ADDITIVES_GROUP_SELECTED_PRODUCT'
export const SET_ADDITIVES_GROUP_PRODUCTS ='SET_ADDITIVES_GROUP_PRODUCTS'
export const SET_ADDITIVES_GROUP_PRODUCTS_TOTAL_LENGTH ='SET_ADDITIVES_GROUP_PRODUCTS_TOTAL_LENGTH'
export const APPEND_ADDITIVES_GROUP_PRODUCTS ='APPEND_ADDITIVES_GROUP_PRODUCTS'
