import React from 'react'
import { HeaderCompanyTitle, HeaderCompanyWrap, HeaderContent, HeaderText, HeaderWrap, InvoiceContent, InvoiceDataWrap, InvoiceFooterWrap, InvoiceInfoText, InvoiceInfoWrap, InvoiceOrderCount, InvoiceOrderHeader, InvoiceOrderHeaderTitle, InvoiceSheetContainer, InvoiceSheetWrap, InvoiceTitle } from './index.styled'
import { useSelector } from 'react-redux'
import InvoiceOrderProductsForPrint from './order/orderProducts'
import InvoiceOrderTotalsForPrint from './order/orderTotals'
import { getNeededDateFormat } from '../../../../../../helper'

const InvoiceSheetForPrint = React.memo(() => {
	const invoice = useSelector(store => store.invoice.selectedInvoice)
	const billingAddress = invoice?.customer?.billingAddress
	const company = invoice?.company
	const order = invoice?.order
	const date = getNeededDateFormat(invoice?.createDate)
	const emailToDisplay = company?.ordersEmail ? company.ordersEmail : company?.ownerEmail

	return (
		<InvoiceSheetContainer>
			<InvoiceSheetWrap id="invoice-sheet-for-print">
				{invoice && <>
					<HeaderCompanyWrap>
						<HeaderCompanyTitle>
							{company.name} | {company.street} {company.house} | {company.zipCode} {company.city}
						</HeaderCompanyTitle>
					</HeaderCompanyWrap>
					<HeaderWrap>
						<HeaderContent>
							<HeaderText>{billingAddress.companyName}</HeaderText>
							<HeaderText>{billingAddress.name}</HeaderText>
							<HeaderText>{billingAddress.street} {billingAddress.streetNumber}</HeaderText>
							<HeaderText>{billingAddress.postalCode} {billingAddress.city}</HeaderText>
						</HeaderContent>
						<HeaderContent>
							<HeaderText>Rechnung: #{invoice.invoiceNumber}</HeaderText>
							<HeaderText>Bestellung: {order.orderDayCounter}</HeaderText>
							<HeaderText>Datum: {date}</HeaderText>
							<HeaderText>Kundennummer: {invoice.customer.customerNumber}</HeaderText>
						</HeaderContent>
					</HeaderWrap>
					<InvoiceContent>
						<InvoiceTitle>Rechnung</InvoiceTitle>
						<InvoiceInfoWrap>
							<InvoiceInfoText>Sehr geehrter (-n) {billingAddress.name},</InvoiceInfoText>
							<InvoiceInfoText>Vielen Dank für Ihr Vertrauen in unsere Dienstleistungen. Wir hoffen, dass Sie zufrieden sind und würden uns freuen, wieder von Ihnen zu hören.</InvoiceInfoText>
						</InvoiceInfoWrap>
						<InvoiceOrderHeader>
							<InvoiceOrderHeaderTitle>Bezeichnung</InvoiceOrderHeaderTitle>
							<InvoiceOrderHeaderTitle>Gesamtpreis</InvoiceOrderHeaderTitle>
						</InvoiceOrderHeader>
						<InvoiceOrderProductsForPrint order={order} />
						<InvoiceOrderTotalsForPrint order={order} />
						<InvoiceOrderCount><span className="bold">Anzahl Teile:</span> <span>{order?.purchaseItems?.length}</span></InvoiceOrderCount>
						<InvoiceInfoWrap className="big-gap">
							<InvoiceInfoText>Dies ist eine elektronische Rechnung. Falls Sie eine Papierrechnung benötigen, geben Sie uns dies bitte bekannt.</InvoiceInfoText>
							<InvoiceInfoText>Der Rechnungsbetrag ist sofort nach Rechnungseingang zu begleichen. Bitte überweisen den Rechnungsbetrag auf das Konto:</InvoiceInfoText>
						</InvoiceInfoWrap>
						<InvoiceDataWrap>
							<div>
								<InvoiceInfoText>Inhaber:</InvoiceInfoText>
								<InvoiceInfoText>{company.name}</InvoiceInfoText>
							</div>
							<div>
								<InvoiceInfoText>BIC:</InvoiceInfoText>
								<InvoiceInfoText>{invoice.iban.bic}</InvoiceInfoText>
							</div>
							<div>
								<InvoiceInfoText>IBAN:</InvoiceInfoText>
								<InvoiceInfoText>{invoice.iban.number}</InvoiceInfoText>
							</div>
						</InvoiceDataWrap>
						<InvoiceInfoWrap className="small-padding">
							<InvoiceInfoText className="footer">Mit freundlichen Grüßen,</InvoiceInfoText>
						</InvoiceInfoWrap>
						<InvoiceInfoWrap className="small-padding">
							<InvoiceInfoText className="footer">{company.name}</InvoiceInfoText>
						</InvoiceInfoWrap>
						<InvoiceFooterWrap>
							<InvoiceInfoText className="footer">{company.name}</InvoiceInfoText>
							<InvoiceInfoText className="footer">{company.street} {company.house} {company.zipCode} {company.city} {company.countryName}</InvoiceInfoText>
							<InvoiceInfoText className="footer">Tel:{company.phonePrefix} {company.phoneNumber} {emailToDisplay}</InvoiceInfoText>
							<InvoiceInfoText className="footer">St.-Nr.: {invoice.company.taxId} IBAN: {invoice.iban.number}</InvoiceInfoText>
						</InvoiceFooterWrap>
					</InvoiceContent>
				</>}
			</InvoiceSheetWrap>
		</InvoiceSheetContainer>
	)
})

InvoiceSheetForPrint.displayName = 'InvoiceSheetForPrint'
export default InvoiceSheetForPrint
