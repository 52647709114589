import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const PayPalNotificationWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5em;

	&.contacts {
		gap: 1.875em;
	}

	@media screen and (max-width: ${theme.point720}) {
		> label {
			width: 100%;
		}
	}
`
PayPalNotificationWrap.displayName = 'PayPalNotificationWrap'

export const PayPalNotificationText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.payPalWarningModal.title};
	text-align: center;
	font-size: 1.25em;
	font-style: normal;
	font-weight: 500;
`
PayPalNotificationText.displayName = 'PayPalNotificationText'

export const PayPalNotificationContent = styled.div`
	display: flex;
	gap: 1.25em;

	> svg {
		width: 10.5em;
		height: 10.5em;
		flex-shrink: 0;
	}

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
		align-items: center;
	}
`
PayPalNotificationContent.displayName = 'PayPalNotificationContent'

export const PayPalNotificationInfo = styled.div`
	display: flex;
	gap: 0.75em;
	width: 20.3em;

	> svg {
		width: 2.12em;
		height: 3.5em;
		flex-shrink: 0;
		fill: ${colorTheme.payPalWarningModal.icon};
	}
`
PayPalNotificationInfo.displayName = 'PayPalNotificationInfo'

export const PayPalNotificationContacts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.43em;
`
PayPalNotificationContacts.displayName = 'PayPalNotificationContacts'

export const PayPalNotificationWatsappContacts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.63em;
`
PayPalNotificationWatsappContacts.displayName = 'PayPalNotificationWatsappContacts'

export const PayPalNotificationSub = styled.div`
	font-size: 1em;
	font-weight: 400;
	font-family: ${theme.fontRobotoMedium};
	text-align: left;
	color: ${colorTheme.payPalWarningModal.text};
`
PayPalNotificationSub.displayName = 'PayPalNotificationSub'

export const PayPalNotificationLink = styled.div`
	font-size: 1.25em;
	font-weight: 500;
	text-decoration-line: underline;
	cursor: pointer;
	color: ${colorTheme.payPalWarningModal.link};
	font-family: ${theme.fontRobotoMedium};
	text-align: left;

	@media screen and (max-width: ${theme.point720}) {
		&.center {
			text-align: center;
			margin-bottom: 0.5em;
		}
	}
`
PayPalNotificationLink.displayName = 'PayPalNotificationLink'

export const PayPalNotificationTitle = styled.div`
	color: ${colorTheme.payPalWarningModal.title};
	font-size: 1.5em;
	font-weight: 600;
	text-align: left;
`
PayPalNotificationTitle.displayName = 'PayPalNotificationTitle'

export const ContactsTitle = styled.div`
	color: ${colorTheme.payPalWarningModal.contacts.title};
	font-size: 1.5em;
	font-weight: 600;
`
ContactsTitle.displayName = 'ContactsTitle'


