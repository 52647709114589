import React, { useCallback, useEffect } from 'react'
import { InView, useInView } from 'react-intersection-observer'
import MainIcon from '../../../img/svg/main'
import { PER_LOAD } from '../../../redux/thunk/constants'
import TableHeaderTooltip from './TableHeaderTooltip'
import { Table, TableBody, TableWrapper } from './style/table/index.styled'
import { TableHead, TableHeadTr, TableTh, TableThInner } from './style/table/head/index.styled'
import { TableLoader } from './style/table/loader/index.styled'

const CustomTable = React.memo(({
																	headers = [], onLoad, totalLength = 0, currentLength = totalLength,
																	params = [], perLoad = PER_LOAD, children, id, isScrollBody, className, loading
																}) => {
	// eslint-disable-next-line
	const { ref, inView } = useInView({
		threshold: 0
	})

	const loadTable = useCallback(() => {
		if (onLoad) onLoad(Math.ceil(currentLength / perLoad), perLoad)
	}, [currentLength, perLoad, onLoad])

	useEffect(() => {
		// eslint-disable-next-line no-mixed-operators
		if ((totalLength === currentLength && currentLength === 0) || (inView && totalLength > currentLength)) {
			loadTable()
		}
	}, [totalLength, currentLength, ...params]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleInViewChange = useCallback((inView) => {
		if (inView && totalLength > currentLength) {
			loadTable()
		}
	}, [totalLength, currentLength, loadTable])

	return (
		<>
			<TableWrapper isScrollBody={isScrollBody}>
				<Table id={id}>
					<TableHead>
						<TableHeadTr isScrollBody={isScrollBody}>
							{headers?.map((head, i) =>
								head?.tooltip ?
									<TableTh key={i} className={head.className} width={head.width} onClick={head.onClick}>
										<TableHeaderTooltip isShow={head?.tooltip?.isShow} width={head?.tooltip?.width} text={head?.tooltip?.text} position={head?.tooltip?.position}>
											{(head.title && head.image) ?
												<TableThInner className={head.className}>{head.title} <MainIcon icon={head.image} className={head.image} /> </TableThInner> :
												head.image ? <MainIcon icon={head.image} /> : head.title}
										</TableHeaderTooltip>
									</TableTh> :
									<TableTh key={i} className={head.className} width={head.width} onClick={head.onClick}>
										{(head.title && head.image) ?
											<TableThInner className={head.className}>{head.title} <MainIcon icon={head.image} /> </TableThInner> :
											head.image ? <MainIcon icon={head.image} /> : head.title}
									</TableTh>)}
						</TableHeadTr>
					</TableHead>
					<TableBody className={className}>
						{children}
					</TableBody>
				</Table>
			</TableWrapper>
			<InView as={TableLoader} onChange={handleInViewChange}>
				<span id="total-count" style={{ opacity: 0 }} ref={ref}>{loading ? 'loading...' : totalLength}</span>
			</InView>
		</>
	)
})

CustomTable.displayName = 'CustomTable'
export default CustomTable
