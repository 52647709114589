import React from 'react'
import { SelectLabel, SelectWrapper } from './index.styled'
import { InputError } from '../TextInput/index.styled'

const SelectInput = React.memo(({
	register,
	options,
	name,
	defaultValue,
	errors = {},
	errorParser,
	children,
	testId,
	tabIndex,
	color,
	size,

	watch,
	onChange,
	onBlur = () => {
	},
	onFocus = () => {
	},
}) => {
	const value = typeof watch === 'function' ? watch?.(name) : watch
	const errorMessage = errors && (errorParser ? errorParser(errors) : errors[name])?.message
	const layout = (errorMessage ? 'error ' : '') + color + ' ' + size + ((value === '') ? ' defaultOption' : '')

	return (
		<SelectWrapper className={layout}>
			<InputError className={layout}>{errorMessage}</InputError>
			<SelectLabel className={layout}>
				<select {...register(name, { ...options, onChange, onBlur })}
								data-testid={testId ?? null}
								id={name}
								defaultValue={defaultValue ? defaultValue : ''}
								onFocus={onFocus}
								className={layout}
								tabIndex={tabIndex}>
					{children}
				</select>
				<svg className="arrow" width="20" height="11" viewBox="0 0 20 11" xmlns="http://www.w3.org/2000/svg">
					<path fillRule="evenodd" clipRule="evenodd" d="M9.99095 11C9.64587 11 9.30302 10.8734 9.0392 10.618L0.396567 2.23589C-0.131074 1.72514 -0.132186 0.897383 0.393228 0.385538C0.917529 -0.126307 1.77022 -0.128509 2.29674 0.382236L9.99095 7.84417L17.7041 0.381137C18.2306 -0.128507 19.0833 -0.127406 19.6087 0.386641C20.1319 0.898486 20.1308 1.72735 19.6032 2.23809L10.9383 10.6191C10.6767 10.8734 10.3338 11 9.99095 11Z" />
				</svg>
			</SelectLabel>
		</SelectWrapper>
	)
})

SelectInput.displayName = 'SelectInput'
export default SelectInput
