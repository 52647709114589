import { APPEND_INVOICES, SET_INVOICES, SET_INVOICES_TOTAL_LENGTH, SET_SELECTED_INVOICE, SET_SHOW_INVOICE_BILLING_ADDRESS_MODAL, SET_SHOW_INVOICE_DATA_MODAL, SET_SHOW_INVOICE_OPTIONS_MODAL, SET_SHOW_INVOICE_RESEND_EMAIL_INFO_MODAL, SET_SHOW_INVOICE_RESEND_EMAIL_MODAL, SET_UNPAID_INVOICES_COUNTER } from '../../actionTypes/invoice'

const initialState = {
	invoices: [],
	invoicesTotalLength: 0,
	unpaidInvoicesCounter: 0,
	selectedInvoice: null,
	modal: {
		showOptions: false,
		showInvoiceData: false,
		showBillingAddress: false,
		showInfoResendEmail: false,
		showResendEmail: false,
	},
}

export function invoiceReducer (state = initialState, action) {
	switch (action.type) {
		case SET_INVOICES:
			return {
				...state,
				invoices: action.value,
			}
		case APPEND_INVOICES:
			return {
				...state,
				invoices: state.invoices.concat(...action.value),
			}
		case SET_INVOICES_TOTAL_LENGTH:
			return {
				...state,
				invoicesTotalLength: action.value,
			}
		case SET_UNPAID_INVOICES_COUNTER:
			return {
				...state,
				unpaidInvoicesCounter: action.value,
			}
		case SET_SELECTED_INVOICE:
			return {
				...state,
				selectedInvoice: action.value,
			}
		case SET_SHOW_INVOICE_OPTIONS_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showOptions: action.value,
				},
			}
		case SET_SHOW_INVOICE_DATA_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showInvoiceData: action.value,
				},
			}
		case SET_SHOW_INVOICE_BILLING_ADDRESS_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showBillingAddress: action.value,
				},
			}
		case SET_SHOW_INVOICE_RESEND_EMAIL_INFO_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showInfoResendEmail: action.value,
				},
			}
		case SET_SHOW_INVOICE_RESEND_EMAIL_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					showResendEmail: action.value,
				},
			}
		default:
			return state
	}
}
