import React from 'react'
import { DropDownButtonWrap } from './index.styled'

const DropDownButton = ({ text, active, onClick }) => {
	return (
		<DropDownButtonWrap className={active ? 'active' : ''}
												onClick={onClick}>
			<svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M8.98271 0.00585938C9.2924 0.00585938 9.6001 0.120859 9.83687 0.352859L17.5933 7.96786C18.0668 8.43186 18.0678 9.18386 17.5963 9.64886C17.1257 10.1139 16.3605 10.1159 15.8879 9.65186L8.98271 2.87286L2.06049 9.65286C1.58795 10.1159 0.822698 10.1149 0.35116 9.64786C-0.11838 9.18286 -0.117381 8.42986 0.356155 7.96586L8.13254 0.351859C8.36731 0.120859 8.67501 0.00585938 8.98271 0.00585938Z" fill="#383A50" />
			</svg>
			{text}
		</DropDownButtonWrap>
	)
}

DropDownButton.displayName = 'DropDownButton'
export default DropDownButton
