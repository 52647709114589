import * as ibantools from 'ibantools'

export const handleValidateIban = (iban) => {
	return ibantools.isValidIBAN(iban)
}

export const handleValidateBic = (bic) => {
	return ibantools.isValidBIC(bic)
}

export const validateBic = (value, isEnabled) => {
	if (!isEnabled) return true
	if (value && isEnabled) {
		return handleValidateBic(value)
	} else {
		return true
	}
}
export const validateIban = (value, isEnabled) => {
	if (!isEnabled) return true
	if (value && isEnabled) {
		return handleValidateIban(value)
	} else {
		return true
	}
}
