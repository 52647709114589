import React from 'react'
import sprite from './index.svg'

const HeaderIcon = React.memo(({ icon }) => {
	return (
		<svg>
			<use width="100%" height="100%" href={sprite + '#' + icon} />
		</svg>
	)
})

HeaderIcon.displayName = 'HeaderIcon'
export default HeaderIcon
