import phoneInstance from './../instance'

const getDesktopSessions = (callbackSuccess, callbackError) => {
  return dispatch => {
    phoneInstance.get('/desktop/sessions')
      .then(res => {
        if (res) typeof callbackSuccess === 'function' && callbackSuccess(res)
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }

}

export default getDesktopSessions
