import styled from 'styled-components'
import colors from '../../../../style/colors'
import { theme } from '../../../../style/theme'
export const InputDescription = styled.div`
  position: relative;

input {
  background: white;
  border-radius: 0.55em;
  display: flex;
  align-items: center;
  font-family: ${theme.fontRobotoMedium};
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.18em;
  padding: 0.23em 1.22em;
  text-align: left;
  border: 1px solid ${colors.gray_light};
  min-height: 3.4em;
  margin-bottom: 0.22em;
  cursor: pointer;
  position: relative;
  width: 100%;
  gap: 0.6em;

  &::placeholder {
    color: ${colors.gray_light};
    opacity: 0.5;
  }
}

input:focus {
  border-color: ${colors.blue};
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.22);
}

input:active {
  border-color: ${colors.blue};
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.22);
}

`
InputDescription.displayName = 'InputDescription'
