import { getGiftCouponsPayment } from '../../../helper/getGiftCouponsPayment'
import { addItemAndCouponPrice, roundPrice } from '../../../helper'

export const calculateGiftCouponsBalance = (giftCoupons, priceWithDiscount) => {
	let total = priceWithDiscount
	return giftCoupons.reduce((res, el) => {
		const { balance } = el
		if (total > 0) {
			const delta = total - el.balance
			if (delta <= 0) {
				const curTotal = total
				total = 0
				return [...res, { ...el, cartBalance: curTotal }]
			} else {
				total = delta
				return [...res, { ...el, cartBalance: balance }]
			}
		} else {
			return [...res, { ...el, cartBalance: 0 }]
		}
	}, [])
}

export const createOrderData = ({ order, orderType, remark, preOrderDate,
																customer, storedCustomer,
																paymentId, paymentMethod, paymentLink,
																tips,
																deferred,
																totalPrice,
																giftCoupons, priceWithGiftCoupons,
																resultItems,
																discountInCurrency,
																extraDiscountInCurrency,
																couponDiscountInCurrency,
																modalLoginUsername }) => {
	const resultTotalPrice = roundPrice(totalPrice + tips)
	const paymentWithGiftCoupon = getGiftCouponsPayment(giftCoupons)
	const payments = paymentMethod === 'GIFT_COUPON' ? paymentWithGiftCoupon :
		[{ id: paymentId,
			link: paymentLink,
			method: paymentMethod,
			total: roundPrice(priceWithGiftCoupons + tips), deferred },
			...paymentWithGiftCoupon]

	return {
		...order,
		orderType,
		totalPrice: resultTotalPrice,
		totalDiscount: discountInCurrency,
		paymentMethod,
		payments,
		preOrder: preOrderDate,
		customer:  customer || (storedCustomer?.name ? { name: storedCustomer?.name } : null),
		deliveryCost: { cost: 0 },
		items: addItemAndCouponPrice(resultItems, extraDiscountInCurrency, couponDiscountInCurrency),
		remark,
		tips,
		processedBy: modalLoginUsername,
		module: { moduleType: 'CASH_DESK' }
	}
}

