import { SET_TSE_CONFIG, SET_TSE_FON_DATA, SET_TSE_NOTIFICATION, UPDATE_TSE_DISABLE_NOTIFICATION, SET_TSE_FON_ERROR, SET_TSE_NULL_ORDERS } from '../../actionTypes/tse'

export const setTseConfig = value => ({ type: SET_TSE_CONFIG, value })

export const setTseNotification = value => ({ type: SET_TSE_NOTIFICATION, value })

export const updateTseDisableNotification = value => ({ type: UPDATE_TSE_DISABLE_NOTIFICATION, value })

export const setTseFonData = value => ({ type: SET_TSE_FON_DATA, value })

export const setTseFonError = value => ({ type: SET_TSE_FON_ERROR, value })

export const setTseNullOrders = value => ({ type: SET_TSE_NULL_ORDERS, value })
