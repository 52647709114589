import { ADD_TABLE_CART_NEW_ORDER_ITEM_SUBITEM, ADD_TABLE_CART_NEW_ORDER_REMARK_TO_ITEM, ADD_TABLE_CART_ORDER_ITEM, ADD_TABLE_CART_REMARK_ORDER_TO_ITEM, RE_INITIALIZE_TABLE_CART, REMOVE_TABLE_CART_ORDER_ITEM, REMOVE_TABLE_CART_ORDER_ITEM_REMARK, REMOVE_TABLE_CART_ORDER_ITEM_SUBITEM, SET_TABLE_CART_ITEM_DISH_COURSE_INDEX, SET_TABLE_CART_ITEM_FOCUS_INDEX, SET_TABLE_CART_LOADING,  SET_TABLE_CART_LOADING_ITEM_ID, SET_TABLE_CART_MODAL_CUSTOM_PRODUCT_IS_OPEN, SET_TABLE_CART_NEW_ORDER, SET_TABLE_CART_ORDER_FOCUS_INDEX, SET_TABLE_CART_ORDERS, SET_TABLE_CART_SELECTED_DISH_COURSE_INDEX, SET_TABLE_CART_SHOW_DISH_COURSE_MODAL, SET_TABLE_CART_SHOW_MOBILE_CART, SET_TABLE_CART_SPLITS, UPDATE_TABLE_CART_ORDER_ITEM } from '../../actionTypes/tableCart'
import { calcNewOrderTotalPrices } from './helper'
import { addSubItem, clone, deleteItem, recalculateSubItems } from '../../../helper'
import { v4 as uuidv4 } from 'uuid'

const initialState = {
	orders: [],
	orderFocusIndex: null,
	itemFocusIndex: null,
	splits: [],
	loading: false,
	newOrder: null,
	selectedDishCourseIndex: 0,
	showDishCourseModal: false,
	itemDishCourseIndex: 0,
	modalCustomProductIsOpen: false,
	showMobileCart: false,
	loadingItemId: '',
}

export function tableCartReducer(state = initialState, action) {
	switch (action.type) {
		case RE_INITIALIZE_TABLE_CART:
			return initialState
		case SET_TABLE_CART_ORDERS:
			return {
				...state,
				orders: action.value
			}
		case SET_TABLE_CART_NEW_ORDER:
			return {
				...state,
				newOrder: calcNewOrderTotalPrices(action.value)
			}
		case ADD_TABLE_CART_ORDER_ITEM:
			recalculateSubItems(action.value)
			const item = { ...action.value, itemPositionId: uuidv4() }
			item.items?.forEach(subItem => {
				subItem.itemPositionId = uuidv4()
				subItem.items?.forEach(subSubItem => subSubItem.itemPositionId = uuidv4())
			})

			if (state.newOrder?.items?.length) {
				state.newOrder.items = state.newOrder.items.concat(item)
			} else {
				state.newOrder = { status: 'BEING_PREPARED', items: [item] }
			}

			return {
				...state,
				newOrder: calcNewOrderTotalPrices(state.newOrder)
			}

		case ADD_TABLE_CART_NEW_ORDER_ITEM_SUBITEM:
			const orderCopy3 = clone(state.newOrder)
			addSubItem(orderCopy3.items[action.value.itemIndex], { ...action.value.item, itemPositionId: uuidv4() })
			recalculateSubItems(orderCopy3.items[action.value.itemIndex])
			return {
				...state,
				newOrder: calcNewOrderTotalPrices(orderCopy3)
			}
		case REMOVE_TABLE_CART_ORDER_ITEM:
			const orderCopy2 = clone(state.newOrder)
			orderCopy2.items = deleteItem(orderCopy2.items, action.value.itemIndex)

			return {
				...state,
				newOrder: orderCopy2.items.length === 0 ? null : calcNewOrderTotalPrices(orderCopy2)
			}
		case REMOVE_TABLE_CART_ORDER_ITEM_SUBITEM:
			const orderCopy4 = clone(state.newOrder)
			orderCopy4.items[action.value.itemIndex].items?.splice(action.value.subItemIndex, 1)
			recalculateSubItems(orderCopy4.items[action.value.itemIndex])
			return {
				...state,
				newOrder: calcNewOrderTotalPrices(orderCopy4)
			}
		case UPDATE_TABLE_CART_ORDER_ITEM:
			const ordersCopy7 = clone(state.newOrder)
			const itemCopy = { ...action.value.item }
			itemCopy.items?.forEach(subItem => {
				subItem.itemPositionId = subItem.itemPositionId || uuidv4()
				subItem.items?.forEach(subSubItem => subSubItem.itemPositionId = subSubItem.itemPositionId || uuidv4())
			})

			ordersCopy7.items[action.value.itemIndex] = recalculateSubItems({ ...itemCopy })

			return {
				...state,
				newOrder : calcNewOrderTotalPrices(ordersCopy7)
			}
		case ADD_TABLE_CART_NEW_ORDER_REMARK_TO_ITEM:
			const ordersCopy8 = clone(state.newOrder)
			ordersCopy8.items[action.value.itemFocusIndex].remark = action.value.comment
			return {
				...state,
				newOrder: ordersCopy8
			}
		case SET_TABLE_CART_ITEM_FOCUS_INDEX:
			return {
				...state,
				itemFocusIndex: action.value
			}
		case SET_TABLE_CART_ORDER_FOCUS_INDEX:
			return {
				...state,
				orderFocusIndex: action.value
			}
		case SET_TABLE_CART_SPLITS:
			return {
				...state,
				splits: action.value
			}
		case REMOVE_TABLE_CART_ORDER_ITEM_REMARK:
			const ordersCopy5 = clone(state.newOrder)
			ordersCopy5.items[action.value.itemIndex].remark = null
			return {
				...state,
				newOrder: ordersCopy5
			}
		case ADD_TABLE_CART_REMARK_ORDER_TO_ITEM:
			const ordersCopy6 = clone(state.orders)
			ordersCopy6[action.value.orderFocusIndex].items[action.value.itemFocusIndex].remark = action.value.comment
			return {
				...state,
				orders: ordersCopy6
			}
		case SET_TABLE_CART_LOADING:
			return {
				...state,
				loading: action.value
			}
		case SET_TABLE_CART_SELECTED_DISH_COURSE_INDEX:
			return {
				...state,
				selectedDishCourseIndex: action.value
			}
		case SET_TABLE_CART_SHOW_DISH_COURSE_MODAL:
			return {
				...state,
				showDishCourseModal: action.value
			}
		case SET_TABLE_CART_ITEM_DISH_COURSE_INDEX:
			return {
				...state,
				itemDishCourseIndex: action.value
			}
		case SET_TABLE_CART_MODAL_CUSTOM_PRODUCT_IS_OPEN:
			return {
				...state,
				modalCustomProductIsOpen: action.value
			}
		case SET_TABLE_CART_SHOW_MOBILE_CART:
			return {
				...state,
				showMobileCart: action.value
			}
		case  SET_TABLE_CART_LOADING_ITEM_ID:
			return {
				...state,
				loadingItemId: action.value
			}
		default:
			return state
	}
}
