import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CustomInfoModal from '../../Elements/modal/CustomInfoModal'
import CheckBoxInputControlled from '../../Elements/inputs/CheckboxInput/controlled'
import Button from '../../Elements/buttons/Button'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import CheckBoxInputControlledGray from '../../Elements/inputs/CheckBoxInputGray/controlled'
import { updatePayPalNotificationThunk } from '../../../redux/thunk/payment'
import { selectCompanyData, selectHasAnyGroup, selectUsername } from '../../../redux/selector/system'
import { useNavigate } from 'react-router-dom'
import { FINAL_DATE } from '../PayPalDeadlineWarningModal/constants'
import { PayPalNotificationWrap } from '../PayPalDeadlineWarningModal/index.styled'

const EVENT_Z_INDEX = 53

const PayPalWarningModal = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isMobile = useWindowBreakPoint(theme.point720)
	const name = useSelector(selectCompanyData('name'))
	const paypalNotification = useSelector(store => store.payment?.paypalNotification)
	const username = useSelector(selectUsername)
	const hasRoleOwner = useSelector(selectHasAnyGroup(['OWNER']))

	const [openModal, setOpenModal] = useState(false)
	const [disabledModal, setDisabledModal] = useState(false)

	const localStorageModalDisabled = JSON.parse(window.localStorage.getItem('payPalWarningModalDisabled'))
	const localStorageModalEnabledByUser = !localStorageModalDisabled?.[username]
	const shouldShowModal = paypalNotification && !paypalNotification?.newConditionsWarningDisabled && localStorageModalEnabledByUser

	const navigate = useNavigate()

	useEffect(() => {
		const now = new Date()
		const finalDate = new Date(FINAL_DATE)
		if (now > finalDate && shouldShowModal && name)
			setOpenModal(true)
		else setOpenModal(false)
	}, [shouldShowModal, name])

	const handleChangeDisabledModal = useCallback(() => {
		setDisabledModal(!disabledModal)
	}, [disabledModal])

	const handleCloseModal = useCallback(() => {
		setOpenModal(false)
		setDisabledModal(false)
	}, [])

	const handleAccept = useCallback(() => {
		if (disabledModal) {
			if (hasRoleOwner)
				dispatch(updatePayPalNotificationThunk({ ...paypalNotification, newConditionsWarningDisabled: disabledModal }))
			else window.localStorage.setItem('payPalWarningModalDisabled', JSON.stringify({ ...localStorageModalDisabled, [username]: disabledModal }))
		}
		handleCloseModal()
	}, [hasRoleOwner, paypalNotification, disabledModal, dispatch, handleCloseModal, localStorageModalDisabled, username])

	const navigateToSettings = useCallback(() => {
		navigate('/settings/payments')
		handleCloseModal()
	}, [navigate, handleCloseModal])

	return (
		<>
			<CustomInfoModal isOpen={openModal}
				title={t('Payments.modal.notification.title')}
				text={t('Payments.modal.notification.deactivationTitle')}
				close={handleCloseModal}
				zIndex={EVENT_Z_INDEX}
				button={<>
					<Button icon="settings-2" onClick={navigateToSettings} text={t('Payments.modal.notification.paymentSettings')} color="gray" />
					<Button icon="checkmark" onClick={handleAccept} tabIndex="1" text="Ok" color="green" keyButton="F2" />
				</>}>
				<PayPalNotificationWrap>
					{!isMobile ? <CheckBoxInputControlled id="accept" label={t('Payments.modal.notification.accept')} className="accept"
																				checked={disabledModal} onChange={handleChangeDisabledModal} />
					: <CheckBoxInputControlledGray label={t('Payments.modal.notification.accept')}
																			 onChange={handleChangeDisabledModal}
																			 isActive={disabledModal} />}
				</PayPalNotificationWrap>
			</CustomInfoModal>
		</>
	)

})

PayPalWarningModal.displayName = 'PayPalWarningModal'
export default PayPalWarningModal
