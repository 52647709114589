import { SET_WEBSHOP_INFO_CONFIG, SET_WEBSHOP_LOGO_CONFIG, SET_WEBSHOP_LOGO_CLOUDINARY_DATA, SET_WEBSHOP_IMAGE_CONDITIONS, SET_WEBSHOP_IMAGE_ERROR_MODAL, UPDATE_WEBSHOP_ORDER_TYPE_STATUSES, SET_WEBSHOP_SHOW_ADVICE_WINDOW } from '../../actionTypes/webshopinfo'

export const setWebshopInfoConfig = value => ({ type: SET_WEBSHOP_INFO_CONFIG, value })

export const setWebshopLogoConfig = value => ({ type: SET_WEBSHOP_LOGO_CONFIG, value })

export const setWebshopLogoCloudinaryData = value => ({ type: SET_WEBSHOP_LOGO_CLOUDINARY_DATA, value })

export const setWebshopImageConditions = value => ({ type: SET_WEBSHOP_IMAGE_CONDITIONS, value })

export const setWebshopImageErrorModal = (value) => ({ type: SET_WEBSHOP_IMAGE_ERROR_MODAL, value })

export const updateWebshopOrderTypeWorkingStatuses = (value) => ({ type: UPDATE_WEBSHOP_ORDER_TYPE_STATUSES, value })

export const setWebshopShowAdviceWindow = (value) => ({ type: SET_WEBSHOP_SHOW_ADVICE_WINDOW, value })
