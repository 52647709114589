export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_CUSTOMER_DELIVERY_COST = 'SET_CUSTOMER_DELIVERY_COST'
export const SET_CUSTOMER_GEO_DATA = 'SET_CUSTOMER_GEO_DATA'
export const SET_AUTOCOMPLETE_GEO = 'SET_AUTOCOMPLETE_GEO'
export const SET_CUSTOMERS_BY_NUMBER = 'SET_CUSTOMERS_BY_NUMBER'
export const SET_CUSTOMERS_BY_NAME = 'SET_CUSTOMERS_BY_NAME'
export const SET_CUSTOMERS_BY_PHONE = 'SET_CUSTOMERS_BY_PHONE'
export const SET_CUSTOMERS_BY_EMAIL = 'SET_CUSTOMERS_BY_EMAIL'
export const SET_CUSTOMERS_BY_COMPANY_NAME = 'SET_CUSTOMERS_BY_COMPANY_NAME'
export const SET_CUSTOMER_MODAL = 'SET_CUSTOMER_MODAL'
export const SET_CUSTOMER_EVENT = 'SET_CUSTOMER_EVENT'
export const SET_CUSTOMER_CALLS = 'SET_CUSTOMER_CALLS'
export const SET_CUSTOMER_INCOMING_CALL = 'SET_CUSTOMER_INCOMING_CALL'
export const SET_CUSTOMER_ADDRESS_IS_NOT_FULL = 'SET_CUSTOMER_ADDRESS_IS_NOT_FULL'
export const SET_CUSTOMER_GEO_DATA_LOADING = 'SET_CUSTOMER_GEO_DATA_LOADING'
