export const setCancelReasonOptions = () => [
	{ value: 'WRONG_REASON', label: 'order.reason_cancel.wrong' },
	{ value: 'CLIENT_REASON', label: 'order.reason_cancel.client' },
	{ value: 'OTHER', label: 'order.reason_cancel.other' },
]

export const setCancelReasonFoodoraOptions = () => [
	{ value: 'LATE_DELIVERY', label: 'order.reason_cancel.late' },
	{ value: 'WRONG_ORDER_ITEMS_DELIVERED', label: 'order.reason_cancel.wrong' },
	{ value: 'UNABLE_TO_FIND', label: 'order.reason_cancel.not_found' },
	{ value: 'UNABLE_TO_PAY', label: 'order.reason_cancel.not_paid' },
	{ value: 'BAD_WEATHER', label: 'order.reason_cancel.bad_weather' },
]
