import React from 'react'
import MainIcon from '../../../../../../img/svg/main'
import {
	SkeletonWrapper, SkeletonHeader, SkeletonLocation,
	SkeletonInfo, SkeletonInfoElemTop, SkeletonInfoElemBottom, SkeletonInfoElement, SkeletonCategories,
	SkeletonDescription, DescriptionTitle, DescriptionSubTitle, SubTitleElement,
	SkeletonProductWrapper, SkeletonProduct, ProductImg, ProductInfoBlock, ProductButton,
} from './index.styled'

const WebshopSkeleton = React.memo(() => {
	return (
		<SkeletonWrapper data-testid="webshop-skeleton">
			<SkeletonHeader>
				<SkeletonLocation />
			</SkeletonHeader>

			<SkeletonInfo>
				<SkeletonInfoElemTop>
					{(Array(2)).fill().map((el, index) =>
						<SkeletonInfoElement key={'skeleton-info-' + index} />)}
				</SkeletonInfoElemTop>
				<SkeletonInfoElemBottom>
					{(Array(3)).fill().map((el, index) =>
						<SkeletonInfoElement key={'skeleton-info-' + index} />)}
				</SkeletonInfoElemBottom>
			</SkeletonInfo>

			<SkeletonCategories />
			<SkeletonDescription>
				<DescriptionTitle />
				<DescriptionSubTitle>
					{(Array(3)).fill().map((el, index) =>
						<SubTitleElement key={'skeleton-subtitle-' + index} />)}
				</DescriptionSubTitle>
			</SkeletonDescription>

			<SkeletonProductWrapper>
				{(Array(3)).fill().map((el, index) =>
					<SkeletonProduct key={'skeleton-product-' + index}>
						<ProductImg>
							<MainIcon icon="webshop-product-icon" />
						</ProductImg>
						<ProductInfoBlock>
							<SkeletonDescription className="product">
								<DescriptionTitle className="product" />
								<DescriptionSubTitle>
									{(Array(3)).fill().map((el, index) =>
										<SubTitleElement key={'skeleton-subtitle-' + index} className="product" />)}
								</DescriptionSubTitle>
							</SkeletonDescription>
							<ProductButton />
						</ProductInfoBlock>
					</SkeletonProduct>)}
			</SkeletonProductWrapper>
		</SkeletonWrapper>
	)
})

WebshopSkeleton.displayName = 'WebshopSkeleton'
export default WebshopSkeleton
