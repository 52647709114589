import React, { useCallback, useEffect, useState } from 'react'
import { WebshopPreviewElemButton, WebshopPreviewElemTop, WebshopPreviewMain, WebshopPreviewWrap } from './index.styled'
import { onlineShopUIUrl } from '../../../../../urls'
import WebshopSkeleton from './WebshopSkeleton'
import { getShopOrderStatus } from '../../../../../helper/getShopOrderStatus'

const WebshopPreview = React.memo(({ shopConfig, colorMobile = 'LIGHT' }) => {
	const [shopPreviewIsLoaded, setShopPreviewIsLoaded] = useState(false)
	const isEnabled = !!shopConfig?.enabled
	const extraButtonText = shopConfig?.extraButton?.text
	const extraButtonLink = shopConfig?.extraButton?.link
	const orderStatus = getShopOrderStatus(shopConfig)
	const orderDelivery = orderStatus?.DELIVERY
	const orderPickup = orderStatus?.PICKUP

	useEffect(() => {
		if (shopPreviewIsLoaded && shopConfig !== null) {
			setShopPreviewIsLoaded(false)
		}
	}, [JSON.stringify(shopConfig)]) // eslint-disable-line react-hooks/exhaustive-deps

	const hideSkeleton = useCallback(() => {
		setShopPreviewIsLoaded(true)
	}, [])

	return (
		<WebshopPreviewWrap colorMobile={colorMobile}>
			<WebshopPreviewElemTop colorMobile={colorMobile} />
			<WebshopPreviewMain className={!shopPreviewIsLoaded ? 'overflow' : ''}>
				{!shopPreviewIsLoaded ? <WebshopSkeleton /> : null}
				<iframe title="webshop"
								key={shopConfig?.colorTheme + '_' + shopConfig?.layoutTheme +
									'_' + shopConfig?.logoTitle + '_' + shopConfig?.backgroundImage +
									'_' + shopConfig?.productsLayout + '_' + shopConfig?.productsSizesLayout +
									'_isActive=' + isEnabled + '_' + extraButtonText + '_' + extraButtonLink +
									'_' + shopConfig?.logoImage + '_' + shopConfig?.showLogoTitle +
									'_' + shopConfig?.backgroundContainerColor + '_' + shopConfig?.useBackgroundContainer +
									'_' + shopConfig?.moderationStatus +
									'_' + shopConfig?.companyName +
									'_' + orderDelivery + '_' + orderPickup +
									'_' + shopConfig?.headerTitleColor +
									'_' + shopConfig?.disableDarkFilter +
									'_' + shopConfig?.categoryDisplayType +
									'_' + shopConfig?.categoryScrollingType}

								src={`${onlineShopUIUrl}/api/preview?secret=123&slug=/${shopConfig?.shopUrl}/products`}
								onLoad={hideSkeleton} />
			</WebshopPreviewMain>
			<WebshopPreviewElemButton colorMobile={colorMobile} />
		</WebshopPreviewWrap>
	)
})

WebshopPreview.displayName = 'WebshopPreview'
export default WebshopPreview
