import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'
export const MobileCartButtonWrap = styled.div`
	display: none;

	@media screen and (max-width: ${theme.point820}) {
		display: block;
	}
`
MobileCartButtonWrap.displayName = 'MobileCartButtonWrap'

export const CartButton = styled.button`
	font-size: 1rem;
	padding: 0.63rem;
	width: 2.69rem;
	height: 2.69rem;
	position: relative;
	border-radius: 0.75em;
	display: flex;
	justify-content: center;
	gap: 0.63em;
	align-items: center;
	color:  ${colorTheme.cart.mobileCartButton.text};
	cursor: pointer;
	transition: all 0.3s ease;

	svg {
		width: 1.5em;
		height: 1.5em;
		margin-left: -3px;
		fill: ${colorTheme.cart.mobileCartButton.icon};
	}

	&:hover {
		background-color: ${colorTheme.cart.mobileCartButton.btnHover};
		box-shadow: none;

		svg {
			fill: ${colorTheme.cart.mobileCartButton.iconHover};
		}
	}

	&:hover:after {
		background-color: ${colorTheme.cart.mobileCartButton.backgroundHover};
		color: ${colorTheme.cart.mobileCartButton.textHover};
	}

	&:after {
		width: 18px;
		height: 18px;
		display: ${props => props.count > 0 ? 'flex' : 'none'};
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -5px;
		right: -5px;
		background-color: ${colorTheme.cart.mobileCartButton.background};
		font-size: 10px;
		content: '${props => props.count}';
		border-radius: 50%;
	}
`
CartButton.displayName = 'CartButton'
