import restaurantInstance from '../instance'

const fetchTables = (page = 0, size = 0, areaId) => {
  return restaurantInstance.get('/tables'
    + '?page=' + page
    + '&size=' + size
    + (areaId ? '&areaId=' + areaId : ''))
}

export default fetchTables
