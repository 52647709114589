import { APPEND_FOODORA_RESTAURANTS, SET_FOODORA_COPY_DATA, SET_FOODORA_RESTAURANT, SET_FOODORA_RESTAURANTS, SET_FOODORA_RESTAURANTS_SHOW_MODAL, SET_FOODORA_RESTAURANTS_TOTAL_LENGTH } from '../../actionTypes/foodoraRestaurant'

const initialState = {
	restaurants: [],
	restaurantsTotalLength: 0,
	restaurant: null,
	showModal: false,
	copyData: null
}

export function foodoraRestaurantReducer(state = initialState, action) {
	switch (action.type) {
		case SET_FOODORA_RESTAURANTS:
			return {
				...state,
				restaurants: action.value
			}
		case SET_FOODORA_RESTAURANTS_TOTAL_LENGTH:
			return {
				...state,
				restaurantsTotalLength: action.value
			}
		case SET_FOODORA_RESTAURANT:
			return {
				...state,
				restaurant: action.value
			}
		case APPEND_FOODORA_RESTAURANTS:
			return {
				...state,
				restaurants: state.restaurants ? state.restaurants.concat(...action.value) : action.value
			}
		case SET_FOODORA_RESTAURANTS_SHOW_MODAL:
			return {
				...state,
				showModal: action.value
			}
		case SET_FOODORA_COPY_DATA:
			return {
				...state,
				copyData: action.value
			}
		default:
			return state
	}
}
