import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const CategoryConfig = lazyWithRetry(() => import(/* webpackChunkName: "category-config" */'../../component/Category/CategoryConfig'))

const CategoryConfigPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<CategoryConfig />
		</Suspense>
	)
})

CategoryConfigPage.displayName = 'CategoryConfigPage'
export default CategoryConfigPage
