import axios from 'axios'
import requestInterceptor from '../../requestInterceptor'
import responseInterceptor from '../../responseInterceptor'
import { marketingServiceUrl } from '../../../../urls'
import { getLocalStorageAccessToken } from '../../../../helper/clientMonitor'

const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
}

const accessToken = getLocalStorageAccessToken()

if (accessToken) {
  headers.Authorization = `Bearer ${accessToken}`
}

const marketingInstance = axios.create({
  baseURL: marketingServiceUrl,
  headers,
})

marketingInstance.interceptors.request.use(requestInterceptor, error => Promise.reject(error))

marketingInstance.interceptors.response.use(response => response, error => {
  return responseInterceptor(error)
})

export default marketingInstance
