import React from 'react'
import { OrderDayCounterWrapper } from './index.styled'

const OrderDayCounter = React.memo(({ item }) => {
	const isCanceled = item.status?.status === 'CANCELED'
	const orderDayCounterLayout = isCanceled ? 'cancelled' : ''

	return (
		<>
			<OrderDayCounterWrapper className={orderDayCounterLayout}>{item.orderDayCounter}</OrderDayCounterWrapper>
			{isCanceled && <OrderDayCounterWrapper>{item.orderCanceledDayCounter}</OrderDayCounterWrapper>}
		</>
	)
})

OrderDayCounter.displayName = 'OrderDayCounter'
export default OrderDayCounter
