import { batch } from 'react-redux'
import processOrder from '../../../axios/order/processOrder'
import { notifyToast, retrieveErrorFromApi } from '../../../../helper'
import { setAppErrors } from '../../../action/system'
import { setOrderModal, setSelectedUser } from '../../../action/driver-monitor'
import restoreOrder from '../../../axios/order/restoreOrder'
import { setShowOrderClosedErrorModal } from '../../../../redux/action/order'

export const setSelectedUserThunk = (user) => {
  return (dispatch) => {
    batch(() => {
      dispatch(setSelectedUser(user))
      dispatch(setOrderModal(false))
    })
  }
}

export const processUserThunk = (order = null, user = null) => (dispatch) => {
	if (!order) {
		notifyToast({ message: 'No order parameter given' }, 'error')
		return
	}

	return processOrder(order, user)
		.then(() => {
			if (user) notifyToast({ message: user + ' assigned' })
			if (!user) notifyToast({ message: 'Removed' }, 'warning')
		}).catch((error) => {
			const isOrderClosed = error.response.data?.apierror.errorStatusCode === 'order_is_already_closed'
			if (isOrderClosed) {
				dispatch(setShowOrderClosedErrorModal(true))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
	})
}

export const restoreOrderThunk = (orderId) => (dispatch) => {
  return restoreOrder(orderId)
    .then(() => {
      notifyToast({ message: 'Order restored' })
    }).catch((error) => {
      dispatch(setAppErrors(retrieveErrorFromApi(error)))
    })
}

export const processUserThunkQrMap = (order = null, user = null, lat = null, lon = null, callbackError) => (dispatch) => {
	if (!order) {
		notifyToast({ message: 'No order parameter given' }, 'error')
		return
	}

	return processOrder(order, user)
		.then(() => {
			if (user) notifyToast({ message: user + ' assigned' })
			if (!user) notifyToast({ message: 'Removed' }, 'warning')
			window.location.href = 'https://www.google.com/maps?daddr=' + lat + ',' + lon
		}).catch((error) => {
			const isOrderClosed = error.response.data?.apierror.errorStatusCode === 'order_is_already_closed'
			if (isOrderClosed) {
				typeof callbackError === 'function' && callbackError()
				dispatch(setShowOrderClosedErrorModal(true))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}
