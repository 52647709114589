import printerInstance from './instance'

const deletePrinter = (printerId, callbackSuccess, callbackError) => {
  return async dispatch => {
    printerInstance.delete('/printers/' + printerId)
      .then(res => {
        if (res) typeof callbackSuccess === 'function' && callbackSuccess(res)
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }
}

export default deletePrinter
