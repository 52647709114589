import React from 'react'
import { KitchenStatusCircleBlock } from './index.styled'

const KitchenStatusCircle = React.memo(({ status, testId }) => {
	return (
		<KitchenStatusCircleBlock data-testid={testId} className={status} />
	)
})

KitchenStatusCircle.displayName = 'KitchenStatusCircle'
export default KitchenStatusCircle
