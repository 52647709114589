import styled from 'styled-components'
import colors from '../../../../../../style/colors'

export const SkeletonWrapper = styled.div`
  font-size: 1rem;
`
SkeletonWrapper.displayName = 'SkeletonWrapper'

export const SkeletonHeader = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 153px;
	background: ${colors.gray_more_lighter};
	margin-bottom: 15px;
`
SkeletonHeader.displayName = 'SkeletonHeader'

export const SkeletonLocation = styled.div`
	position: absolute;
	height: 36px;
	width: 159px;
	border-radius: 18px;
	margin-top: 10px;
	background: ${colors.white};
`
SkeletonLocation.displayName = 'SkeletonLocation'

export const SkeletonInfo = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 15px;
	padding: 0 21px;
	margin-bottom: 18px;
`
SkeletonInfo.displayName = 'SkeletonInfo'

export const SkeletonInfoElement = styled.div`
	height: 20px;
	border-radius: 10px;
	width: 100%;
	background: ${colors.gray_more_lighter};
`
SkeletonInfoElement.displayName = 'SkeletonInfoElement'

export const SkeletonInfoElemTop = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	${SkeletonInfoElement}:nth-child(2) {
		grid-column: 3 / 4;
  }

`
SkeletonInfoElemTop.displayName = 'SkeletonInfoElemTop'

export const SkeletonInfoElemBottom = styled.div`
	display: flex;
	justify-content: center;
	gap: 20px;
	padding: 0 11px;
`
SkeletonInfoElemBottom.displayName = 'SkeletonInfoElemBottom'

export const SkeletonCategories = styled.div`
	width: 100%;
	height: 60px;
	background: ${colors.gray_more_lighter};
	margin-bottom: 19px;
`
SkeletonCategories.displayName = 'SkeletonCategories'

export const SkeletonDescription = styled.div`
	width: 100%;
	padding: 0 21px;
	margin-bottom: 23px;

	&.product {
		padding: 0;
		margin-bottom: 12px;
	}
`
SkeletonDescription.displayName = 'SkeletonDescription'

export const DescriptionTitle = styled.div`
	height: 16px;
	width: 40%;
	border-radius: 8px;
	background: ${colors.gray_more_lighter};
	margin-bottom: 8px;

	&.product {
		width: 46%;
	}
`
DescriptionTitle.displayName = 'DescriptionTitle'

export const DescriptionSubTitle = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 6px;
`
DescriptionSubTitle.displayName = 'DescriptionSubTitle'

export const SubTitleElement = styled.div`
	height: 10px;
	border-radius: 5px;
	background: ${colors.gray_more_lighter};
	width: 95%;

	&:nth-child(2) {
		width: 98%;
  }

	&:nth-child(3) {
		width: 15%;
  }

	&.product {
		width: 94%;

		&:nth-child(2) {
			width: 100%;
		}

		&:nth-child(3) {
			width: 55%;
		}
	}
`
SubTitleElement.displayName = 'SubTitleElement'

export const SkeletonProductWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 21px;
	margin-bottom: 40px;
`
SkeletonProductWrapper.displayName = 'SkeletonProductWrapper'

export const SkeletonProduct = styled.div`
	position: relative;
`
SkeletonProduct.displayName = 'SkeletonProduct'

export const ProductImg = styled.div`
	height: 153px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${colors.gray_more_lighter};
	border-radius: 10px;

	> svg {
		width: 109px;
		height: 70px;
		margin-bottom: 17.4px;
		fill: ${colors.gray_more_lighter};
	}
`
ProductImg.displayName = 'ProductImg'

export const ProductInfoBlock = styled.div`
	border: 1px solid ${colors.gray_more_lighter};
	border-radius: 10px;
	padding: 11px 11px 10px;
	margin: -30px 11px 0;
	background: ${colors.white};
`
ProductInfoBlock.displayName = 'ProductInfoBlock'

export const ProductButton = styled.div`
	height: 35px;
	border: 1px solid ${colors.gray_more_lighter};
	border-radius: 7px;
	background: ${colors.white};
`
ProductButton.displayName = 'ProductButton'
