/* eslint no-console: off */
import { updateMapSelectedWoltDriverDelivery, updateMapWoltDriverDeliveries } from '../../redux/action/map'

export const processWoltMessageThunk = (message) => (dispatch, getState) => {
	switch (message?.type) {
		case 'wolt-plugin.PARTNER_DELIVERY_ORDER_LOCATION_UPDATED':
			updateWoltDriverPositionThunk(message.data, dispatch, getState)
			break
		default:
			// eslint-disable-next-line
			console.error('Unknown wolt-plugin message type: ', JSON.stringify(message))
			break
	}
}

export const updateWoltDriverPositionThunk = (data, dispatch, getState) => {
	const isTrackingOrder = getState().map.selectedWoltDriverDelivery?.orderId === data.orderId
	const woltDriverDeliveries = getState().map.woltDriverDeliveries
	const existDelivery = woltDriverDeliveries.find(order => order.orderId === data.orderId)
	if (isTrackingOrder) dispatch(updateMapSelectedWoltDriverDelivery(data))
	if (existDelivery) dispatch(updateMapWoltDriverDeliveries(data))
}
