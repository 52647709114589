import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setAppModal } from '../../../redux/action/system'
import { useForm } from 'react-hook-form'
import TextInput from '../../Elements/inputs/TextInput'
import { putAutoConfirmationThunk } from '../../../redux/thunk/order/acceptOrder'
import CustomModal from '../../Elements/modal/CustomModal'
import Button from '../../Elements/buttons/Button'
import { Form, FormInfo, FormInputs } from '../../Elements/form/index.styled'
import { FormWrapper } from './index.styled'

const AutoConfirmationModal = React.memo(() => {
	const { t } = useTranslation()
	const { register, handleSubmit, watch, formState: { errors }, reset } = useForm()
	const autoConfirmationModalShow = useSelector(store => store.system.modal?.autoConfirmationModalShow)
	const deliveryTime = useSelector(store => store.autoConfirmationOrder?.autoConfirmation?.deliveryTime)
	const pickUpTime = useSelector(store => store.autoConfirmationOrder?.autoConfirmation?.pickUpTime)

	const dispatch = useDispatch()

	useEffect(() => {
		reset({
			deliveryTime: deliveryTime ? deliveryTime : 45,
			pickUpTime: pickUpTime ? pickUpTime : 15
		})
	}, [deliveryTime, pickUpTime])

	const onSubmit = useCallback((data) => {
		dispatch(putAutoConfirmationThunk({ ...data, active: true }))
		handleClose()
	}, [])

	const handleClose = useCallback(() => {
		dispatch(setAppModal({ autoConfirmationModalShow: false }))
	}, [])

	return (
		<CustomModal isOpen={autoConfirmationModalShow}
								 close={handleClose}
								 enableOutsideClick={false}
								 title="Automatische Bestellannahme"
								 size="small">
			<FormInfo>Bitte wählen Sie die Lieferzeit für die automatische Bestellannahme! Jede Bestellung wird mit der angegebenen Zeit bestätigt und sofort gedruckt!</FormInfo>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FormWrapper>
					<FormInputs cols={2}>
						<TextInput name="deliveryTime"
											 label="Zeit Lieferung (Min.)"
											 errors={errors}
											 register={register}
											 options={{
												 required: t('app.validation.required'),
												 min: {
													 value: 15,
													 message: t('app.validation.minNumber')
												 },
												 max: {
													 value: 240,
													 message: t('app.validation.maxNumber')
												 },
												 pattern: {
													 value: /^[0-9]+$/i,
													 message: t('app.validation.int')
												 }
											 }}
											 watch={watch}
											 color="gray" />
						<TextInput name="pickUpTime"
											 label="Zeit Abholung (Min.)"
											 errors={errors}
											 register={register}
											 options={{
												 required: t('app.validation.required'),
												 min: {
													 value: 15,
													 message: t('app.validation.minNumber')
												 },
												 max: {
													 value: 240,
													 message: t('app.validation.maxNumber')
												 },
												 pattern: {
													 value: /^[0-9]+$/i,
													 message: t('app.validation.int')
												 }
											 }}
											 watch={watch}
											 color="gray" />
					</FormInputs>
					<Button name="save" icon="checkmark" size="big" type="submit" text={t('buttons.controls.save')} color="green" />
				</FormWrapper>
			</Form>
		</CustomModal>
	)
})

AutoConfirmationModal.displayName = 'AutoConfirmationModal'
export default AutoConfirmationModal
