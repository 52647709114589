import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import getCartState from '../../axios/clientMonitorCart/get'
import { setClientMonitorCartState } from '../../action/clientMonitorCart'
import { selectClientMonitorItems } from '../../selector/cart'
import { sendMessageToClientMonitor } from '../../../component/ClientMonitorWindow/helper'
import updateClientMonitorCart from '../../axios/clientMonitorCart/put'
import deleteClientMonitorCart from '../../axios/clientMonitorCart/delete'
import { isLocalStorageCashRegisterMode } from '../../../helper/mode'
import { withJsClientMonitor, withSocketClientMonitor } from '../../../helper/clientMonitor'
import getPublicCartState from '../../axios/clientMonitorCart/public/get'
import createClientMonitorQrCodeLink from '../../axios/clientMonitorCart/qrCode/post'
import { fromLocalStorageValue } from '../../../helper/localStorage/fromLocalStorageValue'

export const getClientMonitorCartStateThunk = (desktopDeviceId) => dispatch => {
	return getCartState(desktopDeviceId)
		.then((res) => {
			dispatch(setClientMonitorCartState(res.data))
			return res.data
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getClientMonitorPublicCartStateThunk = () => dispatch => {
	return getPublicCartState()
		.then((res) => {
			dispatch(setClientMonitorCartState(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateClientMonitorCartThunk = (cartType, dispatchCallback) => async (dispatch, getState) => {
	await dispatchCallback?.()
	const isCashRegisterMode = isLocalStorageCashRegisterMode()
	const desktopDeviceId = fromLocalStorageValue(window.localStorage.desktopDeviceId)
	const currentState = getState()
	const clientMonitorEnabled = currentState.clientMonitorConfig.config?.enabled
	const clientMonitorItems = selectClientMonitorItems(cartType, desktopDeviceId)(currentState)
	if (withJsClientMonitor() && isCashRegisterMode && clientMonitorEnabled) {
		sendMessageToClientMonitor('cashdesk.CART_STATE_CHANGED_EVENT', { cartState: clientMonitorItems })
	}
	if (withSocketClientMonitor() && desktopDeviceId && clientMonitorEnabled) {
		return updateClientMonitorCart(clientMonitorItems.desktopDeviceId, clientMonitorItems.purchaseItems, clientMonitorItems.payments)
			.then(res => {
			}).catch(error => {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			})
	}
}

export const deleteClientMonitorCartThunk = () => (dispatch, getState) => {
	const isCashRegisterMode = isLocalStorageCashRegisterMode()
	const desktopDeviceId = fromLocalStorageValue(window.localStorage.desktopDeviceId)
	const clientMonitorEnabled = getState().clientMonitorConfig.config?.enabled
	if (withJsClientMonitor() && isCashRegisterMode && clientMonitorEnabled) {
		sendMessageToClientMonitor('cashdesk.CART_STATE_DELETED_EVENT', {})
	}
	if (withSocketClientMonitor() && desktopDeviceId && clientMonitorEnabled) {
		return deleteClientMonitorCart(desktopDeviceId)
			.then(res => {
			})
			.catch(error => {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			})
	}
}

export const storeClientMonitorQrCodePaymentSessionThunk = () => async (dispatch, getState) => {
	const isCashRegisterMode = isLocalStorageCashRegisterMode()
	const currentState = getState()
	const desktopDeviceId = fromLocalStorageValue(window.localStorage.desktopDeviceId)
	const clientMonitorEnabled = currentState.clientMonitorConfig.config?.enabled
	const qrCodePaymentSession = currentState.cartPayment.qrCodePaymentSession
	const qrCodeLink = qrCodePaymentSession?.link
	if (withJsClientMonitor() && isCashRegisterMode && clientMonitorEnabled) {
		sendMessageToClientMonitor('cashdesk.CLIENT_MONITOR_QR_CODE_PAYMENT_SESSION_CHANGED', { qrCodePaymentSession })
	}
	if (withSocketClientMonitor() && desktopDeviceId && clientMonitorEnabled) {
		return createClientMonitorQrCodeLink(desktopDeviceId, { qrCodeLink })
			.then(res => {
			}).catch(error => {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			})
	}
}

export const deleteClientMonitorQrCodePaymentSessionThunk = () => async (dispatch, getState) => {
	const isCashRegisterMode = isLocalStorageCashRegisterMode()
	const currentState = getState()
	const desktopDeviceId = fromLocalStorageValue(window.localStorage.desktopDeviceId)
	const clientMonitorEnabled = currentState.clientMonitorConfig.config?.enabled
	if (withJsClientMonitor() && isCashRegisterMode && clientMonitorEnabled) {
		sendMessageToClientMonitor('cashdesk.CLIENT_MONITOR_QR_CODE_PAYMENT_SESSION_CHANGED', { qrCodePaymentSession: null })
	}
	if (withSocketClientMonitor() && desktopDeviceId && clientMonitorEnabled) {
		return createClientMonitorQrCodeLink(desktopDeviceId, { qrCodeLink: null })
			.then(res => {
			}).catch(error => {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			})
	}
}
