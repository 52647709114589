import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setShowOrderClosedErrorModal } from '../../../redux/action/order'
import InfoModal from '../../Elements/modal/InfoModal'

const OrderClosedErrorModal = React.memo(() => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const showModal = useSelector(store => store.order.showOrderClosedErrorModal)

	const handleClose = useCallback(() => {
		dispatch(setShowOrderClosedErrorModal(false))
	}, [])

	return (
		<InfoModal isOpen={showModal}
			title={t('order.modal.error_closed_order_title')}
			text={t('order.modal.error_closed_order_text')}
			close={handleClose} />
	)
})

OrderClosedErrorModal.displayName = 'OrderClosedErrorModal'
export default OrderClosedErrorModal
