import React, { Suspense, useEffect, useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Main from './Main'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppWrapper, Section, ToastWrapper } from './App.styled'
import AppModal from './component/GlobalAppModal'
import { getPhonesThunk, getVoipDesktopSessionsThunk } from './redux/thunk/phone'
import mezmoClientLogger from './logger'
import { getPrinterDesktopSessionsThunk } from './redux/thunk/printer'
import { setSystemKeyboardText, setSystemShowKeyboard, triggerSystemKeyboardEnter } from './redux/action/system'
import ErrorBoundary from './helper/ErrorBoundary'
import { GlobalStyle } from './style/global.styled'
import MenuList from './component/Menu'
import Keyboard from './component/Elements/keyboard'
import { connectSocketIoThunk } from './redux/thunk/socket'
import { selectCompanyIndex, selectHasAccessToken, selectIsOldReactAppVersion } from './redux/selector/system'
import { getSupportDataThunk } from './redux/thunk/support'
import { setSupportDealerContacts } from './redux/action/support'
import { subscribeToConsole } from './helper/subscribeToConsole'
import { ThemeProvider } from 'styled-components'
import { useLocalStorage } from './hooks/useLocalStorage'
import SocketClientMonitorCart from './component/ClientMonitorCart/SocketClientMonitorCart'
import { extractQueryParam, isClientMonitor, withJsClientMonitor, withSocketClientMonitor } from './helper/clientMonitor'
import ClientMonitorWindow from './component/ClientMonitorWindow'
import JsClientMonitorCart from './component/ClientMonitorCart/JsClientMonitorCart'
import VersionUpdater from './component/Elements/versionUpdater'
import { getConfigJsDataThunk } from './redux/thunk/configJs'
import { getCompanyActivationDataThunk } from './redux/thunk/company'
import { setCompanyActivationData } from './redux/action/company'
import { updateClientMonitorConfigThunk } from './redux/thunk/clientMonitorConfig'

const App = React.memo(() => {
	const hasAccessToken = useSelector(selectHasAccessToken)
	const companyIndex = useSelector(selectCompanyIndex)
	const voipDesktopSessionsLength = useSelector(store => store.socket.voipDesktopSessions?.length)
	const keyboardText = useSelector(store => store.system.keyboardText)
	const toggled = useSelector(store => store.system.toggled)
	const showKeyboard = useSelector(store => store.system.showKeyboard)
	const isOldReactAppVersion = useSelector(selectIsOldReactAppVersion)
	const dispatch = useDispatch()
	const socketRefreshInterval = useRef(null)
	const isClientMontr = isClientMonitor()
	const isAuthorized = hasAccessToken && companyIndex
	const [colorTheme] = useLocalStorage('colorTheme', 'LIGHT')
	const [, setDesktopDeviceId] = useLocalStorage('desktopDeviceId', null)
	const [, setDesktopMode] = useLocalStorage('desktopMode', null)
	const [language] = useLocalStorage('i18nextLng', 'de')

	const desktopDeviceId = extractQueryParam('desktopDeviceId')
	const desktopMode = extractQueryParam('desktopMode')
	const layout = (toggled ? 'toggled ' : '') + (!isAuthorized ? 'authWrapper ' : '') + (isOldReactAppVersion ? 'versionUpdater ' : '')
	const socketClientMonitor = withSocketClientMonitor()
	const jsClientMonitor = withJsClientMonitor()
	const configClientMonitor = useSelector(store => store.clientMonitorConfig.config)
	const enabledClientMonitor = useSelector(store => store.clientMonitorConfig.config?.enabled)

	useEffect(() => {
		dispatch(getConfigJsDataThunk())
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const unsubscribe = subscribeToConsole(mezmoClientLogger)
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		if (desktopDeviceId) {
			setDesktopDeviceId(desktopDeviceId)
		}
	}, [desktopDeviceId]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (desktopMode) {
			setDesktopMode(desktopMode)
		}
	}, [desktopMode]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isAuthorized && !isClientMontr) {
			dispatch(connectSocketIoThunk())
			dispatch(getPrinterDesktopSessionsThunk())
			dispatch(getVoipDesktopSessionsThunk())
			dispatch(getPhonesThunk())
		}
	}, [isAuthorized, isClientMontr]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!hasAccessToken && socketRefreshInterval.current) {
			clearInterval(socketRefreshInterval.current)
		}
	}, [!hasAccessToken]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isAuthorized) {
			dispatch(getPhonesThunk())
		}
	}, [voipDesktopSessionsLength]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isAuthorized) {
			dispatch(getCompanyActivationDataThunk())
			dispatch(getSupportDataThunk())
		} else {
			dispatch(setCompanyActivationData(null))
			dispatch(setSupportDealerContacts(null))
		}
	}, [isAuthorized]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isAuthorized && enabledClientMonitor) {
			dispatch(updateClientMonitorConfigThunk({ ...configClientMonitor, language }))
		}
	}, [ isAuthorized, enabledClientMonitor]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<GlobalStyle />
			<ErrorBoundary>
				<Suspense fallback={(<div>Loading..</div>)}>
					<Router>
						<ThemeProvider theme={{ colorTheme }}>
							<AppWrapper id="app-wrapper">
								<VersionUpdater />
								<Section className={layout}>
									{isAuthorized &&
										<MenuList />}
									{isClientMontr && socketClientMonitor &&
										<SocketClientMonitorCart />}
									{isClientMontr && jsClientMonitor &&
										<JsClientMonitorCart />}
									{!isClientMontr &&
										<Main />}
									{showKeyboard &&
										<Keyboard onlyNumbers={true}
															currentValue={keyboardText}
															handleType={text => dispatch(setSystemKeyboardText(text))}
															close={() => dispatch(setSystemShowKeyboard(false))}
															enter={() => dispatch(triggerSystemKeyboardEnter())} />}
								</Section>
								<ToastWrapper>
									<ToastContainer />
								</ToastWrapper>
							</AppWrapper>
							<AppModal />
							<ClientMonitorWindow />
						</ThemeProvider>
					</Router>
				</Suspense>
			</ErrorBoundary>
		</>
	)
})

App.displayName = 'App'
export default App
