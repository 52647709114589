import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getFloat, roundPrice } from '../../../helper'
import { selectFormattedCurrency, selectIsNeededZIndex } from '../../../redux/selector/system'
import Button from '../buttons/Button'
import KeyBindings from '../keybindings'
import CustomModal from '../modal/CustomModal'
import { ButtonsWrapper, ChangeCalculatorContent, ChangeCalculatorWrapper, ChangeInput, ChangeInputSum, ChangeInputText, InputsWrapper, KeyboardsWrapper } from './index.styled'
import NumbersKeyboard from './NumbersKeyboard'
import SumKeyboard from './SumKeyboard'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import GetMoneyInput from './GetMoneyInput'

const EVENT_Z_INDEX = 12

const ModalChangeCalculator = React.memo(({ isOpen, close, totalPrice, isEcTerminal = false, handlePayment }) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const [customerGivenSum, setCustomerGivenSum] = useState('0')
	const [positionAfterComma, setPositionAfterComma] = useState(-1)
	const isMobile = useWindowBreakPoint(theme.point720)
	const roundedTotalPrice = roundPrice(totalPrice)
	const customerGivenSumNumber = +customerGivenSum.replace(',', '.')
	const differenceSum = (customerGivenSumNumber > roundedTotalPrice) ? customerGivenSumNumber - roundedTotalPrice : 0
	const formattedCustomerGivenSum = useSelector(selectFormattedCurrency(customerGivenSumNumber))
	const formattedDifferenceSum = useSelector(selectFormattedCurrency(differenceSum))
	const textForGetMoneyInput = isEcTerminal ? t('changeCalculator.otherAmount') : t('changeCalculator.getMoney')
	const textForChangeInput = isEcTerminal ? t('changeCalculator.tip') : t('changeCalculator.change')

	useEffect(() => {
		// setCustomerGivenSum(totalPrice)
	}, [isOpen])

	const handleReset = useCallback(() => {
		setCustomerGivenSum('0')
		setPositionAfterComma(-1)
	}, [])

	const handleSumType = useCallback((sum) => {
		setCustomerGivenSum(gs => `${+gs + +sum}`)
	}, [])

	const handleKeyboardType = useCallback((value) => {
		if (positionAfterComma === 0) {
			setCustomerGivenSum(gs => (gs + '.' + value))
			setPositionAfterComma(1)
		} else if (positionAfterComma === 1) {
			setCustomerGivenSum(gs => (gs + '' + value))
			setPositionAfterComma(2)
		} else if (positionAfterComma !== 2) {
			setCustomerGivenSum(gs => (gs + '' + value))
		}
	}, [positionAfterComma])

	const handleCommaClick = useCallback(() => {
		if (positionAfterComma === -1) {
			setPositionAfterComma(0)
		}
	}, [positionAfterComma])

	const handleEnter = useCallback(() => {
		// todo: do we need enter button?
	}, [])

	const handleClose = useCallback(() => {
		close()
		handleReset()
	}, [close, handleReset])

	const handleGreenButtonClick = useCallback(() => {
		handleReset()
		handlePayment({ totalPrice, customerGivenSum: Number(customerGivenSum.replace(',', '.')), differenceSum })
	}, [handleReset, handlePayment, totalPrice, customerGivenSum, differenceSum])

	const filter = v => v.replace(/[^0-9.,]/g, '')

	const handleCalculatorSumOnlyMobile = (e) => {
		if (!isMobile) return
		let inputValue = e.target.value
		if (/^-?\d*\.?\d*$/.test(inputValue) || (/^-?\d*\.?\d*,$/.test(inputValue))) {
			setCustomerGivenSum(inputValue.replace('.', ','))
		} else {
			inputValue = filter(inputValue).replace('.', ',')
			setCustomerGivenSum(inputValue)
		}
	}

	const handleOnBlurForMobile = (e) => {
		if (!isMobile) return
		const value = e.target.value
		const formattedValue = value ? (getFloat(value).toFixed(2).replace('.', ',')).toString()  : value
		setCustomerGivenSum(formattedValue)
	}

	const handleFocus = () => {
		if (!+customerGivenSum) setCustomerGivenSum('')
	}

	return (
		<ChangeCalculatorWrapper>
			<CustomModal isOpen={isOpen}
									 close={handleClose}
									 id="modal-change"
									 size="auto"
									 title={t('changeCalculator.title')}
									 titlePrice={roundedTotalPrice}
									 titleUpperCase={true}
									 titleColor="blue"
									 disableBackButton={true}
									 titleInOneRow={true}
									 zIndex={EVENT_Z_INDEX}>
				<ChangeCalculatorContent>
					<InputsWrapper>
						<GetMoneyInput value={isMobile ? customerGivenSum : formattedCustomerGivenSum}
													 textForGetMoneyInput={textForGetMoneyInput}
													 onChange={handleCalculatorSumOnlyMobile}
													 onBlur={handleOnBlurForMobile}
													 onFocus={handleFocus} />
						<ChangeInput data-testid={'calculator-' + (isEcTerminal ? 'tips' : 'change')}>
							<ChangeInputText>{textForChangeInput}:</ChangeInputText>
							<ChangeInputSum>{formattedDifferenceSum}</ChangeInputSum>
						</ChangeInput>
					</InputsWrapper>

					{!isMobile &&
						<KeyboardsWrapper>
							<SumKeyboard handleType={handleSumType} />
							<NumbersKeyboard handleClick={handleKeyboardType}
															 handleCommaClick={handleCommaClick}
															 enter={handleEnter}
															 reset={handleReset} />
						</KeyboardsWrapper>}
				</ChangeCalculatorContent>

				<ButtonsWrapper>
					<Button name="back" icon={'back'} text={t('Cart.Buttons.back')} keyButton="escape" keyText="Esc" zIndex={EVENT_Z_INDEX} color="gray" onClick={handleClose} />
					{isEcTerminal ?
						<Button name="send" icon={'calculator'} text={t('Cart.Buttons.pay')} keyButton="F2" zIndex={EVENT_Z_INDEX} color="green" onClick={handleGreenButtonClick} /> :
						<Button name="send" icon={'print'} text={t('Cart.Buttons.print')} keyButton="F2" zIndex={EVENT_Z_INDEX} color="green" onClick={handleGreenButtonClick} />}
				</ButtonsWrapper>
			</CustomModal>

			{isNeededZIndex && !isMobile &&
				<KeyBindings enter={handleEnter}
										 zero={() => handleKeyboardType('0')}
										 one={() => handleKeyboardType('1')}
										 two={() => handleKeyboardType('2')}
										 three={() => handleKeyboardType('3')}
										 four={() => handleKeyboardType('4')}
										 five={() => handleKeyboardType('5')}
										 six={() => handleKeyboardType('6')}
										 seven={() => handleKeyboardType('7')}
										 eight={() => handleKeyboardType('8')}
										 nine={() => handleKeyboardType('9')}
										 comma={() => handleCommaClick('.')} />}
		</ChangeCalculatorWrapper>
	)
})

ModalChangeCalculator.displayName = 'ModalChangeCalculator'
export default ModalChangeCalculator
