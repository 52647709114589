import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { postLogoutThunk } from '../../redux/thunk/registration/auth'
import { useNavigate } from 'react-router-dom'

const LogOut = React.memo(() => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(postLogoutThunk())
		navigate('/')
	}, [])

	return (
		<>
			<p>none</p>
		</>
	)
})

LogOut.displayName = 'LogOut'
export default LogOut
