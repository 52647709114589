import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectContainsAnyRole } from '../../../../redux/selector/system'
import CustomInfoModal from '../../modal/CustomInfoModal'
import Button from '../../buttons/Button'

const EVENT_Z_INDEX = 89

const ModalECTerminalWarning = React.memo(({ isOpen, setOpenModal }) => {
	const { t } = useTranslation()
	const hasRoleSettingsWrite = useSelector(selectContainsAnyRole(['PROGRAM_SETTINGS_WRITE']))
	const navigate = useNavigate()

	const handleCloseModal = () => {
		setOpenModal(false)
	}

	const navigateToSettings = () => {
		navigate('/settings/payments')
		handleCloseModal()
	}

	return (
		<>
			<CustomInfoModal isOpen={isOpen}
											 title={t('Payments.modal.notification.title')}
											 text={t('Payments.modal.notification.EC-TerminalModal.text')}
											 close={handleCloseModal}
											 modalColor="orange"
											 zIndex={EVENT_Z_INDEX}
											 button={<>
												 {hasRoleSettingsWrite && <Button icon="settings-2" onClick={navigateToSettings} text={t('Payments.modal.notification.EC-TerminalModal.terminalsSettings')} color="gray" zIndex={EVENT_Z_INDEX}/>}
												 <Button icon="checkmark" onClick={handleCloseModal} tabIndex="1" text="Ok" color="green" keyButton="F2" zIndex={EVENT_Z_INDEX} />
											 </>}>
			</CustomInfoModal>
		</>
	)

})

ModalECTerminalWarning.displayName = 'ModalECTerminalWarning'
export default ModalECTerminalWarning
