import CustomModal from '../../../modal/CustomModal'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormInputs } from '../../../form/index.styled'
import Button from '../../../buttons/Button'
import TextInput from '../../../inputs/TextInput'
import { useForm } from 'react-hook-form'
import { InvoiceConfigsDescription, InvoiceConfigsSubtitle, InvoiceConfigsWrapper } from './index.styled'
import { handleValidateBic, handleValidateIban } from '../../../../PaymentsSettings/PaymentForm/PaymentItem/PaymentByInvoiceSettings/helper'

const EVENT_Z_INDEX = 71
const defaultValues = { bic: '', iban: '' }

const InvoiceConfigsModal = React.memo(({ isOpen, close, onSubmitData }) => {
	const { t } = useTranslation()
	const { handleSubmit, register, formState: { errors }, reset, watch } = useForm({ defaultValues })

	const onSubmit = useCallback((data) => {
		onSubmitData(data)
	}, [onSubmitData])

	const handleReset = useCallback(() => {
		reset(defaultValues)
	}, [reset])

	const handleClose = useCallback(() => {
		handleReset()
		close()
	}, [handleReset, close])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 title={t('InvoiceModals.invoiceConfigs.title')}
								 size="x-small"
								 id="invoice-configs-modal"
								 zIndex={EVENT_Z_INDEX}
								 disableBackButton={true}
								 button={
									 <>
										 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')}
														 keyButton="F3" zIndex={EVENT_Z_INDEX}
														 onClick={handleClose}
														 className="back" />
										 <Button name="save" color="green" icon="save" text={t('buttons.controls.save')}
														 keyButton="F2" zIndex={EVENT_Z_INDEX}
														 onClick={handleSubmit(onSubmit)} />
									 </>
								 }>
			<InvoiceConfigsWrapper>
				<InvoiceConfigsSubtitle>{t('InvoiceModals.invoiceConfigs.subtitle')}</InvoiceConfigsSubtitle>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<FormInputs>
						<TextInput testId="сonfig-iban"
											 name="iban"
											 label="IBAN*"
											 watch={watch}
											 errors={errors}
											 register={register}
											 options={{
												 required: t('app.validation.required'),
												 validate: (value) => handleValidateIban(value) || t('app.validation.incorrectData'),
											 }}
											 color="gray" />
						<TextInput testId="сonfig-bic"
											 name="bic"
											 label="BIC*"
											 watch={watch}
											 errors={errors}
											 register={register}
											 options={{
												 required: t('app.validation.required'),
												 validate: (value) => handleValidateBic(value) || t('app.validation.incorrectData'),
											 }}
											 color="gray" />
					</FormInputs>
				</Form>
				<InvoiceConfigsDescription>{'*' + t('InvoiceModals.description')}</InvoiceConfigsDescription>
			</InvoiceConfigsWrapper>
		</CustomModal>
	)
})

InvoiceConfigsModal.displayName = 'InvoiceConfigsModal'
export default InvoiceConfigsModal
