import { retrieveErrorFromApi } from '../../../helper'
import { setDeliveryCartModalChangeCalculatorIsNeeded } from '../../action/deliveryCart'
import { setAppErrors } from '../../action/system'
import getChangeSetting from '../../axios/registration/changeSettings/get'

export const getDeliveryCartModalChangeCalculatorIsNeededThunk = (businessScope) => dispatch => {
	return getChangeSetting('DELIVERY', businessScope)
		.then((res) => {
			dispatch(setDeliveryCartModalChangeCalculatorIsNeeded(res.data.showCalculatorWithChange))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
