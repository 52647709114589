import CustomModal from '../../../modal/CustomModal'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../buttons/Button'
import { useForm } from 'react-hook-form'
import { InvoiceCustomerDataDescription, InvoiceCustomerDataSubtitle, InvoiceCustomerDataWrapper } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import MainCustomerDataForm from './main'
import { formatAddress, removeEmptyFields } from '../../../../../helper'
import { setCustomerAddressIsNotFull } from '../../../../../redux/action/customer'
import { resetCustomerThunk, searchResetThunk, storeCustomerThunk, updateCustomerThunk } from '../../../../../redux/thunk/customer'

const EVENT_Z_INDEX = 72

const defaultValues = {
	phoneNumber: null, customerNumber: null, name: null, companyName: null, address: null, streetNumber: null,
	street: null, city: null, postalCode: null, remark: null, deliveryCost: 0, customerAddress: null, email: null,
}

const InvoiceCustomerDataModal = React.memo(({ isOpen, close, handlePayment, captureCustomerData = false }) => {
	const { t } = useTranslation()
	const { handleSubmit, register, formState: { errors }, reset, setValue, watch, setFocus, clearErrors } = useForm({ defaultValues })
	const dispatch = useDispatch()
	const customer = useSelector(store => store.customerStore.customer)
	const geoData = useSelector(store => store.customerStore.geoData)
	const geoDataLoading = useSelector(store => store.customerStore.geoDataLoading)

	const handleReset = useCallback((values) => {
		reset({ ...defaultValues, ...values })
	}, [reset])

	useEffect(() => {
		if (isOpen && customer) {
			handleReset({
				...customer,
				address: customer ? formatAddress(customer.street, customer.streetNumber, customer.postalCode, customer.city) : null,
				customerAddress: {
					city: customer.city || null,
					street: customer.street || null,
					house: customer.streetNumber || null,
					zipCode: customer.postalCode || null,
					latitude: geoData?.lat || null,
					longitude: geoData?.lon || null,
				},
			})

			if (!customer.city || !customer.street || !customer.postalCode || !geoData?.lat || !geoData?.lon) {
				dispatch(setCustomerAddressIsNotFull(true))
			} else {
				dispatch(setCustomerAddressIsNotFull(false))
			}
		}
	}, [isOpen, customer]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleEscape = useCallback(() => {
		handleReset(defaultValues)
		dispatch(resetCustomerThunk())
		dispatch(searchResetThunk())
	}, [handleReset, dispatch])

	const handleResetBack = useCallback(() => {
		handleReset(captureCustomerData ? customer : defaultValues)
		if (!captureCustomerData) dispatch(resetCustomerThunk())
		dispatch(searchResetThunk())
	}, [handleReset, dispatch, captureCustomerData, customer])

	const handlePaymentData = useCallback((data, customerNumber = null) => {
		const dataValue = customerNumber ? { ...data, customerNumber } : data
		handlePayment(dataValue)
		handleEscape()
	}, [handlePayment, handleEscape])

	const onSubmit = useCallback(async (data) => {
		let dataValue = {
			...data,
			address: formatAddress(data.customerAddress.street, data.customerAddress.house, data.customerAddress.zipCode, data.customerAddress.city),
			city: data.customerAddress.city,
			street: data.customerAddress.street,
			postalCode: data.customerAddress.zipCode,
			streetNumber: data.customerAddress.house,
			geoData: {
				lat: data.customerAddress.latitude,
				lon: data.customerAddress.longitude,
				distance: geoData?.distance, // if distance was calculated
				duration: geoData?.duration,
				image: geoData?.image,
			},
		}
		dataValue = removeEmptyFields(dataValue)
		if (dataValue.customerNumber) {
			await dispatch(updateCustomerThunk(dataValue))
			handlePaymentData(dataValue)
		} else {
			const customerNumber = await dispatch(storeCustomerThunk(dataValue))
			handlePaymentData(dataValue, customerNumber)
		}
	}, [handlePaymentData, geoData, dispatch])

	const handleClose = useCallback(() => {
		handleResetBack()
		close()
	}, [handleResetBack, close])

	return (
		<CustomModal isOpen={isOpen}
								 close={handleClose}
								 title={t('InvoiceModals.customerData.title')}
								 size="x-small"
								 id="invoice-customer-data-modal"
								 zIndex={EVENT_Z_INDEX}
								 disableBackButton={true}
								 button={
									 <>
										 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')}
														 keyButton="F3" zIndex={EVENT_Z_INDEX}
														 onClick={handleClose}
														 className="back" />
										 <Button name="send-email" color="green" icon="send-email" text={t('buttons.controls.send_invoice')}
														 keyButton="F2" zIndex={EVENT_Z_INDEX}
														 onClick={handleSubmit(onSubmit)}
														 disabled={geoDataLoading} />
									 </>
								 }>
			<InvoiceCustomerDataWrapper>
				<InvoiceCustomerDataSubtitle>{t('InvoiceModals.customerData.subtitle')}</InvoiceCustomerDataSubtitle>
				{isOpen &&
					<MainCustomerDataForm {...{ isOpen, handleSubmit, onSubmit, register, errors, reset, setValue, setFocus, watch, zIndex: EVENT_Z_INDEX, handleEscape, clearErrors, captureCustomerData }} />}
				<InvoiceCustomerDataDescription>{'*' + t('InvoiceModals.description')}</InvoiceCustomerDataDescription>
			</InvoiceCustomerDataWrapper>
		</CustomModal>
	)
})

InvoiceCustomerDataModal.displayName = 'InvoiceCustomerDataModal'
export default InvoiceCustomerDataModal
