import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { CountDownWrap, CountTime } from './index.styled'
import { getNeededDateFormat } from '../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { postModalLogoutThunk } from '../../../redux/thunk/modalLogin'
import Button from '../buttons/Button'
import { resetRestaurantModalsThunk } from '../../../redux/thunk/restaurant'

const LoginTimeCountDown = ({ logoutTime }) => {
	const modalLoginUsername = useSelector(state => state.modalLogin.username)
	const dispatch = useDispatch()
	const [date, setDate] = useState(new Date((new Date()).getTime() + 3 * 60 * 1000))

	useEffect(() => {
		setDate(new Date((new Date()).getTime() + logoutTime))
	}, [modalLoginUsername, logoutTime])

	const doLogout = () => {
		dispatch(postModalLogoutThunk())
		dispatch(resetRestaurantModalsThunk())
	}

	return (
		modalLoginUsername ?
			<CountDownWrap className="login-time">
				<h2>{modalLoginUsername}</h2>
				<Countdown date={date}
									 key="Countdown"
									 intervalDelay={0}
									 precision={3}
									 onComplete={doLogout}
									 renderer={props => <CountTime>{getNeededDateFormat(props.total, 'mm:ss')}</CountTime>} />
				<Button name="logout" icon="back" color="red" size="x-small" text="Log out" onClick={doLogout} />
			</CountDownWrap> : null
	)
}

LoginTimeCountDown.displayName = 'LoginTimeCountDown'
export default LoginTimeCountDown
