import { SET_SUPPORT_ACTIVATION_DATE, SET_SUPPORT_DEACTIVATION_DATE, SET_SUPPORT_DEALER_CONTACTS, SET_SUPPORT_SHOW_BILL_UNPAID_MODAL, SET_SUPPORT_SHOW_BILL_UNPAID_WARNING_MODAL, SET_SUPPORT_SHOW_DEACTIVATION_MODAL, SET_SUPPORT_SHOW_DEACTIVATION_WARNING_MODAL, SET_SUPPORT_SHOW_EXTRA_CONTACTS_MODAL, SET_SUPPORT_SHOW_TSE_NOTIFICATION_MODAL } from '../../actionTypes/support'

export const setSupportDealerContacts = value => ({ type: SET_SUPPORT_DEALER_CONTACTS, value })

export const setSupportActivationDate = value => ({ type: SET_SUPPORT_ACTIVATION_DATE, value })

export const setSupportDeactivationDate = value => ({ type: SET_SUPPORT_DEACTIVATION_DATE, value })

export const setSupportShowDeactivationWarningModal = value => ({ type: SET_SUPPORT_SHOW_DEACTIVATION_WARNING_MODAL, value })

export const setSupportShowExtraContactsModal = value => ({ type: SET_SUPPORT_SHOW_EXTRA_CONTACTS_MODAL, value })

export const setSupportShowDeactivationModal = value => ({ type: SET_SUPPORT_SHOW_DEACTIVATION_MODAL, value })

export const setSupportShowTseNotificationModal = value => ({ type: SET_SUPPORT_SHOW_TSE_NOTIFICATION_MODAL, value })

export const setSupportShowBillUnpaidWarningModal = value => ({ type: SET_SUPPORT_SHOW_BILL_UNPAID_WARNING_MODAL, value })

export const setSupportShowBillUnpaidModal = value => ({ type: SET_SUPPORT_SHOW_BILL_UNPAID_MODAL, value })
