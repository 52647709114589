import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const DeliveryConfig = lazyWithRetry(() => import(/* webpackChunkName: "delivery-config" */'../../component/DeliveryConfig'))

const DeliveryConfigPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<DeliveryConfig />
		</Suspense>
	)
})

DeliveryConfigPage.displayName = 'DeliveryConfigPage'
export default DeliveryConfigPage
