import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SelectOptions from '../../Elements/inputs/SelectInput/SelectOptions'
import { useDispatch, useSelector } from 'react-redux'
import CustomTable from '../../Elements/table/old'
import TableTr from '../../Elements/table/old/tr'
import { TableMobileTitle, TableTd } from '../../Elements/table/old/index.styled'
import SelectInputControlled from '../../Elements/inputs/SelectInput/controlled'
import { getDeliverySettingsCartSettingsThunk, getDeliverySettingsChangeSettingsThunk, updateDeliverySettingsCartSettingsThunk, updateDeliverySettingsChangeSettingsThunk } from '../../../redux/thunk/deliverySettings'

const settingsOrderBisnesscope = ['DELIVERY', 'PICKUP', 'COUNTER']

const CartSettingForDelivery = () => {
	const cartSettings = useSelector(store => store.deliverySettings.cartSettings)
	const changeSettings = useSelector(store => store.deliverySettings.changeSettings)
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()

	useEffect(() => {
		dispatch(getDeliverySettingsCartSettingsThunk('DELIVERY'))
		dispatch(getDeliverySettingsChangeSettingsThunk('DELIVERY'))
	}, [])

	const tableHeadersStaticCart = useMemo(() => [
		{ title: t('Settings.TitleCartSettings') },
		{ title: t('Settings.delivery') },
		{ title: t('Settings.pickup') },
		{ title: t('Settings.counter') },
	], [i18n?.language])

	const tableHeadersStaticChange = useMemo(() => [
		{ title: t('Settings.TitleChangeSettings') },
		{ title: t('Settings.delivery') },
		{ title: t('Settings.pickup') },
		{ title: t('Settings.counter') },
	], [i18n?.language])

	const cartSettingsSorted = useMemo(() => {
		if (cartSettings) {
			return [...cartSettings].sort((a, b) => settingsOrderBisnesscope.indexOf(a.businessScope) - settingsOrderBisnesscope.indexOf(b.businessScope)) || []
		}
	}, [cartSettings, settingsOrderBisnesscope])

	const changeSettingsSorted = useMemo(() => {
		if (changeSettings) {
			return [...changeSettings].sort((a, b) => settingsOrderBisnesscope.indexOf(a.businessScope) - settingsOrderBisnesscope.indexOf(b.businessScope)) || []
		}
	}, [changeSettings, settingsOrderBisnesscope])


	const changeCartSetting = (e, businessScope) => {
		dispatch(updateDeliverySettingsCartSettingsThunk({ businessScope, module: 'DELIVERY', showProductQuantity: e.target.value }))
	}

	const changeChangeSetting = (e, businessScope) => {
		dispatch(updateDeliverySettingsChangeSettingsThunk({ businessScope, module: 'DELIVERY', showCalculatorWithChange: e.target.value }))
	}

	return (
		<div>
			<CustomTable id="cartSettingsDelivery-table"
				headers={tableHeadersStaticCart}
				onLoad={() => {
				}}
				currentLength={cartSettings?.length}
				totalLength={cartSettings?.length}
				perLoad={20}>
				<TableTr>
					<TableTd>
						<TableMobileTitle>{tableHeadersStaticCart[0].title}</TableMobileTitle>
						{t('Settings.TextCartSettings')}
					</TableTd>
					{cartSettingsSorted?.map((item, i) =>
						<TableTd key={i}>
							<TableMobileTitle>{tableHeadersStaticCart[i + 1].title}</TableMobileTitle>
							<SelectInputControlled name={`cartSettingsDelivery-${i}`}
								value={item.showProductQuantity}
								onChange={e => changeCartSetting(e, item.businessScope)}>
								<SelectOptions options={[
									{ label: t('Settings.CartSelect.no'), value: false },
									{ label: t('Settings.CartSelect.yes'), value: true }]}
									labelBy="label"
									valueBy="value" />
							</SelectInputControlled>
						</TableTd>)}
				</TableTr>
			</CustomTable>

			<CustomTable id="changeSettingsDelivery-table"
				headers={tableHeadersStaticChange}
				onLoad={() => {
				}}
				currentLength={changeSettings?.length}
				totalLength={changeSettings?.length}
				perLoad={20}>
				<TableTr>
					<TableTd>
						<TableMobileTitle>{tableHeadersStaticChange[0].title}</TableMobileTitle>
						{t('Settings.TextChangeSettings')}
					</TableTd>
					{changeSettingsSorted?.map((item, i) =>
						<TableTd key={i}>
							<TableMobileTitle>{tableHeadersStaticChange[i + 1].title}</TableMobileTitle>
							<SelectInputControlled name={`changeSettingsDelivery-${i}`}
								value={item.showCalculatorWithChange}
								onChange={e => changeChangeSetting(e, item.businessScope)}>
								<SelectOptions options={[
									{ label: t('Settings.CartSelect.no'), value: false },
									{ label: t('Settings.CartSelect.yes'), value: true }]}
									labelBy="label"
									valueBy="value" />
							</SelectInputControlled>
						</TableTd>)}
				</TableTr>
			</CustomTable>
		</div>
	)
}

CartSettingForDelivery.displayName = 'CartSettingForDelivery'
export default CartSettingForDelivery
