import { retrieveErrorFromApi, notifyToast } from '../../../helper'
import { setAppErrors } from '../../action/system'
import { setCashDeskSettingsCartSettings, updateCashDeskSettingsCartSetting, setCashDeskSettingsChangeSettings, updateCashDeskSettingsChangeSetting, setCashDeskSettingsWaiterSettings, setCashDeskSettingsPrintSettings } from '../../action/cashDeskSettings'
import getExpertCartSettings from '../../axios/registration/expertCartSettings'
import updateExpertCartSettings from '../../axios/registration/expertCartSettings/put'
import getChangeSettings from '../../axios/registration/changeSettings'
import updateChangeSettings from '../../axios/registration/changeSettings/put'
import getWaiterSettings from '../../axios/order/waiterSettings/get'
import updateWaiterSettings from '../../axios/order/waiterSettings/put'
import getAutoPrintConfigs from '../../axios/printer/printerSettings'
import updateAutoPrintConfigs from '../../axios/printer/printerSettings/put'

export const getCashDeskSettingsCartSettingsThunk = (module) => dispatch => {
	return getExpertCartSettings(module)
		.then(res => {
			dispatch(setCashDeskSettingsCartSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateCashDeskSettingsCartSettingsThunk = (data) => dispatch => {
	return updateExpertCartSettings(data)
		.then((res) => {
			notifyToast({ message: 'Cart setting successfully updated' })
			return dispatch(updateCashDeskSettingsCartSetting(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCashDeskSettingsChangeSettingsThunk = (module) => dispatch => {
	return getChangeSettings(module)
		.then(res => {
			dispatch(setCashDeskSettingsChangeSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateCashDeskSettingsChangeSettingsThunk = (data) => dispatch => {
	return updateChangeSettings(data)
		.then((res) => {
			notifyToast({ message: 'Change setting successfully updated' })
			return dispatch(updateCashDeskSettingsChangeSetting(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCashDeskSettingsWaiterSettingsThunk = (module) => dispatch => {
	return getWaiterSettings(module)
		.then(res => {
			dispatch(setCashDeskSettingsWaiterSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateCashDeskSettingsWaiterSettingsThunk = (data) => dispatch => {
	return updateWaiterSettings('CASH_DESK', data)
		.then(res => {
			return dispatch(getCashDeskSettingsWaiterSettingsThunk('CASH_DESK'))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCashDeskSettingsPrintSettingsThunk = (module) => dispatch => {
	return getAutoPrintConfigs(module)
		.then(res => {
			dispatch(setCashDeskSettingsPrintSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateCashDeskSettingsPrintSettingsThunk = (id, data, module) => dispatch => {
	return updateAutoPrintConfigs(id, data)
		.then((res) => {
			notifyToast({ message: 'Print setting successfully updated' })
			return dispatch(getCashDeskSettingsPrintSettingsThunk(module))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}


