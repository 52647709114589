import React from 'react'
import { PlusButton, PlusMinusWrap } from './index.styled'

const PlusMinus = React.memo(({ AddButton, onClickPlus, incrementAbility, outline, size, color, children, plusBtnColor }) => {
	const layout = (outline ? 'outline' : '') + (size ? ' ' + size : '') + (color ? ' ' + color : '')

	return (
		<PlusMinusWrap className={ layout}>
			{children}
			{incrementAbility &&
				<PlusButton className={'plus ' + layout + (plusBtnColor ? ' ' + plusBtnColor : '')} onClick={onClickPlus} data-testid="plus">
					{AddButton &&
						<AddButton />}
				</PlusButton>}
		</PlusMinusWrap>
	)
})

PlusMinus.displayName = 'PlusMinus'
export default PlusMinus
