import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TabsContent, TabsWrapper } from './index.styled'
import { Tab, TabCounter, TabCounterText } from '../../../../Elements/tabs/index.styled'

const Tabs = React.memo(({ activeTab, onTabClick }) => {
	const { t } = useTranslation()
	const unpaidInvoicesCounter = useSelector(store => store.invoice.unpaidInvoicesCounter)

	const tabs = [
		{ value: 'ORDERS', title: t('order.tabs.all_orders') },
		{ value: 'INVOICES', title: t('order.tabs.invoices'), counter: unpaidInvoicesCounter }
	]

	return (
		<TabsWrapper>
			<TabsContent>
				{tabs.map(tab => {
					const layout = (activeTab === tab.value ? 'active ' : '') + (tab.type ? tab.type : '')
					return <Tab key={tab.value} onClick={() => onTabClick(tab.value)}
											className={layout}>
						{tab.title}

						{tab.counter > 0 && <TabCounter className={layout}>
							<TabCounterText className={layout} data-testid="pre-order-counter">{tab.counter}</TabCounterText>
						</TabCounter>}
					</Tab>
				})}
			</TabsContent>
		</TabsWrapper>
	)
})

Tabs.displayName = 'Tabs'
export default Tabs
