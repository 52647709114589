import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const MonthlyReport = lazyWithRetry(() => import(/* webpackChunkName: "z-report" */'../../component/Report/form/monthly'))

const MonthlyReportPage = React.memo(() => {
	return (
		<Suspense>
			<MonthlyReport />
		</Suspense>
	)
})

MonthlyReportPage.displayName = 'MonthlyReportPage'
export default MonthlyReportPage
