import React, { useEffect, useMemo } from 'react'
import 'react-toastify/dist/ReactToastify.min.css'
import { startClientMonitorThunk } from '../../redux/thunk/clientMonitorConfig'
import { isClientMonitor } from '../../helper/clientMonitor'
import { selectCompanyIndex, selectHasAccessToken } from '../../redux/selector/system'
import { useDispatch, useSelector } from 'react-redux'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { isCashRegisterMode } from '../../helper/mode'
import { getClientMonitorCartStateThunk, updateClientMonitorCartThunk } from '../../redux/thunk/clientMonitorCart'
import { fromLocalStorageValue } from '../../helper/localStorage/fromLocalStorageValue'

const ClientMonitorWindow = React.memo(() => {
	const hasAccessToken = useSelector(selectHasAccessToken)
	const companyIndex = useSelector(selectCompanyIndex)
	const dispatch = useDispatch()
	const [desktopMode] = useLocalStorage('desktopMode', null)
	const clientMonitorIsActive = useSelector(state => state.clientMonitorConfig.config?.enabled)
	const desktopDeviceId = fromLocalStorageValue(window.localStorage.desktopDeviceId)

	const cashRegisterMode = useMemo(() => isCashRegisterMode(desktopMode), [desktopMode])
	const isClientMonitr = useMemo(() => isClientMonitor(), [])
	const isAuthorized = hasAccessToken && companyIndex

	useEffect(() => {
		if (isAuthorized && cashRegisterMode && !isClientMonitr) {
			dispatch(startClientMonitorThunk())
		}
	}, [isAuthorized, isClientMonitr, cashRegisterMode]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const fetchAndUpdateCartState = async () => {
			if (isAuthorized && clientMonitorIsActive) {
				const data = await dispatch(getClientMonitorCartStateThunk(desktopDeviceId))

				if (data?.purchaseItems?.length) {
					dispatch(updateClientMonitorCartThunk('CASH_DESK', () => {}))
					dispatch(updateClientMonitorCartThunk('DELIVERY', () => {}))
				}
			}
		}

		fetchAndUpdateCartState()
	}, [isAuthorized, clientMonitorIsActive]) // eslint-disable-line react-hooks/exhaustive-deps

	return null
	})

	ClientMonitorWindow.displayName = 'ClientMonitorWindow'
	export default ClientMonitorWindow
