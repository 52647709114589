import React, { useState } from 'react'
import { AccordionBody, AccordionButton, AccordionHeader, AccordionWrapper } from './index.styled'
import MainIcon from '../../../img/svg/main'

const Accordion = ({ titleWhenOpen, titleWhenClose, titleIcon, children, size, color, outline = false }) => {
	const [isOpen, setIsOpen] = useState(false)
	const title = isOpen ? titleWhenOpen : titleWhenClose
	const layout = (size ? size + ' ' : '') + (color ? color + ' ' : '') + (outline ? ' outline ' : '')

	const handleOpen = () => setIsOpen(!isOpen)

	return (
		<AccordionWrapper className={layout}>
			<AccordionHeader className={layout}>
				<AccordionButton onClick={handleOpen} className={layout}>
					{titleIcon && <MainIcon icon={titleIcon} />}
					<span>{title}</span>
					<div className={isOpen ? 'accordion_arrow open_accordion' : 'accordion_arrow'}>
						<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1L7 6L13 1" strokeWidth="2"/>
						</svg>
					</div>
				</AccordionButton>
			</AccordionHeader>
			<AccordionBody className={isOpen ? 'open_accordion' : ''}>
				{children}
			</AccordionBody>
		</AccordionWrapper>

	)
}
Accordion.displayName = 'Accordion'
export default Accordion
