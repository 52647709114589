import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const BusinessScopeDiscount = lazyWithRetry(() => import(/* webpackChunkName: "business-scope-disc" */'../../component/BusinessScopeDiscount'))

const BusinessScopeDiscountPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<BusinessScopeDiscount />
		</Suspense>
	)
})

BusinessScopeDiscountPage.displayName = 'BusinessScopeDiscountPage'
export default BusinessScopeDiscountPage
