import styled from 'styled-components'
import { theme } from '../../style/theme'
import { Rnd } from 'react-rnd'
import { colorTheme } from '../../style/theme/colorTheme'

const HEADER_HEIGHT = 3.75
const FOOTER_HEIGHT = 5.94
export const RestRnd = styled(Rnd)`
	padding: 20px;
`

export const RestTables = styled.div`
	display: grid;
	grid-template-columns: max-content max-content auto;
	height: 100%;

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: auto;
	}
`

export const RestTableIconRotate = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	&:hover {
		cursor: pointer;
	}

	svg {
		width: 1.55em;
		height: 1.55em;
		border: none;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
`

export const RestWallIcon = styled.div`
	&:hover {
		cursor: pointer;
	}

	svg {
		width: 1.25em;
		height: 1.25em;
		border: none;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
`
export const RestWall = styled.div`
	border: 1px ${colorTheme.restaurantSettings.wall.border} solid;
	text-align: center;
	height: 100%;
	width: 100%;
	background-color: ${props => props.color};
	cursor: move;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const RestTablesHeader = styled.div`
	margin: 2.5em 0 5px;
	padding: 0 1.25em;
	font-size: 1rem;
	display: grid;
	grid-template-rows: auto auto 1fr;
	gap: 1.25em;
`

export const RestTablesWrapper = styled.div`
	font-size: 1rem;
	color: ${colorTheme.restaurantSettings.text};
	position: relative;
	width: 100%;
	height: calc(100vh - ${HEADER_HEIGHT}em - ${FOOTER_HEIGHT}em);
	overflow: auto;
	background-size: ${props => props.check ? '20px 20px, 20px 20px, auto' : 'auto'};
	background-image: ${props => props.check ? `linear-gradient(90deg, ${props.gridcolor} 1px, transparent 0),
	linear-gradient(${props.gridcolor} 1px, transparent 0)` : 'none'}, url(${props => props.bgdImg ? props.bgdImg : ''});
	background-color: ${props => props.bgdColor};

	@media screen and (max-width: ${theme.point720}) {
		margin: 1em auto;
	}
`

export const FormInputs = styled.div`
	font-size: 1rem;
	display: grid;
	gap: 1.2em;
	grid-template-columns: repeat(3, 1fr );
	align-items: start;
	margin-bottom: 1.2em;
	margin-top: 1.2em;

	&.half {
		width: 50%;
	}
`

export const FormButtons = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-content: space-between;
	gap: 1.2em;
`
export const RestTablesColorArea = styled.div`
	width: 2.56em;
	height: 100%;
	flex-shrink: 0;
	border-right: 5px solid ${colorTheme.restaurantSettings.colorArea.border};
	background: ${props => props.color || colorTheme.restaurantSettings.colorArea.default};

	&.br-left {
		border-right: none;
		border-left: 5px solid ${colorTheme.restaurantSettings.colorArea.border};
	}
`
export const RadioInputWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	gap: 1.25em;
	align-self: stretch;
	margin-bottom: 1.25em;
`
RadioInputWrap.displayName = 'RadioInputWrap'

export const RadiLabelWrap = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	color: ${colorTheme.input.radio.text}
`
RadiLabelWrap.displayName = 'RadiLabelWrap'

export const RadioExtraText = styled.div`
	font-size: 0.88em;
`
RadioExtraText.displayName = 'RadioExtraText'

export const DisplayTable = styled.div`
	font-size: 1rem;
	min-height: 12.5em;
	display: flex;
	padding: 1.25em;
	justify-content: center;
	align-items: center;
	gap: .63em;
	align-self: stretch;
	border-radius: .63em;
	background: ${colorTheme.restaurantSettings.modal.background};
	margin-bottom: 1.25em;
`
DisplayTable.displayName = 'DisplayTable'

export const TextInputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: .31em;
	flex: 1 0 0;
`
TextInputWrapper.displayName = 'TextInputWrapper'

export const SettingWallFormContent = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 1.25em;
	column-gap: .63em;

	&.mg-top {
		margin-top: 2.5em;
	}
`
SettingWallFormContent.displayName = 'SettingWallFormContent'

export const CreateTablesInfoWrap = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	gap: 2.5em;
	align-items: center;
	justify-content: center;
	height: auto;
	position: relative;
	margin-top: 13.38em;
	background: ${colorTheme.restaurantSettings.modal.backgroundInfo};

	> svg {
		width: 11.31em;
		height: 11.56em;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 5.38em;
	}
`
CreateTablesInfoWrap.displayName = 'CreateTablesInfoWrap'

export const CreateTablesInfoText = styled.div`
	width: 15.33em;
	font-size: 1.5em;
	color: ${colorTheme.restaurantSettings.modal.textInfo};
	text-align: center;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	font-family: ${theme.fontRoboto};
`
CreateTablesInfoText.displayName = 'CreateTablesInfoText'
