import styled from 'styled-components'
import colors from '../../../../../style/colors'
import { theme } from '../../../../../style/theme'

export const MoneyExchangeModalWrapper = styled.div`
	font-size: 1em;
	display: flex;
	flex-direction: column;
	gap: 0.63em;

	input {
		text-align: center !important;
		text-transform: none;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0;
	}
`
MoneyExchangeModalWrapper.displayName = 'MoneyExchangeModalWrapper'

export const MoneyExchangeList = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 0.63em;
`
MoneyExchangeList.displayName = 'MoneyExchangeList'

export const MoneyExchangeItem = styled.button`
	border-radius: 0.43em;
	width: 100%;
	border: 1px solid ${colors.gray_dark};
	padding: 1em;
	background-color: transparent;
	color: ${colors.gray_dark};
	font-size: 1.75em;
	font-family: ${theme.fontRobotoMedium};
	user-select: none;
	cursor: pointer;
	transition: all 0.2s ease 0s;

	&:hover {
		box-shadow: 0 0 0.93em 0.18em rgba(10, 28, 63, 0.34);
		z-index: 1;
	}

	&.noHover {
		box-shadow: none;
		z-index: 0;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.5em;
		padding: 0;
		width: 100%;
		min-height: 3.33em;
	}
`
MoneyExchangeItem.displayName = 'MoneyExchangeItem'

export const GetMoneyInputWrap = styled.div`
  font-size: 1em;
  position: relative;
	width: 100%;
	height: 4.19em;
	border: 2px solid ${colors.blue};
	border-radius: 0.75em;
	padding: 0.3em 3.25em 0.3em 1.25em;
	box-shadow: 0 0 59px rgba(0, 0, 0, 0.31);
	display: flex;
	align-items: center;

  .keyboard {
    font-size: 1em;
    position: absolute;
    right: 0.94em;
    top: calc(50% - 0.7em);
    cursor: pointer;
    width: 2.31em;
    height: 1.44em;
  }

	@media screen and (max-width: ${theme.point720}) {
		height: 3.43em;
		padding-right: 1.25em;

		.keyboard {
			display: none;
		}
	}
`
GetMoneyInputWrap.displayName = 'GetMoneyInputWrap'

export const GetMoneyInputText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	text-transform: uppercase;
	text-align: left;
	color: ${colors.blue};

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.25em;
	}
`
GetMoneyInputText.displayName = 'GetMoneyInputText'

export const MoneyExchangeButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.88em 0;

  @media screen and (max-width: ${theme.point820}) {
		padding: 1.88em 0;
		flex-direction: column;
		gap: 1.25em;
  }
`
MoneyExchangeButtonsWrapper.displayName = 'MoneyExchangeButtonsWrapper'
