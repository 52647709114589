import styled from 'styled-components'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../style/theme'

export const RemarkInputWrap = styled.div`
	display: flex;
	gap: 0.63em;
	margin: 1.25em 5em;

	@media screen and (max-width: ${theme.point820}) {
		flex-direction: column;
		gap: 0.72em;
		margin: 0.72em;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;
		margin: 1.25em;
	}
`
RemarkInputWrap.displayName = 'RemarkInputWrap'

export const RemarkWithKeyboardContent = styled.div`
	position: absolute;
	width: 100%;
	background: ${colorTheme.cartModal.modalSize.remarkInput.keyboard.background};
	bottom: 0;
	box-shadow: 1.256px 17.956px 50px 0px rgba(0, 0, 0, 0.25);

	@media screen and (max-width: ${theme.point820}) {
		box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.30);
		border-top: 1px solid ${colorTheme.cartModal.modalSize.remarkInput.keyboard.border};
	}
`
RemarkWithKeyboardContent.displayName = 'RemarkWithKeyboardContent'

export const RemarkWithKeyboardWrap = styled.div`
	position: fixed;
	font-size: 1rem;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	backdrop-filter: blur(5px);

	&.modal-enter-done {
		opacity: 1;
		z-index: 1000;
	}

	&.modal-enter {
		opacity: 0;
	}

	&.modal-enter-active {
		opacity: 1;
		z-index: 1000;
	}

	&.modal-exit {
		opacity: 1;
		z-index: 1000;
	}

	&.modal-exit-active {
		opacity: 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		position: sticky;
		height: auto;
		width: 100%;
		grid-column: 1 / 4;
		bottom: 0;
		backdrop-filter: none;

		&.modal-enter {
			transform: translateY(100%);
		}

		&.modal-enter-active {
			opacity: 1;
			transform: translateY(0);
		}

		&.modal-exit {
			transform: translateY(0);
		}

		&.modal-exit-active {
			opacity: 1;
			transform: translateY(100%);
		}
	}
`
RemarkWithKeyboardWrap.displayName = 'RemarkWithKeyboardWrap'

export const CloseButtonWrap = styled.div`
	position: absolute;
	top: -3.38em;
	right: 0.25em;
	display: flex;
	width: 3em;
	height: 3em;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: ${colorTheme.keyboard.close.background};
	box-shadow: 0 4px 150px rgba(0, 0, 0, 0.41);
	cursor: pointer;

	> svg {
		width: 1.06em;
		height: 1.06em;
		fill: ${colorTheme.keyboard.close.icon};
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 100%;
		height: 100%;
		position: static;
		background-color: ${colorTheme.keyboard.close.background};
		border-radius: 0.63em;
		display: flex;
		justify-content: center;
		align-items: center;
		user-select: none;
		cursor: pointer;
		grid-column: 4;
		grid-row: 5;
		border: 2px solid ${colorTheme.keyboard.close.border};
		min-height: 2.81em;
	}
`
CloseButtonWrap.displayName = 'CloseButtonWrap'
