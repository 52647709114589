import React from 'react'
import { MinusButton, PlusButton, PlusMinusWrap } from './index.styled'

const PlusMinus = React.memo(({ AddButton, onClickPlus, onClickMinus, incrementAbility, removeAbility, outline, size, color, children, plusBtnColor }) => {
	const layout = (outline ? 'outline' : '') + (size ? ' ' + size : '') + (color ? ' ' + color : '')

	return (
		<PlusMinusWrap className={(incrementAbility && removeAbility ? 'double ' : '') + layout}>
			{removeAbility &&
				<MinusButton className={'minus ' + layout} onClick={onClickMinus} data-testid="minus">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="4" viewBox="0 0 10.781 1.844">
						<path d="M486.363,202.33h-8.948a0.915,0.915,0,1,1,0-1.83h8.948A0.915,0.915,0,1,1,486.363,202.33Z" transform="translate(-476.5 -200.5)" />
					</svg>
				</MinusButton>}
			{children}
			{incrementAbility &&
				<PlusButton className={'plus ' + layout + (plusBtnColor ? ' ' + plusBtnColor : '')} onClick={onClickPlus} data-testid="plus">
					{AddButton ?
						<AddButton /> :
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11.625 11.625">
							<path d="M324.433,202.056l4.309-.018a0.754,0.754,0,0,0,.752-0.752,0.743,0.743,0,0,0-.747-0.748l-4.31.019,0.019-4.31a0.746,0.746,0,0,0-.748-0.748,0.756,0.756,0,0,0-.752.753l-0.019,4.309-4.309.019a0.754,0.754,0,0,0-.752.752,0.741,0.741,0,0,0,.747.747l4.31-.018-0.019,4.309a0.744,0.744,0,0,0,.748.748,0.754,0.754,0,0,0,.752-0.752Z" transform="translate(-317.875 -195.5)" />
						</svg>}
				</PlusButton>}
		</PlusMinusWrap>
	)
})

PlusMinus.displayName = 'PlusMinus'
export default PlusMinus
