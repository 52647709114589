import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const IncomingCallWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 0.5fr min-content;
	align-items: center;
	height: 6em;
	padding: 0.31em 1.25em;
	background-color: ${colorTheme.incomeCallModal.selectedCall.background};
	border-radius: 1.25em;
	border: 2px solid ${colorTheme.incomeCallModal.selectedCall.active};
	box-shadow: 0px 4px 4px 0px ${colorTheme.incomeCallModal.selectedCall.shadow};

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: 1fr min-content;
		min-height: 6em;
		gap: 0 0.71em;
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: 5.56em;
		gap: 0 0.63em;
		padding: 0.63em 0.94em;
	}
`
IncomingCallWrapper.displayName = 'IncomingCallWrapper'
export const IncomingCallPhone = styled.div`
	display: flex;
	gap: 0.44em;
	align-items: center;
`
IncomingCallPhone.displayName = 'IncomingCallPhone'

export const IncomingCallPhoneText = styled.div`
	color: ${colorTheme.incomeCallModal.selectedCall.text};
	font-family: ${theme.fontRoboto};
	font-size: 1.88em;
	font-weight: 400;

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.75em;
	}
`
IncomingCallPhoneText.displayName = 'IncomingCallPhoneText'

export const IncomingCallAddress = styled.div`
	display: flex;
	flex-direction: column;
	color: ${colorTheme.incomeCallModal.selectedCall.text};
	font-family: ${theme.fontRoboto};
	font-size: 1em;
	font-weight: 400;
	justify-self: start;
	text-align: left;

	@media screen and (max-width: ${theme.point820}) {
		flex-direction: row;
		grid-column: 1 / 3;
		padding-left: 4em;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding-left: 3.75em;
	}
`
IncomingCallAddress.displayName = 'IncomingCallAddress'
export const IncomingCallTime = styled.div`
	color: ${colorTheme.incomeCallModal.selectedCall.active};
	font-family: ${theme.fontRoboto};
	font-size: 1.5em;
	font-weight: 400;

	@media screen and (max-width: ${theme.point820}) {
		grid-row: 1;
		grid-column: 2;
	}
`
IncomingCallTime.displayName = 'IncomingCallTime'
