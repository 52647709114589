import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setAppModal } from '../../../../redux/action/system'
import { ModalContent, ModalInfoItem, OrderInfo, OrderInfoBlock, PreOrderIconWrap } from './../index.styled'
import { getNeededDateFormat } from '../../../../helper'
import Currency from '../../../Elements/currency'
import colors from '../../../../style/colors'
import CustomModal from '../../../Elements/modal/CustomModal'
import MainIcon from '../../../../img/svg/main'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'
import DenyStatusButtons from '../statusButtons'
import PlatformIcon from '../../../../img/svg/platforms'
import { denyPreOrderThunk } from '../../../../redux/thunk/order/confirmPreOrder'
import KeyBindings from '../../../Elements/keybindings'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { denyStatusButtons } from '../constants'
import { useTheme } from 'styled-components'

const EVENT_Z_INDEX = 987

const DenyPreOrderModal = React.memo(() => {
	const { t } = useTranslation()
	const { colorTheme } = useTheme()
	const denyPreOrderModalShow = useSelector(store => store.system.modal?.denyPreOrderModalShow)
	const order = useSelector(store => store.system.modalData?.confirmPreOrderModalData[0])
	const isProcessingDenyOrder = useSelector(store => store.order.isProcessingDenyOrder)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const dispatch = useDispatch()
	const isMobile = useWindowBreakPoint(theme.point720)
	const [denyActiveButtonIndex, setDenyActiveButtonIndex] =  useState(null)
	const staticDenyStatusButtonsCount = denyStatusButtons.length
	const geoData = order?.customer?.geoData

	const handleClose = useCallback(() => {
		dispatch(setAppModal({ denyPreOrderModalShow: false }))
		dispatch(setAppModal({ confirmPreOrderModalShow: true }))
		setDenyActiveButtonIndex(null)
	}, [dispatch])

	const handleDeny = useCallback((status) => {
		dispatch(denyPreOrderThunk(order?.orderId, status))
		dispatch(setAppModal({ denyPreOrderModalShow: false }))
	}, [order?.orderId, dispatch])

	const handleArrowRight = useCallback(() => {
		if (denyActiveButtonIndex === null || (denyActiveButtonIndex + 1 >= staticDenyStatusButtonsCount)) {
			setDenyActiveButtonIndex(0)
		} else {
			setDenyActiveButtonIndex(prevIndex => prevIndex + 1)
		}
	}, [denyActiveButtonIndex, staticDenyStatusButtonsCount])

	const handleArrowLeft = useCallback(() => {
		if (denyActiveButtonIndex === null || (denyActiveButtonIndex - 1 < 0)) {
			setDenyActiveButtonIndex(staticDenyStatusButtonsCount - 1)
		} else {
			setDenyActiveButtonIndex(prevIndex => prevIndex - 1)
		}
	}, [denyActiveButtonIndex, staticDenyStatusButtonsCount])

	const handleEnter = useCallback(() => {
		handleDeny(denyStatusButtons[denyActiveButtonIndex].status)
	}, [denyActiveButtonIndex, handleDeny])

	return (
		<>
			<CustomModal isOpen={denyPreOrderModalShow}
									 close={handleClose}
									 id="deny-pre-order-modal"
									 enableOutsideClick={false}
									 size="auto"
									 titleColor="red"
									 zIndex={EVENT_Z_INDEX}
									 title={t('Modal.deny_order_modal.preOrder')}>
				<ModalContent>
					<OrderInfo className="isPreOrder">
						{!isMobile && <PreOrderIconWrap>
							<MainIcon icon="big-calendar" />
						</PreOrderIconWrap>}
						<OrderInfoBlock>
							<ModalInfoItem>
								<MainIcon icon={order?.paymentMethod} fill={colors.gray} testId={order?.paymentMethod} />
								<p className="price"><Currency>{order?.totalPrice}</Currency></p>
							</ModalInfoItem>
						</OrderInfoBlock>
						<OrderInfoBlock>
							<ModalInfoItem>
								<MainIcon icon="restaurant-name" fill={colors.gray} />
								<PlatformIcon icon={`${order?.platformMetaData?.platformType}-${colorTheme}`} />
							</ModalInfoItem>
						</OrderInfoBlock>
						<OrderInfoBlock className={geoData ? 'column' : 'col-2'}>
							<ModalInfoItem className="small">
								<MainIcon icon="date" fill={colors.gray} />
								<p className="date">{getNeededDateFormat(order?.preOrder)}</p>
							</ModalInfoItem>
							<ModalInfoItem className="small">
								<MainIcon icon="time" fill={colors.gray} />
								<p className="time">{getNeededDateFormat(order?.preOrder, 'HH:mm') + ' UHR'}</p>
							</ModalInfoItem>
						</OrderInfoBlock>
						{geoData &&
							<OrderInfoBlock className="column">
								<ModalInfoItem className="small">
									<MainIcon icon="route" fill={colors.gray} />
									<p className="address">{order?.customer?.street} {order?.customer?.streetNumber}, {order?.customer?.postalCode} {order?.customer?.city}</p>
									<p className={geoData?.distance > 0 ? 'distance' : 'hidden'} data-testid="distance">{(geoData?.distance / 1000).toFixed(2)} km</p>
								</ModalInfoItem>
								{geoData?.duration > 0 &&
									<ModalInfoItem className="small">
										<MainIcon icon="time-left" fill={colors.gray} />
										<p className="duration" data-testid="duration">{Math.round(geoData?.duration / 60)} min</p>
									</ModalInfoItem>}
							</OrderInfoBlock>}
					</OrderInfo>
					<DenyStatusButtons handleDeny={handleDeny} activeButtonIndex={denyActiveButtonIndex} disabled={isProcessingDenyOrder} />
				</ModalContent>
			</CustomModal>

			{(isNeededZIndex && !isProcessingDenyOrder) &&
				<KeyBindings enter={handleEnter} arrowRight={handleArrowRight} arrowLeft={handleArrowLeft} />}
		</>
	)
})

DenyPreOrderModal.displayName = 'DenyPreOrderModal'
export default DenyPreOrderModal
