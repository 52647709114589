import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const PrinterConfigForm = lazyWithRetry(() => import(/* webpackChunkName: "printer-config" */'../../component/PrinterConfig/form'))

const PrinterConfigFormPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<PrinterConfigForm />
		</Suspense>
	)
})

PrinterConfigFormPage.displayName = 'PrinterConfigFormPage'
export default PrinterConfigFormPage
