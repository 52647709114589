import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const PermissionsUsers = lazyWithRetry(() => import(/* webpackChunkName: "permission-users" */'../../component/Settings/PermissionsUsers'))

const PermissionsUsersPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<PermissionsUsers />
		</Suspense>
	)
})

PermissionsUsersPage.displayName = 'PermissionsUsersPage'
export default PermissionsUsersPage
