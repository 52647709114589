import styled from 'styled-components'
import { theme } from '../../../style/theme'

export const ButtonWrapper = styled.div`
  font-size: 1rem;
  display: flex;
	justify-content: flex-end;
	margin-bottom: 0.63em;

  @media screen and (max-width: ${theme.point820}) {
		justify-content: center;
  }
`
ButtonWrapper.displayName = 'ButtonWrapper'

export const TodayPreOrdersTableTdListWrap = styled.div`


`
