import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Recovery = lazyWithRetry(() => import(/* webpackChunkName: "recovery" */'../../component/Recovery'))

const RecoveryPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader className="authPage" />}>
			<Recovery />
		</Suspense>
	)
})

RecoveryPage.displayName = 'RecoveryPage'
export default RecoveryPage
