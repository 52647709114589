import { SET_TAX_CONFIGURATION, SET_TAX_DIVIDE_CONFIGURATION, SET_TAX_SETTINGS } from '../../actionTypes/taxSettings'

const initialState = {
  taxSettings: [],
  taxConfiguration: [],
  taxDivideConfiguration: []
}

export function taxSettingsReducer (state = initialState, action) {
  switch (action.type) {
    case SET_TAX_SETTINGS:
      return {
        ...state,
        taxSettings: action.value,
      }
    case SET_TAX_CONFIGURATION:
      return {
        ...state,
        taxConfiguration: action.value,
      }
    case SET_TAX_DIVIDE_CONFIGURATION:
      return {
        ...state,
        taxDivideConfiguration: action.value,
      }
    default:
      return state
  }
}
