import styled from 'styled-components'
import { colorTheme } from '../../../style/theme/colorTheme'
import { theme } from '../../../style/theme'

export const HeaderTitleWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	gap: .63em;
`
HeaderTitleWrapper.displayName = 'HeaderTitleWrapper'

export const HeaderTablesColorArea = styled.div`
	width: 1.44em;
	height: 1.44em;
	border-radius: 50%;
	background-color: ${props => props.color || colorTheme.restaurantSettings.colorArea.default};
`
HeaderTablesColorArea.displayName = 'HeaderTablesColorArea'

export const HeaderTitleText = styled.div`
	font-size: 1.25em;
	color: ${colorTheme.restaurantSettings.text};
	font-style: normal;
	font-family: ${theme.fontRoboto};
	font-weight: 700;
	line-height: normal;

`
HeaderTitleText.displayName = 'HeaderTitleText'
