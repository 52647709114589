import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SplitGrid, TableOrders, TableOrdersHead, TableOrdersProducts, TableOrdersHeadWrapper, SplitButtons } from './index.styled'
import { setTableCartOrders } from '../../../../../redux/action/tableCart'
import { fetchTableOrdersThunk } from '../../../../../redux/thunk/restaurant/order'
import { getTablePrice } from '../../../../../helper'
import CartItem from '../../../../Elements/cart/CartItem'
import CustomModal from '../../../../Elements/modal/CustomModal'
import { setRestaurantOrdersMove,setRestaurantSwapModal,setRestaurantOrderSplitModal, setRestaurantOrderModal } from '../../../../../redux/action/restaurant'
import Button from '../../../../Elements/buttons/Button'
import colors from '../../../../../style/colors'
import { CartTableHead } from '../../../../Elements/cart/index.styled'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import { useWindowBreakPoint } from '../../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../../style/theme'

const EVENT_Z_INDEX = 41

const ModalOrderSplit = () => {
	const { t } = useTranslation()
	const { table, showOrderSplitModal } = useSelector(state => state.restaurant)
	const { orders } = useSelector(state => state.tableCart)
	const pinCodeModalLoginIsNeeded = useSelector(state => state.modalLogin.pinCodeModalLoginIsNeeded)
	const modalLoginUsername = useSelector(state => state.modalLogin.username)
	const dispatch = useDispatch()
	const [split, setSplit] = useState({ items: [], totalPrice: 0 })
	const [touchPad] = useLocalStorage('touchPad', false)
	const isMobile = useWindowBreakPoint(theme.point720)

	const closeModal = () => {
		dispatch(setRestaurantOrderSplitModal(false))
		setSplit({ items: [], totalPrice: 0 })
	}

	useEffect(() => {
		if (!modalLoginUsername && pinCodeModalLoginIsNeeded) {
			closeModal()
		}
	}, [modalLoginUsername, pinCodeModalLoginIsNeeded])

	useEffect(() => {
		if (showOrderSplitModal) {
			setSplit({ items: [], totalPrice: 0 })
			dispatch(fetchTableOrdersThunk(table?.id, true, 'BEING_PREPARED'))
		}
	}, [table?.id, showOrderSplitModal])

	const addItemToSplit = (event, item, orderIndex, itemIndex) => {
		event?.stopPropagation()
		const ordersCopy = [...orders]
		const removedItem = ordersCopy[orderIndex].items.splice(itemIndex, 1)[0]
		dispatch(setTableCartOrders(ordersCopy))
		const splitCopy = { ...split }
		removedItem.orderIndex = orderIndex
		splitCopy.items.push(removedItem)
	}

	const removeItemFromSplit = (itemIndex) => {

		const splitCopy = { ...split }
		const removedItem = splitCopy.items.splice(itemIndex, 1)[0]
		let ordersCopy = [...orders]
		if (ordersCopy.length > 0) {
				ordersCopy[removedItem.orderIndex]?.items.push(removedItem)
		}
		dispatch(setTableCartOrders(ordersCopy))
	}

	const clearSplit = () => {
		setSplit({ items: [], totalPrice: 0 })
	}

	const createSplit = () => {
		const splitItems = split.items.map(item => ({ orderId: orders[item.orderIndex].id, itemPositionId: item.itemPositionId }))
		if (splitItems.length)
			dispatch(setRestaurantOrdersMove(splitItems))
		else dispatch(setRestaurantOrdersMove(null))
		dispatch(setRestaurantOrderSplitModal(false))
		dispatch(setRestaurantSwapModal(true))
		clearSplit()
	}

	const navigateBack = () => {
		dispatch(setRestaurantOrderSplitModal(false))
		dispatch(setRestaurantOrderModal(true))
	}

	const showCreateSplitButton = !split.id && split.items?.length > 0

	const recalcOrder = (items=[]) => {
		return items.reduce((res,el)=>{
		let curArrItem = res.filter(x=>x.itemId===el.itemId)
		if (curArrItem.length===0)
		   return [...res,el]
		else {
		   let curitem = curArrItem[0]
		   return [...res.filter(x=>x.itemId!==el.itemId),{...curitem,quantity:curitem.quantity+el.quantity}]
		}
	  } ,[])
	}

	return (
		<>
			<CustomModal isOpen={showOrderSplitModal}
									 close={closeModal}
									 title={'Splitt Tisch #' + (table?.number || '')}
									 titlePrice={getTablePrice(table) || null}
									 size="large"
									 zIndex={EVENT_Z_INDEX}>
				{!isMobile && <TableOrdersHeadWrapper>
					<TableOrdersHead>{t('restaurant.tables.modal.share_order')}</TableOrdersHead>
					<TableOrdersHead>{t('restaurant.tables.modal.split_order')}</TableOrdersHead>
				</TableOrdersHeadWrapper>}
				<SplitGrid>
					{isMobile && <TableOrdersHead>{t('restaurant.tables.modal.share_order')}</TableOrdersHead>}
					<TableOrders>
						<CartTableHead className={touchPad ? 'touchPad' : ''}>
							<div>{t('Cart.name')}</div>
							<div></div>
							<div>{t('Cart.price')}</div>
							<div>{t('Cart.total')}</div>
						</CartTableHead>
						<TableOrdersProducts>
							{orders?.map((order, orderIndex) =>
								recalcOrder(order.items).map((item, itemIndex) =>
									<CartItem key={`${orderIndex}-${itemIndex}`}
														removeAbility={false}
														item={item}
														orderTask={true}
														onClickPlus={event => addItemToSplit(event, item, orderIndex, itemIndex)}
														plusBtnColor="green"
														AddButton={() =>
															<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M1 15L8 7.99746L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
															</svg>} />))}
						</TableOrdersProducts>
					</TableOrders>
					{isMobile && <TableOrdersHead>{t('restaurant.tables.modal.split_order')}</TableOrdersHead>}
					<TableOrders color={showCreateSplitButton ? colors.green : ''}>
						<CartTableHead className={touchPad ? 'touchPad' : ''}>
							<div>{t('Cart.name')}</div>
							<div></div>
							<div>{t('Cart.price')}</div>
							<div>{t('Cart.total')}</div>
						</CartTableHead>
						<TableOrdersProducts >
							{recalcOrder(split?.items).map((item, itemIndex) =>
								<CartItem key={itemIndex}
													removeAbility={false}
													item={item}
													orderTask={true}
													onClickPlus={() => removeItemFromSplit(itemIndex)}
													AddButton={() =>
														<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M8 1L1 8.00254L8 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
														</svg>} />)}
						</TableOrdersProducts>
					</TableOrders>
				</SplitGrid>
				<SplitButtons>
					<Button name="back"  icon="back" text={t('buttons.controls.back')} keyButton="Escape" keyText="esc" onClick={navigateBack} />
					<Button name="save" icon="checkmark" text={t('restaurant.tables.modal.create_split')} keyButton="F2" color="green" onClick={createSplit} />
				</SplitButtons>
			</CustomModal>
		</>
	)
}

ModalOrderSplit.displayName = 'ModalOrderSplit'
export default ModalOrderSplit
