import { setAppApiHere, setAppErrors, setAppFormChanged, setAppFormChangedModal, setAppNavigateBool, setAppNavigateTo, setSystemFormButtonSave } from '../../action/system'
import getGeoApiKey from '../../axios/geoData/GetGeoApiKey'
import { notifyToast, retrieveErrorFromApi } from '../../../helper'
import deleteAllProduct from '../../axios/product/deleteAll'
import deleteAllCategories from '../../axios/category/deleteAll'

export const resetAppFormChangedThunk = () => (dispatch) => {
	dispatch(setAppFormChangedModal(false))
	dispatch(setAppFormChanged(false))
}

export const appNavigationThunk = (navigate) => (dispatch, getState) => {
	const navigateTo = getState().system.navigate.to
	if (navigateTo) {
		navigate(navigateTo)
		dispatch(resetAppNavigationThunk())
	}
}

export const resetAppNavigationThunk = () => (dispatch) => {
	dispatch(setAppNavigateBool(false))
	dispatch(setAppNavigateTo(null))
}

export const getHereApiKeyThunk = () => (dispatch, getState) => {
	if (getState().system.apiKeys.here_api) return
	dispatch(getGeoApiKey(
		res => {
			if (res.data.hereApiKey && window.location.hostname !== 'localhost') {
				dispatch(setAppApiHere(res.data.hereApiKey))
			} else {
				dispatch(setAppApiHere('G-H6VkellagW4xhAFZbCp3fDshThoD77ck5KGGb6Eyw'))
			}
		},
		error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}))
}

export const deleteProductAndCategoriesThunk = () => (dispatch) => {
	dispatch(deleteAllProduct(
		() => {
			notifyToast({ message: 'Products successfully deleted' })
			return deleteAllCategories()
				.then(() => {
					notifyToast({ message: 'CartCategories successfully deleted' })
				}).catch(error => {
					dispatch(setAppErrors(retrieveErrorFromApi(error)))
				})
		},
		error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}))
}

export const resetSystemFormButtonSaveThunk = () => (dispatch) => {
	dispatch(setSystemFormButtonSave({ processing: false }))
}

export const succeedSystemFormButtonSaveThunk = () => (dispatch) => {
	setTimeout(() => dispatch(setSystemFormButtonSave({ processing: false, success: true })), 800)
}
