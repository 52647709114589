import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const ApiRestaurant = lazyWithRetry(() => import(/* webpackChunkName: "api-restaurant" */'../../component/ApiRestaurant'))

const ApiRestaurantPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<ApiRestaurant />
		</Suspense>
	)
})

ApiRestaurantPage.displayName = 'ApiRestaurantPage'
export default ApiRestaurantPage
