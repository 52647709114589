import React, { useCallback } from 'react'
import { ProductNr, ProductPrice, ProductTitle, ProductWrap } from './index.styled'
import Currency from '../../../../currency'
import { getPosPriceByIndex } from '../../../../../../helper'
import PlusMinus from '../../../CartItem/PlusMinus'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage'
import { getColorsForCart } from '../../../../../../helper/getColorsForCart'

const Product = React.memo(({ active, item, index, orderType, category, onClick, onMouseOver, onClickPlus, onClickMinus, isDisabled }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const [colorTheme] = useLocalStorage('colorTheme', 'LIGHT')
	const isTopping = item.productType === 'TOPPING'
	const layout = (touchPad === true ? 'touchPad ' : '') + (active ? 'active ' : '') + (isTopping ? 'topping ' : '') + (colorTheme === 'DARK' ? 'thinkBorder ' : '')
	const parentCategory = category?.parent
	const colorCategory = parentCategory ? parentCategory?.colorData?.color : category?.colorData?.color
	const themeCategory = parentCategory ? parentCategory.colorData?.theme : category?.colorData?.theme
	const themeStyles = getColorsForCart(colorCategory, themeCategory, colorTheme, touchPad)

	const handleClick = useCallback(e => {
		if (isDisabled) return
		onClick(e, item, index)
	}, [item, index, onClick, isDisabled])

	const handleMouseOver = useCallback(e => {
		onMouseOver(index)
	}, [index, onMouseOver])

	const handlePlusClick = useCallback(e => {
		if (isDisabled) return
		onClickPlus(e, item, index)
	}, [item, index, onClickPlus, isDisabled])

	const handleMinusClick = useCallback(e => {
		onClickMinus(e, item, index)
	}, [item, index, onClickMinus])

	return (
		<ProductWrap className={layout + ' product-wrapper'}
								 themeStyles={themeStyles}
								 tabIndex={10 + index}
								 onClick={handleClick}
								 onMouseOver={handleMouseOver}>
			<ProductNr className={layout + (!item.nr ? ' empty' : '')}>{item.nr}</ProductNr>
			<ProductTitle className={layout}>{item.title}</ProductTitle>
			<ProductPrice className={layout}>
				<div><Currency>{getPosPriceByIndex(item, orderType, 0)?.value}</Currency></div>
				<div>
					<PlusMinus {...{
						onClickPlus: handlePlusClick, onClickMinus: handleMinusClick,
						incrementAbility: true, removeAbility: isTopping, outline: isTopping
					}} />
				</div>
			</ProductPrice>
		</ProductWrap>
	)
})

Product.displayName = 'Product'
export default Product
