import React from 'react'
import { useSelector } from 'react-redux'
import { OrderCost, OrderDelivery, OrderRate, OrderSubTitle, OrderTitle, OrderTotalsWrap, OrderTotalWrapper } from '../index.styled'
import { selectCurrencySymbol } from '../../../../../../../../redux/selector/system'
import { useTranslation } from 'react-i18next'
import Currency from '../../../../../../currency'
import { getFormattedCurrency } from '../../../../../../../../helper'

const InvoiceOrderTotals = React.memo(({ order }) => {
	const { t } = useTranslation()
	const currency = useSelector(selectCurrencySymbol)
	const orderExchangeRate = order?.exchangeRateMetaData?.rate
	const orderExchangeCurrency = order?.exchangeRateMetaData?.currency
	const exchangeRateCurrency = getFormattedCurrency(orderExchangeRate, 'de-DE', orderExchangeCurrency)
	const coupon = order?.purchaseItems?.filter(item => item?.type === 'MARKETING_COUPON')?.[0]
	const extraDiscount = order?.purchaseItems?.filter(item => item?.type === 'EXTRA_DISCOUNT')?.[0]
	const discount = order?.purchaseItems?.filter(item => item?.type === 'DISCOUNT')?.[0]
	const uberEatPlatformFee = order?.purchaseItems?.filter(item => item?.type === 'PLATFORM_FEE')?.[0]
	const deliveryCost = order?.purchaseItems?.filter(item => item?.type === 'DELIVERY_COST')?.[0]

	return (
		<OrderTotalsWrap>
			<OrderDelivery>
				{deliveryCost && <OrderCost>
					<OrderSubTitle className="medium">{t('invoices.modal.invoiceDataModal.order.directions')}: </OrderSubTitle>
					<OrderSubTitle className="medium"><Currency>{deliveryCost?.price}</Currency></OrderSubTitle>
				</OrderCost>}
				{uberEatPlatformFee &&
					<OrderCost data-testid="order-uber-platform-fee">
						<OrderSubTitle className="medium">UberEats {t('invoices.modal.invoiceDataModal.order.payment_fee')}: </OrderSubTitle>
						<OrderSubTitle className="medium"><Currency>{uberEatPlatformFee?.price}</Currency></OrderSubTitle>
					</OrderCost>}
				{discount && <OrderCost data-testid="order-discount">
					<OrderSubTitle className="medium">{t('invoices.modal.invoiceDataModal.order.discount')}: </OrderSubTitle>
					<OrderSubTitle className="medium"><Currency>{Math.abs(discount?.price)}</Currency></OrderSubTitle>
				</OrderCost>}
				{extraDiscount && <OrderCost data-testid="order-extra-discount">
					<OrderSubTitle className="medium">{extraDiscount.name}: </OrderSubTitle>
					<OrderSubTitle className="medium"><Currency>{Math.abs(extraDiscount?.price)}</Currency></OrderSubTitle>
				</OrderCost>}
				{coupon && <OrderCost data-testid="order-coupon">
					<OrderSubTitle className="medium">{t('invoices.modal.invoiceDataModal.order.coupon')} {coupon?.name}</OrderSubTitle>
					<OrderSubTitle className="medium"><Currency>{coupon?.price}</Currency></OrderSubTitle>
				</OrderCost>}
			</OrderDelivery>
			{order?.tips > 0 && (
				<OrderSubTitle data-testid="order-tips" className="medium right bold">{t('invoices.modal.invoiceDataModal.order.tips')}: <Currency>{order?.tips}</Currency></OrderSubTitle>
			)}
			{order?.paymentFee > 0 && (
				<OrderSubTitle className="medium right bold">{t('invoices.modal.invoiceDataModal.order.payment_fee')}: <Currency>{order?.paymentFee}</Currency></OrderSubTitle>
			)}
			<OrderCost className="cost">
				<OrderTitle className="bold">{order?.payments?.map((payment, i) =>
					<div key={i}>{t('invoices.modal.invoiceDataModal.order.total')}:</div>)}</OrderTitle>
				<OrderTitle className="bold">{order?.payments?.map((payment, i) =>
					<div key={i}><Currency>{payment.total}</Currency></div>)}</OrderTitle>
			</OrderCost>
			{order?.exchangeRateMetaData && <OrderTotalWrapper className="top">
				<OrderRate>
					{order?.payments?.map((payment, i) => {
						const totalInCurrency = getFormattedCurrency(Math.floor((payment?.total * orderExchangeRate) * 100) / 100, 'de-DE', orderExchangeCurrency)
						return <div key={i}>
							{t('invoices.modal.invoiceDataModal.order.totalInCurrency')} {orderExchangeCurrency}: {totalInCurrency}
						</div>})}
				</OrderRate>
				<OrderRate>
					{t('invoices.modal.invoiceDataModal.order.exchangeRate')}: 1{currency} = {exchangeRateCurrency}
				</OrderRate></OrderTotalWrapper>}

			<OrderTotalWrapper>
				{order?.taxTotal?.taxes &&
					order?.taxTotal?.taxes.map((tax, i) => (
						<OrderCost key={'vat_' + i}>
							<OrderSubTitle className="medium">{t('invoices.modal.invoiceDataModal.order.vat')} {tax.title}</OrderSubTitle>
							<OrderSubTitle className="medium"><Currency>{tax.vat}</Currency></OrderSubTitle>
						</OrderCost>
					))}
			</OrderTotalWrapper>
		</OrderTotalsWrap>
	)
})

InvoiceOrderTotals.displayName = 'InvoiceOrderTotals'
export default InvoiceOrderTotals
