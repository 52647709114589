import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const PreOrderTableWrapper = styled.div`
	@media screen and (max-width: ${theme.point820}) {
			margin-top: 1.43em;

			tr {
				grid-template-columns: 5.5em 5.5em 11.29em 1fr 1fr 1fr;
				grid-template-rows: auto auto;
				grid-auto-flow: dense;
		}
	}
`
