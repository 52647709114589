import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CustomInfoModal from '../../../../Elements/modal/CustomInfoModal'
import Button from '../../../../Elements/buttons/Button'
import { setRestaurantTableClosedErrorModal } from '../../../../../redux/action/restaurant'

const EVENT_Z_INDEX = 56

const ModalTableClosed = React.memo(() => {
	const { t } = useTranslation()
	const showTableClosedModal = useSelector(state => state.restaurant.showTableClosedModal)
	const dispatch = useDispatch()

	const handleClose = useCallback(() => {
		dispatch(setRestaurantTableClosedErrorModal(false))
	}, [])

	return (
		<CustomInfoModal isOpen={showTableClosedModal}
										 title={t('Payments.modal.notification.title')}
										 text={t('restaurant.tables.modal.table_closed')}
										 close={handleClose}
										 toFront={true}
										 zIndex={EVENT_Z_INDEX}
										 button={<Button name="accept" icon="checkmark" onClick={handleClose} tabIndex="1" text="Ok" color="green" keyButton="F2" />} />
	)
})

ModalTableClosed.displayName = 'ModalTableClosed'
export default ModalTableClosed
