import { retrieveErrorFromApi } from '../../../../helper'
import { setAppErrors } from '../../../action/system'
import { setCancelReason } from '../../../action'
import setOrderStatus from '../../../axios/order/setOrderStatus'
import { setShowCancelOrder, setShowOrder } from '../../../action/driver-monitor'
import registerOrder from '../../../axios/order/registerOrder'

export const setOrderStatusThunk = (order, status, reason, reasonCode) => dispatch => {
	return setOrderStatus(order.orderId, { status, reason, reasonCode })
		.then(() => {
			dispatch(setShowCancelOrder(false))
			dispatch(setShowOrder(false))
			dispatch(setCancelReason(null))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const registerOrderThunk = (orderId) => dispatch => {
	return registerOrder(orderId)
		.then(() => {
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
