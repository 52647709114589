import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileCompany, ProfileCompanyItem, ProfileCompanyText, ProfileInfo, ProfileUser, ProfileWrapper, QrCodeImg, QrCodeText, QrCodeWrapper } from './index.styled'
import btn_down from '../../img/svg/arrow-down.svg'
import { useDispatch, useSelector } from 'react-redux'
import { postLogoutThunk } from '../../redux/thunk/registration/auth'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import qrcode from '../../img/svg/header/qrcode.png'
import Button from '../Elements/buttons/Button'
import { stringToColor } from '../../helper'
import { theme } from '../../style/theme'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { setAppAfterLogout } from '../../redux/action/system'
import { selectCompanyData, selectCompanyIndex, selectIsTestCompany, selectUsername } from '../../redux/selector/system'
import ToggleTheme from './toggleTheme'
import HeaderIcon from '../../img/svg/header'

const Profile = React.memo(() => {
	const { t } = useTranslation()
	const [openProfileMenu, setOpenProfileMenu] = useState(false)
	const companyIndex = useSelector(selectCompanyIndex)
	const email = useSelector(store => store.login.email)
	const username = useSelector(selectUsername)
	const companyName = useSelector(selectCompanyData('name'))
	const companyZipCode = useSelector(selectCompanyData('zipCode'))
	const companyCity = useSelector(selectCompanyData('city'))
	const ownerPhoneNumber = useSelector(selectCompanyData('ownerPhoneNumber'))
	const companyPhoneNumber = useSelector(selectCompanyData('phoneNumber'))
	const isDealer = useSelector(store => store.support.dealerContacts)
	const dealerIndex = useSelector(store => store.support.dealerContacts?.dealerIndex)
	const dispatch = useDispatch()
	const isTablet = useWindowBreakPoint(theme.point820)
	const wrapperProfileRef = useRef()
	const isTestCompany = useSelector(selectIsTestCompany)

	const toggleMenu = useCallback(() => {
		if (!isTablet) {
			setOpenProfileMenu(!openProfileMenu)
		}
	}, [isTablet, openProfileMenu])

	const handleLogout = useCallback(e => {
		e?.preventDefault()
		dispatch(setAppAfterLogout(true))
		dispatch(postLogoutThunk())
	}, [dispatch])

	useOutsideClick(wrapperProfileRef, useCallback(() => {
		setOpenProfileMenu(false)
	}, []))

	return (
		<ProfileWrapper>
			<ProfileInfo ref={wrapperProfileRef}>
				<ProfileUser onClick={toggleMenu} color={stringToColor(email)}>
					<div className="avatar">{email?.charAt(0)}</div>
					<div className="name">
						{email}
						<div className="index">{companyIndex}</div>
					</div>
					{!isTablet &&
						<div className={openProfileMenu ? 'profile_btn active' : 'profile_btn'}>
							<img src={btn_down} alt="arrow_down" />
						</div>}
				</ProfileUser>
				{openProfileMenu &&
					<ProfileCompany>
						<div>
							<ProfileCompanyItem>
								<div data-testid="user-icon"><HeaderIcon icon="user" /></div>
								<ProfileCompanyText>{username}</ProfileCompanyText>
							</ProfileCompanyItem>
							<ProfileCompanyItem>
								<div data-testid="client-icon"><HeaderIcon icon="client" /></div>
								<ProfileCompanyText>{companyIndex}</ProfileCompanyText>
							</ProfileCompanyItem>
							<ProfileCompanyItem>
								<div data-testid="company-name-icon"><HeaderIcon icon="company-name" /></div>
								<ProfileCompanyText>{companyName}</ProfileCompanyText>
							</ProfileCompanyItem>
							<ProfileCompanyItem>
								<div data-testid="zipcode-icon"><HeaderIcon icon="zipcode" /></div>
								<ProfileCompanyText>{companyZipCode} {companyCity}</ProfileCompanyText>
							</ProfileCompanyItem>
							<ProfileCompanyItem>
								<div data-testid="phone-icon"><HeaderIcon icon="phone" /></div>
								<ProfileCompanyText>{ownerPhoneNumber}</ProfileCompanyText>
							</ProfileCompanyItem>
							<ProfileCompanyItem>
								<div data-testid="suitcase-icon"><HeaderIcon icon="suitcase" /></div>
								<ProfileCompanyText>{companyPhoneNumber}</ProfileCompanyText>
							</ProfileCompanyItem>
							{isDealer &&
								<ProfileCompanyItem>
									<span>{t('Profile.dealerIndex')}:</span>
									<ProfileCompanyText>{dealerIndex}</ProfileCompanyText>
								</ProfileCompanyItem>}
						</div>
						{isTestCompany && <ToggleTheme />}
						<QrCodeWrapper>
							<QrCodeText>
								<svg width="31" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="31" height="31" rx="3" fill="#3366CC" />
									<path d="M7.5048 23.634C7.60904 23.7806 7.77594 23.8674 7.95362 23.8674L12.0874 23.7361C13.2853 23.7361 12.8865 23.994 13.2592 22.8425L19.0302 5.41417C19.0869 5.24286 19.0583 5.05448 18.9546 4.90731C18.8503 4.7607 18.6835 4.67395 18.5058 4.67395H13.8492C13.611 4.67395 13.3998 4.82881 13.3242 5.05834L7.42867 23.1272C7.37248 23.299 7.40113 23.4874 7.5048 23.634Z" fill="#fff"></path>
									<path d="M22.2426 21.7746L23.1971 18.8144C23.2524 18.6428 23.2235 18.454 23.1183 18.3079C23.0136 18.162 22.8464 18.0757 22.6681 18.077L15.6757 18.077L13.8452 23.7123L19.6229 23.7123C20.8162 23.7113 21.8693 22.9325 22.2426 21.7746Z" fill="#fff" />
								</svg>
								Fahrer App
							</QrCodeText>
							<QrCodeImg><img src={qrcode} alt="QRCode" /></QrCodeImg>
						</QrCodeWrapper>
						<Button name="logout" color="blue" text={t('LeftBar.menu.Logout')} icon="logout" onClick={handleLogout} className="logout" />
					</ProfileCompany>}
			</ProfileInfo>
		</ProfileWrapper>
	)
})

Profile.displayName = 'Profile'
export default Profile
