import React from 'react'
import { useTranslation } from 'react-i18next'
import ClientMonitorCartLogo from '../common/ClientMonitorCartLogo'
import { QrCodeBlock, QrCodeContent, QrCodePaymentText, QrCodeStepWrapper, QrCodeWrap } from './index.styled'
import { useSelector } from 'react-redux'
import { QRCodeSVG } from 'qrcode.react'
import QrCodeLoader from '../../Elements/cart/ModalQrCodePayment/QrCodeLoader'

const QrCodePaymentStep = React.memo(() => {
	const { t } = useTranslation()
	const qrCodePaymentSession = useSelector(state => state.clientMonitorCart.qrCodePaymentSession)
	const paymentSessionLink = qrCodePaymentSession?.link

	return (
		<QrCodeStepWrapper>
			<QrCodeContent>
				<QrCodeWrap>
					<QrCodeBlock data-testid="qr-code"><QRCodeSVG value={paymentSessionLink} level="M" /></QrCodeBlock>
					<QrCodeLoader />
				</QrCodeWrap>
				<QrCodePaymentText>{t('Modal.qrCodePayment.loadingText')}</QrCodePaymentText>
			</QrCodeContent>
			<ClientMonitorCartLogo layout="custom" />
		</QrCodeStepWrapper>
	)
})

QrCodePaymentStep.displayName = 'QrCodePaymentStep'
export default QrCodePaymentStep
