import { APPEND_ORDERS_COUNTER_ORDER, APPEND_UNPAID_ORDERS_COUNTER_ORDER, REMOVE_ORDERS_COUNTER_ORDER, REMOVE_UNPAID_ORDERS_COUNTER_ORDER, SET_ORDERS_COUNTER_ORDERS, SET_PRE_ORDERS_COUNTER, SET_UNPAID_ORDERS_COUNTER_ORDERS } from '../../actionTypes/ordersCounter'

export const setOrdersCounterOrders = value => ({ type: SET_ORDERS_COUNTER_ORDERS, value })

export const appendOrdersCounterOrder = value => ({ type: APPEND_ORDERS_COUNTER_ORDER, value })

export const removeOrdersCounterOrder = value => ({ type: REMOVE_ORDERS_COUNTER_ORDER, value })

export const setPreOrdersCounter = value => ({ type: SET_PRE_ORDERS_COUNTER, value })

export const setUnpaidOrdersCounterOrders = value => ({ type: SET_UNPAID_ORDERS_COUNTER_ORDERS, value })

export const appendUnpaidOrdersCounterOrder = value => ({ type: APPEND_UNPAID_ORDERS_COUNTER_ORDER, value })

export const removeUnpaidOrdersCounterOrder = value => ({ type: REMOVE_UNPAID_ORDERS_COUNTER_ORDER, value })
