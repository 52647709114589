import React from 'react'
import { Circle, LoaderWrap } from './index.styled'

const HourglassLoader = ({ className }) => {
	return (
		<LoaderWrap data-testid="hourglass-loader">
			<Circle className={className}>
				<svg className="hourglass" viewBox="0 0 40 67" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path className="dot" d="M23.3346 13.3975H16.668V16.7475C16.668 18.59 18.168 20.0975 20.0013 20.0975C21.8346 20.0975 23.3346 18.59 23.3346 16.7475V13.3975Z" fill="#64A9FF" />
					<path fillRule="evenodd" clipRule="evenodd" d="M0 0V6.7H3.33333V16.75C3.33333 25.9625 10.8333 33.5 20 33.5C10.8333 33.5 3.33333 41.0375 3.33333 50.25V60.3H0V67H40V60.3H36.6667V50.25C36.6667 41.0375 29.1667 33.5 20 33.5C29.1667 33.5 36.6667 25.9625 36.6667 16.75V6.7H40V0H0ZM10 6.7H30V16.75C30 22.2775 25.5 26.8 20 26.8C14.5 26.8 10 22.2775 10 16.75V6.7ZM10 50.25V60.3H30V50.25C30 44.7225 25.5 40.2 20 40.2C14.5 40.2 10 44.7225 10 50.25Z" fill="#64A9FF" />
				</svg>
			</Circle>
		</LoaderWrap>)
}

HourglassLoader.displayName = 'HourglassLoader'
export default HourglassLoader
