import printerInstance from '../instance'

const printDriverCalculation = (report, callbackSuccess, callbackError) => {
  return async dispatch => {
    printerInstance.post(
      '/printer/driver', report,
    )
      .then(res => {
        if (res) typeof callbackSuccess === 'function' && callbackSuccess(res)
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }

}

export default printDriverCalculation
