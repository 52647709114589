import React from 'react'
import { Table16ChairsSvg } from './Table16Chairs'
import { Table14ChairsSvg } from './Table14Chairs'
import { Table12ChairsSvg } from './Table12Chairs'
import { Table10ChairsSvg } from './Table10Chairs'
import { Table6ChairsSvg } from './Table6Chairs'
import { Table8ChairsSvg } from './Table8Chairs'
import { SvgTableWrap } from '../index.styled'

const SVGRectangleTable = ({ index, onClick, data, children, color, testId, id, animationClassname }) => {
	const isVertical = data.width < data.height
	const layout = (isVertical ? 'vertical' : '')
	const layoutSvgWrap = (color ? `${color} ` : '') + (data.isEm ? 'sizeWithEm ' : '') + (animationClassname ? animationClassname + ' ' : '')

	const renderTable = () => {
		const { chairCount } = data
		switch (true) {
			case (chairCount >= 15):
				return <Table16ChairsSvg chairCount={chairCount} className={layout} />
			case (chairCount >= 13):
				return <Table14ChairsSvg chairCount={chairCount} className={layout} />
			case (chairCount >= 11):
				return <Table12ChairsSvg chairCount={chairCount} className={layout} />
			case (chairCount >= 9):
				return <Table10ChairsSvg chairCount={chairCount} className={layout} />
			case (chairCount >= 7):
				return <Table8ChairsSvg chairCount={chairCount} className={layout} />
			case (chairCount >= 5):
				return <Table6ChairsSvg chairCount={chairCount} className={layout} />
			default:
				return <Table6ChairsSvg chairCount={chairCount} className={layout} />
		}
	}

	return (
		<>
			<SvgTableWrap {...data}
										id={id}
										onClick={onClick}
										className={layoutSvgWrap}
										data-testid={testId}>
				{children}
				{renderTable()}
			</SvgTableWrap>
		</>
	)
}

SVGRectangleTable.displayName = 'SVGRectangleTable'
export default SVGRectangleTable

