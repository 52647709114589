import styled from 'styled-components'
import colors from '../../../../../style/colors'

export const WebshopPreviewWrap = styled.div`
	font-size: 1rem;
	width: 435px;
	height: 74vh;
	max-height: 757px;
	background: ${props => props.colorMobile === 'LIGHT' ? 'linear-gradient(155.11deg, #FFFFFF 13.2%, #F2F2F2 15.8%)' : 'linear-gradient(155.11deg, #3F3F3F 13.2%, #000000 15.8%)'};
	border: ${props => props.colorMobile === 'LIGHT' ? '0.3em solid #FFFFFF' : 'none'};
	box-shadow: 1.256px 17.956px 50px rgba(0, 0, 0, 0.15);
	border-radius: 2.14em;
	padding: ${props => props.colorMobile === 'LIGHT' ? '0 1.56em' : '0 1.87em'};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
WebshopPreviewWrap.displayName = 'WebshopPreviewWrap'

export const WebshopPreviewMain = styled.div`
	background: ${colors.white};
	width: 100%;
	height: 100%;

	> iframe {
		width: 100%;
		height: 100%;
	}

	&.overflow {
		overflow-y: hidden;
	}
`
WebshopPreviewMain.displayName = 'WebshopPreviewMain'

export const WebshopPreviewElemTop = styled.div`
	background: ${props => props.colorMobile === 'LIGHT' ? '#D9D9D9' : '#000000'};
	border: ${props => props.colorMobile === 'LIGHT' ? '2px solid #FFFFFF' : '2px solid #A9A9A9'};
	border-radius: 0.3em;
	width: 5.81em;
	height: 0.69em;
	margin-top: ${props => props.colorMobile === 'LIGHT' ? '1.06em' : '1.31em'};
	margin-bottom: 0.94em;

`
WebshopPreviewElemTop.displayName = 'WebshopPreviewElemTop'

export const WebshopPreviewElemButton = styled(WebshopPreviewElemTop)`
	border-radius: 0.7em;
	width: 7.5em;
	height: 1.4em;
	margin-top: 1.25em;
	margin-bottom: ${props => props.colorMobile === 'LIGHT' ? '1.25em' : '1.5em'};
`
WebshopPreviewElemButton.displayName = 'WebshopPreviewElemButton'
