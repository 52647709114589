import React from 'react'
import { InfoMessageText, InfoMessageWrapper } from './index.styled'
import MainIcon from '../../../../img/svg/main'

const InfoMessage = React.memo(({ id, center = false, children, fullWidth, className }) => {
	const layout = (!center ? 'start ' : '') + (center ? 'center ' : '') + (fullWidth ? 'fullWidth ' : '') + className

	return (
		<InfoMessageWrapper id={id} className={layout}>
			<MainIcon icon="info" />
			<InfoMessageText className={layout}>
				{children}
			</InfoMessageText>
		</InfoMessageWrapper>
	)
})

InfoMessage.displayName = 'InfoMessage'
export default InfoMessage
