import { SET_MODAL_LOGIN_ACCESS_TOKEN, SET_MODAL_LOGIN_INVALID_PASSWORD_ERROR, SET_MODAL_LOGIN_LOADING, SET_MODAL_LOGIN_OPEN_FOR_WAITER, SET_MODAL_LOGIN_REFRESH_TOKEN, SET_MODAL_LOGIN_USERS, SET_PIN_CODE_MODAL_LOGIN_IS_NEEDED, SET_MODAL_LOGIN_USERNAME } from '../../actionTypes/modalLogin'

const initialState = {
	users: null,
	accessToken: null,
	refreshToken: null,
	invalidPasswordError: false,
	loading: false,
	isOpenModal: false,
	pinCodeModalLoginIsNeeded: false,
	username: null
}

export function modalLoginReducer(state = initialState, action) {
	switch (action.type) {
		case SET_MODAL_LOGIN_USERS:
			return {
				...state,
				users: action.value
			}
		case SET_MODAL_LOGIN_ACCESS_TOKEN:
			return {
				...state,
				accessToken: action.value
			}
		case SET_MODAL_LOGIN_REFRESH_TOKEN:
			return {
				...state,
				refreshToken: action.value
			}
		case SET_MODAL_LOGIN_INVALID_PASSWORD_ERROR:
			return {
				...state,
				invalidPasswordError: action.value
			}
		case SET_MODAL_LOGIN_LOADING:
			return {
				...state,
				loading: action.value
			}
		case SET_MODAL_LOGIN_OPEN_FOR_WAITER:
			return {
				...state,
				isOpenModal: action.value
			}
		case SET_PIN_CODE_MODAL_LOGIN_IS_NEEDED:
			return {
				...state,
				pinCodeModalLoginIsNeeded: action.value
			}
		case SET_MODAL_LOGIN_USERNAME:
			return {
				...state,
				username: action.value
			}
		default:
			return state
	}

}
