import React from 'react'
import Currency from '../../../currency'
import { CartProductDishCourseWrap, CartProductWrap, Product, ProductCol, ProductCount, ProductDiscountPrice, ProductName, ProductNr, ProductPrice, ProductQuantity, ProductToppings, ProductTotal, Topping, ToppingMinus, ToppingName, ToppingPrice, ToppingTotal } from './index.styled'
import PlusMinus from '../PlusMinus'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import { calcCartItemTotalPrice } from '../../../../../helper'
import CartRemark from '../CartRemark'
import Price from '../../../blocks/Price'
import { useSelector } from 'react-redux'
import { selectDiscountForItem } from '../../../../../redux/selector/cart'
import CartDishCourse from '../CartDishCourse'

const CartProduct = React.memo(({ active, item, removeAbility = true, incrementAbility = true, onClick, onClickPlus, onClickMinus, onClickToppingMinus, AddButton, handleRemoveRemark, plusBtnColor, orderTask = false, savedOrder, isMenuItem = false, enabledDishCourse, onClickProduct }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const totalPrice = item ? calcCartItemTotalPrice(item) : 0
	const discount = useSelector(selectDiscountForItem(totalPrice))
	const layout = (touchPad ? 'touchPad ' : '') + (active ? 'active' : '') + (savedOrder ? ' secondary' : '')
	const isCanceledItem = item?.status === 'CANCELED'
	const itemHasDiscount = discount > 0 && !item?.disabledDiscount && !isMenuItem
	const itemHasDishCourse = enabledDishCourse || item?.dishCourse

	const discountedPrice = itemHasDiscount ? totalPrice - discount : totalPrice
	const layoutPrice = (itemHasDiscount ? 'green ' : '')
	const layoutProductWrap = `${layout} ${isCanceledItem ? ' strikethrough' : ''} ${item?.dishCourse ? 'dishCourse' : ''}`
	const layoutDishCourseWrap = (itemHasDishCourse ? 'col-2' : '') + (active ? ' active' : '')
	const isMoneyExchange = item?.type === 'CASH_EXCHANGE'

	return (
		<CartProductDishCourseWrap onClick={onClick} className={layoutDishCourseWrap}>
			{itemHasDishCourse && <CartDishCourse item={item} savedOrder={savedOrder} />}
			<CartProductWrap className={layoutProductWrap} onClick={onClickProduct} data-testid="item-product">
				<Product className={`product ${layout}`} orderTask={orderTask}>
					{!orderTask && <ProductNr className={layout}>{item?.sku}</ProductNr>}
					<ProductName className={layout}>
						{item?.name}
						{!orderTask && item?.quantity > 1 &&
							<ProductQuantity className={layout}> x {item?.quantity}</ProductQuantity>}
					</ProductName>

					{orderTask && <ProductCount className={layout}>x{item?.quantity}</ProductCount>}
					<ProductCol className={layout}>
						{!orderTask && <Currency>{item?.price}</Currency>}
						{orderTask && <Price value={item?.price} size="big" />}
					</ProductCol>
					<ProductTotal className={layout}>
						{itemHasDiscount && <ProductDiscountPrice><Currency>{totalPrice}</Currency></ProductDiscountPrice>}
						{!orderTask && <ProductPrice className={layoutPrice}><Currency>{discountedPrice}</Currency></ProductPrice>}
						{orderTask && <Price value={totalPrice} size="big" />}
					</ProductTotal>
					{!isCanceledItem &&
						<PlusMinus {...{ AddButton, onClickPlus, onClickMinus, incrementAbility, removeAbility, size: touchPad ? 'x-medium' : '', plusBtnColor }} />}
				</Product>
				{(item?.items?.length > 0 || item?.remark) &&
					<ProductToppings className={`toppings ${layout}`}>
						{item?.remark &&
							<CartRemark remark={item?.remark}
													layout={`${layout} ${savedOrder ? ' disabled' : ''}`}
													handleRemoveRemark={handleRemoveRemark}
													removeAbility={isMoneyExchange ? false : removeAbility} />}
						{item?.items?.map((subItem, subItemIndex) =>
							<Topping key={subItemIndex} className={layout + (subItem.quantity < 0 ? ' minus' : '') + (subItem.status === 'CANCELED' || isCanceledItem ? ' strikethrough' : '')} data-testid={subItem.name}>
								<ToppingName className={layout + (subItem.quantity < 0 ? ' minus' : '')}>
									{subItem.quantity > 0 ?
										<svg xmlns="http://www.w3.org/2000/svg" width="11.625" height="11.625" viewBox="0 0 11.625 11.625">
											<path d="M324.433,202.056l4.309-.018a0.754,0.754,0,0,0,.752-0.752,0.743,0.743,0,0,0-.747-0.748l-4.31.019,0.019-4.31a0.746,0.746,0,0,0-.748-0.748,0.756,0.756,0,0,0-.752.753l-0.019,4.309-4.309.019a0.754,0.754,0,0,0-.752.752,0.741,0.741,0,0,0,.747.747l4.31-.018-0.019,4.309a0.744,0.744,0,0,0,.748.748,0.754,0.754,0,0,0,.752-0.752Z" transform="translate(-317.875 -195.5)"></path>
										</svg> :
										<svg width="11" height="1" viewBox="0 0 11 1" xmlns="http://www.w3.org/2000/svg">
											<rect width="11" height="1" rx="0.5" />
										</svg>}
									{subItem.name}
									{(subItem?.quantity > 1 || subItem?.quantity < -1) &&
										<ProductQuantity className={layout}> x {Math.abs(subItem.quantity)}</ProductQuantity>}
								</ToppingName>
								<ToppingPrice className={layout}><Currency>{subItem.price}</Currency></ToppingPrice>
								<ToppingTotal className={layout} />
								{(removeAbility && subItem.status !== 'CANCELED' && !isCanceledItem) &&
									<ToppingMinus className={layout + (subItem.quantity < 0 ? ' minus' : '')}
																onClick={event => onClickToppingMinus(event, subItemIndex)}
																data-testid="btn-remove-topping">
										{touchPad ?
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.75 8.782" fill="#fff">
												<path id="btn-cross-white" d="M636.662,796.46l3.364-3.385a0.6,0.6,0,0,0,0-.838,0.587,0.587,0,0,0-.833,0l-3.364,3.385-3.365-3.385a0.586,0.586,0,0,0-.832,0,0.6,0.6,0,0,0,0,.838L635,796.46l-3.364,3.385a0.6,0.6,0,0,0,0,.838,0.587,0.587,0,0,0,.832,0l3.365-3.385,3.364,3.385a0.591,0.591,0,1,0,.833-0.838Z" transform="translate(-631.469 -792.062)" />
											</svg> :
											<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="22" height="4" viewBox="0 0 10.781 1.844">
												<path d="M486.363,202.33h-8.948a0.915,0.915,0,1,1,0-1.83h8.948A0.915,0.915,0,1,1,486.363,202.33Z" transform="translate(-476.5 -200.5)" />
											</svg>}
									</ToppingMinus>}
							</Topping>)}
					</ProductToppings>}
			</CartProductWrap>
		</CartProductDishCourseWrap>
	)
})

CartProduct.displayName = 'CartProduct'
export default CartProduct
