import React from 'react'
import sprite from '../../../../img/svg/settings/settings.svg'
import { SettingsTitleWrap } from './index.styled'
import SettingsPlatformIcon from '../../../../img/svg/settings/platforms'

const SettingsTitle = React.memo(({ icon, children, marginBottom, iconSize, refProp, isPlatform }) => {
	const layout = (iconSize ? iconSize : '')
	const layoutImage = (!isPlatform ? 'image' : '')
	return (
		<SettingsTitleWrap marginBottom={marginBottom} className={layout} ref={refProp}>
			{icon && <div className={layoutImage}>
				{isPlatform ? <SettingsPlatformIcon icon={icon}/>
				: <svg width="100%" height="100%">
					<use href={sprite + '#' + icon} />
				</svg>}
			</div>}
			<div className="title">{children}</div>
		</SettingsTitleWrap>
	)
})

SettingsTitle.displayName = 'SettingsTitle'
export default SettingsTitle
