import { SET_CLIENT_MONITOR_CONFIG } from '../../actionTypes/clientMonitorConfig'

const initialState = {
	config: null,
}

export function clientMonitorConfigReducer (state = initialState, action) {
	switch (action.type) {
		case SET_CLIENT_MONITOR_CONFIG:
			return {
				...state,
				config: action.value,
			}
		default:
			return state
	}
}
