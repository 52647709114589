import getOrders from '../../axios/orderview'
import { addOrders, addPreOrdersDays, addPreOrdersToday, addUnpaidOrders, setOrders, setOrdersLoading, setOrdersTotalLength, setPreOrdersDaysTotalLength, setPreOrdersToday, setPreOrdersTodayTotalLength, setShowTodayPreOrdersModal, setUnpaidOrdersTotalLength } from '../../action/order'
import { jwtContainsAnyRole, notifyToast, retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import createOrder from '../../axios/order/post'
import updateOrder from '../../axios/order/put'
import { setOrdersCounterOrders, setPreOrdersCounter, setUnpaidOrdersCounterOrders } from '../../action/ordersCounter'
import printOrderView from '../../axios/orderview/printOrder/post'
import printEntertainmentExpensesReceiptView from '../../axios/orderview/printEntertainmentExpensesReceipt/post'
import confirmOrderPayments from '../../axios/order/confirmOrderPayments'
import { fetchPrintSettingsThunk } from '../printReceipt'
import { getInvoicesThunk } from '../invoice'

export const getOrdersThunk = (dateFrom, dateTo, paymentMethod, status, excludedStatus, orderType, deliveryPartnerType = null, processedByUsername, processedByUsernameWithEmpty, page, size, reverseSort = false, closed, withOnlyRegisteredPreOrders) => (dispatch, getState) => {
	dispatch(setOrdersLoading(true))
	return getOrders({
		dateFrom, dateTo, paymentMethod, status, excludedStatus, orderType, deliveryPartnerType, processedByUsername, processedByUsernameWithEmpty, page, size, reverseSort, closed, withOnlyRegisteredPreOrders,
	}).then(res => {
		if (reverseSort === getState().orderfilter.reverse) {
			dispatch(addOrders(res.data))
			if (res.headers['x-orders-total']) {
				dispatch(setOrdersTotalLength(parseInt(res.headers['x-orders-total'])))
			}
		}
		dispatch(setOrdersLoading(false))
	}).catch(error => {
		dispatch(setOrdersLoading(false))
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const getViaSetOrdersThunk = (dateFrom, dateTo, paymentMethod, status, isCancelled, orderType, processedByUsername, reverseSort = true, page, size, closed) => dispatch => {
	return getOrders({ dateFrom, dateTo, paymentMethod, status, isCancelled, orderType, processedByUsername, reverseSort, page, size, closed })
		.then(res => {
			dispatch(setOrders(res.data))
			if (res.headers['x-orders-total']) {
				dispatch(setOrdersTotalLength(parseInt(res.headers['x-orders-total'])))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const storeOrderThunk = (data, hasModalLogin) => (dispatch, getState) => {
	return createOrder(data, hasModalLogin)
		.then(res => {
			const ecTerminalPayment = data?.payments?.find(payment => payment.method === 'EC_TERMINAL')
			if (ecTerminalPayment) {
				/* eslint no-console: off */
				console.log(`Create order: orderId=${res.data.remoteResponse.orderId} paymentId=${ecTerminalPayment?.id} totalPrice=${ecTerminalPayment?.total} companyIndex=${getState().login.companyIndex}`)
			}
			return res.data.remoteResponse.orderId
		})
		.catch(error => {
			const errorStatusCode = error.response?.data?.apierror?.errorStatusCode
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			if (errorStatusCode === 'terminal_payment_session_invalid') {
				const paymentEC = data.payments.find(payment => payment.method === 'EC_TERMINAL')
				/* eslint no-console: off */
				console.error(`Create order: paymentId=${paymentEC?.id} totalPrice=${paymentEC?.total} companyIndex=${getState().login.companyIndex}`)
			} else if (errorStatusCode === 'qr_payment_session_invalid') {
				const paymentQrCode = data.payments.find(payment => payment.method === 'QR_CODE')
				/* eslint no-console: off */
				console.error(`Create order: paymentId=${paymentQrCode?.id} totalPrice=${paymentQrCode?.total} companyIndex=${getState().login.companyIndex}`)
			}
			throw new Error(error.response?.data?.apierror?.message)
		})
}

export const updateOrderThunk = (orderId, data) => (dispatch, getState) => {
	return updateOrder(orderId, data)
		.then(res => {
			return res.data.remoteResponse.orderId
		})
		.catch(error => {
			const errorStatusCode = error.response?.data?.apierror?.errorStatusCode
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			if (errorStatusCode === 'terminal_payment_session_invalid') {
				const paymentEC = data.payments.find(payment => payment.method === 'EC_TERMINAL')
				/* eslint no-console: off */
				console.error(`Update order: paymentId=${paymentEC?.id} totalPrice=${paymentEC?.total} companyIndex=${getState().login.companyIndex}`)
			}
			throw new Error(error.response?.data?.apierror?.message)
		})
}

export const confirmOrderPaymentsThunk = (orderId, paymentData, orderData) => dispatch => {
	const isEcTerminalPaymentMethod = paymentData?.payments[0]?.method === 'EC_TERMINAL'
	const isQrCodePaymentMethod = paymentData?.payments[0]?.method === 'QR_CODE'
	const isInvoiceMethod = paymentData?.payments[0]?.method === 'INVOICE'
	return confirmOrderPayments(orderId, paymentData)
		.then(res => {
			if (!isEcTerminalPaymentMethod && !isQrCodePaymentMethod) {
				dispatch(fetchPrintSettingsThunk(orderId, orderData))
			}
			if (isInvoiceMethod) {
			dispatch(getInvoicesThunk(0, 10))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const resetOrdersThunk = () => {
	return (dispatch) => {
		dispatch(setOrders([]))
		dispatch(setOrdersTotalLength(0))
	}
}

export const printOrderThunk = (orderId, canceledPrint) => dispatch => {
	return printOrderView(orderId, canceledPrint)
		.then(res => {
		})
		.catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const printEntertainmentExpensesReceiptThunk = (orderId) => dispatch => {
	return printEntertainmentExpensesReceiptView(orderId)
		.then(res => {
			notifyToast({ message: 'Order entertainment expenses receipt printed' })
		})
		.catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getNotAcceptOrdersThunk = (dateFrom, dateTo, paymentMethod, status, orderType, processedByUsername, processedByUsernameWithEmpty, page, size) => (dispatch, getState) => {
	return getOrders({
		dateFrom, dateTo, paymentMethod, status, orderType, processedByUsername, processedByUsernameWithEmpty, closed: false, reverseSort: false, page, size,
	}).then(res => {
		dispatch(setOrdersCounterOrders(res.data.map(order => order.orderId)))
	}).catch(error => {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const getPreOrdersTodayThunk = (dateFrom, dateTo, orderType, page, size, reverseSort = false, closed) => (dispatch, getState) => {
	return getOrders({
		dateFrom, dateTo, orderType, page, size, reverseSort, closed, isPreOrder: true,
	}).then(res => {
		dispatch(addPreOrdersToday(res.data))
		if (res.headers['x-orders-total']) {
			dispatch(setPreOrdersTodayTotalLength(parseInt(res.headers['x-orders-total'])))
		}
	}).catch(error => {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const getPreOrdersDaysThunk = (dateFrom, dateTo, orderType, page, size, reverseSort = false, closed) => (dispatch, getState) => {
	return getOrders({
		dateFrom, dateTo, orderType, page, size, reverseSort, closed, isPreOrder: true,
	}).then(res => {
		dispatch(addPreOrdersDays(res.data))
		if (res.headers['x-orders-total']) {
			dispatch(setPreOrdersDaysTotalLength(parseInt(res.headers['x-orders-total'])))
		}
	}).catch(error => {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const getPreOrdersCounterThunk = (dateFrom, dateTo, orderType, status, closed, page, size) => (dispatch, getState) => {
	return getOrders({
		dateFrom, dateTo, orderType, status, closed, page, size, isPreOrder: true,
	}).then(res => {
		if (res.headers['x-orders-total']) {
			dispatch(setPreOrdersCounter(parseInt(res.headers['x-orders-total'])))
		}
	}).catch(error => {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const getViaPreOrdersTodayThunk = (dateFrom, dateTo, status, excludedStatus, orderType, page, size, reverseSort = false, closed = false) => (dispatch, getState) => {
	const accessToken = getState().login.accessToken
	const hasRoleChangeOrder = !!jwtContainsAnyRole(accessToken, ['ORDERS_WRITE', 'DELIVERY_SERVICE_WRITE'])

	return hasRoleChangeOrder &&
		getOrders({
			dateFrom, dateTo, paymentMethod: null, status, excludedStatus, orderType, processedByUsername: null, processedByUsernameWithEmpty: null, reverseSort, closed, isPreOrder: true, withOnlyRegisteredPreorders: false, page, size,
		}).then(res => {
			dispatch(setPreOrdersToday(res.data))
			if (res.headers['x-orders-total']) {
				res.headers['x-orders-total'] > 0 && dispatch(setShowTodayPreOrdersModal(true))
				dispatch(setPreOrdersTodayTotalLength(parseInt(res.headers['x-orders-total'])))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getUnpaidOrdersThunk = (dateFrom, dateTo, paymentMethod, status, excludedStatus, orderType, processedByUsername, page, size, reverseSort = false, closed, isPaid) => (dispatch, getState) => {
	return getOrders({
		dateFrom, dateTo, paymentMethod, status, excludedStatus, orderType, processedByUsername, page, size, reverseSort, closed, isPaid,
	}).then(res => {
		if (reverseSort === getState().orderfilter.reverse) {
			dispatch(addUnpaidOrders(res.data))
			if (res.headers['x-orders-total']) {
				dispatch(setUnpaidOrdersTotalLength(parseInt(res.headers['x-orders-total'])))
			}
		}
	}).catch(error => {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const getUnpaidOrdersCounterThunk = (dateFrom, dateTo, orderType, excludedStatus, status, closed, isPaid, page, size) => (dispatch, getState) => {
	return getOrders({
		dateFrom, dateTo, orderType, excludedStatus, status, closed, isPaid, page, size,
	}).then(res => {
		dispatch(setUnpaidOrdersCounterOrders(res.data.map(order => order.orderId)))
	}).catch(error => {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}
