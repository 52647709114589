import React from 'react'
import sprite from './index.svg'

const MainIcon = React.memo(({ icon, height, width, fill, stroke, style, testId, className }) => {
	const layout = icon + (className ? ' ' + className : '' )

	return (
		<svg data-testid={testId} height={height} width={width} fill={fill} stroke={stroke} style={style} className={layout}>
			<use width="100%" height="100%" href={sprite + '#' + icon} />
		</svg>
	)
})

MainIcon.displayName = 'MainIcon'
export default MainIcon
