import productInstance from './instance'

const deleteAllProduct = (callbackSuccess, callbackError) => {

  return async dispatch => {
    productInstance.delete('/products')
      .then(() => {
        typeof callbackSuccess === 'function' && callbackSuccess()
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }
}

export default deleteAllProduct

