import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { theme } from '../../../../../style/theme'

export const CartTotalsWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	border-top: 2px solid ${colorTheme.clientMonitorCart.cart.total.border};
	margin: 0 3.13em 1.25em;
`
CartTotalsWrapper.displayName = 'CartTotalsWrapper'

export const CartTotalItem = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.94em 0;

	&:not(:last-child, :nth-last-child(2)) {
		border-bottom: 1px solid ${colorTheme.clientMonitorCart.cart.total.borderLight};
	}

	&.summary {
		padding: 1.88em 0;
		border-bottom: 0;
		border-top: 3px solid ${colorTheme.clientMonitorCart.cart.total.borderLight};
	}
`
CartTotalItem.displayName = 'CartTotalItem'

export const CartTotalItemTitle = styled.div`
	color: ${colorTheme.clientMonitorCart.cart.total.title};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	font-weight: 500;

	&.summary {
		font-size: 2em;
		font-weight: 600;
	}
`
CartTotalItemTitle.displayName = 'CartTotalItemTitle'

export const CartTotalItemPrice = styled.div`
	color: ${colorTheme.clientMonitorCart.cart.total.price};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	font-weight: 500;

	&.summary {
		font-family: ${theme.fontRobotoBold};
		font-size: 2em;
		font-weight: 700;
	}
`
CartTotalItemPrice.displayName = 'CartTotalItemPrice'
