export const SET_Z_REPORTS = 'SET_Z_REPORTS'
export const SET_Z_REPORTS_TOTAL_LENGTH = 'SET_Z_REPORTS_TOTAL_LENGTH'
export const SET_Z_REPORT = 'SET_Z_REPORT'
export const APPEND_Z_REPORTS = 'APPEND_Z_REPORTS'
export const SET_Z_REPORT_DATE_FROM = 'SET_Z_REPORT_DATE_FROM'
export const SET_Z_REPORT_DATE_TO = 'SET_Z_REPORT_DATE_TO'
export const SET_Z_REPORT_TEST_IS_LOADING = 'SET_Z_REPORT_TEST_IS_LOADING'
export const APPEND_Z_REPORT_FOR_TODAY = 'APPEND_Z_REPORT_FOR_TODAY'
export const SET_Z_REPORTS_FOR_TODAY = 'SET_Z_REPORTS_FOR_TODAY'
export const ADD_Z_REPORT = 'ADD_Z_REPORT'
export const SET_Z_REPORT_CASH_DATA = 'SET_Z_REPORT_CASH_DATA'
export const SET_Z_REPORT_DATEV = 'SET_Z_REPORT_DATEV'
