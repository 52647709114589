import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const DriverMonitor = lazyWithRetry(() => import(/* webpackChunkName: "driver-monitor" */'../../component/DriverMonitor'))

const DriverMonitorPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<DriverMonitor />
		</Suspense>
	)
})

DriverMonitorPage.displayName = 'DriverMonitorPage'
export default DriverMonitorPage
