import { SET_REGISTRATION_COMPLETE, SET_REGISTRATION_LOADING, SET_REGISTRATION_OWNER_EMAIL, SET_REGISTRATION_OWNER_PHONE_NUMBER } from '../../actionTypes'

const initialState = {
	complete: false,
	isError: false,
	email: null,
	loading: false,
	phoneNumber: null
}

export function registrationOwnerReducer(state = initialState, action) {
	switch (action.type) {
		case SET_REGISTRATION_COMPLETE:
			return {
				...state,
				complete: action.payload.content.complete,
				isError: action.payload.content.isError
			}
		case SET_REGISTRATION_OWNER_EMAIL:
			return {
				...state,
				email: action.payload.content.email

			}
		case SET_REGISTRATION_LOADING:
			return {
				...state,
				loading: action.value
			}
		case SET_REGISTRATION_OWNER_PHONE_NUMBER:
			return {
				...state,
				phoneNumber: action.value
			}

		default:
			return state
	}
}


