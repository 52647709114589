import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ActionButtonsWrapper = styled.div`
  font-size: 1rem;
  display: flex;
  gap: 0.5em;
  justify-content: end;
  width: 100%;
`

export const ActionButton = styled.button`
  font-size: 1rem;
  width: 3.06em;
  height: 3.06em;
  border-radius: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colorTheme.button.type.secondary};
  border: none;
  padding: 0;
  cursor: pointer;
	flex-shrink: 0;

  > svg {
    width: 53%;
    height: 53%;
  }

  &.touchPad-cart {
    width: 2.81em;
    height: 2.81em;
  }

  &.cart {
    width: 2em;
    height: 2em;
  }

  &.big {
    width: 4.18em;
    height: 4.18em;
  }

  @media screen and (max-width: ${theme.point820}) {
    border-radius: 0.54em;

    &.touchPad-cart, &.cart {
      width: 1.42em;
      height: 1.42em;
    }

    &.table-tablet {
      border-radius: 0.86em;
      width: 3.5em;
      height: 3.5em;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    border-radius: 0.75em;

    &.touchPad-cart, &.cart {
      width: 2em;
      height: 2em;
    }

    &.table-tablet {
      border-radius: 0.75em;
      width: 3.06em;
      height: 3.06em;
    }

		&.big {
			width: 3.38em;
			height: 3.38em;
		}
  }
`
ActionButton.displayName = 'ActionButton'
