import getOrders from '../../axios/orderview'
import { setAppErrors } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'
import fetchPositions from '../../axios/registration/position'
import { addMapMarker, removeMapMarker, setMapMarkers, setMapMarkersTotalLength, setMapPositions, setMapSelectedWoltDriverDelivery, setMapWoltDriverDeliveries, updateMapMarker } from '../../action/map'
import { orderFilter } from '../../../helper/ordersFilter'
import fetchWoltDriverDeliveries from '../../axios/wolt/deliveries'

export const getMapOrdersThunk = (dateFrom, dateTo, status, page, size, callback) => async dispatch => {
	try {
		const res = await getOrders({
			dateFrom, dateTo, status, orderType: 'DELIVERY', reverseSort: false, page, size, closed: false,
		})
		if (res.headers['x-orders-total']) {
			dispatch(setMapMarkersTotalLength(parseInt(res.headers['x-orders-total'])))
		}
		dispatch(setMapMarkers(res.data))
		callback?.()
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const addMarkerToMapThunk = (data) => (dispatch, getState) => {
	const markers = getState().map.markers
	const markersStatus = getState().map.markersStatus
	const existMarker = markers.find(order => order.orderId === data.orderId)
	if (!existMarker) {
		const orderFilterTrue = orderFilter(data, { date: new Date(), status: markersStatus, orderType: 'DELIVERY', closed: false })
		if (orderFilterTrue) {
			dispatch(addMapMarker(data))
			dispatch(setMapMarkersTotalLength(getState().map.markersTotalLength + 1))
		}
	}
}

export const updateMarkerToMapThunk = (data) => (dispatch, getState) => {
	const markers = getState().map.markers
	const markersStatus = getState().map.markersStatus
	if (!markers) return
	const existMarker = markers.find(order => order.orderId === data.orderId)
	dispatch(updateMapMarker(data))
	const orderFilterTrue = orderFilter(data, { date: new Date(), status: markersStatus, orderType: 'DELIVERY', closed: false })
	if (orderFilterTrue) {
		if (!existMarker) {
			dispatch(addMapMarker(data))
			dispatch(setMapMarkersTotalLength(getState().map.markersTotalLength + 1))
		}
	} else {
		dispatch(removeMapMarker(data))
	}
}

export const getPositionsThunk = () => dispatch => {
	return fetchPositions()
		.then(res => {
			dispatch(setMapPositions(res.data))
		}).catch(error => {
			if (error.response.status !== 403) {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const fetchMapWoltDriverDeliveriesThunk = (page, size) => dispatch => {
	return fetchWoltDriverDeliveries(page, size)
		.then(res => {
			dispatch(setMapWoltDriverDeliveries(res.data))
		}).catch(error => {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		)
}

export const getMapWoltDriverDeliveryThunk = (orderId) => dispatch => {
	return fetchWoltDriverDeliveries(0, 1, orderId)
		.then(res => {
			dispatch(setMapSelectedWoltDriverDelivery(res.data[0]))
		}).catch(error => {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		)
}
