import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const DriverIntegration = lazyWithRetry(() => import(/* webpackChunkName: "platformDrivers" */'../../component/Settings/DriverIntegration'))

const DriverIntegrationPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<DriverIntegration />
		</Suspense>
	)
})

DriverIntegrationPage.displayName = 'DriverIntegrationPage'
export default DriverIntegrationPage
