import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const KeyboardMain = styled.div`
  font-size: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 0.19em;
  row-gap: 0.19em;

	width: 100%;
  height: 100%;
	max-height: 19.32em;
  max-width: 25.37em;

  @media screen and (max-width: ${theme.point820}) {
    height: 15.2em;
		max-width: 27.67em;

	}

	@media screen and (max-width: ${theme.point520}) {
		margin-top: 0;
    height: 12.43em;
		column-gap: 0.32em;
  	row-gap: 0.32em;
		max-width: none;
  }
`
KeyboardMain.displayName = 'KeyboardMain'

export const Key = styled.button`
  background-color: ${colorTheme.cartModal.changeCalculator.numbers.background};
  border: 2px solid ${colorTheme.cartModal.changeCalculator.numbers.border};
  border-radius: 0.33em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colorTheme.cartModal.changeCalculator.numbers.text};
  font-size: 2.25em;
  font-family: ${theme.fontRobotoRegular};
  padding: 0.4em 0.8em;
  user-select: none;
  cursor: pointer;
	transition: all 0.2s ease 0s;

  &:hover {
    background-color: ${colorTheme.cartModal.changeCalculator.numbers.hoverBackground};
  }

  @media screen and (max-width: ${theme.point820}) {
    font-size: 1.5em;
    border-radius: 0.5em;
  }

  @media screen and (max-width: ${theme.point720}) {
    padding: 0;
  }
`
Key.displayName = 'Key'

export const ClearKey = styled(Key)`
  background-color: ${colorTheme.cartModal.changeCalculator.numbers.clear};
  grid-column: 4;
  grid-row: 1 / 3;
`
ClearKey.displayName = 'ClearKey'

export const EnterKey = styled(Key)`
  background-color: ${colorTheme.cartModal.changeCalculator.numbers.enter};
  grid-column: 4;
  grid-row: 3 / 5;
`
EnterKey.displayName = 'EnterKey'
