import { batch } from 'react-redux'
import fetchPrinters from '../../axios/printer'
import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import { notifyToast, retrieveErrorFromApi } from '../../../helper'
import deletePrinter from '../../axios/printer/delete'
import updatePrinter from '../../axios/printer/put'
import storePrinter from '../../axios/printer/store'
import { appendPrinters, setNamePrinters, setPrinters, setPrintersTotalLength } from '../../action/printer'
import getPrinterNames from '../../axios/printer/getPrinterNames'
import printDriverCalculation from '../../axios/printer/driverCalculation/post'
import getDesktopSessions from '../../axios/printer/getDesktopSessions'
import { setSocketPrinterDesktopSessions } from '../../action/socket'
import openPrintersCashDesk from '../../axios/printer/openCashDesk/post'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'

export const getPrintersThunk = (page = 0, size = 20) => dispatch => {
	return fetchPrinters(page, size)
		.then((res) => {
			if (res.data) {
				if (res.headers['x-printers-total']) {
					dispatch(setPrintersTotalLength(parseInt(res.headers['x-printers-total'])))
				}
				if (page > 0 && size > 0) {
					dispatch(appendPrinters(res.data))
					return
				}
				dispatch(setPrinters(res.data))
			}
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deletePrinterThunk = (printerId) => {
	return (dispatch) => {
		batch(() => {
			dispatch(deletePrinter(printerId,
				(res) => {
					dispatch(getPrintersThunk())
				},
				(error) => {
					dispatch(setAppErrors(retrieveErrorFromApi(error)))
				},
			))
		})
	}
}

export const updatePrinterThunk = (id, data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))
	return updatePrinter(id, data)
		.then((res) => {
			if (res.data) {
				dispatch(succeedSystemFormButtonSaveThunk())
				return dispatch(getPrintersThunk())
			}
		}).catch((error) => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const storePrinterThunk = (data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))
	return storePrinter(data)
		.then((res) => {
			dispatch(succeedSystemFormButtonSaveThunk())
			return dispatch(getPrintersThunk())
		}).catch((error) => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getPrinterNamesThunk = () => {
	return (dispatch) => {
		dispatch(getPrinterNames(
			(res) => {
				if (res.data) {
					dispatch(setNamePrinters(res.data))
				}
			},
			(error) => {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			},
		),
		)
	}
}

export const getPrinterDesktopSessionsThunk = (callbackSuccess) => {
	return dispatch => {
		dispatch(getDesktopSessions(response => {
			dispatch(setSocketPrinterDesktopSessions(response.data))
			typeof callbackSuccess === 'function' && callbackSuccess()
		}, error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}))
	}
}

export const printDriverCalculationThunk = (report = null) => {
	return (dispatch) => {
		batch(() => {
			dispatch(printDriverCalculation(report,
				(res) => {
				},
				(error) => {
					dispatch(setAppErrors(retrieveErrorFromApi(error)))
				},
			),
			)
		})
	}
}

export const openPrintersCashDeskThunk = () => dispatch => {
	return openPrintersCashDesk()
		.then(() => {
			notifyToast({ message: 'Open cash desk' })
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
