const lightColors = {
	white: '#fff',
	white_dark_light: '#ECECEC',
	white_dark: '#EFEFF3',
	white_darker: '#E0E0E0',
	black: '#000',
	black_light: '#262626',
	green: '#38cd90',
	green_dark: '#74B88B',
	pink: '#F7CCD4',
	purple: '#383a50',
	purple_light: '#9aaee1',
	red: '#8F2F36',
	red_bright:'#B40000',
	blue_dark: '#17192d',
	blue: '#2981EE',
	blue_light: '#ECF4FF',
	blue_lighter: '#8F9BAB',
	gray_dark: '#60616e',
	gray_darker: '#060606',
	gray: '#777f8a',
	gray_beta: '#777E89',
	gray_light: '#989da5',
	gray_light_beta: '#B1B1B1',
	gray_lighter: '#adadb4',
	gray_lighter_beta: '#a9b0ba',
	gray_lighter_gamma: '#ACACB3',
	gray_more_lighter: '#e1e1e1',
	gray_very_light: '#F7F7F7',
	orange: '#e67d0c',
	orange_light: '#FEF3E4',
	orange_dark: '#DE7B00',
	brown: '#826244'
}

export default lightColors
