import { setAppErrors } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'
import { setPrintReceiptOrderId, setPrintReceiptPrintSettings } from '../../action/printReceipt'
import updateAutoPrintConfigs from '../../axios/printer/printerSettings/put'
import fetchAutoPrintConfigs from '../../axios/printer/printerSettings/post'

export const fetchPrintSettingsThunk = (orderId, { module = '', platformType = '', businessScope }) => dispatch => {
	dispatch(setPrintReceiptOrderId(orderId))
	return fetchAutoPrintConfigs({ module, platformType, businessScope })
		.then(res => {
			const showModal = res.data.disabled
			if (!showModal) {
				dispatch(setPrintReceiptOrderId(null))
			}
			dispatch(setPrintReceiptPrintSettings(res.data))
		}).catch((error) => {
			dispatch(setPrintReceiptOrderId(null))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updatePrintSettingsThunk = (id, data) => dispatch => {
	return updateAutoPrintConfigs(id, data)
		.then((res) => {
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

