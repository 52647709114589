import { SET_ALL_DESKTOP_USERS, SET_DESKTOP_CLIENT_JSON, SET_DESKTOP_IS_CONNECTING, SET_DESKTOP_SHOW_MODAL } from '../../actionTypes/desktop'

const initialState = {
  users: [],
  showModal: false,
  json: '',
  desktopIsConnecting: false,
}

export function desktopUserReducer (state = initialState, action) {
  switch (action.type) {
    case SET_ALL_DESKTOP_USERS:
      return {
        ...state,
        users: action.value,
      }
    case SET_DESKTOP_SHOW_MODAL:
      return {
        ...state,
        showModal: action.value,
      }
    case SET_DESKTOP_CLIENT_JSON:
      return {
        ...state,
        json: action.value,
      }
    case SET_DESKTOP_IS_CONNECTING:
      return {
        ...state,
        desktopIsConnecting: action.value,
      }
    default:
      return state
  }
}
