import styled from 'styled-components'
import { colorTheme } from '../../style/theme/colorTheme'

export const ClientMonitorWrap = styled.div`
	font-size: 1rem;
	position: relative	;
	background: ${colorTheme.main.background};
	display: grid;
	grid-row: 1 / 3;
	grid-column: 1 / 4;
`
ClientMonitorWrap.displayName = 'ClientMonitorWrap'

export const StartStepWrap = styled.div`
	position: relative;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`
StartStepWrap.displayName = 'StartStepWrap'

export const StartStepImg = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`
StartStepImg.displayName = 'StartStepImg'
