import React, { useCallback } from 'react'
import { ToggleBlock, ToggleButtonWrapper, ToggleItem, ToggleItemIcon, ToggleItemText } from './index.styled'
import ButtonIcon from '../../../../img/svg/button'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'

const ToggleButton = React.memo(({
																	 id, name, toggleIndex, setToggleIndex, items, tabIndex, disabled, testId, color, size = ' ',
																	 className, showTextForMobile = true, filledBackground = false
																 }) => {
	const layout = (disabled ? 'disabled ' : '') + (color ? color : '') + (className ? ` ${className}` : '') + (size && ` ${size}`) + (filledBackground ? ' filledBackground' : '')
	const isMobile = useWindowBreakPoint(theme.point720)

	const incrementToggleIndex = useCallback(() => {
		setToggleIndex(toggleIndex === (items?.length - 1) ? 0 : (toggleIndex + 1))
	}, [setToggleIndex, toggleIndex, items?.length])

	const handleToggleClick = useCallback(() => {
		if (!disabled) {
			incrementToggleIndex()
		}
	}, [disabled, incrementToggleIndex])

	const handleItemClick = useCallback((e, i) => {
		e.stopPropagation()
		if (!disabled) {
			if (i !== toggleIndex) {
				setToggleIndex(i)
			} else {
				incrementToggleIndex()
			}
		}
	}, [disabled, toggleIndex, incrementToggleIndex])

	return (
		<ToggleButtonWrapper id={id}
												 name={name}
												 onClick={handleToggleClick}
												 className={layout}
												 cols={items?.length}
												 tabIndex={tabIndex || '-1'}
												 data-testid={testId}>
			<ToggleBlock className={layout}
									 cols={items?.length}
									 toggleCol={items?.findIndex((itm, i) => i === toggleIndex)} />
			{items?.map((item, i) =>
				<ToggleItem key={i} className={layout} onClick={e => handleItemClick(e, i)} title={item.text}>
					{item.icon &&
						<ToggleItemIcon className={layout + (i === toggleIndex ? ' toggled' : '')}>
							<ButtonIcon width="100%" height="100%" icon={item.icon} />
						</ToggleItemIcon>}
					{(!isMobile || showTextForMobile) &&
						<ToggleItemText className={layout + (i === toggleIndex ? ' toggled' : '')}>
							{item.text}
						</ToggleItemText>}
				</ToggleItem>)}
		</ToggleButtonWrapper>
	)
})

ToggleButton.displayName = 'ToggleButton'
export default ToggleButton
