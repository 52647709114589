import productInstance from '../product/instance'

const fetchAllAdditivesGroups = (page = 0, size = 10, categoryId = null, orderByAsc = null, activePosPrices = false, relatedProductId = null, businessScope = null) => {
  return productInstance.get(
		'/additivesGroups'
		+ '?page=' + page
    + '&size=' + size
		+ (categoryId !== null ? '&categoryId=' + categoryId : '')
		+ (relatedProductId !== null ? '&relatedProductId=' + relatedProductId : '')
		+ (orderByAsc !== null ? '&orderByAsc=' + orderByAsc : '')
		+ (activePosPrices ? '&activePosPrices=' + activePosPrices : '')
		+ (businessScope !== null ? '&businessScope=' + businessScope : '')
	)
}

export default fetchAllAdditivesGroups
