import getBusinessScopeDiscount from '../../axios/order/discount/businessScope/get'
import { setCartDiscount, setCartDiscountType, setCartInitialDiscount } from '../../action/cartDiscount'

export const getBusinessScopeDiscountThunk = (businessScope) => dispatch => {
  return getBusinessScopeDiscount(businessScope)
    .then(res => {
      const businessScopeDiscount = res.data
			dispatch(setCartInitialDiscount(businessScopeDiscount))
      dispatch(setCartDiscount(businessScopeDiscount.discount))
      dispatch(setCartDiscountType(businessScopeDiscount.discountType))
    }).catch(() => {
			dispatch(setCartInitialDiscount({discount: 0, discountType: 'PERCENTS'}))
			dispatch(setCartDiscount(0))
			dispatch(setCartDiscountType('PERCENTS'))
		})
}
