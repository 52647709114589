import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const DeliveryWolt = lazyWithRetry(() => import(/* webpackChunkName: "payment" */'../../component/WoltDeliverySettings'))

const DeliveryWoltPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<DeliveryWolt />
		</Suspense>
	)
})

DeliveryWoltPage.displayName = 'DeliveryWoltPage'
export default DeliveryWoltPage
