import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const CartProductWrap = styled.div`
  font-size: 1rem;
  padding: 0 0.31em 0 0.56em;
  border: 1px solid transparent;
  border-top-color: ${colorTheme.cart.cartProduct.border};

	&.strikethrough {
		text-align: center;


		> div:first-child {
			position: relative;
		}

		> div:first-child::after {
			content: "";
			position: absolute;
			bottom: 50%;
			left: -4px;
			width: 82%;
			height: 2px;
			background: linear-gradient(to right, ${colorTheme.cart.cartProduct.strikethrough} 50%, transparent 50%);
			background-size: 6px 2px;
		}
	}

  &.active {
    border-color: ${colorTheme.cart.cartProduct.active.border};
		background: ${colorTheme.cart.cartProduct.active.background};
    border-radius: 0.75em;
  }

  @media screen and (max-width: ${theme.point820}) {
    &.touchPad {
			grid-template-columns: 1.63em 3fr 1fr 1fr 4.31em;
      gap: 0.5em;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    &.touchPad {
      padding: 0 0.63em;
    }

		&.active.dishCourse {
			border-radius: 0 0.75em 0.75em 0;
		}
  }
`
CartProductWrap.displayName = 'CartProductWrap'

export const Product = styled.div`
  font-size: 1em;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: ${props=> props.orderTask ? '4fr 3.75em 1fr 1fr 4.38em' : '3.75em 4fr 1fr 1fr 4.38em'  };
  gap: 0.25em;
  padding: 0.31em 0;

  &.touchPad {
    grid-template-columns: ${props=> props.orderTask ? '4fr 3.75em 1fr 1fr 6.25em' : '3.75em 4fr 1fr 1fr 6.25em'};
    padding: 0.56em 0;
  }

  @media screen and (max-width: ${theme.point820}) {
    &.touchPad {
      padding: 0.31em 0;
      grid-template-columns: ${props=> props.orderTask ? '4fr 1.63em 1fr 1fr 4.31em': '1.63em 3fr 1fr 1fr 4.31em' } ;
      gap: 0.5em;
    }
  }
`
Product.displayName = 'Product'

export const ProductCol = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.94em;
  line-height: 1.2em;
  flex-wrap: wrap;
  font-family: ${theme.fontRobotoRegular};
  text-align: left;
  color: ${colorTheme.cart.cartProduct.text};

	&.secondary {
		color: ${colorTheme.cart.cartProduct.textSecondary};
	}

  &.active {
    color: ${colorTheme.cart.cartProduct.active.text};
  }

  &.menu {
    font-family: ${theme.fontRobotoBold};
  }

	&.grid {
		display: grid;
	}

  &.touchPad {
    font-size: 1.13em;
  }

  @media screen and (max-width: ${theme.point820}) {
    &.touchPad {
      font-size: 0.94em;
    }
  }
`
ProductCol.displayName = 'ProductCol'

export const ProductNr = styled(ProductCol)`
`
ProductNr.displayName = 'ProductNr'

export const ProductCount = styled(ProductCol)`
	font-size: 1.28rem !important;
	font-weight: 700;
`
ProductCount.displayName = 'ProductCount'

export const ProductName = styled(ProductCol)`
`
ProductName.displayName = 'ProductName'

export const ProductQuantity = styled.div`
  margin-left: 0.38em;
  color: ${colorTheme.cart.cartProduct.productQuantity};
  font-family: ${theme.fontRobotoBold};
  white-space: nowrap;

	&.secondary {
		color: ${colorTheme.cart.cartProduct.textSecondary};
	}
`
ProductQuantity.displayName = 'ProductQuantity'

export const ProductTotal = styled(ProductCol)`
  display: grid;
  justify-content: start;
  text-align: center;

  > div {
    font-family: ${theme.fontRobotoMedium};
  }
`
ProductTotal.displayName = 'ProductTotal'

export const ProductPrice = styled.div`
  &.green {
    color: ${colorTheme.cart.cartProduct.price.afterDiscount};
  }
`
ProductPrice.displayName = 'ProductPrice'
export const ProductDiscountPrice = styled.div`
  color: ${colorTheme.cart.cartProduct.price.discountedPrice};
  font-size: 0.78em;
  font-weight: 500;
  text-decoration-line: line-through;
  margin-bottom: -0.42em;
`
ProductDiscountPrice.displayName = 'ProductDiscountPrice'
export const ProductToppings = styled.div`
  margin-left: 3.75em;

  &.touchPad {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${theme.point820}) {
    margin-left: 2.15em;
  }
`
ProductToppings.displayName = 'ProductToppings'

export const Topping = styled.div`
  padding: 0.31em 0 0.31em 1.2em;
  border-top: solid 1px ${colorTheme.cart.cartProduct.topping.borderTop};
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 4.38em;
  gap: 0.25em;

  &.touchPad {
    padding: 0.31em;
    margin-right: 0.31em;
    border-radius: 0.75em;
		background-color: ${colorTheme.cart.cartProduct.topping.background};
    border: solid 1px ${colorTheme.cart.cartProduct.topping.borderTouchPad};
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2em;
    margin-bottom: 0.56em;
    gap: 0;

    &.minus {
      border: 1px solid ${colorTheme.cart.cartProduct.topping.minus.border};
			display: flex;
			background-color: ${colorTheme.cart.cartProduct.topping.minus.background};
    }
  }

	&.strikethrough {
		text-align: center;

		> div {
			position: relative;
		}

		> div::after {
			content: "";
			position: absolute;
			bottom: 50%;
			left: -5px;
			right: -5px;
			width: 100%;
			height: 2px;
			background: linear-gradient(to right, ${colorTheme.cart.cartProduct.strikethrough} 50%, transparent 50%);
			background-size: 6px 2px;
		}
	}
  @media screen and (max-width: ${theme.point820}) {
    &.touchPad {
      margin-bottom: 0.31em;
    }
  }
`
Topping.displayName = 'Topping'

export const ToppingCol = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.81em;
  line-height: 1.15em;
  font-family: ${theme.fontRobotoRegular};
  color: ${colorTheme.cart.cartProduct.topping.text};

  &.touchPad {
    font-size: 0.88em;
    margin-left: 0.21em;
  }
`
ToppingCol.displayName = 'ToppingCol'

export const ToppingName = styled(ToppingCol)`
	color: ${colorTheme.cart.cartProduct.topping.name};

	svg {
    width: 1em;
    height: 1em;
    border: none;
    cursor: pointer;
    display: flex;
    fill: ${colorTheme.cart.cartProduct.topping.icon};
    margin-right: 0.46em;
  }


	&.touchPad {
		color: ${colorTheme.cart.cartProduct.topping.touchPad.name};

		svg {
			fill: ${colorTheme.cart.cartProduct.topping.touchPad.icon};
		}

		&.secondary svg {
			fill: ${colorTheme.cart.cartProduct.textSecondary};
		}
		&.minus svg{
			fill: ${colorTheme.cart.cartProduct.topping.minus.text};
		}
	}

  &.secondary {
    color: ${colorTheme.cart.cartProduct.textSecondary};
  }

  &.minus {
    color: ${colorTheme.cart.cartProduct.topping.minus.text};
  }
`
ToppingName.displayName = 'ToppingName'

export const ToppingPrice = styled(ToppingCol)`
	color: ${colorTheme.cart.cartProduct.topping.price.text};

	&.touchPad {
    border-radius: 0.64em;
    background-color: ${colorTheme.cart.cartProduct.topping.price.background};
    padding: 0.33em;
    font-size: 0.75em;
    color: ${colorTheme.cart.cartProduct.topping.price.text};
    margin-left: 0.42em;
  }
`
ToppingPrice.displayName = 'ToppingPrice'

export const ToppingTotal = styled(ToppingCol)`
  &.touchPad {
    display: none;
  }
`
ToppingTotal.displayName = 'ToppingTotal'

export const ToppingMinus = styled.div`
  width: 1em;
  height: 1em;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorTheme.cart.cartProduct.topping.removeButton};
  border-radius: 50%;
  margin-left: auto;
  margin-right: 0.56em;

  > svg {
    width: 50%;
  }

  &.touchPad {
    width: 1.44em;
    height: 1.44em;
    border-radius: 0.56em;
    background-color: ${colorTheme.cart.cartProduct.topping.removeButtonTouchPad};
    margin-left: 0.31em;
    margin-right: 0;

		&.disabled {
			background-color: ${colorTheme.cart.cartProduct.topping.removeButton};
			cursor: initial;
		}

    &.minus {
      background-color: ${colorTheme.cart.cartProduct.topping.minus.removeButton};
    }
  }
`
ToppingMinus.displayName = 'ToppingMinus'

export const GiftCouponName = styled(ProductCol)`
	flex-direction: column;
	align-items: start;

	> div {
		font-family: ${theme.fontRobotoMedium};
	}
`
GiftCouponName.displayName = 'GiftCouponName'

export const GiftCouponCode = styled.div`
	font-size: 0.89em;
`
GiftCouponName.displayName = 'GiftCouponName'

export const CartProductDishCourseWrap = styled.div`
  display: grid;
	grid-template-columns: 1fr;
	align-items: center;

	&.col-2 {
		grid-template-columns: 3.12em 1fr;
	}

  &.active {
    & + div > div {
      border-top-color: transparent;
    }

		&:last-child > div:last-child {
			border-bottom-color: ${colorTheme.cart.cartProduct.active.border};
		}
  }

  &:last-child > div:last-child {
    border-bottom-color: ${colorTheme.cart.cartProduct.border};
  }

	&.menu div > div {
		border: transparent;
	}

	@media screen and (max-width: ${theme.point720}) {
		&.col-2 {
			grid-template-columns: auto 1fr;
		}
	}
`
CartProductDishCourseWrap.displayName = 'CartProductDishCourseWrap'

