import React from 'react'
import { NotificationModalBtnText, NotificationModalBtnWrapper } from './index.styled'

const NotificationModalBtn = React.memo(({ text, onClick, className }) => {
		return (
			<NotificationModalBtnWrapper onClick={onClick} className={className}>
				<NotificationModalBtnText className={className}>{text}</NotificationModalBtnText>
			</NotificationModalBtnWrapper>
		)
	}
)

NotificationModalBtn.displayName = 'NotificationModalBtn'
export default NotificationModalBtn
