import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const DeliverySettings = lazyWithRetry(() => import(/* webpackChunkName: "delivery-settings" */'../../component/Settings/DeliverySettings'))

const DeliverySettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<DeliverySettings />
		</Suspense>
	)
})

DeliverySettingsPage.displayName = 'DeliverySettingsPage'
export default DeliverySettingsPage
