import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const PartnerDriverTrackingModalTitleWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	gap: 0.63em;
`
PartnerDriverTrackingModalTitleWrapper.displayName = 'PartnerDriverTrackingModalTitleWrapper'

export const PartnerDriverTrackingModalTitleText = styled.div`
	font-family: ${theme.fontRobotoBold};
	font-size: 1.25em;
	font-weight: 700;
	line-height: 1.15em;
	text-align: left;
	color: ${colorTheme.modal.header.defaultText};
`
PartnerDriverTrackingModalTitleText.displayName = 'PartnerDriverTrackingModalTitleText'
export const PartnerDriverTrackingModalTitleIconWrapper = styled.div`
	margin-left: 0.63em;

	> svg.WOLT_DRIVE {
		width: 4.13em;
		height: 1.5em;
		flex-shrink: 0;
	}
`
PartnerDriverTrackingModalTitleIconWrapper.displayName = 'PartnerDriverTrackingModalTitleIconWrapper'

export const PartnerDriverTrackingModalWrapper = styled.div`
	position: relative;
	height: calc(100vh - 3.75em);
	overflow: hidden;
`
PartnerDriverTrackingModalWrapper.displayName = 'PartnerDriverTrackingModalWrapper'

export const BackButtonWrapper = styled.div`
	position: absolute;
	bottom: 20px;
	display: flex;
	justify-content: center;
	width: 100%;
	z-index: 2;
`
BackButtonWrapper.displayName = 'BackButtonWrapper'
