import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { theme } from '../../../../../style/theme'

export const PreparedMessageWrapper = styled.div`
	font-size: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.31em;

	> svg {
		width: 1.38em;
		height: 1.38em;
		flex-shrink: 0;
	}
`
PreparedMessageWrapper.displayName = 'PreparedMessageWrapper'

export const PreparedMessageText = styled.div`
	color: ${colorTheme.restaurant.sendToKitchenModal.preparedMessage.text};
	font-family: ${theme.fontRobotoBold};
	font-size: 0.88em;
	font-weight: 700;
`
PreparedMessageText.displayName = 'PreparedMessageText'
