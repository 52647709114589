import voucher_codes from 'voucher-code-generator'
import { addYears } from 'date-fns'
import { uuidGenerator } from '../../../../../helper'

export const convert2GiftCouponItem = (coupon) => {
	if (coupon) {
		const codeArr = voucher_codes.generate({
			charset: voucher_codes.charset('numbers'),
			pattern: '####-####-####',
		})
		return {
			itemId: uuidGenerator(),
			type: 'GIFT_COUPON',
			quantity: 1,
			name: coupon.name,
			price:coupon.price,
			couponMetaData: {
				code: codeArr[0],
				expirationDate: addYears(new Date(), 3).toISOString()
			}
		}
	} else {
		return null
	}
}
