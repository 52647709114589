import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const SwitchWrapper = styled.label`
  font-size: 1rem;
  display: block;
  position: relative;
  cursor: pointer;

  input {
    display: none;
  }

  input + span {
    padding-left: 3.75em;
    min-height: 1.81em;
    display: flex;
    align-items: center;
    color: ${colorTheme.input.switch.color};
    position: relative;
    vertical-align: middle;
    transition: color 0.3s ease;
    line-height: 1.06em;
  }

  input + span:before, input + span:after {
    content: "";
    display: block;
    position: absolute;
    box-sizing: border-box;
  }

  input + span:before {
    top: 50%;
    left: 0;
    width: 2.88em;
    height: 1.75em;
    border: 1px solid ${colorTheme.input.switch.border};
    transition: all 0.3s ease;
    border-radius: 0.88em;
		transform: translateY(-50%);
  }

  input + span:after {
    width: 1.25em;
    height: 1.25em;
    background: ${colorTheme.input.switch.background};
    left: 0.25em;
    box-shadow: 0 0.06em 0.19em rgba(18, 22, 33, 0.1);
    transition: all 0.45s ease;
    border-radius: 50%;
	}

  input + span .switch-label {
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.06em;
    position: relative;
    display: block;
		line-height: normal;
  }

  input + span .switch-label:before, input + span .switch-label:after {
    font-size: 0.88em;
    font-weight: 500;
    display: block;
    -webkit-backface-visibility: hidden;
  }

  input:checked + span:before {
    border: 1px solid ${colorTheme.input.switch.active.border};
  }

  input:checked + span:after {
    background: ${colorTheme.input.switch.active.background};
    transform: translate(1.13em, 0);
  }
`
