import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import { PER_LOAD } from '../constants'
import fetchProducts from '../../axios/product'
import { appendCartProductMustToppings, appendCartProductProducts, appendCartProductToppings, setCartProductEnableQuantity, setCartProductItemFocus, setCartProductLoading, setCartProductMustToppings, setCartProductMustToppingsTotal, setCartProductProductFocus, setCartProductProducts, setCartProductProductsTotal, setCartProductToppingGroups, setCartProductToppings, setCartProductToppingsTotal } from '../../action/cartProduct'
import fetchProduct from '../../axios/product/get'
import fetchAllAdditivesGroups from '../../axios/additivesGroups/getAll'
import { batch } from 'react-redux'
import getExpertCartSetting from '../../axios/registration/expertCartSettings/get'
import { compareFilter } from './helper'


export const getCartProductsThunk = (page = 0, size = PER_LOAD, title, nrTitle, productType, categoryId, businessScope, menuPositionId) => (dispatch, getState) => {
	dispatch(setCartProductLoading(true))
	return fetchProducts(page, size, title, nrTitle, productType, categoryId, null, true, null, null, false, businessScope, null, menuPositionId)
		.then((res) => {
			const prevCategoryId = getState().cartCategory.categoryId
			const prevNrTitle = getState().cartProduct.searchText
			if (menuPositionId) {
				if (compareFilter(categoryId,prevCategoryId ,nrTitle ,prevNrTitle)) {
					batch(() => {
						dispatch(setCartProductProductsTotal(parseInt(res.headers['x-products-total'])))
						if (page > 0 && size > 0) {
							dispatch(appendCartProductProducts(res.data))
						} else {
							dispatch(setCartProductProducts(res.data))
						}
						dispatch(setCartProductLoading(false))
					})
				} else {
					dispatch(setCartProductLoading(false))
				}
			} else {
				batch(() => {
					dispatch(setCartProductProductsTotal(parseInt(res.headers['x-products-total'])))
					if (page > 0 && size > 0) {
						dispatch(appendCartProductProducts(res.data))
					} else {
						dispatch(setCartProductProducts(res.data))
					}
					dispatch(setCartProductLoading(false))
				})
			}

		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCartSettingsThunk = (module, orderType) => dispatch => {
	return getExpertCartSetting(module, orderType)
		.then((res) => {
			dispatch(setCartProductEnableQuantity(res.data.showProductQuantity))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCartMustToppingsThunk = (page = 0, size = PER_LOAD, categoryId, businessScope) => dispatch => {
	return fetchProducts(page, size, null, null, 'TOPPING', categoryId, true, true, null, false, false, businessScope)
		.then((res) => {
			if (res && res.data) {
				if (res.headers['x-products-total']) {
					//check size & page make sure we are retrieving all products
					dispatch(setCartProductMustToppingsTotal(parseInt(res.headers['x-products-total'])))
				}
				if (page > 0 && size > 0) {
					//make sure we're querying pagination
					dispatch(appendCartProductMustToppings(res.data))
					return
				}

				dispatch(setCartProductMustToppings(res.data))
			}
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCartToppingsThunk = (page = 0, size = PER_LOAD, categoryId, businessScope) => dispatch => {
	return fetchProducts(page, size, null, null, 'TOPPING', categoryId, false, true, null, false, false, businessScope)
		.then((res) => {
			if (res && res.data) {
				if (res.headers['x-products-total']) {
					//check size & page make sure we are retrieving all products
					dispatch(setCartProductToppingsTotal(parseInt(res.headers['x-products-total'])))
				}
				if (page > 0 && size > 0) {
					//make sure we're querying pagination
					dispatch(appendCartProductToppings(res.data))
					return
				}

				dispatch(setCartProductToppings(res.data))
			}
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCartProductThunk = (item) => dispatch => {
	return fetchProduct(item.itemId)
		.then((res) => {
			dispatch(setCartProductProductFocus(res.data))
			dispatch(setCartProductItemFocus(item))
		}).catch((error) => {
			// dispatch(setAppErrors(retrieveErrorFromApi(error))) todo
		})
}

export const getCartToppingGroupsThunk = (categoryId, relatedProductId, businessScope) => dispatch => {
	return fetchAllAdditivesGroups(0, 20, categoryId, true, true, relatedProductId, businessScope)
		.then((res) => {
			dispatch(setCartProductToppingGroups(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
