import { filterSearchString } from '../../../../../../../helper/filterSearchString'
import { capitalize, formatAddress } from '../../../../../../../helper'

export const filterPhone = (value) => value.replace(/\D/g, '').slice(0, 20)

export const filterNumber = (value) => value.replace(/\D/g, '').slice(0, 19)

export const filterName = (value) => {
	const replaceForbiddenSymbol = filterSearchString(value)
	return capitalize(replaceForbiddenSymbol)
}

export const formatAddressInInput = (data) => {
	return formatAddress(data.street, data.countryName, data.postalCode, data.city)
}

export const filterCompanyName = (value) => {
	return filterSearchString(value)
}
