import React, { Suspense } from 'react'
import PageLoader from '../../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../../helper/lazyWithRetry'

const PasswordLess = lazyWithRetry(() => import(/* webpackChunkName: "password-less" */'../../../component/Login/PasswordLess'))

const PasswordLessPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<PasswordLess />
		</Suspense>
	)
})

PasswordLessPage.displayName = 'PasswordLessPage'
export default PasswordLessPage
