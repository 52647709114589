import styled from 'styled-components'
import colors from '../../../../style/colors'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const TableTdText = styled.span`
	&.error {
		color:  ${colorTheme.table.tableTr.dangerText};
	}
`

export const TextNoWrap = styled.div`
	white-space: nowrap;
`
TextNoWrap.displayName = 'TextNoWrap'

export const CurrencyWrap = styled.div`
  &.error {
    padding: .3em .5em;
    border-radius: .75em;
    background-color: ${colors.red};
    color: ${colors.white};
  }
`
CurrencyWrap.displayName = 'CurrencyWrap'
export const DashedDivider = styled.div`
  width: 100%;
  border: 1px dashed red;
`
DashedDivider.displayName = 'DashedDivider'

