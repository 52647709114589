import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const FoodoraRestaurant = lazyWithRetry(() => import(/* webpackChunkName: "wolt-restaurant" */'../../component/FoodoraRestaurant'))

const FoodoraRestaurantPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<FoodoraRestaurant />
		</Suspense>
	)
})

FoodoraRestaurantPage.displayName = 'FoodoraRestaurantPage'
export default FoodoraRestaurantPage
