import styled from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'


export const WebShopNotificationBtnWrap = styled.button`
	font-size: 1rem;
	background-color: #DE7B00;
	border-radius: 0.75em;
	display: flex;
	justify-content: center;
	gap: 0.69em;
	align-items: center;
	color: ${colors.white};
	padding: 0 0.63em 0 0;
	cursor: pointer;
	border: 1px solid #DE7B00;
	margin-right: 1.5em;
`
WebShopNotificationBtnWrap.displayName = 'WebShopNotificationBtnWrap'

export const WebShopNotificationBtnContent = styled.div`
	background-color: #1D1F31;
	border-radius: 0.75em;
	padding: .19em .56em;
	display: flex;
	align-items: center;


	> svg {
		width: 1.3em;
		height: 1.82em;
		margin-right: 0.56em;
		fill: #DE7B00;
	}
`
WebShopNotificationBtnContent.displayName = 'WebShopNotificationBtnContent'

export const WebShopNotificationBtnText = styled.div`
	font-size: 1.125em;
	display: flex;
	align-items: center;
	font-family: ${theme.fontRobotoMedium};
	color: ${colors.white};
`
WebShopNotificationBtnText.displayName = 'WebShopNotificationBtnText'

export const WebShopNotificationBtnPercent = styled.div`
	font-size: 1rem;
	display: inline-block;
	position: relative;
	width: 2.13em;
	height: 2.13em;
	margin-left: 0.19em;

  > svg {
  	width: 100%;
  	height: 100%;
  }
`
WebShopNotificationBtnPercent.displayName = 'WebShopNotificationBtnPercent'

