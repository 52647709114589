import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { staticPages } from '../../../redux/reducer/system/constants'
import { selectCompanyIndex, selectHasAccessToken } from '../../../redux/selector/system'

const PrivatePage = React.memo(({ component: Component, redirect }) => {
	const hasAccessToken = useSelector(selectHasAccessToken)
	const companyIndex = useSelector(selectCompanyIndex)
	const pages = useSelector(store => store.system.pages)
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (!companyIndex && hasAccessToken) navigate('/registration/company')
	}, [companyIndex]) // eslint-disable-line react-hooks/exhaustive-deps

	const filteredPages = useMemo(() =>
		staticPages.filter(o1 => pages.some(o2 => o1.id === o2)), [pages])

	const hasCurrentLocationAccess = useMemo(() =>
		filteredPages.find(fp => location.pathname.includes(fp.link)), [location.pathname, filteredPages])

	const defaultPagePath = useMemo(() => ({ pathname: filteredPages[0]?.link }), [filteredPages])

	if (filteredPages?.length > 0
		&& !hasCurrentLocationAccess
		&& location.pathname !== '/desktop/client') {
		return <Navigate to={defaultPagePath} />
	}

	return (
		<>
			{hasAccessToken ?
				<Component /> :
				<Navigate to={{
					pathname: redirect ? redirect : '/login',
					search: location?.search,
					state: { from: location },
				}} state={{ from: location }} />}
		</>
	)
})

PrivatePage.displayName = 'PrivatePage'
export default PrivatePage
