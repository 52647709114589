import React, { useEffect, useState } from 'react'
import { InfoOrderBody, InfoOrderCounter, InfoOrderData, InfoOrderFooter, InfoOrderText, InfoOrderTime, InfoOrderTitle, InfoOrderWrap } from './index.styled'
import { useSelector } from 'react-redux'
import MainIcon from '../../../img/svg/main'
import { getNeededDateFormat } from '../../../helper'
import Currency from '../../Elements/currency'
import { selectOrdersById } from '../../../redux/selector/order'
import { calculateTooltipPosition } from './helper'
import { selectRemainingTime } from '../../../redux/selector/timerCounter'
import { useTranslation } from 'react-i18next'
import { compareDesc } from 'date-fns'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import { HEIGHT_TOOLTIP, HEIGHT_TOOLTIP_SMALL, WIDTH_TOOLTIP, WIDTH_TOOLTIP_SMALL } from './constants'

const InfoOrder = React.memo(({ formWidth = 0, orderTableWidth = 0 }) => {
	const { t } = useTranslation()
	const markerCoordinates = useSelector(state => state.map.coordinatesMarker)
	const orderId = useSelector(state => state.map.orderId)
	const order = useSelector(selectOrdersById(orderId))
	const width = useSelector(state => state.map.widthMap)
	const widthMenu = useSelector(state => state.map.widthMenu)
	const heightHeader = useSelector(state => state.map.heightHeader)
	const height = useSelector(state => state.map.heightMap)
	const counterTime = useSelector(selectRemainingTime(order?.platformMetaData?.estimatedProcessTimestamp, t('order.hours'), t('order.minutes')))
	const isSmallDesktop = useWindowBreakPoint(theme.point1280)
	const [tooltipStyle, setTooltipStyle] = useState(null)
	const isDelayTime = order?.platformMetaData?.estimatedProcessTimestamp && compareDesc(new Date(), new Date(order?.platformMetaData?.estimatedProcessTimestamp)) === -1
	const timeText = isDelayTime ? t('order.delay', { counterTime }) : t('order.left', { counterTime })
	const widthTooltip = isSmallDesktop ? WIDTH_TOOLTIP_SMALL : WIDTH_TOOLTIP
	const heightTooltip = isSmallDesktop ? HEIGHT_TOOLTIP_SMALL : HEIGHT_TOOLTIP

	useEffect(() => {
		return () => setTooltipStyle(null)
	}, [])

	useEffect(() => {
		if (markerCoordinates) {
			const tooltipStyle = calculateTooltipPosition(markerCoordinates, width, height, widthTooltip, heightTooltip, formWidth, widthMenu, heightHeader, orderTableWidth)

			setTooltipStyle(tooltipStyle)
		}
	}, [markerCoordinates, height, width, isSmallDesktop, heightHeader, orderTableWidth, widthMenu, formWidth])

	return (
		<>
			<InfoOrderWrap top={tooltipStyle?.top} left={tooltipStyle?.left} position={tooltipStyle?.position}>
				<InfoOrderBody>
					<InfoOrderTitle className={counterTime && 'big'}>
						<InfoOrderCounter className="big blue">
							<MainIcon icon="DELIVERY" />
							<InfoOrderText className="big">№ {order?.orderDayCounter}</InfoOrderText>
						</InfoOrderCounter>
						<InfoOrderText className="blue medium">{getNeededDateFormat(order?.orderDate, 'dd.MM.yyyy HH:mm')}</InfoOrderText>
						{counterTime &&
							<InfoOrderTime className={isDelayTime && 'warning'}>
								<MainIcon icon="clock_on_3" />
								<InfoOrderText className="spacing blue">{timeText}</InfoOrderText>
							</InfoOrderTime>}
					</InfoOrderTitle>

					<InfoOrderData>
						<MainIcon icon="user_in_circle" />
						<InfoOrderText>{order?.customer.customerNumber}</InfoOrderText>
					</InfoOrderData>
					<InfoOrderData>
						<MainIcon icon="user" />
						<InfoOrderText>{order?.customer.name}</InfoOrderText>
					</InfoOrderData>
					<InfoOrderData>
						<MainIcon icon="telephone_icon" />
						<InfoOrderText>{order?.customer.phoneNumber}</InfoOrderText>
					</InfoOrderData>
					<InfoOrderData column="1 / 3">
						<MainIcon icon="address" />
						<InfoOrderText>{order?.customer.street} {order?.customer.streetNumber}, {order?.customer.city}, {order?.customer.postalCode}</InfoOrderText>
					</InfoOrderData>
				</InfoOrderBody>
				<InfoOrderFooter>
					<MainIcon icon="CASH" />
					<InfoOrderText className="big">
						<Currency>{order?.totalPrice}</Currency>
					</InfoOrderText>
				</InfoOrderFooter>
			</InfoOrderWrap>
		</>
	)
})

InfoOrder.displayName = 'InfoOrder'
export default InfoOrder
