import { ADD_CASHBOOK_NEW_TRANSACTION, APPEND_CASHBOOK_NEW_CASHBOOKS, SET_CASHBOOK_NEW_ACCEPT_INITIAL_BALANCE, SET_CASHBOOK_NEW_BALANCE_OF_OLD_CASHBOOK_WAS_ACCEPTED, SET_CASHBOOK_NEW_CASH_BALANCE, SET_CASHBOOK_NEW_CASH_TOTAL, SET_CASHBOOK_NEW_CASHBOOK, SET_CASHBOOK_NEW_CASHBOOK_ID, SET_CASHBOOK_NEW_CASHBOOKS, SET_CASHBOOK_NEW_CASHBOOKS_TOTAL_LENGTH, SET_CASHBOOK_NEW_CATEGORIES, SET_CASHBOOK_NEW_CATEGORY, SET_CASHBOOK_NEW_CATEGORY_ID, SET_CASHBOOK_NEW_DATE_FROM, SET_CASHBOOK_NEW_DATE_TO, SET_CASHBOOK_NEW_INITIAL_BALANCE_OLD_CASHBOOK, SET_CASHBOOK_NEW_LOADING, SET_CASHBOOK_NEW_LOADING_BALANCE, SET_CASHBOOK_NEW_LOADING_DATEV, SET_CASHBOOK_NEW_SHOW_FORM, SET_CASHBOOK_NEW_SHOW_FORM_CATEGORY, SET_CASHBOOK_NEW_SHOW_MODAL_NAVIGATE_TO_DATEV, SET_CASHBOOK_NEW_TRANSACTION_TYPE, UPDATE_CASHBOOK_NEW_TRANSACTION } from '../../actionTypes/cashbookNew'

export const setCashBookNewShowForm = value => ({ type: SET_CASHBOOK_NEW_SHOW_FORM, value })

export const setCashBookNewCategoryId = value => ({ type: SET_CASHBOOK_NEW_CATEGORY_ID, value })

export const setCashBookNewCategories = value => ({ type: SET_CASHBOOK_NEW_CATEGORIES, value })

export const setCashBookNewLoading = value => ({ type: SET_CASHBOOK_NEW_LOADING, value })

export const setCashBookNewDateFrom = value => ({ type: SET_CASHBOOK_NEW_DATE_FROM, value })

export const setCashBookNewDateTo = value => ({ type: SET_CASHBOOK_NEW_DATE_TO, value })

export const setCashBookNewCashBooks = value => ({ type: SET_CASHBOOK_NEW_CASHBOOKS, value })

export const setCashBookNewCashBooksTotalLength = value => ({ type: SET_CASHBOOK_NEW_CASHBOOKS_TOTAL_LENGTH, value })

export const appendCashBookNewCashBooks = value => ({ type: APPEND_CASHBOOK_NEW_CASHBOOKS, value })

export const setCashBookNewCashBookId = value => ({ type: SET_CASHBOOK_NEW_CASHBOOK_ID, value })

export const setCashBookNewCashBook = value => ({ type: SET_CASHBOOK_NEW_CASHBOOK, value })

export const setCashBookNewAcceptInitialBalance = value => ({ type: SET_CASHBOOK_NEW_ACCEPT_INITIAL_BALANCE, value })

export const setCashBookNewInitialBalanceOldCashBook = value => ({ type: SET_CASHBOOK_NEW_INITIAL_BALANCE_OLD_CASHBOOK, value })

export const setCashBookNewBalanceOfOldCashBookWasAccepted = value => ({ type: SET_CASHBOOK_NEW_BALANCE_OF_OLD_CASHBOOK_WAS_ACCEPTED, value })

export const setCashBookNewCashBalance = value => ({ type: SET_CASHBOOK_NEW_CASH_BALANCE, value })

export const setCashBookNewShowFormCategory = value => ({ type: SET_CASHBOOK_NEW_SHOW_FORM_CATEGORY, value })

export const setCashBookNewTransactionType = value => ({ type: SET_CASHBOOK_NEW_TRANSACTION_TYPE, value })

export const setCashBookNewCategory = value => ({ type: SET_CASHBOOK_NEW_CATEGORY, value })

export const addCashBookNewTransaction = value => ({ type: ADD_CASHBOOK_NEW_TRANSACTION, value })

export const updateCashBookNewTransaction = value => ({ type: UPDATE_CASHBOOK_NEW_TRANSACTION, value })

export const setCashBookNewLoadingDateV = value => ({ type: SET_CASHBOOK_NEW_LOADING_DATEV, value })

export const setCashBookNewShowModalNavigateToDateV = value => ({ type: SET_CASHBOOK_NEW_SHOW_MODAL_NAVIGATE_TO_DATEV, value })

export const setCashBookNewLoadingBalance= value => ({ type: SET_CASHBOOK_NEW_LOADING_BALANCE, value })

export const setCashBookNewCashTotal= value => ({ type: SET_CASHBOOK_NEW_CASH_TOTAL, value })

