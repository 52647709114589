export const statusOptionsStatic = [
	{ value: 'NOT_ACCEPTED', label: 'order.status.not_accepted' },
	{ value: 'NOT_PAID', label: 'order.status.not_paid' },
	{ value: 'NOT_REGISTERED', label: 'order.status.not_registered' },
	{ value: 'DENIED', label: 'order.status.denied' },
	{ value: 'BEING_PREPARED', label: 'order.status.prepared' },
	{ value: 'READY', label: 'order.status.ready' },
	{ value: 'ON_THE_WAY', label: 'order.status.on_the_way' },
	{ value: 'COMPLETED', label: 'order.status.completed' },
	{ value: 'CANCELED', label: 'order.status.canceled' },
	{ value: 'CLOSED', label: 'order.status.closed' },
]

export const orderTypeOptionsStatic = [
	{ value: 'DELIVERY', label: 'order.order_type.delivery' },
	{ value: 'PICKUP', label: 'order.order_type.pickup' },
	{ value: 'COUNTER', label: 'order.order_type.counter' },
	{ value: 'RESTAURANT', label: 'order.order_type.restaurant' },
]

export const statusColorOptions = {
	BEING_PREPARED: '#008fd9',
	ON_THE_WAY: '#008fd9',
	COMPLETED: '#38cd90',
	CANCELED: '#eb0901',
	NOT_ACCEPTED: '#ffa921',
	NOT_REGISTERED: '#B7008F',
	DENIED: '#eb0901',
	READY: '#008fd9',
}

export const getSortedOrders = (orders, reverse) => {
	if (reverse) {
		orders.sort((o1, o2) => {
			if (o1.zNumber !== o2.zNumber) {
				return !o2.zNumber ? -1 : (!o1.zNumber ? 1 : (o1.zNumber - o2.zNumber))
			} else {
				return !o2.orderDayCounter ? -1 : (!o1.orderDayCounter ? 1 : (o1.orderDayCounter - o2.orderDayCounter))
			}
		})
	} else {
		orders.sort((o1, o2) => {
			if (o1.zNumber !== o2.zNumber) {
				return !o2.zNumber ? 1 : (!o1.zNumber ? -1 : (o2.zNumber - o1.zNumber))
			} else {
				return !o2.orderDayCounter ? 1 : (!o1.orderDayCounter ? -1 : (o2.orderDayCounter - o1.orderDayCounter))
			}
		})
	}
	return orders
}
