import React from 'react'
import { CartItemAlignWrapper, CartProductBlock, CartProductQuantity, CartProductTitle, CartProductTitleBlock, CartProductWrapper } from '../index.styled'
import CartProduct from '../CartProduct'
import ArrowButton from '../ArrowButton'
import KitchenStatusCircle from '../../KitchenStatusCircle'
import CartDishCourse from '../CartDishCourse'
import PreparedMessage from '../PreparedMessage'
import { useTranslation } from 'react-i18next'
import Button from '../../../../Elements/buttons/Button'
import { useSelector } from 'react-redux'
import Loader from '../../../../Elements/loaders'

const CartMenu = React.memo(({
	item, index, includeStatus, enabledDishCourse,
	direction, onDirectionClick, onPreparedClick,
}) => {
	const { t } = useTranslation()
	const dataTestId = 'menu-' + index
	const isBeingPreparedItem = item?.status === 'BEING_PREPARED'
	const isPlacedItem = item?.status === 'PLACED'
	const isPreparedItem = item?.status === 'PREPARED'
	const isCanceledItem = item?.status === 'CANCELED'
	const itemHasDishCourse = enabledDishCourse || item?.dishCourse
	const layout = (isCanceledItem ? 'canceled ' : '') +
		((includeStatus && itemHasDishCourse) ? 'with-dish-course-and-status ' : '') +
		((includeStatus && !itemHasDishCourse) ? 'with-status ' : '') +
		((!includeStatus && itemHasDishCourse) ? 'with-dish-course ' : '')
	const alignLayout = 'start '
	const loadingItemId = useSelector(state => state.tableCart.loadingItemId)

	return (
		<CartProductWrapper className={layout} data-testid={dataTestId}>
			{itemHasDishCourse && <CartItemAlignWrapper className={alignLayout}><CartDishCourse item={item} /></CartItemAlignWrapper>}
			{includeStatus && <CartItemAlignWrapper className={alignLayout}><KitchenStatusCircle testId={`${dataTestId}-status-${item.status}`} status={item.status} /></CartItemAlignWrapper>}
			<CartProductBlock>
				<CartProductTitleBlock>
					<CartProductTitle className={layout}>{item?.name}</CartProductTitle>
					<CartProductQuantity className={layout} data-testid={dataTestId + '-quantity'}>x{item?.quantity}</CartProductQuantity>
				</CartProductTitleBlock>
				{item?.items.map((item, i) =>
					<CartProduct key={i}
											 item={item}
											 index={i}
											 isMenuCanceled={isCanceledItem}
											 isMenuProduct={true} />)}
			</CartProductBlock>
			<CartItemAlignWrapper className={alignLayout + 'mobile-ml-auto'}>
				{isPlacedItem && <ArrowButton testId={`${dataTestId}-${direction}`} direction={direction} onClick={(e) => onDirectionClick(e, direction)} />}
				{isBeingPreparedItem &&
					<Button name="prepared" icon={loadingItemId !== item.itemPositionId && 'checkmark'} color="green" size="small"
									text={loadingItemId !== item.itemPositionId && t('restaurant.sendToKitchenModal.button.prepared')}
									onClick={onPreparedClick}>
						{loadingItemId === item.itemPositionId && <Loader zoom={0.5} />}
					</Button>}
				{isPreparedItem && <PreparedMessage />}
			</CartItemAlignWrapper>
		</CartProductWrapper>
	)
})

CartMenu.displayName = 'CartMenu'
export default CartMenu
