import styled from 'styled-components'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../style/theme'

export const ResendEmailModalWrap = styled.div`
	font-size: 1rem;
	display: grid;
	gap: 1.25em;
	width: 100%;
`
ResendEmailModalWrap.displayName = 'ResendEmailModalWrap'

export const ResendEmailText = styled.div`
	color: ${colorTheme.invoices.modal.resendEmail.text};
	text-align: center;
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.13em;
	font-weight: 500;
`
ResendEmailText.displayName = 'ResendEmailText'
