import { SET_RESTAURANT_CHANGE_SETTINGS, SET_RESTAURANT_SETTINGS_CART_SETTINGS, SET_RESTAURANT_SETTINGS_DISH_COURSES, SET_RESTAURANT_SETTINGS_PRESET_DISCOUNTS, SET_RESTAURANT_SETTINGS_PRINT_SETTINGS, SET_RESTAURANT_SETTINGS_WAITER_SETTINGS, UPDATE_RESTAURANT_CHANGE_SETTING, UPDATE_RESTAURANT_SETTINGS_CART_SETTING } from '../../actionTypes/restaurantSettings'

export const setRestaurantSettingsCartSettings = value => ({ type: SET_RESTAURANT_SETTINGS_CART_SETTINGS, value })

export const updateRestaurantSettingsCartSetting = value => ({ type: UPDATE_RESTAURANT_SETTINGS_CART_SETTING, value })

export const setRestaurantSettingsChangeSettings = value => ({ type: SET_RESTAURANT_CHANGE_SETTINGS, value })

export const updateRestaurantSettingsChangeSetting = value => ({ type: UPDATE_RESTAURANT_CHANGE_SETTING, value })

export const setRestaurantSettingsWaiterSettings = value => ({ type: SET_RESTAURANT_SETTINGS_WAITER_SETTINGS, value })

export const setRestaurantSettingsPrintSettings = value => ({ type: SET_RESTAURANT_SETTINGS_PRINT_SETTINGS, value })

export const setRestaurantSettingsPresetDiscounts = value => ({ type: SET_RESTAURANT_SETTINGS_PRESET_DISCOUNTS, value })

export const setRestaurantSettingsDishCourses = value => ({ type: SET_RESTAURANT_SETTINGS_DISH_COURSES, value })
