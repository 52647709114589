import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { ToggleButtonWrapper, ToggleItemText } from '../../buttons/ToggleButton/index.styled'
import { KeyboardMain } from '../../keyboard/index.styled'

export const CartCategory = styled.div`
  font-size: 1rem;
  overflow: auto;
	grid-row: 1 / 3;

  &.touchPad {
    display: grid;
    grid-template-rows: min-content min-content min-content auto min-content;
  }

  @media screen and (max-width: ${theme.point820}) {
		grid-row: 1;

    &.touchPad {
      grid-template-rows: min-content min-content auto min-content;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    position: sticky;
    bottom: 0;
		z-index: 101;
    background-color: ${colorTheme.cartCategory.background};
    padding: .63em;
    box-shadow: 0px -5px 10px 0px ${colorTheme.cartCategory.shadow};
		grid-row: 2;

    &.touchPad {
      width: 100%;
      grid-template-columns: auto 3.44em auto;
      grid-template-rows: auto;
      align-items: center;
      column-gap: 0.31em;
			row-gap: 0.63em;
      margin: 0.63em 0 0;
			height: 100%;
			min-height: 4.69em;
    }

    &.hasKeyboard {
      grid-template-rows: 1fr auto;
			margin: 0;

      ${KeyboardMain} {
        box-shadow: none;
      }
    }

    ${ToggleButtonWrapper} {
      height: 3.44em;
    }

    ${ToggleItemText} {
      font-size: 1em;
    }
  }
`
CartCategory.displayName = 'CartCategory'

export const CategoryTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 1.13em;
  font-family: ${theme.fontRobotoMedium};
  line-height: 1.17em;
  color: ${colorTheme.cartCategory.color};
  text-align: center;
  margin-bottom: 0.5em;
  margin-top: 1em;

  &.touchPad {
    display: none;
  }
`

export const CategoryList = styled.div`
  font-size: 1em;

  &.touchPad {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(calc(9vh - 0.19em), max-content);
    gap: 0.19em;
    margin-top: 0.44em;
    overflow: auto;
  }

  @media screen and (max-width: ${theme.point820}) {
    &.touchPad {
			grid-auto-rows: max-content;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    &.touchPad {
      display: none;
    }
  }
`

export const CategoryButton = styled.button`
  width: 100%;
  min-height: 2.75em;
  outline: 0;
  border-radius: 0.6em;
  margin-bottom: 0.15em;
  font-size: 1.25em;
  cursor: pointer;
  color: ${colorTheme.cartCategory.categoryButton.color};
  border: solid 2px ${colorTheme.cartCategory.categoryButton.border};
  background-color: ${colorTheme.cartCategory.categoryButton.background};

  &.active {
    color: ${colorTheme.cartCategory.categoryButton.active.color};
		border: solid 2px ${colorTheme.cartCategory.categoryButton.active.border};
    background-color: ${colorTheme.cartCategory.categoryButton.active.background};
  }
`
CategoryButton.displayName = 'CategoryButton'

export const FavCategoryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${theme.fontRobotoRegular};
  height: 2.75em;
  border: 1px solid ${props => props.themeStyles.category.border};
  border-radius: 0.6em;
  background-color: ${props => props.themeStyles.category.background};
  width: 100%;
  font-size: 1.25em;
	color: ${colorTheme.cartCategory.favCategoryButton.color};
  padding: 0.5em 0.4em;
  margin-bottom: 0.15em;
  cursor: pointer;
  gap: 0.35em;
	word-break: break-all;

  > svg {
    width: 1.1em;
    height: 0.85em;
		fill: ${colorTheme.cartCategory.favCategoryButton.icon};
  }

  &.active {
    background-color: ${colorTheme.cartCategory.favCategoryButton.active.background};
    color: ${colorTheme.cartCategory.favCategoryButton.active.color};
    font-family: ${theme.fontRobotoBold};
  }

  &.focus {
    background-color: ${colorTheme.cartCategory.favCategoryButton.focus.background};
    box-shadow: 0 6px 39px ${colorTheme.cartCategory.favCategoryButton.focus.shadow};
    border: 2px solid ${colorTheme.cartCategory.favCategoryButton.focus.border};
    font-family: ${theme.fontRobotoBold};
    color: ${colorTheme.cartCategory.favCategoryButton.focus.color};
  }

  &.back {
    background-color: ${colorTheme.cartCategory.favCategoryButton.back.background};
    box-shadow: none;
    border-color: ${colorTheme.cartCategory.favCategoryButton.back.border};
    font-family: ${theme.fontRobotoBold};
    color: ${colorTheme.cartCategory.favCategoryButton.back.color};

    &.focus {
      background-color: ${colorTheme.cartCategory.favCategoryButton.back.focus.background};
      box-shadow: 0 6px 39px ${colorTheme.cartCategory.favCategoryButton.back.focus.shadow};
      border: 2px solid ${colorTheme.cartCategory.favCategoryButton.back.focus.border};
      color: ${colorTheme.cartCategory.favCategoryButton.back.focus.color};
    }
  }

  &.touchPad {
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.13em;
    color: ${colorTheme.cartCategory.favCategoryButton.touchPad.color};
    text-align: center;
    letter-spacing: -0.45px;
    border-radius: 0.67em;
    border: none;
    margin: 0;
    height: auto;
		background-color: ${props => props.themeStyles.categories.background} !important;

    &.active {
      color: ${colorTheme.cartCategory.favCategoryButton.color}
      background-color: inherit;
      font-family: ${theme.fontRobotoMedium};
    }

    &:nth-child(8n + 1) {
      background-color: ${colorTheme.cartCategory.favCategoryButton.background.button_8n1};
    }

    &:nth-child(8n + 2) {
			background-color: ${colorTheme.cartCategory.favCategoryButton.background.button_8n2};
    }

    &:nth-child(8n + 3) {
			background-color: ${colorTheme.cartCategory.favCategoryButton.background.button_8n3};
    }

    &:nth-child(8n + 4) {
			background-color: ${colorTheme.cartCategory.favCategoryButton.background.button_8n4};
    }

    &:nth-child(8n + 5) {
			background-color: ${colorTheme.cartCategory.favCategoryButton.background.button_8n5};
    }

    &:nth-child(8n + 6) {
			background-color: ${colorTheme.cartCategory.favCategoryButton.background.button_8n6};
    }

    &:nth-child(8n + 7) {
			background-color: ${colorTheme.cartCategory.favCategoryButton.background.button_8n7};
    }

    &:nth-child(8n + 8) {
			background-color: ${colorTheme.cartCategory.favCategoryButton.background.button_8n8};
    }
  }

	@media screen and (max-width: ${theme.point820}) {
		min-height: 4.43em;
  }

	@media screen and (max-width: ${theme.point720}) {
    &.touchPad {
			height: auto;
    }
  }
`
FavCategoryButton.displayName = 'FavCategoryButton'
