import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const ToppingGroupsConfig = lazyWithRetry(() => import(/* webpackChunkName: "topping-groups-edit" */'../../component/ToppingGroups/ToppingGroupsConfig'))

const ToppingGroupsConfigPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<ToppingGroupsConfig />
		</Suspense>
	)
})

ToppingGroupsConfigPage.displayName = 'ToppingGroupsConfigPage'
export default ToppingGroupsConfigPage
