import React from 'react'
import { Outlet } from 'react-router-dom'

const RestaurantPage = React.memo(() => {
	return (
		<Outlet />
	)
})

RestaurantPage.displayName = 'RestaurantPage'
export default RestaurantPage
