import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const TabsWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	gap: 0.63em;
	border-bottom: 1px solid ${colorTheme.tabs.border};

	@media screen and (max-width: ${theme.point820}) {
		justify-content: center;
		gap: 0.72em;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;
	}
`
TabsWrapper.displayName = 'TabsWrapper'

export const Tab = styled.button`
	font-size: 1.25em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	padding: 0.75em 1em;
	border-radius: 0.6em 0.6em 0 0;
	background-color: ${colorTheme.tabs.tab.background};
	min-height: 2.75em;
	color: ${colorTheme.tabs.tab.text};
	border-color: ${colorTheme.tabs.tab.border} ${colorTheme.tabs.tab.border} transparent ${colorTheme.tabs.tab.border};
	border-style: solid solid none solid;
	border-width: 1px 1px 0 1px;
	cursor: pointer;
	user-select: none;
	transition: all .2s ease-in-out;
	display: flex;
	gap: 0.15em;
	justify-content: center;

	&.active {
		background-color: ${colorTheme.tabs.tab.active.background};
		color: ${colorTheme.tabs.tab.active.text};
		border-color: ${colorTheme.tabs.tab.active.border} ${colorTheme.tabs.tab.active.border} transparent ${colorTheme.tabs.tab.active.border};

		&.error {
			background-color: ${colorTheme.tabs.tab.active.error.background};
			color: ${colorTheme.tabs.tab.active.error.text};
			border-color: ${colorTheme.tabs.tab.active.error.border} ${colorTheme.tabs.tab.active.error.border} transparent ${colorTheme.tabs.tab.active.error.border};
		}
	}

	&.error {
		color: ${colorTheme.tabs.tab.error.text};
	}

	&.full-width {
		flex-grow: 1;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.43em;
		padding: 0.75em 0.5em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.25em;
	}
`
Tab.displayName = 'Tab'

export const TabCounter = styled.div`
	font-size: 1rem;
	display: flex;
	width: 1.5em;
	height: 1.5em;
	justify-content: center;
	align-items: center;
	border-radius: 0.75em;
	background: ${colorTheme.tabs.tab.counter.background};
	margin-top: -0.375em;

	&.error {
		background: ${colorTheme.tabs.tab.counter.error.background};
	}

	&.active {
		background: ${colorTheme.tabs.tab.counter.active.background};
		&.error {
			background-color: ${colorTheme.tabs.tab.counter.active.error.background};
		}
	}
`
TabCounter.displayName = 'TabCounter'

export const TabCounterText = styled.div`
	color: ${colorTheme.tabs.tab.counter.text};
	font-size: 0.87em;
	font-weight: 500;

	&.active {
		color: ${colorTheme.tabs.tab.counter.active.text};
		&.error {
			color: ${colorTheme.tabs.tab.counter.active.error.text};
		}
	}
`
TabCounterText.displayName = 'TabCounterText'
