import React, { useEffect, useMemo } from 'react'
import { NotificationItem, NotificationWrap } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { getNotAcceptOrdersThunk } from '../../../../redux/thunk/order'
import CounterAudio from '../../../Elements/audio/CounterAudio'

const OrdersNotification = () => {
	const date = useSelector(store => store.orderfilter.date)
	const counter = useSelector(store => store.ordersCounter.orders.length)
	const dispatch = useDispatch()

	const displayCounter = useMemo(() => counter <= 20 ? counter : '20+', [counter])

	useEffect(() => {
		dispatch(getNotAcceptOrdersThunk(date, date, null, 'NOT_ACCEPTED',
			null, null, null, 0, 50))
	}, [date])

	return (
		<>
			<NotificationWrap isVisible={!!counter} className="counter">
				<NotificationItem>{displayCounter}</NotificationItem>
			</NotificationWrap>

			<CounterAudio />
		</>
	)
}

OrdersNotification.displayName = 'OrdersNotification'
export default OrdersNotification
