import React from 'react'
import ErrorModal from './modal'

class ErrorBoundary extends React.Component {
	constructor (props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError (error) {
		return { hasError: true }
	}

	componentDidCatch (error, info) {
		// eslint-disable-next-line
		console.error('Catch error: ', window.location.pathname, error, info.componentStack)
	}

	handleReload () {
		window.location.reload()
	}

	render () {
		if (this.state.hasError) {
			return <div className="main">
				<ErrorModal isOpen={true} close={this.handleReload} />
			</div>
		}

		return this.props.children
	}
}

ErrorBoundary.displayName = 'ErrorBoundary'
export default ErrorBoundary
