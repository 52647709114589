import { APPEND_ORDERSMART_RESTAURANTS, SET_ORDERSMART_RESTAURANT, SET_ORDERSMART_RESTAURANTS, SET_ORDERSMART_RESTAURANTS_TOTAL_LENGTH, ADD_ORDERSMART_TESTED_RESTAURANT, SET_ORDERSMART_ORDER_COUNT, SET_ORDERSMART_SHOW_TEST_SUCCESS_MODAL, SET_ORDERSMART_SHOW_TEST_CLIENT_ERROR_MODAL, SET_ORDERSMART_SHOW_TEST_SERVER_ERROR_MODAL, SET_ORDERSMART_TEST_ERROR_STATUS } from '../../actionTypes/ordersmartRestaurant'

const initialState = {
	restaurants: [],
	restaurantsTotalLength: 0,
	restaurant: null,
	testedRestaurants: [],
	testedOrderCount: 0,
	showTestSuccessModal: false,
	showTestClientErrorModal: false,
	showTestServerErrorModal: false,
	testErrorStatus: null,
}

export function ordersmartRestaurantReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ORDERSMART_RESTAURANTS:
			return {
				...state,
				restaurants: action.value,
			}
		case SET_ORDERSMART_RESTAURANTS_TOTAL_LENGTH:
			return {
				...state,
				restaurantsTotalLength: action.value,
			}
		case SET_ORDERSMART_RESTAURANT:
			return {
				...state,
				restaurant: action.value,
			}
		case APPEND_ORDERSMART_RESTAURANTS:
			return {
				...state,
				restaurants: state.restaurants ? state.restaurants.concat(...action.value) : action.value,
			}
		case ADD_ORDERSMART_TESTED_RESTAURANT:
			const index = state.testedRestaurants.findIndex(tr => tr.id === action.value.id)
			return {
				...state,
				testedRestaurants: index === -1 ?
					[...state.testedRestaurants, action.value] :
					[...state.testedRestaurants.slice(0, index), action.value, ...state.testedRestaurants.slice(index + 1)],
			}
		case SET_ORDERSMART_ORDER_COUNT:
			return {
				...state,
				testedOrderCount: action.value,
			}
		case SET_ORDERSMART_SHOW_TEST_SUCCESS_MODAL:
			return {
				...state,
				showTestSuccessModal: action.value,
			}
		case SET_ORDERSMART_SHOW_TEST_CLIENT_ERROR_MODAL:
			return {
				...state,
				showTestClientErrorModal: action.value,
			}
		case SET_ORDERSMART_SHOW_TEST_SERVER_ERROR_MODAL:
			return {
				...state,
				showTestServerErrorModal: action.value,
			}
		case SET_ORDERSMART_TEST_ERROR_STATUS:
			return {
				...state,
				testErrorStatus: action.value,
			}
		default:
			return state
	}
}
