import registrationInstance from '../../instance'

const postPasswordLess = (passwordLessCodeId, callbackSuccess, callbackError) => {
  return dispatch => {
    registrationInstance.post(
      '/auth/less',
      {
        passwordLessCodeId,
      })
      .then(res => {
        typeof callbackSuccess === 'function' && callbackSuccess(res)
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }
}

export default postPasswordLess
