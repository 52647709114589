import paymentInstance from '../instance'
import paymentModalLoginInstance from '../modalLoginInstance'

const getPaymentConfigs = (enabledForCashRegister = false, businessScope = null, hasModalLogin = false) => {
	return (hasModalLogin ? paymentModalLoginInstance : paymentInstance).get('/paymentConfigs'
		+ (enabledForCashRegister ? '?enabledForCashRegister=' + enabledForCashRegister : '')
		+ (businessScope !== null ? '&businessScope=' + businessScope : ''))
}

export default getPaymentConfigs
