/* eslint no-console: off */
import { jwtExpired } from '../helper'
import socketIoClient from 'socket.io-client'
import { websocketProxyUrl } from '../urls'

export const connectSocketIo = (token, setSocketCallback, authenticationCallback, socketReconnectedCallback, messageCallback, clientMonitor = false, deviceId, authUuid) => {
  // const url = 'http://localhost:8083'
  const url = websocketProxyUrl.replace('/websocketproxy', '')
  const socket = socketIoClient(url, {
    path: '/websocketproxy/socket.io',
    port: 80,
    reconnection: true,
    forceNew: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    query: {
      Authorization: clientMonitor ? `authUuid:${authUuid}` : `Bearer ${token}`,
      clientMonitor: clientMonitor,
      deviceId: deviceId ? deviceId : ''
    },
    auth: { token }, // todo: switch to auth token
    transports: ['websocket']
  })
  let firstConnection = true
  setSocketCallback(socket)
  socket.on('connect', () => {
    if (firstConnection) {
      console.log('[socket-io] Socket connected')
    } else {
      console.log('[socket-io] Socket reconnected')
      socketReconnectedCallback(socket)
    }
    firstConnection = false
  })
  socket.on('message', (data) => {
    messageCallback(data)
  })
  socket.on('connect_error', async (error) => {
    console.log('[socket-io] Socket connect error')
    // todo: detect 401 error
    let jwt = socket.io.opts.query.Authorization?.substring(7)
    if (jwtExpired(jwt) && !clientMonitor) {
      const newToken = await authenticationCallback()
      console.log('[socket-io] Socket re-authentication success')
      socket.io.opts.query = {
        Authorization: `Bearer ${newToken}`
      }
      try {
        socket.disconnect()
      } catch (disconnectError) {
        console.log('[socket-io] Socket disconnect error: ', disconnectError)
      }
      socket.connect()
    } else {
      console.warn('[socket-io] Socket connection was broken', error)
    }
  })
  socket.on('disconnect', (reason, description) => {
    console.log('[socket-io] Socket disconnected: ', reason, description)
    if (reason === 'io server disconnect') {
      console.log('[socket-io] Try to reconnect after error status:', reason)
      socket.connect()
    }
  })
  socket.on('ping', () => {
    // console.log('Socket ping')
  })
  socket.on('pong', () => {
    // console.log('Socket pong')
  })
}
