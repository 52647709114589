import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { ModalButtonWrap } from '../../modal/ButtonsModal/index.styled'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const InputWrapper = styled.div`
	position: relative;
	margin-bottom: 0.63em;
`
InputWrapper.displayName = 'InputWrapper'

export const InputLabel = styled.label`
	input {
		border-radius: 0.67em;
		width: 100%;
		font-family: ${theme.fontRobotoMedium};
		height: 3.4em;
		background-color: ${colorTheme.cartModal.modalPayment.input.background};
		border: 2px solid ${colorTheme.cartModal.modalPayment.input.border};
		color: ${colorTheme.cartModal.modalPayment.input.text};
		font-size: 1.375rem;
		line-height: 1.18em;
		padding: 0.23em 1.28em;

		&::placeholder {
			opacity: 0.5;
			color: ${colorTheme.cartModal.modalPayment.input.focus.text};
		}
	}

	&.focused input {
		box-shadow: 0 4px 27px rgba(0, 0, 0, 0.22);
		border: 2px solid ${colorTheme.cartModal.modalPayment.input.focus.border};
	}

	img {
		font-size: 1em;
		position: absolute;
		right: 0.94em;
		top: calc(50% - 0.7em);
		cursor: pointer;
		width: 2.31em;
		height: 1.44em;
	}

	&.disabled {
		input {
			background-color: ${colorTheme.cartModal.modalPayment.input.disabled.background};
			border-color: ${colorTheme.cartModal.modalPayment.input.disabled.border};
			color: ${colorTheme.cartModal.modalPayment.input.disabled.text};

			&::placeholder {
				color: ${colorTheme.cartModal.modalPayment.input.disabled.text};
			}
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		input {
			height: 2.5em;
		}
	}
`
InputLabel.displayName = 'InputLabel'

export const ModalPaymentWrapper = styled.div`
	${ModalButtonWrap} {
		min-height: 3.4em;

		@media screen and (max-width: ${theme.point820}) {
			min-height: 2.5em;
			margin-bottom: 0.45em;
		}
	}
`
ModalPaymentWrapper.displayName = 'ModalPaymentWrapper'
