import { APPEND_REPORTS, SET_DATE_FROM, SET_DATE_TO, SET_MONTH_FROM, SET_MONTH_TO, SET_REPORT_TOTAL_LENGTH, SET_REPORTS, SET_SELECTED_STRIPE_ACCOUNT_ID, SET_STRIPE_REPORT_PRINT_MESSAGE_DATA, SET_STRIPE_REPORT_TO_PRINT, SET_STRIPE_REPORTS_DATA } from '../../actionTypes/report'

export const setReports = reports => ({ type: SET_REPORTS, reports })

export const setReportTotalLength = length => ({ type: SET_REPORT_TOTAL_LENGTH, length })

export const appendReports = reports => ({ type: APPEND_REPORTS, reports })

export const setDateTo = dateTo => ({ type: SET_DATE_TO, dateTo })

export const setDateFrom = dateFrom => ({ type: SET_DATE_FROM, dateFrom })

export const setMonthTo = monthTo => ({ type: SET_MONTH_TO, monthTo })

export const setMonthFrom = monthFrom => ({ type: SET_MONTH_FROM, monthFrom })

export const setStripeReportsData = value => ({ type: SET_STRIPE_REPORTS_DATA, value })

export const setStripeReportToPrint = value => ({ type: SET_STRIPE_REPORT_TO_PRINT, value })

export const setStripeReportPrintMessageData = value => ({ type: SET_STRIPE_REPORT_PRINT_MESSAGE_DATA, value })

export const setSelectedStripeAccountId = value => ({ type: SET_SELECTED_STRIPE_ACCOUNT_ID, value })
