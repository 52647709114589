import styled from 'styled-components'
import colors from '../../../../style/colors'
import { theme } from '../../../../style/theme'

export const Keyboard = styled.div`
  font-size: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 0.19em;
  row-gap: 0.19em;
	filter: drop-shadow(0px 18px 109px rgba(0, 0, 0, 0.18));
`
Keyboard.displayName = 'Keyboard'

export const Key = styled.button`
  font-size: 1em;
  background-color: ${colors.purple};
  border-radius: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  user-select: none;
  cursor: pointer;
	transition: all 0.2s ease 0s;
	min-width: 6.19em;
	min-height: 4.69em;

  &:hover {
    background-color: ${colors.blue};
  }

  @media screen and (max-width: ${theme.point820}) {
		min-width: 7.07em;
		min-height: 5.35em;
  }

  @media screen and (max-width: ${theme.point720}) {
		min-width: 6.19em;
		min-height: 4.69em;
  }
`
Key.displayName = 'Key'

export const KeyText = styled.div`
  font-size: 2.25em;
	color: ${colors.white};
  font-family: ${theme.fontRobotoRegular};

	@media screen and (max-width: ${theme.point820}) {
		font-size: 2.57em;
  }

  @media screen and (max-width: ${theme.point720}) {
		font-size: 2.25em;
  }
`
KeyText.displayName = 'KeyText'

export const ClearKey = styled(Key)`
  background-color: ${colors.red};
`
ClearKey.displayName = 'ClearKey'

export const EnterKey = styled(Key)`
  background-color: ${colors.green};

	> svg {
		width: 1.63em;
		height: 1.31em;
		fill: ${colors.white};
	}
`
EnterKey.displayName = 'EnterKey'
