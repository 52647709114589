import React from 'react'
import Button from '../../buttons/Button'
import CustomModal from '../CustomModal'
import { TextP } from '../../../../style/text/index.styled'

const EVENT_Z_INDEX = 1

const InfoModal = React.memo(({ isOpen, text, close, title = 'Achtung!', children, size = 'small' }) => {
	return (
		<CustomModal isOpen={isOpen}
								 close={close}
								 zIndex={EVENT_Z_INDEX}
								 returnZIndex={0}
								 size={size}
								 title={title}
								 disableBackButton={true}
								 button={<Button name="accept" icon="checkmark" onClick={close} tabIndex="1" text="Ok" color="green" />}>
			<TextP>{text}</TextP>
			{children}
		</CustomModal>
	)
})

InfoModal.displayName = 'InfoModal'
export default InfoModal
