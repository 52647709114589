import React from 'react'
import { PriceWrapper } from './index.styled'
import Currency from '../../currency'

const Price = ({ value, active, size, color }) => {
	const layout = 'price ' + (active ? 'active ' : '') + (size ? size + ' ' : '') + (color ? color + ' ' : '')

	return (
		<PriceWrapper className={layout}><Currency>{value}</Currency></PriceWrapper>
	)
}

Price.displayName = 'Price'
export default Price
