const existColors = ['#F7CCD4', '#D7ECE4', '#D4E1FB', '#DCCCF7', '#EADCCC', '#FFCCE4', '#FFD4AE', '#BAEEFF', '#F1D4FC', '#CCF7CE', '#CCF9F1', '#E5FF97', '#F9F7AC', '#E9CFCF']
export const getColorByName = (() => {
	const colorMap = {}
	return (name) => {
		if (!name) return

		let hash = 0

		for (let i = 0; i < name.length; i++) {
			hash = hash + name.charCodeAt(i)
		}

		const index = hash % 5

		if (colorMap[name]) {
			return colorMap[name]
		}

		const color = existColors[index]
		existColors.splice(index, 1)
		colorMap[name] = color

		return color
	}
})()
