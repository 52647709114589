import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const NotificationWrap = styled.div`
	font-size: 1rem;
	width: 1.625em;
	height: 1.625em;
	border-radius: 50%;
	background-color: ${colorTheme.navBar.item.counter.background};
	margin-left: 0.625em;
	visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.125em 0 0;
`
NotificationWrap.displayName = 'NotificationWrap'

export const NotificationItem = styled.span`
	font-size: 0.81em;
	color: ${colorTheme.navBar.item.counter.title};
	font-family: ${theme.fontRoboto};
`
NotificationItem.displayName = 'NotificationItem'
