import { SET_RECOVERY_COMPLETE } from '../../actionTypes'

const initialState = {
	complete: false,
}

export function recoveryReducer (state = initialState, action) {
	switch (action.type) {
		case SET_RECOVERY_COMPLETE:
			return {
				...state,
				complete: action.payload.content,
			}
		default:
			return state
	}
}


