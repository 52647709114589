import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setRestaurantPaymentModal, setRestaurantPaymentTypeModal, setRestaurantSplitModal, setRestaurantTable } from '../../../../../redux/action/restaurant'
import ButtonsModal from '../../../../Elements/modal/ButtonsModal'
import { getTablePrice } from '../../../../../helper'
import { PaymentCost, PaymentTitle, PaymentTitleWrapper } from '../../../../Elements/cart/index.styled'
import Currency from '../../../../Elements/currency'

const EVENT_Z_INDEX = 33

const ModalPayment = () => {
	const showPaymentModal = useSelector(state => state.restaurant.showPaymentModal)
	const table = useSelector(state => state.restaurant.table)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleClose = () => {
		dispatch(setRestaurantTable(null))
		dispatch(setRestaurantPaymentModal(false))
	}

	const navigateToPaymentType = () => {
		dispatch(setRestaurantPaymentModal(false))
		dispatch(setRestaurantPaymentTypeModal(true))
	}

	const navigateToSplit = () => {
		dispatch(setRestaurantPaymentModal(false))
		dispatch(setRestaurantSplitModal(true))
	}

	const buttons = [
		{ icon: 'together-check', text: t('restaurant.tables.modal.together'), onClick: navigateToPaymentType, show: true },
		{ icon: 'split-check', text: t('restaurant.tables.modal.split'), onClick: navigateToSplit, show: true },
	]

	return (
		<ButtonsModal isOpen={!!(showPaymentModal && table)}
									close={handleClose}
									title={'Tisch #' + (table?.number || '')}
									size="small"
									autoFocus={false}
									zIndex={EVENT_Z_INDEX}
									buttons={buttons}>
			<PaymentTitleWrapper>
				<PaymentTitle>{t('Cart.titlePrice')}: </PaymentTitle>
				<PaymentCost><Currency>{getTablePrice(table)}</Currency></PaymentCost>
			</PaymentTitleWrapper>
		</ButtonsModal>
	)
}

ModalPayment.displayName = 'ModalPayment'
export default ModalPayment
