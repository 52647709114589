import React from 'react'
import CartProduct from '../CartProduct'
import { CartMenuWrapper } from './index.styled'
import Currency from '../../../currency'
import PlusMinus from '../PlusMinus'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import { CartProductDishCourseWrap, CartProductWrap, Product, ProductCol, ProductDiscountPrice, ProductName, ProductNr, ProductPrice, ProductQuantity, ProductToppings, ProductTotal } from '../CartProduct/index.styled'
import { calcCartItemTotalPrice } from '../../../../../helper'
import CartRemark from '../CartRemark'
import { useSelector } from 'react-redux'
import { selectDiscountForItem } from '../../../../../redux/selector/cart'
import CartDishCourse from '../CartDishCourse'

const CartMenu = React.memo(({ active, item, removeAbility = true, onClick, onClickPlus, onClickMinus, AddButton, handleRemoveRemark, savedOrder, enabledDishCourse, onClickProduct }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const totalPrice = item ? calcCartItemTotalPrice(item) : 0
	const discount = useSelector(selectDiscountForItem(totalPrice))
	const layout = (touchPad ? 'touchPad ' : '') + (active ? 'active' : '') + (savedOrder ? ' secondary' : '')
	const isCanceledItem = item?.status === 'CANCELED'
	const layoutWrap = `${active ? 'active' : ''} ${touchPad ? 'touchPad' : ''} ${isCanceledItem ? ' strikethrough' : ''} ${item?.dishCourse ? 'dishCourse' : ''}`
	const itemHasDiscount = discount > 0 && !item?.disabledDiscount
	const itemHasDishCourse = enabledDishCourse || item?.dishCourse

	const discountedPrice = itemHasDiscount ? totalPrice - discount : totalPrice
	const layoutPrice = (itemHasDiscount ? 'green ' : '')
	const layoutDishCourseWrap = (itemHasDishCourse ? 'col-2' : '') + (active ? ' active' : '') + ' menu'

	return (
		<CartProductDishCourseWrap className={layoutDishCourseWrap} onClick={onClick}>
			{itemHasDishCourse && <CartDishCourse item={item} savedOrder={savedOrder}/>}
			<CartProductWrap className={layoutWrap} onClick={onClickProduct}>
				<Product className={layout}>
					<ProductNr className={layout + ' menu'}>{item?.sku}</ProductNr>
					<ProductName className={layout + ' menu'}>
						{item?.name}
						{item?.quantity > 1 &&
							<ProductQuantity className={layout + ' menu'}> x {item?.quantity}</ProductQuantity>}
					</ProductName>
					<ProductCol className={layout + ' menu'}><Currency>{item?.price}</Currency></ProductCol>
					<ProductTotal className={layout + ' menu'}>
						{itemHasDiscount && <ProductDiscountPrice><Currency>{totalPrice}</Currency></ProductDiscountPrice>}
						<ProductPrice className={layoutPrice}><Currency>{discountedPrice}</Currency></ProductPrice>
					</ProductTotal>
					{!isCanceledItem && <PlusMinus {...{ AddButton, onClickPlus, onClickMinus, incrementAbility: true, removeAbility, size: touchPad ? 'x-medium' : '' }} />}
				</Product>
				<CartMenuWrapper className={layout}>
					{item?.remark &&
						<ProductToppings className={`toppings ${layout}`}>
							<CartRemark remark={item?.remark}
													layout={layout}
													handleRemoveRemark={handleRemoveRemark} />
						</ProductToppings>}
					{item?.items.map((item, i) =>
						<CartProduct key={i}
												 active={false}
												 item={item}
												 isMenuItem={true}
												 removeAbility={false}
												 incrementAbility={false}
												 handleRemoveRemark={handleRemoveRemark}
												 savedOrder={savedOrder} />)}
				</CartMenuWrapper>
			</CartProductWrap>
		</CartProductDishCourseWrap>

	)
})

CartMenu.displayName = 'CartMenu'
export default CartMenu
