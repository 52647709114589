import React from 'react'
import { PreOrderTypeTd, PreOrderTypeText, PreOrderTypeTr, PreOrderTypeWrap } from './index.styled'

const PreOrdersType = React.memo(({ children, border }) => {
	const layout = border ? 'border' : ''
	return (
		<PreOrderTypeTr>
			<PreOrderTypeTd className={layout}>
				<PreOrderTypeWrap>
					<PreOrderTypeText>{children}</PreOrderTypeText>
				</PreOrderTypeWrap>
			</PreOrderTypeTd>
		</PreOrderTypeTr>
	)
})

PreOrdersType.displayName = 'PreOrdersType'
export default PreOrdersType
