export const subscribeToConsole = (callback) => {
	const originalLog = window.console.log
	window.console.log = (...data) => {
		callback(data, 'info')
		originalLog(...data)
	}

	const originalInfo = window.console.info
	window.console.info = (...data) => {
		callback(data, 'info')
		originalInfo(...data)
	}

	const originalWarn = window.console.warn
	window.console.warn = (...data) => {
		callback(data, 'warn')
		originalWarn(...data)
	}

	const originalError = window.console.error
	window.console.error = (...data) => {
		callback(data, 'error')
		originalError(...data)
	}

	const unsubscribe = () => {
		window.console.log = originalLog
		window.console.info = originalInfo
		window.console.warn = originalWarn
		window.console.error = originalError
	}

	return unsubscribe
}
