import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { InfoShieldBlock, InfoShieldData, InfoShieldItem } from './index.styled'
import { calculateAllOrderSumThunk } from '../../../../../redux/thunk/order/calculateAllOrderSum'
import { selectHasAnyGroup, selectUsername } from '../../../../../redux/selector/system'
import Currency from '../../../currency'

const OrdersInfoShield = React.memo(() => {
	const sumOrders = useSelector(store => store.order.sumOrders)
	const ordersTotalLength = useSelector(store => store.order.ordersTotalLength)
	const orders = useSelector(store => store.order.orders)
	const paymentMethod = useSelector(store => store.orderfilter.paymentMethod)
	const status = useSelector(store => store.orderfilter.status)
	const orderType = useSelector(store => store.orderfilter.orderType)
	const processedByUsername = useSelector(store => store.orderfilter.processedByUsername)
	const processedByUsernameWithEmpty = useSelector(store => store.orderfilter.processedByUsernameWithEmpty)
	const date = useSelector(store => store.orderfilter.date)
	const username = useSelector(selectUsername)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const userIsAdmin = useSelector(selectHasAnyGroup(['OWNER', 'ADMIN']))
	const userIsDriver = useSelector(selectHasAnyGroup(['DRIVER']))

	useEffect(() => {
		dispatch(calculateAllOrderSumThunk(
			date,
			date,
			paymentMethod,
			status,
			orderType,
			processedByUsername,
			userIsDriver ? username : processedByUsernameWithEmpty
		))
	}, [orders?.length, date, paymentMethod, status, orderType, processedByUsername, processedByUsernameWithEmpty, userIsDriver]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<InfoShieldBlock data-testid="info_shield_block"><InfoShieldItem>
			{t('order.table.totalOrders')} <InfoShieldData>{ordersTotalLength}</InfoShieldData>
		</InfoShieldItem>
			{userIsAdmin && <InfoShieldItem>
				{t('order.table.totalSum')}
				<InfoShieldData><Currency>{sumOrders}</Currency></InfoShieldData>
			</InfoShieldItem>}
		</InfoShieldBlock>
	)
})

OrdersInfoShield.displayName = 'OrdersInfoShield'
export default OrdersInfoShield
