import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { OrdersTabsContent, OrdersTabsWrapper } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { getPreOrdersCounterThunk, getUnpaidOrdersCounterThunk } from '../../../../redux/thunk/order'
import OrdersInfoShield from '../../table/OrdersTable/InfoShield'
import { Tab, TabCounter, TabCounterText } from '../index.styled'

const OrdersTabs = React.memo(({ activeTab, onTabClick, allOrders }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const preOrdersCounter = useSelector(store => store.ordersCounter.preOrdersCounter)
	const unpaidOrdersCounter = useSelector(store => store.ordersCounter.unpaidOrders?.length)
	const unpaidInvoicesCounter = useSelector(store => store.invoice.unpaidInvoicesCounter)
	const date = useSelector(store => store.orderfilter.date)
	const preOrderDate = useSelector(store => store.orderfilter.datePreOrder)
	const orderType = useSelector(store => store.orderfilter.orderType)
	const status = useSelector(store => store.orderfilter.status)
	const excludedStatus = useSelector(store => store.orderfilter.excludedStatus)
	const preOrdersToday = useSelector(store => store.order.preOrdersToday)
	const preOrdersDays = useSelector(store => store.order.preOrdersDays)
	const closed = useSelector(store => store.orderfilter.closed)

	const tabs = [
		{ value: 'ORDERS', title: t('order.tabs.all_orders') },
		{ value: 'PRE_ORDERS', title: t('order.tabs.preorders'), counter: preOrdersCounter },
		{ value: 'UNPAID_ORDERS', title: t('order.tabs.unpaid'), counter: unpaidOrdersCounter, type: 'error' },
		{ value: 'INVOICES', title: t('order.tabs.invoices'), counter: unpaidInvoicesCounter }
	]
	useEffect(() => {
		dispatch(getUnpaidOrdersCounterThunk(date, date, orderType, excludedStatus, status, closed, false, 0, 0))
	}, [excludedStatus]) //eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(getPreOrdersCounterThunk(preOrderDate, preOrderDate, orderType, 'NOT_ACCEPTED', closed, 0, 0))
	}, [preOrdersToday, preOrdersDays]) //eslint-disable-line react-hooks/exhaustive-deps

	return (
		<OrdersTabsWrapper>
			<OrdersTabsContent>
				{tabs.map(tab => {
					const layout = (activeTab === tab.value ? 'active ' : '') + (tab.type ? tab.type : '')
					return <Tab key={tab.value} onClick={() => onTabClick(tab.value)}
											className={layout}>
						{tab.title}

						{tab.counter > 0 && <TabCounter className={layout}>
							<TabCounterText className={layout} data-testid="pre-order-counter">{tab.counter}</TabCounterText>
						</TabCounter>}
					</Tab>
				})}
			</OrdersTabsContent>
			{(allOrders && activeTab === 'ORDERS') && <OrdersInfoShield />}
		</OrdersTabsWrapper>
	)
})

OrdersTabs.displayName = 'OrdersTabs'
export default OrdersTabs
