import React from 'react'
import { MenuItemCheck, MenuItemClose, MenuItemContent, MenuItemContentWrap, MenuItemName, MenuItemProduct, MenuItemTopping, MenuItemToppings, MenuItemWrap } from './index.styled'
import Price from '../../../../blocks/Price'
import MainIcon from '../../../../../../img/svg/main'
import { calcCartItemTotalPrice } from '../../../../../../helper'

const MenuItem = ({ focused, menuProduct, onClick, onClickMinus }) => {
	return (
		<MenuItemWrap onClick={onClick}>
			<MenuItemProduct className={focused ? 'focused' : ''}>{menuProduct.categoryTitle}</MenuItemProduct>
			<MenuItemContent className={focused ? 'focused' : ''}>
				<MenuItemContentWrap>
					<MenuItemCheck>
						<MainIcon icon="menu-check" />
					</MenuItemCheck>
					<MenuItemName>
						{menuProduct.item?.name}
					</MenuItemName>
					<MenuItemToppings>
						{menuProduct.item?.items?.map((subItem, i) =>
							<MenuItemTopping key={i}>{subItem.quantity > 0 ? '+' : '-'}{subItem.name}</MenuItemTopping>)}
						<div><Price value={calcCartItemTotalPrice(menuProduct.item)} /></div>
					</MenuItemToppings>
				</MenuItemContentWrap>
				<MenuItemClose onClick={onClickMinus} data-testid="close-menu-item">
					<MainIcon icon="menu-remove" />
				</MenuItemClose>
			</MenuItemContent>
		</MenuItemWrap>
	)
}

MenuItem.displayName = 'MenuItem'
export default MenuItem
