import React from 'react'
import { WrapperLoader } from './index.styled'

const Loader = React.memo(({ color, zoom = 1 }) => {
	return <WrapperLoader color={color} zoom={zoom} data-testid="loader">
		<div />
		<div />
		<div />
		<div />
	</WrapperLoader>
})

Loader.displayName = 'Loader'
export default Loader
