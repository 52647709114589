import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { ModalBody, ModalCloseBlock, ModalCloseKey, ModalFooter, ModalHeader, ModalHeaderTitle, ModalHeaderTitleBlock, ModalHeaderTitleText, ModalMain, ModalMainBlock, ModalWrapper } from './index.styled'
import { useDispatch, useSelector } from 'react-redux'
import { setAppEventZIndex } from '../../../../redux/action/system'
import Price from '../../blocks/Price'
import Button from '../../buttons/Button'
import { theme } from '../../../../style/theme'
import { useTranslation } from 'react-i18next'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import KeyBindings from '../../keybindings'
import MainIcon from '../../../../img/svg/main'
import Recaptcha from '../../Recaptcha'

const DURATION = 3
const CustomModal = React.memo(({
	isOpen = false, close, title, titlePrice, size, children, id, button,
	enableOutsideClick = true, zIndex = 1, returnZIndex = 0,
	showBackButton, disableBackButton, colorFooter = null, mobileFooter = false, dividedFooterLine = false, footerClassname,
	heading = false, titleUpperCase = false, titleColor, icon, showRecaptcha = false, titleInOneRow = false,
	showCloseButton = true,
}) => {
	const [isShow, setIsShow] = useState(false)
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(zIndex))
	const dispatch = useDispatch()
	const isMobile = useWindowBreakPoint(theme.point720)
	const footerLayout = (colorFooter ? 'footerColor ' : '') + (mobileFooter ? 'mobileFooter ' : '') + (dividedFooterLine ? 'dividedFooterLine ' : '') + (size ? `${size} ` : '') + footerClassname
	const titleLayout = (titleUpperCase ? 'titleUpperCase ' : '') + (titleColor ? titleColor : '') + (size ? ` ${size}` : '')
	const modalMainLayout = (showRecaptcha ? 'with-recaptcha ' : '') + (size ? `${size} ` : '')
	const isTablet = useWindowBreakPoint(theme.point820)
	const nodeRef = useRef(null)

	useEffect(() => {
		if (isOpen) {
			dispatch(setAppEventZIndex(zIndex))
			setIsShow(true)
		}
		return () => {
			setIsShow(false)
			dispatch(setAppEventZIndex(returnZIndex))
		}
	}, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleModalWrapperMouseDown = useCallback(() => {
		if (enableOutsideClick) {
			setIsShow(false)
			close()
		}
	}, [enableOutsideClick, close])

	const handleModalMainMouseDown = useCallback(e => {
		e.stopPropagation()
	}, [])

	return (
		<>
			<CSSTransition in={isShow}
										 timeout={DURATION}
										 classNames="modal"
										 unmountOnExit
										 nodeRef={nodeRef}>
				<ModalWrapper id={id}
											data-testid={id}
											onMouseDown={handleModalWrapperMouseDown}
											ref={nodeRef}>
					<ModalMain onMouseDown={handleModalMainMouseDown} className={modalMainLayout}>
						<ModalMainBlock>
							<ModalHeader heading={heading} className={titleLayout}>
								<ModalHeaderTitle className={size + (titleInOneRow ? ' titleInOneRow' : '')}>
									<ModalHeaderTitleBlock>
										{icon && <MainIcon icon={icon} className="header-icon" />}
										<ModalHeaderTitleText>{title}</ModalHeaderTitleText>
									</ModalHeaderTitleBlock>
									{(titlePrice >= 0 && titlePrice !== null) && <Price value={titlePrice} size="big" color={titleColor ? titleColor : ''} />}
								</ModalHeaderTitle>
								{showCloseButton && isTablet &&
									<ModalCloseBlock onClick={close} className="btn-close-modal mobile">
										<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M0.6 0.599219C1.3 -0.100781 2.5 -0.100781 3.3 0.599219L9.6 6.89922L15.9 0.599219C16.6 -0.100781 17.8 -0.100781 18.6 0.599219C19.3 1.29922 19.3 2.49922 18.6 3.29922L12.3 9.59922L18.6 15.8992C19.3 16.5992 19.3 17.7992 18.6 18.5992C18.2 18.8992 17.8 19.0992 17.3 19.0992C16.8 19.0992 16.3 18.8992 16 18.4992L9.7 12.1992L3.4 18.4992C2.9 18.7992 2.4 18.9992 1.9 18.9992C1.4 18.9992 0.9 18.7992 0.6 18.3992C-0.1 17.6992 -0.1 16.4992 0.6 15.6992L6.9 9.39922L0.6 3.19922C-0.2 2.49922 -0.2 1.29922 0.6 0.599219Z" fill="#2881EE" />
										</svg>
									</ModalCloseBlock>}
							</ModalHeader>
							<ModalBody className={size} heading={heading}>{children}</ModalBody>
							{(button || showBackButton || (isMobile && !disableBackButton)) &&
								<ModalFooter className={footerLayout} colorFooter={colorFooter}>
									{(showBackButton || (isMobile && !disableBackButton)) &&
										<Button name="back" icon="back" text={t('buttons.controls.back')} onClick={close} keyButton="Escape" keyText="esc" zIndex={zIndex} className="back" />}
									{button}
								</ModalFooter>}
							{showCloseButton &&
								<ModalCloseBlock onClick={close} className="btn-close-modal">
									<ModalCloseKey>ESC</ModalCloseKey>
									<svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
										<path d="M19.7301 17.4001L33.1401 3.93006C33.8101 3.26006 33.8101 2.17006 33.1401 1.50006C32.4701 0.830059 31.3901 0.830059 30.7301 1.50006L17.3201 14.9801L3.91006 1.50006C3.24006 0.830059 2.16006 0.830059 1.50006 1.50006C0.830059 2.17006 0.830059 3.26006 1.50006 3.93006L14.9001 17.4001L1.50006 30.8801C0.830059 31.5501 0.830059 32.6401 1.50006 33.3101C1.83006 33.6401 2.27006 33.8101 2.71006 33.8101C3.15006 33.8101 3.58006 33.6401 3.92006 33.3101L17.3201 19.8401L30.7201 33.3001C31.0501 33.6301 31.4901 33.8001 31.9301 33.8001C32.3701 33.8001 32.8001 33.6301 33.1401 33.3001C33.8101 32.6301 33.8101 31.5401 33.1401 30.8701L19.7301 17.4001Z" />
									</svg>
								</ModalCloseBlock>}
						</ModalMainBlock>
						{showRecaptcha && <Recaptcha />}
					</ModalMain>
				</ModalWrapper>
			</CSSTransition>
			{isNeededZIndex && showCloseButton &&
				<KeyBindings escape={close} />}
		</>
	)
})

CustomModal.displayName = 'CustomModal'
export default CustomModal
