import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useAudio from '../../../../hooks/useAudio'

const CounterAudio = React.memo(() => {
	const [, setPlaying] = useAudio('/sounds/order.mp3')
	const orders = useSelector(store => store.ordersCounter.orders)

	useEffect(() => {
		setPlaying(!!orders?.length)
	}, [!!orders?.length])

	return null
})

CounterAudio.displayName = 'CounterAudio'
export default CounterAudio
