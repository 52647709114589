import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Permissions = lazyWithRetry(() => import(/* webpackChunkName: "permissions" */'../../component/Permissions'))

const PermissionsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Permissions />
		</Suspense>
	)
})

PermissionsPage.displayName = 'PermissionsPage'
export default PermissionsPage
