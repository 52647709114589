import styled from 'styled-components'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../style/theme'

export const ResendEmailInfoModalWrap = styled.div`
	font-size: 1rem;
	display: grid;
	gap: 1.25em;
	width: 100%;
`
ResendEmailInfoModalWrap.displayName = 'ResendEmailInfoModalWrap'

export const ResendEmailInfoText = styled.div`
	color: ${colorTheme.invoices.modal.resendEmail.text};
	text-align: center;
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.13em;
	font-weight: 500;
`
ResendEmailInfoText.displayName = 'ResendEmailInfoText'
