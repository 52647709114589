import { SET_CUSTOMERS_IMPORT_COUNT, SET_CUSTOMERS_IMPORT_IS_ERROR } from '../../actionTypes/customersImport'

const initialState = {
	countImported: null,
	isError: false
}

export function customersImportReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CUSTOMERS_IMPORT_COUNT:
			return {
				...state,
				countImported: action.value
			}
		case SET_CUSTOMERS_IMPORT_IS_ERROR:
			return {
				...state,
				isError: action.value
			}
		default:
			return state
	}
}


