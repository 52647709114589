import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const CartProductWrapper = styled.div`
	font-size: 1em;
	display: grid;
	align-items: center;

	&.menu {
		padding: 0 0 0 1.25em;
	}
`
CartProductWrapper.displayName = 'CartProductWrapper'

export const CartProductBlock = styled.div`
	display: grid;
	gap: 0.63em;
	align-items: center;

	&.menu {
		gap: 0.31em;
	}
`
CartProductBlock.displayName = 'CartProductBlock'

export const CartProductTitle = styled.div`
	color: ${colorTheme.restaurant.tableWaiterNotifiedModal.product.title};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	font-weight: 500;

	&.menu {
		font-size: 1em;
	}
`
CartProductTitle.displayName = 'CartProductTitle'

export const CartProductTitleBlock = styled.div`
	display: flex;
	gap: 0.63em;
	align-items: center;
`
CartProductTitleBlock.displayName = 'CartProductTitleBlock'

export const CartProductQuantity = styled.div`
	color: ${colorTheme.restaurant.tableWaiterNotifiedModal.product.quantity};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	font-weight: 500;
`
CartProductQuantity.displayName = 'CartProductQuantity'

export const CartToppings = styled.div`
	display: flex;
	gap: 0.31em;
	flex-wrap: wrap;

	&.menu {
		margin-top: 0.31em;
		padding-left: 0.63em;
	}
`
CartToppings.displayName = 'CartToppings'

export const CartTopping = styled.div`
	display: flex;
	gap: 0.13em;
`
CartTopping.displayName = 'CartTopping'

export const CartToppingTitle = styled.div`
	font-family: ${theme.fontRoboto};
	font-size: 1em;
	font-weight: 400;
	color: ${colorTheme.restaurant.tableWaiterNotifiedModal.product.topping};
`
CartToppingTitle.displayName = 'CartToppingTitle'
