import { SET_RESTAURANT_CHANGE_SETTINGS, SET_RESTAURANT_SETTINGS_CART_SETTINGS, SET_RESTAURANT_SETTINGS_DISH_COURSES, SET_RESTAURANT_SETTINGS_PRESET_DISCOUNTS, SET_RESTAURANT_SETTINGS_PRINT_SETTINGS, SET_RESTAURANT_SETTINGS_WAITER_SETTINGS, UPDATE_RESTAURANT_CHANGE_SETTING, UPDATE_RESTAURANT_SETTINGS_CART_SETTING } from '../../actionTypes/restaurantSettings'

const initialState = {
	cartSettings: [],
	changeSettings: [],
	waiterSettings: null,
	printSettings: {},
	presetDiscounts: [],
	dishCourses: {}
}

export function restaurantSettingsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_RESTAURANT_SETTINGS_CART_SETTINGS:
			return {
				...state,
				cartSettings: action.value
			}
		case UPDATE_RESTAURANT_SETTINGS_CART_SETTING:
			return {
				...state,
				cartSettings: [action.value]
			}
		case SET_RESTAURANT_CHANGE_SETTINGS:
			return {
				...state,
				changeSettings: action.value
			}
		case UPDATE_RESTAURANT_CHANGE_SETTING:
			const updateChangeSettings = [...state.changeSettings].map(item => item.businessScope === action.value.businessScope ? action.value : item)
			return {
				...state,
				changeSettings: updateChangeSettings
			}
		case SET_RESTAURANT_SETTINGS_WAITER_SETTINGS:
			return {
				...state,
				waiterSettings: action.value
			}
		case SET_RESTAURANT_SETTINGS_PRINT_SETTINGS:
			return {
				...state,
				printSettings: action.value
			}
		case SET_RESTAURANT_SETTINGS_PRESET_DISCOUNTS:
			return {
				...state,
				presetDiscounts: action.value
			}
		case SET_RESTAURANT_SETTINGS_DISH_COURSES:
			return {
				...state,
				dishCourses: action.value,
			}
		default:
			return state
	}
}
