import { createGlobalStyle } from 'styled-components'
import { theme } from './theme'
import RobotoBlack from './font/Roboto/Roboto-Black.ttf'
import RobotoLight from './font/Roboto/Roboto-Light.ttf'
import RobotoRegular from './font/Roboto/Roboto-Regular.ttf'
import RobotoMedium from './font/Roboto/Roboto-Medium.ttf'
import RobotoBold from './font/Roboto/Roboto-Bold.ttf'
import RobotoThin from './font/Roboto/Roboto-Thin.ttf'
import BerkshireSwashRegular from './font/BerkshireSwash/BerkshireSwash-Regular.ttf'
import colors from './colors'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "RobotoBlack";
    src: url(${RobotoBlack}) format("truetype");
  }

  @font-face {
    font-family: "RobotoLight";
    src: url(${RobotoLight}) format("truetype");
  }

  @font-face {
    font-family: "RobotoRegular";
    src: url(${RobotoRegular}) format("truetype");
  }

  @font-face {
    font-family: "RobotoMedium";
    src: url(${RobotoMedium}) format("truetype");
  }

  @font-face {
    font-family: "RobotoBold";
    src: url(${RobotoBold}) format("truetype");
  }

  @font-face {
    font-family: "RobotoThin";
    src: url(${RobotoThin}) format("truetype");
  }

  @font-face {
    font-family: "BerkshireSwash";
    src: url(${BerkshireSwashRegular}) format("truetype");
  }

  a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 100%
  }

  article, aside, details, figcaption, figure, footer, header, menu, nav, section {
    display: block
  }

  b, strong {
    font-weight: bold
  }

  img {
    color: transparent;
    font-size: 0;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic
  }

  li {
    display: list-item;
    list-style: none
  }

  table {
    border-collapse: collapse;
    border-spacing: 0
  }

  th, td, caption {
    font-weight: normal;
    text-align: left
  }

  q {
    quotes: none
  }

  q:before, q:after {
    content: none
  }

  sub, sup, small {
    font-size: 75%
  }

  sub, sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline
  }

  sub {
    bottom: -0.25em
  }

  sup {
    top: -0.5em
  }

  svg {
    overflow: hidden
  }

  * {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none
  }

  .clear:after, .clear:before {
    clear: both;
    display: block;
    zoom: 1;
    content: '';
  }

  * {
    font-family: ${theme.fontRobotoRegular};
    box-sizing: border-box;
  }

  html {
    font-size: 16px;

    @media screen and (max-width: ${theme.point1660}) {
      font-size: 13.8px;
    }

    @media screen and (max-width: ${theme.point1440}) {
      font-size: 12px;
    }

    @media screen and (max-width: ${theme.point1280}) {
      font-size: 10.7px;
    }

    @media screen and (max-width: ${theme.point1024}) {
      font-size: 10.7px;
    }

    @media screen and (max-width: ${theme.point820}) {
      font-size: 14px;
    }

    @media screen and (max-width: ${theme.point720}) {
      font-size: 16px;
    }
  }

  input {
    font-family: ${theme.fontRobotoMedium};
    background: ${colors.white};
    border-radius: 0.2em;
    font-style: normal;
    font-size: 1em;
    color: ${colors.black};
    font-weight: 500;
    border: 1px solid transparent;
  }

  ::-webkit-scrollbar {
    width: 0.38em;
    height: 0.38em;
    border-radius: 0.75em;
  }

  ::-webkit-scrollbar-track {
    background-color: #B3B3B3;
    border-radius: 0.75em;
  }

  ::-webkit-scrollbar-thumb {
    background: #37394E;
    border-radius: 0.75em;
  }

  @media screen and (max-width: ${theme.point720}) {
    ::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`
