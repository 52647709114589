import React, { useState, useCallback } from 'react'
import { TextAreaWrapper, TextAreaLabel, TextAreaLabelText, TextAreaError, TextAreaField } from './index.styled'

const TextAreaInput = React.memo(({
	register,
	options,
	name,
	label = '',
	testId,
	color,
	size,
	tabIndex,
	watch,
	defaultValue,
	errors = {},
	errorParser,
	hasLongError = false,
	setValue = () => {
	},
	onChange,
	onBlur = () => {
	},
	onFocus = () => {
	},
	}) => {
	const [focused, setFocused] = useState(false)

	const value = typeof watch === 'function' ? watch?.(name) : watch
	const errorMessage = errors && (errorParser ? errorParser(errors) : errors[name])?.message
	const errorClass = hasLongError ? 'error-big' : 'error'
	const labelUp = focused || defaultValue || (value || value === 0)
	const layout = (focused ? 'focus ' : '') + (errorMessage ? `${errorClass} ` : '') + (labelUp ? '' : 'toggled ') + (color ? color + ' ' : '') + (size ? size + ' ' : '')

	const handleFocus = useCallback(() => {
		setFocused(true)
		onFocus()
	}, [onFocus])

	const handleBlur = useCallback(() => {
		setFocused(false)
		onBlur(value, (v) => setValue(name, v))
	}, [onBlur, value, name])

	return (
		<TextAreaWrapper className={layout}>
			<TextAreaError className={layout}>{errorMessage}</TextAreaError>
			<TextAreaLabel className={layout}>
				<TextAreaLabelText className={layout}>{label}</TextAreaLabelText>
				<TextAreaField {...register(name, { ...options, onChange, onBlur: handleBlur })}
					data-testid={testId ?? null}
					id={name}
					rows="10"
					defaultValue={defaultValue ? defaultValue : ''}
					onFocus={handleFocus}
					className={layout}
					tabIndex={tabIndex} />
			</TextAreaLabel>
		</TextAreaWrapper>
	)
})

TextAreaInput.displayName = 'TextAreaInput'
export default TextAreaInput
