import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setCartProductSearchText } from '../../../../redux/action/cartProduct'
import { SearchClear, SearchEnter, SearchInput, SearchNumber, SearchNumbers, TouchPadSearchWrap } from './index.styled'
import backspace from '../../../../img/svg/input/backspace.svg'
import keyboard from '../../../../img/svg/input/small-keyboard.svg'
import { theme } from '../../../../style/theme'
import Keyboard from '../../keyboard'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import KeyBindings from '../../keybindings'
import { filterSearchString } from '../../../../helper/filterSearchString'

const EVENT_Z_INDEX = 0

const CartTouchPadSearch = React.memo(({ incrementProduct, openModalProductType, disabled, setHasKeyboard }) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const { products, loading, searchText, activeProductIndex } = useSelector(state => state.cartProduct)
	const dispatch = useDispatch()
	const inputSearch = useRef()
	const [showKeyboard, setShowKeyboard] = useState(false)
	const isMobile = useWindowBreakPoint(theme.point720)

	useEffect(() => {
		if (!isMobile && isNeededZIndex) {
			inputSearch.current?.focus()
		}
	}, [isNeededZIndex]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!isMobile && !loading && !products.length
			&& !searchText.includes('+') && !searchText.includes('-') && !searchText.includes(',')) {
			inputSearch.current.select()
		}
	}, [products, loading]) // eslint-disable-line react-hooks/exhaustive-deps

	const onChangeSearchInput = useCallback(event => {
		event.target.value = event.target.value.slice(0, 50)
		dispatch(setCartProductSearchText(filterSearchString(event.target.value)))
	}, [dispatch])

	const handleNumberClick = useCallback((value) => {
		if (disabled) return
		dispatch(setCartProductSearchText(searchText + value))
	}, [dispatch, searchText, disabled])

	const handleSignClick = useCallback((value) => {
		if (disabled) return
		let resultSearch
		if (searchText?.indexOf('+') === -1 && searchText?.indexOf('-') === -1) {
			resultSearch = value + searchText
		} else {
			resultSearch = value + searchText.substring(1)
		}
		dispatch(setCartProductSearchText(resultSearch))
	}, [dispatch, searchText, disabled])

	const handleCommaClick = useCallback(() => {
		if (disabled) return
		let value = ','
		if (searchText?.indexOf(',') !== -1) {
			value = ''
		}
		dispatch(setCartProductSearchText(searchText + value))
	}, [dispatch, searchText, disabled])

	const handleEnter = useCallback(() => {
		if (disabled) return
		setShowKeyboard(false)
		setHasKeyboard(false)
		if (searchText.includes('+') || searchText.includes('-') || searchText.includes(',')) {
			openModalProductType()
		} else if (products && products[activeProductIndex]) {
			incrementProduct(products[activeProductIndex])
			dispatch(setCartProductSearchText(''))
		}
	}, [dispatch, searchText, products, openModalProductType, incrementProduct, disabled, activeProductIndex, setHasKeyboard])

	const handleClear = useCallback(() => {
		dispatch(setCartProductSearchText(searchText?.replace(/.$/, '')))
	}, [dispatch, searchText])

	const toggleKeyboard = useCallback(() => {
		setShowKeyboard(!showKeyboard)
		setHasKeyboard(!showKeyboard)
	}, [showKeyboard, setHasKeyboard])

	const closeKeyboard = useCallback(() => {
		setShowKeyboard(false)
		setHasKeyboard(false)
	}, [setHasKeyboard])

	const handleFocus = useCallback(() => {
		if (isMobile && !showKeyboard) {
			toggleKeyboard()
		}
	}, [isMobile, showKeyboard, toggleKeyboard])

	const handleKeyboardType = useCallback((value) => {
		dispatch(setCartProductSearchText(value.replace(',,', ',')))
	}, [dispatch])

	return (
		<>
			<TouchPadSearchWrap className={searchText ? 'hasSearchText' : ''}>
				<SearchInput>
					<input name="touchPadInput"
								 onChange={onChangeSearchInput}
								 onFocus={handleFocus}
								 value={searchText}
								 ref={inputSearch}
								 placeholder={t('Cart.num_product')}
								 autoFocus={!isMobile}
								 autoComplete="off"
								 role="presentation"
								 inputMode="none"
								 disabled={disabled} />
					{isMobile ?
						<img src={keyboard} alt="Keyboard" className="keyboard" onClick={toggleKeyboard} /> :
						<img src={backspace} alt="<-" className="backspace" onClick={handleClear} />}
				</SearchInput>
				<SearchNumbers>
					<SearchNumber onClick={() => handleSignClick('+')}>+</SearchNumber>
					<SearchNumber onClick={() => handleSignClick('-')}>-</SearchNumber>
					<SearchClear onClick={handleClear}>CE</SearchClear>
					<SearchNumber onClick={() => handleNumberClick('7')}>7</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('8')}>8</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('9')}>9</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('4')}>4</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('5')}>5</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('6')}>6</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('1')}>1</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('2')}>2</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('3')}>3</SearchNumber>
					<SearchNumber onClick={() => handleNumberClick('0')}>0</SearchNumber>
					<SearchNumber onClick={handleCommaClick}>,</SearchNumber>
					<SearchEnter onClick={handleEnter}>
						<svg xmlns="http://www.w3.org/2000/svg" width="25.93" height="20.81" viewBox="0 0 25.93 20.81" fill="#FFF">
							<path d="M1871.82,1036.52v-12.1a1.66,1.66,0,1,0-3.32,0v10.43h-16.99l2.5-2.52a1.7,1.7,0,0,0,0-2.37,1.654,1.654,0,0,0-2.35,0l-5.33,5.38a1.709,1.709,0,0,0-.21.26l-0.23.57a1.735,1.735,0,0,0-.01.68l0.24,0.58a0.973,0.973,0,0,0,.21.27l5.33,5.38a1.665,1.665,0,1,0,2.35-2.36l-2.5-2.52h18.65A1.667,1.667,0,0,0,1871.82,1036.52Z" transform="translate(-1845.88 -1022.75)" />
						</svg>
					</SearchEnter>
				</SearchNumbers>
			</TouchPadSearchWrap>

			{isMobile && showKeyboard &&
				<Keyboard currentValue={searchText}
									handleType={handleKeyboardType}
									onlyNumbers={true}
									close={closeKeyboard}
									enter={handleEnter} />}

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter} />}
		</>
	)
})

CartTouchPadSearch.displayName = 'CartTouchPadSearch'
export default CartTouchPadSearch
