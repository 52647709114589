import React, { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Loader from '../../../loaders'
import { AllProductsLoader, ProductsList } from './index.styled'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import { batch, useDispatch, useSelector } from 'react-redux'
import Product from './Product'
import Category from './Category'
import { setCartCategoryCategoryId, setCartCategoryProductType } from '../../../../../redux/action/cartCategory'
import BackCategory from './BackCategoryButton'
import { setCartProductActiveProductIndex, setCartProductProducts, setCartProductProductsTotal } from '../../../../../redux/action/cartProduct'
import { getCartProductsThunk } from '../../../../../redux/thunk/cartProduct'
import colors from '../../../../../style/colors'
import { flatMapCategories } from '../../../../../helper'
import { selectIsNeededZIndex } from '../../../../../redux/selector/system'
import KeyBindings from '../../../keybindings'
import { setModalLoginOpenForWaiter } from '../../../../../redux/action/modalLogin'
import { useWindowBreakPoint } from '../../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../../style/theme'
import DishCourses from '../../../../Restaurant/TableCart/DishCourses'

const EVENT_Z_INDEX = 0
const size = 40

const AllProducts = React.memo(({ orderType, increment, decrement, handleEnter, isDisabled }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const { ref, inView } = useInView({ threshold: 0 })
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const categories = useSelector(state => state.cartCategory.categories)
	const categoryId = useSelector(state => state.cartCategory.categoryId)
	const productType = useSelector(state => state.cartCategory.productType)
	const products = useSelector(state => state.cartProduct.products)
	const productsTotal = useSelector(state => state.cartProduct.productsTotal)
	const menu = useSelector(state => state.cartProduct.menu)
	const menuProductIndex = useSelector(state => state.cartProduct.menuProductIndex)
	const activeProductIndex = useSelector(state => state.cartProduct.activeProductIndex)
	const loading = useSelector(state => state.cartProduct.loading)
	const searchText = useSelector(state => state.cartProduct.searchText)
	const dispatch = useDispatch()
	const isMobile = useWindowBreakPoint(theme.point720)

	const pinCodeModalLoginIsNeeded = useSelector(state => state.modalLogin.pinCodeModalLoginIsNeeded)
	const modalLoginUsername = useSelector(state => state.modalLogin.username)
	const isAuthorizedToAddProduct = pinCodeModalLoginIsNeeded ? modalLoginUsername : true

	const category = flatMapCategories(categories)?.find(cat => cat.id === categoryId)
	const categoriesToShow = category?.children || categories
	const page = Math.ceil(products.length / size)

	const loadTable = useCallback((page) => {
		const productsWithoutCategoryFilter = (touchPad && !searchText) ? '' : null
		const type = (menu && !productType) ? 'MAIN_DISH, TOPPING, BEVERAGE, MEAL' : productType
		const menuPositionId = menu ? menu.menuPositions[menuProductIndex].menuPositionId : null
		dispatch(getCartProductsThunk(page, size, null, searchText, type, categoryId || productsWithoutCategoryFilter, orderType, menuPositionId))
	}, [touchPad, searchText, productType, categoryId, menu, orderType, menuProductIndex, dispatch])

	useEffect(() => {
		if (productsTotal !== null) {
			const timeOutId = setTimeout(() => loadTable(0), 200)
			return () => clearTimeout(timeOutId)
		}
		// eslint-disable-next-line
	}, [searchText])

	useEffect(() => {
		if (productsTotal !== null) {
			loadTable(0)
		}
		//eslint-disable-next-line
	}, [productType, categoryId, touchPad, menu, orderType])

	useEffect(() => {
		if (inView && (productsTotal === null || productsTotal > products.length) && !loading) {
			loadTable(page)
		}
		//eslint-disable-next-line
	}, [inView, productsTotal, products.length])

	const handleArrowUp = useCallback(() => {
		if (activeProductIndex === 0) {
			dispatch(setCartProductActiveProductIndex(products.length - 1))
		} else {
			dispatch(setCartProductActiveProductIndex(activeProductIndex - 1))
		}
	}, [activeProductIndex, products?.length, dispatch])

	const handleArrowDown = useCallback(() => {
		if (activeProductIndex < (products.length - 1)) {
			dispatch(setCartProductActiveProductIndex(activeProductIndex + 1))
		} else {
			dispatch(setCartProductActiveProductIndex(0))
		}
	}, [activeProductIndex, products?.length, dispatch])

	const handlePlus = useCallback(() => {
		if (!products || !products[activeProductIndex]) return
		increment(products[activeProductIndex])
	}, [products, activeProductIndex, increment])

	const handleMinus = useCallback(() => {
		if (!products || !products[activeProductIndex]) return
		decrement(products[activeProductIndex])
	}, [products, activeProductIndex, decrement])

	const handleProductClick = useCallback((event, item, i) => {
		if (isAuthorizedToAddProduct) {
			increment(item, i)
		} else {
			dispatch(setModalLoginOpenForWaiter(true))
		}
	}, [increment, isAuthorizedToAddProduct, dispatch])

	const handleProductHover = useCallback((i) => {
		if (touchPad) {
			dispatch(setCartProductActiveProductIndex(i))
		}
	}, [touchPad, dispatch])

	const handlePlusClick = useCallback((event, item, i) => {
		event?.stopPropagation()
		if (isAuthorizedToAddProduct) {
			increment(item, i)
		} else {
			dispatch(setModalLoginOpenForWaiter(true))
		}
	}, [increment, isAuthorizedToAddProduct, dispatch])

	const handleMinusClick = useCallback((event, item, i) => {
		event?.stopPropagation()
		if (isAuthorizedToAddProduct) {
			decrement(item, i)
		} else {
			dispatch(setModalLoginOpenForWaiter(true))
		}
	}, [decrement, isAuthorizedToAddProduct, dispatch])

	const handleCategoryClick = useCallback((item) => {
		dispatch(setCartProductProducts([]))
		dispatch(setCartCategoryCategoryId(item.id))
	}, [dispatch])

	const handleBackCategory = useCallback(() => {
		batch(() => {
			dispatch(setCartCategoryProductType(null))
			dispatch(setCartCategoryCategoryId(category?.parent?.id))
			dispatch(setCartProductProducts([]))
			dispatch(setCartProductProductsTotal(0))
		})
	}, [category?.parent?.id, dispatch])

	const layout = touchPad === true ? ' touchPad' : ''

	return (
		<>
			{(isMobile && orderType === 'RESTAURANT') && <DishCourses />}
			<ProductsList id="all-products-table" data-testid="all-products-table" className={layout}>
				{touchPad && category &&
					<BackCategory category={category} onClick={handleBackCategory} />}
				{touchPad && !searchText &&
					categoriesToShow?.map((item, i) =>
						<Category key={i} item={item} index={i} onClick={() => handleCategoryClick(item)} />)}
				{products?.map((item, i) =>
					<Product key={i} active={activeProductIndex === i} item={item} index={i} orderType={orderType}
									 category={category}
									 onClick={handleProductClick}
									 onMouseOver={handleProductHover}
									 onClickPlus={handlePlusClick}
									 onClickMinus={handleMinusClick}
									 isDisabled={isDisabled} />)}
				<AllProductsLoader className={'loaders ' + layout} ref={ref}>
					{loading && <Loader color={colors.gray} />}
				</AllProductsLoader>
			</ProductsList>

			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 enter={handleEnter}
										 plus={handlePlus}
										 minus={handleMinus} />}
		</>
	)
})

AllProducts.displayName = 'AllProducts'
export default AllProducts
