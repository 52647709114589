import React, { Suspense, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getDateParameterString, setWorkingHoursHelper } from '../../../helper'
import { MapWrapper, ToggleSwitchWrapper } from './index.styled'
import { fetchMapWoltDriverDeliveriesThunk, getMapOrdersThunk, getPositionsThunk } from '../../../redux/thunk/map'
import { useResizeDetector } from 'react-resize-detector'
import ToggleButton from '../buttons/ToggleButton'
import { lazyWithRetry } from '../../../helper/lazyWithRetry'
import { setMapHeight, setMapMarkersStatus, setMapWidth } from '../../../redux/action/map'
import { defaultMarkersStatus } from '../../../redux/reducer/map/constants'
import { selectDriversPositions } from '../../../redux/selector/map'

const HereMap = lazyWithRetry(() => import(/* webpackChunkName: "delivery-here-map" */'./hereMap'))

const DeliveryMap = React.memo(({ centerToRight, isDriverMonitor = false }) => {
	const { t } = useTranslation()
	const markers = useSelector(store => store.map.markers)
	const positions = useSelector(selectDriversPositions)
	const status = useSelector(store => store.map.markersStatus)
	const dispatch = useDispatch()
	const { width, height, ref } = useResizeDetector({})

	useEffect(() => {
		dispatch(getPositionsThunk())
	}, [])

	useEffect(() => {
		dispatch(getMapOrdersThunk(
			getDateParameterString(setWorkingHoursHelper(new Date())),
			getDateParameterString(setWorkingHoursHelper(new Date())),
			status, 0, 50))
	}, [status])

	useEffect(() => {
		dispatch(fetchMapWoltDriverDeliveriesThunk(0, 50))
	}, [])

	useEffect(() => {
		dispatch(setMapWidth(width))
		dispatch(setMapHeight(height))
	}, [width, height])

	const onSwitch = useCallback((i) => {
		if (i === 1) {
			dispatch(setMapMarkersStatus('ON_THE_WAY'))
		} else {
			dispatch(setMapMarkersStatus(defaultMarkersStatus))
		}
	}, [])

	const toggleItems = useMemo(() => [{ text: t('AddOrder.Client.toggle') }, { icon: 'big-car' }], [t('AddOrder.Client.toggle')])

	return (
		<MapWrapper ref={ref}>
			<Suspense>
				<HereMap markers={markers}
								 positions={positions}
								 resizeParams={[width, height]}
								 centerToRight={centerToRight} />
			</Suspense>
			<ToggleSwitchWrapper>
				<ToggleButton toggleIndex={(status === 'ON_THE_WAY') ? 1 : 0} setToggleIndex={onSwitch}
											color="blue" items={toggleItems} />
			</ToggleSwitchWrapper>
		</MapWrapper>
	)
})

DeliveryMap.displayName = 'DeliveryMap'
export default DeliveryMap
