import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setAppModal } from '../../../redux/action/system'
import { getNeededDateFormat } from '../../../helper'
import { confirmPreOrderThunk, removeOrderFromConfirmPreOrderModalDataThunk } from '../../../redux/thunk/order/confirmPreOrder'
import Currency from '../../Elements/currency'
import CustomModal from '../../Elements/modal/CustomModal'
import { selectCompanyIndex, selectIsNeededZIndex } from '../../../redux/selector/system'
import { ModalInfo, ModalInfoBlock, ModalInfoItem, PreOrderIconWrap, PreOrderModalWrapper } from './index.styled'
import { ModalContent, OrderUnpaidContent, OrderUnpaidText, OrderUnpaidWrapper } from '../processingTimeOrder/index.styled'
import MainIcon from '../../../img/svg/main'
import colors from '../../../style/colors'
import Button from '../../Elements/buttons/Button'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import KeyBindings from '../../Elements/keybindings'
import { selectActivationData } from '../../../redux/selector/company'

const EVENT_Z_INDEX = 998
const showPlatformDenyModal = 'FOODORA_PLUGIN'

const ConfirmPreOrderModal = React.memo(() => {
	const { t } = useTranslation()
	const confirmPreOrderModalShow = useSelector(store => store.system.modal?.confirmPreOrderModalShow)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const order = useSelector(store => store.system.modalData?.confirmPreOrderModalData[0])
	const isProcessingConfirmOrder = useSelector(store => store.order.isProcessingConfirmOrder)
	const dispatch = useDispatch()
	const geoData = order?.customer?.geoData
	const isMobile = useWindowBreakPoint(theme.point720)
	const companyIndex = useSelector(selectCompanyIndex)
	const showCancelButton = order?.platformMetaData?.platformType === showPlatformDenyModal
	const payed = order?.payments?.filter(item => item?.method === order?.paymentMethod)[0]?.payed
	const { isDeactivated } = useSelector(selectActivationData)

	useEffect(() => {
		if (confirmPreOrderModalShow) {
			// eslint-disable-next-line
			console.log(`Opening preorder popup for confirming the order from platform orderId=${order.orderId} companyIndex=${companyIndex} path=${window.location.pathname}`)
		}
	}, [confirmPreOrderModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleConfirm = useCallback(() => {
		dispatch(confirmPreOrderThunk(order?.orderId))
	}, [dispatch, order?.orderId])

	const handleClose = useCallback(() => {
		dispatch(removeOrderFromConfirmPreOrderModalDataThunk(order?.orderId))
	}, [dispatch, order])

	const handleDeny = useCallback(() => {
		dispatch(setAppModal({ denyPreOrderModalShow: true }))
		handleClose()
	}, [dispatch, handleClose])

	return (
		<>
			<CustomModal isOpen={!isDeactivated && confirmPreOrderModalShow}
									 close={handleClose}
									 enableOutsideClick={false}
									 title={t('Modal.confirm_preorder')}
									 zIndex={EVENT_Z_INDEX}
									 size="auto"
									 disableBackButton={true}
									 button={<>
										{showCancelButton &&
											<Button name="cancel" icon="clear" text={t('Modal.deny_order')}
												zIndex={EVENT_Z_INDEX} color="red"
												onClick={handleDeny}
												className="back"
												disabled={isProcessingConfirmOrder} />}
										<Button name="add" icon="checkmark" text={t('Modal.preorder')} keyButton="F2"
											onClick={handleConfirm} color="green" zIndex={EVENT_Z_INDEX} tabIndex="909" disabled={isProcessingConfirmOrder} />
									</>}>
				<PreOrderModalWrapper>
					{!isMobile && <PreOrderIconWrap>
						<MainIcon icon="big-calendar" />
					</PreOrderIconWrap>}
					<ModalContent>
						<ModalInfo>
							<ModalInfoBlock>
								<ModalInfoItem>
									<MainIcon icon="restaurant-name" fill={colors.gray} />
									<p className="platform">{order?.platformMetaData?.platformName}</p>
								</ModalInfoItem>
							</ModalInfoBlock>
							<ModalInfoBlock>
								{!payed ? <OrderUnpaidWrapper>
									<OrderUnpaidContent>
										<MainIcon icon={order?.paymentMethod} fill={colors.gray} testId={order?.paymentMethod} />
										<OrderUnpaidText data-testid="price"><Currency>{order?.totalPrice}</Currency></OrderUnpaidText>
									</OrderUnpaidContent>
									<OrderUnpaidText className="small">{t('Modal.unpaid_order')}</OrderUnpaidText>
								</OrderUnpaidWrapper> :
								<ModalInfoItem>
									<MainIcon icon={order?.paymentMethod} fill={colors.gray} testId={order?.paymentMethod} />
									<p className="price"><Currency>{order?.totalPrice}</Currency></p>
								</ModalInfoItem>}
							</ModalInfoBlock>
							<ModalInfoBlock className={geoData ? 'column' : 'col-2'}>
								<ModalInfoItem className="small">
									<MainIcon icon="date" fill={colors.gray} />
									<p className="date">{getNeededDateFormat(order?.preOrder)}</p>
								</ModalInfoItem>
								<ModalInfoItem className="small">
									<MainIcon icon="time" fill={colors.gray} />
									<p className="time">{getNeededDateFormat(order?.preOrder, 'HH:mm') + ' UHR'}</p>
								</ModalInfoItem>
							</ModalInfoBlock>
							{geoData &&
								<ModalInfoBlock className="column">
									<ModalInfoItem className="small">
										<MainIcon icon="route" fill={colors.gray} />
										<p className="address">{order?.customer?.street} {order?.customer?.streetNumber}, {order?.customer?.postalCode} {order?.customer?.city}</p>
										<p className={geoData?.distance > 0 ? 'distance' : 'hidden'} data-testid="distance">{(geoData?.distance / 1000).toFixed(2)} km</p>
									</ModalInfoItem>
									{geoData?.duration > 0 &&
										<ModalInfoItem className="small">
											<MainIcon icon="time-left" fill={colors.gray} />
											<p className="duration" data-testid="duration">{Math.round(geoData?.duration / 60)} min</p>
										</ModalInfoItem>}
								</ModalInfoBlock>}
						</ModalInfo>
					</ModalContent>
				</PreOrderModalWrapper>
			</CustomModal>

			{(isNeededZIndex && !isProcessingConfirmOrder) &&
				<KeyBindings enter={handleConfirm} />}
		</>
	)
})

ConfirmPreOrderModal.displayName = 'ConfirmPreOrderModal'
export default ConfirmPreOrderModal
