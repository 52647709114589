import styled from 'styled-components'
import { theme } from '../../../style/theme'
import colors from '../../../style/colors'
import { ModalHeader } from '../../Elements/modal/CustomModal/index.styled'

export const ProcessingModalWrapper = styled.div`
  font-size: 1rem;
  min-height: 100px;
  display: grid;
  grid-template-columns: 1fr 11.25em;
  grid-gap: 3.125em;

  @media screen and (max-width: ${theme.point720}) {
    gap: 1em;
    grid-template-columns: auto;
  }
`
ProcessingModalWrapper.displayName = 'ProcessingModalWrapper'

export const ModalContent = styled.div`
  display: grid;
  height: 100%;
`

export const ModalButtons = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 0.7em;

  @media screen and (max-width: ${theme.point720}) {
    grid-template-rows: auto;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0.4em;
    min-height: 4em;
  }
`
ModalButtons.displayName = 'ModalButtons'

export const ModalButtonsItem = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 0.5em;
  background-color: ${colors.blue};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-family: ${theme.fontRobotoBold};
  cursor: pointer;
  position: relative;
  padding: 0.5em;

  &.active, &:focus {
    background: ${colors.green};
    color: ${colors.white};
    padding-left: 1.45em;
    box-shadow: 0 4px 35px rgba(56, 205, 144, 0.5);

    &:after {
      display: block;
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.0041 0.552342L8.10471 13.3952L3.01103 7.76817C2.36673 7.05697 1.27001 7.00592 0.562336 7.65727C-0.141819 8.30861 -0.19199 9.41501 0.453192 10.1271L6.81259 17.1493C7.05112 17.4116 7.35831 17.5938 7.69454 17.673C7.82041 17.7012 7.94892 17.7179 8.08007 17.7179C8.56418 17.7214 9.02452 17.5207 9.35635 17.166L22.5337 2.94119C23.186 2.23615 23.1499 1.13063 22.451 0.471364C21.7556 -0.18614 20.658 -0.152693 20.0041 0.552342Z' fill='white'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: calc(50% - 0.5em);
      left: 1.65em;
      width: 1.08em;
      height: 0.83em;
    }
  }

  &.disabled {
		background-color: ${colors.gray_light_beta};
		border-color: ${colors.gray_light_beta};
		&.active {
			box-shadow: none;
		}
		&:focus {
			box-shadow: none;
		}
	}

  @media screen and (max-width: ${theme.point720}) {
    font-size: 1em;

    &.active, &:focus {
      padding: 0;

      &:after {
        display: none;
      }
    }
  }
`
ModalButtonsItem.displayName = 'ModalButtonsItem'

export const ModalButtonsItemDeny = styled.button`
  width: 100%;
  border-radius: 0.5em;
  background-color: ${colors.red};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-family: ${theme.fontRobotoRegular};
  cursor: pointer;
  position: relative;
  padding: 0.5em;
  gap: 0.2em;

  svg {
    width: 1em;
    height: 1em;
  }

  &.disabled {
		background-color: ${colors.gray_light_beta};
		border-color: ${colors.gray_light_beta};
	}

  @media screen and (max-width: ${theme.point720}) {
    font-size: 1em;

    &.active, &:focus {
      padding: 0;

      &:after {
        display: none;
      }
    }
  }
`
ModalButtonsItemDeny.displayName = 'ModalButtonsItemDeny'

export const OrderInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.63em;
	margin-bottom: 1.25em;

  @media screen and (max-width: ${theme.point820}) {
    font-size: 0.8em;
  }

  @media screen and (max-width: ${theme.point720}) {
    grid-template-columns: auto;
  }
`

export const OrderInfoBlock = styled.div`
  border-radius: 0.75em;
  border: 1px solid ${colors.gray_light};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  min-height: 5.6em;
	min-width: 13.4em;

  &.col-2 {
    grid-column: 1 / 3;
    gap: 1.56em;
  }

  @media screen and (max-width: ${theme.point720}) {
    &, &.col-2 {
      grid-column: 1;
      gap: 0.63em;
      flex-direction: column;
    }
  }
`
OrderInfoBlock.displayName = 'OrderInfoBlock'

export const OrderInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.63em;

  > svg {
    width: 1.87em;
    height: 1.87em;
    flex-shrink: 0;
  }

  > p {
    font-size: 1.25em;
    color: ${colors.black};
    font-family: ${theme.fontRobotoRegular};

		&.hidden {
			display: none;
		}
  }

  > .distance {
    background-color: ${colors.gray_lighter};
    border-radius: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1em 0.4em;
  }

  &.small {
    gap: 0.44em;

    > svg {
      width: 1.56em;
      height: 1.56em;
    }
  }
`
OrderInfoItem.displayName = 'OrderInfoItem'

export const OrderMap = styled.div`
  height: 28.8em;
  width: 60em;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    display: none;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 12px;

    &.loaded {
      display: block;
    }
  }

  @media screen and (max-width: ${theme.point820}) {
    height: auto;
    width: 100%;
  }

  @media screen and (max-width: ${theme.point720}) {
    display: none;
  }
`
OrderMap.displayName = 'OrderMap'

export const OrderWrap = styled.div`
  height: 28.8em;
  width: 60em;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;

  @media screen and (max-width: ${theme.point820}) {
    height: auto;
    width: 100%;
  }
`
OrderWrap.displayName = 'OrderWrap'

export const OrderTitle = styled(ModalHeader)`
	&.hidden {
		display: none;
	}
`
OrderTitle.displayName = 'OrderTitle'

export const OrderUnpaidWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`
OrderUnpaidWrapper.displayName = 'OrderUnpaidWrapper'

export const OrderUnpaidContent = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5em;

	> svg {
		width: 1.31em;
		height: 1.31em;
		fill: ${colors.red_bright};
	}
`
OrderUnpaidContent.displayName = 'OrderUnpaidContent'

export const OrderUnpaidText = styled.div`
	color: ${colors.red_bright};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	font-weight: 500;

	&.small {
		font-size: 1em;
	}
`
OrderUnpaidText.displayName = 'OrderUnpaidText'
