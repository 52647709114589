import { APPEND_CART_PRODUCT_TOPPINGS, SET_CART_PRODUCT_TOPPINGS_TOTAL, APPEND_CART_PRODUCT_MUST_TOPPINGS, SET_CART_PRODUCT_MUST_TOPPINGS_TOTAL, APPEND_CART_PRODUCT_PRODUCTS, SET_CART_PRODUCT_ACTIVE_PRODUCT_INDEX, SET_CART_PRODUCT_ENABLE_QUANTITY, SET_CART_PRODUCT_ITEM_FOCUS, SET_CART_PRODUCT_LOADING, SET_CART_PRODUCT_MENU, SET_CART_PRODUCT_MENU_PRODUCT_INDEX, SET_CART_PRODUCT_MUST_TOPPINGS, SET_CART_PRODUCT_PRODUCT_FOCUS, SET_CART_PRODUCT_PRODUCTS, SET_CART_PRODUCT_PRODUCTS_TOTAL, SET_CART_PRODUCT_SEARCH_TEXT, SET_CART_PRODUCT_TOPPINGS, SET_CART_PRODUCT_TOPPING_GROUPS } from '../../actionTypes/cartProduct'

export const setCartProductProducts = value => ({ type: SET_CART_PRODUCT_PRODUCTS, value })

export const appendCartProductProducts = value => ({ type: APPEND_CART_PRODUCT_PRODUCTS, value })

export const setCartProductProductsTotal = value => ({ type: SET_CART_PRODUCT_PRODUCTS_TOTAL, value })

export const setCartProductActiveProductIndex = value => ({ type: SET_CART_PRODUCT_ACTIVE_PRODUCT_INDEX, value })

export const setCartProductLoading = value => ({ type: SET_CART_PRODUCT_LOADING, value })

export const setCartProductProductFocus = value => ({ type: SET_CART_PRODUCT_PRODUCT_FOCUS, value })

export const setCartProductItemFocus = value => ({ type: SET_CART_PRODUCT_ITEM_FOCUS, value })

export const setCartProductToppings = value => ({ type: SET_CART_PRODUCT_TOPPINGS, value })

export const appendCartProductToppings = value => ({ type: APPEND_CART_PRODUCT_TOPPINGS, value })

export const setCartProductToppingsTotal = value => ({ type: SET_CART_PRODUCT_TOPPINGS_TOTAL, value })

export const setCartProductMustToppings = value => ({ type: SET_CART_PRODUCT_MUST_TOPPINGS, value })

export const appendCartProductMustToppings = value => ({ type: APPEND_CART_PRODUCT_MUST_TOPPINGS, value })

export const setCartProductMustToppingsTotal = value => ({ type: SET_CART_PRODUCT_MUST_TOPPINGS_TOTAL, value })

export const setCartProductEnableQuantity = value => ({ type: SET_CART_PRODUCT_ENABLE_QUANTITY, value })

export const setCartProductMenu = value => ({ type: SET_CART_PRODUCT_MENU, value })

export const setCartProductMenuProductIndex = value => ({ type: SET_CART_PRODUCT_MENU_PRODUCT_INDEX, value })

export const setCartProductSearchText = value => ({ type: SET_CART_PRODUCT_SEARCH_TEXT, value })

export const setCartProductToppingGroups = value => ({ type: SET_CART_PRODUCT_TOPPING_GROUPS, value })
