import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TableTr from '../../../Elements/table/tr'
import { SIZE } from '../constants'
import TodayPreOrdersTableTdList from './TodayPreOrderTableTdList'
import CustomTable from '../../../Elements/table'


const TodayPreOrdersTable = React.memo(() => {
	const { t, i18n } = useTranslation()
	const orders = useSelector(store => store.order.preOrdersToday)

	const tableHeadersStatic = useMemo(() => [
		{ width: '18%', title: t('order.table.time') },
		{ width: '18%', title: t('order.table.platform') },
		{ width: '28%', title: t('order.table.customer') },
		{ width: '18%', title: t('order.table.sum') },
		{ width: '18%', title: t('order.table.status') }
	], [i18n?.language])

	return (
		<CustomTable id="today-pre-order-table"
								 headers={tableHeadersStatic}
								 onLoad={() => {
								 }}
								 currentLength={orders?.length}
								 totalLength={orders?.length}
								 perLoad={SIZE}>
			{orders?.map((item, i) =>
				<TableTr key={i}
								 name={item?.status?.status}
								 className="row-mob-cols-4 bold-border">
					<TodayPreOrdersTableTdList tableHeadersStatic={tableHeadersStatic} item={item} />
				</TableTr>)}
		</CustomTable>
	)
})

TodayPreOrdersTable.displayName = 'TodayPreOrdersTable'
export default TodayPreOrdersTable
