import styled from 'styled-components'
import { TextSpan } from '../../style/text/index.styled'
import CustomNavLink from '../Elements/link/NavLink'
import { theme } from '../../style/theme'
import { HeaderWrapper } from '../Header/index.styled'
import { colorTheme } from '../../style/theme/colorTheme'
import { ButtonWrapper } from '../Elements/buttons/Button/style/buttonWrap/index.styled'

export const MenuWrapper = styled.nav`
	position: sticky;
	top: 0;
	left: 0;
	height: 100%;
	width: 17.13rem;
	background: ${colorTheme.navBar.background};
	transition: all 0.2s ease;
	flex-shrink: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	grid-row: 1 / 3;
	grid-column: 1;

	&.toggled {
		width: 4.88rem;
	}

	@media screen and (max-width: ${theme.point820}) {
		position: fixed;
		width: 50.5%;
		top: 0;
  		left: 0;
		grid-row: 1;
		z-index: 999;
		overflow-y: auto;

		> ${HeaderWrapper} {
			max-height: 4.28em;
		}

		&.toggled {
			width: 0;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		transition: none;
		width: 100vw;
		overflow-y: visible;

		> ${HeaderWrapper} {
			max-height: 3.76em;
		}
	}
`
MenuWrapper.displayName = 'MenuWrapper'

export const NavList = styled.div`
	padding: 0.01%;

	@media screen and (max-width: ${theme.point820}) {
		border-top: 1px solid ${colorTheme.navBar.borderTop};
	}

	@media screen and (max-width: ${theme.point720}) {
		border-top: none;
	}
`

export const NavItem = styled(CustomNavLink)`
	position: relative;
	height: 3.13em;
	width: 100%;
	list-style: none;
	line-height: 3.13em;
	margin-top: 0.63em;
	padding: 0 1.63em;
	display: flex;
	text-align: center;
	text-decoration: none;
	transition: all 0.4s ease;
	white-space: nowrap;
	align-items: center;

	&.active {
		background: ${colorTheme.navBar.item.active.background};

		> span {
			color: ${colorTheme.navBar.item.active.title};
		}

		.counter {
			background-color: ${colorTheme.navBar.item.counter.backgroundActive};
		}

		svg {
			fill: ${colorTheme.navBar.item.iconActive};
		}
	}

	&.toggled {
		padding: 0 1.5em;
	}

	@media screen and (max-width: ${theme.point820}) {
		height: 5.21em;
		margin-top: 0;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin: 0;
		height: 4.56em;
		padding: 0 1.56em;
	}
`

export const NavImage = styled.div`
	width: 1.88em;
	height: 65%;
	display: flex;
	align-items: center;
	flex-shrink: 0;

	> svg {
		width: 100%;
		height: 100%;
		fill: ${colorTheme.navBar.item.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 2.14em;
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 1.88em;
		height: 50%;
	}
`

export const NavTitle = styled(TextSpan)`
	opacity: 1;
	transition: all 0.2s ease;
	margin-left: 0.82em;
	color: ${colorTheme.navBar.title};
	font-size: 1.06em;
	font-weight: 500;

	&.toggled {
		opacity: 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.57em;
		margin-left: 1.03em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.38em;
		margin-left: 1.14em;
	}
`

export const MenuSwitch = styled.div`
	padding: 0 1.75em;
	margin-top: 2.13em;
	margin-bottom: 1.25em;

	&.toggled {
		padding: 0 0.94em;

		.switch-label {
			display: none;
		}
	}

	.switch-label {
		color: ${colorTheme.navBar.switch.title};
	}

	@media screen and (max-width: ${theme.point820}) {
		margin: 1.42em 0 2.14em;
		padding: 0 1.43em;

		input + span .switch-label {
			font-size: 1.43em;
			font-weight: 700;
			max-width: 10.7em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		margin: 1.25em 0 1.88em;
		padding: 0 1.25em;

		input + span .switch-label {
			font-size: 1.25em;
			max-width: 8em;
		}
	}
`

export const MenuProfile = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;

	@media screen and (max-width: ${theme.point820}) {
		padding: 1.07em;
		min-height: 5.86em;
		background: ${colorTheme.navBar.profile};
		justify-content: space-between;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr max-content;
		gap: 0.5em;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.94em;
		min-height: 5.13em;
	}
`

export const MenuSettings = styled.div`
	margin-top: auto;
	margin-bottom: 2.25em;
	padding: 0 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	transition: all .2s;
	column-gap: 0.2em;
	row-gap: 0.25em;

	&.toggled {
		padding: 0 0.63em;
		margin-bottom: 1em;
	}

	@media screen and (max-width: ${theme.point820}) {
		margin: 2.14em;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 1.88em;
	}
`
MenuSettings.displayName = 'MenuSettings'

export const SettingItem = styled(CustomNavLink)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.63em;
	height: 1.63em;
	border-radius: 0.38em;
	background-color: ${colorTheme.navBar.settingsItem.background};
	cursor: pointer;
	text-decoration: none;
	color: ${colorTheme.navBar.settingsItem.title};
	line-height: initial;

	img {
		width: 40%;
	}

	&.toggled {
		width: 2.5em;
		height: 1.56em;
	}

	&.green {
		background-color: ${colorTheme.navBar.settingsItem.green.background};
	}
`

export const TseSettingItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.63em;
	height: 1.63em;
	border-radius: 0.38em;
	background-color: ${colorTheme.navBar.settingsItem.background};
	cursor: pointer;
	text-decoration: none;
	color: ${colorTheme.navBar.settingsItem.title};
	line-height: initial;
	user-select: none;

	img {
		width: 40%;
	}

	&.toggled {
		width: 2.5em;
		height: 1.56em;
	}

	&.green {
		background-color: ${colorTheme.navBar.settingsItem.green.background};
	}
`

export const LogoutButtonWrap = styled.div`
	@media screen and (max-width: ${theme.point820}) {
		display: flex;
		justify-content: center;
		margin-top: 2.14em;

		.logout {
			max-width: max-content;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 1.88em;

		${ButtonWrapper} {
			padding: 0.5em 0.88em;
			min-height: 3.06em;
		}
	}
`
LogoutButtonWrap.displayName = 'LogoutButtonWrap'
