export const SET_CASHBOOK_NEW_SHOW_FORM = 'SET_CASHBOOK_NEW_SHOW_FORM'
export const SET_CASHBOOK_NEW_CATEGORY_ID = 'SET_CASHBOOK_NEW_CATEGORY_ID'
export const SET_CASHBOOK_NEW_CATEGORIES = 'SET_CASHBOOK_NEW_CATEGORIES'
export const SET_CASHBOOK_NEW_LOADING = 'SET_CASHBOOK_NEW_LOADING'
export const SET_CASHBOOK_NEW_DATE_FROM = 'SET_CASHBOOK_NEW_DATE_FROM'
export const SET_CASHBOOK_NEW_DATE_TO = 'SET_CASHBOOK_NEW_DATE_TO'
export const SET_CASHBOOK_NEW_CASHBOOKS = 'SET_CASHBOOK_NEW_CASHBOOKS'
export const SET_CASHBOOK_NEW_CASHBOOKS_TOTAL_LENGTH = 'SET_CASHBOOK_NEW_CASHBOOKS_TOTAL_LENGTH'
export const APPEND_CASHBOOK_NEW_CASHBOOKS = 'APPEND_CASHBOOK_NEW_CASHBOOKS'
export const SET_CASHBOOK_NEW_CASHBOOK_ID = 'SET_CASHBOOK_NEW_CASHBOOK_ID'
export const SET_CASHBOOK_NEW_CASHBOOK = 'SET_CASHBOOK_NEW_CASHBOOK'
export const SET_CASHBOOK_NEW_ACCEPT_INITIAL_BALANCE = 'SET_CASHBOOK_NEW_ACCEPT_INITIAL_BALANCE'
export const SET_CASHBOOK_NEW_INITIAL_BALANCE_OLD_CASHBOOK = 'SET_CASHBOOK_NEW_INITIAL_BALANCE_OLD_CASHBOOK'
export const SET_CASHBOOK_NEW_BALANCE_OF_OLD_CASHBOOK_WAS_ACCEPTED = 'SET_CASHBOOK_NEW_BALANCE_OF_OLD_CASHBOOK_WAS_ACCEPTED'
export const SET_CASHBOOK_NEW_CASH_BALANCE = 'SET_CASHBOOK_NEW_CASH_BALANCE'
export const SET_CASHBOOK_NEW_SHOW_FORM_CATEGORY = 'SET_CASHBOOK_NEW_SHOW_FORM_CATEGORY'
export const SET_CASHBOOK_NEW_TRANSACTION_TYPE = 'SET_CASHBOOK_NEW_TRANSACTION_TYPE'
export const SET_CASHBOOK_NEW_CATEGORY = 'SET_CASHBOOK_NEW_CATEGORY'
export const ADD_CASHBOOK_NEW_TRANSACTION = 'ADD_CASHBOOK_NEW_TRANSACTION'
export const UPDATE_CASHBOOK_NEW_TRANSACTION = 'UPDATE_CASHBOOK_NEW_TRANSACTION'
export const SET_CASHBOOK_NEW_LOADING_DATEV = 'SET_CASHBOOK_NEW_LOADING_DATEV'
export const SET_CASHBOOK_NEW_SHOW_MODAL_NAVIGATE_TO_DATEV = 'SET_CASHBOOK_NEW_SHOW_MODAL_NAVIGATE_TO_DATEV '
export const SET_CASHBOOK_NEW_LOADING_BALANCE = 'SET_CASHBOOK_NEW_LOADING_BALANCE'
export const SET_CASHBOOK_NEW_CASH_TOTAL = 'SET_CASHBOOK_NEW_CASH_TOTAL'
