import registrationInstance from '../instance'

const fetchAllUsers = (context, group, page, size) => {
  return registrationInstance.get('/users'
    + '?page=' + page + '&size=' + size
    + (context ? '&context=' + context : '')
    + (group ? '&group=' + group : ''))
}

export default fetchAllUsers
