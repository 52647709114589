import { TextBig } from '../../OrdersTable/index.styled'
import Currency from '../../../currency'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OtherDriverText, PartnerDriverTextWrapper, PartnerDriverWrapper } from './index.styled'
import PlatformIcon from '../../../../../img/svg/platforms'

const PartnerDriver = React.memo(({ item }) => {
	const { t } = useTranslation()
	const platformDriverIcon = item?.deliveryMetaData?.partner?.type
	const deliveryCost = item?.deliveryMetaData?.partner?.estimatedDeliveryCost || item?.deliveryMetaData?.partner?.deliveryCost
	const deliveryId = item?.deliveryMetaData?.partner?.deliveryId

	return (
		<PartnerDriverWrapper>
			<PlatformIcon icon={platformDriverIcon} />
			<PartnerDriverTextWrapper>
				{deliveryCost &&
					<TextBig className="light">+<Currency>{deliveryCost}</Currency></TextBig>}
				{deliveryId &&
					<OtherDriverText>{t('order.table.anotherDriver')}</OtherDriverText>}
			</PartnerDriverTextWrapper>
		</PartnerDriverWrapper>
	)
})

PartnerDriver.displayName = 'PartnerDriver'
export default PartnerDriver
