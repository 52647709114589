import styled from 'styled-components'
import { theme } from '../../../style/theme'

export const IncomePhoneModalWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1.25em;
	margin-bottom: 0.31em;

	@media screen and (max-width: ${theme.point820}) {
		margin-bottom: 5em;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 8.8em;
	}
`
IncomePhoneModalWrapper.displayName = 'IncomePhoneModalWrapper'
