import { retrieveErrorFromApi } from '../../../../helper'
import { setAppErrors, setAppModal, setAppModalData } from '../../../action/system'
import confirmOrder from '../../../axios/order/confirmOrder'
import { closeConfirmPreOrderModalThunk, closePlatformDriversOrderModalThunk, handleOrderForOrdersCounterThunk, handleOrderForUnpaidOrdersCounterThunk, updateOrderToTableThunk, updatePreOrderOtherDaysToTableThunk, updatePreOrderTodayToTableThunk, updateUnpaidOrderToTableThunk } from '../../../../socket/order'
import denyOrder from '../../../axios/order/denyOrder'
import { setIsProcessingConfirmOrder, setIsProcessingDenyOrder } from '../../../action/order'

export const removeOrderFromConfirmPreOrderModalDataThunk = (orderId) => (dispatch, getState) => {
	const confirmPreOrderModalData = getState().system.modalData?.confirmPreOrderModalData.filter(o => o.orderId !== orderId)
	dispatch(setAppModal({ confirmPreOrderModalShow: false }))
	dispatch(setAppModalData({ confirmPreOrderModalData }))
}

export const confirmPreOrderThunk = (orderId) => (dispatch) => {
	dispatch(setIsProcessingConfirmOrder(true))
	return confirmOrder(orderId)
		.then(res => {
			dispatch(removeOrderFromConfirmPreOrderModalDataThunk(orderId))
			dispatch(closeConfirmPreOrderModalThunk(res.data))
			dispatch(updateOrderToTableThunk(res.data, 'confirmed_manually'))
			dispatch(closePlatformDriversOrderModalThunk(res.data))
			dispatch(handleOrderForOrdersCounterThunk(res.data))
			dispatch(updatePreOrderTodayToTableThunk(res.data, 'confirmed_manually'))
			dispatch(updatePreOrderOtherDaysToTableThunk(res.data, 'confirmed_manually'))
			dispatch(updateUnpaidOrderToTableThunk(res.data, 'confirmed_manually'))
			dispatch(handleOrderForUnpaidOrdersCounterThunk(res.data))
			dispatch(setIsProcessingConfirmOrder(false))
		}).catch(error => {
			dispatch(removeOrderFromConfirmPreOrderModalDataThunk(orderId))
			dispatch(setIsProcessingConfirmOrder(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const denyPreOrderThunk = (orderId, reason) => dispatch => {
	dispatch(setIsProcessingDenyOrder(true))
	return denyOrder(orderId, reason)
		.then(res => {
			dispatch(removeOrderFromConfirmPreOrderModalDataThunk(orderId))
			dispatch(setIsProcessingDenyOrder(false))
		}).catch(error => {
			dispatch(removeOrderFromConfirmPreOrderModalDataThunk(orderId))
			dispatch(setIsProcessingDenyOrder(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
