import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getFloat, uuidGenerator } from '../../../../helper'
import ModalButton from '../../modal/ButtonsModal/ModalButton'
import CustomModal from '../../modal/CustomModal'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { InputDescription } from './index.styled'
import KeyBindings from '../../keybindings'

const EVENT_Z_INDEX = 5

const ModalProductType = React.memo(({ isOpen, close, incrementProduct, orderType }) => {
	const { t, i18n } = useTranslation()
	const searchText = useSelector(state => state.cartProduct.searchText)
	const [description, setDescription] = useState('')
	const [activeButtonIndex, setActiveButtonIndex] = useState(0)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))

	useEffect(() => {
		if (isOpen) {
			setActiveButtonIndex(0)
			setDescription('')
		}
	}, [isOpen])

	const handleItemClick = useCallback((button) => {
		const item = {
			productId: uuidGenerator(),
			title: button.label,
			productType: button.value,
			posPrices: [{ name: orderType, prices: [{ value: getFloat(searchText), priceTitle: { id: '', title: '' } }] }],
		}

		if (description) {
			item.title = description
		}

		incrementProduct(item)
		setDescription('')
		close()
	}, [incrementProduct, orderType, searchText, close, description])

	const productTypes = useMemo(() => [
		{ label: t('product.form.main.type_options.product'), value: 'MAIN_DISH' },
		{ label: t('product.form.main.type_options.drink'), value: 'BEVERAGE' },
		{ label: t('product.form.main.type_options.deposit'), value: 'BOTTLE_DEPOSIT' },
	], [i18n?.language])

	const buttons = useMemo(() => productTypes.map(item =>
		({ icon: item.value, text: item.label, onClick: () => handleItemClick(item), show: true })), [productTypes, handleItemClick])

	const onChange = (e) => {
		const target = e.target
		setDescription(target.value)
	}

	const handleArrowUp = useCallback(() => {
		if (!buttons[activeButtonIndex - 1]) {
			setActiveButtonIndex(buttons.length - 1)
		} else {
			setActiveButtonIndex(activeButtonIndex - 1)
		}
	}, [buttons, activeButtonIndex])

	const handleArrowDown = useCallback(() => {
		if (!buttons[activeButtonIndex + 1]) {
			setActiveButtonIndex(0)
		} else {
			setActiveButtonIndex(activeButtonIndex + 1)
		}
	}, [buttons, activeButtonIndex])

	const handleEnter = useCallback(() => {
		buttons[activeButtonIndex]?.onClick()
	}, [buttons, activeButtonIndex])

	const handleButtonClick = useCallback((e, button, index) => {
		setActiveButtonIndex(index)
		button.onClick(e)
	}, [])

	return (
		<>
			<CustomModal isOpen={isOpen}
									 close={close}
									 title={t('product.modal.type.title')}
									 titlePrice={getFloat(searchText)}
									 size="small"
									 id="product-type-modal"
									 zIndex={EVENT_Z_INDEX}
									 returnZIndex={0}
			>
				<InputDescription>
					<input type="text" name="description"
								 placeholder={t('product.form.main.type_options.description')}
								 value={description}
								 onChange={onChange} />
				</InputDescription>
				{buttons?.map((button, index) =>
					<ModalButton key={index}
											 index={index}
											 tabIndex={index}
											 button={button}
											 onClick={handleButtonClick}
											 isActive={index === activeButtonIndex} />)}
			</CustomModal>
			{isNeededZIndex &&
				<KeyBindings enter={handleEnter}
										 arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown} />}
		</>
	)
})

ModalProductType.displayName = 'ModalProductType'
export default ModalProductType
