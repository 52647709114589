import { APPEND_PHONES, SET_PHONE, SET_PHONE_TEST_SENT, SET_PHONE_TEST_SUCCESS, SET_PHONE_TOTAL_LENGTH, SET_PHONES, UPDATE_PHONE_STATUS } from '../../actionTypes/phone'

const initialState = {
  phone: null,
  phones: [],
  domainOptions: [],
  phoneTotalLength: 0,
  testSent: false,
  testSuccess: false,
}

export function phoneReducer (state = initialState, action) {
  switch (action.type) {
    case SET_PHONE:
      return {
        ...state,
        phone: action.data,
      }
    case SET_PHONE_TOTAL_LENGTH:
      return {
        ...state,
        phoneTotalLength: action.length,
      }
    case SET_PHONES:
      return {
        ...state,
        phones: action.phones,
      }
    case APPEND_PHONES:
      return {
        ...state,
        phones: state.phones.concat(...action.phones),
      }
    case SET_PHONE_TEST_SENT:
      return {
        ...state,
        testSent: action.value,
      }
    case SET_PHONE_TEST_SUCCESS:
      return {
        ...state,
        testSuccess: action.value,
      }
    case UPDATE_PHONE_STATUS:
      let phonesUpdateCopy = [...state.phones]
      const phoneToUpdate = phonesUpdateCopy.find(phone => phone.id === action.value.id)
      if (phoneToUpdate) {
        phonesUpdateCopy[phonesUpdateCopy.indexOf(phoneToUpdate)] = action.value
      } else {
        phonesUpdateCopy = phonesUpdateCopy.concat(action.value)
      }
      return {
        ...state,
        phones: phonesUpdateCopy,
      }
    default:
      return state
  }

}


