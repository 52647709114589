import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const ProductWrap = styled.li`
  font-size: 1rem;
  border-radius: 0.75em;
  background-color: ${colorTheme.cart.productSearch.productList.backgroundProduct};
	border: ${props => `3px solid ${props.themeStyles.product.border}`};
	position: relative;
  display: flex;
  align-items: center;
  padding: 0.19em 0.31em;
  transition: all .2s;
  min-height: 2.5em;
  margin-bottom: 0.19em;


  &.active {
    border: 2px solid ${colorTheme.cart.productSearch.productList.activeBackgroundProduct};
		color: ${colorTheme.cart.productSearch.productList.textActive};
		box-shadow: 0 0 0.63em 0.38em ${colorTheme.cart.productSearch.productList.shadowProduct};
    z-index: 1;
  }

  &.touchPad {
		background-color: ${colorTheme.cart.productSearch.productList.backgroundProductTouchPad};
		padding-top: 1.88em;
    padding-bottom: 1.88em;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
    cursor: pointer;
		justify-content: center;
		box-shadow: 0 18px 109px rgba(0, 0, 0, 0.15);

		&.thinkBorder {
			border: ${props => `2px solid ${props.themeStyles.product.border}`};
		}

    &.topping {
      background-color: ${colorTheme.cart.productSearch.productList.backgroundTopping};
      border-color: ${colorTheme.cart.productSearch.productList.borderTopping};
    }

    &.active {
      box-shadow: 0 0 0.63em 0.19em rgba(10, 28, 63, 0.34);
    }
  }

  @media screen and (max-width: ${theme.point820}) {
    &.touchPad {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      justify-content: center;
    }
  }
`
ProductWrap.displayName = 'ProductWrap'

export const ProductNr = styled.div`
  width: 2.83em;
  height: 2em;
  border: solid 1px ${colorTheme.cart.productSearch.productList.productNr.border};
  border-radius: 1em;
  font-size: 0.75em;
  font-family: ${theme.fontRobotoBold};
  color: ${colorTheme.cart.productSearch.productList.productNr.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75em;
  transition: all .2s;

  &.empty {
    border: none;
  }

  &.active {
    border-color: ${colorTheme.cart.productSearch.productList.productNr.activeBorder};
    color: ${colorTheme.cart.productSearch.productList.productNr.activeText};
  }

  &.touchPad {
    min-width: 2em;
    position: absolute;
    top: 0.25em;
    left: 0.25em;
    margin-right: 0;
    width: auto;
    padding: 0.33em;
  }

  @media screen and (max-width: ${theme.point820}) {
    font-size: 0.63em;
  }
`
ProductNr.displayName = 'ProductNr'

export const ProductTitle = styled.div`
  line-height: 1.19em;
	color: ${colorTheme.cart.productSearch.productList.text};
  user-select: none;
  font-family: ${theme.fontRobotoRegular};
  font-size: 1.2em;
  word-wrap: anywhere;

	&.active{
		color: ${colorTheme.cart.productSearch.productList.textActive};
	}

  &.touchPad {
    margin-bottom: 0.41em;
    font-size: 1.06em;
    text-align: center;
    line-height: 0.88em;
    font-family: ${theme.fontRobotoMedium};
  }

  @media screen and (max-width: ${theme.point820}) {
    &.touchPad {
      font-size: 0.88em;
      font-family: ${theme.fontRobotoRegular};
      margin-bottom: 0.21em;
    }
  }
`
ProductTitle.displayName = 'ProductTitle'

export const ProductPrice = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  > div:first-child {
    width: auto;
    height: auto;
    padding: 0.21em 0.5em;
    border-radius: 0.86em;
    background-color: ${colorTheme.cart.productSearch.productList.productPrice.background};
    font-size: 0.88em;
    font-family: ${theme.fontRobotoBold};
    color: ${colorTheme.cart.productSearch.productList.productPrice.text};
    letter-spacing: -0.05em;
    text-align: center;
    margin-right: 0.71em;
    border: 1px solid ${colorTheme.cart.productSearch.productList.productPrice.border};
    transition: all .2s;
  }

  &.active > div:first-child {
    background-color: ${colorTheme.cart.productSearch.productList.productPrice.activeBackground};
    border-color: ${colorTheme.cart.productSearch.productList.productPrice.activeBorder};
    color: ${colorTheme.cart.productSearch.productList.productPrice.activeText};
  }

  &.touchPad {
    margin-left: 0;

    > div:first-child {
      margin-right: 0;
      font-size: 0.75em;
      padding: 0.25em 0.42em;
      text-align: center;
    }

    > div:last-child {
      display: none;
    }
  }

  @media screen and (max-width: ${theme.point820}) {
    &.touchPad {
      > div:first-child {
        font-size: 0.8em;
        padding: 0 0.25em;
        font-family: ${theme.fontRobotoMedium};
      }
    }
  }
`
ProductPrice.displayName = 'ProductPrice'
