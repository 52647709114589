import React from 'react'
import { CartProductBlock, CartProductQuantity, CartProductTitle, CartProductTitleBlock, CartProductWrapper } from '../index.styled'
import CartProduct from '../CartProduct'

const CartMenu = React.memo(({ item, index }) => {
	const dataTestId = `menu-${index}-${item?.name}`

	return (
		<CartProductWrapper data-testid={dataTestId}>
			<CartProductBlock>
				<CartProductTitleBlock>
					<CartProductTitle>{item?.name}</CartProductTitle>
					<CartProductQuantity data-testid={dataTestId + '-quantity'}>x{item?.quantity}</CartProductQuantity>
				</CartProductTitleBlock>
				{item?.items.map((item, i) =>
					<CartProduct key={i}
											 item={item}
											 isMenuProduct={true} />)}
			</CartProductBlock>
		</CartProductWrapper>
	)
})

CartMenu.displayName = 'CartMenu'
export default CartMenu
