import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const AreaButtonWrap = styled.div`
  position: relative;

  @media screen and (max-width: ${theme.point720}) {
    background-color: ${props => props.color};
    border-radius: 0.75em;
  }
`
AreaButtonWrap.displayName = 'AreaButtonWrap'

export const AreaButton = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	font-size: 1rem;
	background-color: transparent;
	border: 1px solid ${props => props.color};
	border-radius: 0.75em;
	justify-content: center;
	gap: 0.63em;
	align-items: center;
	color: ${colorTheme.restaurant.area.button.text};
	cursor: pointer;
	min-height: 2.69em;
	padding: 0.5em;

	&.active {
		border: none;
		background-color: ${colorTheme.restaurant.area.button.active.background};

		&::before {
			content: '';
			position: absolute;
			top: -1px;
			left: -1px;
			right: -1px;
			bottom: -1px;
			border: 2px solid ${props => props.color};
			border-radius: 0.75em;
			box-shadow: 0px 0.38em 0.38em 0px rgba(0, 0, 0, 0.75);
		}
	}

	&.waiter-call {
		border: 3px solid ${colorTheme.restaurant.area.waiterCall.background};
	}

	@keyframes shadowPulse {
		0%, 100% {
			box-shadow: none;
		}
		50% {
			box-shadow: 0px 0px 0.63em 0px ${props => props.color};
		}
	}

	&.shadowPulse {
		animation: shadowPulse 0.8s ease-in-out infinite;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.75em 2em 0.75em 0.75em;
		gap: 0.56em;
		justify-content: start;
		background-color: ${colorTheme.restaurant.area.button.mobile.background};
		position: relative;

		> svg {
			position: absolute;
			width: 1.13em;
			height: 0.63em;
			right: 1.19em;
			fill: ${colorTheme.restaurant.area.button.mobile.icon};
		}

		&.active {
			background-color: ${colorTheme.restaurant.area.button.active.mobile.background};
			box-shadow: 0 4px 55px rgba(0, 0, 0, 0.3);

			&::after {
				transform: rotate(180deg);
				top: calc(50%);
			}
		}
	}
`
AreaButton.displayName = 'AreaButton'

export const AreaButtonCircle = styled.div`
  font-size: 1rem;
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  background: ${props => props.color};
  border: 1px solid transparent;
  flex-shrink: 0;

  &.active {
    border-color: ${colorTheme.restaurant.area.button.active.border};
  }

  @media screen and (max-width: ${theme.point720}) {
    width: 1.94em;
    height: 1.94em;

    &.active {
      border: none;
    }
  }
`
AreaButtonCircle.displayName = 'AreaButtonCircle'

export const AreaButtonName = styled.div`
  display: flex;
  align-items: center;
  font-family: ${theme.fontRobotoMedium};
  color: ${colorTheme.restaurant.area.button.name};
  text-align: left;
  font-size: 1.06em;
	word-break: break-all;

	&.black {
    color: ${colorTheme.restaurant.area.button.nameBlack};
    font-family: ${theme.fontRobotoRegular};
  }

  &.active {
    font-family: ${theme.fontRobotoBold};
    color: ${colorTheme.restaurant.area.button.active.name};
  }

  @media screen and (max-width: ${theme.point720}) {
    &, &.active, &.black {
      font-size: 1.25em;
      color: ${colorTheme.restaurant.area.button.active.nameBlack};
      font-family: ${theme.fontRobotoMedium};
      line-height: 1em;
    }
  }
`
AreaButtonName.displayName = 'AreaButtonName'

export const AreaButtonCount = styled.div`
  font-size: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2em;
  height: 2em;
  padding: 0 0.33em;
  border-radius: 0.5em;
  color: ${colorTheme.restaurant.area.button.count.text};
  font-family: ${theme.fontRobotoBold};
  line-height: 1.17em;
  background: ${colorTheme.restaurant.area.button.count.background};

  &.active {
    background: ${colorTheme.restaurant.area.button.active.count.background};
  }

  &.waiter-call {
    background: ${colorTheme.restaurant.area.waiterCall.background};
  }

  @media screen and (max-width: ${theme.point720}) {
    display: none;
  }
`
AreaButtonCount.displayName = 'AreaButtonCount'
