import { getFloat, roundPrice } from '../../../../../helper'

export const getPaymentStatusMessage = (terminal, processingPayment, handleBackIsLoading, paymentError, processError, paymentIsCanceled, t) => {
	if (processingPayment) {
		return t('paymentsTerminal.status_processing')
	} else if (handleBackIsLoading) {
		return t('paymentsTerminal.status_canceling')
	} else if (paymentError) {
		if (processError) {
			switch (processError.statusCode) {
				case 'terminal_reader_offline':
					return t('paymentsTerminal.status_offline')
				case 'terminal_reader_busy':
					return t('paymentsTerminal.status_busy')
				case 'terminal_reader_timeout':
					return t('paymentsTerminal.status_timeout')
				case 'new_terminal_process_not_available':
					return t('paymentsTerminal.status_another_process', { name: terminal.name, amount: processError.data?.currentProcess?.amount })
				default:
					return t('paymentsTerminal.status_error')
			}
		} else if (paymentIsCanceled) {
			return t('paymentsTerminal.status_canceled')
		} else {
			return t('paymentsTerminal.status_error')
		}
	} else {
		return t('paymentsTerminal.status')
	}
}

export const getPercentsTips = (discount, itemsTotal) => {
	if (discount) {
		return roundPrice(itemsTotal * discount / 100.0)
	} else {
		return 0
	}
}

export const getPriceWithTips = (itemsTotal, discount) => {
	return roundPrice(itemsTotal + getFloat(discount))
}
