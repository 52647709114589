import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomModal from '../../Elements/modal/CustomModal'
import Button from '../../Elements/buttons/Button'
import { StyledA, TextP } from '../../../style/text/index.styled'
import { selectCompanyData, selectCompanyIndex } from '../../../redux/selector/system'
import whatsapp_icon from '../../../img/svg/whatsapp_icon.svg'
import { ContactItem, QrCodeWrapper, WrapperContacts } from '../TseDisabledModal/index.styled'
import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import MainIcon from '../../../img/svg/main'
import CheckBoxInputControlled from '../../Elements/inputs/CheckboxInput/controlled'
import { setSupportShowExtraContactsModal, setSupportShowTseNotificationModal } from '../../../redux/action/support'
import { setTseNotification } from '../../../redux/action/tse'
import { ContactExtra, ContactWhatsApp, Text } from '../contactsModal/index.styled'
import { updateTseDisableNotificationThunk } from '../../../redux/thunk/tse'

const EVENT_Z_INDEX = 995

const TseNotificationModal = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const companyIndex = useSelector(selectCompanyIndex)
	const name = useSelector(selectCompanyData('name'))
	const firstName = useSelector(selectCompanyData('firstName'))
	const phoneNumberOfDealer = useSelector(store => store.support.dealerContacts?.supportNumber)
	const whatsAppNumberOfDealer = useSelector(store => store.support.dealerContacts?.whatsAppNumber)
	const isDealer = useSelector(store => store.support.dealerContacts)
	const tseStatusIsDisabled = useSelector(store => store.tse.notification?.status === 'DISABLED')
	const tseDisableNotification = useSelector(store => store.tse.notification?.disableNotification)
	const showTseNotificationModal = useSelector(store => store.support.showTseNotificationModal)

	const whatsappHref = firstName ? `https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}.%20Ich%20möchte%20TSE%20aktivieren.` :
		`https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20Ihr%20Kunde,%20Firma%20${name},%20Kundennummer%20${companyIndex}.%20Ich%20möchte%20TSE%20aktivieren.`
	const preparedWhatsAppNumber = whatsAppNumberOfDealer?.split('').filter(item => /\d/.test(item)).join('')

	const whatsappHrefOfDealer = firstName ? `https://wa.me/${preparedWhatsAppNumber}?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}.%20Ich%20möchte%20TSE%20aktivieren.` :
		`https://wa.me/${preparedWhatsAppNumber}?text=Hallo!%20Ich%20bin%20Ihr%20Kunde,%20Firma%20${name},%20Kundennummer%20${companyIndex}.%20Ich%20möchte%20TSE%20aktivieren.`

	const phoneHrefOfDealer = `tel:${phoneNumberOfDealer}`

	useEffect(() => {
		if (tseStatusIsDisabled && !tseDisableNotification) {
			dispatch(setSupportShowTseNotificationModal(true))
		}
	}, [tseStatusIsDisabled, tseDisableNotification])// eslint-disable-line react-hooks/exhaustive-deps

	const showExtraContacts = useCallback(() => {
		dispatch(setSupportShowExtraContactsModal(true))
	}, [dispatch])

	const handleClose = useCallback(() => {
		dispatch(setSupportShowTseNotificationModal(false))
		dispatch(setTseNotification(null))
	}, [dispatch])

	const disableTseNotification = useCallback((e) => {
		dispatch(updateTseDisableNotificationThunk({ disableNotification: e.target.checked }))
	}, [dispatch])

	return (
		<CustomModal
			isOpen={showTseNotificationModal}
			close={handleClose}
			zIndex={EVENT_Z_INDEX}
			size="small"
			title={t('Modal.tse_modal.title_danger')}
			disableBackButton={true}
			id="tse-notification-modal"
			button={
				<>
					<ContactExtra className="back" onClick={showExtraContacts}>{t('app.modals.extraContacts')}</ContactExtra>
					<Button icon="checkmark" onClick={handleClose} tabIndex="1" text="Ok" color="green" />
				</>
			}>
			<TextP className="red">{t('Modal.tse_modal.title')}</TextP>
			<TextP className="small">{isDealer ? t('Modal.tse_modal.textWithDealer') : t('Modal.tse_modal.text')}</TextP>
			<TextP>{name} - {companyIndex}</TextP>
			<WrapperContacts className="tse-modal-notification">
				{isDealer ? (<> {!!phoneNumberOfDealer &&
					<ContactItem>
						<MainIcon icon="telephone_icon_blue" />
						<StyledA href={phoneHrefOfDealer}>{phoneNumberOfDealer}</StyledA>
					</ContactItem>}
					{!!whatsAppNumberOfDealer &&
						<>
							<ContactWhatsApp>
								<MainIcon testId="mobile-icon" icon="mobile" />
								<Text>WhatsApp</Text>
							</ContactWhatsApp>
							<QrCodeWrapper>
								<QRCodeSVG value={whatsappHrefOfDealer}
													 level="M"
													 imageSettings={{
														 src: whatsapp_icon,
														 width: 32,
														 height: 32,
														 x: null,
														 y: null,
														 excavate: true,
													 }} />
							</QrCodeWrapper>
						</>}
				</>) : (<>
					<ContactWhatsApp>
						<MainIcon icon="mobile" />
						<Text>WhatsApp</Text>
					</ContactWhatsApp>
					<QrCodeWrapper>
						<QRCodeSVG value={whatsappHref}
											 level="M"
											 imageSettings={{
												 src: whatsapp_icon,
												 width: 32,
												 height: 32,
												 x: null,
												 y: null,
												 excavate: true,
											 }} />
					</QrCodeWrapper>
				</>)
				}
			</WrapperContacts>

			<CheckBoxInputControlled label={t('Modal.tse_modal.text_checkbox')}
															 checked={tseDisableNotification}
															 onChange={disableTseNotification} />
		</CustomModal>
	)
})

TseNotificationModal.displayName = 'TseNotificationModal'
export default TseNotificationModal
