import fetchAllUsers from '../../axios/registration/user'
import { setAllUsers } from '../../action/user'
import { setAppErrors } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'

export const fetchAllUsersByRoleThunk = (group) => dispatch => {
  return fetchAllUsers('USERS_CONTEXT', group, 0, 50)
    .then(res => {
      if (res.data) dispatch(setAllUsers(res.data))
    }).catch(error => {
      dispatch(setAppErrors(retrieveErrorFromApi(error)))
    })
}
