import { compareAsc } from 'date-fns'

const getStatusPriority = (order) => {
	if (order?.displayStatus === 'OPENED' && order.preOrder) {
		return 1
	} else if (order?.displayStatus === 'OPENED' && !order.preOrder) {
		return 2
	} else {
		return 3
	}
}

export const sortKitchenOrders = (order1, order2) => {
	const statusPriorityDifference = getStatusPriority(order1) - getStatusPriority(order2)
	if (statusPriorityDifference !== 0) {
		return statusPriorityDifference
	} else if (order1.preOrder && order2.preOrder) {
		return compareAsc(new Date(order1.preOrder), new Date(order2.preOrder))
	}

	return order1.orderDayCounter - order2.orderDayCounter
}
