import React, { Suspense, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CustomFullScreenModal from '../../../../modal/CustomFullScreenModal'
import PlatformIcon from '../../../../../../img/svg/platforms'
import Price from '../../../../blocks/Price'
import { BackButtonWrapper, PartnerDriverTrackingModalTitleIconWrapper, PartnerDriverTrackingModalTitleText, PartnerDriverTrackingModalTitleWrapper, PartnerDriverTrackingModalWrapper } from './index.styled'
import Button from '../../../../buttons/Button'
import { MapWrapper } from '../../../../map/index.styled'
import { lazyWithRetry } from '../../../../../../helper/lazyWithRetry'
import { useResizeDetector } from 'react-resize-detector'
import { useWindowBreakPoint } from '../../../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../../../style/theme'
import InfoOrder from './InfoOrder'
import { getMapWoltDriverDeliveryThunk } from '../../../../../../redux/thunk/map'
import { setMapSelectedWoltDriverDelivery } from '../../../../../../redux/action/map'
import Portal from '../../../../Portal'
import { useTheme } from 'styled-components'

const EVENT_Z_INDEX = 112

const HereMap = lazyWithRetry(() => import(/* webpackChunkName: "partner-driver-here-map" */'../../../../map/hereMap'))

const PartnerDriverTrackingModal = React.memo(({ isOpen, close, order }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { colorTheme } = useTheme()
	const { width, height, ref } = useResizeDetector({})
	const isTablet = useWindowBreakPoint(theme.point820)
	const orderId = order?.orderId
	const woltDriverDelivery = useSelector(state => state.map.selectedWoltDriverDelivery)
	const partnerDriverType = order?.deliveryMetaData?.partner?.type
	const position = { ...woltDriverDelivery?.courierLocation, partnerDriverType }

	const headerTitle =
		<PartnerDriverTrackingModalTitleWrapper>
			<PartnerDriverTrackingModalTitleText>{t('Modal.partner_driver_tracking_modal.title') + ' ' + order?.orderDayCounter}</PartnerDriverTrackingModalTitleText>
			<Price value={order?.totalPrice} />
			<PartnerDriverTrackingModalTitleIconWrapper><PlatformIcon icon={`${partnerDriverType}-${colorTheme}`} /></PartnerDriverTrackingModalTitleIconWrapper>
		</PartnerDriverTrackingModalTitleWrapper>

	useEffect(() => {
		if (isOpen && orderId) {
			dispatch(getMapWoltDriverDeliveryThunk(orderId))
		}
	}, [isOpen, orderId]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback((e) => {
		dispatch(setMapSelectedWoltDriverDelivery(null))
		close(e)
	}, [dispatch, close])

	return (
		<Portal containerId="content">
			<CustomFullScreenModal isOpen={isOpen}
														 close={handleClose}
														 id="partner-driver-tracking-modal"
														 customHeaderTitle={headerTitle}
														 footerClassname="hidden"
														 fullSizeBody={true}
														 zIndex={EVENT_Z_INDEX}>
				<PartnerDriverTrackingModalWrapper>
					{isOpen &&
						<MapWrapper ref={ref}>
							<Suspense>
								<HereMap markers={[order]}
												 positions={[position]}
												 resizeParams={[width, height]}
												 selectOrderOnHover={false} />
							</Suspense>
							{!isTablet &&
								<InfoOrder order={order} />}
							<BackButtonWrapper>
								<Button name="close" icon="clear" color="blue" size={!isTablet && 'big'} text={t('Modal.partner_driver_tracking_modal.button')} onClick={handleClose} />
							</BackButtonWrapper>
						</MapWrapper>}
				</PartnerDriverTrackingModalWrapper>
			</CustomFullScreenModal>
		</Portal>)
})
PartnerDriverTrackingModal.displayName = 'PartnerDriverTrackingModal'
export default PartnerDriverTrackingModal
