import { findBestMatch } from 'string-similarity'

export const getPriceDescriptionsStatic = () => [
	{ value: 1, title: 'category.form.main.size' },
	{ value: 2, title: 'category.form.main.size' },
	{ value: 3, title: 'category.form.main.size' },
	{ value: 4, title: 'category.form.main.size' },
	{ value: 5, title: 'category.form.main.size' },
	{ value: 6, title: 'category.form.main.size' },
	{ value: 7, title: 'category.form.main.size' },
	{ value: 8, title: 'category.form.main.size' },
	{ value: 9, title: 'category.form.main.size' },
	{ value: 10, title: 'category.form.main.size' },
	{ value: 11, title: 'category.form.main.size' },
	{ value: 12, title: 'category.form.main.size' },
]

export const defaultCategories = [
	{ imageId: 'Aktionen', names: ['Aktionen'] },
	{ imageId: 'Al Forno', names: ['Al Forno', 'Aus dem Backofen', 'Aufläufe', 'Lamm aus dem Backofen', 'Pfannen'] },
	{ imageId: 'AlkoholischeGetränke', names: ['Alkoholische'] },
	{ imageId: 'American', names: ['American', 'American Food', 'American Sandwiches'] },
	{ imageId: 'Asiatisch', names: ['Asiatisch', 'Asia Wochen', 'Best Asia Food XXL'] },
	{ imageId: 'Beer', names: ['Beer', 'Bier'] },
	{ imageId: 'Beilagen', names: ['Beilagen'] },
	{ imageId: 'Bliny Crepes', names: ['Bliny', 'Crepes'] },
	{ imageId: 'Bread', names: ['Bread', 'Sandwiches', 'Brot', 'Fladenbrot', 'Baguettes', 'Ciabatta', 'Toast'] },
	{ imageId: 'Burger', names: ['Burger'] },
	{ imageId: 'Chicken Ente', names: ['Chicken', 'Ente', 'Geflügel', 'Knusprige Ente', 'Entenfleisch', 'Tandoori'] },
	{
		imageId: 'Chinesische Thailandische',
		names: ['Chinesische', 'Thailandische', 'China- und Thai'],
	},
	{ imageId: 'Cigköfte', names: ['Cigköfte'] },
	{ imageId: 'Cocktails', names: ['Cocktails'] },
	{ imageId: 'Coffee&Tea', names: ['Coffee&Tea', 'Coffee', 'Tea', 'Bubble Tea'] },
	{ imageId: 'Combomenu', names: ['Burger Menü'] },
	{ imageId: 'Desserts', names: ['Desserts'] },
	{ imageId: 'Dinner for 1', names: ['Dinner for 1'] },
	{ imageId: 'Dinner for 2', names: ['Dinner for 2'] },
	{ imageId: 'Donuts', names: ['Donuts'] },
	{
		imageId: 'Döner&Gyros',
		names: ['Döner&Gyros', 'Döner', 'Wraps', 'Gyros', 'Tacos', 'Hähnchengyros', 'Tacco&Burrito', 'Rollo',
			'Bread & Rolls', 'Falafel', 'Falafel Vegetarisch', 'Pitas', 'Lahmacun', 'Yufka'],
	},
	{ imageId: 'Eis', names: ['Eis', 'Ben & Jerry\'s Eis'] },
	{ imageId: 'Extras', names: ['Extras', 'Soßen und Extras'] },
	{ imageId: 'Fajita', names: ['Fajita'] },
	{ imageId: 'Fischgerichte', names: ['Fisch'] },
	{ imageId: 'Fitness+', names: ['Fitness+'] },
	{ imageId: 'Flammkuchen', names: ['Flammkuchen'] },
	{ imageId: 'Fleisch&Fisch', names: ['Fleisch & Fisch'] },
	{
		imageId: 'Fleisch',
		names: ['Fleisch', 'Gemischte Fleischsorten', 'Hähnchenfleisch', 'Lammfleisch', 'Rindfleisch',
			'Schweinefleisch', 'Hühnerfleisch', 'Putenfleisch', 'Schweinemedaillons', 'Gegrilltes Schweineferkel', 'vom Rind', 'vom Schwein'],
	},
	{ imageId: 'Frühstück', names: ['Frühstück'] },
	{ imageId: 'Gemüse', names: ['Gemüse'] },
	{ imageId: 'Gesundheit+', names: ['Gesundheit+'] },
	{ imageId: 'Getränke', names: ['Getränke', 'Alkoholfreie', 'Saft & Saftschorie', 'Softdrinks', 'Wasser', 'Moloko', 'Red Bull', 'San Pellegrino', 'Elephant', '28 Black'] },
	{ imageId: 'Griechische', names: ['Griechische'] },
	{ imageId: 'Grill', names: ['Grill'] },
	{ imageId: 'Hauptgerichte', names: ['Haupt'] },
	{ imageId: 'HotDogs', names: ['Hot Dogs'] },
	{ imageId: 'Indische', names: ['Indische', 'Indische Vorspeisen'] },
	{ imageId: 'Internationale Gerichte', names: ['Internationale'] },
	{ imageId: 'Kinder Menü', names: ['Kinder Menü'] },
	{ imageId: 'Krabben', names: ['Hummer Krabben', 'Krabben'] },
	{ imageId: 'Lasagne', names: ['Lasagne'] },
	{ imageId: 'Maultaschen', names: ['Maultaschen', 'Piroggen', 'Panzerotti', 'Tortellini'] },
	{ imageId: 'Meeresfrüchte', names: ['Meeresfrüchte', 'Garnelen & Fisch'] },
	{ imageId: 'Menü für 1 Person', names: ['Menü für 1 Person'] },
	{ imageId: 'Menü für 2 Personen', names: ['Menü für 2 Personen', 'Menü für 2 Person'] },
	{ imageId: 'Menü für 3 Personen', names: ['Menü für 3 Personen', 'Menü für 3 Person'] },
	{ imageId: 'Menü für 4 Personen', names: ['Menü für 4 Personen', 'Menü für 4 Person'] },
	{ imageId: 'Menüs', names: ['Menüs'] },
	{ imageId: 'Mexikanisch', names: ['Mexikanisch', 'Mexicanische'] },
	{ imageId: 'Mittagsangebote', names: ['Mittagsangebote', 'Angebote', 'Wochenangebot', 'Tag Angebot', 'Winterangebot'] },
	{ imageId: 'Obstbecher+', names: ['Obstbecher+'] },
	{ imageId: 'Omelette', names: ['Omelette'] },
	{ imageId: 'Pasta', names: ['Pasta', 'Antipasti', 'Spaghetti', 'Rigatoni', 'Tagliatelle'] },
	{
		imageId: 'Pizza',
		names: ['Pizza', 'Calzone', 'Party Pizza', 'Besondere Pizza', 'Pizzabrötchen', 'Meeresfrüchte Pizza',
			'Vegetarische Pizza', 'Amerikanische Pizza', 'Aktionspizzen', 'American Dream Pizzas', 'KinderPizza', 'Türkische Pizza',
			'Pizza BBQ', 'Pizza Holandais', 'Pizza Mozzarella', 'Pizza Cheesy', 'Pizza kreativ', 'Pizzabrot', 'Pizza', 'Süße Pizza & Brötchen'],
	},
	{ imageId: 'Potatoes', names: ['Potatoes', 'Gnocchi', 'Bratkartoffeln', 'Rösti', 'Merides', 'Imbiss'] },
	{ imageId: 'Regionale Gerichte', names: ['Regionale'] },
	{ imageId: 'Reis', names: ['Reis', 'Biryani', 'Risotto', 'Bratreis & Bratnudeln'] },
	{ imageId: 'Reisnudeln', names: ['Reisnudeln', 'Nudeln und Reis', 'Nudeln'] },
	{ imageId: 'Ribs', names: ['Ribs'] },
	{ imageId: 'Salate', names: ['Salate'] },
	{ imageId: 'Sandwich', names: ['Sandwich', 'Baguette', 'Panini', 'Croques'] },
	{ imageId: 'Saucen', names: ['Saucen'] },
	{ imageId: 'Scampi', names: ['Scampi', 'Großgarnelen'] },
	{
		imageId: 'Schaschlik', names: ['Schaschlik', 'Fleisch am Spieß', 'Kleinigkeiten', 'Event- Catering',
			'Für den kleinen Hunger', 'Finger-Food', 'Kleine', 'Vorspeisen'],
	},
	{ imageId: 'Schnitzel', names: ['Schnitzel', 'Holländerschnitzel', 'Putenschnitzel', 'Schweineschnitzel'] },
	{ imageId: 'Schwäbisch', names: ['Schwäbisch'] },
	{ imageId: 'Snacks', names: ['Snacks'] },
	{ imageId: 'Steak', names: ['Steak', 'Steak und CO'] },
	{ imageId: 'Suppen', names: ['Suppen', 'Vorspeisen Suppen'] },
	{
		imageId: 'Sushi',
		names: ['Sushi', 'Maki Sushi', 'Sushi Box', 'Sushi Menüs', 'Aktion Sushi', 'Sushi Rolle', 'Sushi Platten',
			'Sushi Burrito', 'Sushi Rollen', 'Sushi Teller', 'Nigiri Sushi', 'Futomaki', 'Salamanderofen Inside out'],
	},
	{ imageId: 'Teiggerichte', names: ['Teig'] },
	{ imageId: 'Tintenfischgerichte', names: ['Tintenfisch'] },
	{ imageId: 'Unsere Spezialitaeten', names: ['Unsere'] },
	{ imageId: 'Vegetarian', names: ['Vegetarian', 'Vegetarisch', 'Vegan'] },
	{ imageId: 'Warme Speisen', names: ['Warme Speisen'] },
	{ imageId: 'Wine', names: ['Wine', 'Wein'] },
	{ imageId: 'Wok', names: ['Wok', 'Mini Wok', 'Kaiser Wok'] },
	{ imageId: 'Wurst', names: ['Wurst'] },
	{ imageId: 'Zigaretten', names: ['Zigaretten'] },
	{
		imageId: '',
		names: ['Pakoras', 'Hänhendöne', 'Berbacvkenes', 'Partyservice', 'Orientalisch', 'Bruschetta',
			'News/Jobs', 'Giovanni Shop', 'Kiosk', 'Fussball Angebot', 'Poke Bowl', 'Jobs', 'Boxen'],
	},
]

export const getCategoryImage = (name) => {
	if (!name) return null
	let category = defaultCategories.find(category => category.name === name)
	if (!category) {
		const categoryNames = defaultCategories.flatMap(category => category.names)
		const bestMatchCategory = findBestMatch(name, categoryNames).bestMatch
		if (bestMatchCategory.rating > 0.34) {
			const bestMatchCategoryName = bestMatchCategory.target
			category = defaultCategories.find(category => !!category.names.find(catName => catName === bestMatchCategoryName))
		}
	}
	return category?.imageId
}

export const NO_ICON_URL = '/img/category/NO_ICON.svg'
