import styled from 'styled-components'
import { colorTheme } from '../../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../../style/theme'

export const SelectWrapper = styled.div`
	font-size: 1rem;
	position: relative;
	border: 1px solid ${colorTheme.restaurantDiscount.select.border};
	background-color: ${colorTheme.restaurantDiscount.select.background};
	border-radius: 0.75em;
	min-height: 4.19em;
	padding: .93em 1.31em;
	width: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	padding: .93em 1.31em;

	.arrow {
		height: 0.69em;
		width: 1.25em;
		position: absolute;
		top: calc(50% - 0.35em);
		right: 1.38em;
		fill: ${colorTheme.input.select.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		min-height: 3.93em;
		border-radius: 0.86em;
		padding: .65em 1.5em;

		.arrow {
			height: 0.78em;
			width: 1.43em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: 3.44em;
		border-radius: 0.75em;
		padding: .56em 1.31em;

		.arrow {
			height: 0.69em;
			width: 1.25em;
		}

`
SelectWrapper.displayName = 'SelectWrapper'

export const OptionsWrap = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	border: 1px solid ${colorTheme.restaurantDiscount.select.optionBorderWrap};
	border-radius: .75em;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	background-color: ${colorTheme.restaurantDiscount.select.background};
	overflow: hidden;
	z-index: 10;
`
OptionsWrap.displayName = 'OptionsWrap'

export const Option = styled.div`
	min-height: 3.44em;
	padding: 0.5em 1.25em;
	display: flex;
	align-items: center;
	color:  ${colorTheme.restaurantDiscount.select.text};

	&:not(:last-child){
		border-bottom: 1px solid ${colorTheme.restaurantDiscount.select.optionBorder};
	}

	&:hover {
		background-color:${colorTheme.restaurantDiscount.select.optionBackgroundHover};
		color: ${colorTheme.restaurantDiscount.select.textHover};
	}

	@media screen and (max-width: ${theme.point820}) {
		min-height: 3.93em;
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: 3.44em;
	}
`
Option.displayName = 'Option'

export const OptionText = styled.div`
	font-size: 1.13em;
	font-weight: 500;
	font-family: ${theme.fontRobotoMedium};

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.29em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;
	}
`
OptionText.displayName = 'OptionText'

export const SelectOption = styled.div`
	font-size: 1.13em;
	font-weight: 500;
	font-family: ${theme.fontRobotoMedium};
	max-width: 90%;
	color: ${colorTheme.restaurantDiscount.select.text};

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.29em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;
	}
`

SelectOption.displayName = 'SelectOption'
