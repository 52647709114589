import { SET_SUPPORT_ACTIVATION_DATE, SET_SUPPORT_DEACTIVATION_DATE, SET_SUPPORT_DEALER_CONTACTS, SET_SUPPORT_SHOW_BILL_UNPAID_MODAL, SET_SUPPORT_SHOW_BILL_UNPAID_WARNING_MODAL, SET_SUPPORT_SHOW_DEACTIVATION_MODAL, SET_SUPPORT_SHOW_DEACTIVATION_WARNING_MODAL, SET_SUPPORT_SHOW_EXTRA_CONTACTS_MODAL, SET_SUPPORT_SHOW_TSE_NOTIFICATION_MODAL } from '../../actionTypes/support'

const initialState = {
	dealerContacts: null,
	activationDate: null,
	deactivationDate: null,
	showDeactivationWarningModal: false,
	showDeactivationModal: false,
	showExtraContactsModal: false,
	showTseNotificationModal: false,
	showBillUnpaidWarningModal: false,
	showBillUnpaidModal: false,
}

export function supportReducer (state = initialState, action) {
	switch (action.type) {
		case SET_SUPPORT_DEALER_CONTACTS:
			return {
				...state,
				dealerContacts: action.value,
			}
		case SET_SUPPORT_ACTIVATION_DATE:
			return {
				...state,
				activationDate: action.value,
			}
		case SET_SUPPORT_DEACTIVATION_DATE:
			return {
				...state,
				deactivationDate: action.value,
			}
		case SET_SUPPORT_SHOW_DEACTIVATION_WARNING_MODAL:
			return {
				...state,
				showDeactivationWarningModal: action.value,
			}
		case SET_SUPPORT_SHOW_DEACTIVATION_MODAL:
			return {
				...state,
				showDeactivationModal: action.value,
			}
		case SET_SUPPORT_SHOW_EXTRA_CONTACTS_MODAL:
			return {
				...state,
				showExtraContactsModal: action.value,
			}
		case SET_SUPPORT_SHOW_TSE_NOTIFICATION_MODAL:
			return {
				...state,
				showTseNotificationModal: action.value,
			}
		case SET_SUPPORT_SHOW_BILL_UNPAID_WARNING_MODAL:
			return {
				...state,
				showBillUnpaidWarningModal: action.value,
			}
		case SET_SUPPORT_SHOW_BILL_UNPAID_MODAL:
			return {
				...state,
				showBillUnpaidModal: action.value,
			}
		default:
			return state
	}
}
