import React, { useCallback, useEffect, useRef, useState } from 'react'
import AllProducts from './AllProducts'
import { batch, useDispatch, useSelector } from 'react-redux'
import ModalToppingGrid from './ModalToppingGrid'
import ModalSize from './ModalSize'
import { getCartSettingsThunk } from '../../../../redux/thunk/cartProduct'
import { setCartProductActiveProductIndex, setCartProductLoading, setCartProductMenu, setCartProductMenuProductIndex, setCartProductProductFocus, setCartProductSearchText } from '../../../../redux/action/cartProduct'
import { clone, convertProduct2Item, getMenuPositionProduct, getMenuPositionProductPrice, getMenuProductPriceIndex, getPosPriceByIndex, getPosPricesByOrderType, notifyToast, recalculateSubItems } from '../../../../helper'
import keyboardImg from '../../../../img/svg/input/keyboard.svg'
import { InputSearch, ProductListWrapper, ProductSearchWrap } from './index.styled'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import MenuSelection from './MenuSelection'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import CartCategories from '../CartCategories'
import ModalProductType from '../ModalProductType'
import { theme } from '../../../../style/theme'
import Keyboard from '../../keyboard'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { filterSearchString } from '../../../../helper/filterSearchString'
import { useTranslation } from 'react-i18next'

const EVENT_Z_INDEX = 0

const CartProductSearch = React.memo(({ focusedItem, module, orderType, addTopping, addMainDish, handleF2, clearSearchParams, isDisabled }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const products = useSelector(state => state.cartProduct.products)
	const loading = useSelector(state => state.cartProduct.loading)
	const menu = useSelector(state => state.cartProduct.menu)
	const menuProductIndex = useSelector(state => state.cartProduct.menuProductIndex)
	const searchText = useSelector(state => state.cartProduct.searchText)
	const activeProductIndex = useSelector(state => state.cartProduct.activeProductIndex)
	const enableQuantity = useSelector(state => state.cartProduct.enableQuantity)
	const dispatch = useDispatch()
	const [modalToppingGridItem, setModalToppingGridItem] = useState([])
	const [showKeyboard, setShowKeyboard] = useState(false)
	const [modalToppingGridIsOpen, setModalToppingGridIsOpen] = useState(false)
	const [modalProductTypeIsOpen, setModalProductTypeIsOpen] = useState(false)
	const blockScroll = useRef(null)
	const inputSearch = useRef()
	const inputSearchWrap = useRef()
	const menuSelectionRef = useRef()
	const isTablet = useWindowBreakPoint(theme.point820)
	const { t } = useTranslation()

	const focusSearch = useCallback(() => {
		if (!touchPad) {
			inputSearch.current.focus()
		}
	}, [touchPad, inputSearch])

	useEffect(() => {
		focusSearch()
		if (orderType) {
			dispatch(getCartSettingsThunk(module, orderType))
		}
	}, [module, orderType]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (products.length < 21) {
			blockScroll.current.scrollTop = 0
		}
		if (!loading && !products.length && !touchPad
			&& !searchText.includes('+') && !searchText.includes('-') && !searchText.includes(',')) {
			inputSearch.current.select()
		}
	}, [products]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isNeededZIndex) {
			focusSearch()
		}
	}, [isNeededZIndex]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const productsAll = document.querySelector('#cart-list')
		if (productsAll) {
			const productsAllActive = productsAll.querySelector('li.active')
			if (productsAllActive) {
				productsAll.scrollTop = productsAllActive.offsetTop - productsAll.offsetTop - 20
			}
		}
	}, [activeProductIndex])

	const onChangeSearchInput = useCallback(event => {
		if (!menu) clearSearchParams()
		event.target.value = event.target.value.slice(0, 50)
		dispatch(setCartProductSearchText(filterSearchString(event.target.value)))
		dispatch(setCartProductActiveProductIndex(0))
		dispatch(setCartProductLoading(true))
	}, [menu, clearSearchParams, dispatch])

	const onFocusSearchInput = useCallback(() => {
		if (isTablet) {
			setShowKeyboard(true)
			inputSearch.current?.blur()
		}
	}, [isTablet, inputSearch])

	const addFinalToppingToMenu = useCallback((item) => {
		const menuCopy = clone(menu)
		const menuProductItem = menuCopy.menuPositions[menuProductIndex]?.item || menuCopy.menuPositions[menuProductIndex - 1]?.item
		if (menuProductItem) {
			menuProductItem.items = menuProductItem.items ? [...menuProductItem.items, item] : [item]
			recalculateSubItems(menuProductItem)
			dispatch(setCartProductMenu(menuCopy))
		}
	}, [menu, menuProductIndex, dispatch])

	const addToppingToMenu = useCallback((product, quantity) => {
		const menuProductItem = menu.menuPositions[menuProductIndex]?.item || menu.menuPositions.slice().reverse().find(mp => mp.item)?.item
		if (menuProductItem) {
			if (touchPad) {
				setModalToppingGridItem(product)
				setModalToppingGridIsOpen(true)
			} else {
				const priceIndex = menuProductItem.priceIndex
				const price = getPosPriceByIndex(product, orderType, priceIndex)
				let toppingItem = convertProduct2Item(product, quantity, price.value, priceIndex, null)
				addFinalToppingToMenu(toppingItem)
			}
		} else {
			notifyToast({ message: t('Cart.Toasts.item_first') }, 'warning')
		}
	}, [menu, menuProductIndex, touchPad, addFinalToppingToMenu, orderType, t])

	const addToppingToCart = useCallback((product, quantity) => {
		if (focusedItem) {
			if (touchPad) {
				setModalToppingGridItem(product)
				setModalToppingGridIsOpen(true)
			} else {
				const priceIndex = focusedItem.priceIndex >= 0 ? focusedItem.priceIndex : 0
				const price = getPosPriceByIndex(product, orderType, priceIndex)
				let toppingItem = convertProduct2Item(product, quantity, price.value, priceIndex, null)
				addTopping(toppingItem)
			}
		} else {
			notifyToast({ message: t('Cart.Toasts.item_first') }, 'warning')
		}
	}, [focusedItem, touchPad, addTopping, orderType, t])

	const addMainDishWithSizes = useCallback((product) => {
		dispatch(setCartProductProductFocus(product))
	}, [dispatch])

	const addMainDishToCart = useCallback((product) => {
		const prices = getPosPricesByOrderType(product, orderType)
		let mainDishItemWithOneSize = convertProduct2Item(product, 1, prices?.[0]?.value, 0, [])
		if (prices?.length > 1 || product.numberOfMustExtras > 0 || enableQuantity || !product.disableCartModal) {
			addMainDishWithSizes(product)
		} else {
			addMainDish(mainDishItemWithOneSize)
		}
	}, [addMainDish, addMainDishWithSizes, enableQuantity, orderType])

	const addFinalMainDishToMenu = useCallback((item) => {
		const menuCopy = clone(menu)
		const menuPosition = menuCopy.menuPositions[menuProductIndex]
		const menuPositionProduct = getMenuPositionProduct(menuPosition, item.itemId)
		menuPosition.item = item
		menuPosition.item.price = getMenuPositionProductPrice(menuPosition, item, orderType)
		menuPosition.item.extraPrice = menuPositionProduct ? menuPositionProduct.extraPrice : menuPosition.extraPrice
		menuPosition.item.freeExtrasNumber = menuPosition.freeExtrasNumber
		menuPosition.item.withOriginalPrice = menuPositionProduct ? menuPositionProduct.withOriginalPrice : menuPosition.withOriginalPrice
		recalculateSubItems(menuPosition.item)
		menuSelectionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
		batch(() => {
			dispatch(setCartProductMenu(menuCopy))
			dispatch(setCartProductMenuProductIndex(menuCopy.menuPositions.length === (menuProductIndex + 1) ? menuProductIndex : menuProductIndex + 1))
		})
	}, [menu, menuProductIndex, orderType, dispatch])

	const addMainDishToMenu = useCallback((product) => {
		if (product.productType !== 'MENU') {
			const priceIndex1 = getMenuProductPriceIndex(menu, menuProductIndex, product, orderType)
			const price = getPosPriceByIndex(product, orderType, priceIndex1)
			let mainDishItem = convertProduct2Item(product, 1, price.value, priceIndex1, [], price.priceTitle.title)
			if (product.numberOfMustExtras > 0 || !product.disableCartModal) {
				addMainDishWithSizes(product)
			} else {
				addFinalMainDishToMenu(mainDishItem)
			}
		} else {
			notifyToast({ message: t('Cart.Toasts.disable_add_menu_to_menu') }, 'warning')
		}
	}, [menu, menuProductIndex, addFinalMainDishToMenu, addMainDishWithSizes, orderType, t])

	const addItem = useCallback((product, quantity) => {
		if (menu) {
			switch (product.productType) {
				case 'TOPPING':
					addToppingToMenu(product, quantity)
					break
				default:
					if (quantity > 0) {
						addMainDishToMenu(product)
					}
					break
			}
		} else {
			switch (product.productType) {
				case 'TOPPING':
					addToppingToCart(product, quantity)
					break
				case 'MENU':
					dispatch(setCartProductMenu(product))
					break
				default:
					if (quantity > 0) {
						addMainDishToCart(product)
					}
					break
			}
		}
		dispatch(setCartProductSearchText(''))
		dispatch(setCartProductActiveProductIndex(0))
	}, [menu, addToppingToMenu, addMainDishToMenu, addToppingToCart, addMainDishToCart, dispatch])

	const incrementProduct = useCallback((product) => {
		addItem(product, 1)
	}, [addItem])

	const decrementProduct = useCallback((product) => {
		addItem(product, -1)
	}, [addItem])

	const handleKeyboardType = useCallback((textCallbackKay) => {
		dispatch(setCartProductSearchText(textCallbackKay))
	}, [dispatch])

	const toggleKeyboard = useCallback(() => {
		setShowKeyboard(!showKeyboard)
	}, [showKeyboard])

	const closeKeyboard = useCallback(() => {
		setShowKeyboard(false)
	}, [])

	const openModalProductType = useCallback(() => {
		setModalProductTypeIsOpen(true)
	}, [])

	const handleEnter = useCallback(() => {
		if (searchText?.includes('+') || searchText?.includes('-') || searchText?.includes(',')) {
			openModalProductType()
		} else if (products && products[activeProductIndex]) {
			incrementProduct(products[activeProductIndex])
		}
	}, [searchText, products, activeProductIndex, incrementProduct, openModalProductType])

	const closeModalToppingGrid = useCallback(() => {
		setModalToppingGridIsOpen(false)
	}, [])

	const closeModalProductType = useCallback(() => {
		setModalProductTypeIsOpen(false)
	}, [])

	useOutsideClick(inputSearchWrap, closeKeyboard)

	return (
		<>
			<ProductSearchWrap ref={inputSearchWrap} className={'cart-col' + (menu ? ' col-3' : '') + (touchPad ? ' touchPad' : '')}>
				{menu &&
					<MenuSelection addMainDish={addMainDish} orderType={orderType} menuSelectionRef={menuSelectionRef}/>}
				{!touchPad &&
					<InputSearch data-testid="searchInput" className={showKeyboard && isTablet ? 'active' : ''}>
						<input name="searchInput"
									 onChange={onChangeSearchInput}
									 onFocus={onFocusSearchInput}
									 value={searchText}
									 ref={inputSearch}
									 placeholder={t('Cart.search')}
									 autoFocus={true}
									 autoComplete="off"
									 role="presentation"
									 inputMode="tel"
									 disabled={isDisabled}/>
						<img src={keyboardImg} alt="keyboard" onClick={toggleKeyboard} />
					</InputSearch>}
				{showKeyboard &&
					<Keyboard currentValue={searchText}
										handleType={handleKeyboardType}
										onlyNumbers={true}
										close={closeKeyboard}
										enter={handleEnter} />}
				<ProductListWrapper id="cart-list" ref={blockScroll} className={touchPad ? 'touchPad' : ''}>
					<AllProducts orderType={orderType}
											 handleEnter={handleEnter}
											 increment={incrementProduct}
											 decrement={decrementProduct}
											 isDisabled={isDisabled}/>
				</ProductListWrapper>
			</ProductSearchWrap>
			<CartCategories handleF2={handleF2}
											incrementProduct={incrementProduct}
											openModalProductType={openModalProductType}
											openKeyboard={toggleKeyboard}
											isDisabled={isDisabled}
											module={module}/>

			<ModalSize orderType={orderType}
								 addMainDish={menu ? addFinalMainDishToMenu : addMainDish} />
			<ModalToppingGrid isOpen={modalToppingGridIsOpen}
												item={modalToppingGridItem}
												focusedItem={menu ? menu.menuPositions[menuProductIndex] : focusedItem}
												orderType={orderType}
												addTopping={menu ? addFinalToppingToMenu : addTopping}
												close={closeModalToppingGrid} />
			<ModalProductType isOpen={modalProductTypeIsOpen}
												orderType={orderType}
												close={closeModalProductType}
												incrementProduct={incrementProduct} />
		</>
	)
})

CartProductSearch.displayName = 'CartProductSearch'
export default CartProductSearch
