import { SET_COUPONS, APPEND_COUPONS, SET_COUPON, SET_COUPONS_LENGTH, SET_COUPON_ERROR_IS_EXIST, SET_COUPON_DISABLED_FOR_KASSE, SET_GIFT_COUPON, SET_GIFT_COUPON_IS_INVALID, SET_GIFT_COUPONS, SET_GIFT_COUPONS_LENGTH, APPEND_GIFT_COUPONS, SET_GIFT_COUPON_IS_USED, SET_GIFT_COUPON_IS_NOT_EXIST, SET_GIFT_COUPON_IS_CANCELED } from '../../actionTypes/coupon'

const initialState = {
  coupons: [],
  coupon: null,
  couponsLength: 0,
  couponIsExist: false,
	couponDisabledForKasse: false,
	giftCoupons: [],
	giftCouponsLength: 0,
	giftCouponIsInvalid: null,
	giftCouponIsUsed: false,
	giftCouponIsNotExist: false,
	giftCouponIsCanceled: false,
	giftCoupon: null
}

export function couponReducer (state = initialState, action) {
  switch (action.type) {
    case SET_COUPONS:
      return {
        ...state,
        coupons: action.value,
      }
    case APPEND_COUPONS:
      return {
        ...state,
        coupons: state.coupons.concat(...action.value),
      }
		case SET_COUPONS_LENGTH:
      return {
        ...state,
        couponsLength: action.value,
      }
    case SET_COUPON:
      return {
        ...state,
        coupon: action.value,
      }
		case SET_COUPON_ERROR_IS_EXIST:
			return {
				...state,
				couponIsExist: action.value,
			}
		case SET_COUPON_DISABLED_FOR_KASSE:
			return {
				...state,
				couponDisabledForKasse: action.value,
			}
		case SET_GIFT_COUPONS:
			return {
				...state,
				giftCoupons: action.value,
			}
		case APPEND_GIFT_COUPONS:
			return {
				...state,
				giftCoupons: state.giftCoupons.concat(...action.value),
			}
		case SET_GIFT_COUPONS_LENGTH:
			return {
				...state,
				giftCouponsLength: action.value,
			}
		case SET_GIFT_COUPON_IS_INVALID:
			return {
				...state,
				giftCouponIsInvalid: action.value,
			}
		case SET_GIFT_COUPON_IS_USED:
			return {
				...state,
				giftCouponIsUsed: action.value,
			}
		case SET_GIFT_COUPON_IS_NOT_EXIST:
			return {
				...state,
				giftCouponIsNotExist: action.value,
			}
		case SET_GIFT_COUPON_IS_CANCELED:
			return {
				...state,
				giftCouponIsCanceled: action.value,
			}
		case SET_GIFT_COUPON:
			return {
				...state,
				giftCoupon: action.value,
			}
    default:
      return state
  }
}
