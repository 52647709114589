import styled from 'styled-components'
import { theme } from '../../../style/theme'

export const NoDriverModalWrap = styled.div`
	margin: 1.5em 0;
`
NoDriverModalWrap.displayName = 'NoDriverModalWrap'

export const NoDriverModalText = styled.div`
    color: #383A50;
    text-align: center;
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.75em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
NoDriverModalText.displayName = 'NoDriverModalText'