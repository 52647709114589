import { APPEND_WOLT_RESTAURANTS, SET_WOLT_RESTAURANT, SET_WOLT_RESTAURANTS, SET_WOLT_RESTAURANTS_SHOW_ERROR_MODAL, SET_WOLT_RESTAURANTS_TOTAL_LENGTH } from '../../actionTypes/woltRestaurant'

const initialState = {
  restaurants: [],
  restaurantsTotalLength: 0,
  restaurant: null,
  showErrorModal: false
}

export function woltRestaurantReducer (state = initialState, action) {
  switch (action.type) {
    case SET_WOLT_RESTAURANTS:
      return {
        ...state,
        restaurants: action.value,
      }
    case SET_WOLT_RESTAURANTS_TOTAL_LENGTH:
      return {
        ...state,
        restaurantsTotalLength: action.value,
      }
    case SET_WOLT_RESTAURANT:
      return {
        ...state,
        restaurant: action.value,
      }
    case APPEND_WOLT_RESTAURANTS:
      return {
        ...state,
        restaurants: state.restaurants ? state.restaurants.concat(...action.value) : action.value,
      }
		case SET_WOLT_RESTAURANTS_SHOW_ERROR_MODAL:
			return {
				...state,
				showErrorModal: action.value,
			}
    default:
      return state
  }
}
