import React, { useCallback, useEffect, useState } from 'react'
import { PayPalNotificationWrap } from './../PayPalDeadlineWarningModal/index.styled'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CustomInfoModal from '../../Elements/modal/CustomInfoModal'
import CheckBoxInputControlled from '../../Elements/inputs/CheckboxInput/controlled'
import Button from '../../Elements/buttons/Button'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { theme } from '../../../style/theme'
import CheckBoxInputControlledGray from '../../Elements/inputs/CheckBoxInputGray/controlled'
import { selectContainsAnyRole, selectHasAnyGroup, selectUsername } from '../../../redux/selector/system'
import { useNavigate } from 'react-router-dom'

const EVENT_Z_INDEX = 58

const OnlinePaymentsWarningModal = React.memo(() => {
	const { t } = useTranslation()
	const isMobile = useWindowBreakPoint(theme.point720)
	const stripeRegistrationNotCompleted = useSelector(state => state.payment.stripeNotification?.status === 'RESTRICTED')
	const username = useSelector(selectUsername)
	const hasRoleServiceWrite = useSelector(selectContainsAnyRole(['PROGRAM_SETTINGS_WRITE']))
	const hasRoleOwner = useSelector(selectHasAnyGroup(['OWNER']))

	const [openModal, setOpenModal] = useState(false)
	const [disabledModal, setDisabledModal] = useState(false)

	const localStorageModalDisabled = JSON.parse(window.localStorage.getItem('stripeWarningModalDisabled'))
	const localStorageModalEnabled = !localStorageModalDisabled?.['OWNER'] && !localStorageModalDisabled?.[username]
	const shouldShowModal = stripeRegistrationNotCompleted && hasRoleServiceWrite && localStorageModalEnabled

	const navigate = useNavigate()

	useEffect(() => {
		if (shouldShowModal)
			setOpenModal(true)
		else setOpenModal(false)
	}, [shouldShowModal])

	const handleChangeDisabledModal = useCallback(() => {
		setDisabledModal(!disabledModal)
	}, [disabledModal])

	const handleCloseModal = useCallback(() => {
		setOpenModal(false)
		setDisabledModal(false)
	}, [])

	const handleAccept = useCallback(() => {
		if (disabledModal) {
			if (hasRoleOwner)
				window.localStorage.setItem('stripeWarningModalDisabled', JSON.stringify({ 'OWNER': disabledModal }))
			else window.localStorage.setItem('stripeWarningModalDisabled', JSON.stringify({ ...localStorageModalDisabled, [username]: disabledModal }))
		}
		handleCloseModal()
	}, [hasRoleOwner, localStorageModalDisabled, disabledModal, handleCloseModal, username])

	const navigateToSettings = useCallback(() => {
		navigate('/settings/payments')
		handleCloseModal()
	}, [navigate, handleCloseModal])

	return (
		<>
			<CustomInfoModal isOpen={openModal}
				title={t('Payments.modal.notification.title')}
				text={t('Payments.modal.notification.warningStripeTitle')}
				close={handleCloseModal}
				zIndex={EVENT_Z_INDEX}
				modalColor="orange"
				button={<>
					<Button icon="settings-2" onClick={navigateToSettings} text={t('Payments.modal.notification.paymentSettings')} color="gray" />
					<Button icon="checkmark" onClick={handleAccept} tabIndex="1" text="Ok" color="green" keyButton="F2" />
				</>}>
				<PayPalNotificationWrap>
					{!isMobile ? <CheckBoxInputControlled id="accept" label={t('Payments.modal.notification.accept')} className="accept"
						checked={disabledModal} onChange={handleChangeDisabledModal} />
						: <CheckBoxInputControlledGray label={t('Payments.modal.notification.accept')}
							onChange={handleChangeDisabledModal}
							isActive={disabledModal} />}
				</PayPalNotificationWrap>
			</CustomInfoModal>
		</>
	)
})

OnlinePaymentsWarningModal.displayName = 'OnlinePaymentsWarningModal'
export default OnlinePaymentsWarningModal
