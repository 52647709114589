import woltInstance from '../../wolt/instance'

const fetchWoltDriverDeliveries = (page = 0, size = 10, orderId) => {
	return woltInstance.get(
		'/driverIntegrations/deliveries'
		+ '?page=' + page + '&size=' + size
		+ (orderId ? '&orderId=' + orderId : '')
	)
}

export default fetchWoltDriverDeliveries
