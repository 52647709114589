import React from 'react'
import { IncomingCallIconWrapper } from './index.styled'

const IncomingCallIcon = React.memo(() => {
	return (
		<IncomingCallIconWrapper data-testid="incoming-call-icon">
			<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path className="wave-second" d="M25.0492 0.6C25.3492 0 26.0992 -0.15 26.5492 0.15C26.9992 0.45 27.7492 0.9 28.3492 1.65C28.9492 2.4 29.5492 3.3 29.8492 4.5C30.2992 6 30.1492 7.5 29.6992 8.85C29.5492 9.3 29.3992 9.6 29.2492 9.9C28.9492 10.5 28.1992 10.65 27.7492 10.35C27.2992 10.05 27.1492 9.45 27.2992 9V8.85C27.2992 8.7 27.4492 8.55 27.5992 8.25C27.8992 7.35 28.0492 6.3 27.7492 5.25C27.5992 4.5 27.1492 3.9 26.6992 3.3C26.2492 2.7 25.7992 2.4 25.6492 2.25C24.8992 1.8 24.7492 1.2 25.0492 0.6Z" fill="white" />
				<path className="phone" d="M19.0496 19.9523C18.8996 19.3523 18.2996 19.0523 17.6996 19.2023L14.8496 19.9523H14.6996C14.2496 20.1023 13.9496 20.7023 14.0996 21.3023C14.2496 21.9023 14.8496 22.2023 15.4496 22.0523L18.2996 21.3023H18.4496C18.8996 21.0023 19.1996 20.4023 19.0496 19.9523Z" fill="white" />
				<path className="phone" d="M19.6516 5.55081C19.2016 3.75081 17.2516 2.70081 15.4516 3.15081L8.70159 4.95081C6.90159 5.40081 5.70159 7.35081 6.30159 9.15081L10.5016 24.6008C10.9516 26.4008 12.9016 27.4508 14.7016 27.0008L21.6016 25.2008C23.4016 24.7508 24.4516 22.8008 24.0016 21.0008L19.6516 5.55081ZM16.0516 5.25081C16.6516 5.10081 17.2516 5.40081 17.4016 6.00081L21.6016 21.4508C21.7516 22.0508 21.4516 22.6508 20.8516 22.8008L13.9516 24.6008C13.3516 24.7508 12.7516 24.4508 12.6016 23.8508L8.40159 8.55081C8.25159 7.95081 8.55159 7.35081 9.15159 7.05081L16.0516 5.25081Z" fill="white" />
				<path className="wave-first" d="M24.9002 3.4514C24.4502 3.0014 23.7002 3.1514 23.2502 3.6014C22.8002 4.0514 22.9502 4.8014 23.4002 5.2514C23.4002 5.2514 23.5502 5.4014 23.7002 5.5514C23.8502 5.7014 24.0002 5.8514 24.0002 6.0014C24.0002 6.3014 24.0002 6.4514 24.0002 6.7514C24.0002 6.7514 24.0002 6.7514 24.0002 6.9014V7.0514C23.8502 7.6514 24.1502 8.1014 24.6002 8.4014C25.2002 8.7014 25.8002 8.4014 26.1002 7.8014V7.6514L26.2502 7.3514C26.4002 6.7514 26.4002 6.1514 26.2502 5.4014C26.1002 4.9514 25.8002 4.5014 25.5002 4.2014C25.3502 3.9014 25.0502 3.6014 24.9002 3.4514Z" fill="white" />
				<path className="wave-second" d="M0.750028 20.1C1.05003 19.5 1.80003 19.35 2.25003 19.65C2.70003 19.95 3.00003 20.55 2.70003 21L2.55003 21.3C2.55003 21.45 2.40003 21.6 2.40003 21.75C2.10003 22.65 1.95003 23.7 2.25003 24.9C2.40003 25.65 2.85003 26.25 3.30003 26.85C3.75003 27.3 4.05003 27.6 4.35003 27.9H4.50003C5.10003 28.2 5.25003 28.95 4.95003 29.4C4.65003 30 3.90003 30.15 3.45003 29.85C3.00003 29.55 2.40003 29.1 1.65003 28.2C1.05003 27.45 0.600029 26.55 0.300029 25.35C-0.149971 23.85 2.85525e-05 22.35 0.450029 21C0.600029 20.7 0.750028 20.25 0.750028 20.1Z" fill="white" />
				<path className="wave-first" d="M5.54961 21.4458C4.94961 21.1458 4.34961 21.4458 4.04961 22.0458V22.1958L3.89961 22.4958C3.74961 23.0958 3.74961 23.6958 3.89961 24.4458C4.04961 24.8958 4.19961 25.3458 4.49961 25.6458C4.79961 25.9458 4.94961 26.2458 5.24961 26.3958C5.69961 26.8458 6.44961 26.6958 6.89961 26.2458C7.34961 25.7958 7.19961 25.0458 6.74961 24.5958C6.74961 24.5958 6.59961 24.4458 6.44961 24.2958C6.14961 24.2958 5.99961 24.1458 5.99961 23.9958C5.99961 23.6958 5.99961 23.5458 5.99961 23.2458C5.99961 23.2458 5.99961 23.2458 5.99961 23.0958V22.9458C6.29961 22.3458 5.99961 21.7458 5.54961 21.4458Z" fill="white" />
			</svg>
		</IncomingCallIconWrapper>)
})

IncomingCallIcon.displayName = 'IncomingCallIcon'
export default IncomingCallIcon
