import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const CashDesk = lazyWithRetry(() => import(/* webpackChunkName: "cash-desk" */'../../component/CashDesk'))

const CashDeskPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<CashDesk />
		</Suspense>
	)
})

CashDeskPage.displayName = 'CashDeskPage'
export default CashDeskPage
