import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomModal from '../../../../modal/CustomModal'
import DishCourseButton from './Button'
import KeyBindings from '../../../../keybindings'
import { selectIsNeededZIndex } from '../../../../../../redux/selector/system'
import { useTranslation } from 'react-i18next'
import { setTableCartItemDishCourseIndex, setTableCartShowDishCourseModal, updateTableCartOrderItem } from '../../../../../../redux/action/tableCart'

const Z_INDEX = 70

const DishCourseModal = React.memo(() => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(Z_INDEX))
	const itemFocusIndex = useSelector(state => state.tableCart.itemFocusIndex)
	const showDishCourseModal = useSelector(state => state.tableCart.showDishCourseModal)
	const activeDishCourseIndex = useSelector(state => state.tableCart.itemDishCourseIndex)
	const order = useSelector(state => state.tableCart.newOrder)
	const item = order?.items[itemFocusIndex]
	const [activeButtonIndex, setActiveButtonIndex] = useState(0)
	const dishCoursesLength = useSelector(store => store.restaurantSettings.dishCourses?.coursesCount)
	const dishCoursesArray = Array.from({ length: dishCoursesLength }, (_, index) => ({ name: `${t('Settings.dishCourses.dishCourse')} ${index + 1}`, index: index + 1 }))
	const buttons = useMemo(() => [...dishCoursesArray, { name: t('Cart.dishCourseModal.defaultButton'), index: 0 }], [t, dishCoursesArray])
	const dispatch = useDispatch()

	const focusFirstChild = useCallback(() => {
		setActiveButtonIndex(0)
	}, [])

	useEffect(() => {
		if (showDishCourseModal) {
			if (activeDishCourseIndex) {
				setActiveButtonIndex(activeDishCourseIndex)
			} else {
				focusFirstChild()
			}	
		}
	}, [showDishCourseModal]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleArrowUp = useCallback(() => {
		if (!buttons[activeButtonIndex - 1]) {
			setActiveButtonIndex(buttons.length - 1)
		} else {
			setActiveButtonIndex(activeButtonIndex - 1)
		}
	}, [buttons, activeButtonIndex])

	const handleArrowDown = useCallback(() => {
		if (!buttons[activeButtonIndex + 1]) {
			setActiveButtonIndex(0)
		} else {
			setActiveButtonIndex(activeButtonIndex + 1)
		}
	}, [buttons, activeButtonIndex])

	const handleDishCourseModalClose = useCallback(() => {
		dispatch(setTableCartShowDishCourseModal(false))
		dispatch(setTableCartItemDishCourseIndex(0))
	}, [dispatch])

	const handleButtonClick = useCallback((button, index) => {
		setActiveButtonIndex(index)
		dispatch(updateTableCartOrderItem({ item: { ...item, dishCourse: { index: button.index } }, itemIndex: itemFocusIndex }))
		handleDishCourseModalClose()
	}, [item, dispatch, itemFocusIndex, handleDishCourseModalClose])

	const handleEnter = useCallback(() => {
		handleButtonClick( buttons[activeButtonIndex], activeButtonIndex)
	}, [buttons, activeButtonIndex, handleButtonClick])

	return (
		<>
			<CustomModal isOpen={showDishCourseModal}
									 close={handleDishCourseModalClose}
									 title={t('Cart.dishCourseModal.title')}
									 id="dishCourseModal"
									 zIndex={Z_INDEX}
									 returnZIndex={0}>
				{buttons?.map((button, index) =>
					<DishCourseButton key={index}
														index={index}
														tabIndex={index}
														button={button}
														onClick={handleButtonClick}
														isActive={index === activeButtonIndex} />)}
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter}
										 arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown} />}
		</>
	)
})

DishCourseModal.displayName = 'DishCourseModal'
export default DishCourseModal
