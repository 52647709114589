import styled from 'styled-components'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../style/theme'

export const InvoiceBillingAddressWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1.25em;
	margin-bottom: 0.63em;
`
InvoiceBillingAddressWrapper.displayName = 'InvoiceBillingAddressWrapper'

export const InvoiceBillingAddressSubtitle = styled.div`
	color: ${colorTheme.cartModal.invoiceModals.customerData.subtitle};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.13em;
	font-weight: 500;
`
InvoiceBillingAddressSubtitle.displayName = 'InvoiceBillingAddressSubtitle'

export const InvoiceBillingAddressDescription = styled.div`
	color: ${colorTheme.cartModal.invoiceModals.customerData.description};
	font-size: 1.06em;
	font-weight: 400;
`
InvoiceBillingAddressDescription.displayName = 'InvoiceBillingAddressDescription'
