import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { DeliveryCostWrap } from '../../Delivery/DeliveryCart/DeliveryCost/index.styled'
import { DiscountLabel } from './Discount/index.styled'
import { colorTheme } from '../../../style/theme/colorTheme'
export const CustomerData = styled.div`
	position: relative;
	padding-left: 1.88em;
	font-size: 1rem;

	&:before {
		content: url("data:image/svg+xml,%3Csvg width='100%' viewBox='0 0 19 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34461 28C8.9839 28 8.82609 28 7.27053 25.6216C6.70692 24.7536 5.56844 22.9614 4.27214 20.6393C2.33333 17.1337 0 12.3655 0 9.60387C0 4.30596 4.19324 0 9.34461 0C14.496 0 18.6892 4.30596 18.6892 9.60387C18.6892 12.3768 16.3559 17.1337 14.4058 20.6506C13.1208 22.9614 11.9823 24.7649 11.4074 25.6329C9.86312 28 9.70531 28 9.34461 28ZM9.34461 1.35266C4.91465 1.35266 1.30757 5.04992 1.30757 9.60387C1.30757 13.628 7.56361 23.9195 9.34461 26.3317C11.1256 23.9308 17.3816 13.6393 17.3816 9.60387C17.3816 5.04992 13.7746 1.35266 9.34461 1.35266Z' fill='white'/%3E%3Cpath d='M9.3447 13.2561C7.20299 13.2561 5.45581 11.4638 5.45581 9.26573C5.45581 7.06766 7.20299 5.27539 9.3447 5.27539C11.4864 5.27539 13.2336 7.06766 13.2336 9.26573C13.2336 11.4638 11.4864 13.2561 9.3447 13.2561ZM9.3447 6.62805C7.92441 6.62805 6.77465 7.81162 6.77465 9.26573C6.77465 10.7198 7.92441 11.9034 9.3447 11.9034C10.765 11.9034 11.9147 10.7198 11.9147 9.26573C11.9147 7.81162 10.765 6.62805 9.3447 6.62805Z' fill='white'/%3E%3C/svg%3E%0A");
		display: block;
		position: absolute;
		top: 0.38em;
		left: 0;
		width: 1.19em;
		height: 1.75em;
	}

	@media screen and (max-width: ${theme.point820}) {
		display: none;
	}
`

export const CustomerName = styled.div`
	font-size: 1.25em;
	font-family: ${theme.fontRobotoMedium};
	line-height: 1.2em;
	font-weight: 500;
	color: ${colorTheme.cart.customerName.text};

	@media screen and (max-width: ${theme.point820}) {
		display: none;
	}
`

export const CustomerAddress = styled.div`
	font-size: 1em;
	font-family: ${theme.fontRobotoMedium};
	line-height: 1.19em;
	font-weight: 400;
	color: ${colorTheme.cart.customerAddress.text};
`

export const CartGrid = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: 2.15fr 3fr 1fr;
	grid-template-rows: min-content auto;
	gap: 0.63em 1.25em;
	margin: 0.625em;

	& > .cart-col {
		height: calc(100vh - 10.5em);
	}

	& > .cart-col-last {
		height: calc(100vh - 6.36em);
	}

	&.versionUpdater {
		& > .cart-col {
			height: calc(100vh - 12.8em);
		}

		& > .cart-col-last {
			height: calc(100vh - 8.7em);
		}
	}

	@media screen and (max-width: ${theme.point1024}) {
		grid-template-columns: 2.15fr 3fr 1.5fr;
	}

	@media screen and (max-width: ${theme.point820}) {
		margin: 0.71em 0.57em 0.57em;
		gap: 0.29em;
		grid-template-columns: 2.15fr 1.9fr 1.5fr;
		grid-template-rows: 1fr;

		& > .cart-col, .cart-col-last {
			height: calc(100vh - 5.55em);
		}

		&.versionUpdater {
			& > .cart-col, .cart-col-last {
				height: calc(100vh - 7.85em);
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
		grid-template-rows: auto min-content;
		height: 100%;
		gap: 0;
		margin: 0;

		& > .cart-col, .cart-col-last {
			height: auto;

			&:nth-child(1) {
				display: none;
			}
		}

		&.versionUpdater {
			& > .cart-col, .cart-col-last {
				height: auto;
			}
		}

		&.cart {
			margin-right: 0;
			margin-left: 0;
			grid-template-rows: 100%;
			height: 100%;

			> .cart-col, .cart-col-last {
				&:nth-child(1) {
					display: inherit;
				}

				&:nth-child(2), &:nth-child(3) {
					display: none;
				}
			}
		}
	}
`
CartGrid.displayName = 'CartGrid'

export const CartWrapper = styled.div`
	display: grid;
	grid-template-rows: min-content auto min-content min-content;
	grid-row: 2;

	@media screen and (max-width: ${theme.point820}) {
		grid-row: 1;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding-bottom: 0.63em;
		padding-left: 0.63em;
		padding-right: 0.63em;
	}
`

export const CartTableHead = styled.div`
	display: grid;
	list-style: none;
	padding: 0 1em 0.63em 0.63em;
	grid-auto-columns: 1fr;
	grid-auto-rows: 1fr;
	grid-template-columns: 3.75em 3.5fr 3.75em 1fr 1fr;
	gap: 0.25em;

	> div {
		font-size: 1.13em;
		font-weight: 500;
		font-family: ${theme.fontRobotoMedium};
		color: ${colorTheme.cart.textCartTableHead};
	}

	&.touchPad {
		grid-template-columns: 3.75em 3.5fr 3.75em 1fr 1fr;

		> div {
			font-size: 1.25em;
		}
	}

	&.dishCourse {
		grid-template-columns: 3.75em 3.75em 3.5fr 3.75em 1fr 1fr;
		padding: 0 1em 0.63em 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.touchPad {
			grid-template-columns: 1.63em 3fr 1fr 1fr 4.31em;
			gap: 0.5em;

			> div {
				font-size: 1.1em;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.touchPad {
			padding: 0.63em 1em 0.63em 0.63em;

			> div {
				font-size: 1.13em;
			}
		}

		&.dishCourse {
			grid-template-columns: 1.63em 1.63em 3fr 1fr 1fr 4.31em;
		}
	}
`

export const CartProducts = styled.div`
	overflow: auto;
	scroll-behavior: smooth;

	@media screen and (max-width: ${theme.point720}) {
		height: auto;

		&.tableCart {
			height: auto;
		}
	}
`
CartProducts.displayName = 'CartProducts'

export const CartTotal = styled.div`
	border-radius: 0.75em;
	box-shadow: 0 1.13em 6.81em 0 ${colorTheme.cart.cartTotal.shadow};
	background-color: ${colorTheme.cart.cartTotal.background};
	padding: 0 0.94em;
	color: ${colorTheme.cart.cartTotal.text};
	border: 1px solid ${colorTheme.cart.cartTotal.aroundBorder};

	@media screen and (max-width: ${theme.point720}) {
		margin: 0.63em 0;
		padding: 0 0.63em;
	}
`
CartTotal.displayName = 'CartTotal'

export const CartTotalInputs = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 1.88em;
	row-gap: 0.63em;

	> button {
		width: max-content;
	}

	> ${DeliveryCostWrap} {
		justify-self: end;
		width: max-content;
	}

	> ${DiscountLabel} {
		grid-column: 2;
		justify-self: end;
	}

	@media screen and (max-width: ${theme.point720}) {
		column-gap: 0.63em;

		> button {
			grid-column: 1 / 3;
			width: 100%;
		}
	}
`
CartTotalInputs.displayName = 'CartTotalInputs'

export const CartTotalPrice = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.1em 0;
	border-top: 2px solid ${colorTheme.cart.cartTotal.totalBorder};
	height: 3.13em;

	span {
		font-family: ${theme.fontRobotoBold};
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13rem;
	}
`
CartTotalPrice.displayName = 'CartTotalPrice'

export const CartTotalPriceText = styled.div`
	font-size: 1.25rem;
	font-family: ${theme.fontRobotoMedium};

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13rem;
	}
`
CartTotalPriceText.displayName = 'CartTotalPriceText'

export const CartTotalPriceValue = styled.div`
	font-size: 1.25rem;
	font-family: ${theme.fontRobotoBold};

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13rem;
	}
`
CartTotalPriceValue.displayName = 'CartTotalPriceValue'

export const CartTotalContent = styled.div`
	padding: 0.1em 0;
	display: flex;
	align-items: center;
	height: 3.13em;

	&.grid {
		display: grid;
		grid-template-columns: 2.5fr 1fr 1fr;
		align-items: center;
	}

	&.border {
		border-bottom: 1px solid ${colorTheme.cart.cartTotal.border};
	}

	&.border-dashed {
		border-bottom: 1px dashed ${colorTheme.cart.cartTotal.border};
	}
`
CartTotalContent.displayName = 'CartTotalContent'

export const CartTotalText = styled.div`
	font-size: 1.13em;
	font-family: ${theme.fontRobotoRegular};
	color: ${colorTheme.cart.cartTotal.text};

	&.active {
		text-decoration: underline;
		font-family: ${theme.fontRobotoBold};
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 0.88em;
	}
`
CartTotalText.displayName = 'CartTotalText'

export const CartTotalValue = styled.div`
	color: ${colorTheme.cart.cartTotal.text};
	font-size: 1.13em;
	font-family: ${theme.fontRobotoBold};
	color: ${colorTheme.cart.cartTotal.bold};
	font-weight: 500;
	justify-self: end;
`
CartTotalValue.displayName = 'CartTotalValue'

export const CartFooter = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.63em;
	padding: 0;
	margin-top: 0.63em;
	align-items: center;

	.type-info {
		max-width: 10em;
	}

	> div > button {
		width: 100%;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin: 0;
	}
`
CartFooter.displayName = 'CartFooter'

export const ErrorButtonWrap = styled.div`
	font-size: 1.13rem;
	font-weight: 500;
`
ErrorButtonWrap.displayName = 'ErrorButtonWrap'

export const InnerButtonWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 0.56em;

	> svg {
		width: 1.9em;
		height: 1.22em;
		fill: ${colorTheme.toggleButton.whiteColor};
	}

	&.nowrap {
		white-space: nowrap;
	}
`
InnerButtonWrap.displayName = 'InnerButtonWrap'

export const PaymentTitle = styled.div`
	font-size: 1.5em;
	color: ${colorTheme.cart.cartPayment.title};
`

export const PaymentTitleWrapper = styled.div`
	font-size: 1em;
	margin-top: -1.43em;
	margin-bottom: 1.875em;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.63em;
	flex-wrap: wrap;

	@media screen and (max-width: ${theme.point820}) {
		margin-top: 0;
		margin-bottom: 1.5em;

		&.with-gift-coupon {
			display: grid;
			grid-template-columns: repeat(2, auto);
			row-gap: 0;

			${PaymentTitle}:last-child {
				grid-column: 1 / 3;
			}
		}
	}
`

export const PaymentCost = styled.div`
	font-size: 1.875em;
	font-weight: 600;
	color: ${colorTheme.cart.cartPayment.total};
`

export const CartHeaderButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.63em;
	min-height: 3.44em;
	padding: 0.38em;
	border-radius: 0.88em;
	background-color: ${colorTheme.cashDesk.background};
	grid-column: 1 / 3;
	grid-row: 1;
`
CartHeaderButtonWrapper.displayName = 'CartHeaderButtonWrapper'
