import { INCREMENT_TIME_COUNTER } from '../../actionTypes/timeCounter'

const initialState = {
	counter: 0,
}

export function timerCounterReducer(state = initialState, action) {
	switch (action.type) {
		case INCREMENT_TIME_COUNTER:
			return {
				...state,
				counter: state.counter + 1
			}
		default:
			return state
	}
}
