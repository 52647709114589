import { RE_INITIALIZE_ORDER_FILTER, SET_ORDER_FILTER } from '../../actionTypes/orderfilter'
import { getDateParameterString, setWorkingHoursHelper } from '../../../helper'
import { addDays } from 'date-fns'

const initialState = {
	date: getDateParameterString(setWorkingHoursHelper(new Date())),
	paymentMethod: null,
	status: null,
	excludedStatus: null,
	orderType: null,
	roles: null,
	processedByRole: null,
	processedByUsername: null,
	processedByUsernameWithEmpty: '@',
	reverse: false,
	closed: null,
	withOnlyRegisteredPreOrders: null,
	datePreOrder: getDateParameterString(setWorkingHoursHelper(new Date())),
	datePreOrderDaysFrom: getDateParameterString(setWorkingHoursHelper(addDays(new Date(), 1))),
	datePreOrderDaysTo: getDateParameterString(setWorkingHoursHelper(addDays(new Date(), 7))),
	reversePreOrder: false,
	reversePreOrderDays: false,
	isPaid: null,
	deliveryPartnerType: null,
}

export function orderFilterReducer (state = initialState, action) {
	switch (action.type) {
		case RE_INITIALIZE_ORDER_FILTER:
			return initialState
		case SET_ORDER_FILTER:
			return {
				...state,
				...action.value,
			}
		default:
			return state
	}
}
