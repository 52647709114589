import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const WoltRestaurant = lazyWithRetry(() => import(/* webpackChunkName: "wolt-restaurant" */'../../component/WoltRestaurant'))

const WoltRestaurantPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<WoltRestaurant />
		</Suspense>
	)
})

WoltRestaurantPage.displayName = 'WoltRestaurantPage'
export default WoltRestaurantPage
