export const SET_TAKEAWAY_RESTAURANTS = 'SET_TAKEAWAY_RESTAURANTS'
export const SET_TAKEAWAY_RESTAURANTS_TOTAL_LENGTH = 'SET_TAKEAWAY_RESTAURANTS_TOTAL_LENGTH'
export const SET_TAKEAWAY_RESTAURANT = 'SET_TAKEAWAY_RESTAURANT'
export const APPEND_TAKEAWAY_RESTAURANTS = 'APPEND_TAKEAWAY_RESTAURANTS'
export const ADD_TAKEAWAY_TESTED_RESTAURANT = 'ADD_TAKEAWAY_TESTED_RESTAURANT'
export const SET_TAKEAWAY_SHOW_TEST_CLIENT_ERROR_MODAL = 'SET_TAKEAWAY_SHOW_TEST_CLIENT_ERROR_MODAL'
export const SET_TAKEAWAY_SHOW_TEST_SERVER_ERROR_MODAL = 'SET_TAKEAWAY_SHOW_TEST_SERVER_ERROR_MODAL'
export const SET_TAKEAWAY_TEST_ERROR_STATUS = 'SET_TAKEAWAY_TEST_ERROR_STATUS'
export const SET_TAKEAWAY_SHOW_CREATE_RESTAURANT_ERROR_MODAL = 'SET_TAKEAWAY_SHOW_CREATE_RESTAURANT_ERROR_MODAL'
export const SET_TAKEAWAY_RESTAURANT_IS_NOT_VALID = 'SET_TAKEAWAY_RESTAURANT_IS_NOT_VALID'
