import orderAdminInstance from '../adminInstance'

const updateRestaurantOrder = (orderId, data, accessToken) => {
  return orderAdminInstance.put('/orders/' + orderId, data, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
}

export default updateRestaurantOrder
