import axios from 'axios'
import { orderServiceUrl } from '../../../../urls'
import modalLoginRequestInterceptor from '../../modalLoginRequestInterceptor'
import modalLoginResponseInterceptor from '../../modalLoginResponseInterceptor'

const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
}

const orderModalLoginInstance = axios.create({
  baseURL: orderServiceUrl,
  headers,
})

orderModalLoginInstance.interceptors.request.use(config => {
  return modalLoginRequestInterceptor(config)
}, error => Promise.reject(error))

orderModalLoginInstance.interceptors.response.use(response => response, error => {
  return modalLoginResponseInterceptor(error)
})

export default orderModalLoginInstance
