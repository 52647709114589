import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { PinCodeInputWrapper, PinNumberInputField, PinNumberInputWrapper } from '../../inputs/PinCodeInput/index.styled'
import KeyBindings from '../../keybindings'

const PinCodeInputControlled = React.memo(({
	pinCodeArrayValue,
	inputRefs,
	currentFocusedIndex,
	focusInput,
	changeCodeAtFocus,
	onFocus,
	zIndex,
	inputType = 'text',
	password = false,
	size,
	error,
}) => {
	const isNeededZIndex = useSelector(selectIsNeededZIndex(zIndex))

	const handleChange = useCallback((event) => {
		const target = event.target
		const convertedValue = target.value.replace(/\D/g, '')
		if (convertedValue) {
			changeCodeAtFocus(convertedValue)
			focusInput(currentFocusedIndex + 1)
		}
	}, [currentFocusedIndex, changeCodeAtFocus, focusInput])

	const handleInputChange = useCallback((event) => {
		const nativeEvent = event.nativeEvent
		if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
			event.preventDefault()
			changeCodeAtFocus('')
			focusInput(currentFocusedIndex - 1)
		}
	}, [currentFocusedIndex, changeCodeAtFocus, focusInput])

	const handleArrowLeft = useCallback(() => {
		focusInput(currentFocusedIndex - 1)
	}, [currentFocusedIndex, focusInput])

	const handleArrowRight = useCallback(() => {
		focusInput(currentFocusedIndex + 1)
	}, [currentFocusedIndex, focusInput])

	const layout = (password ? 'password ': '') + (error ? 'error ' : '') + size + ' '

	return (
		<>
			<PinCodeInputWrapper className={layout}>
				{pinCodeArrayValue.map((number, index) =>
					<PinNumberInputWrapper key={`pin-number-${index}`} data-testid={`pin-label-${index + 1}`} className={layout + (currentFocusedIndex === index ? 'focus ' : '')}>
						<PinNumberInputField id={`pin-number-${index + 1}`}
							data-testid={`pin-number-${index + 1}`}
							value={String(number)}
							ref={(element) => element && (inputRefs.current[index] = element)}
							onChange={handleChange}
							onFocus={() => onFocus(index)}
							autoComplete="off"
							role="presentation"
							maxLength={1}
							inputMode="none"
							type={inputType}
							className={layout}
							onInput={handleInputChange} />
					</PinNumberInputWrapper>
				)}
			</PinCodeInputWrapper>

			{isNeededZIndex &&
				<KeyBindings arrowRight={handleArrowRight} arrowLeft={handleArrowLeft} />}
		</>
	)
})

PinCodeInputControlled.displayName = 'PinCodeInputControlled'
export default PinCodeInputControlled
