import styled from 'styled-components'
import { TableTdGrid } from '../../style/table/td/index.styled'

export const PreOrderWrapper = styled(TableTdGrid)`
	row-gap: 0;

	> svg {
		grid-row: 1 / 3;
	}

	> div {
		font-size: 0.8em;

		&:nth-child(2) {
			grid-column: 2;
			grid-row: 1;
		}

		&:nth-child(3) {
			grid-column: 2;
			grid-row: 2;
		}
	}
`
PreOrderWrapper.displayName = 'PreOrderWrapper'
