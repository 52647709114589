import React, { useMemo, useState } from 'react'
import { OrderRestaurantMeta } from '../index.styled'
import MainIcon from '../../../../img/svg/main'
import { statusOptionsStatic } from '../../../../redux/reducer/order/constants'
import OrderPayment from '../../../Elements/table/TableTdElements/OrderPayment'
import { getNeededDateFormat } from '../../../../helper'
import { fetchRestaurantOrdersThunk } from '../../../../redux/thunk/restaurant/orders'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ModalOrder from '../modalOrder'
import OrderDayCounter from '../../../Elements/table/TableTdElements/OrderDayCounter'
import CustomTable from '../../../Elements/table'
import TableTr from '../../../Elements/table/tr'
import { TableTd, TableTdGrid, TableTdStatus } from '../../../Elements/table/style/table/td/index.styled'
import { TableOrderMobileTitle, TextBig } from '../../../Elements/table/OrdersTable/index.styled'

const OrdersTable = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const orders = useSelector(state => state.restaurant.orders)
	const ordersTotal = useSelector(state => state.restaurant.ordersTotal)
	const [activeRow, setActiveRow] = useState(0)
	const [selectedOrder, setSelectedOrder] = useState(null)

	const tableHeadersStatic = useMemo(() => [
		{ title: t('order.table.nr') },
		{ title: t('order.table.nr_table') },
		{ title: t('order.table.service') },
		{ title: t('order.table.status') },
		{ title: t('order.table.sum') },
		{ title: t('order.table.time') }
	], [t])

	const onLoad = (page, size) => {
		dispatch(fetchRestaurantOrdersThunk(page, size))
	}

	const selectOrder = (item, i) => {
		if (item?.status?.status !== 'CANCELED') {
			setActiveRow(i)
			setSelectedOrder(item)
		}
	}
	return (
		<>
			<CustomTable headers={tableHeadersStatic}
									 onLoad={onLoad}
									 currentLength={orders?.length}
									 totalLength={ordersTotal}
									 perLoad={20}>
				{orders?.map((item, i) =>
					<TableTr key={i}
									 isActive={i === activeRow}
									 onClick={() => selectOrder(item, i)}
									 className="row-tablet-cols-4 bold-border">
						<TableTd>
							<TableOrderMobileTitle>{tableHeadersStatic[0].title}</TableOrderMobileTitle>
							<OrderDayCounter item={item} />
						</TableTd>
						<TableTd className="col-tablet-2-4 col-mob-2-5">
							<TableOrderMobileTitle>{tableHeadersStatic[1].title}</TableOrderMobileTitle>
							<OrderRestaurantMeta color={item.restaurantMetaData?.area.color}>
								<div className="color" />
								<div className="meta">
									<div className="area">{item.restaurantMetaData?.area.name}; Tisch {item.restaurantMetaData?.table.number}</div>
								</div>
							</OrderRestaurantMeta>
						</TableTd>
						<TableTd className="col-mob-1-3" style={{ wordBreak: 'break-all' }}>
							<TableOrderMobileTitle>{tableHeadersStatic[2].title}</TableOrderMobileTitle>
							<TableTdGrid>
								<MainIcon icon="user" />
								{item.processedBy}
							</TableTdGrid>
						</TableTd>
						<TableTdStatus className={`const-color ${item?.status?.status} col-mob-3-5`}>
							<TableOrderMobileTitle>{tableHeadersStatic[3].title}</TableOrderMobileTitle>
							<TableTdGrid>
								<MainIcon icon={item?.status?.status} />
								<TextBig>{t(statusOptionsStatic.filter(status => status.value === item.status?.status)[0]?.label)}</TextBig>
							</TableTdGrid>
						</TableTdStatus>
						<TableTd className="col-mob-1-3">
							<TableOrderMobileTitle>{tableHeadersStatic[4].title}</TableOrderMobileTitle>
							<OrderPayment item={item} className="small" />
						</TableTd>
						<TableTd className="col-mob-3-5">
							<TableOrderMobileTitle>{tableHeadersStatic[5].title}</TableOrderMobileTitle>
							<TableTdGrid>
								<MainIcon icon="time" />
								{getNeededDateFormat(item.orderDate, 'HH:mm')}
							</TableTdGrid>
						</TableTd>
					</TableTr>)}
			</CustomTable>
			<ModalOrder {...{ selectedOrder, setSelectedOrder }} />
		</>
	)
})

OrdersTable.displayName = 'OrdersTable'
export default OrdersTable
