import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const ProgramSettings = lazyWithRetry(() => import(/* webpackChunkName: "program" */'../../component/Settings/Program'))

const ProgramSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<ProgramSettings />
		</Suspense>
	)
})

ProgramSettingsPage.displayName = 'ProgramSettingsPage'
export default ProgramSettingsPage
