import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const MarketingSettings = lazyWithRetry(() => import(/* webpackChunkName: "marketing-settings" */'../../component/Settings/MarketingSettings'))

const MarketingSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<MarketingSettings />
		</Suspense>
	)
})

MarketingSettingsPage.displayName = 'MarketingSettingsPage'
export default MarketingSettingsPage
