import React from 'react'
import { useTranslation } from 'react-i18next'
import ToggleButton from '../../Elements/buttons/ToggleButton'
import { useLocalStorage } from '../../../hooks/useLocalStorage'

const ToggleTheme = React.memo(() => {
	const { t } = useTranslation()
	const [colorTheme, setColorTheme] = useLocalStorage('colorTheme', 'LIGHT')

	const toggleItems = [
		{ icon: 'sun', text: t('category.form.colorPickerModal.theme.light') },
		{ icon: 'moon', text: t('category.form.colorPickerModal.theme.dark') }
	]

	const toggleThemeChange = (toggleIndex) => {
		setColorTheme(toggleIndex === 1 ? 'DARK' : 'LIGHT')
	}

	return (
		<ToggleButton toggleIndex={colorTheme === 'DARK' ? 1 : 0} setToggleIndex={toggleThemeChange}
			items={toggleItems} color="gray" testId="toggle-theme" size="medium" />
	)
})

ToggleTheme.displayName = 'ToggleTheme'
export default ToggleTheme
