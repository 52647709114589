import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../Elements/buttons/Button'
import CustomModal from '../../../Elements/modal/CustomModal'
import { getTimeMeter } from './helper'
import { TimeButton, TimeButtonsBlock } from './index.styled'

const EVENT_Z_INDEX = 43
const TIME_BUTTONS_IN_MINUTES = [30, 60, 120, 180]

const DisableOrderTypePeriodModal = React.memo(({ isOpen, close, orderTypeToDisable, disableOrderTypePeriod }) => {
	const { t } = useTranslation()
	const [activeButtonIndex, setActiveButtonIndex] = useState(null)

	useEffect(() => {
		if (isOpen) {
			setActiveButtonIndex(1)
		}
	}, [isOpen])

	const handleSelectTime = useCallback((i) => {
		setActiveButtonIndex(i)
	}, [])

	const handleSaveDisabledPeriod = useCallback(() => {
		disableOrderTypePeriod(TIME_BUTTONS_IN_MINUTES[activeButtonIndex])
		close()
	}, [activeButtonIndex, disableOrderTypePeriod, close])

	return (
		<CustomModal isOpen={isOpen}
			size="auto"
			id="disable-order-type-period-modal"
			zIndex={EVENT_Z_INDEX}
			close={close}
			title={t(`WebshopInfo.${orderTypeToDisable}`) + ' ' + t('WebshopInfo.modal.disable_order_type_period.disabled')}
			disableBackButton={true}
			button={
				<>
					<Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')}
						keyButton="F3" zIndex={EVENT_Z_INDEX}
						onClick={close}
						className="back" />
					<Button name="save" color="green" icon="save" text={t('buttons.controls.save')}
						keyButton="F2" zIndex={EVENT_Z_INDEX}
						onClick={handleSaveDisabledPeriod} />
				</>
			}>
			<TimeButtonsBlock>
				{TIME_BUTTONS_IN_MINUTES.map((minutes, index, arr) =>
					<TimeButton key={index}
						className={index === activeButtonIndex && 'active'}
						onClick={() => handleSelectTime(index)}>
						{minutes < 60 ? minutes : minutes / 60}
						<br />
						{getTimeMeter(arr, index, t)}
					</TimeButton>)}
			</TimeButtonsBlock>
		</CustomModal>

	)
})

DisableOrderTypePeriodModal.displayName = 'DisableOrderTypePeriodModal'
export default DisableOrderTypePeriodModal
