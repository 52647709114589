export const SET_INVOICES = 'SET_INVOICES'
export const SET_INVOICES_TOTAL_LENGTH = 'SET_INVOICES_TOTAL_LENGTH'
export const APPEND_INVOICES = 'APPEND_INVOICES'
export const SET_UNPAID_INVOICES_COUNTER = 'SET_UNPAID_INVOICES_COUNTER'
export const SET_SELECTED_INVOICE = 'SET_SELECTED_INVOICE'
export const SET_SHOW_INVOICE_OPTIONS_MODAL = 'SET_SHOW_INVOICE_OPTIONS_MODAL'
export const SET_SHOW_INVOICE_DATA_MODAL = 'SET_SHOW_INVOICE_DATA_MODAL'
export const SET_SHOW_INVOICE_BILLING_ADDRESS_MODAL = 'SET_SHOW_INVOICE_BILLING_ADDRESS_MODAL'
export const SET_SHOW_INVOICE_RESEND_EMAIL_INFO_MODAL = 'SET_SHOW_INVOICE_RESEND_EMAIL_INFO_MODAL'
export const SET_SHOW_INVOICE_RESEND_EMAIL_MODAL = 'SET_SHOW_INVOICE_RESEND_EMAIL_MODAL'
