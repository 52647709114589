import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const PartnerPortal = lazyWithRetry(() => import(/* webpackChunkName: "partnerPortal" */'../../component/PartnerPortal'))

const PartnerPortalPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<PartnerPortal />
		</Suspense>
	)
})

PartnerPortalPage.displayName = 'PartnerPortalPage'
export default PartnerPortalPage