import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import getShopConfig from '../../axios/shop/get'
import { setWebshopImageErrorModal, setWebshopInfoConfig, setWebshopLogoCloudinaryData, setWebshopLogoConfig, updateWebshopOrderTypeWorkingStatuses } from '../../action/webshopinfo'
import updateShopConfig from '../../axios/shop/put'
import getShopLogoConfig from '../../axios/shop/logo/get'
import storeImageToCloudinary from '../../axios/photoUpload/post'
import updateShopOrderTypeWorkingStatus from '../../axios/shop/orderTypeWorkingStatus/put'

export const getShopConfigThunk = () => dispatch => {
	return getShopConfig()
		.then((res) => {
			dispatch(setWebshopInfoConfig(res.data))
		}).catch((error) => {
			// dispatch(setAppErrors(retrieveErrorFromApi(error))) todo: enable when shop will be live for all
		})
}

export const updateShopConfigThunk = (configShop) => dispatch => {
	dispatch(setWebshopInfoConfig(configShop))
	return updateShopConfig(configShop).then((res) => {
		dispatch(setWebshopInfoConfig(res.data))
	}).catch((error) => {
		dispatch(getShopConfigThunk())
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const getShopLogoConfigThunk = () => dispatch => {
	return getShopLogoConfig()
		.then((res) => {
			dispatch(setWebshopLogoConfig(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const storeLogoToCloudinaryThunk = (cloudname, formData) => dispatch => {
	return storeImageToCloudinary(cloudname, formData).then((res) => {
		dispatch(setWebshopLogoCloudinaryData(res.data))
	}).catch((error) => {
		if (error.response.status === 420)
			dispatch(setWebshopImageErrorModal(true))
		else dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}

export const updateShopOrderTypeWorkingStatusThunk = (orderType, data) => dispatch => {
	return updateShopOrderTypeWorkingStatus(orderType, data).then((res) => {
		dispatch(updateWebshopOrderTypeWorkingStatuses(res.data))
	}).catch((error) => {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	})
}
