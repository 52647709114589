import React from 'react'
import { useSelector } from 'react-redux'
import { selectRemainingMinutes } from '../../../../../redux/selector/timerCounter'
import { useTranslation } from 'react-i18next'
import { DriverTime } from './index.styled'

const DriverReturnTime = React.memo(({ duration = 18 * 60, modifiedDate, takenNewOrder }) => {
	const { t } = useTranslation()
	const remainingMinutes = useSelector(selectRemainingMinutes(duration, modifiedDate))
	if (typeof remainingMinutes !== 'number') return null

	return <DriverTime className="driver-time-text">{t('order.in')}{remainingMinutes}{t('order.timer_back_driver')}</DriverTime>
})

DriverReturnTime.displayName = 'DriverReturnTime'
export default DriverReturnTime
