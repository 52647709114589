import React from 'react'
import { ReactComponent as LieferandoSvg } from './assets/lieferando.svg'
import { ReactComponent as UberEatsSvg } from './assets/uber-eats.svg'
import { ReactComponent as WoltSvg } from './assets/wolt.svg'
import { ReactComponent as OrderSmartSvg } from './assets/order-smart.svg'
import { ReactComponent as FoodoraSvg } from './assets/foodora.svg'
import { ReactComponent as JustEatSvg } from './assets/just-eat.svg'

const SettingsPlatformIcon = React.memo(({ icon, height = 20, width = 20, fill, stroke }) => {
	const svgProps = { height, width, fill, stroke, className: icon }

	return (
		<>
			{icon === 'ubereat' && <UberEatsSvg {...svgProps} />}
			{icon === 'wolt' && <WoltSvg {...svgProps} />}
			{icon === 'ordersmart' && <OrderSmartSvg {...svgProps} />}
			{icon === 'foodora' && <FoodoraSvg {...svgProps} />}
			{icon === 'lieferando' && <LieferandoSvg {...svgProps} />}
			{icon === 'just-eat' && <JustEatSvg {...svgProps} />}
		</>
	)
})

SettingsPlatformIcon.displayName = 'SettingsPlatformIcon'
export default SettingsPlatformIcon
