import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { ModalButtonWrap } from '../../modal/ButtonsModal/index.styled'

export const ModalTerminalButtonTitle = styled.div`
	font-weight: 700;
`

export const ModalTerminalButtonSubTitle = styled.div`
	font-size: 0.73em;
	font-weight: 700;

	> span {
		font-weight: 500;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 0.89em;
	}
`

export const ModalTerminalWrapper = styled.div`
	${ModalButtonWrap} {
		min-height: 3.4em;

		@media screen and (max-width: ${theme.point820}) {
			font-size: 1.12em;
			min-height: 4.17em;
			margin-bottom: 0.56em;
		}
	}
`
