import axios from 'axios'
import requestInterceptor from '../../requestInterceptor'
import responseInterceptor from '../../responseInterceptor'
import { imageServiceUrl } from '../../../../urls'
import { getLocalStorageAccessToken } from '../../../../helper/clientMonitor'

const headers = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest',
}

const accessToken = getLocalStorageAccessToken()

if (accessToken) {
	headers.Authorization = `Bearer ${accessToken}`
}

const imageInstance = axios.create({
	baseURL: imageServiceUrl,
	headers,
})

imageInstance.interceptors.request.use(requestInterceptor, error => Promise.reject(error))

imageInstance.interceptors.response.use(response => response, error => {
	return responseInterceptor(error)
})

export default imageInstance
