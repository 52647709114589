import React from 'react'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import Currency from '../../../currency'
import { CartProductDishCourseWrap, CartProductWrap, Product, ProductNr } from '../CartProduct/index.styled'
import { MinusButtonWrap, ProductCode, ProductCouponCol, ProductCouponName, ProductCouponTotal } from './index.styled'
import { MinusButton, PlusButton } from '../PlusMinus/index.styled'
import { encryptGiftCode } from '../../../../../helper/encryptGiftCode'

const CartGiftCoupon = React.memo(({ coupon, onClickMinus, AddButton, onClickPlus, enabledDishCourse }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const { name, price, couponMetaData: { code: couponCode } } = coupon
	const layout = (touchPad ? 'touchPad ' : '')
	const code = AddButton ? encryptGiftCode(couponCode) : couponCode
	const isCanceledItem = coupon?.status === 'CANCELED'
	const layoutDishCourseWrap = (enabledDishCourse ? 'col-2' : '')

	return (
		<CartProductDishCourseWrap  className={layoutDishCourseWrap}>
			{enabledDishCourse && <div />}
			<CartProductWrap className={`${layout} ${isCanceledItem ? ' strikethrough' : ''}`}>
				<Product className={layout}>
					<ProductNr className={layout}>1</ProductNr>
					<ProductCouponName className={layout}>
						{name}
						<ProductCode>
							{code}
						</ProductCode>
					</ProductCouponName>
					<ProductCouponCol className={layout} />
					<ProductCouponTotal className={layout}>
						<Currency>{price}</Currency>
					</ProductCouponTotal>
					{!isCanceledItem && <MinusButtonWrap>
						{AddButton ?
							<PlusButton className="bgdColor" onClick={onClickPlus}>
								<AddButton />
							</PlusButton> :
							<MinusButton className={'minus ' + layout} onClick={onClickMinus} data-testid="remove-gift-coupon">
								<svg xmlns="http://www.w3.org/2000/svg" width="22" height="4" viewBox="0 0 10.781 1.844">
									<path d="M486.363,202.33h-8.948a0.915,0.915,0,1,1,0-1.83h8.948A0.915,0.915,0,1,1,486.363,202.33Z" transform="translate(-476.5 -200.5)" />
								</svg>
							</MinusButton>}
					</MinusButtonWrap>}
				</Product>
			</CartProductWrap>
		</CartProductDishCourseWrap>

	)
})

CartGiftCoupon.displayName = 'CartGiftCoupon'
export default CartGiftCoupon
