import { ARROW_LEFT, ARROW_RIGHT } from '../constants'

export const getSplittedAllOrderItems = (orders) => {
	return orders.reduce((res, order) => {
		if (order.id) {
			const notCanceledItems = order.items.filter(item => item.status !== 'CANCELED')
			const existItemsArray = notCanceledItems.map((item) => ({ orderId: order.id, item }))
			res.push(...existItemsArray)
		} else {
			const newItemsArray = order.items.map((item) => ({ item: { ...item, status: 'PLACED' } }))
			res.push(...newItemsArray)
		}
		return res
	}, [])
}

export const getSplittedPlacedOrderItems = (orders) => {
	return orders.reduce((res, order) => {
		if (order.id) {
			const placedItems = order.items.filter(item => item.status === 'PLACED')
			const existItemsArray = placedItems.map((item) => ({ orderId: order.id, item }))
			res.push(...existItemsArray)
		} else {
			const newItemsArray = order.items.map((item) => ({ item: { ...item, status: 'PLACED' } }))
			res.push(...newItemsArray)
		}
		return res
	}, [])
}

export const sortOrdersByDishCourseIndex = (a, b) => {
	if (a.item?.dishCourse?.index === 0) return 1
	if (b.item?.dishCourse?.index === 0) return -1
	return a.item?.dishCourse?.index - b.item?.dishCourse?.index
}

export const getSplittedPreparedOrderItems = (orders) => {
	return orders.reduce((res, order) => {
		if (order.id) {
			const beingPreparedItems = order.items.filter(item => ['BEING_PREPARED', 'PREPARED'].includes(item.status))
			const existItemsArray = beingPreparedItems.map((item) => ({ orderId: order.id, item }))
			res.push(...existItemsArray)
		}
		return res
	}, [])
}

export const getMoveToKitchenOrderItems = (orderItems) => {
	return orderItems.reduce((res, order) => {
		if (order.orderId && order.item.status === 'PLACED') {
			const existingOrder = res.find(resOrder => resOrder.orderId === order.orderId)
			if (existingOrder) {
				existingOrder.items.push({ itemPositionId: order.item.itemPositionId })
			} else {
				res.push({ orderId: order.orderId, items: [{ itemPositionId: order.item.itemPositionId }] })
			}
		}
		return res
	}, [])
}

export const isDisableMoveToKitchen = (orders) => {
	return !orders.some(order => order.item.status === 'PLACED')
}

export const getItemDirection = (item, placedKitchenItems) => {
	const isPlacedKitchenItem = placedKitchenItems.find(plItem => plItem.item.itemPositionId === item.item.itemPositionId)
	return isPlacedKitchenItem ? ARROW_LEFT : ARROW_RIGHT
}

export const updateKitchenItems = (items, index, newItem) => {
	const itemsCopy = [...items]
	itemsCopy.splice(index, 1, newItem)
	return itemsCopy
}
