import { SET_AUTOCONFIRMATION_ORDER, SET_AUTOCONFIRMATION_ERROR } from '../../actionTypes/acceptOrder'

const initialState = {
  autoConfirmation: {},
  autoConfirmationError: false
}


export function autoConfirmationReducer (state = initialState, action) {
  switch (action.type) {
    case SET_AUTOCONFIRMATION_ORDER:
      return {...state, autoConfirmation: action.data}
    case SET_AUTOCONFIRMATION_ERROR:
      return {...state, autoConfirmationError: action.data}
    default:
      return state
  }
}


