import { setSocketVoipDesktopSessions } from '../../redux/action/socket'
import { toast } from 'react-toastify'
import { setPhoneTestSent, setPhoneTestSuccess, updatePhoneStatus } from '../../redux/action/phone'

export const processPhoneMessageThunk = (message) => (dispatch, getState) => {
  switch (message?.type) {
    case 'phone.DESKTOP_SESSION_LIST_PROVIDED_EVENT':
      dispatch(setSocketVoipDesktopSessions(message.data))
      break
    case 'phone.VOIP_CONNECTION_RECEIVED_EVENT':
      if (getState().phoneStore.testSent && message.data?.status === 'CONNECTED') {
        toast.success('Voip connection success')
        dispatch(setPhoneTestSuccess(true))
      } else if (message.data?.status === 'DISCONNECTED') {
        toast.error(message.data?.message)
        dispatch(setPhoneTestSent(false))
        dispatch(setPhoneTestSuccess(false))
      }
      break
    case 'phone.VOIP_SETTING_UPDATED_EVENT':
      dispatch(updatePhoneStatus(message.data))
      break
    default:
      // eslint-disable-next-line
      console.error('Unknown phone message type: ', JSON.stringify(message))
      break
  }
}
