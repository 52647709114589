import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Discounts = lazyWithRetry(() => import(/* webpackChunkName: "discounts" */'../../component/Settings/Discounts'))

const DiscountsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Discounts />
		</Suspense>
	)
})

DiscountsPage.displayName = 'DiscountsPage'
export default DiscountsPage
