import { setAppErrors } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'
import searchCustomers from '../../axios/customer/get'
import updateCustomer from '../../axios/customer/put'
import deleteCustomers from '../../axios/customer/delete'
import { setAutocompleteGeo, setCustomer, setCustomerAddressIsNotFull, setCustomerCalls, setCustomerGeoData, setCustomerGeoDataLoading, setCustomersByCompanyName, setCustomersByEmail, setCustomersByName, setCustomersByNumber, setCustomersByPhone } from '../../action/customer'
import storeCustomer from '../../axios/customer/post'
import distanceGet from '../../axios/customer/distance/post'
import getCustomerCall from '../../axios/customer/customerCall'
import deliveryDistanceGet from '../../axios/customer/distance/delivery/post'
import getCustomer from '../../axios/customer'

export const storeCustomerThunk = (customer) => async (dispatch) => {
	try {
		const res = await storeCustomer(customer)
		const customerNumber = res.data.remoteResponse.customerNumber
		dispatch(setCustomer({ ...customer, customerNumber }))
		return customerNumber
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const updateCustomerThunk = (customer) => async (dispatch) => {
	try {
		await updateCustomer(customer, customer.customerNumber)
		dispatch(setCustomer(customer))
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const deleteCustomersThunk = (callbackSuccess = () => {
}, callbackError = () => {
}) => dispatch => {
	return deleteCustomers()
		.then(res => {
			callbackSuccess()
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			callbackError()
		})
}

export const distanceCustomer = (data = '') => dispatch => {
	return distanceGet(data)
		.then(res => {
			if (res.data) {
				dispatch(setCustomerGeoData(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			dispatch(setCustomerGeoData(null))
			dispatch(setAutocompleteGeo(true))
		})
}

export const deliveryDistanceCustomer = (data = '') => dispatch => {
	return deliveryDistanceGet(data)
		.then(res => {
			if (res.data) {
				dispatch(setCustomerGeoData(res.data))
				dispatch(setCustomerGeoDataLoading(false))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			dispatch(setCustomerGeoData(null))
			dispatch(setAutocompleteGeo(true))
			dispatch(setCustomerGeoDataLoading(false))
		})
}

export const searchCustomersByPhoneThunk = (phoneNumber = '') => dispatch => {
	return searchCustomers(phoneNumber, '', '')
		.then(res => {
			if (res.data) {
				dispatch(setCustomersByPhone(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const searchCustomersByNumberThunk = (customerNumber = '') => dispatch => {
	return searchCustomers('', customerNumber, '')
		.then(res => {
			if (res.data) {
				dispatch(setCustomersByNumber(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const searchCustomersByNameThunk = (name = '') => dispatch => {
	return searchCustomers('', '', name)
		.then(res => {
			if (res.data) {
				dispatch(setCustomersByName(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
export const searchCustomersByEmailThunk = (email = '') => dispatch => {
	return searchCustomers('', '', '', email)
		.then(res => {
			if (res.data) {
				dispatch(setCustomersByEmail(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
export const searchCustomersByCompanyNameThunk = (companyName = '') => dispatch => {
	return searchCustomers('', '', '', '', companyName)
		.then(res => {
			if (res.data) {
				dispatch(setCustomersByCompanyName(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const searchResetThunk = () => {
	return (dispatch) => {
		dispatch(setCustomersByNumber(null))
		dispatch(setCustomersByPhone(null))
		dispatch(setCustomersByName(null))
		dispatch(setCustomersByEmail(null))
		dispatch(setCustomersByCompanyName(null))
	}
}

export const getCustomerCallThunk = (page = 0, size = 10, customerPhone = null) => dispatch => {
	return getCustomerCall(page, size, customerPhone)
		.then(res => {
			if (res.data) {
				dispatch(setCustomerCalls(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCustomerThunk = (customerNumber) => dispatch => {
	return getCustomer(customerNumber)
		.then(res => {
			dispatch(setCustomer(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const resetCustomerThunk = () => (dispatch) => {
	dispatch(setCustomer(null))
	dispatch(setCustomerAddressIsNotFull(false))
	dispatch(setCustomerGeoDataLoading(false))
}
