import React from 'react'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import ButtonIcon from '../../../../../img/svg/button'
import { ActionButton, ActionButtonsWrapper } from '../../../table/ActionButtons/index.styled'
import { CartProductWrap, GiftCouponCode, GiftCouponName, Product, ProductCol, ProductNr, ProductTotal } from '../CartProduct/index.styled'
import { encryptGiftCode } from '../../../../../helper/encryptGiftCode'
import { useTranslation } from 'react-i18next'

const CartGiftCouponPayment = React.memo(({ coupon, active, handleRemoveCoupon }) => {
	const { t } = useTranslation()
	const [touchPad] = useLocalStorage('touchPad', false)
	const code = encryptGiftCode(coupon.code)
	const layout = (touchPad ? 'touchPad ' : '') + (active ? 'active' : '')
	const deleteButtonLayout = (touchPad ? 'touchPad-cart ' : 'cart')

	return (
		<CartProductWrap className={layout}>
			<Product className={layout}>
				<ProductNr className={layout} />
				<GiftCouponName className={layout}>
					<div>{t('Cart.gift_coupon')}</div>
					<GiftCouponCode>{code}</GiftCouponCode>
				</GiftCouponName>
				<ProductCol className={layout} />
				<ProductTotal className={layout} />
				<ActionButtonsWrapper>
					<ActionButton onClick={handleRemoveCoupon} data-testid="delete-gift-coupon" className={deleteButtonLayout}>
						<ButtonIcon icon="minus" fill="#fff"/>
					</ActionButton>
				</ActionButtonsWrapper>
			</Product>
		</CartProductWrap>
	)
})

CartGiftCouponPayment.displayName = 'CartGiftCouponPayment'
export default CartGiftCouponPayment
