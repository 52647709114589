import { setClientMonitorCartState, setClientMonitorQrCodePaymentSession, showClientMonitorFinalStep } from '../../redux/action/clientMonitorCart'
import { setClientMonitorConfig } from '../../redux/action/clientMonitorConfig'

export const processCashDeskMessageThunk = (message) => (dispatch) => {
	switch (message?.type) {
		case 'cashdesk.CLIENT_MONITOR_ACTIVATED_EVENT':
			//dispatch(startClientMonitorThunk())
			break
		case 'cashdesk.CLIENT_MONITOR_DEACTIVATED_EVENT':
			//dispatch(stopClientMonitorThunk())
			break
		case 'cashdesk.CART_STATE_CHANGED_EVENT':
			updateClientMonitorCartStateThunk(message.data, dispatch)
			break
		case 'cashdesk.CART_STATE_DELETED_EVENT':
			deleteClientMonitorCartStateThunk(message.data, dispatch)
			break
		case 'cashdesk.CLIENT_MONITOR_QR_CODE_LINK_SENT_EVENT':
			setClientMonitorQrCodeLinkThunk(message.data, dispatch)
			break
		case 'cashdesk.CLIENT_MONITOR_CONFIG_CHANGED_EVENT':
			setClientMonitorConfigThunk(message.data, dispatch)
			break
		default:
			// eslint-disable-next-line
			console.error('Unknown cashdesk message type: ', JSON.stringify(message))
			break
	}
}

export const updateClientMonitorCartStateThunk = (data, dispatch) => {
	dispatch(setClientMonitorCartState(data.cartState))
}

const deleteClientMonitorCartStateThunk = (data, dispatch) => {
	if (!Object.keys(data).length) {
		dispatch(showClientMonitorFinalStep(true))
		dispatch(setClientMonitorCartState(null))
		dispatch(setClientMonitorQrCodePaymentSession(null))
	}
}

const setClientMonitorQrCodeLinkThunk = (data, dispatch) => {
	const link = data?.qrCode?.qrCodeLink || null
	dispatch(setClientMonitorQrCodePaymentSession({ link }))
}

const setClientMonitorConfigThunk = (data, dispatch) => {
	dispatch(setClientMonitorConfig(data.monitorConfig))
}
