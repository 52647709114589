import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import { appendPhones, setPhone, setPhones, setPhoneTestSent, setPhoneTestSuccess, setPhoneTotaLength } from '../../action/phone'
import { batch } from 'react-redux'
import { retrieveErrorFromApi } from '../../../helper'
import storePhone from '../../axios/phone/store'
import { PER_LOAD } from '../constants'
import fetchPhones from '../../axios/phone'
import deletePhone from '../../axios/phone/delete'
import updatePhone from '../../axios/phone/put'
import testPhone from '../../axios/phone/testPhone/post'
import getDesktopSessions from '../../axios/phone/getDesktopSessions/_index'
import { toast } from 'react-toastify'
import { setSocketVoipDesktopSessions } from '../../action/socket'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'

export const testPhoneThunk = (data) => {
	return dispatch => {
		batch(() => {
			dispatch(testPhone(data,
				(res) => {
					dispatch(setPhoneTestSent(true))
					toast.info('Sent phone test')
				},
				(error) => {
					dispatch(setPhoneTestSent(false))
					dispatch(setAppErrors(retrieveErrorFromApi(error)))
				},
			))
		})
	}
}

export const resetPhoneThunk = () => (dispatch) => {
	batch(() => {
		dispatch(setPhone(null))
		dispatch(setPhoneTestSent(false))
		dispatch(setPhoneTestSuccess(false))
	})
}

export const getPhonesThunk = (page = 0, size = PER_LOAD) => (dispatch) => {
	return fetchPhones(page, size)
		.then((res) => {
			if (res.data) {
				if (res.headers['x-voip-settings-total']) {
					//check size & page make sure we are retrieving all phones
					dispatch(setPhoneTotaLength(parseInt(res.headers['x-voip-settings-total'])))
				}

				if (page > 0 && size > 0) { //make sure we're querying pagination
					dispatch(appendPhones(res.data))
					return
				}
				dispatch(setPhones(res.data))
			}
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deletePhoneThunk = (data) => {
	return (dispatch) => {
		batch(() => {
			dispatch(deletePhone(data,
				(res) => {
					dispatch(getPhonesThunk())
				},
				(error) => {
					dispatch(setAppErrors(retrieveErrorFromApi(error)))
				},
			))
		})
	}
}

export const updatePhoneThunk = (data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))
	return updatePhone(data)
		.then((res) => {
			dispatch(succeedSystemFormButtonSaveThunk())
			dispatch(resetPhoneThunk())
			return dispatch(getPhonesThunk())
		}).catch((error) => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(resetPhoneThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const storePhoneThunk = (data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))
	return storePhone(data)
		.then((res) => {
			dispatch(succeedSystemFormButtonSaveThunk())
			dispatch(resetPhoneThunk())
			return dispatch(getPhonesThunk())
		}).catch((error) => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(resetPhoneThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getVoipDesktopSessionsThunk = () => {
	return dispatch => {
		dispatch(getDesktopSessions(response => {
			dispatch(setSocketVoipDesktopSessions(response.data))
		}, error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}))
	}
}


