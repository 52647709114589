import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const SettingsTitleWrap = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	gap: 1.2em;
	margin-bottom: 1.63em;
	min-height: 3.63em;

	.title {
		font-size: 1.5em;
		line-height: 1.17em;
		font-family: ${theme.fontRobotoBold};
		color: ${colorTheme.settingsTitle.title};
	}

	.image {
		width: 3.38em;
		height: 3.38em;
		flex-shrink: 0;
	}

	svg {
		fill: ${colorTheme.settingsTitle.icon};
	}

	.lieferando {
    	width: 10.37em;
    	height: 2.25em;
  	}

	.just-eat {
		width: 9.37em;
    	height: 2.25em;
	}

  	.ordersmart {
    	width: 13.56em;
    	height: 1.63em;
  	}

  	.ubereat {
    	width: 9.37em;
    	height: 1.5em;
  	}

  	.wolt {
    	width: 6.87em;
    	height: 2.5em;
  	}

  	.foodora {
    	width: 11em;
    	height: 2em;
  	}

	&.medium {
		.image {
			width: 2.88em;
			height: 2.88em;
		}
	}

	${props => props.marginBottom && `
		margin-bottom: ${props.marginBottom}em;
	`};

	@media screen and (max-width: ${theme.point820}) {
		justify-content: center;

		.title {
			font-size: 1.71em;
		}

		.image {
			width: 3.86em;
			height: 3.86em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 2.31em;

		${props => props.marginBottom && `
    	margin-bottom: ${props.marginBottom}em;
  	`};

		.title {
			font-size: 1.5em;
		}

		.image {
			width: 3.38em;
			height: 3.38em;
		}

		.lieferando, .just-eat {
      		width: 8.37em;
    	}
		
    	.ordersmart {
     		width: 8.56em;
    	}

    	.ubereat {
      		width: 7.37em;
    	}

    	.wolt {
      		width: 5.87em;
      		height: 2em;
    	}

    	.foodora {
      		width: 8em;
    	}
	}
`
