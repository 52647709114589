import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { theme } from '../../../../../style/theme'

export const RestTableWhite = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
  	text-align: center;
  	position: relative;
  	height: ${props => props.height.value}em;
  	width: ${props => props.width.value}em;
  	background-color: ${colorTheme.restaurant.table.backgroundWhite};
  	border-radius: ${props => props.formType === 'CIRCLE' || props.formType === 'OVAL' ? '50%' : '0.8em'};
	cursor: pointer;
`
RestTableWhite.displayName = 'RestTableWhite'

export const RestTable = styled.div`
  	text-align: center;
  	height: ${props => props.height.value - 0.5}em;
  	width: ${props => props.width.value - 0.5}em;
  	background-color: ${colorTheme.restaurant.table.background};
	background-image: linear-gradient(${props => props.color} 35%, ${colorTheme.restaurant.table.background} 35%);
  	border-radius: ${props => props.formType === 'CIRCLE' || props.formType === 'OVAL' ? '50%' : '0.8em'};

	&.busy {
    	background-image: linear-gradient(${colorTheme.restaurant.table.busy.background} 35%, ${colorTheme.restaurant.table.background} 35%);
  	}
`
RestTable.displayName = 'RestTable'

export const RestTableTitle = styled.div`
	height: 35%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${colorTheme.restaurant.table.title};
	font-weight: 700;
	line-height: 1.3em;
	font-size: 1.12rem;
`
RestTableTitle.displayName = 'RestTableTitle'

export const RestChair = styled.div`
	position: absolute;
	width: 31px;
	height: 31px;
  	border-radius: 50%;
  	background-color: ${colorTheme.restaurant.table.backgroundChair};
  	top: ${props => props.top}px;
  	left: ${props => props.left}px;
  	transform: rotate(${props => props.rotate}deg);
  	border: 3px solid ${colorTheme.restaurant.table.borderChair};
`
RestChair.displayName = 'RestChair'

export const RestTablePrice = styled.div`
  	color: ${colorTheme.restaurant.table.price};
  	font-size: ${props => props.width?.value < 5 ? '0.8em' : props.width?.value < 6.2 ? '1em' : '1.4em'};
  	font-family: ${theme.fontRobotoRegular};
	font-weight: 700;
	margin-top: 0.2em;
`
RestTablePrice.displayName = 'RestTablePrice'

export const RestTableWaiterWrap = styled.div`
	font-size: 1rem;
	display: grid;
    grid-template-columns: auto 1fr;
	gap: .32em;
	justify-content: center;
	align-items: center;
	padding: .25em .63em;
	height: 2.12em;
	background: ${colorTheme.tableCart.table.waiter.background};
 	border-radius: 0.63em;
  	border: 2px solid ${colorTheme.tableCart.table.waiter.border};
	width: max-content;

	svg {
		width: 2.25em;
		height: 1.5em;
		fill: ${colorTheme.tableCart.table.waiter.icon};
	}

	&.waiter-call {
		background: ${colorTheme.tableCart.table.waiterCall.background};
		max-width: 17em;
		height: auto;

		svg {
			width: 1.81em;
			height: 1.25em;
		}

		&.small {
			font-size: .94em;
		}
	}
`
RestTableWaiterWrap.displayName = 'RestTableWaiterWrap'

export const RestTableWaiter = styled.div`
	font-size: 1em;
	color: ${colorTheme.tableCart.table.waiter.text};
	font-weight: 500;
`
RestTableWaiter.displayName = 'RestTableWaiter'
