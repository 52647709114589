import axios from 'axios'
import { orderServiceUrl } from '../../../../urls'

const headers = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest',
}

const orderAdminInstance = axios.create({
	baseURL: orderServiceUrl,
	headers,
})

export default orderAdminInstance
