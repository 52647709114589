import { getColorByName } from '../helper'
import MainIcon from '../../../../../img/svg/main'
import { ProcessedByText, TypeIconWrapper, TypeWrapper } from './index.styled'
import React from 'react'
import TableTdElementsTooltip from '../TableTdElementsTooltip'
import { useTranslation } from 'react-i18next'

const OrderType = React.memo(({ item }) => {
	const { t } = useTranslation()
	const isDelivery = item.orderType === 'DELIVERY'
	const isDeliveryByPlatform = item?.orderType === 'DELIVERY_BY_PLATFORM'
	const updatedDeliveryByPlatform = !!(isDelivery && item?.deliveryMetaData?.partner?.type)
	const isPlatformDriverOrder = isDeliveryByPlatform || updatedDeliveryByPlatform
	const typeWrapperColor = (isDelivery && item.processedBy) ? getColorByName(item.processedBy) : ''
	const typeWrapperLayout = (isDelivery || isPlatformDriverOrder) ? 'delivery' : 'order-type'

	return (
		<TypeWrapper>
			<TableTdElementsTooltip text={t(`Filter.type.${item?.orderType}`)}>
				<TypeIconWrapper color={typeWrapperColor} className={typeWrapperLayout}>
					{item?.orderType === 'PICKUP' &&
						<MainIcon icon="PICKUP" />}
					{(item.orderType === 'COUNTER' || item.orderType === 'RESTAURANT') &&
						<MainIcon icon="COUNTER" />}
					{(isDelivery || isPlatformDriverOrder) &&
						<MainIcon icon="DELIVERY" />}
				</TypeIconWrapper>
			</TableTdElementsTooltip>
			{item.processedBy && <ProcessedByText className={typeWrapperLayout + '-text'}>{item.processedBy}</ProcessedByText>}
		</TypeWrapper>
	)
})

OrderType.displayName = 'OrderType'
export default OrderType
