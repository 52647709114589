import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const Ingredients = lazyWithRetry(() => import(/* webpackChunkName: "ingredients" */'../../component/Settings/Ingredients'))

const IngredientsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Ingredients />
		</Suspense>
	)
})

IngredientsPage.displayName = 'IngredientsPage'
export default IngredientsPage
