import React from 'react'

export const Table16ChairsSvg = ({ chairCount, className }) => {
	const chairClassname = (chairNumber) => {
		return chairNumber <= chairCount ? '' : 'hidden'
	}
	return (
		<svg viewBox="0 0 982 200" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<g className={chairClassname(16)}>
				<path d="M3.8999 120.1V79.9004C3.8999 76.6004 6.5999 73.9004 9.8999 73.9004H50.0999C53.3999 73.9004 56.0999 76.6004 56.0999 79.9004V120.1C56.0999 123.4 53.3999 126.1 50.0999 126.1H9.8999C6.6999 126.1 3.8999 123.3 3.8999 120.1Z" fill="#7A7A7A" />
				<path d="M50.1 73.9C53.4 73.9 56.1 76.6 56.1 79.9V120.1C56.1 123.4 53.4 126.1 50.1 126.1H9.9C6.6 126.1 3.9 123.4 3.9 120.1V79.9C3.9 76.6 6.6 73.9 9.9 73.9H50.1ZM50.1 70H9.9C4.4 70 0 74.4 0 79.9V120.1C0 125.6 4.4 130 9.9 130H50.1C55.6 130 60 125.6 60 120.1V79.9C60 74.4 55.6 70 50.1 70Z" fill="white" />
			</g>
			<path d="M73.8999 190.1V149.9C73.8999 146.6 76.5999 143.9 79.8999 143.9H120.1C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.8999C76.6999 196.1 73.8999 193.3 73.8999 190.1Z" fill="#7A7A7A" />
			<path d="M120.1 143.9C123.4 143.9 126.1 146.6 126.1 149.9V190.1C126.1 193.4 123.4 196.1 120.1 196.1H79.9C76.6 196.1 73.9 193.4 73.9 190.1V149.9C73.9 146.6 76.6 143.9 79.9 143.9H120.1ZM120.1 140H79.9C74.4 140 70 144.4 70 149.9V190.1C70 195.6 74.4 200 79.9 200H120.1C125.6 200 130 195.6 130 190.1V149.9C130 144.4 125.6 140 120.1 140Z" fill="white" />
			<path d="M73.8999 50.1004V9.90039C73.8999 6.60039 76.5999 3.90039 79.8999 3.90039H120.1C123.4 3.90039 126.1 6.60039 126.1 9.90039V50.1004C126.1 53.4004 123.4 56.1004 120.1 56.1004H79.8999C76.6999 56.1004 73.8999 53.3004 73.8999 50.1004Z" fill="#7A7A7A" />
			<path d="M120.1 3.9C123.4 3.9 126.1 6.6 126.1 9.9V50.1C126.1 53.4 123.4 56.1 120.1 56.1H79.9C76.6 56.1 73.9 53.4 73.9 50.1V9.9C73.9 6.6 76.6 3.9 79.9 3.9H120.1ZM120.1 0H79.9C74.4 0 70 4.4 70 9.9V50.1C70 55.6 74.4 60 79.9 60H120.1C125.6 60 130 55.6 130 50.1V9.9C130 4.4 125.6 0 120.1 0Z" fill="white" />
			<path d="M204.9 50.1004V9.90039C204.9 6.60039 207.6 3.90039 210.9 3.90039H251.1C254.4 3.90039 257.1 6.60039 257.1 9.90039V50.1004C257.1 53.4004 254.4 56.1004 251.1 56.1004H210.9C207.7 56.1004 204.9 53.3004 204.9 50.1004Z" fill="#7A7A7A" />
			<path d="M251.1 3.9C254.4 3.9 257.1 6.6 257.1 9.9V50.1C257.1 53.4 254.4 56.1 251.1 56.1H210.9C207.6 56.1 204.9 53.4 204.9 50.1V9.9C204.9 6.6 207.6 3.9 210.9 3.9H251.1ZM251.1 0H210.9C205.4 0 201 4.4 201 9.9V50.1C201 55.6 205.4 60 210.9 60H251.1C256.6 60 261 55.6 261 50.1V9.9C261 4.4 256.6 0 251.1 0Z" fill="white" />
			<path d="M204.9 190.1V149.9C204.9 146.6 207.6 143.9 210.9 143.9H251.1C254.4 143.9 257.1 146.6 257.1 149.9V190.1C257.1 193.4 254.4 196.1 251.1 196.1H210.9C207.7 196.1 204.9 193.3 204.9 190.1Z" fill="#7A7A7A" />
			<path d="M251.1 143.9C254.4 143.9 257.1 146.6 257.1 149.9V190.1C257.1 193.4 254.4 196.1 251.1 196.1H210.9C207.6 196.1 204.9 193.4 204.9 190.1V149.9C204.9 146.6 207.6 143.9 210.9 143.9H251.1ZM251.1 140H210.9C205.4 140 201 144.4 201 149.9V190.1C201 195.6 205.4 200 210.9 200H251.1C256.6 200 261 195.6 261 190.1V149.9C261 144.4 256.6 140 251.1 140Z" fill="white" />
			<path d="M334.9 50.1004V9.90039C334.9 6.60039 337.6 3.90039 340.9 3.90039H381.1C384.4 3.90039 387.1 6.60039 387.1 9.90039V50.1004C387.1 53.4004 384.4 56.1004 381.1 56.1004H340.9C337.7 56.1004 334.9 53.3004 334.9 50.1004Z" fill="#7A7A7A" />
			<path d="M381.1 3.9C384.4 3.9 387.1 6.6 387.1 9.9V50.1C387.1 53.4 384.4 56.1 381.1 56.1H340.9C337.6 56.1 334.9 53.4 334.9 50.1V9.9C334.9 6.6 337.6 3.9 340.9 3.9H381.1ZM381.1 0H340.9C335.4 0 331 4.4 331 9.9V50.1C331 55.6 335.4 60 340.9 60H381.1C386.6 60 391 55.6 391 50.1V9.9C391 4.4 386.6 0 381.1 0Z" fill="white" />
			<path d="M334.9 190.1V149.9C334.9 146.6 337.6 143.9 340.9 143.9H381.1C384.4 143.9 387.1 146.6 387.1 149.9V190.1C387.1 193.4 384.4 196.1 381.1 196.1H340.9C337.7 196.1 334.9 193.3 334.9 190.1Z" fill="#7A7A7A" />
			<path d="M381.1 143.9C384.4 143.9 387.1 146.6 387.1 149.9V190.1C387.1 193.4 384.4 196.1 381.1 196.1H340.9C337.6 196.1 334.9 193.4 334.9 190.1V149.9C334.9 146.6 337.6 143.9 340.9 143.9H381.1ZM381.1 140H340.9C335.4 140 331 144.4 331 149.9V190.1C331 195.6 335.4 200 340.9 200H381.1C386.6 200 391 195.6 391 190.1V149.9C391 144.4 386.6 140 381.1 140Z" fill="white" />
			<path d="M464.9 50.1004V9.90039C464.9 6.60039 467.6 3.90039 470.9 3.90039H511.1C514.4 3.90039 517.1 6.60039 517.1 9.90039V50.1004C517.1 53.4004 514.4 56.1004 511.1 56.1004H470.9C467.7 56.1004 464.9 53.3004 464.9 50.1004Z" fill="#7A7A7A" />
			<path d="M511.1 3.9C514.4 3.9 517.1 6.6 517.1 9.9V50.1C517.1 53.4 514.4 56.1 511.1 56.1H470.9C467.6 56.1 464.9 53.4 464.9 50.1V9.9C464.9 6.6 467.6 3.9 470.9 3.9H511.1ZM511.1 0H470.9C465.4 0 461 4.4 461 9.9V50.1C461 55.6 465.4 60 470.9 60H511.1C516.6 60 521 55.6 521 50.1V9.9C521 4.4 516.6 0 511.1 0Z" fill="white" />
			<path d="M464.9 190.1V149.9C464.9 146.6 467.6 143.9 470.9 143.9H511.1C514.4 143.9 517.1 146.6 517.1 149.9V190.1C517.1 193.4 514.4 196.1 511.1 196.1H470.9C467.7 196.1 464.9 193.3 464.9 190.1Z" fill="#7A7A7A" />
			<path d="M511.1 143.9C514.4 143.9 517.1 146.6 517.1 149.9V190.1C517.1 193.4 514.4 196.1 511.1 196.1H470.9C467.6 196.1 464.9 193.4 464.9 190.1V149.9C464.9 146.6 467.6 143.9 470.9 143.9H511.1ZM511.1 140H470.9C465.4 140 461 144.4 461 149.9V190.1C461 195.6 465.4 200 470.9 200H511.1C516.6 200 521 195.6 521 190.1V149.9C521 144.4 516.6 140 511.1 140Z" fill="white" />
			<path d="M594.9 50.1004V9.90039C594.9 6.60039 597.6 3.90039 600.9 3.90039H641.1C644.4 3.90039 647.1 6.60039 647.1 9.90039V50.1004C647.1 53.4004 644.4 56.1004 641.1 56.1004H600.9C597.7 56.1004 594.9 53.3004 594.9 50.1004Z" fill="#7A7A7A" />
			<path d="M641.1 3.9C644.4 3.9 647.1 6.6 647.1 9.9V50.1C647.1 53.4 644.4 56.1 641.1 56.1H600.9C597.6 56.1 594.9 53.4 594.9 50.1V9.9C594.9 6.6 597.6 3.9 600.9 3.9H641.1ZM641.1 0H600.9C595.4 0 591 4.4 591 9.9V50.1C591 55.6 595.4 60 600.9 60H641.1C646.6 60 651 55.6 651 50.1V9.9C651 4.4 646.6 0 641.1 0Z" fill="white" />
			<path d="M594.9 190.1V149.9C594.9 146.6 597.6 143.9 600.9 143.9H641.1C644.4 143.9 647.1 146.6 647.1 149.9V190.1C647.1 193.4 644.4 196.1 641.1 196.1H600.9C597.7 196.1 594.9 193.3 594.9 190.1Z" fill="#7A7A7A" />
			<path d="M641.1 143.9C644.4 143.9 647.1 146.6 647.1 149.9V190.1C647.1 193.4 644.4 196.1 641.1 196.1H600.9C597.6 196.1 594.9 193.4 594.9 190.1V149.9C594.9 146.6 597.6 143.9 600.9 143.9H641.1ZM641.1 140H600.9C595.4 140 591 144.4 591 149.9V190.1C591 195.6 595.4 200 600.9 200H641.1C646.6 200 651 195.6 651 190.1V149.9C651 144.4 646.6 140 641.1 140Z" fill="white" />
			<path d="M724.9 50.1004V9.90039C724.9 6.60039 727.6 3.90039 730.9 3.90039H771.1C774.4 3.90039 777.1 6.60039 777.1 9.90039V50.1004C777.1 53.4004 774.4 56.1004 771.1 56.1004H730.9C727.7 56.1004 724.9 53.3004 724.9 50.1004Z" fill="#7A7A7A" />
			<path d="M771.1 3.9C774.4 3.9 777.1 6.6 777.1 9.9V50.1C777.1 53.4 774.4 56.1 771.1 56.1H730.9C727.6 56.1 724.9 53.4 724.9 50.1V9.9C724.9 6.6 727.6 3.9 730.9 3.9H771.1ZM771.1 0H730.9C725.4 0 721 4.4 721 9.9V50.1C721 55.6 725.4 60 730.9 60H771.1C776.6 60 781 55.6 781 50.1V9.9C781 4.4 776.6 0 771.1 0Z" fill="white" />
			<path d="M724.9 190.1V149.9C724.9 146.6 727.6 143.9 730.9 143.9H771.1C774.4 143.9 777.1 146.6 777.1 149.9V190.1C777.1 193.4 774.4 196.1 771.1 196.1H730.9C727.7 196.1 724.9 193.3 724.9 190.1Z" fill="#7A7A7A" />
			<path d="M771.1 143.9C774.4 143.9 777.1 146.6 777.1 149.9V190.1C777.1 193.4 774.4 196.1 771.1 196.1H730.9C727.6 196.1 724.9 193.4 724.9 190.1V149.9C724.9 146.6 727.6 143.9 730.9 143.9H771.1ZM771.1 140H730.9C725.4 140 721 144.4 721 149.9V190.1C721 195.6 725.4 200 730.9 200H771.1C776.6 200 781 195.6 781 190.1V149.9C781 144.4 776.6 140 771.1 140Z" fill="white" />
			<path d="M978.1 120.1V79.9004C978.1 76.6004 975.4 73.9004 972.1 73.9004H931.9C928.6 73.9004 925.9 76.6004 925.9 79.9004V120.1C925.9 123.4 928.6 126.1 931.9 126.1H972.1C975.3 126.1 978.1 123.3 978.1 120.1Z" fill="#7A7A7A" />
			<path d="M931.9 73.9C928.6 73.9 925.9 76.6 925.9 79.9V120.1C925.9 123.4 928.6 126.1 931.9 126.1H972.1C975.4 126.1 978.1 123.4 978.1 120.1V79.9C978.1 76.6 975.4 73.9 972.1 73.9H931.9ZM931.9 70H972.1C977.6 70 982 74.4 982 79.9V120.1C982 125.6 977.6 130 972.1 130H931.9C926.4 130 922 125.6 922 120.1V79.9C922 74.4 926.4 70 931.9 70Z" fill="white" />
			<path d="M908.1 190.1V149.9C908.1 146.6 905.4 143.9 902.1 143.9H861.9C858.6 143.9 855.9 146.6 855.9 149.9V190.1C855.9 193.4 858.6 196.1 861.9 196.1H902.1C905.3 196.1 908.1 193.3 908.1 190.1Z" fill="#7A7A7A" />
			<path d="M861.9 143.9C858.6 143.9 855.9 146.6 855.9 149.9V190.1C855.9 193.4 858.6 196.1 861.9 196.1H902.1C905.4 196.1 908.1 193.4 908.1 190.1V149.9C908.1 146.6 905.4 143.9 902.1 143.9H861.9ZM861.9 140H902.1C907.6 140 912 144.4 912 149.9V190.1C912 195.6 907.6 200 902.1 200H861.9C856.4 200 852 195.6 852 190.1V149.9C852 144.4 856.4 140 861.9 140Z" fill="white" />
			<path d="M908.1 50.1004V9.90039C908.1 6.60039 905.4 3.90039 902.1 3.90039H861.9C858.6 3.90039 855.9 6.60039 855.9 9.90039V50.1004C855.9 53.4004 858.6 56.1004 861.9 56.1004H902.1C905.3 56.1004 908.1 53.3004 908.1 50.1004Z" fill="#7A7A7A" />
			<path d="M861.9 3.9C858.6 3.9 855.9 6.6 855.9 9.9V50.1C855.9 53.4 858.6 56.1 861.9 56.1H902.1C905.4 56.1 908.1 53.4 908.1 50.1V9.9C908.1 6.6 905.4 3.9 902.1 3.9H861.9ZM861.9 0H902.1C907.6 0 912 4.4 912 9.9V50.1C912 55.6 907.6 60 902.1 60H861.9C856.4 60 852 55.6 852 50.1V9.9C852 4.4 856.4 0 861.9 0Z" fill="white" />
			<path d="M947.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.6001 176 34.1001 176H948C953.5 176 958 171.5 958 166V34C958 28.5 953.4 24 947.9 24Z" fill="#7A7A7A" />
			<path d="M947.9 20H34.1C26.3 20 20 26.3 20 34V166C20 173.7 26.3 180 34.1 180H948C955.8 180 962.1 173.7 962.1 166V34C962 26.3 955.7 20 947.9 20ZM958 166C958 171.5 953.4 176 948 176H34.1C28.6 176 24.1 171.5 24.1 166V34C24.1 28.5 28.6 24 34.1 24H948C953.5 24 958 28.5 958 34V166Z" fill="white" />
			<path className="colorTable" d="M947.9 24H34.1001C28.6001 24 24.1001 28.5 24.1001 34V166C24.1001 171.5 28.6001 176 34.1001 176H948C953.5 176 958 171.5 958 166V34C958 28.5 953.4 24 947.9 24ZM947.3 165.3H34.7001V34.7H947.2V165.3H947.3Z" fill="#38CD90" />
		</svg>
	)
}
Table16ChairsSvg.displayName = 'Table16ChairsSvg'
