import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRestaurantAreaId, setRestaurantTables } from '../../../redux/action/restaurant'
import LoginTimeCountDown from '../../Elements/loginTimeCountDown'
import { theme } from '../../../style/theme'
import { useWindowBreakPoint } from '../../../hooks/useWindowBreakPoint'
import { convertTimeToMilliseconds } from '../../../helper'
import AreaRestaurantButton from './button'

const Areas = ({ swapMode, children }) => {
	const { areas, areaId } = useSelector(state => state.restaurant)
	const dispatch = useDispatch()
	const isMobile = useWindowBreakPoint(theme.point720)
	const logoutTime = useSelector(state => state.restaurantSettings.waiterSettings)?.logoutTime || 0

	useEffect(() => {
		if (!isMobile && !areaId && areas && areas[0]) {
			dispatch(setRestaurantAreaId(areas[0].id))
		}
	}, [areas]) // eslint-disable-line react-hooks/exhaustive-deps

	const toggleArea = (id) => {
		if (areaId !== id) {
			dispatch(setRestaurantTables([]))
			dispatch(setRestaurantAreaId(id))
		} else if (isMobile) {
			dispatch(setRestaurantTables([]))
			dispatch(setRestaurantAreaId(null))
		}
	}

	return (
		<>
			<LoginTimeCountDown logoutTime={convertTimeToMilliseconds(logoutTime)} />
			{areas?.map((area, index) =>
				<AreaRestaurantButton key={index} index={index}
															swapMode={swapMode}
															area={area}
															areaId={areaId}
															toggleArea={toggleArea}>
					{children}
				</AreaRestaurantButton>)}
		</>
	)
}

Areas.displayName = 'Areas'
export default Areas
