import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../Elements/buttons/Button'
import CustomInfoModal from '../../Elements/modal/CustomInfoModal'
import { TseWarningText, TseWarningWrapper } from './index.styled'

const EVENT_Z_INDEX = 992

const TseInvalidDataWarningModal = React.memo(({ isOpen, close }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const navigateToSettings = useCallback(() => {
		navigate('/settings/program/company-data', { state: { scrollToTse: true } })
		close()
	}, [close])

	return (
		<CustomInfoModal isOpen={isOpen}
			title={t('Modal.tse_warning_modal.title')}
			close={close}
			modalColor="orange"
			zIndex={EVENT_Z_INDEX}
			button={<>
				<Button icon="settings-2" onClick={navigateToSettings} text={t('Modal.tse_warning_modal.buttonSettingText')} color="gray" />
				<Button icon="checkmark" onClick={close} tabIndex="1" text={t('buttons.controls.ok')} color="green" keyButton="F2" />
			</>}>
			<TseWarningWrapper>
				<TseWarningText>
					{t('Modal.tse_warning_modal.text')}
					<span onClick={navigateToSettings}>{t('Modal.tse_warning_modal.linkText')}</span>
				</TseWarningText>
			</TseWarningWrapper>
		</CustomInfoModal>
	)

})

TseInvalidDataWarningModal.displayName = 'TseInvalidDataWarningModal'
export default TseInvalidDataWarningModal
