import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const MobileHeading = styled.h2`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  padding: 0.42em;
  font-family: ${theme.fontRobotoBold};
  color: ${colorTheme.basic.heading};
`
MobileHeading.displayName = 'MobileHeading'
