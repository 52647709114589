import styled from 'styled-components'

export const PlatformWrapper = styled.div`
	font-size: 1em;

	&.disabled {
		opacity: 0.5;
	}

	> .ONLINE_SHOP, .restaurant {
		width: 5.95em;
		height: 1.4em;
	}

	> .WOLT_PLUGIN {
		width: 3.3em;
		height: 1.5em;
	}

	> .FOODORA_PLUGIN {
		width: 5em;
		height: 0.9em;
	}

	> .lieferando-de {
		width: 6em;
		height: 1.2em;
	}

	> .phone-order {
		width: 2.9em;
		height: 1.5em;
	}

	> .kiosk {
		width: 5.4em;
		height: 1.4em;
	}

	> svg {
		width: 5em;
		height: 1.3em;
	}

	&.driver-monitor {
		> .WOLT_PLUGIN {
			width: 3.3em;
			height: 1.2em;
		}
	}

`
PlatformWrapper.displayName = 'PlatformWrapper'
