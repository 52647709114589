import React from 'react'
import { PreparedMessageText, PreparedMessageWrapper } from './index.styled'
import { useTranslation } from 'react-i18next'
import MainIcon from '../../../../../img/svg/main'

const PreparedMessage = React.memo(() => {
	const { t } = useTranslation()

	return (
		<PreparedMessageWrapper>
			<MainIcon icon="green-circle-transparent-checkmark" />
			<PreparedMessageText>{t('restaurant.sendToKitchenModal.header.statuses.prepared')}</PreparedMessageText>
		</PreparedMessageWrapper>
	)
})

PreparedMessage.displayName = 'PreparedMessage'
export default PreparedMessage
