import styled from 'styled-components'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { theme } from '../../../../style/theme'

export const CartWrapper = styled.div`
	font-size: 1rem;
	background-color: ${colorTheme.clientMonitorCart.cart.background};
	display: grid;
	grid-template-rows: min-content auto min-content;
	height: 100vh;
`
CartWrapper.displayName = 'CartWrapper'

export const CartHeader = styled.div`
	display: grid;
	grid-template-columns: 2.65fr 0.4fr 1fr 1fr;
	align-items: center;
	border-bottom: 1px solid ${colorTheme.clientMonitorCart.cart.header.border};
	min-height: 2.81em;
	padding-top: 2.5em;
	margin: 0 3.13em;
`
CartHeader.displayName = 'CartHeader'

export const CartHeadTitle = styled.div`
	color: ${colorTheme.clientMonitorCart.cart.header.color};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	font-weight: 600;

	&.align-right {
		text-align: right;
	}
`
CartHeadTitle.displayName = 'CartHeadTitle'

export const CartProductsInner = styled.div`
	padding-right: 1.63em;
`
CartProductsInner.displayName = 'CartProductsInner'

export const CartProducts = styled.div`
	overflow: auto;
	scroll-behavior: smooth;
	margin-right: 1.5em;
	margin-left: 3.13em;
	padding-bottom: 0.31em;
`
CartProducts.displayName = 'CartProducts'

export const CartProductQuantityWrap = styled.div`
	margin-right: .63em;
`
CartProductQuantityWrap.displayName = 'CartProductQuantityWrap'

