import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CartCategory, CategoryButton, CategoryList, CategoryTitle, FavCategoryButton } from './index.styled'
import { useTranslation } from 'react-i18next'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { reInitializeCartCategory, setCartCategoryCategoryId, setCartCategoryProductType } from '../../../../redux/action/cartCategory'
import { getCartCategoriesThunk } from '../../../../redux/thunk/cartCategory'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import CartTouchPadSearch from '../CartTouchPadSearch'
import { flatMapCategories } from '../../../../helper'
import { theme } from '../../../../style/theme'
import ToggleButton from '../../buttons/ToggleButton'
import ButtonIcon from '../../../../img/svg/button'
import colors from '../../../../style/colors'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import KeyBindings from '../../keybindings'
import GiftVoucher from '../GiftVoucher'
import { getColorsForCart } from '../../../../helper/getColorsForCart'

const EVENT_Z_INDEX = 3

const CartCategories = React.memo(({ handleF2, incrementProduct, openModalProductType, openKeyboard, isDisabled, module }) => {
	const [touchPad] = useLocalStorage('touchPad', false)
	const [colorTheme] = useLocalStorage('colorTheme', 'LIGHT')
	const categories = useSelector(state => state.cartCategory.categories)
	const productType = useSelector(state => state.cartCategory.productType)
	const categoryId = useSelector(state => state.cartCategory.categoryId)
	const searchText = useSelector(state => state.cartProduct.searchText)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const dispatch = useDispatch()
	const [focusedCategoryIndex, setFocusedCategoryIndex] = useState(0)
	const isTablet = useWindowBreakPoint(theme.point820)
	const { t } = useTranslation()
	const selectedCategory = flatMapCategories(categories)?.find(cat => cat.id === categoryId)
	const selectedCategoryHasChildren = !!selectedCategory?.children?.length
	const selectedCategoryParent = flatMapCategories(categories)?.find(cat => cat.id === selectedCategory?.parent?.id)
	const [hasKeyboard, setHasKeyboard] = useState(false)
	const categoriesToShow = useMemo(() => selectedCategoryHasChildren ?
		[selectedCategory, ...selectedCategory.children] :
		selectedCategoryParent ?
			[selectedCategoryParent, ...selectedCategoryParent.children]
			: categories, [categories, selectedCategory, selectedCategoryHasChildren, selectedCategoryParent])

	useEffect(() => {
		if (isNeededZIndex) {
			setFocusedCategoryIndex(0)
		} else {
			setFocusedCategoryIndex(null)
		}
	}, [isNeededZIndex])

	useEffect(() => {
		dispatch(getCartCategoriesThunk(false))
		return () => dispatch(reInitializeCartCategory())
	}, [touchPad, dispatch])

	const handleArrowUp = useCallback(() => {
		if (focusedCategoryIndex === 0) {
			setFocusedCategoryIndex(categoriesToShow.length - 1)
		} else {
			setFocusedCategoryIndex(focusedCategoryIndex - 1)
		}
	}, [focusedCategoryIndex, categoriesToShow?.length])

	const handleArrowDown = useCallback(() => {
		if (focusedCategoryIndex < (categoriesToShow.length - 1)) {
			setFocusedCategoryIndex(focusedCategoryIndex + 1)
		} else {
			setFocusedCategoryIndex(0)
		}
	}, [focusedCategoryIndex, categoriesToShow?.length])

	const selectCategory = useCallback((item) => {
		if (selectedCategory?.id === item?.id) {
			if (selectedCategory.parent) {
				dispatch(setCartCategoryCategoryId(selectedCategory.parent.id))
			} else {
				dispatch(setCartCategoryCategoryId(null))
			}
		} else if (selectedCategoryParent?.id === item?.id) {
			if (item?.parent) {
				dispatch(setCartCategoryCategoryId(item.parent.id))
			} else {
				dispatch(setCartCategoryCategoryId(null))
			}
		} else {
			dispatch(setCartCategoryCategoryId(item.id))
		}
	}, [dispatch, selectedCategory, selectedCategoryParent])

	const handleEnter = useCallback(() => {
		if (!categoriesToShow || !categoriesToShow[focusedCategoryIndex]) return
		selectCategory(categoriesToShow[focusedCategoryIndex], focusedCategoryIndex)
		dispatch(setAppEventZIndex(0))
	}, [categoriesToShow, focusedCategoryIndex, dispatch, selectCategory])

	const selectAllCategories = useCallback(() => {
		dispatch(setCartCategoryProductType(null))
		if (!touchPad) {
			dispatch(setCartCategoryCategoryId(null))
		}
	}, [touchPad, dispatch])

	const selectTopping = useCallback(() => {
		dispatch(setCartCategoryProductType('TOPPING'))
		if (!touchPad) {
			dispatch(setCartCategoryCategoryId(null))
		}
	}, [touchPad, dispatch])

	const toggleProductType = useCallback((i) => {
		if (i === 0) {
			selectAllCategories()
		} else {
			selectTopping()
		}
	}, [ selectAllCategories, selectTopping])

	const layout = (touchPad ? ' touchPad' : '') + (hasKeyboard ? ' hasKeyboard' : '')

	return (
		<>
			<CartCategory className={'cart-col-last' + layout}>
				{!isTablet && <>
					<GiftVoucher disabled={isDisabled} module={module} />
					<CategoryButton onClick={selectAllCategories} className={!productType ? 'active' : ''}>{t('Cart.Buttons.all')}</CategoryButton>
					<CategoryButton id="all-extras-button" onClick={selectTopping} className={productType === 'TOPPING' ? 'active' : ''}>{t('Cart.Buttons.allextras')}</CategoryButton>
				</>}
				{isTablet && !searchText && (
					<>
						<GiftVoucher disabled={isDisabled} module={module} />
						<ToggleButton toggleIndex={!productType ? 0 : 1} setToggleIndex={toggleProductType}
													color="blue" size="big" items={[{ text: 'Artikel' }, { text: 'Extras' }]} />
					</>)}
				<CategoryTitle className={layout}>{t('Cart.title')}</CategoryTitle>
				<CategoryList className={layout}>
					{categoriesToShow?.map((item, i) => {
						const parentCategory = item?.parent
						const colorCategory = parentCategory ? parentCategory.colorData?.color : item.colorData?.color
						const themeCategory = parentCategory ? parentCategory.colorData?.theme : item.colorData?.theme
						const themeStyles = getColorsForCart(colorCategory, themeCategory, colorTheme, touchPad)
						const isFocused = focusedCategoryIndex === i
						const isActive = selectedCategory?.id === item?.id
						const isParent = selectedCategoryParent?.id === item?.id || (isActive && selectedCategoryHasChildren)
						return (
							<FavCategoryButton key={i} onClick={() => selectCategory(item)} tabIndex={10 + i}
																 className={(isFocused ? 'focus' : '') + (isActive ? ' active' : '') + (isParent ? ' back' : '') + layout}
																 themeStyles={themeStyles}>
								{isParent && <ButtonIcon icon="back" fill={isFocused || themeCategory === 'LIGHT' ? colors.gray_dark : colors.white} />}
								{item.title}
							</FavCategoryButton>
						)
					})}
				</CategoryList>
				{touchPad &&
					<CartTouchPadSearch incrementProduct={incrementProduct}
															openModalProductType={openModalProductType}
															openKeyboard={openKeyboard}
															disabled={isDisabled}
															setHasKeyboard={setHasKeyboard}/>}
			</CartCategory>

			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 enter={handleEnter}
										 f2={handleF2} />}
		</>)
})

CartCategories.displayName = 'CartCategories'
export default CartCategories
