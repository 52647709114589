import React from 'react'
import { useSelector } from 'react-redux'
import { selectFormattedCurrency } from '../../../redux/selector/system'

const Currency = React.memo(({ children }) => {
	const formattedCurrency = useSelector(selectFormattedCurrency(children))

	return (
		<>{formattedCurrency}</>
	)
})

Currency.displayName = 'Currency'
export default Currency
