import styled from 'styled-components'
import theme from 'styled-theming'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const TextWrap = styled.div`
	display: flex;
	align-items: flex-end;
	gap: .19em;
`
TextWrap.displayName = 'TextWrap'

export const NewBlockWrap = styled.div`
	display: flex;
	padding-bottom: .44em;
	flex-direction: column;
	align-items: flex-start;
	gap: .63em;
`
NewBlockWrap.displayName = 'NewBlockWrap'

export const NewBlockContent = styled.div`
	display: flex;
	padding: .19em .31em;
	justify-content: center;
	align-items: center;
	gap: .63em;
	font-size: 1rem;
	font-style: normal;
	border-radius: .31em;
	background: ${colorTheme.table.newBlock.background};
`
NewBlockContent.displayName = 'NewBlockContent'

export const NewBlockText = styled.div`
	color:  ${colorTheme.table.newBlock.color};
	font-family: ${theme.fontRoboto};
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;
	font-size: .81em;
`
NewBlockText.displayName = 'NewBlockText'

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;

	.phoneNumber {
		font-size: 0.7em;
	}
`
TextWrapper.displayName = 'TextWrapper'

export const DistanceBlock = styled.span`
	font-size: 0.65em;
	color: ${colorTheme.table.distanceBlock.text};
	border-radius: 0.71em;
	background-color: ${colorTheme.table.distanceBlock.background};
	padding: 0.33em 0.42em;
	margin-left: 0.5em;

	&.hidden {
		display: none;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1em;
		line-height: 1.14em;
		font-weight: 500;
	}

	@media screen and (max-width: ${theme.point720}) {
		background-color: ${colorTheme.table.distanceBlock.background}; !important;
	}
`
DistanceBlock.displayName = 'DistanceBlock'
