import React from 'react'
import { useSelector } from 'react-redux'
import TableTr from '../../tr'
import OrdersTableTdList from '../../OrdersTable/OrdersTableTdList'
import PreOrdersType from '../preOrdersType'
import { useTranslation } from 'react-i18next'

const PreOrdersToday = React.memo(({ activeRow, focusRow, tableHeadersStatic, handleClick, handleDbClick }) => {
	const { t } = useTranslation()
	const preOrdersToday = useSelector(store => store.order.preOrdersToday)

	return (
		<>
			{preOrdersToday?.length > 0 &&
				<PreOrdersType>{t('order.preorders.today')}</PreOrdersType>}
			{preOrdersToday?.map((item, i) =>
				<TableTr key={i}
									onDoubleClick={() => handleDbClick(item, i, 'TODAY')} onClick={() => handleClick(item, i, 'TODAY')}
									isActive={focusRow && i === activeRow}
									disabled={item?.status?.status === 'CANCELED' || !!item?.zNumber}
									disabledStatus={!!item?.zNumber}
									name={item?.zNumber ? 'CLOSED' : item?.status?.status}
									className="row-tablet-cols-4 bold-border">
					<OrdersTableTdList tableHeadersStatic={tableHeadersStatic} item={item} />
				</TableTr>)}
		</>
	)
})

PreOrdersToday.displayName = 'PreOrdersToday'
export default PreOrdersToday
