import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WaiterCallAreas, WaiterCallContent, WaiterCallText, WaiterCallWrap } from './index.styled'
import MainIcon from '../../../img/svg/main'
import WaiterCallButton from './button'
import { fetchWaiterCallsThunk } from '../../../redux/thunk/restaurant/waiterCall'
import { useTranslation } from 'react-i18next'
import { selectActiveTablesWaiterCalls } from '../../../redux/selector/restaurant'

const WaiterCall = React.memo(() => {
	const { t } = useTranslation()
	const waiterCalls = useSelector(selectActiveTablesWaiterCalls)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(fetchWaiterCallsThunk())
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		(waiterCalls && waiterCalls?.length > 0) &&
			<WaiterCallWrap>
				<WaiterCallContent>
					<MainIcon icon="waiter-call" />
					<WaiterCallText>{t('restaurant.waiterCall')}</WaiterCallText>
				</WaiterCallContent>
				<WaiterCallAreas>
					{waiterCalls.map((item, index) => (
						<WaiterCallButton key={index} call={item} />
					))}
				</WaiterCallAreas>
			</WaiterCallWrap>
	)
})

WaiterCall.displayName = 'WaiterCall'
export default WaiterCall
