import { getDiscountSymbol } from '../../../../helper'

const sortItemsByLastCoupon = (a, b) => {
	if (a.type === 'MARKETING_COUPON') return 1
	if (b.type === 'MARKETING_COUPON') return -1
	return 0
}

export const convert2ResultItems = (items, currencySymbol) => {
	const resultItems = items.map((item, _, arr) => {
		if (item.type === 'MARKETING_COUPON') {
			const { initialProduct, quantity, type } = item
			const discountSymbol = getDiscountSymbol(initialProduct?.discountData.discountType, currencySymbol)
			return {
				itemId: initialProduct?.id,
				type,
				quantity,
				name: initialProduct?.name ? initialProduct?.name + ' ' + initialProduct?.discountData.discount + discountSymbol : '',
				discountData: initialProduct?.discountData
			}
		} else {
			return item
		}
	})

	return resultItems.sort(sortItemsByLastCoupon)
}

export const convertToClientMonitorPurchaseItems = (resultItems, discountValue, discountType, discount, extraDiscountValue, extraDiscountType, extraDiscount, deliveryCost, couponPrice) => {
	const discountItem = { name: 'Discount', type: 'DISCOUNT', quantity: 1, price: -discount, discountMetaData: { value: discountValue, type: discountType } }
	const extraDiscountItem = { name: 'Extra Rabatt', type: 'EXTRA_DISCOUNT', quantity: 1, price: -extraDiscount, discountMetaData: { value: extraDiscountValue, type: extraDiscountType } }
	const deliveryCostItem = { name: 'Delivery cost', type: 'DELIVERY_COST', quantity: 1, price: deliveryCost }
	const items = [...resultItems, discountItem, extraDiscountItem, deliveryCostItem]
		.map(el => el.type === 'MARKETING_COUPON' ? { ...el, price: -couponPrice } : el)
	return items
}
