import styled from 'styled-components'

export const TerminalWrap = styled.div`
  font-size: 1rem;
	transform: translateX(25%);

	& > svg path.hand {
    animation: hand 3s linear infinite;
  }

	& > svg path.screen {
		animation: screen 3s linear infinite;
  }

	& > svg path.check {
		animation: check 3s linear infinite;
  }

	& > svg path.wave-first {
		animation: waveFirst 3s infinite;
	}

	& > svg path.wave-second {
		animation: waveSecond 3s infinite;
	}

	& > svg path.wave-third {
		animation: waveThird 3s infinite;
	}

	& > svg path.wave-fourth {
		animation: waveFourth 3s infinite;
	}

	@keyframes waveFirst {
		0% {
			opacity: 0;
		}

		65% {
			opacity: 0;
		}

		75% {
			opacity: 1;
		}

		85% {
			opacity: 1;
		}

		90%, 100% {
			opacity: 0;
		}
	}

	@keyframes waveSecond {
		0% {
			opacity: 0;
		}

		68% {
			opacity: 0;
		}

		78% {
			opacity: 1;
		}

		88% {
			opacity: 1;
		}

		90%, 100% {
			opacity: 0;
		}
	}

	@keyframes waveThird {
		0% {
			opacity: 0;
		}

		71% {
			opacity: 0;
		}

		81% {
			opacity: 1;
		}

		91% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes waveFourth {
		0% {
			opacity: 0;
		}

		75% {
			opacity: 0;
		}

		85% {
			opacity: 1;
		}

		95% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes hand {
    0% {
      transform: translate(0);
    }
    25% {
      transform: translate(-25%, -25%);
    }
		35% {
      transform: translate(-25%, -30%);
    }
    55% {
      transform: translate(-25%, -30%);
    }
		65% {
      transform: translate(-25%, -25%);
    }
		80%, 100% {
      transform: translate(0);
    }
  }

	@keyframes screen {
		0% {
			fill: #9E9E9E;
		}
		55% {
			fill: #9E9E9E;
		}
		65% {
			fill:  #2981EE;
		}
		90%, 100% {
			fill:  #2981EE;
		}
	}

	@keyframes check {
		0% {
			fill: #9E9E9E;
		}
		55% {
			fill: #9E9E9E;
		}
		65% {
			fill: #FFFFFF;
		}
		90%, 100% {
			fill: #FFFFFF;
		}
	}
`
TerminalWrap.displayName = 'TerminalWrap'
