import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const Platforms = lazyWithRetry(() => import(/* webpackChunkName: "platforms" */'../../component/Settings/Platforms'))

const PlatformsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<Platforms />
		</Suspense>
	)
})

PlatformsPage.displayName = 'PlatformsPage'
export default PlatformsPage
