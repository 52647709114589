import styled from 'styled-components'
import { WebshopPreviewWrap } from './WebshopPreview/index.styled'
import { theme } from '../../../../style/theme'

export const WebshopActivationModalWrap = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: 16.95em 27.19em 5.44em;
	justify-content: center;
	align-items: start;
	gap: 1.87em;
	margin-bottom: 1.88em;

	> div > .checkboxes {
		display: grid;
		gap: 0.63em;
	}

	> ${WebshopPreviewWrap} {
		grid-column: 2;
		height: 78.1vh;
	}

	@media screen and (max-width: ${theme.point1660}) {
		> ${WebshopPreviewWrap} {
			width: 27.18em;
		}
	}

	@media screen and (max-width: ${theme.point1440}) {
		grid-template-columns: 16.95em 36.5em 5.44em;
		> ${WebshopPreviewWrap} {
			width: 35.5em;
		}
	}
`
WebshopActivationModalWrap.displayName = 'WebshopActivationModalWrap'

export const RadioInputWrapper = styled.div`
	background: #FFFFFF;
	border: ${props => props.checked ? '1px solid #2981EE' : '1px solid #ADADB4'};
	box-shadow: ${props => props.checked ? '1.256px 17.956px 50px rgba(0, 0, 0, 0.15)' : 'none'};
	border-radius: 0.75em;
	padding: 1.12em 0.63em;
`
RadioInputWrapper.displayName = 'RadioInputWrapper'

export const WebshopActivationModalTitle = styled.h2`
	font-size: 1.7rem;
	font-weight: 700;
	line-height: 2em;
	text-align: left;
`
WebshopActivationModalTitle.displayName = 'WebshopActivationModalTitle'

export const WebshopActivationModalLeft = styled.div`
		display: grid;
		grid-column: 1;
		grid-row: 1;
		gap: 2.4em;
		margin-top: 1.5em;
		margin-right: 0.67em;
		> div {
			margin: 0 !important;
		}
		> div:nth-child(2) {
			padding-left: 0.82em !important;
		}
`
