import React from 'react'
import { ProductQuantityInput, ProductQuantityTitle, ProductQuantityWrap } from './index.styled'
import Price from '../../../../blocks/Price'
import PlusMinus from '../../../CartItem/PlusMinus'
import { ButtonKey } from '../../../../buttons/Button/style/index.styled'
import { useLocalStorage } from '../../../../../../hooks/useLocalStorage'

const ProductQuantity = React.memo(({ focus, item, index, type, onClickPlus, onClickMinus }) => {
	const [colorTheme] = useLocalStorage('colorTheme', 'LIGHT')

	const layoutClass = (focus ? 'focus ' : '')
		+ (item.quantity > 0 ? 'plus ' : '')
		+ (item.quantity < 0 ? 'minus ' : '')
		+ (type ? type : '')

	const notMainDish = type !== 'mainDish'
	const outlineIsNeeded = notMainDish && colorTheme === 'LIGHT'


	return (
		<ProductQuantityWrap className={layoutClass}
												 tabIndex={'1' + index}
												 data-testid={item.name}>
			<ProductQuantityTitle className={layoutClass}>
				{notMainDish && item.quantity > 0 &&
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19.336 9.812C19.336 10.531 18.753 11.114 18.034 11.114H10.826V18.033C10.826 18.752 10.243 19.335 9.524 19.335C8.804 19.335 8.221 18.752 8.221 18.033V11.114H1.302C0.584 11.114 0 10.531 0 9.812C0 9.093 0.584 8.51 1.302 8.51H8.221V1.301C8.221 0.582 8.804 0 9.524 0C10.243 0 10.826 0.582 10.826 1.301V8.51H18.034C18.753 8.51 19.336 9.093 19.336 9.812Z" fill="#2981EE" />
					</svg>}
				{notMainDish && item.quantity < 0 &&
					<svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.665 2.704H1.26844C0.568125 2.704 0 2.099 0 1.352C0 0.605 0.568125 0 1.26844 0H16.665C17.3653 0 17.9334 0.605 17.9334 1.352C17.9334 2.099 17.3653 2.704 16.665 2.704Z" fill="#DE7B00" />
					</svg>}
				<p>{item.name}</p>
				{notMainDish && <Price value={item.price} size="small" />}
				{focus &&
					<ButtonKey className="gray">Enter</ButtonKey>}
			</ProductQuantityTitle>
			<PlusMinus {...{
				onClickPlus, onClickMinus, incrementAbility: true, removeAbility: true,
				size: 'medium', color: item.quantity !== 0 ? 'gray' : 'transparent', outline: outlineIsNeeded,
			}}>
				<ProductQuantityInput value={item.quantity} className={(item.quantity !== 0 ? 'active ' : '') + type} readOnly={true} data-testid="quantity"/>
			</PlusMinus>
		</ProductQuantityWrap>
	)
})

ProductQuantity.displayName = 'ProductQuantity'
export default ProductQuantity
