import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedOrder } from '../../../redux/action/order'
import { setModalOrderCustomer } from '../../../redux/action/customer'
import OrdersTable from '../../Elements/table/OrdersTable'
import { useState } from 'react'
import OrdersTabs from '../../Elements/tabs/OrdersTabs'
import PreOrdersTable from '../../Elements/table/PreOrdersTable'
import UnpaidOrdersTable from '../../Elements/table/UnpaidOrdersTable'
import { fetchWoltDriverIntegrationsThunk } from '../../../redux/thunk/woltDriverIntegrations'
import InvoicesTable from '../../Elements/table/InvoicesTable'
import { getInvoicesThunk } from '../../../redux/thunk/invoice'

const defaultFilter = { processedByUsernameWithEmpty: null, orderType: 'DELIVERY,PICKUP,COUNTER,DELIVERY_BY_PLATFORM', closed: false }

const DeliveryTable = React.memo(() => {
	const dispatch = useDispatch()
	const [activeTab, setActiveTab] = useState('ORDERS')
	const defaultOrdersFilter = { ...defaultFilter, withOnlyRegisteredPreOrders: true }

	useEffect(() => {
		dispatch(fetchWoltDriverIntegrationsThunk())
		dispatch(getInvoicesThunk(0, 10))
	}, []) //eslint-disable-line react-hooks/exhaustive-deps

	const selectOrder = useCallback((order) => {
		dispatch(setSelectedOrder(order))
		dispatch(setModalOrderCustomer({ showOrder: true }))
	}, [dispatch])

	const handleTabClick = useCallback((tab) => {
		setActiveTab(tab)
	}, [])

	return (
		<>
			<OrdersTabs onTabClick={handleTabClick} activeTab={activeTab} />
			{activeTab === 'ORDERS' &&
				<OrdersTable id="client-cart-table"
										 reverse={false}
										 defaultFilter={defaultOrdersFilter}
										 onClick={selectOrder}
										 onEnter={selectOrder} />}
			{activeTab === 'PRE_ORDERS' &&
				<PreOrdersTable id="client-preorders-table"
												reverseToday={false}
												reverseDays={true}
												defaultFilter={defaultFilter}
												onClick={selectOrder}
												onEnter={selectOrder} />}
			{activeTab === 'UNPAID_ORDERS' &&
				<UnpaidOrdersTable id="client-unpaid-table"
										 reverse={false}
										 defaultFilter={defaultFilter}
										 onClick={selectOrder}
										 onEnter={selectOrder} />}
			{activeTab === 'INVOICES' &&
				<InvoicesTable id="client-invoices-table" />}
		</>
	)
})

DeliveryTable.displayName = 'DeliveryTable'
export default DeliveryTable
