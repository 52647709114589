import React, { useCallback } from 'react'
import MainIcon from '../../../../../img/svg/main'
import { ModalButtonContent, ModalButtonWrap } from '../index.styled'
import InfoMessage from '../../../message/InfoMessage'

const ModalButton = React.memo(({ index, button, isActive, onClick, isDisabled }) => {
	const layout = (isActive ? 'active ' : '') + (isDisabled ? 'disabled ' : '')

	const handleClick = useCallback((e) => {
		if (isDisabled) return
		onClick(e, button, index)
	}, [onClick, button, index, isDisabled])

	return (
		<ModalButtonContent>
			<ModalButtonWrap tabIndex={index}
											 data-testid={button.testId}
											 onClick={handleClick}
											 className={layout}>
				<MainIcon icon={button.icon} />
				{button.text}
			</ModalButtonWrap>
			{button?.message &&
				<InfoMessage fullWidth={true} center={button.message.center}>{button.message.text}</InfoMessage>}
		</ModalButtonContent>
	)
})

ModalButton.displayName = 'ModalButton'
export default ModalButton
