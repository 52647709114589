import { APPEND_PRODUCT_PERIOD_DAYS, APPEND_PRODUCTS, REMOVE_PRODUCT_PERIOD_DAYS, SET_CATEGORY_ID, SET_LOADING, SET_PRODUCT, SET_PRODUCT_CHANGED, SET_PRODUCT_CORS_ERROR, SET_PRODUCT_IMPORTING_STATUS, SET_PRODUCT_MODE, SET_PRODUCT_PERIOD_DAYS, SET_PRODUCT_PERIOD_END_DATE, SET_PRODUCT_PERIOD_END_TIME, SET_PRODUCT_PERIOD_IS_ACTIVE_TIME, SET_PRODUCT_PERIOD_START_DATE, SET_PRODUCT_PERIOD_START_TIME, SET_PRODUCT_TYPE, SET_PRODUCT_TYPE_BUSINESS_SCOPE, SET_PRODUCTS, SET_PRODUCTS_LENGTH, SET_PRODUCTS_UPDATE_PRODUCT, SET_PRODUCTS_ZUTAT, SET_PRODUCT_IMAGE_CONDITIONS, SET_PRODUCT_IMAGE_CONFIG, SET_PRODUCT_IMAGE_UPLOAD_STEP, SET_PRODUCT_IMAGE_ERROR_MODAL, SET_PRODUCTS_ADDITIONAL_SETTINGS, SET_PRODUCT_ADDITIONAL_SETTINGS_FILTER, SET_PRODUCTS_BY_PRICE_DESCRIPTIONS, APPEND_PRODUCTS_BY_PRICE_DESCRIPTIONS, SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LENGTH, SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LOADING } from '../../actionTypes/product'

export const setProductMode = (value) => ({ type: SET_PRODUCT_MODE, value })

export const setProductChanged = (value) => ({ type: SET_PRODUCT_CHANGED, value })

export const setProducts = (value) => ({ type: SET_PRODUCTS, value })

export const setProductsZutat = (value) => ({ type: SET_PRODUCTS_ZUTAT, value })

export const appendProducts = (value) => ({ type: APPEND_PRODUCTS, value })

export const setProductsTotalLength = (value) => ({ type: SET_PRODUCTS_LENGTH, value })

export const setProduct = (value) => ({ type: SET_PRODUCT, value })

export const setProductType = (value) => ({ type: SET_PRODUCT_TYPE, value })

export const setCategoryId = (value) => ({ type: SET_CATEGORY_ID, value })

export const setLoading = (value) => ({ type: SET_LOADING, value })

export const setProductsUpdateProduct = (value) => ({ type: SET_PRODUCTS_UPDATE_PRODUCT, value })

export const setProductTypeBusinessScope = (value) => ({ type: SET_PRODUCT_TYPE_BUSINESS_SCOPE, value })

export const setProductImportingStatus = (value) => ({ type: SET_PRODUCT_IMPORTING_STATUS, value })

export const setProductCorsError = (value) => ({ type: SET_PRODUCT_CORS_ERROR, value })

export const appendProductPeriodDays = (value) => ({ type: APPEND_PRODUCT_PERIOD_DAYS, value })

export const removeProductPeriodDays = (value) => ({ type: REMOVE_PRODUCT_PERIOD_DAYS, value })

export const setProductPeriodStartTime = (value) => ({ type: SET_PRODUCT_PERIOD_START_TIME, value })

export const setProductPeriodEndTime = (value) => ({ type: SET_PRODUCT_PERIOD_END_TIME, value })

export const setProductPeriodEndDate = (value) => ({ type: SET_PRODUCT_PERIOD_END_DATE, value })

export const setProductPeriodStartDate = (value) => ({ type: SET_PRODUCT_PERIOD_START_DATE, value })

export const setProductPeriodIsActiveTime = (value) => ({ type: SET_PRODUCT_PERIOD_IS_ACTIVE_TIME, value })

export const setProductPeriodDays = (value) => ({ type: SET_PRODUCT_PERIOD_DAYS, value })

export const setProductImageConditions = (value) => ({ type: SET_PRODUCT_IMAGE_CONDITIONS, value })

export const setProductImageConfig = (value) => ({ type: SET_PRODUCT_IMAGE_CONFIG, value })

export const setProductImageUploadStep = (value) => ({ type: SET_PRODUCT_IMAGE_UPLOAD_STEP, value })

export const setProductImageErrorModal = (value) => ({ type: SET_PRODUCT_IMAGE_ERROR_MODAL, value })

export const setProductsAdditionalSettings = (value) => ({ type: SET_PRODUCTS_ADDITIONAL_SETTINGS, value })

export const setProductAdditionalSettingsFilter = (value) => ({ type: SET_PRODUCT_ADDITIONAL_SETTINGS_FILTER, value })

export const setProductsByPriceDescriptions = (value) => ({ type: SET_PRODUCTS_BY_PRICE_DESCRIPTIONS, value })

export const appendProductsByPriceDescriptions = (value) => ({ type: APPEND_PRODUCTS_BY_PRICE_DESCRIPTIONS, value })

export const setProductsByPriceDescriptionsTotalLength = (value) => ({ type: SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LENGTH, value })

export const setProductsByPriceDescriptionsLoading = (value) => ({ type: SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LOADING, value })
