import { DELIVERY } from '../../../component/ToppingGroups/ToppingGroupsConfig/constants'
import { clone } from '../../../helper'
import { APPEND_ADDITIVES_GROUP_SELECTED_TOPPING, APPEND_ADDITIVES_GROUPS, SET_ADDITIVES_GROUPS_ORDER_BY_ASC, UPDATE_ADDITIVES_GROUPS_ORDER, SET_ADDITIVES_GROUPS_TOTAL_LENGTH, SET_ADDITIVES_GROUPS, APPEND_ADDITIVES_GROUP_TOPPINGS, REMOVE_ADDITIVES_GROUP_SELECTED_TOPPING, SET_ADDITIVES_GROUP, SET_ADDITIVES_GROUP_CATEGORY_ID, SET_ADDITIVES_GROUP_IS_SELECTED_ALL_TOPPINGS, SET_ADDITIVES_GROUP_SELECTED_TOPPINGS, SET_ADDITIVES_GROUP_TOPPINGS, SET_ADDITIVES_GROUP_TOPPINGS_TOTAL_LENGTH, SET_ADDITIVES_GROUP_TO_EDIT, SET_ADDITIVES_GROUP_TYPE_BUSINESS_SCOPE, SET_ADDITIVES_GROUP_SELECTED_PRODUCTS, APPEND_ADDITIVES_GROUP_SELECTED_PRODUCT, REMOVE_ADDITIVES_GROUP_SELECTED_PRODUCT, SET_ADDITIVES_GROUP_PRODUCTS, SET_ADDITIVES_GROUP_PRODUCTS_TOTAL_LENGTH, APPEND_ADDITIVES_GROUP_PRODUCTS } from '../../actionTypes/additivesGroups'
import { positionUpdater } from './helper'

const initialState = {
	categoryId: null,
	toppings: [],
	toppingsTotalLength: 0,
	isSelectedAllToppings: false,
	typeBusinessScope: DELIVERY,
	selectedToppings: [],
	toppingGroup: null,
	additivesGroups: [],
	additivesGroupsTotalLength: 0,
	additivesGroupToEdit: null,
	additivesGroupsOrderByAsc: true,
	selectedProducts: [],
	products: [],
	productsTotalLength: 0,
}

export function additivesGroupsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ADDITIVES_GROUP_CATEGORY_ID:
			return {
				...state,
				categoryId: action.value
			}
		case SET_ADDITIVES_GROUP_IS_SELECTED_ALL_TOPPINGS:
			return {
				...state,
				isSelectedAllToppings: action.value
			}
		case SET_ADDITIVES_GROUP_TYPE_BUSINESS_SCOPE:
			return {
				...state,
				typeBusinessScope: action.value
			}
		case SET_ADDITIVES_GROUP_TOPPINGS:
			return {
				...state,
				toppings: action.value
			}
		case SET_ADDITIVES_GROUP_TOPPINGS_TOTAL_LENGTH:
			return {
				...state,
				toppingsTotalLength: action.value
			}
		case APPEND_ADDITIVES_GROUP_TOPPINGS:
			return {
				...state,
				toppings: state.toppings.concat(...action.value)
			}
		case SET_ADDITIVES_GROUP_SELECTED_TOPPINGS:
			return {
				...state,
				selectedToppings: action.value
			}
		case APPEND_ADDITIVES_GROUP_SELECTED_TOPPING:
			return {
				...state,
				selectedToppings: [...state.selectedToppings, action.value]
			}

		case REMOVE_ADDITIVES_GROUP_SELECTED_TOPPING:
			return {
				...state,
				selectedToppings: state.selectedToppings.filter(toppingId => toppingId !== action.value)
			}

		case SET_ADDITIVES_GROUP:
			return {
				...state,
				toppingGroup: action.value
			}
		case SET_ADDITIVES_GROUPS:
			return {
				...state,
				additivesGroups: action.value,
			}
		case APPEND_ADDITIVES_GROUPS:
			return {
				...state,
				additivesGroups: state.additivesGroups.concat(...action.value),
			}
		case SET_ADDITIVES_GROUPS_TOTAL_LENGTH:
			return {
				...state,
				additivesGroupsTotalLength: action.value,
			}
		case SET_ADDITIVES_GROUPS_ORDER_BY_ASC:
			return {
				...state,
				additivesGroupsOrderByAsc: action.value,
			}
		case UPDATE_ADDITIVES_GROUPS_ORDER:
			const additivesGroupsCopy = clone(state.additivesGroups)
			const orderByAsc = state.additivesGroupsOrderByAsc
			return {
				...state,
				additivesGroups: positionUpdater(additivesGroupsCopy, orderByAsc, action.value),
			}
		case SET_ADDITIVES_GROUP_TO_EDIT:
			return {
				...state,
				additivesGroupToEdit: action.value,
			}

		case SET_ADDITIVES_GROUP_SELECTED_PRODUCTS:
			return {
				...state,
				selectedProducts: action.value
			}
		case APPEND_ADDITIVES_GROUP_SELECTED_PRODUCT:
			return {
				...state,
				selectedProducts: [...state.selectedProducts, action.value]
			}
		case REMOVE_ADDITIVES_GROUP_SELECTED_PRODUCT:
			return {
				...state,
				selectedProducts: state.selectedProducts.filter(productId => productId !== action.value)
			}
		case SET_ADDITIVES_GROUP_PRODUCTS:
			return {
				...state,
				products: action.value
			}
		case SET_ADDITIVES_GROUP_PRODUCTS_TOTAL_LENGTH:
			return {
				...state,
				productsTotalLength: action.value
			}
		case APPEND_ADDITIVES_GROUP_PRODUCTS:
			return {
				...state,
				products: state.products.concat(...action.value)
			}

		default:
			return state
	}
}

