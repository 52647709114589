import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setModalLoginInvalidPasswordError, setModalLoginLoading } from '../../../redux/action/modalLogin'
import { selectHasAnyGroup, selectIsNeededZIndex } from '../../../redux/selector/system'
import { getModalLoginUserPinCodeSettingsThunk, getModalLoginUserPinCodeThunk } from '../../../redux/thunk/modalLogin'
import colors from '../../../style/colors'
import Button from '../buttons/Button'
import KeyBindings from '../keybindings'
import Loader from '../loaders'
import CustomModal from '../modal/CustomModal'
import ModalLogin from '../modalLogin'
import NumbersKeyboard from './NumbersKeyboard'
import PinCodeInputControlled from './PinCodeInputControlled'
import { ErrorMessageText, ErrorMessageWrapper, PinCodeInfoText, PinCodeModalLoginWrapper } from './index.styled'
import MainIcon from '../../../img/svg/main'

const pinCodeLength = 4
const emptyPinCodeArray = Array(pinCodeLength).fill('')

const EVENT_Z_INDEX = 57

const PinCodeModalLogin = React.memo(({
	isOpen, close, module, group, title, onLogin = () => {
	}, returnZIndex
}) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const isRestaurantMonitor = useSelector(selectHasAnyGroup(['RESTAURANT_MONITOR']))
	const loading = useSelector(store => store.modalLogin.loading)
	const invalidPasswordError = useSelector(store => store.modalLogin.invalidPasswordError)
	const pinCodeInputRefs = useRef([])
	const [pinCodeArrayValue, setPinCodeArrayValue] = useState(emptyPinCodeArray)
	const [currentFocusedIndex, setCurrentFocusedIndex] = useState(0)
	const [fullPasswordModalLoginIsOpen, setFullPasswordModalLoginIsOpen] = useState(false)
	const pinCodeIsComleted = pinCodeArrayValue.every(number => !!number)

	const dispatch = useDispatch()

	useEffect(() => {
		if (isRestaurantMonitor && module) {
			dispatch(getModalLoginUserPinCodeSettingsThunk(module))
		}
	}, [isRestaurantMonitor, module])

	useEffect(() => {
		if (isOpen) {
			focusInput(0)
		}
	}, [isOpen])

	useEffect(() => {
		if (pinCodeIsComleted) {
			const timeOutId = setTimeout(() => {
				if (isOpen) handleEnter()
			}, 300)
			return () => clearTimeout(timeOutId)
		}
	}, [pinCodeIsComleted])

	const handleReset = useCallback(() => {
		setPinCodeArrayValue(emptyPinCodeArray)
		focusInput(0)
	}, [])

	const handleChangeNumberAtFocus = useCallback((value) => {
		const newValueArray = [...pinCodeArrayValue]
		newValueArray.splice(currentFocusedIndex, 1, value)
		setPinCodeArrayValue(newValueArray)
		if (invalidPasswordError) dispatch(setModalLoginInvalidPasswordError(false))
	}, [pinCodeArrayValue, currentFocusedIndex, invalidPasswordError])

	const handleKeyboardType = useCallback((value) => {
		handleChangeNumberAtFocus(value)
		focusInput(currentFocusedIndex + 1)
	}, [currentFocusedIndex, handleChangeNumberAtFocus])

	const focusInput = useCallback((index) => {
		const activeInput = Math.max(Math.min(pinCodeLength - 1, index), 0)

		if (pinCodeInputRefs.current[activeInput]) {
			pinCodeInputRefs.current[activeInput].focus()
			setCurrentFocusedIndex(activeInput)
		}
	}, [])

	const handleFocus = useCallback((index) => {
		setCurrentFocusedIndex(index)
	}, [])

	const handleOnLogin = useCallback(() => {
		onLogin()
		handleClose()
	}, [onLogin])

	const handleEnter = useCallback(() => {
		const pinCode = pinCodeArrayValue.join('')
		if (pinCode) {
			dispatch(setModalLoginLoading(true))
			dispatch(setModalLoginInvalidPasswordError(false))
			dispatch(getModalLoginUserPinCodeThunk(pinCode, handleOnLogin))
			handleReset()
		}
	}, [pinCodeArrayValue])

	const handleClose = useCallback(() => {
		close()
		dispatch(setModalLoginInvalidPasswordError(false))
		handleReset()
	}, [close])

	const handleOpenFullPasswordModal = useCallback(() => {
		handleClose()
		setFullPasswordModalLoginIsOpen(true)
	}, [])

	const handleCloseFullPasswordModal = useCallback(() => {
		setFullPasswordModalLoginIsOpen(false)
	}, [])

	return (
		<>
			<CustomModal isOpen={isOpen}
				title={title}
				id="pin-code-modal-login"
				close={handleClose}
				returnZIndex={returnZIndex}
				disableBackButton={true}
				showRecaptcha={true}
				zIndex={EVENT_Z_INDEX}>
				{isOpen &&
					<PinCodeModalLoginWrapper>
						<PinCodeInfoText>{t('Login.pinCodeModalLogin.enterPassword')}</PinCodeInfoText>
						<PinCodeInputControlled inputRefs={pinCodeInputRefs}
							size="big"
							pinCodeArrayValue={pinCodeArrayValue}
							changeCodeAtFocus={handleChangeNumberAtFocus}
							currentFocusedIndex={currentFocusedIndex}
							focusInput={focusInput}
							onFocus={handleFocus}
							password={true}
							error={invalidPasswordError}
							zIndex={EVENT_Z_INDEX} />
						{invalidPasswordError &&
							<ErrorMessageWrapper>
								<MainIcon icon="error" />
								<ErrorMessageText>{t('Login.pinCodeModalLogin.errorPasswordMessage')}</ErrorMessageText>
							</ErrorMessageWrapper>}
						<NumbersKeyboard handleClick={handleKeyboardType}
							enter={handleEnter}
							reset={handleReset} />
						<Button color="blue" disabled={loading} onClick={handleOpenFullPasswordModal}>
							{loading ? <Loader color={colors.white} /> : t('Login.pinCodeModalLogin.fullPasswordButton')}
						</Button>
					</PinCodeModalLoginWrapper>}
			</CustomModal>

			<ModalLogin isOpen={fullPasswordModalLoginIsOpen}
				close={handleCloseFullPasswordModal}
				group={group}
				title={title}
				onLogin={onLogin}
				returnZIndex={returnZIndex} />

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter} />}
		</>
	)
})

PinCodeModalLogin.displayName = 'PinCodeModalLogin'
export default PinCodeModalLogin
