/* eslint no-console: off */
import { getAuthenticationThunk } from '../registration/auth'
import { connectSocketIo } from '../../../socket'
import { setSocketIoClient, setSocketReconnected } from '../../action/socket'
import { processPhoneMessageThunk } from '../../../socket/phone'
import { processPrinterMessageThunk } from '../../../socket/printer'
import { processCustomerMessageThunk } from '../../../socket/customer'
import { processRegistrationMessageThunk } from '../../../socket/registration'
import { processRestaurantMessageThunk } from '../../../socket/restaurant'
import { processOrderViewMessageThunk } from '../../../socket/order'
import { format } from 'date-fns'
import { processCashBookMessageThunk } from '../../../socket/cashbook'
import { processFiscalMessageThunk } from '../../../socket/fiscal'
import { processProductMessageThunk } from '../../../socket/product'
import { processPaymentMessageThunk } from '../../../socket/payment'
import { processKitchenMessageThunk } from '../../../socket/kitchen'
import { processWoltMessageThunk } from '../../../socket/wolt'
import { processCashDeskMessageThunk } from '../../../socket/cashdesk'

export const connectSocketIoThunk = () => (dispatch, getState) => {
	if (!getState().socket.socketIoClient?.connected) {
		console.info('[socket-io] Try to connect socket')
		const accessToken = getState().login.accessToken
		connectSocketIo(accessToken,
			socketIoClient => dispatch(setSocketIoClient(socketIoClient)),
			() => dispatch(getAuthenticationThunk()),
			() => dispatch(setSocketReconnected(true)),
			message => {
				switch (message?.context) {
					case 'phone':
						dispatch(processPhoneMessageThunk(message))
						break
					case 'printer':
						dispatch(processPrinterMessageThunk(message))
						break
					case 'customer':
						dispatch(processCustomerMessageThunk(message))
						break
					case 'registration':
						dispatch(processRegistrationMessageThunk(message))
						break
					case 'restaurant':
						dispatch(processRestaurantMessageThunk(message))
						break
					case 'order-view':
						dispatch(processOrderViewMessageThunk(message))
						break
					case 'cashbook':
						dispatch(processCashBookMessageThunk(message))
						break
					case 'fiscal':
						dispatch(processFiscalMessageThunk(message))
						break
					case 'product':
						dispatch(processProductMessageThunk(message))
						break
					case 'payment':
						dispatch(processPaymentMessageThunk(message))
						break
					case 'kitchen':
						dispatch(processKitchenMessageThunk(message))
						break
					case 'wolt-plugin':
						dispatch(processWoltMessageThunk(message))
						break
					case 'cashdesk':
						dispatch(processCashDeskMessageThunk(message))
						break
					default:
						console.log('Unknown message context: ', message)
						break
				}
			})
	}
}

export const connectSocketClientMonitorIoThunk = () => (dispatch, getState) => {
	if (!getState().socket.socketIoClient?.connected) {
		console.info(`Try to connect socket.io ... ${format(new Date(), 'HH:mm:ss')}`)
		const deviceId = window.localStorage.getItem('desktopDeviceId')
		const authUuid = window.localStorage.getItem('authUuid')

		connectSocketIo(null,
			socketIoClient => dispatch(setSocketIoClient(socketIoClient)),
			() => {},
			() => dispatch(setSocketReconnected(true)),
			message => {
				switch (message?.context) {
					case 'cashdesk':
						dispatch(processCashDeskMessageThunk(message))
						break
					default:
						console.log('Unknown message context: ', message)
						break
				}
			}, true, deviceId, authUuid)
	}
}

export const disconnectSocketThunk = () => (dispatch, getState) => {
	let socketIoClient = getState().socket.socketIoClient
	if (socketIoClient) {
		console.info('[socket-io] Called disconnect socket')
		socketIoClient.disconnect()
		dispatch(setSocketIoClient(null))
	}
}
