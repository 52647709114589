export const staticPages = [
	{ title: 'LeftBar.menu.home', link: '/home', id: 'HOME' },
	{ title: 'LeftBar.menu.all_orders', link: '/all-orders', id: 'ORDERS' },
	{ title: 'LeftBar.menu.delivery_service', link: '/delivery', id: 'DELIVERY_SERVICE' },
	{ title: 'LeftBar.menu.cash_desk', link: '/cashdesk', id: 'CASH_DESK' },
	{ title: 'LeftBar.menu.restaurant', link: '/restaurant', id: 'RESTAURANT' },
	{ title: 'LeftBar.menu.kitchen', link: '/kitchen-monitor', id: 'KITCHEN_MONITOR' },
	{ title: 'LeftBar.menu.driver_monitor', link: '/driver-monitor', id: 'DRIVER_MONITOR' },
	{ title: 'LeftBar.menu.reports', link: '/report', id: 'REPORTS' },
	{ title: 'LeftBar.menu.partnerPortal', link: '/partner-portal', id: 'PARTNER_PORTAL' },
	{ title: 'LeftBar.menu.settings', link: '/settings', id: 'PROGRAM_SETTINGS' },
	{ title: 'LeftBar.menu.help', link: '/help', id: 'HELP', static: true },
]
