import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const TimeWrapper = styled.label`
  font-size: 1rem;
  display: flex;
  align-items: center;
	justify-content: center;
  width: 4.81em;
  min-height: 3.19em;
  background: ${colorTheme.input.time.background};
  border-radius: 0.75em;
	border: 1px solid ${colorTheme.input.time.border};
  padding: 0 0.5em;

	&.disabled {
		-webkit-text-fill-color: ${colorTheme.input.time.disabled.text};
		opacity: 1;
		border-color: ${colorTheme.input.time.disabled.border};
	}

	&.error {
		border-color: ${colorTheme.input.time.error.border};
	}

	&.success {
		border-color: ${colorTheme.input.time.success.border};
	}

	&.focus {
		border-color: ${colorTheme.input.time.focus.border};
	}

	&.onlyHours, &.onlyMinutes {
		width: 3.19em;
	}

	&.big {
		min-height: 4.19em;
		width: 8.13em;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.big {
			min-height: 3.93em;
			width: 6.29em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.big {
			min-height: 3.44em;
			width: 5.5em;
		}
	}
`

export const TimeInputField = styled.input`
  width: 100%;
  font-size: 1.13em;
  font-family: ${theme.fontRobotoBold};
	font-weight: 700;
  background: transparent;
  border: none;
  color: ${colorTheme.input.time.text};
	cursor: pointer;
	text-align: center;

	&.disabled {
		cursor: not-allowed;
	}

	&.error {
		color: ${colorTheme.input.time.error.text};
	}

	&.focus {
		color: ${colorTheme.input.time.focus.text};
	}
`
