import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const KitchenMonitor = lazyWithRetry(() => import(/* webpackChunkName: "kitchen-monitor" */'../../component/KitchenMonitor'))

const KitchenMonitorPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<KitchenMonitor />
		</Suspense>
	)
})

KitchenMonitorPage.displayName = 'KitchenMonitorPage'
export default KitchenMonitorPage
