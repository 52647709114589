import styled from 'styled-components'
import { colorTheme } from '../../../style/theme/colorTheme'

export const WaiterCallWrap = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 3.56em;
	z-index: 100;
	box-shadow: 0px 15px 34px 0px rgba(0, 0, 0, 0.60);
	border-radius: 0.75em;
`
WaiterCallWrap.displayName = 'WaiterCallWrap'

export const WaiterCallContent = styled.div`
    display: flex;
    padding: 0.1em 0.63em;
    justify-content: center;
    align-items: center;
    gap: 0.32em;
    height: 1.68em;
    border-radius: 0.5em 0.5em 0 0;
    background: ${colorTheme.restaurant.waiterCall.background};

    > svg {
        width: 1.75em;
        height: 1.25em;
    }
`
WaiterCallContent.displayName = 'WaiterCallContent'

export const WaiterCallText = styled.div`
    color: ${colorTheme.restaurant.waiterCall.text};
    font-size: 1em;
    font-weight: 500;
`
WaiterCallText.displayName = 'WaiterCallText'

export const WaiterCallAreas = styled.div`
    display: flex;
    min-width: 11.87em;
    max-width: 96.87em;
    height: 3.56em;
    padding: 0.1em 0.63em;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 0.63em;
    border-radius: 0 0 0.75em 0.75em;
    border-top: 5px solid ${colorTheme.restaurant.waiterCall.border};
    background: ${colorTheme.restaurant.waiterCall.areas.background};
`
WaiterCallAreas.displayName = 'WaiterCallAreas'

export const WaiterCallAreaButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`
WaiterCallAreaButton.displayName = 'WaiterCallAreaButton'

export const WaiterCallAreaButtonContent = styled.div`
    display: flex;
    padding: 0 0.25em 0 0.5em;
    align-items: center;
    gap: 0.32em;
    background: ${props => props.color};
    min-height: 1.875em;
    border-radius: 0.5em 0 0 0.5em;
`
WaiterCallAreaButtonContent.displayName = 'WaiterCallAreaButtonContent'

export const WaiterCallAreaButtonWaiter = styled.div`
    display: flex;
    padding: 0 0.5em 0 0.37em;
    align-items: center;
    gap: 0.32em;
    background: ${colorTheme.restaurant.waiterCall.areas.waiter.background};
    min-height: 2em;
    border-radius: 0 0.5em 0.5em 0;

    > svg {
        width: 1.37em;
        height: 0.93em;
        fill: ${colorTheme.restaurant.waiterCall.areas.waiter.icon};
    }
`
WaiterCallAreaButtonWaiter.displayName = 'WaiterCallAreaButtonWaiter'

export const WaiterCallAreaText = styled.div`
    color: ${colorTheme.restaurant.waiterCall.areas.area.text};
    font-size: 0.87em;
    font-weight: 600;

    &.waiter {
        color: ${colorTheme.restaurant.waiterCall.areas.waiter.text};
        font-weight: 500;
    }
`
WaiterCallAreaText.displayName = 'WaiterCallAreaText'
