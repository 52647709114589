import axios from 'axios'
import requestInterceptor from '../../requestInterceptor'
import responseInterceptor from '../../responseInterceptor'
import { customerServiceUrl } from '../../../../urls'
import { getLocalStorageAccessToken } from '../../../../helper/clientMonitor'

const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
}

const accessToken = getLocalStorageAccessToken()

if (accessToken) {
  headers.Authorization = `Bearer ${accessToken}`
}

const customerInstance = axios.create({
  baseURL: customerServiceUrl,
  headers,
})

customerInstance.interceptors.request.use(requestInterceptor, error => Promise.reject(error))

customerInstance.interceptors.response.use(response => response, error => {
  return responseInterceptor(error)
})

export default customerInstance
