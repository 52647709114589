import styled from 'styled-components'
import { InputWrapper } from '../inputs/TextInput/index.styled'
import { RecaptchaWrapper } from '../Recaptcha/index.styled'
import { theme } from '../../../style/theme'

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	${RecaptchaWrapper} {
		margin-top: 40px;

		@media screen and (max-width: ${theme.point720}) {
			margin-top: auto;
		}
	}

	${InputWrapper} {
		width: 100%;
		margin-top: 30px;
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 100%;
	}
`
Form.displayName = 'Form'

export const ButtonsWrap = styled.div`
	font-size: 1rem;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	width: 609px;

	> button {
		width: 180px;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 30px;
		width: 100%;
		gap: 10px;

		> button {
			width: 100%;
		}
	}

`
ButtonsWrap.displayName = 'ButtonsWrap'
