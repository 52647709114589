import { APPEND_PARTNER_CLIENTS_REPORTS, APPEND_PARTNER_COMPANIES, SET_PARTNER_CLIENTS_REPORTS, SET_PARTNER_CLIENTS_REPORTS_LENGTH, SET_PARTNER_CLIENTS_REPORTS_MODAL, SET_PARTNER_COMPANIES, SET_PARTNER_COMPANIES_FILTER, SET_PARTNER_COMPANIES_IS_LOADING, SET_PARTNER_COMPANIES_LENGTH, SET_PARTNER_COMPANIES_TOTALS, SET_PARTNER_DEACTIVATION_STRIPE_DATA, SET_PARTNER_DEALER_COMPANIES_FILTER, SET_PARTNER_DEALER_PROVISION, SET_PARTNER_DEALER_REPORT_DATA, SET_PARTNER_DEALER_REPORT_DATE_FROM, SET_PARTNER_DEALER_REPORT_DATE_TO, SET_PARTNER_FILTER, SET_PARTNER_INCLUDE_PARTNER_DEALERS, SET_PARTNER_MORE_COMPANIES_IS_LOADING, SET_PARTNER_PROLONGATION, SET_PARTNER_SHOW_CALENDAR, SET_SHOW_PARTNER_DEALERS_CHECKBOX, UPDATE_PARTNER_COMPANIES } from '../../actionTypes/partnerPortal'

const initialState = {
	dealerCompaniesFilter: [],
	companiesFilter: [],
	companies: [],
	companiesLength: 0,
	companiesIsLoading: false,
	filterSearch: { dateFrom: new Date(), dateTo: new Date() },
	companiesTotals: null,
	showClientsReportsModal: false,
	clientsReportsData: [],
	dealerReportData: null,
	dealerReportDateFrom: null,
	dealerReportDateTo: null,
	clientsReportsDataLength: 0,
	moreCompaniesIsLoading: false,
	showCalendar: false,
	showPartnerDealersCheckbox: false,
	includePartnerDealers: true,
	prolongation: null,
	deactivationStripeData: null
}

export function partnerPortalReducer (state = initialState, action) {
	switch (action.type) {
		case SET_PARTNER_DEALER_COMPANIES_FILTER:
			return {
				...state,
				dealerCompaniesFilter: action.value,
			}
		case SET_PARTNER_COMPANIES_FILTER:
			return {
				...state,
				companiesFilter: action.value,
			}
		case SET_PARTNER_COMPANIES:
			return {
				...state,
				companies: action.value,
			}
		case APPEND_PARTNER_COMPANIES:
			const filteredCompanies = state.companies.concat(...action.value)?.filter((item, index, self) =>
				index === self.findIndex(t => t?.companyIndex === item?.companyIndex)
			)
			return {
				...state,
				companies: filteredCompanies,
			}
		case UPDATE_PARTNER_COMPANIES:
			const updatedPartnerCompanies = [...state.companies].map(item => item.companyIndex === action.value.companyIndex ? { ...item, stripeAccount: { ...item.stripeAccount, status: action.value.status } } : item)
			return {
				...state,
				companies: updatedPartnerCompanies,
			}
		case SET_PARTNER_COMPANIES_TOTALS:
			return {
				...state,
				companiesTotals: action.value,
			}
		case SET_PARTNER_COMPANIES_LENGTH:
			return {
				...state,
				companiesLength: action.value,
			}
		case SET_PARTNER_COMPANIES_IS_LOADING:
			return {
				...state,
				companiesIsLoading: action.value,
			}
		case SET_PARTNER_FILTER:
			return {
				...state,
				filterSearch: action.value,
			}
		case SET_PARTNER_CLIENTS_REPORTS_MODAL:
			return {
				...state,
				showClientsReportsModal: action.value,
			}
		case SET_PARTNER_DEALER_PROVISION:
			return {
				...state,
				dealerProvision: action.value,
			}
		case SET_PARTNER_CLIENTS_REPORTS:
			return {
				...state,
				clientsReportsData: action.value,
			}
		case APPEND_PARTNER_CLIENTS_REPORTS:
			return {
				...state,
				clientsReportsData: state.clientsReportsData.concat(...action.value),
			}
		case SET_PARTNER_CLIENTS_REPORTS_LENGTH:
			return {
				...state,
				clientsReportsDataLength: action.value,
			}
		case SET_PARTNER_DEALER_REPORT_DATA:
			return {
				...state,
				dealerReportData: action.value,
			}
		case SET_PARTNER_DEALER_REPORT_DATE_FROM:
			return {
				...state,
				dealerReportDateFrom: action.value,
			}
		case SET_PARTNER_DEALER_REPORT_DATE_TO:
			return {
				...state,
				dealerReportDateTo: action.value,
			}
		case SET_PARTNER_MORE_COMPANIES_IS_LOADING:
			return {
				...state,
				moreCompaniesIsLoading: action.value,
			}
		case SET_PARTNER_SHOW_CALENDAR:
			return {
				...state,
				showCalendar: action.value,
			}
		case SET_SHOW_PARTNER_DEALERS_CHECKBOX:
			return {
				...state,
				showPartnerDealersCheckbox: action.value,
			}
		case SET_PARTNER_INCLUDE_PARTNER_DEALERS:
			return {
				...state,
				includePartnerDealers: action.value,
			}
		case SET_PARTNER_PROLONGATION:
			return {
				...state,
				prolongation: action.value,
			}
		case SET_PARTNER_DEACTIVATION_STRIPE_DATA:
			return {
				...state,
				deactivationStripeData: action.value
			}
		default:
			return state
	}
}
