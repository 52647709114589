import { SET_AUTOCOMPLETE_GEO, SET_CUSTOMER, SET_CUSTOMER_ADDRESS_IS_NOT_FULL, SET_CUSTOMER_CALLS, SET_CUSTOMER_DELIVERY_COST, SET_CUSTOMER_EVENT, SET_CUSTOMER_GEO_DATA, SET_CUSTOMER_GEO_DATA_LOADING, SET_CUSTOMER_INCOMING_CALL, SET_CUSTOMER_MODAL, SET_CUSTOMERS_BY_EMAIL, SET_CUSTOMERS_BY_NAME, SET_CUSTOMERS_BY_NUMBER, SET_CUSTOMERS_BY_PHONE, SET_CUSTOMERS_BY_COMPANY_NAME } from '../../actionTypes/customer'

export const setCustomer = value => ({ type: SET_CUSTOMER, value })

export const setCustomerDeliveryCost = value => ({ type: SET_CUSTOMER_DELIVERY_COST, value })

export const setCustomerGeoData = value => ({ type: SET_CUSTOMER_GEO_DATA, value })

export const setAutocompleteGeo = value => ({ type: SET_AUTOCOMPLETE_GEO, value })

export const setCustomerCalls = value => ({ type: SET_CUSTOMER_CALLS, value })

export const setCustomerIncomingCall = value => ({ type: SET_CUSTOMER_INCOMING_CALL, value })

export const setModalOrderCustomer = value => ({ type: SET_CUSTOMER_MODAL, value })

export const setEventOrderCustomer = value => ({ type: SET_CUSTOMER_EVENT, value })

export const setCustomersByName = value => ({ type: SET_CUSTOMERS_BY_NAME, value })

export const setCustomersByPhone = value => ({ type: SET_CUSTOMERS_BY_PHONE, value })

export const setCustomersByNumber = value => ({ type: SET_CUSTOMERS_BY_NUMBER, value })

export const setCustomersByEmail = value => ({ type: SET_CUSTOMERS_BY_EMAIL, value })

export const setCustomersByCompanyName = value => ({ type: SET_CUSTOMERS_BY_COMPANY_NAME, value })

export const setCustomerAddressIsNotFull = value => ({ type: SET_CUSTOMER_ADDRESS_IS_NOT_FULL, value })

export const setCustomerGeoDataLoading = value => ({ type: SET_CUSTOMER_GEO_DATA_LOADING, value })
