import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const InfoShieldBlock = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.25em;
  position: relative;
  z-index: 1;
	justify-self: end;

	@media screen and (max-width: ${theme.point820}) {
		grid-column: 1/3;
    grid-row: 1;
		column-gap: 1.43em;
		justify-self: auto;
	}

	@media screen and (max-width: ${theme.point720}) {
		column-gap: 1.25em;
	}
`

InfoShieldBlock.displayName = 'InfoShieldBlock'

export const InfoShieldItem = styled.div`
	color: ${colorTheme.table.infoShield.textItem};
  display: flex;
  align-items: center;
  font-family: ${theme.fontRobotoMedium};
  font-size: 1.13em;
  column-gap: 0.44em;
	width: max-content;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.28em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;
	}
`
InfoShieldItem.displayName = 'InfoShieldItem'

export const InfoShieldData = styled.div`
  padding: 0.22em 0.56em;
  border-radius: 0.67em;
  background: ${colorTheme.table.infoShield.background};
  color: ${colorTheme.table.tableTr.tableLabel.color};
`
InfoShieldData.displayName = 'InfoShieldData'
