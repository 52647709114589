export const SET_ORDERSMART_RESTAURANTS = 'SET_ORDERSMART_RESTAURANTS'
export const SET_ORDERSMART_RESTAURANTS_TOTAL_LENGTH = 'SET_ORDERSMART_RESTAURANTS_TOTAL_LENGTH'
export const SET_ORDERSMART_RESTAURANT = 'SET_ORDERSMART_RESTAURANT'
export const APPEND_ORDERSMART_RESTAURANTS = 'APPEND_ORDERSMART_RESTAURANTS'
export const ADD_ORDERSMART_TESTED_RESTAURANT = 'ADD_ORDERSMART_TESTED_RESTAURANT'
export const SET_ORDERSMART_ORDER_COUNT = 'SET_ORDERSMART_ORDER_COUNT'
export const SET_ORDERSMART_SHOW_TEST_SUCCESS_MODAL = 'SET_ORDERSMART_SHOW_TEST_SUCCESS_MODAL'
export const SET_ORDERSMART_SHOW_TEST_CLIENT_ERROR_MODAL = 'SET_ORDERSMART_SHOW_TEST_CLIENT_ERROR_MODAL'
export const SET_ORDERSMART_SHOW_TEST_SERVER_ERROR_MODAL = 'SET_ORDERSMART_SHOW_TEST_SERVER_ERROR_MODAL'
export const SET_ORDERSMART_TEST_ERROR_STATUS = 'SET_ORDERSMART_TEST_ERROR_STATUS'
