import styled from 'styled-components'
import { colorTheme } from '../../../style/theme/colorTheme'

export const WrapperLoader = styled.div`
  display: flex;
  position: relative;
  left: ${props => props.zoom * 5}px;
  width: ${props => props.zoom * 100}px;
  align-items: center;
  margin: auto;

  div {
    position: absolute;
    width: ${props => props.zoom * 10}px;
    height: ${props => props.zoom * 10}px;
    border-radius: 50%;
    background: ${props => props.color ? props.color : colorTheme.loaders.background};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: ${props => props.zoom * 8}px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: ${props => props.zoom * 8}px;
      --leftPosition: ${props => props.zoom * 24}px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: ${props => props.zoom * 32}px;
      --leftPosition: ${props => props.zoom * 24}px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: ${props => props.zoom * 56}px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(var(--leftPosition), 0);
    }
  }
`
