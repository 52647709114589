import { retrieveErrorFromApi } from '../../../../helper'
import { setRestaurantModalChangeCalculatorIsNeeded } from '../../../action/restaurant'
import { setAppErrors } from '../../../action/system'
import getChangeSetting from '../../../axios/registration/changeSettings/get'

export const getRestaurantModalChangeCalculatorIsNeededThunk = () => dispatch => {
	return getChangeSetting('RESTAURANT', 'RESTAURANT')
		.then((res) => {
			dispatch(setRestaurantModalChangeCalculatorIsNeeded(res.data.showCalculatorWithChange))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
