import styled from 'styled-components'
import colors from '../../../../style/colors'
import { theme } from '../../../../style/theme'

export const IconsInputWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	row-gap: 20px;
	column-gap: 7px;
	width: 608px;

	@media screen and (max-width: ${theme.point720}) {
		width: auto;
		display: flex;
		flex-wrap: wrap;
		row-gap: 20px;
		column-gap: 47px;
		justify-content: center;
		margin-bottom: 30px;
	}
`
IconsInputWrapper.displayName = 'IconsInputWrapper'

export const IconItem = styled.div`
	display: grid;
	justify-content: center;
	cursor: pointer;

	@media screen and (max-width: ${theme.point720}) {
		width: 120px;
	}

	.icon-img {
		width: 80px;
		height: 80px;
		display: flex;
		border-radius: 10px;
		background-color: #A4ACB8;
		padding: 10px 15px;
		margin: 0 auto;

		svg path, svg circle {
			fill: ${colors.white};
		}
	}

	.icon-text {
		font-family: ${theme.fontRobotoMedium};
		margin-top: 10px;
		font-size: 16px;
		color: ${colors.gray_dark};
		line-height: 19px;
		max-width: 120px;
	}

	.icon-green {
		background-color: ${colors.green};
	}

	.black {
		color: ${colors.black};

	}
`
IconItem.displayName = 'IconItem'
