import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOrderEvent } from '../../../../../redux/action'
import { useTranslation } from 'react-i18next'
import { setOrderStatusThunk } from '../../../../../redux/thunk/order/setOrderStatus'
import { setModalOrderCustomer } from '../../../../../redux/action/customer'
import { setAppEventZIndex } from '../../../../../redux/action/system'
import ButtonsModal from '../../../../Elements/modal/ButtonsModal'

const EVENT_Z_INDEX = 2

const CancelOrder = React.memo(() => {
	const cancelReasonOptions = useSelector(store => store.drivermonitor.cancelReasonOptions)
	const selectedOrder = useSelector(store => store.order.selectedOrder)
	const showCancelOrder = useSelector(store => store.customerStore.modal.showCancelOrder)
	const cancelReasonFoodoraOptions = useSelector(store => store.drivermonitor.cancelReasonFoodoraOptions)
	const isFoodoraPlugin = selectedOrder?.platformMetaData?.platformType === 'FOODORA_PLUGIN'
	const cancelReasons = isFoodoraPlugin ? cancelReasonFoodoraOptions : cancelReasonOptions
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleBack = useCallback(() => {
		dispatch(setModalOrderCustomer({ showCancelOrder: false }))
		dispatch(setModalOrderCustomer({ showOrder: true }))
		dispatch(setAppEventZIndex(1))
		dispatch(setOrderEvent({ eventOrder: true }))
	}, [dispatch])

	const handleChangeReason = useCallback(async (event, reason) => {
		if (event) event.preventDefault()
		if (isFoodoraPlugin) {
			const reasonCode = reason
			await dispatch(setOrderStatusThunk(selectedOrder, 'CANCELED', reason, reasonCode))
		} else {
			await dispatch(setOrderStatusThunk(selectedOrder, 'CANCELED', reason))
		}
		dispatch(setModalOrderCustomer({ showCancelOrder: false }))
		dispatch(setModalOrderCustomer({ showOrder: false }))
		dispatch(setAppEventZIndex(0))
	}, [selectedOrder, isFoodoraPlugin, dispatch])

	const buttons = useMemo(() => cancelReasons?.map((option, i) =>
		({ icon: 'cancel', text: t(option.label), onClick: e => handleChangeReason(e, option.value), show: true })), [cancelReasons, t, handleChangeReason])

	return (
		<ButtonsModal isOpen={showCancelOrder}
									close={handleBack}
									title={t('order.modal.want_cancel_order')}
									size="small"
									zIndex={EVENT_Z_INDEX}
									buttons={buttons} />
	)
})

CancelOrder.displayName = 'CancelOrder'
export default CancelOrder
