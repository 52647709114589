import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const RadioWrapper = styled.label`
	font-size: 1rem;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	user-select: none;

	gap: 0.67em;

	&.start {
		align-items: flex-start;
	}

	input {
		display: none;
	}

	&.grid {
		display: grid;
		grid-template-columns: max-content 1fr;
	}

	&.box {
		padding: .2em .63em;
		height: 4.19em;
		justify-content: center;
		align-items: center;
		gap: 1.25em;

		border-radius: .75em;
		border: 1px solid ${colorTheme.input.radio.hasBorder.border};
		background: ${colorTheme.input.radio.hasBorder.background};
	}

	&.box.active {
		background: ${colorTheme.input.radio.hasBorder.active.background};
		border: 1px solid ${colorTheme.input.radio.hasBorder.active.border};
	}

	@media screen and (max-width: ${theme.point820}) {
		&.start-mb {
			align-items: flex-start;
		}
	}

`
RadioWrapper.displayName = 'RadioWrapper'

export const RadioLabel = styled.div`
	font-size: 1em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 400;
	color: ${colorTheme.input.radio.text};
	text-align: left;

	&.active {
		color: ${colorTheme.input.radio.active.text};
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.28em;
		font-weight: 500;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.125em;
	}
`
RadioLabel.displayName = 'RadioLabel'

export const RadioContainer = styled.div`
	font-size: 1rem;
	width: 1.125em;
	height: 1.125em;
	border-radius: 50%;
	border: 2px solid #A4ACB8;
	box-shadow: ${colorTheme.input.radio.shadowOutside} 0px 0px 0px 0.312em, ${colorTheme.input.radio.shadow} 0px 0px 0px 0.437em;
	margin: 0.437em;
	background: ${colorTheme.input.radio.background};
	flex-shrink: 0;

	&.active {
		background-color: ${colorTheme.input.radio.active.background};
		border: none;
		box-shadow: ${colorTheme.input.radio.active.shadowOutside} 0px 0px 0px 0.312em, ${colorTheme.input.radio.active.shadow} 0px 0px 0px 0.437em;
	}

	&.active.disabled{
		background-color: ${colorTheme.input.radio.disabled.background};
		border: none;
		box-shadow: ${colorTheme.input.radio.shadowOutside} 0px 0px 0px 0.312em, ${colorTheme.input.radio.shadow} 0px 0px 0px 0.437em;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.14rem;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1rem;
	}
`
RadioContainer.displayName = 'RadioContainer'

