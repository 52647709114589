import { SET_APP_API_HERE, SET_APP_ERRORS, SET_APP_EVENT, SET_APP_FORM_CHANGED, SET_APP_FORM_CHANGED_MODAL, SET_APP_MODAL, SET_APP_MODAL_DATA, SET_APP_NAVIGATE, SET_APP_NAVIGATE_BOOL, SET_APP_TOGGLED, SET_PAGE, SET_SOCKET_ERROR, SET_SYSTEM_FLAG_LOGOUT, SET_SYSTEM_FORM_BUTTON_SAVE, SET_SYSTEM_KEYBOARD_TEXT, SET_SYSTEM_LOCAL_STORAGE, SET_SYSTEM_SHOW_KEYBOARD, TRIGGER_SYSTEM_KEYBOARD_ENTER } from '../../actionTypes/system'

const initialState = {
	formChanged: false,
	formChangedModal: false,
	navigate: {
		to: null,
		bool: false,
	},
	toggled: false,
	modal: {
		incomeModalShow: false,
		processingTimeOrderModalShow: false,
		confirmPreOrderModalShow: false,
		printMessageModalShow: false,
		autoConfirmationModalShow: false,
		statusModalShow: false,
		contactsModalShow: false,
		platformDriversOrderModalShow: false,
		confirmExpressOrderModalShow: false
	},
	modalData: {
		processingTimeOrderModalData: [],
		confirmPreOrderModalData: [],
		printMessageModalData: [],
		platformDriversOrderModalData: [],
		confirmExpressOrderModalData: [],
		denyOrderModalData: []
	},
	event: {
		eventCall: false,
	},
	apiKeys: {
		here_api: null,
	},
	eventZIndex: 0,
	errors: [],
	socketError: true,
	pages: [],
	showKeyboard: false,
	keyboardText: null,
	keyboardEnterTrigger: 0,
	browserLocalStorage: {},
	appAfterLogout: false,
	formButtonSave: {
		processing: false,
		success: false,
	}
}

export function appReducer (state = initialState, action) {
	switch (action.type) {
		case SET_APP_API_HERE:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					here_api: action.api_here,
				},
			}
		case SET_APP_ERRORS:
			return {
				...state,
				errors: action.errors,
			}
		case SET_APP_FORM_CHANGED:
			return {
				...state,
				formChanged: action.changed,
			}
		case SET_PAGE:
			return {
				...state,
				pages: action.payload.content,
			}
		case SET_APP_FORM_CHANGED_MODAL:
			return {
				...state,
				formChangedModal: action.formChangedModal,
			}
		case SET_APP_NAVIGATE:
			return {
				...state,
				navigate: {
					...state.navigate,
					to: action.navigateTo,
				},
			}
		case SET_APP_NAVIGATE_BOOL:
			return {
				...state,
				navigate: {
					...state.navigate,
					bool: action.navigateBool,
				},
			}
		case SET_APP_TOGGLED:
			return {
				...state,
				toggled: action.toggled,
			}
		case SET_APP_MODAL:
			return {
				...state,
				modal: {
					...state.modal,
					incomeModalShow: action.modal.incomeModalShow ?? state.modal.incomeModalShow,
					processingTimeOrderModalShow: action.modal.processingTimeOrderModalShow ?? state.modal.processingTimeOrderModalShow,
					confirmPreOrderModalShow: action.modal.confirmPreOrderModalShow ?? state.modal.confirmPreOrderModalShow,
					printMessageModalShow: action.modal.printMessageModalShow ?? state.modal.printMessageModalShow,
					autoConfirmationModalShow: action.modal.autoConfirmationModalShow ?? state.modal.autoConfirmationModalShow,
					statusModalShow: action.modal.statusModalShow ?? state.modal.statusModalShow,
					contactsModalShow: action.modal.contactsModalShow ?? state.modal.contactsModalShow,
					denyOrderModalShow: action.modal.denyOrderModalShow ?? state.modal.denyOrderModalShow,
					denyPreOrderModalShow: action.modal.denyPreOrderModalShow ?? state.modal.denyPreOrderModalShow,
					platformDriversOrderModalShow: action.modal.platformDriversOrderModalShow ?? state.modal.platformDriversOrderModalShow,
					confirmExpressOrderModalShow: action.modal.confirmExpressOrderModalShow ?? state.modal.confirmExpressOrderModalShow,
				},
			}
		case SET_APP_MODAL_DATA:
			return {
				...state,
				modalData: {
					...state.modalData,
					processingTimeOrderModalData: action.modalData.processingTimeOrderModalData ?? state.modalData.processingTimeOrderModalData,
					confirmPreOrderModalData: action.modalData.confirmPreOrderModalData ?? state.modalData.confirmPreOrderModalData,
					printMessageModalData: action.modalData.printMessageModalData ?? state.modalData.printMessageModalData,
					platformDriversOrderModalData: action.modalData.platformDriversOrderModalData ?? state.modalData.platformDriversOrderModalData,
					confirmExpressOrderModalData: action.modalData.confirmExpressOrderModalData ?? state.modalData.confirmExpressOrderModalData,
					denyOrderModalData: action.modalData.denyOrderModalData ?? state.modalData.denyOrderModalData,
				},
			}
		case SET_APP_EVENT:
			return {
				...state,
				event: {
					...state.event,
					eventCall: action.event.eventCall ?? state.event.eventCall,
				},
			}
		case 'SET_APP_EVENT_Z_INDEX':
			return {
				...state,
				eventZIndex: action.eventIndex,
			}
		case SET_SOCKET_ERROR:
			return {
				...state,
				socketError: action.value,
			}
		case SET_SYSTEM_SHOW_KEYBOARD:
			return {
				...state,
				showKeyboard: action.value,
			}
		case SET_SYSTEM_KEYBOARD_TEXT:
			return {
				...state,
				keyboardText: action.value,
			}
		case TRIGGER_SYSTEM_KEYBOARD_ENTER:
			return {
				...state,
				keyboardEnterTrigger: state.keyboardEnterTrigger + 1,
			}
		case SET_SYSTEM_LOCAL_STORAGE:
			return {
				...state,
				browserLocalStorage: {
					...state.browserLocalStorage,
					...action.value,
				},
			}
		case SET_SYSTEM_FLAG_LOGOUT:
			return {
				...state,
				appAfterLogout: action.value,
			}
		case SET_SYSTEM_FORM_BUTTON_SAVE:
			return {
				...state,
				formButtonSave: {
					...state.formButtonSave,
					processing: action.formButtonSave.processing ?? state.formButtonSave.processing,
					success: action.formButtonSave.success ?? state.formButtonSave.success,
				},
			}
		default:
			return state
	}
}
