import styled from 'styled-components'
import { theme } from '../../../../../style/theme'

export const OrderContainer = styled.div`
	height: 100%;
	overflow-y: auto;
	padding-right: 0.63em;
`

export const OrderHeaderWrap = styled.div`
  display: flex;
	gap: 0.63em;
	flex-direction: column;
	margin-bottom: 1.25em;

	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: 1.87em;
	}
`
OrderHeaderWrap.displayName = 'OrderHeaderWrap'

export const OrderCompanyTitle = styled.div`
  font-size: 1.25em;
	text-align: center;

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.125em;
	}
`
OrderCompanyTitle.displayName = 'OrderCompanyTitle'

export const OrderTitle = styled.div`
  font-size: 1.25em;
	text-align: left;

	&.center {
		text-align: center;
	}

	&.right {
		text-align: right;
	}

	&.bold {
		font-weight: 700;
	}
`
OrderTitle.displayName = 'OrderTitle'

export const OrderSubTitle = styled.div`
  font-size: 1em;
	text-align: left;

	> span {
		font-weight: 400;
	}

	&.center {
		text-align: center;
	}

	&.right {
		text-align: right;
	}

	&.bold {
		font-weight: 700;
	}
`
OrderSubTitle.displayName = 'OrderSubTitle'

export const OrderText = styled.div`
  font-size: 0.88em;
	text-align: left;
`
OrderText.displayName = 'OrderText'

export const OrderRemark = styled.div`
  font-size: 1em;
	padding: 0.93em 0;
	font-style: italic;
	border-top: 1px dashed #000000;
	border-bottom: 1px dashed #000000;
`
OrderRemark.displayName = 'OrderRemark'

export const OrderProductRemark = styled.div`
  font-size: 1em;
	padding: 0.63em 0;
	font-style: italic;
	border-top: 1px dashed #000000;
	border-bottom: 1px dashed #000000;
`
OrderRemark.displayName = 'OrderRemark'

export const OrderProductsWrap = styled.div`
	margin-bottom: 0.93em;

	> div:last-child {
		border-bottom: 1px dashed #000000;
	}

	@media screen and (max-width: ${theme.point720}) {
		> div:last-child {
			border-bottom: 2px solid #000000;
		}
	}
`
OrderProductsWrap.displayName = 'OrderProductsWrap'

export const OrderProduct = styled.div`
  display: flex;
	gap: 0.93em;
	flex-direction: column;
	padding-bottom: 0.93em;
	border-bottom: 1px dashed #A4ACB8;
	margin-bottom: 0.93em;
`
OrderProduct.displayName = 'OrderProduct'

export const OrderMenuWrapper = styled.div`
  display: flex;
	justify-content: space-between;
`
OrderMenuWrapper.displayName = 'OrderMenuWrapper'

export const OrderProductWrapper = styled.div`
  display: flex;
	justify-content: space-between;
	margin-left: 0.63em;
`
OrderProductWrapper.displayName = 'OrderProductWrapper'

export const OrderIngredients = styled.div`
	margin-left: 0.63em;
`
OrderIngredients.displayName = 'OrderIngredients'

export const OrderToppings = styled.div`
	display: flex;
	gap: 0.37em;
	flex-direction: column;
`
OrderToppings.displayName = 'OrderToppings'

export const OrderTopping = styled.div`
	display: flex;
	justify-content: space-between;
	margin-left: 1.25em;
`
OrderTopping.displayName = 'OrderTopping'

export const OrderCost = styled.div`
	display: flex;
	justify-content: space-between;

	&.cost {
		margin-top: 0.63em;
		text-transform: uppercase;
	}

	@media screen and (max-width: ${theme.point720}) {
		&.cost {
			margin-top: 0.32em;
		}
	}
`
OrderCost.displayName = 'OrderCost'

export const OrderDelivery = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.93em;
	padding-bottom: 0.93em;
	border-bottom: 1px dashed #000000;

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.37em;
	}
`
OrderDelivery.displayName = 'OrderDelivery'

export const OrderTotalsWrap = styled.div`
	display: flex;
	gap: 0.93em;
	flex-direction: column;
`
OrderTotalsWrap.displayName = 'OrderTotalsWrap'

export const OrderTotalWrapper = styled.div`
	display: flex;
	gap: 0.37em;
	flex-direction: column;

	&.top {
		margin-top: -0.32em;
	}

	@media screen and (max-width: ${theme.point720}) {
		&.top {
			margin-top: -0.63em;
		}
	}
`
OrderTotalWrapper.displayName = 'OrderTotalWrapper'

export const OrderCount = styled.div`
	font-size: 1em;
	font-weight: 700;
	margin-top: 1.25em;
	margin-bottom: 1.87em;
	padding-bottom: 1.87em;
	border-bottom: 1px dashed #60616E;

	> span {
		font-weight: 400;
	}
`
OrderCount.displayName = 'OrderCount'

export const OrderRate = styled.div`
	font-size: 1.07em;
	text-align: right;
	text-transform: uppercase;
`
OrderRate.displayName = 'OrderRate'

export const OrderGiftCouponTitle = styled.div`
	font-size: 1em;
	text-align: left;
	font-family: ${theme.fontRobotoBold};
`
OrderGiftCouponTitle.displayName = 'OrderGiftCouponTitle'

export const OrderGiftCouponWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.12em;
`
OrderGiftCouponWrap.displayName = 'OrderGiftCouponWrap'
