import styled from 'styled-components'
import { theme } from '../../../../../../../../style/theme'
import { colorTheme } from '../../../../../../../../style/theme/colorTheme'

export const ItemGrid = styled.div`
	display: grid;
	grid-template-columns: 1.44em auto;
	row-gap: 0.56em;
	column-gap: 0.31em;
	color: ${colorTheme.cartModal.invoiceModals.customerData.autocompleteItem.text};

	svg {
		margin: 0 auto;
		width: 100%;
		height: 1.2em;
	}

	&.col-1 {
		grid-template-columns: auto;
		gap: 0.3em;
	}

	&.col-2 {
		align-items: center;
		grid-template-columns: 2em auto;

		.first {
			display: flex;
			align-items: center;
			justify-content: center;

			> svg {
				margin: 0 auto;
				width: 100%;
				height: 2em;
				fill: ${colorTheme.cartModal.invoiceModals.customerData.autocompleteItem.icon};
			}
		}
	}

	.first {
		font-family: ${theme.fontRobotoBold};
		font-size: 1.13em;
		line-height: 1.17em;
		font-weight: bold;
		word-break: break-all;
		text-align: left;
	}

	.second {
		font-family: ${theme.fontRobotoRegular};
		line-height: 1.17em;
		word-break: break-all;
		text-align: left;

		> :first-child {
			font-size: 1.38em;
		}

		> :nth-child(2) {
			font-size: 1em;
		}
	}

	&.active {
		color: ${colorTheme.cartModal.invoiceModals.customerData.autocompleteItem.active.text};

		svg path {
			fill: ${colorTheme.cartModal.invoiceModals.customerData.autocompleteItem.active.icon};
		}
	}
`
