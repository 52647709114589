import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const ToppingGroups = lazyWithRetry(() => import(/* webpackChunkName: "topping-groups" */'../../component/ToppingGroups'))

const ToppingGroupsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<ToppingGroups />
		</Suspense>
	)
})

ToppingGroupsPage.displayName = 'ToppingGroupsPage'
export default ToppingGroupsPage
