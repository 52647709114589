import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const PhoneTableWrapper = styled.div`
	font-size: 1rem;
	max-height: 50vh;
	width: 100%;
	padding-right: 0.31em;
	margin-top: 0.63em;
	overflow-y: auto;

	@media screen and (max-width: ${theme.point820}) {
		max-height: none;
	}
`
PhoneTableWrapper.displayName = 'PhoneTableWrapper'

export const PhoneTableRow = styled.div`
	display: grid;
	grid-template-columns: 0.9fr min-content 1.1fr;
	gap: 1.25em;
	width: 100%;
	height: 3.44em;
	background-color: ${colorTheme.incomeCallModal.table.background};
	padding: 0.31em 0.63em;
	align-items: center;

	&:nth-child(2n), &.empty {
		background-color: ${colorTheme.incomeCallModal.table.secondBackground};
	}

	&.active {
		border-radius: 0.63em;
		border: 1px solid ${colorTheme.incomeCallModal.selectedCall.active};
	}

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: 1fr min-content;
		min-height: 6em;
		gap: 0.71em;
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: 5.25em;
		gap: 0.63em;
		padding: 0.94em 0.63em;
	}
`
PhoneTableRow.displayName = 'PhoneTableRow'
export const PhoneTableTelTd = styled.div`
	display: flex;
	gap: 0.38em;

	> svg {
		width: 1em;
		height: 1.56em;
		fill: ${colorTheme.incomeCallModal.table.icon};
		flex-shrink: 0;
	}
`
PhoneTableTelTd.displayName = 'PhoneTableTelTd'
export const PhoneTableTelText = styled.div`
	color: ${colorTheme.incomeCallModal.table.text};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	font-weight: 500;
`
PhoneTableTelText.displayName = 'PhoneTableTelText'
export const PhoneTableTimeTd = styled.div`
	color: ${colorTheme.incomeCallModal.table.active};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1em;
	font-weight: 500;

`
PhoneTableTimeTd.displayName = 'PhoneTableTimeTd'
export const PhoneTableAddressTd = styled.div`
	color: ${colorTheme.incomeCallModal.table.text};
	font-family: ${theme.fontRoboto};
	font-size: 1em;
	font-weight: 400;
	justify-self: end;

	@media screen and (max-width: ${theme.point820}) {
		grid-column: 1 / 3;
		justify-self: start;
	}
`
PhoneTableAddressTd.displayName = 'PhoneTableAddressTd'
export const EmptyPhoneTableText = styled.div`
	color: ${colorTheme.incomeCallModal.table.text};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.5em;
	font-weight: 500;
	grid-column: 1 / 4;
`
EmptyPhoneTableText.displayName = 'EmptyPhoneTableText'
