import styled from 'styled-components'

export const QrCodeLoaderWrap = styled.div`
	font-size: 1rem;
	transform: translateX(5%);
	padding: 1.25em 0 1.25em;

	& > svg path.qr-code-static {
		transform: translateY(5%);
	}

	& > svg path.hand {
		animation: hand 3s linear infinite;
	}

	& > svg path.qr-code {
		animation: qr-code 3s linear infinite;
	}

	& > svg path.check {
		animation: check 3s linear infinite;
	}

	& > svg path.wave {
		transform: translateX(17%);
	}

	& > svg path.wave-first {
		animation: waveFirst 3s infinite 1s;
	}

	& > svg path.wave-second {
		animation: waveSecond 3s infinite 1s;
	}

	& > svg path.wave-third {
		animation: waveThird 3s infinite 1s;
	}

	& > svg path.wave-fourth {
		animation: waveFourth 3s infinite 1s;
	}

	@keyframes waveFirst {
		0% {
			opacity: 0;
		}

		25% {
			opacity: 0;
		}

		45% {
			opacity: 1;
		}

		50% {
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes waveSecond {
		0% {
			opacity: 0;
		}

		28% {
			opacity: 0;
		}

		48% {
			opacity: 1;
		}

		53% {
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes waveThird {
		0% {
			opacity: 0;
		}

		31% {
			opacity: 0;
		}

		51% {
			opacity: 1;
		}

		56% {
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes waveFourth {
		0% {
			opacity: 0;
		}

		35% {
			opacity: 0;
		}

		55% {
			opacity: 1;
		}

		60% {
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes hand {
		0% {
			transform: translate(40%, 5%);
		}
		15%, 85% {
			transform: translate(25%, 5%);
		}
		25%, 75% {
			transform: translate(17%, 5%);
		}
		100% {
			transform: translate(40%, 5%);
		}
	}

	@keyframes qr-code {
		0% {
			opacity: 0;
		}
		35% {
			opacity: 0;
			transform: translate(28.5%, 17%);
		}
		40% {
			opacity: 1;
			transform: translate(28.5%, 17%);
		}
		65% {
			opacity: 1;
			transform: translate(28.5%, 17%);
		}
		75% {
			opacity: 1;
			transform: translate(28.5%, 17%);
		}
		85% {
			transform: translate(37%, 17%);
		}
		92% {
			transform: translate(44%, 17%);
		}
		95% {
			transform: translate(47%, 17%);
		}
		100% {
			opacity: 0;
			transform: translate(50%, 17%);
		}
	}

	@keyframes check {
		0% {
			opacity: 0;
		}
		45% {
			opacity: 0;
			transform: translate(31%, 50%);
		}
		50% {
			opacity: 1;
			transform: translate(31%, 50%);
		}
		75% {
			opacity: 1;
			transform: translate(31%, 50%);
		}
		92% {
			transform: translate(47%, 50%);
		}
		95% {
			transform: translate(49%, 50%);
		}
		100% {
			opacity: 0;
			transform: translate(52%, 50%);
		}
	}
`
QrCodeLoaderWrap.displayName = 'QrCodeLoaderWrap'
