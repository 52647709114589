import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ButtonsModal from '../../../../modal/ButtonsModal'
import { setSelectedInvoice, setShowInvoiceBillingAddressModal, setShowInvoiceDataModal, setShowInvoiceOptionsModal, setShowInvoiceResendEmailModal } from '../../../../../../redux/action/invoice'
import { selectContainsAnyRole } from '../../../../../../redux/selector/system'
import { updateInvoiceStatusThunk } from '../../../../../../redux/thunk/invoice'
import { getCustomerThunk } from '../../../../../../redux/thunk/customer'
import { setCustomer } from '../../../../../../redux/action/customer'

const EVENT_Z_INDEX = 80

const InvoiceOptionsModal = React.memo(({ handlePrint }) => {
	const { t } = useTranslation()
	const showOptionsModal = useSelector(store => store.invoice.modal.showOptions)
	const selectedInvoice = useSelector(store => store.invoice.selectedInvoice)
	const dispatch = useDispatch()
	const hasRole = useSelector(selectContainsAnyRole(['ORDERS_WRITE', 'CASH_DESK_WRITE', 'DELIVERY_SERVICE_WRITE', 'RESTAURANT_WRITE']))
	const invoiceCustomerNumber = selectedInvoice.customer.customerNumber

	const isInvoicePayed = selectedInvoice.markAsPaid
	const disableInfoMessage = !hasRole ? { text: t('invoices.modal.optionsModal.disableMessage'), center: true } : null

	useEffect(() => {
		if (invoiceCustomerNumber) {
			dispatch(getCustomerThunk(invoiceCustomerNumber))
		}
	}, [invoiceCustomerNumber]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		dispatch(setSelectedInvoice(null))
		dispatch(setCustomer(null))
		dispatch(setShowInvoiceOptionsModal(false))
	}, [dispatch])

	const handleShowInvoiceData = useCallback(() => {
		dispatch(setShowInvoiceOptionsModal(false))
		dispatch(setShowInvoiceDataModal(true))
	}, [dispatch])

	const handleChangeInvoiceClick = useCallback(() => {
		dispatch(setShowInvoiceOptionsModal(false))
		dispatch(setShowInvoiceBillingAddressModal(true))
	}, [dispatch])

	const handleResendInvoice = useCallback(() => {
		dispatch(setShowInvoiceOptionsModal(false))
		dispatch(setShowInvoiceResendEmailModal(true))
	}, [dispatch])

	const handleChangeInvoiceStatus = useCallback(async () => {
		await dispatch(updateInvoiceStatusThunk(selectedInvoice.id, !isInvoicePayed))
		handleClose()
	}, [dispatch, selectedInvoice, handleClose, isInvoicePayed])

	const buttons = [
		{ icon: 'show', text: t('invoices.modal.optionsModal.buttons.showInvoice'), onClick: handleShowInvoiceData, show: true },
		{ icon: 'print', text: t('invoices.modal.optionsModal.buttons.printInvoice'), onClick: handlePrint, show: true },
		{ icon: 'change', text: t('invoices.modal.optionsModal.buttons.changeInvoice'), onClick: handleChangeInvoiceClick, show: !isInvoicePayed },
		{ icon: 'send-email', text: t('invoices.modal.optionsModal.buttons.sendAgainInvoice'), onClick: handleResendInvoice, show: true },
		{ icon: 'circle-checkmark-gray', text: t('invoices.modal.optionsModal.buttons.markPaidInvoice'), onClick: handleChangeInvoiceStatus, show: !isInvoicePayed, disabled: !hasRole, message: disableInfoMessage },
		{ icon: 'circle-cross-gray', text: t('invoices.modal.optionsModal.buttons.markUnPaidInvoice'), onClick: handleChangeInvoiceStatus, show: isInvoicePayed, disabled: !hasRole, message: disableInfoMessage },
	]

	return (
		<ButtonsModal isOpen={showOptionsModal && selectedInvoice}
									close={handleClose}
									id="invoice-options-modal"
									title={t('invoices.modal.optionsModal.title') + ' #' + selectedInvoice?.invoiceNumber}
									titlePrice={selectedInvoice?.totalAmount}
									zIndex={EVENT_Z_INDEX}
									returnZIndex={0}
									size="small"
									buttons={buttons} />
	)
})

InvoiceOptionsModal.displayName = 'InvoiceOptionsModal'
export default InvoiceOptionsModal
