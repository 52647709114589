import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const TaxSettings = lazyWithRetry(() => import(/* webpackChunkName: "tax-settings" */'../../component/TaxSettings'))

const TaxSettingPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<TaxSettings />
		</Suspense>
	)
})

TaxSettingPage.displayName = 'TaxSettingPage'
export default TaxSettingPage
