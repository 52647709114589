import styled from 'styled-components'

export const ToggleSwitchWrapper = styled.div`
  position: absolute;
  top: 1.5em;
  right: 2.31em;
`
ToggleSwitchWrapper.displayName = 'ToggleSwitchWrapper'

export const MapWrapper = styled.div`
  min-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

	&.cross-hair {
		cursor: crosshair;
	}
`
MapWrapper.displayName = 'MapWrapper'
