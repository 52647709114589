import { setAppErrors, setPage } from '../../action/system'
import getPage from '../../axios/page'
import { retrieveErrorFromApi } from '../../../helper'
import { getStaticPagesIds } from '../../reducer/system/helper'

export const getPageThunk = () => dispatch => {
	return getPage()
		.then(res => {
			const uiStaticPagesIds = getStaticPagesIds()
			const pages = [...res.data, ...uiStaticPagesIds]
			dispatch(setPage(pages))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
