import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const ExtraContactsWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5em;

	&.contacts {
		gap: 1.875em;
	}

	@media screen and (max-width: ${theme.point720}) {
		> label {
			width: 100%;
		}
	}
`
ExtraContactsWrap.displayName = 'ExtraContactsWrap'

export const ExtraContactsContent = styled.div`
	display: flex;
	gap: 1.25em;

	> svg {
		width: 10.5em;
		height: 10.5em;
		flex-shrink: 0;
	}

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: column;
		align-items: center;
	}
`
ExtraContactsContent.displayName = 'ExtraContactsContent'

export const ExtraContactsInfo = styled.div`
	display: flex;
	gap: 0.75em;
	width: 20.3em;

	> svg {
		width: 2.12em;
		height: 3.5em;
		flex-shrink: 0;
		fill: ${colorTheme.extraContactsModal.icon};
	}
`
ExtraContactsInfo.displayName = 'ExtraContactsInfo'

export const ExtraContacts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.43em;
`
ExtraContacts.displayName = 'ExtraContacts'

export const ExtraContactsWatsappContacts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.63em;
`
ExtraContactsWatsappContacts.displayName = 'ExtraContactsWatsappContacts'

export const ExtraContactsSub = styled.div`
	font-size: 1em;
	font-weight: 400;
	font-family: ${theme.fontRobotoMedium};
	text-align: left;
	color: ${colorTheme.extraContactsModal.text};
`
ExtraContactsSub.displayName = 'ExtraContactsSub'

export const ExtraContactsLink = styled.div`
	font-size: 1.25em;
	font-weight: 500;
	text-decoration-line: underline;
	cursor: pointer;
	color: ${colorTheme.extraContactsModal.link};
	font-family: ${theme.fontRobotoMedium};
	text-align: left;

	@media screen and (max-width: ${theme.point720}) {
		&.center {
			text-align: center;
			margin-bottom: 0.5em;
		}
	}
`
ExtraContactsLink.displayName = 'ExtraContactsLink'

export const ExtraContactsTitle = styled.div`
	color: ${colorTheme.extraContactsModal.title};
	font-size: 1.5em;
	font-weight: 600;
`
ExtraContactsTitle.displayName = 'ExtraContactsTitle'
