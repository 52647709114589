import styled from 'styled-components'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { theme } from '../../../../../style/theme'

export const ArrowButtonWrapper = styled.button`
	font-size: 1em;
	width: 2.63em;
	height: 2.63em;
	padding: 0.1em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.44em;
	cursor: pointer;

	> svg {
		width: 0.56em;
		height: 1.06em;
		flex-shrink: 0;
		fill: ${colorTheme.restaurant.sendToKitchenModal.arrowButton.icon};
	}

	&.ARROW_RIGHT {
		background-color: ${colorTheme.restaurant.sendToKitchenModal.arrowButton.arrowRight};
	}

	&.ARROW_LEFT {
		background-color: ${colorTheme.restaurant.sendToKitchenModal.arrowButton.arrowLeft};

		> svg {
			transform: rotate(180deg);
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		min-height: 3em;
		width: max-content;
		padding: 0.35em 0.79em;
		justify-content: center;
		gap: 0.36em;

		> svg {
			width: 0.43em;
			height: 0.79em;
			flex-shrink: 0;
			fill: ${colorTheme.restaurant.sendToKitchenModal.arrowButton.icon};
		}

		&.arrow-left {
			> svg {
				order: -1;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		min-height: 2.63em;
		padding: 0.31em 0.69em;
		gap: 0.31em;

		> svg {
			width: 0.38em;
			height: 0.69em;
		}
	}
`
ArrowButtonWrapper.displayName = 'ArrowButtonWrapper'
export const ArrowButtonText = styled.div`
	@media screen and (max-width: ${theme.point820}) {
		color: ${colorTheme.restaurant.sendToKitchenModal.arrowButton.text};
		font-family: ${theme.fontRobotoMedium};
		font-size: 1em;
		font-weight: 500;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 0.88em;
	}
`
ArrowButtonText.displayName = 'ArrowButtonText'
