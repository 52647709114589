import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'
import { colorTheme } from '../../../../../../style/theme/colorTheme'

export const InvoiceSheetContainer = styled.div`
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
`
InvoiceSheetContainer.displayName = 'InvoiceSheetContainer'

export const InvoiceSheetWrap = styled.div`
	position: relative;
	width: 740px;
	font-family: ${theme.fontRoboto};
`
InvoiceSheetWrap.displayName = 'InvoiceSheetWrap'

export const HeaderCompanyWrap = styled.div`
	padding-bottom: 10px;
`
HeaderCompanyWrap.displayName = 'HeaderCompanyWrap'

export const HeaderCompanyTitle = styled.div`
	color: ${colorTheme.invoices.modal.invoiceData.text};
	font-size: 9px;
	font-weight: 400;
`
HeaderCompanyTitle.displayName = 'HeaderCompanyTitle'

export const HeaderWrap = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px 0;
`
HeaderWrap.displayName = 'HeaderWrap'

export const HeaderContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 7px;
`
HeaderWrap.displayName = 'HeaderWrap'

export const HeaderText = styled.div`
	color: ${colorTheme.invoices.modal.invoiceData.text};
	font-size: 9px;
	font-weight: 500;
`
HeaderText.displayName = 'HeaderText'

export const InvoiceContent = styled.div`
	margin-top: 70px;
`
InvoiceContent.displayName = 'InvoiceContent'

export const InvoiceTitle = styled.div`
	color: ${colorTheme.invoices.modal.invoiceData.text};
	text-align: center;
	font-size: 17px;
	font-weight: 600;
`
InvoiceTitle.displayName = 'InvoiceTitle'

export const InvoiceInfoWrap = styled.div`
	display: grid;
	gap: 10px;
	padding: 20px 0;

	&.big-gap {
		gap: 20px;
	}

	&.small-padding {
		padding: 5px 0;
	}
`
InvoiceInfoWrap.displayName = 'InvoiceInfoWrap'

export const InvoiceDataWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 7px;
	padding: 10px 0;

	> div {
		display: flex;
		gap: 10px;
	}
`
InvoiceDataWrap.displayName = 'InvoiceDataWrap'

export const InvoiceInfoText = styled.div`
	color: ${colorTheme.invoices.modal.invoiceData.text};
	font-size: 12px;
	font-weight: 400;

	&.footer {
		font-size: 9px;
	}
`
InvoiceInfoText.displayName = 'InvoiceInfoText'

export const InvoiceFooterWrap = styled.div`
	text-align: center;
	display: grid;
	margin-top: 5px;
`
InvoiceFooterWrap.displayName = 'InvoiceFooterWrap'

export const InvoiceOrderHeader = styled.div`
	display: flex;
	padding: 10px 5px;
	justify-content: space-between;
	align-items: center;
	background: ${colorTheme.invoices.modal.invoiceData.backgroundHeader};
	margin: 20px 0 10px;
`
InvoiceOrderHeader.displayName = 'InvoiceOrderHeader'

export const InvoiceOrderHeaderTitle = styled.div`
	color: ${colorTheme.invoices.modal.invoiceData.text};
	font-size: 10px;
	font-weight: 600;
`
InvoiceOrderHeaderTitle.displayName = 'InvoiceOrderHeaderTitle'

export const InvoiceOrderCount = styled.div`
	margin-top: 20px;
	margin-bottom: 28px;
	padding-bottom: 10px;
	border-bottom: 3px solid ${colorTheme.invoices.modal.invoiceData.text};
	text-align: center;

	> span {
		font-size: 10px;
		font-weight: 400;
	}

	> span.bold {
		font-weight: 700;
	}
`
InvoiceOrderCount.displayName = 'InvoiceOrderCount'
