import axios from 'axios'
import { restaurantServiceUrl } from '../../../../urls'
import modalLoginRequestInterceptor from '../../modalLoginRequestInterceptor'
import modalLoginResponseInterceptor from '../../modalLoginResponseInterceptor'

const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
}

const restaurantModalLoginInstance = axios.create({
  baseURL: restaurantServiceUrl,
  headers,
})

restaurantModalLoginInstance.interceptors.request.use(config => {
  return modalLoginRequestInterceptor(config)
}, error => Promise.reject(error))

restaurantModalLoginInstance.interceptors.response.use(response => response, error => {
  return modalLoginResponseInterceptor(error)
})

export default restaurantModalLoginInstance
