import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const PaymentsTerminalWrap = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .94em;
  padding: 5.5em 1em;
	width: 52.56em;
  border: 1px solid ${colorTheme.cartModal.terminalProcess.border};
  border-radius: .75em;

  @media screen and (max-width: ${theme.point820}) {
    margin: 0 .63em;
    padding: 1.88em .63em;
		width: auto;
  }

  &.error {
    border: 1px solid ${colorTheme.cartModal.terminalProcess.error};
    padding: 7.75em 1em;

    > svg {
      width: 5.88em;
      height: 5.38em;
      fill: ${colorTheme.cartModal.terminalProcess.error};
    }

    @media screen and (max-width: ${theme.point820}) {
      padding: 1.88em .63em;
			width: auto;
    }
  }

`
PaymentsTerminalWrap.displayName = 'PaymentsTerminalWrap'

export const PaymentsTerminalInfo = styled.div`
  display: flex;
	flex-direction: column;
  gap: .31em;
	font-size: 1.25em;
	color: ${colorTheme.cartModal.terminalProcess.text};
`
PaymentsTerminalInfo.displayName = 'PaymentsTerminalInfo'

export const PaymentsTerminalStatus = styled.div`
	font-family: ${theme.fontRobotoBold};
	font-size: 1.5em;
	color: ${colorTheme.cartModal.terminalProcess.text};

	&.error {
		color: ${colorTheme.cartModal.terminalProcess.error};
	}
`
PaymentsTerminalStatus.displayName = 'PaymentsTerminalStatus'

export const PaymentsTerminalText = styled.span`
`
PaymentsTerminalText.displayName = 'PaymentsTerminalText'

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.88em 0;

  @media screen and (max-width: ${theme.point820}) {
		padding: 1.88em .63em;
		flex-direction: column;
		gap: 1.25em;
  }
`
ButtonsWrapper.displayName = 'ButtonsWrapper'

export const TestCardBlock = styled.div`
  display: flex;
  gap: 10px;
`
TestCardBlock.displayName = 'TestCardBlock'

export const TipsBlock = styled.div`
	display: flex;
	gap: .67em;
	align-items: center
`

TipsBlock.displayName = 'TipsBlock'

export const TestBlockWrap = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	gap: 1.25em;
	align-items: center`
TestBlockWrap.displayName = 'TestBlockWrap'
