import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const AllOrder = lazyWithRetry(() => import(/* webpackChunkName: "all-order" */'../../component/AllOrder'))

const AllOrderPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<AllOrder />
		</Suspense>
	)
})

AllOrderPage.displayName = 'AllOrderPage'
export default AllOrderPage
