import React from 'react'
import { RecaptchaText, RecaptchaWrapper } from './index.styled'
import MainIcon from '../../../img/svg/main'
import { useTranslation } from 'react-i18next'

const Recaptcha = React.memo(({ className }) => {
	const { t } = useTranslation()

	return (
		<RecaptchaWrapper className={className}>
			<MainIcon icon="recaptcha" />
			<RecaptchaText className={className}>
				{t('recaptcha_text')}
			</RecaptchaText>
		</RecaptchaWrapper>
	)
})

Recaptcha.displayName = 'Recaptcha'
export default Recaptcha
