import axios from 'axios'

const URL = 'https://geocode.search.hereapi.com/v1'


const headers = {
  'X-Request-ID': '',
}


const hereGeoCodeInstance = axios.create({
  baseURL: URL,
  headers,
})


export default hereGeoCodeInstance
