import { APPEND_DELIVERY_CONFIG_POLYGON, APPEND_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, APPEND_DELIVERY_CONFIGS, DELETE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, SET_DELIVERY_CONFIG, SET_DELIVERY_CONFIG_DRAWING_MODE, SET_DELIVERY_CONFIG_ID_EDITED_ZONE, SET_DELIVERY_CONFIG_LENGTH, SET_DELIVERY_CONFIG_NEW_ZONE_DATA, SET_DELIVERY_CONFIG_POLYGON_COLOR, SET_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, SET_DELIVERY_CONFIG_POLYGON_ERROR, SET_DELIVERY_CONFIG_POLYGONS, SET_DELIVERY_CONFIG_POLYGONS_LENGTH, SET_DELIVERY_CONFIG_SHOW_CREATING_FORM, SET_DELIVERY_CONFIG_SHOW_DEFINE_POLYGON_MODAL, SET_DELIVERY_CONFIG_TYPE, SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SEARCH_IS_FINISHED, SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SUGGESTIONS, SET_DELIVERY_CONFIGS, UPDATE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA, UPDATE_DELIVERY_CONFIG_ZIP_LOCALITY_SUGGESTION } from '../../actionTypes/deliveryConfig'
import { DISTANCE } from '../../../component/DeliveryConfig/DeliveryTabs/constatnts'
import { ZIP_LOCALITY } from '../../../component/DeliveryConfig/deliveryPolygon/form/constants'

const initialState = {
	configs: [],
	config: null,
	configLength: 0,
	type: DISTANCE,
	configPolygonsLength: 0,
	polygons: [],
	polygonsLength: 0,
	editZoneId: null,
	polygonCoordinatesData: [],
	polygonColor: null,
	newZoneData: null,
	showDefinePolygonModal: false,
	showCreatingForm: false,
	polygonError: false,
	drawingMode: ZIP_LOCALITY,
	zipLocalitiesSearchIsFinished: false,
	zipLocalitiesSuggestions: []
}

export function deliveryConfigReducer(state = initialState, action) {
	switch (action.type) {
		case SET_DELIVERY_CONFIGS:
			return {
				...state,
				configs: action.value
			}
		case APPEND_DELIVERY_CONFIGS:
			return {
				...state,
				configs: state.configs.concat(...action.value)
			}
		case SET_DELIVERY_CONFIG:
			return {
				...state,
				config: action.value
			}
		case SET_DELIVERY_CONFIG_LENGTH:
			return {
				...state,
				configLength: action.value
			}
		case SET_DELIVERY_CONFIG_TYPE:
			return {
				...state,
				type: action.value
			}
		case SET_DELIVERY_CONFIG_POLYGONS:
			return {
				...state,
				polygons: action.value
			}
		case APPEND_DELIVERY_CONFIG_POLYGON:
			return {
				...state,
				polygons: state.polygons.concat(...action.value)
			}
		case SET_DELIVERY_CONFIG_POLYGONS_LENGTH:
			return {
				...state,
				polygonsLength: action.value
			}
		case SET_DELIVERY_CONFIG_ID_EDITED_ZONE:
			return {
				...state,
				editZoneId: action.value
			}
		case SET_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA:
			return {
				...state,
				polygonCoordinatesData: action.value
			}
		case APPEND_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA:
			return {
				...state,
				polygonCoordinatesData: state.polygonCoordinatesData.concat(...action.value)
			}
		case UPDATE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA:
			let polygonCoordinatesDataCopy = [...state.polygonCoordinatesData]
			polygonCoordinatesDataCopy[action.value.polygonIndex] = { coordinates: action.value.coordinates, zipLocality: action.value.zipLocality }
			return {
				...state,
				polygonCoordinatesData: polygonCoordinatesDataCopy
			}
		case DELETE_DELIVERY_CONFIG_POLYGON_COORDINATES_DATA:
			let polygonCoordinatesDataCopy2 = [...state.polygonCoordinatesData]
			polygonCoordinatesDataCopy2.splice(action.value.polygonIndex, 1)
			return {
				...state,
				polygonCoordinatesData: polygonCoordinatesDataCopy2
			}
		case SET_DELIVERY_CONFIG_POLYGON_COLOR:
			return {
				...state,
				polygonColor: action.value
			}
		case SET_DELIVERY_CONFIG_NEW_ZONE_DATA:
			return {
				...state,
				newZoneData: action.value
			}
		case SET_DELIVERY_CONFIG_SHOW_DEFINE_POLYGON_MODAL:
			return {
				...state,
				showDefinePolygonModal: action.value
			}
		case SET_DELIVERY_CONFIG_SHOW_CREATING_FORM:
			return {
				...state,
				showCreatingForm: action.value
			}
		case SET_DELIVERY_CONFIG_POLYGON_ERROR:
			return {
				...state,
				polygonError: action.value
			}
		case SET_DELIVERY_CONFIG_DRAWING_MODE:
			return {
				...state,
				drawingMode: action.value
			}
		case SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SEARCH_IS_FINISHED:
			return {
				...state,
				zipLocalitiesSearchIsFinished: action.value
			}
		case SET_DELIVERY_CONFIG_ZIP_LOCALITIES_SUGGESTIONS:
			return {
				...state,
				zipLocalitiesSuggestions: action.value
			}
		case UPDATE_DELIVERY_CONFIG_ZIP_LOCALITY_SUGGESTION:
			let zipLocalitiesSuggestionsCopy = [...state.zipLocalitiesSuggestions]
			zipLocalitiesSuggestionsCopy[action.value.suggestionIndex] = action.value.zipLocalitySuggestion
			return {
				...state,
				zipLocalitiesSuggestions: zipLocalitiesSuggestionsCopy
			}
		default:
			return state
	}
}
