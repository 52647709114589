export const SET_PRODUCT_MODE = 'SET_PRODUCT_MODE'
export const SET_PRODUCT_CHANGED = 'SET_PRODUCT_CHANGED'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCTS_ZUTAT = 'SET_PRODUCTS_ZUTAT'
export const APPEND_PRODUCTS = 'APPEND_PRODUCTS'
export const SET_PRODUCTS_LENGTH = 'SET_PRODUCTS_LENGTH'
export const SET_PRODUCT = 'SET_PRODUCT'
export const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE'
export const SET_CATEGORY_ID = 'SET_CATEGORY_ID'
export const SET_LOADING = 'SET_LOADING'
export const SET_PRODUCTS_UPDATE_PRODUCT = 'SET_PRODUCTS_UPDATE_PRODUCT'
export const SET_PRODUCT_TYPE_BUSINESS_SCOPE = 'SET_PRODUCT_TYPE_BUSINESS_SCOPE'
export const SET_PRODUCT_IMPORTING_STATUS = 'SET_PRODUCT_IMPORTING_STATUS'
export const SET_PRODUCT_CORS_ERROR = 'SET_PRODUCT_CORS_ERROR'
export const APPEND_PRODUCT_PERIOD_DAYS = 'APPEND_PRODUCT_PERIOD_DAYS'
export const REMOVE_PRODUCT_PERIOD_DAYS = 'REMOVE_PRODUCT_PERIOD_DAYS'
export const SET_PRODUCT_PERIOD_DAYS = 'SET_PRODUCT_PERIOD_DAYS'
export const SET_PRODUCT_PERIOD_START_TIME = 'SET_PRODUCT_PERIOD_START_TIME'
export const SET_PRODUCT_PERIOD_END_TIME = 'SET_PRODUCT_PERIOD_END_TIME'
export const SET_PRODUCT_PERIOD_START_DATE = 'SET_PRODUCT_PERIOD_START_DATE'
export const SET_PRODUCT_PERIOD_END_DATE = 'SET_PRODUCT_PERIOD_END_DATE'
export const SET_PRODUCT_PERIOD_IS_ACTIVE_TIME = 'SET_PRODUCT_PERIOD_IS_ACTIVE_TIME'
export const SET_PRODUCT_IMAGE_CONDITIONS = 'SET_PRODUCT_IMAGE_CONDITIONS'
export const SET_PRODUCT_IMAGE_CONFIG = 'SET_PRODUCT_IMAGE_CONFIG'
export const SET_PRODUCT_IMAGE_UPLOAD_STEP = 'SET_PRODUCT_IMAGE_UPLOAD_STEP'
export const SET_PRODUCT_IMAGE_ERROR_MODAL = 'SET_PRODUCT_IMAGE_ERROR_MODAL'
export const SET_PRODUCTS_ADDITIONAL_SETTINGS = 'SET_PRODUCTS_ADDITIONAL_SETTINGS'
export const SET_PRODUCT_ADDITIONAL_SETTINGS_FILTER = 'SET_PRODUCT_ADDITIONAL_SETTINGS_FILTER'
export const SET_PRODUCTS_BY_PRICE_DESCRIPTIONS = 'SET_PRODUCTS_BY_PRICE_DESCRIPTIONS'
export const APPEND_PRODUCTS_BY_PRICE_DESCRIPTIONS = 'APPEND_PRODUCTS_BY_PRICE_DESCRIPTIONS'
export const SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LENGTH = 'SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LENGTH'
export const SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LOADING = 'SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LOADING'
