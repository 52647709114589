import styled from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'
import { OrderInfo, OrderInfoBlock, OrderInfoItem } from '../processingTimeOrder/index.styled'

export const PreOrderModalWrapper = styled.div`
	font-size: 1rem;
	min-height: 100px;
	display: grid;
	grid-template-columns: 16.375em 1fr;
	grid-gap: 1.313em;

	@media screen and (max-width: ${theme.point720}) {
		gap: 1em;
		grid-template-columns: auto;
	}
`
PreOrderModalWrapper.displayName = 'PreOrderModalWrapper'

export const PreOrderIconWrap = styled.div`
	border-radius: 0.75em;
	border: 1px solid ${colors.gray_light};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1em;
	min-height: 5.6em;

	svg {
		width: 5.17em;
		height: 5.8em;
	}
`
PreOrderIconWrap.displayName = 'PreOrderIconWrap'

export const ModalInfo = styled(OrderInfo)`
	gap: 1.25em;
	margin-bottom: 0;
`
ModalInfo.displayName = 'ModalInfo'

export const ModalInfoBlock = styled(OrderInfoBlock)`
	min-height: 7.56em;
	min-width: 11.8em;

	&.column {
		flex-direction: column;
		gap: 0.43em;
	}
`
ModalInfoBlock.displayName = 'ModalInfoBlock'

export const ModalInfoItem = styled(OrderInfoItem)`
`
ModalInfoItem.displayName = 'ModalInfoItem'
