import styled from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'

export const ButtonLoginWrapper = styled.div`
	font-size: 1.25em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	color: ${colors.gray_lighter};
	cursor: pointer;
	min-height: 2.75em;
	display: flex;
	justify-content: center;
	align-items: center;

	${props => props.isBorder && `
		border: 1px solid ${colors.gray_lighter};
		border-radius: 1.35em;
		padding: 0.5em 2.25em;
	`}
`
ButtonLoginWrapper.displayName = 'ButtonLoginWrapper'

export const ButtonsLoginWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1.25em;
	margin-right: 1.25em;
`
ButtonsLoginWrapper.displayName = 'ButtonsLoginWrapper'
