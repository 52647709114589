import styled from 'styled-components'
import colors from '../../style/colors'
import { theme } from '../../style/theme'
import { ButtonText } from '../Elements/buttons/Button/style/index.styled'
import { ButtonWrapper } from '../Elements/buttons/Button/style/buttonWrap/index.styled'

export const WebshopInfoWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	margin-right: 1.5em;
	position: relative;
`
WebshopInfoWrapper.displayName = 'WebshopInfoWrapper'

export const WebshopInfoDropdown = styled.div`
	position: absolute;
	top: 3.2em;
	right: -1em;
	width: 19.19em;
	padding: 1.88em;
	border-radius: 0.75em;
	box-shadow: 0.02em 0.25em 5.44em 0 rgba(0, 0, 0, 0.53);
	background-color: ${colors.white};
	z-index: 999;
	max-height: calc(100vh - 5.13em);
	overflow: auto;

	${ButtonWrapper} {
		width: 100%;
		margin-top: 1.05em;
		min-height: 3.06em;

		&.activation {
			margin-top: 0;
		}
	}

	${ButtonText} {
		font-size: 1.12em;
	}
`
WebshopInfoDropdown.displayName = 'WebshopInfoDropdown'

export const QrCodeAdviceWrapper = styled.div`
	background: ${colors.black};
	border-radius: 0.75em;
	padding: 0.75em 4.1em 1.56em;
	width: 100%;
`

export const QrCodeImg = styled.div`
	background: ${colors.white};
	padding: 0.5em;

	&.info {
		margin-top: 1.13em;
		cursor: pointer;
	}

	&.advice {
		margin-top: 0.94em;
	}

	> svg {
		width: 100%;
		height: 100%;
	}
`

export const QrCodeText = styled.div`
	font-family: ${theme.fontRobotoBold};
	font-size: 1em;
	font-weight: bold;
	line-height: 1.19em;
	text-transform: uppercase;
	color: ${colors.white};
	display: flex;
	justify-content: center;
	align-items: center;

	> svg {
		margin-right: 0.63em;
	}
`

export const WebShopButton = styled.button`
	font-size: 1rem;
	background-color: #17192d;
	border-radius: 0.75em;
	display: flex;
	justify-content: center;
	gap: 0.47em;
	align-items: center;
	color: ${colors.white};
	min-height: 2.53em;
  	padding: 0.38em 0.63em 0.38em 0.5em;
	cursor: pointer;
	border: 1px solid #ADADB4;

	> svg {
    width: 1.3em;
    height: 1.82em;
		fill: #ADADB4;
		margin-right: 0.13em;
  }

	&.active {
		border: 1px solid #3BE19D;

		> svg:first-child {
			fill: #3BE19D;
		}
	}

	&.inactive {
		border: 1px solid ${colors.white};
		background-color: #8F2F36;

		> svg:first-child {
			fill: ${colors.white};
		}
	}
`
WebShopButton.displayName = 'WebShopButton'

export const WebShopButtonText = styled.div`
	font-size: 1.125em;
	display: flex;
	align-items: center;
	font-family: ${theme.fontRobotoMedium};
	color: ${colors.white};
	text-align: left;
`
WebShopButtonText.displayName = 'WebShopButtonText'

export const WebShopButtonIcon = styled.div`
	background: ${colors.red};
	border-radius: 0.31em;
	padding: 0.25em;
	display: flex;

	> svg {
		width: 1.25em;
		height: 1em;
		fill: ${colors.white};
	}

	&.active {
		background: #38CD90;

		> svg {
			fill: #1C1E30;
		}
	}

	&.inactive {
		background: ${colors.white};

		> svg {
			fill: #8F2F36;
		}
	}
`
WebShopButtonIcon.displayName = 'WebShopButtonIcon'

export const WebShopButtonIconWrapper = styled.div`
	display: flex;
	gap: 0.18em;
`
WebShopButtonIconWrapper.displayName = 'WebShopButtonIconWrapper'

export const WebShopButtonLine = styled.div`
	border-left: 1px solid ${colors.white};
	padding: 1.31em 1em;
	position: absolute;
	right: 0;

	&.active {
		border-left: 1px solid #38CD90;
	}
`
WebShopButtonLine.displayName = 'WebShopButtonLine'

export const WebShopButtonExpand = styled.div`
	transform: rotate(0deg);
	transition: transform .2s;
	margin-top: 0.05em;
	margin-right: -0.125em;
	margin-left: 0.75em;

	&.is-open {
		transform: rotate(180deg);
	}

	&.active {
		svg {
			fill: #ADADB4;
		}
	}

	svg {
		width: 0.94em;
		height: 0.5em;
		margin-right: 0;
		fill: ${colors.white};
	}
`
WebShopButtonExpand.displayName = 'WebShopButtonExpand'
