import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const ZReport = lazyWithRetry(() => import(/* webpackChunkName: "z-report" */'../../component/Report/form/zreport'))

const ZReportPage = React.memo(() => {
	return (
		<Suspense>
			<ZReport />
		</Suspense>
	)
})

ZReportPage.displayName = 'ZReportPage'
export default ZReportPage
