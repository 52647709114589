import { createStore, applyMiddleware, compose } from 'redux'
import { rootReducer } from '../redux/reducer'
import thunk from 'redux-thunk'

const composeEnhancers = (process.env.NODE_ENV !== 'production' &&
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
)
