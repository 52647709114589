import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ActionModalText = styled.div`
  font-family: ${theme.fontRobotoMedium};
  font-size: 2em;
  color: ${colorTheme.modal.action.text};
`
ActionModalText.displayName = 'ActionModalText'
