/* eslint no-console: off */
import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import createQrPaymentSession from '../../../axios/payment/qrPaymentSession/post'
import { setCartPaymentQrCodePaymentSession, setCartPaymentQrCodePaymentSessionIsCreating } from '../../../action/cartPayment'
import getQrPaymentSession from '../../../axios/payment/qrPaymentSession/get'
import deleteQrPaymentSession from '../../../axios/payment/qrPaymentSession/delete'
import { deleteClientMonitorQrCodePaymentSessionThunk } from '../../clientMonitorCart'

export const createQrPaymentSessionThunk = (totalSum, hasModalLogin) => async (dispatch, getState) => {
	dispatch(setCartPaymentQrCodePaymentSessionIsCreating(true))
	try {
		const res = await createQrPaymentSession(totalSum, hasModalLogin)
		dispatch(setCartPaymentQrCodePaymentSession(res.data))
		console.log(`Created QR payment session: total=${totalSum} sessionId=${res.data.sessionId} companyIndex=${getState().login.companyIndex}`)
		return res.data
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	} finally {
		dispatch(setCartPaymentQrCodePaymentSessionIsCreating(false))
	}
}
export const getQrPaymentSessionThunk = (sessionId, hasModalLogin) => async (dispatch, getState) => {
	try {
		console.log(`Get QR payment session: session=${sessionId} companyIndex=${getState().login.companyIndex}`)
		const res = await getQrPaymentSession(sessionId, hasModalLogin)
		const qrCodePaymentSession = getState().cartPayment.qrCodePaymentSession
		if (qrCodePaymentSession && qrCodePaymentSession.sessionId === res.data.sessionId) {
			console.log(`Got QR payment session: session=${sessionId} status=${res.data.status} companyIndex=${getState().login.companyIndex}`)
			dispatch(setCartPaymentQrCodePaymentSession(res.data))
		}
	} catch (error) {
		if (error.response.status === 404) {
			console.warn('QR payment session not found')
		} else {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
	}
}

export const deleteQrPaymentSessionThunk = (sessionId, hasModalLogin) => async (dispatch, getState) => {
	try {
		console.log(`Delete QR payment session start request: session=${sessionId} companyIndex=${getState().login.companyIndex}`)
		const res = await deleteQrPaymentSession(sessionId, hasModalLogin)
		console.log(`Delete QR payment session get response: session=${sessionId} companyIndex=${getState().login.companyIndex} data=${res.data}`)
		return res.data
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	} finally {
		await dispatch(setCartPaymentQrCodePaymentSession(null))
		await dispatch(deleteClientMonitorQrCodePaymentSessionThunk())
	}
}

