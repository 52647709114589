import axios from 'axios'
import { registrationServiceUrl } from '../../../../urls'
import modalLoginRequestInterceptor from '../../modalLoginRequestInterceptor'
import modalLoginResponseInterceptor from '../../modalLoginResponseInterceptor'

const headers = {
	'Content-Type': 'application/json',
	'X-Requested-With': 'XMLHttpRequest',
}

const registrationModalLoginInstance = axios.create({
	baseURL: registrationServiceUrl,
	headers,
})

registrationModalLoginInstance.interceptors.request.use(modalLoginRequestInterceptor, error => Promise.reject(error))

registrationModalLoginInstance.interceptors.response.use(response => response, error => {
	return modalLoginResponseInterceptor(error)
})

export default registrationModalLoginInstance
