import { createSelector } from 'reselect'
import { milisecToHoursMinutes } from '../../../helper'

export const selectTimerCounter = store => store.timerCounter.counter

export const selectRemainingMinutes = (duration, modifiedDate) => createSelector(
	selectTimerCounter,
	(counter) => {
		const modifiedDateObject = new Date(modifiedDate)
		const currentTime = new Date()
		const returnTime = new Date(modifiedDateObject.getTime() + (duration * 1000))
		if (currentTime.getTime() < returnTime.getTime()) {
			const timeDiff = returnTime.getTime() - currentTime.getTime()
			return Math.floor(timeDiff / (1000 * 60))
		}
		const add5Min = returnTime.getTime() + (5 * 60 * 1000)

		if (currentTime.getTime() <= add5Min) {
			const timeDiff = add5Min - currentTime.getTime()
			return timeDiff > 0 ? 0 : null
		}
		return null
	}
)

export const selectRemainingTime = (estimatedProcessTimestamp, hoursSign = 'h', minutesSign = 'm') => createSelector(
	selectTimerCounter,
	(counter) => {
		if (!estimatedProcessTimestamp) return null
		const estimatedProcessTimestampObject = new Date(estimatedProcessTimestamp)
		const currentTime = new Date()
		const leftTime = new Date(estimatedProcessTimestampObject.getTime() - currentTime.getTime())

		if (currentTime.getTime() > estimatedProcessTimestampObject.getTime()) {
			const delayTime = currentTime.getTime() - estimatedProcessTimestampObject.getTime()
			return milisecToHoursMinutes(delayTime, hoursSign, minutesSign)
		} else {
			return milisecToHoursMinutes(leftTime, hoursSign, minutesSign)
		}
	}
)

export const selectIsDriverLate = (estimatedProcessTimestamp) => createSelector(
	selectTimerCounter,
	(counter) => {
		if (!estimatedProcessTimestamp) return null
		const estimatedProcessTimestampObject = new Date(estimatedProcessTimestamp)
		const currentTime = new Date()

		return currentTime.getTime() > estimatedProcessTimestampObject.getTime()
	}
)
