import { APPEND_CASHBOOK_ENTRIES_FOR_ALL_DAYS, SET_CASHBOOK_DATE_ENTRY, SET_CASHBOOK_DATE_FROM, SET_CASHBOOK_DATE_TO, SET_CASHBOOK_ENTRIES_FOR_ALL_DAYS, SET_CASHBOOK_ENTRIES_TOTAL_LENGTH_FOR_ALL_DAYS, SET_CASHBOOK_LOADING } from '../../actionTypes/cashbook'

const initialState = {
  dateFrom: null,
  dateTo: null,
  entriesTotalLengthForAllDays: null,
  entriesForAllDays: [],
  loading: false
}

export function cashBookReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CASHBOOK_DATE_FROM:
      return {
        ...state,
        dateFrom: action.value,
      }
    case SET_CASHBOOK_DATE_TO:
      return {
        ...state,
        dateTo: action.value,
      }
    case SET_CASHBOOK_DATE_ENTRY:
      return {
        ...state,
        datePaymentTransactions: action.value,
      }
    case SET_CASHBOOK_ENTRIES_FOR_ALL_DAYS:
      return {
        ...state,
        entriesForAllDays: action.value,
      }
    case SET_CASHBOOK_ENTRIES_TOTAL_LENGTH_FOR_ALL_DAYS:
      return {
        ...state,
        entriesTotalLengthForAllDays: action.value,
      }
    case APPEND_CASHBOOK_ENTRIES_FOR_ALL_DAYS:
      return {
        ...state,
        entriesForAllDays: state.entriesForAllDays.concat(...action.value),
      }
    case SET_CASHBOOK_LOADING:
      return {
        ...state,
        loading: action.value,
      }
    default:
      return state
  }
}


