import React from 'react'
import { useTranslation } from 'react-i18next'
import { getNeededDateFormat } from '../../../../helper'
import MainIcon from '../../../../img/svg/main'
import PlatformIcon from '../../../../img/svg/platforms'
import Accordion from '../../../Elements/accordion'
import Currency from '../../../Elements/currency'
import Order from '../../../Elements/modal/order/Order'
import { CarIcon, ModalContent, ModalWrapper, OrderInfo, OrderInfoBlock, OrderInfoItem, OrderInfoText, OrderPreorderBlock, OrderTimeBlock, OrderWrap } from '../index.styled'
import sprite from '../../../../img/svg/main/index.svg'
import { useTheme } from 'styled-components'

const OrderWithoutTimeButtons = React.memo(({ order }) => {
	const { t } = useTranslation()
	const { colorTheme } = useTheme()
	const driverArriveTime = order?.deliveryMetaData?.partner?.pickupEta
	const preorderDate = order?.preOrder

	return (
		<>
			<ModalWrapper data-testid="platform-drivers-order-wrapper" className="full-width">
				<ModalContent>
					<OrderInfo>
						<OrderInfoBlock data-testid="time" className="with-image">
							<OrderTimeBlock className={preorderDate ? 'gap-sm' : 'gap-m'}>
								{preorderDate ?
									<OrderPreorderBlock>
										<OrderInfoItem>
											<OrderInfoText className="small">{t('Modal.platform_drivers_order_modal.driverArrive')}</OrderInfoText>
											<MainIcon icon="clock_on_3" className="dark" />
											<OrderInfoText>{getNeededDateFormat(driverArriveTime, 'H:mm')}</OrderInfoText>
										</OrderInfoItem>
										<OrderInfoItem>
											<OrderInfoText className="small purple">{t('Modal.platform_drivers_order_modal.preorderAt')}</OrderInfoText>
											<MainIcon icon="clock_on_3" />
											<OrderInfoText className="purple">{getNeededDateFormat(preorderDate, 'H:mm')}</OrderInfoText>
										</OrderInfoItem>
									</OrderPreorderBlock> :
									<OrderInfoItem>
										<MainIcon icon="clock_on_3" />
										<OrderInfoText className="purple">{getNeededDateFormat(driverArriveTime, 'H:mm')}</OrderInfoText>
									</OrderInfoItem>}
								<OrderInfoItem className={preorderDate ? 'justify-end' : ''}>
									<MainIcon icon="date" />
									<OrderInfoText className="purple">{getNeededDateFormat(driverArriveTime, 'dd.MM.y')}</OrderInfoText>
								</OrderInfoItem>
							</OrderTimeBlock>
							<CarIcon className={preorderDate ? 'margin-0' : ''}>
								<svg data-testid="car-express">
									<use href={sprite + '#car_express'} />
								</svg>
							</CarIcon>
						</OrderInfoBlock>
						<OrderInfoBlock data-testid="platform">
							<PlatformIcon icon={`${order?.platformMetaData?.platformType}-${colorTheme}`} />
						</OrderInfoBlock>
						<OrderInfoBlock data-testid="payment">
							<OrderInfoItem>
								<MainIcon icon={order?.paymentMethod} />
								<OrderInfoText className="purple"><Currency>{order?.totalPrice}</Currency></OrderInfoText>
							</OrderInfoItem>
						</OrderInfoBlock>
					</OrderInfo>
					<Accordion titleIcon="report"
										 titleWhenOpen={t('Modal.platform_drivers_order_modal.viewOrder')}
										 titleWhenClose={t('Modal.platform_drivers_order_modal.viewOrder')}
										 size="small"
										 outline={true}>
						<OrderWrap>
							<Order order={order} />
						</OrderWrap>
					</Accordion>
				</ModalContent>
			</ModalWrapper>
		</>
	)
})

OrderWithoutTimeButtons.displayName = 'OrderWithoutTimeButtons'
export default OrderWithoutTimeButtons
