import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const CashDeskSettings = lazyWithRetry(() => import(/* webpackChunkName: "cash-desk-settings" */'../../component/Settings/CashDeskSettings'))

const CashDeskSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<CashDeskSettings />
		</Suspense>
	)
})

CashDeskSettingsPage.displayName = 'CashDeskSettingsPage'
export default CashDeskSettingsPage
