import React, { useRef, useState } from 'react'
import { useOutsideClick } from '../../../../hooks/useOutsideClick'
import { CenterContainer, TooltipBox, TooltipText, TooltipWrapper } from './index.styled'

const TableHeaderTooltip = ({ position, text, children, isShow = true, width }) => {
	const [isHovered, setIsHovered] = useState(false)
	const [isFocused, setIsFocused] = useState(false)
	const tooltipRef = useRef(null)

	const showTooltip = isShow && (isHovered || isFocused)

	const handleClick = (e) => {
		e.preventDefault()
		if (tooltipRef.current) {
			tooltipRef.current.blur()
		}
	}

	useOutsideClick(tooltipRef, () => {
		setIsHovered(false)
	})

	return (
		<TooltipWrapper onPointerEnter={() => setIsHovered(true)}
										onPointerLeave={() => setIsHovered(false)}
										onFocus={() => setIsFocused(true)}
										onBlur={() => setIsFocused(false)}
										showOnFocus={isFocused}
										onClick={handleClick}
										ref={tooltipRef}>
			{showTooltip && (
				<CenterContainer position={position} width={width}>
					<TooltipBox position={position}>
						<TooltipText>{text}</TooltipText>
					</TooltipBox>
				</CenterContainer>
			)}
			{children}
		</TooltipWrapper>
	)
}

TableHeaderTooltip.displayName = 'TableHeaderTooltip'
export default TableHeaderTooltip
