import { setAppErrors } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'
import getSupportData from '../../axios/registration/supportData'
import { setSupportDealerContacts } from '../../action/support'

export const getSupportDataThunk = (callbackSuccess, callbackError) => dispatch => {
	return getSupportData()
		.then(res => {
			dispatch(setSupportDealerContacts(res.data))
			if (typeof callbackSuccess === 'function') callbackSuccess()
		}).catch((error) => {
			if (typeof callbackSuccess === 'function') callbackError()
			if (error.response.data?.errorStatusCode === 'dealer_not_found') {
				dispatch(setSupportDealerContacts(null))
			} else {
				dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}
