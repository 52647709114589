import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { TooltipWrapper } from '../../tooltip/index.styled'

export const DiscountLabel = styled.label`
	width: 100%;
	height: 100%;
	cursor: pointer;
	font-size: 1em;
	display: grid;
	align-items: center;
	grid-template-columns: 2.5fr 1fr 1fr;

	> ${TooltipWrapper} {
		justify-self: end;
	}
`
DiscountLabel.displayName = 'DiscountLabel'

export const DiscountTextWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 0.63em;
`
DiscountTextWrap.displayName = 'DiscountTextWrap'

export const DiscountInput = styled.div`
	display: flex;
	width: 5.88em;
	height: 2.13em;
	border-radius: 0.37em;
	border: 1px solid ${colorTheme.cart.discountInput.border};
	background-color: ${colorTheme.cart.discountInput.background};
	outline: 0;
	padding: 0;

	&.active {
		border-color: ${colorTheme.cart.discountInput.borderActive};
	}

	> input {
		font-size: 1.12em;
		width: 54%;
		padding: 0;
		border: none;
		margin: 0 0.31em;
		background: transparent;
		font-family: ${theme.fontRobotoMedium};
		text-align: center;
		box-sizing: border-box;
		color: ${colorTheme.cart.discountInput.input.text};
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
			display: none;
			-webkit-appearance: none;
			margin: 0;
		}
	}

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 46%;
		padding: 0;
		outline: none;
		border-left: 1px solid ${colorTheme.cart.discountInput.border};
		background-color: transparent;
		appearance: none;
		font-size: 1.12em;
		font-family: ${theme.fontRobotoMedium};
		color: ${colorTheme.cart.discountInput.currency.text};
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 6.06em;
	}
`
