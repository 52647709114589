import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../buttons/Button'
import CustomModal from '../../../modal/CustomModal'
import { GetMoneyInputText, GetMoneyInputWrap, GiftVoucherButtonsWrapper, GiftVoucherCostItem, GiftVoucherCostList, GiftVoucherModalWrapper } from './index.styled'
import { selectCurrencySymbol, selectFormattedCurrency, selectIsNeededZIndex } from '../../../../../redux/selector/system'
import keyboardImg from '../../../../../img/svg/input/keyboard.svg'
import Keyboard from '../../../keyboard'
import ReactDOM from 'react-dom'
import KeyBindings from '../../../keybindings'
import { addCashDeskItem } from '../../../../../redux/action/cashDesk'
import { addDeliveryCartItem } from '../../../../../redux/action/deliveryCart'
import { convert2GiftCouponItem } from '../helper'
import { addTableCartOrderItem } from '../../../../../redux/action/tableCart'
import { updateClientMonitorCartThunk } from '../../../../../redux/thunk/clientMonitorCart'

const EVENT_Z_INDEX = 48
const EVENT_Z_INDEX_FOR_ESC = 49

const costs = ['10', '20', '30', '50', '75', '100', '125', '150']

const ActivateGiftVoucherModal = React.memo(({ isOpen, close, module }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const currencySymbol = useSelector(selectCurrencySymbol)
	const [moneySum, setMoneySum] = useState(0)
	const [moneyKeyboard, setMoneyKeyboard] = useState('')
	const [showKeyboard, setShowKeyboard] = useState(false)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const formattedSum = useSelector(selectFormattedCurrency(moneySum))
	const formattedKeyboard = useSelector(selectFormattedCurrency(typeof moneyKeyboard === 'number' ? moneyKeyboard : Number(moneyKeyboard)))
	const domElement = document.getElementById('content')

	const onClose = useCallback(() => {
		close()
		setMoneySum(0)
		setMoneyKeyboard('')
	}, [close])

	const addGiftCouponToCart = useCallback(() => {
		const giftCoupon = {
			name: 'Geschenk Gutschein',
			price: moneySum || +moneyKeyboard,
		}

		const giftCouponItem = convert2GiftCouponItem(giftCoupon)

		if (module === 'CASH_DESK') {
			dispatch(updateClientMonitorCartThunk('CASH_DESK',
				() => dispatch(addCashDeskItem(giftCouponItem))))
		} else if (module === 'DELIVERY') {
			dispatch(updateClientMonitorCartThunk('DELIVERY',
				() => dispatch(addDeliveryCartItem(giftCouponItem))))
		} else {
			dispatch(addTableCartOrderItem(giftCouponItem))
		}

		onClose()
	}, [onClose, moneySum, moneyKeyboard, dispatch, module])

	const handleClick = useCallback((cost) => {
		setShowKeyboard(false)
		setMoneyKeyboard('')
		setMoneySum(cost)
	}, [])

	const toggleKeyboard = useCallback(() => {
		setShowKeyboard(!showKeyboard)
	}, [showKeyboard])

	const handleKeyboardOfScreenType = useCallback((value) => {
		setMoneySum(0)
		const regex = /^[\d.,]*$/
		if (!regex.test(value)) return
		if (value.includes(',')) {
			if (!String(moneyKeyboard).includes('.') && String(moneyKeyboard).length) {
				return setMoneyKeyboard(value.replace(',', '.'))
			} else {
				return
			}
		}
		setMoneyKeyboard(value)
	}, [moneyKeyboard])

	const closeKeyboard = useCallback(() => {
		setShowKeyboard(false)
	}, [])

	const handleEnter = useCallback(() => {
		addGiftCouponToCart()
	}, [addGiftCouponToCart])

	const handleDelete = useCallback(() => {
		setMoneySum(0)
		setMoneyKeyboard(moneyKeyboard => moneyKeyboard.replace(/.$/, ''))
	}, [])

	const handleCommaClick = (value) => {
		setMoneySum(0)
		setMoneyKeyboard(moneyKeyboard + value)
	}

	const handleKeyboardType = useCallback((value) => {
		setMoneySum(0)
		setMoneyKeyboard(moneyKeyboard + value)
	}, [moneyKeyboard])

	return (
		<>
			<CustomModal isOpen={isOpen}
									 close={onClose}
									 size="auto"
									 title={t('Cart.GiftVoucher.title')}
									 zIndex={EVENT_Z_INDEX}
									 disableBackButton={true}
									 icon="gift-voucher">
				<GiftVoucherModalWrapper>
					<GiftVoucherCostList>
						{costs.map(cost => (
							<GiftVoucherCostItem key={cost}
																	 onClick={() => handleClick(+cost)}
																	 className={moneyKeyboard ? 'noHover' : ''}
																	 name={cost}>
								{cost + ' ' + currencySymbol}
							</GiftVoucherCostItem>
						))}
					</GiftVoucherCostList>
					<GetMoneyInputWrap className={((moneySum || moneyKeyboard) ? 'active ' : '')}>
						<GetMoneyInputText className="input">
							{t('Cart.GiftVoucher.title') + ': ' + (moneySum > 0 ? formattedSum : formattedKeyboard)}
						</GetMoneyInputText>
						<img src={keyboardImg} alt="keyboard" onClick={toggleKeyboard} className="keyboard" />
					</GetMoneyInputWrap>
				</GiftVoucherModalWrapper>
				<GiftVoucherButtonsWrapper>
					<Button onClick={onClose}
									text={t('buttons.controls.cancel_1')}
									icon="clear"
									color="gray"
									name="cansel"
									keyButton="ESC"
									zIndex={EVENT_Z_INDEX_FOR_ESC} />
					<Button onClick={addGiftCouponToCart}
									text={t('buttons.controls.save')}
									icon="save"
									color="green"
									name="activate"
									keyButton="F2"
									zIndex={EVENT_Z_INDEX}
									disabled={!moneySum && !moneyKeyboard} />
				</GiftVoucherButtonsWrapper>
				{showKeyboard && ReactDOM.createPortal(
					<Keyboard onlyNumbers={true}
										handleType={handleKeyboardOfScreenType}
										currentValue={moneyKeyboard}
										close={closeKeyboard}
										enter={handleEnter} />, domElement)}
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter}
										 delet={handleDelete}
										 comma={() => handleCommaClick('.')}
										 zero={() => handleKeyboardType('0')}
										 one={() => handleKeyboardType('1')}
										 two={() => handleKeyboardType('2')}
										 three={() => handleKeyboardType('3')}
										 four={() => handleKeyboardType('4')}
										 five={() => handleKeyboardType('5')}
										 six={() => handleKeyboardType('6')}
										 seven={() => handleKeyboardType('7')}
										 eight={() => handleKeyboardType('8')}
										 nine={() => handleKeyboardType('9')} />}
		</>
	)
})

ActivateGiftVoucherModal.displayName = 'ActivateGiftVoucherModal'
export default ActivateGiftVoucherModal
