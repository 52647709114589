import React from 'react'
import { SvgTableWrap } from '../index.styled'

const SVGRoundTable = ({ index, onClick, data, children, color, testId, id, animationClassname }) => {
	const layout = (color ? `${color} ` : '') + 'round ' + (data.isEm ? 'sizeWithEm ' : '') + (animationClassname ? animationClassname + ' ' : '')
	return (
		<>
			<SvgTableWrap {...data}
										className={layout}
										id={id}
										onClick={onClick}
										data-testid={testId}>
				{children}
				<RoundTableSVG chairCount={data.chairCount} />
			</SvgTableWrap>
		</>
	)
}
SVGRoundTable.displayName = 'SVGRoundTable'
export default SVGRoundTable

export const RoundTableSVG = ({ chairCount }) => {
	const chairClassname = (chairNumber) => {
		return chairNumber <= chairCount ? '' : 'hidden'
	}
	return (
		<svg viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g className={chairClassname(3)}>
				<path d="M17.8571 40.6337L40.0643 18.4908C41.8979 16.6541 44.8521 16.6541 46.6857 18.4908L68.8928 40.6337C70.7264 42.4704 70.7264 45.4296 68.8928 47.2663L46.6857 69.5112C44.8521 71.348 41.8979 71.348 40.0643 69.5112L17.8571 47.2663C16.0235 45.4296 16.0235 42.4704 17.8571 40.6337Z" fill="#7A7A7A" />
				<path d="M43.4263 17.0612C44.6487 17.0612 45.8711 17.5714 46.7879 18.4898L68.8932 40.6327C70.7268 42.4694 70.7268 45.4286 68.8932 47.2653L46.686 69.5102C45.7692 70.4286 44.5468 70.9388 43.3244 70.9388C42.102 70.9388 40.8796 70.4286 39.9628 69.5102L17.8575 47.2653C16.0239 45.4286 16.0239 42.4694 17.8575 40.6327L40.0646 18.4898C40.9815 17.5714 42.2039 17.0612 43.4263 17.0612ZM43.4263 14C41.3889 14 39.4534 14.8163 37.9254 16.2449L15.7183 38.4898C12.6623 41.551 12.6623 46.449 15.7183 49.5102L37.9254 71.7551C39.3516 73.1837 41.3889 74 43.4263 74C45.4636 74 47.3991 73.1837 48.9271 71.7551L71.1343 49.5102C74.1903 46.449 74.1903 41.551 71.1343 38.4898L48.9271 16.2449C47.3991 14.8163 45.4636 14 43.4263 14Z" fill="white" />
			</g>
			<g className={chairClassname(1)}>
				<path d="M119.963 40.6337L142.189 18.4908C144.025 16.6541 146.981 16.6541 148.816 18.4908L171.042 40.7357C172.878 42.5725 172.878 45.5317 171.042 47.3684L148.816 69.5112C146.981 71.348 144.025 71.348 142.189 69.5112L119.963 47.2663C118.128 45.4296 118.128 42.4704 119.963 40.6337Z" fill="#7A7A7A" />
				<path d="M145.452 17.0612C146.675 17.0612 147.899 17.5714 148.816 18.4898L171.042 40.7347C172.877 42.5714 172.877 45.5306 171.042 47.3673L148.816 69.5102C147.899 70.4286 146.675 70.9388 145.452 70.9388C144.228 70.9388 143.005 70.4286 142.087 69.5102L119.861 47.2653C118.026 45.4286 118.026 42.4694 119.861 40.6327L142.189 18.4898C143.107 17.5714 144.228 17.0612 145.452 17.0612ZM145.452 14C143.413 14 141.476 14.8163 139.946 16.2449L117.72 38.4898C114.662 41.551 114.662 46.449 117.72 49.5102L139.946 71.7551C141.374 73.1837 143.413 74 145.452 74C147.491 74 149.428 73.1837 150.957 71.7551L173.183 49.5102C174.611 48.0816 175.426 46.0408 175.426 44C175.426 41.9592 174.611 40.0204 173.183 38.4898L150.957 16.2449C149.53 14.8163 147.593 14 145.452 14Z" fill="white" />
			</g>
			<g className={chairClassname(4)}>
				<path d="M119.963 142.634L142.189 120.389C144.025 118.553 146.981 118.553 148.816 120.389L171.042 142.634C172.878 144.471 172.878 147.43 171.042 149.267L148.816 171.512C146.981 173.348 144.025 173.348 142.189 171.512L119.963 149.267C118.128 147.43 118.128 144.471 119.963 142.634Z" fill="#7A7A7A" />
				<path d="M145.452 119.061C146.675 119.061 147.899 119.571 148.816 120.49L171.042 142.735C172.877 144.571 172.877 147.531 171.042 149.367L148.816 171.612C147.899 172.531 146.675 173.041 145.452 173.041C144.228 173.041 143.005 172.531 142.087 171.612L119.861 149.367C118.026 147.531 118.026 144.571 119.861 142.735L142.087 120.49C143.107 119.469 144.228 119.061 145.452 119.061ZM145.452 116C143.413 116 141.476 116.816 139.946 118.245L117.72 140.49C114.662 143.551 114.662 148.449 117.72 151.51L139.946 173.755C141.374 175.184 143.413 176 145.452 176C147.491 176 149.428 175.184 150.957 173.755L173.183 151.51C174.611 150.082 175.426 148.041 175.426 146C175.426 143.959 174.611 142.02 173.183 140.49L150.957 118.245C149.53 116.714 147.593 116 145.452 116Z" fill="white" />
			</g>
			<g className={chairClassname(2)}>
				<path d="M17.8576 142.634L40.0648 120.389C41.8984 118.553 44.8525 118.553 46.6862 120.389L68.8933 142.634C70.7269 144.471 70.7269 147.43 68.8933 149.267L46.6862 171.512C44.8525 173.348 41.8984 173.348 40.0648 171.512L17.8576 149.267C16.024 147.43 16.024 144.471 17.8576 142.634Z" fill="#7A7A7A" />
				<path d="M43.4263 119.061C44.6487 119.061 45.8711 119.571 46.7879 120.49L68.8932 142.633C70.7268 144.469 70.7268 147.429 68.8932 149.265L46.686 171.51C45.7692 172.429 44.5468 172.939 43.3244 172.939C42.102 172.939 40.8796 172.429 39.9628 171.51L17.7556 149.265C15.922 147.429 15.922 144.469 17.7556 142.633L39.9628 120.388C40.9814 119.469 42.2039 119.061 43.4263 119.061ZM43.4263 116C41.3889 116 39.4534 116.816 37.9254 118.245L15.7183 140.49C12.6623 143.551 12.6623 148.449 15.7183 151.51L37.9254 173.755C39.3516 175.184 41.3889 176 43.4263 176C45.4636 176 47.3991 175.184 48.9271 173.755L71.1343 151.51C74.1903 148.449 74.1903 143.551 71.1343 140.49L48.9271 118.245C47.3991 116.714 45.4636 116 43.4263 116Z" fill="white" />
			</g>
			<g className={chairClassname(7)}>
				<path d="M78.7279 3.96715L110.088 4.0126C112.683 4.01039 114.772 6.0993 114.77 8.69463L114.816 40.0548C114.813 42.6501 112.721 44.7426 110.126 44.7448L78.6933 44.7715C76.0979 44.7737 74.009 42.6848 74.0112 40.0895L74.0379 8.65714C74.0402 6.06181 76.1326 3.96935 78.7279 3.96715Z" fill="#7A7A7A" />
				<path d="M113.476 5.37782C114.34 6.24219 114.844 7.46734 114.843 8.765L114.816 40.0531C114.814 42.6485 112.721 44.7409 110.126 44.7431L78.6938 44.7698C77.3961 44.7709 76.171 44.2673 75.3066 43.403C74.4423 42.5386 73.9386 41.3134 73.9397 40.0158L74.0385 8.65548C74.0407 6.06015 76.1331 3.96769 78.7285 3.96549L110.089 4.01094C111.386 4.00984 112.611 4.51344 113.476 5.37782ZM115.64 3.21321C114.2 1.77258 112.254 0.981215 110.163 0.910899L78.7311 0.937602C74.4055 0.941277 70.9421 4.40466 70.9384 8.73021L70.9117 40.1625C70.91 42.1811 71.7734 44.199 73.214 45.6396C74.6547 47.0802 76.6005 47.8716 78.6911 47.9419L110.123 47.9152C114.449 47.9115 117.912 44.4482 117.916 40.1226L117.943 8.69028C117.872 6.59965 117.081 4.65383 115.64 3.21321Z" fill="white" />
			</g>
			<g className={chairClassname(6)}>
				<path d="M149.802 74.0422L181.176 74.101C183.772 74.0999 185.863 76.1906 185.862 78.787L185.848 110.233C185.847 112.829 183.755 114.922 181.158 114.923L149.785 114.864C147.188 114.865 145.098 112.774 145.099 110.178L145.112 78.7322C145.113 76.1358 147.206 74.0433 149.802 74.0422Z" fill="#7A7A7A" />
				<path d="M184.493 75.3958C185.358 76.2609 185.863 77.4868 185.862 78.785L185.849 110.231C185.848 112.827 183.755 114.92 181.159 114.921L149.785 114.862C148.487 114.862 147.261 114.358 146.396 113.493C145.531 112.628 145.026 111.402 145.027 110.104L145.04 78.6581C145.042 76.0617 147.134 73.9693 149.73 73.9681L181.176 74.099C182.474 74.0985 183.628 74.5307 184.493 75.3958ZM186.658 73.2312C185.216 71.7894 183.269 70.9969 181.177 70.9256L149.732 70.939C145.404 70.9408 141.941 74.4042 141.939 78.7316L141.926 110.177C141.925 112.197 142.789 114.216 144.231 115.658C145.673 117.099 147.62 117.892 149.712 117.963L181.157 117.95C183.177 117.949 185.197 117.083 186.64 115.64C188.083 114.197 188.877 112.249 188.95 110.157L188.963 78.7116C188.964 76.6922 188.172 74.7452 186.658 73.2312Z" fill="white" />
			</g>
			<g className={chairClassname(8)}>
				<path d="M78.8016 145.04L110.247 145.027C112.844 145.026 114.934 147.116 114.933 149.713L114.92 181.158C114.919 183.755 112.826 185.847 110.23 185.848L78.7843 185.862C76.1879 185.863 74.0972 183.772 74.0983 181.176L74.1116 149.73C74.1127 147.134 76.2052 145.041 78.8016 145.04Z" fill="#7A7A7A" />
				<path d="M113.493 146.396C114.358 147.261 114.863 148.487 114.862 149.785L114.849 181.231C114.848 183.827 112.755 185.92 110.159 185.921L78.713 185.934C77.4148 185.935 76.1889 185.43 75.3238 184.565C74.4587 183.7 73.9543 182.474 73.9549 181.176L73.9683 149.73C73.9694 147.134 76.0618 145.041 78.6582 145.04L110.104 145.027C111.546 145.026 112.628 145.531 113.493 146.396ZM115.658 144.231C114.216 142.789 112.269 141.997 110.177 141.926L78.7317 141.939C74.4043 141.941 70.9409 145.404 70.9391 149.732L70.9257 181.177C70.9249 183.197 71.7895 185.216 73.2313 186.658C74.6732 188.099 76.6202 188.892 78.7117 188.963L110.157 188.95C112.177 188.949 114.197 188.083 115.64 186.64C117.083 185.197 117.877 183.249 117.95 181.157L117.963 149.712C118.036 147.62 117.172 145.745 115.658 144.231Z" fill="white" />
			</g>
			<g className={chairClassname(5)}>
				<path d="M8.72708 73.969L40.1594 73.9423C42.7547 73.9401 44.8437 76.029 44.8414 78.6243L44.8147 110.057C44.8125 112.652 42.7201 114.744 40.1247 114.747L8.69241 114.773C6.09708 114.776 4.00818 112.687 4.01038 110.091L4.03708 78.659C4.03929 76.0637 6.13175 73.9712 8.72708 73.969Z" fill="#7A7A7A" />
				<path d="M43.4751 75.3778C44.3395 76.2422 44.8431 77.4673 44.842 78.765L44.8154 110.053C44.8132 112.648 42.7207 114.741 40.1254 114.743L8.69307 114.77C7.39541 114.771 6.17027 114.267 5.30589 113.403C4.44152 112.539 3.93791 111.313 3.93901 110.016L3.96572 78.5835C3.96792 75.9881 6.06038 73.8957 8.65571 73.8935L40.088 73.8668C41.4577 73.9377 42.6107 74.5134 43.4751 75.3778ZM45.6397 73.2132C44.1991 71.7726 42.2533 70.9812 40.1627 70.9109L8.73032 70.9376C4.40477 70.9413 0.941387 74.4047 0.937711 78.7302L0.911003 110.163C0.909289 112.181 1.77269 114.199 3.21331 115.64C4.65394 117.08 6.59976 117.872 8.69038 117.942L40.1227 117.915C44.4483 117.912 47.9116 114.448 47.9153 110.123L47.942 78.6903C47.9439 76.5275 47.0803 74.6538 45.6397 73.2132Z" fill="white" />
			</g>
			<path d="M95.0002 171.799C137.416 171.799 171.8 137.415 171.8 94.9992C171.8 52.5837 137.416 18.1992 95.0002 18.1992C52.5847 18.1992 18.2002 52.5837 18.2002 94.9992C18.2002 137.415 52.5847 171.799 95.0002 171.799Z" fill="#7A7A7A" />
			<path className="colorTable" d="M95.0002 26.7326C132.654 26.7326 163.267 57.3459 163.267 94.9992C163.267 132.653 132.654 163.266 95.0002 163.266C57.3469 163.266 26.7335 132.653 26.7335 94.9992C26.7335 57.3459 57.3469 26.7326 95.0002 26.7326ZM95.0002 18.1992C52.5469 18.1992 18.2002 52.5459 18.2002 94.9992C18.2002 137.453 52.5469 171.799 95.0002 171.799C137.454 171.799 171.8 137.453 171.8 94.9992C171.8 52.5459 137.454 18.1992 95.0002 18.1992Z" fill="#38CD90" />
			<path d="M95 18.2C137.453 18.2 171.8 52.5467 171.8 95C171.8 137.453 137.453 171.8 95 171.8C52.5467 171.8 18.2 137.453 18.2 95C18.2 52.5467 52.5467 18.2 95 18.2ZM95 15C50.84 15 15 50.84 15 95C15 139.16 50.84 175 95 175C139.16 175 175 139.16 175 95C175 50.84 139.16 15 95 15Z" fill="white" />
		</svg>
	)
}
RoundTableSVG.displayName = 'RoundTableSVG'
