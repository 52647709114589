import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import colors from '../../../../style/colors'

export const OrderButtonsWrap = styled.div`
  display: grid;
	grid-template-columns: 1fr 1fr 1fr;
  gap: 0.63em;
	width: 100%;

	@media screen and (max-width: ${theme.point720}) {
    grid-template-columns: auto;
  }
`
OrderButtonsWrap.displayName = 'OrderButtonsWrap'

export const DenyButton = styled.button`
	font-size: 1em;
  	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 3.44em;
	padding: 0.63em 1em;
	border-radius: 0.75em;
	border: 2px solid ${colors.blue};
	background-color: ${colors.white};
	user-select: none;
	cursor: pointer;

	&.active {
		background-color: ${colors.green};
		border-color: ${colors.green};
	}

	&.disabled {
		background-color: ${colors.gray_light_beta};
		border-color: ${colors.gray_light_beta};
	}
`
DenyButton.displayName = 'DenyButton'

export const DenyButtonText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.25em;
	font-weight: 500;
	color: ${colors.purple};

	&.active {
		color: ${colors.white};
	}
`
DenyButtonText.displayName = 'DenyButtonText'
