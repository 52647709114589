import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BillingAddressFormWrapper, CustomerAddressWrapper, CustomerNameContainer, FormLine } from './index.styled'
import TextInput from '../../../../../inputs/TextInput'
import CheckBoxInputControlled from '../../../../../inputs/CheckboxInput/controlled'
import { filterName, filterPhone, formatAddressInInput } from '../../../../../cart/InvoiceModals/InvoiceCustomerDataModal/main/helper'
import AddressSearchInputs from '../../../../../inputs/AddressSearchInputs'
import { selectCompanyData } from '../../../../../../../redux/selector/system'
import { setCustomerGeoData, setCustomerGeoDataLoading } from '../../../../../../../redux/action/customer'
import { deliveryDistanceCustomer } from '../../../../../../../redux/thunk/customer'

const MainBillingAddressForm = ({ zIndex, onSubmit, handleSubmit, register, errors, setValue, watch, setFocus }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const customer = useSelector(store => store.customerStore.customer)
	const geoData = useSelector(store => store.customerStore.geoData)
	const [isFirma, setIsFirma] = useState(true)

	const companyCountryCode = useSelector(selectCompanyData('countryCode'))
	const watchAddressHouse = watch('customerAddress.house')
	const watchAddressStreet = watch('customerAddress.street')
	const watchAddressCity = watch('customerAddress.city')
	const watchAddressZipCode = watch('customerAddress.zipCode')
	const isValidAddress = watchAddressStreet && watchAddressHouse && watchAddressCity && watchAddressZipCode

	useEffect(() => {
		if (customer && !customer?.companyName) {
			setIsFirma(false)
		} else {
			setIsFirma(true)
		}
	}, [customer?.companyName]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (geoData) {
			setValue('customerAddress.latitude', geoData.lat)
			setValue('customerAddress.longitude', geoData.lon)
		}
	}, [geoData]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isValidAddress) {
			dispatch(setCustomerGeoData(null)) // should reset geoData, cause of coordinates change, so it contains deprecated values
			dispatch(setCustomerGeoDataLoading(true))
			const timeOutId = setTimeout(() => {
				dispatch(deliveryDistanceCustomer({
					street: watchAddressStreet,
					streetNumber: watchAddressHouse,
					city: watchAddressCity,
					postalCode: watchAddressZipCode,
				}))
			}, 300)
			return () => clearTimeout(timeOutId)
		}
	}, [watchAddressHouse])// eslint-disable-line react-hooks/exhaustive-deps

	const toggleFirmaShow = useCallback(() => {
		setIsFirma(prevState => !prevState)
	}, [])

	const handlePhoneChange = useCallback((e) => {
		e.target.value = filterPhone(e.target.value)
		setValue('phoneNumber', e.target.value)
	}, [setValue])

	const handleNameChange = useCallback((e) => {
		e.target.value = filterName(e.target.value)
		setValue('name', e.target.value)
	}, [setValue])

	return (
		<BillingAddressFormWrapper id="invoice-billing-address-form"
															 data-testid="invoice-billing-address-form"
															 onSubmit={handleSubmit(onSubmit)}>
			<FormLine>
				<TextInput testId="customer-phone-number"
									 name="phoneNumber"
									 tabIndex="901"
									 label={t('AddOrder.Client.form.Telefonnumer') + '*'}
									 errors={errors}
									 register={register}
									 options={{
										 required: t('app.validation.required'),
										 pattern: {
											 value: /^[0-9]{3,15}$/i,
											 message: t('app.validation.minNumber3'),
										 },
									 }}
									 onChange={handlePhoneChange}
									 watch={watch('phoneNumber')}
									 color="gray"
									 inputMode="tel" />
				<TextInput testId="customer-number"
									 name="customerNumber"
									 tabIndex="902"
									 disabled={true}
									 label={t('AddOrder.Client.form.customerNumber')}
									 errors={errors}
									 register={register}
									 options={{
										 pattern: {
											 value: /^[0-9]{1,150}$/i,
											 message: t('app.validation.number'),
										 },
									 }}
									 watch={watch('customerNumber')}
									 color="gray"
									 inputMode="tel" />
			</FormLine>
			<CustomerNameContainer className={isFirma ? 'columns' : ''}>
				<TextInput testId="customer-name"
									 name="name"
									 tabIndex="903"
									 label={t('AddOrder.Client.form.Name') + (!isFirma ? '*' : '')}
									 errors={errors}
									 register={register}
									 options={{
										 required: !isFirma && t('app.validation.required'),
									 }}
									 onChange={handleNameChange}
									 watch={watch}
									 color="gray">
					<CheckBoxInputControlled id="firma-id" testId="firma-id" label={t('AddOrder.Client.form.Company')} className="firma-id"
																	 checked={isFirma} onChange={toggleFirmaShow} />
				</TextInput>
			</CustomerNameContainer>
			{isFirma &&
				<TextInput testId="customer-company"
									 name="companyName"
									 tabIndex="904"
									 errors={errors}
									 label={t('AddOrder.Client.form.Company') + (isFirma ? '*' : '')}
									 register={register}
									 options={{
										 required: isFirma && t('app.validation.required'),
									 }}
									 color="gray"
									 watch={watch} />}
			<CustomerAddressWrapper>
				<AddressSearchInputs firstInputName="address"
														 secondInputName="streetNumber"
														 dataName="customerAddress"
														 firstTabIndex="905"
														 secondTabIndex="906"
														 firstLabel={t('AddOrder.Client.form.Strasse') + '*'}
														 secondLabel={t('AddOrder.Client.form.Hausnummer') + '*'}
														 errors={errors}
														 watch={watch}
														 setValue={setValue}
														 setFocus={setFocus}
														 formatAddress={formatAddressInInput}
														 countryDataRequired={false}
														 autocompleteRequest={true}
														 register={register}
														 countryCode={companyCountryCode}
														 returnZIndex={zIndex}
														 cols="cols-2"
														 color="gray" />
				<input data-testid="customer-geodata-distance" type="hidden" value={geoData?.distance || ''} /> {/* only for tests */}
			</CustomerAddressWrapper>
		</BillingAddressFormWrapper>
	)
}

MainBillingAddressForm.displayName = 'MainBillingAddressForm'
export default MainBillingAddressForm
