import styled from 'styled-components'
import { theme } from '../../style/theme'
import { colorTheme } from '../../style/theme/colorTheme'

export const AreasMobileWrapper = styled.div`
	padding: 0.63rem;
	display: grid;
	gap: 0.63rem;
	grid-auto-rows: min-content;
	background: ${colorTheme.restaurant.areas.background};
	min-height: calc(100% - 4.5em);
`
AreasMobileWrapper.displayName = 'AreasMobileWrapper'

export const TablesWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 14.38em;
	font-size: 1rem;
	height: 100%;

	@media screen and (max-width: ${theme.point820}) {
		//padding: 1em;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
		padding: 0.63em 0.44em;
		border-radius: 0 0 0.75em 0.75em;
		background-color: transparent;
	}
`
TablesWrapper.displayName = 'TablesWrapper'

export const TablesMainWrapper = styled.div`
	border-top: 1.25em solid ${props => props.areaColor ? props.areaColor : 'transparent'};
	border-left: 1.25em solid ${props => props.areaColor ? props.areaColor : 'transparent'};
	border-right: .63em solid ${props => props.areaColor ? props.areaColor : 'transparent'};
	border-bottom: none;
	padding: 1.25em 0 1.25em 1.25em;
	background-color: ${props => props.color ? props.color : colorTheme.restaurant.areas.tables.background};
	background-image: ${props => props.bgdImg ? `url(${props.bgdImg})` : ''};
	background-size: auto;

	height: 100%;
	overflow: auto;
	position: relative;

	@media screen and (max-width: ${theme.point820}) {
		border-radius: 0.75em 0 0 0;
		padding: 0.44em;
	}

	@media screen and (max-width: ${theme.point720}) {
		border: none;
		background-color: transparent;
		margin: 0;
		padding: 0;
		border-radius: 0;
	}
`
TablesMainWrapper.displayName = 'TablesMainWrapper'
