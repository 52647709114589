import React, { useCallback, useEffect } from 'react'
import Button from '../../../../buttons/Button'
import TextInput from '../../../../inputs/TextInput'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ResendEmailModalWrap, ResendEmailText } from './index.styled'
import CustomModal from '../../../../modal/CustomModal'
import { setSelectedInvoice, setShowInvoiceResendEmailModal } from '../../../../../../redux/action/invoice'
import { setCustomer } from '../../../../../../redux/action/customer'
import { convert2InvoiceCustomer } from '../InvoiceBillingAddressModal/helper'
import { sendInvoiceEmailThunk, updateInvoiceCustomerThunk } from '../../../../../../redux/thunk/invoice'
import { removeEmptyFields } from '../../../../../../helper'
import { updateCustomerThunk } from '../../../../../../redux/thunk/customer'
import { Form } from '../../../../form/index.styled'
import { emailPattern } from '../../../../../../helper/email'

const EVENT_Z_INDEX = 85

const defaultValues = {
	email: null,
}

const InvoiceResendEmailModal = React.memo(() => {
	const { t } = useTranslation()
	const { handleSubmit, register, formState: { errors }, reset, watch } = useForm({ defaultValues })
	const dispatch = useDispatch()
	const showResendEmailModal = useSelector(store => store.invoice.modal.showResendEmail)
	const customer = useSelector(store => store.customerStore.customer)
	const selectedInvoice = useSelector(store => store.invoice.selectedInvoice)

	const handleReset = useCallback((values) => {
		reset({ ...defaultValues, ...values })
	}, [reset])

	useEffect(() => {
		if (customer) {
			handleReset({
				...customer,
			})
		}
	}, [customer]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = useCallback(() => {
		dispatch(setShowInvoiceResendEmailModal(false))
		handleReset(defaultValues)
		dispatch(setCustomer(null))
		dispatch(setSelectedInvoice(null))
	}, [dispatch, handleReset])

	const handleResendEmail = useCallback(async (data) => {
		const customerData = convert2InvoiceCustomer(data)
		await dispatch(updateInvoiceCustomerThunk(selectedInvoice.id, customerData))
		dispatch(sendInvoiceEmailThunk(selectedInvoice.id))
		handleClose()
	}, [selectedInvoice, handleClose, dispatch])

	const onSubmit = useCallback(async (data) => {
		let dataValue = { ...data }
		dataValue = removeEmptyFields(dataValue)
		await dispatch(updateCustomerThunk(dataValue))
		handleResendEmail(dataValue)
	}, [handleResendEmail, dispatch])

	return (
		<CustomModal isOpen={showResendEmailModal && customer}
								 close={handleClose}
								 title={t('invoices.modal.resendEmail.title')}
								 size="x-small"
								 id="invoice-resend-email-modal"
								 zIndex={EVENT_Z_INDEX}
								 disableBackButton={true}
								 button={
									 <>
										 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')}
														 keyButton="F3" zIndex={EVENT_Z_INDEX}
														 onClick={handleClose}
														 className="back" />
										 <Button name="send-email" color="green" icon="send-email" text={t('buttons.controls.send_invoice')}
														 keyButton="F2" zIndex={EVENT_Z_INDEX}
														 onClick={handleSubmit(onSubmit)} />
									 </>
								 }>
			<ResendEmailModalWrap>
				<ResendEmailText>{t('invoices.modal.resendEmail.description')}</ResendEmailText>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<TextInput testId="billing-address-email"
										 name="email"
										 label={t('AddOrder.Client.form.email') + '*'}
										 errors={errors}
										 register={register}
										 options={{
											 required: t('app.validation.required'),
											 pattern: {
												 value: emailPattern,
												 message: t('app.validation.invalid_email'),
											 },
										 }}
										 watch={watch('email')}
										 color="gray" />
				</Form>
			</ResendEmailModalWrap>
		</CustomModal>
	)
})

InvoiceResendEmailModal.displayName = 'InvoiceResendEmailModal'
export default InvoiceResendEmailModal
