import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { getUnpaidOrdersThunk } from '../../../../redux/thunk/order'
import { useTranslation } from 'react-i18next'
import { setUnpaidOrders, setUnpaidOrdersTotalLength } from '../../../../redux/action/order'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { reInitializeOrderFilter, setOrderFilter } from '../../../../redux/action/orderfilter'
import CustomTable from '../index'
import { setSocketReconnected } from '../../../../redux/action/socket'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'
import TableTr from '../tr'
import { OrderTableWrapper } from '../OrdersTable/index.styled'
import UnpaidOrdersTableTdList from './UnpaidOrdersTableTdList'

const EVENT_Z_INDEX = 0

const UnpaidOrdersTable = React.memo(({ id, reverse, defaultFilter = {}, onClick, onDbClick, onEnter, onEscape, onDelete, onNrClick }) => {
	const { t, i18n } = useTranslation()
	const [activeRow, setActiveRow] = useState(0)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const orders = useSelector(store => store.order.unpaidOrders)
	const ordersTotalLength = useSelector(store => store.order.unpaidOrdersTotalLength)
	const paymentMethod = useSelector(store => store.orderfilter.paymentMethod)
	const status = useSelector(store => store.orderfilter.status)
	const excludedStatus = useSelector(store => store.orderfilter.excludedStatus)
	const orderType = useSelector(store => store.orderfilter.orderType)
	const processedByUsername = useSelector(store => store.orderfilter.processedByUsername)
	const date = useSelector(store => store.orderfilter.date)
	const reverseSort = useSelector(store => store.orderfilter.reverse)
	const closed = useSelector(store => store.orderfilter.closed)
	const isPaid = useSelector(store => store.orderfilter.isPaid)
	const socketReconnected = useSelector(store => store.socket.socketReconnected)
	const [filtersInitialized, setFiltersInitialized] = useState(false)
	const dispatch = useDispatch()

	const order = orders && orders[activeRow]

	const tableHeadersStatic = useMemo(() => [
		{ title: t('order.table.nr'), onClick: onNrClick },
		{ title: t('order.table.time') },
		{ title: t('order.table.platform') },
		{ title: t('order.table.customer') },
		{ title: t('order.table.sum') },
		{ title: t('order.table.type') },
		{ title: t('order.table.status') }
	], [i18n?.language, onNrClick])

	useEffect(() => {
		const filter = { ...defaultFilter, reverse, isPaid: false, processedByUsernameWithEmpty: null }
		dispatch(setAppEventZIndex(EVENT_Z_INDEX))
		dispatch(setOrderFilter(filter))
		setFiltersInitialized(true)
		return () => dispatch(reInitializeOrderFilter())
	}, [])

	useEffect(() => {
		batch(() => {
			dispatch(setUnpaidOrders([]))
			dispatch(setUnpaidOrdersTotalLength(0))
			setActiveRow(0)
		})
	}, [date, paymentMethod, status, orderType, processedByUsername, isPaid, closed])

	useEffect(() => {
		if (socketReconnected) {
			// eslint-disable-next-line
			console.info('Load orders by socket reconnect')
			batch(() => {
				dispatch(setUnpaidOrders([]))
				dispatch(setUnpaidOrdersTotalLength(0))
			})
		}
		return () => dispatch(setSocketReconnected(false))
	}, [socketReconnected])

	const onLoad = useCallback((page, size) => {
		dispatch(getUnpaidOrdersThunk(
			date,
			date,
			paymentMethod,
			status,
			excludedStatus,
			orderType,
			processedByUsername,
			page, size, reverseSort, closed, isPaid))
	}, [date, paymentMethod, status, orderType, processedByUsername, reverseSort, closed, isPaid])

	const handleArrowUp = useCallback(() => {
		if (activeRow < 0) {
			setActiveRow(orders.length)
		} else {
			setActiveRow(activeRow - 1)
		}
	}, [activeRow, orders?.length])

	const handleArrowDown = useCallback(() => {
		if (activeRow < orders?.length) {
			setActiveRow(activeRow + 1)
		} else {
			setActiveRow(0)
		}
	}, [activeRow, orders?.length])

	const handleClick = useCallback((item, i) => {
		setActiveRow(i)
		if (onClick) onClick(item)
	}, [onClick])

	const handleDbClick = useCallback((item, i) => {
		setActiveRow(i)
		if (onDbClick) onDbClick(item)
	}, [onDbClick])

	const handleEnter = useCallback(() => {
		if (!order) return
		if (onEnter) onEnter(order)
	}, [order, onEnter])

	const handleEscape = useCallback(() => {
		if (onEscape) onEscape()
	}, [onEscape])

	const handleDelete = useCallback(() => {
		if (!order) return
		if (onDelete) onDelete(order)
	}, [order, onDelete])

	return (
		<OrderTableWrapper id={id}>
			{filtersInitialized &&
				<CustomTable headers={tableHeadersStatic}
										 onLoad={onLoad}
										 currentLength={orders?.length}
										 totalLength={ordersTotalLength}
										 params={[date, paymentMethod, status, orderType, processedByUsername, reverse, closed]}
										 perLoad={10}>
					{orders?.map((item, i) =>
						<TableTr key={i}
										 onDoubleClick={() => handleDbClick(item, i)} onClick={() => handleClick(item, i)}
										 isActive={i === activeRow}
										 disabled={item?.status?.status === 'CANCELED' || !!item?.zNumber}
										 disabledStatus={!!item?.zNumber}
										 name={item?.zNumber ? 'CLOSED' : item?.status?.status}
										 className="row-mob-cols-4 bold-border">
							<UnpaidOrdersTableTdList tableHeadersStatic={tableHeadersStatic} item={item} />
						</TableTr>)}
				</CustomTable>}

			{isNeededZIndex &&
				<KeyBindings arrowUp={handleArrowUp}
										 arrowDown={handleArrowDown}
										 enter={handleEnter}
										 escape={handleEscape}
										 delet={handleDelete} />}
		</OrderTableWrapper>
	)
})

UnpaidOrdersTable.displayName = 'UnpaidOrdersTable'
export default UnpaidOrdersTable
