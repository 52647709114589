import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setAppModal, setAppModalData } from '../../../redux/action/system'
import { ModalButtons, ModalButtonsItem, ModalButtonsItemDeny, ModalContent, OrderInfo, OrderInfoBlock, OrderInfoItem, OrderMap, OrderTitle, OrderUnpaidContent, OrderUnpaidText, OrderUnpaidWrapper, OrderWrap, ProcessingModalWrapper } from './index.styled'
import { getNeededDateFormat } from '../../../helper'
import { addOrderProcessingTimeThunk, denyOrderThunk, removeOrderFromProcessingTimeModalDataThunk } from '../../../redux/thunk/order/addOrderProcessingTime'
import Currency from '../../Elements/currency'
import Loader from '../../Elements/loaders'
import colors from '../../../style/colors'
import { orderTypeOptionsStatic } from '../../../redux/reducer/order/constants'
import CustomModal from '../../Elements/modal/CustomModal'
import MainIcon from '../../../img/svg/main'
import { selectCompanyIndex, selectIsNeededZIndex } from '../../../redux/selector/system'
import Order from '../../Elements/modal/order/Order'
import KeyBindings from '../../Elements/keybindings'
import DropDownButton from '../../Elements/buttons/DropDownButton'
import { selectActivationData } from '../../../redux/selector/company'
import { convertMapImageUrl } from './helper'

const EVENT_Z_INDEX = 998
const BUTTONS_COUNT = 6
const showDenyButtonPlatformTypeList = ['UBER_EAT_PLUGIN', 'WOLT_PLUGIN', 'ONLINE_SHOP', 'FOODORA_PLUGIN']
const showPlatformDenyModal = 'FOODORA_PLUGIN'

const ProcessingTimeOrderModal = React.memo(() => {
	const { t } = useTranslation()
	const processingTimeOrderModalShow = useSelector(store => store.system.modal?.processingTimeOrderModalShow)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const order = useSelector(store => store.system.modalData?.processingTimeOrderModalData[0])
	const isProcessingConfirmOrder = useSelector(store => store.order.isProcessingConfirmOrder)
	const isProcessingDenyOrder = useSelector(store => store.order.isProcessingDenyOrder)
	const dispatch = useDispatch()
	const [activeButtonIndex, setActiveButtonIndex] = useState(2)
	const [imageLoaded, setImageLoaded] = useState(false)
	const [showOrder, setShowOrder] = useState(false)
	const geoData = order?.customer?.geoData
	const timeButtons = useMemo(() => [15, 30, 45, 60, 70, 90], [])
	const showDenyButton = showDenyButtonPlatformTypeList.includes(order?.platformMetaData?.platformType)
	const textDropDownButton = !showOrder ? 'Bestellung Anzeigen' : 'Zurück zur Karte'
	const companyIndex = useSelector(selectCompanyIndex)
	const payed = order?.payments?.filter(item => item?.method === order?.paymentMethod)[0]?.payed
	const { isDeactivated } = useSelector(selectActivationData)
	const isDisabled = isProcessingConfirmOrder || isProcessingDenyOrder
	const layoutDisabled = isDisabled ? 'disabled ' : ''

	useEffect(() => {
		setImageLoaded(false)
	}, [])

	useEffect(() => {
		if (processingTimeOrderModalShow) {
			// eslint-disable-next-line
			console.log(`Opening processing time popup for confirming the order from platform orderId=${order.orderId} companyIndex=${companyIndex} path=${window.location.pathname}`)
		}
	}, [processingTimeOrderModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleConfirm = useCallback((processingTime) => {
		dispatch(addOrderProcessingTimeThunk(order?.orderId, processingTime))
	}, [dispatch, order?.orderId])

	const handleClose = useCallback(() => {
		dispatch(removeOrderFromProcessingTimeModalDataThunk(order?.orderId))
	}, [dispatch, order])

	const handleArrowUp = useCallback(() => {
		if ((activeButtonIndex - 1) < 0) {
			setActiveButtonIndex(BUTTONS_COUNT - 1)
		} else {
			setActiveButtonIndex(activeButtonIndex - 1)
		}
	}, [activeButtonIndex])

	const handleArrowDown = useCallback(() => {
		if ((activeButtonIndex + 1) >= BUTTONS_COUNT) {
			setActiveButtonIndex(0)
		} else {
			setActiveButtonIndex(activeButtonIndex + 1)
		}
	}, [activeButtonIndex])

	const handleEnter = useCallback(() => {
		handleConfirm(timeButtons[activeButtonIndex])
	}, [activeButtonIndex, handleConfirm, timeButtons])

	const handleDeny = useCallback(() => {
		if (order?.platformMetaData?.platformType === showPlatformDenyModal) {
			dispatch(setAppModal({ denyOrderModalShow: true }))
			dispatch(setAppModalData({ denyOrderModalData: [order] }))
			handleClose()
		} else {
			dispatch(denyOrderThunk(order?.orderId, 'Denied by user'))
		}
	}, [dispatch, order, handleClose])

	const handleClickDropDownButton = () => setShowOrder(!showOrder)

	return (
		<>
			<CustomModal isOpen={!isDeactivated && processingTimeOrderModalShow}
									 close={handleClose}
									 id="platform-order-modal"
									 enableOutsideClick={false}
									 size="auto"
									 zIndex={EVENT_Z_INDEX}
									 title={`${t('Modal.change_order')} (${t(orderTypeOptionsStatic.find(t => t.value === order?.orderType)?.label)})`}>
				<ProcessingModalWrapper data-testid="processing-time-wrapper" className={geoData ? 'with-geodata' : ''}>
					<ModalContent>
						<OrderInfo>
							<OrderInfoBlock>
								{!payed ?
									<OrderUnpaidWrapper>
										<OrderUnpaidContent>
											<MainIcon icon={order?.paymentMethod} fill={colors.gray} />
											<OrderUnpaidText data-testid="price"><Currency>{order?.totalPrice}</Currency></OrderUnpaidText>
										</OrderUnpaidContent>
										<OrderUnpaidText className="small">{t('Modal.unpaid_order')}</OrderUnpaidText>
									</OrderUnpaidWrapper> :
									<OrderInfoItem>
										<MainIcon icon={order?.paymentMethod} fill={colors.gray} />
										<p data-testid="price"><Currency>{order?.totalPrice}</Currency></p>
									</OrderInfoItem>}
							</OrderInfoBlock>
							<OrderInfoBlock>
								<OrderInfoItem>
									<MainIcon icon="restaurant-name" fill={colors.gray} />
									<p data-testid="platform">{order?.platformMetaData?.platformName}</p>
								</OrderInfoItem>
							</OrderInfoBlock>
							<OrderInfoBlock className="col-2">
								<OrderInfoItem className="small">
									<MainIcon icon="time" fill={colors.gray} />
									<p data-testid="time">{getNeededDateFormat(order?.orderDate, 'HH:mm') + ' UHR'}</p>
								</OrderInfoItem>
								<OrderInfoItem className="small">
									<MainIcon icon="date" fill={colors.gray} />
									<p>{getNeededDateFormat(order?.orderDate)}</p>
								</OrderInfoItem>
								{geoData &&
									<OrderInfoItem className="small">
										<MainIcon icon="route" fill={colors.gray} />
										<p data-testid="address">{order?.customer?.street} {order?.customer?.streetNumber}, {order?.customer?.postalCode}</p>
										<p className={geoData?.distance > 0 ? '' : 'hidden'} data-testid="distance">{(geoData?.distance / 1000).toFixed(2)} km</p>
									</OrderInfoItem>}
								{geoData?.duration > 0 &&
									<OrderInfoItem className="small">
										<MainIcon icon="time-left" fill={colors.gray} />
										<p data-testid="duration">{Math.round(geoData?.duration / 60)} min</p>
									</OrderInfoItem>}
							</OrderInfoBlock>
						</OrderInfo>
						{geoData && <DropDownButton text={textDropDownButton} active={showOrder} onClick={handleClickDropDownButton} />}
						{geoData && !showOrder && (
							<OrderMap>
								<img className={imageLoaded ? 'loaded' : ''} src={convertMapImageUrl(geoData?.image)} alt="Map" onLoad={() => setImageLoaded(true)} />
								{!imageLoaded && <Loader color={colors.blue} zoom={2} />}
							</OrderMap>
						)}
						{showOrder && (
							<OrderWrap>
								<OrderTitle className={order?.orderDayCounter ? '' : 'hidden'}>{'Bestellungsnr. ' + order?.orderDayCounter}</OrderTitle>
								<Order order={order} />
							</OrderWrap>
						)}
					</ModalContent>
					<ModalButtons>
						{timeButtons?.map((time, index) =>
							<ModalButtonsItem key={time}
																className={(activeButtonIndex === index ? 'active ' : '') + layoutDisabled}
																onClick={!isDisabled ? () => handleConfirm(time) : () => {}}>
								{time} min
							</ModalButtonsItem>)}
						{showDenyButton &&
							<ModalButtonsItemDeny onClick={!isDisabled ? handleDeny : () => {}} className={layoutDisabled}>
								<MainIcon icon="cancel" fill={colors.white} />{t('Modal.deny_order')}
							</ModalButtonsItemDeny>}
					</ModalButtons>
				</ProcessingModalWrapper>
			</CustomModal>

			{(isNeededZIndex && !isDisabled) &&
				<KeyBindings enter={handleEnter} arrowUp={handleArrowUp} arrowDown={handleArrowDown} />}
		</>
	)
})

ProcessingTimeOrderModal.displayName = 'ProcessingTimeOrderModal'
export default ProcessingTimeOrderModal
