import React from 'react'
import { useTranslation } from 'react-i18next'
import { OrderContainer, OrderCount } from './index.styled'
import OrderTotals from './OrderTotals'
import OrderHeader from './OrderHeader'
import OrderProducts from './OrderProducts'
import { countOrderItems } from './helper'

const Order = React.memo(({ order }) => {
	const { t } = useTranslation()
	const countItems = countOrderItems(order)

	return (
		<OrderContainer data-testid="order">
			<OrderHeader order={order} />
			<OrderProducts order={order} />
			<OrderTotals order={order} />
			<OrderCount>{t('Modal.count')}: <span>{countItems}</span></OrderCount>
		</OrderContainer>
	)
})

Order.displayName = 'Order'
export default Order
