import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TextInputControlled from '../../inputs/TextInput/controlled'
import { WaiterDiscountInputWrapper, WaiterTimeError, WaiterTimeSelectWrapper } from './index.styled'
import CustomTable from '../../table'
import TableTr from '../../table/tr'
import SelectInputControlled from '../../inputs/SelectInput/controlled'
import { TableTd } from '../../table/style/table/td/index.styled'
import { TableMobileTitle } from '../../table/style/table/title/index.styled'

const WaiterCartSettings = React.memo(({ waiterLogoutHours, waiterLogoutMinutes, onChangeWaiterLogoutTime, minWaiterLogoutTime, timeError, waiterDiscount, onChangeWaiterDiscount, onBlurWaiterDiscount, showWaiterDiscountCheck, discountError }) => {
	const { t } = useTranslation()
	const hours = Array.from({ length: 24 }, (_, i) => i < 10 ? `0${i}` : `${i}`)
	const minutes = Array.from({ length: 60 }, (_, i) => i < 10 ? `0${i}` : `${i}`)

	const tableHeadersStatic = useMemo(() => [
		{ width: '50%', title: t('Settings.WaiterCartSettings.waiterTimeSetting'), className: 'bold cart-setting' },
		{ width: '50%', title: t('Settings.WaiterCartSettings.waiterMaxDiscount'), className: 'bold cart-setting' },
	], [t])

	const timeInputLayout = (timeError ? 'error ' : 'purple ')

	return (
		<>
			<CustomTable id="waiterCartSettings-table"
						 headers={tableHeadersStatic}
						 onLoad={() => {
						 }}
						 currentLength={1}
						 totalLength={1}>
				<TableTr className="bold-border row-tablet-cols-2 row-mob-cols-1">
					<TableTd className="cart-setting">
						<TableMobileTitle className="medium">{tableHeadersStatic[0].title}</TableMobileTitle>
						<WaiterTimeError className={timeInputLayout}>{t('Settings.WaiterCartSettings.error.time_error', { minutes: minWaiterLogoutTime })}</WaiterTimeError>
						<WaiterTimeSelectWrapper>
							<SelectInputControlled name="hours"
												   value={waiterLogoutHours}
												   color={timeInputLayout}
												   onChange={(e) => onChangeWaiterLogoutTime(e, 'hours')}>
								{hours.map(hour => (
									<option key={hour} value={hour}>
										{hour}
									</option>
								))}
							</SelectInputControlled>
							<span>:</span>
							<SelectInputControlled name="minutes"
												   value={waiterLogoutMinutes}
												   color={timeInputLayout}
												   onChange={(e) => onChangeWaiterLogoutTime(e, 'minutes')}>
								{minutes.map(minute => (
									<option key={minute} value={minute}>
										{minute}
									</option>
								))}
							</SelectInputControlled>
						</WaiterTimeSelectWrapper>
					</TableTd>
					<TableTd className="cart-setting">
						<TableMobileTitle className="small">{tableHeadersStatic[1].title}</TableMobileTitle>
						<WaiterDiscountInputWrapper>
							<TextInputControlled name="waiter-discount"
												 testId="waiter-discount"
												 label={t('Settings.WaiterCartSettings.waiterMaxDiscount')}
												 value={waiterDiscount}
												 onChange={onChangeWaiterDiscount}
												 onBlur={onBlurWaiterDiscount}
												 showCheck={showWaiterDiscountCheck}
												 errorMessage={discountError}
												 color="gray" />
						</WaiterDiscountInputWrapper>
					</TableTd>
				</TableTr>
			</CustomTable>
		</>
	)
})

WaiterCartSettings.displayName = 'WaiterCartSettings'
export default WaiterCartSettings
