import React from 'react'
import { Option, OptionsWrap, OptionText } from '../index.styled'

const Options = ({ options, onClick }) => {

	return (
		<OptionsWrap>
			{options?.map((item, i) =>
				<Option key={i} data-name={item.label} onClick={() => onClick(item)}>
					<OptionText>{item.label}</OptionText>
				</Option>)}
		</OptionsWrap>
	)
}

Options.displayName = 'Options'
export default Options
