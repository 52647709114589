import React from 'react'
import { useTranslation } from 'react-i18next'
import TimeInput from '../../../../Elements/inputs/TimeInput'
import { PreorderDelayWrapper, PreorderTitle, PreorderTimeWrapper, TimeBlock, TimeValueWrapper, TimeTitle, TimeText, DisabledBlock } from './index.styled'
import CheckBoxInputControlledGray from '../../../../Elements/inputs/CheckBoxInputGray/controlled'

const PreorderDelay = React.memo(({ isModal, delayAfterOpen, delayBeforeClose, onChangeDelayAfterOpen, onBlurDelayAfterOpen, onChangeDelayBeforeClose, onBlurDelayBeforeClose, onChangePreOrderDisabled, preOrderDisabled }) => {
	const { t } = useTranslation()
	const layout = isModal ? 'modal ' : ''

	return (
		<PreorderDelayWrapper className={layout}>
			<PreorderTitle className={layout}>{t('WebShopWorkingHours.receiveOrdersTitle')}</PreorderTitle>
			<PreorderTimeWrapper>
				<TimeBlock>
					<TimeTitle>{t('WebShopWorkingHours.afterOpening')}</TimeTitle>
					<TimeValueWrapper>
						<TimeInput name="hours"
							id="afterOpening-hours"
							onlyHours={true}
							value={delayAfterOpen?.hours}
							onChange={onChangeDelayAfterOpen}
							onBlur={onBlurDelayAfterOpen} />
						<TimeText>{t('WebShopWorkingHours.hours')}</TimeText>
					</TimeValueWrapper>
					<TimeValueWrapper>
						<TimeInput name="minutes"
							id="afterOpening-minutes"
							onlyMinutes={true}
							value={delayAfterOpen?.minutes}
							onChange={onChangeDelayAfterOpen}
							onBlur={onBlurDelayAfterOpen} />
						<TimeText>{t('WebShopWorkingHours.minutes')}</TimeText>
					</TimeValueWrapper>
				</TimeBlock>
				<TimeBlock>
					<TimeTitle>{t('WebShopWorkingHours.beforeClose')}</TimeTitle>
					<TimeValueWrapper>
						<TimeInput name="hours"
							id="beforeClose-hours"
							onlyHours={true}
							value={delayBeforeClose?.hours}
							onChange={onChangeDelayBeforeClose}
							onBlur={onBlurDelayBeforeClose} />
						<TimeText>{t('WebShopWorkingHours.hours')}</TimeText>
					</TimeValueWrapper>
					<TimeValueWrapper>
						<TimeInput name="minutes"
							id="beforeClose-minutes"
							onlyMinutes={true}
							value={delayBeforeClose?.minutes}
							onChange={onChangeDelayBeforeClose}
							onBlur={onBlurDelayBeforeClose} />
						<TimeText>{t('WebShopWorkingHours.minutes')}</TimeText>
					</TimeValueWrapper>
				</TimeBlock>
				<DisabledBlock>
					<CheckBoxInputControlledGray label={t('WebShopWorkingHours.nonWorkingHoursDisabled')}
						onChange={() => onChangePreOrderDisabled('nonWorkingHours')}
						isActive={preOrderDisabled?.nonWorkingHours} />
					<CheckBoxInputControlledGray label={t('WebShopWorkingHours.workingHoursDisabled')}
						onChange={() => onChangePreOrderDisabled('workingHours')}
						isActive={preOrderDisabled?.workingHours} />
				</DisabledBlock>
			</PreorderTimeWrapper>
		</PreorderDelayWrapper>
	)
})

PreorderDelay.displayName = 'PreorderDelay'
export default PreorderDelay
