import styled from 'styled-components'
import { theme } from '../../../style/theme'
import colors from '../../../style/colors'

export const OrderInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25em;
	margin-bottom: 1.25em;

	&.isPreOrder {
		grid-template-columns: 16.75em 1fr 1fr;

		> div:first-child {
			grid-row: 1 / 3;
		}
	}

  @media screen and (max-width: ${theme.point820}) {
    font-size: 0.8em;
  }

  @media screen and (max-width: ${theme.point720}) {
    grid-template-columns: auto;

		&.isPreOrder {
			grid-template-columns: auto;
		}
  }
`

export const OrderInfoBlock = styled.div`
  border-radius: 0.75em;
  border: 1px solid ${colors.gray_light};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  min-height: 4.3em;

  &.col {
    grid-column: 1 / 3;
    gap: 1.56em;
  }

	&.col-2 {
		grid-column: 2 / 4;
		gap: 1.56em;
  }

	&.column {
		flex-direction: column;
		gap: 0.43em;
	}

  @media screen and (max-width: ${theme.point720}) {
    &, &.col, &.col-2 {
      grid-column: 1;
      gap: 0.63em;
      flex-direction: column;
    }
  }
`
OrderInfoBlock.displayName = 'OrderInfoBlock'

export const OrderInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.63em;

	> .FOODORA_PLUGIN {
		width: 10.4em;
		height: 1.88em;
	}

  > svg {
    width: 1.87em;
    height: 1.87em;
    flex-shrink: 0;
  }

  > p {
    font-size: 1.25em;
    color: ${colors.purple};
    font-family: ${theme.fontRobotoRegular};

		&.hidden {
			display: none;
		}
  }

  > .distance {
    background-color: ${colors.gray_lighter};
    border-radius: 0.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1em 0.4em;
  }

  &.small {
    gap: 0.44em;

    > svg {
      width: 1.56em;
      height: 1.56em;
    }

    > p {
      font-size: 1.25em;
    }
  }
`
OrderInfoItem.displayName = 'OrderInfoItem'

export const PreOrderIconWrap = styled.div`
	border-radius: 0.75em;
	border: 1px solid ${colors.gray_light};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1em;
	min-height: 5.6em;

	svg {
		width: 5.17em;
		height: 5.8em;
	}
`
PreOrderIconWrap.displayName = 'PreOrderIconWrap'

export const ModalInfoItem = styled(OrderInfoItem)`
	> .FOODORA_PLUGIN {
		width: 6.25em;
		height: 1.12em;
	}
`
ModalInfoItem.displayName = 'ModalInfoItem'

export const ModalContent = styled.div`
  display: grid;
  height: 100%;
`


