/* eslint no-console: off */
import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import getPaymentConfigs from '../../axios/payment/paymentConfigs/get'
import { appendCartPaymentTerminals, setCartPaymentInvoiceConfigs, setCartPaymentPayments, setCartPaymentSessionCreateIsLoading, setCartPaymentSessionProcessIsLoading, setCartPaymentTerminalError, setCartPaymentTerminalPaymentSession, setCartPaymentTerminalPaymentSessionInvalid, setCartPaymentTerminals, setCartPaymentTerminalsLength, setProcessPaymentIsLoading } from '../../action/cartPayment'
import fetchTerminals from '../../axios/payment/terminals/getAll'
import { PER_LOAD } from '../constants'
import createTerminalConnectionToken from '../../axios/payment/terminals/connectionToken/post'
import createTerminalPaymentSession from '../../axios/payment/terminals/paymentSession/post'
import deleteTerminalPaymentSession from '../../axios/payment/terminals/paymentSession/delete'
import simulateTerminalPayment from '../../axios/payment/terminals/simulateTerminalPayment/post'
import processTerminalPaymentSession from '../../axios/payment/terminals/paymentSession/process/post'
import getTerminalPaymentSession from '../../axios/payment/terminals/paymentSession/get'
import getInvoiceConfigs from '../../axios/invoice/configs'
import createInvoiceConfigs from '../../axios/invoice/configs/post'

export const getCartPaymentThunk = (businessScope, hasModalLogin) => dispatch => {
	return getPaymentConfigs(true, businessScope, hasModalLogin)
		.then((res) => {
			dispatch(setCartPaymentPayments(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getCartPaymentTerminalsThunk = (page = 0, size = PER_LOAD, hasModalLogin) => dispatch => {
	return fetchTerminals(page, size, true, hasModalLogin)
		.then((res) => {
			if (res && res.data) {
				if (res.headers['x-terminals-total']) {
					//check size & page make sure we are retrieving all terminals
					dispatch(setCartPaymentTerminalsLength(parseInt(res.headers['x-terminals-total'])))
				}
				if (page > 0 && size > 0) {
					//make sure we're querying pagination
					dispatch(appendCartPaymentTerminals(res.data))
					return
				}

				dispatch(setCartPaymentTerminals(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const createTerminalConnectionTokenThunk = (terminalId, hasModalLogin) => async dispatch => {
	try {
		const res = await createTerminalConnectionToken(terminalId, hasModalLogin)
		return res.data.token
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const createTerminalPaymentSessionThunk = (terminalId, totalSum, paymentSource, hasModalLogin) => async (dispatch, getState) => {
	dispatch(setCartPaymentSessionCreateIsLoading(true))
	try {
		console.log(`Create payment session: terminal=${terminalId} total=${totalSum} companyIndex=${getState().login.companyIndex}`)
		const res = await createTerminalPaymentSession(terminalId, totalSum, paymentSource, hasModalLogin)
		console.log(`Created payment session: terminal=${terminalId} total=${totalSum} sessionId=${res.data.sessionId} companyIndex=${getState().login.companyIndex}`)
		dispatch(setCartPaymentTerminalPaymentSession(res.data))
		return res.data
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	} finally {
		dispatch(setCartPaymentSessionCreateIsLoading(false))
	}
}

export const getTerminalPaymentSessionThunk = (terminalId, sessionId, hasModalLogin) => async (dispatch, getState) => {
	try {
		console.log(`Get payment session: session=${sessionId} companyIndex=${getState().login.companyIndex}`)
		const res = await getTerminalPaymentSession(terminalId, sessionId, hasModalLogin)
		const terminalPaymentSession = getState().cartPayment.terminalPaymentSession
		if (terminalPaymentSession
			&& terminalPaymentSession.sessionId === res.data.sessionId
			&& res.data.status !== 'PROCESSING') {
			console.log(`Got payment session: session=${sessionId} status=${res.data.status} companyIndex=${getState().login.companyIndex}`)
			dispatch(setCartPaymentTerminalPaymentSession(res.data))
			if (res.data.status === 'CARD_VALIDATION_FAILED') {
				dispatch(setProcessPaymentIsLoading(false))
			}
		}
	} catch (error) {
		if (error.response.status === 404) {
			console.warn('Terminal payment session not found')
		} else {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
	}
}

export const processTerminalPaymentSessionThunk = (terminalId, sessionId, hasModalLogin, data) => async (dispatch, getState) => {
	try {
		console.log(`Process payment: terminal=${terminalId} session=${sessionId} companyIndex=${getState().login.companyIndex}`)
		dispatch(setCartPaymentSessionProcessIsLoading(true))
		const res = await processTerminalPaymentSession(terminalId, sessionId, hasModalLogin, data)
		dispatch(setCartPaymentTerminalPaymentSession(res.data))
		dispatch(setProcessPaymentIsLoading(true))
	} catch (error) {
		if (error.response.status === 400) {
			const responseData = error?.response?.data
			const message = responseData?.message || responseData?.apierror?.message || responseData?.error
			console.warn(message)
			dispatch(setCartPaymentTerminalError({
				statusCode: responseData?.apierror?.errorStatusCode,
				data: responseData?.apierror?.data,
				message: message,
			}))
		} else { // 4xx, 5xx
			const responseData = error?.response?.data
			const message = responseData?.message || responseData?.apierror?.message || responseData?.error
			dispatch(setCartPaymentTerminalError({
				statusCode: null,
				message: message,
			}))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
	} finally {
		dispatch(setCartPaymentSessionProcessIsLoading(false))
	}
}

export const deleteTerminalPaymentSessionThunk = (terminalId, sessionId, hasModalLogin) => async (dispatch, getState) => {
	try {
		console.log(`Delete payment session start request: terminal=${terminalId} session=${sessionId} companyIndex=${getState().login.companyIndex}`)
		const res = await deleteTerminalPaymentSession(terminalId, sessionId, hasModalLogin)
		console.log(`Delete payment session get response : terminal=${terminalId} session=${sessionId} companyIndex=${getState().login.companyIndex} data=${res.data}`)
		return 'terminal_delete_payment_session_success'
	} catch (error) {
		const isCancelNotAvailableError = error.response.data?.apierror?.errorStatusCode === 'terminal_payment_cancel_not_available'
		if (isCancelNotAvailableError) {
			console.log('Delete payment session: terminal_payment_cancel_not_available')
			return 'terminal_payment_cancel_not_available'
		} else {
			console.log(`Delete payment session unexpected error ${error?.response?.data?.apierror}`)
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			return 'unexpected_error'
		}
	}
}

export const simulateTerminalPaymentThunk = (terminalId, data, hasModalLogin) => async dispatch => {
	try {
		const res = await simulateTerminalPayment(terminalId, data, hasModalLogin)
		return res.data
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const getCartPaymentInvoiceConfigsThunk = () => dispatch => {
	return getInvoiceConfigs()
		.then((res) => {
			if (res.data.length) {
				dispatch(setCartPaymentInvoiceConfigs(res.data[0]))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const createCartPaymentInvoiceConfigsThunk = (data) => async (dispatch) => {
	try {
		const res = await createInvoiceConfigs(data)
		dispatch(setCartPaymentInvoiceConfigs(res.data))
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const resetTerminalPaymentErrorsThunk = () => dispatch => {
	dispatch(setCartPaymentTerminalPaymentSessionInvalid(false))
	dispatch(setCartPaymentTerminalError(null))
}
