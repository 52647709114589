import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAppModal } from '../../../redux/action/system'
import CustomModal from '../../Elements/modal/CustomModal'
import Button from '../../Elements/buttons/Button'
import { TextP } from '../../../style/text/index.styled'

const EVENT_Z_INDEX = 997

const ModalButton = React.memo(({ handleClose }) => {
	return (<Button icon="checkmark" onClick={handleClose} tabIndex="1" text="Ok" color="green" />)
})
ModalButton.displayName = 'ModalButton'

const StatusModal = React.memo(() => {
	const statusModalShow = useSelector(store => store.system.modal?.statusModalShow)
	const dispatch = useDispatch()

	const handleClose = useCallback(() => {
		dispatch(setAppModal({ statusModalShow: false }))
	}, [])

	return (
		<CustomModal isOpen={statusModalShow}
								 close={handleClose}
								 zIndex={EVENT_Z_INDEX}
								 size="small"
								 title="Störung!"
								 disableBackButton={true}
								 button={<ModalButton handleClose={handleClose} />}>
			<TextP>
				Die automatische Bestellannahme funktioniert momentan nicht. Wir haben technisches Problem erkannt. Wir versuchen dieses Problem so schnell wie möglich zu beheben.
				<br />
				Danke für Ihre Verständnis.
			</TextP>
		</CustomModal>
	)
})

StatusModal.displayName = 'StatusModal'
export default StatusModal
