import printerInstance from './../instance'

const getPrinterNames = (callbackSuccess, callbackError) => {
  return dispatch => {
    printerInstance.get('/printers/names')
      .then(res => {
        if (res) typeof callbackSuccess === 'function' && callbackSuccess(res)
      })
      .catch(error => {
        typeof callbackError === 'function' && callbackError(error)
      })
  }
}

export default getPrinterNames
