import React, { useCallback } from 'react'
import { convertProduct2Item, getPosPriceByIndex } from '../../../../../helper'
import PlusMinus from '../../CartItem/PlusMinus'
import CustomModal from '../../../modal/CustomModal'

const ModalToppingGrid = React.memo(({ isOpen, item, focusedItem, orderType, addTopping, close }) => {
	const addToppingItem = useCallback((quantity) => {
		const priceIndex = focusedItem.priceIndex
		const price = getPosPriceByIndex(item, orderType, priceIndex)
		let toppingItem = convertProduct2Item(item, quantity, price.value, priceIndex, null)
		addTopping(toppingItem)
		close()
	}, [focusedItem, item, orderType, addTopping, close])

	const handlePlus = useCallback(() => {
		addToppingItem(1)
	}, [addToppingItem])

	const handleMinus = useCallback(() => {
		addToppingItem(-1)
	}, [addToppingItem])

	return (
		<CustomModal isOpen={isOpen}
								 size="auto"
								 id="topping-grid-modal"
								 title={item.title}
								 close={close}
								 autoFocus={false}>
			<PlusMinus {...{ onClickPlus: handlePlus, onClickMinus: handleMinus, incrementAbility: true, removeAbility: true, size: 'large' }} />
		</CustomModal>
	)
})

ModalToppingGrid.displayName = 'ModalToppingGrid'
export default ModalToppingGrid
