import invoiceInstance from './instance'

const fetchInvoices = (page, size) => {
	return invoiceInstance.get(
		'/invoices'
		+ '?page=' + page
		+ '&size=' + size)
}

export default fetchInvoices
