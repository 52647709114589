import { retrieveErrorFromApi } from '../../../../helper'
import { setAppErrors } from '../../../action/system'
import registerPreOrders from '../../../axios/order/registerPreOrders'

export const registerPreOrdersThunk = () => (dispatch) => {
	return registerPreOrders()
		.then(res => {
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
