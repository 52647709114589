export const convert2InvoiceCustomer = ({
	customerNumber = null, name = null, companyName = null,
	street = null, streetNumber = null, city = null, postalCode = null,
	phoneNumber = null, email = null
}) => {
	return ({
		customerNumber, name, companyName, phoneNumber, email,
		street, streetNumber, city, postalCode,
	})
}
