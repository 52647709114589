import { SET_CASH_DESK_SETTINGS_CART_SETTINGS, UPDATE_CASH_DESK_SETTINGS_CART_SETTING, SET_CASH_DESK_CHANGE_SETTINGS, UPDATE_CASH_DESK_CHANGE_SETTING, SET_CASH_DESK_SETTINGS_WAITER_SETTINGS, SET_CASH_DESK_SETTINGS_PRINT_SETTINGS } from '../../actionTypes/cashDeskSettings'

const initialState = {
	cartSettings: [],
	changeSettings: [],
	waiterSettings: null,
	printSettings: []
}

export function cashDeskSettingsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CASH_DESK_SETTINGS_CART_SETTINGS:
			return {
				...state,
				cartSettings: action.value,
			}
		case UPDATE_CASH_DESK_SETTINGS_CART_SETTING:
			const updateCartSettings = [...state.cartSettings].map(item => item.businessScope === action.value.businessScope ? action.value : item)
			return {
				...state,
				cartSettings: updateCartSettings,
			}
		case SET_CASH_DESK_CHANGE_SETTINGS:
			return {
				...state,
				changeSettings: action.value,
			}
		case UPDATE_CASH_DESK_CHANGE_SETTING:
			const updateChangeSettings = [...state.changeSettings].map(item => item.businessScope === action.value.businessScope ? action.value : item)
			return {
				...state,
				changeSettings: updateChangeSettings,
			}
		case SET_CASH_DESK_SETTINGS_WAITER_SETTINGS:
			return {
				...state,
				waiterSettings: action.value,
			}
		case SET_CASH_DESK_SETTINGS_PRINT_SETTINGS:
			return {
				...state,
				printSettings: action.value,
			}
		default:
			return state
	}
}
