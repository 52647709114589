import { retrieveErrorFromApi } from '../../../../helper'
import { setIsProcessingConfirmOrder, setIsProcessingDenyOrder } from '../../../action/order'
import { setAppErrors, setAppModal, setAppModalData } from '../../../action/system'
import addOrderProcessingTime from '../../../axios/order/addOrderProcessingTime'
import denyOrder from '../../../axios/order/denyOrder'

export const removeOrderFromPlatformDriversOrderModalDataThunk = (orderId) => (dispatch, getState) => {
	const platformDriversOrderModalData = getState().system.modalData?.platformDriversOrderModalData?.filter(o => o.orderId !== orderId)
	dispatch(setAppModal({ platformDriversOrderModalShow: false }))
	dispatch(setAppModalData({ platformDriversOrderModalData }))
}

export const addPlatformDriversOrderProcessingTimeThunk = (orderId, processingTime) => dispatch => {
	dispatch(setIsProcessingConfirmOrder(true))
	return addOrderProcessingTime(orderId, processingTime)
		.then(res => {
			dispatch(removeOrderFromPlatformDriversOrderModalDataThunk(orderId))
			dispatch(setIsProcessingConfirmOrder(false))
		}).catch(error => {
			dispatch(removeOrderFromPlatformDriversOrderModalDataThunk(orderId))
			dispatch(setIsProcessingConfirmOrder(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const denyPlatformDriversOrderThunk = (orderId, reason) => dispatch => {
	dispatch(setIsProcessingDenyOrder(true))
	return denyOrder(orderId, reason)
		.then(res => {
			dispatch(removeOrderFromPlatformDriversOrderModalDataThunk(orderId))
			dispatch(setIsProcessingDenyOrder(false))
		}).catch(error => {
			dispatch(removeOrderFromPlatformDriversOrderModalDataThunk(orderId))
			dispatch(setIsProcessingDenyOrder(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
