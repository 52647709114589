import styled from 'styled-components'
import colors from '../../../../style/colors'
import { theme } from '../../../../style/theme'

export const WorkingHoursWrapper = styled.div`
	font-size: 1rem;

	&.modal {
		max-height: 70vh;
		overflow-y: auto;
		padding-top: 0.81em;
		padding-bottom: 0.38em;
	}
`
WorkingHoursWrapper.displayName = 'WorkingHoursWrapper'

export const DayBlock = styled.div`
	display: grid;
	grid-template-columns: minmax(11em, 21.88em) minmax(7.3em, auto) minmax(11.69em, auto) 10.3em 10.3em 11.3em 11.42em;
	column-gap: 2.5em;
	align-items: center;
	padding: 0.94em 0;
	border-bottom: 1px dashed ${colors.gray_light};

	&.border-bold {
		border-bottom: 3px solid ${colors.gray_light};

		&.modal {
			border-bottom: 2px solid ${colors.gray_light};
		}
	}

	&.modal {
		padding: 0.75em 0;
	}

	@media screen and (max-width: ${theme.point1024}) {
		grid-template-columns: repeat(2, minmax(10.3em, 1fr));
		column-gap: 0.63em;
		row-gap: 1.25em;
		padding: 0.63em 0 1.25em;
	}
`
DayBlock.displayName = 'DayBlock'

export const DayTitle = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colors.black};
	font-size: 1.5em;
	line-height: 1.17em;
	text-align: left;

	&.small {
		font-size: 1.25em;
		line-height: 1.15em;
		margin-bottom: 0.94em;

		@media screen and (max-width: ${theme.point1024}) {
			padding-top: 1.5em;
		}
	}

	@media screen and (max-width: ${theme.point1024}) {
		grid-column: 1 / 3;
	}
`
DayTitle.displayName = 'DayTitle'

export const CopyButton = styled.div`
	font-family: ${theme.fontRobotoRegular};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.13em;
	padding: 0.83em 0.61em;
	line-height: 1.17em;
	color: ${colors.white};
	background-color: ${props => props.color ? props.color : colors.purple};
	border-radius: 0.75em;
	cursor: pointer;
	text-align: left;
`
CopyButton.displayName = 'CopyButton'

export const MarginWrapper = styled.div`
	> ${CopyButton} {
		margin-left: -0.63em;
	}

	@media screen and (max-width: ${theme.point1024}) {
		> ${CopyButton} {
			margin-left: 0;
		}
	}
`
MarginWrapper.displayName = 'MarginWrapper'
