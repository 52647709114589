import styled from 'styled-components'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../style/theme'

export const InvoiceSheetWrap = styled.div`
	font-size: 1rem;
	padding: 3.13em 5.01em;
	border-radius: 0.75em;
	border: 1px dashed ${colorTheme.invoices.modal.invoiceData.border};
	background: ${colorTheme.invoices.modal.invoiceData.background};
	position: relative;
	overflow: hidden;

	@media screen and (max-width: ${theme.point820}) {
		margin-bottom: 6em;
	}
`
InvoiceSheetWrap.displayName = 'InvoiceSheetWrap'

export const InvoiceLabelBill = styled.div`
	display: flex;
	transform: rotate(45deg);
	padding: 0.47em 0;
	justify-content: center;
	align-items: center;
	gap: 0.44em;
	position: absolute;
	width: 15.62em;
	height: 3.50em;
	right: -3.75em;
	top: 2.63em;

	> svg {
		width: 1.75em;
		height: 1.75em;
		fill: ${colorTheme.invoices.modal.invoiceData.label.icon};
		flex-shrink: 0;
	}

	&.green {
		background-color: ${colorTheme.invoices.modal.invoiceData.label.backgroundGreen};
	}

	&.orange {
		background-color: ${colorTheme.invoices.modal.invoiceData.label.backgroundOrange};
	}
`
InvoiceLabelBill.displayName = 'InvoiceLabelBill'

export const InvoiceLabelText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.invoices.modal.invoiceData.label.text};
	font-size: 1.26em;
	font-weight: 600;
`
InvoiceLabelText.displayName = 'InvoiceLabelText'

export const HeaderCompanyWrap = styled.div`
	padding: 1.88em 0 0.63em;
`
HeaderCompanyWrap.displayName = 'HeaderCompanyWrap'

export const HeaderCompanyTitle = styled.div`
	color: ${colorTheme.invoices.modal.invoiceData.text};
	font-size: 1.13em;
	font-weight: 400;
	text-align: left;
`
HeaderCompanyTitle.displayName = 'HeaderCompanyTitle'

export const HeaderWrap = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1.26em 0;
`
HeaderWrap.displayName = 'HeaderWrap'

export const HeaderContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.44em;
	align-items: start;

	&.flex-end {
		align-items: end;
	}
`
HeaderWrap.displayName = 'HeaderWrap'

export const HeaderText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.invoices.modal.invoiceData.text};
	font-size: 1.26em;
	font-weight: 500;
`
HeaderText.displayName = 'HeaderText'

export const InvoiceContent = styled.div`
	margin-top: 7.04em;
`
InvoiceContent.displayName = 'InvoiceContent'

export const InvoiceTitle = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.invoices.modal.invoiceData.text};
	text-align: center;
	font-size: 2.25em;
	font-weight: 600;
`
InvoiceTitle.displayName = 'InvoiceTitle'

export const InvoiceInfoWrap = styled.div`
	display: grid;
	gap: 0.63em;
	padding: 1.26em 0;

	&.big-gap {
		gap: 1.26em;
	}
`
InvoiceInfoWrap.displayName = 'InvoiceInfoWrap'

export const InvoiceDataWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.44em;
	padding: 0.63em 0;

	> div {
		display: flex;
		gap: 0.63em;
	}
`
InvoiceDataWrap.displayName = 'InvoiceDataWrap'

export const InvoiceInfoText = styled.div`
	color: ${colorTheme.invoices.modal.invoiceData.text};
	font-size: 1.26em;
	font-weight: 400;
	text-align: left;

	&.center {
		text-align: center;
	}
`
InvoiceInfoText.displayName = 'InvoiceInfoText'

export const InvoiceFooterWrap = styled.div`
	display: grid;
	align-items: center;
	justify-content: center;
	margin-top: 0.63em;
`
InvoiceFooterWrap.displayName = 'InvoiceFooterWrap'

export const InvoiceOrderHeader = styled.div`
	display: flex;
	padding: 0.63em 0.32em;
	justify-content: space-between;
	align-items: center;
	background: ${colorTheme.invoices.modal.invoiceData.backgroundHeader};
	margin: 1.26em 0 0.63em;
`
InvoiceOrderHeader.displayName = 'InvoiceOrderHeader'

export const InvoiceOrderHeaderTitle = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.invoices.modal.invoiceData.text};
	font-size: 1.38em;
	font-weight: 600;
`
InvoiceOrderHeaderTitle.displayName = 'InvoiceOrderHeaderTitle'

export const InvoiceOrderCount = styled.div`
	margin-top: 1.26em;
	margin-bottom: 1.75em;
	padding-bottom: 0.63em;
	border-bottom: 3px solid ${colorTheme.invoices.modal.invoiceData.text};
	text-align: center;
	color: ${colorTheme.invoices.modal.invoiceData.text};

	> span {
		font-size: 1.13em;
		font-weight: 400;
	}

	> span.bold {
		font-family: ${theme.fontRobotoMedium};
		font-weight: 700;
	}
`
InvoiceOrderCount.displayName = 'InvoiceOrderCount'

