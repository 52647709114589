import { APPEND_PRODUCT_PERIOD_DAYS, APPEND_PRODUCTS, REMOVE_PRODUCT_PERIOD_DAYS, SET_CATEGORY_ID, SET_LOADING, SET_PRODUCT, SET_PRODUCT_CHANGED, SET_PRODUCT_CORS_ERROR, SET_PRODUCT_IMPORTING_STATUS, SET_PRODUCT_MODE, SET_PRODUCT_PERIOD_DAYS, SET_PRODUCT_PERIOD_END_DATE, SET_PRODUCT_PERIOD_END_TIME, SET_PRODUCT_PERIOD_IS_ACTIVE_TIME, SET_PRODUCT_PERIOD_START_DATE, SET_PRODUCT_PERIOD_START_TIME, SET_PRODUCT_TYPE, SET_PRODUCT_TYPE_BUSINESS_SCOPE, SET_PRODUCTS, SET_PRODUCTS_LENGTH, SET_PRODUCTS_UPDATE_PRODUCT, SET_PRODUCTS_ZUTAT, SET_PRODUCT_IMAGE_CONDITIONS, SET_PRODUCT_IMAGE_CONFIG, SET_PRODUCT_IMAGE_UPLOAD_STEP, SET_PRODUCT_IMAGE_ERROR_MODAL, SET_PRODUCTS_ADDITIONAL_SETTINGS, SET_PRODUCT_ADDITIONAL_SETTINGS_FILTER, SET_PRODUCTS_BY_PRICE_DESCRIPTIONS, APPEND_PRODUCTS_BY_PRICE_DESCRIPTIONS, SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LENGTH, SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LOADING } from '../../actionTypes/product'
import { add } from 'date-fns'
import { END_TIME, START_TIME, WORK_DAYS_OF_WEEK } from '../../../component/Product/form/constants'

const initialState = {
	products: [],
	productsTotalLength: 0,
	product: null,
	productType: null,
	category_Id: null,
	loading: false,
	mode: true,
	productChanged: false,
	productsZutat: [],
	typeBusinessScope: 'DELIVERY',
	productsImportingStatus: null,
	isCorsError: false,
	productPeriodDays: WORK_DAYS_OF_WEEK,
	productPeriodStartTime: START_TIME,
	productPeriodEndTime: END_TIME,
	productPeriodStartDate: new Date(),
	productPeriodEndDate: add(new Date(), { years: 1 }),
	productPeriodIsActiveTime: false,
	productImageConditions: false,
	productImageConfig: null,
	productImageUploadStep: 'DEFAULT',
	showProductImageErrorModal: false,
	productsAdditionalSettings: null,
	additionalSettingsFilter: null,
	productsByPriceDescription: [],
	productsByPriceDescriptionTotalLength: 0,
	productsByPriceDescriptionLoading: false,
}

export function productReducer(state = initialState, action) {
	switch (action.type) {
		case SET_PRODUCT_MODE:
			return {
				...state,
				mode: action.value
			}
		case SET_PRODUCT_CHANGED:
			return {
				...state,
				productChanged: action.value
			}
		case SET_PRODUCTS:
			return {
				...state,
				products: action.value
			}
		case SET_PRODUCTS_ZUTAT:
			return {
				...state,
				productsZutat: action.value
			}
		case APPEND_PRODUCTS:
			return {
				...state,
				products: state.products.concat(...action.value)
			}
		case SET_PRODUCTS_LENGTH:
			return {
				...state,
				productsTotalLength: action.value
			}
		case SET_PRODUCT:
			return {
				...state,
				product: action.value
			}
		case SET_PRODUCT_TYPE:
			return {
				...state,
				productType: action.value
			}
		case SET_CATEGORY_ID:
			return {
				...state,
				category_Id: action.value
			}
		case SET_LOADING:
			return {
				...state,
				loading: action.value
			}
		case SET_PRODUCTS_UPDATE_PRODUCT:
			return {
				...state,
				products: [...state.products].map((product) => (product.productId === action.value.productId ? action.value : product))
			}
		case SET_PRODUCT_TYPE_BUSINESS_SCOPE:
			return {
				...state,
				typeBusinessScope: action.value
			}
		case SET_PRODUCT_IMPORTING_STATUS:
			return {
				...state,
				productsImportingStatus: action.value
			}
		case SET_PRODUCT_CORS_ERROR:
			return {
				...state,
				isCorsError: action.value
			}
		case APPEND_PRODUCT_PERIOD_DAYS:
			return {
				...state,
				productPeriodDays: [...state.productPeriodDays, action.value]
			}
		case REMOVE_PRODUCT_PERIOD_DAYS:
			return {
				...state,
				productPeriodDays: state.productPeriodDays.filter(day => day !== action.value)
			}
		case SET_PRODUCT_PERIOD_DAYS:
			return {
				...state,
				productPeriodDays: action.value
			}
		case SET_PRODUCT_PERIOD_START_TIME:
			return {
				...state,
				productPeriodStartTime: action.value
			}
		case SET_PRODUCT_PERIOD_END_TIME:
			return {
				...state,
				productPeriodEndTime: action.value
			}
		case SET_PRODUCT_PERIOD_START_DATE:
			return {
				...state,
				productPeriodStartDate: action.value
			}
		case SET_PRODUCT_PERIOD_END_DATE:
			return {
				...state,
				productPeriodEndDate: action.value
			}
		case SET_PRODUCT_PERIOD_IS_ACTIVE_TIME:
			return {
				...state,
				productPeriodIsActiveTime: action.value
			}
		case SET_PRODUCT_IMAGE_CONDITIONS:
			return {
				...state,
				productImageConditions: action.value
			}
		case SET_PRODUCT_IMAGE_CONFIG:
			return {
				...state,
				productImageConfig: action.value
			}
		case SET_PRODUCT_IMAGE_UPLOAD_STEP:
			return {
				...state,
				productImageUploadStep: action.value
			}
		case SET_PRODUCT_IMAGE_ERROR_MODAL:
			return {
				...state,
				showProductImageErrorModal: action.value
			}
		case SET_PRODUCTS_ADDITIONAL_SETTINGS:
			return {
				...state,
				productsAdditionalSettings: action.value
			}
		case SET_PRODUCT_ADDITIONAL_SETTINGS_FILTER:
			return {
				...state,
				additionalSettingsFilter: action.value
			}
		case SET_PRODUCTS_BY_PRICE_DESCRIPTIONS:
			return {
				...state,
				productsByPriceDescription: action.value
			}
		case APPEND_PRODUCTS_BY_PRICE_DESCRIPTIONS:
			return {
				...state,
				productsByPriceDescription: state.productsByPriceDescription.concat(...action.value)
			}
		case SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LENGTH:
			return {
				...state,
				productsByPriceDescriptionTotalLength: action.value
			}
		case SET_PRODUCTS_BY_PRICE_DESCRIPTIONS_LOADING:
			return {
				...state,
				productsByPriceDescriptionLoading: action.value
			}

		default:
			return state
	}
}
