import { APPEND_INVOICES, SET_INVOICES, SET_INVOICES_TOTAL_LENGTH, SET_SELECTED_INVOICE, SET_SHOW_INVOICE_BILLING_ADDRESS_MODAL, SET_SHOW_INVOICE_DATA_MODAL, SET_SHOW_INVOICE_OPTIONS_MODAL, SET_SHOW_INVOICE_RESEND_EMAIL_INFO_MODAL, SET_SHOW_INVOICE_RESEND_EMAIL_MODAL, SET_UNPAID_INVOICES_COUNTER } from '../../actionTypes/invoice'

export const setInvoices = value => ({ type: SET_INVOICES, value })
export const setInvoicesTotalLength = value => ({ type: SET_INVOICES_TOTAL_LENGTH, value })
export const appendInvoices = value => ({ type: APPEND_INVOICES, value })
export const setUnpaidInvoicesCounter = value => ({ type: SET_UNPAID_INVOICES_COUNTER, value })
export const setSelectedInvoice = value => ({ type: SET_SELECTED_INVOICE, value })
export const setShowInvoiceOptionsModal = value => ({ type: SET_SHOW_INVOICE_OPTIONS_MODAL, value })
export const setShowInvoiceDataModal = value => ({ type: SET_SHOW_INVOICE_DATA_MODAL, value })
export const setShowInvoiceBillingAddressModal = value => ({ type: SET_SHOW_INVOICE_BILLING_ADDRESS_MODAL, value })
export const setShowInvoiceResendEmailInfoModal = value => ({ type: SET_SHOW_INVOICE_RESEND_EMAIL_INFO_MODAL, value })
export const setShowInvoiceResendEmailModal = value => ({ type: SET_SHOW_INVOICE_RESEND_EMAIL_MODAL, value })
