import { useDispatch, useSelector } from 'react-redux'
import { setSystemLocalStorage } from '../../redux/action/system'
import { toLocalStorageValue } from '../../helper/localStorage/toLocalStorageValue'
import { fromLocalStorageValue } from '../../helper/localStorage/fromLocalStorageValue'

export const useLocalStorage = (key, initialValue) => {
	const browserLocalStorage = useSelector(state => state.system.browserLocalStorage)
	const dispatch = useDispatch()

	const setValue = (value) => {
		dispatch(setSystemLocalStorage({ [key]: value }))
		window.localStorage[key] = toLocalStorageValue(value)
	}

	let storageValue = browserLocalStorage?.[key] || fromLocalStorageValue(window.localStorage[key]) || initialValue
	storageValue = storageValue === 'false' ? false : storageValue
	
	return [storageValue, setValue]
}
