export const fromLocalStorageValue = (value) => {
	try {
		// work with types: number, boolean, object, null
		if (value === 'false') return value
		return JSON.parse(value)
	} catch (e) {
		// work with string
		return value
	}
}
