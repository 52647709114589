import React from 'react'
import { LoaderWrapper } from './index.styled'

const CircleLoader = React.memo(({className}) => {
	return (
		<LoaderWrapper className={className}>
			<div />
			<div />
			<div />
		</LoaderWrapper>
	)
})


CircleLoader.displayName = 'CircleLoader'
export default CircleLoader
