import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClientMonitorWrap } from '../index.styled'
import StartStep from '../StartStep'
import CartStep from '../CartStep'
import FinalStep from '../FinalStep'
import { selectCartCouponPayments, selectCartItems } from '../../../redux/selector/clientMonitorCart'
import { setClientMonitorCartState, setClientMonitorQrCodePaymentSession, showClientMonitorFinalStep } from '../../../redux/action/clientMonitorCart'
import { setClientMonitorConfig } from '../../../redux/action/clientMonitorConfig'
import { sendMessageToParentWindow } from '../../ClientMonitorWindow/helper'
import QrCodePaymentStep from '../QrCodePaymentStep'

const JsClientMonitorCart = React.memo(() => {
	const dispatch = useDispatch()
	const cartItems = useSelector(selectCartItems)
	const couponPayments = useSelector(selectCartCouponPayments)
	const selectShowFinalStep = useSelector(state => state.clientMonitorCart.showFinalStep)
	const qrCodePaymentSession = useSelector(state => state.clientMonitorCart.qrCodePaymentSession)

	const showCartStep = !!(cartItems.length || couponPayments.length) && !qrCodePaymentSession
	const showFinalStep = selectShowFinalStep && !qrCodePaymentSession
	const showQrCodePaymentStep = qrCodePaymentSession
	const showStartStep = !showCartStep && !showFinalStep && !showQrCodePaymentStep

	useEffect(() => {
		window.addEventListener('message', (event) => {
			switch (event.data?.type) {
				case 'cashdesk.CLIENT_MONITOR_CONFIG_CHANGED':
					dispatch(setClientMonitorConfig(event.data.data))
					break
				case 'cashdesk.CART_STATE_CHANGED_EVENT':
					dispatch(setClientMonitorCartState(event.data.data.cartState))
					break
				case 'cashdesk.CART_STATE_DELETED_EVENT':
					dispatch(showClientMonitorFinalStep(true))
					dispatch(setClientMonitorCartState(null))
					break
				case 'cashdesk.CLIENT_MONITOR_QR_CODE_PAYMENT_SESSION_CHANGED':
					dispatch(setClientMonitorQrCodePaymentSession(event.data.data.qrCodePaymentSession))
					break
				default:
					break
			}
		})
		sendMessageToParentWindow('client-monitor.CLIENT_MONITOR_LOADED', {})
	}, []) //eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (cartItems?.length || couponPayments?.length) {
			dispatch(showClientMonitorFinalStep(false))
		}
	}, [cartItems?.length, couponPayments?.length]) //eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ClientMonitorWrap>
			{showStartStep && <StartStep />}
			{showCartStep && <CartStep />}
			{showQrCodePaymentStep && <QrCodePaymentStep />}
			{showFinalStep && <FinalStep />}
		</ClientMonitorWrap>
	)
})

JsClientMonitorCart.displayName = 'JsClientMonitorCart'
export default JsClientMonitorCart
