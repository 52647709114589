import { theme } from '../../../style/theme'
import { DEFAULT_BAR_COUNTER_SIZE, DEFAULT_CIRCLE_SIZE, DEFAULT_OVAL_SIZE, DEFAULT_RECTANGLE_SIZE_FOR_6_CHAIRS } from '../../../component/RestaurantSchema/constants'

const windowBreakPoint = (breakpoint) => {
	const point = parseInt(breakpoint.replace('px', ''))
	const { innerWidth: width } = window
	return (width <= point)
}

export const point1660 = windowBreakPoint(theme.point1660)
export const point1440 = windowBreakPoint(theme.point1440)
export const point1280 = windowBreakPoint(theme.point1280)
export const point1024 = windowBreakPoint(theme.point1024)
export const point820 = windowBreakPoint(theme.point820)
export const point720 = windowBreakPoint(theme.point720)

export const fillMeta = {
	formType: 'CIRCLE',
	chairCount: 4,
	coordX: { value: 45, type: 'EM' },
	coordY: { value: 70, type: 'EM' },
	width: { value: 0, type: 'EM' },
	height: { value: 0, type: 'EM' },
}

export const PATTERN_SIZE_TABLE = [
	{ formType: 'CIRCLE', width: DEFAULT_CIRCLE_SIZE.width, height: DEFAULT_CIRCLE_SIZE.height },
	{ formType: 'OVAL', width: DEFAULT_OVAL_SIZE.width, height: DEFAULT_OVAL_SIZE.height },
	{ formType: 'RECTANGLE', width: DEFAULT_RECTANGLE_SIZE_FOR_6_CHAIRS.width, height: DEFAULT_RECTANGLE_SIZE_FOR_6_CHAIRS.height },
	{ formType: 'BAR_COUNTER', width: DEFAULT_BAR_COUNTER_SIZE.width, height: DEFAULT_BAR_COUNTER_SIZE.height },
]

export const DEFAULT_FONT_SIZE = 16
