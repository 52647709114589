import SVGOvalTable from '../tables/SVGTables/SVGOvalTable'
import SVGRectangleTable from '../tables/SVGTables/SVGRectangleTable'
import SVGRoundTable from '../tables/SVGTables/SVGRoundTable'
import BarCounter from '../tables/BarCounter'

export const PADDING_FOR_RND = 20
export const PADDING_W_BAR_COUNTER_FOR_RND = 50
export const PADDING_H_BAR_COUNTER_FOR_RND = 40
export const MIN_TABLE_REDUCTION_COEFFICIENT = 0.6
export const tableComponents = {
	OVAL: SVGOvalTable,
	RECTANGLE: SVGRectangleTable,
	CIRCLE: SVGRoundTable,
	BAR_COUNTER: BarCounter
}

export const DEFAULT_OVAL_SIZE = {
	width: 174,
	height: 130
}

export const DEFAULT_CIRCLE_SIZE = {
	width: 130,
	height: 130
}

export const DEFAULT_RECTANGLE_SIZE_FOR_6_CHAIRS = {
	width: 232,
	height: 140
}

export const DEFAULT_RECTANGLE_SIZE_FOR_8_CHAIRS = {
	width: 322,
	height: 168
}
export const DEFAULT_RECTANGLE_SIZE_FOR_10_CHAIRS = {
	width: 414,
	height: 168
}

export const DEFAULT_RECTANGLE_SIZE_FOR_12_CHAIRS = {
	width: 502,
	height: 140
}
export const DEFAULT_RECTANGLE_SIZE_FOR_14_CHAIRS = {
	width: 594,
	height: 140
}
export const DEFAULT_RECTANGLE_SIZE_FOR_16_CHAIRS = {
	width: 683,
	height: 140
}

export const DEFAULT_BAR_COUNTER_SIZE = {
	width: 382,
	height: 76
}

export const DEFAULT_AREA_SIZE = {
	width: 1604,
	height: 924
}

export const rectangleSizes = {
	6: DEFAULT_RECTANGLE_SIZE_FOR_6_CHAIRS,
	8: DEFAULT_RECTANGLE_SIZE_FOR_8_CHAIRS,
	10: DEFAULT_RECTANGLE_SIZE_FOR_10_CHAIRS,
	12: DEFAULT_RECTANGLE_SIZE_FOR_12_CHAIRS,
	14: DEFAULT_RECTANGLE_SIZE_FOR_14_CHAIRS,
	16: DEFAULT_RECTANGLE_SIZE_FOR_16_CHAIRS
}
