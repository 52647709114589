import React from 'react'
import colors from '../../../style/colors'
import { PageLoaderWrap } from './index.styled'
import Loader from '../loaders'
import MainWrapper from '../../MainWrapper'
import Header from '../../Header'

const PageLoader = React.memo(({ className }) => {

	return (
		<>
			<Header />
			<MainWrapper className={className}>
				<PageLoaderWrap>
					<Loader color={colors.blue} zoom={2} />
				</PageLoaderWrap>
			</MainWrapper>
		</>
	)
})

PageLoader.displayName = 'PageLoader'
export default PageLoader
