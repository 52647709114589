import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const CouponConfig = lazyWithRetry(() => import(/* webpackChunkName: "coupon-config" */'../../component/Coupon/CouponConfig'))

const CouponConfigPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<CouponConfig />
		</Suspense>
	)
})

CouponConfigPage.displayName = 'CouponConfigPage'
export default CouponConfigPage
