import { uuidGenerator } from '../../../helper'

export const secondWindowStore = {}

export const openClientMonitorWindow = () => {
	// eslint-disable-next-line no-console
	console.log('Opening client monitor window ...')
	const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no'
	secondWindowStore.window = window.open('/client-monitor', 'client-monitor', params)
	return secondWindowStore.window
}

export const closeClientMonitorWindow = () => {
	// eslint-disable-next-line no-console
	console.log('Closing client monitor window ...')
	secondWindowStore.window?.close?.()
}

export const sendMessageToClientMonitor = (eventType, data) => {
	secondWindowStore.window?.postMessage({ eventId: uuidGenerator(), traceId: uuidGenerator(), type: eventType, data }, '*')
}

export const sendMessageToParentWindow = (eventType, data) => {
	window.opener?.postMessage({ eventId: uuidGenerator(), traceId: uuidGenerator(), type: eventType, data }, '*')
}
