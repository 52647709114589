import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'
import { ButtonKey } from '../../../buttons/Button/style/index.styled'
import { ButtonWrapper } from '../../../buttons/Button/style/buttonWrap/index.styled'

const HEIGHT_OF_ELEMENTS_WITHOUT_SIZE_BLOCK = 25

export const SizesBlock = styled.div`
	font-size: 1rem;
	display: flex;
	gap: 0.63em;
	justify-content: space-between;
	margin-bottom: 1.25em;

	@media screen and (max-width: ${theme.point820}) {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
		gap: 0.72em;
		margin-bottom: 1.43em;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;
		margin-bottom: 1.25em;
	}
`
SizesBlock.displayName = 'SizesBlock'


export const SizeButtonWrap = styled.button`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0.55em 0.68em;
	min-height: 5.09em;
	background-color: ${colorTheme.cartModal.modalSize.sizeButton.background};
	border: 1px solid ${colorTheme.cartModal.modalSize.sizeButton.border};
	border-radius: 0.55em;
	font-size: 1.38em;
	font-weight: 500;
	font-family: ${theme.fontRobotoMedium};
	text-align: center;
	color: ${colorTheme.cartModal.modalSize.sizeButton.text};
	cursor: pointer;
	user-select: none;
	gap: 0.14em;
	transition: all 0.3s ease;

	&.active {
		background: ${colorTheme.cartModal.modalSize.sizeButton.active.background};
		border-color: ${colorTheme.cartModal.modalSize.sizeButton.active.border};
		box-shadow: 0 0.18em 2.27em rgba(0, 0, 0, 0.3);
		color: ${colorTheme.cartModal.modalSize.sizeButton.active.text};
	}

	> ${ButtonKey} {
		margin-top: 0.35em;
	}

	&:hover {
		border: 1px solid ${colorTheme.cartModal.modalSize.sizeButton.hoverBorder};
		box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.58em;
		min-height: 5.09em;
		gap: 0.23em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.38em;
	}
`
SizeButtonWrap.displayName = 'SizeButtonWrap'

export const ToppingsWrapper = styled.div`
	margin-bottom: 1.25em;
	margin-top: -0.63em;

	&.must-extras {
		margin-top: 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1rem;
		margin-top: -0.71em;
		margin-bottom: 1.43em;
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-top: -0.63em;
		margin-bottom: 1.25em;
	}
`

export const ToppingsBlock = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 0.63em;

	@media screen and (max-width: ${theme.point1024}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (max-width: ${theme.point820}) {
		grid-template-columns: 1fr;
		gap: 0.72em;
	}

	@media screen and (max-width: ${theme.point720}) {
		gap: 0.63em;
	}
`
ToppingsBlock.displayName = 'ToppingsBlock'

export const ModalFooterWrap = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	width: 100%;
`
ModalFooterWrap.displayName = 'ModalFooterWrap'

export const InputRemark = styled.div`
	position: relative;
	width: 100%;

	input {
		border-radius: 0.67em;
		width: 100%;
		height: 3.05em;
		background-color: ${colorTheme.cartModal.modalSize.remarkInput.background};
		border: 1px solid ${colorTheme.cartModal.modalSize.remarkInput.border};
		font-family: ${theme.fontRoboto};
		font-size: 1.13em;
		line-height: 1.31em;
		color: ${colorTheme.cartModal.modalSize.remarkInput.text};
		padding: 0.94em 3.1em;

		&::placeholder {
			color: ${colorTheme.cartModal.modalSize.remarkInput.placeholder};
		}
	}

	input:focus {
		border-color: ${colorTheme.cartModal.modalSize.remarkInput.focus.border};
		box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.22);
	}

	svg {
		font-size: 1em;
		position: absolute;
		left: 1em;
		top: calc(50% - 0.78em);
		cursor: pointer;
		width: 1.69em;
		height: 1.56em;
		fill: ${colorTheme.cartModal.modalSize.remarkInput.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		input {
			font-size: 1.29em;
		}

		svg {
			width: 1.93em;
			height: 1.79em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		input {
			font-size: 1.13em;
		}

		svg {
			width: 1.69em;
			height: 1.56em;
		}
	}
`
InputRemark.displayName = 'InputRemark'

export const InputRemarkWrap = styled.div`
	display: flex;
	gap: 0.63em;
	width: 100%;

	@media screen and (max-width: ${theme.point820}) {
		gap: 0.72em;
	}
`
InputRemarkWrap.displayName = 'InputRemarkWrap'

export const AddButtonWrap = styled.div`
	display: flex;
	align-items: center;
	font-size: 1rem;
	justify-content: space-between;
	width: 100%;
	gap: 0.63em;

	@media screen and (max-width: ${theme.point820}) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		> :first-child {
			${props => props.quantity && `
        grid-column: 1;
      `}
		}

		> ${InputRemarkWrap} {
			grid-column: 1 / 3;
			${props => props.quantity && `
        grid-column: 2;
      `}
		}

		${ButtonWrapper} {
			width: 100%;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: repeat(2, 1fr);

		> :first-child {
			${props => props.quantity && `
        grid-column: 1 / 3;
      `}
		}

		> ${InputRemarkWrap} {
			grid-column: 1 / 3;
		}
	}
`
AddButtonWrap.displayName = 'AddButtonWrap'

export const ToppingsButtonsWrapper = styled.div`
	height: calc(94.5vh - ${HEIGHT_OF_ELEMENTS_WITHOUT_SIZE_BLOCK}em);
	overflow-y: auto;

	@media screen and (max-width: ${theme.point820}) {
		overflow-y: visible;
		height: auto;
		margin-bottom: 8.25em;
	}
`
ToppingsButtonsWrapper.displayName = 'ToppingsButtonsWrapper'

export const EmptyToppingsWrapper = styled.div`
	font-size: 2.25em;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px dashed #A4ACB8;
	color: #A4ACB8;
	height: 100%;
	min-height: 3.75em;

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.75em;
		min-height: 12em;
	}
`
EmptyToppingsWrapper.displayName = 'EmptyToppingsWrapper'

export const IngredientsBlock = styled.div`
	font-size: 1.13em;
	margin-top: -1.44em;
	margin-bottom: 1.11em;
	color: ${colorTheme.cartModal.modalSize.ingredients};

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.29em;
		margin-top: 0;
		margin-bottom: 0.94em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;
	}
`
IngredientsBlock.displayName = 'IngredientsBlock'

export const KeyboardButtonWrap = styled.div`
	div {
		width: 2.25em;
		height: 1.63em;
	}

	@media screen and (max-width: ${theme.point820}) {
		button {
			height: 100%;
		}

		div {
			width: 2.57em;
			height: 1.85em;
		}
	}
`
KeyboardButtonWrap.displayName = 'KeyboardButtonWrap'