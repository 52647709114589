import printerInstance from '../instance'

const fetchAutoPrintConfigs = ({ module, platformType, businessScope }) => {
	return printerInstance.post('/autoPrintConfigs/autoPrint', {
		...(module && { module }),
		...(platformType && { platformMetaData: { platformType } }),
		businessScope,
	})
}

export default fetchAutoPrintConfigs
