import { notifyToast } from '../../../helper'
import { SET_APP_API_HERE, SET_APP_ERRORS, SET_APP_EVENT, SET_APP_FORM_CHANGED, SET_APP_FORM_CHANGED_MODAL, SET_APP_MODAL, SET_APP_MODAL_DATA, SET_APP_NAVIGATE, SET_APP_NAVIGATE_BOOL, SET_APP_TOGGLED, SET_PAGE, SET_SOCKET_ERROR, SET_SYSTEM_FLAG_LOGOUT, SET_SYSTEM_FORM_BUTTON_SAVE, SET_SYSTEM_KEYBOARD_TEXT, SET_SYSTEM_LOCAL_STORAGE, SET_SYSTEM_SHOW_KEYBOARD, TRIGGER_SYSTEM_KEYBOARD_ENTER } from '../../actionTypes/system'

export const setAppErrors = errors => {
	notifyToast(errors, 'error')
	return { type: SET_APP_ERRORS, errors }
}

export const setAppFormChanged = changed => ({ type: SET_APP_FORM_CHANGED, changed })

export const setAppFormChangedModal = formChangedModal => ({ type: SET_APP_FORM_CHANGED_MODAL, formChangedModal })

export const setAppNavigateTo = navigateTo => ({ type: SET_APP_NAVIGATE, navigateTo })

export const setAppNavigateBool = navigateBool => ({ type: SET_APP_NAVIGATE_BOOL, navigateBool })

export const setToggled = toggled => ({ type: SET_APP_TOGGLED, toggled })

export const setAppModal = modal => ({ type: SET_APP_MODAL, modal })

export const setAppModalData = modalData => ({ type: SET_APP_MODAL_DATA, modalData })

export const setAppEvent = event => ({ type: SET_APP_EVENT, event })

export const setAppEventZIndex = eventIndex => ({ type: 'SET_APP_EVENT_Z_INDEX', eventIndex })

export const setSocketError = value => ({ type: SET_SOCKET_ERROR, value })

export const setPage = content => ({ type: SET_PAGE, payload: { content } })

export const setAppApiHere = api_here => ({ type: SET_APP_API_HERE, api_here })

export const setSystemShowKeyboard = value => ({ type: SET_SYSTEM_SHOW_KEYBOARD, value })

export const setSystemKeyboardText = value => ({ type: SET_SYSTEM_KEYBOARD_TEXT, value })

export const triggerSystemKeyboardEnter = () => ({ type: TRIGGER_SYSTEM_KEYBOARD_ENTER })

export const setSystemLocalStorage = value => ({ type: SET_SYSTEM_LOCAL_STORAGE, value })

export const setAppAfterLogout = value => ({ type: SET_SYSTEM_FLAG_LOGOUT, value })

export const setSystemFormButtonSave = formButtonSave => ({ type: SET_SYSTEM_FORM_BUTTON_SAVE, formButtonSave })
