import styled from 'styled-components'
import { theme } from '../../../style/theme'
import colors from '../../../style/colors'

export const QrCodeWrapper = styled.div`
	background: ${colors.black};
	border-radius: 0.75em;
	padding: 1.06em 1.44em 1.44em;
`

export const WebshopInfoSwitchWrapper = styled.div`
	display: flex;
	margin-top: 1em;
	padding: 0.63em 0;
	border: 1px solid #777E89;
	border-radius: 0.75em;
	justify-content: center;
`
WebshopInfoSwitchWrapper.displayName = 'WebshopInfoSwitchWrapper'

export const WebshopInfoSwitch = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: min-content;
	align-items: center;
	gap: 0.63em 0;
`
WebshopInfoSwitch.displayName = 'WebshopInfoSwitch'

export const SwitchText = styled.div`
	display: flex;
	gap: 0.47em;
	font-weight: 600;
	font-size: 1.06em;
	line-height: 1.25em;
	text-transform: uppercase;
	color: #777E89;

	> svg {
		width: 1.25em;
		height: 1em;
		fill: #777E89;
	}

	&.active {
		color: #38CD90;

		> svg {
			fill: #38CD90;
		}
	}
`
SwitchText.displayName = 'SwitchText'

export const DisabledOrderTypePeriodText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.88em;
	font-weight: 500;
	color: ${colors.gray_beta};
	text-align: center;
	grid-column: 1 / 3;
	margin-top: -0.71em;
`
DisabledOrderTypePeriodText.displayName = 'DisabledOrderTypePeriodText'