import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'
import storeWoltDriverIntegrations from '../../axios/wolt/driverIntegration/store'
import { setWoltDriverErrorOtherFields, setWoltDriverErrorVenueId, setWoltDriverIntegrations, setWoltDriverIntegrationsOrder, setWoltDriverIntegrationsDeliveryPromises, setWoltDriverNotFound } from '../../action/woltDriverIntegrations'
import fetchWoltDriverIntegrations from '../../axios/wolt/driverIntegration'
import deleteDriverIntegrations from '../../axios/wolt/driverIntegration/delete'
import storeWoltDriverDeliveryPromises from '../../axios/wolt/deliveryPromises/store'
import storeWoltDriverDeliveries from '../../axios/wolt/deliveries/store'

export const storeWoltDriverIntegrationsThunk = (data, callbackSuccess) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

	return storeWoltDriverIntegrations(data)
		.then(() => {
			dispatch(succeedSystemFormButtonSaveThunk())
			dispatch(fetchWoltDriverIntegrationsThunk(0, 10))
			if (typeof callbackSuccess === 'function') callbackSuccess()
		}).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			if (error.response?.status === 400) {
				const errorStatusCode = error.response?.data?.apierror?.errorStatusCode
				if (errorStatusCode === 'wolt_drive_invalid_venue_id') {
					return dispatch(setWoltDriverErrorVenueId(true))
				}
				if (errorStatusCode === 'wolt_drive_invalid_merchant_id_or_token') {
					return dispatch(setWoltDriverErrorOtherFields(true))
				}
			}
			else {
				return dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const fetchWoltDriverIntegrationsThunk = () => dispatch => {
	return fetchWoltDriverIntegrations(0, 10)
		.then(res => {
			dispatch(setWoltDriverIntegrations(res.data))
		}).catch(error => dispatch(setAppErrors(retrieveErrorFromApi(error))))
}

export const deleteWoltDriverIntegrationsThunk = (id) => dispatch => {
	return deleteDriverIntegrations(id)
		.then(() => {
			dispatch(fetchWoltDriverIntegrationsThunk())
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}


export const storeWoltDriverDeliveryPromisesThunk = (data) => dispatch => {
	return storeWoltDriverDeliveryPromises(data)
		.then(res => {
			dispatch(setWoltDriverIntegrationsDeliveryPromises(res.data))
			dispatch(setWoltDriverIntegrationsOrder(data))
		}).catch(error => {
			if (['BAD_REQUEST', 'NOT_FOUND'].includes(error.response?.data?.apierror?.status))
				dispatch(setWoltDriverNotFound(true))
			else dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
		)
}

export const storeWoltDriverDeliveriesThunk = (data) => dispatch => {
	return storeWoltDriverDeliveries(data)
		.then(res => {
		}).catch(error => {
			if (error.response?.data?.apierror?.status === 'BAD_REQUEST')
				dispatch(setWoltDriverNotFound(true))
			else dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
		)
}
