import React from 'react'
import CartMenu from './CartMenu'
import CartProduct from './CartProduct'
import CartGiftCoupon from './CartGiftCoupon'

const CartItem = React.memo(({
		active, item, removeAbility, onClickPlus, AddButton,
		plusBtnColor = null, savedOrder = false,
	},
) => {
	return (
		item?.type === 'MENU' ?
			<CartMenu active={active}
								item={item}
								savedOrder={savedOrder}
								removeAbility={removeAbility}
								AddButton={AddButton}
								onClickPlus={onClickPlus} /> :
			item?.type === 'GIFT_COUPON' ?
				<CartGiftCoupon coupon={item}
												AddButton={AddButton}
												onClickPlus={onClickPlus} /> :
				<CartProduct item={item}
										 removeAbility={removeAbility}
										 AddButton={AddButton}
										 onClickPlus={onClickPlus}
										 plusBtnColor={plusBtnColor} />
	)
})

CartItem.displayName = 'CartItem'
export default CartItem
