import React from 'react'
import { useTranslation } from 'react-i18next'
import { denyStatusButtons } from '../constants'
import { DenyButton, DenyButtonText, OrderButtonsWrap } from './index.styled'

const DenyStatusButtons = React.memo(({ handleDeny, activeButtonIndex, disabled }) => {
	const { t } = useTranslation()
	const layout = disabled ? 'disabled ' : ''

	return (
		<OrderButtonsWrap>
			{denyStatusButtons?.map((button, index) =>
				<DenyButton key={index}
					onClick={!disabled ? () => handleDeny(button.status) : () => {}}
					className={layout + (activeButtonIndex === index ? 'active' : '')}>
					<DenyButtonText className={activeButtonIndex === index ? 'active ' : ''}>
						{t(button.label)}
					</DenyButtonText>
				</DenyButton>)}
		</OrderButtonsWrap>
	)
})

DenyStatusButtons.displayName = 'DenyStatusButtons'
export default DenyStatusButtons
