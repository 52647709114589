import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import colors from '../../../../style/colors'
import { TimeError } from '../../../WebShopSchedule/WorkingHours/WorkingHoursConfig/TimePeriods/index.styled'

export const WaiterTimeError = styled(TimeError)`
	display: none;
	&.error {
		display: block;
		padding: 0;
		text-align: center;
		color: ${colors.red};
	}
`
WaiterTimeError.displayName = 'WaiterTimeError'

export const WaiterTimeSelectWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	gap: 0.63em;
	align-items: center;
	width: 14.7em;

	> span {
		color: #000;
		font-family: ${theme.fontRobotoMedium};
		font-size: 2.5em;
		font-weight: 500;
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 100%;
	}
`
WaiterTimeSelectWrapper.displayName = 'WaiterTimeSelectWrapper'

export const WaiterDiscountInputWrapper = styled.div`
	@media screen and (max-width: ${theme.point820}) {
		width: 100%;
	}
`
WaiterDiscountInputWrapper.displayName = 'WaiterDiscountInputWrapper'
