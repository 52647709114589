import { setProducts } from '../../../action/product'
import productInstance from '../instance'
import { notifyToast, retrieveErrorFromApi } from '../../../../helper'

const searchProduct = (event) => {
  return dispatch => {
    productInstance.get('/products?page=0&size=0&nrTitle=' + (event ? event : ''))
      .then(res => {
        dispatch(setProducts(res.data))
      })
      .catch(error => {
        notifyToast(retrieveErrorFromApi(error))
      })
  }
}

export default searchProduct
