export const SET_ORDERS = 'SET_ORDERS'
export const SET_ORDERS_TOTAL_LENGTH = 'SET_ORDERS_TOTAL_LENGTH'
export const ADD_ORDERS = 'ADD_ORDERS'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER'
export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING'
export const RE_INITIALIZE_ORDER = 'RE_INITIALIZE_ORDER'
export const SET_SUM_ORDERS = 'SET_SUM_ORDERS'
export const SET_SHOW_ORDER_CLOSED_ERROR_MODAL = 'SET_SHOW_ORDER_CLOSED_ERROR_MODAL'
export const ADD_PRE_ORDERS_TODAY = 'ADD_PRE_ORDERS_TODAY'
export const SET_PRE_ORDERS_TODAY_TOTAL_LENGTH = 'SET_PRE_ORDERS_TODAY_TOTAL_LENGTH'
export const ADD_PRE_ORDERS_DAYS = 'ADD_PRE_ORDERS_DAYS'
export const SET_PRE_ORDERS_DAYS_TOTAL_LENGTH = 'SET_PRE_ORDERS_DAYS_TOTAL_LENGTH'
export const ADD_NEW_PRE_ORDER_TODAY = 'ADD_NEW_PRE_ORDER_TODAY'
export const UPDATE_PRE_ORDER_TODAY = 'UPDATE_PRE_ORDER_TODAY'
export const REMOVE_PRE_ORDER_TODAY = 'REMOVE_PRE_ORDER_TODAY'
export const UPDATE_PRE_ORDER_DAYS = 'UPDATE_PRE_ORDER_DAYS'
export const REMOVE_PRE_ORDER_DAYS = 'REMOVE_PRE_ORDER_DAYS'
export const ADD_NEW_PRE_ORDER_DAYS = 'ADD_NEW_PRE_ORDER_DAYS'
export const SET_PRE_ORDERS_NOT_ACCEPTED = 'SET_PRE_ORDERS_NOT_ACCEPTED'
export const SET_PRE_ORDERS_TODAY = 'SET_PRE_ORDERS_TODAY'
export const SET_PRE_ORDERS_DAYS = 'SET_PRE_ORDERS_DAYS'
export const SET_SHOW_TODAY_PRE_ORDERS_MODAL = 'SET_SHOW_TODAY_PRE_ORDERS_MODAL'
export const ADD_UNPAID_ORDERS = 'ADD_UNPAID_ORDERS'
export const SET_UNPAID_ORDERS_TOTAL_LENGTH = 'SET_UNPAID_ORDERS_TOTAL_LENGTH'
export const SET_UNPAID_ORDERS = 'SET_UNPAID_ORDERS'
export const ADD_NEW_UNPAID_ORDER = 'ADD_NEW_UNPAID_ORDER'
export const REMOVE_UNPAID_ORDER = 'REMOVE_UNPAID_ORDER'
export const UPDATE_UNPAID_ORDER = 'UPDATE_UNPAID_ORDER'
export const SET_IS_PROCESSING_CONFIRM_ORDER = 'SET_IS_PROCESSING_CONFIRM_ORDER'
export const SET_IS_PROCESSING_DENY_ORDER = 'SET_IS_PROCESSING_DENY_ORDER'
