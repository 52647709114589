import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { convertTimePeriodsToMinutes, convertTimePeriodsToSeconds, convertTimeStrToHoursAndMinutesObj } from '../../../WebShopSchedule/helper'
import { deleteWebshopDayWithPeriodsError } from '../../../../redux/action/webshopSchedule'
import { updateShopConfigThunk } from '../../../../redux/thunk/webshopinfo'
import { getShopWorkScheduleThunk, updateShopWorkScheduleThunk } from '../../../../redux/thunk/webshopSchedule'
import Button from '../../../Elements/buttons/Button'
import CustomModal from '../../../Elements/modal/CustomModal'
import SettingsTitle from '../../../Elements/settings/SettingsTitle'
import WorkingHoursConfig from '../../../WebShopSchedule/WorkingHours/WorkingHoursConfig'
import { DEFAULT_DAYS, DEFAULT_TIME_PREORDER_DELAY } from '../../../WebShopSchedule/constants'

const EVENT_Z_INDEX = 42

const WebshopScheduleModal = React.memo(({ isOpen, close, openActivationModal, openPopup }) => {
	const { t } = useTranslation()
	const config = useSelector(store => store.webshopinfo.config)
	const activationDate = useSelector(store => store.webshopinfo.config?.activationDate)
	const workingHoursConfig = useSelector(state => state.webshopSchedule.workingHoursConfig)
	const preOrderDelayAfterOpen = useSelector(state => state.webshopSchedule.preOrderDelay?.delayAfterOpening)
	const preOrderDelayBeforeClose = useSelector(state => state.webshopSchedule.preOrderDelay?.delayBeforeClosing)
	const daysWithPeriodsError = useSelector(state => state.webshopSchedule.daysWithPeriodsError)
	const [workDays, setWorkDays] = useState(DEFAULT_DAYS)
	const [delayAfterOpen, setDelayAfterOpen] = useState(DEFAULT_TIME_PREORDER_DELAY)
	const [delayBeforeClose, setDelayBeforeClose] = useState(DEFAULT_TIME_PREORDER_DELAY)
	const dispatch = useDispatch()
	const isEnabled = !!config?.enabled
	const allDaysWithCorrectTimePeriods = !Object.keys(daysWithPeriodsError)?.length

	useEffect(() => {
		if (isOpen && activationDate) {
			dispatch(getShopWorkScheduleThunk())
		}
	}, [isOpen])

	useEffect(() => {
		if (workingHoursConfig.length) {
			setWorkDays(convertTimePeriodsToMinutes(workingHoursConfig))
		}
	}, [!!workingHoursConfig.length])

	useEffect(() => {
		if (preOrderDelayAfterOpen) {
			setDelayAfterOpen(convertTimeStrToHoursAndMinutesObj(preOrderDelayAfterOpen))
		}
	}, [preOrderDelayAfterOpen])

	useEffect(() => {
		if (preOrderDelayBeforeClose) {
			setDelayBeforeClose(convertTimeStrToHoursAndMinutesObj(preOrderDelayBeforeClose))
		}
	}, [preOrderDelayBeforeClose])

	const clearAllPeriodsError = useCallback(() => {
		Object.keys(daysWithPeriodsError).forEach(day =>
			dispatch(deleteWebshopDayWithPeriodsError(day)))
	}, [daysWithPeriodsError])

	const handleCloseClick = useCallback(() => {
		if (workingHoursConfig.length) {
			setWorkDays(convertTimePeriodsToMinutes(workingHoursConfig))
		} else {
			setWorkDays(DEFAULT_DAYS)
		}

		if (preOrderDelayAfterOpen) {
			setDelayAfterOpen(convertTimeStrToHoursAndMinutesObj(preOrderDelayAfterOpen))
		} else {
			setDelayAfterOpen(DEFAULT_TIME_PREORDER_DELAY)
		}

		if (preOrderDelayBeforeClose) {
			setDelayBeforeClose(convertTimeStrToHoursAndMinutesObj(preOrderDelayBeforeClose))
		} else {
			setDelayBeforeClose(DEFAULT_TIME_PREORDER_DELAY)
		}

		if (!allDaysWithCorrectTimePeriods) {
			clearAllPeriodsError()
		}

		close()
	}, [close, workingHoursConfig, preOrderDelayAfterOpen, preOrderDelayBeforeClose, allDaysWithCorrectTimePeriods, clearAllPeriodsError])

	const handleOpenActivationModalClick = useCallback(() => {
		handleCloseClick()
		openActivationModal()
	}, [handleCloseClick, openActivationModal])

	const handleActivationClick = useCallback(() => {
		if (allDaysWithCorrectTimePeriods) {
			const convertedWorkDays = convertTimePeriodsToSeconds(workDays)
			const delayAfterOpening = `${delayAfterOpen.hours}:${delayAfterOpen.minutes}:00`
			const delayBeforeClosing = `${delayBeforeClose.hours}:${delayBeforeClose.minutes}:00`

			dispatch(updateShopWorkScheduleThunk(convertedWorkDays, { delayAfterOpening, delayBeforeClosing }))
			dispatch(updateShopConfigThunk({
				...config, enabled: !isEnabled,
			}))

			if (!isEnabled) openPopup()
			close()
		}
	}, [close, delayAfterOpen, delayBeforeClose, workDays, config, isEnabled, openPopup, allDaysWithCorrectTimePeriods])

	return (
		<CustomModal isOpen={isOpen}
			close={handleOpenActivationModalClick}
			size="auto"
			zIndex={EVENT_Z_INDEX}
			showBackButton={true}
			heading={true}
			button={
				<Button name="activate" icon="checkmark" color="green"
					text={t('WebshopInfo.modal.activate')}
					zIndex={EVENT_Z_INDEX} keyButton="F2"
					disabled={!allDaysWithCorrectTimePeriods}
					onClick={handleActivationClick} />
			}>
			<SettingsTitle icon="shop_working_hours">{t('Settings.WebShopWorkingHours')}</SettingsTitle>
			<WorkingHoursConfig {...{ isModal: true, workDays, setWorkDays, delayAfterOpen, setDelayAfterOpen, delayBeforeClose, setDelayBeforeClose }} />
		</CustomModal>
	)
})

WebshopScheduleModal.displayName = 'WebshopScheduleModal'
export default WebshopScheduleModal
