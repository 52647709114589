import React, { useCallback, useEffect, useRef } from 'react'
import { WebshopActiveDropdown, WebshopAdviceWrapper, WebshopButtonWrapper, WebshopDropdownSvg, WebshopDropdownText, WebshopDropdownTitle, WebshopDropdownWrapper } from './index.styled'
import sprite from '../../../img/svg/main/index.svg'

const useOutsideAdviceClick = (ref, callback) => {
	const handleClick = useCallback(e => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback()
		}
	}, [ref.current, callback])

	useEffect(() => {
		document.addEventListener('mousedown', handleClick)
		return () => {
			document.removeEventListener('mousedown', handleClick)
		}
	})
}

const AdviceWindow = React.memo(({ showAdvice, icon, title, text, closeWindow, buttons, additionalContent, children }) => {
	const refAdviceWindow = useRef()

	useOutsideAdviceClick(refAdviceWindow, closeWindow)
	return (
		<WebshopAdviceWrapper>
			{children}
			{showAdvice && <WebshopActiveDropdown ref={refAdviceWindow}>
				<WebshopDropdownWrapper>
					<WebshopDropdownSvg data-testid={icon}>
						<use href={sprite + `#${icon}`} />
					</WebshopDropdownSvg>
					<WebshopDropdownTitle>{title}</WebshopDropdownTitle>
				</WebshopDropdownWrapper>
				<WebshopDropdownText>{text}</WebshopDropdownText>
				<WebshopButtonWrapper>
					{buttons}
				</WebshopButtonWrapper>
				{additionalContent}
			</WebshopActiveDropdown>}
		</WebshopAdviceWrapper>
	)
})

AdviceWindow.displayName = 'AdviceWindow'
export default AdviceWindow
