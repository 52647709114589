import React from 'react'
import { CartProductDishCourse, CartProductDishCourseEmpty, CartProductDishCourseText } from './index.styled'
import { COLORS } from '../../../../RestaurantCartSettings/DishCoursesSettings/constants'

const CartDishCourse = React.memo(({ item }) => {
	return (
		<>
			{item.dishCourse ?
				<CartProductDishCourse color={COLORS[item?.dishCourse?.index - 1]} data-testid={`dish-course-${item.name}`}>
					<CartProductDishCourseText>{item?.dishCourse?.index}</CartProductDishCourseText>
				</CartProductDishCourse>
				: <CartProductDishCourseEmpty />}
		</>
	)
})

CartDishCourse.displayName = 'CartDishCourse'
export default CartDishCourse
