import { setAppErrors } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'
import getCompanyActivationData from '../../axios/registration/company/activationData/get'
import { setCompanyActivationData } from '../../action/company'
export const getCompanyActivationDataThunk = () => async dispatch => {
	try {
		const res = await getCompanyActivationData()
		dispatch(setCompanyActivationData(res.data))
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}
