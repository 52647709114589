import { SET_CANCEL_REASON, SET_ORDER_EVENT, SET_ORDER_USER_MODAL, SET_SELECTED_USER, SET_SHOW_CANCEL_ORDER, SET_SHOW_ORDER, SET_SHOW_ORDER_DATA, SET_SHOW_SINGLE_DRIVER } from '../../actionTypes/driver-monitor'
import { setCancelReasonFoodoraOptions, setCancelReasonOptions } from './constants'

const initialState = {
  drivers: [],
  selectedUser: null,
  cancelReason: setCancelReasonOptions()[0].value,
  cancelReasonOptions: setCancelReasonOptions(),
	cancelReasonFoodoraOptions: setCancelReasonFoodoraOptions(),
  modal: {
    showSelectedUser: false,
    showOrder: false,
    showOrderData: false,
    showCancelOrder: false,
    showSingleDriver: false,
  },
  event: {
    eventTable: true,
    eventUser: true,
    eventOrder: false,
    eventCancelOrder: false,
    eventCall: false,
  },
}

export function driverMonitorReducer (state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_ORDER:
      return {
        ...state,
        modal: {
          ...state.modal,
          showOrder: action.payload.content,
        },
      }
    case SET_SHOW_ORDER_DATA:
      return {
        ...state,
        modal: {
          ...state.modal,
          showOrderData: action.payload.content,
        },
      }
    case SET_SHOW_CANCEL_ORDER:
      return {
        ...state,
        modal: {
          ...state.modal,
          showCancelOrder: action.payload.content,
        },
      }
    case SET_ORDER_USER_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          showSelectedUser: action.showSelectedUser,
        },
      }
    case SET_ORDER_EVENT:
      return {
        ...state,
        event: {
          ...state.events,
          eventTable: action.payload.content.eventTable ?? state.event.eventTable,
          eventUser: action.payload.content.eventUser ?? state.event.eventUser,
          eventOrder: action.payload.content.eventOrder ?? state.event.eventOrder,
          eventCancelOrder: action.payload.content.eventCancelOrder ?? state.event.eventCancelOrder,
        },
      }
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.user,
      }
    case SET_CANCEL_REASON:
      return {
        ...state,
        cancelReason: action.cancelReason ? action.cancelReason : setCancelReasonOptions()[0].value,
      }
    case SET_SHOW_SINGLE_DRIVER:
      return {
        ...state,
        modal: {
          ...state.modal,
          showSingleDriver: action.payload.content,
        },
      }
    default:
      return state
  }
}


