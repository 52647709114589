import styled from 'styled-components'
import { theme } from '../../../../../../style/theme'

export const BackCategoryButton = styled.li`
	background: ${props => props.themeStyles.backCategory.background};
	border-radius: 0.75em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 2px solid ${props => props.themeStyles.backCategory.border};
	cursor: pointer;
	min-height: 8.5em;

	> svg {
		margin: 0 auto;
		fill: ${props => props.themeStyles.backCategory.backIcon};
	}

	> div {
		background: ${props => props.themeStyles.backCategory.backgroundInside};
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.71em;
		height: 100%;
		border-radius: 0.65em;
		font-family: ${theme.fontRobotoMedium};
		font-size: 1.06em;
		color: ${props => props.themeStyles.backCategory.color};
		padding: 1.18em 0.29em;
		word-break: break-all;
		text-align: center;
	}

	@media screen and (max-width: ${theme.point820}) {
		min-height: auto;

		> div {
			gap: 0.44em;
			padding: 0.6em 0.3em;
		}
	}
`

export const BackCategoryButtonIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4em;
	height: 4em;

	svg, img {
		width: 100%;
		height: 100%;
		fill: ${props => props.themeStyles.backCategory.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 2.5em;
		height: 2.5em;
	}
`
