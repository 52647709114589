import { APPEND_PHONES, SET_PHONE, SET_PHONE_TEST_SENT, SET_PHONE_TEST_SUCCESS, SET_PHONE_TOTAL_LENGTH, SET_PHONES, UPDATE_PHONE_STATUS } from '../../actionTypes/phone'

export const setPhone = data => ({ type: SET_PHONE, data })

export const setPhoneTotaLength = length => ({ type: SET_PHONE_TOTAL_LENGTH, length })

export const setPhones = phones => ({ type: SET_PHONES, phones })

export const appendPhones = phones => ({ type: APPEND_PHONES, phones })

export const setPhoneTestSent = value => ({ type: SET_PHONE_TEST_SENT, value })

export const setPhoneTestSuccess = value => ({ type: SET_PHONE_TEST_SUCCESS, value })

export const updatePhoneStatus = value => ({ type: UPDATE_PHONE_STATUS, value })
