import { combineReducers } from 'redux'
import { categoryReducer } from './category'
import { printerReducer } from './printer'
import { productReducer } from './product'
import { registrationOwnerReducer } from './registration/Owner'
import { loginReducer } from './login'
import { recoveryReducer } from './recovery/Recovery'
import { registrationCompanyReducer } from './registration/Company'
import { userReducer } from './user'
import { orderReducer } from './order'
import { driverMonitorReducer } from './drivermonitor'
import { appReducer } from './system'
import { customerReducer } from './customer'
import { phoneReducer } from './phone'
import { reportReducer } from './report'
import { desktopUserReducer } from './desktop'
import { allOrderReducer } from './all-order'
import { deliveryConfigReducer } from './deliveryConfig'
import { permissionReducer } from './permission/Permission'
import { platformUserReducer } from './platform/PlatformUser'
import { takeawayRestaurantReducer } from './takeawayRestaurant'
import { orderFilterReducer } from './orderfilter'
import { taxSettingsReducer } from './taxSettings'
import { areaReducer } from './area'
import { restaurantReducer } from './restaurant'
import { mapReducer } from './map'
import { tableCartReducer } from './tableCart'
import { cartCategoryReducer } from './cartCategory'
import { cartProductReducer } from './cartProduct'
import { modalLoginReducer } from './modalLogin'
import { autoConfirmationReducer } from './acceptOrder'
import { deliveryCartReducer } from './deliveryCart'
import { ordersmartRestaurantReducer } from './ordersmartRestaurant'
import { cashDeskReducer } from './cashDesk'
import { businessScopeDiscountReducer } from './businessScopeDiscount'
import { cartDiscountReducer } from './cartDiscount'
import { cashBookReducer } from './cashbook'
import { socketReducer } from './socket'
import { deliverySettingsReducer } from './deliverySettings'
import { cashDeskSettingsReducer } from './cashDeskSettings'
import { restaurantSettingsReducer } from './restaurantSettings'
import { uberEatRestaurantReducer } from './ubereatRestaurant'
import { cashDrawerReducer } from './cashDrawer'
import { ordersCounterReducer } from './ordersCounter'
import { tseReducer } from './tse'
import { productFilterReducer } from './productFilter'
import { statisticsReducer } from './statistics'
import { cashBookNewReducer } from './cashbookNew'
import { zReportReducer } from './zreport'
import { supportReducer } from './support'
import { kitchenMonitorReducer } from './kitchenMonitor'
import { webshopInfoReducer } from './webshopInfo'
import { migrationReducer } from './migration'
import { webshopScheduleReducer } from './webshopSchedule'
import { woltRestaurantReducer } from './woltRestaurant'
import { datevReducer } from './datev'
import { shopSeoDataReducer } from './shopSeoData'
import { additivesGroupsReducer } from './additivesGroups'
import { couponReducer } from './coupon'
import { timerCounterReducer } from './timerCounter'
import { SET_LOGOUT } from '../actionTypes'
import { customersImportReducer } from './customersImport'
import { paymentReducer } from './payment'
import { cartPaymentReducer } from './cartPayment'
import { foodoraRestaurantReducer } from './foodoraRestaurant'
import { partnerPortalReducer } from './partnerPortal'
import { woltDriverIntegrationsReducer } from './woltDriverIntegrations'
import { clientMonitorConfigReducer } from './clientMonitorConfig'
import { clientMonitorCartReducer } from './clientMonitorCart'
import { printReceiptReducer } from './printReceipt'
import { invoiceReducer } from './invoice'
import { companyReducer } from './company'
import { orderAndPayConfigsReducer } from './orderAndPayConfigs'

const allReducers = combineReducers({
	category: categoryReducer,
	printer: printerReducer,
	product: productReducer,
	phoneStore: phoneReducer,
	registrationOwner: registrationOwnerReducer,
	registrationCompany: registrationCompanyReducer,
	login: loginReducer,
	company: companyReducer,
	recovery: recoveryReducer,
	user: userReducer,
	desktop: desktopUserReducer,
	platform: platformUserReducer,
	order: orderReducer,
	orderfilter: orderFilterReducer,
	drivermonitor: driverMonitorReducer,
	socket: socketReducer,
	system: appReducer,
	customerStore: customerReducer,
	reportStore: reportReducer,
	zReport: zReportReducer,
	allOrderStore: allOrderReducer,
	deliveryConfig: deliveryConfigReducer,
	permission: permissionReducer,
	takeawayRestaurant: takeawayRestaurantReducer,
	ordersmartRestaurant: ordersmartRestaurantReducer,
	uberEatRestaurant: uberEatRestaurantReducer,
	woltRestaurant: woltRestaurantReducer,
	area: areaReducer,
	taxSettings: taxSettingsReducer,
	restaurant: restaurantReducer,
	map: mapReducer,
	tableCart: tableCartReducer,
	deliveryCart: deliveryCartReducer,
	cartCategory: cartCategoryReducer,
	cartProduct: cartProductReducer,
	modalLogin: modalLoginReducer,
	autoConfirmationOrder: autoConfirmationReducer,
	cashDesk: cashDeskReducer,
	businessScopeDiscount: businessScopeDiscountReducer,
	cartDiscount: cartDiscountReducer,
	cashbook: cashBookReducer,
	datev: datevReducer,
	deliverySettings: deliverySettingsReducer,
	cashDeskSettings: cashDeskSettingsReducer,
	restaurantSettings: restaurantSettingsReducer,
	cashDrawer: cashDrawerReducer,
	ordersCounter: ordersCounterReducer,
	tse: tseReducer,
	productFilter: productFilterReducer,
	statistics: statisticsReducer,
	cashbookNew: cashBookNewReducer,
	support: supportReducer,
	kitchenMonitor: kitchenMonitorReducer,
	webshopinfo: webshopInfoReducer,
	webshopSchedule: webshopScheduleReducer,
	migration: migrationReducer,
	shopSeoData: shopSeoDataReducer,
	additivesGroups: additivesGroupsReducer,
	coupon: couponReducer,
	timerCounter: timerCounterReducer,
	customersImport: customersImportReducer,
	payment: paymentReducer,
	cartPayment: cartPaymentReducer,
	foodoraRestaurant: foodoraRestaurantReducer,
	partnerPortal: partnerPortalReducer,
	woltDriverIntegrations: woltDriverIntegrationsReducer,
	clientMonitorConfig: clientMonitorConfigReducer,
	clientMonitorCart: clientMonitorCartReducer,
	printReceipt: printReceiptReducer,
	invoice: invoiceReducer,
	orderAndPayConfigs: orderAndPayConfigsReducer,
})

export const rootReducer = (state, action) => {
	if (action.type === SET_LOGOUT) {
		const { registrationOwner, registrationCompany, login, recovery, socket } = state
		const notResetedState = { registrationOwner, registrationCompany, login, recovery, socket }
		return allReducers(notResetedState, action)
	}
	return allReducers(state, action)
}
