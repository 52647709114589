import { createSelector } from 'reselect'
import { getLatestOrder, getOrdersThisDriver } from './helper'

const selectOrders = store => store.order.orders
const selectMarkers = store => store.map.markers

export const selectOrdersByDrivers = createSelector(
	selectOrders,
	(orders) => orders?.filter(order => order.orderType === 'DELIVERY' && !!order.processedBy)
		.reduce((acc, order, i, orders) => {
			return {
				...acc,
				[order.processedBy]: getOrdersThisDriver(orders, order.processedBy)
			}
		}, {})
)

export const selectLastOrderIdByDriver = (driver) => createSelector(
	selectOrdersByDrivers,
	(driverOrdersMap) => {
		if (!driver) return ''
		const driverOrders = driverOrdersMap && driverOrdersMap[driver]
		let lastOrder

		if (driverOrders?.some((order) => order.status.status === 'ON_THE_WAY')) {
			return null
		} else {
			lastOrder = getLatestOrder(driverOrders)
		}
		return lastOrder?.orderId
	}
)

export const selectOrdersById = (orderId) => createSelector(
	selectMarkers,
	(markers) => markers?.find(marker => marker.orderId === orderId)
)

