import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const TypeWrapper = styled.div`
	display: flex;
	align-items: center;
	width: max-content;
	gap: 0.35em;
	margin-top: 0;

	@media screen and (max-width: ${theme.point820}) {
		grid-row: 1;
	}
`
TypeWrapper.displayName = 'TypeWrapper'


export const TypeIconWrapper = styled.div`
	font-size: 0.8em;
	border-radius: 0.63em;
	background-color: ${props => props.color ? props.color : colorTheme.table.tableTr.backgroundType};
	padding: 0.39em 0.93em;

	svg {
		width: 1.31em;
		height: 1.62em;
		fill: ${colorTheme.table.tableTr.icon} !important;
	}

	&.delivery {
		padding: 0.39em 0.57em;

		svg {
			width: 2em;
			height: 1.42em;
		}
	}


	@media screen and (max-width: ${theme.point820}) {
		font-size: 0.8em;

		&.delivery {
			font-size: 0.91em;
		}
	}
`
TypeIconWrapper.displayName = 'TypeIconWrapper'

export const ProcessedByText = styled.div`
	font-size: 0.8em;
	font-weight: 500;
	color: ${colorTheme.table.tableTr.colorType};

	@media screen and (max-width: ${theme.point820}) {
		font-size: 0.8em;

		&.delivery {
			font-size: 0.91em;
		}
	}
`
ProcessedByText.displayName = 'ProcessedByText'
