import { SET_TSE_CONFIG, SET_TSE_FON_DATA, SET_TSE_NOTIFICATION, UPDATE_TSE_DISABLE_NOTIFICATION, SET_TSE_FON_ERROR, SET_TSE_NULL_ORDERS } from '../../actionTypes/tse'

const initialState = {
	config: null,
	notification: null,
	fonData: null,
	fonError: {
		invalidFonData: false,
		invalidVatId: false,
	},
	nullOrders: []
}

export function tseReducer (state = initialState, action) {
	switch (action.type) {
		case SET_TSE_CONFIG:
			return {
				...state,
				config: action.value,
			}
		case SET_TSE_NOTIFICATION:
			return {
				...state,
				notification: action.value,
			}
		case UPDATE_TSE_DISABLE_NOTIFICATION:
			return {
				...state,
				notification: {
					...state.notification,
					disableNotification: action.value,
				},
			}
		case SET_TSE_FON_DATA:
			return {
				...state,
				fonData: action.value,
			}
		case SET_TSE_FON_ERROR:
			return {
				...state,
				fonError: {
					...state.fonError,
					invalidFonData: action.value.invalidFonData ?? state.fonError.invalidFonData,
					invalidVatId: action.value.invalidVatId ?? state.fonError.invalidVatId,
				},
			}
		case SET_TSE_NULL_ORDERS:
			return {
				...state,
				nullOrders: action.value,
			}
		default:
			return state
	}
}


