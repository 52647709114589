import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CustomModal from '../../modal/CustomModal'
import Button from '../../buttons/Button'
import CustomDatePicker from '../../datepicker'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import KeyBindings from '../../keybindings'
import { useTranslation } from 'react-i18next'

const EVENT_Z_INDEX = 8

const ModalDate = React.memo(({ isOpen, date, setDate, close }) => {
	const { t } = useTranslation()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))

	useEffect(() => {
		if (isOpen) {
			const now = new Date()
			now.setMinutes(now.getMinutes() - now.getMinutes() % 5 + 35)
			setDate(now)
		}
	}, [isOpen])

	const handleEnter = useCallback(() => {
		close()
	}, [close])

	const closeAndResetDate = useCallback(() => {
		setDate(null)
		close()
	}, [setDate, close])

	return (
		<>
			<CustomModal isOpen={isOpen}
									 title={t('Modal.confirm_preorder')}
									 size="auto"
									 close={closeAndResetDate}
									 zIndex={EVENT_Z_INDEX}
									 showBackButton={true}
									 button={
										 <Button name="send" icon="checkmark" text={t('buttons.controls.confirm')} keyButton="F2"
														 color="green" zIndex={EVENT_Z_INDEX} onClick={close} />
									 }>
				<CustomDatePicker date={date} setDate={setDate} minDate={new Date()} />
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter} />}
		</>
	)
})

ModalDate.displayName = 'ModalDate'
export default ModalDate
