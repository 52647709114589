import styled from 'styled-components'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	height: 1.15em;
	width: 1.15em;
	justify-self: start;
	margin-right: 0.6em;

	div {
		position: absolute;
		width: 0.4em;
		height: 0.4em;
		border-radius: 50%;
		background: ${colorTheme.loaders.circle.background};

		&:nth-child(1) {
			top: 0;
			left: 0;
			animation: dot-animation .7s infinite;
		}

		&:nth-child(2) {
			top: 0.05em;
			left: 0.75em;
			transform: scale(0.2);
			animation: dot-animation .7s infinite 0.2s;
		}

		&:nth-child(3) {
			top: 0.65em;
			left: 0.35em;
			transform: scale(0.2);
			animation: dot-animation .7s infinite 0.4s;
		}
	}

	&.blue div {
		background: ${colorTheme.loaders.circle.blue.background};

	}

	&.big div {
		width: 0.8em;
		height: 0.8em;
	}

	@keyframes dot-animation {
		0% {
			transform: scale(1);
		}

		100% {
			transform: scale(0.2);
		}
	}
`
LoaderWrapper.displayName = 'LoaderWrapper'
