import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const MonthlyStatistics = lazyWithRetry(() => import(/* webpackChunkName: "monthly-statistics" */'../../component/Statistic/MonthlyStatistics'))

const MonthlyStatisticsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<MonthlyStatistics />
		</Suspense>
	)
})

MonthlyStatisticsPage.displayName = 'MonthlyStatisticsPage'
export default MonthlyStatisticsPage
