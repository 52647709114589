import styled from 'styled-components'
import colors from '../../style/colors'
import { theme } from '../../style/theme'

export const HeaderWrapper = styled.header`
	grid-row: 1;
	grid-column: 2;
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	height: 100%;
	padding: 0.63em 1.75em;
	gap: 2em;
	position: relative;

	&.authPage {
		grid-column-start: span 3;
		padding: 1.25em 3.75em;

		.language {
			margin-right: 0;
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		grid-column: 1;
		grid-template-columns: 1fr auto 1fr;
		padding: 0.71em 0.93em 0.5em 0.71em;

		&.authPage {
			grid-template-columns: 1fr;
			padding: 1.43em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.63em 0.63em 0.44em;
		gap: 0.63em;
		background: ${colors.blue_dark};

		&.authPage {
			padding: 2.88em 0.63em 1.44em;
		}
	}
`
HeaderWrapper.displayName = 'HeaderWrapper'

export const ButtonToggle = styled.div`
	position: absolute;
	left: -0.7em;
	top: calc(50% - 0.66em);
	bottom: auto;
	height: 1.31em;
	width: 0.7em;
	transition: all 0.2s ease;
	transform: rotate(0deg);
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
	}

	&.toggled {
		transform: rotate(180deg);
		left: 0;
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 3.07em;
		height: 3.07em;
		border-radius: 0.86em;
		background: ${colors.white};
		position: static;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 0.25em;
		cursor: pointer;
		transition: all 0.2s;

		> span {
			width: 1.64em;
			height: 0.29em;
			border-radius: 0.75em;
			background: ${colors.blue};
			transition: all 0.2s;

			:nth-child(1) {
				transform: translateY(0.25em) rotate(45deg);
			}

			:nth-child(2) {
				display: none;
			}

			:nth-child(3) {
				transform: translateY(-0.25em) rotate(-45deg);
			}
		}

		&.toggled {
			transform: none;

			> span {
				transform: none;
				display: block;
			}
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 2.69em;
		height: 2.69em;
		border-radius: 0.75em;
		gap: 0.25em;

		> span {
			width: 1.44em;
			height: 0.25em;
			border-radius: 0.75em;
		}
	}
`
ButtonToggle.displayName = 'ButtonToggle'

export const HeaderCenter = styled.div`
	overflow-x: auto;
	width: 100%;
	padding: .15em;
	display: grid;
	gap: 0.63em;
	align-items: center;
	grid-auto-columns: max-content;
	grid-auto-flow: column;


	@media screen and (max-width: ${theme.point820}) {
		justify-self: center;
		grid-column: 2;
		gap: 0.71em;

		&.authPage {
			grid-column: 1;
			justify-content: center;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		justify-self: center;
		gap: 0.63em;

		&.authPage {
			align-self: start;
		}
	}
`
HeaderCenter.displayName = 'HeaderCenter'

export const HeaderRight = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.mode {
		margin-right: 0.35em;
	}

	.language {
		margin-right: 1.63em;
	}

	@media screen and (max-width: ${theme.point820}) {
		&.hidden {
			display: none;
		}
	}

`
HeaderRight.displayName = 'HeaderRight'
