import { retrieveErrorFromApi } from '../../../../helper'
import { closeConfirmExpressOrderModalThunk, closePlatformDriversOrderModalThunk, handleOrderForOrdersCounterThunk, handleOrderForUnpaidOrdersCounterThunk, updateOrderToTableThunk, updateUnpaidOrderToTableThunk } from '../../../../socket/order'
import { setIsProcessingConfirmOrder, setIsProcessingDenyOrder } from '../../../action/order'
import { setAppErrors, setAppModal, setAppModalData } from '../../../action/system'
import addOrderProcessingTime from '../../../axios/order/addOrderProcessingTime'
import denyOrder from '../../../axios/order/denyOrder'

export const removeOrderFromConfirmExpressOrderModalDataThunk = (orderId) => (dispatch, getState) => {
	const confirmExpressOrderModalData = getState().system.modalData?.confirmExpressOrderModalData?.filter(o => o.orderId !== orderId)
	dispatch(setAppModal({ confirmExpressOrderModalShow: false }))
	dispatch(setAppModalData({ confirmExpressOrderModalData }))
}

export const confirmExpressOrderThunk = (orderId) => dispatch => {
	dispatch(setIsProcessingConfirmOrder(true))
	return addOrderProcessingTime(orderId, 0)
		.then(res => {
			dispatch(removeOrderFromConfirmExpressOrderModalDataThunk(orderId))
			dispatch(closeConfirmExpressOrderModalThunk(res.data))
			dispatch(updateOrderToTableThunk(res.data, 'confirmed_manually'))
			dispatch(closePlatformDriversOrderModalThunk(res.data))
			dispatch(handleOrderForOrdersCounterThunk(res.data))
			dispatch(updateUnpaidOrderToTableThunk(res.data, 'confirmed_manually'))
			dispatch(handleOrderForUnpaidOrdersCounterThunk(res.data))
			dispatch(setIsProcessingConfirmOrder(false))
		}).catch(error => {
			dispatch(removeOrderFromConfirmExpressOrderModalDataThunk(orderId))
			dispatch(setIsProcessingConfirmOrder(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const denyExpressOrderThunk = (orderId, reason) => dispatch => {
	dispatch(setIsProcessingDenyOrder(true))
	return denyOrder(orderId, reason)
		.then(res => {
			dispatch(removeOrderFromConfirmExpressOrderModalDataThunk(orderId))
			dispatch(setIsProcessingDenyOrder(false))
		}).catch(error => {
			dispatch(removeOrderFromConfirmExpressOrderModalDataThunk(orderId))
			dispatch(setIsProcessingDenyOrder(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
