import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const CheckboxWrapper = styled.label`
	font-size: 1rem;
	display: flex;
	gap: 0.63em;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	user-select: none;

	height: 4.19em;
	background: ${colorTheme.input.checkBoxGray.background};
	border: 2px solid ${colorTheme.input.checkBoxGray.border};
	border-radius: 0.75em;
	padding: 1.06em 1.25em;

	&.active {
		background-color: ${colorTheme.input.checkBoxGray.active.background};
		border: 1px solid ${colorTheme.input.checkBoxGray.active.border};
	}

	&.start {
		align-items: flex-start;
	}

	@media screen and (max-width: ${theme.point820}) {
		padding: 0.84em 0.96em;
		height: 3.93em;
	}

	@media screen and (max-width: ${theme.point720}) {
		padding: 0.69em 1.25em;
		height: 3.44em;
	}

`

export const CheckboxContainer = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	user-select: none;

	height: 2em;
	width: 2em;
	border-radius: 0.38em;
	background-color: ${colorTheme.input.checkBoxGray.backgroundContainer};
	border: 1px solid ${colorTheme.input.checkBoxGray.borderContainer};
	flex-shrink: 0;

	&.active {
		background-color: ${colorTheme.input.checkBoxGray.active.backgroundContainer};
		border: none;
	}

	&.disabled {
		border: 1px solid ${colorTheme.input.checkBoxGray.disabled.border};
	}

	&.active&.disabled {
		background-color: ${colorTheme.input.checkBoxGray.disabled.background};
		border: none;
	}

	@media screen and (max-width: ${theme.point820}) {
		height: 2.29em;
		width: 2.29em;
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 2em;
		width: 2em;
	}
`

export const CheckedIcon = styled.svg`
	width: 1.13em;
	height: 0.87em;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	display: flex;
	align-items: center;
	justify-content: center;

	> svg {
		width: 100%;
		height: 100%;
		fill: ${colorTheme.input.checkBoxGray.icon};
	}

	&.active {
		> svg {
			fill: ${colorTheme.input.checkBoxGray.active.icon};
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 1.29em;
		height: 1em;
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 1.13em;
		height: 0.87em;
	}
`
CheckedIcon.displayName = 'CheckedIcon'

export const CheckboxLabel = styled.div`
	font-size: 1em;
	font-family: ${theme.fontRoboto};
	font-weight: 600;
	color: ${colorTheme.input.checkBoxGray.text};

	&.disabled {
		color: ${colorTheme.input.checkBoxGray.disabled.text};
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.14em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1em;
	}
`
CheckboxLabel.displayName = 'CheckboxLabel'

export const CheckboxHidden = styled.input`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;

`
