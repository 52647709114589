import { COLORS } from '../../../../RestaurantCartSettings/DishCoursesSettings/constants'
import React, { useCallback } from 'react'
import { CartProductDishCourse, CartProductDishCourseEmpty, CartProductDishCourseText } from './index.styled'
import { setTableCartItemDishCourseIndex, setTableCartShowDishCourseModal } from '../../../../../redux/action/tableCart'
import { useDispatch, useSelector } from 'react-redux'

const CartDishCourse = React.memo(({ item, savedOrder }) => {
	const dispatch = useDispatch()
	const dishCoursesLength = useSelector(store => store.restaurantSettings.dishCourses?.coursesCount)

	const openDishCourseModal = useCallback(() => {
		if (savedOrder) return
		const index = item?.dishCourse?.index === 0 ? dishCoursesLength : item?.dishCourse?.index - 1
		dispatch(setTableCartShowDishCourseModal(true))
		dispatch(setTableCartItemDishCourseIndex(index))
	}, [savedOrder, dispatch, item, dishCoursesLength])

	return (
		<>
			{item.dishCourse ?
				<CartProductDishCourse color={COLORS[item?.dishCourse?.index - 1]} onClick={openDishCourseModal} data-testid={`dish-course-${item.name}`}>
					<CartProductDishCourseText>{item?.dishCourse?.index}</CartProductDishCourseText>
				</CartProductDishCourse>
				: <CartProductDishCourseEmpty />}
		</>
	)
})

CartDishCourse.displayName = 'CartDishCourse'
export default CartDishCourse
