export const SET_APP_ERRORS = 'SET_APP_ERRORS'
export const SET_APP_API_HERE = 'SET_APP_API_HERE'
export const SET_APP_FORM_CHANGED = 'SET_APP_FORM_CHANGED'
export const SET_APP_FORM_CHANGED_MODAL = 'SET_APP_FORM_CHANGED_MODAL'
export const SET_APP_NAVIGATE = 'SET_APP_NAVIGATE'
export const SET_APP_NAVIGATE_BOOL = 'SET_APP_NAVIGATE_BOOL'
export const SET_APP_TOGGLED = 'SET_APP_TOGGLED'
export const SET_APP_MODAL = 'SET_APP_MODAL'
export const SET_APP_MODAL_DATA = 'SET_APP_MODAL_DATA'
export const SET_APP_EVENT = 'SET_APP_EVENT'
export const SET_SOCKET_ERROR = 'SET_SOCKET_ERROR'
export const SET_PAGE = 'SET_PAGE'
export const SET_SYSTEM_SHOW_KEYBOARD = 'SET_SYSTEM_SHOW_KEYBOARD'
export const SET_SYSTEM_KEYBOARD_TEXT = 'SET_SYSTEM_KEYBOARD_TEXT'
export const TRIGGER_SYSTEM_KEYBOARD_ENTER = 'TRIGGER_SYSTEM_KEYBOARD_ENTER'
export const SET_SYSTEM_LOCAL_STORAGE = 'SET_SYSTEM_LOCAL_STORAGE'
export const SET_SYSTEM_FLAG_LOGOUT = 'SET_SYSTEM_FLAG_LOGOUT'
export const SET_SYSTEM_FORM_BUTTON_SAVE = 'SET_SYSTEM_FORM_BUTTON_SAVE'
