import { APPEND_ORDERS_COUNTER_ORDER, APPEND_UNPAID_ORDERS_COUNTER_ORDER, REMOVE_ORDERS_COUNTER_ORDER, REMOVE_UNPAID_ORDERS_COUNTER_ORDER, SET_ORDERS_COUNTER_ORDERS, SET_PRE_ORDERS_COUNTER, SET_UNPAID_ORDERS_COUNTER_ORDERS } from '../../actionTypes/ordersCounter'

const initialState = {
	orders: [],
	unpaidOrders: [],
	preOrdersCounter: 0
}

export function ordersCounterReducer(state = initialState, action) {
	switch (action.type) {
		case SET_ORDERS_COUNTER_ORDERS:
			return { ...state, orders: action.value}
		case APPEND_ORDERS_COUNTER_ORDER:
			return { ...state, orders: [...state.orders, action.value] }
		case REMOVE_ORDERS_COUNTER_ORDER:
			return { ...state, orders: state.orders.filter(order => order !== action.value) }
		case SET_PRE_ORDERS_COUNTER:
			return {
				...state,
				preOrdersCounter: action.value,
			}
		case SET_UNPAID_ORDERS_COUNTER_ORDERS:
			return { ...state, unpaidOrders: action.value}
		case APPEND_UNPAID_ORDERS_COUNTER_ORDER:
			return { ...state, unpaidOrders: [...state.unpaidOrders, action.value] }
		case REMOVE_UNPAID_ORDERS_COUNTER_ORDER:
			return { ...state, unpaidOrders: state.unpaidOrders.filter(order => order !== action.value) }
		default:
			return state
	}
}


