import { SET_PERMISSIONS } from '../../actionTypes/permission'

const initialState = {
  permissions: [],
}

export function permissionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      }
    default:
      return state
  }
}
