import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const OrdersmartRestaurant = lazyWithRetry(() => import(/* webpackChunkName: "ordersmart-restaurant" */'../../component/OrdersmartRestaurant'))

const OrdersmartRestaurantPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<OrdersmartRestaurant />
		</Suspense>
	)
})

OrdersmartRestaurantPage.displayName = 'OrdersmartRestaurantPage'
export default OrdersmartRestaurantPage
