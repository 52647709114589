import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const InvoiceCustomerDataWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1.25em;
	margin-bottom: 0.63em;
`
InvoiceCustomerDataWrapper.displayName = 'InvoiceCustomerDataWrapper'

export const InvoiceCustomerDataSubtitle = styled.div`
	color: ${colorTheme.cartModal.invoiceModals.customerData.subtitle};
	font-family: ${theme.fontRobotoMedium};
	font-size: 1.13em;
	font-weight: 500;
`
InvoiceCustomerDataSubtitle.displayName = 'InvoiceCustomerDataSubtitle'

export const InvoiceCustomerDataDescription = styled.div`
	color: ${colorTheme.cartModal.invoiceModals.customerData.description};
	font-size: 1.06em;
	font-weight: 400;
`
InvoiceCustomerDataDescription.displayName = 'InvoiceCustomerDataDescription'
