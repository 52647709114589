import React, { useState, useRef } from 'react'
import { CenterContainer, TooltipBox, TooltipWrapper } from './index.styled'

const Tooltip = ({ position, text, children, background, margin, isShow = true, width, outline = false }) => {
	const [isHovered, setIsHovered] = useState(false)
	const [isFocused, setIsFocused] = useState(false)
	const targetRef = useRef(null)

	const showTooltip = isShow && (isHovered || isFocused)
	const marginTooltip = margin && `${parseFloat(margin) + 10}px`
	const layout = outline ? 'outline' : ''

	const handleClick = (e) => {
		e.preventDefault()
		if (targetRef.current) {
			targetRef.current.blur()
		}
	}

	return (
		<TooltipWrapper onPointerEnter={() => setIsHovered(true)}
										onPointerLeave={() => setIsHovered(false)}
										onFocus={() => setIsFocused(true)}
										onBlur={() => setIsFocused(false)}
										onClick={handleClick}
										ref={targetRef}
										showOnFocus={isFocused}>
			{showTooltip && (
				<CenterContainer position={position} margin={marginTooltip} width={width}>
					<TooltipBox background={background} position={position} className={layout}>
						{text}
					</TooltipBox>
				</CenterContainer>
			)}
			{children}
		</TooltipWrapper>
	)
}

Tooltip.displayName = 'Tooltip'
export default Tooltip
