import styled, { keyframes } from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const TooltipWrapper = styled.div`
	font-size: inherit;
	position: relative;
	cursor: inherit;
	display: flex;
`
TooltipWrapper.displayName = 'TooltipWrapper'

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`

export const Tooltip = styled.div`
	position: absolute;
	width: max-content;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	animation: ${fadeIn} 0.2s linear;
	top: -0.7em;
	left: 50%;
	transform: translate(-50%, -50%);

	@media screen and (max-width: ${theme.point820}) {
		width: 100%;
	}
`
Tooltip.displayName = 'Tooltip'

export const TooltipLineWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	min-height: 2em;
	border-radius: 0.6em;
	background-color:  ${colorTheme.tooltip.outlineBorder};
	width: 100%;
	height: 100%;
`
TooltipLineWrap.displayName = 'TooltipLineWrap'

export const TooltipBox = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.3em 1em;
		border-radius: 0.6em;
		background-color: ${colorTheme.tooltip.lightBackground};
		min-height: 1.9em;
	}
`
TooltipBox.displayName = 'TooltipBox'

export const TooltipText = styled.div`
	color: ${colorTheme.tooltip.text};
	font-family: ${theme.fontRobotoMedium};
	font-size: 0.75em;
	font-weight: 500;
`
TooltipText.displayName = 'TooltipText'

export const TooptipArrow = styled.div`
	width: 0;
	height: 0;
	border-width: 0.5em;
	border-style: solid;
	border-color: ${colorTheme.tooltip.outlineBorder} transparent transparent transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-top: -0.1em;

	&:before {
		position: absolute;
		content: '';
		display: block;
		border-width: 0.4em;
		border-style: solid;
		border-color: ${colorTheme.tooltip.lightBackground} transparent transparent transparent;
		top: -0.6em;
	}
`
TooptipArrow.displayName = 'TooptipArrow'
