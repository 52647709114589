import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClientMonitorWrap } from '../index.styled'
import { useSearchParams } from 'react-router-dom'
import StartStep from '../StartStep'
import { getClientMonitorPublicConfigThunk } from '../../../redux/thunk/clientMonitorConfig'
import { connectSocketClientMonitorIoThunk } from '../../../redux/thunk/socket'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { setSocketReconnected } from '../../../redux/action/socket'
import CartStep from '../CartStep'
import FinalStep from '../FinalStep'
import { selectCartCouponPayments, selectCartItems } from '../../../redux/selector/clientMonitorCart'
import { getClientMonitorPublicCartStateThunk } from '../../../redux/thunk/clientMonitorCart'
import { showClientMonitorFinalStep } from '../../../redux/action/clientMonitorCart'
import QrCodePaymentStep from '../QrCodePaymentStep'
import { extractQueryParam } from '../../../helper/clientMonitor'
import { useTranslation } from 'react-i18next'

const SocketClientMonitorCart = React.memo(() => {
	const { i18n } = useTranslation()
	const dispatch = useDispatch()
	const [, setSearchParams] = useSearchParams()
	const socketReconnected = useSelector(store => store.socket.socketReconnected)
	const [authUuid, setAuthUuid] = useLocalStorage('authUuid', null)
	const clientMonitorIsActive = useSelector(state => state.clientMonitorConfig.config?.enabled)
	const cartItems = useSelector(selectCartItems)
	const couponPayments = useSelector(selectCartCouponPayments)
	const selectShowFinalStep = useSelector(state => state.clientMonitorCart.showFinalStep)
	const qrCodePaymentSession = useSelector(state => state.clientMonitorCart.qrCodePaymentSession)
	const clientMonitorLanguage = useSelector(store => store.clientMonitorConfig.config?.language)
	const authUuidParam = extractQueryParam('authUuid')
	const showQrCodePaymentStep = qrCodePaymentSession?.link
	const showCartStep = !!(cartItems.length || couponPayments.length) && clientMonitorIsActive && !showQrCodePaymentStep
	const showFinalStep = selectShowFinalStep && clientMonitorIsActive && !qrCodePaymentSession
	const showStartStep = !showCartStep && !showFinalStep && !showQrCodePaymentStep

	useEffect(() => {
		if (authUuidParam) {
			setAuthUuid(authUuidParam)
			removeParams()
		}
	}, [authUuidParam]) //eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (authUuid) {
			dispatch(getClientMonitorPublicConfigThunk())
			dispatch(connectSocketClientMonitorIoThunk())
		}
	}, [authUuid])//eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (authUuid) {
			dispatch(getClientMonitorPublicCartStateThunk())
		}
	}, [authUuid])//eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (authUuid) {
			i18n.changeLanguage(clientMonitorLanguage)
		}
	}, [authUuid, clientMonitorLanguage])//eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (cartItems?.length || couponPayments?.length) {
			dispatch(showClientMonitorFinalStep(false))
		}
	}, [cartItems?.length, couponPayments?.length])//eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (socketReconnected) {
			// eslint-disable-next-line
			console.info('Load client monitor cart state by socket reconnect')
			dispatch(getClientMonitorPublicCartStateThunk())
		}
		return () => dispatch(setSocketReconnected(false))
	}, [socketReconnected])//eslint-disable-line react-hooks/exhaustive-deps

	const removeParams = () => {
		setSearchParams({})
	}

	return (
		<ClientMonitorWrap>
			{showStartStep && <StartStep />}
			{showCartStep && <CartStep />}
			{showQrCodePaymentStep && <QrCodePaymentStep />}
			{showFinalStep && <FinalStep />}
		</ClientMonitorWrap>
	)
})

SocketClientMonitorCart.displayName = 'SocketClientMonitorCart'
export default SocketClientMonitorCart
