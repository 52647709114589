import restaurantAdminInstance from '../../../adminInstance'
import restaurantInstance from '../../../instance'

const deleteTableOrderItem = (tableId, orderId, itemPositionId, accessToken, hasAdminModalLogin = true) => {
  return (hasAdminModalLogin ? restaurantAdminInstance : restaurantInstance)
    .delete(`/tables/${tableId}/orders/${orderId}/items/${itemPositionId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
}

export default deleteTableOrderItem
