import customerInstance from './instance'

const searchCustomers = (phoneNumber = '', customerNumber = '', name = '', email = '', companyName = '') => {
  return customerInstance.get('/customers?page=0&size='
    + (phoneNumber ? '&phoneNumber=' + phoneNumber : '')
    + (customerNumber ? '&customerNumber=' + customerNumber : '')
    + (name ? '&name=' + name : '')
    + (email ? '&email=' + email : '')
    + (companyName ? '&companyName=' + companyName : ''))
}

export default searchCustomers
