import React from 'react'
import { Split, SplitList, SplitListWrap } from '../index.styled'
import { useSelector } from 'react-redux'
import Currency from '../../../../../Elements/currency'
import RestaurantIcon from '../../../../../../img/svg/restaurant'

const SplitsList = ({ selectSplit, split }) => {
    const splits = useSelector(state => state.tableCart?.splits)

	return (
		<SplitListWrap>
			<SplitList>
				{splits?.map((splitItem, i) =>
					<Split key={i} onClick={() => selectSplit(splitItem)} className={(splitItem.id === split?.id) ? 'active' : null}>
						<div className="image"><RestaurantIcon icon="split"/></div>
						<div className="name">Splitt {i + 1}:</div>
						<div className="price"><Currency>{splitItem.totalPrice}</Currency></div>
					</Split>)}
			</SplitList>
		</SplitListWrap>
	)
}

SplitsList.displayName = 'SplitsList'
export default SplitsList
