import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonsModal from '../../modal/ButtonsModal'
import { useDispatch, useSelector } from 'react-redux'
import { ModalTerminalButtonSubTitle, ModalTerminalButtonTitle, ModalTerminalWrapper } from './index.styled'
import { PaymentCost, PaymentTitle, PaymentTitleWrapper } from '../index.styled'
import Currency from '../../currency'
import { setCartPaymentSelectedTerminal } from '../../../../redux/action/cartPayment'

const EVENT_Z_INDEX = 86

const ModalTerminalButtons = React.memo(({ isOpen, close, totalPrice, onTerminalSelected }) => {
	const { t } = useTranslation()
	const terminals = useSelector(store => store.cartPayment.terminals)

	const dispatch = useDispatch()

	const handleTerminalClick = useCallback((item) => {
		dispatch(setCartPaymentSelectedTerminal(item))
		onTerminalSelected(item)
	}, [dispatch, onTerminalSelected])

	const terminalButtons = useMemo(() => terminals.map((terminal, i) =>
		({
			icon: 'EC_TERMINAL', text: <div>
				<ModalTerminalButtonTitle>{terminal.name}</ModalTerminalButtonTitle>
				<ModalTerminalButtonSubTitle>SN: <span>{terminal.registrationCode}</span>
				</ModalTerminalButtonSubTitle>
			</div>, onClick: () => handleTerminalClick(terminal), show: true,
		})), [terminals, handleTerminalClick])

	const onClose = () => {
		close()
	}

	return (
		<ModalTerminalWrapper>
			<ButtonsModal id="cart-terminals"
										isOpen={isOpen}
										close={onClose}
										size="small"
										title={t('Modal.payment.title')}
										zIndex={EVENT_Z_INDEX}
										buttons={terminalButtons}>
				<PaymentTitleWrapper>
					<PaymentTitle>{t('Cart.titlePrice')}: </PaymentTitle>
					<PaymentCost><Currency>{totalPrice}</Currency></PaymentCost>
				</PaymentTitleWrapper>
			</ButtonsModal>
		</ModalTerminalWrapper>
	)
})

ModalTerminalButtons.displayName = 'ModalTerminal'
export default ModalTerminalButtons
