export const SET_CART_PRODUCT_PRODUCTS = 'SET_CART_PRODUCT_PRODUCTS'
export const APPEND_CART_PRODUCT_PRODUCTS = 'APPEND_CART_PRODUCT_PRODUCTS'
export const SET_CART_PRODUCT_PRODUCTS_TOTAL = 'SET_CART_PRODUCT_PRODUCTS_TOTAL'
export const SET_CART_PRODUCT_ACTIVE_PRODUCT_INDEX = 'SET_CART_PRODUCT_ACTIVE_PRODUCT_INDEX'
export const SET_CART_PRODUCT_LOADING = 'SET_CART_PRODUCT_LOADING'
export const SET_CART_PRODUCT_PRODUCT_FOCUS = 'SET_CART_PRODUCT_PRODUCT_FOCUS'
export const SET_CART_PRODUCT_ITEM_FOCUS = 'SET_CART_PRODUCT_ITEM_FOCUS'
export const SET_CART_PRODUCT_TOPPINGS = 'SET_CART_PRODUCT_TOPPINGS'
export const APPEND_CART_PRODUCT_TOPPINGS = 'APPEND_CART_PRODUCT_TOPPINGS'
export const SET_CART_PRODUCT_TOPPINGS_TOTAL = 'SET_CART_PRODUCT_TOPPINGS_TOTAL'
export const SET_CART_PRODUCT_MUST_TOPPINGS = 'SET_CART_PRODUCT_MUST_TOPPINGS'
export const APPEND_CART_PRODUCT_MUST_TOPPINGS = 'APPEND_CART_PRODUCT_MUST_TOPPINGS'
export const SET_CART_PRODUCT_MUST_TOPPINGS_TOTAL = 'SET_CART_PRODUCT_MUST_TOPPINGS_TOTAL'
export const SET_CART_PRODUCT_ENABLE_QUANTITY = 'SET_CART_PRODUCT_ENABLE_QUANTITY'
export const SET_CART_PRODUCT_MENU = 'SET_CART_PRODUCT_MENU'
export const SET_CART_PRODUCT_MENU_PRODUCT_INDEX = 'SET_CART_PRODUCT_MENU_PRODUCT_INDEX'
export const SET_CART_PRODUCT_SEARCH_TEXT = 'SET_CART_PRODUCT_SEARCH_TEXT'
export const SET_CART_PRODUCT_TOPPING_GROUPS = 'SET_CART_PRODUCT_TOPPING_GROUPS'
