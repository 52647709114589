import { getNeededDateFormat } from '../../../../../helper'
import React from 'react'
import { TableTdGrid } from '../../style/table/td/index.styled'

const OrderDate = React.memo(({ item }) => {
	return (
		<TableTdGrid>
			{item.orderDate && <div>{getNeededDateFormat(item.orderDate, 'HH:mm')}</div>}
		</TableTdGrid>
	)
})

OrderDate.displayName = 'OrderDate'
export default OrderDate
