import styled from 'styled-components'
import { colorTheme } from '../../../../style/theme/colorTheme'
import { TableData } from '../SVGTables/index.styled'

export const RestBarCounter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: relative;
	height: ${props => props.height}px;
	width: ${props => props.width}px;
	box-shadow: 0 0 0 .5em ${colorTheme.restaurantSettings.table.green}, /* Green border */ 0 0 0 .68em ${colorTheme.restaurantSettings.table.backgroundWhite}; /* White border */
	background-color: ${colorTheme.restaurantSettings.table.background};
	border-radius: 0.8em;
	margin-left: .63em;

	&.gray {
		box-shadow: 0 0 0 .5em ${colorTheme.restaurantSettings.table.gray},
		0 0 0 .68em ${colorTheme.restaurantSettings.table.backgroundWhite};
	}

	&.red {
		box-shadow: 0 0 0 .5em ${colorTheme.restaurantSettings.table.red},
		0 0 0 .68em ${colorTheme.restaurantSettings.table.backgroundWhite};
	}

	&.sizeWithEm {
		height: ${props => props.height}em;
		width: ${props => props.width}em
	}

	${TableData} {
		height: inherit;

		&.vertical {
			height: auto;
		}
	}
`
RestBarCounter.displayName = 'RestBarCounter'
