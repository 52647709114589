import { RE_INITIALIZE_PRODUCT_FILTER, SET_PRODUCT_FILTER } from '../../actionTypes/productFilter'

const initialState = {
  searchText: '',
  productType: null,
  categoryId: null,
}

export function productFilterReducer (state = initialState, action) {
  switch (action.type) {
		case RE_INITIALIZE_PRODUCT_FILTER:
      return initialState
    case SET_PRODUCT_FILTER:
      return {
        ...state,
        ...action.value,
      }
    default:
      return state
  }
}
