import { retrieveErrorFromApi } from '../../../helper'
import { setAppErrors } from '../../action/system'
import { appendInvoices, setInvoices, setInvoicesTotalLength, setSelectedInvoice, setUnpaidInvoicesCounter } from '../../action/invoice'
import fetchInvoices from '../../axios/invoice'
import getInvoice from '../../axios/invoice/get'
import updateInvoiceStatus from '../../axios/invoice/status'
import updateInvoiceCustomer from '../../axios/invoice/customer'
import sendInvoice from '../../axios/invoice/send'

export const getInvoicesThunk = (page, size) => dispatch => {
	return fetchInvoices(page, size)
		.then(res => {
			dispatch(setInvoicesTotalLength(res.data.page.totalElements))
			if (page > 0 && size > 0) {
				dispatch(appendInvoices(res.data.page.content))
				return
			}
			dispatch(setInvoices(res.data.page.content))
			dispatch(setUnpaidInvoicesCounter(res.data.totalUnpaid))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getInvoiceThunk = (invoiceUuid) => async (dispatch) => {
	try {
		const res = await getInvoice(invoiceUuid)
		dispatch(setSelectedInvoice(res.data))
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const updateInvoiceStatusThunk = (invoiceUuid, markAsPaid) => dispatch => {
	return updateInvoiceStatus(invoiceUuid, markAsPaid)
		.then(res => {
			return dispatch(getInvoicesThunk(0, 10))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateInvoiceCustomerThunk = (invoiceUuid, data) => async (dispatch) => {
	try {
		await updateInvoiceCustomer(invoiceUuid, data)
		return dispatch(getInvoiceThunk(invoiceUuid))
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const sendInvoiceEmailThunk = (invoiceUuid) => dispatch => {
	return sendInvoice(invoiceUuid)
		.then(res => {
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
