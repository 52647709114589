import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const TakeawayRestaurant = lazyWithRetry(() => import(/* webpackChunkName: "takeaway-restaurant" */'../../component/TakeawayRestaurant'))

const TakeawayRestaurantPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<TakeawayRestaurant />
		</Suspense>
	)
})

TakeawayRestaurantPage.displayName = 'TakeawayRestaurantPage'
export default TakeawayRestaurantPage
