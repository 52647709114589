import React from 'react'
import { HeaderTablesColorArea, HeaderTitleText, HeaderTitleWrapper } from './index.styled'

const HeaderTitle = React.memo(({ color, title }) => {
	return (
		<HeaderTitleWrapper>
			<HeaderTablesColorArea color={color} />
			<HeaderTitleText>{title}</HeaderTitleText>
		</HeaderTitleWrapper>
	)
})
HeaderTitle.displayName = 'HeaderTitle'
export default HeaderTitle
