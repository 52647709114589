export const RE_INITIALIZE_MAP = 'RE_INITIALIZE_MAP'
export const SET_MAP_MARKERS = 'SET_MAP_MARKERS'
export const ADD_MAP_MARKERS = 'ADD_MAP_MARKERS'
export const SET_MAP_MARKERS_TOTAL_LENGTH = 'SET_MAP_MARKERS_TOTAL_LENGTH'
export const ADD_MAP_MARKER = 'ADD_MAP_MARKER'
export const UPDATE_MAP_MARKER = 'UPDATE_MAP_MARKER'
export const REMOVE_MAP_MARKER = 'REMOVE_MAP_MARKER'
export const SET_MAP_POSITIONS = 'SET_MAP_POSITIONS'
export const UPDATE_MAP_POSITION = 'UPDATE_MAP_POSITION'
export const SET_MAP_COORDINATES_MARKER = 'SET_MAP_COORDINATES_MARKER'
export const SET_MAP_ORDER_ID_OF_MARKER = 'SET_MAP_ORDER_ID_OF_MARKER'
export const SET_MAP_MARKERS_STATUS = 'SET_MAP_MARKERS_STATUS'
export const SET_MAP_WIDTH = 'SET_MAP_WIDTH'
export const SET_MAP_HEIGHT = 'SET_MAP_HEIGHT'
export const SET_MAP_WIDTH_MENU = 'SET_MAP_WIDTH_MENU'
export const SET_MAP_HEIGHT_HEADER = 'SET_MAP_HEIGHT_HEADER'
export const SET_MAP_SELECTED_WOLT_DRIVER_DELIVERY = 'SET_MAP_SELECTED_WOLT_DRIVER_DELIVERY'
export const UPDATE_MAP_SELECTED_WOLT_DRIVER_DELIVERY = 'UPDATE_MAP_SELECTED_WOLT_DRIVER_DELIVERY'
export const SET_MAP_WOLT_DRIVER_DELIVERIES = 'SET_MAP_WOLT_DRIVER_DELIVERIES'
export const UPDATE_MAP_WOLT_DRIVER_DELIVERIES = 'UPDATE_MAP_WOLT_DRIVER_DELIVERIES'
