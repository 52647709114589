import React, { Suspense } from 'react'
import { lazyWithRetry } from '../../helper/lazyWithRetry'
import PageLoader from '../../component/Elements/pageLoader'

const CashBookSettings = lazyWithRetry(() => import(/* webpackChunkName: "cash-book-settings" */'../../component/Settings/CashBookSettings'))

const CashBookSettingsPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<CashBookSettings />
		</Suspense>
	)
})

CashBookSettingsPage.displayName = 'CashBookSettingsPage'
export default CashBookSettingsPage
