import { createSelector } from 'reselect'
import { isDisabledTerminalTips } from './helper'

export const selectSelectedTerminal = store => store.cartPayment.selectedTerminal

export const selectDisabledTerminalTips = (orderType) => createSelector(
	selectSelectedTerminal,
	(selectedTerminal) => {
		return isDisabledTerminalTips(selectedTerminal, orderType)
	}
)
