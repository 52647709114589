import { SET_BUSINESS_SCOPE_KASSE_DISCOUNTS, SET_BUSINESS_SCOPE_SHOP_DISCOUNTS, SET_BUSINESS_SCOPE_USE_SHOP_DISCOUNT, SET_BUSINESS_SCOPE_LAST_SAVED_DISCOUNT, SET_BUSINESS_SCOPE_CURRENT_CHANGED_DISCOUNT } from '../../actionTypes/businessScopeDiscount'

const initialState = {
	kasseDiscounts: [],
	shopDiscounts: [],
	useShopDiscount: false,
	lastSavedDiscount: null,
	currentChangedDiscount: null
}

export function businessScopeDiscountReducer(state = initialState, action) {
	switch (action.type) {
		case SET_BUSINESS_SCOPE_KASSE_DISCOUNTS:
			return {
				...state,
				kasseDiscounts: action.value,
			}
		case SET_BUSINESS_SCOPE_SHOP_DISCOUNTS:
			return {
				...state,
				shopDiscounts: action.value,
			}
		case SET_BUSINESS_SCOPE_USE_SHOP_DISCOUNT:
			return {
				...state,
				useShopDiscount: action.value,
			}
		case SET_BUSINESS_SCOPE_LAST_SAVED_DISCOUNT:
			return {
				...state,
				lastSavedDiscount: action.value,
			}
		case SET_BUSINESS_SCOPE_CURRENT_CHANGED_DISCOUNT:
			return {
				...state,
				currentChangedDiscount: action.value,
			}
		default:
			return state
	}
}
