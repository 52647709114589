export const SET_REGISTRATION_COMPLETE = 'SET_REGISTRATION_COMPLETE'
export const SET_LOGIN_DATA = 'SET_LOGIN_DATA'
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING'
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS'
export const SET_RECOVERY_COMPLETE = 'SET_RECOVERY_COMPLETE'
export const SET_COMPANY_REGISTERED = 'SET_COMPANY_REGISTERED'
export const SET_COMPANY_REGISTERED_LOADING = 'SET_COMPANY_REGISTERED_LOADING'
export const SET_LOGIN_USER = 'SET_LOGIN_USER'
export const SET_LOGIN_EXCHANGE_RATES = 'SET_LOGIN_EXCHANGE_RATES'
export const SET_LOGOUT = 'SET_LOGOUT'
export const SET_LOGIN_FAILED = 'SET_LOGIN_FAILED'
export const SET_LOGIN_INVALID_PASSWORD_ERROR = 'SET_LOGIN_INVALID_PASSWORD_ERROR'
export const SET_LOGIN_INVALID_INDEX_ERROR = 'SET_LOGIN_INVALID_INDEX_ERROR'
export const ADD_NEW_ORDER = 'ADD_NEW_ORDER'
export const REMOVE_ORDER = 'REMOVE_ORDER'
export const SET_CANCEL_REASON = 'SET_CANCEL_REASON'
export const SET_ORDER_EVENT = 'SET_ORDER_EVENT'
export const SET_REGISTRATION_OWNER_EMAIL = 'SET_REGISTRATION_OWNER_EMAIL'
export const SET_REGISTRATION_LOADING = 'SET_REGISTRATION_LOADING'
export const SET_REACT_APP_VERSION = 'SET_REACT_APP_VERSION'
export const SET_PAGE_AFTER_LOGIN = 'SET_PAGE_AFTER_LOGIN'
export const SET_REGISTRATION_OWNER_PHONE_NUMBER = 'SET_REGISTRATION_OWNER_PHONE_NUMBER'
