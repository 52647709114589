import axios from 'axios'
import requestInterceptor from '../../requestInterceptor'
import responseInterceptor from '../../responseInterceptor'
import { woltPluginUrl } from '../../../../urls'
import { getLocalStorageAccessToken } from '../../../../helper/clientMonitor'

const headers = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
}
const accessToken = getLocalStorageAccessToken()

if (accessToken) {
  headers.Authorization = `Bearer ${accessToken}`
}

const woltInstance = axios.create({
  baseURL: woltPluginUrl,
  headers,
})

woltInstance.interceptors.request.use(requestInterceptor, error => Promise.reject(error))

woltInstance.interceptors.response.use(response => response, error => {
  return responseInterceptor(error)
})

export default woltInstance
