import React, { useCallback, useRef } from 'react'
import Keyboard from './keyboard'
import MainIcon from '../../../../../../img/svg/main'
import { CloseButtonWrap, RemarkInputWrap, RemarkWithKeyboardContent, RemarkWithKeyboardWrap } from './index.styled'
import { useWindowBreakPoint } from '../../../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../../../style/theme'
import { InputRemark } from '../index.styled'
import Button from '../../../../buttons/Button'
import { useTranslation } from 'react-i18next'
import ProductQuantity from '../ProductQuantity'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { useOutsideClick } from '../../../../../../hooks/useOutsideClick'

const DURATION = 100

const RemarkWithKeyboard = ({ isOpen, close, onChangeRemarkInput, remarkText, itemFocus, setItemFocus, addToCart }) => {
	const enableQuantity = useSelector(state => state.cartProduct.enableQuantity)
	const menu = useSelector(state => state.cartProduct.menu)
	const isTablet = useWindowBreakPoint(theme.point820)
	const { t } = useTranslation()
	const nodeRef = useRef(null)

	const handleKeyboardType = useCallback((textCallbackKey) => {
		onChangeRemarkInput(textCallbackKey)
	}, [onChangeRemarkInput])

	const handleRemarkClick = useCallback(e => {
		e.stopPropagation()
	}, [])

	const handleAccept = useCallback(() => {
		close()
		addToCart()
	}, [close, addToCart])

	useOutsideClick(nodeRef, close)

	return (
		<CSSTransition in={isOpen}
									 timeout={DURATION}
									 classNames="modal"
									 unmountOnExit
									 nodeRef={nodeRef}>
			<RemarkWithKeyboardWrap ref={nodeRef} onClick={close} data-testid="remark-with-keyboard">
				<RemarkWithKeyboardContent>
					{!isTablet && <CloseButtonWrap onClick={close}>
						<MainIcon icon="cross" testId="close" />
					</CloseButtonWrap>}
					<RemarkInputWrap onClick={handleRemarkClick}>
						{enableQuantity && itemFocus && !menu &&
							<ProductQuantity focus={false} item={{ ...itemFocus, name: t('Cart.count') }} size="big" color="gray" type="mainDish"
															 onClickPlus={() => setItemFocus({ ...itemFocus, quantity: itemFocus.quantity + 1 })}
															 onClickMinus={() => itemFocus.quantity > 1 && setItemFocus({ ...itemFocus, quantity: itemFocus.quantity - 1 })} />}
						<InputRemark>
							<MainIcon icon="remark" />
							<input name="remarkInput"
										 onChange={(e) => onChangeRemarkInput(null, e)}
										 value={remarkText}
										 placeholder={isTablet ? t('Modal.remark_input_short') : t('Modal.remark_input')}
										 autoComplete="off"
										 role="presentation"
										 inputMode="none" />
						</InputRemark>
						{!isTablet && <Button name="save-remark" icon="checkmark" text={t('Cart.Buttons.buttonAdd')} keyButton="F2" color="green" className="add-remark"
																	onClick={handleAccept} disabled={!itemFocus} />}
					</RemarkInputWrap>
					<Keyboard currentValue={remarkText}
										handleType={handleKeyboardType}
										enter={close} />
				</RemarkWithKeyboardContent>
			</RemarkWithKeyboardWrap>
		</CSSTransition>
	)
}

RemarkWithKeyboard.displayName = 'RemarkWithKeyboard'
export default RemarkWithKeyboard
