import { PlatformWrapper } from './index.styled'
import PlatformIcon from '../../../../../img/svg/platforms'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCompanyData } from '../../../../../redux/selector/system'
import { useTheme } from 'styled-components'

const PlatformType = React.memo(({ item }) => {
	const countryCode = useSelector(selectCompanyData('countryCode'))
	const { colorTheme } = useTheme()
	const isCanceled = item.status?.status === 'CANCELED'
	const isDisabled = isCanceled || !!item.zNumber
	const lieferandoIcon = countryCode === 'CHE' ? 'lieferando-che' : 'lieferando-de'
	const platformIcon = item?.platformMetaData?.platformType ?
		(item?.platformMetaData?.platformType === 'TAKEAWAY_PLUGIN' ? lieferandoIcon : item?.platformMetaData?.platformType) :
		(item?.restaurantMetaData ? 'restaurant' : item?.kioskMetaData ? 'kiosk' : 'phone-order')

	return (
		item.platformMetaData?.platformType === 'API_PLUGIN' ?
			item.platformMetaData?.platformName :
			<PlatformWrapper className={isDisabled && 'disabled'}>
				<PlatformIcon icon={`${platformIcon}-${colorTheme}`} />
			</PlatformWrapper>
	)
})

PlatformType.displayName = 'PlatformType'
export default PlatformType
