import React from 'react'
import { ReactComponent as PaypalSvg } from './assets/paypal.svg'
import { ReactComponent as AmericanExpressSvg } from './assets/american_express.svg'
import { ReactComponent as ApplePaySvg } from './assets/apple_pay.svg'
import { ReactComponent as GiropaySvg } from './assets/giropay.svg'
import { ReactComponent as GooglePaySvg } from './assets/google_pay.svg'
import { ReactComponent as KlarnaSvg } from './assets/klarna.svg'
import { ReactComponent as MastercardSvg } from './assets/mastercard.svg'
import { ReactComponent as SofortSvg } from './assets/sofort.svg'
import { ReactComponent as VisaSvg } from './assets/visa.svg'

const OrderAndPayPaymentIcon = React.memo(({ icon, height = 20, width = 20, fill, stroke }) => {
	const svgProps = { height, width, fill, stroke, className: icon }

	return (
		<>
			{icon === 'PAYPAL' && <PaypalSvg {...svgProps} />}
			{icon === 'MASTERCARD' && <MastercardSvg {...svgProps} />}
			{icon === 'VISA' && <VisaSvg {...svgProps} />}
			{icon === 'APPLE_PAY' && <ApplePaySvg {...svgProps} />}
			{icon === 'GOOGLE_PAY' && <GooglePaySvg {...svgProps} />}
			{icon === 'KLARNA' && <KlarnaSvg {...svgProps} />}
			{icon === 'AMERICAN_EXPRESS' && <AmericanExpressSvg {...svgProps} />}
			{icon === 'GIROPAY' && <GiropaySvg {...svgProps} />}
			{icon === 'SOFORT' && <SofortSvg {...svgProps} />}
		</>
	)
})

OrderAndPayPaymentIcon.displayName = 'OrderAndPayPaymentIcon'
export default OrderAndPayPaymentIcon
