import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Areas from '../../index'
import { setRestaurantOrdersMove, setRestaurantSwapModal } from '../../../../../redux/action/restaurant'
import colors from '../../../../../style/colors'
import { fetchRestaurantTablesThunk } from '../../../../../redux/thunk/restaurant/table'
import Loader from '../../../../Elements/loaders'
import { ButtonWrap, ModalTableSwapWrapper, SwapModalAreas, SwapModalAreasContent, SwapModalAreasWrap, SwapTables, SwapTablesScroll, SwapTablesWrapper } from './index.styled'
import SwapTable from './SwapTable'
import SwapTableNew from './SwapTableNew'
import { theme } from '../../../../../style/theme'
import { useWindowBreakPoint } from '../../../../../hooks/useWindowBreakPoint'
import { RestWall, RestWrapper } from '../index.styled'
import { fillTables } from '../../../../../helper/restaurant'
import CustomFullScreenModal from '../../../../Elements/modal/CustomFullScreenModal'
import HeaderTitle from '../../../../RestaurantSchema/HeaderTitle'
import Button from '../../../../Elements/buttons/Button'
import { useTranslation } from 'react-i18next'
import { RestTablesColorArea } from '../../../../RestaurantSchema/index.styled'

const ModalTableSwap = () => {
	const { t } = useTranslation()
	const { tables, tablesLoading, areas, areaId, table: selectedTable, showSwapModal } = useSelector(state => state.restaurant)
	const dispatch = useDispatch()
	const isMobile = useWindowBreakPoint(theme.point720)

	const areaColor = areas?.find(area => area.id === areaId)?.meta?.color

	useEffect(() => {
		if (areaId && showSwapModal && selectedTable) {
			dispatch(fetchRestaurantTablesThunk(areaId))
		}
	}, [areaId, showSwapModal]) //eslint-disable-line react-hooks/exhaustive-deps

	const closeModal = () => {
		dispatch(setRestaurantOrdersMove([]))
		dispatch(setRestaurantSwapModal(false))
	}

	let restTable = []
	if (!isMobile && tables && tables.length) {
		restTable = fillTables(tables)
	}

	const area = areas?.filter(a => a.id === areaId)
	let walls = []
	let floor = {}
	if (!isMobile && area && area.length) {
		walls = area[0]?.walls || []
		floor = area[0]?.floor || {}
	}

	const SwapTablesComp = (
		<SwapTablesWrapper color={areaColor}
											 colorFloor={floor?.meta?.color}
											 bgdImg={floor?.meta?.color !== '' ? '' : `/img/floors/${floor?.meta?.backgroundImage}.webp`}
											 id="restaurant-table-swap-list">
			<SwapTablesScroll>
				<SwapTables>
					{!tablesLoading ?
						isMobile ?
							(tables?.map((table, index) =>
								<SwapTable key={index} index={index} table={table} />))
							:
							(restTable?.map((table, index) =>
								<SwapTableNew key={index} index={index} table={table} />,
							))
						: <div className="loader"><Loader color={colors.white} /></div>}
					{!isMobile ? (walls.map((wall, i) =>
						<RestWrapper key={i} {...wall.meta} >
							<RestWall id={`wall_${i}`} data-testid={`wall_${i}`} {...wall.meta} />
						</RestWrapper>,
					)) : null
					}
				</SwapTables>
			</SwapTablesScroll>
		</SwapTablesWrapper>
	)

	return (
		<CustomFullScreenModal isOpen={!!(showSwapModal && selectedTable)}
													 customHeaderTitle={<HeaderTitle color={areaColor} title={(`Tisch #${selectedTable?.number} Wechseln`)} />}
													 fullSizeBody={true}
													 bodyClassName="lightGray"
													 close={closeModal}
													 button={
														 <ButtonWrap>
															 <Button name="cancel" icon="clear" text={t('buttons.controls.cancel_1')} keyButton="F3" onClick={closeModal} className="back" />
														 </ButtonWrap>
													 }>

			{isMobile ?
				<SwapModalAreas id="swap-area-list">
					<Areas swapMode={true}>
						{isMobile && SwapTablesComp}
					</Areas>
				</SwapModalAreas> :
				<ModalTableSwapWrapper>
					{SwapTablesComp}
					<RestTablesColorArea color={areaColor} className="br-left" />
					<SwapModalAreasWrap id="swap-area-list">
						<SwapTablesScroll>
							<SwapModalAreasContent>
								<Areas swapMode={true} />
							</SwapModalAreasContent>
						</SwapTablesScroll>
					</SwapModalAreasWrap>
				</ModalTableSwapWrapper>}
		</CustomFullScreenModal>
	)
}
ModalTableSwap.displayName = 'ModalTableSwap'
export default ModalTableSwap
