import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setAppModal } from '../../../../redux/action/system'
import { ModalContent, OrderInfo, OrderInfoBlock, OrderInfoItem } from './../index.styled'
import { getNeededDateFormat } from '../../../../helper'
import { denyOrderThunk } from '../../../../redux/thunk/order/addOrderProcessingTime'
import Currency from '../../../Elements/currency'
import colors from '../../../../style/colors'
import { orderTypeOptionsStatic } from '../../../../redux/reducer/order/constants'
import CustomModal from '../../../Elements/modal/CustomModal'
import MainIcon from '../../../../img/svg/main'
import PlatformIcon from '../../../../img/svg/platforms'
import DenyStatusButtons from '../statusButtons'
import { denyExpressOrderThunk } from '../../../../redux/thunk/order/confirmExpressOrder'
import KeyBindings from '../../../Elements/keybindings'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { denyStatusButtons } from '../constants'
import { useTheme } from 'styled-components'

const EVENT_Z_INDEX = 986

const DenyOrderModal = React.memo(() => {
	const { t } = useTranslation()
	const { colorTheme } = useTheme()
	const denyOrderModalShow = useSelector(store => store.system.modal?.denyOrderModalShow)
	const order = useSelector(store => store.system.modalData?.denyOrderModalData[0])
	const isProcessingDenyOrder = useSelector(store => store.order.isProcessingDenyOrder)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const dispatch = useDispatch()
	const [denyActiveButtonIndex, setDenyActiveButtonIndex] = useState(null)
	const staticDenyStatusButtonsCount = denyStatusButtons.length
	const geoData = order?.customer?.geoData

	const handleClose = useCallback(() => {
		dispatch(setAppModal({ denyOrderModalShow: false }))
		setDenyActiveButtonIndex(null)
		if (order?.expressDelivery || order?.expectedPickupTime)
			dispatch(setAppModal({ confirmExpressOrderModalShow: true }))
		else
			dispatch(setAppModal({ processingTimeOrderModalShow: true }))
	}, [order, dispatch])

	const handleDeny = useCallback((status) => {
		if (order?.expressDelivery || order?.expectedPickupTime)
			dispatch(denyExpressOrderThunk(order?.orderId, status))
		else
			dispatch(denyOrderThunk(order?.orderId, status))
		dispatch(setAppModal({ denyOrderModalShow: false }))
	}, [order, dispatch])

	const handleArrowRight = useCallback(() => {
		if (denyActiveButtonIndex === null || (denyActiveButtonIndex + 1 >= staticDenyStatusButtonsCount)) {
			setDenyActiveButtonIndex(0)
		} else {
			setDenyActiveButtonIndex(prevIndex => prevIndex + 1)
		}
	}, [denyActiveButtonIndex, staticDenyStatusButtonsCount])

	const handleArrowLeft = useCallback(() => {
		if (denyActiveButtonIndex === null || (denyActiveButtonIndex - 1 < 0)) {
			setDenyActiveButtonIndex(staticDenyStatusButtonsCount - 1)
		} else {
			setDenyActiveButtonIndex(prevIndex => prevIndex - 1)
		}
	}, [denyActiveButtonIndex, staticDenyStatusButtonsCount])

	const handleEnter = useCallback(() => {
		handleDeny(denyStatusButtons[denyActiveButtonIndex].status)
	}, [denyActiveButtonIndex, handleDeny])

	return (
		<>
			<CustomModal isOpen={denyOrderModalShow}
									 close={handleClose}
									 id="deny-order-modal"
									 enableOutsideClick={false}
									 size="auto"
									 titleColor="red"
									 zIndex={EVENT_Z_INDEX}
									 title={`${t('Modal.deny_order_modal.order')} (${t(orderTypeOptionsStatic.find(t => t.value === order?.orderType)?.label)})`}>
				<ModalContent>
					<OrderInfo>
						<OrderInfoBlock>
							<OrderInfoItem>
								<MainIcon icon={order?.paymentMethod} fill={colors.gray} />
								<p data-testid="price"><Currency>{order?.totalPrice}</Currency></p>
							</OrderInfoItem>
						</OrderInfoBlock>
						<OrderInfoBlock>
							<OrderInfoItem>
								<MainIcon icon="restaurant-name" fill={colors.gray} />
								<PlatformIcon icon={`${order?.platformMetaData?.platformType}-${colorTheme}`} />
							</OrderInfoItem>
						</OrderInfoBlock>
						<OrderInfoBlock className="col">
							<OrderInfoItem className="small">
								<MainIcon icon="time" fill={colors.gray} />
								<p data-testid="time">{getNeededDateFormat(order?.orderDate, 'HH:mm') + ' UHR'}</p>
							</OrderInfoItem>
							<OrderInfoItem className="small">
								<MainIcon icon="date" fill={colors.gray} />
								<p>{getNeededDateFormat(order?.orderDate)}</p>
							</OrderInfoItem>
							{geoData &&
								<OrderInfoItem className="small">
									<MainIcon icon="route" fill={colors.gray} />
									<p data-testid="address">{order?.customer?.street} {order?.customer?.streetNumber}, {order?.customer?.postalCode}</p>
									<p className={geoData?.distance > 0 ? '' : 'hidden'} data-testid="distance">{(geoData?.distance / 1000).toFixed(2)} km</p>
								</OrderInfoItem>}
							{geoData?.duration > 0 &&
								<OrderInfoItem className="small">
									<MainIcon icon="time-left" fill={colors.gray} />
									<p data-testid="duration">{Math.round(geoData?.duration / 60)} min</p>
								</OrderInfoItem>}
						</OrderInfoBlock>
					</OrderInfo>
					<DenyStatusButtons handleDeny={handleDeny} activeButtonIndex={denyActiveButtonIndex} disabled={isProcessingDenyOrder} />
				</ModalContent>
			</CustomModal>

			{(isNeededZIndex && !isProcessingDenyOrder) &&
				<KeyBindings enter={handleEnter} arrowRight={handleArrowRight} arrowLeft={handleArrowLeft} />}
		</>
	)
})

DenyOrderModal.displayName = 'DenyOrderModal'
export default DenyOrderModal
