import { SET_SHOP_SEO_META_DATA, SET_SHOP_SEO_KEYWORDS, ADD_SHOP_SEO_KEYWORD, REMOVE_SHOP_SEO_KEYWORD, SET_SHOP_SEO_FACEBOOK_PIXEL_ID } from '../../actionTypes/shopSeoData'

const initialState = {
	seoMetaData: null,
	keywords: [],
	facebookPixelId: null
}

export function shopSeoDataReducer (state = initialState, action) {
	switch (action.type) {
		case SET_SHOP_SEO_META_DATA:
			return {
				...state,
				seoMetaData: action.value,
			}
		case SET_SHOP_SEO_KEYWORDS:
			return {
				...state,
				keywords: action.value,
			}
		case ADD_SHOP_SEO_KEYWORD:
			return {
				...state,
				keywords: [action.value, ...state.keywords]
			}
		case REMOVE_SHOP_SEO_KEYWORD:
			const filteredKeywords = state.keywords?.filter(word => word.id !== action.value)
			return {
				...state,
				keywords: filteredKeywords,
			}
		case SET_SHOP_SEO_FACEBOOK_PIXEL_ID:
			return {
				...state,
				facebookPixelId: action.value,
			}
		default:
			return state
	}
}
