import React from 'react'
import { RadioContainer, RadioLabel, RadioWrapper } from '../index.styled'

const RadioInputControlled = React.memo(({ id, label, name, checked, onChange, className, testId , disabled}) => {
	const layout = (className ? `${className}` : '') + (checked ? ' active ' : '') + (disabled ? ' disabled ' : '')

	return (
		<RadioWrapper id={id} className={className}>
			<input name={name}
						 checked={!!checked}
						 onChange={onChange}
						 type="checkbox"
						 data-testid={testId}
						 disabled={disabled} />
			<RadioContainer checked={checked} className={layout}>
			</RadioContainer>
			<RadioLabel checked={checked} className={layout}>{label}</RadioLabel>
		</RadioWrapper>
	)
})

RadioInputControlled.displayName = 'RadioInputControlled'
export default RadioInputControlled
