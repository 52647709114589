import React, { Suspense } from 'react'
import PageLoader from '../../component/Elements/pageLoader'
import { lazyWithRetry } from '../../helper/lazyWithRetry'

const User = lazyWithRetry(() => import(/* webpackChunkName: "user" */'../../component/User'))

const UserPage = React.memo(() => {
	return (
		<Suspense fallback={<PageLoader />}>
			<User />
		</Suspense>
	)
})

UserPage.displayName = 'UserPage'
export default UserPage
