import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const ModalWrapper = styled.div`
	font-size: 1rem;
	display: grid;
	grid-template-columns: 1fr 10em;
	grid-gap: 0.63em;
	margin-bottom: 0.63em;

	&.full-width {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: ${theme.point720}) {
	grid-template-columns: auto;
}
`
ModalWrapper.displayName = 'ModalWrapper'

export const ModalContent = styled.div`
	display: grid;
	width: 51.88em;
	align-items: start;
	gap: 0.63em;
	padding-right: 0.31em;
	max-height: calc(100vh - 20em);
	overflow-y: auto;

	@media screen and (max-width: ${theme.point820}) {
		width: 100%;
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 100%;
		max-height: 100%;
	}
`

ModalContent.displayName = 'ModalContent'

export const OrderInfoBlock = styled.div`
	border-radius: 0.75em;
	border: 1px solid ${colorTheme.platformDriversModal.border};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.25em;
	height: 7.5em;

	> .WOLT_PLUGIN-LIGHT, .WOLT_PLUGIN-DARK {
		width: 5.19em;
		height: 1.88em;
	}

	> .TAKEAWAY_PLUGIN-LIGHT, .TAKEAWAY_PLUGIN-DARK {
		width: 8.75em;
		height: 2em;
	}

	> .FOODORA_PLUGIN-LIGHT, .FOODORA_PLUGIN-DARK {
		width: 10.44em;
		height: 1.88em;
	}

	&.with-image {
		display: grid;
		position: relative;
	}

	@media screen and (max-width: ${theme.point820}) {
		gap: 0.63em;
		padding: 0.63em;

		&.with-image {
			gap: 1.43em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.with-image {
			gap: 1.25em;
		}
	}
`
OrderInfoBlock.displayName = 'OrderInfoBlock'

export const OrderInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.63em;

	${OrderInfoBlock}:first-child {
		grid-column: 1 / 3;
	}

	@media screen and (max-width: ${theme.point720}) {
    grid-template-columns: 1fr;

		${OrderInfoBlock}:first-child {
			grid-column: 1;
		}
  }
`

OrderInfo.displayName = 'OrderInfo'

export const OrderInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.31em;

  > svg {
    width: 2em;
    height: 2em;
    flex-shrink: 0;
		fill: ${colorTheme.platformDriversModal.icon.gray};
  }

  > svg.clock_on_3, svg.date {
    width: 1.63em;
    height: 1.63em;
		fill: ${colorTheme.platformDriversModal.icon.blue};

		&.big {
			width: 2em;
			height: 2em;
		}

		&.dark {
			fill: ${colorTheme.platformDriversModal.icon.darkBlue};
		}
  }

	@media screen and (max-width: ${theme.point820}) {
		&.justify-end {
			align-self: flex-end;
		}
	}
`
OrderInfoItem.displayName = 'OrderInfoItem'

export const OrderTimeBlock = styled.div`
	display: flex;
	gap: 1.88em;

	&.gap-m {
		gap: 1.25em;
	}

	&.gap-sm {
		gap: 0.94em;
	}

	@media screen and (max-width: ${theme.point820}) {
    flex-direction: column;
		align-items: start;
		gap: 0.63em;
		grid-column: 2;
		grid-row: 1;
  }
`
OrderTimeBlock.displayName = 'OrderTimeBlock'

export const CourierIcon = styled.div`
	 position: absolute;
	 left: 1.25em;
	 width: 6.63em;
	 height: 5.25em;

		> svg {
			width: 100%;
			height: 100%;
		}

		@media screen and (max-width: ${theme.point820}) {
			position: static;
			grid-column: 1;
		}
`
CourierIcon.displayName = 'CourierIcon'

export const CarIcon = styled.div`
	 position: absolute;
	 left: 1.88em;
	 width: 5.18em;
	 height: 5.25em;

		> svg {
			width: 100%;
			height: 100%;
		}

		@media screen and (max-width: ${theme.point820}) {
			position: static;
			grid-column: 1;
			margin-right: 3.25em;
		}

		@media screen and (max-width: ${theme.point720}) {
			&.margin-0 {
				margin-right: 0;
			}
		}
`
CarIcon.displayName = 'CarIcon'

export const ModalButtons = styled.div`
  height: max-content;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 0.63em;

  @media screen and (max-width: ${theme.point720}) {
		grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    min-height: 3.44em;
		padding-right: 0.31em;
  }
`
ModalButtons.displayName = 'ModalButtons'

export const ModalButtonsItem = styled.button`
  font-size: 1.25em;
  width: 100%;
  height: 100%;
  border-radius: 0.6em;
  background-color: ${colorTheme.platformDriversModal.buttons.background};
  color: ${colorTheme.platformDriversModal.buttons.text};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fontRobotoMedium};
	font-weight: 600;
  cursor: pointer;
  position: relative;
  padding: 0.25em 0.75em;
	gap: 0.5em;
	height: 2.75em;

	> svg {
		width: 1.45em;
		height: 1.2em;
		fill: ${colorTheme.platformDriversModal.buttons.icon};
	}

  &.active, &:focus {
    background: ${colorTheme.platformDriversModal.buttons.active.background};
    color: ${colorTheme.platformDriversModal.buttons.active.text};
    box-shadow: 0 4px 35px ${colorTheme.platformDriversModal.buttons.active.shadow};

		> svg {
			fill: ${colorTheme.platformDriversModal.buttons.active.icon};
		}
  }

  &.disabled {
		background-color: ${colorTheme.platformDriversModal.buttons.disabled.background};
		border-color: ${colorTheme.platformDriversModal.buttons.disabled.background};
		&.active {
			box-shadow: none;
		}
		&:focus {
			box-shadow: none;
		}
	}

  @media screen and (max-width: ${theme.point820}) {
    &.active, &:focus {
      padding: 0;
    }
  }
`
ModalButtonsItem.displayName = 'ModalButtonsItem'

export const OrderWrap = styled.div`
  height: 15em;
  width: 100%;
  display: flex;
  flex-direction: column;
	padding: 0.63em 0 0 0.63em;

  @media screen and (max-width: ${theme.point820}) {
    height: auto;
    width: 100%;
  }
`
OrderWrap.displayName = 'OrderWrap'

export const OrderInfoText = styled.div`
	font-size: 1.25em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	color: ${colorTheme.platformDriversModal.text.dark};

	&.small {
		font-size: 1em;
	}

	&.bold {
		font-size: 2em;
	}

	&.purple {
		color: ${colorTheme.platformDriversModal.text.purple};
	}

  @media screen and (max-width: ${theme.point820}) {
    &.bold {
			font-size: 1.5em;
		}
  }
`
OrderInfoText.displayName = 'OrderInfoText'

export const OrderInfoWarning = styled.div`
	display: flex;
	grid-column: 1 / 3;
	width: 100%;
	padding: 1.25em;
	justify-content: center;
	gap: .67em;
	border-radius: .75em;
	border: 1px dashed  ${colorTheme.platformDriversModal.info};
	font-size: 1rem;

	> svg {
		width: 1.5em;
		height: 1.5em;
		fill: ${colorTheme.platformDriversModal.info};
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-column: 1;
		text-align: left;
	}

`
OrderInfoWarning.displayName = 'OrderInfoWarning'

export const OrderInfoWarningText = styled.div`
	font-size: 1.25em;
	color: ${colorTheme.platformDriversModal.info};

	@media screen and (max-width: ${theme.point720}) {
		padding-top: .13em;
	}
`
OrderInfoWarningText.displayName = 'OrderInfoWarningText'

export const OrderPreorderBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.63em;
	padding-right: 0.94em;
	justify-content: center;
	align-items: flex-end;
	border-right: 1px solid ${colorTheme.platformDriversModal.preorderBorder};

	@media screen and (max-width: ${theme.point820}) {
		gap: 0.36em;
		padding-right: 0;
		border: none;
	}
`
OrderPreorderBlock.displayName = 'OrderPreorderBlock'
