import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setRestaurantOrderModal, setRestaurantOrderSplitModal, setRestaurantSwapModal, setRestaurantTable } from '../../../../../redux/action/restaurant'
import ButtonsModal from '../../../../Elements/modal/ButtonsModal'
import { getTablePrice } from '../../../../../helper'
import { PaymentCost, PaymentTitle, PaymentTitleWrapper } from '../../../../Elements/cart/index.styled'
import Currency from '../../../../Elements/currency'
import { selectGiftCouponOnTable } from '../../../../../redux/selector/restaurant'

const EVENT_Z_INDEX = 40

const ModalOrder = () => {
	const showOrderModal = useSelector(state => state.restaurant.showOrderModal)
	const table = useSelector(state => state.restaurant.table)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const hasGiftCoupon = useSelector(selectGiftCouponOnTable)

	const handleClose = () => {
		dispatch(setRestaurantTable(null))
		dispatch(setRestaurantOrderModal(false))
	}

	const handleSwapClick = () => {
		dispatch(setRestaurantOrderModal(false))
		dispatch(setRestaurantSwapModal(true))
	}

	const navigateToSplit = () => {
		dispatch(setRestaurantOrderModal(false))
		dispatch(setRestaurantOrderSplitModal(true))
	}

	const buttons = [
		{ icon: 'swap_order', text: t('restaurant.tables.modal.all_order'), onClick: handleSwapClick, show: true },
		{ icon: 'split_order', text: t('restaurant.tables.modal.part_order'), onClick: navigateToSplit, show: !hasGiftCoupon },
	]
	return (
		<ButtonsModal isOpen={!!(showOrderModal && table)}
									close={handleClose}
									title={'Tisch #' + (table?.number || '')}
									size="small"
									autoFocus={false}
									zIndex={EVENT_Z_INDEX}
									buttons={buttons}>
			<PaymentTitleWrapper>
				<PaymentTitle>{t('Cart.titlePrice')}: </PaymentTitle>
				<PaymentCost><Currency>{getTablePrice(table)}</Currency></PaymentCost>
			</PaymentTitleWrapper>
		</ButtonsModal>
	)
}

ModalOrder.displayName = 'ModalOrder'
export default ModalOrder
