import React, { useEffect, useRef, useState } from 'react'
import { CartFooter, CartProducts, CartTableHead, CartTotal, CartTotalContent, CartTotalPrice, CartTotalPriceText, CartTotalPriceValue, CartTotalText, CartTotalValue, CartWrapper } from '../../../../Elements/cart/index.styled'
import Currency from '../../../../Elements/currency'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '../../../../../hooks/useLocalStorage'
import Button from '../../../../Elements/buttons/Button'
import { usePrevious } from '../../../../../hooks/usePrevious'
import ActionModal from '../../../../Elements/modal/ActionModal'
import { useDispatch, useSelector } from 'react-redux'
import CircleLoader from '../../../../Elements/loaders/CircleLoader'
import { getBusinessScopeDiscountThunk } from '../../../../../redux/thunk/cartDiscount'
import { selectCartDiscountValue, selectDiscountOnTable, selectTotalPriceOnTable } from '../../../../../redux/selector/restaurant'
import CartItem from '../../../../Elements/cart/CartItem'
import { useWindowBreakPoint } from '../../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../../style/theme'
import { setTableCartShowMobileCart } from '../../../../../redux/action/tableCart'
import { useNavigate } from 'react-router-dom'
import { checkSavedCartItemsRemoveAbility } from './helper'

const EVENT_Z_INDEX = 36

const Cart = ({ showTotal, handleOpenProduct, orders, orderFocusIndex, itemFocusIndex, zIndex, setFocus, incrementQuantity, dropProduct, dropTopping, submitCart, removeRemark, addItemToNewOrder, hasGiftCoupon }) => {
	const dispatch = useDispatch()
	const [touchPad] = useLocalStorage('touchPad', false)
	const { t } = useTranslation()
	const cartRef = useRef()
	const items = orders ? orders[orders?.length - 1]?.items : null
	const prevItems = usePrevious(items)
	const loading = useSelector(state => state.tableCart.loading)
	const hasNewOrder = orders?.some(order => !order.id)
	const [backModalIsOpen, setBackModalIsOpen] = useState(false)
	const discountValue = useSelector(selectCartDiscountValue(hasGiftCoupon))
	const discountInCurrency = useSelector(selectDiscountOnTable(orders, hasGiftCoupon))
	const totalPrice = useSelector(selectTotalPriceOnTable)
	const enabledDishCourse = useSelector(store => store.restaurantSettings.dishCourses?.enabled)
	const isMobile = useWindowBreakPoint(theme.point720)
	const navigate = useNavigate()

	const savedItemsRemoveAbility = checkSavedCartItemsRemoveAbility(orders)
	const layoutHead = (touchPad ? 'touchPad' : '') + (enabledDishCourse ? ' dishCourse' : '')

	useEffect(() => {
		dispatch(getBusinessScopeDiscountThunk('RESTAURANT'))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (items?.length > prevItems?.length) {
			cartRef.current.scrollTop = 100000
		}
	}, [items]) // eslint-disable-line react-hooks/exhaustive-deps

	const navigateBack = () => {
		navigate('/restaurant')
		dispatch(setTableCartShowMobileCart(false))
	}

	const handleBackClick = () => {
		if (hasNewOrder) {
			setBackModalIsOpen(true)
		} else {
			navigateBack()
		}
	}

	return (
		<CartWrapper className="cart-col">
			<CartTableHead className={layoutHead}>
				{enabledDishCourse && (isMobile ? <div></div> : <div>Gang</div>)}
				<div>ID</div>
				<div>{t('Cart.name')}</div>
				<div>{t('Cart.price')}</div>
				<div>{t('Cart.total')}</div>
			</CartTableHead>
			<CartProducts className={'tableCart ' + (touchPad ? 'touchPad' : '')} ref={cartRef}>
				{orders?.map((order, orderIndex) =>
					order.items?.map((item, itemIndex) =>
						<CartItem key={`${orderIndex}-${itemIndex}`}
											active={orderIndex === orderFocusIndex && itemIndex === itemFocusIndex}
											item={item}
											savedOrder={!!order.id}
											removeAbility={!order.id || savedItemsRemoveAbility}
											onClick={() => setFocus(orderIndex, itemIndex, item)}
											onClickPlus={event => order.id ? addItemToNewOrder(event, item, itemIndex, orderIndex) : incrementQuantity(event, item, itemIndex, orderIndex)}
											onClickMinus={event => dropProduct(event, orderIndex, itemIndex, !order.id, item)}
											onClickToppingMinus={(event, subItemIndex) => dropTopping(event, orderIndex, itemIndex, subItemIndex, !order.id)}
											handleRemoveRemark={(event, subItemIndex) => removeRemark(event, orderIndex, itemIndex, subItemIndex, !order.id)}
											enabledDishCourse={enabledDishCourse}
											onClickProduct={(e) => handleOpenProduct(orderIndex, itemIndex, item, e)} />))}
			</CartProducts>
			{showTotal &&
				<CartTotal>
					<CartTotalContent className="grid">
						<CartTotalText>{t('Cart.discount')}:</CartTotalText>
						<CartTotalValue>{discountValue}%</CartTotalValue>
						<CartTotalValue>-<Currency>{discountInCurrency}</Currency></CartTotalValue>
					</CartTotalContent>
					<CartTotalPrice>
						<CartTotalPriceText>{t('Cart.totalprice')}</CartTotalPriceText>
						<CartTotalPriceValue className="total">
							<Currency>{totalPrice}</Currency>
						</CartTotalPriceValue>
					</CartTotalPrice>
				</CartTotal>}
			<CartFooter>
				<Button name="back" icon="back" text={t('buttons.controls.back')} keyButton="F4" zIndex={zIndex} onClick={handleBackClick} />
				<Button name="send" icon={!loading && 'print'} text={loading ? t('restaurant.button_is_printing') : t('restaurant.button_print')} keyButton="F2" color={loading ? 'gray' : 'green'} zIndex={zIndex} onClick={submitCart} disabled={loading || !hasNewOrder}>
					{loading && <CircleLoader />}
				</Button>
			</CartFooter>
			<ActionModal modalIsOpen={backModalIsOpen}
									 accept={navigateBack}
									 cancel={() => setBackModalIsOpen(false)}
									 close={() => setBackModalIsOpen(false)}
									 modalTitle={t('Cart.ActionModal.title')}
									 modalBody={t('Cart.ActionModal.body')}
									 zIndex={EVENT_Z_INDEX} />
		</CartWrapper>
	)
}

Cart.displayName = 'Cart'
export default Cart
