export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_CATEGORIES_UPDATE_CATEGORY = 'SET_CATEGORIES_UPDATE_CATEGORY'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_CATEGORIES_LENGTH = 'SET_CATEGORIES_LENGTH'
export const APPEND_CATEGORIES = 'APPEND_CATEGORIES'
export const SET_DEFAULT_CATEGORIES = 'SET_DEFAULT_CATEGORIES'
export const SET_CATEGORY_PARENTS = 'SET_CATEGORY_PARENTS'
export const UPDATE_CATEGORY_ORDER = 'UPDATE_CATEGORY_ORDER'
export const SET_SHOW_CATEGORY_SIZE_ERROR_MODAL = 'SET_SHOW_CATEGORY_SIZE_ERROR_MODAL'
export const SET_CATEGORY_IMAGE_UPLOAD_STEP = 'SET_CATEGORY_IMAGE_UPLOAD_STEP'
export const SET_CATEGORY_IMAGE_CONDITIONS = 'SET_CATEGORY_IMAGE_CONDITIONS'
export const SET_CATEGORY_IMAGE_CONFIG = 'SET_CATEGORY_IMAGE_CONFIG'
export const SET_CATEGORY_IMAGE_ERROR_MODAL = 'SET_CATEGORY_IMAGE_ERROR_MODAL'
export const SET_CATEGORY_ICON_URL = 'SET_CATEGORY_ICON_URL'
export const SET_CATEGORY_COLOR_DATA = 'SET_CATEGORY_COLOR_DATA'
export const SET_SHOW_CATEGORY_ICON_PICKER = 'SET_SHOW_CATEGORY_ICON_PICKER'
export const SET_CATEGORY_DELETE_ERROR_MODAL = 'SET_CATEGORY_DELETE_ERROR_MODAL'
export const SET_SHOW_CATEGORY_COLOR_PICKER = 'SET_SHOW_CATEGORY_COLOR_PICKER'
export const SET_CATEGORY_IMAGE_NAV_UPLOAD_STEP = 'SET_CATEGORY_IMAGE_NAV_UPLOAD_STEP'
export const SET_CATEGORY_IMAGE_NAV_CONDITIONS = 'SET_CATEGORY_IMAGE_NAV_CONDITIONS'
export const SET_CATEGORY_IMAGE_NAV_CONFIG = 'SET_CATEGORY_IMAGE_NAV_CONFIG'
export const SET_CATEGORY_IMAGE_NAV_ERROR_MODAL = 'SET_CATEGORY_IMAGE_NAV_ERROR_MODAL'
